import React, { useState } from "react";
import {
  Button,
  Input,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "reactstrap";
import location from "../../../assets/img/location.svg";
import { useNavigate } from "react-router";
import { useSelector, useDispatch } from "react-redux";
import { buyAddOn } from "../../../store/membership/membershipActions";
import { toast, ToastContainer } from "react-toastify";

const AddOn = (props) => {
  const { addOnModal, setAddOnModal, data } = props;
  const [selectedAdd, setSelectedAdd] = useState([]);
  const [haveCoupon, setHaveCoupon] = useState(false);
  const dispatch = useDispatch();

  const navigate = useNavigate();

  const { user } = useSelector((state) => state.auth);

  const handleCheckChange = (data, e) => {
    console.log(data?.Feauture_Values[0]?.value, "fff");
    if (e.target.checked) {
      setSelectedAdd((prev) => {
        let newArr = [...prev];
        newArr.push({
          feature_id: data?.id,
          feature_count: data?.add_on_value,
          user_id: user?.data?.id,
          membership_id: user?.data?.membership?.id,
          amount: data?.Feauture_Values[0]?.value,
        });
        return newArr;
      });
    } else {
      setSelectedAdd((prev) => {
        const filterArr = prev.filter((item) => item.feature_id !== data.id);
        return filterArr;
      });
    }
  };

  const handleBuy = () => {
    const data = {
      amount: 100,
      description: "Purchased Add-ons",
      transaction_status: "SUCCESS",
      addons: selectedAdd,
      type: "debit",
    };
    dispatch(buyAddOn(data))
      .then((res) => {
        if (res.success) {
          toast.success(res.message, {
            position: toast.POSITION.BOTTOM_LEFT,
          });
          props.setUpdate(!props.update);

          setAddOnModal(false);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  function totalAmount() {
    const totalAmount = selectedAdd?.reduce(
      (acc, ele) => acc + (Number(ele?.amount) || 0),
      0
    );

    return totalAmount;
  }

  return (
    <div>
      <Modal
        className="setting-modal-default-modal"
        isOpen={addOnModal}
        size="lg"
        style={{ width: "550px" }}
        centered
      >
        <div className="popup">
          <ModalHeader toggle={() => setAddOnModal(false)}>
            <h5 className="text-light">
              {user?.data?.membership?.name} Add-Ons Packages
            </h5>
          </ModalHeader>
          <ModalBody>
            {/* <div className='location' style={{ marginTop: "10px" }}>
              <p className='mt-0'><span className='gray-light400'>Your Location:</span> <img src={location} alt='Icon' /><span className='gray-light600'>{data?.location}</span></p>
            </div> */}
            <div className="px-2">
              {data?.data && data?.data.length > 0 ? (
                data?.data.map((ele) => {
                  let cat;
                  if (ele?.Feauture_Values[0]?.type == "cat1") {
                    cat = "Category 1";
                  } else if (ele?.Feauture_Values[0]?.type == "cat2") {
                    cat = "Category 2";
                  } else {
                    cat = "Category 3";
                  }
                  return (
                    <div
                      className="d-flex justify-content-between align-items-center mt-3"
                      // style={{
                      //   boxSizing: "border-box",
                      //   flexDirection: "row",
                      //   borderRadius: "8px",
                      //   boxShadow: "rgba(0, 0, 0, 0.2) 0px 0px 20px",
                      //   padding: "10px",
                      //   marginTop: "20px",
                      //   marginBottom: "20px",
                      //   backgroundColor: "rgb(240, 240, 240)",
                      // }}
                    >
                      <div className="d-flex">
                        <input
                          type="checkbox"
                          onChange={(e) => handleCheckChange(ele, e)}
                          style={{ height: "20px", width: "20px" }}
                          className=" mt-1"
                        />
                        <div className="mx-2">
                          <p className="mt-0">{ele.name}</p>
                          <span>
                            {ele?.add_on_value}x included in membership
                          </span>
                        </div>
                      </div>
                      <p style={{ fontWeight: 400 }}>
                        ${ele?.Feauture_Values[0]?.value}
                      </p>
                    </div>
                  );
                })
              ) : (
                <></>
              )}
            </div>
          </ModalBody>
          <div className="line"></div>
          <div className="p-4">
            <div className="d-flex align-items-center">
              <input
                type="checkbox"
                checked={haveCoupon}
                onChange={() => setHaveCoupon(!haveCoupon)}
                style={{ height: "15px", width: "15px" }}
              />
              <span className="mx-3">I have a coupon code</span>
            </div>
            {haveCoupon && (
              <div className="d-flex justify-content-between mt-3">
                <Input type="text" style={{ width: "70%" }} />
                <div>
                  <Button
                    style={{
                      color: "#0432A3",
                      border: 0,
                      background: "rgb(4 50 163 / 9%)",
                    }}
                  >
                    Apply Code
                  </Button>
                </div>
              </div>
            )}
            <div className="text-end mt-5">
              <div className="d-flex flex-column">
                <span className="text-dark" style={{ fontWeight: 500 }}>
                  Total Cost : {totalAmount()}$
                </span>
                <span className="text-dark" style={{ fontWeight: 500 }}>
                  Discount : {haveCoupon ? 8 : 0}$
                </span>
              </div>
              <div className="d-flex mt-3 justify-content-end">
                <h3 className="text-dark">${totalAmount()}</h3>
                <button
                  className="btn btn-blue mx-2 me-0"
                  onClick={() => handleBuy()}
                >
                  Buy
                </button>
              </div>
              <div className="d-flex flex-column mt-1">
                <small
                  className="text-secondary"
                  style={{ fontWeight: 400, paddingBottom: 0 }}
                >
                  Add-ons will be discontinued upon
                </small>
                <small
                  className="text-secondary mt-0"
                  style={{ fontWeight: 400, paddingBottom: 0 }}
                >
                  unsubscribing or downgrading your subscription.
                </small>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default AddOn;
