import React from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import pagePlaceholder from "../../assets/img/page-placeholder.svg";
import confidential from "../../assets/img/confidentialIcon.svg";
import closeIcon from "../../assets/img/Close_x_icon.svg";
import { useNavigate } from "react-router-dom";
import { GetTime } from "../../core/components/Utility/Utility";

const MyJobView = (props) => {
  const { getAllJobs, loadMore, isMoreJobs, selectedSubTab, handleClose } =
    props;
  const navigate = useNavigate();
  return (
    <>
      {getAllJobs.length > 0 ? (
        <InfiniteScroll
          dataLength={getAllJobs?.length ? getAllJobs?.length : 0}
          next={loadMore}
          hasMore={isMoreJobs}
          style={{ overflow: "visible" }}
        >
          <div className="my-job-nav-list-outer">
            {getAllJobs?.map((item, index) => (
              <div className="my-job-nav-list" key={index}>
                <div className="profile-detail-outer" style={{ width: "80%" }}>
                  <img
                    src={
                      item?.company_visible
                        ? item?.company?.icon
                          ? item?.company?.icon
                          : pagePlaceholder
                        : confidential
                    }
                    alt="Logo"
                    style={{
                      width: "50px",
                      height: "50px",
                      borderRadius: "50%",
                    }}
                  />
                  <div className="profile-detail">
                    <h5
                      className="card-control-text-oneline"
                      onClick={() => navigate(`/jobdetails/${item.id}`)}
                    >
                      {item?.title}
                    </h5>
                    <h6 className="card-control-text-twoline">
                      {item?.company_visible
                        ? item?.company_name
                        : "Confidential"}{" "}
                      &#x2022; {item?.job_location}
                    </h6>
                  </div>
                </div>
                <div className="close">
                  {selectedSubTab === "Saved" && (
                    <div
                      className="close-icon"
                      onClick={() => handleClose(item?.id)}
                    >
                      <img src={closeIcon} alt="delIcon" />
                    </div>
                  )}
                  {selectedSubTab === "Smart Interview Sent" &&
                    (item?.shortlistedJob?.interview_results?.length > 0 ? (
                      <p>
                        <strong>Submitted</strong>
                      </p>
                    ) : item?.shortlistedJob?.interview_result == "rejected" ? (
                      <p>
                        <strong>Rejected</strong>
                      </p>
                    ) : new Date(item?.shortlistedJob?.interview_date) >
                      new Date() ? (
                      <button
                        className="btn btn-blue"
                        style={{ width: "max-content" }}
                        onClick={() =>
                          navigate("/recruiter/smart-interview", {
                            state: item?.shortlistedJob,
                          })
                        }
                      >
                        Give Interview
                      </button>
                    ) : (
                      <p>
                        <strong>Expired</strong>
                      </p>
                    ))}
                  <div className="time">{GetTime(item?.createdAt)}</div>
                </div>
              </div>
            ))}
          </div>
        </InfiniteScroll>
      ) : (
        <div className="my-job-nav-list-outer d-flex justify-content-center mt-3">
          <h6 className="create">No Jobs</h6>
        </div>
      )}
    </>
  );
};

export default MyJobView;
