import {
  GET_TALENT_POOL_DETAIL,
  GET_TALENT_POOL_DETAIL_BY_TOKEN,
  GET_TALENT_POOL_DETAIL_BY_TOKEN_FAIL,
  GET_TALENT_POOL_DETAIL_FAIL,
  LOGIN_FAIL,
  LOGIN_SUCCESS,
  LOGOUT_PORTAL,
  REGISTER_FAIL,
  REGISTER_SUCCESS,
  SET_MESSAGE,
  SUBSCRIBE_FOR_JOB,
  VERIFY_PORTAL_SUCCESS,
} from "../types";

import { initPageLoad } from "../loader/loaderActions";
import { portalApi } from "../../api/portalApi";
import { authApi } from "../../api/authAPI";
import { toast } from "react-toastify";

export const getTalentPoolDetails = (params) => (dispatch) => {
  dispatch(initPageLoad());
  return portalApi.getTalentPoolDetail(params).then(
    (response) => {
      dispatch({
        type: GET_TALENT_POOL_DETAIL,
        payload: response?.data,
      });
      return Promise.resolve(response);
    },
    (error) => {
      dispatch({
        type: GET_TALENT_POOL_DETAIL_FAIL,
      });
      console.log(error);
      return Promise.reject(error);
    }
  );
};

export const getTalentPoolDetailsByToken = () => (dispatch) => {
  dispatch(initPageLoad());
  return portalApi.getTPDetailByToken().then(
    (response) => {
      dispatch({
        type: GET_TALENT_POOL_DETAIL_BY_TOKEN,
        payload: response?.data,
      });
      return Promise.resolve(response);
    },
    (error) => {
      dispatch({
        type: GET_TALENT_POOL_DETAIL_BY_TOKEN_FAIL,
      });
      console.log(error);
      return Promise.reject(error);
    }
  );
};

export const verifyPortalSignup = (data) => (dispatch) => {
  return portalApi.verifyPortalOtp(data).then(
    (data) => {
      dispatch({
        type: VERIFY_PORTAL_SUCCESS,
        payload:  data ,
      });

      return Promise.resolve(data);
    },
    (error) => {
      console.log(error);
      toast.error(error.response.data.message, {
        position: toast.POSITION.BOTTOM_LEFT,
      });
      return Promise.reject(error);
    }
  );
};

export const register = (signUpData) => (dispatch) => {
  return portalApi.register(signUpData).then(
    (data) => {
      dispatch({
        type: REGISTER_SUCCESS,
        payload: { user: data },
      });

      dispatch({
        type: SET_MESSAGE,
        payload: data.message,
      });

      return Promise.resolve(data);
    },
    (error) => {
      console.log(error);
      dispatch({
        type: REGISTER_FAIL,
      });

      return Promise.reject(error);
    }
  );
};

export const login = (data, status) => (dispatch) => {
  return portalApi.login(data, status).then(
    (data) => {
      dispatch({
        type: LOGIN_SUCCESS,
        payload: { user: data },
      });
      return Promise.resolve(data);
    },
    (error) => {
      console.log(error);
      dispatch({
        type: LOGIN_FAIL,
      });
      return Promise.reject(error);
    }
  );
};

export const loginWithNP =
  (email, password, registration_type, rememberMe) => (dispatch) => {
    return portalApi
      .loginNP(email, password, registration_type, rememberMe)
      .then(
        (data) => {
          dispatch({
            type: LOGIN_SUCCESS,
            payload: { user: data },
          });
          return Promise.resolve(data);
        },
        (error) => {
          console.log(error);
          dispatch({
            type: LOGIN_FAIL,
          });
          return Promise.reject(error);
        }
      );
  };

export const logoutPortal = () => (dispatch) => {
  return authApi.logoutPortal().then(
    (response) => {
      dispatch({
        type: LOGOUT_PORTAL,
      });
      return Promise.resolve(response);
    },
    (error) => {
      return Promise.reject();
    }
  );
};

export const subscribeForJob = (data) => (dispatch) => {
  return portalApi.subscribe(data).then(
    (response) => {
      dispatch({
        type: SUBSCRIBE_FOR_JOB,
      });
      return Promise.resolve(response);
    },
    (error) => {
      return Promise.reject();
    }
  );
};
