import React, { useState, useEffect } from "react";
import SidebarFooter from "../../setting/SidebarFooter";
import { useNavigate, useLocation, Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import placeholderUser from "../../../assets/img/placeholder_user.jpg";
import { getSuggestedGroups } from "../../../store/groups/groupsActions";
import { getSuggestedEvents } from "../../../store/events/eventActions";
import { getAllPages } from "../../../store/pages/pagesAction";
import { getSuggestedArticles } from "../../../store/articles/articleActions";
import { getSuggestedNewsletters } from "../../../store/newsletter/newsletterActions";
import { myNetworkAPI } from "../../../api/myNetworkAPI";

var categories = [
  {
    name: "People",
    icon: "user-icon",
    route: "/peoples",
  },
  {
    name: "Posts",
    icon: "paper-plane",
    route: "/posts",
  },
  {
    name: "Groups",
    icon: "icons-share",
    route: "/groups",
  },
  {
    name: "Events",
    icon: "event-icon",
    route: "/event",
  },
  {
    name: "Pages",
    icon: "page-icon",
    route: "/pages",
  },
  {
    name: "Articles",
    icon: "artical-icon",
    route: "/articles",
  },
  {
    name: "Newsletters",
    icon: "news-icon",
    route: "/newsletter",
  },
  {
    name: "Hashtags",
    icon: "hashtag-icon",
    route: "/hashtag",
  },
];

export default function NetworkSidebar() {
  const navigate = useNavigate();

  const [sectionActive, setSectionActive] = useState("");
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const { user } = useSelector((state) => state.auth);
  const [countData, setCountData] = useState();

  useEffect(() => {
    for (var i = 0; i < categories.length; i++) {
      if (categories[i].route === pathname) {
        setSectionActive(categories[i].name);
        break;
      }
    }
  }, [pathname]);

  useEffect(() => {
    myNetworkAPI.getCounts().then((res) => {
      setCountData(res?.data);
    });
  }, []);
  function abbreviateNumber(value) {
    var suffixes = ["", "K", "M", "B", "T"];
    var suffixNum = Math.floor(("" + value).length / 3);
    var shortValue = parseFloat(
      (suffixNum != 0 ? value / Math.pow(1000, suffixNum) : value).toPrecision(
        2
      )
    );
    if (shortValue % 1 != 0) {
      shortValue = shortValue.toFixed(1);
    }
    return shortValue + suffixes[suffixNum];
  }

  return (
    <div>
      <div className="aside">
        <div className="sidebar">
          <aside>
            <div className="bg">
              <div className="back-image">
                {user?.data?.background_img ? (
                  <img
                    src={user?.data?.background_img}
                    alt=""
                    className="back-image-fluid"
                    style={{ position: "absolute" }}
                  />
                ) : (
                  <></>
                )}
              </div>
              <div className="profile-img">
                <Link to={"/profile/" + user?.data?.id}>
                  <img
                    src={
                      user?.data?.profile_img
                        ? user?.data?.profile_img
                        : placeholderUser
                    }
                    alt=""
                    className="img-fluid"
                  />
                </Link>
              </div>
            </div>
            <div className="nobel">
              {" "}
              {user?.data?.membership?.name || "Starter"}
            </div>
            <div className="px-3">
              <div className="profile-details">
                <Link to={"/profile/" + user?.data?.id}>
                  <h6 className="userNameTextWrap">
                    {user?.data?.first_name} {user?.data?.last_name}
                  </h6>
                </Link>
                <p className="card-control-text-twoline">
                  {/* {user?.data?.highestExperience ? (
                  <>
                    {user?.data?.highestExperience?.title +
                      " at " +
                      user?.data?.highestExperience?.company_name}
                  </>
                ) : (
                  ""
                )} */}
                  {user?.data?.profile_headline ? (
                    <>{user?.data?.profile_headline}</>
                  ) : (
                    ""
                  )}
                </p>
              </div>
              <div className="margin">
                <div
                  className="follower"
                  onClick={() => {
                    navigate("/connections");
                  }}
                >
                  <div className="box curserPointer">
                    <h6>
                      {user?.data?.connections
                        ? abbreviateNumber(user?.data?.connections)
                        : 0}
                    </h6>
                    <p>
                      {user?.data?.connections > 1
                        ? "Connections"
                        : "Connection"}
                    </p>
                  </div>
                  <div className="h-border "></div>
                  <div className="box curserPointer">
                    <h6>
                      {user?.data?.followers
                        ? abbreviateNumber(user?.data?.followers)
                        : 0}
                    </h6>
                    <p>
                      {user?.data?.followers > 1 ? "Followers" : "Follower"}
                    </p>
                  </div>
                  <div className="h-border"></div>

                  <div className="box curserPointer">
                    <h6>
                      {user?.data?.followings
                        ? abbreviateNumber(user?.data?.followings)
                        : 0}
                    </h6>
                    <p>
                      Following
                      {/* {user?.data?.followings > 1 ? "Followings" : "Following"} */}
                    </p>
                  </div>
                </div>
                <div className="line mb-20"></div>
              </div>
              <div className="view">
                {/* <div className="btn btn-blue d-block">
                {" "}
                <Link to={"/profile/" + user?.data?.id}>
                  <span style={{ color: "#ffffff" }}>View My Profile</span>
                </Link>
              </div> */}
                <div className="view_profile_network">
                  <Link to={"/profile/" + user?.data?.id}>
                    <span>View My Profile</span>
                  </Link>
                </div>
              </div>
              <div className="social">
                <ul className=" pb-3">
                  {categories.map((section, idx) => {
                    return (
                      <li
                        key={idx}
                        onClick={() => {
                          navigate(section.route);
                        }}
                        className={
                          section.name === sectionActive ? "active" : ""
                        }
                      >
                        <i className={section.icon}></i>
                        {section.name}

                        {section.name === "Groups" && countData?.group > 0 && (
                          <span className="count">{countData?.group}</span>
                        )}
                        {section.name === "Events" && countData?.event > 0 && (
                          <span className="count">{countData?.event}</span>
                        )}
                        {section.name === "Pages" && countData?.page > 0 && (
                          <span className="count">{countData?.page}</span>
                        )}
                        {section.name === "Articles" &&
                          countData?.article > 0 && (
                            <span className="count">{countData?.article}</span>
                          )}
                        {section.name === "Newsletters" &&
                          countData?.newsletter > 0 && (
                            <span className="count">
                              {countData?.newsletter}
                            </span>
                          )}
                      </li>
                    );
                  })}
                </ul>
              </div>
            </div>
          </aside>
        </div>
        <div>
          <Link to={"/network"}>
            <div className="left px-3 py-3 mb-10 d-flex justify-content-center border-radius-5 bg-white">
              <div className="discover">
                <h4>Discover more</h4>
              </div>
            </div>
          </Link>

          <SidebarFooter />
        </div>
      </div>
    </div>
  );
}
