import React, { useState, useEffect } from "react";
import {
  Modal,
  Input,
  ModalHeader,
  Label,
  FormGroup,
  ModalBody,
} from "reactstrap";
import "../groups.scss";
import { createGroup } from "../../../../store/groups/groupsActions";
import { useDispatch, useSelector } from "react-redux";
import EventBannerModal from "../../Events/components/eventBannerModal";
import axios from "axios";
import {
  editGroup,
  getGroupDetail,
} from "../../../../store/groups/groupsActions";
import Autocomplete from "react-autocomplete";
import { GetAllUsers } from "../../../../store/getAllUsers/allUsersAction";
import DropzoneGroups from "./DragNDrop";
import TagsInput from "../../../../core/components/HashTags/tagsInput";
import { getIndustryData } from "../../../../store/master/masterActions";
import Loader from "../../../../core/components/Loaders/ComponentLoader";
import placeholderUser from "../../../../assets/img/placeholder_user.jpg";
import AppLoader from "../../../../core/components/Loaders/appLoader/appLoader";
import { toast } from "react-toastify";
import DeleteModal from "../../../../core/components/modal/DeleteModal";
import edit from "../../../../assets/img/edit.svg";
const CreateGroupModal = (props) => {
  const { modal, toggle, title, editGroupData, setEditGroupData } = props;

  const { industryData } = useSelector((state) => state.master);
  const { isPageLoading } = useSelector((state) => state.loader);
  const { group_detail } = useSelector((state) => state.groups);
  const [uploadImage, setUploadedImage] = useState("");
  const [bannerModal, setBannerModal] = useState(false);
  const [croppedImage, setCroppedImage] = useState(
    editGroupData ? editGroupData.icon : ""
  );
  const [croppedBackgroundImage, setCroppedBackgroundImage] = useState(
    editGroupData ? editGroupData.background_img : ""
  );
  const [textLength, setTextLength] = useState(
    editGroupData?.description ? editGroupData.description.split(" ").length : 0
  );
  const [memberInput, setMemberInput] = useState("");
  const [memberData, setMemberData] = useState("");
  const [memberArray, setMemberArray] = useState([]);
  const [memberList, setMemberList] = useState([]);
  const [formStatus, setFormStatus] = useState(false);
  const [tags, setTags] = useState(
    editGroupData ? editGroupData?.hashtags?.map((tags) => tags.name) : []
  );
  const [industryInput, setIndustryInput] = useState(
    editGroupData ? editGroupData?.industry : ""
  );
  const [responseMsg, setResponseMsg] = useState("");
  const [addConfirmModal, setAddConfirmModal] = useState(false);
  const [removeConfirmModal, setRemoveConfirmModal] = useState(false);
  const [removeSpeakerId, setRemoveSpeakerId] = useState("");
  const [membersOpen, setMembersOpen] = useState(false);

  const [groupData, setGroupData] = useState(
    editGroupData
      ? {
          id: editGroupData.id,
          name: editGroupData.name,
          icon: editGroupData.icon,
          background_img: editGroupData.background_img,
          type: editGroupData.type,
          description: editGroupData.description,
          members_limit: editGroupData.members_limit,
          industry: editGroupData.industry,
          members: "",
          hashtags: editGroupData.hashtags,
        }
      : {
          name: "",
          icon: "",
          background_img: "",
          type: "public",
          description: "",
          members_limit: "",
          industry: "",
          members: "",
          hashtags: "",
        }
  );

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getIndustryData());
  }, []);

  useEffect(() => {
    let token = localStorage.getItem("accessToken");
    if (editGroupData?.id) {
      dispatch(getGroupDetail(editGroupData.id, token)).then((res) => {
        setMemberArray(
          res[0].members?.map((member) => ({
            id: member.user_id,
            profile_img: member.profile_img,
            first_name: member.first_name,
            last_name: member.last_name,
          }))
        );
      });
    }
  }, []);

  useEffect(() => {
    if (memberInput) {
      dispatch(GetAllUsers({ search: memberInput.val }))
        .then((res) => {
          if (res?.success === true) {
            setMemberList(res?.data);
          } else setMemberList([]);
        })
        .catch(() => {
          setMemberList([]);
        });
    }
  }, [memberInput]);

  function wordLimit(limit) {
    var val = groupData.description;
    var words = val.split(/\s+/);
    var legal = "";
    for (let i = 0; i < words.length; i++) {
      if (i < limit) {
        legal += words[i] + " ";
      }
      if (i >= limit) {
        setGroupData({ ...groupData, description: legal.trim() });
      }
    }
  }

  function getWordCount(str) {
    return str.split(" ").filter(function (n) {
      return n !== "";
    }).length;
  }

  const isDuplicate = () => {
    var containsId = memberArray.find((x) => x.id === memberData.id);
    return containsId;
  };

  const addMember = () => {
    if (!isDuplicate() && memberData) {
      setMemberArray([...memberArray, memberData]);
      setMemberData("");
      setMemberInput("");
    }
  };

  const removeSpeaker = (memberId) => {
    console.log(memberId);
    let filteredArray = memberArray.filter((item) => item.id !== memberId);
    setMemberArray(filteredArray);
  };

  useEffect(() => {
    checkValidation();
  }, [groupData.name, groupData.description, groupData.members_limit]);

  const [error, setError] = useState({
    name: "",
    description: "",
  });

  const checkValidation = () => {
    setFormStatus(true);
    if (!groupData?.name?.trim()) {
      setFormStatus(false);
    }
    if (!groupData?.description?.trim()) {
      setFormStatus(false);
    }
    if (!groupData?.members_limit) {
      setFormStatus(false);
    }
  };

  const createGroupSubmit = () => {
    let newDataFormat = {
      name: groupData.name,
      icon: croppedImage,
      background_img: croppedBackgroundImage,
      type: groupData.type,
      description: groupData.description,
      members_limit: groupData.members_limit,
      industry: groupData.industry,
      members: memberArray
        .filter(function (e) {
          return e.id;
        })
        .map(function (e) {
          return e.id;
        }),
      hashtags: tags,
    };
    const token = localStorage.getItem("accessToken");
    if (groupData.id) {
      // newDataFormat.id = groupData.id;
      dispatch(editGroup(groupData.id, newDataFormat, token))
        .then((data) => {
          setEditGroupData("");
          toast.success("Group Updated Successfully.", {
            position: toast.POSITION.BOTTOM_LEFT,
          });
          toggle();
        })
        .catch((e) => {});
    } else {
      setFormStatus(false);
      dispatch(createGroup(newDataFormat, token))
        .then((res) => {
          console.log("group res", res);
          toast.success("Group Created Successfully!.", {
            position: toast.POSITION.BOTTOM_LEFT,
          });
          toggle();
          // setResponseMsg("Group Created Successfully.");
          // setTimeout(() => {
          //   // setResponseMsg(false);
          //   setFormStatus(true);
          //   toggle();
          // }, 2000);
        })
        .catch((e) => {});
    }
  };

  const onChangePicture = (e) => {
    if (e.target.files[0]) {
      setUploadedImage(URL.createObjectURL(e.target.files[0]));
      setBannerModal(true);
    }
  };

  const onInputClick = (event) => {
    event.target.value = "";
  };

  const handleBannerPicture = async (file, type) => {
    const newImage = new File([file], "newGroupCropped", { type: "image/png" });

    try {
      const UPLOAD_URL = `${process.env.REACT_APP_API_URL}api/v1/upload`;
      const formData = new FormData();
      const headers = {
        "Accept-Language": "en",
        "content-type": "multipart/form-data",
      };

      formData.append("image", newImage);

      await axios.post(UPLOAD_URL, formData, { headers }).then((res) => {
        console.log(res?.data?.urlsArray[0]);
        if (type === "background") {
          setCroppedBackgroundImage(res?.data?.urlsArray[0]);
        } else {
          setCroppedImage(res?.data?.urlsArray[0]);
        }
      });
    } catch (err) {
      console.log(err);
    }
  };

  const closeModal = () => {
    setEditGroupData("");
    toggle();
  };

  const [titleError, setTitleError] = useState("");

  const validTitle = () => {
    if (!groupData?.name?.trim()) {
      setTitleError("Please enter a valid name");
    } else {
      setTitleError("");
    }
  };

  //const [selectedMembers, setSelectedMembers] = useState([]);
  const toggleConfirmModal = () => {
    if (memberData?.length === 0) {
      toast.error("Please select at least one member.", {
        position: toast.POSITION.BOTTOM_LEFT,
      });
      console.log("Please select at least one member.");
    } else {
      setAddConfirmModal(!addConfirmModal);
    }
  };
  console.log("memberArrraytest305", memberData);

  return (
    <>
      {isPageLoading && <AppLoader />}
      <div>
        <Modal isOpen={modal} size="md" style={{ maxWidth: "600px" }}>
          <div className="create-group">
            <ModalHeader toggle={closeModal}>
              {editGroupData ? "Edit Group" : title}
            </ModalHeader>
            <ModalBody>
              <div className="box">
                <div className="main-drag group-drag">
                  <DropzoneGroups
                    handleBannerPicture={handleBannerPicture}
                    croppedBackgroundImage={croppedBackgroundImage}
                  />
                  <input
                    type="file"
                    id="group_icon_pic"
                    name="group_icon_pic"
                    accept="img/*"
                    style={{ display: "none" }}
                    onChange={onChangePicture}
                    onClick={onInputClick}
                  />
                  <div
                    className="add-icon object-fit-cover"
                    onClick={() => {
                      try {
                        document.getElementById("chosefile").click();
                      } catch (error) {
                        console.log(error);
                      }
                    }}
                  >
                    {croppedImage ? (
                      <>
                        <img
                          src={croppedImage}
                          width="100%"
                          height="100%"
                          className="create-page-icon"
                          alt="icon"
                          style={{ position: "inherit", borderRadius: "50%" }}
                        />
                        <div
                          className="create-page-edit"
                          style={{
                            position: "absolute",
                            bottom: "6px",
                            marginLeft: "70px",
                          }}
                        >
                          <label htmlFor="group_icon_pic">
                            <img src={edit} alt="" />
                          </label>
                        </div>
                      </>
                    ) : (
                      <label
                        style={{ display: "block" }}
                        htmlFor="group_icon_pic"
                      >
                        <div className="img"></div>
                        <p style={{ cursor: "pointer" }}>Add Icon</p>
                      </label>
                    )}
                  </div>
                </div>
                <form className="mt-7">
                  <Input
                    className="input-type"
                    type="text"
                    placeholder="Group Name*"
                    maxLength="50"
                    value={groupData.name}
                    onBlur={validTitle}
                    onChange={(e) =>
                      setGroupData({ ...groupData, name: e.target.value })
                    }
                  />
                  {titleError && <p className="text-danger">{titleError}</p>}
                  <Input
                    className="input-type"
                    type="textarea"
                    value={groupData.description}
                    onKeyUp={wordLimit(200)}
                    maxLength="1500"
                    placeholder="Description*"
                    onChange={(e) => {
                      setGroupData({
                        ...groupData,
                        description: e.target.value,
                      });
                      setTextLength(
                        getWordCount(e.target.value) > 200
                          ? 200
                          : getWordCount(e.target.value)
                      );
                    }}
                  />
                  <p className="title-count"> {textLength}/200</p>

                  <TagsInput
                    tags={tags}
                    setTags={setTags}
                    placeholder="Add #"
                  />

                  {/* <Input
                  className="input-type"
                  type="text"
                  placeholder="Group Industry"
                  maxLength="50"
                  value={groupData.industry}
                  onChange={(e) =>
                    setGroupData({ ...groupData, industry: e.target.value })
                  }
                /> */}

                  <div className="autocomplete-wrapper">
                    <Autocomplete
                      inputProps={{ placeholder: "Group Industry" }}
                      value={industryInput}
                      items={industryData}
                      getItemValue={(item) => item.name}
                      shouldItemRender={(item, value) =>
                        item.name.toLowerCase().indexOf(value.toLowerCase()) >
                        -1
                      }
                      renderMenu={(items, value) => (
                        <div className="dropdown custom-scrollbar">
                          {items.length === 0
                            ? `No matches for ${value}`
                            : items}
                        </div>
                      )}
                      renderItem={(item, isHighlighted) => (
                        <div
                          className={`item ${
                            isHighlighted ? "selected-item" : ""
                          }`}
                        >
                          {item.name}
                        </div>
                      )}
                      onChange={(event, val) => setIndustryInput(val)}
                      onSelect={(val, obj) => {
                        setGroupData({ ...groupData, industry: val });
                        setIndustryInput(val);
                      }}
                      wrapperStyle={{}}
                    />
                  </div>
                  <FormGroup className="checkbox-type-margin" check inline>
                    <Input
                      name="radio2"
                      type="radio"
                      value="public"
                      checked={groupData?.type === "public" ? "checked" : ""}
                      onChange={(e) =>
                        setGroupData({ ...groupData, type: e.target.value })
                      }
                    />
                    <Label check>
                      <div className="d-flex align-items-center justify-content-center">
                        <div className="globe"></div>
                        <p>Public</p>
                      </div>
                    </Label>
                  </FormGroup>
                  <FormGroup check inline>
                    <Input
                      name="radio2"
                      type="radio"
                      value="private"
                      checked={groupData?.type === "private" ? "checked" : ""}
                      onChange={(e) =>
                        setGroupData({ ...groupData, type: e.target.value })
                      }
                    />
                    <Label check>
                      <div className="d-flex align-items-center justify-content-center">
                        <div className="privateIcon"></div>
                        <p>Private</p>
                      </div>
                    </Label>
                  </FormGroup>
                  <div className="speaker">
                    <div className="autocomplete-wrapper">
                      <Autocomplete
                        open={membersOpen}
                        inputProps={{ placeholder: "Add Members" }}
                        value={memberInput.val}
                        items={memberList}
                        getItemValue={(item) =>
                          item.first_name + " " + item.last_name
                        }
                        // shouldItemRender={(item, value) =>
                        //   item.first_name
                        //     .toLowerCase()
                        //     .indexOf(value.toLowerCase()) > -1
                        // }
                        renderMenu={(items, value) => (
                          <div className="dropdown custom-scrollbar">
                            {items.length === 0
                              ? `No matches for ${value}`
                              : items}
                          </div>
                        )}
                        renderItem={(item, isHighlighted) => (
                          <div
                            className={`item ${
                              isHighlighted ? "selected-item" : ""
                            }`}
                            style={{ display: "flex" }}
                          >
                            <img
                              src={
                                item.profile_img
                                  ? item.profile_img
                                  : placeholderUser
                              }
                              alt="dropdown_pic"
                              className="image-fit mr-10"
                              style={{ height: "36px", width: "36px" }}
                            />
                            <div>
                              <div className="name-heading-drop ">
                                {item.first_name} {item.last_name}
                              </div>
                              <div className="sub-heading-drop">
                                {item?.profile?.profile_headline ? (
                                  item?.profile?.profile_headline
                                ) : (
                                  <></>
                                )}
                              </div>
                            </div>
                          </div>
                        )}
                        onChange={(event, val) => {
                          if (val.length === 0) {
                            if (membersOpen) setMembersOpen(false);
                          } else {
                            if (!membersOpen) setMembersOpen(true);
                          }
                          setMemberInput({ val });
                        }}
                        onSelect={(val, obj) => {
                          setMemberInput({ val });
                          setMemberData(obj);
                          setMembersOpen(false);
                        }}
                        wrapperStyle={{}}
                      />

                      <div
                        className="add"
                        onClick={() => toggleConfirmModal()}
                      ></div>
                    </div>
                  </div>

                  <div className="images mt-3">
                    {memberArray.map((data, idx) => {
                      if (idx < 8) {
                        return (
                          <div className="preview-img" key={idx}>
                            <img
                              src={
                                data?.profile_img
                                  ? data?.profile_img
                                  : placeholderUser
                              }
                              className="image-fit"
                              alt=""
                              onClick={() =>
                                window.open(
                                  `${window.location.origin}/profile/${data?.id}`
                                )
                              }
                            />
                            <div
                              className="close"
                              onClick={() => {
                                setRemoveConfirmModal(!removeConfirmModal);
                                setRemoveSpeakerId(data?.id);
                              }}
                            >
                              X
                            </div>
                          </div>
                        );
                      } else return null;
                    })}
                    {memberArray?.length > 8 && (
                      <div className="preview-img">
                        <span className="count-img">
                          +{memberArray?.length - 8}
                        </span>
                      </div>
                    )}
                  </div>

                  <div className="row mt-3">
                    <div className="col-sm-12">
                      <Label>
                        <b>Group Member Limit*</b>
                      </Label>
                      <Input
                        type="select"
                        name="select"
                        id="exampleSelect"
                        placeholder="Select"
                        className="input-type-nopad"
                        value={groupData.members_limit}
                        onChange={(e) =>
                          setGroupData({
                            ...groupData,
                            members_limit: e.target.value,
                          })
                        }
                      >
                        <option value="">Select</option>
                        <option value="50">10</option>
                        <option value="50">20</option>
                        <option value="50">50</option>
                        <option value="50">75</option>
                        <option value="100">100</option>
                        <option value="200">200</option>
                        <option value="300">300</option>
                        <option value="50">400</option>
                        <option value="50">500</option>
                        <option value="50">600</option>
                        <option value="50">700</option>
                        <option value="50">800</option>
                        <option value="50">900</option>
                        <option value="50">1000</option>
                      </Input>
                    </div>
                  </div>
                </form>
                {/* {responseMsg && (
                  <label className="label-input100 text-success">
                    <b>{responseMsg}</b>
                  </label>
                )} */}
              </div>
            </ModalBody>
            <div className="mt-30 mb-20 ml-30 mr-30">
              {formStatus ? (
                <div
                  className="btn btn-blue d-block"
                  onClick={createGroupSubmit}
                >
                  {editGroupData ? "Update Group" : "Create Group"}
                </div>
              ) : (
                <div className="btn disabled-btn-blue d-block">
                  {editGroupData ? "Update Group" : "Create Group"}
                </div>
              )}
            </div>
          </div>
        </Modal>

        {bannerModal && (
          <EventBannerModal
            modal={bannerModal}
            toggle={() => setBannerModal(!bannerModal)}
            title="Edit Banner"
            uploadImage={uploadImage}
            croppedImage={croppedImage}
            setCroppedImage={setCroppedImage}
            handleBannerPicture={handleBannerPicture}
            setBannerModal={setBannerModal}
          />
        )}

        {addConfirmModal && (
          <DeleteModal
            modal={DeleteModal}
            toggle={() => setAddConfirmModal(!addConfirmModal)}
            title="Add Confirmation"
            deleteConfirm={() => {
              addMember();
            }}
            text="Do you want to add the member?"
          />
        )}

        {removeConfirmModal && (
          <DeleteModal
            modal={DeleteModal}
            toggle={() => setRemoveConfirmModal(!removeConfirmModal)}
            title="Remove Confirmation"
            deleteConfirm={() => {
              removeSpeaker(removeSpeakerId);
            }}
            text="Do you want to remove the member?"
          />
        )}
      </div>
    </>
  );
};

export default CreateGroupModal;
