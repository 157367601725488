import React from "react";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import { useNavigate } from "react-router-dom";
const CustomModel = ({
  modal,
  toggle,
  title,
  buttonTitle,
  message,
  handleSubmit,
  bodyTitle,
  bodyImage,
}) => {
  const navigate = useNavigate();
  return (
    <>
      <Modal
        size="md"
        isOpen={modal}
        style={{ maxWidth: "450px" }}
        centered
        className="back_ground_screening_model"
      >
        <ModalHeader
          className="dicardModalHeader text-white d-flex justify-content-start  align-items-center"
          toggle={toggle}
          style={{
            background:
              "linear-gradient(120.5deg, #5B2E92 0%, #29409C 50.49%, #0F91D2 100%)",
          }}
        >
          {title}
        </ModalHeader>
        <ModalBody>
          <div className="event-create-popup">
            {bodyTitle && (
              <h4 className="text-dark mb-3">
                <img src={bodyImage} width={20} /> {bodyTitle}
              </h4>
            )}
            <p>{message}</p>
            <div className="d-flex justify-content-center align-items-center mt-3">
              <button className="btn btn-blue" onClick={handleSubmit}>
                {buttonTitle}
              </button>
            </div>
          </div>
        </ModalBody>
      </Modal>
    </>
  );
};

export default CustomModel;
