import { Modal, ModalHeader, ModalBody } from "reactstrap";
import React from "react";
import { useSelector } from "react-redux";

const DeleteModal = (props) => {
  const { modal, toggle, title, deleteConfirm, text,isSmartInterview } = props;

  const closeButton = () => {
    toggle();
  };

  return (
    <Modal
      size="md"
      isOpen={modal}
      style={{ maxWidth: "450px" }}
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      {title === "" ? (
        <></>
      ) : (
        <ModalHeader
          className="dicardModalHeader"
          toggle={toggle}
          style={{ color: "#000" }}
        >
          {title}
        </ModalHeader>
      )}
      <ModalBody>
        <div className="event-create-popup">
          {<p style={{ textAlign: "center" }}>{text}</p>}
          <div className="text-center mt-30">
            <button className="btn btn-light text-blue" onClick={closeButton}>
            { isSmartInterview?"Back": "No"}
            </button>
            <button
              className="btn btn-blue ml-30"
              onClick={() => {
                deleteConfirm();
                closeButton();
              }}
            >
             {isSmartInterview?"Start": "Yes"}
            </button>
          </div>
        </div>
      </ModalBody>
    </Modal>
  );
};

export default DeleteModal;
