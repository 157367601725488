import axios from "axios";
import React, { useEffect, useState } from "react";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import rectangleImg from "../../../../assets/img/RectanglepayBycard.png";
import GifModal from "../../../../core/components/common/GifModal";
import { toast } from "react-toastify";
import celebBackground from "../../../../assets/img/celebBackground.jpg";

const SelectCelebImage = (props) => {
  const {
    modal,
    toggle,
    title,
    nextModal,
    images,
    backModal,
    selectedImage,
    setSelectedImage,
    data,
    image_text,
  } = props;
  const handleClose = () => {
    setSelectedImage("");
  };

  useEffect(() => {
    console.log(data, image_text, "gif data");
  }, [data]);
  const handleChange = async (e) => {
    if (e.target.files.length) {
      //  const  imageUrl =  URL.createObjectURL(e.target.files[0])
      //    setSelectedImage(imageUrl);
      try {
        const UPLOAD_URL = `${process.env.REACT_APP_API_URL}api/v1/upload`;
        const formData = new FormData();
        const headers = {
          "Accept-Language": "en",
          "content-type": "multipart/form-data",
        };
        formData.append("image", e.target.files[0]);
        await axios.post(UPLOAD_URL, formData, { headers }).then((res) => {
          console.log("chosen file", res.data.urlsArray[0]);
          console.log("Testthe data", data);
          setSelectedImage(res.data.urlsArray[0]);
        });
      } catch (err) {
        console.log(err);
      }
    } else {
      //alert("PLease choose file");
      toast.error("Please choose file");
    }
  };

  return (
    <Modal
      isOpen={modal}
      size="lg"
      style={{ maxWidth: "600px" }}
      centered
      className="gradient-background"
    >
      <ModalHeader style={{ padding: "16px 30px" }} toggle={toggle}>
        {title}
      </ModalHeader>
      <ModalBody>
        <div>
          <div className="imageContainer">
            <div className="mb-3">
              <ul className="imageList">
                {selectedImage ? (
                  <>
                    <img
                      src={selectedImage}
                      alt="thumbnail"
                      className="celebImageSelect"
                    />
                    <div
                      className="celebImageClose"
                      onClick={() => handleClose()}
                    >
                      X
                    </div>
                  </>
                ) : (
                  <li
                    style={{
                      width: "100%",
                      height: "300px",
                      background: `url(${celebBackground}) no-repeat center center`,
                      backgroundSize: "cover",
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                    }}
                  >
                    <h3
                      style={{
                        alignSelf: "center",
                        textAlign: "center",
                        marginLeft: "20px",
                        color: "black",
                      }}
                    >
                      {image_text}
                    </h3>
                    <img
                      style={{
                        width: "300px",
                        objectFit: "contain",
                        height: "87%",
                      }}
                      src={images}
                      alt="thumbnail"
                      className="celebImages"
                      //style={{heigt:"100%", marginRight: "26px",marginBottom: "14px",objectFit:"contain"}}
                      onClick={() => {
                        //setSelectedImage(images);
                        document.getElementById("selectedImg").scrollIntoView({
                          behavior: "smooth",
                        });
                      }}
                    />
                  </li>
                )}
              </ul>
            </div>

            <section
              style={{ marginBottom: "1.6rem", border: "1px solid #d1dfff" }}
            >
              <div className="addPhotodiv" id="selectedImg">
                <div>
                  <p
                    style={{
                      color: "blue",
                      cursor: "pointer",
                      textAlign: "center",
                    }}
                    onClick={() => {
                      try {
                        document.getElementById("chosefile").click();
                      } catch (error) {
                        console.log(error);
                      }
                    }}
                  >
                    Add a Photo
                  </p>
                </div>
              </div>
            </section>
          </div>
        </div>
        <input
          type="file"
          accept="image/png, image/gif, image/jpeg"
          id="chosefile"
          onChangeCapture={(e) => {
            handleChange(e);
          }}
        />
      </ModalBody>
      <div>
        <div className="row buttonRow">
          <div className="col-4 buttonCol">
            <button className="btn secondarybtn" onClick={() => backModal()}>
              Back
            </button>
            <button
              className="btn btn-blue"
              onClick={() => nextModal(selectedImage)}
            >
              Next
            </button>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default SelectCelebImage;
