import React from "react";
import Carousel from "react-multi-carousel";

const ImageCarousel = (props) => {
  const {
    images,
    largerView,
    Tags,
    createLink,
    showTags,
    setShowTags,
    fromDashboard,
  } = props;

  const responsive = {
    desktop: { breakpoint: { max: 3000, min: 1024 }, items: 1 },
    mobile: { breakpoint: { max: 464, min: 0 }, items: 1 },
    tablet: { breakpoint: { max: 1024, min: 464 }, items: 1 },
  };

  if (largerView)
    return (
      <div id="main-slide" className="carousel slide" data-ride="carousel">
        <div className="carousel-inner">
          <Carousel
            responsive={responsive}
            additionalTransfrom={0}
            arrows
            centerMode={false}
            className="larger-image-view"
            dotListClass=""
            draggable
            focusOnSelect={false}
            infinite={false}
            itemClass=""
            keyBoardControl
            minimumTouchDrag={80}
            renderButtonGroupOutside={false}
            renderDotsOutside={false}
            showDots={false}
            sliderClass=""
            slidesToSlide={1}
            swipeable
          >
            {images &&
              images?.length > 0 &&
              images?.map((item, index) => {
                return (
                  <div
                    key={index}
                    className="carousel-item active img-crousle"
                    onClick={() => setShowTags(!showTags)}
                  >
                    <img
                      alt=""
                      className="d-block w-100"
                      src={props.fromDashboard ? item.document_url : item}
                    />
                    {Tags && showTags ? (
                      <div className=" create-post">
                        {Tags.map((user, indx) => (
                          <>
                            {user?.media === item?.document_url && (
                              <div
                                key={indx}
                                className="left-side"
                                role="tooltip"
                                style={{
                                  position: "absolute",
                                  left: user.coordinate_x,
                                  top: user.coordinate_y,
                                  zIndex: 49,
                                  display: "inline-block",
                                }}
                              >
                                <span
                                  className="small-tooltip tagedperson"
                                  onClick={() => createLink(user)}
                                >
                                  {user?.user?.first_name.slice(0, 12) +
                                    (user?.user?.first_name.length > 12
                                      ? "..."
                                      : "") +
                                    " " +
                                    user?.user?.last_name.slice(0, 10) +
                                    (user?.user?.last_name.length > 10
                                      ? "..."
                                      : "")}
                                </span>
                              </div>
                            )}
                          </>
                        ))}
                      </div>
                    ) : (
                      <></>
                    )}
                  </div>
                );
              })}
          </Carousel>
        </div>
      </div>
    );

  return (
    <Carousel
      additionalTransfrom={0}
      arrows
      // autoPlaySpeed={3000}
      centerMode={false}
      partialVisible={false}
      className="px-0"
      containerClass="container-with-dots"
      dotListClass=""
      draggable
      focusOnSelect={false}
      infinite={false}
      itemClass=""
      keyBoardControl
      minimumTouchDrag={80}
      pauseOnHover
      renderArrowsWhenDisabled={false}
      renderButtonGroupOutside={false}
      renderDotsOutside={false}
      responsive={{
        desktop: {
          breakpoint: {
            max: 3000,
            min: 1024,
          },
          items: 2,
          partialVisibilityGutter: 40,
        },
        mobile: {
          breakpoint: {
            max: 464,
            min: 0,
          },
          items: 1,
          partialVisibilityGutter: 30,
        },
        tablet: {
          breakpoint: {
            max: 1024,
            min: 464,
          },
          items: 2,
          partialVisibilityGutter: 30,
        },
      }}
      rewind={false}
      rewindWithAnimation={false}
      rtl={false}
      shouldResetAutoplay
      showDots={false}
      sliderClass=""
      slidesToSlide={1}
      swipeable
    >
      {images &&
        images?.length > 0 &&
        images?.map((item, index) => {
          return (
            <div
              key={index}
              className="imgCrousle"
              onClick={() => {
                fromDashboard? props.changeImage("image"):props.changeImage(index)
              }}
            >
              <img
                alt=""
                src={props.fromDashboard ? item.document_url : item}
                className="img-fluid"
              />
            </div>
          );
        })}
    </Carousel>
  );
};

export default ImageCarousel;
