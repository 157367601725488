import React, { useState } from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import tick from "../../../../assets/img/tick-icon.svg";
import add from "../../../../assets/img/add-icon.svg";
import ellips from "../../../../assets/img/Ellipse-1.png";
import {
  followPage,
  getAllPages,
  unFollowPage,
} from "../../../../store/pages/pagesAction";
import { useNavigate, useParams } from "react-router-dom";
import EditUrl from "./EditUrl";

const PageViewSidebar = () => {
  const { pageData } = useSelector((state) => state.pages);
  const [isSticky, setIsSticky] = useState(false);
  const [editURL, setEditURL] = useState(true);
  const [textURL, setTextURL] = useState();
  const [pageNavURLStatus, setPageNavURLStatus] = useState("");
  const [urlErrorMessage, setUrlErrorMessage] = useState("");
  const [isEnable, setIsEnable] = useState(true);
  const  params=useParams();

  //console.log(params);
  //console.log(pageNavURL);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  useEffect(() => {
    dispatch(getAllPages({ page: 1, size: 5 }));
  }, []);
  const handleFollow = (pageID, is_following) => {
    if (is_following) {
      dispatch(unFollowPage(pageID));
    } else {
      dispatch(followPage(pageID));
    }
  };
  const navigateToPageAdmin = (id) => {
    navigate(`/pageadminview/${id}`);
    window.location.reload();
  };

const imageStyle={
  
  objectFit: "cover",
  height: "55px",
  borderRadius:"55%",
  width: "55px"
}

  return (
    <>
    <div className="side mt-4">
      <div className="bg-white p-20">
        <h3>Pages people also viewed</h3>
        {pageData ? (
          pageData?.data?.map((data, index) => {
            return (
              <div className="box " key={index}>
                <div className="d-flex gap-3 mt-20 mb-20">
                  <div className="img">
                    <img
                      src={data?.icon ? data?.icon : ellips}
                      //className="image-fit"
                      style={imageStyle}
                      alt=""
                    />

                    
   
                  </div>
                  <div className="content ">
                    <h4
                      onClick={() => navigateToPageAdmin(data?.id)}
                      className="card-control-text-twoline"
                    >
                      {data?.name}
                    </h4>
                    <p className="card-control-text-oneline">
                      {data?.industry ? data?.industry : ""}
                    </p>
                    {/* <div className="btn">follow</div> */}
                    <div
                      className="btn follow-btn"
                      onClick={() => {
                        handleFollow(data.id, data.is_following);
                      }}
                    >
                      {data.is_following ? (
                        <span className="follow-btn-icon">
                          {/* <img src={tick} alt="" className="followicon" />{" "} */}
                          Unfollow
                        </span>
                      ) : (
                        <span>
                          {/* <img src={add} alt="" className="followicon" /> */}
                          Follow
                        </span>
                      )}
                    </div>
                  </div>
                </div>
                <div className="line"></div>
              </div>
            );
          })
        ) : ( "")
        }
       
      </div>
    </div>
    
    {/* <div className="insight-side">
          <div className="bg-white b-5 ">
            <div className="row p-20">
              <div className="col-8">
                <h2 className="">Page Url</h2>
              </div>
             
            </div>

            <div className="line"></div>
          
           
            <a
              href={`/pageadminview/${textURL}`}
              className="text-blue-500"
              style={{
                wordWrap: "break-word",
                display: "block",
                padding: "13px",
              }}
            >
            </a>
          
           
          </div>
        </div> */}
        </>
  );
};

export default PageViewSidebar;
