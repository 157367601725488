import React from "react";
import ReactQuill from "react-quill";
import "quill-mention";
import "react-quill/dist/quill.snow.css";
import "./TextBoxCustom.scss";

const atValues = [
  { id: 1, value: "shubham1" },
  { id: 2, value: "shubham2" },
  { id: 3, value: "shubham3" },
  { id: 4, value: "shubham4" },
  { id: 5, value: "shubham5" },
  { id: 6, value: "shubham6" },
  { id: 7, value: "shubham7" },
  { id: 8, value: "shubham8" },
  { id: 9, value: "shubham9" },
  { id: 10, value: "shubham10" },
  { id: 11, value: "shubham11" }
];
const hashValues = [
  { id: 3, value: "Fredrik Sundqvist 2" },
  { id: 4, value: "Patrik Sjölin 2" }
];

const mentionModuleConfig = {
  allowedChars: /^[A-Za-z\sÅÄÖåäö]*$/,
  mentionDenotationChars: ["@", "#"],
  dataAttributes: [],
  source: function(searchTerm, renderList, mentionChar) {
    let values;

    if (mentionChar === "@") {
      values = atValues;
    } else {
      values = hashValues;
    }

    if (searchTerm.length === 0) {
      renderList(values, searchTerm);
    } else {
      const matches = [];
      for (let i = 0; i < values.length; i++)
        if (~values[i].value.toLowerCase().indexOf(searchTerm.toLowerCase()))
          matches.push(values[i]);
      renderList(matches, searchTerm);
    }
  }
};

const modules = {
  toolbar:false,
  mention: mentionModuleConfig
};

function TextBoxCustom({ postData, setPostData }) {

  const handleChange = (content, delta, source, editor) => {
    setPostData({ ...postData, description: content });
    // onChange(content); // should useDebounce
    console.log(delta, source, editor);
    console.log(editor.getContents());

  };
  return (
    <ReactQuill
      theme="snow"
      value={postData.description}
      onChange={handleChange}
      modules={modules}
      placeholder="What do you want to talk about?"
    />
  );
}

export default TextBoxCustom;
