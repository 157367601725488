import React from "react";
import Dropdown from "../../../core/components/DropDown/Dropdown";
import logo from "../../../assets/img/np-logo.svg";
import { useState } from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { getFAQSearch } from "../../../store/help/helpAction";
import BaannerImage from "../../../assets/img/helpCenter.png";

const HelpCenterSearchWebview = () => {
  const [searchList, setSearchList] = useState([]);
  const [dropdown, setDropdown] = useState(false);
  const dispatch = useDispatch();
  const list = useSelector((state) => state.help.searchList);
  const [label, setLabel] = useState("");

  useEffect(() => {
    if (list && list?.length > 0) {
      setSearchList(list);
    }
  }, [list]);

  useEffect(() => {
    document.addEventListener("mousedown", clickOutsideAdmin);
    return () => {
      document.removeEventListener("mousedown", clickOutsideAdmin);
    };
  }, []);

  const clickOutsideAdmin = (e) => {
    if (e?.toElement.className === "list") {
      return;
    }
    setDropdown(false);
  };

  const handleSearchChange = (e) => {
    if (e === "") {
      setSearchList([]);
      setDropdown(false);
    } else {
      setDropdown(true);
      dispatch(getFAQSearch({ search: e }));
    }
  };
  return (
    <>
      <div className="bg">
        <div className="container">
          <div className="row ">
            <div className="col col-lg-8 col-xl-8">
              <div className="row">
                <div className="col-8 col-md-8 col-lg-12">
                  <h1 className="mobile-res">Help Center</h1>
                </div>
                <div className="col-4 d-block d-md-none d-lg-none text-center">
                  <img
                    src={BaannerImage}
                    className="w-auto "
                    alt=""
                    style={{ height: "70px" }}
                  />
                </div>
              </div>
              <p className="helpP">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc
                vulputate libero et velit interdum, ac aliquet odio mattis.
                Class aptent taciti sociosqu ad litora torquent per conubia
                nostra, per inceptos
              </p>
              <div className="boxes flex-column">
                <div className="search">
                  <Dropdown
                    label="Select"
                    data={[
                      "NobelPage Help",
                      "NobelPage Help",
                      "NobelPage Help",
                      "NobelPage Help",
                    ].map((item, index) => ({
                      value: item,
                      id: index,
                    }))}
                    dynamicPlaceHolder={label}
                    dropdownValue="degree"
                    handleSelect={(value) => setLabel(value.value)}
                  />
                </div>
                <div className="search-box">
                  <input
                    type="text"
                    placeholder="How can we help?"
                    onChange={(e) => handleSearchChange(e.target.value)}
                  />
                  <div className="search-img"></div>
                  {dropdown &&
                    (searchList.length > 0 ? (
                      <div className="search-list-box">
                        <ul>
                          {searchList.map((item) => (
                            <Link to={`/help/question/${item.id}?mode=mobile`}>
                              <li
                                className="list"
                                onClick={() => setDropdown(!dropdown)}
                              >
                                {item.question}
                              </li>
                            </Link>
                          ))}
                        </ul>
                      </div>
                    ) : (
                      <div className="search-list-box">
                        <ul>
                          <li className="list">No Result Found</li>
                        </ul>
                      </div>
                    ))}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="n-logo">
          <img src={logo} alt="" width="100%" />
        </div>
      </div>
    </>
  );
};

export default HelpCenterSearchWebview;
