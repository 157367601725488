import React from "react";
import Header from "../core/components/layout/Header/Header";
import Loginfooter from "../core/components/layout/footer/LoginFooter";
import logo from "../assets/img/np-logo.svg";
import feed from "../assets/img/feedback.png";
import { Link } from "react-router-dom";

export default function Feedback() {
  return (
    <div>
      <Header />
      <div className="feedback help">
        <div className="bg">
          <div className="container">
            <div className="row">
              <div className="col-lg-8">
                <h1>Help Center</h1>
                <p>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc
                  vulputate libero et velit interdum, ac aliquet odio mattis.
                  Class aptent taciti sociosqu ad litora torquent per conubia
                  nostra, per inceptos
                </p>
                <div className="boxes">
                  <div className="search">
                    <select>
                      <option>NobelPage Help</option>
                      <option>NobelPage Help 5</option>
                      <option>NobelPage Help</option>
                      <option>NobelPage Help</option>
                    </select>
                  </div>
                  <div className="search-box">
                    <input type="text" placeholder="How can we help?" />
                    <div className="search-img"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="n-logo">
            <img src={logo} alt="" width="100%" />
          </div>
        </div>
        <div className="context">
          <section className="padding">
            <div className="container">
              <div className="row">
                <div className="col-lg-12">
                  <div className="wrapper">
                    <div className="heading">
                      <h4>How do I search on NobelPage</h4>
                    </div>
                    <p>
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                      Etiam eu turpis molestie, dictum est a, mattis tellus. Sed
                      dignissim, metus nec fringilla accumsan, risus sem
                      sollicitudin lacus, ut interdum tellus elit sed risus.
                      Maecenas eget condimentum velit, sit amet feugiat lectus.
                      Class aptent taciti sociosqu ad litora torquent per
                      conubia nostra, per inceptos himenaeos. Praesent auctor
                      purus luctus enim egestas, ac scelerisque ante pulvinar.
                      Donec ut rhoncus ex. Suspendisse ac rhoncus nisl, eu
                      tempor urna. Curabitur vel bibendum lorem. Morbi convallis
                      convallis diam sit amet lacinia. Aliquam in elementum
                      tellus. Curabitur tempor quis eros tempus lacinia. Nam
                      bibendum pellentesque quam a convallis. Sed ut vulputate
                      nisi. Integer in felis sed leo vestibulum venenatis.
                      Suspendisse quis arcu sem. Aenean feugiat ex eu vestibulum
                      vestibulum. Morbi a eleifend magna. Nam metus lacus,
                      porttitor eu mauris a, blandit ultrices nibh. Mauris sit
                      amet magna non ligula vestibulum eleifend. Nulla varius
                      volutpat turpis sed lacinia. Nam eget mi in purus lobortis
                      eleifend. Sed nec ante dictum sem condimentum ullamcorper
                      quis venenatis nisi. Proin vitae facilisis nisi, ac
                      posuere leo.
                    </p>
                    <div className="my-5">
                      <img src={feed} alt="" className="img-fluid" />
                    </div>
                    <p>
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                      Etiam eu turpis molestie, dictum est a, mattis tellus. Sed
                      dignissim, metus nec fringilla accumsan, risus sem
                      sollicitudin lacus, ut interdum tellus elit sed risus.
                      Maecenas eget condimentum velit, sit amet feugiat lectus.
                      Class aptent taciti sociosqu ad litora torquent per
                      conubia nostra, per inceptos himenaeos. Praesent auctor
                      purus luctus enim egestas, ac scelerisque ante pulvinar.
                      Donec ut rhoncus ex. Suspendisse ac rhoncus nisl, eu
                      tempor urna. Curabitur vel bibendum lorem. Morbi convallis
                      convallis diam sit amet lacinia. Aliquam in elementum
                      tellus. Curabitur tempor quis eros tempus lacinia. Nam
                      bibendum pellentesque quam a convallis. Sed ut vulputate
                      nisi. Integer in felis sed leo vestibulum venenatis.
                      Suspendisse quis arcu sem. Aenean feugiat ex eu vestibulum
                      vestibulum. Morbi a eleifend magna. Nam metus lacus,
                      porttitor eu mauris a, blandit ultrices nibh. Mauris sit
                      amet magna non ligula vestibulum eleifend. Nulla varius
                      volutpat turpis sed lacinia. Nam eget mi in purus lobortis
                      eleifend. Sed nec ante dictum sem condimentum ullamcorper
                      quis venenatis nisi. Proin vitae facilisis nisi, ac
                      posuere leo.
                    </p>

                    <div className="helpfull">
                      <div className="wrapper">
                        <p>Was this answer helpful?</p>
                      </div>
                      <div className="buttons">
                        <Link to className="active">
                          Yes
                        </Link>
                        <Link to>No</Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="bottom">
                <ul>
                  <li>Contact us</li>
                  <li>FAQ</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Loginfooter />
    </div>
  );
}
