import React, { useState } from "react";
import "./side.scss";
import play from "../../../../assets/img/play.svg";
import videoPic from "../../../../assets/img/videodemo.png";
import VideoViewerModal from "../../VideoViewer/videoViewer";
import add from "../../../../assets/img/add-icon.svg";
import DeleteModal from "../../modal/DeleteModal";
import plusIcon from "../../../../assets/img/plusIconFigma.svg"

export const VideoSidebar = (props) => {
  const {
    URLData,
    deleteIntroVideo,
    isAllowedToEdit,
    addIntroModal,
    setAddIntroModal,
  } = props;
  const [closeModal, setCloseModal] = useState(false);

  return (
    <div>
      <div className="sidebar-custom">
        <div className="wrapper">
          <div className="row mb-20">
            <div className="col-8">
              <div className="text">
                <p>Introduction Video</p>
              </div>
            </div>
            { isAllowedToEdit() && (
              <div className="col-4">
                <div className="float-end">
                  {URLData && (
                    <div
                      className="delete"
                      onClick={() => setCloseModal(!closeModal)}
                    ></div>
                  )}
                </div>
              </div>
            )}
          </div>
          <div className="row">
            <div className="col-lg-12">
              <div className="video">
                {URLData ? (
                  <div>
                    <video
                      controls
                      width="100%"
                      controlsList="nodownload"
                      src={URLData}
                    ></video>
                  </div>
                ) : isAllowedToEdit() ? (
                  <>
                  <div style={{display:"flex"}}>
                  <img src={plusIcon} alt="" style={{cursor: "pointer", marginLeft:"10px"}} onClick={() => setAddIntroModal(!addIntroModal)}/>
                    <p
                      style={{ fontSize: "14px",  marginLeft:"17px", cursor: "pointer",marginTop:"2px" }}
                      onClick={() => setAddIntroModal(!addIntroModal)}
                    >
                      {" "}
                       Add Intro Video{" "}
                    </p>
                  </div>
                    
                  </>
                ) : (
                  <>
                    <p
                      style={{ fontSize: "14px", cursor: "pointer" }}
                      // onClick={() => setAddIntroModal(!addIntroModal)}
                    >
                      {" "}
                      No Intro Video{" "}
                    </p>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      {closeModal && (
        <DeleteModal
          modal={DeleteModal}
          toggle={() => setCloseModal(!closeModal)}
          title="Delete Confirmation"
          deleteConfirm={deleteIntroVideo}
          text="Are you want to sure to delete this?"
        />
      )}
    </div>
  );
};
