import React from "react";
import logo from "../../assets/img/np-logo.svg";
import BannerImage from "../../assets/img/community.png";
import './CommunityWebView.scss';

export default function CommunityWebView(props) {
  const { data } = props;

  return (
    <div className="webViewCommunity">
      <div className="community">
        <div className="bg">
          <div className="container">
            <div className="row">
              <div className="col-lg-8">
                <div className="row">
                  <div className="col-8 col-md-8 col-lg-12">
                    <h1 className="mobile-res">{data.title}</h1>
                  </div>
                  <div className="col-4 d-block d-md-none d-lg-none text-center">
                    <img src={BannerImage} className="w-auto " alt='' style={{height:"70px"}} />
                  </div>
                </div>
                <p>
                  {data.subtitle}{" "}
                </p>
              </div>
            </div>
          </div>
          <div className="n-logo">
            <img src={logo} alt="" width="100%" />
          </div>
        </div>
        <div className="contextCommunityWebView">
          <section className="padding">
            <div className="container">
              <div className="row">
                <div className="col-lg-12">
                  {data?.body?.map((item, index) => {
                    return (
                      <div className="text my-5" key={index}>
                        <h6>{item.title}</h6>
                        <p>
                          {item.body}
                        </p>
                      </div>
                    )
                  })}
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    </div>
  );
}
