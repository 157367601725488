import React, { useState, useEffect } from "react";
import { Input, Modal, ModalBody, ModalHeader } from "reactstrap";
import YearPickerCustom from "../../../core/components/DatePicker/YearPickerReact";

const AddNewCard = (props) => {
  const { modal, toggle } = props;

  return (
    <div>
      <Modal isOpen={modal} size="lg" style={{ width: "600px" }} centered>
        <div className="popup">
          <div className="add-expriece">
            <ModalHeader toggle={toggle}>Add New Card</ModalHeader>
            <ModalBody className="m-0 px-16 py-0 mb-4">
              <form>
                <div className="title mt-20">
                  <label style={{ color: "#01052d" }}>Cardholder Name</label>
                  <Input
                    type="text"
                    placeholder=""
                    //     onClick={(e) => setErrorName("")}
                    //     value={expData.name}
                    //     maxLength="50"
                    //     onChange={(e) =>
                    //       setExpData({ ...expData, name: e.target.value })
                    //     }
                  />
                  {/* {errorName && <label className="label-input100 text-danger">{errorName}</label> */}
                </div>
                <div className="title">
                  <label style={{ color: "#01052d" }}>Card Number</label>
                  <Input
                    type="text"
                    placeholder=""
                    //     onClick={(e) => setErrorName("")}
                    //     value={expData.name}
                    //     maxLength="50"
                    //     onChange={(e) =>
                    //       setExpData({ ...expData, name: e.target.value })
                    //     }
                  />
                  {/* {errorName && <label className="label-input100 text-danger">{errorName}</label> */}
                </div>
                <div className="title">
                  <div className="row">
                    <div className="col-md-6 col-12">
                      <label style={{ color: "#01052d" }} htmlFor="">
                        Expiration Date
                      </label>
                      <YearPickerCustom
                        //   value={expData.year ? new Date(expData.year) : ""}
                        //   onChange={(date) => { date ? setExpData({ ...expData, year: moment(date).format("YYYY") }) : <></> }}
                        placeholder="MM/YYYY"
                        //   onClick={() => setErrorDate("")}
                        //max={new Date()}
                      />
                      {/* } */}
                      {/* {errorDate && <label className="label-input100 text-danger">{errorDate}</label>} */}
                    </div>
                    <div className="col-md-6 col-12">
                      <label style={{ color: "#01052d" }} htmlFor="">
                        CVV
                      </label>

                      <Input
                        type="text"
                        placeholder="XXXX"
                        //     onClick={(e) => setErrorName("")}
                        //     value={expData.name}
                        //     maxLength="50"
                        //     onChange={(e) =>
                        //       setExpData({ ...expData, name: e.target.value })
                        //     }
                      />
                    </div>
                  </div>
                </div>
                <div className="text-center">
                  <div className={"btn btn-blue"} style={{ marginTop: "15px" }}>
                    Save
                  </div>
                </div>
              </form>
            </ModalBody>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default AddNewCard;
