import React, { useEffect, useState, useRef } from "react";
import "../../network.scss";
import DOMPurify from "dompurify";
import { useParams, useNavigate } from "react-router-dom";
import {
  getArticleDetail,
  likeArticle,
  commentArticle,
  getCommentDetails,
} from "../../../../store/articles/articleActions";
import { useDispatch, useSelector } from "react-redux";
import ArticleDetails from "./ArticleDetails";

export default function ArticleDetailsContainer() {
  const { id } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const divRef = useRef(null);
  const dropDownRef = useRef(null);
  const token = localStorage.getItem("accessToken");

  const [openShareModal, setOpenShareModal] = useState(false);
  const [openEditArticle, setOpenEditArticle] = useState(false);
  const [editArticleData, setEditArticleData] = useState("");
  const [shareData, setShareData] = useState("");
  const [commentData, setCommentData] = useState("");
  const [currentCount, setCurrentCount] = useState(0);
  const { article_details } = useSelector((state) => state.article);
  const [isReaction, setIsReaction] = useState(-1);
  const [shareDropdown, setShareDropdown] = useState(false);



  const [openStaticsModel, setOpenStaticsModel]= useState(false);

  useEffect(() => {
    dispatch(getArticleDetail(id, token));
  }, []);

  useEffect(() => {
    dispatch(
      getCommentDetails({
        type_id: id,
        type: "article",
        limit: 5,
        skip: currentCount,
      })
    );
  }, [currentCount]);

  const { user } = useSelector((state) => state.auth);
  const { comment_list } = useSelector((state) => state.article);

  function createMarkup(html) {
    return {
      __html: DOMPurify.sanitize(html),
    };
  }

  const executeScroll = () => divRef.current.scrollIntoView();

  const likeHandler = (id, type) => {
    let data = {
      reaction_type: "article",
      reaction_type_id: id,
    };
    dispatch(likeArticle(data, type));
    setIsReaction(-1);
  };

  const commentHandler = (id) => (e) => {
    if (e.key === "Enter") {
      let data = {
        comment: commentData,
        type: "article",
        type_id: id,
      };
      dispatch(commentArticle(data));
      setCommentData("");
    }
  };

  const loadMoreComments = () => {
    setCurrentCount(currentCount + 5);
  };

  const clickOutsideDropdown = (e) => {
    if (dropDownRef?.current?.contains(e.target)) {
      return;
    }
    setShareDropdown(false);
  };

  useEffect(() => {
    document.addEventListener("mousedown", clickOutsideDropdown);
    return () => {
      document.removeEventListener("mousedown", clickOutsideDropdown);
    };
  }, [shareDropdown]);

  return (
    <ArticleDetails
      openShareModal={openShareModal}
      setOpenShareModal={setOpenShareModal}
      openEditArticle={openEditArticle}
      setOpenEditArticle={setOpenEditArticle}
      editArticleData={editArticleData}
      setEditArticleData={setEditArticleData}
      createMarkup={createMarkup}
      article_details={article_details}
      user={user}
      shareData={shareData}
      setShareData={setShareData}
      navigate={navigate}
      likeHandler={likeHandler}
      total_comment={comment_list?.items}
      divRef={divRef}
      executeScroll={executeScroll}
      commentData={commentData}
      setCommentData={setCommentData}
      commentHandler={commentHandler}
      comment_list={comment_list?.data}
      loadMoreComments={loadMoreComments}
      dropDownRef={dropDownRef}
      shareDropdown={shareDropdown}
      setShareDropdown={setShareDropdown}
      setOpenStaticsModel={setOpenStaticsModel}
      openStaticsModel={openStaticsModel}
    />
  );
}
