import React, { useRef, useState } from "react";
import OutsideNavbar from "../../navbar/OutsideNavbar";
import { useDispatch, useSelector } from "react-redux";
import { getSinglMember } from "../../../store/directory/directoryAction";
import { useEffect } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import Footer from "../../../core/components/layout/footer/Footer";
import ArticlesFeatured from "../../../core/components/layout/Sidebar/ArticlesFeatured";
import { VideoSidebar } from "../../../core/components/layout/Sidebar/VideoSidebar";
import Ad from "../../../core/components/layout/Sidebar/Ad";
import edit from "../../../assets/img/edit.svg";
import emp from "../../../assets/img/emp.svg";
import msg from "../../../assets/img/notification.svg";
import edu from "../../../assets/img/edu.svg";
import userpic from "../../../assets/img/user-a.svg";
import view from "../../../assets/img/view 1.svg";
import bar from "../../../assets/img/bar-chart 1.svg";
import ser from "../../../assets/img/search-results 1.svg";
import del from "../../../assets/img/Trash can.svg";
import networkIcon from "../../../assets/img/Union.svg";
import blockIcon from "../../../assets/img/blockIcon.svg";
import leadIcon from "../../../assets/img/leadIcon.svg";
import connectIcon from "../../../assets/img/connectIcon.png";
import user_icon from "../../../assets/img/user_profile.svg";
import placeholderUser from "../../../assets/img/placeholder_user.jpg";
import add from "../../../assets/img/add-icon.svg";
import plus from "../../../assets/img/plus-grey.svg";
import tick from "../../../assets/img/tick-icon-grey.svg";
import buld from "../../../assets/img/circle-building.svg";
import degre from "../../../assets/img/circle-degree.svg";
import share from "../../../assets/img/share-rec.svg";
import down from "../../../assets/img/download.svg";
import eye from "../../../assets/img/eye-blue.svg";
import Contactinfo from "../../../routes/profile-pages/profile/components/Contactinfo";
import AddContactInfoModal from "../../../routes/profile-pages/profile/components/AddContactInfoModal";
import { toast } from "react-toastify";
import * as html2canvas from "html2canvas";
import moment from "moment";

const MemberDetails = (props) => {
  const dispatch = useDispatch();
  const { singleMemberData } = useSelector((state) => state.directory);
  const { id } = useParams();
  const navigate = useNavigate();
  const [contactinfoModal, setContactinfoModal] = useState(false);
  const [dropDown, setDropDown] = useState(false);
  const [showAllSkills, setShowAllSkills] = useState(1);
  var sections = [
    {
      name: "Activity",
      route: "/activity",
    },
    {
      name: "Recommendations",
      route: "/recommendations",
    },
    {
      name: "Interests",
      route: "/interests",
    },
    {
      name: "Social Media Channel",
      route: "/socialmedia",
    },
  ];
  const node = useRef();
  useEffect(() => {
    dispatch(getSinglMember(id));
  }, []);
  useEffect(() => {
    document.addEventListener("mousedown", clickOutsideDropdown);
    return () => {
      document.removeEventListener("mousedown", clickOutsideDropdown);
    };
  }, [dropDown]);
  const handleLogin = () => {
    window.scrollTo(0, 0);
    toast.warning("Please Login/Signup to access further", {
      position: toast.POSITION.BOTTOM_LEFT,
    });
    navigate("/");
  };
  const clickOutsideDropdown = (e) => {
    if (node?.current?.contains(e.target)) {
      // console.log("clicked inside");
      return;
    }
    // console.log("clicked outside scope");
    setDropDown(false);
  };
  const handlePdf = () => {
    // const input = pdfRef.current;
    // html2canvas(input).then((canvas) => {
    //   const imgData = canvas.toDataURL("image/png");
    //   const pdf = new jsPDF("p", "px", "a4");
    //   var width = pdf.internal.pageSize.getWidth();
    //   var height = pdf.internal.pageSize.getHeight();
    //   pdf.addImage(imgData, "JPEG", 0, 0, width, height);
    //   pdf.save("test.pdf");
    // });
  };
  const dateUtility = (a, b) => {
    if (b === null) {
      let startDate = moment(a);
      let endDate = moment();

      let yearDiff = endDate.diff(startDate, "years");
      startDate.add(yearDiff, "years");

      let monthDiff = endDate.diff(startDate, "months");
      startDate.add(monthDiff, "months");

      return (
        <span>
          {yearDiff > 0 && yearDiff + (yearDiff > 1 ? " years" : " year")}{" "}
          {monthDiff > 0 && monthDiff + (monthDiff > 1 ? " months" : " month")}{" "}
        </span>
      );
    } else {
      let startDate = moment(a);
      let endDate = moment(b);

      let yearDiff = endDate.diff(startDate, "years");
      startDate.add(yearDiff, "years");

      let monthDiff = endDate.diff(startDate, "months");
      startDate.add(monthDiff, "months");

      return (
        <span>
          {yearDiff > 0 && yearDiff + (yearDiff > 1 ? " years" : " year")}{" "}
          {monthDiff > 0 && monthDiff + (monthDiff > 1 ? " months" : " month")}
        </span>
      );
    }
  };

  const map = {};
  singleMemberData?.experiences?.forEach((obj) => {
    const { company_name, value } = obj;
    if (map[company_name]) {
      map[company_name].push(obj);
    } else {
      map[company_name] = [obj];
    }
  });

  let newExperienceData = Object.entries(map).map(([key, value]) => ({
    [key]: value,
  }));

  newExperienceData = newExperienceData.map((exp) => {
    exp.is_visible = false;
    Object.values(exp)[0].map((exp2) => {
      if (exp2.is_visible) exp.is_visible = true;
    });
    return exp;
  });
  return (
    <div>
      <OutsideNavbar />
      <div className="profile">
        <div className="back">
          <div className="container">
            <div className="row">
              <div className="col-lg-12 mt-26">
                <div className="edit-box ">
                  <div className="profile-bg">
                    {singleMemberData?.background_img ? (
                      <img
                        src={singleMemberData?.background_img}
                        className="img-fluid-banner"
                        alt=""
                      />
                    ) : (
                      <></>
                    )}

                    <div className="nobel">
                      <span>
                        {" "}
                        {singleMemberData?.membership?.name
                          ? singleMemberData?.membership?.name?.toUpperCase()
                          : "Starter".toUpperCase()}
                      </span>
                    </div>
                  </div>
                  <div className="row px-3 pb-2">
                    <div className="col-xl-8 col-lg-12">
                      <div className="left">
                        <div className="details">
                          <div className="profile-image">
                            <div className="pro">
                              <img
                                src={
                                  singleMemberData?.profile_img
                                    ? singleMemberData?.profile_img
                                    : placeholderUser
                                }
                                className="img-fluid"
                                alt=""
                              />
                            </div>
                          </div>
                          <div className="mt w-100">
                            <div className="top">
                              <div className="name">
                                <h2>
                                  {singleMemberData?.first_name}{" "}
                                  {singleMemberData?.last_name}
                                </h2>
                              </div>
                              {singleMemberData?.is_email_verified === 100 && (
                                <div className="varify">
                                  <div className="checkMark"></div>
                                </div>
                              )}
                              {/* <div className="hiring">
                                      <span>Hiring</span>
                                    </div> */}
                              {/* <div className="details-img">
                                      <img
                                        src={emp}
                                        alt=""
                                        data-placement="top"
                                        title=" Employment Verified"
                                        role="tooltip"
                                      />
                                      <img
                                        src={edu}
                                        className="mx-1 mx-sm-2"
                                        alt=""
                                        data-placement="top"
                                        title=" Education Verified"
                                        role="tooltip"
                                      />
                                      <img
                                        src={userpic}
                                        alt=""
                                        data-placement="top"
                                        title={
                                          "Global Criminality Verified on " +
                                          DATE
                                        }
                                        role="tooltip"
                                      />
                                    </div> */}
                            </div>
                            <div className="work my-2">
                              <span>{singleMemberData?.profile_headline}</span>
                            </div>
                            <div className="where">
                              {singleMemberData?.location && (
                                <>
                                  <p className="location"></p>
                                  <span className="location-span">
                                    {/* {singleMemberData[0]?.state?.name} |{" "}
                                    {singleMemberData[0]?.country?.name} */}
                                    {singleMemberData?.location?.replaceAll(
                                      ",",
                                      " | "
                                    )}
                                  </span>
                                </>
                              )}
                              <div className="link">
                                {" "}
                                <div>Contact Info </div>
                                <img
                                  role="button"
                                  src={eye}
                                  alt=""
                                  onClick={() => {
                                    setContactinfoModal(!contactinfoModal);
                                  }}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="details mt-3">
                          {singleMemberData?.highestExperience && (
                            <div className="company">
                              <div className="circle">
                                <img src={buld} className="" alt="" />
                              </div>
                              <h6 className="">
                                {
                                  singleMemberData?.highestExperience
                                    .company_name
                                }
                              </h6>
                            </div>
                          )}

                          {singleMemberData?.highestEducation && (
                            <div className="univer">
                              <div className="circle">
                                <img src={degre} className="" alt="" />
                              </div>
                              <h6 className="">
                                {
                                  singleMemberData?.highestEducation
                                    ?.school_name
                                }
                              </h6>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="col-xl-4 col-lg-12">
                      <div className="right">
                        <div
                          className={
                            // isAllowedToEdit()
                            //   ? "follow"
                            //   :
                            "follow justify-content-end"
                          }
                          onClick={() => {
                            // navigate("/connections", {
                            //   state: { user_id: user_id },
                            // });
                            window.scrollTo(0, 0);
                            toast.success(
                              "Please Login/Signup to access further",
                              {
                                position: toast.POSITION.BOTTOM_LEFT,
                              }
                            );
                            navigate(
                              "/"
                              // {
                              //   state: { navigateto: "profile" },
                              // }
                            );
                          }}
                        >
                          <div className="connections curserPointer">
                            <span
                              className={
                                // isAllowedToEdit() ? "" :
                                "member-view"
                              }
                            >
                              {singleMemberData?.connections}
                            </span>
                            <span className="member-color">
                              {singleMemberData?.connections > 1
                                ? "Connections"
                                : "Connection"}
                            </span>
                          </div>
                          <div className="follower curserPointer">
                            <span className={"member-view"}>
                              {singleMemberData?.followers}
                            </span>
                            <span className="member-color">
                              {singleMemberData?.followers > 1
                                ? "Followers"
                                : "Follower"}
                            </span>
                          </div>

                          <div className="following curserPointer">
                            <span className={"member-view"}>
                              {singleMemberData?.followings}
                            </span>
                            <span className={"member-color"}>
                              {/* {singleMemberData?.followings > 1
                                ? "Followings"
                                : "Following"} */}
                              Following
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="bottom" data-html2canvas-ignore="true">
                      <div className="center">
                        <div className="activity">
                          <ul>
                            {sections.map((section, idx) => {
                              return (
                                <li
                                  key={idx}
                                  onClick={() => {
                                    window.scrollTo(0, 0);
                                    toast.success(
                                      "Please Login/Signup to access further",
                                      {
                                        position: toast.POSITION.BOTTOM_LEFT,
                                      }
                                    );
                                    navigate("/");
                                  }}
                                >
                                  {section.name}
                                </li>
                              );
                            })}
                          </ul>
                        </div>
                        {/* {isAllowedToEdit() && (
                                <div className="add">
                                  <span
                                    onClick={() => {
                                      setSectionModal(!sectionModal);
                                    }}
                                  >
                                    + Add a Section to Profile
                                  </span>
                                </div>
                              )} */}
                      </div>
                      <div className="member">
                        {console.log(
                          "singleMemberData?.creator_mode",
                          singleMemberData?.follow_status
                        )}

                        {singleMemberData?.creator_mode == true ? (
                          <div
                            className="btn btn-light"
                            onClick={() => {
                              // create_follower();
                              handleLogin();
                            }}
                          >
                            <img src={add} alt="" className="connect-img" />
                            Follow
                          </div>
                        ) : (
                          <div
                            className="btn btn-light"
                            onClick={() => {
                              // sendConnectionHandle();
                              handleLogin();
                            }}
                          >
                            <img
                              src={connectIcon}
                              alt=""
                              className="connect-img"
                            />
                            Connect
                          </div>
                        )}

                        <div
                          className="btn border-btn"
                          onClick={() => handleLogin()}
                        >
                          <span>NPMail</span>
                        </div>
                        <div ref={node}>
                          <div
                            ref={node}
                            className="dots"
                            onClick={() => setDropDown(!dropDown)}
                          >
                            <span>&#x2022;</span>
                            <span>&#x2022;</span>
                            <span>&#x2022;</span>
                          </div>
                          <div className="drop">
                            {dropDown && (
                              <div className="box">
                                <ul>
                                  <li>
                                    {singleMemberData[0]?.creator_mode ===
                                    false ? (
                                      singleMemberData[0]?.follow_status ===
                                      false ? (
                                        <div
                                          onClick={() => {
                                            // create_follower();
                                            handleLogin();
                                          }}
                                          className="connect-status"
                                        >
                                          <img
                                            src={plus}
                                            alt=""
                                            className="connect-img"
                                            style={{
                                              paddingRight: "9px",
                                              width: "23px",
                                            }}
                                          />
                                          Follow
                                        </div>
                                      ) : singleMemberData[0]?.follow_status ===
                                        true ? (
                                        <div
                                          onClick={() => {
                                            handleLogin();
                                          }}
                                          className="connect-status"
                                        >
                                          <img
                                            src={tick}
                                            alt=""
                                            className="connect-img"
                                            style={{
                                              paddingRight: "9px",
                                              width: "21px",
                                            }}
                                          />
                                          Unfollow
                                        </div>
                                      ) : (
                                        <></>
                                      )
                                    ) : singleMemberData[0]?.creator_mode ===
                                      true ? (
                                      singleMemberData[0]?.connection_status ===
                                      "pending" ? (
                                        <div className="connect-status">
                                          <img src={networkIcon} alt="" />{" "}
                                          Pending
                                        </div>
                                      ) : singleMemberData[0]
                                          ?.connection_status === "confirm" ? (
                                        <>
                                          <div
                                            onClick={() => handleLogin()}
                                            className="connect-status"
                                          >
                                            <img
                                              src={msg}
                                              alt=""
                                              className="connect-img"
                                              style={{
                                                paddingRight: "9px",
                                                width: "25px",
                                              }}
                                            />
                                            Message
                                          </div>
                                        </>
                                      ) : (
                                        <>
                                          <div
                                            onClick={() => {
                                              handleLogin();
                                            }}
                                          >
                                            <img src={networkIcon} alt="" />{" "}
                                            Connect
                                          </div>
                                        </>
                                      )
                                    ) : (
                                      <></>
                                    )}
                                  </li>
                                  <li>
                                    <img src={share} alt="" /> Share Profile
                                  </li>
                                  <li>
                                    <img src={down} alt="" />{" "}
                                    <span
                                      onClick={handlePdf}
                                      style={{ cursor: "pointer" }}
                                    >
                                      Save to PDF
                                    </span>
                                  </li>
                                  {/* <li>
                                            <img src={blockIcon} alt="" />{" "}
                                            Report / Block
                                          </li>
                                          <li onClick={() => addLead()}>
                                            <img src={leadIcon} alt="" /> Add as
                                            Lead
                                          </li> */}
                                </ul>
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="mt-26">
                  <div className="row">
                    <div className="col-lg-9">
                      <div className="edit-box">
                        <div className="profile-about p-30">
                          <div
                            className={
                              "about"
                              // (singleMemberData?.about?.length > 1
                              //   ? ""
                              //   : " about-extra-space")
                            }
                          >
                            <h3>About</h3>
                            <p>{singleMemberData?.about}</p>
                          </div>
                          <div className="line"></div>
                          {singleMemberData?.resume_url && (
                            <>
                              <div className="resume">
                                <p>
                                  View Resume: :{" "}
                                  <a
                                    href={singleMemberData?.resume_url}
                                    target="_blank"
                                  >
                                    {singleMemberData?.resume_name}
                                  </a>
                                </p>
                              </div>
                              <div className="line"></div>
                            </>
                          )}
                          <div className="line"></div>
                          <div className="experience">
                            <div className="flexs my-sm-4 my-2 ">
                              <div className="heading">
                                <h6>Experience</h6>
                              </div>
                            </div>
                          </div>
                          <div className="eperience-details">
                            {newExperienceData.map((item, index) => (
                              <div key={index}>
                                <div className="flex">
                                  <div className="exper-pic">
                                    <img src={buld} alt="" />
                                  </div>
                                  <div className="details w-80">
                                    <h6>
                                      {Object.values(item)[1]
                                        ? Object.keys(item)[0]
                                        : "Confidential"}
                                    </h6>
                                    <p>
                                      {dateUtility(
                                        Object.values(item)[0][0].start_date,
                                        Object.values(item)[0][0].end_date
                                      )}
                                    </p>
                                  </div>
                                </div>
                                <div className="designation">
                                  <div className="hr-line"></div>
                                  {Object.values(item)[0].map(
                                    (item3, index, { length }) => (
                                      <div className="right-align" key={index}>
                                        <div className="details w-80">
                                          <span>
                                            <strong>
                                              {item3.is_visible === false
                                                ? "Confidential"
                                                : item3.title}
                                            </strong>{" "}
                                            | {item3.employment_type} |{" "}
                                            {item3?.location}
                                            <span className="brief">
                                              <img
                                                src={emp}
                                                style={{
                                                  width: "15px",
                                                }}
                                                alt=""
                                                title=" Employment Verified"
                                                role="tooltip"
                                              />
                                            </span>
                                          </span>
                                          {index + 1 === length ? (
                                            <div className=""> </div>
                                          ) : (
                                            <div className="hr"></div>
                                          )}
                                          {item3?.end_date ? (
                                            <>
                                              <span
                                                style={{
                                                  display: "flex",
                                                }}
                                              >
                                                <span>
                                                  {moment(item3.start_date)
                                                    .utc()
                                                    .format("MMM YYYY")}
                                                  -
                                                  {moment(item3.end_date)
                                                    .utc()
                                                    .format("MMM YYYY")}{" "}
                                                  &nbsp;
                                                </span>
                                                <span>
                                                  {dateUtility(
                                                    item3.start_date,
                                                    item3.end_date
                                                  )}
                                                </span>
                                              </span>{" "}
                                            </>
                                          ) : (
                                            <>
                                              <span
                                                style={{
                                                  display: "flex",
                                                }}
                                              >
                                                <span>
                                                  {moment(item3.start_date)
                                                    .utc()
                                                    .format("MMM YYYY")}
                                                  -Present &nbsp;
                                                </span>
                                                <span>
                                                  {dateUtility(
                                                    item3.start_date,
                                                    item3.end_date
                                                  )}
                                                </span>
                                              </span>{" "}
                                            </>
                                          )}
                                        </div>
                                        <div className="details">
                                          <p>
                                            {
                                              // item3.is_visible ===
                                              // false
                                              //   ?
                                              //   "Confidential"
                                              //   :
                                              item3.description
                                            }
                                          </p>
                                        </div>
                                      </div>
                                    )
                                  )}
                                </div>
                              </div>
                            ))}
                            <div className="line"></div>
                            <div className="education">
                              <div className="my-sm-4 my-2">
                                <div className="heading">
                                  <h6>Education</h6>
                                </div>
                              </div>
                              {singleMemberData?.educations?.map(
                                (item, index) => (
                                  <div className="mr" key={index}>
                                    <div className="edu">
                                      <div className="degree-pic">
                                        <img
                                          src={degre}
                                          className="img-fluid image-fit"
                                          alt=""
                                        />
                                      </div>
                                      <div className="details w-80">
                                        <div className="">
                                          <h6>
                                            {item.school_name}{" "}
                                            <img
                                              src={edu}
                                              style={{ width: "15px" }}
                                              alt=""
                                              title=" Education Verified"
                                              role="tooltip"
                                            />
                                          </h6>
                                          <span>
                                            {item.degree}
                                            <br />
                                            {moment(item.start_date)
                                              .utc()
                                              .format("YYYY")}
                                            -
                                            {moment(item.end_date)
                                              .utc()
                                              .format("YYYY")}
                                          </span>
                                        </div>
                                      </div>
                                    </div>
                                    <p>{item.description}</p>
                                  </div>
                                )
                              )}
                            </div>
                            <div className="line"></div>
                            <div className="honors my-sm-4 my-2">
                              <div className="heading">
                                <h6>Honors & Awards</h6>
                              </div>
                              {singleMemberData?.honors_and_awards?.map(
                                (item, index) => (
                                  <>
                                    <div className="detai mt-30" key={item.id}>
                                      <div className="colum mb-20 mt-20">
                                        <div className="w-80">
                                          <h6 className="dark-blue-color">
                                            {item?.name}
                                          </h6>
                                          {item?.Industry?.length > 0 && (
                                            <span>
                                              {item?.Industry[0]?.name}
                                            </span>
                                          )}
                                          <span>{item?.year}</span>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="line"></div>
                                  </>
                                )
                              )}
                            </div>
                            <div className="line"></div>
                            <div className="skills my-sm-4 my-2">
                              <div className="heading">
                                <h6>Skills</h6>
                              </div>
                              {singleMemberData?.exps_and_skills?.map(
                                (item, index) => (
                                  <>
                                    {index < showAllSkills ? (
                                      <>
                                        <div className="develop" key={item.id}>
                                          <div className="w-80">
                                            <span>{item.skills}</span>
                                          </div>
                                        </div>
                                        <div className="line"></div>
                                      </>
                                    ) : (
                                      ""
                                    )}
                                  </>
                                )
                              )}
                              {singleMemberData?.exps_and_skills?.length >
                                1 && (
                                <div className="text-center">
                                  {showAllSkills === 10 ? (
                                    <span
                                      className="show"
                                      onClick={() => {
                                        setShowAllSkills(1);
                                      }}
                                    >
                                      Show less skills
                                    </span>
                                  ) : (
                                    <span
                                      className="show"
                                      onClick={() => {
                                        setShowAllSkills(10);
                                      }}
                                    >
                                      Show all skills
                                    </span>
                                  )}
                                </div>
                              )}
                            </div>
                            <div className="line"></div>
                            <div className="license my-sm-4 my-2">
                              <div className="heading">
                                <h6>Licenses & Certificates</h6>
                              </div>
                              {singleMemberData?.license_certifications?.map(
                                (item, index) => (
                                  <>
                                    <div
                                      className="details mb-20 mt-20"
                                      key={item.id}
                                    >
                                      <div className="w-80">
                                        <h6>{item.name}</h6>
                                        <span>{item.issuing_org}</span>
                                        <span>{item.issuing_org_id}</span>
                                        <span>
                                          Issued{" "}
                                          {moment(item.start_date)
                                            .utc()
                                            .format("MMMM YYYY")}{" "}
                                        </span>
                                      </div>
                                    </div>
                                    <div className="line"></div>
                                  </>
                                )
                              )}
                            </div>
                            <div className="line"></div>
                            <div className="language my-sm-4 my-2">
                              <div className="heading">
                                <h6>Languages</h6>
                              </div>
                              {singleMemberData?.languages?.map(
                                (item, index) => (
                                  <>
                                    <div className="details" key={item.id}>
                                      <div className="w-80">
                                        <h6 className="dark-blue-color">
                                          {item.name}
                                        </h6>
                                        <span>
                                          {item.proficiency} working proficiency
                                        </span>
                                      </div>
                                    </div>
                                    <div className="line"></div>
                                  </>
                                )
                              )}
                            </div>
                            <div className="line"></div>
                            <div className="course my-sm-4 my-2">
                              <div className="heading">
                                <h6>Courses</h6>
                              </div>
                              {singleMemberData?.courses?.map((item, index) => (
                                <>
                                  <div className="details" key={item.id}>
                                    <div className="w-80">
                                      <h6 className="dark-blue-color">
                                        {item.name}
                                      </h6>
                                      <span>
                                        {item?.association?.company_name}
                                      </span>
                                    </div>
                                  </div>
                                  <div className="line"></div>
                                </>
                              ))}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-3">
                      <div className="mb-20">
                        <Ad />
                      </div>

                      {/* <div>
                        <ArticlesFeatured articles_list={my_articles} />
                      </div> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* <Footers /> */}
          <Footer />
        </div>
      </div>

      {contactinfoModal && (
        <Contactinfo
          modal={contactinfoModal}
          toggle={() => setContactinfoModal(!contactinfoModal)}
          title="Contact Info"
          firstName={singleMemberData?.first_name}
          lastName={singleMemberData?.last_name}
          address={singleMemberData?.address}
          dob={singleMemberData?.dob}
          phone={singleMemberData?.phone}
          email={singleMemberData?.email}
          isAllowedToEdit={false}
        />
      )}
    </div>
  );
};

export default MemberDetails;
