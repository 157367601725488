import { call } from "./apiCall";

class MyNetworkAPI {
    
    getCounts() {
      let token = localStorage.getItem("accessToken");
      return new Promise((resolve, reject) => {
        (async () => {
          try {
            const res = await call(
              "get",
             `api/v1/feed/feed-count?type=myNetwork`,
              null,
              null,
              token
            );
            if (res.data.success === true) {
              resolve(res.data);
            }
            if (res.data.success === false) {
              resolve(res.data);
            }
          } catch (err) {
            console.log(err);
            reject(err);
          }
        })();
      });
    }
}

export const myNetworkAPI = new MyNetworkAPI();