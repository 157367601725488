// create a react component name as jobDetail

import React, { Component, useEffect, useState } from "react";
import "./JobDetail.scss";
import cover from "../../../assets/img/background.svg";
import companylogo from "../../../assets/img/company-name.svg";
import building from "../../../assets/img/building-white.svg";
import location from "../../../assets/img/location-white.svg";
import placeholderUser from "../../../assets/img/default_profile_sqr img.svg";
import pagePlaceholder from "../../../assets/img/page-placeholder.svg";
import user_logout from "../../../assets/img/Logout.svg";
import { useLocation, useNavigate } from "react-router-dom";
import FooterJobUpdates from "../portalFooter/FooterJobUpdates";
import PortalFooter from "../portalFooter/PortalFooter";
import PortalApplyAlert from "./PortalApplyAlert";
import { useDispatch, useSelector } from "react-redux";
import {
  applyForJob,
  getJobsDetail,
  jobViewlog,
} from "../../../store/jobs/jobAction";
import { logoutPortal } from "../../../store/portal/portalAction";
import { ToastContainer, toast } from "react-toastify";

const PortalJobDetail = (props) => {
  // const { themeColor } = props;
  const { state } = useLocation();
  const { themeColor, jobData, param } = state;
  const [alertModal, setAlertModal] = useState(false);
  const { getAJob } = useSelector((state) => state.jobs);
  const { isLoggedIn } = useSelector((state) => state.portal);
  const { profileData } = useSelector((state) => state.profile);
  const [profileDropdown, setProfileDropdown] = useState(false);
  const [applyText, setApplyText] = useState("");
  const dispatch = useDispatch();
  const navigate = useNavigate();
  useEffect(() => {
    dispatch(getJobsDetail(jobData?.id)).then((res) => {
      try {
        dispatch(jobViewlog(jobData?.id));
      } catch (error) {
        console.log(error);
      }
      // setSelectedJob(res?.data);
      // window.scrollTo(0, 0);
    });
  }, [jobData]);
  const handleLogout = async () => {
    try {
      dispatch(logoutPortal())
        .then(() => {
          navigate(`/tp/portal/${param}/login`);
        })
        .catch(() => {
          navigate(`/tp/portal/${param}/login`);
        });
    } catch (e) {
      console.log(e);
    }
  };

  const handleApply = () => {
    if (applyText === "Applied") {
      toast.success("Already Applied to this job", {
        position: toast.POSITION.BOTTOM_LEFT,
      });
    } else {
      let data = {};
      if (profileData?.cover_letter_subject && profileData?.cover_letter_desc) {
        data.cover_letter_subject = profileData?.cover_letter_subject;
        data.cover_letter_desc = profileData?.cover_letter_desc;
      }
      dispatch(applyForJob(jobData?.id, data))
        .then((res) => {
          if (res.success) {
            toast.success(res.message, {
              position: toast.POSITION.BOTTOM_LEFT,
            });
            setApplyText("Applied");
            if (jobData?.apply_link) window.open(jobData?.apply_link);
          }
        })
        .catch((error) => {
          if (error?.message === "User was logged out from the session")
            navigate(`/tp/portal/${param}/login`);
        });
    }
  };
  useEffect(() => {
    getAJob?.appliedJob ? setApplyText("Applied") : setApplyText("Apply Now");
  }, [getAJob]);

  return (
    <div className="job-detail" style={{ backgroundColor: "white" }}>
      <div className="portal-cover-img">
        <img src={cover} alt="big-img" className="img-cover" />
        <div className="header-row">
          <div className="job_details_header">
            <div>
              <img
                src={
                  getAJob?.company?.icon
                    ? getAJob?.company?.icon
                    : pagePlaceholder
                }
                alt="companny-logo"
                style={{ width: "50px", height: "50px", borderRadius: "50%" }}
              />
            </div>
            <div className="authenticate">
              {!isLoggedIn ? (
                <>
                  <p
                    className="login"
                    onClick={() => navigate(`/tp/portal/${param}/login`)}
                  >
                    Login
                  </p>
                  <div
                    className="btn btn-orange"
                    onClick={() => navigate(`/tp/portal/${param}/signup`)}
                  >
                    Regiter
                  </div>
                </>
              ) : (
                <a className="nav-link">
                  <div className="prof" style={{ position: "sticky" }}>
                    <img
                      src={
                        profileData?.profile_img
                          ? profileData.profile_img
                          : placeholderUser
                      }
                      className="rounded-circle"
                      height={40}
                      width={40}
                      alt=""
                      onClick={() => setProfileDropdown(!profileDropdown)}
                    />
                    {profileDropdown ? (
                      <div className="admin" style={{ width: "170px" }}>
                        <ul>
                          <li
                            className="dropdown-span"
                            style={{ cursor: "pointer" }}
                          >
                            <img
                              src={
                                profileData?.profile_img
                                  ? profileData.profile_img
                                  : placeholderUser
                              }
                              alt=""
                              style={{ height: "17px", width: "17px" }}
                            />
                            <span
                              onClick={() =>
                                navigate(`/tp/portal/${param}/profile/edit`)
                              }
                            >
                              View Profile
                            </span>
                          </li>
                          <li
                            className="dropdown-span"
                            style={{ cursor: "pointer" }}
                          >
                            <img
                              src={user_logout}
                              alt="log_out"
                              style={{ height: "17px", width: "17px" }}
                            />
                            <span onClick={handleLogout}>Log out</span>
                          </li>
                        </ul>
                      </div>
                    ) : (
                      <></>
                    )}
                  </div>
                </a>
              )}
            </div>
          </div>
        </div>

        <div className="search">
          <h1>{jobData?.title}</h1>
          <div
            className="d-flex align-items-center mt-3 job_location_company"
            style={{ maxWidth: "600px" }}
          >
            <div className="d-flex align-items-center">
              <img src={building} alt="building" />
              <p className="ms-1 me-5 card-control-text-oneline">
                {getAJob?.company_name}
              </p>
            </div>
            <div className="d-flex align-items-center">
              <img src={location} alt="location" />
              <p className="ms-1 card-control-text-oneline">
                {getAJob?.job_location}
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="job-description">
          <div className="row">
            <div className="col-md-8 ">
              <span className="card-title ">
                <strong>
                  {getAJob?.min_salary && getAJob?.max_salary ? "₹ " : ""}{" "}
                  {getAJob?.min_salary} {getAJob?.max_salary ? "-" : ""}{" "}
                  {getAJob?.max_salary}
                </strong>{" "}
                {getAJob?.min_salary && getAJob?.max_salary ? " P.A." : ""}
              </span>
              <p className="mt-2">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                Consequat nunc ac a magna at elementum.{" "}
              </p>
            </div>
            <div className="col-md-4 apply">
              <div
                className="btn"
                style={{ background: themeColor, cursor: "pointer" }}
                onClick={() => {
                  !isLoggedIn
                    ? navigate(`/tp/portal/${param}/login`)
                    : profileData?.first_name && profileData?.last_name
                    ? handleApply()
                    : applyText != "Applied"
                    ? setAlertModal(true)
                    : toast.success("Already Applied to this job", {
                      position: toast.POSITION.BOTTOM_LEFT,
                    });
                }}
              >
                {/* Apply Now */}
                {applyText}
              </div>
            </div>
          </div>
          <div className="line mt-5 mb-5"></div>
          <div className="job-details">
            {jobData?.skills?.length > 0 && (
              <p className="skills">
                Skills: {jobData?.skills?.map((skill) => skill.name).join(", ")}
              </p>
            )}
            {jobData?.education?.length > 0 && (
              <div className="qualification">
                <h5>Qualifications</h5>
                <ul>
                  {jobData?.education?.map((qualification, index) => (
                    <li key={index}>{qualification}</li>
                  ))}
                </ul>
              </div>
            )}
            <div className="job-descrp">
              <h5>Job description</h5>
              <div dangerouslySetInnerHTML={{ __html: jobData?.description }} />
            </div>
            {/* <div className="looking">
              <h5>Who We Are Looking For</h5>
              <ul>
                <li>
                  As part of the design team head, you will be responsible to
                  elevate customer journeys and product experiences across all
                  our platforms{" "}
                </li>
                <li>
                  Drive product and design ideation, planning and prioritisation
                  of our diverse range of products and features
                </li>
                <li>
                  Push the envelope on our products’ design by keeping a watch
                  on the emerging trends in design and content
                </li>
                <li>
                  Work closely with cross-disciplinary teams including Product,
                  Engineering, Marketing and various Business teams to deliver
                  delightful customer experiences.
                </li>
              </ul>
            </div> */}
          </div>
        </div>
        <FooterJobUpdates themeColor={themeColor} />
      </div>
      <PortalFooter talentPoolData={jobData?.company} />
      <ToastContainer />
      {alertModal && (
        <PortalApplyAlert
          modal={alertModal}
          toggle={() => setAlertModal(false)}
          title="Complete profile before applying to Job"
          themeColor={themeColor}
          param={param}
        />
      )}
    </div>
  );
};

export default PortalJobDetail;
