import React, { useEffect, useState } from "react";
import Layout from "./Layout/Layout";
import "./wallet.scss";
import { Link } from "react-router-dom";
import delIcon from "../../assets/img/deleteLeadIcon.svg";
import visaCardIcon from "../../assets/img/visaCard.png";
import masterCardIcon from "../../assets/img/masterCard.jpg";
import AddNewCard from "./components/AddNewCard";
import WalletPlus from "../../assets/img/walletPlusIcon.svg";
import WalletScoutMemb from "../../assets/img/walletscoutMemberIcon.svg";
import walletBanner from "../../assets/img/wallet_banner.jpg";
import exportIcon from "../../assets/img/export-icon.svg";
import Refer from "../../core/components/layout/Sidebar/Refer";
import closeIcon from "../../assets/img/closeIcon2.svg";
import Advertisement from "../dashboard/Advertisement";
import dropdownIcon from "../../assets/img/drop-down.svg";
import ReferInfo from "./components/ReferInfo";
import placeholderUser from "../../assets/img/placeholder_user.jpg";
import info from "../../assets/img/info2.svg";
import { WEB_URL } from "../../config";
import UpgradeSidebar from "../../core/components/layout/Sidebar/Upgrade";
import { useSelector } from "react-redux";
import AddOn from "./components/AddOn";
import { ExportToExcel } from "../LeadsManagement/components/ExportData/ExportToExcel";

const CardData = [
  {
    card_number: "4484 - XXXXXXXX - 1234",
    card_type: "visa",
  },
  {
    card_number: "1257 - XXXXXXXX - 5565",
    card_type: "master",
  },
];

export default function Wallet(props) {
  const {
    cardModal,
    setCardModal,
    navigate,
    refer_data,
    referModal,
    setReferModal,
    referToggle,
    setReferToggle,
    user,
    addOnModal,
    setAddOnModal,
    addonData,
    addons,
    transactions,
    update,
    setUpdate
  } = props;

  const { profileData } = useSelector((state) => state.profile);
  const expData = useSelector((state) => state.profile.profileData);
  const [reverse, setReverse] = useState(false)
  
  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are zero-based
    const year = String(date.getFullYear()).slice(-2); // Get last two digits of the year
    return `${day}-${month}-${year}`;
  };

  let data
  if(reverse){
    data = transactions.reverse()
  }else{
    data = transactions
  }

  return (
    <Layout>
      <div className="col-lg-6">
        <div className="networks mt-4">
          <div className="main-center">
            <div className="wrapper min-height p-30">
              <div class="d-flex justify-content-between ">
                <h4 className="wallet_header">Wallet</h4>
                <img
                  src={info}
                  height="30px"
                  alt="setting_icon"
                  className="curserPointer"
                  onClick={() => setReferToggle(!referToggle)}
                />
              </div>

              <div className="row">
                <div className="col-md-6 ">
                  <p className="mt-20 text blue-color">
                    Refer 5 Friends and Receive Nobel membership for free{" "}
                  </p>
                  <p className="grey-color">
                    Get a one-time 50% discount on your desired Membership when
                    5 individuals register for Nobel or above within the offer
                    period! Offer valid once per year.
                  </p>
                  <div className="d-flex justify-content-between mt-3">
                    <p
                      className="text green-color"
                      style={{ fontSize: "14px" }}
                    >
                      Successful invites: {refer_data.success}
                    </p>
                    <p className="mt-0 text red-color">
                      Remaining: {refer_data.remaining}
                    </p>
                  </div>
                </div>
                <div className="col-md-6">
                  <img src={walletBanner} alt="wallet_banner" />
                </div>
              </div>

              {referToggle && (
                <div>
                  {" "}
                  <div className="p-2 mt-20">
                    <span>Refer Link</span> -{" "}
                    <span className="blue-color">
                      {WEB_URL}signup?referId={user?.data?.id}
                    </span>
                  </div>
                  <div
                    className="grey-bg p-2 bg-white add-scroll"
                    style={{ maxHeight: "600px" }}
                  >
                    {refer_data &&
                      refer_data?.results?.map((data, index) => {
                        return (
                          <div
                            className="d-flex mb-2 mt-4"
                            style={{ alignItems: "center" }}
                            key={index}
                          >
                            <div className="img">
                              <img
                                src={
                                  data?.profile_img
                                    ? data?.profile_img
                                    : placeholderUser
                                }
                                style={{ height: "64px", width: "64px" }}
                                alt="profile img"
                                className="curserPointer"
                                onClick={() => navigate(`/profile/${data?.id}`)}
                              />
                            </div>

                            <div className="text">
                              <h6 className="ml-20">
                                <span
                                  className="curserPointer"
                                  onClick={() =>
                                    navigate(`/profile/${data?.id}`)
                                  }
                                >
                                  {data?.first_name + " " + data?.last_name}
                                </span>
                              </h6>
                              {data?.profile?.profile_headline && (
                                <p>{data?.profile?.profile_headline}</p>
                              )}
                            </div>
                          </div>
                        );
                      })}
                  </div>
                </div>
              )}
            </div>
            

            <div>
              <div className="wrapper min-height-220 p-30">
                <div className="d-flex justify-content-between">
                  <h4 className="wallet_header">{`${user?.data?.membership?.name} Member`}</h4>
                  <img
                    style={{ marginLeft: "-270px", marginBottom: "8px" }}
                    src={WalletScoutMemb}
                    alt="WalletMember_icon"
                  />
                  <img
                    src={WalletPlus}
                    alt="WalletMembership_icon"
                    onClick={() => setAddOnModal(true)}
                    style={{ cursor: "pointer" }}
                  />
                </div>
                <div className="d-flex justify-content-between mt-3">
                  <p className="text dark-blue-color">Added packages</p>
                  <p className="text mt-0 dark-blue-color">Exp. date</p>
                </div>
                <div className="line mt-2 mb-3"></div>
                {addons.length > 0 ? addons.map((ele) => {
                  return(
                    <div className="d-flex justify-content-between mt-1">
                  <p>{ele?.feature?.name}</p>
                  <p className="mt-0">
                    {formatDate(user?.data?.membership_expiry)}
                  </p>
                </div>
                  )
                }) : <>No addons found.</>}
                
                
              </div>
            </div>

            {/* <div className="wrapper min-height-220 p-30"> 
              <div class="d-flex justify-content-between "> 
                <h4 className="wallet_header">Scout Member</h4> 
                <img  style={{
                  marginLeft: "-270px",
                  marginBottom: "8px"
                }}src={WalletScoutMemb} alt="WalletMember_icon" />
                <img src={WalletPlus} alt="WalletMembership_icon" onClick={() => navigate("/membership")} style={{cursor: "pointer"}} />
              </div>
              <div class="d-flex justify-content-between mt-3">
                <p className="text dark-blue-color">Added packages</p>  
                <p className="text mt-0 dark-blue-color">Exp. date</p> 
              </div> 
              <div className="line mt-2 mb-3"></div> 
              <div class="d-flex justify-content-between mt-1">
                <p>1. CV download editable (with different layouts)</p>  
                <p className="mt-0">{formatDate(membership_expiry)}</p> 
              </div> 
              <div className="d-flex justify-content-between mt-1"> 
                <p>2. Profile truth badge</p> 
                <p className="mt-0"></p> 
              </div>
              <div className="d-flex justify-content-between mt-1">
                <p>
                  3. NPM upgrade <b>(3/10)</b> 
                </p>
                <p className="mt-0">15-04-22</p> 
              </div>
            </div> */}

            <div className="wrapper p-30">
              <div className="row mb-3">
                <div className="col-6 mb-2">
                  <div className="d-flex gap-2">
                    <h4 className="wallet_header ">Wallet History</h4>
                    <ExportToExcel
                    apiData={transactions}
                  fileName="WalletHistory"
                  type = "wallet"
                    />
                  </div>
                  <p className="">Last 10 transactions</p>
                </div>
                <div className="col-6">
                  <div className="float-end d-flex gap-2" style={{justifyContent:"center", alignItems:"center"}}>
                    <span>
                      Sort by:
                    </span>
                    <select
                    value={reverse}
                    onChange={(e) => setReverse(e.target.value)}
                      className="form-select"
                      style={{
                        width: "90px",
                        fontSize: "12px",
                      }}
                     
                    >
                      <option value={false}>
                        Latest
                      </option>

                      <option value={true}>
                        Oldest
                      </option>
                     
                    </select>
                  </div>
                </div>
              </div>
              <div className="mt-20">
                {data.length > 0 ? 
                data.map((data, index) => {
                  let d = new Date(data.createdAt)
                  let dformat = [d.getMonth()+1,
                    d.getDate(),
                    d.getFullYear()].join('/')+' at '+
                   [d.getHours(),
                    d.getMinutes(),
                    d.getSeconds()].join(':');
                  // date = date.toString()
                  return (
                    <>
                      <div className="mt-20 mb-20">
                        <div className="row">
                          <div className="col-9">
                            <p className="text grey-color">
                              {data.description}
                            </p>
                            <p className="wallet_subdescription grey-color">
                              {dformat}
                            </p>
                          </div>
                          <div className="col-3 align_end">
                            <p
                              className={
                                data.type === "credit"
                                  ? "wallet_amount fw-700 f-green"
                                  : "wallet_amount fw-700 f-red"
                              }
                            >
                              {data.type == "credit" ? `+ $${data.amount}` : `- $${data.amount}`}
                            </p>
                            <p
                              className={
                                data.transaction_status === "SUCCESS"
                                  ? "wallet_amount f-green"
                                  : "wallet_amount f-red"
                              }
                            >
                              {data.transaction_status}
                            </p>
                          </div>
                        </div>
                        <div className="line mt-20"></div>
                      </div>
                    </>
                  );
                }) : 
                <>No transactions found.</>}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="col-lg-3">
        <div className="main-center mt-4">
          <div className="mb-20">
              <Advertisement type="wallet"  />
            </div>
            </div>
        <div className="main-center mt-4">
          <Link to="/membership">
            <UpgradeSidebar />
          </Link>
        </div>
        <div className="main-center mt-4">
          <div className="wrapper p-3">
            <h4 className="wallet_header ">My Cards</h4>
            <div className="">
              {CardData.map((data, index) => {
                return (
                  <>
                    <div className="mt-20 ">
                      <div className="row">
                        <div className="col-10">
                          <div className="card-left">
                            <img
                              src={
                                data.card_type === "visa"
                                  ? visaCardIcon
                                  : masterCardIcon
                              }
                              alt="card_pic"
                            />
                            <div className="heading">
                              <p>
                                <b>Master Card: XX58</b>
                              </p>
                              <span className="para">Exp. date: 12/22</span>
                            </div>
                          </div>
                        </div>
                        <div className="col-2 align_end">
                          <img
                            src={closeIcon}
                            alt="delIcon"
                            className="curserPointer"
                          />
                        </div>
                      </div>
                      <div className="check mt-10">
                        <input
                          type="radio"
                          id="experienceCheck"
                          name="experienceCheck"
                          className="mb-3"
                        />
                        <label
                          htmlFor="educationCheck"
                          className="mb-3 me-4 ms-2"
                        >
                          Make the card as "default"
                        </label>
                      </div>
                    </div>
                    {index < 1 ? <div className="line"></div> : <></>}
                  </>
                );
              })}
              <div className="add" onClick={() => setCardModal(!cardModal)}>
                {" "}
                + Add New Card
              </div>
            </div>
          </div>
        </div>
        <div className="main-center mt-4">
          <Refer />
        </div>
      </div>

      {cardModal && (
        <AddNewCard modal={cardModal} toggle={() => setCardModal(!cardModal)} />
      )}

      {referModal && (
        <ReferInfo
          modal={referModal}
          toggle={() => setReferModal(!referModal)}
          refer_data={refer_data}
        />
      )}

      {
        addOnModal && (
          <AddOn addOnModal= {addOnModal} setAddOnModal = {setAddOnModal} data={addonData} update={update} setUpdate={setUpdate}/>
        )
      }

      
    </Layout>
  );
}
