import React from "react";
import "../dashboard.scss";
import cale from "../../../assets/img/calendar.svg";
import photo from "../../../assets/img/photo.svg";
import video from "../../../assets/img/video.svg";
import article from "../../../assets/img/article.svg";
import news from "../../../assets/img/news.svg";
import eve from "../../../assets/img/events.svg";
import add from "../../../assets/img/add-icon.svg";
import users from "../../../assets/img/users.svg";
import Navbar from "../../../pages/navbar/navbar";
import Chat from "../../../pages/chat/chat";
import { Link } from "react-router-dom";
import CreatePostModal from "../components/createPost/CreatePostModal";
import ViewReactionModal from "../components/viewReactionModal";
import CreateArticleModal from "../../mynetwork/Articles/components/createArticle";
import CreateEventModal from "../../mynetwork/Events/components/createEvent";
import CreateNewsletterModal from "../../mynetwork/Newsletter/components/CreateNewsletter";
import Advertisement from "../Advertisement";
import InfiniteScroll from "react-infinite-scroll-component";
import DiscardEventModal from "../../mynetwork/Events/components/DiscardModal";
import Ad from "../../../core/components/layout/Sidebar/Ad";
import placeholderUser from "../../../assets/img/placeholder_user.jpg";
import Refer from "../../../core/components/layout/Sidebar/Refer";
import SidebarFooter from "../../setting/SidebarFooter";
import PostOnDashboard from "../components/postComponent/PostOnDashboard";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ReportModal from "../components/reportTypeModal";
import DeleteModal from "../../../core/components/modal/DeleteModal";
import ShareModal from "../../../core/components/sharePost/sharePost";
import RoleChangeModal from "../components/RoleChangeModal";

const HashtagPosts = (props) => {
  const {
    postData,
    suggested_articles,
    suggested_groups,
    suggested_events,
    user,
    dashboardData,
    userName,
    newsletterModal,
    setNewsletterModal,
    userId,
    articleModal,
    setArticleModal,
    eventModal,
    setEventModal,
    handleNext,
    currentCount,
    discardModalStatus,
    setDiscardModalStatus,
    isEdit,
    setIsEdit,
    saveDraft,
    dropRefShare,
    getHashtagText,
    createLink,
    viewConnection,
    followedTagList,
    suggested_people,
    shareData,
    setShareData,
    pageData,
    dropDown,
    setDropDown,
    editPost,
    delete_post,
    closeModal,
    setCloseModal,
    deleteId,
    setDeleteId,
    isSaved,
    setSaved,
    dropRef,
    privacy_type,
    save_post,
    removeSavePost,
    handleLikes,
    handleReaction,
    isComments,
    setIsComments,
    isReaction,
    isViewReaction,
    setViewReaction,
    postId,
    setPostID,
    pageOwner,
    isPagePosts,
    createPostModal,
    setCreatePostModal,
    updateData,
    setUpdateData,
    createPostClick,
    setCreatePostClick,
    closeAllModal,
    isDataAvailable,
    setDataAvailable,
    draftDetails,
    setDraftDetails,
    setReportModal,
    reportModal,
    dropDownRepost,
    setDropDownRepost,
    dropRefRepost,
    roleShareModal,
    setRoleShareModal,
    rolePageId,
    setRolePageId,
    getPageDetailData,
    sendRequest,
    hideUserPost,
    commentBoxStatus,
    setCommentBoxStatus,
    dropDownShare,
    setDropDownShare,
    repostPost,
    likeResult,
    selectedUserId,
    setSelectedUserId,
    setOpenShareModal,
    openShareModal,
    reportType,
    setReportType,
    reportCommentId,
    setReportCommentId,
    peopleFollowRequest,
    peopleUnFollowRequest,
    handlePageFollow,
    handlePageUnFollow,
    keyword,
    follow_hashtag,
  } = props;

  return (
    <div className="dashboard bg-grey">
      <Navbar isJobs={false} />
      <div className="container mt-4">
        <div className="row">
          <div className="col-lg-3">
            <div className="left border-radius-5">
              <div className="profile">
                {user?.data?.background_img ? (
                  <img
                    src={user?.data?.background_img}
                    alt="background img"
                    className="back-image-fluid"
                    style={{ position: "absolute" }}
                  />
                ) : (
                  <></>
                )}

                <div className="img">
                  <Link to={"/profile/" + user?.data?.id}>
                    <img
                      src={
                        user?.data?.profile_img
                          ? user?.data?.profile_img
                          : placeholderUser
                      }
                      alt="profile img"
                      className="img-fluid"
                    />
                  </Link>
                </div>
              </div>
              <div className="starter">
                {" "}
                {user?.data?.membership?.name || "Starter"}
              </div>
              <div className="px-3 pb-3">
                <div className="profile-details">
                  <Link to={"/profile/" + user?.data?.id}>
                    <h6>
                      {user?.data?.first_name} {user?.data?.last_name}
                    </h6>
                  </Link>

                  <p className="card-control-text-twoline">
                    {user?.data?.profile_headline
                      ? user?.data?.profile_headline
                      : ""}
                  </p>
                </div>
                <div className="margin">
                  <div className="follower" onClick={viewConnection}>
                    <div className="box curserPointer">
                      <h6>{user?.data?.connections}</h6>
                      <p>
                        {user?.data?.connections > 1
                          ? "Connections"
                          : "Connection"}
                      </p>
                    </div>
                    <div className="h-border "></div>
                    <div className="box curserPointer">
                      <h6>{user?.data?.followers}</h6>
                      <p>
                        {user?.data?.followers > 1 ? "Followers" : "Follower"}
                      </p>
                    </div>
                    <div className="h-border"></div>

                    <div className="box curserPointer">
                      <h6>{user?.data?.followings}</h6>
                      <p>
                        {/* {user?.data?.followings > 1
                          ? "Followings"
                          : "Following"} */}
                        Following
                      </p>
                    </div>
                  </div>
                  <div className="line mb-20"></div>
                </div>
                <div className="update mt-3">
                  <Link to={"/profile/" + user?.data?.id}>
                    <span>View My Profile</span>
                  </Link>
                </div>
              </div>
            </div>
            <Link to={"/network"}>
              <div className="left px-3 py-3 mt-10 d-flex justify-content-center border-radius-5">
                <div className="discover">
                  <h4>Discover more</h4>
                </div>
              </div>
            </Link>
            <div className="mt-10">
              <SidebarFooter />
            </div>
          </div>
          <div className="col-lg-6 mb-3">
            <div className="center">
              <div className="msg">
                <div className="msg-box-create">
                  <div className="hashTagBox">
                    <h2>#{keyword}</h2>
                    {postData?.follower_count > 1 ? (
                      <p> {postData?.follower_count} Followers</p>
                    ) : (
                      <p> {postData?.follower_count} Follower</p>
                    )}
                    {postData?.is_following === true ? (
                      <div
                        className="btn btn-blue mt-10"
                        onClick={() => {
                          follow_hashtag(keyword, "unfollow");
                        }}
                      >
                        Unfollow
                      </div>
                    ) : (
                      <div
                        className="btn btn-blue mt-10"
                        onClick={() => {
                          follow_hashtag(keyword, "follow");
                        }}
                      >
                        Follow
                      </div>
                    )}
                  </div>
                </div>
                {postData?.is_following && (
                  <div className="msg-box-create mt-10">
                    <div className="type">
                      <div className="profile">
                        <img
                          src={
                            user?.data?.profile_img
                              ? user?.data?.profile_img
                              : placeholderUser
                          }
                          alt="profile img"
                          className="img-fluid"
                        />
                      </div>
                      <textarea
                        id="w3review"
                        rows="2"
                        className="curserPointer"
                        onKeyDown={() => {
                          setCreatePostModal(!createPostModal);
                          setIsEdit(0);
                          setCreatePostClick("");
                        }}
                        placeholder="Share your thoughts..."
                        onClick={() => {
                          setUpdateData(`#${keyword}`);
                          setCreatePostModal(!createPostModal);
                          setIsEdit(0);
                          setCreatePostClick("");
                        }}
                      ></textarea>
                    </div>
                    <div className="social mt-2">
                      <div
                        className="box"
                        onClick={() => {
                          setCreatePostModal(!createPostModal);
                          setCreatePostClick("photo");
                        }}
                      >
                        <img src={photo} alt="" />
                        <p>Photo</p>
                      </div>
                      <div
                        className="box"
                        onClick={() => {
                          setCreatePostModal(!createPostModal);
                          setCreatePostClick("video");
                        }}
                      >
                        <img src={video} alt="" />
                        <p>Video</p>
                      </div>
                      {/* <div
                      className="box"
                      onClick={() => setArticleModal(!articleModal)}
                    >
                      <img src={article} alt="" />
                      <p>Article</p>
                    </div> */}
                      {/* <div
                      className="box"
                      onClick={() => setEventModal(!eventModal)}
                    >
                      <img src={eve} alt="" />
                      <p>Event</p>
                    </div> */}
                      {/* <div
                      className="box"
                      onClick={() => setNewsletterModal(!newsletterModal)}
                    >
                      <img src={news} alt="" />
                      <p>Newsletter</p>
                    </div> */}
                    </div>
                  </div>
                )}
                <div className="line mt-20"></div>
                <InfiniteScroll
                  dataLength={currentCount}
                  next={handleNext}
                  hasMore={true}
                  style={{ overflow: "hidden" }}
                >
                  {postData?.data?.map((item, index) => (
                    <PostOnDashboard
                      item={item}
                      index={index}
                      dropDown={dropDown}
                      userId={userId}
                      user={user}
                      editPost={editPost}
                      delete_post={delete_post}
                      dropRef={dropRef}
                      dropRefShare={dropRefShare}
                      getHashtagText={getHashtagText}
                      createLink={createLink}
                      privacy_type={privacy_type}
                      save_post={save_post}
                      removeSavePost={removeSavePost}
                      setDropDown={setDropDown}
                      handleReaction={handleReaction}
                      isViewReaction={isViewReaction}
                      setViewReaction={setViewReaction}
                      setPostID={setPostID}
                      userName={userName}
                      reportModal={reportModal}
                      setReportModal={setReportModal}
                      hideUserPost={hideUserPost}
                      reportType={reportType}
                      setReportType={setReportType}
                      reportCommentId={reportCommentId}
                      setReportCommentId={setReportCommentId}
                      commentBoxStatus={commentBoxStatus}
                      setCommentBoxStatus={setCommentBoxStatus}
                      dropDownShare={dropDownShare}
                      setDropDownShare={setDropDownShare}
                      dropDownRepost={dropDownRepost}
                      setDropDownRepost={setDropDownRepost}
                      dropRefRepost={dropRefRepost}
                      repostPost={repostPost}
                      likeResult={likeResult}
                      sendRequest={sendRequest}
                      setOpenShareModal={setOpenShareModal}
                      openShareModal={openShareModal}
                      selectedUserId={selectedUserId}
                      setSelectedUserId={setSelectedUserId}
                      peopleFollowRequest={peopleFollowRequest}
                      peopleUnFollowRequest={peopleUnFollowRequest}
                      handlePageFollow={handlePageFollow}
                      handlePageUnFollow={handlePageUnFollow}
                      roleShareModal={roleShareModal}
                      setRoleShareModal={setRoleShareModal}
                      rolePageId={rolePageId}
                      setRolePageId={setRolePageId}
                      shareData={shareData}
                      setShareData={setShareData}
                      pageData={pageData}
                    />
                  ))}
                </InfiniteScroll>
              </div>
            </div>
          </div>

          <div className="col-lg-3">
            <div className="middle">
              <Advertisement />

              <div style={{ marginTop: "26px" }}>
                <Ad />
              </div>
              <div className="member">
                <Refer />
              </div>
            </div>
          </div>
        </div>
        <ToastContainer />
      </div>
      <Chat />

      {createPostModal && (
        <CreatePostModal
          modal={createPostModal}
          toggle={() => {
            if (isDataAvailable) {
              setDiscardModalStatus(true);
            } else {
              setCreatePostModal(!createPostModal);
            }
          }}
          toggleOff={() => {
            setCreatePostModal(!createPostModal);
          }}
          title="Create Post"
          userName={userName}
          userId={userId}
          createPostClick={createPostClick}
          discardModalStatus={discardModalStatus}
          setDiscardModalStatus={setDiscardModalStatus}
          closeAllModal={closeAllModal}
          updateData={updateData}
          isEdit={isEdit}
          setIsEdit={setIsEdit}
          isDataAvailable={isDataAvailable}
          setDataAvailable={setDataAvailable}
          draftDetails={draftDetails}
          setDraftDetails={setDraftDetails}
          dashboardData={dashboardData}
          setCommentBoxStatus={setCommentBoxStatus}
          keyword={keyword}
          isHashtagPost={true}
        />
      )}
      {isViewReaction && (
        <ViewReactionModal
          modal={isViewReaction}
          toggle={() => setViewReaction(!isViewReaction)}
          title="View Reactions"
          userName={userName}
          postId={postId}
        />
      )}

      {articleModal && (
        <CreateArticleModal
          modal={articleModal}
          toggle={() => setArticleModal(!articleModal)}
          title="Create Article"
          source="home"
        />
      )}

      {eventModal && (
        <CreateEventModal
          modal={eventModal}
          toggle={() => setEventModal(!eventModal)}
          title="Create Event"
          source="home"
          // successfulStatus={successfulStatus}
        />
      )}

      {newsletterModal && (
        <CreateNewsletterModal
          modal={newsletterModal}
          toggle={() => setNewsletterModal(!newsletterModal)}
          title="Create Newsletter"
          source="home"
        />
      )}

      {discardModalStatus && (
        <DiscardEventModal
          modal={discardModalStatus}
          close={closeAllModal}
          toggle={() => setDiscardModalStatus(!discardModalStatus)}
          title="Discard Changes."
          saveDraft={saveDraft}
          isDash={true}
        />
      )}

      {discardModalStatus && (
        <DiscardEventModal
          modal={discardModalStatus}
          close={closeAllModal}
          toggle={() => setDiscardModalStatus(!discardModalStatus)}
          title="Discard Changes."
          saveDraft={saveDraft}
          isDash={true}
        />
      )}

      {reportModal && (
        <ReportModal
          modal={reportModal}
          toggle={() => setReportModal(!reportModal)}
          post_id={postId}
          reportType={reportType} //"post"/"comment"
          reportCommentId={reportCommentId} //null in case of post
        />
      )}

      {closeModal && (
        <DeleteModal
          modal={DeleteModal}
          toggle={() => setCloseModal(!closeModal)}
          title="Delete Confirmation"
          deleteConfirm={() => {
            delete_post(deleteId);
          }}
          text="Are you want to sure to delete this?"
        />
      )}
      {openShareModal && (
        <ShareModal
          modal={openShareModal}
          toggle={() => setOpenShareModal(!openShareModal)}
          title="Share"
          shareData={shareData}
          type="shared_post"
          source="dashboard"
          toggleSuccessful={() => setOpenShareModal(!openShareModal)}
        />
      )}

      {roleShareModal && (
        <RoleChangeModal
          modal={roleShareModal}
          toggle={() => setRoleShareModal(!roleShareModal)}
          title="Post, like, comment and share "
          postId={postId}
          getPageDetailData={getPageDetailData}
          rolePageId={rolePageId}
        />
      )}
    </div>
  );
};
export default HashtagPosts;
