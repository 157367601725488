import React, { useEffect, useState } from "react";
import LoginPortal from "./LoginPortal";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  getTalentPoolDetails,
  getTalentPoolDetailsByToken,
  login,
  loginWithNP,
} from "../../../store/portal/portalAction";
import { ToastContainer, toast } from "react-toastify";

const LoginPortalContainer = () => {
  const [loginWithNp, setLoginWithNp] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { param } = useParams();
  const { talentPoolData, talentPoolDataByToken } = useSelector(
    (state) => state.portal
  );
  console.log(talentPoolData, talentPoolDataByToken);
  const [formData, setFormData] = useState({
    email: " ",
    password: "",
  });
  const [error, setError] = useState({
    email: "",
    password: "",
  });

  const [errorMessage, setErrorMessage] = useState("");

  useEffect(() => {
    dispatch(getTalentPoolDetailsByToken());
    dispatch(getTalentPoolDetails(param));
  }, []);

  useEffect(() => {
    if (talentPoolData?.id === talentPoolDataByToken?.id)
      navigate(`/tp/portal/${param}/dashboard`);
  }, [talentPoolDataByToken, talentPoolData]);

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
    setErrorMessage("");
    setError({
      email: "",
      password: "",
    });
  };
  const handleValidation = (type) => {
    let formIsValid = true;
    let emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
    let passwordValid =
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!""#$%&'()*+,-./:;<=>?@[\]^_`{|}~])[A-Za-z\d!""#$%&'()*+,-./:;<=>?@[\]^_`{|}~]{8,}$/;

    if (type === "email" || type === "all") {
      if (!formData?.email.trim()) {
        setError({ ...error, email: "Please enter email" });
        formIsValid = false;
        return;
      } else if (!emailRegex.test(formData?.email.trim())) {
        setError({
          ...error,
          email: "Please enter your email in a valid format.",
        });
        formIsValid = false;
        return;
      } else {
        setError({
          ...error,
          email: "",
        });
      }
    }
    if (type === "all" || type === "password") {
      if (!formData?.password) {
        setError({
          ...error,
          password: "Please enter password",
        });
        formIsValid = false;
        return;
      } else if (!passwordValid.test(formData?.password.trim())) {
        setError({
          ...error,
          password:
            'The password should be minimum 8 characters long and contain at least one : Upper and Lower Case Alphabet, Number, Special Character. The special characters that can be used are [!""#$%&\'()*+,-./:;<=>?@[]^_`{|}~]',
        });
        formIsValid = false;
        return;
      } else {
        setError({
          ...error,
          password: "",
        });
      }
    }

    return formIsValid;
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (handleValidation("all")) {
      console.log(handleValidation("all"));
      dispatch(
        login(
          {
            email: formData?.email,
            password: formData?.password,
            id: parseInt(param),
          },
          false
        )
      )
        .then((res) => {
          console.log(res, "res");

          if (res?.success) {
            toast.success("Logged in successfully", {
              position: toast.POSITION.BOTTOM_LEFT,
            });

            navigate(`/tp/portal/${param}/dashboard`);
          } else setErrorMessage(res?.message);
        })
        .catch((err) => {
          setErrorMessage(err?.response?.data?.message);
        });
    }
  };

  const handleNPSubmit = (e) => {
    e.preventDefault();

    if (handleValidation("all")) {
      console.log(handleValidation("all"));
      dispatch(
        login(
          {
            email: formData?.email,
            password: formData?.password,
            id: parseInt(param),
          },
          true
        )
      )
        .then((res) => {
          console.log(res, "res");
          if (res?.success) {
            toast.success("Logged in successfully", {
              position: toast.POSITION.BOTTOM_LEFT,
            });
            navigate(`/tp/portal/${param}/dashboard`);
          } else setErrorMessage(res?.message);
        })
        .catch((err) => {
          setErrorMessage(err?.response?.data?.message);
        });
    }
  };

  return (
    <>
      <LoginPortal
        param={param}
        data={talentPoolData}
        error={error}
        formData={formData}
        handleChange={handleChange}
        handleSubmit={handleSubmit}
        handleNPSubmit={handleNPSubmit}
        handleValidation={handleValidation}
        navigate={navigate}
        loginWithNp={loginWithNp}
        setLoginWithNp={setLoginWithNp}
        errorMessage={errorMessage}
      />
      <ToastContainer />
    </>
  );
};

export default LoginPortalContainer;
