import {
  GET_MEMBERSHIP_TYPES,
  GET_MEMBERSHIP_BY_ID,
  GET_MEMBERSHIP_FEATURES,
} from "../types";

export const initialState = {
  membershipTypes: [],
  membershipDetails: [],
  membershipFeatures: [],
};

const membership = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case GET_MEMBERSHIP_TYPES:
      return {
        ...state,
        membershipTypes: payload,
      };

    case GET_MEMBERSHIP_BY_ID:
      return {
        ...state,
        membershipDetails: payload,
      };

    case GET_MEMBERSHIP_FEATURES:
      return {
        ...state,
        membershipFeatures: payload,
      };

    default:
      return state;
  }
};

export default membership;
