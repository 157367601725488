import moment from "moment";
import translateInstance from "../TranslateContent/Translate";
import DOMPurify from "dompurify";
import { htmlToText } from "html-to-text";

export const handleTranslate = async (
  text,
  commentID,
  setIsTranslated,
  setTraslatedText,
  selectedLanguage
) => {
  try {
    setIsTranslated(commentID);

    // Use await to wait for the translation to complete
    const result = await translateInstance.TranslatedText(
      text,
      "auto",
      selectedLanguage
    );

    // Set the translated text in the state
    setTraslatedText(result);
  } catch (error) {
    console.error("Error translating text:", error);
  }
};

export const autoDetect = async (text, setDetectedLanguage) => {
  try {
    const result = await translateInstance.DeductText(text);
    setDetectedLanguage(result);
  } catch (error) {
    console.error("Error translating text:", error);
  }
};

export const GetTime = (date) => {
  const now = moment();
  const hours = now.diff(date, "hours");
  const days = now.diff(date, "days");
  const weeks = now.diff(date, "weeks");

  if (hours >= 22 && hours <= 47) {
    return "1 day ago";
  } else if (days >= 7) {
    if (days <= 13) {
      return "1 week ago";
    } else if (days > 13 && days <= 25) {
      return `${weeks} weeks ago`;
    }
  }

  return moment(date).fromNow();
};

export function createMarkup(html) {
  return {
    __html: DOMPurify.sanitize(html),
  };
}

export function abbreviateNumber(value) {
  var suffixes = ["", "K", "M", "B", "T"];
  var suffixNum = Math.floor(("" + value).length / 3);
  var shortValue = parseFloat(
    (suffixNum != 0 ? value / Math.pow(1000, suffixNum) : value).toPrecision(2)
  );
  if (shortValue % 1 != 0) {
    shortValue = shortValue.toFixed(1);
  }
  return shortValue + suffixes[suffixNum];
}

// export function GetHashtagText(content) {
//   content
//     ?.replace(/\n#/g, " \n#")
//     .replace(/\n/g, " \n")
//     ?.split(" ")
//     ?.map((str, index) => {
//       if (str.startsWith("#") || str.startsWith("\n#")) {
//         let keyword = str.startsWith("#") ? str.substring(1) : str.substring(2);
//         return (
//           <a
//             href={`/hashtag/feed/?keyword=${keyword}`}
//             className="text-blue-500"
//             key={index}
//           >
//             {str}{" "}
//           </a>
//         );
//       }
//       return str + " ";
//     });
// }

export function GetHashtagText(content) {
  return content
    ?.replace(/\n#/g, " \n#")
    .replace(/\n/g, " \n")
    ?.split(" ")
    ?.map((str, index) => {
      if (str.startsWith("#") || str.startsWith("\n#")) {
        let keyword = str.startsWith("#") ? str.substring(1) : str.substring(2);
        return (
          <a
            href={`/hashtag/feed/?keyword=${keyword}`}
            className="text-blue-500"
            key={index}
          >
            {str}{" "}
          </a>
        );
      }
      return str + " ";
    });
}

export function GetFormattedText(content) {
  if (!content) return null;

  const hashtagRegex1 = /#([a-zA-Z\d-]+)/g;
  const hashtagRegex2 = /#\[(.*?)\]/g;
  const mentionRegex = /@\[(.*?)\]\((\d+)\)/g;

  // Replace hashtags
  content = content.replace(hashtagRegex2, (match, tag) => {
    return `<a href="/hashtag/feed/?keyword=${tag}" class="text-blue-500">#${tag}</a>`;
  });
  content = content.replace(hashtagRegex1, (match, tag) => {
    return `<a href="/hashtag/feed/?keyword=${tag}" class="text-blue-500">#${tag}</a>`;
  });

  // Replace mentions
  content = content.replace(mentionRegex, (match, name, id) => {
    return `<a href="/profile/${id}" class="text-blue-500">${name}</a>`;
  });

  return (
    <span
      dangerouslySetInnerHTML={{ __html: content.replace(/\n/g, " <br/> ") }}
    ></span>
  );
}

export function createLink(content) {
  content?.map((str, index) => {
    return (
      <a href={`/profile/${str.user_id}`} className="text-blue-500" key={index}>
        {str?.user?.first_name} {str?.user?.last_name}{" "}
      </a>
    );
  });
}

export function getReadingTime(html) {
  const text = htmlToText(html);
  const wordCount = text.split(/\s+/).length;
  console.log(wordCount);
  const wordsPerMinute = 200;
  const timeToRead = Math.round(wordCount / wordsPerMinute);
  return timeToRead;
}
