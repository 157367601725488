import { BLOG_GET_ALL_BLOGS,BLOG_GET_ALL_BY_ID, BLOG_GET_CATEGORY } from "../types";
import { blogApi } from "../../api/blogApi";

export const allBlogAction = (payload) => (dispatch) => {
 blogApi.getAll(payload).then((blogs) => {
  dispatch({
   type: BLOG_GET_ALL_BLOGS,
   payload: blogs
  });
 })
}

export const getBlogByIdAction = (id) => (dispatch) => {
    blogApi.getById(id).then((blogs) => {
     dispatch({
      type: BLOG_GET_ALL_BY_ID,
      payload: blogs
     });
    })
   }
   
   export const getBlogCategoryAction = () => (dispatch) => {
    blogApi.getBlogCategory().then((blogs) => {
     dispatch({
      type: BLOG_GET_CATEGORY,
      payload: blogs.data
     });
    })
   }
   
      