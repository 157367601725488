import { React, useRef, useState, useEffect } from "react";
import "./navbar.scss";
import bell from "../../assets/img/bell.svg";
import noti from "../../assets/img/notification.svg";
import set from "../../assets/img/setting.svg";
import logo from "../../assets/img/logo.svg";
import placeholderUser from "../../assets/img/placeholder_user.jpg";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { logout } from "../../store/auth/authActions";
import { GetAllUsers } from "../../store/getAllUsers/allUsersAction";
import Autocomplete from "react-autocomplete";
import { useLocation } from "react-router-dom";

var categories = [
  {
    name: "Home",
    route: "/dashboard",
    routeMap: ["/dashboard"],
    icon: "home-inactive",
    icon_active: "home",
  },
  {
    name: "My Network",
    route: "/network",
    routeMap: [
      "/network",
      "/posts",
      "/peoples",
      "/groups",
      "/event",
      "/pages",
      "/newsletter",
      "/articles",
      "/hashtag",
    ],
    icon: "union",
    icon_active: "union-active",
  },
  {
    name: "Jobs",
    route: "/dashboard",
    routeMap: ["/dashboard"],
    icon: "brief",
    icon_active: "brief",
  },
  {
    name: "Wallet",
    route: "/dashboard",
    routeMap: ["/dashboard"],
    icon: "wallet",
    icon_active: "brief",
  },
];

export default function OutsideNavbar() {
  const { user } = useSelector((state) => state.auth);
  const [admin, setAdmin] = useState(true);

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const node1 = useRef();

  const [memberInput, setMemberInput] = useState("");
  const [memberData, setMemberData] = useState("");
  const [memberList, setMemberList] = useState([]);
  const [sectionActive, setSectionActive] = useState("");
  const { pathname } = useLocation();

  useEffect(() => {
    if (memberInput) {
      dispatch(GetAllUsers({ search: memberInput.val }))
        .then((res) => {
          if (res?.success) {
            setMemberList(res?.data);
          } else setMemberList([]);
        })
        .catch(() => {
          setMemberList([]);
        });
    }
  }, [memberInput]);

  const adminClick = () => {
    setAdmin(!admin);
  };

  const clickOutsideAdmin = (e) => {
    if (node1?.current?.contains(e.target)) {
      return;
    }
    setAdmin(true);
  };

  useEffect(() => {
    document.addEventListener("mousedown", clickOutsideAdmin);

    return () => {
      document.removeEventListener("mousedown", clickOutsideAdmin);
    };
  }, [admin]);

  useEffect(() => {
    for (var i = 0; i < categories.length; i++) {
      if (categories[i].routeMap.includes(pathname)) {
        setSectionActive(categories[i].name);
        break;
      }
    }
  }, [pathname]);

  const handleLogout = async () => {
    try {
      dispatch(logout())
        .then(() => {
          navigate("/");
        })
        .catch(() => {
          navigate("/");
        });
    } catch (e) {
      console.log(e);
    }
  };
  const goToSttings = () => {
    navigate("/setting");
  };

  return (
    <div>
      <header className="dashboard for-login-signup">
        <nav className="navbar navbar-expand-xl bg-white navbar-white p-0">
          <div className="container outside-navbar">
            <a className="navbar-brand" href="/">
              <img src={logo} alt="noblepage_logo" />
            </a>
            {/* <button
              className="navbar-toggler"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#collapsibleNavbar"
            >
              <span className="navbar-toggler-icon"></span>
            </button> */}
            <div className="collapse navbar-collapse" id="collapsibleNavbar">
              <ul className="navbar-nav ms-auto">
                <ul style={{ display: "flex", gap: "5px" }}>
                  <li className="nav-item me-2">
                    <div
                      className="login-button"
                      onClick={() => navigate("/signup")}
                    >
                      <span>Signup</span>
                    </div>
                  </li>
                  <li className="nav-item">
                    <div className="login-button" onClick={() => navigate("/")}>
                      <span>Login</span>
                    </div>
                  </li>
                </ul>
              </ul>
            </div>
          </div>
        </nav>
      </header>
    </div>
  );
}
