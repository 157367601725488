import React, { useEffect } from "react";
import {
  GetTime,
  createMarkup,
} from "../../../core/components/Utility/Utility";
import { useNavigate, useParams } from "react-router";
import { ToastContainer, toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { getJobsDetail } from "../../../store/jobs/jobAction";
import add from "../../../assets/img/add-icon.svg";
import placeholderUser from "../../../assets/img/placeholder_user.jpg";
import pagePlaceholder from "../../../assets/img/page-placeholder.svg";
import expIcon from "../../../assets/img/exp-job.svg";
import walletIcon from "../../../assets/img/wallet-job.svg";
import locationIcon from "../../../assets/img/location-job.svg";
import noblePage from "../../../assets/img/noble-job.jpg";
import connectIcon from "../../../assets/img/connectIcon.png";
import { getJobPublicDetail } from "../../../store/directory/directoryAction";
import confidential from "../../../assets/img/confidentialIcon.svg";

const JobDetail = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { singlePublicJobData } = useSelector((state) => state.directory);

  const handleClick = () => {
    // window.scrollTo(0, 0);
    // toast.warning("Please Login/Signup to access further", {
    //   position: toast.POSITION.BOTTOM_LEFT,
    // });
    navigate("/message");
  };

  useEffect(() => {
    dispatch(getJobPublicDetail({ id }));
  }, [id]);

  return (
    <div className="container ">
      <div className="row">
        {/* <div className="left-sidebar bg-white col-lg-5">
            <div className=" leftTop-sidebar mb-20">
              <div
                className="d-flex justify-content-between top-buttons"
                style={{
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                }}
              >
                <div className="d-flex justify-content-betweens">
                  <img src={editIcon} alt="my_jobs" />{" "}
                  <span
                    className="sub-text ml-10"
                    // onClick={() => navigate("/post-job")}
                    onClick={() => openRecruiterModal()}
                  >
                    Post a Job
                  </span>
                </div>
                <div
                  className="d-flex justify-content-betweens"
                  onClick={() => navigate("/myjobs")}
                >
                  <img src={caseIcon} alt="my_jobs" />{" "}
                  <span className="sub-text ml-10">My Jobs</span>
                </div>
                <div
                  className="d-flex justify-content-betweens"
                  onClick={() => getFilteredJobs()}
                >
                  <img src={AdIcon} alt="featured_jobs" />
                  <span
                    className="sub-text ml-10"
                    style={{ color: jobType == "featured" ? "black" : "unset" }}
                  >
                    Featured Jobs
                  </span>
                </div>
                <div className="d-flex justify-content-betweens">
                  <img
                    src={threeDots}
                    alt="my_jobs"
                    onClick={() => alert("Required Figma")}
                    style={{ height: "15px", marginTop: "3px" }}
                  />{" "}
                </div>
              </div>
              {searchKey && (
                <div className="job-alert">
                  <div>
                    <p className="search-value">
                      {searchKey}
                      {searchFieldValue && ` in ${searchFieldValue}`}
                    </p>

                    <p className="search-num">{totalCount} search result</p>
                  </div>
                  <div className="d-flex gap-3">
                    <p>Alert on</p>
                    <FormGroup switch>
                      <Input
                      style={{backgroundColor:"#008C39"}}
                        type="switch"
                        role="switch"
                        checked={isJobAlert ? true : false}
                        onChange={(e) => handleSwitchChange(e)}
                      />
                    </FormGroup>
                  </div>
                </div>
              )}
              {!searchKey && !jobType && (
                <>
                  <RecentJobSearches />
                </>
              )}
            </div>
            {!searchKey && !jobType && (
              <span className="job-recommend">Recommended for you</span>
            )}
            <InfiniteScroll
              dataLength={
                jobType == "featured"
                  ? getFeaturedJobs?.length
                  : getAllJobs?.length
              }
              next={() => loadMore()}
              hasMore={hasMore}
              style={{ overflow: "hidden" }}
            >
              {(
                jobType === "featured"
                  ? getFeaturedJobs?.length
                  : getAllJobs?.length
              ) ? (
                <div>
                  {jobType !== "featured"
                    ? getAllJobs?.map((jobData, idx) => (
                        <JobCard
                          key={idx}
                          onClick={() => handleJobCardClick(jobData)}
                          jobData={jobData}
                          selectedJob={selectedJob}
                        />
                      ))
                    : getFeaturedJobs?.map((jobData, idx) => (
                        <JobCard
                          key={idx}
                          onClick={() => handleJobCardClick(jobData)}
                          jobData={jobData}
                          selectedJob={selectedJob}
                        />
                      ))}
                </div>
              ) : (
                <div className="row">
                  <p style={{ textAlign: "center" }} className="my-30">
                    <b>No jobs</b>
                  </p>
                </div>
              )}
            </InfiniteScroll>
          </div> */}
        <div className="col-lg-2"></div>
        <div className="col-lg-8">
          <div className="bg-white p-4 main-content">
            <div className="d-flex justify-content-between">
              <div>
                <h3 className="main-header mb-2">
                  {singlePublicJobData?.title}
                </h3>
                <span className="sub-header ">
                  {singlePublicJobData?.company_visible
                    ? singlePublicJobData?.company_name
                    : "Confidential"}
                  &#x2022; {singlePublicJobData?.employment_type} &#x2022;{" "}
                  {GetTime(singlePublicJobData?.createdAt)}{" "}
                </span>
              </div>
              <img
                src={
                  singlePublicJobData?.company_visible
                    ? singlePublicJobData?.company?.icon
                      ? singlePublicJobData?.company?.icon
                      : pagePlaceholder
                    : confidential
                }
                alt="company_logo"
                style={{
                  height: "50px",
                  width: "50px",
                  borderRadius: "50%",
                }}
              />
            </div>
            <div className="row mt-20">
              <div className="col-sm-6">
                <div className="d-flex">
                  <img src={expIcon} alt="exp_icon" />
                  <span className="ml-10">
                    {singlePublicJobData?.min_experience}{" "}
                    {singlePublicJobData?.max_experience ? "-" : ""}{" "}
                    {singlePublicJobData?.max_experience} years
                  </span>
                </div>
                <div className="d-flex mt-10">
                  <img src={walletIcon} alt="salary_icon" />
                  <span className="ml-10">
                    ₹ {singlePublicJobData?.min_salary}{" "}
                    {singlePublicJobData?.max_salary ? "-" : ""}{" "}
                    {singlePublicJobData?.max_salary} P.A.
                  </span>
                </div>
                <div className="d-flex mt-10">
                  <img src={locationIcon} alt="location_icon" />
                  <span className="ml-10">
                    {singlePublicJobData?.job_location}
                  </span>
                </div>
              </div>
              <div className="col-sm-6">
                <div className="btn-job">
                  <div
                    className="btn btn-light blue-color"
                    onClick={() => {
                      // handleSave(getAJob?.id);
                      handleClick();
                    }}
                  >
                    {singlePublicJobData?.savedJob?.status ? "Unsave" : "Save"}
                  </div>

                  {singlePublicJobData?.apply_smart ? (
                    <div
                      className="btn btn-blue ml-20"
                      onClick={() => {
                        handleClick();
                      }}
                    >
                      <img
                        src={noblePage}
                        alt="noblepage_logo"
                        className="mr-10"
                      />
                      <span>Smart Apply</span>
                    </div>
                  ) : (
                    <div
                      className="btn btn-blue ml-20"
                      onClick={() => {
                        handleClick();
                      }}
                    >
                      <span>Apply</span>
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div className="job-profile-box">
              <div className="row">
                <div className="col-md-6">
                  <div
                    className="user-post"
                    style={{
                      display: "flex",
                      alignItems: "flex-start",
                      justifyContent: "space-between",
                    }}
                  >
                    <div
                      className="prof-img"
                      style={{ marginRight: "10px", marginTop: "10px" }}
                    >
                      <img
                        src={
                          singlePublicJobData?.user?.profile_img
                            ? singlePublicJobData?.user?.profile_img
                            : placeholderUser
                        }
                        alt="placeholder"
                        style={{
                          height: "50px",
                          width: "50px",
                          borderRadius: "50%",
                        }}
                      />
                    </div>
                    <div style={{ flex: 1, overflow: "hidden" }}>
                      <div>
                        <h4
                          className="poster-name card-control-text-oneline"
                          style={{
                            // marginBottom: "5px",
                            // whiteSpace: "nowrap",
                            // overflow: "hidden",
                            textOverflow: "ellipsis",
                            cursor: "pointer",
                          }}
                          onClick={() =>
                            navigate(
                              `/profile/${singlePublicJobData?.user?.id}`
                            )
                          }
                        >
                          {singlePublicJobData?.user?.first_name +
                            " " +
                            singlePublicJobData?.user?.last_name}
                        </h4>
                      </div>

                      <p
                        className="designation-name card-control-text-oneline"
                        style={{
                          marginBottom: "5px",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                        }}
                      >
                        {singlePublicJobData?.user?.profile?.profile_headline}{" "}
                        {singlePublicJobData?.company_name
                          ? `at ${singlePublicJobData?.company_name}`
                          : ""}
                      </p>
                      <p className="sub-title" style={{ marginBottom: "5px" }}>
                        Job Poster
                      </p>
                    </div>
                  </div>
                </div>
                {console.log(singlePublicJobData, "fff")}
                <div className="col-md-6">
                  <div className="d-flex justify-content-end">
                    {singlePublicJobData?.user?.connection_status ? (
                      <div
                        className="btn blue-color"
                        onClick={() => {
                          handleClick();
                        }}
                      >
                        Message
                      </div>
                    ) : (
                      <>
                        <div className="btn blue-color">Send NPMail</div>
                        <div
                          className="btn btn-light blue-color"
                          //   onClick={() => sendRequest(getAJob?.user?.id)}
                          onClick={() => {
                            handleClick();
                          }}
                        >
                          <img
                            src={connectIcon}
                            alt="noblepage_logo"
                            className="icon-small mr-10"
                          />
                          <span>Connect</span>
                        </div>
                      </>
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div className="description mt-30">
              <h3 className="main-header">Job Description</h3>
              <div
                dangerouslySetInnerHTML={createMarkup(
                  singlePublicJobData?.description
                )}
                style={{ wordBreak: "break-all" }}
                className="my-3"
              ></div>
            </div>
            <div className="job-helpful">
              <h5>Was this Job helpful?</h5>
              <div className="d-flex" style={{ cursor: "pointer" }}>
                <h5
                  onClick={() =>
                    toast.success("Thank you for your feedback", {
                      position: toast.POSITION.BOTTOM_LEFT,
                    })
                  }
                >
                  Yes
                </h5>
                <span className="ml-5 mr-5" style={{ marginTop: "2px" }}>
                  {" / "}
                </span>
                <h5
                  onClick={() =>
                    toast.success("Thank you for your feedback", {
                      position: toast.POSITION.BOTTOM_LEFT,
                    })
                  }
                >
                  {" No"}
                </h5>
              </div>
            </div>
            {singlePublicJobData?.company_visible &&
              singlePublicJobData?.company_id && (
                <div className="job-about-comapny">
                  <h3 className="main-header mb-3">About Company</h3>
                  <div className="row">
                    <div className="col-md-9">
                      <div className="comapny-details">
                        {console.log(singlePublicJobData)}
                        <div className="prof-img">
                          <img
                            src={
                              singlePublicJobData?.company_visible
                                ? singlePublicJobData?.company?.icon
                                  ? singlePublicJobData?.company?.icon
                                  : pagePlaceholder
                                : confidential
                            }
                            style={{
                              paddingBottom: "2px",
                              paddingRight: "3px",
                              width: "100%",
                            }}
                            alt="placeholder"
                          />
                        </div>
                        <div style={{ flex: 1, overflow: "hidden" }}>
                          <h4
                            className="poster-name card-control-text-oneline"
                            style={{
                              marginBottom: "5px",
                              whiteSpace: "nowrap",
                              overflow: "hidden",
                              textOverflow: "ellipsis",
                              cursor: "pointer",
                            }}
                            onClick={() =>
                              navigate(
                                `/pageadminview/${singlePublicJobData?.company_id}`
                              )
                            }
                          >
                            {singlePublicJobData?.company_name}
                          </h4>
                          <p
                            className="designation-name card-control-text-oneline"
                            style={{
                              marginBottom: "5px",
                              overflow: "hidden",
                              textOverflow: "ellipsis",
                            }}
                          >
                            {singlePublicJobData?.company?.location}
                            {singlePublicJobData?.company?.location &&
                            singlePublicJobData?.company_followers_count > 0
                              ? ","
                              : ""}{" "}
                            {singlePublicJobData?.company_followers_count}{" "}
                            {singlePublicJobData?.company_followers_count > 1
                              ? " followers"
                              : " follower"}
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-3">
                      <div
                        className="btn btn-light btn-margin-bottom follow-btn"
                        style={{ float: "right" }}
                        onClick={() => {
                          // navigate("/connections", {
                          //   state: { user_id: user_id },
                          // });
                          handleClick();
                        }}
                      >
                        {/* {selectedJob?.company_is_following ? (
                              <span>
                                <img
                                  src={unfollow}
                                  alt=""
                                  className="followicon"
                                />{" "}
                                Unfollow
                              </span>
                            ) : ( */}
                        <span>
                          <img src={add} alt="" className="followicon" /> Follow
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="row mb-3">
                    {/* <p>{selectedJob?.company?.description}</p> */}
                  </div>
                </div>
              )}
          </div>
        </div>
        <div className="col-lg-2"></div>
      </div>
      {/* <ToastContainer /> */}
    </div>
  );
};

export default JobDetail;
