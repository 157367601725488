import React, { useState } from "react";
import Footer from "../core/components/layout/footer/Footer";
import Header from "../core/components/layout/Header/Header";
import Heading from "../core/components/layout/Heading";
import logo from "../assets/img/np-logo.svg";
import flag from "../assets/img/Flags.svg";

import MobileInput from "../core/components/Inputs/MobileInput";
import { call } from "../api/apiCall";
import { Link, useNavigate } from "react-router-dom";

export default function Phone() {
  const [phoneNumber, setPhoneNumber] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [showError, setShowError] = useState("")
  const navigate = useNavigate();
  const handleSubmit = async () => {
    let forgotPassData = {
      phone: phoneNumber,
    }
    try {
      const res = await call(
        "post",
        "api/v1/auth/forgot-password",
        null,
        forgotPassData
      )
      if (res.data.success === true) {
        console.log("forgot res", res.data);
        navigate('/otp', { state: { phone: phoneNumber } });

      }

    } catch (err) {
      console.log(err)
      setShowError(err.response.data.message)
    }
  };

  const validation = () => {
    if (!phoneNumber) {
      setErrorMessage("Please enter your registered mobile number.");
    }
    else {
      handleSubmit();
    }
  };

  // const handelChangeInput = (e) => {
  //   setPhoneNumber( e)
  //   setErrorMessage("")
  //   setShowError("")
  // }

  return (
    <div>
      <Header />
      <div className="banner">
        <div className="container">
          <div className="row">
            <div className="col-lg-6">
              <Heading />
            </div>
            <div className="col-lg-6">
              <div className="forgot">
                <form className="auth-form">
                  <h2>Forgot Password?</h2>
                  <p>
                    Enter your registered email address below, and we'll send
                    you a one-time password (OTP) to reset your password
                  </p>
                  {showError && <label className="label-input100 text-danger">{showError}</label>}
                  <MobileInput
                    label="Mobile"
                    value="phoneNumber"
                    onChange={(value, country) => {
                      setPhoneNumber(
                        `+${country.dialCode}-${value.slice(country.dialCode.length)}`
                      )
                      setErrorMessage("")
                      setShowError("")
                    }
                    }
                    error={errorMessage}
                  />
                  {/* <div className="blue-text">Use email</div> */}
                  <span className="use-phone-text">
                    <Link to="/forgot">Use email </Link>
                  </span>
                  <div className="text-center d-grid ">
                    <div className="btn btn-blue" onClick={validation}>
                      Send OTP
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
          <div className="minHeight"></div>
        </div>
        <div className="n-logo">
          <img src={logo} alt="" width="100%" />
        </div>
      </div>
      <Footer />
    </div>
  );
}
