import React from "react";
import like from "../../../../assets/img/like_1.svg";
import likeGrey from "../../../../assets/img/like_grey.svg";
import hand from "../../../../assets/img/clapping 3.svg";
import heart from "../../../../assets/img/ant-design_heart-filled.svg";
import hat from "../../../../assets/img/hat 1.svg";
import bulb from "../../../../assets/img/idea_1.svg";
import emoj from "../../../../assets/img/smile_1.svg";
import surp from "../../../../assets/img/facebook-reactions_1.svg";
import deleteIcon from "../../../../assets/img/delete.svg";
import placeholderUser from "../../../../assets/img/placeholder_user.jpg";
import edit from "../../../../assets/img/edit-icon.svg";
import slash from "../../../../assets/img/slash.svg";
import cross_circle from "../../../../assets/img/cross_circle.svg";
import watchIcon from "../../../../assets/img/Watch-icon.svg";
import DeleteModal from "../../../../core/components/modal/DeleteModal";
import relevant from "../../../../assets/img/relevant.png";
import "./comments.scss";
import DropDowns from "./DropDowns";
import pin from "../../../../assets/img/pin-grey.png";
import { useState } from "react";
import { useEffect } from "react";
import { updateComment } from "../../../../store/dashboard/dashboardActions";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { Input, Spinner } from "reactstrap";
import ReplyData from "./ReplyData";
import EmojiPicker from "emoji-picker-react";
import ViewReactionModal from "../viewReactionModal";
import translateInstance from "../../../../core/components/TranslateContent/Translate";
import {
  abbreviateNumber,
  autoDetect,
  handleTranslate,
} from "../../../../core/components/Utility/Utility";

const CommentData = (props) => {
  const {
    comment,
    index,
    addComment,
    setAddComment,
    handleChange,
    handleSubmit,
    commentData,
    handleReplySubmit,
    handleReply,
    reply,
    user,
    handleCommentsReaction,
    replyBoxStatus,
    setReplyBoxStatus,
    getCommentReply,
    getTime,
    showReplyEmoji,
    setShowReplyEmoji,
    onReplyEmojiClick,
    setReplyId,
    closeCommentModal,
    setCloseCommentModal,
    setCommentDeleteId,
    closeReplyModal,
    setCloseReplyModal,
    setReplyDeleteId,
    dropDownComm,
    setDropDownComm,
    dropDownReply,
    setDropDownReply,
    dropRefComment,
    item,
    userId,
    setPostID,
    reportModal,
    setReportModal,
    reportType,
    setReportType,
    reportCommentId,
    setReportCommentId,
    inputComment,
    isReplyDD,
    setIsReplyDD,
    dropRefReplies,
    pinCommentToTop,
    UnpinComment,
    confirmBlockModal,
    setConfirmBlockModal,
    setBlockCommentId,
    setBlockUserId,
    blockComment,
    totalReplies,
    type,
    setRepliesCount,
    setShowEmoji,
  } = props;

  const [isCommentEdit, setIsCommentEdit] = useState(false);
  const [textComment, setTextComment] = useState(comment?.comment);
  const [showAllReplies, setShowAllReplies] = useState(false);
  const [editCommentId, setEditCommentId] = useState();
  const [viewCommentReaction, setViewCommentReaction] = useState(false);
  const [selectedCommentId, setSelectedCommentId] = useState();
  const [isTranslated, setIsTranslated] = useState();
  const [translatedText, setTraslatedText] = useState();
  const [detectedLanguage, setDetectedLanguage] = useState();
  const dispatch = useDispatch();

  const editComment = (commentData) => {
    setEditCommentId(commentData?.id);
    setIsCommentEdit(!isCommentEdit);
    setDropDownComm(-1);
    setDropDownReply(-1);
  };
  //  console.log("comment", comment);

  useEffect(() => {
    setTextComment(comment?.comment);
    autoDetect(comment?.comment, setDetectedLanguage);
  }, [comment]);

  useEffect(() => {
    document.getElementById(editCommentId)?.focus();
  }, [editCommentId]);

  const handleEdit = (event, postID) => {
    let token = window.localStorage.getItem("accessToken");
    if (event.key === "Enter") {
      dispatch(
        updateComment(token, editCommentId, { comment: textComment }, postID)
      ).then((res) => {
        toast.success(res.message, {
          position: toast.POSITION.BOTTOM_LEFT,
        });
      });
      setIsCommentEdit(false);
    } else {
      setTextComment(event.target.value);
    }
  };

  console.log(comment, comment?.reaction?.count);

  return (
    <div className="read" key={index}>
      <div className="row ">
        <div className=" col-1">
          <div className="prof-img">
            {comment?.Page ? (
              <img
                src={
                  comment?.Page?.icon === null || comment?.Page?.icon === ""
                    ? placeholderUser
                    : comment?.Page?.icon
                }
                alt=""
                className="img-fluid"
              />
            ) : (
              <img
                src={
                  comment?.user?.profile_img === null ||
                  comment?.user?.profile_img === ""
                    ? placeholderUser
                    : comment?.user?.profile_img
                }
                alt=""
                className="img-fluid"
              />
            )}
          </div>
        </div>
        <div className=" col-11">
          <div className="msg-box">
            <div className="commentNameDiv">
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <div
                  className={` ${
                    type === "myPost" ||
                    type === "savedPost" ||
                    type === "scheduledPost" ||
                    type === "draftPost"
                      ? "networkNameWidth"
                      : "namewidth"
                  }`}

                  // style={{
                  //   width: "72%",
                  //   display: "flex",
                  //   alignItems: "center",
                  // }}
                >
                  {comment?.Page ? (
                    <h5 className="card-control-text-oneline">
                      {comment?.Page?.name}
                    </h5>
                  ) : (
                    <h5 className="card-control-text-oneline">
                      {comment?.user?.first_name} {comment?.user?.last_name}
                    </h5>
                  )}

                  {item?.pinned_comment === comment?.id ? (
                    <div
                      className="mb-2 ms-3"
                      onClick={() => {
                        if (userId === item?.user_id) UnpinComment();
                      }}
                    >
                      <img
                        src={pin}
                        alt=""
                        style={{
                          width: "17px",
                          height: "17px",
                          padding: "2px",
                        }}
                      />
                      <span className="pin-text">Pinned</span>
                    </div>
                  ) : (
                    <></>
                  )}
                </div>
                <div className="time">
                  <span>{getTime(comment?.createdAt)}</span>
                </div>
                <div
                  ref={dropRefComment}
                  className="threedots"
                  onClick={() => {
                    setIsReplyDD(!isReplyDD);
                    if (dropDownComm === index) {
                      setDropDownComm(-1);
                      setDropDownReply(-1);
                    } else {
                      setDropDownComm(index);
                      setDropDownReply(-1);
                    }
                  }}
                >
                  <span className="clickDots">&#x2022;</span>
                  <span className="clickDots">&#x2022;</span>
                  <span className="clickDots">&#x2022;</span>
                </div>

                <DropDowns
                  dropDownComm={dropDownComm}
                  index={index}
                  comment={comment}
                  user={user}
                  userId={userId}
                  item={item}
                  dropRefComment={dropRefComment}
                  editComment={editComment}
                  setCloseCommentModal={setCloseCommentModal}
                  closeCommentModal={closeCommentModal}
                  setCommentDeleteId={setCommentDeleteId}
                  setReportModal={setReportModal}
                  reportModal={reportModal}
                  reportType={reportType}
                  setReportType={setReportType}
                  reportCommentId={reportCommentId}
                  setReportCommentId={setReportCommentId}
                  setPostID={setPostID}
                  pinCommentToTop={pinCommentToTop}
                  setDropDownComm={setDropDownComm}
                  confirmBlockModal={confirmBlockModal}
                  setConfirmBlockModal={setConfirmBlockModal}
                  setBlockCommentId={setBlockCommentId}
                  setBlockUserId={setBlockUserId}
                  blockComment={blockComment}
                  setRepliesCount={setRepliesCount}
                />
              </div>
              {isCommentEdit ? (
                <input
                  className="commentClass"
                  id={editCommentId}
                  ref={inputComment}
                  value={textComment}
                  style={{
                    border: "none",
                    background: "rgba(238, 240, 242, 0.35)",
                    marginTop: "4px",
                    marginBottom: "8px",
                    wordBreak: "break-all",
                  }}
                  disabled={!isCommentEdit}
                  onBlur={() => setIsCommentEdit(false)}
                  onChange={(event) => setTextComment(event.target.value)}
                  onKeyDown={(e) => {
                    handleEdit(e, item?.id);
                  }}
                />
              ) : (
                <div
                  className="commentClass"
                  id={editCommentId}
                  ref={inputComment}
                  style={{
                    border: "none",
                    background: "rgba(238, 240, 242, 0.35)",
                    marginTop: "4px",
                    marginBottom: "8px",
                    wordBreak: "break-all",
                  }}
                  role="textbox"
                  aria-multiline="true"
                >
                  {textComment}
                </div>
              )}

              {detectedLanguage != user?.data?.content_language && (
                <span
                  onClick={() =>
                    handleTranslate(
                      textComment,
                      comment.id,
                      setIsTranslated,
                      setTraslatedText,
                      user?.data?.content_language
                    )
                  }
                >
                  translate
                </span>
              )}
              {isTranslated === comment.id && (
                <div
                  className="commentClass"
                  //  id={editCommentId}
                  //  ref={inputComment}
                  style={{
                    border: "none",
                    background: "rgba(238, 240, 242, 0.35)",
                    marginTop: "4px",
                    marginBottom: "8px",
                    wordBreak: "break-all",
                  }}
                  role="textbox"
                  aria-multiline="true"
                >
                  {translatedText || (
                    <Spinner
                      style={{
                        margin: "0.5rem",
                        height: "1.5rem",
                        width: "1.5rem",
                        border: "var(--bs-spinner-border-width) solid  #0432a3",
                        borderRightColor: "transparent",
                      }}
                    >
                      Loading...
                    </Spinner>
                  )}
                </div>
              )}
            </div>
            {/* <div className="time">
            <span>
              {moment(comment.createdAt).fromNow(true)}
              {" ago"}
            </span>
          </div> */}
          </div>
          <div className="flex">
            <div className="like_main_comment curserPointer">
              <div className="likes_comment ">
                {comment?.reaction?.byUser?.type === "Like" ? (
                  <img src={like} alt="" />
                ) : (
                  <img
                    src={like}
                    alt=""
                    onClick={() => {
                      handleCommentsReaction(comment, "Like");
                    }}
                  />
                )}
                {comment?.reaction?.byUser?.type === "Love" ? (
                  <img src={heart} alt="" />
                ) : (
                  <img
                    src={heart}
                    alt=""
                    onClick={() => {
                      handleCommentsReaction(comment, "Love");
                    }}
                  />
                )}
                {comment?.reaction?.byUser?.type === "Respect" ? (
                  <img src={hat} alt="" />
                ) : (
                  <img
                    src={hat}
                    alt=""
                    onClick={() => {
                      handleCommentsReaction(comment, "Respect");
                    }}
                  />
                )}
                {comment?.reaction?.byUser?.type === "Celebrate" ? (
                  <img src={hand} alt="" />
                ) : (
                  <img
                    src={hand}
                    alt=""
                    onClick={() => {
                      handleCommentsReaction(comment, "Celebrate");
                    }}
                  />
                )}
                {comment?.reaction?.byUser?.type === "Insightful" ? (
                  <img src={bulb} alt="" />
                ) : (
                  <img
                    src={bulb}
                    alt=""
                    onClick={() => {
                      handleCommentsReaction(comment, "Insightful");
                    }}
                  />
                )}
                {comment?.reaction?.byUser?.type === "Curious" ? (
                  <img src={emoj} alt="" />
                ) : (
                  <img
                    src={emoj}
                    alt=""
                    onClick={() => {
                      handleCommentsReaction(comment, "Curious");
                    }}
                  />
                )}
                {comment?.reaction?.byUser?.type === "Surprise" ? (
                  <img src={surp} alt="" />
                ) : (
                  <img
                    src={surp}
                    alt=""
                    onClick={() => {
                      handleCommentsReaction(comment, "Surprise");
                    }}
                  />
                )}
              </div>
              {item?.as_a_page ? (
                comment.reaction.byUsers?.find(
                  ({ page_id }) => page_id === item?.as_a_page?.id
                ) ? (
                  comment?.reaction?.byUsers.map((reactionDel) =>
                    // console.log(item?.as_a_page?.id, reactionDel?.page_id)
                    item?.as_a_page?.id === reactionDel?.page_id ? (
                      reactionDel?.type === "Like" ? (
                        <span
                          onClick={() =>
                            handleCommentsReaction(
                              comment,
                              "Like",
                              "deleteReaction"
                            )
                          }
                          style={{ color: "#0432A3" }}
                        >
                          <img src={like} alt="" /> Like
                        </span>
                      ) : reactionDel?.type === "Celebrate" ? (
                        <span
                          onClick={() =>
                            handleCommentsReaction(
                              comment,
                              "Celebrate",
                              "deleteReaction"
                            )
                          }
                          style={{ color: "#198754" }}
                        >
                          <img src={hand} alt="" /> Celebrate
                        </span>
                      ) : reactionDel?.type === "Curious" ? (
                        <span
                          onClick={() =>
                            handleCommentsReaction(
                              comment,
                              "Curious",
                              "deleteReaction"
                            )
                          }
                          style={{ color: "#ffc107" }}
                        >
                          <img src={emoj} alt="" /> Curious
                        </span>
                      ) : reactionDel?.type === "Love" ? (
                        <span
                          onClick={() =>
                            handleCommentsReaction(
                              comment,
                              "Love",
                              "deleteReaction"
                            )
                          }
                          style={{ color: "#dc3545" }}
                        >
                          <img src={heart} alt="" /> Love
                        </span>
                      ) : reactionDel?.type === "Respect" ? (
                        <span
                          onClick={() =>
                            handleCommentsReaction(
                              comment,
                              "Respect",
                              "deleteReaction"
                            )
                          }
                          style={{ color: "#DF6B00" }}
                        >
                          <img src={hat} alt="" /> Respect
                        </span>
                      ) : reactionDel?.type === "Insightful" ? (
                        <span
                          onClick={() =>
                            handleCommentsReaction(
                              comment,
                              "Insightful",
                              "deleteReaction"
                            )
                          }
                          style={{ color: "#3F2DAF" }}
                        >
                          <img src={bulb} alt="" /> Insightful
                        </span>
                      ) : reactionDel?.type === "Surprise" ? (
                        <span
                          onClick={() =>
                            handleCommentsReaction(
                              comment,
                              "Surprise",
                              "deleteReaction"
                            )
                          }
                          style={{ color: "#ffc107" }}
                        >
                          <img src={surp} alt="" /> Surprise
                        </span>
                      ) : comment?.reaction?.byUsers === null ? (
                        <span>
                          <img src={likeGrey} alt="" /> <span>Like</span>
                        </span>
                      ) : (
                        <></>
                      )
                    ) : (
                      <></>
                    )
                  )
                ) : (
                  <span>
                    <img src={likeGrey} alt="" /> <span>Like</span>
                  </span>
                )
              ) : comment?.reaction?.user?.type === "Like" ? (
                <span
                  onClick={() =>
                    handleCommentsReaction(comment, "Like", "deleteReaction")
                  }
                  style={{ color: "#0432A3" }}
                >
                  <img src={like} alt="" /> Like
                </span>
              ) : comment?.reaction?.user?.type === "Celebrate" ? (
                <span
                  onClick={() =>
                    handleCommentsReaction(
                      comment,
                      "Celebrate",
                      "deleteReaction"
                    )
                  }
                  style={{ color: "#198754" }}
                >
                  <img src={hand} alt="" /> Celebrate
                </span>
              ) : comment?.reaction?.user?.type === "Curious" ? (
                <span
                  onClick={() =>
                    handleCommentsReaction(comment, "Curious", "deleteReaction")
                  }
                  style={{ color: "#ffc107" }}
                >
                  <img src={emoj} alt="" /> Curious
                </span>
              ) : comment?.reaction?.user?.type === "Love" ? (
                <span
                  onClick={() =>
                    handleCommentsReaction(comment, "Love", "deleteReaction")
                  }
                  style={{ color: "#dc3545" }}
                >
                  <img src={heart} alt="" /> Love
                </span>
              ) : comment?.reaction?.user?.type === "Respect" ? (
                <span
                  onClick={() =>
                    handleCommentsReaction(comment, "Respect", "deleteReaction")
                  }
                  style={{ color: "#DF6B00" }}
                >
                  <img src={hat} alt="" /> Respect
                </span>
              ) : comment?.reaction?.user?.type === "Insightful" ? (
                <span
                  onClick={() =>
                    handleCommentsReaction(
                      comment,
                      "Insightful",
                      "deleteReaction"
                    )
                  }
                  style={{ color: "#3F2DAF" }}
                >
                  <img src={bulb} alt="" /> Insightful
                </span>
              ) : comment?.reaction?.user?.type === "Surprise" ? (
                <span
                  onClick={() =>
                    handleCommentsReaction(
                      comment,
                      "Surprise",
                      "deleteReaction"
                    )
                  }
                  style={{ color: "#ffc107" }}
                >
                  <img src={surp} alt="" /> Surprise
                </span>
              ) : comment?.reaction?.user === null ? (
                <span>
                  <img src={likeGrey} alt="" /> <span>Like</span>
                </span>
              ) : (
                <></>
              )}
              {comment?.reaction?.count > 0 && (
                <span style={{ fontSize: "12px", marginLeft: "5px" }}>
                  ({abbreviateNumber(comment?.reaction?.count)})
                </span>
              )}
            </div>
            <div
              className="others viewReactionsType"
              onClick={() => {
                setViewCommentReaction(!viewCommentReaction);
                setSelectedCommentId(comment?.id);
              }}
            >
              <div className="reply curserPointer">
                <span>View</span>
              </div>
            </div>
            <div className="reply curserPointer">
              <span
                onClick={() => {
                  setReplyBoxStatus((prevState) => [
                    ...prevState,
                    { id: comment?.id, status: true },
                  ]);
                  setShowAllReplies(true);
                  document.getElementById(`replyinput${comment?.id}`)?.focus();
                }}
              >
                Reply
              </span>
            </div>
            {comment?.replies?.length > 0 && (
              <div
                className="view curserPointer"
                // onClick={() => {
                //   handleViewMoreReactions(comment.id);
                // }}
              >
                {/* {console.log("comment?.replies?.length", comment)} */}
                {/* {showAllReplies ? (
                  <></>
                  // <span
                  //   onClick={() => {
                  //     getCommentReply(comment?.id);
                  //     setShowAllReplies(false);
                  //   }}
                  // >
                  //   View less replies
                  // </span>
                ) : ( */}
                <span
                  onClick={() => {
                    getCommentReply(comment?.id);
                    setShowAllReplies(true);
                  }}
                  style={{ fontSize: "12px", marginLeft: "5px" }}
                >
                  View {abbreviateNumber(comment?.reply_count)}{" "}
                  {comment?.reply_count > 1 ? " replies" : " reply"}
                </span>
                {/* )} */}
              </div>
            )}
          </div>

          {replyBoxStatus?.find((x) => x.id === comment?.id)?.status ===
          true ? (
            <div className="thoughts mb-4 mt-4">
              <div className="pro-img">
                {item?.as_a_page ? (
                  <img
                    src={
                      item?.as_a_page?.icon
                        ? item?.as_a_page?.icon
                        : placeholderUser
                    }
                    className="user-circular-img"
                    alt=""
                  />
                ) : (
                  <img
                    src={
                      user?.data?.profile_img
                        ? user?.data?.profile_img
                        : placeholderUser
                    }
                    alt=""
                  />
                )}
              </div>
              <div className="type">
                <Input
                  name="reply"
                  value={reply ? reply?.objects[`${comment?.id}`]?.value : ""}
                  placeholder="Reply"
                  id={`replyinput${comment?.id}`}
                  onChange={(event) => handleReply(event, comment?.id)}
                  onKeyDown={(e) => {
                    handleReplySubmit(e, comment?.id);
                  }}
                  className={reply ? "pe-5" : ""}
                />
              </div>
              <div
                className="emojee"
                onClick={() => {
                  setShowReplyEmoji(!showReplyEmoji);
                  setReplyId(comment?.id);
                  setShowEmoji(false);
                }}
                style={{
                  position: "absolute",
                  right: "14px",
                  marginTop: "6px",
                  paddingLeft: "5px",
                }}
              ></div>
              <div
                className="mt-130"
                style={{
                  position: "absolute",
                  marginTop: "40px",
                  right: "20px",
                  zIndex: "1",
                }}
              >
                {showReplyEmoji && (
                  <EmojiPicker
                    height={350}
                    width={400}
                    onEmojiClick={onReplyEmojiClick}
                    autoFocusSearch={false}
                    Theme="light"
                  />
                )}
              </div>
            </div>
          ) : (
            ""
          )}
          {comment?.replies && showAllReplies
            ? comment?.replies?.map((replies, index) => (
                <ReplyData
                  showAllReplies={showAllReplies}
                  index={index}
                  replies={replies}
                  item={item}
                  getTime={getTime}
                  dropRefReplies={dropRefReplies}
                  setIsReplyDD={setIsReplyDD}
                  isReplyDD={isReplyDD}
                  setDropDownReply={setDropDownReply}
                  setDropDownComm={setDropDownComm}
                  comment={comment}
                  user={user}
                  userId={userId}
                  editComment={editComment}
                  setCloseCommentModal={setCloseCommentModal}
                  closeCommentModal={closeCommentModal}
                  setCommentDeleteId={setCommentDeleteId}
                  setReportModal={setReportModal}
                  reportModal={reportModal}
                  reportType={reportType}
                  setReportType={setReportType}
                  reportCommentId={reportCommentId}
                  setReportCommentId={setReportCommentId}
                  setPostID={setPostID}
                  pinCommentToTop={pinCommentToTop}
                  setReplyDeleteId={setReplyDeleteId}
                  setCloseReplyModal={setCloseReplyModal}
                  closeReplyModal={closeReplyModal}
                  setConfirmBlockModal={setConfirmBlockModal}
                  setBlockCommentId={setBlockCommentId}
                  setBlockUserId={setBlockUserId}
                  blockComment={blockComment}
                  dropDownReply={dropDownReply}
                  confirmBlockModal={confirmBlockModal}
                  handleCommentsReaction={handleCommentsReaction}
                  replyBoxStatus={replyBoxStatus}
                  setReplyBoxStatus={setReplyBoxStatus}
                  handleReply={handleReply}
                  handleReplySubmit={handleReplySubmit}
                  setShowReplyEmoji={setShowReplyEmoji}
                  showReplyEmoji={showReplyEmoji}
                  setReplyId={setReplyId}
                  reply={reply}
                />
              ))
            : ""}
          {totalReplies <=
            (comment?.replies === undefined ? 0 : comment?.replies.length) ||
          comment?.reply_count == 0 ||
          !showAllReplies ? (
            <></>
          ) : (
            <p
              className="load_more mb-2"
              onClick={() => getCommentReply(comment?.id)}
            >
              <span className="clickDots">&#x2022;</span>
              <span className="clickDots">&#x2022;</span>
              <span className="clickDots">&#x2022;</span> Load more replies
            </p>
          )}
        </div>
      </div>
      {viewCommentReaction && (
        <ViewReactionModal
          modal={viewCommentReaction}
          toggle={() => setViewCommentReaction(!viewCommentReaction)}
          title="Reactions"
          // userName={userName}
          postId={selectedCommentId}
          type="comment"
        />
      )}
    </div>
  );
};

export default CommentData;
