import React from "react";
import "./backgroundScreening.scss";
import headerImage from "../../assets/img/headerImage.png";

const BackgroundHeader = () => {
  return (
    <>
      <header className="background_screen_header">
        <div className="container">
          <div className="d-flex justify-content-between align-items-center">
            <h3 className="text-white">
              Global Background Screening and Profile Truth Badges
            </h3>
            <img src={headerImage} />
          </div>
        </div>
      </header>
    </>
  );
};

export default BackgroundHeader;
