import React from "react";
import Navbar from "../../../pages/navbar/navbar";
import { Link } from "react-router-dom";
import placeholderUser from "../../../assets/img/placeholder_user.jpg";
import Advertisement from "../Advertisement";
import Ad from "../../../core/components/layout/Sidebar/Ad";
import Refer from "../../../core/components/layout/Sidebar/Refer";
import PostOnDashboard from "../components/postComponent/PostOnDashboard";
import AppLoader from "../../../core/components/Loaders/appLoader/appLoader";
import { useDispatch, useSelector } from "react-redux";
import RoleChangeModal from "../components/RoleChangeModal";
import ShareModal from "../../../core/components/sharePost/sharePost";
import DeleteModal from "../../../core/components/modal/DeleteModal";
import ReportModal from "../components/reportTypeModal";
import { ToastContainer } from "react-toastify";

const PostDetails = (props) => {
  const {
    user,
    postDetailData,
    privacy_type,
    dropDownShare,
    setDropDownShare,
    editPost,
    save_post,
    removeSavePost,
    dropRef,
    dropRefShare,
    delete_post,
    likeResult,
    peopleFollowRequest,
    peopleUnFollowRequest,
    peopleRemoveConnection,
    handlePageFollow,
    handlePageUnFollow,
    setDropDownRepost,
    setDropDown,
    dropDown,
    commentStatus,
    setCommentStatus,
    handleCommentStatus,
    setCommentBoxStatus,
    dropDownRepost,
    repostPost,
    dropRefRepost,
    handleReaction,
    isViewReaction,
    setViewReaction,
    setPost_ID,
    setReportModal,
    reportModal,
    hideUserPost,
    commentBoxStatus,
    postViewCounter,
    openShareModal,
    setOpenShareModal,
    setShareData,
    shareData,
    sendRequest,
    roleShareModal,
    setRoleShareModal,
    getPageDetailData,
    rolePageId,
    setRolePageId,
    post_Id,
    reportType,
    setReportType,
    reportCommentId,
    setReportCommentId,
    closeModal,
    setCloseModal,
    deleteId,
    setDeleteId,
  } = props;

  const { isLoading } = useSelector((s) => s.loader);
  const { isPageLoading } = useSelector((state) => state.loader);

  return (
    <div className="dashboard bg-grey">
      <Navbar isJobs={false} />
      <div className="container mt-4">
        {isLoading || isPageLoading ? (
          <AppLoader />
        ) : (
          <div className="row">
            <div className="col-lg-3">
              <div className="left" style={{ borderRadius: "5px" }}>
                <div className="profile">
                  {user?.data?.background_img ? (
                    <img
                      src={user?.data?.background_img}
                      alt="background img"
                      className="back-image-fluid"
                      style={{ position: "absolute" }}
                    />
                  ) : (
                    <></>
                  )}

                  <div className="img">
                    <Link to={"/profile/" + user?.data?.id}>
                      <img
                        src={
                          user?.data?.profile_img
                            ? user?.data?.profile_img
                            : placeholderUser
                        }
                        alt="profile img"
                        className="img-fluid"
                      />
                    </Link>
                  </div>
                </div>
                <div className="starter">
                  {" "}
                  {user?.data?.membership?.name || "Starter"}
                </div>
                <div className="px-3 pb-3">
                  <div className="profile-details">
                    <Link to={"/profile/" + user?.data?.id}>
                      <h6>
                        {user?.data?.first_name} {user?.data?.last_name}
                      </h6>
                    </Link>
                    <p className="card-control-text-twoline">
                      {user?.data?.profile_headline
                        ? user?.data?.profile_headline
                        : ""}
                    </p>
                  </div>
                  <div className="margin">
                    <div className="follower" s>
                      <div className="box curserPointer">
                        <h6>{user?.data?.connections}</h6>
                        <p>
                          {user?.data?.connections > 1
                            ? "Connections"
                            : "Connection"}
                        </p>
                      </div>
                      <div className="h-border "></div>
                      <div className="box curserPointer">
                        <h6>{user?.data?.followers}</h6>
                        <p>
                          {user?.data?.followers > 1 ? "Followers" : "Follower"}
                        </p>
                      </div>
                      <div className="h-border"></div>

                      <div className="box curserPointer">
                        <h6>{user?.data?.followings}</h6>
                        <p>
                          {/* {user?.data?.followings > 1
                            ? "Followings"
                            : "Following"} */}
                          Following
                        </p>
                      </div>
                    </div>
                    <div className="line mb-20"></div>
                  </div>
                  <div className="update mt-3">
                    <Link to={"/profile/" + user?.data?.id}>
                      <span>View My Profile</span>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="center">
                <div className="msg">
                  <PostOnDashboard
                    item={postDetailData?.data[0]}
                    index={0}
                    dropDown={dropDown}
                    userId={user?.data?.id}
                    editPost={editPost}
                    delete_post={delete_post}
                    isSinglePost={true}
                    commentStatus={commentStatus}
                    setCommentStatus={setCommentStatus}
                    handleCommentStatus={handleCommentStatus}
                    dropRef={dropRef}
                    privacy_type={privacy_type}
                    save_post={save_post}
                    removeSavePost={removeSavePost}
                    setDropDown={setDropDown}
                    handleReaction={handleReaction}
                    isViewReaction={isViewReaction}
                    setViewReaction={setViewReaction}
                    setPostID={setPost_ID}
                    userName={
                      user?.data?.first_name + " " + user?.data?.last_name
                    }
                    setReportModal={setReportModal}
                    reportModal={reportModal}
                    hideUserPost={hideUserPost}
                    commentBoxStatus={commentBoxStatus}
                    setCommentBoxStatus={setCommentBoxStatus}
                    dropDownShare={dropDownShare}
                    setDropDownShare={setDropDownShare}
                    repostPost={repostPost}
                    likeResult={likeResult}
                    peopleFollowRequest={peopleFollowRequest}
                    peopleUnFollowRequest={peopleUnFollowRequest}
                    peopleRemoveConnection={peopleRemoveConnection}
                    handlePageFollow={handlePageFollow}
                    handlePageUnFollow={handlePageUnFollow}
                    postViewCounter={postViewCounter}
                    openShareModal={openShareModal}
                    setOpenShareModal={setOpenShareModal}
                    shareData={shareData}
                    setShareData={setShareData}
                    dropDownRepost={dropDownRepost}
                    setDropDownRepost={setDropDownRepost}
                    dropRefRepost={dropRefRepost}
                    roleShareModal={roleShareModal}
                    setRoleShareModal={setRoleShareModal}
                    rolePageId={rolePageId}
                    setRolePageId={setRolePageId}
                    user={user}
                    sendRequest={sendRequest}
                    setDeleteId={setDeleteId}
                    reportCommentId={reportCommentId}
                    setReportCommentId={setReportCommentId}
                    reportType={reportType}
                    setReportType={setReportType}
                  />
                </div>
              </div>
            </div>

            <div className="col-lg-3">
              <div className="middle">
                <Advertisement />
                <div style={{ marginTop: "26px" }}>
                  <Ad />
                </div>
                <div className="member">
                  <Refer />
                </div>
              </div>
            </div>
          </div>
        )}
        <ToastContainer />
      </div>

      {reportModal && (
        <ReportModal
          modal={reportModal}
          toggle={() => setReportModal(!reportModal)}
          post_id={post_Id}
          reportType={reportType} //"post"/"comment"
          reportCommentId={reportCommentId} //null in case of post
        />
      )}

      {closeModal && (
        <DeleteModal
          modal={DeleteModal}
          toggle={() => setCloseModal(!closeModal)}
          title="Delete Confirmation"
          deleteConfirm={() => {
            delete_post(deleteId);
          }}
          text="Are you want to sure to delete this?"
        />
      )}
      {openShareModal && (
        <ShareModal
          modal={openShareModal}
          toggle={() => setOpenShareModal(!openShareModal)}
          title="Share"
          shareData={shareData}
          type="shared_post"
          source="dashboard"
          toggleSuccessful={() => setOpenShareModal(!openShareModal)}
          isSinglePost={true}
        />
      )}

      {roleShareModal && (
        <RoleChangeModal
          modal={roleShareModal}
          toggle={() => setRoleShareModal(!roleShareModal)}
          title="Post, like, comment and share "
          postId={post_Id}
          getPageDetailData={getPageDetailData}
          rolePageId={rolePageId}
        />
      )}
    </div>
  );
};
export default PostDetails;
