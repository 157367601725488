import { Modal, ModalHeader, ModalBody } from "reactstrap";
import React, { useState, useCallback } from 'react'
import Cropper from 'react-easy-crop'
//import "../ImageCrop/background.scss"
import getCroppedImg from "../../../../core/components/ImageCrop/cropImage";

const EventBannerModal = (props) => {

  const {modal, toggle, title, uploadImage, handleBannerPicture} = props
 
  const [crop, setCrop] = useState({ x: 0, y: 0 })
  const [zoom, setZoom] = useState(1)
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null)
  
  const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels)
  }, [])

  const showCroppedImage = useCallback(async () => {
    try {
      const croppedImage = await getCroppedImg(
        uploadImage,
        croppedAreaPixels,
      )
    //   console.log('donee',  URL.createObjectURL(croppedImage) )
      handleBannerPicture(croppedImage);
      toggle();
    } catch (e) {
      console.error(e)
    }
  }, [croppedAreaPixels])

//   const onClose = useCallback(() => {
//     setCroppedImage(null)
//   }, [])

  console.log(uploadImage)

  return (
    <Modal
    size="md"
      isOpen={modal}
      toggle={toggle}
      className="modal-style image-cropper"
      style={{ maxWidth: "600px" }}
    >
      <ModalHeader toggle={toggle}>{title}</ModalHeader>
      <ModalBody>
      <div>
        <div style={{height:"300px", width:"100%"}}>
            <Cropper
          image={uploadImage}
          crop={crop}
          zoom={zoom}
          aspect={3 / 2}
          onCropChange={setCrop}
          onCropComplete={onCropComplete}
          cropSize={{ width: 500, height: 250 }}
          onZoomChange={setZoom}
          objectFit="horizontal-cover"
          style={{
            containerStyle: {
              width: "100%",
              height: "300px",
              backgroundColor: "#3f51b5",
              padding:0
            },
          }}
        /></div>
        <div className='sub-heading-slider'>
          <span>Zoom</span>
          <span>{zoom}</span>
        </div>
        <div className="controls">
        <input
          type="range"
          value={zoom}
          min={1}
          max={4}
          step={0.1}
          aria-labelledby="Zoom"
          onChange={(e) => {
            setZoom(e.target.value)
          }}
          className="zoom-range"
        />
      </div>
      </div>
   
        <div className="buttons">
          <span></span>
          <div className="btns">
            {/* <div className="btn btn-blue transparent">Change Photo</div> */}
            <div className="btn btn-blue"  onClick={showCroppedImage}>Apply</div>
          </div>
        </div>
      </ModalBody>
    </Modal>
  );
};

export default EventBannerModal;
