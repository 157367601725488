import axios from "axios";
import { call } from "./apiCall";
import { Navigate, useNavigate } from "react-router";
import { toast } from "react-toastify";

const setSession = (accessToken) => {
  if (accessToken) {
    localStorage.setItem("accessToken", accessToken);
    // axios.defaults.headers.common.Authorization = `Bearer ${accessToken}`
  } else {
    localStorage.removeItem("accessToken");
    delete axios.defaults.headers.common.Authorization;
  }
};

class AuthApi {
  login(emailName, password, registration_type, rememberMe, fcm_token) {
    return new Promise((resolve, reject) => {
      let loginData = {
        email: emailName,
        password: password,
        registration_type: registration_type,
        remember_me: rememberMe,
        fcm_token: fcm_token,
      };

      (async () => {
        try {
          const res = await call("post", "api/v1/auth/login", null, loginData);
          if (res.data.success === true) {
            if (!res.data.is2fa) {
              const accessToken = res?.data?.token;
              const chatToken = res?.data?.chatToken;
              setSession(accessToken);
              localStorage.setItem("chatToken", chatToken);
            } else {
              localStorage.setItem("otpToken", res.data.otpToken);
            }
            resolve(res.data);
          }
          if (res.data.success === false) {
            resolve(res.data);
          }
        } catch (err) {
          console.log(err);
          reject(err);
        }
      })();
    });
  }

  register(signUpData) {
    return new Promise((resolve, reject) => {
      (async () => {
        try {
          const res = await call(
            "post",
            "api/v1/auth/signup",
            null,
            signUpData
          );
          console.log(res.data);
          const accessToken = res?.data?.token;
          setSession(accessToken);
          resolve(res.data);
        } catch (err) {
          console.log(err);
          reject(err);
        }
      })();
    });
  }

  logout() {
    return new Promise((resolve, reject) => {
      let token = localStorage.getItem("accessToken");
      (async () => {
        try {
          const res = await call(
            "delete",
            "api/v1/auth/logout",
            null,
            null,
            token
          );
          localStorage.clear();
          resolve(res.data);
        } catch (err) {
          console.log(err);
          reject(err);
        }
      })();
    });
  }

  logoutPortal() {
    return new Promise((resolve, reject) => {
      let token = localStorage.getItem("accessTpToken");
      (async () => {
        try {
          const res = await call(
            "delete",
            "api/v1/auth/logout",
            null,
            null,
            token
          );
          localStorage.clear();
          resolve(res.data);
        } catch (err) {
          console.log(err);
          reject(err);
        }
      })();
    });
  }

  updateDetails(formdata) {
    return new Promise((resolve, reject) => {
      const token = localStorage.getItem("accessToken");
      (async () => {
        try {
          const res = await call(
            "patch",
            "/update-user",
            null,
            formdata,
            token
          );
          console.log(res.data);
          resolve(res.data);
        } catch (err) {
          console.log(err);
          reject(err);
        }
      })();
    });
  }

  getuserbytoken() {
    return new Promise((resolve, reject) => {
      const token = localStorage.getItem("accessToken");
      (async () => {
        try {
          const res = await call(
            "get",
            "api/v1/user/get_by_token",
            null,
            null,
            token
          );

          resolve(res.data);
        } catch (err) {
          console.log(err);
          if (
            err.response?.data?.message ===
            "User was logged out from the session"
          ) {
            toast.error(
              err.response?.data?.message,

              {
                position: toast.POSITION.BOTTOM_LEFT,
              }
            );
            <Navigate to="/login" />;
          }
          resolve(null);
        }
      })();
    });
  }

  validateOtp(data) {
    const token = localStorage.getItem("otpToken");
    return new Promise((resolve, reject) => {
      (async () => {
        try {
          const res = await call(
            "post",
            "api/v1/auth/validate-2fa",
            null,
            { otp: data },
            token
          );
          if (res.data.success) {
            setSession(res.data.token);
            resolve(res.data);
          }
        } catch (error) {
          reject(error);
        }
      })();
    });
  }

  resendOtp2fa() {
    const token = localStorage.getItem("otpToken");
    return new Promise((resolve, reject) => {
      (async () => {
        try {
          const res = await call(
            "get",
            "api/v1/auth/resend-otp-2fa",
            null,
            null,
            token
          );
          if (res.data.success) {
            console.log(res.data);
            localStorage.setItem("otpToken", res.data.otpToken);
            resolve(res.data);
          }
        } catch (error) {
          reject(error);
        }
      })();
    });
  }
}

export const authApi = new AuthApi();
