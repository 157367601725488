import React from "react";
import "./MediaView.scss";
import ReactPlayer from "react-player";
import DocView from "../../../../core/components/DocView/DocView";
import DocViewer, { DocViewerRenderers } from "@cyntler/react-doc-viewer";
import document from "../../../../assets/img/document.svg";

function MediaView({
  data,
  isImage,
  isVideo,
  isAttachment,
  mediaName,
  isAudio,
  handleMediaClose,
}) {
  return (
    <div className="media-view">
      <div className="media-box">
        {isImage && (
          <img
            src={data}
            alt="thumbnail"
            width="100%"
            height="100%"
            className="p-1 main-img"
          />
        )}
        {isVideo && (
          <ReactPlayer
            url={data}
            playing={false}
            controls={true}
            width="100%"
            height="100%"
          />
        )}
        {isAttachment && (
          // <DocViewer
          //   documents={data?.map((v) => ({
          //     uri: v,
          //   }))}
          //   pluginRenderers={DocViewerRenderers}
          //   config={{ header: { disableHeader: true } }}
          //   style={{
          //     width: "100%",
          //     maxHeight: 300,
          //     minHeight: 200,
          //     border: "1px solid #e5e5e5",
          //   }}
          //   theme={{
          //     disableThemeScrollbar: true,
          //   }}
          // />
          <div className="media-doc">
            <img src={document} className="doc-image" alt="doc" />
            <span>{mediaName || "File"}</span>
          </div>
        )}
        {isAudio && (
          <audio controls style={{ width: "100%" }}>
            <source src={data} type="audio/mp3" />
          </audio>
        )}
      </div>
      {handleMediaClose && (
        <div className="cross">
          {
            <div className="close" onClick={() => handleMediaClose(data)}>
              X
            </div>
          }
        </div>
      )}
    </div>
  );
}

export default MediaView;
