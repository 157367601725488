import {
  GET_SUGGESTEDTAG_LIST,
  GET_FOLLOWEDTAG_LIST,
  GET_MYTAG_LIST,
  FOLLOW_HASHTAG,
  LOAD_MORE_SUGGESTEDTAG_LIST,
  LOAD_MORE_FOLLOWEDTAG_LIST,
  GET_MORE_MYTAG_LIST,
} from "../types";

export const initialState = {
  suggestedTagList: [],
  followedTagList: [],
  myTagList: [],
};

const hashtag = (state = initialState, action) => {
  switch (action.type) {
    case GET_SUGGESTEDTAG_LIST:
      return { ...state, suggestedTagList: action.payload };

    case LOAD_MORE_SUGGESTEDTAG_LIST:
      return {
        ...state,
        suggestedTagList: [...state.suggestedTagList, ...action.payload],
      };

    case GET_FOLLOWEDTAG_LIST:
      return { ...state, followedTagList: action.payload };

    case LOAD_MORE_FOLLOWEDTAG_LIST:
      return {
        ...state,
        followedTagList: [...state.followedTagList, ...action.payload],
      };

    case GET_MYTAG_LIST:
      return { ...state, myTagList: action.payload };

    case GET_MORE_MYTAG_LIST:
      return {
        ...state,
        myTagList: [...state.myTagList, ...action.payload],
      };

    case FOLLOW_HASHTAG:
      if (action.payload.tab === "suggested_tags") {
        return {
          ...state,
          suggestedTagList: state.suggestedTagList.map((x) =>
            action.payload.type === "follow"
              ? x.id === action.payload.id
                ? { ...x, follow_type: true }
                : x
              : x.id === action.payload.id
              ? { ...x, follow_type: undefined }
              : x
          ),
        };
      } else if (action.payload.tab === "followed_tags") {
        console.log(action.payload.id);
        return {
          ...state,
          followedTagList: state.followedTagList.map((x) =>
            action.payload.type === "follow"
              ? x.id === action.payload.id
                ? { ...x, follow_type: true }
                : x
              : x.id === action.payload.id
              ? { ...x, follow_type: false }
              : x
          ),
        };
      } else if (action.payload.tab === "my_tags") {
        return {
          ...state,
          myTagList: state.myTagList.map((x) =>
            action.payload.type === "follow"
              ? x.id === action.payload.id
                ? { ...x, follow_type: true }
                : x
              : x.id === action.payload.id
              ? { ...x, follow_type: undefined }
              : x
          ),
        };
      }
      return state;

    //    case  GET_HASHTAG_LIST:
    //     return { ...state, hashtagList: action.payload };

    default:
      return state;
  }
};

export default hashtag;
