import React, { useEffect, useState } from "react";
import ClassicTemplate from "./CVTemplates/ClassicTemplate";
import FunctionalTemplate from "./CVTemplates/FunctionalTemplate";
import CombinedTemplate from "./CVTemplates/CombinedTemplate";
import ModernTemplate from "./CVTemplates/ModernTemplate";
import {
  addexperiencedetails,
  deleteUserExperience,
  getAllCompanyList,
} from "../../store/profile/profileActions";
import { getLocationsData } from "../../store/connections/connectionsActions";
import { useDispatch, useSelector } from "react-redux";
import DatePickerCustom from "../../core/components/DatePicker/DatePickerReact";
import moment from "moment";
import Autocomplete from "react-autocomplete";
import DeleteModal from "../../core/components/modal/DeleteModal";
import { initPageLoad, stopPageLoad } from "../../store/loader/loaderActions";
import AppLoader from "../../core/components/Loaders/appLoader/appLoader";
import {
  addWorkExperience,
  deleteWorkExperience,
  editWorkExperience,
} from "../../store/cv/cvActions";
import ThumbnailResumePreview from "./ThumbnailResumePreview";
import buld from "../../assets/img/circle-building.svg";
export const EditResumeTwo = ({
  pageNo,
  setPageNo,
  editCV,
  user,
  color,
  token,
  skillBackground,
  interestBackground,

  setPreviewCV,
  setPreviewResumeModal,
}) => {
  const { isPageLoading } = useSelector((state) => state.loader);
  // const [pageNo, setPageNo] = useState(1);
  const [locationList, setLocationList] = useState([]);
  const [companyList, setCompanyList] = useState([]);
  const dispatch = useDispatch();
  const [isEdit, setIsEdit] = useState(-1);
  const [locationInput, setLocationInput] = useState("");
  const { isLoading, cvTypes } = useSelector((state) => state.cv);
  const [memberInput, setMemberInput] = useState("");
  const [expData, setExpData] = useState({
    title: "",
    company_name: "",
    location: "",
    start_date: null,
    end_date: null,
    description: "",
    is_current: false,
  });

  const [error, setError] = useState({
    title: "",
    company: "",
    start_date: "",
    end_date: "",
    location: "",
    description: "",
  });

  const getCompanyList = (e) => {
    dispatch(getAllCompanyList({ search: e }))
      .then((res) => {
        if (res?.success === true) {
          setCompanyList(res?.data);
        } else setCompanyList([]);
      })
      .catch(() => {
        setCompanyList([]);
      });
  };

  const getLocation = (e) => {
    dispatch(getLocationsData(e))
      .then((res) => {
        if (res?.data) {
          setLocationList(res?.data);
        } else setLocationList([]);
      })
      .catch(() => {
        setLocationList([]);
      });
  };

  const handleSubmit = () => {
    if (checkValidation("all")) {
      dispatch(initPageLoad());
      if (isEdit > -1) dispatch(editWorkExperience(expData, isEdit, editCV));
      else dispatch(addWorkExperience(expData, editCV));
      setExpData({
        title: "",
        company_name: "",
        location: "",
        start_date: null,
        end_date: null,
        description: "",
        is_current: false,
      });
      setIsEdit(-1);
      dispatch(stopPageLoad());
    }
  };

  const handleChange = (e) => {
    setExpData({ ...expData, [e.target.name]: e.target.value });
  };

  const checkValidation = (type) => {
    let formIsValid = true;

    if (type === "title" || type === "all") {
      if (!expData?.title?.trim()) {
        setError({ ...error, title: "Title is mandatory." });
        formIsValid = false;
        return;
      } else {
        setError({ ...error, title: "" });
      }
    }

    if (type === "company" || type === "all") {
      if (!expData?.company_name) {
        setError({ ...error, company: "Company is mandatory." });
        formIsValid = false;
        return;
      } else {
        setError({ ...error, company: "" });
      }
    }

    if (type === "description" || type === "all") {
      if (!expData?.description?.trim()) {
        setError({ ...error, description: "Description is mandatory." });
        formIsValid = false;
        return;
      } else {
        setError({ ...error, description: "" });
      }
    }

    if (type === "location" || type === "all") {
      if (!expData?.location) {
        setError({ ...error, location: "Location is mandatory." });
        formIsValid = false;
        return;
      } else {
        setError({ ...error, location: "" });
      }
    }
    if (type === "start_date" || type === "all") {
      if (!expData?.start_date) {
        setError({ ...error, start_date: "Start date is mandatory" });
        formIsValid = false;
        return;
      } else {
        setError({ ...error, start_date: "" });
      }
    }

    if (type === "end_date" || type === "all") {
      if (expData?.is_current == false && expData?.start_date) {
        if (!expData.end_date) {
          setError({ ...error, end_date: "End date is mandatory" });
          formIsValid = false;
          return;
        } else {
          setError({ ...error, end_date: "" });
        }
      }
    }

    return formIsValid;
  };

  const handleDelete = (id) => {
    dispatch(deleteWorkExperience(id, editCV));
  };

  const handleEdit = (id) => {
    const data = user?.experiences?.find((ele, i) => i == id);
    setExpData({
      title: data?.title,
      company_name: data?.company_name,
      location: data?.location,
      start_date: data?.start_date,
      end_date: data?.end_date,
      description: data?.description,
      is_current: data?.is_current,
    });

    setIsEdit(id);
  };

  const [confirmDelete, setConfirmDelete] = useState(false);
  const [deleteId, setDeleteId] = useState();

  return (
    <div>
      {isPageLoading && <AppLoader />}

      <div className="edit-resume">
        <div className="container">
          <div className="row mt-30">
            <div className="col-lg-2 "></div>
            <div className="col-lg-6">
              <form>
                <div className="row">
                  <div className="col-12">
                    <h6>Work Experience</h6>
                  </div>
                </div>
                <div className="row mt-3">
                  <div className="col-sm-6">
                    <div className="mb-3">
                      <label for="exampleInputEmail1" className="form-label">
                        Job Title
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        name="title"
                        onBlur={() => checkValidation("title")}
                        value={expData?.title}
                        onChange={(e) => handleChange(e)}
                        maxLength={100}
                      />
                      {error?.title && (
                        <p className="text-danger">{error?.title}</p>
                      )}
                    </div>
                  </div>
                  <div className="col-sm-6">
                    <div className="mb-3">
                      <label for="exampleInputEmail1" className="form-label">
                        Company
                      </label>
                      {/* <input type="text" className="form-control" name="company" value={expData.company} onChange={(e)=> handleChange(e)} /> */}
                      <div className="autocomplete-wrap ">
                        <Autocomplete
                          className="form-control"
                          inputProps={{
                            placeholder: "",
                            onBlur: () => checkValidation("company"),
                          }}
                          maxLength={50}
                          value={expData?.company_name}
                          items={companyList}
                          getItemValue={(item) => item?.name}
                          // shouldItemRender={(item, value) =>
                          //   item.name.toLowerCase().indexOf(value.toLowerCase()) > -1
                          // }
                          renderMenu={(items, value) => (
                            <div
                              className="dropdown bg-white p-3"
                              style={{
                                height: "150px",
                                overflow: "auto",
                                zIndex: 999,
                                border: "1px solid #d1dfff",
                              }}
                            >
                              {items?.length === 0 ? (
                                `No matches for ${value}`
                              ) : items?.length >= 50 ? (
                                <p style={{ cursor: "pointer" }}>
                                  {items.slice(0, 50)}...
                                </p>
                              ) : (
                                <p style={{ cursor: "pointer" }}>{items}</p>
                              )}
                            </div>
                          )}
                          renderItem={(item, isHighlighted) => (
                            <div
                              className={`item mt-2 ${
                                isHighlighted ? "selected-item" : ""
                              }`}
                            >
                              <img
                                src={item?.icon || buld}
                                height={25}
                                width={25}
                                className="me-2 rounded-circle"
                              />
                              {item?.name}
                            </div>
                          )}
                          onChange={(obj, val) => {
                            getCompanyList(val);

                            setExpData({
                              ...expData,
                              company_name: val,
                              page_id: null,
                            });
                          }}
                          onSelect={(val, obj) => {
                            setExpData({
                              ...expData,
                              company_name: val,
                              page_id: obj?.id,
                            });
                          }}
                          wrapperStyle={{}}
                          // onClick={()=> checkValidation("company")}
                        />
                        {/* {error?.company && <p className='text-danger'>{error?.company}</p>} */}
                      </div>
                      {error?.company && (
                        <p className="text-danger">{error?.company}</p>
                      )}
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-12">
                    <div className="mb-3">
                      <label for="exampleInputEmail1" className="form-label">
                        Description
                      </label>
                      <textarea
                        maxLength={200}
                        className="form-control"
                        name="description"
                        onBlur={() => checkValidation("description")}
                        value={expData?.description}
                        onChange={(e) => handleChange(e)}
                      ></textarea>
                      {error?.description && (
                        <p className="text-danger">{error?.description}</p>
                      )}
                    </div>
                  </div>
                </div>
                <div className="col-sm-12">
                  <div className="mb-3">
                    <label for="exampleInputEmail1" className="form-label">
                      Location
                    </label>
                    {/* <input type="text" className="form-control" name="location" value={expData.location} onChange={(e)=> handleChange(e)} /> */}
                    <div className="autocomplete-wrap w-100">
                      <Autocomplete
                        inputProps={{
                          placeholder: "Location",
                          onBlur: () => checkValidation("location"),
                        }}
                        value={expData?.location}
                        items={locationList}
                        getItemValue={(item) => item?.name}
                        // shouldItemRender={(item, value) =>
                        //   item.name.toLowerCase().indexOf(value.toLowerCase()) > -1
                        // }
                        renderMenu={(items, value) => (
                          <div className="dropdown border border-1 p-2">
                            {!items?.length && value?.trim() !== ""
                              ? `No matches for ${value}`
                              : items}
                          </div>
                        )}
                        renderItem={(item, isHighlighted) => (
                          <div
                            className={`item mt-2 ${
                              isHighlighted ? "selected-item" : ""
                            }`}
                            style={{ cursor: "pointer" }}
                          >
                            {item?.name}
                          </div>
                        )}
                        onChange={(obj, val) => {
                          getLocation(val);
                          setExpData({
                            ...expData,
                            location: val,
                          });
                        }}
                        onSelect={(val, obj) => {
                          setLocationInput(val);
                          setExpData({
                            ...expData,
                            location: obj?.name,
                          });
                        }}
                        wrapperStyle={{}}
                      />
                    </div>
                    {error?.location && (
                      <p className="text-danger">{error?.location}</p>
                    )}
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-6 col-12">
                    <label htmlFor="" className="mb-2">
                      Start Date
                    </label>

                    <DatePickerCustom
                      value={
                        expData?.start_date ? new Date(expData?.start_date) : ""
                      }
                      onChange={(date) => {
                        date
                          ? setExpData({
                              ...expData,
                              start_date: moment(date).format("YYYY-MM-DD"),
                              end_date: "",
                            })
                          : setExpData({
                              ...expData,
                              start_date: "",
                              end_date: "",
                            });
                      }}
                      placeholder=""
                      onBlur={() => checkValidation("start_date")}
                      max={new Date()}
                    />
                    {error?.start_date && (
                      <p className="text-danger">{error?.start_date}</p>
                    )}
                  </div>

                  <div className="col-md-6 col-12">
                    <label htmlFor="" className="mb-2">
                      End Date
                    </label>

                    <DatePickerCustom
                      value={
                        expData?.end_date ? new Date(expData?.end_date) : ""
                      }
                      onChange={(date) => {
                        date
                          ? setExpData({
                              ...expData,
                              end_date: moment(date).format("YYYY-MM-DD"),
                            })
                          : setExpData({ ...expData, end_date: "" });
                      }}
                      placeholder=""
                      // onFocus={(e) => setErrorDate("")}
                      disabled={
                        expData?.start_date === "" ||
                        expData?.is_current === true
                          ? true
                          : false
                      }
                      min={
                        expData?.start_date ? new Date(expData?.start_date) : ""
                      }
                      max={new Date()}
                      onBlur={() => checkValidation("end_date")}
                    />
                    {error?.end_date && (
                      <p className="text-danger">{error?.end_date}</p>
                    )}
                  </div>
                </div>
                <div className="row">
                  <div className="col-sm-6 mt-2">
                    <div className="mb-3 form-check cv_check d-flex align-items-end">
                      <input
                        type="checkbox"
                        className="form-check-input"
                        id="exampleCheck1"
                        checked={expData?.is_current}
                        onChange={(e) => {
                          if (e.target.checked)
                            setError({ ...error, end_date: "" });
                          setExpData({
                            ...expData,
                            is_current: e.target.checked,
                            end_date: null,
                          });
                        }}
                      />
                      <label className="form-check-label" for="exampleCheck1">
                        I currently work here
                      </label>
                    </div>
                  </div>
                  <div className="col-sm-6 mt-3">
                    <div className="float-end">
                      <div
                        style={{ cursor: "pointer" }}
                        className="text-blue bg-transparent fw-bold"
                        onClick={handleSubmit}
                      >
                        {isEdit > -1 ? "Update" : "Add"}
                      </div>
                    </div>
                  </div>
                </div>
                {user?.experiences?.map((ele, i) => {
                  return (
                    <>
                      <div
                        className="row mt-2"
                        // style={
                        //   user?.experiences?.length > 1
                        //     ? { borderBottom: "1px solid #d2d2d2" }
                        //     : {}
                        // }
                      >
                        <div className="col-sm-6">
                          <div className="">
                            <span className="tick">
                              <img src={require("../../assets/img/tick.png")} />
                            </span>
                            <span className="ui ms-3">
                              {ele?.title?.length > 10
                                ? ele.title.slice(0, 8) + "..."
                                : ele?.title}{" "}
                              at{" "}
                              {ele?.company_name?.length > 10
                                ? ele?.company_name.slice(0, 8) + "..."
                                : ele?.company_name}
                            </span>
                          </div>
                        </div>
                        <div className="col-sm-6">
                          <div className="float-end">
                            <div className="d-flex gap-3">
                              <div
                                className="edit"
                                onClick={() => {
                                  handleEdit(i);
                                  setIsEdit(i);
                                }}
                              ></div>
                              <div
                                className="delete"
                                onClick={() => {
                                  setDeleteId(i);
                                  setConfirmDelete(true);
                                }}
                              ></div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </>
                  );
                })}
                <div className="text-center mx-4 mt-5">
                  <div className="" style={{ marginLeft: "25%" }}>
                    <button
                      className=" btn btn-light text-blue me-3"
                      onClick={() => setPageNo(1)}
                    >
                      Back
                    </button>
                    <button
                      className=" btn btn-blue"
                      onClick={() => setPageNo(3)}
                    >
                      Next
                    </button>
                  </div>
                </div>
              </form>
            </div>
            <div className="col-lg-4">
              <div className="resume ">
                <div className="container">
                  <div className="bg-white main-box">
                    <div className="row header ">
                      <div className="col-lg-12 ">
                        {/* {editCV == "classic" ? (
                          <ClassicTemplate
                            userData={user}
                            color={color?.classic}
                            skillBackground={skillBackground?.classic}
                            interestBackground={interestBackground?.classic}
                          />
                        ) : editCV == "functional" ? (
                          <FunctionalTemplate
                            userData={user}
                            color={color?.functional}
                            skillBackground={skillBackground?.functional}
                            interestBackground={interestBackground?.functional}
                          />
                        ) : editCV == "combined" ? (
                          <CombinedTemplate
                            userData={user}
                            color={color?.combined}
                            skillBackground={skillBackground?.combined}
                            interestBackground={interestBackground?.combined}
                          />
                        ) : (
                          <ModernTemplate
                            userData={user}
                            color={color?.modern}
                            skillBackground={skillBackground?.modern}
                            interestBackground={interestBackground?.modern}
                          />
                        )} */}

                        <div>
                          {isLoading ? (
                            <div className="position-relative">
                              <AppLoader />
                            </div>
                          ) : (
                            <div className="" style={{ position: "relative" }}>
                              <ThumbnailResumePreview url={cvTypes[editCV]} />
                              <button
                                style={{
                                  position: "absolute",
                                  zIndex: 999999,
                                  right: "10px",

                                  bottom: "10px",
                                  background: "#0432A3",
                                  color: "#fff",
                                  borderRadius: "20px",
                                  padding: "5px",
                                  fontSize: "12px",
                                }}
                                onClick={() => {
                                  setPreviewCV(editCV);
                                  setPreviewResumeModal(true);
                                }}
                              >
                                Preview
                              </button>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* <div className="bullets mt-30">
                <div className="dot active"></div>
                <div className="dot"></div>
              </div> */}
            </div>
          </div>
        </div>
      </div>
      {confirmDelete && (
        <DeleteModal
          text="Are you sure you want to delete experience"
          title="Delete Experience"
          modal={confirmDelete}
          toggle={() => setConfirmDelete(!confirmDelete)}
          deleteConfirm={() => handleDelete(deleteId)}
        />
      )}
    </div>
  );
};
