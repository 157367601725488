import { INITIALIZE_THE_LOADER, STOP_THE_LOADER, INITIALIZE_THE_PAGE_LOADER, STOP_THE_PAGE_LOADER, INIT_SETTING_PAGE_LOADER, STOP_SETTING_PAGE_LOADER} from "../types";

export const initLoad = () => (dispatch) => {
  dispatch({
    type: INITIALIZE_THE_LOADER,
    payload: true,
  });
};

export const stopLoad = () => (dispatch) => {
  dispatch({
    type: STOP_THE_LOADER,
    payload: false,
  });
};

export const initPageLoad = () => (dispatch) => {
  dispatch({
    type: INITIALIZE_THE_PAGE_LOADER,
    payload: true,
  });
};

export const stopPageLoad = () => (dispatch) => {
  dispatch({
    type: STOP_THE_PAGE_LOADER,
    payload: false,
  });
};

export const initSettingsLoad = () => (dispatch) => {
  dispatch({
    type: INIT_SETTING_PAGE_LOADER,
    payload: true,
  });
};

export const stopSettingsLoad = () => (dispatch) => {
  dispatch({
    type: STOP_SETTING_PAGE_LOADER,
    payload: false,
  });
};
