import {
  REGISTER_SUCCESS,
  REGISTER_FAIL,
  LOGIN_SUCCESS,
  LOGIN_FAIL,
  LOGOUT,
  SET_MESSAGE,
  INIT_SUCCESS,
  INIT_FAIL,
  UPDATE_USER_DATA,
  UPDATE_MEMBERSHIP_DATA,
} from "../types";

import { authApi } from "../../api/authAPI";
import { profileApi } from "../../api/profileAPI";

export const register = (signUpData) => (dispatch) => {
  console.log("signUpData", signUpData);
  return authApi.register(signUpData).then(
    (data) => {
      dispatch({
        type: REGISTER_SUCCESS,
        payload: { user: data },
      });

      dispatch({
        type: SET_MESSAGE,
        payload: data.message,
      });

      return Promise.resolve();
    },
    (error) => {
      console.log(error);
      dispatch({
        type: REGISTER_FAIL,
      });

      return Promise.reject(error);
    }
  );
};

export const login =
  (username, password, registration_type, rememberMe, fcm_token) =>
  (dispatch) => {
    return authApi
      .login(username, password, registration_type, rememberMe, fcm_token)
      .then(
        (data) => {
          if (!data.is2fa) {
            dispatch({
              type: LOGIN_SUCCESS,
              payload: { user: data },
            });
          }
          return Promise.resolve(data);
        },
        (error) => {
          dispatch({
            type: LOGIN_FAIL,
          });
          return Promise.reject(error);
        }
      );
  };

export const loadUser = (cb) => {
  return async (dispatch) => {
    let user = null;
    const accessToken = localStorage.getItem("accessToken");
    const isWebView = localStorage.getItem("isWebView");
    if (accessToken) {
      user = await authApi.getuserbytoken();
    }
    if (user) {
      dispatch({
        type: INIT_SUCCESS,
        payload: { user: user, isWebView },
      });
    } else {
      dispatch({
        type: INIT_FAIL,
      });

      dispatch({
        type: SET_MESSAGE,
        payload: "Token expired or Not found",
      });
    }
    cb(false);
  };
};

// export const loadUser = (cb) => {
//   return async (dispatch) => {
//     try {
//       const accessToken = localStorage.getItem("accessToken");
//       const isWebView = localStorage.getItem("isWebView");

//       if (!accessToken) {
//         dispatch({
//           type: INIT_FAIL,
//         });
//         dispatch({
//           type: SET_MESSAGE,
//           payload: "Token not found",
//         });
//         cb(false); // No token, fail immediately
//         return;
//       }

//       let user = null;
//       try {
//         user = await authApi.getuserbytoken();
//       } catch (error) {
//         console.error("Failed to fetch user:", error);
//         dispatch({
//           type: INIT_FAIL,
//         });
//         dispatch({
//           type: SET_MESSAGE,
//           payload: "Failed to fetch user data",
//         });
//         cb(false); // API call failed
//         return;
//       }

//       if (user) {
//         dispatch({
//           type: INIT_SUCCESS,
//           payload: { user, isWebView },
//         });
//         cb(true); // Success, user data loaded
//       } else {
//         dispatch({
//           type: INIT_FAIL,
//         });
//         dispatch({
//           type: SET_MESSAGE,
//           payload: "Token expired or not found",
//         });
//         cb(false); // Token invalid, fail
//       }
//     } catch (error) {
//       console.error("Unexpected error:", error);
//       dispatch({
//         type: INIT_FAIL,
//       });
//       dispatch({
//         type: SET_MESSAGE,
//         payload: "An unexpected error occurred",
//       });
//       cb(false); // Unexpected error
//     }
//   };
// };

// export const loadUser = (cb) => {
//   return async (dispatch) => {
//     let user = null;
//     const accessToken = localStorage.getItem("accessToken");
//     const isWebView = localStorage.getItem("isWebView");

//     if (accessToken) {
//       try {
//         user = await authApi.getuserbytoken();
//       } catch (error) {
//         console.error("Failed to fetch user:", error);
//       }
//     }

//     if (user) {
//       dispatch({
//         type: INIT_SUCCESS,
//         payload: { user: user, isWebView },
//       });
//       cb(true); // User loaded successfully
//     } else {
//       dispatch({
//         type: INIT_FAIL,
//       });

//       dispatch({
//         type: SET_MESSAGE,
//         payload: "Token expired or Not found",
//       });
//       cb(false); // User failed to load
//     }
//   };
// };

// export const loadUser = (cb) => {
//   return async (dispatch) => {
//     try {
//       let user = null;
//       const accessToken = localStorage.getItem("accessToken");
//       const isWebView = localStorage.getItem("isWebView") === "true"; // Parse isWebView

//       if (accessToken) {
//         try {
//           user = await authApi.getuserbytoken();
//         } catch (error) {
//           console.error("Failed to fetch user:", error);
//           dispatch({
//             type: INIT_FAIL,
//           });
//           dispatch({
//             type: SET_MESSAGE,
//             payload: "Failed to fetch user data",
//           });
//           cb(false);
//           return;
//         }
//       }

//       if (user) {
//         dispatch({
//           type: INIT_SUCCESS,
//           payload: { user, isWebView },
//         });
//         cb(true); // User loaded successfully
//       } else {
//         dispatch({
//           type: INIT_FAIL,
//         });
//         dispatch({
//           type: SET_MESSAGE,
//           payload: "Token expired or not found",
//         });
//         cb(false); // User failed to load
//       }
//     } catch (error) {
//       console.error("Unexpected error:", error);
//       dispatch({
//         type: INIT_FAIL,
//       });
//       dispatch({
//         type: SET_MESSAGE,
//         payload: "An unexpected error occurred",
//       });
//       cb(false); // Error scenario
//     }
//   };
// };

export const logout = () => (dispatch) => {
  return authApi.logout().then(
    (response) => {
      localStorage.removeItem("accessToken");
      dispatch({
        type: LOGOUT,
      });
      return Promise.resolve(response);
    },
    (error) => {
      dispatch({
        type: LOGOUT,
      });
      return Promise.reject();
    }
  );
};

export const upDateUserData = (data) => (dispatch) => {
  dispatch({
    type: UPDATE_USER_DATA,
    payload: data,
  });
};

export const validateOtp = (otp) => (dispatch) => {
  return authApi.validateOtp(otp).then(
    (data) => {
      if (!data.is2fa) {
        dispatch({
          type: LOGIN_SUCCESS,
          payload: { user: data },
        });
      }
      return Promise.resolve(data);
    },
    (error) => {
      dispatch({
        type: LOGIN_FAIL,
      });
      return Promise.reject(error);
    }
  );
};
export const resend2faOtp = () => (dispatch) => {
  return authApi
    .resendOtp2fa()
    .then((data) => {
      return Promise.resolve(data);
    })
    .catch((error) => {
      return Promise.reject(error);
    });
};

export const updateNextPlan =
  (userData, membershipData, token) => (dispatch) => {
    return profileApi.postUserDetails(userData, token).then(
      (response) => {
        dispatch({
          type: UPDATE_MEMBERSHIP_DATA,
          payload: membershipData,
        });
        return Promise.resolve(response);
      },
      (error) => {
        console.log(error);
        return Promise.reject(error);
      }
    );
  };

export const updateCurrentPlan =
  (userData, membershipData, token) => (dispatch) => {
    return profileApi.postUserDetails(userData, token).then(
      (response) => {
        dispatch({
          type: UPDATE_MEMBERSHIP_DATA,
          payload: membershipData,
        });
        return Promise.resolve(response);
      },
      (error) => {
        console.log(error);
        return Promise.reject(error);
      }
    );
  };
