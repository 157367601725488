import React from "react";
import placeholderUser from "../../../../assets/img/placeholder_user.jpg";
import { useNavigate } from "react-router-dom";
import moment from "moment";

function List(props) {
  const navigate = useNavigate();
  const { profileViewersList, sendConnectionHandle } = props;
  const mutualFriendsCount = props.profileViewersList?.shared_connection.length;

  return (
    <div className="p-20">
      <div className="row">
        <div className="col-lg-2 col-md-2 col-sm-2 mb-2">
          <div className="profile-img">
            <img
              src={
                profileViewersList?.User?.profile_img
                  ? profileViewersList?.User?.profile_img
                  : placeholderUser
              }
              className="image-fit"
              alt=""
              onClick={() =>
                navigate(`/profile/${profileViewersList?.user_id}`)
              }
            />
          </div>
        </div>
        <div className="col-lg-7 col-md-7 col-sm-7 mb-3 ">
          <div className="details ">
            <h2
              onClick={() =>
                navigate(`/profile/${profileViewersList?.user_id}`)
              }
              className="user-profile-name"
            >
              {profileViewersList?.User?.first_name}{" "}
              {profileViewersList?.User?.last_name}
            </h2>{" "}
            {profileViewersList?.experiences?.title &&
              profileViewersList?.experiences?.company_name && (
                <p
                  className="card-control-text-oneline"
                  style={{ width: "85%" }}
                >
                  {profileViewersList?.experiences?.title} at{" "}
                  {profileViewersList?.experiences?.company_name}
                </p>
              )}
            {profileViewersList?.updatedAt && (
              <p>
                Viewed {moment(profileViewersList?.updatedAt).fromNow(true)} ago
              </p>
            )}
            {mutualFriendsCount > 0 && (
              <p>{mutualFriendsCount} mutual connection</p>
            )}
          </div>
        </div>
        <div className="col-lg-3 col-md-3 col-sm-3  ">
          <div className="d-flex float-xl-end">
            {profileViewersList?.connectionStatus === "confirm" ? (
              <div className="btn btn-light listMessage_Button">
                {/* Message or Connect based on connection status */}
                <i className="msg"></i>Message
              </div>
            ) : profileViewersList?.connectionStatus === "pending" ? (
              <div className="btn btn-light listMessage_Button">
                <i className="conne"></i>Pending
              </div>
            ) : (
              <div
                className="btn btn-light listMessage_Button"
                onClick={() =>
                  sendConnectionHandle(profileViewersList?.user_id)
                }
              >
                <i className="conne"></i>Connect
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default List;
