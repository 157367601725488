import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import AddPageAdmin from "./AddPageAdminModal";
import PageAdminRoll from "./PageAdminRoll";
import placeholderUser from "../../../../assets/img/placeholder_user.jpg";
import add from "../../../../assets/img/addWhite.svg";
import DeleteModal from "../../../../core/components/modal/DeleteModal";

const PageAdminsModal = (props) => {
  const {
    modal,
    toggle,
    title,
    addPageAdminRole,
    pageID,
    getPageAdminDetails,
    deletePageAdminDetail,
    getPermissions,
  } = props;
  const [addPageAdminModal, setAddPageAdminModal] = useState(false);
  const [pageAdminRoll, setPageAdminRoll] = useState(false);
  const [roll, setRoll] = useState("");
  const [selected, setSelected] = useState("");
  const [selectedUserId, setSelectedUserId] = useState();
  const [isTransferPage, setIsTransferPage] = useState(false);
  const [closeModal, setCloseModal] = useState(false);
  const [deleteId, setDeleteId] = useState();
  const superAdmin = useSelector(
    (state) => state.pages?.singlePageData[0]?.Created_by
  );
  const pageAdmins = useSelector(
    (store) => store.pages?.pageAdminDetails?.data
  );

  useEffect(() => {
    getPageAdminDetails();
  }, []);

  const addAdmin = () => {
    const data = {
      user_id: selected.id || selectedUserId,
      page_id: pageID,
      type: roll,
    };
    addPageAdminRole(data);
  };

  const deleteAdmin = (userId) => {
    const deleteDetails = {
      user_id: userId,
      page_id: pageID,
    };
    deletePageAdminDetail(deleteDetails);
  };

  const editAdmin = (user, roll, id) => {
    setSelected(user);
    setSelectedUserId(id);
    setRoll(roll);
    setPageAdminRoll(true);
  };

  return (
    <div>
      <Modal
        isOpen={modal}
        size="lg"
        style={{ maxWidth: "1174px", maxHeight: "546px" }}
        className="networks-popup"
        centered
      >
        <ModalHeader toggle={toggle}>{title}</ModalHeader>
        <ModalBody>
          <div className="admin-popup">
            <div className="container">
              <div className="row mb-5 ">
                <div className="col-lg-6 d-flex align-items-center">
                  <p>
                    Only the super admin can transfer or delete the page. Admins
                    have rights to manage everything else. Content Managers can
                    edit and public content on your page. HR Managers can manage
                    job ads and the Talent Pool.
                  </p>
                </div>
                <div className="col-lg-6">
                  <div className="float-sm-end">
                    {getPermissions.transfer_page && (
                      <button
                        className="btn text-blue me-2"
                        onClick={() => {
                          setAddPageAdminModal(true);
                          setIsTransferPage(true);
                        }}
                      >
                        Transfer Page
                      </button>
                    )}
                    <button
                      className="btn btn-blue fs-6"
                      onClick={() => {
                        setAddPageAdminModal(true);
                        setIsTransferPage(false);
                      }}
                    >
                      <img src={add} alt="" className="followicon me-2" />
                      {"   Add Admin"}
                    </button>
                  </div>
                </div>
              </div>
              <div className="grey-bg p-0 bg-white add-scroll">
                <div className="row mb-4">
                  <div className="col-lg-1">
                    <div className="img">
                      <img
                        src={
                          superAdmin?.profile_img
                            ? superAdmin?.profile_img
                            : placeholderUser
                        }
                        className="image-fit"
                        alt=""
                      />
                    </div>
                  </div>
                  <div className="col-lg-5">
                    <div className="text">
                      <h6>
                        {superAdmin?.first_name + " " + superAdmin?.last_name}
                      </h6>
                      {superAdmin?.profile?.profile_headline && (
                        <p>{superAdmin?.profile?.profile_headline}</p>
                      )}
                    </div>
                  </div>
                  <div className="col-lg-3">
                    <div className="text">
                      <h6>Super Admin</h6>
                    </div>
                  </div>
                  <div className="col-lg-3"></div>
                </div>
                {pageAdmins &&
                  pageAdmins.map((data, index) => {
                    return (
                      <div className="row mb-4" key={index}>
                        <div className="col-lg-1">
                          <div className="img">
                            <img
                              src={
                                data?.User?.profile_img
                                  ? data?.User?.profile_img
                                  : placeholderUser
                              }
                              className="image-fit"
                              alt="profile image"
                            />
                          </div>
                        </div>
                        <div className="col-lg-5">
                          <div className="text">
                            <h6>
                              {data?.User?.first_name +
                                " " +
                                data?.User?.last_name}
                            </h6>
                            {data?.User?.profile?.profile_headline && (
                              <p>{data?.User?.profile?.profile_headline}</p>
                            )}
                          </div>
                        </div>
                        <div className="col-lg-2">
                          <div className="text">
                            <h6>{data?.type}</h6>
                          </div>
                        </div>
                        <div className="col-lg-3">
                          <div className="float-end d-flex gap-4 ">
                            <div
                              className="edit"
                              onClick={() =>
                                editAdmin(data.User, data.type, data.user_id)
                              }
                            ></div>
                            <div
                              className="delete"
                              onClick={() => {
                                setCloseModal(!closeModal);
                                setDeleteId(data.user_id);
                              }}
                            ></div>
                          </div>
                        </div>
                      </div>
                    );
                  })}
              </div>
            </div>
          </div>
        </ModalBody>
      </Modal>
      {addPageAdminModal && (
        <AddPageAdmin
          modal={addPageAdminModal}
          toggle={() => setAddPageAdminModal(!addPageAdminModal)}
          title={isTransferPage ? "Transfer Page" : "Add Page Admin"}
          isTransferPage={isTransferPage}
          pageID={pageID}
          setAddPageAdminModal={setAddPageAdminModal}
          setRoll={setRoll}
          selected={selected}
          setSelected={setSelected}
          addAdmin={addAdmin}
          setPageAdminRoll={setPageAdminRoll}
          pageAdminRoll={pageAdminRoll}
        />
      )}

      {pageAdminRoll && (
        <PageAdminRoll
          modal={pageAdminRoll}
          toggle={() => setPageAdminRoll(!pageAdminRoll)}
          title="Add Page Admin"
          selected={selected}
          setAddPageAdminModal={setAddPageAdminModal}
          roll={roll}
          setRoll={setRoll}
          setSelected={setSelected}
          addAdmin={addAdmin}
        />
      )}

      {closeModal && (
        <DeleteModal
          modal={closeModal}
          toggle={() => setCloseModal(!closeModal)}
          title="Delete Confirmation"
          deleteConfirm={() => {
            deleteAdmin(deleteId);
          }}
          text="Are you want to sure to delete the role?"
        />
      )}
    </div>
  );
};

export default PageAdminsModal;
