import {
  GET_CONTENT_LANGUAGE_LIST,
  GET_LANGUAGE_LIST,
  GET_SETTING_DATA,
  UPDATE_SETTING_DATA,
  GET_SECONDARY_EMAIL,
  GET_SESSION_LIST,
  UPDATE_SETTING_DATA_BADGES
} from "../types";

const initialState = [];

export default function (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case GET_SETTING_DATA:
      const { settingData } = action.payload;
      console.log("action.payload",action.payload);
      return {
        ...state,
        settingData: action.payload,
      };

    case UPDATE_SETTING_DATA:
      return {
        ...state,
        settingData: { ...state.settingData, ...action.payload },
      };

      case UPDATE_SETTING_DATA_BADGES:
        return{
          ...state,
          data: state.settingData.data.map((item, index) => {
            if (index === 11) {
                return {
                    ...item,
                    settings: item.settings.map((setting, settingIndex) => {
                        if (settingIndex === 5) {
                            return {
                                ...setting,
                                value: action.payload.setting[0].value
                            };
                        }
                        return setting;
                    })
                };
            }
            return item;
        })
        }

    case GET_SECONDARY_EMAIL:
      return {
        ...state,
        secondaryEmailList : action.payload,
      };

      case GET_SESSION_LIST:
      return {
        ...state,
        sessionList : action.payload,
      };

    case GET_LANGUAGE_LIST:
      const { LanguageListData } = action.payload;
      return {
        ...state,
        LanguageListData: action.payload,
      };

    case GET_CONTENT_LANGUAGE_LIST:
      return {
        ...state,
        ContentLanguageList: action.payload,
      };

    default:
      return state;
  }
}
