import { landingApi } from "../../api/landingApi";
import { FOOTER_FIRST, FOOTER_SECOND, FOOTER_THIRD, GET_LANDING_DATA } from "../types";

export const getLandingPageData = ( query) => (dispatch) => {
    return landingApi.getLandingData(query).then(
        (res) => {
          if(query.title==="footer-1"){
            dispatch({
              type:  FOOTER_FIRST,
              payload: res.data,
            });
            return Promise.resolve(res.data);
          }
          else  if(query.title==="footer-2"){
            dispatch({
              type:  FOOTER_SECOND,
              payload: res.data,
            });
            return Promise.resolve(res.data);
          }
          else  if(query.title==="footer-3"){
            dispatch({
              type:  FOOTER_THIRD,
              payload: res.data,
            });
            return Promise.resolve(res.data);
          }
          dispatch({
            type:  GET_LANDING_DATA,
            payload: res.data,
          });
          return Promise.resolve(res.data);
        
        },
        (error) => {
          console.log(error);
          return Promise.reject(error);
        }
      );
  };