import React, { useEffect, useState, useRef } from "react";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import { useDispatch, useSelector } from "react-redux";
import dmoj from "../../../assets/img/emojee.svg";
import VisibilityModal from "../../../routes/dashboard/components/Visibility/VisibilityModal";
import pub from "../../../assets/img/public.svg";
import people from "../../../assets/img/people.svg";
import union from "../../../assets/img/Union.svg";
import placeholderUser from "../../../assets/img/placeholder_user.jpg";
import "./sharePost.scss";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import { shareContent } from "../../../store/share/shareActions";
import { createArticle } from "../../../store/articles/articleActions";
import { createNewsletter } from "../../../store/newsletter/newsletterActions";
import { mergeShareData } from "../../../store/dashboard/dashboardActions";
import EmojiPicker from "emoji-picker-react";
import TextInput from "react-autocomplete-input";
import { getHashTagList } from "../../../store/master/masterActions";
import { createEvent } from "../../../store/events/eventActions";
import { getPageDetails } from "../../../store/dashboard/dashboardActions";
import { getSuggestedGroups } from "../../../store/groups/groupsActions";
import { toast } from "react-toastify";
import TextBoxCustom from "./components/TextBoxCustom";
import SelectGroup from "../../../routes/dashboard/components/Visibility/SelectGroup";
import ImageCarousel from "../../../routes/dashboard/components/ImageCarousel";
import PublishAsModal from "../../../routes/dashboard/components/PublishAsModal";
import profileadd from "../../../assets/img/fluent_people.svg";
import {
  GetHashtagText,
  GetTime,
  createLink,
  getReadingTime,
} from "../Utility/Utility";

const ShareModal = (props) => {
  const {
    modal,
    toggle,
    title,
    shareData,
    type,
    source,
    toggleCase,
    atriclePageName,
    createdAt,
    pageID,
    toggleSuccessful,
    pageIcon,
    notPublishAs,
    isSinglePost,
  } = props;

  const { user } = useSelector((state) => state.auth);
  const { getPageDetailData } = useSelector((state) => state.dashboard);
  const { joined_groups } = useSelector((state) => state.groups);
  const myRefname = useRef(null);
  console.log(shareData);
  const [visibiltyModal, setVisibiltyModal] = useState(false);
  const [selectGroupModal, setSelectGroupModal] = useState(false);
  const [groupName, setGroupName] = useState("");
  const [groupId, setGroupId] = useState();
  const [showEmojiBox, setShowEmojiBox] = useState(false);
  const [pageName, setPageName] = useState(
    shareData?.as_a_page ? shareData?.as_a_page.name : ""
  );
  const [hashTagData, setHashTagData] = useState([]);
  const [publishUserId, setPublishUserId] = useState(
    shareData?.as_a_page ? shareData?.as_a_page.id : user?.data?.id
  );
  const [publishAsModal, setPublishAsModal] = useState(false);
  const [typeShare, setTypeShare] = useState("");
  const [sharedBy, setSharedBy] = useState("");
  const [hashtagList, setHashtagList] = useState([]);
  const [privacyId, setPrivacyId] = useState(1);
  const [visibileAs, setVisibileAs] = useState("Public");
  const [visibleIcon, setVisibleIcon] = useState(pub);
  const [profileIcon, setProfileIcon] = useState(
    shareData?.as_a_page ? shareData?.as_a_page.icon : user?.data?.profile_img
  );
  const [sharedByType, setSharedByType] = useState("user");
  const [visiblePersons, setVisiblePersons] = useState([]);

  const [postData, setPostData] = useState({
    type: type,
    shared_id: shareData?.id,
    description: "",
    privacy_id: privacyId,
    shared_by: publishUserId,
    hashtags: [],
    shared_by_type: shareData?.as_a_page
      ? "page"
      : createdAt === "page"
      ? "page"
      : sharedByType,
  });

  useEffect(() => {
    setPostData({ ...postData, shared_by_type: sharedByType });
  }, [sharedByType]);

  const [nPMemeber, setNPMember] = useState(true);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    if (createdAt === "page") {
      setPageName(atriclePageName);
      setProfileIcon(pageIcon);
      setPublishUserId(parseInt(pageID));
      setSharedByType("page");
      //postData.shared_by_type = "page";
      // postData.shared_by = parseInt(pageID);
      setPostData({ ...postData, shared_by_type: "page" });
    }
  }, []);
  useEffect(() => {
    console.log("inuseEffectpost", postData);
    console.log("inuseEffect CreatedAt", createdAt);
  }, [postData]);

  useEffect(() => {
    let token = window.localStorage.getItem("accessToken");
    dispatch(getPageDetails(token));
    dispatch(getSuggestedGroups("joined"));
  }, []);

  useEffect(() => {
    if (groupName) {
      setPageName("");
      setSharedByType("group");
      setPublishUserId(groupId);
    }
  }, [groupName]);
  useEffect(() => {
    setPostData({
      ...postData,
      shared_by: publishUserId,
      shared_by_type: sharedByType,
    });
  }, [publishUserId]);
  const handleRequestOptions = (keyword) => {
    dispatch(getHashTagList({ search: keyword }))
      .then((res) => {
        setHashtagList(res?.data?.map((a) => a.name));
      })
      .catch(() => {
        setHashtagList([]);
      });
  };

  const sharePostHandle = () => {
    let token = localStorage.getItem("accessToken");
    if (source === "home" && type === "article") {
      dispatch(createArticle(shareData, token))
        .then((articleData) => {
          postData.shared_id = articleData.id;
          postData.shared_by_type =
            createdAt === "page" ? "page" : postData.shared_by_type;
          dispatch(shareContent(postData, token)).then((resShareData) => {
            dispatch(mergeShareData(resShareData));
            toast.success("Shared Successfully!.", {
              position: toast.POSITION.BOTTOM_LEFT,
            });
            toggleCase();
            toggle();
          });
          toggle();
        })
        .catch((e) => {});
    } else if (source === "home" && type === "event") {
      dispatch(createEvent(shareData, token))
        .then((eventData) => {
          postData.shared_id = eventData.id;
          postData.shared_by_type =
            createdAt === "page" ? "page" : postData.shared_by_type;
          dispatch(shareContent(postData, token)).then((resShareData) => {
            dispatch(mergeShareData(resShareData));
            toast.success("Shared Successfully!.", {
              position: toast.POSITION.BOTTOM_LEFT,
            });
            toggleCase();
            toggle();
          });
          toggle();
        })
        .catch((e) => {});
    } else if (source === "home" && type === "newsletter") {
      console.log(shareData, "shareDatat");
      dispatch(createNewsletter(shareData, token))
        .then((newsletterData) => {
          postData.shared_id = newsletterData.id;
          postData.shared_by_type =
            createdAt === "page" ? "page" : postData.shared_by_type;
          dispatch(shareContent(postData, token)).then((resShareData) => {
            dispatch(mergeShareData(resShareData));
            toast.success("Shared Successfully!.", {
              position: toast.POSITION.BOTTOM_LEFT,
            });
            toggleCase();
            toggle();
          });
          toggle();
        })
        .catch((e) => {});
    } else if (source === "dashboard" && type === "shared_post") {
      dispatch(shareContent(postData, token))
        .then((resShareData) => {
          dispatch(mergeShareData(resShareData));
          toast.success("Reposted Successfully! ", {
            position: toast.POSITION.BOTTOM_LEFT,
          });
          toggle();
          if (isSinglePost) navigate("/dashboard");
        })
        .catch((e) => {
          console.log("error", e);
          alert("Something went wrong!!");
        });
    } else
      dispatch(shareContent(postData, token))
        .then((resShareData) => {
          toast.success("Shared Successfully! ", {
            position: toast.POSITION.BOTTOM_LEFT,
          });
          toggle();
          source === "eventDetails" ||
          source === "newsletterDetails" ||
          source === "articleDetails" ? (
            <></>
          ) : (
            toggleSuccessful()
          );
        })
        .catch((e) => {
          alert("Something went wrong!!");
        });
  };

  const addEmoji = (e) => {
    let sym = e.unified.split("-");
    let codesArray = [];
    sym.forEach((el) => codesArray.push("0x" + el));
    let emoji = String.fromCodePoint(...codesArray);
    setPostData({ ...postData, description: postData.description + emoji });
  };

  useEffect(() => {
    let hashtags = postData?.description
      ?.split(" ")
      ?.filter((v) => v.startsWith("#"));
    setHashTagData(hashtags);
    setPostData({ ...postData, hashtags: hashTagData?.map((s) => s.slice(1)) });
  }, [postData?.description]);

  const handleClick = () => {
    setPostData({ ...postData, description: postData.description + " #" });
    myRefname.current.refInput.current.focus();
  };

  const setVisibility = () => {
    if (privacyId == 1) {
      setVisibileAs("Public");
      setVisibleIcon(pub);
      setPostData({
        ...postData,
        privacy_id: 1,
      });
    }
    if (privacyId == 2) {
      setVisibileAs("Connections Only");
      setVisibleIcon(union);
      setPostData({
        ...postData,
        privacy_id: 2,
      });
      setSharedByType("user");
    }
    if (privacyId == 3) {
      setVisibileAs(groupName);
      setVisibleIcon(people);
      setPostData({
        ...postData,
        privacy_id: 3,
      });
      setSharedByType("group");
    }
    if (privacyId == 4) {
      setVisibileAs("Specific Persons");
      setVisibleIcon(profileadd);
      let visible = [user?.data?.id];
      for (let i = 0; i < visiblePersons?.length; i++) {
        visible.push(visiblePersons[i]);
      }
      setPostData({
        ...postData,
        privacy_id: 4,
        visibility_ids: visible,
      });
      setSharedByType("user");
    }
  };

  useEffect(() => {
    setVisibility();
  }, [privacyId, groupName]);

  function privacy_type(privacy_id) {
    if (privacy_id === 1) return " Public";
    if (privacy_id === 2) return " Connection";
    if (privacy_id === 3) return " Group";
    if (privacy_id === 4) return " Private";
  }

  const showImageData = (data, id) => {
    return (
      <>
        <div
          key={id}
          style={{ width: "100%", position: "relative" }}
          className={"mb-20 box-img curserPointer"}
        >
          {data?.document_type === "image" && (
            <img
              id={id}
              src={data.document_url}
              alt="thumbnail"
              width="100%"
              height="100%"
              className="main-img"
            />
          )}
          {data?.document_type === "video" && (
            <>
              <video
                controls={true}
                controlsList="nodownload"
                width="100%"
                height="100%"
                className="main-img"
              >
                <source src={`${data.document_url}#t=0.01`} />
              </video>
            </>
          )}
          {data?.document_type === "attachment" && (
            <div className="fs-6">
              <iframe
                title="-"
                width="100%"
                height="350px"
                sandbox="allow-scripts allow-same-origin"
                src={`https://docs.google.com/gview?url=${data?.document_url}&embedded=true`}
              ></iframe>
            </div>
          )}
        </div>
      </>
    );
  };

  return (
    <div>
      <Modal isOpen={modal} className="modal-lg create-post-modal">
        <div className="share-user-post">
          <div>
            <ModalHeader toggle={toggle}>{title}</ModalHeader>
            <ModalBody>
              <div className="">
                <div className="row mb-20 align-items-center m-0">
                  <div className="col-lg-2 col-2">
                    <div className="float-start">
                      <div className="pro-img">
                        <img
                          src={profileIcon ? profileIcon : placeholderUser}
                          alt=""
                          className="image-fit"
                        />
                      </div>
                    </div>
                  </div>
                  <div
                    className="col-lg-3 col-3"
                    style={{ paddingLeft: "0px" }}
                  >
                    <div
                      className={
                        "name form-select" +
                        (!notPublishAs ? "" : "no-dropdown")
                      }
                      onClick={() => {
                        !notPublishAs
                          ? setPublishAsModal(true)
                          : setPublishAsModal(false);
                      }}
                    >
                      <span>
                        {pageName
                          ? pageName
                          : `${user?.data?.first_name} ${" "} ${
                              user?.data?.last_name
                            }`}
                      </span>
                    </div>
                  </div>
                  <div className="col-lg-3 col-3  ">
                    <div
                      className="public form-select"
                      onClick={() => setVisibiltyModal(!visibiltyModal)}
                    >
                      <span>
                        <img src={visibleIcon} alt="/" className="me-1" />
                        {visibileAs}
                      </span>
                    </div>
                  </div>
                  <div className="col-lg-4 col-4 ">
                    <div className="float-end"></div>
                  </div>
                </div>
                <div></div>
                <div className="row m-0">
                  {/* <textarea className="form-control border-none" 
                  placeholder="What do you want to talk about?" 
                  maxLength="120"
                  value={postData?.description}
                  onChange={(e) =>
                    setPostData({ ...postData, description: e.target.value })
                  }
                  /> */}
                  <TextInput
                    ref={myRefname}
                    trigger={["#"]}
                    options={{ "#": hashtagList }}
                    className="form-control border-none"
                    rows={5}
                    value={postData?.description}
                    placeholder="What do you want to talk about?"
                    onRequestOptions={handleRequestOptions}
                    onChange={(e, val) => {
                      if (!val) {
                        setPostData({ ...postData, description: e });
                        //    setPostDetails({ ...postDetails, description: e });
                      }
                    }}
                    onSelect={(val, obj) => {
                      setPostData({ ...postData, description: val });
                      // setPostDetails({ ...postDetails, description: val });
                    }}
                  />
                  {/* <div className="rich-editor-share">
                    <TextBoxCustom 
                    postData={postData}
                    setPostData={setPostData}/>
                    </div> */}
                  {type === "event" ? (
                    <div className="box mt-20">
                      <div className="row">
                        <div className="col-lg-12 p-0">
                          <div className="boxe-img">
                            <img
                              src={shareData?.media ? shareData?.media : null}
                              className="img-fit"
                              alt=""
                            />
                          </div>
                        </div>
                      </div>
                      <div className="row py-4">
                        <div className="col-sm-6">
                          <div className="text">
                            <h6>{shareData?.title}</h6>
                            <p className="my-2">
                              {moment
                                .utc(shareData?.event_time)
                                .format("dddd MMM DD")}
                              ,{" "}
                              {moment
                                .utc(shareData?.event_time)
                                .format("hh:mm A (UTC 5.30)")}
                            </p>
                            <span>
                              Event created by
                              {createdAt === "page"
                                ? " " + pageName
                                : " " +
                                  shareData?.User?.first_name +
                                  " " +
                                  shareData?.User?.last_name}
                            </span>
                            <button className="btn btn-blue mt-2">
                              {shareData?.event_type === "virtual"
                                ? "Virtual"
                                : "Physical"}
                            </button>
                          </div>
                        </div>
                        <div className="col-sm-6 mt-2">
                          <div className="float-sm-end">
                            <button
                              className="btn btn-light"
                              onClick={() =>
                                shareData?.id
                                  ? navigate(`/eventdetail/${shareData?.id}`)
                                  : ""
                              }
                            >
                              View Event
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : type === "article" || type === "newsletter" ? (
                    <div className="box mt-20">
                      <div className="row">
                        <div className="col-lg-12 p-0">
                          <div className="boxe-img">
                            <img
                              src={shareData?.media ? shareData?.media : null}
                              className="img-fit"
                              alt=""
                            />
                          </div>
                        </div>
                      </div>
                      <div className="row py-4">
                        <div className="col-sm-9">
                          <div className="text">
                            <h6>{shareData?.title}</h6>
                            <span>
                              {getReadingTime(shareData?.content) < 1
                                ? "Less then 1 min"
                                : getReadingTime(shareData?.content) +
                                  " min"}{" "}
                              to read
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : type === "shared_post" ? (
                    <div className="shareRepost">
                      <div className="main-Post-div ">
                        <div
                          className="prof-img"
                          onClick={() =>
                            navigate(`/profile/${shareData?.user_id}`)
                          }
                        >
                          <img
                            src={
                              shareData?.Page?.icon
                                ? shareData?.Page?.icon
                                : shareData?.User?.profile_img
                                ? shareData?.User?.profile_img
                                : placeholderUser
                            }
                            alt=""
                            className="img-fluid"
                            style={{ width: "40px" }}
                          />
                        </div>
                        <div className="details">
                          {shareData?.Page ? (
                            <>
                              <h4
                                className="userNameTextWrap"
                                onClick={() =>
                                  navigate(
                                    `/pageadminview/${shareData?.Page?.id}`
                                  )
                                }
                              >
                                {shareData?.Page?.name}
                              </h4>
                              <p>{shareData?.Page?.industry}</p>
                            </>
                          ) : (
                            <>
                              <h4
                                className="userNameTextWrap"
                                onClick={() =>
                                  navigate(`/profile/${shareData?.user_id}`)
                                }
                              >
                                {shareData?.User?.first_name +
                                  " " +
                                  shareData?.User?.last_name}
                              </h4>
                              {shareData?.User?.profile?.profile_headline ? (
                                <p className=" card-control-text-twoline maxWid">
                                  {shareData?.User?.profile?.profile_headline}
                                </p>
                              ) : (
                                ""
                              )}
                            </>
                          )}
                          <p>
                            <span>{GetTime(shareData?.updatedAt)}</span>
                            <span className="ml-5">&#x2022;</span>
                            <span>
                              <>{privacy_type(shareData?.privacy_id)}</>
                            </span>
                          </p>
                        </div>
                        {/* <div className="varify">
                                  <div className="checkMark"></div>
                                </div>
                                <div className="hiring">
                                  <span>Hiring</span>
                                </div> */}
                      </div>
                      <div
                        id="descriptionOverFlow"
                        className="text form-control border-none"
                        style={{ padding: "0px" }}
                      >
                        <span className="descriptionOverFlowSpan">
                          {shareData?.description
                            ? GetHashtagText(shareData?.description)
                            : ""}
                          {shareData?.type === "article" ||
                          shareData?.type === "newsletter" ? (
                            <div className="share-article-post">
                              <div className="box mt-20">
                                <div className="row">
                                  <div className="col-lg-12 p-0">
                                    <div className="boxe-img">
                                      <img
                                        src={shareData?.SharedDetails?.media}
                                        className="img-fit"
                                        alt=""
                                      />
                                    </div>
                                  </div>
                                </div>
                                <div className="row py-4">
                                  <div className="col-sm-9">
                                    <div className="text">
                                      {shareData?.type === "article" ? (
                                        <h6
                                          onClick={() =>
                                            navigate(
                                              `/articledetail/${shareData?.SharedDetails?.id}`
                                            )
                                          }
                                        >
                                          {shareData?.SharedDetails?.title}
                                        </h6>
                                      ) : (
                                        <h6
                                          onClick={() =>
                                            navigate(
                                              `/newsletterdetail/${shareData?.SharedDetails?.id}`
                                            )
                                          }
                                        >
                                          {shareData?.SharedDetails?.title}
                                        </h6>
                                      )}
                                      <span>
                                        {getReadingTime(
                                          shareData?.SharedDetails?.content
                                        ) < 1
                                          ? "Less then 1 min"
                                          : getReadingTime(
                                              shareData?.SharedDetails?.content
                                            ) + " min"}{" "}
                                        to read
                                      </span>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          ) : (
                            ""
                          )}
                          {shareData?.type === "event" ? (
                            <div className="share-event-post">
                              <div className="box mt-20">
                                <div className="row">
                                  <div className="col-lg-12 p-0">
                                    <div className="boxe-img">
                                      <img
                                        src={shareData?.SharedDetails?.media}
                                        className="img-fit"
                                        alt=""
                                      />
                                    </div>
                                  </div>
                                </div>
                                <div className="row py-4">
                                  <div className="col-sm-8">
                                    <div className="text">
                                      <h6>{shareData?.SharedDetails?.title}</h6>
                                      <p className="my-2">
                                        {moment
                                          .utc(
                                            shareData?.SharedDetails?.event_time
                                          )
                                          .format("dddd MMM DD")}
                                        ,{" "}
                                        {moment
                                          .utc(
                                            shareData?.SharedDetails?.event_time
                                          )
                                          .format("hh:mm A (UTC 5.30)")}
                                      </p>
                                      <span>
                                        Event created by{" "}
                                        {
                                          shareData?.SharedDetails?.User
                                            ?.first_name
                                        }{" "}
                                        {
                                          shareData?.SharedDetails?.User
                                            ?.last_name
                                        }
                                      </span>
                                      <button className="btn btn-blue mt-2">
                                        {shareData?.SharedDetails
                                          ?.event_type === "virtual"
                                          ? "Virtual"
                                          : "Physical"}
                                      </button>
                                    </div>
                                  </div>
                                  <div className="col-sm-4 mt-2">
                                    <div className="float-sm-end">
                                      <button
                                        className="btn btn-light"
                                        onClick={() =>
                                          navigate(
                                            `/eventdetail/${shareData?.SharedDetails?.id}`
                                          )
                                        }
                                      >
                                        View Event
                                      </button>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          ) : (
                            ""
                          )}
                          {shareData?.Celebration
                            ? createLink(
                                shareData?.Celebration?.celebration_tag
                              )
                            : ""}
                          {shareData?.Feed_Documents && (
                            <div className="row showcarousalimg position-relative">
                              <div
                                // onClick={() => navigatePostPageView(item)}
                                className={
                                  shareData?.Feed_Documents?.length === 2
                                    ? "col-lg-6 imagestwo"
                                    : "px-0"
                                }
                              >
                                {shareData?.Feed_Documents?.length >= 1 &&
                                  showImageData(
                                    shareData?.Feed_Documents[0],
                                    "Image0"
                                  )}
                                {shareData?.Feed_Documents.length === 2 &&
                                  showImageData(
                                    shareData?.Feed_Documents[1],
                                    "Image1"
                                  )}
                              </div>
                              {shareData?.Feed_Documents?.length >= 3 && (
                                <ImageCarousel
                                  images={shareData?.Feed_Documents}
                                  //  changeImage={changeImage}
                                  fromDashboard={true}
                                />
                              )}
                            </div>
                          )}
                        </span>
                      </div>
                    </div>
                  ) : (
                    ""
                  )}
                </div>
                <div className="row ">
                  <div className="col-12 col-lg-6 pt-4">
                    <div className="d-flex flex-wrap gap-1">
                      <div className="emoj">
                        <img
                          src={dmoj}
                          alt=""
                          onClick={() => setShowEmojiBox(!showEmojiBox)}
                        />
                        <div style={{ position: "absolute" }}>
                          {showEmojiBox ? (
                            <EmojiPicker onEmojiClick={addEmoji} />
                          ) : (
                            <></>
                          )}
                        </div>
                      </div>
                      {/* <div className="emoj">
                        <img
                          src={bx}
                          alt=""
                          onClick={() => {
                            try {
                              document.getElementById("chosefile1").click();
                            } catch (error) {
                              console.log(error);
                            }
                          }}
                        />
                      </div>
                      <div className="emoj">
                        <img
                          src={bj}
                          alt=""
                        />
                      </div>
                      <div className="emoj">
                        <img
                          src={paper}
                          alt=""
                        />
                      </div>
                      <div className="emoj">
                        <img
                          src={party}
                          alt=""
                        />
                      </div>
                      <div className="emoj">
                        <img
                          src={poll}
                          alt=""
                        />
                      </div> */}
                      <div className="add btn-light" onClick={handleClick}>
                        Add #
                      </div>
                    </div>
                  </div>
                  {nPMemeber === true ? (
                    <div className="col-12 col-lg-6 pt-4">
                      {/* <div className="float-xl-end">
                        <div className="d-flex position-relative">
                          <span className="post">Post on Twitter:</span>
                          <div className="input">
                            <div className="twitter">
                              <input type="checkbox" />
                              <div className="icon"></div>
                            </div>
                          </div>
                        </div>
                      </div> */}
                    </div>
                  ) : (
                    <div className="col-12 col-lg-6">
                      <div className="float-xl-end mb-20 ">
                        <div className="d-flex position-relative">
                          <div className="add btn-light">
                            Share on Social Media
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </ModalBody>

            <div className="p-20">
              <div className="btn btn-blue d-block" onClick={sharePostHandle}>
                Share Post
              </div>
            </div>
          </div>

          {visibiltyModal && (
            <VisibilityModal
              modal={visibiltyModal}
              toggle={() => setVisibiltyModal(!visibiltyModal)}
              title="Who can see your post?"
              privacyId={privacyId}
              setPrivacyId={setPrivacyId}
              joined_groups={joined_groups}
              setSelectGroupModal={setSelectGroupModal}
              setVisibiltyModal={setVisibiltyModal}
              setVisiblePersons={setVisiblePersons}
            />
          )}
          {selectGroupModal && (
            <SelectGroup
              modal={selectGroupModal}
              toggle={() => setSelectGroupModal(!selectGroupModal)}
              setPrivacyId={setPrivacyId}
              title="Who can see your post?"
              setGroupName={setGroupName}
              groupId={groupId}
              setGroupId={setGroupId}
              joined_groups={joined_groups}
              setVisibiltyModal={setVisibiltyModal}
            />
          )}
          {publishAsModal && (
            <PublishAsModal
              modal={publishAsModal}
              toggle={() => setPublishAsModal(!publishAsModal)}
              title="Publish as"
              atriclePageName={atriclePageName}
              publishUserId={publishUserId}
              setPublishUserId={setPublishUserId}
              setPageName={setPageName}
              setProfileImg={setProfileIcon}
              sharedByType={sharedByType}
              createdAt={createdAt}
              setSharedByType={setSharedByType}
              getPageDetailData={getPageDetailData}
              pageID={pageID}
            />
          )}
        </div>
      </Modal>
    </div>
  );
};

export default ShareModal;
