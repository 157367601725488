import { exploreAPI } from "../../api/exploreAPI";

import {
  EXPLORE_SUCCESS,
  EXPLORE_FAIL,
  EXPLORE_DETAILS_SUCCESS,
  EXPLORE_DETAILS_FAIL,
} from "../types";

export const getExploreData = (data) => (dispatch) => {
  return exploreAPI.explore(data).then(
    (data) => {
      dispatch({
        type: EXPLORE_SUCCESS,
        payload: { user: data },
      });

      return Promise.resolve(data);
    },
    (error) => {
      console.log(error);
      dispatch({
        type: EXPLORE_FAIL,
      });
      return Promise.reject(error);
    }
  );
};

export const getExploreDetailsData = (id) => (dispatch) => {
  return exploreAPI.exploreDetails(id).then(
    (data) => {
      // console.log(data);
      dispatch({
        type: EXPLORE_DETAILS_SUCCESS,
        payload: data,
      });

      return Promise.resolve(data);
    },
    (error) => {
      console.log(error);
      dispatch({
        type: EXPLORE_DETAILS_FAIL,
      });
      return Promise.reject(error);
    }
  );
};
