import axios from "axios";
import { logout } from "../store/auth/authActions";
//const accessToken = localStorage.getItem('accessToken');
export const call = async (method, url, params = {}, data = {}, token) => {
  data = { ...data };

  const api = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
  });

  // Add a request interceptor to attach the authorization token
  // api.interceptors.request.use(
  //   (config) => {
  //     const token = localStorage.getItem("accessToken");
  //     if (token) {
  //       config.headers.Authorization = `Bearer ${token}`;
  //     }
  //     return config;
  //   },
  //   (error) => {
  //     return Promise.reject(error);
  //   }
  // );

  // Add a response interceptor to handle 401 and 403 errors
  let currentUrl;
  api.interceptors.request.use(
    (config) => {
      currentUrl = config.url;
      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );

  api.interceptors.response.use(
    (response) => {
      return response;
    },
    (error) => {
      const { response } = error;
      if (
        response &&
        (response.status === 401 || response.status === 403) &&
        currentUrl &&
        currentUrl != "api/v1/auth/validate-2fa" &&
        currentUrl != "api/v1/portal/detail-by-token"
      ) {
        // Handle unauthorized or forbidden error here
        localStorage.removeItem("accessToken");
        window.location.replace("/");
        console.log("Unauthorized or Forbidden Error:", error);
      }
      return Promise.reject(error);
    }
  );

  const result = await api({
    method,
    url,
    params,
    data: { ...data },
    headers: {
      "Content-Type": "application/json",
      "Accept-Language": "en",
      authorization: token ? `Bearer ${token}` : "",
    },
    // timeout: 60000,
    responseType: "json",
  });

  return result;
  // }
  // return null
};
