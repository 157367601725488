import React from "react"

export default function Heading(props) {
  const {data} = props;
  return (
    <div>
      <div className="heading">
                <h1>{data?.title}</h1>
                <p>{data?.subtitle}</p>
              </div>
    </div>
  )
}
