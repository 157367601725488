import React, { useEffect, useState } from "react";
import { call } from "../../../api/apiCall";
import infoIcon from "../../../assets/img/infoIcon.png";
import { Input } from "reactstrap";
import { useDispatch } from "react-redux";
import { updateCriminal } from "../../../store/backgroundScreening/backgroundScreeningAction";
const CriminalForm = ({
  completedStep,
  setCompletedStep,
  activeStep,
  setActiveStep,
  data,
  // setData,
  // step,
  // setStep,
}) => {
  const [countryList, setCountryList] = useState([]);
  const [formData, setFormData] = useState({
    residence: data?.criminal?.residence || "",
    nationality: data?.criminal?.nationality,
  });

  const dispatch = useDispatch();

  useEffect(() => {
    setFormData({
      residence: data?.criminal?.residence || "",
      nationality: data?.criminal?.nationality,
    });
  }, [data]);

  useEffect(() => {
    (async () => {
      try {
        const res = await call("get", "api/v1/country", null, null);
        setCountryList(res?.data?.data);
      } catch (err) {
        console.log(err);
      }
    })();
  }, []);

  const [error, setError] = useState({
    residence: "",
    nationality: "",
  });

  const checkValidation = (type) => {
    let isFormValid = true;

    if (type == "residence" || type == "all") {
      if (!formData?.residence) {
        setError({ ...error, residence: "Residence is required" });
        isFormValid = false;
        return;
      } else {
        setError({ ...error, residence: "" });
      }
    }

    if (type == "nationality" || type == "all") {
      if (!formData?.nationality) {
        setError({ ...error, nationality: "Nationality is required" });
        isFormValid = false;
        return;
      } else {
        setError({ ...error, nationality: "" });
      }
    }
    return isFormValid;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (checkValidation("all")) {
      dispatch(updateCriminal(formData));
      if (!completedStep.includes(`step${activeStep}`))
        setCompletedStep([...completedStep, `step${activeStep}`]);
      window.scrollTo(0, 0);
      setActiveStep(activeStep + 1);
    }
  };
  return (
    <>
      <div className="px-5 py-3 package_form">
        <form>
          <div className="row mt-3">
            <div className="col-6 d-flex flex-column justify-content-end">
              <label htmlFor="name_type" className="form-label">
                <img src={infoIcon} width={20} /> Country of Residence{" "}
                <span className="text-danger">*</span>
              </label>
              <Input
                type="select"
                value={formData?.residence}
                onClick={() => checkValidation("residence")}
                onChange={(e) =>
                  setFormData({
                    ...formData,
                    residence: e.target.value,
                  })
                }
              >
                <option>Select</option>
                {countryList?.map((ele) => {
                  return (
                    <>
                      <option value={ele.name}>{ele?.name}</option>
                    </>
                  );
                })}
              </Input>
              {error?.residence && (
                <p className="text-danger">{error?.residence}</p>
              )}
            </div>

            <div className="col-6 d-flex flex-column justify-content-end">
              <label htmlFor="name_type" className="form-label">
                <img src={infoIcon} width={20} /> Nationality{" "}
                <span className="text-danger">*</span>
              </label>
              <Input
                type="select"
                value={formData?.nationality}
                onClick={() => checkValidation("nationality")}
                onChange={(e) =>
                  setFormData({
                    ...formData,
                    nationality: e.target.value,
                  })
                }
              >
                <option>Select</option>
                {countryList?.map((ele) => {
                  return (
                    <>
                      <option value={ele?.name}>{ele?.name}</option>
                    </>
                  );
                })}
              </Input>
              {error?.nationality && (
                <p className="text-danger">{error?.nationality}</p>
              )}
            </div>
          </div>

          <div className="d-flex justify-content-center align-items-center mt-3">
            <button className="btn btn-blue" onClick={(e) => handleSubmit(e)}>
              Continue to Next Step
            </button>
          </div>
        </form>
      </div>
    </>
  );
};

export default CriminalForm;
