import React from "react";
import image from "../../../assets/img/nobel.svg";
// import "./Directory.scss";
import { useNavigate } from "react-router-dom";
import InfiniteScroll from "react-infinite-scroll-component";

const PeopleDirectory = (props) => {
  const {
    publicMembersList,
    SearchMembersByText,
    setSearchKey,
    SearchAlphabaMembers,
    SearchAllMembers,
    loadMoreData,
    totalCount,
    handleEnterSubmit,
  } = props;
  const navigate = useNavigate();

  const navigateToMemberDetail = (id) => {
    navigate(`/directory/memberdetail/${id}`);
  };

  return (
    <div className="directory">
      <div className="header">
        <div className="container">
          <div className="row">
            <div className="col-lg-8">
              <img
                src={image}
                alt=""
                style={{ cursor: "pointer" }}
                onClick={() => navigate("/")}
              />
            </div>
            <div className="col-lg-4">
              <div className="search">
                <input
                  type="text"
                  placeholder="Search"
                  onChange={(e) => setSearchKey(e.target.value)}
                  onKeyDown={(e) => handleEnterSubmit(e)}
                />
                <div
                  className="search-img"
                  onClick={() => SearchMembersByText()}
                ></div>
              </div>
            </div>
          </div>
        </div>
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <h1>Directory / Members</h1>
              <p className="mt-3">
              Browse through our directory to discover talented individuals, entrepreneurs, and industry experts from around the world.
              </p>
            </div>
          </div>
        </div>
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="browse">
                <h3>Browse By</h3>
                <ul className="mb-4 mt-3">
                  <li onClick={() => SearchAlphabaMembers("A")}>A</li>
                  <li onClick={() => SearchAlphabaMembers("B")}>B</li>
                  <li onClick={() => SearchAlphabaMembers("C")}>C</li>
                  <li onClick={() => SearchAlphabaMembers("D")}>D</li>
                  <li onClick={() => SearchAlphabaMembers("E")}>E</li>
                  <li onClick={() => SearchAlphabaMembers("F")}>F</li>
                  <li onClick={() => SearchAlphabaMembers("G")}>G</li>
                  <li onClick={() => SearchAlphabaMembers("H")}>H</li>
                  <li onClick={() => SearchAlphabaMembers("I")}>I</li>
                  <li onClick={() => SearchAlphabaMembers("J")}>J</li>
                  <li onClick={() => SearchAlphabaMembers("K")}>K</li>
                  <li onClick={() => SearchAlphabaMembers("L")}>L</li>
                  <li onClick={() => SearchAlphabaMembers("M")}>M</li>
                  <li onClick={() => SearchAlphabaMembers("N")}>N</li>
                  <li onClick={() => SearchAlphabaMembers("O")}>O</li>
                  <li onClick={() => SearchAlphabaMembers("P")}>P</li>
                  <li onClick={() => SearchAlphabaMembers("Q")}>Q</li>
                  <li onClick={() => SearchAlphabaMembers("R")}>R</li>
                  <li onClick={() => SearchAlphabaMembers("S")}>S</li>
                  <li onClick={() => SearchAlphabaMembers("T")}>T</li>
                  <li onClick={() => SearchAlphabaMembers("U")}>U</li>
                  <li onClick={() => SearchAlphabaMembers("V")}>V</li>
                  <li onClick={() => SearchAlphabaMembers("W")}>W</li>
                  <li onClick={() => SearchAlphabaMembers("X")}>X</li>
                  <li onClick={() => SearchAlphabaMembers("Y")}>Y</li>
                  <li onClick={() => SearchAlphabaMembers("Z")}>Z</li>
                  {/* <li
                    onClick={() =>  SearchAllCompany()}
                  >
                    All
                  </li> */}
                </ul>
              </div>
              <InfiniteScroll
                dataLength={
                  publicMembersList?.length ? publicMembersList?.length : 0
                }
                next={loadMoreData}
                hasMore={
                  totalCount === 0
                    ? true
                    : totalCount <= publicMembersList?.length
                    ? false
                    : true
                }
                style={{ overflow: "visible" }}
                loader={
                  publicMembersList ? <p>Loading...</p> : <p>Nothing found!</p>
                }
              >
                <div className="community">
                  {publicMembersList ? (
                    <div className="row list">
                      {publicMembersList?.map((item, index) => {
                        return (
                          <div
                            key={index}
                            className="col-lg-4 list-name card-control-text-oneline"
                            onClick={() => navigateToMemberDetail(item?.id)}
                          >
                            {/* {console.log("item",item)} */}
                            {item?.first_name + " " + item?.last_name}
                          </div>
                        );
                      })}
                    </div>
                  ) : (
                    <>
                      <h6 className="d-flex justify-content-center">
                        Not found any members directory
                      </h6>
                    </>
                  )}
                </div>
              </InfiniteScroll>
              <div className="line mt-20 mb-20"> </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PeopleDirectory;
