// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getMessaging, getToken, onMessage } from "firebase/messaging";
import { useSelector } from "react-redux";

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyCgE7HpogeeIZvt8FL9hUyaIPZ2gh_N3mA",
  authDomain: "noblepage-c03ea.firebaseapp.com",
  projectId: "noblepage-c03ea",
  storageBucket: "noblepage-c03ea.appspot.com",
  messagingSenderId: "664026663066",
  appId: "1:664026663066:web:1ffb23525988ba93d2f723",
  measurementId: "G-QC5Q2SQD2X"
};

// Initialize Firebase
const firebaseApp = initializeApp(firebaseConfig);
// Messaging service
const messaging = getMessaging(firebaseApp)

export const getTokenFirebase = async () => {
  return getToken(messaging, {vapidKey: 'BO5lmGvhubDz-dgze9nY8lxiAPuvnoxV6Ii3l27G9Lxn7Vmpl2yeljE5zDCb8KePidfu6LREVVXWVxPdioAT61A'}).then((currentToken) => {
   console.log("currentToken",currentToken);
    if (currentToken) {
      return currentToken
    } else {
      return null
    }
  }).catch((err) => {
    console.log("firebase error",err);
    throw err
  });
}

export const onMessageListener = () =>
  new Promise((resolve) => {
    onMessage(messaging, (payload) => {
      resolve(payload);
    });
});