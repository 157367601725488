import React, { useState } from "react";
import Header from "../../core/components/layout/Header/Header";
import Card from "./BlogCard/Card";
import BlogHeader from "./BlogHeader/BlogHeader";
import "./Blog.scss";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import Pagination from "../../core/components/Pagination/Pagination";
import { allBlogAction } from "../../store/blog/blogActions";
import LoginFooter from "../../core/components/layout/footer/LoginFooter";

export default function Blog({ pageNo, pageSize, blogData }) {
  const cardData = useSelector((state) => state.blog.blogs);
  const blogCategory = useSelector((state) => state.blog.blogCategory);
  const data = useSelector((state) => state.blog.blogs.data);

  const [itemOffset, setItemOffset] = useState(1);
  const [currentItems, setCurrentItems] = useState([]);
  const [pageCount, setPageCount] = useState(0);
  const [dropdownValues, setDropdownValues] = useState({
    category_id: "",
    order_by: "",
  });

  const dispatch = useDispatch();
  let token = localStorage.getItem("accessToken");

  let itemsPerPage = 3;

  useEffect(() => {
    if (data && data.length > 0) {
      const endOffset = itemOffset + itemsPerPage;
      setCurrentItems(data && data.slice(itemOffset, endOffset));
      setPageCount(Math.ceil(data.length / itemsPerPage));
    }
  }, [itemOffset, itemsPerPage, data]);

  const handleCategorySelection = (e, type) => {
    if (e.target.value !== "default") {
      let payload = {
        page: pageNo,
        size: pageSize,
        [type]: e.target.value,
      };
      let filterArr = Object.keys(dropdownValues).filter((el) => el != type);
      if (dropdownValues[filterArr[0]] != "") {
        payload[filterArr[0]] = dropdownValues[filterArr[0]];
      }
      dispatch(allBlogAction(payload, token));
    }
    setDropdownValues({ ...dropdownValues, [type]: e.target.value });
  };

  const handlePageClick = (event) => {
    const newOffset = (event.selected * itemsPerPage) % data.length;
    setItemOffset(newOffset);
  };

  return (
    <div className="blog">
      <Header /> 
      <BlogHeader blogData={blogData} />
      <div className="container">
        {/* <div className="row"> */}
        <div className="mt-55">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="filter">
                  <div>
                    <div className="select-box">
                      <select
                        onClick={(e) =>
                          handleCategorySelection(e, "category_id")
                        }
                          style={{ border: "none" ,width:"107px",backgroundColor:"unset"}}
                        className="category"
                        name="category"
                        id="category"
                      >
                        <option>Category</option>
                        {blogCategory?.map((item) => (
                          <option key={item.id} value={item.id}>
                            {" "}
                            {item.name}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                  <div className="select-box">
                    <select
                      onClick={(e) => handleCategorySelection(e, "order_by")}
                      style={{ border: "none",width:"68px",backgroundColor:"unset" }}
                      className="sort"
                      name="sort"
                      id="sort"
                    >
                      <option value="default">Sort by</option>
                      <option value="asc">Oldest</option>
                      <option value="desc">Latest</option>
                    </select>
                  </div>
                </div>
              </div>
            </div>
            <div className="line my-3 px-3"></div>
            <div className="mt-30">
              <div className="row">
                {currentItems &&
                  currentItems.map((data) => {
                    return (
                      <div className="col-xl-4 col-12" key={data.id}>
                        <Card data={data} />
                      </div>
                    );
                  })}
              </div>
            </div>
          </div>
        </div>
        <Pagination
          data={cardData.data || []}
          handlePageClick={handlePageClick}
          pageCount={pageCount}
          pageRangeDisplayed={3}
        />
        {/* </div> */}
      </div>
      <LoginFooter />
    </div>
  );
}
