import { call } from "./apiCall";
class JobApi {
  createJobPost(params) {
    return new Promise((resolve, reject) => {
      const token = localStorage.getItem("accessToken");
      (async () => {
        try {
          const res = await call(
            "post",
            `api/v1/job/create`,
            null,
            params,
            token
          );

          if (res.data.success === true) {
            resolve(res.data);
          }
          if (res.data.success === false) {
            resolve(res.data);
          }
        } catch (err) {
          console.log(err);
          reject(err);
        }
      })();
    });
  }
  getJobList(params) {
    return new Promise(async (resolve, reject) => {
      let token = localStorage.getItem("accessToken");
      let tokentp = localStorage?.getItem("accessTpToken");
      try {
        const res = await call(
          "get",
          token || tokentp ? `api/v1/job/job-list` : `api/v1/public/job-list`,
          params,
          null,
          token || tokentp || null
        );
        if (res.data.success === true) {
          resolve(res.data);
        }
      } catch (err) {
        console.log(err);
        reject(err);
      }
    });
  }

  getPublicJobList(params) {
    return new Promise(async (resolve, reject) => {
      try {
        const res = await call(
          "get",
          `api/v1/public/job-list`,
          params,
          null,
          null
        );
        if (res.data.success === true) {
          resolve(res.data);
        }
      } catch (err) {
        console.log(err);
        reject(err);
      }
    });
  }

  getPortalJobList(params) {
    return new Promise(async (resolve, reject) => {
      let tokentp = localStorage?.getItem("accessTpToken");
      try {
        const res = await call(
          "get",
          tokentp ? `api/v1/job/job-list` : `api/v1/public/job-list`,
          params,
          null,
          tokentp
        );
        if (res.data.success === true) {
          resolve(res.data);
        }
      } catch (err) {
        console.log(err);
        reject(err);
      }
    });
  }

  shortlistCandidate(data) {
    return new Promise((resolve, reject) => {
      let token = localStorage.getItem("accessToken");
      (async () => {
        try {
          const res = await call(
            "post",
            `api/v1/job/shortlist-candidate`,
            null,
            data,
            token
          );
          if (res.data.success === true) {
            resolve(res.data);
          }
        } catch (err) {
          console.log(err);
          reject(err);
        }
      })();
    });
  }

  getShortlistedCandidate(params) {
    return new Promise((resolve, reject) => {
      let token = localStorage.getItem("accessToken");
      (async () => {
        try {
          const res = await call(
            "get",
            `api/v1/job/shortlist-candidate`,
            params,
            null,
            token
          );
          if (res.data.success === true) {
            resolve(res.data);
          }
        } catch (err) {
          console.log(err);
          reject(err);
        }
      })();
    });
  }

  getJobDetails(params) {
    return new Promise((resolve, reject) => {
      let token = localStorage.getItem("accessToken");
      let tokentp = localStorage.getItem("accessTpToken");
      (async () => {
        try {
          const res = await call(
            "get",
            `api/v1/job/job-detail/${params}`,
            null,
            null,
            token || tokentp
          );
          if (res.data.success === true) {
            resolve(res.data);
          }
        } catch (err) {
          console.log(err);
          reject(err);
        }
      })();
    });
  }

  getApplicantsDetailsById(params) {
    return new Promise((resolve, reject) => {
      let token = localStorage.getItem("accessToken");
      (async () => {
        try {
          const res = await call(
            "get",
            `api/v1/job/applicant/${params}`,
            null,
            null,
            token
          );
          if (res.data.success === true) {
            resolve(res.data);
          }
        } catch (err) {
          console.log(err);
          reject(err);
        }
      })();
    });
  }

  getJobMessage(params) {
    return new Promise((resolve, reject) => {
      let token = localStorage.getItem("accessToken");
      (async () => {
        try {
          const res = await call(
            "get",
            `api/v1/job/job-messages`,
            params,
            null,
            token
          );
          if (res.data.success === true) {
            resolve(res.data);
          }
        } catch (err) {
          console.log(err);
          reject(err);
        }
      })();
    });
  }

  getJobDetailUpdate(data, jobId) {
    return new Promise((resolve, reject) => {
      let token = localStorage.getItem("accessToken");
      (async () => {
        try {
          const res = await call(
            "patch",
            `api/v1/job/update-job/${jobId}`,
            null,
            data,
            token
          );
          resolve(res.data);
        } catch (err) {
          console.log(err);
          reject(err);
        }
      })();
    });
  }

  getApplicantsUpdate(data, id) {
    return new Promise((resolve, reject) => {
      let token = localStorage.getItem("accessToken");
      (async () => {
        try {
          const res = await call(
            "patch",
            `api/v1/job/apply-status/${id}`,
            null,
            data,
            token
          );
          resolve(res.data);
        } catch (err) {
          console.log(err);
          reject(err);
        }
      })();
    });
  }

  updateMessage(data) {
    return new Promise((resolve, reject) => {
      let token = localStorage.getItem("accessToken");
      (async () => {
        try {
          const res = await call(
            "patch",
            `api/v1/job/job-messages`,
            null,
            data,
            token
          );
          if (res.data.success === true) {
            resolve(res.data);
          }
        } catch (err) {
          console.log(err);
          reject(err);
        }
      })();
    });
  }

  getJobApplicatantsDetails(params) {
    return new Promise((resolve, reject) => {
      let token = localStorage.getItem("accessToken");
      (async () => {
        try {
          const res = await call(
            "get",
            `api/v1/job/applicants`,
            params,
            null,
            token
          );
          if (res.data.success === true) {
            resolve(res.data);
          }
        } catch (err) {
          console.log(err);
          reject(err);
        }
      })();
    });
  }

  createQuestionary(data) {
    return new Promise((resolve, reject) => {
      const token = localStorage.getItem("accessToken");
      (async () => {
        try {
          const res = await call(
            "post",
            `api/v1/job/questionary`,
            null,
            data,
            token
          );

          resolve(res.data);
        } catch (err) {
          console.log(err);
          reject(err);
        }
      })();
    });
  }
  updateQuestionaire(data, id) {
    return new Promise((resolve, reject) => {
      const token = localStorage.getItem("accessToken");
      (async () => {
        try {
          const res = await call(
            "patch",
            `api/v1/job/questionary/${id}`,
            null,
            data,
            token
          );

          resolve(res.data);
        } catch (err) {
          console.log(err);
          reject(err);
        }
      })();
    });
  }
  getQuestionary(params) {
    return new Promise((resolve, reject) => {
      let token = localStorage.getItem("accessToken");
      (async () => {
        try {
          const res = await call(
            "get",
            `api/v1/job/questionary-list`,
            params,
            null,
            token
          );
          if (res.data.success === true) {
            resolve(res.data);
          }
        } catch (err) {
          console.log(err);
          reject(err);
        }
      })();
    });
  }

  deleteQuestionarySet(setId) {
    return new Promise((resolve, reject) => {
      let token = localStorage.getItem("accessToken");
      (async () => {
        try {
          const res = await call(
            "delete",
            `api/v1/job/questionary/${setId}`,
            null,
            null,
            token
          );
          if (res.data.success === true) {
            resolve(res.data);
          }
        } catch (err) {
          console.log(err);
          reject(err);
        }
      })();
    });
  }

  updateQuestionary(setId, data) {
    return new Promise((resolve, reject) => {
      let token = localStorage.getItem("accessToken");
      (async () => {
        try {
          const res = await call(
            "patch",
            `api/v1/job/questionary/${setId}`,
            null,
            data,
            token
          );
          if (res.data.success === true) {
            resolve(res.data);
          }
        } catch (err) {
          console.log(err);
          reject(err);
        }
      })();
    });
  }

  sentSmartInterview(data) {
    return new Promise((resolve, reject) => {
      let token = localStorage.getItem("accessToken");
      (async () => {
        try {
          const res = await call(
            "patch",
            `api/v1/job/send-interview`,
            null,
            data,
            token
          );
          if (res.data.success === true) {
            resolve(res.data);
          }
        } catch (err) {
          console.log(err);
          reject(err);
        }
      })();
    });
  }

  updateShotlistById(id, data) {
    return new Promise((resolve, reject) => {
      let token = localStorage.getItem("accessToken");
      (async () => {
        try {
          const res = await call(
            "patch",
            `api/v1/job/shortlist-candidate/${id}`,
            null,
            data,
            token
          );
          if (res.data.success === true) {
            resolve(res.data);
          }
        } catch (err) {
          console.log(err);
          reject(err);
        }
      })();
    });
  }

  saveJobByID(jobId) {
    return new Promise((resolve, reject) => {
      let token = localStorage.getItem("accessToken");
      (async () => {
        try {
          const res = await call(
            "patch",
            `api/v1/job/saved-job/${jobId}`,
            null,
            null,
            token
          );
          resolve(res.data);
        } catch (err) {
          console.log(err);
          reject(err);
        }
      })();
    });
  }

  getQuestionsList(params) {
    return new Promise((resolve, reject) => {
      let token = localStorage.getItem("accessToken");
      (async () => {
        try {
          const res = await call(
            "get",
            `api/v1/job/apply-questions/${params}`,
            null,
            null,
            token
          );
          if (res.data.success === true) {
            resolve(res.data);
          }
        } catch (err) {
          console.log(err);
          reject(err);
        }
      })();
    });
  }

  updateApplyJob(data, id) {
    return new Promise((resolve, reject) => {
      let token = localStorage.getItem("accessToken");
      (async () => {
        try {
          const res = await call(
            "post",
            `api/v1/job/apply-job/${id}`,
            null,
            data,
            token
          );
          if (res.data.success === true) {
            resolve(res.data);
          }
        } catch (err) {
          console.log(err);
          reject(err);
        }
      })();
    });
  }

  applyJob(id, data = {}) {
    return new Promise((resolve, reject) => {
      let token = localStorage.getItem("accessToken");
      let tokentp = localStorage?.getItem("accessTpToken");
      (async () => {
        try {
          const res = await call(
            "post",
            `api/v1/job/apply-job/${id}`,
            null,
            data,
            token || tokentp
          );
          if (res.data.success === true) {
            resolve(res.data);
          }
        } catch (err) {
          console.log(err);
          reject(err);
        }
      })();
    });
  }

  createAlertforJob(data) {
    return new Promise((resolve, reject) => {
      const token = localStorage.getItem("accessToken");
      (async () => {
        try {
          const res = await call(
            "post",
            `api/v1/job/job-alert`,
            null,
            data,
            token
          );
          resolve(res.data);
        } catch (err) {
          console.log(err);
          reject(err);
        }
      })();
    });
  }

  getAlertsofJob() {
    return new Promise((resolve, reject) => {
      let token = localStorage.getItem("accessToken");
      (async () => {
        try {
          const res = await call(
            "get",
            `api/v1/job/job-alert`,
            null,
            null,
            token
          );
          if (res.data.success) {
            resolve(res.data);
          }
        } catch (err) {
          console.log(err);
          reject(err);
        }
      })();
    });
  }

  deleteAlertofJob(id) {
    return new Promise((resolve, reject) => {
      const token = localStorage.getItem("accessToken");
      (async () => {
        try {
          const res = await call(
            "delete",
            `api/v1/job/job-alert/${id}`,
            null,
            null,
            token
          );
          resolve(res.data);
        } catch (err) {
          console.log(err);
          reject(err);
        }
      })();
    });
  }

  editAlertofJob(data, id) {
    return new Promise((resolve, reject) => {
      let token = localStorage.getItem("accessToken");
      (async () => {
        try {
          const res = await call(
            "patch",
            `api/v1/job/job-alert/${id}`,
            null,
            data,
            token
          );
          if (res.data.success === true) {
            resolve(res.data);
          }
        } catch (err) {
          console.log(err);
          reject(err);
        }
      })();
    });
  }

  salaryComparision(data) {
    return new Promise((resolve, reject) => {
      let token = localStorage.getItem("accessToken");
      (async () => {
        try {
          const res = await call(
            "post",
            `api/v1/job/salary-comparision`,
            null,
            data,
            token
          );
          resolve(res.data);
        } catch (err) {
          console.log(err);
          reject(err);
        }
      })();
    });
  }

  updateJobPreference(data) {
    return new Promise((resolve, reject) => {
      let token = localStorage.getItem("accessToken");
      (async () => {
        try {
          const res = await call(
            "patch",
            `api/v1/job/job-preference`,
            null,
            data,
            token
          );
          resolve(res.data);
        } catch (err) {
          console.log(err);
          reject(err);
        }
      })();
    });
  }

  jobPreference() {
    return new Promise((resolve, reject) => {
      let token = localStorage.getItem("accessToken");
      (async () => {
        try {
          const res = await call(
            "get",
            `api/v1/job/job-preference`,
            null,
            null,
            token
          );
          resolve(res.data);
        } catch (err) {
          console.log(err);
          reject(err);
        }
      })();
    });
  }

  createSearchHistory(data) {
    return new Promise((resolve, reject) => {
      let token = localStorage.getItem("accessToken");
      (async () => {
        try {
          const res = await call(
            "post",
            `api/v1/user/search-history`,
            null,
            data,
            token
          );
          resolve(res.data);
        } catch (err) {
          console.log(err);
          reject(err);
        }
      })();
    });
  }

  getSearchHistory(params) {
    return new Promise((resolve, reject) => {
      let token = localStorage.getItem("accessToken");
      (async () => {
        try {
          const res = await call(
            "get",
            `api/v1/user/search-history`,
            params,
            null,
            token
          );
          resolve(res.data);
        } catch (err) {
          console.log(err);
          reject(err);
        }
      })();
    });
  }

  deleteSearchHistory(params) {
    return new Promise((resolve, reject) => {
      let token = localStorage.getItem("accessToken");
      (async () => {
        try {
          const res = await call(
            "delete",
            `api/v1/user/search-history`,
            params,
            null,
            token
          );
          resolve(res.data);
        } catch (err) {
          console.log(err);
          reject(err);
        }
      })();
    });
  }

  getQuestionaryAnswer(id) {
    return new Promise((resolve, reject) => {
      let token = localStorage.getItem("accessToken");
      (async () => {
        try {
          const res = await call(
            "get",
            `api/v1/job/questionary-answer/${id}`,
            null,
            null,
            token
          );
          if (res.data.success) {
            resolve(res.data);
          }
        } catch (err) {
          console.log(err);
          reject(err);
        }
      })();
    });
  }

  createGiveInterview(data) {
    return new Promise((resolve, reject) => {
      let token = localStorage.getItem("accessToken");
      (async () => {
        try {
          const res = await call(
            "post",
            `api/v1/job/give-interview`,
            null,
            data,
            token
          );
          resolve(res.data);
        } catch (err) {
          console.log(err);
          reject(err);
        }
      })();
    });
  }

  jobViewlog(job_id) {
    let token = localStorage.getItem("accessToken");
    return new Promise((resolve, reject) => {
      (async () => {
        try {
          const res = await call(
            "post",
            `api/v1/user/view-log`,
            null,
            { view_type: "job", job_id },
            token
          );
          if (res.data.success === true) {
            resolve(res.data);
          }
          if (res.data.success === false) {
            resolve(res.data);
          }
        } catch (err) {
          console.log(err);
          reject(err);
        }
      })();
    });
  }
  applicantJobViewlog(applicant_id, job_id) {
    let token = localStorage.getItem("accessToken");
    return new Promise((resolve, reject) => {
      (async () => {
        try {
          const res = await call(
            "post",
            `api/v1/user/view-log`,
            null,
            { view_type: "applicant", applicant_id, job_id },
            token
          );
          if (res.data.success === true) {
            resolve(res.data);
          }
          if (res.data.success === false) {
            resolve(res.data);
          }
        } catch (err) {
          console.log(err);
          reject(err);
        }
      })();
    });
  }

  updateTalentPool(data, id) {
    return new Promise((resolve, reject) => {
      let token = localStorage.getItem("accessToken");
      (async () => {
        try {
          const res = await call(
            "patch",
            `api/v1/portal/update/${id}`,
            null,
            data,
            token
          );
          if (res.data.success === true) {
            resolve(res.data);
          }
        } catch (err) {
          console.log(err);
          reject(err);
        }
      })();
    });
  }

  getPrepareQuestion(param) {
    return new Promise((resolve, reject) => {
      let token = localStorage.getItem("accessToken");
      (async () => {
        try {
          const res = await call(
            "get",
            `api/v1/job/preperation-question-list`,
            param,
            null,
            token
          );
          if (res.data.success) {
            resolve(res.data);
          }
        } catch (err) {
          console.log(err);
          reject(err);
        }
      })();
    });
  }

  addJobFeedback(answer, type_id) {
    let token = localStorage.getItem("accessToken");
    return new Promise((resolve, reject) => {
      (async () => {
        try {
          const res = await call(
            "post",
            `api/v1/feed/add-feedback`,
            null,
            { type: "job", type_id, answer },
            token
          );
          if (res.data.success === true) {
            resolve(res.data);
          }
          if (res.data.success === false) {
            resolve(res.data);
          }
        } catch (err) {
          console.log(err);
          reject(err);
        }
      })();
    });
  }
}

export const jobApi = new JobApi();
