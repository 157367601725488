import React from "react";
import Login from "../../../../pages/Login";
import logo from "../../../../assets/img/np-logo.svg";
import "./banner.scss";

export default function Banner(props) {
  const { data } = props;
  return (
    <div className="banner border-5">
      <div className="container">
        <div className="row">
          <div className="col-lg-6">
            <div className="heading">
              <h1>{data?.title}</h1>
              <p>{data?.subtitle}</p>
            </div>
          </div>
          <div className="col-lg-6">
            <Login />
          </div>
        </div>
      </div>
      <div className="n-logo">
        <img src={logo} alt="" width="100%" />
      </div>
    </div>
  );
}
