import React from "react";
import { Link, useNavigate } from "react-router-dom";
import "./ArticlesFeatured.scss";
import nopreview from "../../../../assets/img/no-preview.png";

export default function PostsFeatured(props) {
  const { posts_list } = props;

  console.log(posts_list);
  const navigate = useNavigate();
  return (
    <div className="mt-20 bg-white px-3 py-1" style={{ borderRadius: "5px" }}>
      <div className="featured-article my-4">
        <h4 className="mb-3 ">Featured Posts</h4>
        <div className="line"></div>
        <div className="mt-3">
          {posts_list?.map((item, index) => {
            if (index < 5) {
              return (
                <div key={index} className="box">
                  <div className="img">
                    {/* {item?.media ? (
                      <img src={item?.media} alt="" />
                    ) : ( */}
                    <img src={null} alt="" />
                    {/* )} */}
                  </div>
                  <div className="text">
                    <Link to={`/articledetail/${item?.id}`}>
                      <h6
                        className="card-control-text-twoline"
                        style={{ wordBreak: "break-word" }}
                      >
                        {item?.description}
                      </h6>
                    </Link>
                  </div>
                </div>
              );
            }
          })}
          {/* <div className="mt-3 ">
            <Link to={"/articles"}>
              <span>See all</span>
            </Link>
          </div> */}
          <div className="btn btn-light mt-3 text-blue d-block">
            <span
              style={{ fontSize: "15px", color: "#0432a3", fontWeight: 600 }}
              onClick={() => navigate("/posts")}
            >
              See all
            </span>
          </div>
        </div>
      </div>
    </div>
  );
}
