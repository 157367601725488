import {
  GET_SUGGESTED_ARTICLES,
  GET_MY_ARTICLES,
  CREATE_MY_ARTICLE,
  GET_ARTICLE_DETAIL,
  EDIT_MY_ARTICLE,
  EDIT_MY_ARTICLE_DETAILS,
  LOAD_MORE_MY_ARTICLES,
  LOAD_MORE_SUGGESTED_ARTICLES,
  GET_DRAFT_ARTICLE,
  LOAD_MORE_DRAFT_ARTICLE,
  GET_PAGE_ARTICLES,
  LIKE_ARTICLE,
  UNLIKE_ARTICLE,
  COMMENT_ARTICLE,
  GET_ARTICLE_COMMENT_LIST,
  GET_MORE_ARTICLE_COMMENT_LIST,
  EDITOR_IMAGE_UPDATE_URL,
  EDITOR_IMAGE_CLEAR_URL,
} from "../types";

export const initialState = {
  suggested_articles: [],
  my_articles: [],
  article_details: [],
  drafts: [],
  page_articles: [],
  comment_list: [],
  editorImgUrls:[],
};

const article = (state = initialState, action) => {
  switch (action.type) {
    case GET_SUGGESTED_ARTICLES:
      return { ...state, suggested_articles: action.payload };
    case GET_PAGE_ARTICLES:
      return { ...state, page_articles: action.payload };
    case LOAD_MORE_SUGGESTED_ARTICLES:
      return {
        ...state,
        suggested_articles: [...state.suggested_articles, ...action.payload],
      };

    case GET_MY_ARTICLES:
      return { ...state, my_articles: action.payload };

    case LOAD_MORE_MY_ARTICLES:
      return {
        ...state,
        my_articles: [...state.my_articles, ...action.payload],
      };

    case GET_DRAFT_ARTICLE:
      return { ...state, drafts: action.payload };

    case LOAD_MORE_DRAFT_ARTICLE:
      return {
        ...state,
        drafts: [...state.drafts, ...action.payload],
      };

    case CREATE_MY_ARTICLE:
      return { ...state, my_articles: [action.payload, ...state.my_articles] };

    case EDIT_MY_ARTICLE:
      return {
        ...state,
        my_articles: state.my_articles.map((item) =>
          item.id === action.payload.id ? { ...item, ...action.payload } : item
        ),
      };

    case EDIT_MY_ARTICLE_DETAILS:
      return { ...state, article_details: [action.payload] };

    case GET_ARTICLE_DETAIL:
      return { ...state, article_details: action.payload };

    case GET_ARTICLE_COMMENT_LIST:
      return { ...state, comment_list: action.payload };

    case GET_MORE_ARTICLE_COMMENT_LIST:
      return {
        ...state,
        comment_list: {
          ...state.comment_list,
          data: [...state.comment_list.data, ...action.payload.data],
        },
      };

    case COMMENT_ARTICLE:
      return {
        ...state,
        comment_list: {
          ...state.comment_list,
          data: [action.payload, ...state.comment_list.data],
          items: state.comment_list.items + 1,
        },
      };

    case LIKE_ARTICLE:
      return {
        ...state,
        article_details: state.article_details.map((item) =>
          item.id === action.payload.reaction_type_id
            ? { ...item, like_check: true, like_count: item.like_count + 1 }
            : item
        ),
      };

    case UNLIKE_ARTICLE:
      return {
        ...state,
        article_details: state.article_details.map((item) =>
          item.id === action.payload.reaction_type_id
            ? { ...item, like_check: false, like_count: item.like_count - 1 }
            : item
        ),
      };

      case EDITOR_IMAGE_UPDATE_URL:
        return {
          ...state,
          editorImgUrls: [...state.editorImgUrls, ...action.payload],
          
         
        };

        case EDITOR_IMAGE_CLEAR_URL:
        return {
          ...state,
          editorImgUrls: [],
          
         
        };

    default:
      return state;
  }
};

export default article;
