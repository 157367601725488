import React, { useEffect } from "react";
import GooglePlayNew from "../assets/img/GooglePlayNew.svg";
import AppStoreNew from "../assets/img/AppStoreNew.svg";
import GooglePlay1 from "../../src/assets/img/GooglePlay1.svg";
import AppStore1 from "../../src/assets/img/AppStore1.svg";
import Footer from "../core/components/layout/footer/Footer";
import Header from "../core/components/layout/Header/Header";
import Banner from "../core/components/layout/Banner/Banner";
import Footers from "../core/components/layout/n-footer/Footers";
import PaginationSlider from "./PaginationSlider";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import { call } from "../api/apiCall";
import { useDispatch, useSelector } from "react-redux";
import { landingApi } from "../api/landingApi";
import { getLandingPageData } from "../store/landing/landingAction";
import { ToastContainer } from "react-toastify";

export default function LandingPage() {
  const [currentPage, setCurrentPage] = useState(0);
  const [data, setData] = useState({});
  const [landingExplore, setLandingExplore] = useState();
  const [landingConverSlider, setLandingConverSlider] = useState();
  const [landingMobile, setLandingMobile] = useState();
  const [wpImg, setWpImg] = useState();
  const [landingExploreLinks, setLandingExploreLinks] = useState();
  const dispatch = useDispatch();
  const navigate = useNavigate();
//const [mobileData,setMobileData]=useState();
 
  const { firstFooter,secondFooter } = useSelector((state) => state.landingPage);

  useEffect(() => {
    dispatch(getLandingPageData({ title: "home" })).then((res) => {
      setData(res);

      setLandingExplore(
        res?.body?.filter((item) => item.key == "home-explore")[0]
      );
      setLandingMobile(
        res?.body?.filter((item) => item.key == "home-mobile")[0]
      );
      setWpImg(
        res?.body?.filter((item) => item.key == "home-wp-mobile")[0]
      );
      
      setLandingConverSlider(
        res?.body?.filter((item) => item.key == "home-slider")
      );
      setLandingExploreLinks(
        res?.body?.filter((item) => item.key == "home-explore-links")
      );
    });
    dispatch(getLandingPageData({ title: "footer-1" }));

    // dispatch(getLandingPageData({ title: "mobile" })).then((res) => {
    //   setMobileData(res);
    // });
    

  }, []);


  const pageCount = landingConverSlider?.length;

  return (
    <div className="login">
      <Header />
      <Banner data={data} />
      <div className="content">
        <section className="main">
          <div className="container">
            <div className="row">
              <div className="col-lg-6">
                <h2>{landingExplore?.title}</h2>
                <p>{landingExplore?.body}</p>
                <div
                  className="information"
                  onClick={() => window.open(landingExplore?.link)}
                >
                  <span>See More Information</span>
                </div>
              </div>
              <div className="col-lg-6">
                <ul>
                  {landingExploreLinks?.map((item, index) => (
                    // <a
                    //   href={item.link.length > 0 ? `${item.link}` : "#"}
                    //   className="text-blue-500"
                    //   key={index}
                    // >

                    <li
                      onClick={() =>
                        item.link.length > 0
                          ? window.open(`${item.link}`)
                          : navigate(`/explore`)
                      }
                      key={index}
                    >
                      {item.title}
                    </li>
                    // </a>
                  ))}
                </ul>
              </div>
            </div>
          </div>
        </section>

        <section className="">
          <div className="inform">
            <div className="container">
              <div className="bg"></div>
              <div className="row">
                <div className="col-lg-6">
                  <h2 className="homepage-inform">
                    {/* {paginationData[currentPage]?.title} */}
                    {landingConverSlider &&
                      landingConverSlider[currentPage]?.title}
                  </h2>
                  <p>
                    {/* {paginationData[currentPage]?.details} */}
                    {landingConverSlider &&
                      landingConverSlider[currentPage]?.body}
                  </p>
                </div>
                <div
                  className="information"
                  onClick={() =>
                    landingConverSlider &&
                    window.open(landingConverSlider[currentPage]?.link)
                  }
                >
                  <span>See More Information</span>
                </div>
                <div className="pagination-slider pt-5">
                  <PaginationSlider
                    paginationData={landingConverSlider}
                    pageCount={pageCount}
                    setCurrentPage={setCurrentPage}
                    currentPage={currentPage}
                  />
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="">
          <div className="mob">
          {/* style={{background: `url(${landingMobile?.media}) no-repeat left`,}} */}
            <div className="container">
              <div className="row">
                <div className="col-lg-6"></div>
                <div className="col-lg-6 homepage-mob">
                  <div className="display">
                    <h2>{landingMobile?.title}</h2>
                    <p>{landingMobile?.body}</p>
                    <div className="apps">
                      <img 
                      style={{width:"180px",height:"51.54px"}}
                        src={GooglePlayNew}
                        alt=""
                        onClick={() =>
                          window.open(landingMobile?.link.split(" ")[1])
                        }
                      />
                      <img
                      style={{width:"180px",height:"51.54px"}}
                        src={AppStoreNew}
                        alt=""
                        onClick={() =>
                          window.open(landingMobile?.link.split(" ")[0])
                        }
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* <section className="">
          <div className="mob">
            <div className="container">
              <div className="row">
                <div className="col-lg-6"></div>
                <div className="col-lg-6 homepage-mob">
                  <div className="display">
                    <h2>{landingMobile?.title}</h2>
                    <p>{landingMobile?.body}</p>
                    <div className="apps">
                      <img
                        src={play}
                        alt=""
                        onClick={() =>
                          window.open(landingMobile?.link.split(" ")[1])
                        }
                      />
                      <img
                        src={app}
                        alt=""
                        onClick={() =>
                          window.open(landingMobile?.link.split(" ")[0])
                        }
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section> */}
       
        <section className="mob4" style={{paddingTop:"190px"}}>
        <div className="container">
          <div className="row">
            <div className="col-lg-5">
              <div className="display">
                <h2 style={{color: "#01052d"}}>{wpImg?.title}</h2>
                <p>{wpImg?.body}</p>
                
                {/* <h2 style={{color: "#01052d"}}>The WorkerPool APP </h2>
                <h6 style={{marginBottom:"20px",marginTop:"20px"}}>Dedicated for Blue and Pink Collar Jobs.</h6>
                <p>Tailored for companies and individuals seeking or providing blue and pink collar workers, as well as skilled nannies and more. Whether you're a recruiter ora worker seeking a job, connect and register for free today to access a pool of opportunities.</p>
                */}
                <div className="apps">
                  <img
                   style={{width:"180px",height:"51.54px",marginRight:"20px"}}
                    src={AppStore1}
                    alt=""
                    onClick={() =>
                      window.open(landingMobile?.link.split(" ")[0])
                    }
                  />
                  <img
                   style={{width:"180px",height:"51.54px"}}
                    src={GooglePlay1}
                    alt=""
                    onClick={() =>
                      window.open(landingMobile?.link.split(" ")[1])
                    }
                  />
                </div>
              </div>
            </div>
            {/* <div className="col-lg-7"></div> */}
          </div>
        </div>
      </section>
    
        <Footers />
      </div>
      
      <Footer/>
   
      
      <ToastContainer />
    </div>
  );
}
