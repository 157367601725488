import { jobApi } from "../../api/jobs";
import { pageApi } from "../../api/pageAPI";
import { portalApi } from "../../api/portalApi";
import { initLoad, stopLoad } from "../loader/loaderActions";
import {
  CLOSE_JOB,
  GET_ALL_APPLICANTS_DETAILS_BYID,
  GET_APPLICANTS,
  GET_COMPANY_JOBS,
  GET_MEMBER_LIST_SUCCESS,
  GET_SINGLE_MEMBER_DATA,
  GET_TALENT_POOL,
  LOAD_MORE_GET_COMPANY_JOBS,
  LOAD_MORE_MEMBER_LIST_SUCCESS,
  UPDATE_APPLICANTS,
  UPDATE_TALENT_POOL,
} from "../types";

export const getTalentPool = (params) => (dispatch) => {
  dispatch(initLoad());
  return pageApi.getTalentPool(params).then(
    (response) => {
      dispatch({
        type: GET_TALENT_POOL,
        payload: response.data,
      });
      dispatch(stopLoad());
      return Promise.resolve(response);
    },
    (error) => {
      console.log(error);
      dispatch(stopLoad());
      return Promise.reject(error);
    }
  );
};

export const getJobs = (params, currentCount, type) => (dispatch) => {
  dispatch(initLoad());
  return jobApi.getJobList(params).then(
    (response) => {
      if (type === "company") {
        if (currentCount === 0) {
          dispatch({
            type: GET_COMPANY_JOBS,
            payload: response.data,
          });
          dispatch(stopLoad());
          return Promise.resolve(response);
        } else {
          dispatch({
            type: LOAD_MORE_GET_COMPANY_JOBS,
            payload: response.data,
          });
          dispatch(stopLoad());
          return Promise.resolve(response);
        }
      }
    },
    (error) => {
      console.log(error);
      dispatch(stopLoad());
      return Promise.reject(error);
    }
  );
};

// export const updateJob = (data, jobId) => (dispatch) => {
//   return jobApi.getJobDetailUpdate(data, jobId).then(
//     (response) => {
//       dispatch({
//         type: UPDATE_JOB,
//         payload: response.data,
//       });
//       return Promise.resolve(response);
//     },
//     (error) => {
//       console.log(error);
//       return Promise.reject(error);
//     }
//   );
// };
export const closeCompanyJob = (jobId) => (dispatch) => {
  return jobApi.getJobDetailUpdate({ expiry_date: new Date() }, jobId).then(
    (response) => {
      dispatch({
        type: CLOSE_JOB,
        payload: { id: jobId },
      });
      return Promise.resolve(response);
    },
    (error) => {
      console.log(error);
      return Promise.reject(error);
    }
  );
};

// export const getDetailApplicantId = (params) => (dispatch) => {
//   return jobApi.getApplicantsDetailsById(params).then(
//     (response) => {
//       dispatch({
//         type: GET_ALL_APPLICANTS_DETAILS_BYID,
//         payload: response.data,
//       });
//       return Promise.resolve(response);
//     },
//     (error) => {
//       console.log(error);
//       return Promise.reject(error);
//     }
//   );
// };

// export const getJobsMessage = (params) => (dispatch) => {
//   return jobApi.getJobMessage(params).then(
//     (response) => {
//       dispatch({
//         type: GET_ALL_JOBS_MESSAGES,
//         payload: response.data,
//       });
//       return Promise.resolve(response);
//     },
//     (error) => {
//       console.log(error);
//       return Promise.reject(error);
//     }
//   );
// };

export const getApplicants = (params) => (dispatch) => {
  dispatch(initLoad());
  return jobApi.getJobApplicatantsDetails(params).then(
    (response) => {
      dispatch({
        type: GET_APPLICANTS,
        payload: { id: params?.job_id, data: response.data },
      });
      dispatch(stopLoad());
      return Promise.resolve(response);
    },
    (error) => {
      dispatch(stopLoad());
      console.log(error);
      return Promise.reject(error);
    }
  );
};

export const getApplicantsById = (id) => (dispatch) => {
  dispatch(initLoad());
  return jobApi.getApplicantsDetailsById(id).then(
    (response) => {
      dispatch({
        type: GET_ALL_APPLICANTS_DETAILS_BYID,
        payload: response?.data,
      });
      dispatch(stopLoad());
      return Promise.resolve(response);
    },
    (error) => {
      dispatch(stopLoad());
      console.log(error);
      return Promise.reject(error);
    }
  );
};
export const updateApplicant = (data, id) => (dispatch) => {
  return jobApi.getApplicantsUpdate(data, id).then(
    (response) => {
      return Promise.resolve(response);
    },
    (error) => {
      console.log(error);
      return Promise.reject(error);
    }
  );
};

export const updateTalentPool = (data, id) => (dispatch) => {
  return jobApi.updateTalentPool(data, id).then(
    (response) => {
      dispatch({
        type: UPDATE_TALENT_POOL,
        payload: data,
      });
      dispatch(stopLoad());
      return Promise.resolve(response);
    },
    (error) => {
      dispatch(stopLoad());
      console.log(error);
      return Promise.reject(error);
    }
  );
};

// export const updateJobsMessage = (data) => (dispatch) => {
//   return jobApi.updateMessage(data).then(
//     (response) => {
//       dispatch({
//         type: UPDATE_JOB_MESSAGE,
//         payload: response.data,
//       });
//       return Promise.resolve(response);
//     },
//     (error) => {
//       console.log(error);
//       return Promise.reject(error);
//     }
//   );
// };

// export const shortListCandidateForJobs = (data) => (dispatch) => {
//   return jobApi.shortlistCandidate(data).then(
//     (response) => {
//       dispatch({
//         type: SHORTLIST_CANDIDATE_FOR_JOBS,
//         payload: response.data,
//       });
//       return Promise.resolve(response);
//     },
//     (error) => {
//       console.log(error);
//       return Promise.reject(error);
//     }
//   );
// };

export const getmembers = (id, params, currentCount,singleData) => (dispatch) => {
  dispatch(initLoad());
  return portalApi.getMemebersList(id, params, currentCount).then(
    (response) => {
      console.log("response",currentCount,response);
      if(singleData ){
        dispatch({
          type: GET_SINGLE_MEMBER_DATA,
          payload: response.data,
        });
        dispatch(stopLoad());
        return Promise.resolve(response);
      }
     else if (currentCount === 0) {
        dispatch({
          type: GET_MEMBER_LIST_SUCCESS,
          payload: response.data,
        });
        dispatch(stopLoad());
        return Promise.resolve(response);
      } else {
        dispatch({
          type: LOAD_MORE_MEMBER_LIST_SUCCESS,
          payload: response.data,
        });
        dispatch(stopLoad());
        return Promise.resolve(response);
      }
    },
    (error) => {
      console.log(error);
      dispatch(stopLoad());
      return Promise.reject(error);
    }
  );
};
