import React, { useState, useEffect } from "react";
import Header from "../../../core/components/layout/Header/Header";
import Loginfooter from "../../../core/components/layout/footer/LoginFooter";
import { Link, Navigate, useNavigate, useParams } from "react-router-dom";
import "./impQues.scss";
import HelpCenterSearch from "../components/HelpCenterSearch";
import { useDispatch, useSelector } from "react-redux";
import {
  getFAQSearch,
  postSupportData,
  sendFAQFeedback,
} from "../../../store/help/helpAction";
import HelpCenterSearchWebview from "../components/HelpCenterSearchWebview";
import ContactUsModal from "../components/ContactUsModal";
import { toast, ToastContainer } from "react-toastify";

export default function ImportantQuestions(props, args) {
  const details = useSelector((state) => state.help.searchById);
  const dispatch = useDispatch();

  const [modal, setModal] = useState(false);
  const [ifClicked, setClicked] = useState(false);
  const [mode, setMode] = useState("");
  const initialState = {
    full_name: "",
    email: "",
    support_type: "",
    subject: "",
    custom_subject: "",
    message: "",
    page_link: "",
    device_type: "",
    media: "",
  };
  const [formData, setFormData] = useState(initialState);
  const modals = () => setModal(!modal);
  const { id } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    let params = new URL(document.location).searchParams;
    let param = params.get("mode");
    setMode(param);
  }, []);

  useEffect(() => {
    dispatch(getFAQSearch({ id }));
    setClicked(false);
  }, [id]);

  const clicked = (isClicked, response) => {
    if (isClicked === true) {
      setClicked(true);
    }
    if (response === true) {
      let data1 = {
        feedback: "positive",
        id: id,
      };
      dispatch(sendFAQFeedback(data1));
    } else {
      let data = {
        feedback: "negative",
        id: id,
      };
      dispatch(sendFAQFeedback(data));
    }

    toast.success("Thanks for your feedback", {
      position: toast.POSITION.BOTTOM_LEFT,
    });
  };

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // dispatch(postSupportData(formData)).then((response) => {
    //   toast.success("Submitted successfully!", {
    //     position: toast.POSITION.BOTTOM_LEFT,
    //   });
    //   setFormData(initialState);
    //   modals();
    // });
  };
  const HelpCenter = () => {
    navigate("/help");
  };

  return (
    <div className={mode === "mobile" ? "helpWebView" : ""}>
      {mode === "mobile" ? <></> : <Header />}
      <div className="help">
        {mode === "mobile" ? <HelpCenterSearchWebview /> : <HelpCenterSearch />}
        <div className="context">
          <section className="padding">
            <div className="container">
              <button
                onClick={() => HelpCenter()}
                style={{
                  padding: "10px",
                  backgroundColor: "#59afff", // Initial background color
                  color: "#fff", // Initial text color
                  border: "1px solid #ccc",
                  borderRadius: "5px",
                  cursor: "pointer",
                  transition: "background-color 0.3s, color 0.3s", // Add color transition
                }}
                onMouseOver={(e) => {
                  e.target.style.backgroundColor = "#0a5dc2"; // Hover background color
                  e.target.style.color = "#fff"; // Hover text color
                }}
                onMouseOut={(e) => {
                  e.target.style.backgroundColor = "#59afff"; // Initial background color on mouse out
                  e.target.style.color = "#fff"; // Initial text color on mouse out
                }}
              >
                Back to Help Center
              </button>
              {/* <Link to="/help" style={{paddingLeft:"10px"}}>Back Help Center</Link> */}
              <div className="row mt-4">
                <div className="col-lg-12">
                  <div>
                    <div className="wrapper">
                      <div className="heading">
                        <h4>{details?.question}</h4>
                      </div>
                    </div>
                    {details?.answer ? (
                      <div className="content">
                        {/* <p>{details?.answer}</p> */}
                        <p
                          dangerouslySetInnerHTML={{
                            __html: details?.answer,
                          }}
                        />
                      </div>
                    ) : (
                      <>
                        <p>No answer is available</p>
                        <img
                          alt=""
                          className="imgClass my-4"
                          src="https://as2.ftcdn.net/v2/jpg/03/17/25/45/1000_F_317254576_lKDALRrvGoBr7gQSa1k4kJBx7O2D15dc.jpg"
                        />
                      </>
                    )}
                  </div>
                </div>
              </div>
              {!ifClicked ? (
                <div className="row">
                  <div className="col-lg-12">
                    <div className="wrapper">
                      <div className="feedBack">
                        <p>Was this answer helpful?</p>
                        <div className="d-flex">
                          <button
                            className="btn btn-blue"
                            onClick={() => clicked(true, true)}
                          >
                            Yes
                          </button>
                          <button
                            className="btn btn-grey"
                            onClick={() => clicked(true, false)}
                          >
                            No
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ) : (
                ""
              )}
            </div>
          </section>
        </div>
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="bottom my-2">
                <ul>
                  <li onClick={modals}>Contact us</li>
                  <li>
                    {/* <Link
                      to={mode === "mobile" ? "/help?mode=mobile" : "/help"}
                    >
                      FAQ 
                    </Link> */}
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ContactUsModal
        modal={modal}
        modals={modals}
        args={args}
        handleSubmit={handleSubmit}
        formData={formData}
        handleChange={handleChange}
      />
      <ToastContainer />
      {mode === "mobile" ? <></> : <Loginfooter />}
    </div>
  );
}
