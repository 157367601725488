import {
    GET_RECEIVED_RECOMMENDATION,
    GET_GIVEN_RECOMMENDATION,
    ADD_RECOMMENDATION,
    ASK_RECOMMENDATION,
    UPDATE_RECOMMENDATION,
    DELETE_RECOMMENDATION,
    GET_PENDING_RECOMMENDATION,
    TOGGLE_UPDATE_RECOMMENDATION
  } from "../types";

  import { recommendationApi } from "../../api/recommendationApi";
  import { initLoad } from "../loader/loaderActions";
  import { stopLoad } from "../loader/loaderActions";

  export const getRecommendationData = (type, params) => (dispatch) => {
    dispatch(initLoad());
    return recommendationApi.getRecommendationData(params).then(
      (res) => {
        if (type === "given") {
          dispatch({
            type: GET_GIVEN_RECOMMENDATION,
            payload: res.data,
          });

        } else if (type === "received") {
          dispatch({
            type: GET_RECEIVED_RECOMMENDATION,
            payload: res.data,
          });
        }
        else if (type === "pending") {
          dispatch({
            type: GET_PENDING_RECOMMENDATION,
            payload: res.data,
          });
        }
        dispatch(stopLoad());
        return Promise.resolve(res);
      },
      (error) => {
        dispatch(stopLoad());
        return Promise.reject(error);
      }
    );
  };

  export const addRecommendation = (data) => (dispatch) => {
    dispatch(initLoad());
    return recommendationApi.addRecommendation(data).then(
      (res) => {
          dispatch({
            type: ADD_RECOMMENDATION,
            payload: res.data,
          });
        dispatch(stopLoad());
        return Promise.resolve(res);
      },
      (error) => {
        dispatch(stopLoad());
        return Promise.reject(error);
      }
    );
  };
  export const askRecommendation = (data) => (dispatch) => {
    dispatch(initLoad());
    return recommendationApi.askRecommendation(data).then(
      (res) => {
          dispatch({
            type: ASK_RECOMMENDATION,
            payload: res.data,
          });

        dispatch(stopLoad());
        return Promise.resolve(res);
      },
      (error) => {
        dispatch(stopLoad());
        return Promise.reject(error);
      }
    );
  };
  export const updateRecommendation = (data) => (dispatch) => {
    dispatch(initLoad());
    return recommendationApi.updateRecommendation(data).then(
      (res) => {
          dispatch({
            type: UPDATE_RECOMMENDATION,
            payload: res.data,
          });
          dispatch({
            type: TOGGLE_UPDATE_RECOMMENDATION,
            payload: data,
          });

        dispatch(stopLoad());
        return Promise.resolve(res);
      },
      (error) => {
        dispatch(stopLoad());
        return Promise.reject(error);
      }
    );
  };
  export const deleteRecommendation = (id) => (dispatch) => {
    dispatch(initLoad());
    return recommendationApi.deleteRecommendation(id).then(
      (res) => {
          dispatch({
            type: DELETE_RECOMMENDATION,
            payload: id,
          });

        dispatch(stopLoad());
        return Promise.resolve(res);
      },
      (error) => {
        dispatch(stopLoad());
        return Promise.reject(error);
      }
    );
  };
