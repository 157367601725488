import React from "react";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import FormInput from "../../../../../core/components/Inputs/FormInput";
import Loader from "../../../../../core/components/Loaders/ComponentLoader";
import { change_email } from "../../../../../store/profile/profileActions";
import { toast } from "react-toastify";
import { confirmPassTransferPage } from "../../../../../store/pages/pagesAction";
import AppLoader from "../../../../../core/components/Loaders/appLoader/appLoader";
const ConfirmPassword = (props) => {
  const { modal, toggle, title, email, emailToVerification } = props;
  const [password, setPassword] = useState("");
  const [passErrorMessage, setPassErrorMessage] = useState("");
  const { isPageLoading } = useSelector((state) => state.loader);
  const dispatch = useDispatch();
  const validation = (password) => {
    let passwordValid =
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;
    if (!password) {
      setPassErrorMessage("Please Enter Your Password");
      return;
    }
    if (!passwordValid.test(password)) {
      setPassErrorMessage(
        'The password should be minimum 8 characters long and contain at least one : Upper and Lower Case Alphabet, Number, Special Character. The special characters that can be used are [!""#$%&\'()*+,-./:;<=>?@[]^_`{|}~]'
      );
      return;
    }
    handleSubmit();
  };

  const confirmPass = () => {
    const temp = { password };
    dispatch(confirmPassTransferPage(temp)).then((res) => {
      if (res.success === true) {
        changeEmail();
      }
    });
  };
  const changeEmail = () => {
    const data = { email: emailToVerification };
    dispatch(change_email(data)).then((res) => {
      if (res.success === true) {
        toast.success(res.message, {
          position: toast.POSITION.BOTTOM_LEFT,
        });
        toggle();
      } else alert(res.message);
    });
  };
  const handelPassChange = (e) => {
    setPassword(e.target.value);
    setPassErrorMessage("");
  };

  const handleSubmit = async () => {
    confirmPass(password);
  };
  return (
    <>
      {isPageLoading && <AppLoader />}
      <Modal
        isOpen={modal}
        size="lg"
        style={{ maxWidth: "600px", maxHeight: "300px" }}
        className="topDiv"
        centered
      >
        <ModalHeader className="header px-5" toggle={toggle}>
          {title}
        </ModalHeader>
        <ModalBody>
          <div className="admin-popup">
            <div className="container">
              <div className="p-0 bg-white">
                <div className="row mb-4">
                  <div className="col-lg-12">
                    <p>
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                      Nunc vulputate libero et velit interdum, ac aliquet odio
                      mattis.
                    </p>
                    <div className="d-flex position-relative form-input">
                      <FormInput
                        maxLength="24"
                        type="password"
                        placeholder="Password"
                        error={passErrorMessage}
                        onChange={handelPassChange}
                        // containerClassName="confirmpass"
                        containerClassName={
                          "confirmpass " +
                          (passErrorMessage ? "error-form" : "")
                        }
                      />
                    </div>
                    <div className="text-center">
                      <button
                        className="btn btn-blue mt-4"
                        onClick={() => validation(password)}
                      >
                        Confirm
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </ModalBody>
      </Modal>
    </>
  );
};

export default ConfirmPassword;
