import React, { useEffect, useRef, useState } from "react";
import Navbar from "../../../../pages/navbar/navbar";
import "./WatchSmartInterview.scss";
import crossiIcon from "../../../../assets/img/Closeicon.svg";
import ArrowLeft from "../../../../assets/img/Arrow-left.svg";
import ArrowRight from "../../../../assets/img/Arrow-right.svg";
import AvatarIcon from "../../../../assets/img/default_profile_sqr img.svg";
import BlueTickIcon from "../../../../assets/img/big-verify.svg";
import EduIcon from "../../../../assets/img/edu.svg";
import UserVerifyIcon from "../../../../assets/img/user-a.svg";
import { call } from "../../../../api/apiCall";
import { jobApi } from "../../../../api/jobs";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { updateJobData } from "../../../../store/jobs/jobAction";
import { ToastContainer, toast } from "react-toastify";
import waterMark from "../../../../assets/img/introvideowatermark.png";

const WatchSmartInterview = () => {
  const [data, setData] = useState();
  const { state } = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const videoPlayer = useRef(null);
  const [currentQue, setCurrentQue] = useState(0);
  const getAnswer = async () => {
    if (state?.shortlist_id) {
      const res = await jobApi.getQuestionaryAnswer(state?.shortlist_id);
      if (res?.success) setData(res?.data);
    }
    console.log(state);
  };
  useEffect(() => {
    getAnswer();
  }, []);

  useEffect(() => {
    if (!state?.interview_result) videoPlayer?.current?.load();
  }, [currentQue, data]);

  useEffect(() => {
    if (state?.interview_result) {
      let current = currentQue;
      videoPlayer?.current?.addEventListener("timeupdate", () => {
        const currentTime = videoPlayer?.current?.currentTime;
        const nextQuestionIndex = data?.answer?.findIndex(
          (q, i) => parseFloat(q.timestamp / 1000) > currentTime
        );

        if (nextQuestionIndex !== -1 && nextQuestionIndex !== current) {
          setCurrentQue(nextQuestionIndex);
          current = nextQuestionIndex;
        }
      });
      videoPlayer?.current?.load();
    }
  }, [data]);

  const handleToplistCandidate = () => {
    dispatch(
      updateJobData({ toplist_id: state?.shortlist_id }, state?.job_id, "")
    )
      .then((response) => {
        if (response.success) {
          setData((d) => ({ ...d, user: { ...d.user, toplisted: true } }));
          toast.success("Candidate toplisted successfully", {
            position: toast.POSITION.BOTTOM_LEFT,
          });
        }
        // navigate(-1)
      })
      .catch((error) => {
        console.error("Error in Candidate toplisted:", error);
      });
  };

  function truncateName(name, maxLength) {
    if (name && name.length > maxLength) {
      return name.substring(0, maxLength) + "...";
    }
    return name;
  }

  return (
    <>
      <div>
        <Navbar />
        <ToastContainer />
        <div className="watch_smart_interview">
          <div className="bg">
            <div className="container">
              <div className="bg-white p-3 interview_container">
                <div className="d-flex justify-content-between align-items-center">
                  <h4 className="text-dark">Smart Interview</h4>
                  <img
                    src={crossiIcon}
                    onClick={() => navigate("/shortlist/candidates")}
                  />
                </div>
                <p className="text-secondary mt-2">
                  The candidate's recorded smart interview video cannot be saved
                  or recorded. You have a 14-day window to watch or rewatch the
                  interview before it disappears.
                </p>
                {data?.answer?.length ? (
                  <div>
                    <div className="user_detail_container mt-5">
                      <div className="d-flex justify-content-between align-items-center">
                        <div className="d-flex">
                          <img
                            src={data?.user?.profile_img || AvatarIcon}
                            className="rounded-circle"
                            style={{ height: "80px", width: "80px" }}
                          />
                          <div className="mx-3 mt-3 d-flex justify-content-between">
                            <div>
                              {/* <h5 className="text-dark">
                                {data?.user?.first_name} {data?.user?.last_name}{" "}
                                <span>
                                  <img className="mx-2" src={BlueTickIcon} />
                                  <img className="mx-2" src={EduIcon} />
                                  <img className="mx-2" src={UserVerifyIcon} />
                                </span>
                              </h5> */}
                              <h5 className="text-dark">
                                {truncateName(
                                  `${data?.user?.first_name} ${data?.user?.last_name}`,
                                  20
                                )}
                                <span>
                                  <img className="mx-2" src={BlueTickIcon} />
                                  <img className="mx-2" src={EduIcon} />
                                  <img className="mx-2" src={UserVerifyIcon} />
                                </span>
                              </h5>

                              <p className="text-secondary">
                                {data?.user?.profile?.profile_headline}
                              </p>
                            </div>
                          </div>
                        </div>
                        {data?.user?.toplisted ? (
                          <div className="toplist mx-4 px-4">Toplisted</div>
                        ) : (
                          <div
                            className=""
                            onClick={() => handleToplistCandidate()}
                          >
                            <p className="toplist">Toplist this candidate</p>
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="w-100 mt-5 pt-2 pb-1">
                      <div className="position-relative">
                        <video
                          width="100%"
                          height="500"
                          controls
                          controlsList="nodownload nofullscreen"
                          ref={videoPlayer}
                          disablePictureInPicture
                          className="interview-video-player"
                        >
                          <source
                            src={
                              state?.interview_result ||
                              data?.answer[currentQue]?.answer_url
                            }
                            type="video/mp4"
                          />
                        </video>
                        <div className="question_container">
                          <p>
                            {currentQue + 1}.{" "}
                            {data?.answer[currentQue]?.question}{" "}
                          </p>
                        </div>
                        <img
                          style={{
                            width: "82px",
                            position: "absolute",
                            top: "10px",
                            right: "20px",
                            mixBlendMode: "difference",
                          }}
                          src={waterMark}
                          alt="NobelPage logo"
                        />
                      </div>
                    </div>
                    <div className="mt-4 d-flex justify-content-between">
                      <div style={{ minWidth: "20px" }}>
                        {currentQue > 0 && !state?.interview_result && (
                          <img
                            src={ArrowLeft}
                            style={{ cursor: "pointer" }}
                            // disabled={currentQue < 1}
                            onClick={() =>
                              currentQue > 0
                                ? setCurrentQue(currentQue - 1)
                                : setCurrentQue(currentQue)
                            }
                          />
                        )}
                      </div>
                      <button
                        className="btn text-white sub_btn"
                        onClick={() => navigate("/shortlist/candidates")}
                      >
                        Exit
                      </button>
                      <div style={{ minWidth: "20px" }}>
                        {currentQue < data?.answer?.length - 1 &&
                          !state?.interview_result && (
                            <img
                              src={ArrowRight}
                              style={{ cursor: "pointer" }}
                              // disabled={currentQue >= data?.answer?.length}
                              onClick={() =>
                                currentQue < data?.answer?.length - 1 &&
                                setCurrentQue(currentQue + 1)
                              }
                            />
                          )}
                      </div>
                    </div>
                  </div>
                ) : (
                  <div className="text-danger text-center my-3 fw-bold">
                    {" "}
                    Smart Interview result is not available.{" "}
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default WatchSmartInterview;
