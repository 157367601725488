import React, { useEffect, useState } from "react";
import signupimage from "../../../assets/img/signup-portal.svg";
import logo from "../../../assets/img/np-logo.svg";
import { useLocation, useNavigate, useParams,Link } from "react-router-dom";
import "./VerifyOtp.scss";
import Heading from "../../../core/components/layout/Heading";
import FormInput from "../../../core/components/Inputs/FormInput";
import { call } from "../../../api/apiCall";
import Footer from "../../../core/components/layout/footer/Footer";
import { useDispatch, useSelector } from "react-redux";
import { getTalentPoolDetails, getTalentPoolDetailsByToken } from "../../../store/portal/portalAction";

export default function VerifyOtp(props) {
  const location = useLocation();
  const { talentPoolData, talentPoolDataByToken } = useSelector((state) => state.portal);
  const { param } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [otp, setOtp] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [showError, setShowError] = useState("");
  const [showResentError, setShowResentError] = useState("");
  const [showMessage, setShowMessage] = useState(true);
  // const [loading, setLoading] = useState(false)
  useEffect(() => {
    if (
      (location && location.state && location.state.email) ||
      (location && location.state && location.state.phone)
    ) {
      console.log(location.state.email);
      console.log(location.state.phone);
    } else {
      navigate("/error");
    }
  });
  useEffect(() => {
    dispatch(getTalentPoolDetails(param));
    dispatch(getTalentPoolDetailsByToken());
  }, []);
  useEffect(() => {
    if (talentPoolData?.id===talentPoolDataByToken?.id) navigate(`/tp/portal/${param}/dashboard`);
  },[talentPoolDataByToken,talentPoolData]);
  const validation = () => {
    if (!otp) {
      setErrorMessage("Please Enter Your OTP");
    } else {
      handleSubmit();
    }
  };
  let email = location?.state?.email;
  let phone = location?.state?.phone;
  let forgotPassData = {
    email: email,
  };
  let forgotPassPhoneData = {
    phone: phone,
  };
  const handelResent = async () => {
    let data;
    if (location?.state?.email) {
      data = forgotPassData;
    } else {
      data = forgotPassPhoneData;
    }
    try {
      const res = await call("post", "api/v1/auth/forgot-password", null, data);
      if (res.data.success == true) {
        console.log("forgot success data", res.data.success);
        setShowResentError(res.data.message);
      }
      // else {
      //   setShowError(res.data.message)
      // // console.log("forgot unsuccess data", res.data);
      // //  {res.data.message && <label className="label-input100 text-danger">{res.data.message}</label>}
      // // return
      // }
    } catch (err) {
      console.log(err);
      setShowError(err.response.data.message);
    }
  };
  const handleSubmit = async () => {
    let otpEmailData = {
      email: email,
      otp: otp,
    };
    let otpPhoneData = {
      phone: phone,
      otp: otp,
    };

    let otpData;
    if (location.state.email) {
      otpData = otpEmailData;
    } else {
      otpData = otpPhoneData;
    }

    try {
      const res = await call("post", "api/v1/auth/verify-otp", null, otpData);
      if (res.data.success === true) {
        // console.log("otp res", res.data);
        setShowResentError(res.data.message);

        navigate(`/tp/portal/${param}/reset-password`, {
          state: { token: res.data.token },
        });
      }
    } catch (err) {
      console.log(err);
      setShowError(err.response.data.message);
    }
  };
  const handelChangeOtp = (e) => {
    setOtp(e.target.value);
    setErrorMessage("");
    setShowError("");
    setShowResentError("");
  };
  useEffect(() => {
    setTimeout(() => {
      setShowMessage(false);
    }, 2000);
  }, []);

  return (
    <div>
      <div className=" login-portal" style={{ height: "100vh",background:"white"  }}>
        <div className="row" style={{margin:"auto",width:"100%"}}>
          <div className="col-lg-4 forgot-left" style={{ height: "100vh" }}>
            <img
              src={ talentPoolData?.background_img
                  ? talentPoolData?.background_img
                  : signupimage}
              alt="signupimage"
              className="center"
            />
          </div>
          <div className="col-lg-8 right" style={{display:"flex"}}>
            <div className="forgot " style={{margin:"auto"}}>
              <form className="auth-form">
                <h2 className="dark-blue-color">Enter OTP</h2>
                {showError && (
                  <label className="label-input100 text-danger">
                    {showError}
                  </label>
                )}
                {showResentError && (
                  <label className="label-input100 text-primary">
                    {showResentError}
                  </label>
                )}
                {showMessage && (
                  <label className="label-input100 text-primary">
                    OTP has been sent successfully to registered email Id.
                  </label>
                )}
                <FormInput
                  type="text"
                  label="OTP"
                  maxlength="4"
                  onChange={handelChangeOtp}
                  placeholder="Enter One Time Password"
                  error={errorMessage}
                  style={{ padding: ".375rem .75rem" }}
                  containerClassName={errorMessage ? "error-form" : ""}
                />
                     <span className="use-phone-text">
                  {/* <Link to="/">Use phone number </Link> */}
                  <Link to={`/tp/portal/${param}/login`}>
                    <div
                      style={{
                        color: "rgb(4, 50, 163)",
                        marginTop: "10px",
                        cursor: "pointer",
                      }}
                    >
                      Back to login
                    </div>
                  </Link>
                </span>

                <p className="mt-3">
                Check your registered email and enter the sent OTP.
                </p>

                <div className="text-center d-grid mt-4">
                  <div
                    className="btn btn-blue"
                    style={{ background: `${talentPoolData?.theme}` }}
                    onClick={validation}
                  >
                    Verify
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
        <div className="minHeight"></div>
      </div>
    </div>
  );
}
