import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import "../../../profile/profile.scss";
import { useSelector, useDispatch } from "react-redux";
import List from "../List";
import { getProfileViewersList } from "../../../../../store/profile/profileActions";
import ProfileViewCount from "../ProfileViewCount";
import Navbar from "../../../../../pages/navbar/navbar";
import Chat from "../../../../../pages/chat/chat";
import SidebarFooter from "../../../../setting/SidebarFooter";
import Ad from "../../../../../core/components/layout/Sidebar/Ad";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import lockWithBlueBg from "../../../../../assets/img/lockWithBlueBg.png";
import "./ViewerList.scss";
import routeNames from "../../../../routeNames";
import AppLoader from "../../../../../core/components/Loaders/appLoader/appLoader";

function ViewerList(props) {
  const navigate = useNavigate();
  const { user } = useSelector((state) => state.auth);
  const { isPageLoading } = useSelector((state) => state.loader);
  const {
    profileViewersList,
    companyFilter,
    currentCount,
    searchAppearenceCount,
    query,
    setQuery,
    sendConnectionHandle,
  } = props;
  return (
    <>
      <Navbar isJobs={false} />

      <div className="main-bg" style={{ minHeight: "100%" }}>
        <div className="people-view" style={{ minHeight: "92.5vh" }}>
          <div className="container">
            <div className="row">
              <div className="col-lg-9">
                <div className="wrappers">
                  <div className="p-20" style={{ position: "relative" }}>
                    {isPageLoading === true && <AppLoader />}
                    <div>
                      <h2>Who's viewed your profile</h2>
                      <p>People who viewed your profile in the past 90 days</p>
                    </div>
                    <div className="d-flex flex-wrap gap-3 mt-30">
                      <div
                        className={
                          Object.keys(query).length > 0
                            ? "bubble outline curserPointer"
                            : "bubble blue-button curserPointer"
                        }
                        onClick={() => {
                          setQuery({});
                        }}
                      >
                        <p>All Viewers</p>
                      </div>
                      {companyFilter && Object.keys(companyFilter) ? (
                        <>
                          {Object.keys(companyFilter)?.map((item, index) => {
                            return (
                              <div
                                key={index}
                                className={
                                  query?.company == item
                                    ? "bubble blue-button curserPointer"
                                    : "bubble outline curserPointer"
                                }
                                onClick={() => {
                                  setQuery({ company: item });
                                }}
                              >
                                <p>
                                  {Object.values(companyFilter)[index]} work at{" "}
                                  {item}{" "}
                                </p>
                              </div>
                            );
                          })}
                        </>
                      ) : (
                        ""
                      )}
                      {/* <div className="bubble outline">
                        <p>2 work at Lilac Netstager Technologies</p>
                      </div> */}

                      <div
                        className={
                          query?.searchAppearences
                            ? "bubble blue-button curserPointer"
                            : "bubble outline curserPointer"
                        }
                        onClick={() => {
                          setQuery({ searchAppearences: true });
                        }}
                      >
                        <p>
                          {searchAppearenceCount} found you through NobelPage
                          Search
                        </p>
                      </div>
                    </div>
                  </div>

                  <ProfileViewCount
                    profileViewersCount={
                      currentCount ? currentCount : profileViewersList.length
                    }
                  />
                  {user?.data?.membership?.id > 1 ? (
                    <>
                      {profileViewersList &&
                        profileViewersList?.map((item, i) => (
                          <List
                            key={i}
                            profileViewersList={item}
                            sendConnectionHandle={sendConnectionHandle}
                          />
                        ))}
                    </>
                  ) : (
                    <div className="background-image d-flex flex-column align-items-center justify-content-center">
                      <img
                        className="mb-30"
                        src={lockWithBlueBg}
                        alt="Unlock Features"
                      />
                      <h4 className="unlock-feature-title mb-4">
                        Unlock More Features
                      </h4>
                      <p className="unlock-feature-title mb-20">
                        Upgrade now to unlock additional features and add-on
                        packages
                      </p>
                      <div
                        className="rounded blue-button curserPointer"
                        onClick={() => {
                          navigate(routeNames.MEMBERSHIP);
                        }}
                      >
                        <p>Explore Our Memberships</p>
                      </div>
                    </div>
                  )}
                </div>
              </div>
              <div className="col-lg-3">
                <Ad />
                <div className="mt-20">
                  <SidebarFooter />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Chat />
      <ToastContainer />
    </>
  );
}

export default ViewerList;
