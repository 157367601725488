import React from "react";
import "./JoinUs.scss";
import arrow from "../../../../assets/img/arrow.svg";
import { Link } from "react-router-dom";

const JoinUsSection = () => {
  return (
    <section className="join-now-section">
      <div className="join-now-outer">
        <div className="join-now-inner" style={{ width: "70%" }}>
          <h2>Benefits of NobelPage</h2>
          <p>
            Discover limitless opportunities with A NobelPage Membership! Access
            exclusive content, connect with industry leaders, and unlock your
            full potential.
          </p>
        </div>
        <span className="join-now-btn">
          <Link
            to={process.env.REACT_APP_WEB_STAGE_URL}
            target="blank"
            className="text-dark"
          >
            Join Now <img src={arrow} alt="arrow" />
          </Link>
        </span>
      </div>
    </section>
  );
};

export default JoinUsSection;
