import React, { useEffect, useState } from "react";
import cover from "../../../assets/img/portal-cover.png";
import companylogo from "../../../assets/img/company-name.svg";
import findjob from "../../../assets/img/find-job.svg";
import search from "../../../assets/img/Search-white.svg";
import "./PortalDashboard.scss";
import Autocomplete from "react-autocomplete";
import JobCard from "./JobCard";
import { FormGroup, Input } from "reactstrap";
import BenifitCard from "./BenifitCard";
import CardCarousel from "./CardCarousel";
import FooterJobUpdates from "../portalFooter/FooterJobUpdates";
import PortalFooter from "../portalFooter/PortalFooter";
import { useDispatch, useSelector } from "react-redux";
import placeholderUser from "../../../assets/img/default_profile_sqr img.svg";
import user_logout from "../../../assets/img/Logout.svg";
import searchIcon from "../../../assets/img/Search-white.svg";
import { useNavigate, useParams } from "react-router-dom";
import { getUserProfile } from "../../../store/profile/profileActions";
import { logoutPortal } from "../../../store/portal/portalAction";
import {
  createSearchHistory,
  getAllPortalJobs,
  getJobs,
  getSearchHistory,
} from "../../../store/jobs/jobAction";
import { getLocationsData } from "../../../store/connections/connectionsActions";
import InfiniteScroll from "react-infinite-scroll-component";
import { ToastContainer } from "react-toastify";
import AppLoader from "../../../core/components/Loaders/appLoader/appLoader";
import pagePlaceholder from "../../../assets/img/page-placeholder.svg";
const PortalDashboard = () => {
  const [themeColor, setThemeColor] = useState();
  const { profileData } = useSelector((state) => state.profile);
  const { getAllJobs, getAllFeaturedJobs } = useSelector((state) => state.jobs);
  const { searchHistory } = useSelector((state) => state.jobs);
  const { isLoading } = useSelector((state) => state.loader);
  const { talentPoolData,talentPoolDataByToken } = useSelector((state) => state.portal);
  const [profileDropdown, setProfileDropdown] = useState(false);
  const { param } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const token = localStorage.getItem("accessTpToken");
  const [currentCount, setCurrectCount] = useState(0);
  const [totalCount, setTotalCount] = useState(0);
  const [locationOpen, setLocationOpen] = useState(false);
  const [searchFieldValue, setSearchFieldValue] = useState(null);
  const [locationsAPIData, setLocationsAPIData] = useState([]);
  const [isAllJobs, setIsAllJobs] = useState(false);
  const [hasMore, setHasMore] = useState(true);
  const [isMostRelevent, setIsMostRelevent] = useState(false);
  const [isRecommended, setIsRecommended] = useState(false);
  const [workType, setWorkType] = useState("");
  const [isOlder, setIsOlder] = useState(false);
  const [locationDetails, setLocationDetails] = useState({
    location: searchFieldValue,
  });
  const [searchKey, setSearchKey] = useState("");
  const menuStyle = {
    fontSize: "14px",
    fontWeight: 500,
  };
  // useEffect(() => {
  //   dispatch(getTalentPoolDetailsByToken(param)).then((res) => {
  //     if (res?.success) setTpData(res?.data);
  //   });
  // }, []);
  useEffect(() => {
    if (talentPoolData)
      setThemeColor(
        talentPoolData?.theme ? talentPoolData.theme : "rgb(246, 138, 24)"
      );
    getFeaturedJobs();
  }, [talentPoolData]);
  useEffect(() => {
    // dispatch(getUserProfile(0, token));
    getPortalJobs();
    dispatch(getSearchHistory({ limit: 4, skip: 0, type: "job" }))
      .then((res) => {
        // if (!res?.data?.length) setIsViewMore(false);
      })
      .catch((err) => {});
  }, []);

  useEffect(() => {
    if (getAllJobs?.length < totalCount) setHasMore(true);
    else setHasMore(false);
  }, [currentCount, totalCount]);

  const searchJobs = () => {
    setCurrectCount(0);
    dispatch(
      createSearchHistory({ search: searchKey, type: "job", type_id: null })
    );
    dispatch(
      getAllPortalJobs(
        {
          limit: 10,
          skip: 0,
          search: searchKey,
          location: searchFieldValue,
          company_id: talentPoolData?.company_id,
          sort: "desc",
        },
        currentCount
      )
    ).then((res) => {
      setTotalCount(res?.totalItem);
    });
  };

  const getPortalJobs = () => {
    setCurrectCount(0);
    dispatch(
      getAllPortalJobs(
        {
          limit: 10,
          skip: 0,
          search: searchKey,
          location: searchFieldValue,
          company_id: talentPoolData?.company_id,
          sort: "desc",
          // employment_type: employmentValue,
          workspace_type: remoteJobs ? "Remote" : null,
          // salary: salaryEstimation,
          // smart_apply: smartApply,
        },
        currentCount
      )
    ).then((res) => {
      setTotalCount(res?.totalItem);
    });
  };

  // console.log("talentPoolData?.company_id", talentPoolData?.company_id);

  const getFeaturedJobs = () => {
    // setJobType("featured");
    setCurrectCount(0);
    dispatch(
      getAllPortalJobs(
        {
          limit: 30,
          skip: currentCount,
          filter: "allfeatured",
          company_id: talentPoolData?.company_id,
        },
        currentCount,
        "allfeatured"
      )
    ).then((res) => {
      // setCardData(res?.data);
      // setTotalCount(res?.totalItem);
      // setIsJobAlert(res?.jobAlert);
    });
  };

  const handleLogout = async () => {
    try {
      dispatch(logoutPortal())
        .then(() => {
          navigate(`/tp/portal/${param}/login`);
        })
        .catch(() => {
          navigate(`/tp/portal/${param}/login`);
        });
    } catch (e) {
      console.log(e);
    }
  };
  const inputProps = {
    placeholder: "Location",
    style: {
      fontSize: "14px",
      fontWeight: 500,
    },
  };
  const getLocations = ({ value }) => {
    if (value)
      dispatch(getLocationsData(value))
        .then((response) => {
          if (response.data) {
            setLocationsAPIData(response.data);
          } else setLocationsAPIData([]);
        })
        .catch((err) => {
          setLocationsAPIData([]);
        });
    else setLocationsAPIData([]);
  };

  const handleAllSearch = () => {
    setSearchKey("");
    setSearchFieldValue("");
    setIsAllJobs(true);
    setIsMostRelevent(false);
    setIsOlder(false);
    setIsRecommended(false);
    dispatch(
      getAllPortalJobs(
        {
          limit: 10,
          skip: 0,
          company_id: talentPoolData?.company_id,
          filter: "recommended",
          // workspace_type: remoteJobs ? "Remote" : null,
          // // salary: salaryEstimation,
          // smart_apply: smartApply,
          sort: "desc",
        },
        0
      )
    ).then((res) => {
      setTotalCount(res?.totalItem);
    });
  };

  const handleRelevent = () => {
    setIsAllJobs(false);
    setIsMostRelevent(true);
    setIsOlder(false);
    setIsRecommended(false);
  };
  const handleRecommended = () => {
    setCurrectCount(0);
    setIsAllJobs(false);
    setIsMostRelevent(false);
    setIsOlder(false);
    setIsRecommended(true);
    dispatch(
      getAllPortalJobs(
        {
          limit: 10,
          skip: 0,
          search: searchKey,
          location: searchFieldValue,
          company_id: talentPoolData?.company_id,
          filter: "recommended",
          workspace_type: remoteJobs ? "Remote" : null,
          // salary: salaryEstimation,
          // smart_apply: smartApply,
        },
        currentCount
      )
    ).then((res) => {
      setTotalCount(res?.totalItem);
    });
  };

  const handleOlder = () => {
    setCurrectCount(0);
    setIsAllJobs(false);
    setIsMostRelevent(false);
    setIsOlder(true);
    setIsRecommended(false);
    dispatch(
      getAllPortalJobs(
        {
          limit: 10,
          skip: 0,
          search: searchKey,
          location: searchFieldValue,
          company_id: talentPoolData?.company_id,
          sort: "asc",
          workspace_type: remoteJobs ? "Remote" : null,
          // salary: salaryEstimation,
          // smart_apply: smartApply,
        },
        currentCount
      )
    ).then((res) => {
      setTotalCount(res?.totalItem);
    });
  };
  const handleWorkType = (e) => {
    setCurrectCount(0);
    setWorkType(e.target.value);
    dispatch(
      getAllPortalJobs(
        {
          limit: 10,
          skip: 0,
          search: searchKey,
          location: searchFieldValue,
          company_id: talentPoolData?.company_id,
          employment_type: e.target.value,
          workspace_type: remoteJobs ? "Remote" : null,
        },
        currentCount
      )
    ).then((res) => {
      setTotalCount(res?.totalItem);
    });
  };
  const handleSearchKey = (search) => {
    if (search) setSearchKey(search);
  };
  const [remoteJobs, setRemoteJobs] = useState(false);

  const handleRemote = (e) => {
    setRemoteJobs(e.target.checked);
    dispatch(
      getAllPortalJobs(
        {
          limit: 10,
          skip: 0,
          search: searchKey,
          location: searchFieldValue,
          company_id: talentPoolData?.company_id,
          employment_type: workType,
          workspace_type: e.target.checked ? "Remote" : null,
          filter: "recommended",
        },
        0
      )
    ).then((res) => {
      setTotalCount(res?.totalItem);
      setCurrectCount(0);
    });
  };

  const loadMore = () => {
    const newSkip = currentCount + 10;
    // const skipforsearch = currentCount + 100;

    // if (searchKey) {
    //   dispatch(
    //     getJobs({ limit: 100, skip: skipforsearch, search: searchKey }, newSkip)
    //   ).then((res) => {
    //     setTotalCount(res.totalItem);
    //     setCurrectCount(newSkip);
    //     // setIsJobAlert(res?.jobAlert);
    //   });
    // } else
    if (workType) {
      dispatch(
        getAllPortalJobs(
          {
            limit: 10,
            skip: newSkip,
            search: searchKey,
            location: searchFieldValue,
            company_id: talentPoolData?.company_id,
            employment_type: workType,
            workspace_type: remoteJobs ? "Remote" : null,
          },
          currentCount
        )
      ).then((res) => {
        setTotalCount(res?.totalItem);
      });
    } else if (isMostRelevent) {
      dispatch(
        getAllPortalJobs(
          {
            limit: 10,
            skip: newSkip,
            search: searchKey,
            location: searchFieldValue,
            company_id: talentPoolData?.company_id,
            employment_type: workType,
            workspace_type: remoteJobs ? "Remote" : null,
            filter: "recommended",
          },
          currentCount
        )
      ).then((res) => {
        setTotalCount(res?.totalItem);
        setCurrectCount(newSkip);
      });
    } else if (isRecommended) {
      dispatch(
        getAllPortalJobs(
          {
            limit: 10,
            skip: newSkip,
            search: searchKey,
            location: searchFieldValue,
            company_id: talentPoolData?.company_id,
            employment_type: workType,
            workspace_type: remoteJobs ? "Remote" : null,
            filter: "recommended",
          },
          currentCount
        )
      ).then((res) => {
        setTotalCount(res?.totalItem);
        setCurrectCount(newSkip);
      });
    } else if (isOlder) {
      dispatch(
        getAllPortalJobs(
          {
            limit: 10,
            skip: newSkip,
            search: searchKey,
            location: searchFieldValue,
            company_id: talentPoolData?.company_id,
            employment_type: workType,
            workspace_type: remoteJobs ? "Remote" : null,
            sort: "asc",
          },
          currentCount
        )
      ).then((res) => {
        setTotalCount(res?.totalItem);
        setCurrectCount(newSkip);
      });
    } else
      dispatch(
        getAllPortalJobs(
          {
            limit: 10,
            skip: newSkip,
            search: searchKey,
            location: searchFieldValue,
            company_id: talentPoolData?.company_id,
            workspace_type: remoteJobs ? "Remote" : null,
          },
          newSkip
        )
      ).then((res) => {
        setTotalCount(res?.totalItem);
        setCurrectCount(newSkip);
      });
    // dispatch(getJobs({ limit: 10, skip: newSkip }, newSkip)).then((res) => {
    //   setTotalCount(res.totalItem);
    //   setCurrectCount(newSkip);
    //   // setIsJobAlert(res?.jobAlert);
    // });
  };

  const checkedStyle = {
    accentColor: themeColor,
    backgroundColor: themeColor,
  };
  return (
    <div className="portal-dashboard">
      {isLoading && <AppLoader />}
      <div className="portal-cover-img">
        <img
          src={
            talentPoolData?.background_img
              ? talentPoolData?.background_img
              : cover
          }
          alt="big-img"
          className="img-cover"
        />
        <div className="d-flex justify-content-center">
          <div className="header-row ">
            <div
              className="d-flex justify-content-sm-center"
              style={{ height: "40px", alignItems: "center" }}
            >
              <img
                src={
                  talentPoolData?.icon ? talentPoolData?.icon : pagePlaceholder
                }
                alt="companny-logo"
                style={{ width: "50px", height: "50px", borderRadius: "50%" }}
              />
              <h4 className="card-control-text-oneline ms-2">
                {talentPoolData?.company_name}
              </h4>
            </div>
            <div className="authenticate">
              {talentPoolData?.id!=talentPoolDataByToken?.id ? (
                <>
                  <p
                    className="login"
                    onClick={() => navigate(`/tp/portal/${param}/login`)}
                  >
                    Login
                  </p>
                  <div
                    className="btn btn-orange"
                    onClick={() => navigate(`/tp/portal/${param}/signup`)}
                    style={{ background: themeColor,whiteSpace:"nowrap" }}
                  >
                    Register Now
                  </div>
                </>
              ) : (
                <a className="nav-link">
                  <div className="prof" style={{ position: "sticky" }}>
                    <img
                      src={
                        profileData?.profile_img
                          ? profileData.profile_img
                          : placeholderUser
                      }
                      className="rounded-circle"
                      height={40}
                      width={40}
                      alt=""
                      onClick={() => setProfileDropdown(!profileDropdown)}
                    />
                    {profileDropdown ? (
                      <div className="admin" style={{ width: "170px" }}>
                        <ul>
                          <li className="dropdown-span">
                            <img
                              src={
                                profileData?.profile_img
                                  ? profileData.profile_img
                                  : placeholderUser
                              }
                              alt=""
                              style={{ height: "17px", width: "17px" }}
                            />
                            <span
                              onClick={() =>
                                navigate(`/tp/portal/${param}/profile/edit`)
                              }
                            >
                              View Profile
                            </span>
                          </li>
                          <li className="dropdown-span">
                            <img
                              src={user_logout}
                              alt="log_out"
                              style={{ height: "17px", width: "17px" }}
                            />
                            <span onClick={() => handleLogout()}>Log out</span>
                          </li>
                        </ul>
                      </div>
                    ) : (
                      <></>
                    )}
                  </div>
                </a>
              )}
            </div>
          </div>
        </div>

        <div className="center_banner_content">
          <div className="find-jobs">
            <img src={findjob} alt="find-jobs" />
          </div>
          <div className="testtt">
            <div className="search">
              <div>
                <Input
                  type="text"
                  value={searchKey}
                  // onChange={(e) => setSearchKey(e.target.value)}
                  onChange={(e) => {
                    handleSearchKey(e.target.value);
                    setSearchKey(e.target.value);
                  }}
                  placeholder="Search for jobs by skill or keyword"
                />
              </div>
              <div className="autocomplete-wrapper" style={{ width: "200px" }}>
                <Autocomplete
                  open={locationOpen}
                  inputProps={inputProps}
                  value={searchFieldValue}
                  items={locationsAPIData}
                  getItemValue={(item) => item.name}
                  shouldItemRender={() => locationsAPIData}
                  renderMenu={(items, value) => (
                    <div className="dropdown dropdown-location">
                      {items.length === 0 ? `No matches for ${value}` : items}
                    </div>
                  )}
                  renderItem={(item, isHighlighted) => (
                    <div
                      className={`item ${isHighlighted ? "selected-item" : ""}`}
                    >
                      {item.name}
                    </div>
                  )}
                  onChange={(e, val) => {
                    if (val.length === 0) {
                      if (locationOpen) setLocationOpen(false);
                    } else {
                      if (!locationOpen) setLocationOpen(true);
                    }
                    setSearchFieldValue(e.target.value);
                    getLocations(e.target);
                  }}
                  onSelect={(value, obj) => {
                    setSearchFieldValue(value);
                    setLocationDetails({
                      ...locationDetails,
                      location: obj.name,
                    });
                    setLocationOpen(false);
                  }}
                  wrapperStyle={{}}
                  menuStyle={menuStyle}
                />
              </div>
              <div
                className="search-icon"
                style={{ backgroundColor: themeColor }}
              >
                <img
                  src={searchIcon}
                  alt="search"
                  onClick={() => searchJobs()}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container" style={{ backgroundColor: "white" }}>
        <div className="heading">
          {getAllJobs?.length > 0 || getAllFeaturedJobs?.length > 0 ? (
            <h2>New Jobs</h2>
          ) : (
            <h4>No Jobs</h4>
          )}
        </div>
        <div>
          {getAllFeaturedJobs?.length > 2 ? (
            <div className="card-crousel">
              <CardCarousel
                featuredJobs={getAllFeaturedJobs}
                themeColor={themeColor}
                param={param}
                isLoggedIn={talentPoolData?.id===talentPoolDataByToken?.id}
              />{" "}
            </div>
          ) : getAllFeaturedJobs?.length == 2 ? (
            <div className="d-flex">
              <JobCard
                themeColor={themeColor}
                jobs={getAllFeaturedJobs[0]}
                param={param}
                isLoggedIn={talentPoolData?.id===talentPoolDataByToken?.id}
              />
              <JobCard
                themeColor={themeColor}
                jobs={getAllFeaturedJobs[1]}
                param={param}
                isLoggedIn={talentPoolData?.id===talentPoolDataByToken?.id}
              />
            </div>
          ) : getAllFeaturedJobs?.length == 1 ? (
            <div className="d-flex justify-content-center">
              <JobCard
                themeColor={themeColor}
                jobs={getAllFeaturedJobs[0]}
                param={param}
                isLoggedIn={talentPoolData?.id===talentPoolDataByToken?.id}
              />{" "}
            </div>
          ) : (
            <></>
          )}
        </div>
        <div className="row">
          <div className="col-lg-3 ">
            <h6>Filter</h6>
            <div className="d-flex  mt-3">
              <input
                type="radio"
                name="fav_language"
                className="me-3 custom-radio"
                style={{ backgroundColor: themeColor, accentColor: "#40a100c4"}}
                onClick={() => handleAllSearch()}
              />

              <p>All jobs</p>
            </div>

            <div className="d-flex   mt-3">
              <input
                type="radio"
                name="fav_language"
                className="me-3"
                style={{ backgroundColor: themeColor, accentColor: "#40a100c4" }}
                onClick={() => handleRelevent()}
              />
              <p>Most Relevant</p>
            </div>

            <div className="d-flex   mt-3">
              <input
                type="radio"
                name="fav_language"
                className="me-3"
                style={{ backgroundColor: themeColor, accentColor: "#40a100c4" }}
                onChange={() => handleRecommended()}
              />
              <p>Recommended</p>
            </div>
            <div className="d-flex  mt-3">
              <input
                type="radio"
                name="fav_language"
                className="me-3"
                style={{ backgroundColor: themeColor, accentColor: "#40a100c4" }}
                onClick={() => handleOlder()}
              />
              <p>Most Older</p>
            </div>
            <h6 className="mt-3">Type of Work</h6>
            <select
              className="form-select mt-2"
              aria-label="Default select example"
              onChange={(e) => handleWorkType(e)}
            >
              <option selected>All Types</option>
              <option value="Full-time">Full time</option>
              <option value="Part-time">Part time</option>
              <option value="Contract">Contract</option>
              <option value="Temporary">Temporary</option>
              <option value="Other">Other</option>
              <option value="Volunteer">Volunteer</option>
              <option value="Internship">Internship</option>
            </select>
            {/* <h6 className="mt-3">Industries</h6>
            <select
              className="form-select mt-2"
              aria-label="Default select example"
            >
              <option selected>All Industries</option>
              <option value="1">Technology</option>
              <option value="2">Marketing</option>
            </select> */}

            <FormGroup switch className="mt-3 d-flex">
              <Input
                type="switch"
                role="switch"
                className="me-3"
                style={remoteJobs ? checkedStyle : {}}
                checked={remoteJobs}
                onChange={(e) => {
                  handleRemote(e);
                  // setRemoteJobs(e.target.checked);
                }}
              />
              <p>Only Remote</p>
            </FormGroup>
          </div>
          <div className="col-lg-6">
            {/* <InfiniteScroll
              dataLength={getAllJobs?.length}
              next={() => loadMore()}
              hasMore={hasMore}
              style={{ overflow: "hidden" }}
            > */}
            {getAllJobs?.map((itm, idx) => (
              <JobCard
                key={idx}
                themeColor={themeColor}
                jobs={itm}
                param={param}
                isLoggedIn={talentPoolData?.id===talentPoolDataByToken?.id}
              />
            ))}
            {hasMore && getAllJobs?.length > 0 && (
              <p
                onClick={() => loadMore()}
                style={{
                  cursor: "pointer",
                  fontSize: "500",
                  marginLeft: "25px",
                }}
              >
                Load More...
              </p>
            )}
            {/* </InfiniteScroll> */}
          </div>
          <div className="col-lg-3 right-side">
            <div className="recent-views">
              {searchHistory?.length > 0 && <h6 className="">Recent Views</h6>}
              {searchHistory?.map((item, index) => {
                return (
                  <p className="mt-3" key={index}>
                    {item?.search}
                  </p>
                );
              })}
            </div>
            <div style={{position:"relative"}}>
              <BenifitCard />
            </div>
          </div>
        </div>
        <FooterJobUpdates themeColor={themeColor} />
      </div>
      <PortalFooter talentPoolData={talentPoolData} />
      <ToastContainer />
    </div>
  );
};

export default PortalDashboard;
