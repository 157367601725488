import React from "react";
import { Routes, Route } from "react-router-dom";
import Phone from "../pages/Phone";
import SignupContainer from "./Signup/Signup.container";
import ProfileContainer from "./profile-pages/profile/Profile.container";
import IntroContainer from "./Intro/Intro.container";
import Otp from "./Otp/Otp";
import LegalContainer from "./Legal/Legal.container";
import HelpContainer from "./Help/Help.container";
import ImportantQuestions from "./Help/importantQuestions";
import Listing from "./Help/Listing";
import Feedback from "../pages/Feedback";
import DirectoryContainer from "../pages/Directory/Directory.container";
import LandingPage from "../pages/LandingPage";
import ChangePassword from "./ChangePassword/ChangePassword";
import routeNames from "./routeNames";
import Banner from "../core/components/layout/Banner/Banner";
import AboutContainer from "./About/About.container";
import Membership from "./membership/membership";
import Forgot from "./Forget/Forget";
import { LinkedInCallback } from "react-linkedin-login-oauth2";
import Error from "../routes/error/error";
import PrivateRoute from "./PrivateRoute";
import Explore from "../routes/explore/Explore.container";
import Mobile from "./mobile/Mobile";
import Setting from "./setting/Setting";
import ExploreDetails from "../routes/explore/ExploreDetails/ExploreDetails";
import BlogContainer from "./Blog/Blog.Container";
import BlogDetail from "./Blog/BlogDetails/BlogDetails";
import DashboardContainer from "./dashboard/Dashboard.container";
import Message from "../pages/message/Message";
import MyNetWork from "./mynetwork/MyNetwork";
import PostsContainer from "./mynetwork/Posts/Posts.container";
import EventsContainer from "./mynetwork/Events/Events.container";
import CommunityContainer from "./Community/Community.container";
import NewsletterContainer from "./mynetwork/Newsletter/Newsletter.container";
import ArticleContainer from "./mynetwork/Articles/Article.container";
import ConnectionsContainer from "./Connections/Connections.Container";
import NewsletterDetailsContainer from "./mynetwork/Newsletter/NewsletterDetails/NewsletterDetails.container";
import ViewerList from "../routes/profile-pages/profile/components/viewerList/viewerList.Cointainer";
import GroupsContainer from "./mynetwork/Groups/Groups.container";
import PeoplesContainer from "./mynetwork/People/Peoples.container";
import HashtagContainer from "./mynetwork/Hashtag/Hashtag.container";
import PagesContainer from "./mynetwork/Pages/Pages.container";
import EventDetailsContainer from "./mynetwork/Events/event-details/EventDetails.container";
import ArticleDetailsContainer from "./mynetwork/Articles/ArticleDetails/ArticleDetails.container";
import GroupsDetailsContainer from "./mynetwork/Groups/group-details/GroupDetails.container";
import JobDetails from "../pages/Jobdetails/JobDetails";
import { ApplicationNobel } from "../pages/applicationnobel/ApplicationNobel";
import Cv from "../pages/CV/Cv";
import PageAdminDetailsContainer from "./mynetwork/Pages/pageDetails/PageAdminDetails.container";
import InvitationsContainer from "./mynetwork/Invitations/Invitations.container";
import PostDetailsContainer from "./dashboard/PostDetails/postDetails.container";
import CampaignContainer from "./Campaign/Campaign/Campaign.container";
import CreateCampaigncontainer from "./Campaign/CreateCampaign/CreateCampaign.container";
import HashtagPostsContainer from "./dashboard/hashtagPosts/hashtagPosts.container";
import CompanyDetails from "../pages/Directory/CompanyDetails";
import LeadsManageContainer from "./LeadsManagement/LeadManage.container";
import WalletContainer from "./wallet/Wallet.container";
import WalletHistory from "./wallet/components/WalletHistory";
import SignupPortalContainer from "./Portal/Signup/SignupPortalContainer";
import ForgotPass from "./Portal/forgotPassword/ForgetPass";
import LoginPortalContainer from "./Portal/Login/LoginPortalContainer";
import PortalDashboardContainer from "./Portal/findJobs/PortalDashboardContainer";
import MembershipPlan from "./membership/MembershipPlan";
import PortalJobDetail from "./Portal/jobDetails/PortalJobDetail";
import EditPortalProfile from "./Portal/completeProfile/EditPortalProfile";
import PortalWelcome from "./Portal/welcome/PortalWelcome";
import TalentPoolDashboard from "./talentPool/talentPoolDashboard/TalentPoolDashboard";
import ApplicantDeatil from "./talentPool/talentPoolDashboard/ApplicantDetail";
import JobsContainer from "./Jobs/Jobs.container";
import PostJobContainer from "./Jobs/PostJob/PostJob.container";
import RecruiterContainer from "./Jobs/Recruiter/Recruiter.container";
import JobSearch from "./JobSearch/JobSearch";
import CheckAutoCompleteModal from "./dashboard/components/createPost/autocompletes/CheckAutoCompleteModal";
import InterviewPreperation from "./JobSearch/InterviewPreperation";
import MyJobs from "./JobSearch/MyJobs";
import Notifications from "./Notifications/Notification";
import PeopleDirectoryContainer from "../pages/Directory/member/PeopleDirectoryContainer";
import MemberDetails from "../pages/Directory/member/MemberDetails";
import ArticlePublicDetails from "../pages/Directory/articles/ArticlePublicDetails";
import ArticleDirectoryContainer from "../pages/Directory/articles/ArticleDirectoryContainer";
import NewsletterDirectoryContainer from "../pages/Directory/newsletters/NewsletterDirectoryContainer";
import NewsletterPublicDetails from "../pages/Directory/newsletters/NewsletterPublicDetails";
import PostDirectoryContainer from "../pages/Directory/posts/PostDirectoryContainer";
import PostPublicDetails from "../pages/Directory/posts/PostPublicDetails";
import HiringModals from "./Hiring/HiringModal";
import SettingModals from "./setting/modals/SettingModals";
import RecruiterModals from "./Jobs/Recruiter/RecruiterModals/RecruiterModals";
import SmartInterview from "./Jobs/Recruiter/SmartInterview";
import ShortlistedCandidates from "./Jobs/components/ShortlistedCandidates/ShortlistedCandidates";
import JobModal from "./JobSearch/JobModal";
import CVContainer from "../pages/CV/CVContainer";
import GiveSmartInterview from "./Jobs/Recruiter/GiveSmartInterview/GiveSmartInterview";
import WatchSmartInterview from "./Jobs/Recruiter/WatchSmartInterview/WatchSmartInterview";
import TPPrivateRoute from "./TPPrivateRoute";
import VerifyOtp from "./Portal/verifyOtp/VerifyOtp";
import ResetPassword from "./Portal/changePassword/ChangePassword";
import JobsDirectory from "../pages/Directory/jobs/JobsDirectory";
import JobDetail from "../pages/Directory/jobs/JobDetail";
import Otp2fa from "./2fa/Otp2fa";
import MemberDetail from "./talentPool/talentPoolDashboard/MemberDetail";
import { BackgroundServiceScreen } from "./backgroundScreening/backgroundServiceScreen";
import BackgroundReportScreen from "./backgroundScreening/backgroundReportScreen";
import { CreateOrderContainer } from "./backgroundScreening/createOrder/createOrderContainer";
import OrderPreview from "./backgroundScreening/createOrder/OrderPreview";
import ScreeningPayment from "./backgroundScreening/createOrder/ScreeningPayment";
import BackgroundReportInfo from "./backgroundScreening/backgroundReportInfo";
import SearchScreen from "./search/SearchScreen";
import PageJobs from "./mynetwork/Pages/components/PageJobs";
import ComingSoonDetailPage from "./mynetwork/Pages/ComingSoon/ComingSoonDetailPage";
import CreateArticleNew from "./mynetwork/Articles/CreateArticleNew";
import CreateNewsletter from "./mynetwork/Newsletter/components/createLetter";
import CreateArticleMobile from "./mynetwork/Articles/createArticleMobile";

const Router = () => (
  <Routes>
    <Route path={routeNames.HOME} element={<LandingPage  />} />
    <Route path={routeNames.OTP2FA} element={<Otp2fa />} />
    <Route path={routeNames.ABOUT} element={<AboutContainer />} />
    <Route path={routeNames.BANNER} element={<Banner />} />
    <Route path={routeNames.FORGET} element={<Forgot />} />
    <Route path={routeNames.PHONE} element={<Phone />} />
    <Route path={routeNames.SIGNUP} element={<SignupContainer />} />
    <Route path={routeNames.OTP} element={<Otp />} />
    <Route path={routeNames.CHANGE_PASSWORD} element={<ChangePassword />} />
    <Route path={routeNames.LEGAL} element={<LegalContainer />} />
    <Route path={routeNames.COMMUNITY} element={<CommunityContainer />} />
    <Route path={routeNames.HELP} element={<HelpContainer />} />
    <Route
      path={routeNames.IMPORTANTQUESTION}
      element={<ImportantQuestions />}
    />
    <Route path={routeNames.LISTINGPAGE} element={<Listing />} />
    <Route path={routeNames.FEEDBACK} element={<Feedback />} />
    {/* directories */}
    <Route path={routeNames.DIRECTORY} element={<DirectoryContainer />} />
    <Route path={routeNames.COMPANYDETAIL} element={<CompanyDetails />} />
    <Route
      path={routeNames.MEMBERDIRECTORY}
      element={<PeopleDirectoryContainer />}
    />
    <Route path={routeNames.MEMBERDETAIL} element={<MemberDetails />} />
    <Route
      path={routeNames.ARTICLEDIRECTORY}
      element={<ArticleDirectoryContainer />}
    />
    <Route
      path={routeNames.ARTICLEPUBLICDETAIL}
      element={<ArticlePublicDetails />}
    />
    <Route
      path={routeNames.NEWSLETTERDIRECTORY}
      element={<NewsletterDirectoryContainer />}
    />
    <Route
      path={routeNames.NEWSLETTERPUBLICDETAIL}
      element={<NewsletterPublicDetails />}
    />
    <Route
      path={routeNames.POSTDIRECTORY}
      element={<PostDirectoryContainer />}
    />
    <Route path={routeNames.POSTPUBLICDETAIL} element={<PostPublicDetails />} />
    <Route path={routeNames.JOBDIRECTORY} element={<JobsDirectory />} />
    <Route path={routeNames.JOBPUBLICDETAIL} element={<JobDetail />} />

    <Route path={routeNames.BLOG} element={<BlogContainer />} />
    <Route path={routeNames.EXPLORE} element={<Explore />} />
    <Route
      exact
      path={routeNames.EXPLOREDETAILS}
      element={<ExploreDetails />}
    />
    <Route path={routeNames.BLOGDETAIL} element={<BlogDetail />} />
    <Route path={routeNames.MOBILE} element={<Mobile />} />
    <Route path={routeNames.CONNECTIONS} element={<ConnectionsContainer />} />
    <Route path={routeNames.NETWORK} element={<MyNetWork />} />
    <Route path={routeNames.INVITATION} element={<InvitationsContainer />} />
    <Route path={routeNames.MYPOSTS} element={<PostsContainer />} />
    <Route path={routeNames.MYEVENTS} element={<EventsContainer />} />
    <Route path={routeNames.GROUPS} element={<GroupsContainer />} />
    <Route path={routeNames.PEOPLES} element={<PeoplesContainer />} />
    <Route path={routeNames.HASHTAG} element={<HashtagContainer />} />
    <Route
      path={routeNames.NEWSLETTERDETAIL}
      element={<NewsletterDetailsContainer />}
    />
    <Route
      path={routeNames.ARTICLEDETAIL}
      element={<ArticleDetailsContainer />}
    />
    <Route path={routeNames.EVENTDETAILS} element={<EventDetailsContainer />} />
    <Route
      path={routeNames.GROUPDETAILS}
      element={<GroupsDetailsContainer />}
    />
    <Route
      path={routeNames.CREATEARTICLENEW}
      element={<CreateArticleNew/>}
    />
  <Route
      path={routeNames.CREATEARTICLEMOBILE}
      element={<CreateArticleMobile/>}
    />
<Route
      path={routeNames.CREATENEWSLETTERNEW}
      element={<CreateNewsletter/>}
    />

    {/* intro  */}
    <Route
      path={routeNames.INTRO}
      element={
        <PrivateRoute>
          <IntroContainer />
        </PrivateRoute>
      }
    />
    <Route path={routeNames.MEMBERSHIP} element={<Membership />} />
    <Route path={routeNames.MEMBERSHIPPLAN} element={<MembershipPlan />} />
    <Route
      path={routeNames.DASHBOARD}
      element={
        <PrivateRoute>
          <DashboardContainer />
        </PrivateRoute>
      }
    />
    {/* pages */}
    <Route path={routeNames.PAGES} element={<PagesContainer />} />
    <Route
      path={routeNames.PAGEADMINVIEW}
      element={<PageAdminDetailsContainer />}
    />
    <Route path={routeNames.CHECKAUTO} element={<CheckAutoCompleteModal />} />
    {/* campaign */}
    <Route path={routeNames.CAMPAIGN} element={<CampaignContainer />} />
    <Route
      path={routeNames.CREATECAMPAIGN}
      element={<CreateCampaigncontainer />}
    />

    {/* lead_management */}
    <Route path={routeNames.LEADS} element={<LeadsManageContainer />} />

    {/* wallet */}
    <Route path={routeNames.WALLET} element={<WalletContainer />} />
    <Route path={routeNames.WALLETHISTORY} element={<WalletHistory />} />

    {/* jobs */}
    <Route path={routeNames.JOBS} element={<JobsContainer />} />
    <Route path={routeNames.POSTJOB} element={<PostJobContainer />} />

    {/* recruiter */}
    <Route path={routeNames.RECRUITER} element={<RecruiterContainer />} />
    <Route path={routeNames.RECRUITERMODALS} element={<RecruiterModals />} />
    <Route path={routeNames.SMARTINTERVIEW} element={<SmartInterview />} />

    <Route
      path={routeNames.GIVESMARTINTERVIEW}
      element={
        <PrivateRoute>
          <GiveSmartInterview />
        </PrivateRoute>
      }
    />
    <Route
      path={routeNames.WATCHSMARTINTERVIEW}
      element={
        <PrivateRoute>
          <WatchSmartInterview />
        </PrivateRoute>
      }
    />

    {/* job details */}
    <Route path={routeNames.JOBDETAILS} element={<JobDetails />} />
    <Route path={routeNames.APPLICATIONNOBEL} element={<ApplicationNobel />} />
    <Route
      path={routeNames.SHORTLISTEDCANDIDATES}
      element={<ShortlistedCandidates />}
    />

    {/* job search */}
    <Route path={routeNames.JOBSSEARCH} element={<JobSearch />} />
    <Route path={routeNames.JOBMODAL} element={<JobModal />} />

    {/* my jobs */}
    <Route path={routeNames.MYJOBS} element={<MyJobs />} />

    {/* notifications */}
    <Route path={routeNames.NOTIFICATIONS} element={<Notifications />} />
    {/* interview preperation */}
    <Route
      path={routeNames.INTERVIEWPREPERATION}
      element={<InterviewPreperation />}
    />

    {/* hiring modals */}
    <Route path={routeNames.HIRINGMODALS} element={<HiringModals />} />

    {/* CV*/}
    <Route
      path={routeNames.CV}
      element={
        <PrivateRoute>
          <Cv />
        </PrivateRoute>
      }
    />

    {/* intro  */}
    <Route exact path="/linkedin" element={<LinkedInCallback />} />
    <Route path="*" element={<Error />} />

    {/* profile */}
    <Route
      path={routeNames.PROFILE}
      element={
        <PrivateRoute>
          <ProfileContainer />
        </PrivateRoute>
      }
    />
    {/* <Route
      path={routeNames.USER_PROFILE}
      element={
        <PrivateRoute>
          <ProfileContainer />
        </PrivateRoute>
      }
    /> */}
    {/* Add Profile Views component here */}
    <Route
      path={routeNames.PROFILE_VIEWS}
      element={
        <PrivateRoute>
          <ViewerList />
        </PrivateRoute>
      }
    />
    {/* message */}
    <Route path={routeNames.MESSAGE} element={<Message />} />
    {/* Newsletter */}
    <Route path={routeNames.NEWSLETTER} element={<NewsletterContainer />} />
    <Route path={routeNames.ARTICLE} element={<ArticleContainer />} />

    {/* setting  */}
    <Route path={routeNames.SETTING} element={<Setting />} />
    {/* <Route path={routeNames.PROFILE_WEBVIEW} element={<ProfileContainer />} /> */}
    <Route path={routeNames.POSTDETAILS} element={<PostDetailsContainer />} />
    <Route path={routeNames.HASHTAGPOSTS} element={<HashtagPostsContainer />} />

    {/* setting Modals */}
    <Route path={routeNames.SETTINGMODALS} element={<SettingModals />} />

    {/* portal */}
    <Route path={routeNames.PORTALSIGNUP} element={<SignupPortalContainer />} />
    <Route path={routeNames.PORTALLOGIN} element={<LoginPortalContainer />} />
    <Route path={routeNames.PORTALFORGOT} element={<ForgotPass />} />
    <Route
      path={routeNames.PORTALDASHBOARD}
      element={<PortalDashboardContainer />}
    />
    <Route path={routeNames.VERIFYOTP} element={<VerifyOtp />} />
    <Route
      path={routeNames.PORTALJOBDETAILS}
      element={
        <TPPrivateRoute>
          <PortalJobDetail />
        </TPPrivateRoute>
      }
    />
    <Route
      path={routeNames.PORTALEDITPROFILE}
      element={
        <TPPrivateRoute>
          <EditPortalProfile />
        </TPPrivateRoute>
      }
    />
    <Route
      path={routeNames.PORTALWELCOME}
      element={
        <TPPrivateRoute>
          <PortalWelcome />
        </TPPrivateRoute>
      }
    />
    <Route path={routeNames.RESETPASSWORD} element={<ResetPassword />} />
    {/* Talent Pool */}
    <Route
      path={routeNames.TALENTPOOLDASHBOARD}
      element={
        <PrivateRoute>
          <TalentPoolDashboard />
        </PrivateRoute>
      }
    />
    <Route
      path={routeNames.APPLICANTDETAILS}
      element={
        <PrivateRoute>
          <ApplicantDeatil />
        </PrivateRoute>
      }
    />
    <Route
      path={routeNames.TPMEMBEREDTAILS}
      element={
        <PrivateRoute>
          <MemberDetail />
        </PrivateRoute>
      }
    />
    <Route
      path={routeNames.BACKGROUNDSERVICESCREEN}
      element={
        <PrivateRoute>
          <BackgroundServiceScreen />
        </PrivateRoute>
      }
    />
    <Route
      path={routeNames.BACKGROUNDREPORTSCREEN}
      element={
        <PrivateRoute>
          <BackgroundReportScreen />
        </PrivateRoute>
      }
    />
    <Route
      path={routeNames.CREATEORDER}
      element={
        <PrivateRoute>
          <CreateOrderContainer />
        </PrivateRoute>
      }
    />
    <Route
      path={routeNames.ORDERPREVIEW}
      element={
        <PrivateRoute>
          <OrderPreview />
        </PrivateRoute>
      }
    />

    <Route
      path={routeNames.SCREENINGPAYMENT}
      element={
        <PrivateRoute>
          <ScreeningPayment />
        </PrivateRoute>
      }
    />

    <Route
      path={routeNames.REPORTINFO}
      element={
        <PrivateRoute>
          <BackgroundReportInfo />
        </PrivateRoute>
      }
    />

    <Route
      path={routeNames.SEARCH}
      element={
        <PrivateRoute>
          <SearchScreen />
        </PrivateRoute>
      }
    />
    <Route
    path={routeNames.PAGEJOBS}
    element={
      <PrivateRoute>
        <PageJobs/>
      </PrivateRoute>
    }
      />

<Route
  path={routeNames.COMINGSOONDETAILPAGE}
    element={
      <PrivateRoute>
        <ComingSoonDetailPage/>
      </PrivateRoute>
    }
      />


  </Routes>
);

export default Router;
