import React, { useState } from "react";
import edits from "../../../../assets/img/main_edit.svg";
import CustomProfileModal from "../../../../routes/setting/components/CustomProfileModal";
import { WEB_URL } from "../../../../config";

export const LinkSidebar = (props) => {
  const { profileURL, customProfileModal, setCustomProfileModal } = props;

  return (
    <div>
      <div
        className="link-sidebar mt-20"
        style={{
          backgroundColor: "white",
          borderRadius: "5px",
          padding: "20px",
        }}
      >
        <div className="box ">
          {/* <div className="padding">
            <h6>Add profile in another language</h6>
            <div className="flex">
              <span className="active me-5">English</span>
              <span>Arabic</span>
              <div className="close">X</div>
            </div>
          </div> */}
          {/* <div className="line"></div> */}
          <div className="padding">
            <div className="link">
              <h6 style={{ display: "flex", justifyContent: "space-between" }}>
                Edit your custom URL
                <div className="edit" style={{ cursor: "pointer" }}>
                  <img
                    src={edits}
                    alt=""
                    onClick={() => setCustomProfileModal(!customProfileModal)}
                  />
                </div>
              </h6>
              <p
                style={{ fontSize: "15px", wordBreak: "break-word" }}
                className="mt-10"
              >
                {WEB_URL}profile/
                {profileURL}
              </p>
            </div>
          </div>
        </div>
      </div>
      {customProfileModal && (
        <CustomProfileModal
          modal={customProfileModal}
          toggle={() => setCustomProfileModal(!customProfileModal)}
          title="Custom Profile"
          profileURL={profileURL}
        />
      )}
    </div>
  );
};
