import React from "react";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import FormInput from "../../../../core/components/Inputs/FormInput";
import {
  confirmPassTransferPage,
  transferPageAdmin,
} from "../../../../store/pages/pagesAction";
import Loader from "../../../../core/components/Loaders/ComponentLoader";
import DeleteModal from "../../../../core/components/modal/DeleteModal";
import { toast } from "react-toastify";
import AppLoader from "../../../../core/components/Loaders/appLoader/appLoader";
const ConfirmPassword = (props) => {
  const { modal, toggle, title, selected, pageID, setAddPageAdminModal } =
    props;
  const [password, setPassword] = useState("");
  const [passErrorMessage, setPassErrorMessage] = useState("");
  const [closeModal, setCloseModal] = useState(false);
  const { isPageLoading } = useSelector((state) => state.loader);
  const dispatch = useDispatch();
  const message = `You won't be able to revert this!
  Are you sure? You will transfer all page admin rights to
     ${selected.first_name + " " + selected.last_name}`;
  const validation = (password) => {
    let passwordValid =
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;
    if (!password) {
      setPassErrorMessage("Please Enter Your Password");
      return;
    }
    if (!passwordValid.test(password)) {
      setPassErrorMessage(
        'The password should be minimum 8 characters long and contain at least one : Upper and Lower Case Alphabet, Number, Special Character. The special characters that can be used are [!""#$%&\'()*+,-./:;<=>?@[]^_`{|}~]'
      );
      return;
    }
    handleSubmit();
  };
  const navigateToPageAdmin = () => {
    window.location.reload();
  };

  const confirmPass = () => {
    const temp = { password };
    dispatch(confirmPassTransferPage(temp)).then((res) => {
      if (res.success == true) {
        setCloseModal(true);
      }
    });
  };
  const transferSuperAdmin = () => {
    dispatch(transferPageAdmin(pageID, selected.id)).then((res) => {
      if (res.success === true) {
        closeModals();
        navigateToPageAdmin();
      } else alert(res.message);
    });
  };
  const handelPassChange = (e) => {
    setPassword(e.target.value);
    setPassErrorMessage("");
  };
  const closeModals = () => {
    setAddPageAdminModal(false);
    toggle();
  };
  const handleSubmit = async () => {
    confirmPass(password);
  };
  return (
    <>
      {isPageLoading && <AppLoader />}
      <Modal
        isOpen={modal}
        size="lg"
        style={{ maxWidth: "600px", maxHeight: "300px" }}
        className="topDiv gradient-background"
        centered
      >
        <ModalHeader className="header px-5" toggle={toggle}>
          {title}
        </ModalHeader>
        <ModalBody>
          <div className="admin-popup">
            <div className="container">
              <div className="p-0 bg-white">
                <div className="row mb-4">
                  <div className="col-lg-12">
                    <p>Please confirm by entering your password.</p>
                    <div className="d-flex position-relative form-input">
                      <FormInput
                        maxLength="24"
                        type="password"
                        placeholder="Password"
                        error={passErrorMessage}
                        onChange={handelPassChange}
                        // containerClassName ="confirmpass"
                        containerClassName={
                          "confirmpass " +
                          (passErrorMessage ? "error-form" : "")
                        }
                        style={{ paddingLeft: "10px" }}
                      />
                    </div>
                    <div className="text-center">
                      <button
                        className="btn btn-blue mt-4"
                        onClick={() => validation(password)}
                      >
                        Confirm
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </ModalBody>
      </Modal>
      {closeModal && (
        <DeleteModal
          modal={DeleteModal}
          toggle={() => setCloseModal(!closeModal)}
          title=" Transform Confirmation"
          deleteConfirm={() => {
            transferSuperAdmin();
          }}
          text={message}
        />
      )}
    </>
  );
};

export default ConfirmPassword;
