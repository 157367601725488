import React from "react";
import { Modal, ModalBody, ModalHeader } from "reactstrap";

const ScheduleMeetingModal = ({ modal, toggle, title }) => {
  return (
    <>
      <Modal
        size="xl"
        isOpen={modal}
        className={"modal-size-sm popup-message-modal gradient-background"}
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        {
          <ModalHeader className="p-3" toggle={toggle}>
            <h3
              className="d-flex gap-3 align-items-center"
              style={{ color: "rgb(255, 255, 255)", fontSize: "15px" }}
            >
              {title}
            </h3>
          </ModalHeader>
        }
        <ModalBody>
          <div className="set-away-messages-popup mt-2">
            <div className="set-away-messages-popup-outer">
              <div className="heading mb-10">
                <h5>Here is the link to your meeting </h5>
              </div>
              <div className="row">
                <div className="col-lg-12">
                  <p>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                    Nunc vulputate libero et velit interdum, ac aliquet odio
                    mattis.
                  </p>
                  <div className="inputs">
                    <input
                      type="text"
                      className="form-control my-5"
                      placeholder="Add title"
                    />
                    <div className="box mt-30">
                      <p>https://nobelmeet.google.com/bhz-wdbb-gnt</p>
                    </div>
                  </div>
                </div>
                <div className="buttons mt-5">
                  <button className="btn btn-blue">Copy Meeting Link</button>
                </div>
              </div>
            </div>
          </div>
        </ModalBody>
      </Modal>
    </>
  );
};
export default ScheduleMeetingModal;
