import React, { useState, useEffect } from "react";
import CommentsComp from "./comments";
import { useDispatch, useSelector } from "react-redux";
import {
  getAllComments,
  addComments,
  addCommentReply,
  getAllCommentReply,
  pinComment,
  block_comment,
} from "../../../../store/dashboard/dashboardActions";
import { reactionsCommentData } from "../../../../store/reactionPost/reactionAction";
import {
  deleteCommentData,
  deleteReplyData,
} from "../../../../store/dashboard/dashboardActions";
import moment from "moment";
import { useRef } from "react";
import { toast } from "react-toastify";

export default function CommentsContainer(props) {
  const {
    postId,
    dropDown,
    setDropDown,
    item,
    userId,
    setPostID,
    reportModal,
    setReportModal,
    reportType,
    setReportType,
    reportCommentId,
    setReportCommentId,
    type,
    isPagePosts,
    selectedUserId,
    likeResult
  } = props;
  const { user } = useSelector((state) => state.auth);
  const [addComment, setAddComment] = useState("");
  const [reply, setReply] = useState("");
  const [showEmoji, setShowEmoji] = useState(false);
  const [showReplyEmoji, setShowReplyEmoji] = useState(false);
  const [currentCountComment, setCurrentCountComment] = useState(0);
  const [currentCountReplies, setCurrentCountReplies] = useState(0);
  const [replyBoxStatus, setReplyBoxStatus] = useState([]);
  const [totalComments, setTotalComments] = useState();
  const [totalReplies, setTotalReplies] = useState();
  const [replyId, setReplyId] = useState();
  const [closeCommentModal, setCloseCommentModal] = useState(false);
  const [commentDeleteId, setCommentDeleteId] = useState();
  const [closeReplyModal, setCloseReplyModal] = useState(false);
  const [replyDeleteId, setReplyDeleteId] = useState();
  const [dropDownComm, setDropDownComm] = useState(-1);
  const [dropDownReply, setDropDownReply] = useState(-1);
  const [shortedBy, setShortedBy] = useState("Most relevant");
  const [shortDropDown, setShortDropDown] = useState(false);
  const [isReplyDD, setIsReplyDD] = useState(false);
  const [confirmBlockModal, setConfirmBlockModal] = useState(false);
  const [blockUserId, setBlockUserId] = useState();
  const [blockCommentId, setBlockCommentId] = useState();
  const [updateComment, setUpdateComment] = useState();
  const [repliesCount, setRepliesCount] = useState();

  const dropRefComment = useRef(null);
  const dropRefReplies = useRef(null);
  const inputComment = useRef(null);
  const dispatch = useDispatch();
  const token = window.localStorage.getItem("accessToken");

  useEffect(() => {
    let limit = currentCountComment === 0 ? 2 : 10;
    let skip = currentCountComment;
    if (postId) {
      dispatch(
        getAllComments(token, {
          post_id: postId.id,
          limit,
          skip,
          sort_by: shortedBy.slice(5),
        })
      ).then((response) => {
        setTotalComments(response.items);
      });
    }
  }, [currentCountComment]);


  const getRecentComments = () => {
    let limit = currentCountComment === 0 ? 2 : 10;
    let skip = currentCountComment;
    dispatch(
      getAllComments(token, {
        post_id: postId.id,
        limit,
        skip,
        sort_by: "recent",
      })
    ).then((response) => {
      setTotalComments(response.items);
    });
  };
  const getReleventComments = () => {
    let limit = currentCountComment === 0 ? 2 : 10;
    let skip = currentCountComment;
    dispatch(
      getAllComments(token, {
        post_id: postId.id,
        limit,
        skip,
        sort_by: "relevant",
      })
    ).then((response) => {
      setTotalComments(response.items);
    });
  };

  const onEmojiClick = (emojiData, event) => {
    let emojiText = addComment;
    emojiText += emojiData.emoji;
    setAddComment(emojiText);
    // setShowEmoji(false)
  };

  const onReplyEmojiClick = (emojiData, event) => {
    // postDetails.description += emojiData.emoji;
    // setPostDetails({ ...postDetails });
    let text = reply
      ? reply?.objects[`${replyId}`]?.value + emojiData.emoji
      : emojiData.emoj;
    setReply((state) => {
      const newObject = { ...state.objects };
      newObject[`${replyId}`] = { value: text, key: replyId };
      return { objects: newObject };
    });
  };

  const handleChange = (event) => {
    setAddComment(event.target.value);
  };

  const handleSubmit = (event) => {
    let data = { post_id: postId.id, comment: addComment };
    if (item?.as_a_page) {
      Object.assign(data, { page_id: item?.as_a_page?.id });
    } 
    else if(isPagePosts && selectedUserId) {
      Object.assign(data, { page_id: item?.Page?.id });
    }
    let token = window.localStorage.getItem("accessToken");
    if (event.keyCode === 13) {
      dispatch(addComments(data, token));
      setAddComment("");
    }
  };

  const pinCommentToTop = (commentId) => {
    let data = { post_id: postId.id, comment_id: commentId };
    let unPin = false;
    dispatch(pinComment(data, token, unPin));
    setDropDownComm(-1);
    setDropDownReply(-1);
  };

  const UnpinComment = () => {
    let data = { post_id: postId.id, comment_id: null };
    let unPin = true;
    dispatch(pinComment(data, token, unPin));
    setDropDownComm(-1);
    setDropDownReply(-1);
  };

  const deleteComment = (commentId) => {
    let data = { type_id: postId.id, type: "post", id: commentId };
    dispatch(deleteCommentData(data, token, repliesCount)).then((response) => {
      toast.success(response.message, {
        position: toast.POSITION.BOTTOM_LEFT,
      });
    });
  };

  const deleteReply = (replyId, commentId) => {
    let data = { type_id: postId.id, type: "post", id: replyId };
    let token = window.localStorage.getItem("accessToken");
    dispatch(deleteReplyData(data, commentId, token)).then((response) => {
      toast.success(response.message, {
        position: toast.POSITION.BOTTOM_LEFT,
      });
    });
  };

  const handleReply = (event, id) => {
    setReply((state) => {
      const newObject = { ...state.objects };
      newObject[`${id}`] = { value: event.target.value, key: id };
      return { objects: newObject };
    });
  };

  const handleReplySubmit = (event, parent_id) => {
    if (event.keyCode === 13) {
      let data = {
        post_id: postId.id,
        comment: reply?.objects[`${parent_id}`]?.value,
        parent_id,
      };
      if (item?.as_a_page) {
        Object.assign(data, { page_id: item?.as_a_page?.id });
      }
      dispatch(addCommentReply(data, token));
      setReply("");
      setShowReplyEmoji(false);
    }
  };

  function handleCommentsReaction(comment, reacted, type, commentId) {
    let data = {
      reaction_type: "comment",
      reaction_type_id: comment.id,
      type: reacted,
    };
    if (item?.as_a_page) {
      Object.assign(data, { page_id: item?.as_a_page?.id });
    }
    dispatch(reactionsCommentData(data, postId.id, type, commentId));
  }

  const loadMoreCommentData = () => {
    if (currentCountComment === 0) {
      setCurrentCountComment(2);
    } else {
      setCurrentCountComment(currentCountComment + 10);
    }
  };

  const getCommentReply = (comment_id) => {
    let limit = 2;
    // let skip = currentCountReplies === 0 ? 1 : currentCountReplies + 1;
    // let limit = currentCountReplies === 0 ? 2 : 10;
    let skip = currentCountReplies + 1;
    if (postId) {
      dispatch(
        getAllCommentReply(token, {
          post_id: postId.id,
          parent_id: comment_id,
          limit,
          skip,
        })
      ).then((res) => {
        setTotalReplies(res.items);
      });
    }
    setCurrentCountReplies(currentCountReplies + 2);
  };

  const blockComment = () => {
    let data = {
      type: "post",
      type_id: blockCommentId,
      user_id: blockUserId,
    };
    setConfirmBlockModal(!confirmBlockModal);
    dispatch(block_comment(data)).then((response) => {
      toast.success(response.message, {
        position: toast.POSITION.BOTTOM_LEFT,
      });
    });
  };

  const getTime = (date) => {
    let result = moment(date).fromNow();
    const now = moment();
    const hours = now.diff(date, "hours");
    const days = now.diff(date, "days");
    const weeks = now.diff(date, "weeks");
    if (hours >= 22 && hours <= 47) {
      result = `1 day ago`;
    } else if (days >= 7) {
      if (days <= 13) {
        result = `1 week ago`;
      } else if (days > 13 && days <= 25) {
        result = `${weeks} weeks ago`;
      }
    }
    return result;
  };

  // console.log(repliesCount);

  return (
    <>
      <CommentsComp
        commentData={postId?.commentData}
        addComment={addComment}
        setAddComment={setAddComment}
        handleChange={handleChange}
        handleSubmit={handleSubmit}
        handleReplySubmit={handleReplySubmit}
        handleReply={handleReply}
        reply={reply}
        showEmoji={showEmoji}
        setShowEmoji={setShowEmoji}
        onEmojiClick={onEmojiClick}
        user={user}
        handleCommentsReaction={handleCommentsReaction}
        loadMoreCommentData={loadMoreCommentData}
        totalComments={totalComments}
        totalReplies={totalReplies}
        replyBoxStatus={replyBoxStatus}
        setReplyBoxStatus={setReplyBoxStatus}
        getCommentReply={getCommentReply}
        getTime={getTime}
        showReplyEmoji={showReplyEmoji}
        setShowReplyEmoji={setShowReplyEmoji}
        onReplyEmojiClick={onReplyEmojiClick}
        setReplyId={setReplyId}
        deleteComment={deleteComment}
        pinCommentToTop={pinCommentToTop}
        UnpinComment={UnpinComment}
        deleteReply={deleteReply}
        closeCommentModal={closeCommentModal}
        setCloseCommentModal={setCloseCommentModal}
        commentDeleteId={commentDeleteId}
        setCommentDeleteId={setCommentDeleteId}
        closeReplyModal={closeReplyModal}
        setCloseReplyModal={setCloseReplyModal}
        replyDeleteId={replyDeleteId}
        setReplyDeleteId={setReplyDeleteId}
        dropDownComm={dropDownComm}
        setDropDownComm={setDropDownComm}
        dropDownReply={dropDownReply}
        setDropDownReply={setDropDownReply}
        dropRefComment={dropRefComment}
        item={item}
        userId={userId}
        setPostID={setPostID}
        reportModal={reportModal}
        setReportModal={setReportModal}
        reportType={reportType}
        setReportType={setReportType}
        reportCommentId={reportCommentId}
        setReportCommentId={setReportCommentId}
        shortedBy={shortedBy}
        setShortedBy={setShortedBy}
        shortDropDown={shortDropDown}
        setShortDropDown={setShortDropDown}
        isReplyDD={isReplyDD}
        setIsReplyDD={setIsReplyDD}
        dropRefReplies={dropRefReplies}
        confirmBlockModal={confirmBlockModal}
        setConfirmBlockModal={setConfirmBlockModal}
        setBlockCommentId={setBlockCommentId}
        setBlockUserId={setBlockUserId}
        blockComment={blockComment}
        getRecentComments={getRecentComments}
        getReleventComments={getReleventComments}
        type={type}
        setRepliesCount={setRepliesCount}
        isPagePosts={isPagePosts}
        selectedUserId={selectedUserId}
      />
    </>
  );
}
