import React, { useEffect, useState } from "react";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import { ToastContainer, toast } from "react-toastify";
import tick from "../../../assets/img/tick-icon-grey.svg";
import InfoIcon from "../../../assets/img/infoIcon.png";
import { useDispatch } from "react-redux";
import {
  getUserSetting,
  updateSettingDetails,
} from "../../../store/setting/SettingAction";

const SocialMediaModal = (props) => {
  const { modal, toggle, title, data, centered } = props;

  const [socialData, setSocialData] = useState({
    facebook_link: "",
    facebook_toggle: 0,
    instagram_link: "",
    instagram_toggle: 0,
    twitter_link: "",
    twitter_toggle: 0,
    linkedin_link: "",
    linkedin_toggle: 0,
    facebook_addlink: false,
    twitter_addlink: false,
    linkedin_addlink: false,
    instagram_addlink: false,
    facebook_embed: false,
    twitter_embed: false,
    linkedin_embed: false,
    instagram_embed: false,
    facebook_input: "",
    twitter_input: "",
    linkedin_input: "",
    instagram_input: "",
    facebook_embed_input: "",
    twitter_embed_input: "",
    linkedin_embed_input: "",
    instagram_embed_input: "",
  });
  let token = window.localStorage.getItem("accessToken");

  const socialLinksRegex = {
    facebook:
      /https?:\/\/(www\.)?facebook\.com\/(share\/r\/[A-Za-z0-9]+|photo\/\?fbid=\d+(&set=a\.\d+)?|[A-Za-z0-9_\.]+\/(posts|videos|photos|watch|permalink\.php\?story_fbid=|story\.php\?story_fbid=|groups\/[^\/]+\/permalink|events\/[^\/]+\/permalink|photo\.php\?fbid=)\d+|[A-Za-z0-9_\.]+\/?[^\/\s]+)/,
    instagram:
      /https?:\/\/(www\.)?instagram\.com\/(p\/[\w-]+\/?|tv\/[\w-]+\/?|reel\/[\w-]+\/?|stories\/[^\/]+\/\d+\/?|explore\/tags\/[\w-]+\/?)/,
    linkedin:
      /https?:\/\/(www\.)?linkedin\.com\/embed\/feed\/update\/urn:li:[^:]+:\d+/,
    twitter:
      /https?:\/\/(www\.)?(twitter\.com|x\.com)\/(i\/web\/status\/|status\/|tweet\/status\/)?(\d+|[\w-]+\/status\/\d+)/,
  };

  const handleCross = (name) => {
    if (name == "facebook") {
      socialData.facebook_addlink = false;
      socialData.facebook_input = "";
      socialData.facebook_embed = false;
      socialData.facebook_embed_input = "";
    }
    if (name == "twitter") {
      socialData.twitter_addlink = false;
      socialData.twitter_input = "";
      socialData.twitter_embed = false;
      socialData.twitter_embed_input = "";
    }
    if (name == "instagram") {
      socialData.instagram_addlink = false;
      socialData.instagram_input = "";
      socialData.instagram_embed = false;
      socialData.instagram_embed_input = "";
    }
    if (name == "linkedin") {
      socialData.linkedin_addlink = false;
      socialData.linkedin_input = "";
      socialData.linkedin_embed = false;
      socialData.linkedin_embed_input = "";
    }
    setSocialData({ ...socialData });
  };

  const handleSaveClick = (name) => {
    if (name == "facebook") {
      if (
        socialData.facebook_input &&
        socialLinksRegex.facebook.test(socialData.facebook_input)
      ) {
        socialData.facebook_addlink = false;
        socialData.facebook_link = socialData.facebook_input;
        socialData.facebook_input = "";
        setSocialData({ ...socialData });
        updateMode(10, socialData.facebook_link);
      } else
        toast.error(
          "Facebook link is not valid. Please read the instructions."
        );
    }
    if (name == "instagram") {
      if (
        socialData.instagram_input &&
        socialLinksRegex.instagram.test(socialData.instagram_input)
      ) {
        socialData.instagram_addlink = false;
        socialData.instagram_link = socialData.instagram_input;
        socialData.instagram_input = "";
        setSocialData({ ...socialData });
        updateMode(12, socialData.instagram_link);
      } else
        toast.error(
          "Instagram link is not valid. Please read the instructions."
        );
    }
    if (name == "twitter") {
      if (
        socialData.twitter_input &&
        socialLinksRegex.twitter.test(socialData.twitter_input)
      ) {
        socialData.twitter_addlink = false;
        socialData.twitter_link = socialData.twitter_input;
        socialData.twitter_input = "";
        setSocialData({ ...socialData });
        updateMode(14, socialData.twitter_link);
      } else
        toast.error("Twitter link is not valid. Please read the instructions.");
    }
    if (name == "linkedin") {
      if (
        socialData.linkedin_input &&
        socialLinksRegex.linkedin.test(socialData.linkedin_input)
      ) {
        socialData.linkedin_addlink = false;
        socialData.linkedin_link = socialData.linkedin_input;
        socialData.linkedin_input = "";
        setSocialData({ ...socialData });
        updateMode(16, socialData.linkedin_link);
      } else
        toast.error(
          "Linkedin link is not valid. Please read the instructions."
        );
    }
  };

  const updateToggle = (name, value) => {
    try {
      let updateData = {};
      if (value) {
        if (name == "facebook") {
          socialData.facebook_toggle = 1;
          updateData = {
            setting: [
              {
                setting_master_id: 11,
                value: 1 + "",
              },
            ],
          };
        }
        if (name == "twitter") {
          socialData.twitter_toggle = 1;
          updateData = {
            setting: [
              {
                setting_master_id: 15,
                value: 1 + "",
              },
            ],
          };
        }
        if (name == "instagram") {
          socialData.instagram_toggle = 1;
          updateData = {
            setting: [
              {
                setting_master_id: 13,
                value: 1 + "",
              },
            ],
          };
        }
        if (name == "linkedin") {
          socialData.linkedin_toggle = 1;
          updateData = {
            setting: [
              {
                setting_master_id: 17,
                value: 1 + "",
              },
            ],
          };
        }
      } else {
        if (name == "facebook") {
          socialData.facebook_toggle = 0;
          updateData = {
            setting: [
              {
                setting_master_id: 11,
                value: 0 + "",
              },
            ],
          };
        }
        if (name == "twitter") {
          updateData = {
            setting: [
              {
                setting_master_id: 15,
                value: 0 + "",
              },
            ],
          };
          socialData.twitter_toggle = 0;
        }
        if (name == "instagram") {
          socialData.instagram_toggle = 0;
          updateData = {
            setting: [
              {
                setting_master_id: 13,
                value: 0 + "",
              },
            ],
          };
        }
        if (name == "linkedin") {
          socialData.linkedin_toggle = 0;
          updateData = {
            setting: [
              {
                setting_master_id: 17,
                value: 0 + "",
              },
            ],
          };
        }
      }
      setSocialData({ ...socialData });
      dispatch(updateSettingDetails(updateData, token)).then((res) => {
        toast.success(res.message, {
          position: toast.POSITION.BOTTOM_LEFT,
        });
        dispatch(getUserSetting(0, token));
      });
    } catch (error) {
      console.log(error);
    }
  };

  const handleEmbedCode = (name) => {
    if (name == "facebook") {
      if (socialData.facebook_embed_input) {
        const iframe = socialData.facebook_embed_input.split("?");

        const encodedURL = new URLSearchParams(
          iframe?.length > 1 ? iframe[1] : ""
        )?.get("href");
        const decodedURL = decodeURIComponent(encodedURL);

        if (decodedURL && socialLinksRegex.facebook.test(decodedURL)) {
          socialData.facebook_embed = false;
          socialData.facebook_link = decodedURL;
          socialData.facebook_embed_input = "";
          setSocialData({ ...socialData });
          updateMode(10, decodedURL);
        } else
          toast.error("Facebook Embed Code is not valid. Please try again.");
      } else toast.error("Facebook Embed Code is required.");
    }
    if (name == "instagram") {
      if (socialData.instagram_embed_input) {
        const iframe = socialData.instagram_embed_input;

        const doc = new DOMParser().parseFromString(iframe, "text/html");
        const decodedURL = doc
          ?.querySelector("blockquote")
          ?.getAttribute("data-instgrm-permalink");

        if (decodedURL && socialLinksRegex.instagram.test(decodedURL)) {
          socialData.instagram_embed = false;
          socialData.instagram_link = decodedURL;
          socialData.instagram_embed_input = "";
          setSocialData({ ...socialData });
          updateMode(12, decodedURL);
        } else
          toast.error("Instagram Embed Code is not valid. Please try again.");
      } else toast.error("Instagram Embed Code is required.");
    }
    if (name == "twitter") {
      if (socialData.twitter_embed_input) {
        const iframe = socialData.twitter_embed_input;

        const doc = new DOMParser().parseFromString(iframe, "text/html");
        const decodedURL = doc
          ?.querySelector('blockquote a[href^="https://twitter.com"]')
          ?.getAttribute("href");

        if (decodedURL && socialLinksRegex.twitter.test(decodedURL)) {
          socialData.twitter_embed = false;
          socialData.twitter_link = decodedURL;
          socialData.twitter_embed_input = "";
          setSocialData({ ...socialData });
          updateMode(14, decodedURL);
        } else
          toast.error("Twitter Embed Code is not valid. Please try again.");
      } else toast.error("Twitter Embed Code is required.");
    }
    if (name == "linkedin") {
      if (socialData.linkedin_embed_input) {
        const iframe = socialData.linkedin_embed_input;

        const doc = new DOMParser().parseFromString(iframe, "text/html");
        const decodedURL = doc?.querySelector("iframe")?.getAttribute("src");

        if (decodedURL && socialLinksRegex.linkedin.test(decodedURL)) {
          socialData.linkedin_embed = false;
          socialData.linkedin_link = decodedURL;
          socialData.linkedin_embed_input = "";
          setSocialData({ ...socialData });
          updateMode(16, decodedURL);
        } else
          toast.error("Linkedin Embed Code is not valid. Please try again.");
      } else toast.error("Linkedin Embed Code is required.");
    }
  };

  const dispatch = useDispatch();
  // const { user } = useSelector((state) => state.auth);

  useEffect(() => {
    let temp = {};
    for (let index = 0; index < data?.settings.length; index++) {
      const element = data?.settings[index];
      temp[element.key] = element.value;
    }
    setSocialData({ ...socialData, ...temp });
  }, [data]);

  const addLink = (name) => {
    try {
      socialData[name] = true;
      if (name?.split("_")[1] == "addlink")
        socialData[`${name?.split("_")[0]}_input`] =
          socialData[`${name?.split("_")[0]}_link`];
      setSocialData({ ...socialData });
    } catch (error) {
      console.log(error);
    }
  };

  const handleDelete = (id) => {
    try {
      let updateData = {
        setting: [
          {
            setting_master_id: id,
            value: "",
          },
        ],
      };
      dispatch(updateSettingDetails(updateData, token)).then((res) => {
        dispatch(getUserSetting(0, token));
        toast.success(res.message, {
          position: toast.POSITION.BOTTOM_LEFT,
        });
      });
    } catch (error) {
      console.log(error);
    }
  };

  const updateMode = (rowValue, socialValueUpdate) => {
    try {
      let token = localStorage.getItem("accessToken");
      let updateData = {
        setting: [
          {
            setting_master_id: rowValue,
            value: socialValueUpdate,
          },
        ],
      };
      dispatch(updateSettingDetails(updateData, token)).then((res) => {
        dispatch(getUserSetting(0, token));
        toast.success(res.message, {
          position: toast.POSITION.BOTTOM_LEFT,
        });
      });
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div>
      <Modal
        isOpen={modal}
        size="lg"
        style={{ maxWidth: "574px" }}
        centered={centered || false}
      >
        <div className="popup">
          <div className="setting-popup">
            <ModalHeader toggle={toggle} id="account-modal">
              {title}
            </ModalHeader>
            <ModalBody>
              <div className="box social-media">
                <p style={{ marginBottom: "20px" }}>
                  Connect your social media channels to your profile to enhance
                  visibility and networking.{" "}
                  <span className="know-more" style={{ color: "#0432a3" }}>
                    Know more <img src={InfoIcon} width={15} />
                    <div className="tooltip-content">
                      <h5>Embed Guidance:</h5>
                      <ul>
                        <li>
                          <h6>Facebook:</h6>
                          <ul>
                            <li>
                              Click on the three dots in the top-right corner of
                              the post.
                            </li>
                            <li>Select "Embed" from the options.</li>
                            <li>Click on the Advanced settings.</li>
                            <li>Get url of post and add as a link.</li>
                          </ul>
                          <p>Or</p>
                          <ul>
                            <li>
                              Click on the three dots in the top-right corner of
                              the post.
                            </li>
                            <li>Select "Embed" from the options.</li>
                            <li>
                              Copy the embed code and paste it into embed box.
                            </li>
                          </ul>
                        </li>
                        <li>
                          <h6>Instagram:</h6>
                          <ul>
                            <li>Open a post and click on the three dots.</li>
                            <li>
                              Select "Copy Link" from the options. Copied URL
                              should be in the format:
                              "https://www.instagram.com/p/abc123xyzAB/"
                            </li>
                            <li>Paste the url as a link.</li>
                          </ul>
                          <p>Or</p>
                          <ul>
                            <li>Open a post and click on the three dots.</li>
                            <li>Select "Embed" from the options.</li>
                            <li>
                              Copy the embed code and paste it into embed box.
                            </li>
                          </ul>
                        </li>
                        <li>
                          <h6>LinkedIn:</h6>
                          <ul>
                            <li>
                              Click on the three dots in the top-right corner of
                              the post.
                            </li>
                            <li>Select "Embed this post." from the options.</li>
                            <li>
                              Copy the embed code and paste it into embed box or
                              Use the "src" attribute of the iframe and add as a
                              link.
                            </li>
                          </ul>
                        </li>
                        <li>
                          <h6>Twitter:</h6>
                          <ul>
                            <li>Click on the "Share" icon below the tweet.</li>
                            <li>
                              Select "Copy link." from the options. Copied URL
                              should be in the format:
                              "https://twitter.com/username/status/1234567890123456789".
                            </li>
                            <li>Paste the url as a link.</li>
                          </ul>
                          <p>Or</p>
                          <ul>
                            <li>
                              Click on the three dots in the top-right corner of
                              the post.
                            </li>
                            <li>Select "Embed Post" from the options.</li>
                            <li>
                              Copy the embed code and paste it into embed box.
                            </li>
                          </ul>
                        </li>
                      </ul>
                    </div>
                    <div className="tooltip-arrow"></div>
                  </span>
                </p>
                {/* facebook code start here  */}
                <div className="social-links">
                  <div className="d-flex">
                    <div className="icon">
                      <div className="facebook"></div>
                    </div>
                    {socialData.facebook_addlink ? (
                      <>
                        <div className="link">
                          <input
                            type="text"
                            className="form-control"
                            value={socialData.facebook_input}
                            readonly
                            onChange={(e) => {
                              socialData.facebook_input = e.target.value;
                              setSocialData({ ...socialData });
                            }}
                            onKeyDown={(e) =>
                              e?.keyCode == 13 && handleSaveClick("facebook")
                            }
                          />
                        </div>
                        <div className="edit-box">
                          <img
                            src={tick}
                            alt=""
                            style={{ width: "15px" }}
                            onClick={() => handleSaveClick("facebook")}
                          />
                          <div
                            className="close"
                            onClick={() => handleCross("facebook")}
                          >
                            X
                          </div>
                        </div>
                      </>
                    ) : socialData.facebook_embed ? (
                      <>
                        <div className="link">
                          <textarea
                            type="text"
                            className="form-control"
                            value={socialData.facebook_embed_input}
                            onChange={(e) => {
                              socialData.facebook_embed_input = e.target.value;
                              setSocialData({ ...socialData });
                            }}
                          />
                        </div>
                        <div className="edit-box">
                          <img
                            src={tick}
                            alt=""
                            style={{ width: "15px" }}
                            onClick={() => handleEmbedCode("facebook")}
                          />
                          <div
                            className="close"
                            onClick={() => handleCross("facebook")}
                          >
                            X
                          </div>
                        </div>
                      </>
                    ) : (
                      <>
                        {socialData.facebook_link ? (
                          <div
                            className="link"
                            onClick={() => {
                              addLink("facebook_addlink");
                            }}
                          >
                            <span className="card-control-text-oneline">
                              {socialData.facebook_link}
                            </span>
                          </div>
                        ) : (
                          <div
                            className="link"
                            onClick={() => {
                              addLink("facebook_addlink");
                            }}
                          >
                            <span className="text-blue cursor-pointer">
                              + Add link
                            </span>
                          </div>
                        )}

                        <div className="d-flex">
                          {socialData.facebook_link ? (
                            <div className="edit-box">
                              {/* <div
                                className="link"
                                onClick={() => {
                                 addLink("facebook_addlink");
                                }}
                              >
                                {" "}
                                <span>Edit</span>{" "}
                              </div> */}
                              <div
                                className="pencil"
                                onClick={() => {
                                  addLink("facebook_addlink");
                                }}
                              ></div>
                              <div
                                className="embed"
                                onClick={() => {
                                  addLink("facebook_embed");
                                }}
                              >
                                {"</>"}
                              </div>
                              <div
                                className="delete"
                                onClick={() => handleDelete(10)}
                              ></div>
                            </div>
                          ) : (
                            <div className="edit-box">
                              <div
                                className="embed"
                                onClick={() => {
                                  addLink("facebook_embed");
                                }}
                              >
                                {"</>"}
                              </div>
                            </div>
                          )}
                          <div className="switches">
                            <label className="switch">
                              <input
                                type="checkbox"
                                checked={+socialData.facebook_toggle}
                                onChange={(e) => {
                                  socialData.facebook_link ||
                                  !e?.target?.checked
                                    ? updateToggle(
                                        "facebook",
                                        e?.target?.checked
                                      )
                                    : toast.error("Facebook link is required.");
                                }}
                              />
                              <span className="slider round"></span>
                            </label>
                          </div>
                        </div>
                      </>
                    )}
                  </div>
                </div>

                {/* Instagram code start here */}
                <div className="social-links">
                  <div className="d-flex">
                    <div className="icon">
                      <div className="instagram"></div>
                    </div>
                    {socialData.instagram_addlink ? (
                      <>
                        <div className="link">
                          <input
                            type="text"
                            className="form-control"
                            value={socialData.instagram_input}
                            readonly
                            onChange={(e) => {
                              socialData.instagram_input = e.target.value;
                              setSocialData({ ...socialData });
                            }}
                            onKeyDown={(e) =>
                              e.keyCode == 13 && handleSaveClick("instagram")
                            }
                          />
                        </div>
                        <div className="edit-box">
                          <img
                            src={tick}
                            alt=""
                            style={{ width: "15px" }}
                            onClick={() => handleSaveClick("instagram")}
                          />
                          <div
                            className="close"
                            onClick={() => handleCross("instagram")}
                          >
                            X
                          </div>
                        </div>
                      </>
                    ) : socialData.instagram_embed ? (
                      <>
                        <div className="link">
                          <textarea
                            type="text"
                            className="form-control"
                            value={socialData.instagram_embed_input}
                            onChange={(e) => {
                              socialData.instagram_embed_input = e.target.value;
                              setSocialData({ ...socialData });
                            }}
                          />
                        </div>
                        <div className="edit-box">
                          <img
                            src={tick}
                            alt=""
                            style={{ width: "15px" }}
                            onClick={() => handleEmbedCode("instagram")}
                          />
                          <div
                            className="close"
                            onClick={() => handleCross("instagram")}
                          >
                            X
                          </div>
                        </div>
                      </>
                    ) : (
                      <>
                        {socialData.instagram_link ? (
                          <div
                            className="link"
                            onClick={() => {
                              addLink("instagram_addlink");
                            }}
                          >
                            <span className="card-control-text-oneline">
                              {socialData.instagram_link}
                            </span>
                          </div>
                        ) : (
                          <div
                            className="link"
                            onClick={() => {
                              addLink("instagram_addlink");
                            }}
                          >
                            <span className="text-blue cursor-pointer">
                              + Add link
                            </span>
                          </div>
                        )}

                        <div className="d-flex">
                          {socialData.instagram_link ? (
                            <div className="edit-box">
                              {/* <div
                                className="link"
                                onClick={() => {
                                  addLink("instagram_addlink");
                                }}
                              >
                                {" "}
                                <span>Edit</span>{" "}
                              </div> */}
                              <div
                                className="pencil"
                                onClick={() => {
                                  addLink("instagram_addlink");
                                }}
                              ></div>
                              <div
                                className="embed"
                                onClick={() => {
                                  addLink("instagram_embed");
                                }}
                              >
                                {"</>"}
                              </div>
                              <div
                                className="delete"
                                onClick={() => handleDelete(12)}
                              ></div>
                            </div>
                          ) : (
                            <div className="edit-box">
                              <div
                                className="embed"
                                onClick={() => {
                                  addLink("instagram_embed");
                                }}
                              >
                                {"</>"}
                              </div>
                            </div>
                          )}
                          <div className="switches">
                            <label className="switch">
                              <input
                                type="checkbox"
                                checked={+socialData.instagram_toggle}
                                onChange={(e) => {
                                  socialData.instagram_link ||
                                  !e?.target?.checked
                                    ? updateToggle(
                                        "instagram",
                                        e?.target?.checked
                                      )
                                    : toast.error(
                                        "Instagram link is required."
                                      );
                                }}
                              />
                              <span className="slider round"></span>
                            </label>
                          </div>
                        </div>
                      </>
                    )}
                  </div>
                </div>

                {/* twitter code start here */}
                <div className="social-links">
                  <div className="d-flex">
                    <div className="icon">
                      <div className="twitter"></div>
                    </div>
                    {socialData.twitter_addlink ? (
                      <>
                        <div className="link">
                          <input
                            type="text"
                            className="form-control"
                            value={socialData.twitter_input}
                            readonly
                            onChange={(e) => {
                              socialData.twitter_input = e.target.value;
                              setSocialData({ ...socialData });
                            }}
                            onKeyDown={(e) =>
                              e.keyCode == 13 && handleSaveClick("twitter")
                            }
                          />
                        </div>
                        <div className="edit-box">
                          <img
                            src={tick}
                            alt=""
                            style={{ width: "15px" }}
                            onClick={() => handleSaveClick("twitter")}
                          />
                          <div
                            className="close"
                            onClick={() => handleCross("twitter")}
                          >
                            X
                          </div>
                        </div>
                      </>
                    ) : socialData.twitter_embed ? (
                      <>
                        <div className="link">
                          <textarea
                            type="text"
                            className="form-control"
                            value={socialData.twitter_embed_input}
                            onChange={(e) => {
                              socialData.twitter_embed_input = e.target.value;
                              setSocialData({ ...socialData });
                            }}
                          />
                        </div>
                        <div className="edit-box">
                          <img
                            src={tick}
                            alt=""
                            style={{ width: "15px" }}
                            onClick={() => handleEmbedCode("twitter")}
                          />
                          <div
                            className="close"
                            onClick={() => handleCross("twitter")}
                          >
                            X
                          </div>
                        </div>
                      </>
                    ) : (
                      <>
                        {socialData.twitter_link ? (
                          <div
                            className="link"
                            onClick={() => {
                              addLink("twitter_addlink");
                            }}
                          >
                            <span className="card-control-text-oneline">
                              {socialData.twitter_link}
                            </span>
                          </div>
                        ) : (
                          <div
                            className="link"
                            onClick={() => {
                              addLink("twitter_addlink");
                            }}
                          >
                            <span className="text-blue cursor-pointer">
                              + Add link
                            </span>
                          </div>
                        )}

                        <div className="d-flex">
                          {socialData.twitter_link ? (
                            <div className="edit-box">
                              {/* <div
                                className="link"
                                role="button"
                                onClick={() => {
                                  addLink("twitter_addlink");
                                }}
                              >
                                {" "}
                                <span>Edit</span>{" "}
                                </div> */}
                              <div
                                className="pencil"
                                onClick={() => {
                                  addLink("twitter_addlink");
                                }}
                              ></div>
                              <div
                                className="embed"
                                onClick={() => {
                                  addLink("twitter_embed");
                                }}
                              >
                                {"</>"}
                              </div>
                              <div
                                className="delete"
                                onClick={() => handleDelete(14)}
                              ></div>
                            </div>
                          ) : (
                            <div className="edit-box">
                              <div
                                className="embed"
                                onClick={() => {
                                  addLink("twitter_embed");
                                }}
                              >
                                {"</>"}
                              </div>
                            </div>
                          )}
                          <div className="switches">
                            <label className="switch">
                              <input
                                type="checkbox"
                                checked={+socialData.twitter_toggle}
                                onChange={(e) => {
                                  socialData.twitter_link || !e?.target?.checked
                                    ? updateToggle(
                                        "twitter",
                                        e?.target?.checked
                                      )
                                    : toast.error("Twitter link is required.");
                                }}
                              />
                              <span className="slider round"></span>
                            </label>
                          </div>
                        </div>
                      </>
                    )}
                  </div>
                </div>

                {/* linked code start here */}
                <div className="social-links">
                  <div className="d-flex">
                    <div className="icon">
                      <div className="linkdin"></div>
                    </div>
                    {socialData.linkedin_addlink ? (
                      <>
                        <div className="link">
                          <input
                            type="text"
                            className="form-control"
                            value={socialData.linkedin_input}
                            readonly
                            onChange={(e) => {
                              socialData.linkedin_input = e.target.value;
                              setSocialData({ ...socialData });
                            }}
                            onKeyDown={(e) =>
                              e.keyCode == 13 && handleSaveClick("linkedin")
                            }
                          />
                        </div>
                        <div className="edit-box">
                          <img
                            src={tick}
                            alt=""
                            style={{ width: "15px" }}
                            onClick={() => handleSaveClick("linkedin")}
                          />
                          <div
                            className="close"
                            onClick={() => handleCross("linkedin")}
                          >
                            X
                          </div>
                        </div>
                      </>
                    ) : socialData.linkedin_embed ? (
                      <>
                        <div className="link">
                          <textarea
                            type="text"
                            className="form-control"
                            value={socialData.linkedin_embed_input}
                            onChange={(e) => {
                              socialData.linkedin_embed_input = e.target.value;
                              setSocialData({ ...socialData });
                            }}
                          />
                        </div>
                        <div className="edit-box">
                          <img
                            src={tick}
                            alt=""
                            style={{ width: "15px" }}
                            onClick={() => handleEmbedCode("linkedin")}
                          />
                          <div
                            className="close"
                            onClick={() => handleCross("linkedin")}
                          >
                            X
                          </div>
                        </div>
                      </>
                    ) : (
                      <>
                        {socialData.linkedin_link ? (
                          <div
                            className="link"
                            onClick={() => {
                              addLink("linkedin_addlink");
                            }}
                          >
                            <span className="card-control-text-oneline">
                              {socialData.linkedin_link}
                            </span>
                          </div>
                        ) : (
                          <div
                            className="link"
                            onClick={() => {
                              addLink("linkedin_addlink");
                            }}
                          >
                            <span className="text-blue cursor-pointer">
                              + Add link
                            </span>
                          </div>
                        )}

                        <div className="d-flex">
                          {socialData.linkedin_link ? (
                            <div className="edit-box">
                              {/* <div
                                className="link"
                                onClick={() => {
                                  addLink("linkedin_addlink");
                                }}
                              >
                                {" "}
                                <span>Edit</span>{" "}
                              </div> */}
                              <div
                                className="pencil"
                                onClick={() => {
                                  addLink("linkedin_addlink");
                                }}
                              ></div>
                              <div
                                className="embed"
                                onClick={() => {
                                  addLink("linkedin_embed");
                                }}
                              >
                                {"</>"}
                              </div>
                              <div
                                className="delete"
                                onClick={() => handleDelete(16)}
                              ></div>
                            </div>
                          ) : (
                            <div className="edit-box">
                              <div
                                className="embed"
                                onClick={() => {
                                  addLink("linkedin_embed");
                                }}
                              >
                                {"</>"}
                              </div>
                            </div>
                          )}
                          <div className="switches">
                            <label className="switch">
                              <input
                                type="checkbox"
                                checked={+socialData.linkedin_toggle}
                                onChange={(e) => {
                                  socialData.linkedin_link ||
                                  !e?.target?.checked
                                    ? updateToggle(
                                        "linkedin",
                                        e?.target?.checked
                                      )
                                    : toast.error("Linkedin link is required.");
                                }}
                              />
                              <span className="slider round"></span>
                            </label>
                          </div>
                        </div>
                      </>
                    )}
                  </div>
                </div>
              </div>
            </ModalBody>
          </div>
        </div>
      </Modal>
      <ToastContainer />
    </div>
  );
};

export default SocialMediaModal;
