import { GET_CANDIDATE_LIST, GET_MORE_CANDIDATE_LIST } from "../types";

export const initialState = {
  candidateList: [],
};

const recuriter = (state = initialState, action) => {
  switch (action.type) {
    case GET_CANDIDATE_LIST:
      return { ...state, candidateList: action.payload };
    case GET_MORE_CANDIDATE_LIST:
      return {
        ...state,
        candidateList: [...state.candidateList, ...action.payload],
      };
    default:
      return state;
  }
};

export default recuriter;
