import React from "react";
import { Link } from "react-router-dom";
import tick from "../../assets/img/green-tick.svg";
import cross from "../../assets/img/cross.png";
import gold from "../../assets/img/gold.svg";
import { useState } from "react";
import { useEffect } from "react";

const MemberShipCards = (props) => {
  const {
    plan,
    index,
    membershipPlanName,
    detailPage,
    handleMore,
    isKnowMore,
    setIsKnowMore,
    isUserPlan,
  } = props;
  const [checkedFeature, setCheckedFeature] = useState();
  const [selectPlanName, setSelectPlanName] = useState("");

  useEffect(() => {
    const filteredData = plan?.feautures.filter((res) => res.checkmark == true);
    setCheckedFeature(filteredData);

    // console.log("plan", plan);
  }, []);

  useEffect(() => {
    if (!isKnowMore) setSelectPlanName("");
  }, [isKnowMore]);


  return (
    <div
      key={index}
      className={
        "col-md-3 box" +
        // (membershipPlanName === plan?.name && detailPage ? " box-active" : "")
        (isUserPlan?" box-active" : "")
      }
      style={{display:"flex",flexDirection:"column",justifyContent:"space-between" }}
    >
      <div
        style={{
          padding: "32px 32px 10px 32px",
          overflow: "auto",
          maxHeight: "875px",
        }}
      >
        <div className="flex">
          <h2>{plan?.name}</h2>
          {plan?.icon == "NP Gold" && <img src={gold} alt="" />}
        </div>
        <p className="card-control-text-fourline">{plan?.description}</p>
        <p style={{ marginBottom: "15px" }}>
          <span
            key={index}
            onClick={() => {
              // setIsKnowMore(!isKnowMore);
              // setSelectPlanName(plan?.name);
            }}
          >
            {" "}
            Know More
          </span>
        </p>
        {/* {selectPlanName === plan?.name && isKnowMore ? ( */}
        <ul>
          {plan?.feautures?.map((feature, index) => (
            <li>
              <img
                src={feature?.checkmark === true ? tick : cross}
                alt="checktrue"
                className="img-check"
              />
              {feature?.Feauture?.name}
            </li>
          ))}
        </ul>
      </div>
      <div style={{ position: "releative", bottom: "0px" }}>
        <div
          className={
            "line " + (plan?.name === "Starter" ? "mb-4 mt-rem" : "mb-3")
          }
        ></div>
        {/* <div className="line mb-3"></div> */}
        {/* <div className={"bottom " +(plan?.name === "Starter"?"pt-2 pb-4":"")}> */}
        <div className="bottom ">
          <div className="price">
            <span>
              {plan?.name === "Starter" ? (
                "Free"
              ) : (
                <>
                  <del>${plan?.yearlyTotalPrize}</del> ${plan?.originalYearPrize} /
                  Year
                </>
              )}
            </span>
          </div>
          <div className="sav my-2">
            {plan?.name !== "Starter" && (
              <span>You Saved {plan?.discount}%</span>
            )}
          </div>
          <div
            id="more-btn-card"
            className={"more " + (plan?.name === "Starter" ? "mt-32" : "mt-40")}
            //   style={{
            //     marginTop: "10px",
            //   }}
            onClick={() => handleMore(plan?.name, plan?.id)}
          >
            {isUserPlan ? (
              <>
                <span id="current-plan">Current plan</span>
                <span id="more-details">More Details</span>
              </>
            ) : (
              <>
                <span id="current-plan">More Details</span>
                <span id="more-details">More Details</span>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  
  );
};

export default MemberShipCards;
