import React, { useState } from "react";
import AddExperience from "./AddExperience";
import AddEducation from "./AddEducation";
import EditIntroFirst from "./EditIntroFirst";
import EditContactInfo from "./EditContactInfo";
import UploadResume from "./UploadResume";
import AddCoverLatter from "./AddCoverLatter";
import { ToastContainer } from "react-toastify";

const IntroProfile = (props) => {
  const { activeTab, setActiveTab, activePage, setActivePage, profileData } =
    props;

  return (
    <div >
    <div>
      {activePage == "intro" && activeTab == "profile" && (
        <EditIntroFirst
          activePage={activePage}
          setActivePage={setActivePage}
          activeTab={activeTab}
          setActiveTab={setActiveTab}
          profileData={profileData}
        />
      )}
      {activePage == "experience" && activeTab == "profile" && (
        <AddExperience
          activePage={activePage}
          setActivePage={setActivePage}
          activeTab={activeTab}
          setActiveTab={setActiveTab}
          profileData={profileData}
        />
      )}
      {activePage == "education" && activeTab == "profile" && (
        <AddEducation
          activePage={activePage}
          setActivePage={setActivePage}
          activeTab={activeTab}
          setActiveTab={setActiveTab}
          profileData={profileData}
        />
      )}
      {activePage == "contact" && activeTab == "profile" && (
        <EditContactInfo
          activePage={activePage}
          setActivePage={setActivePage}
          activeTab={activeTab}
          setActiveTab={setActiveTab}
          profileData={profileData}
        />
      )}
      {activeTab == "resume" && (
        <UploadResume
          activePage={activePage}
          setActivePage={setActivePage}
          activeTab={activeTab}
          setActiveTab={setActiveTab}
          profileData={profileData}
        />
      )}
      {activeTab == "letter" && (
        <AddCoverLatter
          activePage={activePage}
          setActivePage={setActivePage}
          activeTab={activeTab}
          setActiveTab={setActiveTab}
          profileData={profileData}
        />
      )}
      </div>
      <ToastContainer />
    </div>
    
  );
};

export default IntroProfile;
