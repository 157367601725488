import { call } from "./apiCall";

class RecommendationApi {
    getRecommendationData(params){
        return new Promise( (resolve, reject) => {
            const token = localStorage.getItem("accessToken");
            (async () => {
                try {
                    const res = await call(
                        "get",
                        "api/v1/user/recommendation",
                        params,
                        null,
                        token
                    )

                    if(res.data.success===true){
                        resolve(res.data)
                  }
                  if(res.data.success===false){
                    resolve(res.data)
                  }

                } catch (err) {
                    console.log(err)
                    reject(err)
                }
            })()
        })
    }


    addRecommendation(data){
        const token = localStorage.getItem("accessToken");
        return new Promise( (resolve, reject) => {
              (async () => {
                try {
                    const res = await call(
                        "post",
                        `api/v1/user/recommendation`,
                        null,
                        data,
                        token
                    )
                    if(res.data.success===true){
                        resolve(res.data)
                  }
                  if(res.data.success===false){
                    resolve(res.data)
                  }

                } catch (err) {
                    console.log(err)
                    reject(err)
                }
            })()
        })
    }

    askRecommendation(data){
        const token = localStorage.getItem("accessToken");
        return new Promise( (resolve, reject) => {
              (async () => {
                try {
                    const res = await call(
                        "post",
                        `api/v1/user/ask-recommendation`,
                        null,
                        data,
                        token
                    )
                    if(res.data.success===true){
                        resolve(res.data)
                  }
                  if(res.data.success===false){
                    resolve(res.data)
                  }

                } catch (err) {
                    console.log(err)
                    reject(err)
                }
            })()
        })
    }
    updateRecommendation(data){
        const token = localStorage.getItem("accessToken");
        console.log("data", data);
        return new Promise( (resolve, reject) => {
              (async () => {
                try {
                    const res = await call(
                        "patch",
                        `api/v1/user/recommendation`,
                        null,
                        data,
                        token
                    )
                    if(res.data.success===true){
                        resolve(res.data)
                  }
                  if(res.data.success===false){
                    resolve(res.data)
                  }

                } catch (err) {
                    console.log(err)
                    reject(err)
                }
            })()
        })
    }
    deleteRecommendation(id){
        const token = localStorage.getItem("accessToken");
        return new Promise( (resolve, reject) => {
              (async () => {
                try {
                    const res = await call(
                        "delete",
                        `api/v1/user/recommendation/${id.id}`,
                        null,
                        null,
                        token
                    )
                    if(res.data.success===true){
                        resolve(res.data)
                  }
                  if(res.data.success===false){
                    resolve(res.data)
                  }

                } catch (err) {
                    console.log(err)
                    reject(err)
                }
            })()
        })
    }

}

export const recommendationApi= new RecommendationApi()