/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import {
  allBlogAction,
  getBlogCategoryAction,
} from "../../store/blog/blogActions";
import Blog from "./Blog";
import "./Blog.scss";
import { getLandingPageData } from "../../store/landing/landingAction";

export default function BlogContainer() {
  const [pageSize] = useState(5);
  const [pageNo, setPageNo] = useState(1);
  const dispatch = useDispatch();
  let params = new URL(document.location).searchParams;
  let mode = params.get("mode");
  const [blogData, setBlogData] = useState();

  useEffect(() => {
    let payload = {
      page: pageNo,
      size: pageSize,
    };
    dispatch(allBlogAction(payload));
    dispatch(getBlogCategoryAction());
  }, [pageNo, pageSize]);

  useEffect(() => {
    dispatch(getLandingPageData({ title: "blogs" })).then((res) => {
      setBlogData(res);
    });
  }, []);

  const handlePagination = (value) => {
    if (value === "previous") {
      setPageNo((prevState) => prevState - 1);
    } else if (value === "next") {
      setPageNo((prevState) => prevState + 1);
    } else {
      setPageNo(value);
    }
  };
  return (
    <Blog
      pageNo={pageNo}
      pageSize={pageSize}
      handlePagination={handlePagination}
      blogData={blogData}
    />
  );
}
