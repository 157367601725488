import React, { useState } from "react";
import lamp from "../../assets/img/lamp-on.svg";
import profile from "../../assets/img/user.svg";
import ticket from "../../assets/img/ticket-discount.svg";
import security from "../../assets/img/security.svg";
import brifcase from "../../assets/img/briefcase.svg";
import user from "../../assets/img/profile-add.svg";
import { Collapse, CardBody, Card } from "reactstrap";
import { Link } from "react-router-dom";
import "./HelpWebView.scss";
import ContactUsModal from "./components/ContactUsModal";
import HelpCenterSearchWebview from "./components/HelpCenterSearchWebview";

export default function HelpWebView(props, args) {
  const { questions, collapse, data, subcollapse, subtoggle, toggle, modals,
     modal, handleSubmit, formData, handleChange, handlefileChange } = props;

  return (
    <div className="helpWebView">
      <div className="help">
        <HelpCenterSearchWebview />
        <div className="contextHelp">
          <section className="paddingHelp">
            <div className="container">
              <div className="row">
                <div className="col-lg-12">
                  <div className="wrapper">
                    <div className="heading">
                      <h4>NobelPage Frequently Asked Questions</h4>
                    </div>
                    <div className="list">
                      {/* <h5>Popular Questions</h5> */}
                      <ul>
                        {questions.map((item, id) => {
                          return <li key={id}>
                            <Link to={`/help/question/${item.id}?mode=mobile`}>
                              {item.question}
                            </Link>
                          </li>;
                        })}
                      </ul>
                    </div>
                  </div>
                  <div className="wrapper">
                    <div className="heading">
                      <h5>Recommended topics</h5>
                    </div>
                    <div className="main-box">
                      <div className="box">
                        <div className="text-center" style={{ cursor: 'pointer' }}>
                          <Link to={`/help/question/topic?mode=mobile`} state={{ tag: "basic" }}>
                            <div className="icon">
                              <img src={lamp} alt="" />
                            </div>
                            <div className="content">
                              <p>Basics</p>
                            </div>
                          </Link>
                        </div>

                      </div>
                      <div className="box">
                        <div className="text-center" style={{ cursor: 'pointer' }}>
                          <Link to={`/help/question/topic?mode=mobile`} state={{ tag: "profile" }}>
                            <div className="icon">
                              <img src={profile} alt="" />
                            </div>
                            <div className="content">
                              <p>Your Profile</p>
                            </div>
                          </Link>
                        </div>
                      </div>
                      <div className="box">
                        <div className="text-center" style={{ cursor: 'pointer' }}>
                          <Link to={`/help/question/topic?mode=mobile`} state={{ tag: "subscription" }}>
                            <div className="icon">
                              <img src={ticket} alt="" />
                            </div>
                            <div className="content">
                              <p>Subscription Billing</p>
                            </div>
                          </Link>
                        </div>
                      </div>
                      <div className="box">
                        <div className="text-center" style={{ cursor: 'pointer' }}>
                          <Link to={`/help/question/topic?mode=mobile`} state={{ tag: "privacy" }}>
                            <div className="icon">
                              <img src={security} alt="" />
                            </div>
                            <div className="content">
                              <p>Data and Privacy</p>
                            </div>
                          </Link>
                        </div>
                      </div>
                      <div className="box">
                        <div className="text-center" style={{ cursor: 'pointer' }}>
                          <Link to={`/help/question/topic?mode=mobile`} state={{ tag: "job_search" }}>

                            <div className="icon">
                              <img src={brifcase} alt="" />
                            </div>
                            <div className="content">
                              <p>Search and Apply for Jobs</p>
                            </div>
                          </Link>
                        </div>
                      </div>
                      <div className="box">
                        <div className="text-center" style={{ cursor: 'pointer' }}>
                          <Link to={`/help/question/topic?mode=mobile`} state={{ tag: "connection" }}>

                            <div className="icon">
                              <img src={user} alt="" />
                            </div>
                            <div className="content">
                              <p>Connections</p>
                            </div>

                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="accordian">
                    <div className={collapse ? "title" : " title titleUp"} onClick={toggle}>
                      Pricing, licensing, and support
                    </div>
                    <Collapse isOpen={collapse}>
                      <Card>
                        <CardBody>
                          <div className="d-flex subToggle">
                            {data.map((item, index) => {
                              return (
                                <div key={index} className="d-flex">
                                  <div

                                    className={subcollapse ? "arrow" : "arrow arrowUp"}
                                    onClick={subtoggle}
                                  ></div>
                                  <div className="sub-accordian">
                                    <div className="subtitle">
                                      {item.question}
                                    </div>
                                    <Collapse isOpen={subcollapse}>
                                      <Card>
                                        <CardBody>
                                          <p>{item.answer} </p>
                                        </CardBody>
                                      </Card>
                                    </Collapse>
                                  </div>
                                </div>
                              );
                            })}
                          </div>
                        </CardBody>
                      </Card>
                    </Collapse>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="bottom my-2">
                <ul>
                  <li onClick={modals}>Contact us</li>
                  <li><Link to='/help?mode=mobile'>FAQ</Link></li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ContactUsModal
        modal={modal}
        modals={modals}
        args={args}
        handleSubmit={handleSubmit}
        formData={formData}
        handleChange={handleChange}
      />
    </div >
  );
}
