import React, { useEffect, useState } from "react";
import Header from "../../core/components/layout/Header/Header";
import LoginFooter from "../../core/components/layout/footer/LoginFooter";
import logo from "../../assets/img/np-logo.svg";
import GooglePlay1 from "../../assets/img/GooglePlay1.svg";
import AppStoreNew from "../../assets/img/AppStoreNew.svg";
import GooglePlayNew from "../../assets/img/GooglePlayNew.svg";
import AppStore1 from "../../assets/img/AppStore1.svg";
import "./mobile.scss";
import { useDispatch } from "react-redux";
import { getLandingPageData } from "../../store/landing/landingAction";
import mob3 from "../../assets/img/mob3.png";

export default function Mobile() {
  const [mobileData, setMobileData] = useState();
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getLandingPageData({ title: "mobile" })).then((res) => {
      setMobileData(res);
    });
  }, []);
  console.log("mobileData", mobileData);

  return (
    <div className="mobile">
      <Header />
      <div className="bgNew">
        <div className="container">
          <div className="row">
            <div className="col-lg-8">
              <h1>{mobileData?.title}</h1>
              <p style={{ marginTop: "30px" }}>{mobileData?.subtitle}</p>
            </div>
          </div>
        </div>
        <div className="n-logo">
          <img src={logo} alt="" width="100%" />
        </div>
      </div>
      <section
        className="mob"
        style={
          mobileData?.body[0]?.media
            ? { backgroundImage: `url(${mobileData?.body[0]?.media})` }
            : {}
        }
      >
        <div className="container">
          <div className="row">
            <div className="col-lg-5">
              <div className="display">
                <h2>{mobileData?.title}</h2>
                {/* <p>{mobileData?.subtitle}</p> */}
                <p
                  style={{
                    lineHeight: "none",
                    marginTop: "0px",
                    fontSize: "14px",
                    fontWeight: "lighter",
                  }}
                >
                  {mobileData?.body[1]?.body}
                </p>
                <div className="apps">
                  <img
                    src={AppStoreNew}
                    alt=""
                    onClick={() =>
                      mobileData?.body[0]?.link?.split(" ")?.length > 0 &&
                      window.open(mobileData?.body[0]?.link.split(" ")[0])
                    }
                  />
                  <img
                    src={GooglePlayNew}
                    alt=""
                    onClick={() =>
                      mobileData?.body[0]?.link?.split(" ")?.length > 1 &&
                      window.open(mobileData?.body[0]?.link.split(" ")[1])
                    }
                  />
                </div>
              </div>
            </div>
            {/* <div className="col-lg-7"></div> */}
          </div>
        </div>
      </section>

      <section className="mob2">
        <div className="container">
          <div className="row">
            <div className="col-lg-7">
              <img
                className="w-100"
                src={mobileData?.body[1]?.media || mob3}
                alt=""
              />
            </div>
            <div className="col-lg-5 d-flex">
              <div className="display1 m-auto">
                <h2 style={{ color: "#01052d" }}>
                  {mobileData?.body[0]?.title}
                </h2>
                <p>{mobileData?.body[0]?.body}</p>
                <div className="apps">
                  <img
                    style={{ width: "285px", height: "82px" }}
                    src={AppStore1}
                    alt=""
                    onClick={() =>
                      mobileData?.body[1]?.link?.split(" ")?.length > 0 &&
                      window.open(mobileData?.body[1]?.link.split(" ")[0])
                    }
                  />
                  <img
                    style={{ width: "285px", height: "82px" }}
                    src={GooglePlay1}
                    alt=""
                    onClick={() =>
                      mobileData?.body[1]?.link?.split(" ")?.length > 0 &&
                      window.open(mobileData?.body[1]?.link.split(" ")[1])
                    }
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <LoginFooter />
    </div>
  );
}
