import { Modal, ModalHeader, ModalBody } from "reactstrap";
import React, { useState, useCallback } from "react";
import Cropper from "react-easy-crop";
import getCroppedImg from "../../../../core/components/ImageCrop/cropImage";
import { upload } from "@testing-library/user-event/dist/upload";

const BackgroundPhotoModal = (props) => {
  const {
    modal,
    toggle,
    title,
    handleBannerPicture,
    editBackgroundImage,
    handleRemovePicture,
  } = props;

  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
  const [uploadImage, setUploadedImage] = useState(
    editBackgroundImage ? editBackgroundImage : ""
  );

  const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels);
  }, []);

  const showCroppedImage = async () => {
    try {
      if (uploadImage === "") {
        handleRemovePicture("background");
      } else {
        const croppedImage = await getCroppedImg(
          uploadImage,
          croppedAreaPixels
        );
        handleBannerPicture(croppedImage, "background");
      }
      toggle();
    } catch (e) {
      console.error(e);
    }
  };

  const onChangePicture = (e) => {
    console.log(e.target.files);
    if (e.target.files[0]) {
      setUploadedImage(URL.createObjectURL(e.target.files[0]));
    }
  };

  const onRemovePicture = () => {
    setUploadedImage("");
  };

  const onInputClick = (event) => {
    event.target.value = "";
  };

  return (
    <Modal
      size="xl"
      isOpen={modal}
      toggle={toggle}
      className="modal-style image-cropper"
    >
      <ModalHeader toggle={toggle}>{title}</ModalHeader>
      <ModalBody>
        <div>
          <div style={{ height: "300px", width: "100%" }}>
            <Cropper
              image={uploadImage}
              crop={crop}
              zoom={zoom}
              aspect={3 / 2}
              minZoom={1}
              onCropChange={setCrop}
              onCropComplete={onCropComplete}
              cropSize={{ width: 900, height: 250 }}
              onZoomChange={setZoom}
              objectFit="horizontal-cover"
              style={{
                containerStyle: {
                  width: "100%",
                  height: "300px",
                  background:
                    "linear-gradient(117.14deg, #652D92 0%, #2E3192 31.21%, #2353A8 66.87%, #0D95D5 93.36%)",
                  padding: 0,
                  boxShadow: "2px 2px 4px rgba(171, 190, 209, 0.6)",
                  border: "2px solid #FFFFFF",
                },
              }}
            />
          </div>
          <div className="sub-heading-slider">
            <span>Zoom</span>
            <span>{zoom}</span>
          </div>
          <div className="controls">
            <input
              type="range"
              value={zoom}
              min={1}
              max={4}
              step={0.1}
              aria-labelledby="Zoom"
              onChange={(e) => {
                setZoom(e.target.value);
              }}
              className="zoom-range"
            />
          </div>
        </div>

        <div className="buttons">
          <span onClick={onRemovePicture}>Remove Photo</span>
          <div className="btns">
            <input
              type="file"
              id="profile_pic"
              name="profile_pic"
              accept="img/*"
              style={{ display: "none" }}
              onChange={onChangePicture}
              onClick={onInputClick}
            />
            <label htmlFor="profile_pic">
              <div className="btn btn-blue transparent">Change Photo</div>
            </label>
            {uploadImage === editBackgroundImage ? (
              <div className="btn btn-blue disabled-btn-blue">Apply</div>
            ) : (
              <div className="btn btn-blue" onClick={showCroppedImage}>
                Apply
              </div>
            )}
          </div>
        </div>
      </ModalBody>
    </Modal>
  );
};

export default BackgroundPhotoModal;
