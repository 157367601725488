import { Navigate, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";

const PrivateRoute = ({ children }) => {
  const location = useLocation();
  const { isLoggedIn } = useSelector((state) => state.auth);
  const token = localStorage.getItem("accessToken");

  if (!token || !isLoggedIn) {
    return <Navigate to="/" state={{ from: location }} />;
  }
  return children;
};

export default PrivateRoute;
