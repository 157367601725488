import React, { useState } from "react";
import {
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
} from "reactstrap";
import { useDispatch, useSelector } from "react-redux";
import { addlicensedetails } from "../../../../store/profile/profileActions";
import moment from "moment";
import DatePickerCustom from "../../../../core/components/DatePicker/DatePickerReact";
import { useEffect } from "react";
import { toast } from "react-toastify";

const AddLicenseCertificate = (props) => {
  const { modal, toggle, editLicenseData, setEditLicenseData } = props;
  const [errorName, setErrorName] = useState("");
  const [errorOrg, setErrorOrg] = useState("");
  const [isExpire, setIsExpery] = useState(false);
  const [errorDate, setErrorDate] = useState("");
  const [expData, setExpData] = useState(
    editLicenseData
      ? {
          id: editLicenseData.id,
          name: editLicenseData.name,
          start_date: moment(editLicenseData.start_date)
            .utc()
            .format("YYYY-MM-DD"),
          end_date: editLicenseData.end_date
            ? moment(editLicenseData.end_date).utc().format("YYYY-MM-DD")
            : null,
          credential_id: editLicenseData.credential_id.trim(),
          issuing_org: editLicenseData.issuing_org,
        }
      : {
          name: "",
          start_date: "",
          end_date: null,
          credential_id: "",
          issuing_org: "",
        }
  );

  const dispatch = useDispatch();

  useEffect(() => {
    if (editLicenseData) {
      if (!editLicenseData?.end_date) setIsExpery(true);
    }
  }, []);

  const checkSignUpValidation = (type) => {
    let formIsValid = true;

    if (type === "all" || type === "name") {
      if (!expData.name.trim()) {
        setErrorName("This field is mandatory.");
        formIsValid = false;
      } else {
        setErrorName("");
      }
    }
    if (type === "all" || type === "issuing_org") {
      if (!expData.issuing_org.trim()) {
        setErrorOrg("This field is mandatory.");
        formIsValid = false;
      } else {
        setErrorOrg("");
      }
    }

    if (type === "all" || type === "date") {
      if (!isExpire) {
        if (!expData.start_date || !expData.end_date) {
          setErrorDate("Start Date and End Date is required.");
          formIsValid = false;
        } else {
          setErrorDate("");
        }
      } else {
        if (!expData.start_date) {
          setErrorDate("Start Date is required.");
          formIsValid = false;
        } else {
          setErrorDate("");
        }
      }
    }

    return formIsValid;
  };

  const handleSubmit = () => {
    if (checkSignUpValidation("all")) {
      let expDataformat = {
        license_certifications: [expData],
      };
      console.log(expDataformat);
      var token = localStorage.getItem("accessToken");
      dispatch(addlicensedetails(expDataformat, token))
        .then((res) => {
          if (res.success) {
            toast.success(res.message, {
              position: toast.POSITION.BOTTOM_LEFT,
            });
            if (editLicenseData) {
              setEditLicenseData();
            }
            toggle();
          }
        })
        .catch((e) => {
          console.log(e);
          alert("Backend Side Validation =>" + e.response.data.message);
        });
    }
  };

  const CloseModal = () => {
    if (editLicenseData) {
      setEditLicenseData();
    }
    toggle();
  };
  // console.log("expDataformat");
  return (
    <div>
      <Modal
        isOpen={modal}
        size="lg"
        className="fix-width gradient-background"
        centered
      >
        <div className="popup">
          <div className="add-expriece">
            <ModalHeader toggle={CloseModal}>
              {editLicenseData
                ? "Edit License or Certificate"
                : "Add License or Certificate"}
            </ModalHeader>
            <ModalBody className="m-0 px-16 py-0 mb-4">
              <p>* Indicates Required</p>
              <form>
                <div className="title">
                  <label>Name*</label>
                  <Input
                    type="text"
                    placeholder=""
                    onClick={(e) => setErrorName("")}
                    value={expData.name}
                    maxLength="50"
                    onChange={(e) =>
                      setExpData({ ...expData, name: e.target.value })
                    }
                  />
                  {errorName && (
                    <label className="label-input100 text-danger">
                      {errorName}
                    </label>
                  )}
                </div>
                <div className="title">
                  <label>Issuing organization*</label>
                  <Input
                    type="text"
                    placeholder=""
                    value={expData.issuing_org}
                    maxLength="50"
                    onClick={(e) => setErrorOrg("")}
                    onChange={(e) =>
                      setExpData({ ...expData, issuing_org: e.target.value })
                    }
                  />
                  {errorOrg && (
                    <label className="label-input100 text-danger">
                      {errorOrg}
                    </label>
                  )}
                </div>
                <div className="checkExpiry">
                  <input
                    type="checkbox"
                    checked={isExpire}
                    onChange={() => setIsExpery(!isExpire)}
                  />
                  <label htmlFor="licenseexpiry">
                    The credential does not expire.
                  </label>
                </div>
                <div className="title">
                  <div className="row">
                    <div className="col-md-6 col-12">
                      <label htmlFor="">Start date</label>
                      <DatePickerCustom
                        value={
                          expData.start_date ? new Date(expData.start_date) : ""
                        }
                        onChange={(date) => {
                          date
                            ? setExpData({
                                ...expData,
                                start_date: moment(date).format("YYYY-MM-DD"),
                                end_date: null,
                              })
                            : setExpData({
                                ...expData,
                                start_date: "",
                                end_date: null,
                              });
                        }}
                        placeholder="Pick"
                        onFocus={(e) => setErrorDate("")}
                      />
                    </div>
                    <div className="col-md-6 col-12">
                      <label htmlFor="">End date</label>
                      <DatePickerCustom
                        value={
                          expData.end_date ? new Date(expData.end_date) : ""
                        }
                        onChange={(date) => {
                          date
                            ? setExpData({
                                ...expData,
                                end_date: moment(date).format("YYYY-MM-DD"),
                              })
                            : setExpData({ ...expData, end_date: "" });
                        }}
                        placeholder="Pick"
                        onClick={() => setErrorDate("")}
                        disabled={
                          expData.start_date === "" || isExpire ? true : false
                        }
                        min={
                          expData.start_date ? new Date(expData.start_date) : ""
                        }
                        onFocus={(e) => setErrorDate("")}
                      />
                    </div>
                  </div>
                  {errorDate && (
                    <label className="label-input100 text-danger">
                      {errorDate}
                    </label>
                  )}
                </div>
                <div className="title">
                  <label>Credential ID</label>
                  <Input
                    type="text"
                    placeholder=""
                    value={expData.credential_id}
                    maxLength="50"
                    onChange={(e) =>
                      setExpData({ ...expData, credential_id: e.target.value })
                    }
                  />
                </div>
                <div className="text-center">
                  <div
                    className={"btn btn-blue"}
                    style={{ marginTop: "15px" }}
                    onClick={handleSubmit}
                  >
                    Send
                  </div>
                </div>
              </form>
            </ModalBody>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default AddLicenseCertificate;
