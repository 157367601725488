//create ForgotPasswordModal.js
import React, { useState } from "react";
import { Modal, ModalBody, ModalHeader } from "reactstrap";

import email from "../../../assets/img/a.svg";
import pass from "../../../assets/img/shield-security.svg";
import FormInput from "../../../core/components/Inputs/FormInput";
import "./PortalAuth.scss";
import { useNavigate } from "react-router";
import OtpEmail from "../Signup/OtpEmail";

const ForgotPasswordModal = (props) => {
  const { modal, toggle, title,setOtpModal } = props;
  const [email, setEmail] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [showErrorMessage, setShowErrorMessage] = useState("");
  const navigate = useNavigate();
  const handleSubmit = async () => {
    let forgotPassData = {
      email: email,
    };
    try {
        setOtpModal(true);
        toggle();
    //   const res = await call(
    //     "post",
    //     "api/v1/auth/forgot-password",
    //     null,
    //     forgotPassData
    //   );

    //   if (res.data.success === true) {
    //     //  console.log("forgot res", res.data.message);

    //     navigate("/otp", { state: { email: email } });
    //   }
    //   // if (res.response.data.success === false) {
    //   else {
    //     console.log("forgot res unsuccess", res);
    //     return;
    //   }
    } catch (err) {
      console.log(err);
      if (err.response.data.message === "No record found.")
        setShowErrorMessage(
          `We couldn't find an account associated with
           ${email}.Please try with an alternate email.`
        );
      else setShowErrorMessage(err.response.data.message);
    }
  };
  const handelChangeEmail = (e) => {
    setEmail(e.target.value);
    setErrorMessage("");
  };

  const validation = () => {
    let emailvalid =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (!email) {
      setErrorMessage("Please enter your email address.");
    } else if (!emailvalid.test(email)) {
      setErrorMessage("Please enter a valid email address.");
    } else {
      handleSubmit();
    }
  };
  return (
    <Modal
    isOpen={modal}
    style={{ width: "464px", left: "17%", top: "10%" }}
    aria-labelledby="contained-modal-title-vcenter">
      <div className="forgot-modal">
        <ModalHeader toggle={toggle} >{title}
            </ModalHeader>
        <ModalBody>
          <div className="login-form">
            <form onSubmit={(e) => {
                    e.preventDefault();
                  }}>
                    <p style={{fontSize:"14px"}}>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                    Nunc vulputate libero et velit interdum, ac aliquet odio
                    mattis.
                  </p>
                  <FormInput
                    type="email"
                    label="Email"
                    icon={email}
                    onChange={handelChangeEmail}
                    placeholder="Enter Email"
                    error={errorMessage}
                    onClick={() => {
                      setShowErrorMessage("");
                    }}
                    // containerClassName={"mb-3"}
                    containerClassName={"mb-3 "+(errorMessage?"error-form":"")}
                  />

              <div className="text-center d-grid my-3" onClick={validation}>
                <button
                  type="button"
                  className="btn btn-orange"
                >
                  Send Otp
                </button>
              </div>
              {showErrorMessage && (
                    <label className="label-input100 text-danger">
                      {showErrorMessage}
                    </label>
                  )}
            </form>
          </div>
        </ModalBody>
      </div>
     
    </Modal>
  );
};

export default ForgotPasswordModal;