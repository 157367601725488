import React from 'react'
import {useNavigate } from 'react-router-dom';

function ExploreCardsThumbnails(props) {
    const navigate = useNavigate();
    // console.log("props.item",props.item);

  return (
    <div className="col-lg-4">
         <div className="thumbnail"
         onClick={()=>navigate(`/exploredetails?id=${props?.id}`)}>
            <div className="img">
                <img src={props?.imgURL} className="img-fluid" alt=""/>
            </div>
            <div className="p-3">
                <div className="text">
                    <h6>{props?.title}</h6>
                </div>  
                <div className="main-texts mt-2">
                    <p>{props?.desc}</p>
                </div>
            </div>
        </div>
    </div>
  )
}

export default ExploreCardsThumbnails
