import React, { useState } from "react";
import Navbar from "../../../../pages/navbar/navbar";
import LeftSideBar from "../CandidateComponents/LeftSideBar";
import MainComponent from "../CandidateComponents/MainComponent";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
// import {
//   getAllRecuriter,
//   requestforCandidateDetails,
// } from "../../../../store/recuriterSearch/recuriterSearchAction";
import FilterHeader from "../CandidateComponents/FilterHeader";
import { getJobs, getShotlistedList } from "../../../../store/jobs/jobAction";
import { ToastContainer, toast } from "react-toastify";
import "../../Recruiter/Recruiter.scss";
import { useLocation, useNavigate } from "react-router-dom";
import {
  getCandidateList,
  requestforCandidateDetails,
} from "../../../../store/recuriterSearch/recuriterAction";
import Chat from "../../../../pages/chat/chat";
import { jobApi } from "../../../../api/jobs";

const ShortlistedCandidates = () => {
  const { getMyJobs, shortlisedList, viewShortListed } = useSelector(
    (state) => state.jobs
  );
  const [selectedJobId, setSelectedJobId] = useState();
  const [tab, setTab] = useState("shotlisted");
  const [selectedJob, setSelectedJob] = useState();
  const [shortlistedFor, setShortlistedFor] = useState([]);
  const [currentCount, setCurrentCount] = useState(0);
  const [totalCount, setTotalCount] = useState(0);
  const [isMoreShortlisted, setIsMoreShortlisted] = useState();

  const [currentMyJobCount, setCurrentMyJobCount] = useState(0);
  const [totalMyJobCount, setTotalMyJobCount] = useState(0);
  const [isMoreMyJob, setIsMoreMyJob] = useState();

  const [sortBy, setSortBy] = useState("asc");
  const [locationFilter, setLocationFilter] = useState(null);
  const [experienceFilter, setExperienceFilter] = useState(null);
  const [educationFilter, setEducationFilter] = useState(null);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const params = useLocation();
  // useEffect(() => {
  //   // dispatch(
  //   //   getCandidateList({
  //   //     // skip: 0,
  //   //     // limit: 10,
  //   //     // skills: skillValue,
  //   //     // national: value,
  //   //     // company: searchValue,
  //   //   })
  //   // );
  //   // dispatch(
  //   //   getJobs(
  //   //     { limit: 10, skip: currentMyJobCount, filter: "my" },
  //   //     currentMyJobCount,
  //   //     "my"
  //   //   )
  //   // ).then((res) => {
  //   //   setTotalMyJobCount(res.totalItem);
  //   // });
  // }, []);

  useEffect(() => {
    if (params?.state?.tab) {
      setTab(params.state.tab);
    }

    if (params?.state?.jobId) {
      dispatch(
        getJobs(
          {
            id: params?.state?.jobId,
            limit: 10,
            skip: currentMyJobCount,
            filter: "my",
          },
          currentMyJobCount,
          "my"
        )
      ).then((res) => {
        setTotalMyJobCount(res.totalItem);
      });
    } else
      dispatch(
        getJobs(
          { limit: 10, skip: currentMyJobCount, filter: "my" },
          currentMyJobCount,
          "my"
        )
      ).then((res) => {
        setTotalMyJobCount(res.totalItem);
      });

    window.history.replaceState({}, "");
  }, [currentMyJobCount]);

  const loadData = () => {
    if (tab === "shotlisted") {
      if (selectedJobId)
        dispatch(
          getShotlistedList(
            {
              job_id: selectedJobId,
              skip: currentCount,
              type: "shortlisted",
              education: educationFilter,
              limit: 10,
              sort: sortBy,
              location: locationFilter,
              experience: experienceFilter,
              education: educationFilter,
            },
            currentCount
          )
        ).then((res) => {
          setTotalCount(res?.items);
        });
    } else if (tab === "viewCandidate") {
      if (selectedJobId)
        dispatch(
          getShotlistedList(
            {
              job_id: selectedJobId,
              skip: currentCount,
              type: "view",
              limit: 10,
              sort: sortBy,
              location: locationFilter,
              experience: experienceFilter,
              education: educationFilter,
            },
            currentCount
          )
        ).then((res) => {
          setTotalCount(res?.item);
        });
    } else if (tab === "viewSmartInterview") {
      if (selectedJobId)
        dispatch(
          getShotlistedList(
            {
              job_id: selectedJobId,
              skip: currentCount,
              type: "watch",
              limit: 10,
              sort: sortBy,
              location: locationFilter,
              experience: experienceFilter,
              education: educationFilter,
            },
            currentCount
          )
        ).then((res) => {
          setTotalCount(res?.item);
        });
    }
  };
  useEffect(() => {
    loadData();
  }, [
    selectedJobId,
    sortBy,
    locationFilter,
    educationFilter,
    experienceFilter,
    tab,
    currentCount,
    totalCount,
  ]);
  const loadMoreData = () => {
    setCurrentCount(currentCount + 10);
  };

  const loadMoreMyJob = () => {
    setTimeout(() => {
      setCurrentMyJobCount(currentMyJobCount + 10);
    }, 2000);
  };
  useEffect(() => {
    if (getMyJobs?.length < totalMyJobCount) setIsMoreMyJob(true);
    else setIsMoreMyJob(false);
  }, [currentMyJobCount, totalMyJobCount]);

  useEffect(() => {
    if (
      tab === "viewCandidate"
        ? viewShortListed?.length
        : shortlisedList?.length < totalCount
    )
      setIsMoreShortlisted(true);
    else setIsMoreShortlisted(false);
  }, [currentCount, totalCount]);

  const requestCandidateData = () => {
    const data = {
      shortlist_ids: shortlistedFor,
      job_id: selectedJobId,
    };

    dispatch(requestforCandidateDetails(data)).then((res) => {
      if (res.success) {
        toast.success(res.message, {
          position: toast.POSITION.BOTTOM_LEFT,
        });

        //dispatch(jobApi?.shortlistCandidate(data.job_id));
        loadData();
      }
    });
  };

  return (
    <div className="shortlisted bg-grey">
      <Navbar isJobs={false} />
      <div className="container mt-4">
        <div className="row px-2">
          <FilterHeader
            setCurrentCount={setCurrentCount}
            selectedJobId={selectedJobId}
            setSortBy={setSortBy}
            setLocationFilter={setLocationFilter}
            setExperienceFilter={setExperienceFilter}
            setEducationFilter={setEducationFilter}
            tab={tab}
            setTab={setTab}
          />
        </div>
        {getMyJobs?.length > 0 ? (
          <div className="row">
            <div className="col-lg-4">
              <LeftSideBar
                selectedJobId={selectedJobId}
                setSelectedJobId={setSelectedJobId}
                getAllJobs={getMyJobs}
                setCurrentCount={setCurrentCount}
                // headerText={"36 Total Shortlisted Candidats"}
                headerText={""}
                isMoreMyJob={isMoreMyJob}
                loadMoreMyJob={loadMoreMyJob}
                tab={tab}
                setSelectedJob={setSelectedJob}
              />
            </div>
            <div className="col-lg-8 recruiter">
              {shortlisedList ? (
                <MainComponent
                  candidateData={
                    tab === "viewCandidate" ? viewShortListed : shortlisedList
                  }
                  shortlistedFor={shortlistedFor}
                  setShortlistedFor={setShortlistedFor}
                  requestCandidateData={requestCandidateData}
                  totalCount={totalCount}
                  loadMoreData={loadMoreData}
                  currentCount={currentCount}
                  isMoreShortlisted={isMoreShortlisted}
                  tab={tab}
                  selectedJobId={selectedJobId}
                  selectedJob={selectedJob}
                />
              ) : (
                <div className="bg-white d-flex justify-content-center">
                  <h4>No candidate shortlisted</h4>
                </div>
              )}
            </div>
          </div>
        ) : (
          <>
            <div
              className="create"
              onClick={() =>
                navigate("/jobs", {
                  state: {
                    isOpenCreatePost: true,
                  },
                })
              }
            >
              Create Jobs
            </div>
            <div
              style={{
                textAlign: "center",
                fontSize: "15px",
                margintTop: "10px",
                color: "red",
              }}
            >
              You do not have created any job yet. Please create a job first
            </div>
          </>
        )}
      </div>
      <ToastContainer />
      <Chat />
    </div>
  );
};

export default ShortlistedCandidates;
