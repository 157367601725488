import { Modal, ModalHeader, ModalBody } from "reactstrap";
import React from "react";
import { useSelector } from "react-redux";
import locationIcon from "../../../assets/img/location.svg";
import { campaignAPI } from "../../../api/campaignAPI";
import { toast } from "react-toastify";
const WhyAdModel = (props) => {
  const { modal, toggle, activeAd } = props;
  const closeButton = () => {
    toggle();
  };

  const { user } = useSelector((state) => state.auth);
  console.log(user?.data, "jfjfjfjfjf");

  const handleFeedback = async (answer) => {
    try {
      const res = await campaignAPI.addFeedback({
        type: "campaign",
        type_id: activeAd?.campaignDetails?.id,
        answer,
      });
      if (res?.success) {
        toast.success(res?.message, { position: "bottom-right" });
        toggle();
      }
    } catch (err) {
      console.log(err);
    }
  };

  console.log(activeAd);

  return (
    <Modal
      size="lg"
      isOpen={modal}
      style={{ maxWidth: "600px" }}
      aria-labelledby="contained-modal-title-vcenter"
    >
      <ModalHeader className="dicardModalHeader text-dark" toggle={toggle}>
        Why am I seeing this ad?
      </ModalHeader>

      <ModalBody>
        <div className="">
          <div className="d-flex align-items-center">
            <img src={activeAd?.src} width={70} height={60} />
            <p className="mx-3">
              You’re seeing this ad because your profile and activity matches
              the intended audience
            </p>
          </div>

          <h6 className="mt-3 text-dark mb-2">Reasons you’re seeing this ad</h6>

          <div className="d-flex align-items-center mb-3 justify-content-between">
            <div className="d-flex align-items-center" style={{ width: "70%" }}>
              <img src={locationIcon} width={20} />
              <p className="card-control-text-twoline mx-2">
                {user?.data?.location || "No Location Found"}
              </p>
            </div>
            <a href={`/profile/${user?.data?.id}`}>Update Location </a>
          </div>

          {!Object.keys(activeAd?.Feedback || {}).length && (
            <div className="d-flex align-items-center justify-content-between">
              <p>Is this ad relevant to you?</p>
              <div className="d-flex align-items-center justify-content-between">
                <button
                  className="bg-transparent me-3"
                  onClick={() => handleFeedback(true)}
                >
                  Yes
                </button>
                <button
                  className="bg-transparent"
                  onClick={() => handleFeedback(false)}
                >
                  No
                </button>
              </div>
            </div>
          )}
        </div>
      </ModalBody>
    </Modal>
  );
};

export default WhyAdModel;
