import { settingApi } from "../../api/settingApi";
import { initPageLoad, stopPageLoad } from "../loader/loaderActions";
import {
  FAIL_GET_LANGUAGE_LIST,
  FAIL_GET_SETTING_DATA,
  GET_CONTENT_LANGUAGE_LIST,
  GET_LANGUAGE_LIST,
  GET_SECONDARY_EMAIL,
  GET_SESSION_LIST,
  GET_SETTING_DATA,
  UPDATE_PROFILE_URL,
  UPDATE_SETTING_DATA,
  UPDATE_SETTING_DATA_BADGES,
} from "../types";

export const getUserSetting =
  (id, token, user_id = null) =>
  (dispatch) => {
    return settingApi.getSettingDetails(id, token, { user_id }).then(
      (response) => {
        dispatch({
          type: GET_SETTING_DATA,
          payload: response,
        });
        return Promise.resolve(response);
      },
      (error) => {
        dispatch({
          type: FAIL_GET_SETTING_DATA,
        });
        console.log(error);
        return Promise.reject(error);
      }
    );
  };

export const updateSettingDetails = (settingData, token) => (dispatch) => {
  dispatch(initPageLoad());
  return settingApi.updateSettingDetails(settingData, token).then(
    (response) => {
      dispatch({
        type: UPDATE_SETTING_DATA,
        payload: settingData,
      });
      dispatch({
        type: UPDATE_PROFILE_URL,
        payload: settingData.setting[0].value,
      });
      dispatch(stopPageLoad());
      return Promise.resolve(response);
    },
    (error) => {
      console.log(error);
      dispatch(stopPageLoad());
      return Promise.reject(error);
    }
  );
};

export const updateSettingWTLoadingDetails =
  (settingData, token) => (dispatch) => {
    return settingApi.updateSettingDetails(settingData, token).then(
      (response) => {
        dispatch({
          type: UPDATE_SETTING_DATA_BADGES,
          payload: settingData,
        });
        // dispatch({
        //   type: UPDATE_PROFILE_URL,
        //   payload: settingData.setting[0].value,
        // });
        return Promise.resolve(response);
      },
      (error) => {
        console.log(error);
        return Promise.reject(error);
      }
    );
  };

export const getLanguageList = () => (dispatch) => {
  return settingApi.getLanguageList().then(
    (response) => {
      dispatch({
        type: GET_LANGUAGE_LIST,
        payload: response,
      });
      return Promise.resolve(response);
    },
    (error) => {
      dispatch({
        type: FAIL_GET_LANGUAGE_LIST,
      });
      console.log(error);
      return Promise.reject(error);
    }
  );
};

export const getContentLanguageList = () => (dispatch) => {
  return settingApi.getContentLanguageList().then(
    (response) => {
      dispatch({
        type: GET_CONTENT_LANGUAGE_LIST,
        payload: response,
      });
      return Promise.resolve(response);
    },
    (error) => {
      dispatch({
        type: FAIL_GET_LANGUAGE_LIST,
      });
      console.log(error);
      return Promise.reject(error);
    }
  );
};

export const getEmailList = (token) => (dispatch) => {
  return settingApi.getSecondayEmailList(token).then(
    (response) => {
      dispatch({
        type: GET_SECONDARY_EMAIL,
        payload: response,
      });
      return Promise.resolve(response);
    },
    (error) => {
      console.log(error);
      return Promise.reject(error);
    }
  );
};

export const addSecondaryEmail = (data, token) => (dispatch) => {
  return settingApi.addSecondaryEmail(data, token).then(
    (response) => {
      return Promise.resolve(response);
    },
    (error) => {
      return Promise.reject(error);
    }
  );
};

export const getSessionList = (token) => (dispatch) => {
  return settingApi.getSession(token).then(
    (response) => {
      dispatch({
        type: GET_SESSION_LIST,
        payload: response,
      });
      return Promise.resolve(response);
    },
    (error) => {
      console.log(error);
      return Promise.reject(error);
    }
  );
};

export const deleteSession = (token, id) => (dispatch) => {
  return settingApi.deleteSessionSingle(token, id).then(
    (response) => {
      return Promise.resolve(response);
    },
    (error) => {
      return Promise.reject(error);
    }
  );
};

export const deleteAllSessions = (token, bodyData) => (dispatch) => {
  return settingApi.deleteSessionAll(token, bodyData).then(
    (response) => {
      return Promise.resolve(response);
    },
    (error) => {
      return Promise.reject(error);
    }
  );
};

export const updatePass = (token, bodyData) => (dispatch) => {
  return settingApi.updatePassword(token, bodyData).then(
    (response) => {
      return Promise.resolve(response);
    },
    (error) => {
      return Promise.reject(error);
    }
  );
};
