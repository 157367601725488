import React, { useEffect } from "react";
import {
  Modal,
  ModalHeader,
  ModalBody,
  Input,
  Label,
  FormGroup,
} from "reactstrap";
import { get_Report_type } from "../../../store/dashboard/dashboardActions";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { submit_report } from "../../../store/dashboard/dashboardActions";
import { useState } from "react";
import DeleteModal from "../../../core/components/modal/DeleteModal";
import { useNavigate } from "react-router-dom";

export default function ReportModal(props) {
  const {
    modal,
    toggle,
    post_id,
    iswebview,
    reportType,
    reportTypeId,
    reportCommentId,
  } = props;

  const [confirmModal, setConfirmModal] = useState(false);
  const [reportReason, setReportReason] = useState("");
  const [reportId, setReportId] = useState(0);
  const [isOthers, setIsOthers] = useState(false);
  const [otherReason, setOtherReason] = useState("");

  const dispatch = useDispatch();

  const { getReportData } = useSelector((state) => state.dashboard);

  useEffect(() => {
    let token = window.localStorage.getItem("accessToken");
    dispatch(get_Report_type(token));
  }, []);

  const navigate = useNavigate();

  const reportSubmit = (report_id) => {
    console.log({
      report_type_id: report_id,
      type: reportType,
      type_id: reportTypeId,
      message: reportReason,
      report_type: report_id ? "" : otherReason,
    });
    setConfirmModal(!confirmModal);
    let data;

    reportType === "post"
      ? (data = {
          post_id: post_id,
          report_type_id: report_id,
          type: reportType,
          // type_id:reportCommentId,
          message: reportReason,
          report_type: report_id ? "" : otherReason,
        })
      : reportType === "comment"
      ? (data = {
          // post_id: post_id,
          report_type_id: report_id,
          type: reportType,
          type_id: reportCommentId,
          message: reportReason,
          report_type: report_id ? "" : otherReason,
        })
      : (data = {
          report_type_id: report_id,
          type: reportType,
          type_id: reportTypeId,
          message: reportReason,
          report_type: report_id ? "" : otherReason,
        });

    dispatch(submit_report(data)).then((response) => {
      toast.success("Successfully reported!", {
        position: toast.POSITION.BOTTOM_LEFT,
      });
      toggle();
      navigate("/dashboard");
    });
  };

  const handleSubmit = () => {
    if (reportReason || otherReason) {
      setConfirmModal(!confirmModal);
    } else alert("Please select reason for report");
  };

  return (
    <div>
      <Modal
        isOpen={modal}
        toggle={toggle}
        className="add-section report-modal fix-width"
        centered
      >
        <ModalHeader style={{ padding: "20px !important" }} toggle={toggle}>
          Why you are reporting this {reportType || "Post"}?
        </ModalHeader>
        <ModalBody>
          <div className="radios">
            {getReportData?.data?.map((data, index) => {
              return (
                <div className="d-flex align-items-baseline my-2" key={index}>
                  <input
                    className="me-2 mt-1"
                    style={{ height: "15px", width: "15px" }}
                    type="radio"
                    id="html"
                    name="reportReason"
                    value={data?.type}
                    onClick={() => {
                      setReportReason(data?.type);
                      setReportId(data?.id);
                    }}
                    required
                  />
                  <label className="text-capitalize" htmlFor="html">
                    {data?.type}
                  </label>
                  {/* <FormGroup check >
                  <Label check style={{ color: "#717171"}}>
                    <Input type="radio" name="radio2" value={data?.type} className="text-capitalize" />
                    {data?.type}
                  </Label>
                </FormGroup> */}
                </div>
              );
            })}
            <div className=" my-2">
              <input
                className="me-2 mt-1"
                style={{ height: "15px", width: "15px" }}
                type="radio"
                id="html"
                name="reportReason"
                value={otherReason}
                onClick={() => {
                  setIsOthers(true);
                  setReportReason("");
                  setReportId(null);
                }}
                required
              />
              <label className="text-capitalize" htmlFor="html">
                Others
              </label>
              {isOthers && (
                <div className="other-reson">
                  <input
                    type="text"
                    maxLength={50}
                    onChange={(e) => setOtherReason(e.target.value)}
                    placeholder="Please enter you reason"
                  />
                </div>
              )}
              {/* <FormGroup check >
                  <Label check style={{ color: "#717171"}}>
                    <Input type="radio" name="radio2" value={data?.type} className="text-capitalize" />
                    {data?.type}
                  </Label>
                </FormGroup> */}
            </div>
          </div>

          <div className="text-center mt-3">
            <button
              onClick={() => handleSubmit()}
              className={
                iswebview
                  ? "blue noBorder whiteText w-100"
                  : "blue noBorder whiteText"
              }
            >
              Report
            </button>
          </div>
        </ModalBody>
      </Modal>
      {confirmModal && (
        <DeleteModal
          modal={DeleteModal}
          toggle={() => setConfirmModal(!confirmModal)}
          title=""
          deleteConfirm={() => reportSubmit(reportId)}
          text={`Would you like to report this ${reportType || "Post"} for ${
            otherReason ? otherReason : reportReason
          }?`}
        />
      )}
    </div>
  );
}
