import React, { useState } from "react";
import { Modal, ModalBody } from "reactstrap";
import ShareModal from "../../../../core/components/sharePost/sharePost";

const EventSuccessfulModal = (props) => {
  const { modal, toggle, title, shareData } = props;

  const [openShareModal, setOpenShareModal] = useState(false);

  return (
    <Modal size="md" isOpen={modal} style={{ maxWidth: "600px" }} centered>
      <ModalBody>
        <div className="event-create-popup">
          <div className="check"></div>
          <div className="text-center">
            <h4>Event Was Created Successfully</h4>
            <div className="line mt-20 mb-20"></div>
            <p>Would you like to share this event on your network?</p>
            <div className="button mt-30">
              <button className="btn btn-light text-blue" onClick={toggle}>
                Close
              </button>
              <button
                className="btn btn-blue ml-2"
                onClick={() => {
                  setOpenShareModal(!openShareModal);
                }}
              >
                <div className="d-flex align-items-center">
                  <i className="ci-share"></i>Share
                </div>
              </button>
            </div>
          </div>
        </div>
      </ModalBody>
      {openShareModal && (
        <ShareModal
          modal={openShareModal}
          toggle={() => setOpenShareModal(!openShareModal)}
          title="Share"
          shareData={shareData}
          type="event"
          toggleSuccessful={toggle}
        />
      )}
    </Modal>
  );
};

export default EventSuccessfulModal;
