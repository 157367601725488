import React, { useEffect, useState } from "react";
import companylogo from "../../../assets/img/company-name.svg";
import profileicon from "../../../assets/img/profile-icon-pool.png";
// import placeholderUser from "../../../assets/img/default_profile_sqr img.svg";
import edit from "../../../assets/img/edit-orange.svg";
import "./EditPortalProfile.scss";
import IntroProfile from "./IntroProfile";
import PortalFooter from "../portalFooter/PortalFooter";
import { useDispatch, useSelector } from "react-redux";
import {
  getTalentPoolDetails,
  getTalentPoolDetailsByToken,
  logoutPortal,
} from "../../../store/portal/portalAction";
import { useNavigate, useParams } from "react-router-dom";
import { getUserProfile } from "../../../store/profile/profileActions";
import placeholderUser from "../../../assets/img/default_profile_sqr img.svg";
import { getUserSetting } from "../../../store/setting/SettingAction";
import user_logout from "../../../assets/img/Logout.svg";
import { ToastContainer } from "react-toastify";
import pagePlaceholder from "../../../assets/img/page-placeholder.svg";
const EditPortalProfile = () => {
  const { talentPoolDataByToken, talentPoolData } = useSelector(
    (state) => state.portal
  );
  const [tpData, setTpData] = useState();
  const [profileDropdown, setProfileDropdown] = useState(false);
  const navigate = useNavigate();
  const { profileData } = useSelector((state) => state.profile);
  // const themeColor = "#FFB800";
  // const [introBorderColor, setIntroBorderColor] = useState(
  //   "rgba(209, 223, 255, 1)"
  // );
  // const [resumeBorderColor, setResumeBorderColor] = useState(
  //   "rgba(209, 223, 255, 1)"
  // );
  // const [coverLetterBorderColor, setCoverLetterBorderColor] = useState(
  //   "rgba(209, 223, 255, 1)"
  // );
  // const handleIntroCardClick = () => {
  //   setActiveTab("profile");
  //   setIntroBorderColor(themeColor);
  //   setResumeBorderColor("rgba(209, 223, 255, 1)");
  //   setCoverLetterBorderColor("rgba(209, 223, 255, 1)");
  // };
  // const handleResumeCardClick = () => {
  //   setIntroBorderColor("rgba(209, 223, 255, 1)");
  //   setResumeBorderColor(themeColor);
  //   setCoverLetterBorderColor("rgba(209, 223, 255, 1)");
  // };
  // const handleCoverLetterCardClick = () => {
  //   setIntroBorderColor("rgba(209, 223, 255, 1)");
  //   setResumeBorderColor("rgba(209, 223, 255, 1)");
  //   setCoverLetterBorderColor(themeColor);
  // };
  const dispatch = useDispatch();
  const { param } = useParams();
  const token = localStorage.getItem("accessTpToken");
  useEffect(() => {
    dispatch(getTalentPoolDetailsByToken()).then((res) => {
      if (res?.success) setTpData(res?.data);
    });
    dispatch(getTalentPoolDetails(param));
  }, []);
  useEffect(() => {
    console.log("talentPoolDataByToken", talentPoolDataByToken);
    if (tpData)
      dispatch(getUserProfile(talentPoolDataByToken?.member?.user_id, token));
    dispatch(getUserSetting(5, token));
  }, [tpData]);

  const handleLogout = async () => {
    try {
      dispatch(logoutPortal())
        .then(() => {
          navigate(`/tp/portal/${param}/login`);
        })
        .catch(() => {
          navigate(`/tp/portal/${param}/login`);
        });
    } catch (e) {
      console.log(e);
    }
  };

  const [activeTab, setActiveTab] = useState("profile");
  const [activePage, setActivePage] = useState("intro");
  return (
    <>
      <div className="bg-white">
        <div className="edit-portal-profile">
          <div className="header-row container">
            <div className="company_logo">
              <img
                src={talentPoolDataByToken?.icon || pagePlaceholder}
                alt="companny-logo"
                style={{ width: "50px", height: "50px", borderRadius: "50%" }}
                // height={100}
                // width={150}
              />
              <span className="mx-3">
                {talentPoolDataByToken?.company_name?.length > 20
                  ? talentPoolDataByToken?.company_name
                  : talentPoolDataByToken?.company_name}
              </span>
            </div>
            <a className="nav-link">
              <div className="prof" style={{ position: "sticky", zIndex: "1" }}>
                <div className="profile-icon">
                  <img
                    src={
                      profileData?.profile_img
                        ? profileData.profile_img
                        : placeholderUser
                    }
                    style={{ cursor: "pointer" }}
                    alt="profile-icon"
                    onClick={() => setProfileDropdown(!profileDropdown)}
                  />
                  <h5
                    className="ms-3 card-control-text-oneline"
                    style={{ width: "100px" }}
                  >
                    {profileData?.first_name ? profileData?.first_name : "Hi"}{" "}
                    {profileData?.last_name ? profileData?.last_name : "User"}
                  </h5>
                </div>
                {profileDropdown ? (
                  <div className="admin" style={{ width: "170px" }}>
                    <ul>
                      <li
                        className="dropdown-span"
                        style={{ cursor: "pointer" }}
                      >
                        <img
                          src={
                            profileData?.profile_img
                              ? profileData.profile_img
                              : placeholderUser
                          }
                          alt=""
                          style={{ height: "17px", width: "17px" }}
                        />
                        <span
                          onClick={() =>
                            navigate(`/tp/portal/${param}/dashboard`)
                          }
                        >
                          Home
                        </span>
                      </li>
                      <li
                        className="dropdown-span"
                        style={{ cursor: "pointer" }}
                      >
                        <img
                          src={user_logout}
                          alt="log_out"
                          style={{ height: "17px", width: "17px" }}
                        />
                        <span onClick={handleLogout}>Log out</span>
                      </li>
                    </ul>
                  </div>
                ) : (
                  <></>
                )}
              </div>
            </a>
          </div>
          <div className="container mt-5">
            <div className="row">
              <div className="col-md-4">
                <div
                  className="card"
                  style={{
                    borderColor:
                      activeTab == "profile"
                        ? talentPoolDataByToken?.theme
                        : "rgba(209, 223, 255, 1)",
                    cursor: "pointer",
                    borderRadius: 0,
                  }}
                  onClick={() => {
                    setActiveTab("profile");
                    setActivePage("intro");
                  }}
                >
                  <div className="card-header">
                    <h5>Edit Intro</h5>
                  </div>
                  <div className="card-body">
                    <p>
                      Update your intro and profile data to keep your
                      information current and relevant.
                    </p>
                  </div>
                </div>
                <div
                  className="card"
                  style={{
                    borderColor:
                      activeTab == "resume"
                        ? talentPoolDataByToken?.theme
                        : "rgba(209, 223, 255, 1)",
                    cursor: "pointer",
                    borderRadius: 0,
                  }}
                  onClick={() => setActiveTab("resume")}
                >
                  <div className="card-header">
                    <h5>Resume</h5>
                  </div>
                  <div className="card-body">
                    <p>Upload your updated CV / Resume.</p>
                  </div>
                </div>
                <div
                  className="card"
                  style={{
                    borderColor:
                      activeTab == "letter"
                        ? talentPoolDataByToken?.theme
                        : "rgba(209, 223, 255, 1)",
                    cursor: "pointer",
                    borderRadius: 0,
                  }}
                  onClick={() => setActiveTab("letter")}
                >
                  <div className="card-header">
                    <h5>Cover Letter</h5>
                  </div>
                  <div className="card-body">
                    <p>
                      Enhance your profile and job applications by including a
                      brief cover letter.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-md-8 main-form-div">
                <IntroProfile
                  activeTab={activeTab}
                  setActiveTab={setActiveTab}
                  activePage={activePage}
                  setActivePage={setActivePage}
                  profileData={profileData}
                />
              </div>
            </div>
          </div>
        </div>

        <PortalFooter talentPoolData={talentPoolData} activeTab={activeTab} />
      </div>
    </>
  );
};

export default EditPortalProfile;
