import React, { useState, useEffect } from "react";
import "../../../profile/profile.scss";
import { useSelector, useDispatch } from "react-redux";
import { getProfileViewersList } from "../../../../../store/profile/profileActions";
import ViewerList from "./ViewerList.jsx";
import { sendConnectionRequest } from "../../../../../store/connections/connectionsActions";
import { toast } from "react-toastify";
import {
  initPageLoad,
  stopPageLoad,
} from "../../../../../store/loader/loaderActions.js";
import { useLocation } from "react-router-dom";

function ViewerListContainer() {
  const dispatch = useDispatch();
  const location = useLocation();

  const [profileViewersList, setProfileViewersList] = useState([]);
  const [companyFilter, setCompanyFilter] = useState({});
  const [query, setQuery] = useState(
    location?.state?.searchAppearences ? { searchAppearences: true } : {}
  );
  const [currentCount, setCurrentCount] = useState(0);
  const [searchAppearenceCount, setSearchAppearenceCount] = useState(0);
  const getProfileViewersFxn = (dataObj) => {
    dispatch(initPageLoad());
    dispatch(getProfileViewersList(dataObj))
      .then((response) => {
        setProfileViewersList(response?.data);
        setCurrentCount(response?.items || 0);
        setSearchAppearenceCount(response?.searchAppearences || 0);
        setCompanyFilter(response?.companyList || {});
        dispatch(stopPageLoad());
      })
      .catch((err) => {
        dispatch(stopPageLoad());
        console.log("error!!", err);
      });
  };

  useEffect(() => {
    getProfileViewersFxn(query);
  }, [query]);

  const sendConnectionHandle = (id) => {
    dispatch(sendConnectionRequest(id)).then((res) => {
      let newArr = profileViewersList?.map((item, i) => {
        if (item?.user_id === id) {
          return { ...item, connectionStatus: "pending" };
        } else {
          return item;
        }
      });
      setProfileViewersList(newArr);
      toast.success("Connection request sent successfuly", {
        position: toast.POSITION.BOTTOM_LEFT,
      });
    });
  };

  return (
    <>
      <ViewerList
        profileViewersList={profileViewersList}
        companyFilter={companyFilter}
        currentCount={currentCount}
        searchAppearenceCount={searchAppearenceCount}
        query={query}
        setQuery={setQuery}
        sendConnectionHandle={sendConnectionHandle}
      />
    </>
  );
}
export default ViewerListContainer;
