import React, { useCallback, useRef, useState } from "react";
import Webcam from "react-webcam";
import "./recordVideo.scss";
import axios from "axios";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { uploadVideo } from "../../../../../store/profile/profileActions";


var SharedArrayBuffer={};
export default function WebcamVideo(props) {
  const { setUrlData, toggle } = props;
  const webcamRef = useRef(null);
  const mediaRecorderRef = useRef(null);
  const [capturing, setCapturing] = useState(false);
  const [recordedChunks, setRecordedChunks] = useState([]);

  const dispatch = useDispatch();

  const handleDataAvailable = useCallback(
    ({ data }) => {
      if (data.size > 0) {
        setRecordedChunks((prev) => prev.concat(data));
      }
    },
    [setRecordedChunks]
  );

  const handleStartCaptureClick = useCallback(() => {
    setCapturing(true);
    mediaRecorderRef.current = new MediaRecorder(webcamRef.current.stream, {
      mimeType: "video/webm",
    });
    mediaRecorderRef.current.addEventListener(
      "dataavailable",
      handleDataAvailable
    );
    mediaRecorderRef.current.start();
  }, [webcamRef, setCapturing, mediaRecorderRef, handleDataAvailable]);

  const handleStopCaptureClick = useCallback(() => {
    mediaRecorderRef.current.stop();
    setCapturing(false);
  }, [mediaRecorderRef, setCapturing]);

  const handleDownload = useCallback(async () => {
    if (recordedChunks?.length) {
      const blob = new Blob(recordedChunks, {
        type: "video/webm",
      });
      const newVideo = new File([blob], "newRecordedVideo.webm", {
        type: "video/webm",
      });
      let letnew1 = {
        file: newVideo,
      };
      try {
        const UPLOAD_URL = `${process.env.REACT_APP_API_URL}api/v1/compress-upload`;
        const formData = new FormData();
        const headers = {
          "Accept-Language": "en",
          "content-type": "multipart/form-data",
        };

        formData.append("image", letnew1.file);
        await axios.post(UPLOAD_URL, formData, { headers }).then((res) => {
          console.log(res?.data?.urlsArray[0]);
          setUrlData(res?.data?.urlsArray[0]);
          let token = localStorage.getItem("accessToken");
          let data = {
            intro_video: res?.data?.urlsArray[0],
          };
          dispatch(uploadVideo(data, token)).then((response) => {
            toast.success("Upload saved successfully", {
              position: toast.POSITION.BOTTOM_LEFT,
            });
            toggle();
          });
        });
      } catch (err) {
        console.log(err);
      }
      // const url = URL.createObjectURL(blob);
      // const a = document.createElement("a");
      // document.body.appendChild(a);
      // a.style = "display: none";
      // a.href = url;
      // a.download = "react-webcam-stream-capture.webm";
      // a.click();
      // window.URL.revokeObjectURL(url);
      setRecordedChunks([]);
    }
  }, [recordedChunks]);

  const videoConstraints = {
    width: 1000,
    height: 500,
    facingMode: "user",
  };

  return (
    <div className="record_video">
      <div className="App">
        <div className="Container">
          <Webcam
            height={400}
            width="100%"
            audio={true}
            style={{ backgroundColor: "black" }}
            // mirrored={true}
            ref={webcamRef}
            videoConstraints={videoConstraints}
          />
          {/* <video ref={videoRef} controls style={{ visbility: "hidden" }}></video> */}
          {/* <div className="button-class" onClick={hanleAutoCompress}>
              Compress
            </div> */}
          {capturing ? (
            <div className="button-class" onClick={handleStopCaptureClick}>
              Stop Capture
            </div>
          ) : (
            <div className="button-class" onClick={handleStartCaptureClick}>
              Start Capture
            </div>
          )}
          {recordedChunks.length > 0 && (
            <div className="button-class" onClick={handleDownload}>
              Upload Video
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
