import { useEffect, useState } from "react";
import { BrowserRouter } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import "./assets/styles/style.scss";
import { useDispatch, useSelector } from "react-redux";
import { loadUser } from "./store/auth/authActions.js";
import Router from "./routes/router.js";
import Wrapper from "./routes/ScrollToTop";
import NoInternetConnection from "./routes/error/noInternetError";
import { getTokenFirebase } from "./routes/Notifications/NotifyFirebase.js";
import { initPageLoad, stopPageLoad } from "./store/loader/loaderActions.js";
import SocketService from "./pages/message/service/socket/socket.service.js";

function App() {
  const dispatch = useDispatch();
  const [processing, setProcessing] = useState(true);
  const { user } = useSelector((state) => state.auth);
  const chat = SocketService;

  useEffect(() => {
    setProcessing(true);
    dispatch(initPageLoad());
    dispatch(loadUser(setProcessing))
      .then((res) => {
        dispatch(stopPageLoad());

        // Socket IO connection
        chat.init();
      })
      .catch((err) => {
        dispatch(stopPageLoad());
      });
  }, []);

  // useEffect(() => {
  //     // Function to fetch token and set it in local storage
  // const fetchTokenAndSetLocalStorage = async () => {
  //   try {
  //     const token = await getTokenFirebase(); // Await the promise
  //     localStorage.setItem("firebase_token", token); // Set token in local storage
  //   } catch (error) {
  //     console.error("Error while fetching token:", error);
  //   }
  // };
  //     fetchTokenAndSetLocalStorage()
  // }, []);

  // async function requestPermission() {
  //   //requesting permission using Notification API
  //   const permission = await Notification.requestPermission();
  //   if (window.location.href !== "/mobile/create-article") {
  //     if (permission === "granted") {
  //       const token = await getTokenFirebase();

  //       //We can send token to server
  //       console.log("Token generated : ", token);
  //       localStorage.setItem("firebase_token", token);
  //     } else if (permission === "denied") {
  //       //notifications are blocked
  //       alert("You denied for the notification");
  //     }
  //   }
  // }

  async function requestPermission() {
    try {
      const permission = await Notification.requestPermission();

      if (window.location.pathname !== "/mobile/create-article") {
        if (permission === "granted") {
          const token = await getTokenFirebase();
          localStorage.setItem("firebase_token", token);
        } else if (permission === "denied") {
          alert("You denied notifications");
        }
      }
    } catch (error) {
      console.error(
        "Error requesting notification permission or fetching token: ",
        error
      );
    }
  }

  useEffect(() => {
    requestPermission();
  }, []);

  // const getNoitfication = async () =>{
  //   try {
  //     const res = await axios.get(
  //       "http://localhost:3005/api/v1/notification/unreadnotifications"
  //     );
  //     console.log("res", res);
  //   } catch (error) {
  //     console.log("error", error);
  //   }
  // }

  const appStyle = user?.data?.membership?.background
    ? {
        backgroundImage: `url(${user?.data?.membership?.background})`,
        backgroundColor: "unset",
      }
    : user?.data
    ? { background: "#eff2f6 center/cover no-repeat fixed" }
    : {};

  if (processing) {
    return null;
  }
  return (
    <div className="App" style={appStyle}>
      <BrowserRouter>
        <Wrapper>
          <NoInternetConnection>
            <Router />
          </NoInternetConnection>
        </Wrapper>
      </BrowserRouter>
    </div>
  );
}

export default App;
