import { toast } from "react-toastify";
import { campaignAPI } from "../../api/campaignAPI";
import {
  POST_CAMPAIGN_DATA,
  GET_CAMPAIGN_LIST,
  LOAD_MORE_CAMPAIGNS,
  GET_FEATURED_CAMPAIGN_LIST,
  LOAD_FEATURED_MORE_CAMPAIGNS,
  DELETE_CAMPAIGN,
} from "../types";

export const postCampaign = (data) => (dispatch) => {
  return campaignAPI.createCampaign(data).then(
    (response) => {
      dispatch({
        type: POST_CAMPAIGN_DATA,
        payload: response.data,
      });
      return Promise.resolve(response);
    },
    (error) => {
      console.log(error);
      toast.error(
        error.response.data.message,

        {
          position: toast.POSITION.BOTTOM_LEFT,
        }
      );
      return Promise.reject(error);
    }
  );
};

export const getCampaign = (data, currentCount) => (dispatch) => {
  return campaignAPI.getCampaign(data).then(
    (response) => {
      if (currentCount === 0) {
        // if(data.type==="featured"){
        //   dispatch({
        //     type:GET_FEATURED_CAMPAIGN_LIST,
        //     payload: response.data,
        //   })

        // } else
        dispatch({
          type: GET_CAMPAIGN_LIST,
          payload: response.data,
        });
      } else {
        // if(data.type==="featured"){
        //   dispatch({
        //     type:LOAD_FEATURED_MORE_CAMPAIGNS,
        //     payload: response.data,
        //   })
        // }
        dispatch({
          type: LOAD_MORE_CAMPAIGNS,
          payload: response.data,
        });
      }
      return Promise.resolve(response);
    },
    (error) => {
      console.log(error);
      return Promise.reject(error);
    }
  );
};

export const deleteCampaign = (id) => (dispatch) => {
  return campaignAPI.deleteCampaign(id).then(
    (response) => {
      dispatch({
        type: DELETE_CAMPAIGN,
        payload: id,
      });
      return Promise.resolve(response);
    },
    (error) => {
      console.log(error);
      toast.error(
        error.response.data.message,

        {
          position: toast.POSITION.BOTTOM_LEFT,
        }
      );
      return Promise.reject(error);
    }
  );
};
