import React, { useEffect, useState } from "react";
import { Input, Modal, ModalBody, ModalHeader } from "reactstrap";
import ".././CelebrationPostModals/selectOption.scss";
import { toast } from "react-toastify";
import rectangleImage from "../../../../assets/img/RectanglepayBycard.png"
const CreatePollModal = (props) => {
  const { modal, toggle, nextModal, title, userName, isEditPoll, pollData } =
    props;
  const inputArr = [
    {
      title: "",
    },
    {
      title: "",
    },
  ];
  const [arr, setArr] = useState(inputArr);
  const [isSubmit, setSubmit] = useState(true);
  const [formData, setFormData] = useState({
    question: "",
    option: [],
    poll_duration: 12,
  });
  useEffect(() => {
    if (pollData && isEditPoll) {
      setFormData(pollData);
      if (pollData && pollData?.option?.length > 0) {
        setArr(pollData.option);
      }
    }
  }, []);

  const validation = () => {
    if (!formData.question?.trim()) {
      toast.error("Please enter a question");
      return false;
    }
    const options = arr.map((option) => option.title.trim());
    const uniqueOptions = new Set(options);

    if (options.some((title) => !title)) {
      toast.error("Please enter valid options");
      return false;
    }

    if (options.length !== uniqueOptions.size) {
      toast.error("Duplicate options are not allowed");
      return false;
    }

    return true;
  };

  useEffect(() => {
    if (formData.question.trim(" ").length > 0) {
      if (
        arr[0]?.title?.trim(" ").length >= 1 &&
        arr[1]?.title?.trim(" ").length >= 1
      ) {
        setSubmit(false);
      } else {
        setSubmit(true);
      }
    } else setSubmit(true);
  }, [formData]);

  const removeOption = (ind) => {
    const removedData = arr.filter((item, i) => {
      return i !== ind;
    });
    console.log(removedData);
    setArr(removedData);
  };

  const addInput = () => {
    // for(let i=0; i<arr.length-1 ;i++ ){
    // if(arr[i]?.title.trim(" ").length>0){
    // if(pollData && pollData?.option.length>0){
    //   setArr(pollData.option)
    // }
    setArr((s) => {
      return [
        ...s,
        {
          title: "",
        },
      ];
    });
    // }
    // }
  };

  const finalForm = (e) => {
    e.preventDefault();
    formData.option = arr;
    if (validation()) nextModal(formData);
  };

  const handleOption = (e) => {
    e.preventDefault();
    const index = e.target.id;
    if (e.target.value.length <= 30) {
      setArr((s) => {
        const newArr = s.slice();
        newArr[index].title = e.target.value;
        return newArr;
      });
    }
  };

  const handleChange = (e) => {
    if (e.target.value.length <= 150)
      setFormData({ ...formData, [e.target.name]: e.target.value });
  };
  const handleDurationChange = (e) => {
    formData.poll_duration = Number(e.target.value);
    setFormData({ ...formData });
  };

  return (
    <div>
      <Modal isOpen={modal} size="lg" style={{ maxWidth: "600px" }} className="gradient-background" centered>
        <ModalHeader toggle={toggle}>
          {title}
          {/* <div className="icon-expand"></div> */}
        </ModalHeader>
        <form className="pollModal">
          <ModalBody>
            <label className="form-label  pollModal">Your question</label>
            <textarea
              name="question"
              value={formData.question}
              className="form-control"
              placeholder="e.g which one is best? Facebook or instagram"
              onChange={handleChange}
            />
            <div className="paraClass">
              <p className="textFeildP">{formData.question.length}/150</p>
            </div>

            {arr.map((item, i) => {
              return (
                <>
                  <div className="row">
                    <label className="form-label col">Option {i + 1}</label>
                    {i > 1 ? (
                      <p
                        className="col pollParaTag"
                        onClick={() => removeOption(i)}
                      >
                        Remove
                      </p>
                    ) : (
                      ""
                    )}
                  </div>
                  <input
                    onChange={handleOption}
                    value={item.title}
                    id={i}
                    placeholder="Your option"
                    className="form-control"
                    size="30"
                  />
                  <div className="paraClass">
                    {" "}
                    <p>{item.title.length}/30</p>
                  </div>
                </>
              );
            })}
            {arr.length < 4 ? (
              <h5 className="curserPointer" onClick={addInput}>
                + Add option
              </h5>
            ) : (
              <></>
            )}
            <label className="form-label">Poll duration</label>
            <select
              defaultValue={24}
              className="form-select"
              value={formData.poll_duration}
              name="poll_duration"
              onChange={(e) => handleDurationChange(e)}
            >
              <option value={12} hidden disabled>
                Select poll duration
              </option>
              <option value={24}>1 Day</option>
              <option value={7 * 24}>1 Week</option>
              <option value={30 * 24}>1 Month</option>
            </select>

            <p>
              Political, medical-related information or other sensitive data are
              strictly prohibited.
            </p>
          </ModalBody>
          <div className="row buttonRow">
            <div className="col-4 buttonCol">
              <button className="btn secondarybtn">Back</button>
              {isSubmit ? (
                <button className="btn btn-blue" disabled={true}>
                  Done
                </button>
              ) : (
                <button
                  className="btn btn-blue"
                  disabled={false}
                  onClick={(e) => finalForm(e)}
                >
                  Done
                </button>
              )}
            </div>
          </div>
        </form>
      </Modal>
    </div>
  );
};

export default CreatePollModal;
