import { leadsAPI } from "../../api/leadsAPI";
import {
  POST_LEAD_DATA,
  GET_LEAD_LIST,
  ADD_LEAD_GROUP,
  ADD_LEAD_ACTIONS,
  GET_LEADS_ACTION_LIST,
  EDIT_LEAD_DATA,
  DELETE_LEAD_DATA,
  SEND_LEADS_CONNECTION,
  DELETE_LEAD_GROUP,
  EDIT_LEAD_GROUP
} from "../types";

export const postLead = (data) => (dispatch) => {
  return leadsAPI.createLead(data).then(
    (response) => {
      dispatch({
        type: POST_LEAD_DATA,
        payload: response.data,
      });
      return Promise.resolve(response);
    },
    (error) => {
      console.log(error);
      return Promise.reject(error);
    }
  );
};

export const editLeadData = (data, id, type) => (dispatch) => {
  return leadsAPI.editLead(data, id).then(
    (response) => {
      dispatch({
        type: EDIT_LEAD_DATA,
        payload: { data, id, type },
      });
      return Promise.resolve(response);
    },
    (error) => {
      console.log(error);
      return Promise.reject(error);
    }
  );
};

export const deleteLeadData = (id) => (dispatch) => {
  return leadsAPI.deleteLead(id).then(
    (response) => {
      dispatch({
        type: DELETE_LEAD_DATA,
        payload: id,
      });
      return Promise.resolve(response);
    },
    (error) => {
      console.log(error);
      return Promise.reject(error);
    }
  );
};

export const getLeads = (data) => (dispatch) => {
  return leadsAPI.getLeadsList(data).then(
    (response) => {
      dispatch({
        type: GET_LEAD_LIST,
        payload: response.data,
      });
      return Promise.resolve(response);
    },
    (error) => {
      console.log(error);
      return Promise.reject(error);
    }
  );
};

export const addLeadGroup = (leads_group, data, action = "add") => (dispatch) => {
  return leadsAPI.addGroupList(leads_group).then(
    (response) => {
      if(action == "delete"){
        dispatch({
          type: DELETE_LEAD_GROUP,
          payload: data,
        });
      }else if(action == "edit"){
        data["newGroups"] = leads_group.leads_group
        dispatch({
          type: EDIT_LEAD_GROUP,
          payload: data
        })
      }else{
        dispatch({
          type: ADD_LEAD_GROUP,
          payload: data,
        });
      }
      return Promise.resolve(response);
    },
    (error) => {
      console.log(error);
      return Promise.reject(error);
    }
  );
};

export const addLeadActions = (data) => (dispatch) => {
  return leadsAPI.leadAction(data).then(
    (response) => {
      dispatch({
        type: ADD_LEAD_ACTIONS,
        payload: data,
      });
      return Promise.resolve(response);
    },
    (error) => {
      console.log(error);
      return Promise.reject(error);
    }
  );
};

export const getLeadActionbyId = (data) => (dispatch) => {
  return leadsAPI.leadActionId(data).then(
    (response) => {
      dispatch({
        type: GET_LEADS_ACTION_LIST,
        payload: response.data,
      });
      return Promise.resolve(response);
    },
    (error) => {
      console.log(error);
      return Promise.reject(error);
    }
  );
};

export const leadConnectionStatus = (data) => (dispatch) => {
  return leadsAPI.leadConnectionAPI(data).then(
    (response) => {
      dispatch({
        type: SEND_LEADS_CONNECTION,
        payload: data,
      });
      return Promise.resolve(response);
    },
    (error) => {
      console.log(error);
      return Promise.reject(error);
    }
  );
};
