import React from "react";
import DocViewer, { DocViewerRenderers } from "@cyntler/react-doc-viewer";

const DocView = (props) => {
  const { docURL } = props;
  const docs = [{ uri: docURL }];

  return (
    docURL && (
      <DocViewer
        documents={docs}
        pluginRenderers={DocViewerRenderers}
        config={{ header: { disableHeader: true } }}
        style={{
          width: "100%",
          maxHeight: 300,
          minHeight: 300,
          border: "1px solid #e5e5e5",
        }}
        theme={{
          disableThemeScrollbar: true,
        }}
      />
    )
  );
};

export default DocView;
