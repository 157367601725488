import React, { useEffect, useRef, useState } from "react";
import { Dropdown, FormGroup, Input, Label } from "reactstrap";
import JoinUsSection from "../../../core/components/Sections/JoinUsSection/JoinUsSection";
import { call } from "../../../api/apiCall";
import { getLocationsData } from "../../../store/connections/connectionsActions";
import {
  addexperiencedetails,
  getAllCompanyList,
} from "../../../store/profile/profileActions";
import Autocomplete from "react-autocomplete";
import buld from "../../../assets/img/circle-building.svg";
import { useDispatch, useSelector } from "react-redux";
import DatePickerCustom from "../../../core/components/DatePicker/DatePickerReact";
import moment from "moment";
import AddMediaExper from "../../profile-pages/profile/components/AddMediaExper";
import axios from "axios";
import { toast } from "react-toastify";
import {
  initLoad,
  initPageLoad,
  stopLoad,
  stopPageLoad,
} from "../../../store/loader/loaderActions";
import deleteIcon from "../../../assets/img/deleteIcon.svg";

import AppLoader from "../../../core/components/Loaders/appLoader/appLoader";
import DeleteModal from "../../../core/components/modal/DeleteModal";
const AddExperience = (props) => {
  const token = localStorage.getItem("accessTpToken");
  const { activePage, setActivePage, activeTab, setActiveTab } = props;
  const { talentPoolData } = useSelector((state) => state.portal);
  const dispatch = useDispatch();
  const { isPageLoading } = useSelector((state) => state.loader);
  const imgRef = useRef();
  const [companyType, setCompanyType] = useState("");
  const [showLabel, setShowLabel] = useState("");
  const [memberInput, setMemberInput] = useState("");
  const [locationInput, setLocationInput] = useState("");
  const [companyId, setCompanyId] = useState(null);
  const [companyList, setCompanyList] = useState([]);
  const [locationList, setLocationList] = useState([]);
  const [listImage, setListImage] = useState("");
  const [addMediaModal, setAddMediaModal] = useState(false);
  const [image, setImage] = useState("");
  const [fileName, setFileName] = useState("");
  const [mediaDescription, setMediaDescription] = useState("");
  const [selectedMediaId, setSelectedMediaId] = useState("");
  const { profileData } = useSelector((state) => state.profile);
  const [deleteModal, setDeleteModal] = useState(false);
  const [deleteIndex, setDeleteIndex] = useState();
  const [expData, setExpData] = useState({
    title: "",
    employment_type: "",
    company_name: "",
    location: "",
    start_date: "",
    end_date: null,
    description: "",
    is_current: false,
    is_visible: true,
    notify_network: false,
    medias: [],
    country_id: "",
    state_id: "",
    page_id: "",
    city_id: 392,
  });
  const [errorTitle, setErrorTitle] = useState("");
  const [errorCompany, setErrorCompany] = useState("");
  const [errorLocation, setErrorLocation] = useState("");
  const [errorDate, setErrorDate] = useState({
    start_date: "",
    end_date: "",
  });

  const checkValidation = (type) => {
    let formIsValid = true;

    if (type === "title" || type === "all") {
      if (!expData?.title?.trim()) {
        setErrorTitle("The Title is mandatory.");
        formIsValid = false;
      } else {
        setErrorTitle("");
      }
    }

    if (type === "company" || type === "all") {
      if (!expData?.company_name) {
        setErrorCompany("The company name is mandatory.");
        formIsValid = false;
        return;
      } else if (!expData?.page_id) {
        setErrorCompany("Please select company");
        formIsValid = false;
        return;
      } else {
        setErrorCompany("");
      }
    }
    if (type === "location" || type === "all") {
      if (!expData?.location) {
        setErrorLocation("Location is mandatory.");
        formIsValid = false;
        return;
      } else {
        setErrorLocation("");
      }
    }
    if (type === "date" || type === "all") {
      if (!expData?.start_date) {
        setErrorDate({ ...errorDate, start_date: "Start date is mandatory" });
        formIsValid = false;
        return;
      } else if (expData?.is_current === false && expData?.start_date) {
        if (!expData?.end_date) {
          setErrorDate({ ...errorDate, end_date: "End date is mandatory" });
          formIsValid = false;
          return;
        }
      } else {
        setErrorDate("");
      }
    }

    return formIsValid;
  };

  useEffect(() => {
    (async () => {
      try {
        const res = await call("get", "api/v1/employment-type", null, null);
        setCompanyType(res.data);
      } catch (err) {
        console.log(err);
      }
    })();

    setShowLabel(expData.employment_type);
  }, []);

  useEffect(() => {
    if (locationInput) {
      dispatch(getLocationsData(locationInput))
        .then((res) => {
          if (res?.data) {
            setLocationList(res?.data);
          } else setLocationList([]);
        })
        .catch(() => {
          setLocationList([]);
        });
    }
  }, [locationInput]);

  useEffect(() => {
    if (memberInput) {
      dispatch(getAllCompanyList({ search: memberInput.val }))
        .then((res) => {
          if (res?.success === true) {
            setCompanyList(res?.data);
          } else setCompanyList([]);
        })
        .catch(() => {
          setCompanyList([]);
        });
    }
  }, [memberInput]);

  // const handleChange = async (e, type) => {
  //   console.log(e, type, e.target.value, e.target.files);
  //   var fileExtension = e.target.value.split(".").pop();
  //   var validFile = true;
  //   let fileValidation =
  //     /^(jpg|jpeg|png|JPG|JPEG|PNG|pdf|ppt|pptx|txt|^text\/plain|doc|docx|xls|xlsx)$/;
  //   if (e.target.id === "chosefile1") {
  //     if (fileValidation.test(fileExtension) == false) {
  //       validFile = false;
  //     }
  //   }
  //   console.log(validFile, "outer");

  //   if (validFile) {
  //     console.log(validFile, "outer");
  //     let tempimage = [];
  //     Array.from(e.target.files).map((file) => {
  //       tempimage.push({
  //         type,
  //         file,
  //       });
  //     });
  //     try {
  //      ;
  //       const formData = new FormData();
  //       const headers = {
  //         "Accept-Language": "en",
  //         "content-type": "multipart/form-data",
  //       };

  //       for (var i = 0; i < tempimage.length; i++) {
  //         formData.append("image", tempimage[i].file);
  //       }
  //       dispatch(initLoad());
  //       await axios.post(UPLOAD_URL, formData, { headers }).then((res) => {
  //         dispatch(stopLoad());
  //         let { filesArray, urlsArray } = res.data;
  //         setFileName(filesArray[0].originalname);
  //         setImage(urlsArray[0]);
  //         setAddMediaModal(true);
  //       });
  //     } catch (err) {
  //       if (err.response.data.message === "File too large") {
  //         toast.warn("File is too large, please upload another file", {
  //           position: toast.POSITION.BOTTOM_LEFT,
  //         });
  //         dispatch(stopLoad());
  //       }
  //       console.log(err);
  //     }
  //   } else {
  //     toast.warn("Please choose valid file type", {
  //       position: toast.POSITION.BOTTOM_LEFT,
  //     });
  //     return;
  //   }
  // };

  const handleChange = async (e, type) => {
    console.log(e, type, e.target.value, e.target.files);
    var fileExtension = e.target.value.split(".").pop();
    var validFile = true;
    let fileValidation = /^(jpg|jpeg|png|gif|JPG|JPEG|PNG|GIF)$/;

    if (e.target.id === "chosefile1") {
      if (!fileValidation.test(fileExtension)) {
        validFile = false;
      }
    }

    if (validFile) {
      let tempimage = [];
      Array.from(e.target.files).map((file) => {
        tempimage.push({
          type,
          file,
        });
      });
      try {
        const UPLOAD_URL = `${process.env.REACT_APP_API_URL}api/v1/upload`;
        const formData = new FormData();
        const headers = {
          "Accept-Language": "en",
          "content-type": "multipart/form-data",
        };

        for (var i = 0; i < tempimage.length; i++) {
          formData.append("image", tempimage[i].file);
        }
        dispatch(initLoad());
        const res = await axios.post(UPLOAD_URL, formData, { headers });
        dispatch(stopLoad());
        let { filesArray, urlsArray } = res.data;
        setFileName(filesArray[0].originalname);
        setImage(urlsArray[0]);
        setAddMediaModal(true);
      } catch (err) {
        if (
          err.response &&
          err.response.data &&
          err.response.data.message === "File too large"
        ) {
          toast.warn("File is too large, please upload another file", {
            position: toast.POSITION.BOTTOM_LEFT,
          });
        } else {
          console.log(err);
        }
        dispatch(stopLoad());
      }
    } else {
      toast.error(
        "Please upload only file types jpg/jpeg/png/gif/JPG/JPEG/PNG/GIF"
      );
      return;
    }
  };

  const handleAddMedia = () => {
    setExpData({
      ...expData,
      medias: [
        ...expData?.medias,
        {
          title: fileName,
          description: mediaDescription,
          image: image,
          type: "experience",
        },
      ],
    });
    setFileName("");
    setMediaDescription("");
    setAddMediaModal(false);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (checkValidation("all")) {
      dispatch(initPageLoad());
      dispatch(addexperiencedetails(expData, token))
        .then((res) => {
          if (res.success == true) {
            setActivePage("intro");
            setExpData({
              title: "",
              employment_type: "",
              company_name: "",
              location: "",
              start_date: "",
              end_date: null,
              description: "",
              is_current: "",
              is_visible: true,
              notify_network: false,
              medias: [],
              country_id: "",
              state_id: "",
              page_id: "",
              city_id: 392,
            });
            toast.success("Experience added successfully", {
              position: toast.POSITION.BOTTOM_LEFT,
            });
          }
          dispatch(stopPageLoad());
        })
        .catch((e) => {
          dispatch(stopPageLoad());
          console.log(e);
        });
    }
  };
  return (
    <>
      {isPageLoading && <AppLoader />}
      <div className="add-experience-section">
        <div className="edit-form">
          <h3>Add Experience</h3>
          <form>
            <div className="exper mb-3 mt-3">
              <FormGroup>
                <Label for="educationdropdown">Title</Label>

                <Input
                  type="text"
                  onClick={(e) => setErrorTitle("")}
                  placeholder="Job title"
                  maxLength="50"
                  onBlur={() => checkValidation("title")}
                  value={expData.title}
                  onChange={(e) => {
                    setExpData({ ...expData, title: e.target.value });
                  }}
                />
                {errorTitle && (
                  <p className="label-input100 text-danger">{errorTitle}</p>
                )}
              </FormGroup>
            </div>
            <div className="exper mb-3 mt-3">
              <FormGroup>
                <Label for="educationdropdown">Employment type</Label>
                <Input
                  value={expData.employment_type}
                  style={{ color: "#676A79", height: "40px" }}
                  id="type"
                  name="select"
                  type="select"
                  onChange={(e) =>
                    setExpData({
                      ...expData,
                      employment_type: e.target.value,
                    })
                  }
                >
                  <option value="">Select type</option>
                  {companyType?.data?.map((item, index) => {
                    return (
                      <option key={index} value={item.name}>
                        {item.name}
                      </option>
                    );
                  })}
                </Input>
              </FormGroup>
            </div>
            <div className="exper mb-3 mt-3">
              <FormGroup>
                <Label for="employmentropdown">Company name</Label>
                {/* {false ? (
                <Dropdown label="Select" />
              ) : (
                <Input id="employmentropdown" name="select" type="select">
                  <option value="">--select--</option>
                  <option value="1">Option 1</option>
                  <option value="2">Option 2</option>
                  <option value="3">Option 3</option>
                </Input>
              )} */}

                <div className="company-custom-dropdown mb-20">
                  <div className="autocomplete-wrapper portal-auto-complete location">
                    <Autocomplete
                      // inputProps={{ placeholder: "Add Members" }}
                      inputProps={{
                        style: {
                          height: "40px",
                        },
                        maxLength: 30,
                        onBlur: () => checkValidation("company"),
                      }}
                      value={memberInput.val}
                      items={companyList}
                      getItemValue={(item) => item.name}
                      // shouldItemRender={(item, value) =>
                      //   item.first_name
                      //     .toLowerCase()
                      //     .indexOf(value.toLowerCase()) > -1
                      // }
                      renderMenu={(items, value) =>
                        items.length === 0 ? (
                          <></>
                        ) : (
                          <div className="dropdown">{items} </div>
                        )
                      }
                      renderItem={(item, isHighlighted) => (
                        <div
                          className={`item ${
                            isHighlighted ? "selected-item" : ""
                          }`}
                          style={{ display: "flex" }}
                        >
                          {/* <img
                              src={item.icon ? item.icon : buld}
                              
                              // expData.is_visible
                              alt="dropdown_pic"
                              className="image-fit mr-10"
                              style={{ height: "36px", width: "36px" }}
                            /> */}
                          {/* {console.log(expData, companyId)} */}
                          <img
                            style={{
                              height: "35px",
                              width: "35px",
                              borderRadius: "50%",
                            }}
                            // src={
                            //   companyId && expData.is_visible ? buld : listImage
                            // }
                            src={item.icon ? item.icon : buld}
                            alt=""
                          />
                          <div className="mx-2">
                            <div className="name-heading-drop ">
                              {item.name}
                            </div>
                          </div>
                        </div>

                        // <div
                        //   className={`item ${
                        //     isHighlighted ? "selected-item" : ""
                        //   }`}
                        //   style={{ display: "flex" }}
                        // >
                        //   {/* <img
                        //       src={item.icon ? item.icon : buld}

                        //       // expData.is_visible
                        //       alt="dropdown_pic"
                        //       className="image-fit mr-10"
                        //       style={{ height: "36px", width: "36px" }}
                        //     /> */}
                        //   {companyId && expData.is_visible && (
                        //     <img
                        //       className="me-3"
                        //       style={{ height: "35px", width: "35px" }}
                        //       src={listImage}
                        //       alt=""
                        //     />
                        //   )}
                        //   <div>
                        //     <div
                        //       className="name-heading-drop mx-3"
                        //       style={{ paddingLeft: companyId ? "10px" : "0" }}
                        //     >
                        //       {item.name}
                        //     </div>
                        //     {/* <div className="sub-heading-drop">
                        //       {item?.profile?.profile_headline ? (
                        //         item?.profile?.profile_headline
                        //       ) : (
                        //         <></>
                        //       )}
                        //     </div> */}
                        //   </div>
                        // </div>
                      )}
                      onChange={(event, val) => {
                        setMemberInput({ val });
                        setCompanyId("");
                        setExpData({
                          ...expData,
                          company_name: val,
                          page_id: null,
                        });
                      }}
                      onSelect={(val, obj) => {
                        setMemberInput({ val });
                        setListImage(obj.icon || buld);
                        setCompanyId(obj.id);
                        setExpData({
                          ...expData,
                          company_name: val,
                          page_id: obj.id,
                        });
                      }}
                      wrapperStyle={{}}
                      renderInput={(params) => {
                        return (
                          <div class="fake-input">
                            <input
                              {...params}
                              type="text"
                              placeholder="Company name"
                              // onClick={(e) => setErrorCompany("")}
                              // onBlur={() => checkValidation("company")}
                            />
                            {/* <img src={companyId ? listImage : buld} alt="" /> */}

                            <img
                              style={{ height: "35px", width: "35px" }}
                              src={
                                companyId && expData.is_visible
                                  ? listImage
                                  : buld
                              }
                              alt=""
                            />

                            {/* <img src={companyId ? (expData.is_visible ? listImage : buld) : null} alt="" /> */}
                          </div>
                        );
                      }}
                    />
                  </div>
                  {errorCompany && (
                    <p className="label-input100 text-danger">{errorCompany}</p>
                  )}
                </div>
              </FormGroup>
            </div>
            <div className="exper mb-3 mt-3">
              <FormGroup>
                <Label for="employmentropdown">Location</Label>
                <div className="company-custom-dropdown mb-20">
                  <div className="autocomplete-wrapper portal-auto-complete">
                    <Autocomplete
                      inputProps={{
                        placeholder: "Location",
                        style: { height: "40px" },
                        onBlur: () => checkValidation("location"),
                      }}
                      value={locationInput}
                      items={locationList}
                      getItemValue={(item) => item.name}
                      // shouldItemRender={(item, value) =>
                      //   item.name.toLowerCase().indexOf(value.toLowerCase()) > -1
                      // }
                      // renderMenu={(items, value) => (
                      //   <div className="dropdown">
                      //     {items.length === 0
                      //       ? `No matches for ${value}`
                      //       : items}
                      //   </div>
                      // )}
                      renderMenu={(items, value) => {
                        const noMatches =
                          items.length === 0 && value.trim() !== "";
                        return (
                          <div className="dropdown">
                            {noMatches ? `No matches for ${value}` : items}
                          </div>
                        );
                      }}
                      renderItem={(item, isHighlighted) => (
                        <div
                          className={`item ${
                            isHighlighted ? "selected-item" : ""
                          }`}
                        >
                          {item.name}
                        </div>
                      )}
                      onChange={(event, val) => setLocationInput(val)}
                      onSelect={(val, obj) => {
                        setLocationInput(val);
                        setExpData({
                          ...expData,
                          location: obj.name,
                          country_id: obj.country_id ? obj.country_id : null,
                          state_id: obj.state_id ? obj.state_id : null,
                          city_id: obj.city_id ? obj.city_id : null,
                        });
                      }}
                      wrapperStyle={{}}
                    />
                  </div>
                  {errorLocation && (
                    <p className="label-input100 text-danger">
                      {errorLocation}
                    </p>
                  )}
                </div>
              </FormGroup>
            </div>
            <div className="double-input">
              <div className="mb-3">
                <label>Start date</label>
                {/* <Input type="date" placeholder="--pick--" /> */}

                <DatePickerCustom
                  value={expData.start_date ? new Date(expData.start_date) : ""}
                  onChange={(date) => {
                    date
                      ? setExpData({
                          ...expData,
                          start_date: moment(date).format("YYYY-MM-DD"),
                          end_date: "",
                        })
                      : setExpData({
                          ...expData,
                          start_date: "",
                          end_date: "",
                        });
                  }}
                  max={new Date()}
                  placeholder="dd/mm/yyyy"
                  onFocus={(e) =>
                    setErrorDate({ ...errorDate, start_date: "" })
                  }
                />
                {errorDate?.start_date && (
                  <p className="label-input100 text-danger">
                    {errorDate?.start_date}
                  </p>
                )}
              </div>
              <div className="mb-3">
                <label>End date</label>
                <DatePickerCustom
                  value={expData.end_date ? new Date(expData.end_date) : ""}
                  onChange={(date) => {
                    date
                      ? setExpData({
                          ...expData,
                          end_date: moment(date).format("YYYY-MM-DD"),
                        })
                      : setExpData({ ...expData, end_date: "" });
                  }}
                  placeholder="dd/mm/yyyy"
                  onFocus={(e) => setErrorDate({ ...errorDate, end_date: "" })}
                  disabled={
                    expData.start_date === "" || expData.is_current === true
                      ? true
                      : false
                  }
                  max={new Date()}
                  min={expData.start_date ? new Date(expData.start_date) : ""}
                />
                {errorDate?.end_date && (
                  <p className="label-input100 text-danger">
                    {errorDate?.end_date}
                  </p>
                )}
              </div>
            </div>
            <div className="checkbox-box">
              <input
                type="checkbox"
                checked={expData.is_current}
                onChange={(e) =>
                  setExpData({
                    ...expData,
                    is_current: e.target.checked,
                    end_date: null,
                  })
                }
              />
              I am currently working in this role
            </div>
            <div className="contact-info mb-3 mt-4">
              <h6>Media</h6>
              <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc
                vulputate <br />
                libero et velit interdum, ac aliquet odio mattis.
              </p>
              <span
                style={{ color: `${talentPoolData?.theme}` }}
                className="addButton orange-clr"
                onClick={() => {
                  try {
                    document.getElementById("chosefile1").click();
                  } catch (error) {
                    console.log(error);
                  }
                }}
              >
                + Add media
              </span>
            </div>

            {expData?.medias?.map((item, index) => {
              return (
                <div
                  className="show-media d-flex justify-content-between align-items-center m-3"
                  key={index}
                >
                  <div className="show-media d-flex m-3">
                    <div className="show-media-image">
                      <img
                        alt="media"
                        src={item?.image}
                        className="main-image"
                        width={50}
                        height={50}
                      />
                    </div>
                    <div className="mx-3">
                      <p className="card-control-text-oneline">{item?.title}</p>
                      <p className="card-control-text-oneline">
                        {item?.description}
                      </p>
                    </div>
                  </div>
                  <img
                    src={deleteIcon}
                    height={30}
                    width={30}
                    onClick={() => {
                      setDeleteModal(true);
                      setDeleteIndex(index);
                    }}
                  />
                </div>
              );
            })}
          </form>
          <input
            type="file"
            ref={imgRef}
            accept="image/png,image/jpeg"
            id="chosefile1"
            onChange={(e) => handleChange(e, "image")}
            onClick={(e) => {
              e.target.value = "";
              setImage("");
              setFileName("");
            }}
          />
        </div>

        {!profileData?.np && <JoinUsSection />}
        <button
          className="close-btn"
          onClick={() => setActivePage("intro")}
          style={{
            border: `1px solid ${talentPoolData?.theme}`,
            color: `${talentPoolData?.theme}`,
          }}
        >
          Close
        </button>
        <button
          className="save-btn"
          onClick={(e) => handleSubmit(e)}
          style={{ background: `${talentPoolData?.theme}` }}
        >
          Save
        </button>

        {addMediaModal && (
          <AddMediaExper
            modal={addMediaModal}
            toggle={() => setAddMediaModal(!addMediaModal)}
            image={image}
            setImage={setImage}
            fileName={fileName}
            setFileName={setFileName}
            mediaDescription={mediaDescription}
            setMediaDescription={setMediaDescription}
            add={() => handleAddMedia()}
            expData={expData}
            setExpData={setExpData}
            selectedMediaId={selectedMediaId}
          />
        )}

        {deleteModal && (
          <DeleteModal
            modal={DeleteModal}
            toggle={() => setDeleteModal(!deleteModal)}
            title="Delete Confirmation"
            deleteConfirm={() => {
              const media = expData?.medias?.filter(
                (ele, i) => i !== deleteIndex
              );
              setExpData({ ...expData, medias: media });
              setDeleteIndex();
            }}
            text="Are you sure you want to delete this?"
          />
        )}
      </div>
    </>
  );
};

export default AddExperience;
