import React, { useState, useEffect } from "react";
import SidebarFooter from "../../setting/SidebarFooter";
import { useNavigate, Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import placeholderUser from "../../../assets/img/placeholder_user.jpg";
import pagePlaceholder from "../../../assets/img/page-placeholder.svg";
import cale from "../../../assets/img/calendar.svg";
import "./WalletSidebar.scss";
import { getSuggestedArticles } from "../../../store/articles/articleActions";
import { getSuggestedGroups } from "../../../store/groups/groupsActions";
import { getSuggestedEvents } from "../../../store/events/eventActions";
import { getHashtagData } from "../../../store/hashtag/hashtagActions";
import { getAllPages } from "../../../store/pages/pagesAction";

export default function WalletSidebar() {
  const { user } = useSelector((state) => state.auth);
  const { pageData } = useSelector((state) => state.pages);
  const { suggested_articles } = useSelector((state) => state.article);
  const { suggested_groups } = useSelector((state) => state.groups);
  const { suggested_events } = useSelector((state) => state.events);
  const { followedTagList } = useSelector((state) => state.hashtag);

  const [isSticky, setIsSticky] = useState(false);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    let token = window.localStorage.getItem("accessToken");
    dispatch(
      getSuggestedArticles(
        token,
        "suggested",
        { type: "suggested", skip: 0, limit: 10 },
        true
      )
    );
    dispatch(getSuggestedGroups(null));
    dispatch(getSuggestedEvents(token, { type: "suggested" }));
    let query = "followed";
    dispatch(getHashtagData(query));
    dispatch(getAllPages({ filter: "my" }));
  }, []);

  useEffect(() => {
    const handleScroll = () => {
      const sidebar = document.querySelector(".default_sidebar");
      const sidebarRect = sidebar?.getBoundingClientRect();
      // console.log(sidebarRect.top + sidebarRect.height < window.innerHeight);
      if (sidebarRect.top + sidebarRect.height < window.innerHeight) {
        setIsSticky(true);
        sidebar.style.top = `${sidebarRect.top}px`;
        // console.log(sidebar.style.top);
      } else setIsSticky(false);
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const viewConnection = () => {
    navigate("/connections");
  };

  return (
    <div
      className={
        isSticky ? "default_sidebar sidebar-sticky-left" : "default_sidebar"
      }
    >
      <div className="left border-radius-5">
        <div className="profile">
          {user?.data?.background_img ? (
            <img
              src={user?.data?.background_img}
              alt="background img"
              className="back-image-fluid"
              style={{ position: "absolute" }}
            />
          ) : (
            <></>
          )}

          <div className="img">
            <Link to={"/profile/" + user?.data?.id}>
              <img
                src={
                  user?.data?.profile_img
                    ? user?.data?.profile_img
                    : placeholderUser
                }
                alt="profile img"
                className="img-fluid"
              />
            </Link>
          </div>
        </div>
        <div className="starter">
          {" "}
          {user?.data?.membership?.name || "Starter"}
        </div>
        <div className="px-3">
          <div className="profile-details">
            <Link to={"/profile/" + user?.data?.id}>
              {/* { console.log("user", user)} */}
              <h6 className="userNameTextWrap">
                {user?.data?.first_name} {user?.data?.last_name}
              </h6>
            </Link>

            <p className="card-control-text-twoline">
              {user?.data?.profile_headline ? user?.data?.profile_headline : ""}
            </p>
          </div>
          <div className="margin">
            <div className="follower" onClick={viewConnection}>
              <div className="box curserPointer">
                <h6>{user?.data?.connections}</h6>
                <p>
                  {user?.data?.connections > 1 ? "Connections" : "Connection"}
                </p>
              </div>
              <div className="h-border "></div>
              <div className="box curserPointer">
                <h6>{user?.data?.followers}</h6>
                <p>{user?.data?.followers > 1 ? "Followers" : "Follower"}</p>
              </div>
              <div className="h-border"></div>

              <div className="box curserPointer">
                <h6>{user?.data?.followings}</h6>
                <p>{user?.data?.followings > 1 ? "Followings" : "Following"}</p>
              </div>
            </div>
            <div className="line mb-20"></div>
          </div>
          <div className="update mt-3">
            <Link to={"/profile/" + user?.data?.id}>
              <span>View My Profile</span>
            </Link>
          </div>
          <div className="pages my-4">
            <h4 className="mb-3">
              {pageData?.data?.length > 1 ? "My Pages" : "My Page"}
            </h4>
            <div className="line"></div>
            <div className="mt-3">
              {pageData?.data?.map((item, index) => {
                if (index < 3) {
                  return (
                    <div key={index} className="box">
                      <Link to={`/pageadminview/${item?.id}`}>
                        <div className="img">
                          {item?.background_img ? (
                            <img src={item?.background_img} alt="" />
                          ) : (
                            <img alt="" />
                          )}
                          <img
                            src={item?.icon ? item?.icon : pagePlaceholder}
                            alt=""
                            className="page-icon"
                          />
                        </div>
                      </Link>
                      <div className="text">
                        <Link to={`/pageadminview/${item?.id}`}>
                          <h6
                            className="card-control-text-twoline"
                            style={{
                              wordBreak: "break-word",
                              color: "#0432a3",
                            }}
                          >
                            {item.name}
                          </h6>
                        </Link>
                        <span>
                          {item?.followers_count}{" "}
                          {item?.followers_count > 1 ? "followers" : "follower"}
                        </span>
                      </div>
                    </div>
                  );
                }
              })}
              <div className="mt-3">
                <Link to={"/pages"}>
                  <span>See all</span>
                </Link>
              </div>
            </div>
          </div>
          <div className="article my-4">
            <h4 className="mb-3">Articles for you</h4>
            <div className="line"></div>
            <div className="mt-3">
              {suggested_articles?.map((item, index) => {
                if (index < 3) {
                  return (
                    <div key={index} className="box">
                      <Link to={`/articledetail/${item?.id}`}>
                        <div className="img">
                          {item?.media ? (
                            <img src={item?.media} alt="" />
                          ) : (
                            <img src={null} alt="" />
                          )}
                        </div>
                      </Link>
                      <div className="text">
                        <Link to={`/articledetail/${item?.id}`}>
                          <h6
                            className="card-control-text-twoline"
                            style={{ wordBreak: "break-word" }}
                          >
                            {item.title}
                          </h6>
                        </Link>
                        <span>12,932 viewers</span>
                      </div>
                    </div>
                  );
                }
              })}
              <div className="mt-3">
                <Link to={"/articles"}>
                  <span>See all</span>
                </Link>
              </div>
            </div>
          </div>
          <div className="groups my-4">
            <h4 className="mb-3">Groups</h4>
            <div className="line mb-3"></div>
            <ul>
              {suggested_groups?.map((item, index) => {
                if (index < 3) {
                  return (
                    <Link to={`/groupdetails/${item?.id}`}>
                      <li
                        className="card-control-text-oneline mb-2"
                        style={{ wordBreak: "break-word" }}
                        key={index}
                      >
                        {item.name}
                      </li>
                    </Link>
                  );
                }
              })}
            </ul>
            <Link to={"/groups"}>
              <span className="ms-0">See all</span>
            </Link>
          </div>
          <div className="events">
            <h4 className="mb-3">Events</h4>
            <div className="line mb-3"></div>
            <ul className="mb-2">
              {suggested_events?.map((item, index) => {
                if (index < 3) {
                  return (
                    <Link to={`/eventdetail/${item?.id}`}>
                      <li
                        className="card-control-text-oneline"
                        style={{ wordBreak: "break-word" }}
                        key={index}
                      >
                        <div className="icon">
                          <img src={cale} alt="" />
                        </div>{" "}
                        {item.title}
                      </li>
                    </Link>
                  );
                }
              })}
            </ul>
            <Link to={"/event"}>
              <span className="ms-0">See all</span>
            </Link>
          </div>
          <div className="follow mt-4 mb-10 pb-2">
            <h4 className="mb-3">Followed Hashtags</h4>
            <div className="line"></div>
            <div className="tag_parent">
              {followedTagList?.map((item, index) => {
                if (index < 6) {
                  return (
                    <div>
                      <p className="tags_text">#{item?.name}</p>
                    </div>
                  );
                }
              })}
            </div>
            <Link to={"/hashtag"}>
              <span>See all</span>
            </Link>
          </div>
        </div>
      </div>
      <Link to={"/network"}>
        <div className="left px-3 py-3 mb-10 d-flex justify-content-center border-radius-5">
          <div className="discover">
            <h4>Discover more</h4>
          </div>
        </div>
      </Link>
      <div>
        <div>
          <SidebarFooter />
        </div>
      </div>
    </div>
  );
}
