import React, { useEffect } from "react";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import {
  initPageLoad,
  stopPageLoad,
} from "../../../store/loader/loaderActions";
import { UpdatePortal } from "../../../store/pages/pagesAction";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";

const SuccessModal = (props) => {
  const {
    modal,
    toggle,
    title,
    talentPoolData,
    singlePageDetails,
    sAdminEmail,
  } = props;
  const dispatch = useDispatch();
  console.log("super", sAdminEmail);

  const handleClose = () => {
    toggle();
  };

  const handlePortalActivate = () => {
    dispatch(initPageLoad());
    dispatch(UpdatePortal(talentPoolData?.id, { status: true }))
      .then(() => {
        dispatch(stopPageLoad());
        singlePageDetails();
        toast.success("Talent Pool activated successfully.", {
          position: toast.POSITION.BOTTOM_LEFT,
        });
        toggle();
      })
      .catch((err) => {
        toast.error(err?.response?.data?.message || err?.message, {
          position: toast.POSITION.BOTTOM_LEFT,
        });
        dispatch(stopPageLoad());
      });
  };

  return (
    <Modal
      size="md"
      isOpen={modal}
      style={{ width: "570px", marginTop: "16%" }}
    >
      <ModalBody>
        <div className="success-popup">
          <div className="check"></div>
          <div className="text-center">
            <h4>{title}</h4>
            <div className="line mt-20 mb-20"></div>
            <span className="d-flex justify-content-center align-items-center">
              <p>Verification link sent to</p> <h6> {sAdminEmail}</h6>
            </span>
            <p className="my-3">
              After verification, you'll be able to manage your Talent Pool via
              your page. You can incorporate the admin tool link into your
              career website page, and it will function independently.
            </p>
            <div className="button mt-30 d-flex justify-content-center align-items-center">
              {/* <button className="btn ml-2" onClick={() => handleClose()}>
                <div className="d-flex align-items-center">Close</div>
              </button> */}
              <button
                className="btn btn-blue ml-2"
                // onClick={() => {
                //   setOpenShareModal(!openShareModal);
                // }}
                onClick={() => handlePortalActivate()}
              >
                <div className="d-flex align-items-center">
                  Activate Talent Pool
                </div>
              </button>
            </div>
          </div>
        </div>
      </ModalBody>
      {/* {openShareModal && 
      <ShareModal
            modal={openShareModal}
            toggle={() => setOpenShareModal(!openShareModal)}
            title="Share"
            shareData={shareData}
            type="event"
            toggleSuccessful={toggle}
          />} */}
    </Modal>
  );
};

export default SuccessModal;
