import React, { useEffect, useState } from "react";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import { useDispatch, useSelector } from "react-redux";
import { getLeadActionbyId } from "../../../store/leads/leadsActions";
import moment from "moment";

const StatusModal = (props) => {
  const { title, modal, toggle, leadUserData } = props;

  const dispatch = useDispatch();

  const { leadsActionList } = useSelector((state) => state.leads);

  const [viewMore, setViewMore] = useState();

  useEffect(() => {
    dispatch(getLeadActionbyId(leadUserData.id));
  }, []);

  return (
    <div>
      <Modal
        size="md"
        className="contact-info fix-width"
        toggle={toggle}
        isOpen={modal}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        // scrollable={true}
      >
        <div className="popup">
          <div className="edit-about">
            <ModalHeader style={{ border: "none" }} toggle={toggle}>
              {title}
            </ModalHeader>
            <div className="line mt-20"></div>
            <ModalBody
              style={{
                maxHeight: "500px",
                overflowY: "auto",
              }}
            >
              {leadsActionList?.length === 0 ? (
                <div className="px-3">No Status Data!</div>
              ) : (
                leadsActionList?.map((data, idx, row) => {
                  return (
                    <>
                      <div
                        key={idx}
                        className=" px-3 mt-10 d-flex justify-content-between"
                      >
                        <span
                          className="blue-color"
                          style={{ textTransform: "capitalize" }}
                        >
                          <b>Call to {data.type}</b>
                        </span>
                        <span className="blue-color">
                          <b>
                            {moment.utc(data.createdAt).format("MMMM-DD-YYYY")}
                          </b>
                        </span>
                      </div>
                      <div className="px-3">
                        <span
                          className={
                            viewMore !== idx && "card-control-text-twoline"
                          }
                        >
                          {data.comment}
                        </span>
                        <div>
                          {data.comment?.length >132 && (viewMore != idx) && (
                            <span
                              className="blue-color curserPointer"
                              onClick={() => setViewMore(idx)}
                            >
                              View More
                            </span>
                          )}
                        </div>
                      </div>
                      {idx + 1 === row.length ? (
                        <></>
                      ) : (
                        <div className="line"></div>
                      )}
                    </>
                  );
                })
              )}

              {/* <div className="savebutton" style={{ textAlign: "center" }}>
                <div className={"btn btn-blue"} style={{ width: "100px" }}>
                  Save
                </div>
              </div> */}
            </ModalBody>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default StatusModal;
