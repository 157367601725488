import moment from "moment";
import React, { useEffect, useState } from "react";
import { call } from "../../../api/apiCall";
import FormInput from "../../../core/components/Inputs/FormInput";
import MobileInput from "../../../core/components/Inputs/MobileInput";
import DatePickerCustom from "../../../core/components/DatePicker/DatePickerReact";
import Dropzone from "./dragAndDrop/DragAndDrop";
import axios from "axios";
import removeIcon from "../../../assets/img/removeIcon.png";
import infoIcon from "../../../assets/img/infoIcon.png";
import { Input } from "reactstrap";
import {
  PhoneNumberUtil,
  PhoneNumberFormat as PNF,
} from "google-libphonenumber";
import postalCodes from "postal-codes-js";
import { useDispatch } from "react-redux";
import { updateEducation } from "../../../store/backgroundScreening/backgroundScreeningAction";
import ApiLoader from "../../../core/components/Loaders/apiLoader/apiLoader";
import InfoAlertModel from "./InfoAlertModel";
import { addeducationdetails } from "../../../store/profile/profileActions";
import { toast } from "react-toastify";
const EducationForm = ({
  user,
  ele,
  index,
  // step,
  // setStep,
  completedStep,
  setCompletedStep,
  activeStep,
  setActiveStep,
  data,
  // setFormData,
}) => {
  const [alertModel, setAlertModel] = useState(false);
  const [updateInfo, setupdateInfo] = useState(false);
  const [degreeList, setDegreeList] = useState([]);
  const [studyFieldList, setStudyFieldList] = useState([]);
  const [countryList, setCountryList] = useState([]);
  const [stateList, setStateList] = useState([]);
  const [cityList, seCityList] = useState([]);
  const [countryId, setCountryId] = useState(
    data?.education[index]?.country_id || null
  );
  const [stateId, setStateId] = useState(
    data?.education[index]?.state_id || null
  );
  const [loading, setLoading] = useState(false);
  const [phoneCount, setPhoneCount] = useState("");
  const phoneUtil = PhoneNumberUtil.getInstance();
  const dispatch = useDispatch();
  const [filesSize, setFilesSize] = useState([]);
  const [formData, setFormData] = useState({
    id: data?.education[index]?.id,
    school_name: data?.education[index]?.school_name || "",
    country: data?.education[index]?.country || "",
    campus: data?.education[index]?.campus || "",
    state: data?.education[index]?.state || "",
    applicable_state: data?.education[index]?.applicable_state || false,
    city: data?.education[index]?.city || "",
    phone: data?.education[index]?.phone || "",
    email: data?.education[index]?.email || "",
    zip: data?.education[index]?.zip || "",
    address: data?.education[index]?.address || "",
    student_id: data?.education[index]?.student_id || "",
    major: data?.education[index]?.major || "",
    degree: data?.education[index]?.degree || "",
    comment: data?.education[index]?.comment || "",
    gpa: data?.education[index]?.gpa || "",
    start_date: data?.education[index]?.start_date || "",
    end_date: data?.education[index]?.end_date || "",
    graduated: data?.education[index]?.graduated || false,
    highest_achieved: data?.education[index]?.highest_achieved || false,
    transcript: data?.education[index]?.transcript || false,
    document: data?.education[index]?.document || [],
    country_id: data?.education[index]?.country_id || null,
    state_id: data?.education[index]?.state_id || null,
    city_id: data?.education[index]?.city_id || null,
  });

  useEffect(() => {
    setFormData({
      id: data?.education[index]?.id,
      school_name: data?.education[index]?.school_name || "",
      country: data?.education[index]?.country || "",
      campus: data?.education[index]?.campus || "",
      state: data?.education[index]?.state || "",
      applicable_state: data?.education[index]?.applicable_state || false,
      city: data?.education[index]?.city || "",
      phone: data?.education[index]?.phone || "",
      email: data?.education[index]?.email || "",
      zip: data?.education[index]?.zip || "",
      address: data?.education[index]?.address || "",
      student_id: data?.education[index]?.student_id || "",
      major: data?.education[index]?.major || "",
      degree: data?.education[index]?.degree || "",
      comment: data?.education[index]?.comment || "",
      gpa: data?.education[index]?.gpa || "",
      start_date: data?.education[index]?.start_date || "",
      end_date: data?.education[index]?.end_date || "",
      graduated: data?.education[index]?.graduated || false,
      highest_achieved: data?.education[index]?.highest_achieved || false,
      transcript: data?.education[index]?.transcript || false,
      document: data?.education[index]?.document || [],
      country_id: data?.education[index]?.country_id || null,
      state_id: data?.education[index]?.state_id || null,
      city_id: data?.education[index]?.city_id || null,
    });
    setCountryId(data?.education[index]?.country_id || null);
    setStateId(data?.education[index]?.state_id || null);
  }, []);
  useEffect(() => {
    (async () => {
      try {
        const res = await call("get", "api/v1/degree", null, null);
        setDegreeList(res?.data?.data);
      } catch (err) {
        console.log(err);
      }
    })();
  }, []);

  useEffect(() => {
    (async () => {
      try {
        const res = await call("get", "api/v1/study-field", null, null);
        setStudyFieldList(res?.data?.data);
      } catch (err) {
        console.log(err);
      }
    })();
  }, []);

  useEffect(() => {
    (async () => {
      try {
        const res = await call("get", "api/v1/country", null, null);
        setCountryList(res?.data?.data);
      } catch (err) {
        console.log(err);
      }
    })();
  }, []);

  useEffect(() => {
    (async () => {
      try {
        const res = await call(
          "get",
          `api/v1/state?country_id=${countryId}`,
          null,
          null
        );
        setStateList(res?.data?.data);
      } catch (err) {
        console.log(err);
      }
    })();
  }, [countryId]);

  useEffect(() => {
    (async () => {
      try {
        const res = await call(
          "get",
          formData?.applicable_state && stateId
            ? `api/v1/city?state_id=${stateId}`
            : `api/v1/city?country_id=${countryId}`,
          null,
          null
        );
        seCityList(res?.data?.data);
      } catch (err) {
        console.log(err);
      }
    })();
  }, [stateId, countryId]);

  const handleBannerPicture = async (files) => {
    try {
      setLoading(true);
      if (!files.length) {
        throw new Error("No files selected");
      }

      const UPLOAD_URL = `${process.env.REACT_APP_API_URL}api/v1/upload`;
      const formdata = new FormData();
      const headers = {
        "Accept-Language": "en",
        "content-type": "multipart/form-data",
      };

      for (let i = 0; i < files.length; i++) {
        formdata.append("image", files[i]);
      }

      await axios
        .post(UPLOAD_URL, formdata, { headers })
        .then((res) => {
          // Construct the array of document objects including URLs
          const documents = res?.data?.filesArray.map(
            ({ originalname, size }, index) => ({
              url: res?.data?.urlsArray[index],
              name: originalname,
              size,
            })
          );

          setFormData({
            ...formData,
            document: !formData?.document?.length
              ? documents
              : [...formData?.document, ...documents],
          });
          setLoading(false);
        })
        .catch((err) => {
          setLoading(false);
        });
    } catch (err) {
      console.log(err.message);
      setLoading(false);
    }
  };

  const handleChangeValue = (type) => {
    const expData = user?.educations?.find(
      (ele) => data?.education[index]?.id == ele.id
    );

    if (
      type == "school_name" &&
      expData?.school_name &&
      formData?.school_name !== expData?.school_name
    ) {
      toast.error("School Name is different from current education");
    }

    if (
      type == "start_date" &&
      expData?.start_date &&
      moment(formData?.start_date).format("DD-mm-yyyy") !==
        moment(expData?.start_date).format("DD-mm-yyyy")
    ) {
      toast.error("Start Date is different from current education");
    }

    if (
      type == "end_date" &&
      expData?.end_date &&
      moment(formData?.end_date).format("DD-mm-yyyy") !==
        moment(expData?.start_date).format("DD-mm-yyyy")
    ) {
      toast.error("End Date is different from current education");
    }

    if (
      type == "degree" &&
      expData?.degree &&
      formData?.degree !== expData?.degree
    ) {
      toast.error("Degree is different from current education");
    }
    if (type == "grade" && expData?.grade && formData?.gpa !== expData?.grade) {
      toast.error("Grade is different from current education");
    }
  };

  const checkInfo = () => {
    let info = true;
    const expData = user?.educations?.find(
      (ele) => data?.education[index]?.id == ele.id
    );

    if (
      (expData?.school_name &&
        formData?.school_name !== expData?.school_name) ||
      (expData?.start_date &&
        moment(formData?.start_date).format("DD-mm-yyyy") !==
          moment(expData?.start_date).format("DD-mm-yyyy")) ||
      (expData?.end_date &&
        moment(formData?.end_date).format("DD-mm-yyyy") !==
          moment(expData?.start_date).format("DD-mm-yyyy")) ||
      (expData?.degree && formData?.degree !== expData?.degree) ||
      (expData?.grade && formData?.gpa !== expData?.grade)
    ) {
      setAlertModel(true);
      info = false;
    }
    return info;
  };

  const handleSubmit = (e) => {
    e?.preventDefault();
    if (checkValidation("all")) {
      if (checkInfo()) {
        dispatch(updateEducation(formData, index));
        if (!completedStep.includes(`step${activeStep}`))
          setCompletedStep([...completedStep, `step${activeStep}`]);
        window.scrollTo(0, 0);
        setActiveStep(activeStep + 1);
      }
    }
  };

  const handleNext = () => {
    if (checkValidation("all")) {
      dispatch(updateEducation(formData, index));
      if (!completedStep.includes(`step${activeStep}`))
        setCompletedStep([...completedStep, `step${activeStep}`]);
      window.scrollTo(0, 0);
      setActiveStep(activeStep + 1);
    }
  };

  const [error, setError] = useState({
    school_name: "",
    country: "",
    state: "",
    city: "",
    phone: "",
    email: "",
    major: "",
    degree: "",
    gpa: "",
    start_date: "",
    end_date: "",
    graduated: "",
    highest_achieved: "",
    transcript: "",
    document: "",
    zip: "",
    address: "",
    student_id: "",
  });
  const phoneCountValidation = () => {
    let num = JSON.stringify(formData?.phone);
    let code = JSON.stringify(phoneCount);
    const number = phoneUtil.parse(num, code);
    return phoneUtil.isValidNumber(number);
  };
  const checkValidation = (type) => {
    let isFormValid = true;
    let emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
    let nameRegex = /^[^\d\s]+$/;
    let numberRegex = /^[0-9]+$/;
    let webRegex =
      /^(https?:\/\/)?([a-zA-Z0-9]([a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?\.)+[a-zA-Z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)$/;
    if (type == "school_name" || type == "all") {
      if (!formData?.school_name) {
        setError({ ...error, school_name: "School name is required" });
        isFormValid = false;
        return;
      } else setError({ ...error, school_name: "" });
    }
    if (type == "email" || type == "all") {
      if (!formData?.email) {
        setError({ ...error, email: "Email is required" });
        isFormValid = false;
        return;
      } else if (!emailRegex.test(formData?.email)) {
        setError({
          ...error,
          email: "Please enter your email in a valid format.",
        });
        isFormValid = false;
        return;
      } else setError({ ...error, email: "" });
    }

    if (type == "country" || type == "all") {
      if (!formData?.country) {
        setError({ ...error, country: "Country is required" });
        isFormValid = false;
        return;
      } else setError({ ...error, country: "" });
    }

    if (type == "phone" || type == "all") {
      if (!formData?.phone) {
        setError({ ...error, phone: "Phone is required" });
        isFormValid = false;
        return;
      } else if (formData?.phone < 10) {
        setError({ ...error, phone: "Please enter the valid number." });
        isFormValid = false;
        return;
      } else if (!phoneCountValidation()) {
        setError({ ...error, phone: "Please enter the valid number." });
        isFormValid = false;
        return;
      } else setError({ ...error, phone: "" });
    }

    if (!formData?.applicable_state) {
      if (type == "state" || type == "all") {
        if (!formData?.state) {
          setError({ ...error, state: "State is required" });
          isFormValid = false;
          return;
        } else setError({ ...error, state: "" });
      }
    }
    if (type == "city" || type == "all") {
      if (!formData?.city) {
        setError({ ...error, city: "City is required" });
        isFormValid = false;
        return;
      } else setError({ ...error, city: "" });
    }

    if (type == "major" || type == "all") {
      if (!formData?.major) {
        setError({ ...error, major: "Major is required" });
        isFormValid = false;
        return;
      } else setError({ ...error, major: "" });
    }

    if (type == "degree" || type == "all") {
      if (!formData?.degree) {
        setError({ ...error, degree: "Degree is required" });
        isFormValid = false;
        return;
      } else setError({ ...error, degree: "" });
    }
    if (type == "start_date" || type == "all") {
      if (!formData?.start_date) {
        setError({ ...error, start_date: "Start date is required" });
        isFormValid = false;
        return;
      } else setError({ ...error, start_date: "" });
    }
    if (type == "end_date" || type == "all") {
      if (!formData?.end_date) {
        setError({ ...error, end_date: "End Date is required" });
        isFormValid = false;
        return;
      } else setError({ ...error, end_date: "" });
    }

    if (type == "document" || type == "all") {
      if (!formData?.document?.length) {
        setError({ ...error, document: "Documents are required" });
        isFormValid = false;
        return;
      } else setError({ ...error, document: "" });
    }
    // if (type == "degree" || type == "all") {
    //   if (!formData?.degree) {
    //     setError({ ...error, degree: "Degree is required" });
    //     isFormValid = false;
    //     return;
    //   } else setError({ ...error, degree: "" });
    // }

    // if (type == "graduated" || type == "all") {
    //   if (formData?.graduated == "") {
    //     setError({ ...error, graduated: "Graduated is required" });
    //     isFormValid = false;
    //     return;
    //   } else setError({ ...error, graduated: "" });
    // }
    // if (type == "highest_achieved" || type == "all") {
    //   if (formData?.highest_achieved == "") {
    //     setError({ ...error, highest_achieved: "highest Achieve is required" });
    //     isFormValid = false;
    //     return;
    //   } else setError({ ...error, highest_achieved: "" });
    // }
    // if (type == "transcript" || type == "all") {
    //   if (formData?.transcript == "") {
    //     setError({ ...error, transcript: "Transcript is required" });
    //     isFormValid = false;
    //     return;
    //   } else setError({ ...error, transcript: "" });
    // }
    if (type == "zip" || type == "all") {
      if (formData?.zip && !numberRegex.test(formData?.zip?.trim())) {
        setError({ ...error, zip: "Only numeric are allowed" });
        isFormValid = false;
        return;
      } else {
        setError({ ...error, zip: "" });
      }
    }
    if (formData?.address) {
      if (type == "address" || type == "all") {
        if (!formData?.address?.trim()) {
          setError({ ...error, address: "Address s required" });
          isFormValid = false;
          return;
        } else if (!webRegex.test(formData?.address?.trim())) {
          setError({ ...error, address: "Please enter a valid address" });
          isFormValid = false;
          return;
        } else {
          setError({ ...error, address: "" });
        }
      }
    }
    if (formData?.student_id) {
      if (type == "student_id" || type == "all") {
        if (!formData?.student_id?.trim()) {
          setError({ ...error, student_id: "Roll No. s required" });
          isFormValid = false;
          return;
        } else if (!numberRegex.test(formData?.student_id?.trim())) {
          setError({ ...error, student_id: "Roll No. should be numeric" });
          isFormValid = false;
          return;
        } else {
          setError({ ...error, student_id: "" });
        }
      }
    }
    if (formData?.campus) {
      if (type == "all" || type == "campus") {
        if (!formData?.campus?.trim()) {
          setError({ ...error, campus: "Campus is required" });
          isFormValid = false;
          return;
        } else {
          setError({ ...error, campus: "" });
        }
      }
    }

    return isFormValid;
  };

  const token = localStorage.getItem("accessToken");

  useEffect(() => {
    if (updateInfo) {
      const expData = user?.educations?.find(
        (ele) => data?.education[index]?.id == ele.id
      );

      dispatch(
        addeducationdetails(
          {
            educations: [
              {
                id: expData?.id,
                school_name: formData?.school_name,
                degree: formData?.degree,
                start_date: formData?.start_date,
                end_date: formData?.end_date,
                grade: formData?.gpa,
              },
            ],
          },
          token
        )
      );
      handleNext();
      setAlertModel(false);
    }
  }, [updateInfo]);

  return (
    <>
      <div className="px-5 py-3 package_form">
        <form>
          <div className="row">
            <div className="col-6 d-flex flex-column justify-content-start">
              <FormInput
                type="text"
                label={"School Name"}
                labelImage={infoIcon}
                required={true}
                value={formData?.school_name}
                onChange={(e) => {
                  // const updatedEducation = [...data.education];
                  // updatedEducation[index] = {
                  //   ...updatedEducation[index],
                  //   school_name: e.target.value,
                  // };

                  setFormData({
                    ...formData,
                    school_name: e.target.value,
                  });
                }}
                tooltipMessage={"Enter the name of the school attended."}
                onBlur={() => {
                  checkValidation("school_name");
                  handleChangeValue("school_name");
                }}
                error={error?.school_name}
              />
            </div>
            <div className="col-6 d-flex flex-column justify-content-start">
              <FormInput
                type="text"
                label={"Campus"}
                value={formData?.campus}
                onChange={(e) => {
                  // const updatedEducation = [...data.education];
                  // updatedEducation[index] = {
                  //   ...updatedEducation[index],
                  //   campus: e.target.value,
                  // };

                  setFormData({
                    ...formData,
                    campus: e.target.value,
                  });
                }}
                onBlur={() => checkValidation("campus")}
                error={error?.campus}
                tooltipMessage={
                  "Many schools have separate records and registrars offices for each campus, so we need to know which campus the student attended in order to verify this information."
                }
              />
            </div>
          </div>

          <div className="row">
            <div className="col-6 d-flex flex-column justify-content-start">
              <FormInput
                type="email"
                label={"Email"}
                required={true}
                labelImage={infoIcon}
                value={formData?.email}
                onChange={(e) => {
                  // const updatedEducation = [...data.education];
                  // updatedEducation[index] = {
                  //   ...updatedEducation[index],
                  //   email: e.target.value,
                  // };

                  setFormData({
                    ...formData,
                    email: e.target.value,
                  });
                }}
                onBlur={() => checkValidation("email")}
                error={error?.email}
              />
            </div>
            <div className="col-6 d-flex flex-column justify-content-end">
              <label htmlFor="name_type" className="form-label">
                <button
                  type="button"
                  className="bg-transparent border-0"
                  data-toggle="tooltip"
                  data-placement="top"
                  title="Country encompassing the search"
                >
                  <img src={infoIcon} width={20} className="me-2" />
                </button>{" "}
                Country <span className="text-danger">*</span>
              </label>
              <Input
                type="select"
                value={formData?.country}
                onClick={() => checkValidation("country")}
                onChange={(e) => {
                  // const updatedEducation = [...data.education];
                  // updatedEducation[index] = {
                  //   ...updatedEducation[index],
                  //   country: e.target.value,
                  //   countryId: countryList?.find(
                  //     (ele) => ele?.name == e.target.value
                  //   )?.id,
                  // };
                  setCountryId(
                    countryList?.find((ele) => ele?.name == e.target.value)?.id
                  );
                  setFormData({
                    ...formData,
                    country: e.target.value,
                    country_id: countryList?.find(
                      (ele) => ele?.name == e.target.value
                    )?.id,
                  });
                }}
              >
                <option>Select</option>
                {countryList?.map((ele) => {
                  return (
                    <>
                      <option value={ele?.name}>{ele?.name}</option>
                    </>
                  );
                })}
              </Input>
              {error?.country && (
                <p className="text-danger">{error?.country}</p>
              )}
            </div>
          </div>

          <div className="row">
            <div className="col-6  d-flex flex-column justify-content-start">
              <MobileInput
                label="Phone"
                labelImage={infoIcon}
                required={true}
                onChange={(value, country, e, formattedValue) => {
                  // const updatedEducation = [...data.education];
                  // updatedEducation[index] = {
                  //   ...updatedEducation[index],
                  //   phone: `+${country.dialCode}-${value.slice(
                  //     country.dialCode.length
                  //   )}`,
                  // };
                  setPhoneCount(country.countryCode);
                  setFormData({
                    ...formData,
                    phone: `+${country.dialCode}-${value.slice(
                      country.dialCode.length
                    )}`,
                  });
                }}
                value={formData?.phone}
                inputType="page"
                containerClassName="mt-3"
                onBlur={() => checkValidation("phone")}
                error={error?.phone}
              />
            </div>

            <div className="col-6 d-flex flex-column justify-content-end">
              <label
                htmlFor="name_type"
                className="form-label d-flex justify-content-between align-items-center"
              >
                <div className="">
                  <button
                    type="button"
                    className="bg-transparent border-0"
                    data-toggle="tooltip"
                    data-placement="top"
                    title="The subject's Date of Birth"
                  >
                    <img src={infoIcon} width={20} className="me-2" />
                  </button>{" "}
                  State / Province{" "}
                  {!formData?.applicable_state && (
                    <span className="text-danger">*</span>
                  )}
                </div>
                <div
                  className="d-flex align-items-center"
                  style={{ width: "30%" }}
                >
                  <p
                    // style={{ marginLeft: "120px " }}
                    className="mx-2"
                  >
                    Not Applicable
                  </p>
                  <div className="switch-box d-flex justify-content-center align-items-center">
                    <label className="switch">
                      <input
                        type="checkbox"
                        checked={formData?.applicable_state}
                        onChange={(e) => {
                          // const updatedEducation = [...data.education];
                          // updatedEducation[index] = {
                          //   ...updatedEducation[index],
                          //   applicable_state: !formData?.applicable_state,
                          // };
                          if (e.target.checked) {
                            setFormData({
                              ...formData,
                              applicable_state: true,
                              state: "",
                            });
                            setError({ ...error, state: "" });
                          } else {
                            setFormData({
                              ...formData,
                              applicable_state: false,
                            });
                          }
                        }}
                      />
                      <span className="slider round"></span>
                    </label>
                  </div>
                </div>
              </label>
              <Input
                type="select"
                value={formData?.state}
                disabled={!formData?.country || formData?.applicable_state}
                onClick={() => checkValidation("state")}
                onChange={(e) => {
                  // const updatedEducation = [...data.education];
                  // updatedEducation[index] = {
                  //   ...updatedEducation[index],
                  //   state: e.target.value,
                  //   state_id: stateList?.find(
                  //     (ele) => ele?.name == e.target.value
                  //   )?.id,
                  // };
                  setStateId(
                    stateList?.find((ele) => ele?.name == e.target.value)?.id
                  );
                  setFormData({
                    ...formData,
                    state: e.target.value,
                    state_id: stateList?.find(
                      (ele) => ele?.name == e.target.value
                    )?.id,
                  });
                }}
              >
                <option>Select</option>
                {stateList?.map((ele) => {
                  return (
                    <>
                      <option value={ele.name}>{ele?.name}</option>
                    </>
                  );
                })}
              </Input>
              {error?.state && <p className="text-danger">{error?.state}</p>}
            </div>
          </div>

          <div className="row">
            <div className="col-6 d-flex flex-column justify-content-start">
              <FormInput
                type="text"
                label={"Web Address"}
                labelImage={infoIcon}
                value={formData?.address}
                onChange={(e) => {
                  // const updatedEducation = [...data.education];
                  // updatedEducation[index] = {
                  //   ...updatedEducation[index],
                  //   address: e.target.value,
                  // };
                  setFormData({
                    ...formData,
                    address: e.target.value,
                  });
                }}
                onBlur={() => checkValidation("address")}
                error={error?.address}
              />
            </div>
            <div className="col-6 d-flex flex-column justify-content-end">
              <label htmlFor="name_type" className="form-label">
                <button
                  type="button"
                  className="bg-transparent border-0"
                  data-toggle="tooltip"
                  data-placement="top"
                  title="The subject's Date of Birth"
                >
                  <img src={infoIcon} width={20} className="me-2" />
                </button>{" "}
                City <span className="text-danger">*</span>
              </label>
              <Input
                type="select"
                value={formData?.city}
                onClick={() => checkValidation("city")}
                onChange={(e) => {
                  // const updatedEducation = [...data.education];
                  // updatedEducation[index] = {
                  //   ...updatedEducation[index],
                  //   city: e.target.value,
                  //   city_id: cityList?.find(
                  //     (ele) => ele?.name == e.target.value
                  //   )?.id,
                  // };
                  setFormData({
                    ...formData,
                    city: e.target.value,
                    city_id: cityList?.find(
                      (ele) => ele?.name == e.target.value
                    )?.id,
                  });
                }}
              >
                <option>Select</option>
                {cityList?.map((ele) => {
                  return (
                    <>
                      <option value={ele?.name}>{ele?.name}</option>
                    </>
                  );
                })}
              </Input>
              {error?.city && <p className="text-danger">{error?.city}</p>}
            </div>
          </div>

          <div className="row">
            <div className="col-6 d-flex flex-column justify-content-start">
              <FormInput
                type="text"
                label={"Roll Number / Student ID"}
                labelImage={infoIcon}
                value={formData?.student_id}
                onChange={(e) => {
                  // const updatedEducation = [...data.education];
                  // updatedEducation[index] = {
                  //   ...updatedEducation[index],
                  //   student_id: e.target.value,
                  // };

                  setFormData({
                    ...formData,
                    student_id: e.target.value,
                  });
                }}
                onBlur={() => checkValidation("student_id")}
                error={error?.student_id}
              />
            </div>
            <div className="col-6 d-flex flex-column justify-content-start">
              <FormInput
                type="text"
                label={"Zip (type 00000 if not applicable)"}
                labelImage={infoIcon}
                value={formData?.zip}
                onChange={(e) => {
                  setFormData({
                    ...formData,
                    zip: e.target.value,
                  });
                }}
                maxLength={6}
                onBlur={() => checkValidation("zip")}
                error={error?.zip}
              />
            </div>
          </div>

          <div className="row mt-3">
            <div className="col-6 d-flex flex-column justify-content-end">
              <label htmlFor="name_type" className="form-label">
                <button
                  type="button"
                  className="bg-transparent border-0"
                  data-toggle="tooltip"
                  data-placement="top"
                  title="Enter the name of the subject's major."
                >
                  <img src={infoIcon} width={20} className="me-2" />
                </button>{" "}
                Major <span className="text-danger">*</span>
              </label>
              <Input
                type="select"
                value={formData?.major}
                onClick={() => checkValidation("major")}
                onChange={(e) => {
                  // const updatedEducation = [...data.education];
                  // updatedEducation[index] = {
                  //   ...updatedEducation[index],
                  //   major: e.target.value,
                  // };
                  setFormData({
                    ...formData,
                    major: e.target.value,
                  });
                }}
              >
                <option value={""}>Select</option>
                {studyFieldList?.map((ele) => {
                  return (
                    <>
                      <option value={ele?.name}>{ele?.name}</option>
                    </>
                  );
                })}
              </Input>
              {error?.major && <p className="text-danger">{error?.major}</p>}
            </div>
            <div className="col-6 d-flex flex-column justify-content-end">
              <label htmlFor="name_type" className="form-label">
                <button
                  type="button"
                  className="bg-transparent border-0"
                  data-toggle="tooltip"
                  data-placement="top"
                  title="Enter the degree the subject earned."
                >
                  <img src={infoIcon} width={20} className="me-2" />
                </button>{" "}
                Degree <span className="text-danger">*</span>
              </label>
              <Input
                type="select"
                value={formData?.degree}
                onClick={() => {
                  checkValidation("degree");
                  handleChangeValue("degree");
                }}
                onChange={(e) => {
                  // const updatedEducation = [...data.education];
                  // updatedEducation[index] = {
                  //   ...updatedEducation[index],
                  //   degree: e.target.value,
                  // };
                  setFormData({
                    ...formData,
                    degree: e.target.value,
                  });
                }}
              >
                <option value={""}>Select</option>
                {degreeList?.map((ele) => {
                  return (
                    <>
                      <option value={ele?.name}>{ele?.name}</option>
                    </>
                  );
                })}
              </Input>
              {error?.degree && <p className="text-danger">{error?.degree}</p>}
            </div>
          </div>

          <div className="row mt-3">
            <div className="col-6 d-flex flex-column">
              <div className="d-flex">
                <label
                  htmlFor="name_type"
                  className="form-label"
                  style={{ width: "auto" }}
                >
                  Attended From <span className="text-danger">*</span>
                </label>
                <div className="mx-3">
                  <DatePickerCustom
                    value={
                      formData?.start_date
                        ? new Date(formData?.start_date)
                        : null
                    }
                    onChange={(date) => {
                      // const updatedEducation = [...data.education];
                      // updatedEducation[index] = {
                      //   ...updatedEducation[index],
                      //   start_date: date
                      //     ? moment(date).format("YYYY-MM-DD")
                      //     : null,
                      // };

                      setFormData({
                        ...formData,
                        start_date: date
                          ? moment(date).format("YYYY-MM-DD")
                          : null,
                      });
                    }}
                    max={new Date()}
                    placeholder=""
                    onBlur={() => {
                      checkValidation("start_date");
                      handleChangeValue("start_date");
                    }}
                  />
                  {error?.start_date && (
                    <p className="text-danger">{error?.start_date}</p>
                  )}
                </div>
              </div>
              <div className="d-flex mt-3">
                <label
                  htmlFor="name_type"
                  className="form-label"
                  style={{ width: "auto" }}
                >
                  Attended To <span className="text-danger">*</span>
                </label>
                <div className="mx-4">
                  <DatePickerCustom
                    value={
                      formData?.end_date ? new Date(formData?.end_date) : null
                    }
                    onChange={(date) => {
                      // const updatedEducation = [...data.education];
                      // updatedEducation[index] = {
                      //   ...updatedEducation[index],
                      //   end_date: date
                      //     ? moment(date).format("YYYY-MM-DD")
                      //     : null,
                      // };

                      setFormData({
                        ...formData,
                        end_date: date
                          ? moment(date).format("YYYY-MM-DD")
                          : null,
                      });
                    }}
                    max={new Date()}
                    placeholder=""
                    onBlur={() => {
                      checkValidation("end_date");
                      handleChangeValue("end_date");
                    }}
                    disabled={formData?.is_current}
                  />
                  {error?.end_date && (
                    <p className="text-danger">{error?.end_date}</p>
                  )}
                </div>
              </div>
            </div>
            <div className="col-6">
              <FormInput
                type="text"
                label={"GPA"}
                labelImage={infoIcon}
                value={formData?.gpa}
                onChange={(e) => {
                  // const updatedEducation = [...data.education];
                  // updatedEducation[index] = {
                  //   ...updatedEducation[index],
                  //   gpa: e.target.value,
                  // };

                  setFormData({
                    ...formData,
                    gpa: e.target.value,
                  });
                }}
                maxLength={3}
                tooltipMessage={"Enter the GPA of the subject."}
                onBlur={() => handleChangeValue("grade")}
              />
            </div>
          </div>

          <div className="row mt-3">
            <div className="col-6 d-flex flex-column">
              <div className="">
                <label
                  htmlFor="name_type"
                  className="form-label"
                  style={{ width: "auto" }}
                >
                  <button
                    type="button"
                    className="bg-transparent border-0"
                    data-toggle="tooltip"
                    data-placement="top"
                    title="The subject's Date of Birth"
                  >
                    <img src={infoIcon} width={20} className="me-2" />
                  </button>{" "}
                  Graduated ? <span className="text-danger">*</span>
                </label>
                <Input
                  type="select"
                  value={formData?.graduated}
                  // onClick={() => checkValidation("graduated")}
                  onChange={(e) => {
                    // const updatedEducation = [...data.education];
                    // updatedEducation[index] = {
                    //   ...updatedEducation[index],
                    //   graduated: Boolean(e.target.value),
                    // };
                    setFormData({
                      ...formData,
                      graduated: Boolean(e.target.value),
                    });
                  }}
                >
                  <option value="">Select</option>
                  <option value={true}>Yes</option>
                  <option value={false}>No</option>
                </Input>
                {/* {error?.graduated && (
                  <p className="text-danger">{error?.graduated}</p>
                )} */}
              </div>
              <div className="mt-3">
                <label
                  htmlFor="name_type"
                  className="form-label"
                  style={{ width: "auto" }}
                >
                  <button
                    type="button"
                    className="bg-transparent border-0"
                    data-toggle="tooltip"
                    data-placement="top"
                    title="The subject's Date of Birth"
                  >
                    <img src={infoIcon} width={20} className="me-2" />
                  </button>{" "}
                  Highest Achieved ? <span className="text-danger">*</span>
                </label>
                <Input
                  type="select"
                  value={formData?.highest_achieved}
                  // onClick={() => checkValidation("highest_achieved")}
                  onChange={(e) => {
                    // const updatedEducation = [...data.education];
                    // updatedEducation[index] = {
                    //   ...updatedEducation[index],
                    //   highest_achieved: Boolean(e.target.value),
                    // };
                    setFormData({
                      ...formData,
                      highest_achieved: Boolean(e.target.value),
                    });
                  }}
                >
                  <option value="">Select</option>
                  <option value={true}>Yes</option>
                  <option value={false}>No</option>
                </Input>
                {/* {error?.highest_achieved && (
                  <p className="text-danger">{error?.highest_achieved}</p>
                )} */}
              </div>

              <div className="mt-3">
                <label
                  htmlFor="name_type"
                  className="form-label"
                  style={{ width: "auto" }}
                >
                  <button
                    type="button"
                    className="bg-transparent border-0"
                    data-toggle="tooltip"
                    data-placement="top"
                    title="The subject's Date of Birth"
                  >
                    <img src={infoIcon} width={20} className="me-2" />
                  </button>{" "}
                  Transcript (ToR) <span className="text-danger">*</span>
                </label>
                <Input
                  type="select"
                  value={formData?.transcript}
                  // onClick={() => checkValidation("transcript")}
                  onChange={(e) => {
                    // const updatedEducation = [...data.education];
                    // updatedEducation[index] = {
                    //   ...updatedEducation[index],
                    //   transcript: Boolean(e.target.value),
                    // };
                    setFormData({
                      ...formData,
                      transcript: Boolean(e.target.value),
                    });
                  }}
                >
                  <option value="">Select</option>
                  <option value={true}>Yes</option>
                  <option value={false}>No</option>
                </Input>
                {/* {error?.transcript && (
                  <p className="text-danger">{error?.transcript}</p>
                )} */}
              </div>
            </div>
            <div className="col-6">
              <label htmlFor="name_type" className="form-label">
                <button
                  type="button"
                  className="bg-transparent border-0"
                  data-toggle="tooltip"
                  data-placement="top"
                  title="The subject's Date of Birth"
                >
                  <img src={infoIcon} width={20} className="me-2" />
                </button>{" "}
                Comments{" "}
              </label>
              <textarea
                rows={6}
                style={{ width: "100%", padding: "10px" }}
                value={formData?.comment}
                onChange={(e) => {
                  // const updatedEducation = [...data.education];
                  // updatedEducation[index] = {
                  //   ...updatedEducation[index],
                  //   comment: e.target.value,
                  // };
                  setFormData({
                    ...formData,
                    comment: e.target.value,
                  });
                }}
              />
            </div>
          </div>
          <div className="row mt-3">
            <div className="col-12">
              <label htmlFor="name_type" className="form-label">
                Attachments <span className="text-danger">*</span>
              </label>
              <p className="mt-2">
                Please attach your supporting education documents for
                verification, such as the certificate and/ or transcript of
                record (ToR).
              </p>
            </div>
          </div>
          {loading ? (
            <div className="d-flex justify-content-center align-items-center">
              <ApiLoader />
            </div>
          ) : (
            <Dropzone handleBannerPicture={handleBannerPicture} />
          )}
          {formData?.document?.length == 0 && error?.document && (
            <p className="text-danger">{error?.document}</p>
          )}
          <div className="row row-cols-2 p-3">
            {formData?.document?.map((ele, index) => (
              <div className="col p-2 bg-white mt-2 mb-2" key={index}>
                <div className="d-flex justify-content-between align-items-center">
                  <div className="d-flex align-items-center justify-content-between">
                    <p
                      className="m-0 px-2 py-1"
                      style={{ background: "#BDE7FF" }}
                    >
                      {ele?.name?.split(".")?.pop()?.toUpperCase() || "Pdf"}
                    </p>
                    <p className="m-0 mx-3 text-dark">{ele?.name}</p>
                  </div>
                  <div className="d-flex justify-content-center align-items-center">
                    <p
                      className="mx-4 text-secondary"
                      style={{ fontSize: "12px", paddingBottom: 0 }}
                    >
                      {(ele?.size / 1024).toFixed(2) + " KB"}
                    </p>
                    <img
                      src={removeIcon}
                      alt="remove"
                      width={15}
                      onClick={() =>
                        setFormData({
                          ...formData,
                          document: formData?.document?.filter(
                            (_, i) => i !== index
                          ),
                        })
                      }
                    />
                  </div>
                </div>
              </div>
            ))}
          </div>
          <div className="d-flex justify-content-center align-items-center mt-3">
            <button className="btn btn-blue" onClick={(e) => handleSubmit(e)}>
              Continue to Next Step
            </button>
          </div>
        </form>

        {alertModel && (
          <InfoAlertModel
            modal={alertModel}
            toggle={() => setAlertModel(!alertModel)}
            updateInfo={updateInfo}
            setupdateInfo={setupdateInfo}
            handleNext={handleNext}
          />
        )}
      </div>
    </>
  );
};

export default EducationForm;
