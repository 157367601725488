import {
  CREATE_POST_SUCCESS,
  UPDATE_POST_SUCCESS,
  GET_POST_DETAILS,
  FAIL_POST_POLL,
  POST_POLL,
  GET_CELEBRATION_TYPES,
  VOTES_RESULT,
  DELETE_POST_SUCCESS,
  MERGE_SHARE_DATA,
  GET_PAGE_DETAILS,
  ADD_COMMENT,
  DELETE_COMMENT,
  DELETE_COMMENT_REPLY,
  GET_COMMENT,
  GET_REPORT_TYPE,
  LIKE_REACTION_DATA,
  SAVE_POST,
  DELETE_SAVED_POST,
  LIKE_REACTION_COMMENT_DATA,
  DELETE_REACTION_DATA,
  GET_POST_DATA_DETAIL,
  ADD_MORE_COMMENT,
  ADD_COMMENT_REPLY,
  HIDE_USER_POST,
  DELETE_REACTION_COMMENT_DATA,
  DELETE_REACTION_REPLY_DATA,
  GET_MORE_COMMENT_REPLY,
  REPOST_POST,
  LIKE_REACTION_REPLY_DATA,
  UPDATE_HOME_CONNECTION_STATUS,
  FOLLOW_PAGE_SUCCESS_HOME,
  USER_FOLLOW_STATUS_HOME,
  USER_UNFOLLOW_STATUS_HOME,
  UNFOLLOW_PAGE_SUCCESS_HOME,
  GET_HASHTAG_POST_FEED,
  FOLLOW_HASHTAG_POST_FEED,
  UNFOLLOW_HASHTAG_POST_FEED,
  FILTER_UPDATE_MY_POST_LIST,
  COMMENT_STATUS,
  COMMENT_STATUS_TRUE,
  COMMENT_STATUS_FALSE,
  POSTVIEWCOUNT_SUCCESS,
  PIN_COMMENT,
  UPDATE_COMMENT,
  UNPIN_COMMENT,
  REMOVE_CONNECTION,
  AS_A_PAGE_ROLE,
  UPDATE_COMMENT_REPLY,
  GET_STATISTICS,
  GET_MORE_STATISTICS,
  GET_AD_LIST,
} from "../types";

const initialState = [];

const dashboard = (state = initialState, action) => {
  const { type, payload } = action;
  // console.log("action.payload",action.payload);
  switch (type) {
    case CREATE_POST_SUCCESS:
      return {
        ...state,
        postData: { ...state.postData, ...action.payload },
      };
    case UPDATE_POST_SUCCESS:
      return {
        ...state,
        postData: {
          ...state.postData,
          data: state.postData.data.map((x) =>
            x.id === action.payload.id ? { ...x, ...action.payload } : x
          ),
        },
      };

    case FILTER_UPDATE_MY_POST_LIST:
      return {
        ...state,
        postData: {
          ...state.postData,
          data: state.postData.data.filter(
            (item) => item.id !== action.payload.id
          ),
        },
      };

    case DELETE_POST_SUCCESS:
      return {
        ...state,
        postData: { ...state.postData, data: action.payload },
      };
    case GET_POST_DETAILS:
      return {
        ...state,
        postData: action.payload,
      };
    case POST_POLL:
      const { pollData } = action.payload;
      return {
        ...state,
        postData: {
          ...state.postData,
          data: state.postData.data.map((x) =>
            x.id === action.payload.postId
              ? {
                  ...x,
                  Poll: {
                    ...x.Poll,
                    options: x.Poll?.options?.map((x) =>
                      x.id === action.payload.pollData.option_id
                        ? { ...x, vote_count: x.vote_count + 1 }
                        : x
                    ),
                  },
                }
              : {
                  ...x,
                }
          ),
        },
        // pollData: action.payload,
      };
    case GET_CELEBRATION_TYPES:
      const { celerabationTypes } = action.payload;
      return {
        ...state,
        celerabationTypes: action.payload,
      };

    case VOTES_RESULT:
      return {
        ...state,
        votesResult: action.payload,
      };

    case GET_PAGE_DETAILS:
      return {
        ...state,
        getPageDetailData: action.payload,
      };

    case MERGE_SHARE_DATA:
      return {
        ...state,
        postData: {
          ...state.postData,
          data: [action.payload, ...state.postData.data],
        },
      };
    case GET_COMMENT:
      if (action.payload.response.success === true) {
        return {
          ...state,
          postData: {
            ...state.postData,
            data: state.postData.data.map((x) =>
              x.id === action.payload.post_id.post_id
                ? { ...x, commentData: action.payload.response.data }
                : x
            ),
          },
        };
      } else if (action.payload.response.success === false) {
        return {
          ...state,
          commentData: [],
        };
      } else return;

    case ADD_MORE_COMMENT:
      return {
        ...state,
        postData: {
          ...state.postData,
          data: state.postData.data.map((x) =>
            x.id === action.payload.post_id.post_id
              ? {
                  ...x,
                  commentData: [
                    ...x.commentData,
                    ...action.payload.response.data,
                  ],
                }
              : x
          ),
        },
      };

    case ADD_COMMENT:
      return {
        ...state,
        postData: {
          ...state.postData,
          data: state.postData.data.map((x) =>
            x.id === action.payload.post_id.post_id
              ? x.commentData
                ? {
                    ...x,
                    commentData: [
                      { ...action.payload.response, reply_count: 0 },
                      ...x.commentData,
                    ],
                    comment_count: x.comment_count + 1,
                  }
                : {
                    ...x,
                    commentData: [
                      { ...action.payload.response, reply_count: 0 },
                    ],
                  }
              : x
          ),
        },
      };

    case PIN_COMMENT:
      return {
        ...state,
        postData: {
          ...state.postData,
          data: state.postData.data.map((x) =>
            x.id === action.payload.post_id
              ? { ...x, pinned_comment: action.payload.comment_id }
              : x
          ),
        },
      };

    case UNPIN_COMMENT:
      return {
        ...state,
        postData: {
          ...state.postData,
          data: state.postData.data.map((x) =>
            x.id === action.payload.post_id ? { ...x, pinned_comment: null } : x
          ),
        },
      };

    case DELETE_COMMENT:
      // console.log(action.payload.post_id)
      return {
        ...state,
        postData: {
          ...state.postData,
          data: state.postData.data.map((x) =>
            x.id === action.payload.post_id.type_id
              ? x.commentData
                ? {
                    ...x,
                    comment_count:
                      action.payload?.reply_count > 0
                        ? x.comment_count - action.payload.reply_count - 1
                        : x.comment_count - 1,
                    commentData: x.commentData.filter(
                      (item) => item.id !== action.payload.post_id.id
                    ),
                  }
                : x
              : x
          ),
        },
      };
    case UPDATE_COMMENT:
      return {
        ...state,
        postData: {
          ...state.postData,
          data: state.postData.data.map((x) =>
            x.id === action.payload.postID
              ? x.commentData.id === action.payload.commentID
                ? { ...x, ...action.payload.commentText }
                : x
              : x
          ),
        },
      };

    case ADD_COMMENT_REPLY:
      return {
        ...state,
        postData: {
          ...state.postData,
          data: state.postData.data.map((x) =>
            x.id === action.payload.post_id.post_id
              ? {
                  ...x,
                  // comment_count: x.comment_count + 1,
                  commentData: x.commentData.map((x) =>
                    x.id === action.payload.response.parent_id
                      ? {
                          ...x,
                          reply_count: x.reply_count + 1,
                          replies: [...x.replies, action.payload.response],
                        }
                      : x
                  ),
                }
              : x
          ),
        },
      };

    case UPDATE_COMMENT_REPLY:
      return {
        ...state,
        postData: {
          ...state.postData,
          data: state.postData.data.map((x) =>
            x.id === action.payload.postID
              ? {
                  ...x,
                  commentData: x.commentData.map((x) =>
                    x.id === action.payload.commentID
                      ? {
                          ...x,
                          replies: x.replies.map((x) =>
                            x.id === action.payload.replyId
                              ? { ...x, ...action.payload.commentText }
                              : x
                          ),
                        }
                      : x
                  ),
                }
              : x
          ),
        },
      };

    case DELETE_COMMENT_REPLY:
      return {
        ...state,
        postData: {
          ...state.postData,
          data: state.postData.data.map((x) =>
            x.id === action.payload.post_id.type_id
              ? {
                  ...x,
                  // comment_count: x.comment_count - 1,
                  commentData: x.commentData.map((x) =>
                    x.id === action.payload.commentId
                      ? {
                          ...x,
                          reply_count: x.reply_count - 1,
                          replies: x.replies.filter(
                            (item) => item.id !== action.payload.post_id.id
                          ),
                        }
                      : x
                  ),
                }
              : x
          ),
        },
      };

    case GET_REPORT_TYPE:
      return {
        ...state,
        getReportData: action.payload,
      };

    case HIDE_USER_POST:
      return {
        ...state,
        postData: {
          ...state.postData,
          data: state.postData.data.filter(
            (item) => item.id !== action.payload
          ),
        },
      };

    case REPOST_POST:
      return {
        ...state,
        postData: {
          ...state.postData,
          data: [action.payload, ...state.postData.data],
        },
      };

    case LIKE_REACTION_DATA:
      return {
        ...state,
        postData: {
          ...state.postData,
          data: state.postData.data.map((x) =>
            x.id === action.payload.post_id
              ? x.reaction.byUser === null && !action.payload.page_id
                ? {
                    ...x,
                    reaction: {
                      ...x.reaction,
                      count: x.reaction.count + 1,
                      type: [
                        // { type: action.payload.type },
                        ...x.reaction.type,
                      ],
                      byUser: {
                        ...x.reaction.byUser,
                        type: action.payload.type,
                      },
                    },
                  }
                : {
                    ...x,
                    reaction: {
                      ...x.reaction,
                      count: action.payload.page_id
                        ? x.reaction.byUsers?.find(
                            ({ page_id }) => page_id === action.payload.page_id
                          )
                          ? x.reaction.count
                          : x.reaction.count + 1
                        : x.reaction.count,
                      type: [
                        // { type: action.payload.type },
                        ...x.reaction.type,
                      ],
                      byUser:
                        x.as_a_page === null || x.as_a_page === undefined
                          ? {
                              ...x.reaction.byUser,
                              type: action.payload.type,
                            }
                          : x.reaction.byUser,
                      byUsers:
                        x.as_a_page === null || x.as_a_page === undefined
                          ? x.reaction.byUsers
                          : x.reaction.byUsers?.length > 0
                          ? x.reaction.byUsers?.find(
                              ({ page_id }) =>
                                page_id === action.payload.page_id ||
                                action.payload.page_id === undefined
                            )
                            ? x.reaction.byUsers.map((y) =>
                                y.page_id === action.payload.page_id
                                  ? {
                                      ...y,
                                      page_id: action.payload.page_id,
                                      type: action.payload.type,
                                    }
                                  : y
                              )
                            : [action.payload, ...x.reaction.byUsers]
                          : [action.payload],
                    },
                  }
              : x
          ),
        },
      };

    case DELETE_REACTION_DATA:
      return {
        ...state,
        postData: {
          ...state.postData,
          data: state.postData.data.map((x) =>
            x.id === action.payload.post_id
              ? {
                  ...x,
                  reaction: action.payload.page_id
                    ? {
                        ...x.reaction,
                        count: x.reaction.count - 1,
                        type: [...x.reaction.type],
                        byUsers: x.reaction.byUsers.filter(
                          (obj) => obj.page_id !== action.payload.page_id
                        ),
                      }
                    : {
                        ...x.reaction,
                        count: x.reaction.count - 1,
                        type: [...x.reaction.type],
                        byUser: null,
                      },
                }
              : x
          ),
        },
      };

    case LIKE_REACTION_COMMENT_DATA:
      return {
        ...state,
        postData: {
          ...state.postData,
          data: state.postData.data.map((x) =>
            x.id === action.payload.post_id
              ? {
                  ...x,
                  // commentData: x.commentData.map((x1) =>
                  //   x1.id === action.payload.data.reaction_type_id
                  //     ? x1.reaction.user === null
                  //       ? {
                  //           ...x1,
                  //           reaction: {
                  //             ...x1.reaction,
                  //             count: x1.reaction.count + 1,
                  //             type: [...x1.reaction.type, { type: action.payload.data.type }],
                  //             user: {
                  //               ...x1.reaction.user,
                  //               type: action.payload.data.type,
                  //             },
                  //             byUser: {
                  //               ...x1.reaction.byUser,
                  //               type: action.payload.data.type,
                  //             },
                  //           },
                  //         }
                  //       : {
                  //           ...x1,
                  //           // reaction: {
                  //           //   ...x.reaction,
                  //           //   count: x.reaction.count,
                  //           //   type: [...x.reaction.type],
                  //           //   user: {
                  //           //     ...x.reaction.byUser,
                  //           //     type: action.payload.data.type,
                  //           //   },
                  //           // },
                  //           reaction: {
                  //             ...x1.reaction,
                  //             count: action.payload.data.page_id
                  //               ? x1.reaction.byUsers?.find(
                  //                   ({ page_id }) => page_id === action.payload.data.page_id
                  //                 )
                  //                 ? x1.reaction.count
                  //                 : x1.reaction.count + 1
                  //               : x1.reaction.count,
                  //             type: [...x1.reaction.type, { type: action.payload.data.type }],
                  //             user: {
                  //               ...x1.reaction.user,
                  //               type: action.payload.data.type,
                  //             },
                  //             byUser:
                  //               x1.as_a_page === null || x1.as_a_page === undefined
                  //                 ? {
                  //                     ...x1.reaction.byUser,
                  //                     type: action.payload.data.type,
                  //                   }
                  //                 : x1.reaction.byUser,
                  //             byUsers:
                  //               x1.as_a_page === null || x1.as_a_page === undefined
                  //                 ? x1.reaction.byUsers
                  //                 : x1.reaction.byUsers?.length > 0
                  //                 ? x1.reaction.byUsers?.find(
                  //                     ({ page_id }) =>
                  //                       page_id === action.payload.data.page_id ||
                  //                       action.payload.data.page_id === undefined
                  //                   )
                  //                   ? x1.reaction.byUsers.map((y) =>
                  //                       y.page_id === action.payload.data.page_id
                  //                         ? {
                  //                             ...y,
                  //                             page_id: action.payload.data.page_id,
                  //                             type: action.payload.data.type,
                  //                           }
                  //                         : y
                  //                     )
                  //                   : [...x.reaction.byUsers,action.payload.data]
                  //                 : [action.payload.data],
                  //           },
                  //         }
                  //     : x1
                  // ),
                  commentData: x.commentData.map((x1) =>
                    x1.id === action.payload.data.reaction_type_id
                      ? !action.payload.data?.page_id
                        ? {
                            ...x1,
                            reaction: {
                              ...x1.reaction,
                              count: x1.reaction.user
                                ? x1.reaction.count
                                : x1.reaction.count + 1,
                              type: [
                                ...x1.reaction.type,
                                { type: action.payload.data.type },
                              ],
                              user: {
                                ...x1.reaction.user,
                                type: action.payload.data.type,
                              },
                              byUsers: x1.reaction.byUsers?.length
                                ? x1.reaction.byUsers?.find(
                                    ({ page_id }) => page_id === null
                                  )
                                  ? x1.reaction.byUsers.map((y) =>
                                      y.page_id === null
                                        ? {
                                            ...y,
                                            type: action.payload.data.type,
                                          }
                                        : y
                                    )
                                  : [
                                      ...x.reaction.byUsers,
                                      {
                                        type: action.payload.data?.type,
                                        page_id: null,
                                      },
                                    ]
                                : [
                                    {
                                      type: action.payload.data?.type,
                                      page_id: null,
                                    },
                                  ],
                            },
                          }
                        : {
                            ...x1,
                            reaction: {
                              ...x1.reaction,
                              count: x1.reaction.byUsers?.find(
                                ({ page_id }) =>
                                  page_id === action.payload.data.page_id
                              )
                                ? x1.reaction.count
                                : x1.reaction.count + 1,
                              type: [
                                ...x1.reaction.type,
                                { type: action.payload.data.type },
                              ],
                              byUsers: x1.reaction.byUsers?.length
                                ? x1.reaction.byUsers?.find(
                                    ({ page_id }) =>
                                      page_id === action.payload.data.page_id
                                  )
                                  ? x1.reaction.byUsers.map((y) =>
                                      y.page_id === action.payload.data.page_id
                                        ? {
                                            ...y,
                                            page_id:
                                              action.payload.data.page_id,
                                            type: action.payload.data.type,
                                          }
                                        : y
                                    )
                                  : [
                                      ...x.reaction.byUsers,
                                      {
                                        type: action.payload.data?.type,
                                        page_id: action.payload.data?.page_id,
                                      },
                                    ]
                                : [
                                    {
                                      type: action.payload.data?.type,
                                      page_id: action.payload.data?.page_id,
                                    },
                                  ],
                            },
                          }
                      : x1
                  ),
                }
              : x
          ),
        },
      };

    case UPDATE_HOME_CONNECTION_STATUS:
      return {
        ...state,
        postData: {
          ...state.postData,
          data: state.postData.data.map((x) =>
            x.id === action.payload.post_id
              ? {
                  ...x,
                  User: {
                    ...x.User,
                    connection_status: action.payload.data,
                  },
                }
              : {
                  ...x,
                }
          ),
        },
      };
    case USER_FOLLOW_STATUS_HOME:
      return {
        ...state,
        postData: {
          ...state.postData,
          data: state.postData.data.map((x) =>
            x.id === action.payload.post_id
              ? {
                  ...x,
                  User: {
                    ...x.User,
                    follow_status: true,
                  },
                }
              : {
                  ...x,
                }
          ),
        },
      };

    case USER_UNFOLLOW_STATUS_HOME:
      return {
        ...state,
        postData: {
          ...state.postData,
          data: state.postData.data.map((x) =>
            x.id === action.payload.post_id
              ? {
                  ...x,
                  User: {
                    ...x.User,
                    follow_status: false,
                  },
                }
              : {
                  ...x,
                }
          ),
        },
      };

    case REMOVE_CONNECTION:
      return {
        ...state,
        postData: {
          ...state.postData,
          data: state.postData.data.map((x) =>
            x.id === action.payload.post_id
              ? {
                  ...x,
                  User: {
                    ...x.User,
                    connection_status: null,
                  },
                }
              : {
                  ...x,
                }
          ),
        },
      };

    case FOLLOW_PAGE_SUCCESS_HOME:
      return {
        ...state,
        postData: {
          ...state.postData,
          data: state.postData.data.map((x) =>
            x.id === action.payload.post_id
              ? {
                  ...x,
                  Page: {
                    ...x.Page,
                    is_following: true,
                  },
                }
              : {
                  ...x,
                }
          ),
        },
      };
    case UNFOLLOW_PAGE_SUCCESS_HOME:
      return {
        ...state,
        postData: {
          ...state.postData,
          data: state.postData.data.map((x) =>
            x.id === action.payload.post_id
              ? {
                  ...x,
                  Page: {
                    ...x.Page,
                    is_following: false,
                  },
                }
              : {
                  ...x,
                }
          ),
        },
      };

    case DELETE_REACTION_COMMENT_DATA:
      return {
        ...state,
        postData: {
          ...state.postData,
          data: state.postData.data.map((x) =>
            x.id === action.payload.post_id
              ? {
                  ...x,
                  commentData: x.commentData.map((x) =>
                    x.id === action.payload.data.reaction_type_id
                      ? {
                          ...x,
                          // reaction: {
                          //   ...x.reaction,
                          //   count: x.reaction.count - 1,
                          //   type: [...x.reaction.type],
                          //   user: null,
                          // },
                          reaction: action.payload.data.page_id
                            ? {
                                ...x.reaction,
                                count: x.reaction.count - 1,
                                type: [...x.reaction.type],
                                byUsers: x.reaction.byUsers.filter(
                                  (obj) =>
                                    obj.page_id !== action.payload.data?.page_id
                                ),
                              }
                            : {
                                ...x.reaction,
                                count: x.reaction.count - 1,
                                type: [...x.reaction.type],
                                user: null,
                                byUsers: x.reaction.byUsers.filter(
                                  (obj) => obj.page_id !== null
                                ),
                              },
                        }
                      : x
                  ),
                }
              : x
          ),
        },
      };

    case SAVE_POST:
      return {
        ...state,
        postData: {
          ...state.postData,
          data: state.postData?.data?.map((x) =>
            x.id === action.payload ? { ...x, Saved_Post: true } : x
          ),
        },
      };

    case DELETE_SAVED_POST:
      return {
        ...state,
        postData: {
          ...state.postData,
          data: state.postData?.data?.map((x) =>
            x.id === action.payload ? { ...x, Saved_Post: false } : x
          ),
        },
      };

    case GET_MORE_COMMENT_REPLY:
      let reverseList = action.payload.response.data.reverse();
      return {
        ...state,
        postData: {
          ...state.postData,
          data: state.postData.data.map((x) =>
            x.id === action.payload.post_id.post_id
              ? {
                  ...x,
                  commentData: x.commentData.map((x) =>
                    x.id === action.payload.post_id.parent_id
                      ? {
                          ...x,
                          replies: [...reverseList, ...x.replies],
                        }
                      : x
                  ),
                }
              : x
          ),
        },
      };

    case DELETE_REACTION_REPLY_DATA:
      return {
        ...state,
        postData: {
          ...state.postData,
          data: state.postData.data.map((x) =>
            x.id === action.payload.post_id
              ? {
                  ...x,
                  commentData: x.commentData.map((x) =>
                    x.id === action.payload.comment_id
                      ? {
                          ...x,
                          replies: x.replies.map((x) =>
                            x.id === action.payload.data.reaction_type_id
                              ? {
                                  ...x,
                                  reaction: {
                                    ...x.reaction,
                                    count: x.reaction.count - 1,
                                    type: [...x.reaction.type],
                                    user: null,
                                  },
                                }
                              : x
                          ),
                        }
                      : x
                  ),
                }
              : x
          ),
        },
      };

    case LIKE_REACTION_REPLY_DATA:
      return {
        ...state,
        postData: {
          ...state.postData,
          data: state.postData.data.map((x) =>
            x.id === action.payload.post_id
              ? {
                  ...x,
                  commentData: x.commentData.map((x) =>
                    x.id === action.payload.comment_id
                      ? {
                          ...x,
                          replies: x.replies.map((x) =>
                            x.id === action.payload.data.reaction_type_id
                              ? x.reaction.user === null
                                ? {
                                    ...x,
                                    reaction: {
                                      ...x.reaction,
                                      count: x.reaction.count + 1,
                                      type: [...x.reaction.type],
                                      user: {
                                        ...x.reaction.user,
                                        type: action.payload.data.type,
                                      },
                                    },
                                  }
                                : {
                                    ...x,
                                    reaction: {
                                      ...x.reaction,
                                      count: x.reaction.count,
                                      type: [...x.reaction.type],
                                      user: {
                                        ...x.reaction.byUser,
                                        type: action.payload.data.type,
                                      },
                                    },
                                  }
                              : x
                          ),
                        }
                      : x
                  ),
                }
              : x
          ),
        },
      };

    case GET_HASHTAG_POST_FEED:
      return {
        ...state,
        postData: {
          ...state.postData,
          data: action.payload.data.posts,
          message: action.payload.message,
          success: action.payload.success,
          is_following: action.payload.data.is_following,
          follower_count: action.payload.data.follower_count,
        },
      };

    case FOLLOW_HASHTAG_POST_FEED:
      return {
        ...state,
        postData: {
          ...state.postData,
          is_following: true,
          follower_count: state.postData.follower_count + 1,
        },
      };

    case UNFOLLOW_HASHTAG_POST_FEED:
      return {
        ...state,
        postData: {
          ...state.postData,
          is_following: false,
          follower_count: state.postData.follower_count - 1,
        },
      };

    case COMMENT_STATUS_TRUE:
      return {
        ...state,
        postData: {
          ...state.postData,
          data: state.postData.data.map((x) =>
            x.id === action.payload ? { ...x, comment_status: false } : x
          ),
        },
      };
    case COMMENT_STATUS_FALSE:
      return {
        ...state,
        postData: {
          ...state.postData,
          data: state.postData.data.map((x) =>
            x.id === action.payload ? { ...x, comment_status: true } : x
          ),
        },
      };

    case POSTVIEWCOUNT_SUCCESS:
      return {
        ...state,
        postData: {
          ...state.postData,
        },
      };

    case AS_A_PAGE_ROLE:
      return {
        ...state,
        postData: {
          ...state.postData,
          data: state.postData.data.map((x) =>
            x.id === action.payload.postId
              ? {
                  ...x,
                  as_a_page: action.payload.pageData,
                }
              : {
                  ...x,
                }
          ),
        },
      };

    case GET_STATISTICS:
      return {
        ...state,
        statisticsData: {
          data: payload?.data,
          items: payload?.items,
        },
      };
    case GET_MORE_STATISTICS:
      return {
        ...state,
        statisticsData: {
          data: [...state?.statisticsData?.data, ...payload?.data],
          items: payload?.items,
        },
      };
    case GET_AD_LIST:
      return {
        ...state,
        ads: payload?.data,
      };
    default:
      return state;
  }
};
export default dashboard;
