import { GET_INDUSTRY_DATA, GET_HASHTAG_LIST} from "../types";

export const initialState = {
industryData:[],
hashtagList:[]

};

const master = (state = initialState, action) => {
 switch (action.type) {
  
  case  GET_INDUSTRY_DATA:
   return { ...state, industryData: action.payload };

   case  GET_HASHTAG_LIST:
    return { ...state, hashtagList: action.payload };

   default:
   return state;
 }
};

export default master;