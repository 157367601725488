import React, { useState, useEffect } from "react";
import { useNavigate, Link, useParams } from "react-router-dom";
import routeNames from "../../../routes/routeNames";

import Chat from "../../../pages/chat/chat";
import Navbar from "../../../pages/navbar/navbar";
import edit from "../../../assets/img/edit.svg";
import emp from "../../../assets/img/emp.svg";
import msg from "../../../assets/img/notification.svg";
import edu from "../../../assets/img/edu.svg";
import userpic from "../../../assets/img/user-a.svg";
import view from "../../../assets/img/view 1.svg";
import bar from "../../../assets/img/bar-chart 1.svg";
import ser from "../../../assets/img/search-results 1.svg";
import del from "../../../assets/img/Trash can.svg";
import networkIcon from "../../../assets/img/Union.svg";
import blockIcon from "../../../assets/img/blockIcon.svg";
import reportIcon from "../../../assets/img/slash.svg";
import leadIcon from "../../../assets/img/leadIcon.svg";
import connectIcon from "../../../assets/img/connectIcon.png";
import removeConnection from "../../../assets/img/remove-user-16.png";
import user_icon from "../../../assets/img/user_profile.svg";
import placeholderUser from "../../../assets/img/placeholder_user.jpg";
import add from "../../../assets/img/add-icon.svg";
import plus from "../../../assets/img/plus-grey.svg";
import tick from "../../../assets/img/tick-icon-grey.svg";
import eye from "../../../assets/img/eye-blue.svg";
import addIcon from "../../../assets/img/follow.svg";
import plusIcon from "../../../assets/img/plusIconFigma.svg";
import EditIcon from "../../../assets/img/EditIconFigma.svg";
import GoldIcon from "../../../assets/img/gold.svg";
import exp_tb from '../../../assets/img/exp_badge.svg'
import edu_tb from '../../../assets/img/edu_badge.svg'
import cri_tb from "../../../assets/img/criminal_badge.svg"

import "./profile.scss";
import Footers from "../../../core/components/layout/n-footer/Footers";
import Footer from "../../../core/components/layout/footer/Footer";
import EditProfileModal from "./components/EditProfileModal";
import EditAbout from "./components/EditAbout";
import ImageModal from "../../../core/components/modal/ImageModal";
import AddExperModal from "./components/AddExperModal";
import AddSkillModal from "./components/AddSkillModal";
import AddEducationModal from "./components/AddEducationModal";
import AddCourseModal from "./components/AddCourseModal";
import AddLanguageModal from "./components/AddLanguageModal";
import AddLicenseCertificate from "./components/AddLicenseCertificateModal";
import AddSection from "./components/AddSection";
import Contactinfo from "./components/Contactinfo";
import AddContactInfoModal from "./components/AddContactInfoModal";
import AddHonorsAwards from "./components/AddAwardsHonors";
import GiveRecommendationModal from "./components/recommend/giveRecommendation";
import buld from "../../../assets/img/circle-building.svg";
import confidential from "../../../assets/img/confidentialIcon.svg";
import degre from "../../../assets/img/circle-degree.svg";
import share from "../../../assets/img/share-rec.svg";
import down from "../../../assets/img/download.svg";
import Sidebar from "../../../core/components/layout/Sidebar/Sidebar";
import moment from "moment";
import Refer from "../../../core/components/layout/Sidebar/Refer";
import Activities from "./components/Activities";
import Recomendation from "./components/recommend/Recomendation";
import bannerImage from "../../../assets/img/sample_banner_image.png";
import { VideoSidebar } from "../../../core/components/layout/Sidebar/VideoSidebar";
import Loader from "../../../core/components/Loaders/ComponentLoader";
import ProfilePhotoModal from "./components/ProfilePhotoModal";
import BackgroundPhotoModal from "./components/BackgroundPhotoModal";
import AddIntroVideo from "./components/AddVideoModal";
import AddResumeModal from "./components/AddResumeModal";
import CreatorModeModal from "../../../routes/setting/components/CreatorModeModal";
import { LinkSidebar } from "../../../core/components/layout/Sidebar/LinkSidebar";
import VerificationModal from "./components/verification/VerificationModal";
import AppLoader from "../../../core/components/Loaders/appLoader/appLoader";
import DeleteModal from "../../../core/components/modal/DeleteModal";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Ad from "../../../core/components/layout/Sidebar/Ad";
import PeopleYouMayKnow from "../../../core/components/layout/Sidebar/PeopleYouMayKnow";
import ArticlesFeatured from "../../../core/components/layout/Sidebar/ArticlesFeatured";
import PostsFeatured from "../../../core/components/layout/Sidebar/PostsFeatured";
import SidebarFooter from "../../setting/SidebarFooter";
import MutualConnectionModal from "../../../core/components/modal/mutualConnection";
import NpMailModal from "../../../core/components/modal/NpMailModal";
import ReportModal from "../../dashboard/components/reportTypeModal";
import Interest from "./components/Interest";
import SocialMedia from "./components/SocialMedia";
import { useSelector } from "react-redux";

//let b64s="data:image/jpeg;base64,";

export default function Profile(props) {
  const navigate = useNavigate();
  const { isSeekingBadgeModalOpen = false, isRecommendationTabActive = false } =
    useParams();
  const {
    profileData,
    deleteSkill,
    handlePdf,
    editmodal,
    setEditmodal,
    editAbout,
    setEditAbout,
    addExpModal,
    setAddExpModal,
    addSkillModal,
    setAddSkillModal,
    educationModal,
    setEducationModal,
    courseModal,
    setCourseModal,
    languageModal,
    setLanguageModal,
    licenseCertificateModal,
    setLicenseCertificateModal,
    sectionModal,
    setSectionModal,
    contactinfoModal,
    setContactinfoModal,
    addContactInfoModal,
    setAddContactInfoModal,
    editExperienceData,
    setEditExperienceData,
    editEducationData,
    setEditEducationData,
    editCourseData,
    setEditCourseData,
    editLanguageData,
    setEditLanguageData,
    editLicenseData,
    setEditLicenseData,
    editSkillsData,
    setEditSkillsData,
    showAllSkills,
    setShowAllSkills,
    dropDown,
    setDropDown,
    pdfRef,
    imageModal,
    setImageModal,
    newExperienceData,
    dateUtility,
    croppedImage,
    setCroppedImage,
    node,
    sections,
    activeSection,
    setActiveSection,
    isAllowedToEdit,
    sendConnectionHandle,
    isLoading,
    profilePhotoModal,
    setProfilePhotoModal,
    handleBannerPicture,
    backgroundPhotoModal,
    setBackgroundPhotoModal,
    awardsHonorsModal,
    setAwardsHonorsModal,
    editHonorsAwardsData,
    setEditHonorsAwardsData,
    user_id,
    giveRecommendation,
    setGiveRecommendation,
    addIntroModal,
    setAddIntroModal,
    addResumeModal,
    setAddResumeModal,
    creatorModeModal,
    setCreatorModeModal,
    deleteIntroVideo,
    create_follower,
    create_unfollower,
    verificationModal,
    setVerificationModal,
    handleRemovePicture,
    isPageLoading,
    closeModal,
    setCloseModal,
    deleteItem,
    setDeleteItem,
    askRecommendation,
    setAskRecommendation,
    writeRecommendationModal,
    setWriteRecommendationModal,
    isEditRecommend,
    setIsEditRecommend,
    writeTitle,
    setWriteTitle,
    addLead,
    suggested_people,
    sendRequest,
    memberView,
    setMemberView,
    my_articles,
    mode,
    public_post,
    mutualConnectionModal,
    setMutualConnectionModal,
    user,
    abbreviateNumber,
    peopleRemoveConnection,
    blockUser,
    npMailModal,
    setNpMailModal,
    id,
  } = props;

  const [customProfileModal, setCustomProfileModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [isUnfollowed, setIsUnfollowed] = useState(false);
  const [isRemoved, setIsRemoved] = useState(false);
  const { screeningList } = useSelector((state) => state?.backgroundScreen);
  const [reportModal, setReportModal] = useState(false);
  //Modal Functionality
  const openDeleteModal = () => {
    setShowDeleteModal(true);
  };

  const closeDeleteModal = () => {
    setShowDeleteModal(false);
  };

  useEffect(() => {
    if (isRecommendationTabActive) {
      setActiveSection("Recommendations");
    }
  }, [isRecommendationTabActive]);

  let modeCheck;

  if (profileData?.membership?.id == "1") {
    modeCheck = 500;
  } else if (profileData?.membership?.id == "2") {
    modeCheck = 100;
  } else {
    modeCheck = 1;
  }

  return (
    <>
      <div>
        <div className="">
          <Navbar isJobs={false} />
          {isPageLoading === true ? (
            <AppLoader />
          ) : (
            <div className="profile">
              <div className="back">
                <div className="container">
                  <div className="row" ref={pdfRef}>
                    <div className="col-lg-12 mt-26">
                      <div className="edit-box ">
                        <div className="profile-bg">
                          {profileData?.background_img ? (
                            <img
                              src={profileData?.background_img}
                              //src={bs64s}
                              className="img-fluid-banner"
                              alt=""
                            />
                          ) : (
                            <></>
                          )}
                          {isAllowedToEdit() && (
                            <div
                              className="edit"
                              onClick={() =>
                                setBackgroundPhotoModal(!backgroundPhotoModal)
                              }
                            >
                              <img src={edit} alt="" />
                            </div>
                          )}
                          <div className="nobel">
                            {isAllowedToEdit() ? (
                              <span>
                                {`${profileData?.membership?.name}`.toUpperCase()}
                              </span>
                            ) : (
                              profileData?.membership?.id > 1 && (
                                <span className="p-2">
                                  <img
                                    src={GoldIcon}
                                    width={20}
                                    height={20}
                                    alt=""
                                  />
                                </span>
                              )
                            )}
                          </div>
                        </div>
                        <div className="row px-3 pb-2">
                          <div className="col-xl-8 col-lg-12">
                            <div className="left">
                              <div className="details">
                                <div className="profile-image">
                                  <div className="pro">
                                    <img
                                      src={
                                        profileData?.profile_img
                                          ? profileData.profile_img
                                          : placeholderUser
                                      }
                                      className="img-fluid"
                                      alt=""
                                    />
                                  </div>
                                  {isAllowedToEdit() && (
                                    <div className="edit">
                                      <img
                                        src={edit}
                                        alt=""
                                        onClick={() =>
                                          setProfilePhotoModal(
                                            !profilePhotoModal
                                          )
                                        }
                                      />
                                    </div>
                                  )}
                                </div>
                                <div className="mt w-100">
                                  <div className="top">
                                    <div className="name">
                                      <h2>
                                        {profileData?.first_name}{" "}
                                        {profileData?.last_name}
                                      </h2>
                                    </div>
                                    {profileData?.profile_verified_percentage ===
                                      100 && (
                                        <div className="varify">
                                          <div className="checkMark"></div>
                                        </div>
                                      )}
                                    {profileData?.badge == "hiring" &&
                                      profileData?.membership?.name !==
                                      "Starter" && (
                                        <div className="hiring">
                                          <span>Hiring</span>
                                        </div>
                                      )}
                                    {profileData?.badge == "seeking" && (
                                      <div className="seeking">
                                        <span>Seeking</span>
                                      </div>
                                    )}
                                    <div style={{display:"flex", columnGap:"10px"}}>
                                      {profileData?.screening_status == "Verified" ? <img src = {cri_tb}></img> : <></>} 
                                      {profileData?.isEduTb ? <img src = {edu_tb}></img> : <></>}
                                      {profileData?.isExpTb ? <img src = {exp_tb}></img> : <></>}
                                    </div>
                                    {/* <div className="details-img">
                                      <img
                                        src={emp}
                                        alt=""
                                        data-placement="top"
                                        title=" Employment Verified"
                                        role="tooltip"
                                      />
                                      <img
                                        src={edu}
                                        className="mx-1 mx-sm-2"
                                        alt=""
                                        data-placement="top"
                                        title=" Education Verified"
                                        role="tooltip"
                                      />
                                      <img
                                        src={userpic}
                                        alt=""
                                        data-placement="top"
                                        title={
                                          "Global Criminality Verified on " +
                                          DATE
                                        }
                                        role="tooltip"
                                      />
                                    </div> */}
                                  </div>
                                  <div className="work my-2">
                                    <span>{profileData?.profile_headline}</span>
                                  </div>
                                  <div className="where">
                                    {profileData?.location && (
                                      <>
                                        <p className="location"></p>
                                        <span>
                                          {/* {profileData?.state?.name} |{" "}
                                    {profileData?.country?.name} */}
                                          {profileData?.location?.replaceAll(
                                            ",",
                                            " | "
                                          )}
                                        </span>
                                      </>
                                    )}
                                    <div
                                      className="link d-flex"
                                      style={{ height: "fit-content" }}
                                    >
                                      <div style={{ minWidth: "max-content" }}>
                                        Contact Info
                                      </div>
                                      <img
                                        style={{ marginLeft: "3px" }}
                                        role="button"
                                        src={eye}
                                        alt=""
                                        onClick={() => {
                                          setContactinfoModal(
                                            !contactinfoModal
                                          );
                                        }}
                                      />
                                      {isAllowedToEdit() && (
                                        <img
                                          role="button"
                                          src={edit}
                                          alt=""
                                          onClick={() => {
                                            setAddContactInfoModal(
                                              !addContactInfoModal
                                            );
                                          }}
                                          style={{ marginLeft: "3px" }}
                                        />
                                      )}
                                    </div>
                                  </div>
                                  {profileData?.shared_connection && (
                                    <div className="where">
                                      <div className="mutual-connection">
                                        <div className="d-flex">
                                          {profileData?.shared_connection?.map(
                                            (data, index) => {
                                              if (index < 3) {
                                                return (
                                                  <span
                                                    className="list_img"
                                                    key={index}
                                                  >
                                                    <img
                                                      src={
                                                        data?.profile_img
                                                          ? data?.profile_img
                                                          : placeholderUser
                                                      }
                                                      alt="placeholder_image"
                                                      className="image-fit"
                                                    />
                                                  </span>
                                                );
                                              }
                                              return <></>;
                                            }
                                          )}
                                        </div>
                                        {profileData?.shared_connection
                                          ?.length > 0 && (
                                            <span
                                              onClick={() =>
                                                setMutualConnectionModal(
                                                  !mutualConnectionModal
                                                )
                                              }
                                              style={{ marginLeft: "-5px" }}
                                              className=""
                                            >
                                              {
                                                profileData?.shared_connection
                                                  ?.length
                                              }{" "}
                                              {profileData?.shared_connection
                                                ?.length > 1
                                                ? " Mutual Connections"
                                                : "Mutual Connection"}
                                              {": "}
                                              {
                                                profileData?.shared_connection[0]
                                                  ?.first_name
                                              }
                                              {
                                                profileData?.shared_connection[0]
                                                  ?.last_name
                                              }{" "}
                                              {profileData?.shared_connection
                                                ?.length > 1 && "and others"}
                                            </span>
                                          )}
                                      </div>
                                    </div>
                                  )}
                                </div>
                              </div>
                              <div className="details mt-3">
                                {profileData?.highestExperience && (
                                  <div className="company d-flex justify-content-center align-items-center">
                                    <div className="circle">
                                      <img
                                        src={
                                          profileData?.highestExperience?.Page
                                            ?.icon || buld
                                        }
                                        width="40"
                                        className="rounded-circle"
                                        alt=""
                                      />
                                    </div>
                                    <h6 className="">
                                      {
                                        profileData?.highestExperience
                                          .company_name
                                      }
                                    </h6>
                                  </div>
                                )}

                                {profileData?.highestEducation && (
                                  <div className="univer">
                                    <div className="circle">
                                      <img
                                        src={
                                          profileData?.highestEducation?.Page
                                            ? profileData?.highestEducation
                                              ?.Page?.icon
                                            : degre
                                        }
                                        className=""
                                        alt=""
                                      />
                                    </div>
                                    <h6 className="">
                                      {
                                        profileData?.highestEducation
                                          ?.school_name
                                      }
                                    </h6>
                                  </div>
                                )}
                              </div>
                            </div>
                          </div>
                          <div className="col-xl-4 col-lg-12">
                            <div className="right">
                              {isAllowedToEdit() && (
                                <>
                                  <div className="progres">
                                    <div className="first">
                                      <div className="progress">
                                        <div
                                          className="progress-bar"
                                          style={{
                                            width: (
                                              profileData?.profile_complete_percentage +
                                              "%"
                                            ).toString(),
                                          }}
                                        ></div>
                                      </div>
                                      <span>
                                        {profileData?.profile_complete_percentage +
                                          "%"}{" "}
                                        profile complete
                                      </span>
                                    </div>
                                    <div className="second">
                                      <div className="progress">
                                        <div
                                          className="progress-bar"
                                          style={{
                                            width:
                                              profileData?.profile_verified_percentage +
                                              "%",
                                          }}
                                        ></div>
                                      </div>
                                      <span
                                        style={{ cursor: "pointer" }}
                                        onClick={() =>
                                          setVerificationModal(
                                            !verificationModal
                                          )
                                        }
                                      >
                                        {profileData?.profile_verified_percentage +
                                          "%"}{" "}
                                        profile verified
                                      </span>
                                    </div>
                                  </div>
                                  <div className="line"></div>
                                </>
                              )}
                              <div
                                className={
                                  isAllowedToEdit()
                                    ? "follow"
                                    : "follow justify-content-end"
                                }
                                onClick={() => {
                                  navigate("/connections", {
                                    state: { user_id: user_id },
                                  });
                                }}
                              >
                                <div className="connections curserPointer">
                                  <span
                                    className={
                                      isAllowedToEdit() ? "" : "member-view"
                                    }
                                  >
                                    {profileData?.connections != null
                                      ? abbreviateNumber(
                                        profileData?.connections
                                      )
                                      : "Hidden"}
                                  </span>
                                  <span
                                    className={
                                      isAllowedToEdit() ? "" : "member-color"
                                    }
                                  >
                                    {profileData?.connections > 1
                                      ? "Connections"
                                      : "Connection"}
                                  </span>
                                </div>
                                {!isAllowedToEdit() && (
                                  <div className="h-border "></div>
                                )}
                                <div className="follower curserPointer">
                                  <span
                                    className={
                                      isAllowedToEdit() ? "" : "member-view"
                                    }
                                  >
                                    {profileData?.followers != null
                                      ? abbreviateNumber(profileData?.followers)
                                      : "Hidden"}
                                  </span>
                                  <span
                                    className={
                                      isAllowedToEdit() ? "" : "member-color"
                                    }
                                  >
                                    {profileData?.followers > 1
                                      ? "Followers"
                                      : "Follower"}
                                  </span>
                                </div>
                                {!isAllowedToEdit() && (
                                  <div className="h-border "></div>
                                )}
                                <div className="following curserPointer">
                                  <span
                                    className={
                                      isAllowedToEdit() ? "" : "member-view"
                                    }
                                  >
                                    {profileData?.followings != null
                                      ? abbreviateNumber(
                                        profileData?.followings
                                      )
                                      : "Hidden"}
                                  </span>
                                  <span
                                    className={
                                      isAllowedToEdit() ? "" : "member-color"
                                    }
                                  >
                                    {/* {profileData?.followings > 1
                                      ? "Followings"
                                      : "Following"} */}
                                    Following
                                  </span>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div
                            className="bottom"
                            data-html2canvas-ignore="true"
                          >
                            <div className="center">
                              <div className="activity">
                                <ul>
                                  {sections.map((section, idx) => {
                                    return (
                                      <li
                                        key={idx}
                                        onClick={() => {
                                          setActiveSection(section.name);
                                        }}
                                        className={
                                          activeSection === section.name
                                            ? "active-color"
                                            : ""
                                        }
                                      >
                                        {section.name}
                                      </li>
                                    );
                                  })}

                                  {/* <li>Activity</li>
                              <li>Recommendations</li>
                              <li>Interests</li>
                              <li>Social Media Channel</li> */}
                                </ul>
                              </div>
                              {isAllowedToEdit() && (
                                <div
                                  className="add p-1  rounded"
                                  style={{
                                    background: "#eaf0ff",
                                  }}
                                >
                                  <span
                                    style={{ fontWeight: 400 }}
                                    onClick={() => {
                                      setSectionModal(!sectionModal);
                                    }}
                                  >
                                    + Add a Section to Profile
                                  </span>
                                </div>
                              )}
                            </div>
                            {isAllowedToEdit() ? (
                              <div className="member">
                                <div
                                  className="view"
                                  onClick={() => setMemberView(true)}
                                >
                                  <Link to={`/profile/${user_id}?mode=member`}>
                                    <span>View as Member</span>
                                  </Link>
                                </div>
                                <div className="edit">
                                  <span
                                    onClick={() => {
                                      setEditmodal(!editmodal);
                                    }}
                                  >
                                    Edit Profile
                                  </span>
                                </div>
                                <div ref={node}>
                                  <div
                                    ref={node}
                                    className="dots bg-transparent"
                                    onClick={() => setDropDown(!dropDown)}
                                  >
                                    <span>&#x2022;</span>
                                    <span>&#x2022;</span>
                                    <span>&#x2022;</span>
                                  </div>
                                  <div className="drop">
                                    {dropDown && (
                                      <div className="box">
                                        <ul>
                                          <li
                                            onClick={() => {
                                              navigator.clipboard.writeText(
                                                `${window.location.origin}/profile/${user_id}`
                                              );
                                              toast.success(
                                                "Profile URL is copied!",
                                                {
                                                  position:
                                                    toast.POSITION.BOTTOM_LEFT,
                                                }
                                              );
                                            }}
                                          >
                                            <img src={share} alt="" /> Share
                                            Profile
                                          </li>
                                          <li>
                                            <img src={down} alt="" />{" "}
                                            <span
                                              onClick={handlePdf}
                                              style={{ cursor: "pointer" }}
                                            >
                                              Export to PDF
                                            </span>
                                          </li>
                                          <li>
                                            <img src={user_icon} alt="" />
                                            <span
                                              onClick={() => {
                                                profileData?.connections >=
                                                  modeCheck
                                                  ? setCreatorModeModal(
                                                    !creatorModeModal
                                                  )
                                                  : toast.success(
                                                    `Based on your current membership plan, you need to have atleast ${modeCheck} connections to enable creator mode.`,
                                                    {
                                                      position:
                                                        toast.POSITION
                                                          .BOTTOM_LEFT,
                                                    }
                                                  );
                                              }}
                                              style={{ cursor: "pointer" }}
                                            >
                                              {" "}
                                              Creator Mode
                                            </span>
                                          </li>
                                        </ul>
                                      </div>
                                    )}
                                  </div>
                                </div>
                              </div>
                            ) : (
                              <div className="member">
                                {mode === "member" && (
                                  <div
                                    className="view"
                                    onClick={() => setMemberView(false)}
                                  >
                                    <Link to={`/profile/${user_id}`}>
                                      <span>View as Admin</span>
                                    </Link>
                                  </div>
                                )}
                                {profileData?.creator_mode === true ? (
                                  profileData?.follow_status === false ? (
                                    <div
                                      className="btn btn-light"
                                      onClick={() => {
                                        create_follower();
                                      }}
                                    >
                                      <img
                                        src={add}
                                        alt=""
                                        className="connect-img"
                                      />
                                      Follow
                                    </div>
                                  ) : profileData?.follow_status === true ? (
                                    <div
                                      className="btn btn-light"
                                      // onClick={() => {
                                      //   create_unfollower();
                                      // }}
                                      onClick={() => openDeleteModal()}
                                    >
                                      {/* <img
                                    src={add}
                                    alt=""
                                    className="connect-img"
                                  /> */}
                                      Unfollow
                                    </div>
                                  ) : (
                                    <></>
                                  )
                                ) : profileData?.creator_mode === false ? (
                                  profileData?.connection_status ===
                                    "pending" ? (
                                    <div className="btn btn-light">Pending</div>
                                  ) : profileData?.connection_status ===
                                    "confirm" ? (
                                    <div
                                      className="btn btn-light"
                                      onClick={() =>
                                        alert(
                                          "This funtionality will be implemented in future milestones"
                                        )
                                      }
                                    >
                                      Message
                                    </div>
                                  ) : (
                                    <div
                                      className="btn btn-light"
                                      onClick={() => {
                                        sendConnectionHandle();
                                      }}
                                    >
                                      <img
                                        src={connectIcon}
                                        alt=""
                                        className="connect-img"
                                      />
                                      Connect
                                    </div>
                                  )
                                ) : (
                                  <></>
                                )}

                                <div
                                  className="btn border-btn"
                                  onClick={() =>
                                    // alert(
                                    //   "This funtionality will be implemented in future milestones"
                                    // )
                                    setNpMailModal(true)
                                  }
                                >
                                  <span>NPMail</span>
                                </div>
                                <div ref={node}>
                                  <div
                                    ref={node}
                                    className="dots"
                                    onClick={() => setDropDown(!dropDown)}
                                  >
                                    <span>&#x2022;</span>
                                    <span>&#x2022;</span>
                                    <span>&#x2022;</span>
                                  </div>
                                  <div className="drop">
                                    {dropDown && (
                                      <div className="box">
                                        <ul>
                                          <li>
                                            {/* <img src={networkIcon} alt="" />{" "}
                                            Connect */}
                                            {profileData?.creator_mode ===
                                              false ? (
                                              profileData?.follow_status ===
                                                false ? (
                                                <div
                                                  onClick={() => {
                                                    create_follower();
                                                  }}
                                                  className=""
                                                >
                                                  <img
                                                    src={plus}
                                                    alt=""
                                                    className="connect-img"
                                                    style={{
                                                      paddingRight: "9px",
                                                      width: "23px",
                                                    }}
                                                  />
                                                  Follow
                                                </div>
                                              ) : profileData?.follow_status ===
                                                true ? (
                                                <div
                                                  // onClick={() => {
                                                  //   create_unfollower();
                                                  // }}
                                                  onClick={() =>
                                                    openDeleteModal()
                                                  }
                                                  className=""
                                                >
                                                  <img
                                                    src={tick}
                                                    alt=""
                                                    className="connect-img"
                                                    style={{
                                                      paddingRight: "9px",
                                                      width: "21px",
                                                    }}
                                                  />
                                                  Unfollow
                                                </div>
                                              ) : (
                                                <></>
                                              )
                                            ) : profileData?.creator_mode ===
                                              true ? (
                                              profileData?.connection_status ===
                                                "pending" ? (
                                                <div className="">
                                                  <img
                                                    src={networkIcon}
                                                    alt=""
                                                    className="connect-img"
                                                    style={{
                                                      paddingRight: "9px",
                                                      width: "25px",
                                                    }}
                                                  />
                                                  Pending
                                                </div>
                                              ) : profileData?.connection_status ===
                                                "confirm" ? (
                                                <>
                                                  <div
                                                    onClick={() =>
                                                      alert(
                                                        "This funtionality will be implemented in future milestones"
                                                      )
                                                    }
                                                    className=""
                                                  >
                                                    <img
                                                      src={msg}
                                                      alt=""
                                                      className="connect-img"
                                                      style={{
                                                        paddingRight: "9px",
                                                        width: "25px",
                                                      }}
                                                    />
                                                    Message
                                                  </div>
                                                </>
                                              ) : (
                                                <>
                                                  <div
                                                    onClick={() => {
                                                      sendConnectionHandle();
                                                    }}
                                                  >
                                                    <img
                                                      src={networkIcon}
                                                      alt=""
                                                      className="connect-img"
                                                      style={{
                                                        paddingRight: "9px",
                                                        width: "25px",
                                                      }}
                                                    />
                                                    Connect
                                                  </div>
                                                </>
                                              )
                                            ) : (
                                              <></>
                                            )}
                                          </li>
                                          {profileData?.connection_status ===
                                            "confirm" ? (
                                            <li>
                                              <div
                                                // onClick={() =>
                                                //   peopleRemoveConnection()
                                                // }
                                                onClick={() => {
                                                  setIsRemoved(true);
                                                  openDeleteModal();
                                                }}
                                              >
                                                <img
                                                  src={removeConnection}
                                                  alt=""
                                                  className="connect-img"
                                                  style={{
                                                    paddingRight: "9px",
                                                    width: "25px",
                                                  }}
                                                />
                                                Remove
                                              </div>
                                            </li>
                                          ) : (
                                            <></>
                                          )}
                                          <li
                                            onClick={() => {
                                              navigator.clipboard.writeText(
                                                `${window.location.origin}/profile/${user_id}`
                                              );
                                              toast.success(
                                                "Profile URL is copied!",
                                                {
                                                  position:
                                                    toast.POSITION.BOTTOM_LEFT,
                                                }
                                              );
                                            }}
                                          >
                                            <img src={share} alt="" /> Share
                                            Profile
                                          </li>
                                          <li>
                                            <img src={down} alt="" />{" "}
                                            <span
                                              onClick={handlePdf}
                                              style={{ cursor: "pointer" }}
                                            >
                                              Save to PDF
                                            </span>
                                          </li>
                                          {user_id != user?.data?.id && (
                                            <li
                                              onClick={() =>
                                                setReportModal(true)
                                              }
                                            >
                                              <img src={reportIcon} alt="" />{" "}
                                              Report
                                            </li>
                                          )}
                                          {user_id != user?.data?.id && (
                                            <li onClick={() => blockUser()}>
                                              <img src={blockIcon} alt="" />{" "}
                                              Block
                                            </li>
                                          )}
                                          {user?.data?.membership?.name !=
                                            "Starter" && (
                                              <li onClick={() => addLead()}>
                                                <img src={leadIcon} alt="" /> Add
                                                as Lead
                                              </li>
                                            )}
                                        </ul>
                                      </div>
                                    )}
                                  </div>
                                </div>
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                      <div className="mt-26">
                        {activeSection === "Activity" ? (
                          <Activities
                            profileData={profileData}
                            deleteIntroVideo={deleteIntroVideo}
                            isAllowedToEdit={isAllowedToEdit}
                            suggested_people={suggested_people}
                            sendRequest={sendRequest}
                            addIntroModal={addIntroModal}
                            setAddIntroModal={setAddIntroModal}
                          />
                        ) : activeSection === "Recommendations" ? (
                          <Recomendation
                            profileData={profileData}
                            deleteIntroVideo={deleteIntroVideo}
                            isAllowedToEdit={isAllowedToEdit}
                            askRecommendation={askRecommendation}
                            setAskRecommendation={setAskRecommendation}
                            writeRecommendationModal={writeRecommendationModal}
                            setWriteRecommendationModal={
                              setWriteRecommendationModal
                            }
                            experienceData={newExperienceData}
                            giveRecommendation={giveRecommendation}
                            setGiveRecommendation={setGiveRecommendation}
                            isEditRecommend={isEditRecommend}
                            setIsEditRecommend={setIsEditRecommend}
                            writeTitle={writeTitle}
                            setWriteTitle={setWriteTitle}
                            memberView={memberView}
                            addIntroModal={addIntroModal}
                            setAddIntroModal={setAddIntroModal}
                          />
                        ) : activeSection === "Interests" ? (
                          <Interest
                            profileData={profileData}
                            deleteIntroVideo={deleteIntroVideo}
                            isAllowedToEdit={isAllowedToEdit}
                            addIntroModal={addIntroModal}
                            setAddIntroModal={setAddIntroModal}
                          />
                        ) : activeSection === "Social Media Channel" ? (
                          <SocialMedia
                            profileData={profileData}
                            deleteIntroVideo={deleteIntroVideo}
                            isAllowedToEdit={isAllowedToEdit}
                            addIntroModal={addIntroModal}
                            setAddIntroModal={setAddIntroModal}
                          />
                        ) : (
                          <div className="row">
                            <div className="col-lg-9">
                              <div className="edit-box">
                                <div className="profile-about p-30">
                                  <div
                                    className={
                                      "about" +
                                      (profileData?.about?.length > 1
                                        ? ""
                                        : " about-extra-space")
                                    }
                                  >
                                    <h3>About</h3>
                                    {isAllowedToEdit() && (
                                      <div
                                        className="edit"
                                        onClick={() => {
                                          setEditAbout(!editAbout);
                                        }}
                                      >
                                        <img src={EditIcon} />
                                      </div>
                                    )}
                                    <p>{profileData?.about}</p>
                                  </div>
                                  {/* <div className="line"></div> */}
                                  {profileData?.resume_url && (
                                    <>
                                      <div className="resume">
                                        <p>
                                          View Resume:{" "}
                                          <a
                                            href={profileData?.resume_url}
                                            target="_blank"
                                          >
                                            {profileData?.resume_name}
                                          </a>
                                        </p>
                                      </div>
                                      {/* <div className="line"></div> */}
                                    </>
                                  )}
                                  {isAllowedToEdit() && (
                                    <>
                                      <div className="flex">
                                        <div className="column">
                                          <div className="icon">
                                            <img src={view} alt="" />
                                          </div>
                                          <div className="content curserPointer">
                                            <h6
                                              className="text-dark"
                                              onClick={() => {
                                                navigate(
                                                  routeNames.PROFILE_VIEWS
                                                );
                                              }}
                                            >
                                              {profileData?.userProfileViews}{" "}
                                              profile views
                                            </h6>
                                            <p>
                                              Check who’s viewed your profile
                                            </p>
                                          </div>
                                        </div>
                                        <div className="column">
                                          <div className="icon">
                                            <img src={bar} alt="" />
                                          </div>
                                          <div
                                            className="content curserPointer"
                                            onClick={() => navigate("/posts")}
                                          >
                                            <h6 className="text-dark">
                                              {profileData?.userPostViews} post
                                              views
                                            </h6>
                                            <p>
                                              Check out who's engaging with your
                                              posts.
                                            </p>
                                          </div>
                                        </div>
                                        <div className="column">
                                          <div className="icon">
                                            <img src={ser} alt="" />
                                          </div>
                                          <div className="content curserPointer">
                                            <h6
                                              className="text-dark"
                                              onClick={() => {
                                                navigate(
                                                  routeNames.PROFILE_VIEWS,
                                                  {
                                                    state: {
                                                      searchAppearences: true,
                                                    },
                                                  }
                                                );
                                              }}
                                            >
                                              {profileData?.searchAppearences}{" "}
                                              search appearances
                                            </h6>
                                            <p>
                                              See how often you appear in search
                                              results
                                            </p>
                                          </div>
                                        </div>
                                      </div>
                                    </>
                                  )}
                                  <div className="line"></div>
                                  <div
                                    className="experience"
                                    style={{ marginRight: "5px" }}
                                  >
                                    <div className="flexs my-sm-4 my-2 ">
                                      <div className="heading">
                                        <h6>Experience</h6>
                                      </div>
                                      {isAllowedToEdit() && (
                                        <div className="add-expe">
                                          <div
                                            className="add"
                                            onClick={() => {
                                              setAddExpModal(!addExpModal);
                                            }}
                                          >
                                            <img
                                              src={plusIcon}
                                              className=""
                                              alt=""
                                            />
                                            {/* + Add Experience */}
                                          </div>
                                        </div>
                                      )}
                                    </div>
                                  </div>
                                  <div className="eperience-details">
                                    {isAllowedToEdit()
                                      ? newExperienceData.map((item, index) => (
                                        <div key={index}>
                                          <div className="flex">
                                            <div className="exper-pic">
                                              {
                                                <img
                                                  src={
                                                    Object.values(item)[0][0]
                                                      ?.Page
                                                      ? Object.values(
                                                        item
                                                      )[0][0].Page?.icon
                                                      : buld
                                                  }
                                                  alt=""
                                                />
                                              }

                                              {/* <img src={Object.values(item)[0][0]?.Page?Object.values(item)[0][0].Page?.icon:buld} alt="" />  */}
                                            </div>
                                            <div className="details w-80">
                                              <div style={{ display: "flex", columnGap: "10px" }}>
                                                <h6>{Object.keys(item)[0]}</h6>
                                                {item.screening_status == "Verified" ? <img src={exp_tb}></img> : <></>}
                                              </div>
                                              <p>
                                                {dateUtility(
                                                  Object.values(item)[0][0]
                                                    .start_date,
                                                  Object.values(item)[0][0]
                                                    .end_date
                                                )}
                                              </p>
                                            </div>
                                          </div>
                                          <div className="designation">
                                            <div className="hr-line"></div>
                                            {Object.values(item)[0].map(
                                              (item3, index, { length }) => (
                                                <div
                                                  className="right-align"
                                                  key={index}
                                                >
                                                  <div className="details w-80">
                                                    <span>
                                                      <strong>
                                                        {item3.title}
                                                      </strong>{" "}
                                                      {item3?.employment_type &&
                                                        `| ${item3?.employment_type}`}{" "}
                                                      {item3?.location &&
                                                        `| ${item3?.location}`}{" "}
                                                      {item3.screening_status == "Verified" ? <img style={{marginLeft:"10px"}} src={exp_tb}></img> : <></>}
                                                    </span>
                                                    {index + 1 === length ? (
                                                      <div className="">
                                                        {" "}
                                                      </div>
                                                    ) : (
                                                      <div className="hr"></div>
                                                    )}
                                                    {item3?.end_date ? (
                                                      <>
                                                        <span
                                                          style={{
                                                            display: "flex",
                                                          }}
                                                        >
                                                          <span>
                                                            {moment(
                                                              item3.start_date
                                                            )
                                                              .utc()
                                                              .format(
                                                                "MMM YYYY"
                                                              )}
                                                            -
                                                            {moment(
                                                              item3.end_date
                                                            )
                                                              .utc()
                                                              .format(
                                                                "MMM YYYY"
                                                              )}{" "}
                                                            &nbsp;
                                                          </span>
                                                          <span>
                                                            {dateUtility(
                                                              item3.start_date,
                                                              item3.end_date
                                                            )}
                                                          </span>
                                                        </span>{" "}
                                                      </>
                                                    ) : (
                                                      <>
                                                        <span
                                                          style={{
                                                            display: "flex",
                                                          }}
                                                        >
                                                          <span>
                                                            {moment(
                                                              item3.start_date
                                                            )
                                                              .utc()
                                                              .format(
                                                                "MMM YYYY"
                                                              )}
                                                            -Present &nbsp;
                                                          </span>
                                                          <span>
                                                            {dateUtility(
                                                              item3.start_date,
                                                              item3.end_date
                                                            )}
                                                          </span>
                                                        </span>{" "}
                                                      </>
                                                    )}

                                                    {isAllowedToEdit() && (
                                                      // !screeningList?.some(
                                                      //   (exp) =>
                                                      //     exp?.employment?.some(
                                                      //       (el) =>
                                                      //         el?.id ===
                                                      //         item3?.id
                                                      //     ) &&
                                                      //     exp?.backgroundStatus ===
                                                      //       "pending"
                                                      // )
                                                      // &&
                                                      <div
                                                        className="edit"
                                                        onClick={() => {
                                                          setAddExpModal(
                                                            !addExpModal
                                                          );
                                                          setEditExperienceData(
                                                            item3
                                                          );
                                                        }}
                                                      >
                                                        <img
                                                          src={EditIcon}
                                                          alt=""
                                                        />
                                                      </div>
                                                    )}
                                                  </div>
                                                  <div className="details">
                                                    <p>{item3.description}</p>
                                                  </div>
                                                  {item3?.medias?.map(
                                                    (itm, index) => {
                                                      return (
                                                        <div
                                                          className="details w-80"
                                                          key={index}
                                                        >
                                                          <div className="row show-media">
                                                            <div className="col-4 ms-3">
                                                              <img
                                                                alt="media"
                                                                src={
                                                                  itm?.image
                                                                }
                                                                style={{
                                                                  objectFit:
                                                                    "cover",
                                                                  width:
                                                                    "-webkit-fill-available",
                                                                }}
                                                              />
                                                            </div>
                                                          </div>
                                                        </div>
                                                      );
                                                    }
                                                  )}
                                                </div>
                                              )
                                            )}
                                          </div>
                                        </div>
                                      ))
                                      : newExperienceData.map((item, index) => (
                                        <div key={index}>
                                          {
                                            (Object.values(item)[0][0]?.Page
                                              ? Object.values(item)[0][0].Page
                                                ?.icon
                                              : buld,
                                              Object.values(item)[0][0]?.Page,
                                              "Object.values(item)[0][0]?.Page")
                                          }
                                          <div className="flex">
                                            <div className="exper-pic">
                                              {!item.is_visible ? (
                                                <img
                                                  src={confidential}
                                                  alt=""
                                                />
                                              ) : (
                                                <img
                                                  src={
                                                    Object.values(item)[0][0]
                                                      ?.Page
                                                      ? Object.values(
                                                        item
                                                      )[0][0].Page?.icon
                                                      : buld
                                                  }
                                                  alt=""
                                                />
                                              )}
                                            </div>
                                            <div className="details w-80">
                                              <h6>
                                                {Object.values(item)[1]
                                                  ? Object.keys(item)[0]
                                                  : "Confidential"}
                                              </h6>
                                              <p>
                                                {
                                                  // Object.values(item)[1]
                                                  //   ?
                                                  dateUtility(
                                                    Object.values(item)[0][0]
                                                      .start_date,
                                                    Object.values(item)[0][0]
                                                      .end_date
                                                  )
                                                  // : "Confidential"
                                                }
                                              </p>
                                            </div>
                                          </div>
                                          <div className="designation">
                                            <div className="hr-line"></div>
                                            {Object.values(item)[0].map(
                                              (item3, index, { length }) => (
                                                <div
                                                  className="right-align"
                                                  key={index}
                                                >
                                                  <div className="details w-80">
                                                    <span>
                                                      <strong>
                                                        {item3.is_visible ===
                                                          false &&
                                                          // ? "Confidential"
                                                          // :
                                                          item3.title}
                                                      </strong>{" "}
                                                      |{" "}
                                                      {item3.employment_type}{" "}
                                                      | {item3?.location}
                                                      {/* <span className="brief">
                                                          <img
                                                            src={emp}
                                                            style={{
                                                              width: "15px",
                                                            }}
                                                            alt=""
                                                            title=" Employment Verified"
                                                            role="tooltip"
                                                          />
                                                        </span> */}
                                                      {item3.screening_status == "Verified" ? <img src={exp_tb}></img> : <></>}
                                                    </span>
                                                    {index + 1 === length ? (
                                                      <div className="">
                                                        {" "}
                                                      </div>
                                                    ) : (
                                                      <div className="hr"></div>
                                                    )}
                                                    {item3?.end_date ? (
                                                      <>
                                                        <span
                                                          style={{
                                                            display: "flex",
                                                          }}
                                                        >
                                                          <span>
                                                            {moment(
                                                              item3.start_date
                                                            )
                                                              .utc()
                                                              .format(
                                                                "MMM YYYY"
                                                              )}
                                                            -
                                                            {moment(
                                                              item3.end_date
                                                            )
                                                              .utc()
                                                              .format(
                                                                "MMM YYYY"
                                                              )}{" "}
                                                            &nbsp;
                                                          </span>
                                                          <span>
                                                            {dateUtility(
                                                              item3.start_date,
                                                              item3.end_date
                                                            )}
                                                          </span>
                                                        </span>{" "}
                                                      </>
                                                    ) : (
                                                      <>
                                                        <span
                                                          style={{
                                                            display: "flex",
                                                          }}
                                                        >
                                                          <span>
                                                            {moment(
                                                              item3.start_date
                                                            )
                                                              .utc()
                                                              .format(
                                                                "MMM YYYY"
                                                              )}
                                                            -Present &nbsp;
                                                          </span>
                                                          <span>
                                                            {dateUtility(
                                                              item3.start_date,
                                                              item3.end_date
                                                            )}
                                                          </span>
                                                        </span>{" "}
                                                      </>
                                                    )}

                                                    {isAllowedToEdit() && (
                                                      <div
                                                        className="edit"
                                                        onClick={() => {
                                                          setAddExpModal(
                                                            !addExpModal
                                                          );
                                                          setEditExperienceData(
                                                            item3
                                                          );
                                                        }}
                                                      >
                                                        {/* <img src={EditIcon} alt="" /> */}
                                                        Edit
                                                      </div>
                                                    )}
                                                  </div>
                                                  <div className="details">
                                                    <p>{item3.description}</p>
                                                  </div>
                                                  <div className="details">
                                                    {item3?.medias?.map(
                                                      (itm, index) => {
                                                        return (
                                                          <div
                                                            className="row show-media"
                                                            key={index}
                                                          >
                                                            <div className="col-4 ms-3">
                                                              <img
                                                                alt="media"
                                                                src={
                                                                  itm?.image
                                                                }
                                                                style={{
                                                                  objectFit:
                                                                    "cover",
                                                                  width:
                                                                    "-webkit-fill-available",
                                                                }}
                                                              />
                                                            </div>
                                                          </div>
                                                        );
                                                      }
                                                    )}
                                                  </div>
                                                </div>
                                              )
                                            )}
                                          </div>
                                        </div>
                                      ))}
                                    <div className="line"></div>
                                    <div
                                      className="education"
                                      style={{ marginRight: "5px" }}
                                    >
                                      <div className="my-sm-4 my-2">
                                        <div className="heading">
                                          <h6>Education</h6>
                                          {isAllowedToEdit() && (
                                            <div
                                              className="add"
                                              onClick={() =>
                                                setEducationModal(
                                                  !educationModal
                                                )
                                              }
                                            >
                                              <img
                                                src={plusIcon}
                                                className=""
                                                alt=""
                                              />
                                            </div>
                                          )}
                                        </div>
                                      </div>
                                      {profileData?.educations?.map(
                                        (item, index) => (
                                          <div className="mr" key={index}>
                                            <div className="edu">
                                              <div className="degree-pic">
                                                <img
                                                  src={degre}
                                                  className="img-fluid image-fit"
                                                  alt=""
                                                />
                                              </div>
                                              <div className="details w-80">
                                                <div className="">
                                                  <div style={{ display: "flex", columnGap: "10px" }}>
                                                    <h6>
                                                      {item.school_name}
                                                    </h6>
                                                    {item.screening_status == "Verified" ? <img src={edu_tb}></img> : <></>}
                                                  </div>
                                                  <span>
                                                    {item.degree}
                                                    <br />
                                                    {item.start_date
                                                      ? moment(item.start_date)
                                                        .utc()
                                                        .format("YYYY")
                                                      : ""}

                                                    {item.end_date
                                                      ? -moment(item.end_date)
                                                        .utc()
                                                        .format("YYYY")
                                                      : ""}
                                                  </span>
                                                </div>
                                              </div>
                                              {isAllowedToEdit() && (
                                                // !screeningList?.some(
                                                //   (exp) =>
                                                //     exp?.education?.some(
                                                //       (el) =>
                                                //         el?.id === item?.id
                                                //     ) &&
                                                //     exp?.backgroundStatus ===
                                                //       "pending"
                                                // ) &&
                                                <div
                                                  className="edit"
                                                  onClick={() => {
                                                    setEducationModal(
                                                      !educationModal
                                                    );
                                                    setEditEducationData(item);
                                                  }}
                                                >
                                                  <img
                                                    src={EditIcon}
                                                    alt=""
                                                    style={{
                                                      marginRight: "-5px",
                                                    }}
                                                  />
                                                </div>
                                              )}
                                            </div>
                                            <p>{item.description}</p>

                                            <div className="details">
                                              {item?.medias?.map(
                                                (itm, index) => {
                                                  return (
                                                    <div
                                                      className="row show-media"
                                                      key={index}
                                                    >
                                                      <div className="col-4 ms-3">
                                                        <img
                                                          alt="media"
                                                          src={itm?.image}
                                                          style={{
                                                            objectFit: "cover",
                                                            width:
                                                              "-webkit-fill-available",
                                                          }}
                                                        />
                                                      </div>
                                                    </div>
                                                  );
                                                }
                                              )}
                                            </div>
                                          </div>
                                        )
                                      )}
                                    </div>
                                    <div className="line"></div>
                                    <div
                                      className="honors my-sm-4 my-2"
                                      style={{ marginRight: "5px" }}
                                    >
                                      <div className="heading">
                                        <h6>Honors & Awards</h6>
                                        {isAllowedToEdit() && (
                                          <div
                                            className="add"
                                            onClick={() =>
                                              setAwardsHonorsModal(
                                                !awardsHonorsModal
                                              )
                                            }
                                          >
                                            <img
                                              src={plusIcon}
                                              className=""
                                              alt=""
                                            />
                                            {/* + Add Honors & Awards */}
                                          </div>
                                        )}
                                      </div>
                                      {profileData?.honors_and_awards?.map(
                                        (item, index, { length }) => (
                                          <>
                                            <div
                                              className="detai mt-30"
                                              key={item.id}
                                            >
                                              <div className="colum mb-20 mt-20">
                                                <div className="w-80">
                                                  <h6 className="dark-blue-color">
                                                    {item?.name}
                                                  </h6>
                                                  {item?.Industry?.length >
                                                    0 && (
                                                      <span>
                                                        {item?.Industry[0]?.name}
                                                      </span>
                                                    )}
                                                  <span>{item?.year}</span>
                                                </div>
                                                {isAllowedToEdit() && (
                                                  <div
                                                    style={{ display: "flex" }}
                                                  >
                                                    <div
                                                      className="edit"
                                                      onClick={() => {
                                                        setAwardsHonorsModal(
                                                          !awardsHonorsModal
                                                        );
                                                        setEditHonorsAwardsData(
                                                          item
                                                        );
                                                      }}
                                                    >
                                                      <img
                                                        src={EditIcon}
                                                        alt=""
                                                        style={{
                                                          marginRight: "-5px",
                                                        }}
                                                      />
                                                    </div>
                                                    {/* <div className="delete">
                                                        <img
                                                          src={del}
                                                          alt=""
                                                     
                                                        />
                                                      </div> */}
                                                  </div>
                                                )}
                                              </div>
                                            </div>
                                            {index + 1 !== length ? (
                                              <div className="line"></div>
                                            ) : (
                                              <></>
                                            )}
                                          </>
                                        )
                                      )}
                                    </div>
                                    <div className="line"></div>
                                    <div className="skills my-sm-4 my-2">
                                      <div className="heading">
                                        <h6>Skills</h6>
                                        {isAllowedToEdit() && (
                                          <div
                                            className="add"
                                            onClick={() => {
                                              setAddSkillModal(!addSkillModal);
                                            }}
                                          >
                                            {/* + Add Skill */}
                                            <img src={plusIcon} alt="" />
                                          </div>
                                        )}
                                      </div>
                                      {profileData?.exps_and_skills?.map(
                                        (item, index, { length }) => (
                                          <>
                                            {index < showAllSkills ? (
                                              <>
                                                <div
                                                  className="develop"
                                                  key={item.id}
                                                >
                                                  <div className="w-80">
                                                    <span>{item.skills}</span>
                                                  </div>

                                                  <div className="right-align">
                                                    {isAllowedToEdit() && (
                                                      <>
                                                        <div
                                                          style={{
                                                            display: "flex",
                                                            // marginRight:
                                                            //   "-25px",
                                                          }}
                                                        >
                                                          <div
                                                            onClick={() => {
                                                              setAddSkillModal(
                                                                !addSkillModal
                                                              );
                                                              setEditSkillsData(
                                                                item
                                                              );
                                                            }}
                                                          >
                                                            <img
                                                              src={EditIcon}
                                                              alt=""
                                                            />
                                                            {/* Edit */}
                                                          </div>
                                                          <div
                                                            style={{
                                                              marginLeft:
                                                                "10px",
                                                            }}
                                                          >
                                                            <img
                                                              src={del}
                                                              alt=""
                                                              onClick={() => {
                                                                // deleteSkill(item)
                                                                setCloseModal(
                                                                  !closeModal
                                                                );
                                                                setDeleteItem(
                                                                  item
                                                                );
                                                              }}
                                                            />
                                                          </div>
                                                        </div>
                                                      </>
                                                    )}
                                                  </div>
                                                </div>
                                                {index + 1 !== length ? (
                                                  <div className="line"></div>
                                                ) : (
                                                  <></>
                                                )}
                                              </>
                                            ) : (
                                              ""
                                            )}
                                          </>
                                        )
                                      )}
                                      {profileData?.exps_and_skills?.length >
                                        1 && (
                                          <div className="text-center">
                                            {showAllSkills === 10 ? (
                                              <span
                                                className="show"
                                                onClick={() => {
                                                  setShowAllSkills(1);
                                                }}
                                              >
                                                Show less skills
                                              </span>
                                            ) : (
                                              <span
                                                className="show"
                                                onClick={() => {
                                                  setShowAllSkills(10);
                                                }}
                                              >
                                                Show all skills
                                              </span>
                                            )}
                                          </div>
                                        )}
                                    </div>
                                    <div className="line"></div>
                                    <div className="license my-sm-4 my-2">
                                      <div className="heading">
                                        <h6>Licenses & Certifications</h6>
                                        {isAllowedToEdit() && (
                                          <div
                                            className="add"
                                            onClick={() =>
                                              setLicenseCertificateModal(
                                                !licenseCertificateModal
                                              )
                                            }
                                          >
                                            {/* + Add Licenses & Certificate */}
                                            <img
                                              src={plusIcon}
                                              className=""
                                              alt=""
                                            />
                                          </div>
                                        )}
                                      </div>
                                      {profileData?.license_certifications?.map(
                                        (item, index, { length }) => (
                                          <>
                                            <div
                                              className="details mb-20 mt-20"
                                              key={item.id}
                                            >
                                              <div className="w-80">
                                                <h6>{item.name}</h6>
                                                <span>{item.issuing_org}</span>
                                                <span>
                                                  {item.issuing_org_id}
                                                </span>
                                                <span>
                                                  Issued{" "}
                                                  {moment(item.start_date)
                                                    .utc()
                                                    .format("MMMM YYYY")}{" "}
                                                </span>
                                              </div>
                                              {isAllowedToEdit() && (
                                                <div
                                                  className="edit"
                                                  onClick={() => {
                                                    setLicenseCertificateModal(
                                                      !licenseCertificateModal
                                                    );
                                                    setEditLicenseData(item);
                                                  }}
                                                >
                                                  <img
                                                    src={EditIcon}
                                                    alt=""
                                                    style={{
                                                      marginRight: "-5px",
                                                    }}
                                                  />
                                                </div>
                                              )}
                                            </div>
                                            {index + 1 !== length ? (
                                              <div className="line"></div>
                                            ) : (
                                              <></>
                                            )}
                                          </>
                                        )
                                      )}
                                    </div>
                                    <div className="line"></div>
                                    <div className="language my-sm-4 my-2">
                                      <div className="heading">
                                        <h6>Languages</h6>
                                        {isAllowedToEdit() && (
                                          <div
                                            className="add"
                                            onClick={() =>
                                              setLanguageModal(!languageModal)
                                            }
                                          >
                                            {/* + Add Language */}
                                            <img
                                              src={plusIcon}
                                              className=""
                                              alt=""
                                            />
                                          </div>
                                        )}
                                      </div>
                                      {profileData?.languages?.map(
                                        (item, index, { length }) => (
                                          <>
                                            <div
                                              className="details"
                                              key={item.id}
                                            >
                                              <div className="w-80">
                                                <h6 className="dark-blue-color">
                                                  {item.name}
                                                </h6>
                                                <span>
                                                  {item.proficiency} working
                                                  proficiency
                                                </span>
                                              </div>
                                              {isAllowedToEdit() && (
                                                <div
                                                  className="edit"
                                                  onClick={() => {
                                                    setLanguageModal(
                                                      !languageModal
                                                    );
                                                    setEditLanguageData(item);
                                                  }}
                                                >
                                                  <img
                                                    src={EditIcon}
                                                    alt=""
                                                    style={{
                                                      marginRight: "-5px",
                                                    }}
                                                  />
                                                </div>
                                              )}
                                            </div>
                                            {index + 1 !== length ? (
                                              <div className="line"></div>
                                            ) : (
                                              <></>
                                            )}
                                          </>
                                        )
                                      )}
                                    </div>
                                    <div className="line"></div>
                                    <div className="course my-sm-4 my-2">
                                      <div className="heading">
                                        <h6>Courses</h6>
                                        {isAllowedToEdit() && (
                                          <div
                                            className="add"
                                            onClick={() =>
                                              setCourseModal(!courseModal)
                                            }
                                          >
                                            {/* + Add Course */}
                                            <img
                                              src={plusIcon}
                                              className=""
                                              alt=""
                                            />
                                          </div>
                                        )}
                                      </div>
                                      {profileData?.courses?.map(
                                        (item, index, { length }) => (
                                          <>
                                            <div
                                              className="details"
                                              key={item.id}
                                            >
                                              <div className="w-80">
                                                <h6 className="dark-blue-color">
                                                  {item.name}
                                                </h6>
                                                <span>
                                                  {
                                                    item?.association
                                                      ?.company_name
                                                  }
                                                </span>
                                              </div>
                                              {isAllowedToEdit() && (
                                                <div
                                                  className="edit"
                                                  onClick={() => {
                                                    setCourseModal(
                                                      !courseModal
                                                    );
                                                    setEditCourseData(item);
                                                  }}
                                                >
                                                  <img
                                                    src={EditIcon}
                                                    alt=""
                                                    style={{
                                                      marginRight: "-5px",
                                                    }}
                                                  />
                                                </div>
                                              )}
                                            </div>
                                            {index + 1 !== length ? (
                                              <div className="line"></div>
                                            ) : (
                                              <></>
                                            )}
                                          </>
                                        )
                                      )}
                                    </div>
                                    <div className="line"></div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="col-lg-3">
                              {/* <div className="mb-20"><Ad /></div> */}
                              {
                                <div
                                  className="mb-20"
                                  style={{
                                    boxShadow:
                                      "0px 0px 0px 1px rgba(0, 0, 0, .1)",
                                  }}
                                >
                                  <VideoSidebar
                                    URLData={profileData?.intro_video}
                                    deleteIntroVideo={deleteIntroVideo}
                                    isAllowedToEdit={isAllowedToEdit}
                                    addIntroModal={addIntroModal}
                                    setAddIntroModal={setAddIntroModal}
                                  />
                                </div>
                              }
                              {isAllowedToEdit() && (
                                <div
                                  style={{
                                    boxShadow:
                                      "0px 0px 0px 1px rgba(0, 0, 0, .1)",
                                  }}
                                >
                                  <Sidebar
                                    profileData={profileData?.worker}
                                    isSeekingBadgeModal={
                                      isSeekingBadgeModalOpen
                                    }
                                  />
                                </div>
                              )}

                              {isAllowedToEdit() && (
                                <div
                                  style={{
                                    boxShadow:
                                      "0px 0px 0px 1px rgba(0, 0, 0, .1)",
                                  }}
                                >
                                  <LinkSidebar
                                    profileURL={profileData?.profile_url}
                                    customProfileModal={customProfileModal}
                                    setCustomProfileModal={
                                      setCustomProfileModal
                                    }
                                  />
                                </div>
                              )}
                              {!isAllowedToEdit() && (
                                <div
                                  style={{
                                    boxShadow:
                                      "0px 0px 0px 1px rgba(0, 0, 0, .1)",
                                  }}
                                >
                                  <ArticlesFeatured
                                    articles_list={my_articles}
                                  />
                                </div>
                              )}
                              {!isAllowedToEdit() && (
                                <div
                                  style={{
                                    boxShadow:
                                      "0px 0px 0px 1px rgba(0, 0, 0, .1)",
                                  }}
                                >
                                  <PostsFeatured posts_list={public_post} />
                                </div>
                              )}
                              <div className="mb-20">
                                <div
                                  style={{
                                    boxShadow:
                                      "0px 0px 0px 1px rgba(0, 0, 0, .1)",
                                  }}
                                >
                                  <PeopleYouMayKnow
                                    suggested_people={suggested_people}
                                    sendRequest={sendRequest}
                                  />
                                </div>
                              </div>
                              <div
                                className="sidebar-sticky"
                                style={{
                                  boxShadow:
                                    "0px 0px 0px 1px rgba(0, 0, 0, .1)",
                                }}
                              >
                                <SidebarFooter />
                              </div>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}

          <Chat />
        </div>
        {editmodal && (
          <EditProfileModal
            firstName={profileData?.first_name}
            modal={editmodal}
            lastName={profileData?.last_name}
            profileHeadline={profileData?.profile_headline}
            designation={profileData?.designation}
            toggle={() => setEditmodal(!editmodal)}
            addContactInfoModal={addContactInfoModal}
            setAddContactInfoModal={setAddContactInfoModal}
            educationModal={educationModal}
            setEducationModal={setEducationModal}
            educationData={profileData?.educations}
            setEditEducationData={setEditEducationData}
            addExpModal={addExpModal}
            setAddExpModal={setAddExpModal}
            experienceData={newExperienceData}
            setEditExperienceData={setEditExperienceData}
            highestEducation={profileData?.highestEducation}
            highestExperience={profileData?.highestExperience}
            profileImage={profileData?.profile_img}
            backgroundImage={profileData?.background_img}
            backgroundPhotoModal={backgroundPhotoModal}
            setBackgroundPhotoModal={setBackgroundPhotoModal}
            handleBannerPicture={handleBannerPicture}
            profilePhotoModal={profilePhotoModal}
            setProfilePhotoModal={setProfilePhotoModal}
          />
        )}

        {editAbout && (
          <EditAbout
            toggle={() => {
              setEditAbout(!editAbout);
            }}
            modal={editAbout}
            aboutData={profileData?.about}
            title="Edit About"
          />
        )}

        {reportModal && (
          <ReportModal
            modal={reportModal}
            toggle={() => setReportModal(!reportModal)}
            reportType={"profile"} //"post"/"comment"
            reportTypeId={user_id} //null in case of post
          />
        )}

        {/* <ImageModal
        modal={imageModal}
        toggle={imageToggle}
        croppedImage={croppedImage}
        setCroppedImage={setCroppedImage}
      /> */}

        {addExpModal && (
          <AddExperModal
            modal={addExpModal}
            toggle={() => setAddExpModal(!addExpModal)}
            editExperienceData={editExperienceData}
            setEditExperienceData={setEditExperienceData}
            isAllowedToEdit={isAllowedToEdit}
            isInBackgroundProcess={screeningList?.some(
              (exp) =>
                exp?.employment?.some(
                  (el) => el?.id === editExperienceData?.id
                ) && exp?.backgroundStatus === "pending"
            )}
          />
        )}

        {educationModal && (
          <AddEducationModal
            modal={educationModal}
            toggle={() => setEducationModal(!educationModal)}
            title="Add Education"
            editEducationData={editEducationData}
            setEditEducationData={setEditEducationData}
            isAllowedToEdit={isAllowedToEdit}
            isInBackgroundProcess={screeningList?.some(
              (exp) =>
                exp?.education?.some(
                  (el) => el?.id === editEducationData?.id
                ) && exp?.backgroundStatus === "pending"
            )}
          />
        )}

        {addSkillModal && (
          <AddSkillModal
            modal={addSkillModal}
            toggle={() => setAddSkillModal(!addSkillModal)}
            editSkillsData={editSkillsData}
            setEditSkillsData={setEditSkillsData}
            educationData={profileData?.educations}
            experienceData={newExperienceData}
          />
        )}

        {courseModal && (
          <AddCourseModal
            modal={courseModal}
            toggle={() => setCourseModal(!courseModal)}
            editCourseData={editCourseData}
            setEditCourseData={setEditCourseData}
            experiences={newExperienceData}
          />
        )}

        {languageModal && (
          <AddLanguageModal
            modal={languageModal}
            toggle={() => setLanguageModal(!languageModal)}
            editLanguageData={editLanguageData}
            setEditLanguageData={setEditLanguageData}
          />
        )}
        {verificationModal && (
          <VerificationModal
            modal={verificationModal}
            toggle={() => setVerificationModal(!verificationModal)}
            email={profileData?.email}
            isVerfied={profileData?.is_email_verified}
          />
        )}

        {licenseCertificateModal && (
          <AddLicenseCertificate
            modal={licenseCertificateModal}
            toggle={() => setLicenseCertificateModal(!licenseCertificateModal)}
            editLicenseData={editLicenseData}
            setEditLicenseData={setEditLicenseData}
          />
        )}

        {awardsHonorsModal && (
          <AddHonorsAwards
            modal={awardsHonorsModal}
            toggle={() => setAwardsHonorsModal(!awardsHonorsModal)}
            editHonorsAwardsData={editHonorsAwardsData}
            setEditHonorsAwardsData={setEditHonorsAwardsData}
          />
        )}

        {giveRecommendation && (
          <GiveRecommendationModal
            modal={giveRecommendation}
            toggle={() => setGiveRecommendation(!giveRecommendation)}
            experienceData={newExperienceData}
            title="Give a recommendation"
            writeRecommendationModal={writeRecommendationModal}
            setWriteRecommendationModal={setWriteRecommendationModal}
            writeTitle={writeTitle}
            setWriteTitle={setWriteTitle}
            user={user}
          />
        )}

        {addIntroModal && (
          <AddIntroVideo
            modal={addIntroModal}
            toggle={() => setAddIntroModal(!addIntroModal)}
          />
        )}

        {addResumeModal && (
          <AddResumeModal
            modal={addResumeModal}
            toggle={() => setAddResumeModal(!addResumeModal)}
          />
        )}

        {sectionModal && (
          <AddSection
            modal={sectionModal}
            toggle={() => setSectionModal(!sectionModal)}
            title="Add a section to Profile"
            editAbout={editAbout}
            setEditAbout={setEditAbout}
            educationModal={educationModal}
            setEducationModal={setEducationModal}
            addExpModal={addExpModal}
            setAddExpModal={setAddExpModal}
            addSkillModal={addSkillModal}
            setAddSkillModal={setAddSkillModal}
            languageModal={languageModal}
            setLanguageModal={setLanguageModal}
            courseModal={courseModal}
            setCourseModal={setCourseModal}
            licenseCertificateModal={licenseCertificateModal}
            setLicenseCertificateModal={setLicenseCertificateModal}
            awardsHonorsModal={awardsHonorsModal}
            setAwardsHonorsModal={setAwardsHonorsModal}
            giveRecommendation={giveRecommendation}
            setGiveRecommendation={setGiveRecommendation}
            addIntroModal={addIntroModal}
            setAddIntroModal={setAddIntroModal}
            addResumeModal={addResumeModal}
            setAddResumeModal={setAddResumeModal}
          />
        )}

        {contactinfoModal && (
          <Contactinfo
            modal={contactinfoModal}
            toggle={() => setContactinfoModal(!contactinfoModal)}
            title="Contact Info"
            firstName={profileData?.first_name}
            lastName={profileData?.last_name}
            address={profileData?.address}
            dob={profileData?.dob}
            phone={profileData?.phone}
            email={profileData?.email}
            profileURL={profileData?.profile_url}
            addContactInfoModal={addContactInfoModal}
            setAddContactInfoModal={setAddContactInfoModal}
            isAllowedToEdit={isAllowedToEdit()}
          />
        )}

        {addContactInfoModal && (
          <AddContactInfoModal
            modal={addContactInfoModal}
            toggle={() => {
              setAddContactInfoModal(!addContactInfoModal);
            }}
            title="Edit Contact Info"
            country={profileData?.country}
            countryCodeValue={profileData?.country?.code}
            state={profileData?.state?.id}
            city={profileData?.city?.id}
            location={profileData?.location}
            pincode={profileData?.pin_code}
            phone={profileData?.phone}
            address={profileData?.address}
            dob={profileData?.dob}
            nationality={profileData?.nationality}
            email={profileData?.email}
            website={profileData?.website}
            profileUrl={profileData?.profile_url}
            customProfileModal={customProfileModal}
            setCustomProfileModal={setCustomProfileModal}
          />
        )}

        {profilePhotoModal && (
          <ProfilePhotoModal
            modal={profilePhotoModal}
            toggle={() => setProfilePhotoModal(!profilePhotoModal)}
            title="Profile Photo"
            editProfileImage={profileData?.profile_img}
            handleBannerPicture={handleBannerPicture}
            handleRemovePicture={handleRemovePicture}
          // setBannerModal={setBannerModal}
          />
        )}

        {backgroundPhotoModal && (
          <BackgroundPhotoModal
            modal={backgroundPhotoModal}
            toggle={() => setBackgroundPhotoModal(!backgroundPhotoModal)}
            title="Background Photo"
            editBackgroundImage={profileData?.background_img}
            handleBannerPicture={handleBannerPicture}
            // setBannerModal={setBannerModal}
            handleRemovePicture={handleRemovePicture}
          />
        )}

        {creatorModeModal && (
          <CreatorModeModal
            modal={creatorModeModal}
            toggle={() => setCreatorModeModal(!creatorModeModal)}
            title="Enable creator mode"
          />
        )}

        {closeModal && (
          <DeleteModal
            modal={DeleteModal}
            toggle={() => setCloseModal(!closeModal)}
            title="Delete Confirmation"
            deleteConfirm={() => {
              deleteSkill(deleteItem);
            }}
            text="Are you want to sure to delete this?"
          />
        )}

        {showDeleteModal && (
          <DeleteModal
            modal={DeleteModal}
            toggle={closeDeleteModal}
            title={
              isRemoved == true
                ? "Remove Confirmation"
                : "Unfollow Confirmation"
            }
            deleteConfirm={() => {
              isRemoved == true
                ? peopleRemoveConnection()
                : create_unfollower();

              // Call the unfollow function
              closeDeleteModal();
              setIsRemoved(false);
            }}
            text={
              isRemoved == true
                ? "Are you sure you want to Remove?"
                : "Are you sure you want to unfollow?"
            }
          />
        )}

        {mutualConnectionModal && (
          <MutualConnectionModal
            modal={mutualConnectionModal}
            toggle={() => setMutualConnectionModal(!mutualConnectionModal)}
            listData={profileData}
          />
        )}
        {npMailModal && (
          <NpMailModal
            modal={npMailModal}
            toggle={() => setNpMailModal(!npMailModal)}
            profileData={profileData}
          //   profileData={{
          //   id: selectedUser?.user?.id,
          //   first_name: selectedUser?.user?.first_name,
          //   last_name: selectedUser?.user?.last_name,
          //   profile_img: selectedUser?.user?.profile_img,
          //   profile_headline: selectedUser?.user?.profile?.profile_headline,
          // }}
          />
        )}
        <ToastContainer />
      </div>
    </>
  );
}

Profile.defaultProps = {
  isRecommendationTabActive: false,
};
