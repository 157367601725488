import React, { useEffect, useState } from "react";
import Header from "../../core/components/layout/Header/Header";
import Loginfooter from "../../core/components/layout/footer/LoginFooter";
import logo from "../../assets/img/np-logo.svg";
import about from "../../assets/img/about.png";
import mission from "../../assets/img/mission.jpg";
import vission from "../../assets/img/vission.jpg";
import BeginningSlider from "./slider";

export default function About(props) {
  const { data } = props;
  const [header, setHeader] = useState(null);
  const [mission, setMission] = useState(null);
  const [vission, setVission] = useState(null);
  const [stat, setStat] = useState(null);
  const [timeSlider, setTimeSlider] = useState(null);

  useEffect(() => {
    if (data?.body) {
      setHeader({
        header1: data?.body?.filter((item) => item.key == "about-heading-1")[0],
        header2: data?.body?.filter((item) => item.key == "about-heading-2")[0],
        header3: data?.body?.filter((item) => item.key == "about-heading-3")[0],
        header4: data?.body?.filter((item) => item.key == "about-heading-4")[0],
      });
      setMission(data?.body?.filter((item) => item.key == "about-mission")[0]);
      setVission(data?.body?.filter((item) => item.key == "about-vision")[0]);
      setStat(data?.body?.filter((item) => item.key == "about-stat"));
      setTimeSlider(data?.body?.filter((item) => item.key == "about-tl"));
    }
  }, [data]);
  return (
    <div>
      <Header />
      <div className="about">
        <div className="bg">
          <div className="container">
            <div className="row">
              <div className="col-sm-8 margin-top">
                <h1>{data?.title}</h1>
                <p style={{ marginTop: "30px" }}>{data?.subtitle} </p>
              </div>
              <div className="col-sm-4">
                <img src={about} alt="" className="bg-image" />
              </div>
            </div>
          </div>
          <div className="n-logo">
            <img src={logo} alt="" width="100%" />
          </div>
        </div>
      </div>
      <div className="context">
        <section className="background OurJourneyBg">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                {/* {data?.body?.length > 0 && data?.body[0]?.body ? (
                  <div className="content">
                    <p
                      dangerouslySetInnerHTML={{
                        __html: data?.body[0]?.body,
                      }}
                    />
                  </div>
                ) : (
                  <>
                    <p>NoblePage is loading...</p>
                  </>
                )} */}

                {/* {data?.body?.map((item, index) => {
                  return (
                    <div className="text  my-5" key={index}>
                      <h6>{item.title}</h6>
                      <p>{item.body}</p>
                    </div>
                  );
                })} */}

                <h2 className="section-heading mt-md-0 mt-4">
                  {header?.header1?.title}
                </h2>
                <div className="row gx-lg-5 gx-md-4 gx-3">
                  <div
                    style={{
                      columnCount: 2,
                      wordBreak: "break-word",
                      whiteSpace: "pre-wrap",
                    }}
                  >
                    <p>{header?.header1?.body}</p>
                  </div>
                  {/* <div className="col-md-6 col-12">
                    <p>
                      A community where everyone, regardless of background,
                      ethnicity, or gender, is welcomed with open arms. A
                      community where skills shine brighter than stereotypes. A
                      community where dreams are not just encouraged but
                      nurtured.
                    </p>
                    <p>
                      At NobelPage, we're not just about networking; we're about
                      changing lives. Every connection made, every opportunity
                      unlocked, is a step closer to our vision of a world where
                      meritocracy reigns supreme.
                    </p>
                    <p>
                      Join us on this journey of empowerment and transformation.
                      Together, let's rewrite the narrative of career
                      opportunities, one connection at a time. NobelPage: Where
                      fairness meets opportunity, and dreams become reality.
                    </p>
                  </div> */}
                </div>
                <div className="accomplished">
                  <div className="row align-items-center gx-lg-5 gx-md-4 gx-3">
                    <div className="col-lg-6 col-md-12 col-12">
                      <h2 className="section-heading w-75 text-start mb-lg-4 mb-3">
                        {header?.header2?.title}
                      </h2>
                      <p>{header?.header2?.body}</p>
                    </div>
                    <div className="col-lg-6 col-md-12 col-12">
                      <div className="accomplished-list mt-lg-0 mt-4">
                        <ul className="list-unstyled">
                          {stat?.map((ele) => {
                            return (
                              <>
                                <li>
                                  <h2 className="section-heading text-start">
                                    {ele?.title}{" "}
                                  </h2>
                                  <p>{ele?.body}</p>
                                </li>
                              </>
                            );
                          })}
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="missionVission">
          <div className="container">
            <div className="row">
              <div className="col-12">
                <h2 className="section-heading">{header?.header3?.title}</h2>
              </div>
            </div>
            <div className="row align-items-center mb-lg-5 mb-4  gx-lg-5 gx-md-4 gx-3">
              <div className="col-lg-8 col-md-7 col-12 order-md-0 order-1">
                <h5 className="sub-heading">{mission?.title}</h5>
                <p>{mission?.body}</p>
              </div>
              <div className="col-lg-4 col-md-5 col-12 order-md-1 order-0 mb-md-0 mb-3">
                <img src={mission?.media} alt="mission" className="img-fluid" />
              </div>
            </div>
            <div className="row align-items-center  gx-lg-5 gx-md-4 gx-3">
              <div className="col-lg-4 col-md-5 col-12  mb-md-0 mb-3">
                <img src={vission?.media} alt="vission" className="img-fluid" />
              </div>
              <div className="col-lg-8 col-md-7 col-12  order-0">
                <h5 className="sub-heading">{vission?.title}</h5>
                <p>{vission?.body}</p>
              </div>
            </div>
          </div>
        </section>
        <section className="nobelPageTimeLineBox">
          <div className="container">
            <div className="row">
              <div className="col-xl-4 col-lg-5 col-md-6 col-12">
                <h2 className="section-heading text-start ">
                  {header?.header4?.title}
                </h2>
              </div>
            </div>
            <div className="nobelPageTimeLine">
              <BeginningSlider data={timeSlider} />
            </div>
          </div>
        </section>
      </div>
      <div className="line mt-3"></div>
      <Loginfooter />
    </div>
  );
}
