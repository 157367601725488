import React, { useEffect, useState } from "react";
import Peoples from "./Peoples";
import { useDispatch, useSelector } from "react-redux";
import { getpeopleList } from "../../../store/people/peopleActions";
import { getSuggestedPeopleList } from "../../../store/people/peopleActions";
import { acceptConnectionRequest } from "../../../store/people/peopleActions";
import { sendConnectionRequest } from "../../../store/connections/connectionsActions";
import { useNavigate } from "react-router-dom";

export default function PeoplesContainer() {
  const { suggested_people } = useSelector((state) => state.people);
  const { my_connections } = useSelector((state) => state.people);
  const { pending_invitations } = useSelector((state) => state.people);
  const { isPageLoading } = useSelector((state) => state.loader);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [tabs, setTabs] = useState("suggested_people");
  const [searchKey, setSearchKey] = useState("");
  const [currentCount, setCurrentCount] = useState(0);
  const [totalCount, setTotalCount] = useState(0);
  const [searchVal, setSearchVal] = useState(0);
  const [mutualConnectionModal, setMutualConnectionModal] = useState(false);
  const [userMutualData, setUserMutualData] = useState("");
  const [ignoreModal, setIgnoreModal] = useState(false);
  const [ignoreId, setIgnoreId] = useState(null);

  useEffect(() => {
    dispatch(getpeopleList("pending"));
  }, []);

  useEffect(() => {
    if (tabs === "suggested_people") {
      if (searchKey === "") {
        dispatch(
          getSuggestedPeopleList({
            type: "suggested",
            limit: 10,
            skip: currentCount,
          })
        ).then((res) => {
          setTotalCount(res?.totalItems);
        });
      } else {
        dispatch(
          getSuggestedPeopleList({
            type: "suggested",
            search: searchKey,
            limit: 20,
            skip: currentCount,
          })
        ).then((res) => {
          setTotalCount(res?.totalItems);
        });
      }
    } else if (tabs === "my_connections") {
      if (searchKey === "") {
        dispatch(
          getpeopleList("confirm", null, { limit: 20, skip: currentCount })
        ).then((res) => {
          setTotalCount(res?.totalItems);
        });
      } else {
        dispatch(
          getpeopleList("confirm", null, {
            search: searchKey,
            limit: 20,
            skip: currentCount,
          })
        ).then((res) => {
          setTotalCount(res?.totalItems);
        });
      }
    } else if (tabs === "pending_invitations") {
      let status = "pending";
      let type = "received";
      dispatch(
        getpeopleList(status, type, { status: "pending", type: "receive" })
      );
    }
  }, [tabs, currentCount, searchVal]);

  const acceptRequest = (id, request_type) => {
    dispatch(acceptConnectionRequest(id, request_type));
  };

  const sendRequest = (id) => {
    dispatch(sendConnectionRequest(id));
  };

  const searchPeople = (searchKey) => {
    setCurrentCount(0);
    setSearchVal(!searchVal);
  };

  const loadMoreData = () => {
    setTimeout(() => {
      setCurrentCount(currentCount + 20);
    }, 2000);
  };

  useEffect(() => {
    setCurrentCount(0);
  }, [tabs]);

  return (
    <Peoples
      suggested_people={suggested_people}
      my_connections={my_connections}
      pending_invitations={pending_invitations}
      acceptRequest={acceptRequest}
      tabs={tabs}
      setTabs={setTabs}
      isPageLoading={isPageLoading}
      sendRequest={sendRequest}
      navigate={navigate}
      searchPeople={searchPeople}
      searchKey={searchKey}
      setSearchKey={setSearchKey}
      currentCount={currentCount}
      loadMoreData={loadMoreData}
      totalCount={totalCount}
      mutualConnectionModal={mutualConnectionModal}
      setMutualConnectionModal={setMutualConnectionModal}
      userMutualData={userMutualData}
      setUserMutualData={setUserMutualData}
      ignoreModal={ignoreModal}
      setIgnoreModal={setIgnoreModal}
      ignoreId={ignoreId}
      setIgnoreId={setIgnoreId}
    />
  );
}
