import React, { useState, useEffect } from "react";
import {
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
} from "reactstrap";

import NotifyToggle from "../../../../core/components/modal/NotifyToggle";
import { call } from "../../../../api/apiCall";
import { useDispatch, useSelector } from "react-redux";
import { addeducationdetails } from "../../../../store/profile/profileActions";
import degre from "../../../../assets/img/circle-degree.svg";
import moment from "moment";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import DatePickerCustom from "../../../../core/components/DatePicker/DatePickerReact";
import Dropdown from "../../../../core/components/DropDown/Dropdown";
import AddMediaExper from "./AddMediaExper";
import { toast } from "react-toastify";
import {
  initLoad,
  initPageLoad,
  stopLoad,
  stopPageLoad,
} from "../../../../store/loader/loaderActions";
import axios from "axios";
import { useRef } from "react";
import edit from "../../../../assets/img/edit.svg";

const AddEducationModal = (props) => {
  const {
    modal,
    toggle,
    title,
    editEducationData,
    setEditEducationData,
    isAllowedToEdit,
    isInBackgroundProcess,
  } = props;
  const [errorMessage, setErrorMessage] = useState("");
  const [errorDate, setErrorDate] = useState("");
  const [degreeList, setDegreeList] = useState("");
  const [studyFieldList, setStudyFieldList] = useState("");
  const [textLength, setTextLength] = useState(
    editEducationData?.description
      ? editEducationData.description.split(/\+/g).join("").length
      : 0
  );
  const [addMediaModal, setAddMediaModal] = useState(false);
  const [image, setImage] = useState("");
  const [fileName, setFileName] = useState("");
  const [mediaDescription, setMediaDescription] = useState("");
  const [selectedMediaId, setSelectedMediaId] = useState("");

  const [editedStartDate, setEditedStartDate] = useState(
    editEducationData?.start_date
      ? moment(editEducationData.start_date).utc().format("YYYY-MM-DD")
      : ""
  );
  const [editedEndDate, setEditedEndDate] = useState(
    editEducationData?.end_date
      ? moment(editEducationData.end_date).utc().format("YYYY-MM-DD")
      : ""
  );
  const [showLabel, setShowLabel] = useState("");
  const [showLabelFos, setShowLabelFos] = useState("");

  const [expData, setExpData] = useState(
    editEducationData
      ? {
          id: editEducationData.id,
          school_name: editEducationData.school_name,
          degree: editEducationData.degree,
          field_of_study: editEducationData.field_of_study,
          start_date: editedStartDate,
          end_date: editedEndDate,
          grade: editEducationData.grade,
          description: editEducationData.description,
          medias: editEducationData?.medias ? editEducationData?.medias : [],
          is_highest: editEducationData.is_highest,
          notify_network: false,
        }
      : {
          school_name: "",
          degree: "",
          field_of_study: "",
          start_date: "",
          end_date: "",
          grade: "",
          description: "",
          medias: [],
          is_highest: false,
          notify_network: false,
        }
  );

  const dispatch = useDispatch();
  const imgRef = useRef();
  const handleChange = async (e, type) => {
    var fileExtension = e.target.value.split(".").pop();
    var validFile = true;
    let fileValidation =
      /^(jpg|jpeg|png|JPG|JPEG|PNG|pdf|ppt|pptx|txt|^text\/plain|doc|docx|xls|xlsx)$/;
    if (e.target.id === "chosefile1") {
      if (fileValidation.test(fileExtension) == false) {
        validFile = false;
      }
    }
    if (validFile) {
      let tempimage = [];
      Array.from(e.target.files).map((file) => {
        tempimage.push({
          type,
          file,
        });
      });
      try {
        const UPLOAD_URL = `${process.env.REACT_APP_API_URL}api/v1/upload`;
        const formData = new FormData();
        const headers = {
          "Accept-Language": "en",
          "content-type": "multipart/form-data",
        };

        for (var i = 0; i < tempimage.length; i++) {
          formData.append("image", tempimage[i].file);
        }
        dispatch(initLoad());
        await axios.post(UPLOAD_URL, formData, { headers }).then((res) => {
          dispatch(stopLoad());
          let { filesArray, urlsArray } = res.data;
          setFileName(filesArray[0].originalname);
          setImage(urlsArray[0]);
        });
        //  e.target.files = ''
      } catch (err) {
        if (err.response.data.message === "File too large") {
          toast.warn("File is too large, please upload another file", {
            position: toast.POSITION.BOTTOM_LEFT,
          });
          dispatch(stopLoad());
        }
        console.log(err);
      }
    } else {
      // alert("Please choose valid file type");
      toast.warn("Please choose valid file type", {
        position: toast.POSITION.BOTTOM_LEFT,
      });
      return;
    }
  };

  const handleAddMedia = () => {
    setExpData({
      ...expData,
      medias: [
        ...expData?.medias,
        {
          title: fileName,
          description: mediaDescription,
          image: image,
          type: "education",
        },
      ],
    });
    setAddMediaModal(false);
  };
  useEffect(() => {
    if (image) setAddMediaModal(true);
  }, [image]);

  useEffect(() => {
    (async () => {
      try {
        const res = await call("get", "api/v1/degree", null, null);
        setDegreeList(res.data);
      } catch (err) {
        console.log(err);
      }
    })();
  }, []);

  useEffect(() => {
    (async () => {
      try {
        const res = await call("get", "api/v1/study-field", null, null);
        setStudyFieldList(res.data);
      } catch (err) {
        console.log(err);
      }
    })();
    setShowLabel(expData.degree);
    setShowLabelFos(expData.field_of_study);
  }, []);

  const checkValidation = () => {
    let formIsValid = true;
    if (!expData.school_name.trim()) {
      setErrorMessage("This field is mandatory.");
      formIsValid = false;
      return;
    } else {
      setErrorMessage("");
    }

    if (!expData.start_date) {
      setErrorDate("The field is mandatory");
      formIsValid = false;
      return;
    } else if (expData.start_date) {
      if (!expData.end_date) {
        setErrorDate("End Date is required.");
        formIsValid = false;
      }
    } else {
      setErrorDate("");
    }

    return formIsValid;
  };

  const handleSubmit = () => {
    if (checkValidation()) {
      let newData = expData;
      Object.keys(newData).forEach((key) => {
        if (!newData[key]) delete newData[key];
      });
      let expDataformat = {
        educations: [newData],
      };
      var token = localStorage.getItem("accessToken");
      dispatch(addeducationdetails(expDataformat, token))
        .then(() => {
          if (editEducationData) {
            setEditEducationData();
          }
          toggle();
        })
        .catch((e) => {
          console.log(e);
          alert("Something went wrong.");
        });
    }
  };

  const CloseModal = () => {
    if (editEducationData) {
      setEditEducationData();
    }
    toggle();
  };

  const handleSelectD = (value) => {
    setShowLabel(value.value);
    setExpData({
      ...expData,
      degree: value.value,
    });
  };

  const handleSelectfieldofstudy = (value) => {
    setShowLabelFos(value.value);
    setExpData({
      ...expData,
      field_of_study: value.value,
    });
  };

  return (
    <div>
      <Modal isOpen={modal} className="fix-width gradient-background" centered>
        <div className="popup">
          <div className="add-expriece">
            <ModalHeader toggle={CloseModal} id="account-modal">
              {title}
            </ModalHeader>
            <ModalBody>
              {isInBackgroundProcess && (
                <p className="text-danger mb-2">
                  Currently your request for background screening is in process
                  for that education so you can edit description only
                </p>
              )}
              <form>
                <NotifyToggle
                  setExpData={setExpData}
                  expData={expData}
                  isInBackgroundProcess={isInBackgroundProcess}
                />
                <div className="title">
                  <label>School</label>
                  <div className="icon">
                    <Input
                      type="text"
                      placeholder="Enter Title"
                      maxLength="80"
                      value={expData.school_name}
                      onClick={(e) => setErrorMessage("")}
                      onChange={(e) =>
                        setExpData({ ...expData, school_name: e.target.value })
                      }
                      disabled={isInBackgroundProcess}
                    />
                    <div className="icon-img">
                      <img src={degre} alt="" />
                    </div>
                  </div>
                  {errorMessage && (
                    <label className="label-input100 text-danger">
                      {errorMessage}
                    </label>
                  )}
                </div>
                <div className="title">
                  <Label for="exampleSelect">Degree</Label>
                  <Input
                    className="input-drop"
                    value={expData.degree}
                    id="exampleSelect"
                    name="select"
                    type="select"
                    onChange={(e) =>
                      setExpData({
                        ...expData,
                        degree: e.target.value,
                      })
                    }
                    disabled={isInBackgroundProcess}
                  >
                    <option value="">Select Degree</option>
                    {degreeList?.data?.map((item, index) => {
                      return (
                        <option key={index} value={item.name}>
                          {item.name}
                        </option>
                      );
                    })}
                  </Input>
                </div>
                <div className="title">
                  <FormGroup>
                    <Label for="fieldStudySelect">Field of study</Label>
                    <Input
                      className="input-drop"
                      value={expData.field_of_study}
                      style={{ color: "#676A79" }}
                      id="fieldStudySelect"
                      name="select"
                      type="select"
                      onChange={(e) =>
                        setExpData({
                          ...expData,
                          field_of_study: e.target.value,
                        })
                      }
                      disabled={isInBackgroundProcess}
                    >
                      <option value="">Select Field of Study</option>
                      {studyFieldList?.data?.map((item, index) => {
                        return (
                          <option key={index} value={item.name}>
                            {item.name}
                          </option>
                        );
                      })}
                    </Input>
                    {/* } */}
                  </FormGroup>
                </div>
                <div className="title">
                  <div className="row">
                    <div className="col-md-6 col-12">
                      <label htmlFor="">Start date</label>
                      <DatePickerCustom
                        value={
                          expData.start_date ? new Date(expData.start_date) : ""
                        }
                        disabled={isInBackgroundProcess}
                        onChange={(date) => {
                          date
                            ? setExpData({
                                ...expData,
                                start_date: moment(date).format("YYYY-MM-DD"),
                                end_date: "",
                              })
                            : setExpData({
                                ...expData,
                                start_date: "",
                                end_date: "",
                              });
                        }}
                        placeholder="Pick"
                        onFocus={(e) => setErrorDate("")}
                        max={new Date()}
                      />
                    </div>

                    <div className="col-md-6 col-12">
                      <label htmlFor="">End date (or expected)</label>

                      <DatePickerCustom
                        value={
                          expData.end_date ? new Date(expData.end_date) : ""
                        }
                        onChange={(date) => {
                          date
                            ? setExpData({
                                ...expData,
                                end_date: moment(date).format("YYYY-MM-DD"),
                              })
                            : setExpData({ ...expData, end_date: "" });
                        }}
                        placeholder="Pick"
                        onClick={() => setErrorDate("")}
                        disabled={isInBackgroundProcess || !expData.start_date}
                        min={
                          expData.start_date ? new Date(expData.start_date) : ""
                        }
                        onFocus={(e) => setErrorDate("")}
                        max={new Date()}
                      />
                    </div>
                    {errorDate && (
                      <label className="label-input100 text-danger">
                        {errorDate}
                      </label>
                    )}
                  </div>
                </div>

                <div className="title">
                  <label>Grade</label>
                  <Input
                    value={expData.grade}
                    onChange={(e) =>
                      setExpData({ ...expData, grade: e.target.value })
                    }
                    disabled={isInBackgroundProcess}
                  />
                </div>
                <div>
                  <label htmlFor="">Description</label>
                  <Input
                    value={expData.description}
                    type="textarea"
                    placeholder="Description"
                    maxlength="200"
                    onChange={(e) => {
                      setExpData({ ...expData, description: e.target.value });
                      setTextLength(
                        e.target.value.trim() === "" ? 0 : e.target.value.length
                      );
                    }}
                    disabled={isInBackgroundProcess}
                  />
                  <p className="left">{textLength}/200</p>
                </div>
                <div className="media">
                  <label>Media</label>
                  <p>
                    Incorporate relevant media to support your education, if
                    available.
                  </p>
                </div>
                <div className="add-media mt-2">
                  <span
                    onClick={() => {
                      try {
                        document.getElementById("chosefile1").click();
                      } catch (error) {
                        console.log(error);
                      }
                    }}
                  >
                    + Add media
                  </span>
                  {expData?.medias?.map((item, index) => {
                    return (
                      <div className="row show-media" key={index}>
                        <div className="col-4 ms-3 show-media-image">
                          <img
                            alt="media"
                            src={item?.image}
                            className="main-image"
                          />
                          {isAllowedToEdit ||
                            (isInBackgroundProcess && (
                              <div className="edit">
                                <img
                                  src={edit}
                                  alt=""
                                  onClick={() => {
                                    setImage(item?.image);
                                    setFileName(item?.title);
                                    setMediaDescription(item?.description);
                                    setAddMediaModal(true);
                                    setSelectedMediaId(item?.id);
                                  }}
                                />
                              </div>
                            ))}
                        </div>
                        <div className="col-8">
                          <p className="card-control-text-oneline">
                            {item?.title}
                          </p>
                          <p className="card-control-text-oneline">
                            {item?.description}
                          </p>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </form>
              <input
                type="file"
                ref={imgRef}
                accept="image/png, image/gif, image/jpeg"
                id="chosefile1"
                onChange={(e) => handleChange(e, "image")}
                onClick={(e) => {
                  e.target.value = "";
                  setImage("");
                  setFileName("");
                }}
                disabled={isInBackgroundProcess}
              />
            </ModalBody>
            <div className="p-20" style={{ textAlign: "center" }}>
              <div className="btn btn-blue" onClick={handleSubmit}>
                Save
              </div>
            </div>
          </div>
        </div>
      </Modal>
      {addMediaModal && (
        <AddMediaExper
          modal={addMediaModal}
          toggle={() => setAddMediaModal(!addMediaModal)}
          image={image}
          setImage={setImage}
          fileName={fileName}
          setFileName={setFileName}
          mediaDescription={mediaDescription}
          setMediaDescription={setMediaDescription}
          add={() => handleAddMedia()}
          expData={expData}
          setExpData={setExpData}
          selectedMediaId={selectedMediaId}
        />
      )}
    </div>
  );
};

export default AddEducationModal;
