import React, { useEffect, useState } from "react";
import logo from "../../../assets/img/np-logo.svg";
import signupimage from "../../../assets/img/signup-portal.svg";
import pagePlaceholder from "../../../assets/img/page-placeholder.svg";
import { Link, useNavigate, useParams } from "react-router-dom";
import Header from "../../../core/components/layout/Header/Header";
import Heading from "../../../core/components/layout/Heading";
import Footer from "../../../core/components/layout/footer/Footer";
import FormInput from "../../../core/components/Inputs/FormInput";
import "./Forget.scss";
import { call } from "../../../api/apiCall";
import { useDispatch, useSelector } from "react-redux";
import { getTalentPoolDetails, getTalentPoolDetailsByToken } from "../../../store/portal/portalAction";
import { toast } from "react-toastify";

export default function ForgotPass() {
  const [email, setEmail] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [showErrorMessage, setShowErrorMessage] = useState("");
  const { talentPoolData, talentPoolDataByToken } = useSelector(
    (state) => state.portal
  );
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { param } = useParams();
  useEffect(() => {
    // if (talentPoolData?.id===talentPoolDataByToken?.id) navigate(`/tp/portal/${param}/dashboard`);
    dispatch(getTalentPoolDetails(param));
    dispatch(getTalentPoolDetailsByToken());
  }, []);
  useEffect(() => {
    if (talentPoolData?.id === talentPoolDataByToken?.id)
      navigate(`/tp/portal/${param}/dashboard`);
  }, [talentPoolDataByToken, talentPoolData]);
  const handleSubmit = async () => {
    let forgotPassData = {
      email: email,
    };
    try {
      const res = await call(
        "post",
        "api/v1/auth/forgot-password?type=tp",
        null,
        forgotPassData
      );

      if (res.data.success === true) {
        toast.success("Otp send Successfully", {
          position: toast.POSITION.BOTTOM_LEFT,
        });
        navigate(`/tp/portal/${param}/verify-otp`, { state: { email: email } });
      } else {
        console.log("forgot res unsuccess", res);
        return;
      }
    } catch (err) {
      console.log(err);
      if (err.response.data.message === "No record found.")
        setShowErrorMessage(
          `We couldn't find an account associated with
           ${email}. Please try with an alternate email.`
        );
      else setShowErrorMessage(err.response.data.message);
    }
  };

  const handelChangeEmail = (e) => {
    setEmail(e.target.value);
    setErrorMessage("");
  };

  const validation = () => {
    let emailvalid =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (!email) {
      setErrorMessage("Please enter your email address.");
    } else if (!emailvalid.test(email)) {
      setErrorMessage("Please enter a valid email address.");
    } else {
      handleSubmit();
    }
  };

  return (
    <div>
      {/* <Header /> */}
      <div
        className=" login-portal forgot_pass_container"
        style={{ height: "100vh", background: "white", overflowY: "auto" }}
      >
        <div className="row" style={{ margin: "auto", width: "100%", height: "100%" }}>
          <div className="col-lg-4 forgot-left">
            <img
              src={
                talentPoolData?.background_img
                  ? talentPoolData?.background_img
                  : signupimage
              }
              alt="signupimage"
              className="center"
            />
          </div>
          <div className="col-lg-8 right" style={{ display: "flex" }}>
            <div className="forgot" style={{ margin: "auto" }}>
              <form
                className="auth-form tp-forgot"
                onSubmit={(e) => {
                  e.preventDefault();
                }}
                style={{
                  background: "#FFFFFF",
                  padding: "24px",
                  WebkitBackdropFilter: "blur(5px)",
                  backdropFilter: "blur(5px)",
                  borderRadius: "5px",
                  position: "relative",
                  maxWidth: "454px",
                  width: "100%",
                  // marginBottom: "55px",
                  // float: "right",
                  // marginTop: "20%",
                  // marginLeft: "30vh",
                  // marginRight: "100px",
                }}
              >
                <h2 style={{ color: "black" }}>Forgot Password?</h2>
                <p style={{ fontSize: "14px" }}>
                  Enter your registered email address below, and we'll send
                  you a one-time password (OTP) to reset your password.
                </p>

                <FormInput
                  type="email"
                  label="Email"
                  onChange={handelChangeEmail}
                  placeholder="Enter Email"
                  error={errorMessage}
                  containerClassName={errorMessage ? "error-form" : ""}
                />
                <span className="use-phone-text">
                  {/* <Link to="/">Use phone number </Link> */}
                  <Link to={`/tp/portal/${param}/login`}>
                    <div
                      style={{
                        color: "rgb(4, 50, 163)",
                        marginTop: "10px",
                        cursor: "pointer",
                      }}
                    >
                      Back to login
                    </div>
                  </Link>
                </span>

                <div className="text-center d-grid mt-5">
                  <div
                    className="btn btn-orange"
                    onClick={validation}
                    style={{ background: `${talentPoolData?.theme}` }}
                  >
                    Send OTP
                  </div>
                </div>
                {showErrorMessage && (
                  <label className="label-input100 text-danger">
                    {showErrorMessage}
                  </label>
                )}
                <div
                  className="d-flex justify-content-center mt-4"
                  style={{ height: "40px", alignItems: "center" }}
                >
                  <img
                    src={talentPoolData?.icon ? talentPoolData?.icon : pagePlaceholder}
                    alt="companny-logo"
                    style={{ width: "50px", height: "50px", borderRadius: "50%" }}
                  />
                  <h4 className="card-control-text-oneline ms-2">
                    {talentPoolData?.company_name}
                  </h4>
                </div>
              </form>
            </div>
          </div>
        </div>
        <div className="minHeight"></div>
      </div>
    </div>
  );
}
