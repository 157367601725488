import React, { useEffect } from "react";
import {
  Modal,
  ModalHeader,
  ModalBody,
  Input,
  Label,
  FormGroup,
  Toast,
} from "reactstrap";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { useState } from "react";
import gallaryIcon from "../../../assets/img/gallery-add.svg";
import TagsInput from "../../../core/components/HashTags/tagsInput";
import crossIcon from "../../../assets/img/cross_circle.svg";
import axios from "axios";
import {
  CreatePageProduct,
  updatePageProduct,
} from "../../../store/pages/pagesAction";
export default function ProductServiceModel(props) {
  const {
    modal,
    toggle,
    page_id,
    productData,
    isProductEdit,
    productModel,
    setProductModel,
  } = props;

  const dispatch = useDispatch();

  const [formData, setFormData] = useState({
    title: "",
    category: "",
    action: "",
    about: "",
    link: [""],
    images: ["", "", "", ""],
    hashtag: [],
    icon: "",
  });

  useEffect(() => {
    setFormData({
      title: productData?.title || "",
      category: productData?.category || "",
      action: productData?.action || "",
      about: productData?.about || "",
      link: productData?.link || [""],
      images: productData?.images || ["", "", "", ""],
      hashtag: productData?.hashtag || [],
      icon: productData?.icon || "",
    });
  }, [productData]);

  const handleCross = (i) => {
    const updatedLinks = [...formData.link];
    updatedLinks.splice(i, 1);
    setFormData({ ...formData, link: updatedLinks });
  };

  const [error, setError] = useState({
    title: "",
    category: "",
    action: "",
    about: "",
    link: "",
    images: "",
    hashtag: "",
    icon: "",
  });

  const checkValidation = (type) => {
    let isFormValid = true;

    if (type == "all" || type == "title") {
      if (!formData?.title.trim()) {
        setError({ ...error, title: "Title is required" });
        isFormValid = false;
        return;
      } else {
        setError({ ...error, title: "" });
      }
    }
    if (type == "all" || type == "category") {
      if (!formData?.category) {
        setError({ ...error, category: "Category is required" });
        isFormValid = false;
        return;
      } else {
        setError({ ...error, category: "" });
      }
    }

    if (type == "all" || type == "action") {
      if (!formData?.action.trim()) {
        setError({ ...error, action: "Action is required" });
        isFormValid = false;
        return;
      } else {
        setError({ ...error, action: "" });
      }
    }

    if (type == "all" || type == "about") {
      if (!formData?.about.trim()) {
        setError({ ...error, about: "About is required" });
        isFormValid = false;
        return;
      } else {
        setError({ ...error, about: "" });
      }
    }

    if (type == "all" || type == "image") {
      if (formData?.images.includes("")) {
        setError((prevError) => ({
          ...prevError,
          images: "Images is required",
        }));
        isFormValid = false;
        return;
      } else {
        setError((prevError) => ({
          ...prevError,
          images: "",
        }));
      }
    }

    if (type == "all" || type == "link") {
      if (formData?.link.includes("")) {
        setError((prevError) => ({
          ...prevError,
          link: "Link is required",
        }));
        isFormValid = false;
        return;
      } else {
        setError((prevError) => ({
          ...prevError,
          link: "",
        }));
      }
    }

    if (type == "all" || type == "hashtag") {
      if (!formData?.hashtag.length) {
        setError((prevError) => ({
          ...prevError,
          hashtag: "Hashtag is required",
        }));
        isFormValid = false;
        return;
      } else {
        setError((prevError) => ({
          ...prevError,
          hashtag: "",
        }));
      }
    }
    return isFormValid;
  };

  const handleIconUpload = async (file) => {
    try {
      const UPLOAD_URL = `${process.env.REACT_APP_UPLOAD_URL}api/v1/upload`;
      const formDatas = new FormData();
      const headers = {
        "Accept-Language": "en",
        "content-type": "multipart/form-data",
      };

      formDatas.append("image", file);

      await axios.post(UPLOAD_URL, formDatas, { headers }).then((res) => {
        setFormData({ ...formData, icon: res?.data?.urlsArray[0] });
      });
    } catch (err) {
      console.log(err);
    }
  };

  const handleImageUpload = async (file, i) => {
    try {
      const UPLOAD_URL = `${process.env.REACT_APP_UPLOAD_URL}api/v1/upload`;
      const formData = new FormData();
      const headers = {
        "Accept-Language": "en",
        "content-type": "multipart/form-data",
      };

      formData.append("image", file);

      await axios
        .post(UPLOAD_URL, formData, { headers })
        .then((response) => {
          const imageUrl = response?.data?.urlsArray[0];

          setFormData((prevState) => {
            const updatedImages = [...prevState.images];
            updatedImages[i] = imageUrl;
            return { ...prevState, images: updatedImages };
          });
        })
        .catch((err) => console.log(err));
    } catch (error) {
      console.log(error);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (checkValidation("all")) {
      if (productData) {
        dispatch(updatePageProduct({ ...formData, page_id }, productData?.id))
          .then((res) => {
            toggle();
            toast.success(res?.message, {
              position: toast.POSITION.BOTTOM_LEFT,
            });
          })
          .catch((err) => {
            toast.error(
              err.response?.data?.message,

              {
                position: toast.POSITION.BOTTOM_LEFT,
              }
            );
          });
      } else {
        dispatch(CreatePageProduct({ ...formData, page_id }))
          .then((res) => {
            console.log(res);
            toast.success(res?.message, {
              position: toast.POSITION.BOTTOM_LEFT,
            });
            toggle();
          })
          .catch((err) => {
            toast.error(
              err.response?.data?.message,

              {
                position: toast.POSITION.BOTTOM_LEFT,
              }
            );
          });
      }
    }
  };

  return (
    <div>
      <Modal
        isOpen={modal}
        toggle={toggle}
        className="add-section report-modal fix-width"
        centered
      >
        <div className="create-post">
          <ModalHeader style={{ padding: "20px !important" }} toggle={toggle}>
            Add Product / Service
          </ModalHeader>
          <ModalBody>
            <div className="row mt-3">
              <div
                className="col-4 d-flex justify-content-center align-items-center rounded"
                style={
                  formData?.icon === ""
                    ? { background: "#F1F1F1" }
                    : { background: "" }
                }
              >
                <div
                  className="d-flex flex-column justify-content-center align-items-center"
                  style={{ position: "relative" }}
                >
                  {formData?.icon ? (
                    <>
                      <img src={formData?.icon} alt={`Image`} width={"100%"} />
                      <div
                        onClick={() =>
                          setFormData((prevState) => {
                            return {
                              ...prevState,
                              icon: "",
                            };
                          })
                        }
                        style={{
                          position: "absolute",
                          top: 0,
                          right: "10px",
                        }}
                      >
                        <img src={crossIcon} />
                      </div>
                    </>
                  ) : (
                    <>
                      <img src={gallaryIcon} width={30} />
                      <input
                        type="file"
                        id="icon"
                        onChange={(e) => handleIconUpload(e.target.files[0])}
                      />
                      <label
                        htmlFor="icon"
                        className="form-label fw-light mt-2"
                        style={{ color: "#0432A3", fontSize: "15px" }}
                      >
                        Add Icon
                      </label>
                    </>
                  )}
                </div>
              </div>
              <div className="col-8">
                <div className="">
                  <label
                    className="form-label fw-light"
                    style={{ fontSize: "15px" }}
                  >
                    Name
                  </label>
                  <Input
                    type="text"
                    value={formData?.title}
                    onChange={(e) =>
                      setFormData({ ...formData, title: e.target.value })
                    }
                    onBlur={() => checkValidation("title")}
                  />
                  {error?.title && (
                    <p className="text-danger">{error?.title}</p>
                  )}
                </div>

                <div className="mt-2">
                  <label
                    className="form-label fw-light "
                    style={{ fontSize: "15px" }}
                  >
                    Category
                  </label>
                  <Input
                    type="select"
                    className="border"
                    value={formData?.category}
                    onChange={(e) =>
                      setFormData({ ...formData, category: e.target.value })
                    }
                    onClick={() => checkValidation("category")}
                  >
                    <option value="">Product / Service</option>
                    <option value="product">Product</option>
                    <option value="service">Service</option>
                  </Input>
                  {error?.category && (
                    <p className="text-danger">{error?.category}</p>
                  )}
                </div>
              </div>
            </div>
            <div className="row mt-2">
              <div className="col-12">
                <label
                  className="form-label fw-light"
                  style={{ fontSize: "15px" }}
                >
                  Call to Action
                </label>
                <Input
                  type="text"
                  value={formData?.action}
                  onChange={(e) =>
                    setFormData({ ...formData, action: e.target.value })
                  }
                  onBlur={() => checkValidation("action")}
                />
                {error?.action && (
                  <p className="text-danger">{error?.action}</p>
                )}
              </div>
            </div>

            <div className="row mt-2">
              <div className="col-12 d-flex flex-column">
                <label
                  className="form-label fw-light"
                  style={{ fontSize: "15px" }}
                >
                  About
                </label>
                <textarea
                  rows={5}
                  className="w-100 border p-3"
                  value={formData?.about}
                  onChange={(e) =>
                    setFormData({ ...formData, about: e.target.value })
                  }
                  onBlur={() => checkValidation("about")}
                />
                {error?.about && <p className="text-danger">{error?.about}</p>}
              </div>
            </div>

            <div className="mt-2">
              <label
                className="form-label fw-light"
                style={{ fontSize: "15px" }}
              >
                Add Image
              </label>
              <div className="row gap-5 row-gap-end-0 p-2 justify-content-around">
                {formData?.images?.map((ele, index) => (
                  <>
                    <div
                      key={index}
                      className={
                        ele == ""
                          ? "col-5 d-flex justify-content-center align-items-center rounded border p-3 "
                          : "col-5 d-flex justify-content-center align-items-center rounded"
                      }
                    >
                      <div
                        className="d-flex flex-column justify-content-center align-items-center"
                        style={{ position: "relative" }}
                      >
                        {ele !== "" ? (
                          <>
                            <img
                              src={ele}
                              alt={`Image ${index}`}
                              width={"100%"}
                            />
                            <div
                              onClick={() =>
                                setFormData((prevState) => {
                                  const updatedImages = [...prevState.images];
                                  updatedImages[index] = "";
                                  return {
                                    ...prevState,
                                    images: updatedImages,
                                  };
                                })
                              }
                              style={{
                                position: "absolute",
                                top: 0,
                                right: "10px",
                              }}
                            >
                              <img src={crossIcon} />
                            </div>
                          </>
                        ) : (
                          <>
                            <img
                              src={gallaryIcon}
                              alt="Gallery Icon"
                              width={30}
                            />
                            <input
                              type="file"
                              onChange={(e) =>
                                handleImageUpload(e.target.files[0], index)
                              }
                              id={`image-${index}`}
                            />
                            <label
                              htmlFor={`image-${index}`}
                              className="form-label fw-light mt-2"
                              style={{ color: "#0432A3", fontSize: "15px" }}
                            >
                              Add Image
                            </label>
                          </>
                        )}
                      </div>
                    </div>
                  </>
                ))}
              </div>
              {error.images && <p className="text-danger">{error.images}</p>}
            </div>

            <div className="row mt-2">
              <div className="col-12 d-flex flex-column">
                <label
                  className="form-label fw-light"
                  style={{ fontSize: "15px" }}
                >
                  Links
                </label>
                {formData?.link?.map((ele, i) => {
                  return (
                    <div className="d-flex justify-content-center align-items-center">
                      <Input
                        type="text"
                        className="mt-2"
                        value={ele}
                        onChange={(e) => {
                          const updatedLinks = [...formData.link];
                          updatedLinks[i] = e.target.value;
                          setFormData({ ...formData, link: updatedLinks });
                        }}
                      />
                      {formData?.link?.length > 1 && i > 0 && (
                        <img
                          src={crossIcon}
                          className="mx-2"
                          onClick={() => handleCross(i)}
                        />
                      )}
                    </div>
                  );
                })}
              </div>
              {error.link && <p className="text-danger">{error?.link}</p>}
            </div>
            <div
              className="mt-3"
              style={{ color: "#0432A3", cursor: "pointer" }}
              onClick={() =>
                setFormData({ ...formData, link: [...formData?.link, ""] })
              }
            >
              + Add Another
            </div>

            <div className="row mt-2">
              <div className="col-12 d-flex flex-column">
                <label
                  className="form-label fw-light"
                  style={{ fontSize: "15px" }}
                >
                  Hashtag
                </label>
                <div className="border">
                  <TagsInput
                    tags={formData?.hashtag}
                    setTags={(tags) =>
                      setFormData({ ...formData, hashtag: tags })
                    }
                  />
                </div>
              </div>
              {error?.hashtag && <p className="text-danger">{error.hashtag}</p>}
            </div>
            <div className="d-flex justify-content-center align-items-center w-100 mt-3">
              <button
                className="btn btn-blue w-100"
                onClick={(e) => handleSubmit(e)}
              >
                Add Product / Service
              </button>
            </div>
          </ModalBody>
        </div>
      </Modal>
    </div>
  );
}
