import React, { useEffect, useState } from "react";
import PostJob from "../../core/components/layout/Sidebar/PostJob";
import Navbar from "../navbar/navbar";
import SidebarFooter from "../../routes/setting/SidebarFooter";
import "./jobdetails.scss";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import {
  getJobsDetail,
  jobViewlog,
  updateJobData,
} from "../../store/jobs/jobAction";
import EditorBox from "./JobEditorDetail";
import Edit from "../../assets/img/edit.png";
import pagePlaceholder from "../../assets/img/page-placeholder.svg";
import DeleteModal from "../../core/components/modal/DeleteModal";
import { ToastContainer, toast } from "react-toastify";
import { GetTime } from "../../core/components/Utility/Utility";
import confidential from "../../assets/img/confidentialIcon.svg";

export default function JobDetails() {
  const { getAJob } = useSelector((state) => state.jobs);
  const { user } = useSelector((state) => state.auth);

  const [convertedContent, setConvertedContent] = useState(null);
  const [closeJob, setCloseJob] = useState(false);
  const [closejobId, setClosejobId] = useState("");
  const [dbFormat, setDbFormat] = useState(
    getAJob?.description ? getAJob?.description : ""
  );
  const [editMode, setEditMode] = useState(false);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const params = useParams();

  const [jobDescription, setJobDescription] = useState(getAJob?.description);
  useEffect(() => {
    dispatch(getJobsDetail(params?.id));
    dispatch(jobViewlog(params?.id));
  }, []);

  const EditDescriptionData = () => {
    dispatch(
      updateJobData(
        { description: convertedContent },
        params?.id,
        "update-desc"
      )
    )
      .then((response) => {
        if (response.success) {
          toast.success(response.message, {
            position: toast.POSITION.BOTTOM_LEFT,
          });
        }
        setEditMode(false);
      })
      .catch((error) => {
        console.error("Error updating job description:", error);
      });
  };

  const expiry_check = new Date(getAJob?.expiry_date) > new Date();

  const CloseJobApplicants = (closejobId) => {
    dispatch(updateJobData({ expiry_date: new Date() }, closejobId, "closejob"))
      .then((response) => {
        toast.success("Close Successfully", {
          position: toast.POSITION.BOTTOM_LEFT,
        });
        // navigate(-1)
        navigate("/jobs");
      })
      .catch((error) => {
        console.error("Error updating job close job:", error);
      });
  };

  return (
    <div>
      <Navbar isJobs={false} />
      <div className="job-details admin-bg">
        <div className=" pt-26">
          <div className="container">
            <div className="bg-white p-20">
              <div className="row ">
                <div className="col-lg-1 pe-0 mb-2">
                  <div className="profile-img">
                    {console.log(getAJob)}
                    <img
                      src={
                        getAJob?.user?.id != user?.data?.id
                          ? getAJob?.company_visible
                            ? getAJob?.company?.icon
                              ? getAJob?.company?.icon
                              : pagePlaceholder
                            : confidential
                          : getAJob?.company?.icon
                          ? getAJob?.company?.icon
                          : pagePlaceholder
                      }
                      className="image-fit"
                      alt=""
                    />
                  </div>
                </div>
                <div className="col-lg-6 ps-lg-0">
                  <h1>{getAJob?.title}</h1>
                  <p className="job-style">
                    {getAJob?.user?.id != user?.data?.id
                      ? getAJob?.company_visible
                        ? getAJob?.company_name
                        : "Confidential"
                      : getAJob?.company_name}
                    <span> {getAJob?.company_location}</span>
                  </p>
                  <div className="d-flex gap-3 align-items-center mt-20">
                    {expiry_check ? (
                      <strong style={{ color: "#008C39" }}>Active</strong>
                    ) : (
                      <strong>InActive</strong>
                    )}
                    <p className="job-style">
                      Posted {GetTime(getAJob?.createdAt)}
                    </p>
                    <p className="mt-0 job-style">
                      {getAJob?.view_count} views
                    </p>
                  </div>
                </div>
                <div className="col-lg-5 mt-20 ">
                  {getAJob?.user?.id === user?.data?.id && (
                    <div className="float-lg-end">
                      {getAJob?.applied_count > 0 ? (
                        <>
                          {expiry_check && (
                            <button
                              className="btn btn-blue mb-sm-0 mb-3"
                              onClick={() => {
                                navigate(`/applicationnobel/${params?.id}`);
                              }}
                            >
                              View applicants
                            </button>
                          )}
                        </>
                      ) : (
                        <button disabled> No applicants</button>
                      )}
                      {expiry_check && (
                        <button
                          className="btn btn-border ms-sm-3 mb-sm-0 mb-3"
                          onClick={() => {
                            setCloseJob(true);
                            setClosejobId(params?.id);
                          }}
                        >
                          Close job
                        </button>
                      )}
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="pt-26">
          <div className="container">
            <div className="row  ">
              <div className="col-lg-9">
                <div
                  className="bg-white p-20 pb-5 job-desc"
                  style={{ wordWrap: "break-word" }}
                >
                  {getAJob?.user?.id === user?.data?.id ? (
                    <div
                      className="d-flex justify-content-between align-items-center"
                      onClick={() => {
                        setEditMode(true);
                      }}
                    >
                      <h4 className="mb-4 text-dark">Job description</h4>

                      <img className="edit" src={Edit} alt="Edit" />
                    </div>
                  ) : (
                    <></>
                  )}
                  {editMode ? (
                    <>
                      <EditorBox
                        style={{ minHeight: "500px" }}
                        convertedContent={
                          convertedContent || getAJob?.description
                        }
                        setConvertedContent={setConvertedContent}
                        dbFormat={dbFormat || getAJob?.description}
                        setDbFormat={setDbFormat}
                        value={convertedContent || getAJob?.description}
                        onChange={(e) => setJobDescription(e.target.value)}
                      />
                      <div className="d-flex justify-content-center align-items-center">
                        <button
                          className="btn btn-blue d-block mt-3 px-5"
                          onClick={() => {
                            EditDescriptionData();
                          }}
                        >
                          Save
                        </button>
                      </div>
                    </>
                  ) : (
                    <div
                      dangerouslySetInnerHTML={{
                        __html: getAJob?.description || convertedContent,
                      }}
                    />
                  )}
                </div>
              </div>
              <div className="col-lg-3">
                <PostJob getAJob={getAJob} user={user} />
                <div className="my-4">
                  <SidebarFooter />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {closeJob && (
        <DeleteModal
          modal={closeJob}
          toggle={() => setCloseJob(!closeJob)}
          title="Close Job"
          deleteConfirm={() => {
            CloseJobApplicants(closejobId);
          }}
          text="Are you want to sure close this job?"
        />
      )}
      <ToastContainer />
    </div>
  );
}
