import React from 'react'
import './appDownloadSection.scss'
import mobileAndroidApp from '../../../../../../assets/svg/mobileAdnroidApp.svg'
import mobileAppleApp from '../../../../../../assets/svg/mobileAppleApp.svg'
import workPoolAdndroidApp from '../../../../../../assets/svg/workPoolAdndroidApp.svg'
import workPoolAppleApp from '../../../../../../assets/svg/workPoolAppleApp.svg'
const AppDownloadSection = () => {
  return (
    <>
    <div className='d-flex justify-content-evenly flex-wrap gap-4 app-download-container '>
        <div className='download-section'>
            <h1 className='download-app-title'>NobelPage Mobile App</h1>
            <p className='download-description px-2'>
            Our exceptional app is enables seamless management of everything at your fingertips.
            </p>
            <div className='d-flex gap-2 flex-wrap justify-content-center'>
                <div>
                    <img src={mobileAppleApp} alt='download-apple-app cursor-pointer'></img>
                </div>
                <div>
                <img src={mobileAndroidApp} alt='download-android-app cursor-pointer'></img>

                </div>
            </div>
        </div>
        <div className='download-section'>
            <h1 className='download-app-title'>WorkerPool Mobile App</h1>
            <p className='download-description'>
            Designed for Workers Everywhere! Simple for Everyone – Whether You’re Searching or Offering.
            </p>
            <div className='d-flex gap-2 flex-wrap  justify-content-center'>
                <div>
                    <img src={workPoolAppleApp} alt='download-apple-app cursor-pointer'></img>
                </div>
                <div>
                <img src={workPoolAdndroidApp} alt='download-android-app cursor-pointer'></img>

                </div>
            </div>
        </div>
    </div>
    </>
  )
}

export default AppDownloadSection