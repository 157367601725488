import { groupsApi } from "../../api/groupsApi";
import { memebersApi } from "../../api/memberApi";
import { initPageLoad, stopPageLoad } from "../loader/loaderActions";
import { GET_ALL_MEMBER_SUCCESS, WITHDRAW_MEMBER_REQUEST } from "../types";

export const getAllMemeber = (query) => (dispatch, getState) => {
  return memebersApi.getMemebers(query).then(
    (response) => {
      if (response.success) {
        dispatch({
          type: GET_ALL_MEMBER_SUCCESS,
          payload: response.data,
        });
      }
      return Promise.resolve(response);
    },
    (error) => {
      console.log(error);
      return Promise.reject(error);
    }
  );
};

export const withdrawMemberRequest = (data) => (dispatch) => {
  dispatch(initPageLoad());
  return groupsApi.withdraw(data).then((group) => {
    dispatch({
      type: WITHDRAW_MEMBER_REQUEST,
      payload: data,
    });
    dispatch(stopPageLoad());
    return Promise.resolve(group.data);
  });
};
