import React, { useEffect } from "react";
import ".././Blog.scss"
import logo from "../../../assets/img/np-logo.svg";
import Header from '../../../core/components/layout/Header/Header';
import arrow from "../../../assets/img/Arrow-right.svg"
import { useDispatch, useSelector } from "react-redux";

import moment from 'moment/moment'
import { useNavigate, useParams } from "react-router";
import { allBlogAction, getBlogByIdAction } from "../../../store/blog/blogActions";
import Card from '../BlogCard/Card'
import CarouselSlider from "../../../core/components/CarouselSlider/CarouselSlider";
import Footer from "../../../core/components/layout/footer/Footer";
import blog from "../../../assets/img/blog.png";
import LoginFooter from "../../../core/components/layout/footer/LoginFooter";


export default function BlogDetail() {
  const dispatch = useDispatch();
  const navigate = useNavigate()

  const cardData = useSelector((state) => state.blog.currentBlog.data);
  const relatedBlogs = useSelector((state) => state.blog.currentBlog.relatedBlogs);
  const allBlogs = useSelector((state) => state.blog.blogs?.data)
  const { id } = useParams();
  useEffect(() => {
    if (id) {
      let payload = {
        "page": 1,
        "size": 5
      }
      dispatch(allBlogAction(payload));
      dispatch(getBlogByIdAction(id))
    }
  }, [id]);

  const handleNext = async(id) => {
    let index = await allBlogs.findIndex((item) => item.id === +id);
    if (allBlogs.length > index+1) {
      let id = allBlogs[index + 1].id
      dispatch(getBlogByIdAction(id))
      navigate(`/blogdetail/${id}`)
    }
  }

  return (
    <div>
      <div className="blog">
        <Header />
        <div className="bg">
          <div className="container">
            <div className="row">
              <div className="col-sm-8 margin-top">
                <h1>Blog</h1>
                <p>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc
                  vulputate libero et velit interdum, ac aliquet odio mattis.
                  Class aptent taciti sociosqu ad litora torquent per conubia
                  nostra, per inceptos
                </p>
              </div>
              <div className="col-sm-4">
             <img src={blog} alt="" className="bg-image"/>
              </div>
            </div>
          </div>
          <div className="n-logo">
            <img src={logo} alt="" width="100%" />
          </div>
        </div>
      </div>
      <div className="blog-details ">
        <div className="container my-5">
          <div className="row">
            <div className="col-lg-12">
              <h2>
              {cardData?.title}
              </h2>
              <div className="img">
                <img src={cardData?.thumbnail_url} alt="" className="img-fluid" />
              </div>
              <div className="pro">
                <div className="profile-image">
                  <img src={cardData?.User?.profile_img} alt="" />
                </div>
                <div className="text">
                  <span>{cardData?.User?.first_name + " " + cardData?.User?.last_name}</span>
                </div>
                <div className="dates">
                  <span>
                  {moment(cardData?.createdAt).format(
                              "ddd, MMM DD, YYYY"
                            )}{" "}
                            at{" "}
                            {moment(cardData?.createdAt).format(
                              "hh:mm A z"
                            )}
                            </span>
                </div>
              </div>
              <div className="line my-3"></div>
              <div className="content">
                <p>
                  {
                    cardData?.description
                  }
                </p>
                <div className="text-center my-5">
                  <div className="view">
                    <button onClick={() => handleNext(id)}>
                      View Next Blog
                      <img src={arrow} alt=""  className="ms-3"/>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>

        </div>
        <div className="container">
          <h4 className="my-3">Related Blogs</h4>
          {/* <div className="row"> */}
          <div className="col-lg-12 mb-4">
            {
              relatedBlogs &&
              <CarouselSlider RenderItems={relatedBlogs.map((item, id) => {
                return (
                  <div className="col-12" key={id}>
                    <Card key={id} data={item} />
                  </div>
                )
              })} />
            }
          </div>

          {/* </div> */}
        </div>
       
      </div>
      <LoginFooter />
    </div>
  );
}
