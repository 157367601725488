import React, { useState, useEffect } from "react";
import { getConnectionsData } from "../../../../store/connections/connectionsActions";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import placeholderUser from "../../../../assets/img/placeholder_user.jpg";
import add from "../../../../assets/img/addWhite.svg";
import { editGroup } from "../../../../store/groups/groupsActions";
import SearchMemberModal from "./SearchMember";
import DeleteModal from "../../../../core/components/modal/DeleteModal";
import { ToastContainer, toast } from "react-toastify";
import {
  Modal,
  Input,
  ModalHeader,
  Label,
  FormGroup,
  ModalBody,
} from "reactstrap";
import {
  getAllMemeber,
  withdrawMemberRequest,
} from "../../../../store/memberGroup/memberAction";
import Close from "../../../../assets/img/close.js";
import Tick from "../../../../assets/img/tick.js";
import "../groups.scss";

const ShowGroupMembers = (props) => {
  const {
    modal,
    toggle,
    memberData,
    groupData,
    user,
    isAdmin,
    groupId,
    getMemeberPending,
    getMemeberRequested,
    getMemeberFinal,
  } = props;

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [searchMembersModal, setSearchMembersModal] = useState(false);
  const [removeMemberId, setRemoveMemberId] = useState();
  const [removeModal, setRemoveModal] = useState(false);
  const [rejectModal, setRejectModal] = useState(false);
  const [showText, setShowText] = useState("members");
  const [rejectId, setRejectId] = useState("");
  const [rejectGroupId, setRejectGroupId] = useState("");
  const [acceptModal, setAcceptModal] = useState(false);
  const [acceptId, setAcceptId] = useState("");
  const [acceptGroupId, setAcceptGroupId] = useState("");

  const [ignoreModal, setIgnoreModal] = useState(false);
  const [ignoreId, setIgnoreId] = useState("");
  const [ignoreGroupId, setIgnoreGroupId] = useState("");

  const addGroupMembers = (data, type) => {
    const token = localStorage.getItem("accessToken");
    if (type === "add") {
      dispatch(editGroup(groupData.id, data, token)).then((res) => {
        if (res) {
          toast.success("Group join request sent successfuly", {
            position: toast.POSITION.BOTTOM_LEFT,
          });
        }
      });
      setSearchMembersModal(!searchMembersModal);
    } else if (type === "remove") {
      dispatch(editGroup(groupData.id, data, token));
    }
  };

  const removeGroupMember = () => {
    let membersId = groupData?.members.map((data) => data.user_id);
    const index = membersId.indexOf(removeMemberId);
    membersId.splice(index, 1);

    let data = {
      name: groupData.name,
      icon: groupData.icon,
      background_img: groupData.background_img,
      type: groupData.type,
      description: groupData.description,
      members_limit: groupData.members_limit,
      industry: groupData.industry,
      members: membersId,
      hashtags: ["string"],
    };
    addGroupMembers(data, "remove");
  };

  const withdrawMemeberAction = (user_id, groupId) => {
    const data = {
      user_id: user_id,
      group_id: groupId,
      request_status: "confirm",
    };
    dispatch(withdrawMemberRequest(data)).then((response) =>
      toast.success("Request Withdraw Successfully!", {
        position: toast.POSITION.BOTTOM_LEFT,
      })
    );
  };

  const withdrawMemeberReuestAction = (user_id, groupId) => {
    const data = {
      user_id: user_id,
      group_id: groupId,
      request_status: "cancel",
    };

    dispatch(withdrawMemberRequest(data))
      .then((response) => {
        toast.success("Request Withdrawal Successfull!", {
          position: toast.POSITION.BOTTOM_LEFT,
        });
      })
      .catch((error) => {
        console.error("Error in withdrawMemberRequest:", error);
      });
    getAllMemeber();
  };

  const withdrawMemeberRejectReuestAction = (user_id, groupId) => {
    const data = {
      user_id: user_id,
      group_id: groupId,
      request_status: "reject",
    };
    dispatch(withdrawMemberRequest(data)).then((response) =>
      toast.success("Request Rejected!", {
        position: toast.POSITION.BOTTOM_LEFT,
      })
    );
  };

  console.log(groupData, user);

  return (
    <div>
      <Modal
        isOpen={modal}
        //   size="lg"
        centered
        className="gradient-background"

        //   style={{ maxWidth: "1174px", maxHeight: "546px" }}
      >
        <div>
          <ModalHeader toggle={toggle} className="modal-group-title">
            View Members
          </ModalHeader>
          <ModalBody style={{ padding: "30px 0" }}>
            <div className="admin-popup">
              <div className="container " style={{ padding: 0 }}>
                <div
                  className="grey-bg p-0 bg-white mb-3"
                  style={{ maxHeight: "600px" }}
                >
                  {groupData?.is_joined === "confirm" &&
                  groupData?.type === "public" &&
                  groupData?.admin.id !== user?.data?.id ? (
                    <></>
                  ) : (
                    <ul className="nav nav-tabs mb-4" role="tablist">
                      <li className="nav-item nav-group-item">
                        <a
                          className={
                            showText === "members"
                              ? "nav-link active"
                              : "nav-link"
                          }
                          data-bs-toggle="tab"
                          href="#home"
                          onClick={() => {
                            getMemeberFinal();
                            setShowText("members");
                          }}
                        >
                          Group Members
                        </a>
                      </li>
                      {groupData?.admin?.id == user?.data?.id && (
                        <>
                          <li className="nav-item ">
                            <a
                              className={
                                showText === "requests"
                                  ? "nav-link active"
                                  : "nav-link"
                              }
                              data-bs-toggle="tab"
                              href="#home"
                              onClick={() => {
                                getMemeberRequested();
                                setShowText("requests");
                              }}
                            >
                              Requested
                            </a>
                          </li>
                          <li className="nav-item">
                            <a
                              className={
                                showText === "pending"
                                  ? "nav-link active"
                                  : "nav-link"
                              }
                              data-bs-toggle="tab"
                              href="#home"
                              onClick={() => {
                                getMemeberPending();
                                setShowText("pending");
                              }}
                            >
                              Invited
                            </a>
                          </li>
                        </>
                      )}
                    </ul>
                  )}
                  {memberData &&
                    memberData.map((data, index) => {
                      return (
                        <div
                          className="row mb-4"
                          style={{
                            alignItems: "center",
                            width: "100%",
                            margin: "auto",
                            paddingLeft: "12px",
                            paddingRight: "30px",
                          }}
                          key={index}
                        >
                          <div className="col-lg-1">
                            <div className="img">
                              <img
                                src={
                                  data?.profile_img
                                    ? data?.profile_img
                                    : placeholderUser
                                }
                                className="image-fit"
                                alt="profile img"
                              />
                            </div>
                          </div>
                          <div className="col-lg-5 pl-20">
                            <div className="text">
                              <h6>
                                {data?.first_name.slice(0, 20) +
                                  (data.first_name.length > 20 ? "..." : "") +
                                  " " +
                                  data?.last_name.slice(0, 20) +
                                  (data.last_name.length > 20 ? "..." : "")}
                              </h6>
                              {data?.profile?.profile_headline && (
                                <p>{data?.profile?.profile_headline}</p>
                              )}
                            </div>
                          </div>
                          <div className="col-lg-3">
                            <div className="text">
                              <h6>{data?.type}</h6>
                            </div>
                          </div>
                          {showText === "members" && (
                            <>
                              {groupData?.admin.id === user?.data?.id && (
                                <div className="col-lg-3">
                                  <div className="float-end d-flex gap-4 ">
                                    <div
                                      className="d-flex flex-row align-items-center gap-2 mr-20 cursor-pointer"
                                      onClick={() => {
                                        setRemoveMemberId(data?.user_id);
                                        setRemoveModal(!removeModal);
                                      }}
                                    >
                                      <Close color="#0432A3" />
                                      <h6
                                        style={{
                                          color: "rgba(4, 50, 163, 1)",
                                          cursor: "pointer",
                                        }}
                                      >
                                        Remove
                                      </h6>
                                    </div>
                                  </div>
                                </div>
                              )}
                            </>
                          )}
                          {showText === "requests" &&
                            groupData?.admin?.id == user?.data?.id && (
                              <>
                                <div className="col-lg-3">
                                  <div className="float-end d-flex gap-4 ">
                                    <div
                                      className="d-flex flex-row align-items-center gap-2 cursor-pointer"
                                      onClick={() => {
                                        console.log();
                                        setAcceptModal(!acceptModal);
                                        setAcceptId(data.user_id);
                                        setAcceptGroupId(groupId);
                                        withdrawMemeberAction(
                                          data.user_id,
                                          groupId
                                        );
                                      }}
                                    >
                                      <Tick color="#0432A3" />
                                      <h6
                                        style={{
                                          color: "rgba(4, 50, 163, 1)",
                                          cursor: "pointer",
                                        }}
                                      >
                                        Accept
                                      </h6>
                                    </div>
                                    <div
                                      className="d-flex flex-row align-items-center gap-2 cursor-pointer"
                                      onClick={() => {
                                        setRejectModal(!rejectModal);
                                        setRejectId(data.user_id);
                                        setRejectGroupId(groupId);
                                        withdrawMemeberReuestAction(
                                          data?.user_id,
                                          groupId
                                        );
                                      }}
                                    >
                                      <Close />
                                      <h6
                                        style={{
                                          color: "rgba(217, 83, 79, 1)",
                                          cursor: "pointer",
                                        }}
                                      >
                                        Reject
                                      </h6>
                                    </div>
                                  </div>
                                </div>
                              </>
                            )}
                          {showText === "pending" &&
                            groupData?.admin?.id == user?.data?.id && (
                              <>
                                <div className="col-lg-3">
                                  <div className="float-end d-flex gap-4">
                                    {data?.request_status === "pending" ? (
                                      <div
                                        className="d-flex flex-row align-items-center gap-2 cursor-pointer"
                                        onClick={() => {
                                          setIgnoreModal(!ignoreModal);
                                          setIgnoreId(data.user_id);
                                          setIgnoreGroupId(groupId);
                                          withdrawMemeberRejectReuestAction(
                                            data?.user_id,
                                            groupId
                                          );
                                        }}
                                      >
                                        <Close color="#0432A3" />
                                        <h6
                                          style={{
                                            color: "rgba(4, 50, 163, 1)",
                                            cursor: "pointer",
                                          }}
                                        >
                                          Widthraw
                                        </h6>
                                      </div>
                                    ) : (
                                      <div>
                                        <h6
                                          style={{
                                            color: "rgba(121, 116, 116, 1)",
                                          }}
                                        >
                                          Rejected
                                        </h6>
                                      </div>
                                    )}
                                  </div>
                                </div>
                              </>
                            )}
                        </div>
                      );
                    })}
                </div>
              </div>
            </div>
          </ModalBody>
        </div>
      </Modal>
      {searchMembersModal && (
        <SearchMemberModal
          modal={searchMembersModal}
          toggle={() => setSearchMembersModal(!searchMembersModal)}
          title={"Add Members"}
          addGroupMembers={addGroupMembers}
          editGroupData={groupData}
        />
      )}
      {removeModal && (
        <DeleteModal
          modal={DeleteModal}
          toggle={() => setRemoveModal(!removeModal)}
          title="Remove User"
          deleteConfirm={() => {
            removeGroupMember();
          }}
          text="Are you want to sure remove the member?"
        />
      )}
    </div>
  );
};

ShowGroupMembers.defaultProps = {
  isAdmin: false,
};

export default ShowGroupMembers;
