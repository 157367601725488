import {
  GET_ALL_NOTIFICATIONS,
  DELETE_NOTIFICATIONS,
  LOAD_MORE_GET_ALL_NOTIFICATIONS,
  GET_UNREAD_NOTIFICATIONS,
  LOAD_MORE_UNREAD_NOTIFICATIONS,
  UPDATE_READ_STATUS_NOTIFICATIONS,
  UPDATE_PINNED_STATUS_NOTIFICATIONS,
  UPDATE_ALL_AS_READ_NOTIFICATIONS,
  UPDATE_ALL_AS_UNREAD_NOTIFICATIONS,
} from "../types";

export const initialState = {
  allNotifications: [],
  unreadNotifications: [],
};

const notification = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case GET_ALL_NOTIFICATIONS:
      return {
        ...state,
        allNotifications: payload.data,
      };

    case LOAD_MORE_GET_ALL_NOTIFICATIONS:
      return {
        ...state,
        allNotifications: [...state.allNotifications, ...payload.data],
      };

    case GET_UNREAD_NOTIFICATIONS:
      return {
        ...state,
        unreadNotifications: payload.data,
      };

    case LOAD_MORE_UNREAD_NOTIFICATIONS:
      return {
        ...state,
        unreadNotifications: [...state.unreadNotifications, payload.data],
      };

    case UPDATE_READ_STATUS_NOTIFICATIONS:
      return {
        ...state,
        allNotifications: state.allNotifications.map((x) =>
          x.id === payload.id ? { ...x, read: payload.data.readStatus } : x
        ),
        unreadNotifications: state.unreadNotifications.map((x) =>
          x.id === payload.id ? { ...x, read: payload.data.readStatus } : x
        ),
      };
    case UPDATE_PINNED_STATUS_NOTIFICATIONS:
      return {
        ...state,
        allNotifications: state.allNotifications.map((x) =>
          x.id === payload.id ? { ...x, pinned: payload.data.pinnedStatus } : x
        ),
      };

    case UPDATE_ALL_AS_READ_NOTIFICATIONS:
      return {
        ...state,
        allNotifications: state.allNotifications.map((x) => ({
          ...x,
          read: true,
        })),
        unreadNotifications: state.unreadNotifications.map((x) => ({
          ...x,
          read: true,
        })),
      };
    case UPDATE_ALL_AS_UNREAD_NOTIFICATIONS:
      return {
        ...state,
        allNotifications: state.allNotifications.map((x) => ({
          ...x,
          read: false,
        })),
        unreadNotifications: state.unreadNotifications.map((x) => ({
          ...x,
          read: false,
        })),
      };
    case DELETE_NOTIFICATIONS:
      return {
        ...state,
        allNotifications: state.allNotifications.filter(
          (item) => item.id !== payload.id
        ),
        unreadNotifications: state.unreadNotifications.filter(
          (item) => item.id !== payload.id
        ),
      };
    default:
      return state;
  }
};

export default notification;
