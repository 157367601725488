import React, { useState} from "react";
import Recruiter from "./Recruiter";

export default function RecruiterContainer() {

  const [activeCandidate, setActiveCandidate] = useState(null);
  

  return (
    <Recruiter
      activeCandidate={activeCandidate}
      setActiveCandidate={setActiveCandidate}
    />
  );
}
