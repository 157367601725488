import React, { useEffect, useState } from "react";
import shopping from "../../assets/img/shopping-list.svg";
import close from "../../assets/img/close.svg";
import placeholderUser from "../../assets/img/placeholder_user.jpg";
import { GetTime } from "../../core/components/Utility/Utility";
const ApplicantCard = ({
  applicant,
  onClick,
  onShoppingClick,
  onCloseClick,
  data,
  setSelectedApplicant,
  selectedApplicant,
}) => {
  const handleShoppingClick = (event) => {
    onShoppingClick(); // Trigger the callback function from ApplicationNobel
    event.stopPropagation();
  };

  const handleCloseClick = (event) => {
    onCloseClick(); // Trigger the callback function from ApplicationNobel
    event.stopPropagation();
  };
  const [selected, setSelected] = useState();
  const [showApplyStatus, setShowApplyStatus] = useState(false);

  useEffect(() => {
    setShowApplyStatus(false);
    setSelected(selectedApplicant?.id === applicant?.id);
  }, [selectedApplicant]);
  useEffect(() => {
    console.log("selected", selected);
  }, [selected]);

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setShowApplyStatus(true);
    }, 1000);
    return () => clearTimeout(timeoutId);
  }, [data?.apply_status]);

  return (
    <div
      className="position-relative grey"
      onClick={() => {
        setSelectedApplicant(applicant);
        window.scrollTo(0, 0);
      }}
      style={{ cursor: "pointer", height: "116px" }}
    >
      <div className="row mt-2 p-3 ">
        <div className="col-lg-2">
          <div className="profile-img">
            <img
              src={
                applicant?.user?.profile_img
                  ? applicant?.user?.profile_img
                  : placeholderUser
              }
              className="image-fit"
              alt=""
            />
          </div>
        </div>
        <div className="col-lg-10">
          <div className="details card-control-text-oneline">
            <div className="d-flex justify-content-between">
              <h3
                className="card-control-text-oneline"
                style={{ width: "65%" }}
              >
                {applicant?.user?.first_name} {applicant?.user?.last_name}
              </h3>
              {selected &&
                data?.apply_status !== "pending" &&
                showApplyStatus && (
                  <span
                    className="text-capitalize"
                    style={
                      data?.apply_status == "rejected"
                        ? { color: "red", float: "right" }
                        : { color: "green", float: "right" }
                    }
                  >
                    {data?.apply_status}
                  </span>
                )}
            </div>
            <p className="card-control-text-oneline">
              {applicant?.user?.profile?.profile_headline}
            </p>
            <p>Applied {GetTime(applicant?.createdAt)} </p>
          </div>
          {selected && data?.apply_status === "pending" && showApplyStatus && (
            <>
              <div>
                <div className="shopping" onClick={handleShoppingClick}>
                  <img src={shopping} />
                </div>
                <div className="close" onClick={handleCloseClick}>
                  <img src={close} />
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default ApplicantCard;
