import { Modal, ModalHeader, ModalBody } from "reactstrap";
import React from "react";
import placeholderUser from "../../../assets/img/placeholder_user.jpg";
import { useNavigate } from "react-router-dom";

const MutualConnectionModal = (props) => {
  const { modal, toggle, listData } = props;

  const navigate = useNavigate();

  return (
    <Modal
      size="md"
      isOpen={modal}
      style={{ maxWidth: "550px" }}
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <ModalHeader className="dicardModalHeader" toggle={toggle}>
        Mutual connections with {listData?.first_name} {listData?.last_name}
      </ModalHeader>
      <ModalBody style={{ paddingTop: "10px" }}>
        <div className="mutualConnection">
          {listData?.shared_connection?.length > 0 ? (
            listData?.shared_connection?.map((data, index) => {
              return (
                <div
                  key={index}
                  className="row unitUserRow"
                  style={{ gap: "0px" }}
                >
                  <div className="col-2 d-flex align-items-center justify-content-end sm-sx-p-0">
                    <img
                      className="unitUserImg"
                      onClick={() => navigate(`/profile/${data?.id}`)}
                      src={
                        data?.profile_img ? data?.profile_img : placeholderUser
                      }
                      alt=""
                    />
                  </div>
                  <div
                    className={
                      data?.profile_headline
                        ? "col-8"
                        : "col-8 d-flex align-items-center"
                    }
                  >
                    <p
                      className="text-black fw-bold fs-6 unitUserName"
                      onClick={() => {
                        navigate(`/profile/${data?.id}`);
                        toggle();
                      }}
                    >
                      {data?.first_name} {data?.last_name}
                    </p>
                    {/* <p className="text-black-50 fw-light fs-6 unitUserName">Web Developer at Advantage</p> */}
                    {data?.profile_headline ? (
                      <p className="text-black-50 fw-light fs-6 unitUserName">
                        {data?.profile_headline}
                      </p>
                    ) : (
                      <></>
                    )}
                  </div>
                  <div className="line mt-10"></div>
                </div>
              );
            })
          ) : (
            <h5>No mutual connections</h5>
          )}
        </div>
      </ModalBody>
    </Modal>
  );
};

export default MutualConnectionModal;
