import React, { useEffect, useState } from "react";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import pass from "../../../assets/img/shield-security.svg";
import FormInput from "../../../core/components/Inputs/FormInput";

const MergeAccountModal = (props) => {
  const { modal, toggle, title } = props;
  const [btnStatus, setBtnStatus] = useState(false);
  const [emailName, setEmailName] = useState("");
  const [password, setPassword] = useState("");
  const [errorEmail, setErrorEmail] = useState("");
  const [errorPassword, setErrorPassword] = useState("");

  const checkValidation = (type) => {
    let emailvalid =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    let passwordValid =
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;
    let formIsValid = true;

    if (type === "emailName" || type === "all") {
      if (!emailName) {
        setErrorEmail("Please enter your email.");
        formIsValid = false;
      } else if (!emailvalid.test(emailName)) {
        setErrorEmail("Please enter your email in a valid format.");
        formIsValid = false;
      } else {
        setErrorEmail("");
      }
    }
    if (type === "password" || type === "all") {
      if (!password) {
        setErrorPassword("Please enter your password.");
        formIsValid = false;
      } else if (!passwordValid.test(password)) {
        setErrorPassword(
          'The password should be minimum 8 characters long and contain at least one : Upper and Lower Case Alphabet, Number, Special Character. The special characters that can be used are [!""#$%&\'()*+,-./:;<=>?@[]^_`{|}~]'
        );
        formIsValid = false;
      } else {
        setErrorPassword("");
      }
    }
    return formIsValid;
  };

  useEffect(() => {
    alert(
      "This feature will be implemented in the future milestone"
    );
    if (!emailName || !password) {
      setBtnStatus(false);
    }
    if (emailName && password) {
      setBtnStatus(true);
    }
  }, [emailName, password]);

  const handleMergeSubmit = (e) => {
    e.preventDefault();
    if (checkValidation("all")) {
      toggle();
      // handleFormSubmit();
    }
  };

  return (
    <div>
      <Modal isOpen={modal} size="lg" style={{ maxWidth: "500px" }}>
        <div className="popup">
          <div className="setting-popup">
            <ModalHeader toggle={toggle} id="account-modal">{title}</ModalHeader>
            <ModalBody>
              <div className="box merge-account">
                <p>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc
                  vulputate libero et velit interdum, ac aliquet
                </p>
                <h6 className="mt-20">Gayathri Suresh</h6>
                <p>Ui/Ux Designer</p>
                <p className="mt-20">
                  Lorem ipsum dolor sit amet, consectetur adipiscing{" "}
                </p>

                <FormInput
                  type="email"
                  onChange={(e) => {
                    setEmailName(e.target.value);
                    setErrorEmail("");
                  }}
                  placeholder="Email"
                  onBlur={() => checkValidation("emailName")}
                  error={errorEmail}
                  containerClassName={"inputfields"+(errorEmail?"error-form":"")}
                />
                <FormInput
                  type="password"
                  onChange={(e) => {
                    setPassword(e.target.value);
                    setErrorPassword("");
                  }}
                  placeholder="Enter Password"
                  onBlur={() => checkValidation("password")}
                  error={errorPassword}
                  // containerClassName="inputfields"
                  containerClassName={"inputfields"+(errorPassword?"error-form":"")}
                />

                <div className="text-center mt-20">
                  <div
                    className={
                      btnStatus ? "btn btn-blue" : "btn btn-blue disabledItem"
                    }
                    onClick={(e) => handleMergeSubmit(e)}
                    disabled={btnStatus}
                  >
                    Submit
                  </div>
                </div>
              </div>
            </ModalBody>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default MergeAccountModal;
