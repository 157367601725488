import React, { useState } from "react";
import { MentionsInput, Mention } from "react-mentions";
import defaultMentionStyle from "./defaultMentionStyle";
import defaultStyle from "./defaultStyle";
import defaultHashtagStyle from "./defaultHashtagStyle";
import style from "./style.module.css"
import { useEffect } from "react";
import "./autocomplete.scss"
export default function CheckAutoCompleteModal() {
  const [inputData, setInputData] = useState("");
  const [tagNames, setTagNames] = useState([]);

  const userData = [
    {
      id: "imran",
      display: "imran"
    },
    {
      id: "samy",
      display: "sammy"
    },
    {
      id: "leo",
      display: "leo"
    }
  ];

  const [hashtagData, setHashtagData] = useState([
    {
      id: "resturent",
      display: "resturent"
    },
    {
      id: "school",
      display: "school"
    },
    {
      id: "cafe",
      display: "cafe"
    }
  ]);

  const onMentionChange = (e,
    newValue,
    newPlainTextValue,
    mentions) => {
  //  event.stopPropagation();
  console.log("event.target",
  newPlainTextValue,
  );
    setInputData(e.target.value);
   
  };


  return (
    <div className="App">
      <div style={{ padding: "15px 15px" }}>
        <MentionsInput
            style={defaultStyle}
          // classNames={style}
           onChange={(e,
            newValue,
            newPlainTextValue,
            mentions) => onMentionChange(e,
              newValue,
              newPlainTextValue,
              mentions)}
          // onChange={(event, newValue) => setInputData(newValue)}
          value={inputData}
          placeholder={"Write here..."}
        >
          <Mention
            trigger="#"
            //  style={defaultHashtagStyle}
             className="hastagstyle"
            data={hashtagData}
            // markup="#id"
            // renderSuggestion={(
            //   suggestion,
            //   search,
            //   highlightedDisplay,
            //   index,
            //   focused
            // ) => {
            //   return <span>{"#" + suggestion.display}</span>;
            // }}
            // onAdd={(display) =>
            //   { console.log("hash", display);
            //   setHashtagData((hashtagData) => [...hashtagData, hashtagData.id])}
            // }
            appendSpaceOnAdd={true}
            markup="#[__display__](__id__)"
          />
          <Mention
            trigger="@"
            //  style={defaultMentionStyle}
            data={userData}
             className="mentionstyle"
            // markup="@@@____id__^^____display__@@@^^^"
            // renderSuggestion={(
            //   suggestion,
            //   search,
            //   highlightedDisplay,
            //   index,
            //   focused
            // ) => {
            //   return <span>{suggestion.display}</span>;
            // }}
            markup="@[__display__](__id__)"
          />
        </MentionsInput>
      </div>
    </div>
  );
}
