import React, { useEffect, useState } from "react";
import { Input, Modal, ModalBody, ModalHeader } from "reactstrap";

import { useDispatch, useSelector } from "react-redux";
import {
  getUserSetting,
  updateSettingDetails,
} from "../../../store/setting/SettingAction";
import { call } from "../../../api/apiCall";
import { ToastContainer, toast } from "react-toastify";
import { WEB_URL } from "../../../config";

const CustomProfileModal = (props) => {
  const { modal, toggle, title, data, profileURL } = props;
  // const [errorMessage, setErrorMessage] = useState("");
  const customUrl = data?.settings[0]?.value || profileURL;
  const token = localStorage.getItem("accessToken");
  const dispatch = useDispatch();
  const [customProfileLink, setCustomProfileLink] = useState(customUrl);
  const [customProfileLinkStatus, setCustomProfileLinkStatus] = useState("");

  const handleChange = (e) => {
    //const value=updateMode();
    // const validFormat = /^[a-zA-Z0-9-]+$/;

    // if (!validFormat.test(customProfileLink)) {
    //   setErrorMessage("Invalid format. Please provide a valid endpoint.");
    // }
    // else{
    //   setErrorMessage("");
    // }
    // console.log(value);
    setCustomProfileLink(e.target.value);

    console.log(customProfileLink);
  };

  const checkProfileLink = async () => {
    try {
      const res = await call(
        "get",
        `api/v1/user/url-exist/${customProfileLink}`,
        {},
        {},
        token
      );

      if (res.data.success === true) {
        setCustomProfileLinkStatus("available");
      }
      if (res.data.success === false) {
        setCustomProfileLinkStatus("Unavailable");
      }
    } catch (err) {
      setCustomProfileLinkStatus("Unavailable");
      console.log(err);
    }
  };

  // const updateMode = async () => {
  //   try {
  //     let updateData = {
  //       setting: [
  //         {
  //           setting_master_id: 18,
  //           value: customProfileLink,
  //         },
  //       ],
  //     };
  //     if (customProfileLinkStatus === "available") {
  //       dispatch(updateSettingDetails(updateData, token)).then((res) => {
  //         dispatch(getUserSetting(0, token));
  //         toast.success(res.message, {
  //           position: toast.POSITION.BOTTOM_LEFT,
  //         });

  //         toggle();
  //       });
  //     }
  //   } catch (error) {
  //     console.log(error);
  //   }
  // };

  /* Validation for profile endpoint */

  // const updateMode = async () => {
  //   try {
  //     // Check if the customProfileLink matches the expected format
  //     const validFormat = /^(?!profile\/)[a-zA-Z0-9-]+$/; // Exclude "profile/" from the start

  //     if (!validFormat.test(customProfileLink)) {
  //       setErrorMessage("Invalid format. Please provide a valid endpoint.");
  //       return;
  //     }


  //     let updateData = {
  //       setting: [
  //         {
  //           setting_master_id: 18,
  //           value: customProfileLink,
  //         },
  //       ],
  //     };

  //     if (customProfileLinkStatus === "available") {
  //       dispatch(updateSettingDetails(updateData, token)).then((res) => {
  //         dispatch(getUserSetting(0, token));
  //         toast.success(res.message, {
  //           position: toast.POSITION.BOTTOM_LEFT,
  //         });

  //         toggle();
  //       });
  //     }
  //   } catch (error) {
  //     console.log(error);
  //   }
  // };


  const updateMode = async () => {
    try {
      // Check if the customProfileLink matches the expected format


      // Check if the customProfileLink is available (after format validation)
      await checkProfileLink();

      if (customProfileLinkStatus === "available") {
        let updateData = {
          setting: [
            {
              setting_master_id: 18,
              value: customProfileLink,
            },
          ],
        };

        dispatch(updateSettingDetails(updateData, token)).then((res) => {
          dispatch(getUserSetting(0, token));
          toast.success(res.message, {
            position: toast.POSITION.BOTTOM_LEFT,
          });

          toggle();
        });
      } else {
        //setErrorMessage("Invalid endpoint. This endpoint is not available.");
      }
    } catch (error) {
      console.log(error);
    }
  };


  useEffect(() => {
    if (customProfileLink !== customUrl) {
      checkProfileLink();
      console.log("available");
    }
    setCustomProfileLinkStatus("");
  }, [customProfileLink]);

  const customProfileInValid=()=>{
    let temp= customProfileLink && !/^[a-zA-Z0-9_-]+$/.test(customProfileLink) ;
    console.log(temp,"customProfileInValid");
    return temp;
  }

  return (
    <div>
      <Modal isOpen={modal} size="lg" style={{ maxWidth: "500px" }} centered>
        <div className="popup">
          <div className="setting-popup">
            <ModalHeader toggle={toggle} id="account-modal">{title}</ModalHeader>
            <ModalBody>
              <span>
                <b>URL</b>
              </span>
              :{" "}
              <span
                style={{ wordBreak: "break-all" }}
                className="curserPointer blue-color"
              >
                {WEB_URL}profile/
                {profileURL}
              </span>
              <div className="text-box mt-20">
                <input
                  className="link border-none w-100"
                  value={customProfileLink}
                  onChange={(e) => handleChange(e)}
                />
                <div
                  className={
                    customProfileLinkStatus === "available"
                      ? "avilable ml-10"
                      : "unAvilable ml-10"
                  }
                >
                  {customProfileLinkStatus}
                </div>
              </div>
              {customProfileInValid() && (
                <p className="text-danger">
                  The custom profile link cannot contain spaces or special characters (% ? ^ $ ! ~ ` " : ; .)
                </p>
              )}
              
              <div className="text-end mt-3">
                <div className="btn btn-blue" onClick={ ()=>{  if(!customProfileInValid()){updateMode()} }} >
                  Save
                </div>
              </div>
            </ModalBody>

          </div>
        </div>
        <ToastContainer />
      </Modal>
    </div>
  );
};

export default CustomProfileModal;
