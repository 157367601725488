import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import {
  Modal,
  ModalBody,
  ModalHeader,
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
} from "reactstrap";
import { getAllReactions } from "../../../store/reactionPost/reactionAction";
import like from "../../../assets/img/like_1.svg";
import hand from "../../../assets/img/clapping 3.svg";
import heart from "../../../assets/img/ant-design_heart-filled.svg";
import hat from "../../../assets/img/hat 1.svg";
import bulb from "../../../assets/img/idea_1.svg";
import emoj from "../../../assets/img/smile_1.svg";
import surp from "../../../assets/img/facebook-reactions_1.svg";
import UnitUserList from "../../../core/components/unitUserList/unitUserList";
import "./viewReactionModalView.scss";
import classnames from "classnames";

const ViewReactionModal = (props) => {
  const { modal, toggle, title, postId, type } = props;
  const [currentActiveTab, setCurrentActiveTab] = useState("1");
  const [reacts, setReacts] = useState({});
  const dispatch = useDispatch();

  function toggled(tab) {
    if (currentActiveTab !== tab) setCurrentActiveTab(tab);
  }

  useEffect(() => {
    dispatch(getAllReactions(postId, {type:type})).then((res) => {
      setReacts(res);
    });
  }, []);

  return (
    <div>
      <Modal
        isOpen={modal}
        size="lg"
        style={{ maxWidth: "600px", position: "relative" }}
        centered
      >
        <ModalHeader style={{ borderBottom: "none" }} toggle={toggle}>
          {title}
        </ModalHeader>
        <ModalBody
          className="viewReactionsView mb-20"
          style={{ padding: "10px 0px 30px", overflowX: "hidden" }}
        >
          <Nav tabs className="justify-content-around align-items-center">
            <NavItem>
              <NavLink
                className={classnames(
                  { active: currentActiveTab === "1" },
                  "navlink"
                )}
                onClick={() => {
                  toggled("1");
                }}
              >
                All {reacts?.data?.length > 0 ? reacts?.data?.length : ""}
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                className={classnames(
                  { active: currentActiveTab === "2" },
                  "navlink"
                )}
                onClick={() => {
                  toggled("2");
                }}
              >
                <img className="reaction-icon" src={like} alt="like" />
                {reacts.data &&
                reacts?.data.filter((data) => data.type === "Like").length > 0
                  ? reacts?.data.filter((data) => data.type === "Like").length
                  : ""}
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                className={classnames(
                  { active: currentActiveTab === "3" },
                  "navlink"
                )}
                // style={NavlinkStyle}
                onClick={() => {
                  toggled("3");
                }}
              >
                <img className="reaction-icon" src={heart} alt="heart" />
                {reacts.data &&
                reacts?.data.filter((data) => data.type === "Love").length > 0
                  ? reacts?.data.filter((data) => data.type === "Love").length
                  : ""}
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                className={classnames(
                  { active: currentActiveTab === "4" },
                  "navlink"
                )}
                onClick={() => {
                  toggled("4");
                }}
              >
                <img className="reaction-icon" src={hat} alt="hat" />
                {reacts.data &&
                reacts?.data.filter((data) => data.type === "Respect")
                  .length > 0
                  ? reacts?.data.filter((data) => data.type === "Respect")
                      .length
                  : ""}
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                className={classnames(
                  { active: currentActiveTab === "5" },
                  "navlink"
                )}
                onClick={() => {
                  toggled("5");
                }}
              >
                <img className="reaction-icon" src={hand} alt="hand" />
                {reacts.data &&
                reacts?.data.filter((data) => data.type === "Celebrate")
                  .length > 0
                  ? reacts?.data.filter((data) => data.type === "Celebrate")
                      .length
                  : ""}
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                className={classnames(
                  { active: currentActiveTab === "6" },
                  "navlink"
                )}
                onClick={() => {
                  toggled("6");
                }}
              >
                <img className="reaction-icon" src={bulb} alt="bulb" />
                {reacts.data &&
                reacts?.data.filter((data) => data.type === "Insightful")
                  .length > 0
                  ? reacts?.data.filter((data) => data.type === "Insightful")
                      .length
                  : ""}
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                className={classnames(
                  { active: currentActiveTab === "7" },
                  "navlink"
                )}
                onClick={() => {
                  toggled("7");
                }}
              >
                <img className="reaction-icon" src={emoj} alt="emoj" />
                {reacts.data &&
                reacts?.data.filter((data) => data.type === "Curious").length >
                  0
                  ? reacts?.data.filter((data) => data.type === "Curious")
                      .length
                  : ""}
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                className={classnames(
                  { active: currentActiveTab === "8" },
                  "navlink"
                )}
                onClick={() => {
                  toggled("8");
                }}
              >
                <img className="reaction-icon" src={surp} alt="surp" />
                {reacts.data &&
                reacts?.data.filter((data) => data.type === "Surprise").length >
                  0
                  ? reacts?.data.filter((data) => data.type === "Surprise")
                      .length
                  : ""}
              </NavLink>
            </NavItem>
          </Nav>
          <TabContent activeTab={currentActiveTab}>
            <TabPane tabId="1">
              {reacts.data?.map((item, index) => (
                <div key={index} className="viewReactionRow">
                  <UnitUserList data={item} />
                </div>
              ))}
            </TabPane>
            <TabPane tabId="2">
              {reacts &&
                reacts.data &&
                reacts?.data
                  ?.filter((data) => data.type === "Like")
                  .map((item, index) => (
                    <div key={index} className="viewReactionRow">
                      <UnitUserList data={item} />
                    </div>
                  ))}
            </TabPane>
            <TabPane tabId="3">
              {reacts &&
                reacts.data &&
                reacts?.data
                  ?.filter((data) => data.type === "Love")
                  .map((item, index) => (
                    <div key={index} className="viewReactionRow">
                      <UnitUserList data={item} />
                    </div>
                  ))}
            </TabPane>
            <TabPane tabId="4">
              {reacts &&
                reacts.data &&
                reacts?.data
                  ?.filter((data) => data.type === "Respect")
                  .map((item, index) => (
                    <div key={index} className="viewReactionRow">
                      <UnitUserList data={item} />
                    </div>
                  ))}
            </TabPane>
            <TabPane tabId="5">
              {reacts &&
                reacts.data &&
                reacts?.data
                  ?.filter((data) => data.type === "Celebrate")
                  .map((item, index) => (
                    <div key={index} className="viewReactionRow">
                      <UnitUserList data={item} />
                    </div>
                  ))}
            </TabPane>
            <TabPane tabId="6">
              {reacts &&
                reacts.data &&
                reacts?.data
                  ?.filter((data) => data.type === "Insightful")
                  .map((item, index) => (
                    <div key={index} className="viewReactionRow">
                      <UnitUserList data={item} />
                    </div>
                  ))}
            </TabPane>
            <TabPane tabId="7">
              {reacts &&
                reacts.data &&
                reacts?.data
                  ?.filter((data) => data.type === "Curious")
                  .map((item, index) => (
                    <div key={index} className="viewReactionRow">
                      <UnitUserList data={item} />
                    </div>
                  ))}
            </TabPane>
            <TabPane tabId="8">
              {reacts &&
                reacts.data &&
                reacts?.data
                  ?.filter((data) => data.type === "Surprise")
                  .map((item, index) => (
                    <div key={index} className="viewReactionRow">
                      <UnitUserList data={item} />
                    </div>
                  ))}
            </TabPane>
          </TabContent>
        </ModalBody>
      </Modal>
    </div>
  );
};

export default ViewReactionModal;
