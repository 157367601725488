import React, { useEffect, useState } from "react";
import { Bar } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";


ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const BarChart = (props) => {
const {insightData} = props
  const labels = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];
  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: "top",
      },
    //   title: {
    //     display: true,
    //     text: "Chart.js Bar Chart",
    //   },
    },
    scales: {
        x: {
           grid: {
              display: false
           }
        },
        y: {
           grid: {
              display: false
           }
        }
     }
  };

  const data = {
    labels,
    datasets: [
      {
        label: `${Object.keys(insightData)[1]} Year`,
        data: Object.values(insightData)[1]?.map((x) => x.count),
        backgroundColor: "#0432a3",
      },
      {
        label: `${Object.keys(insightData)[0]} Year`,
        data: Object.values(insightData)[0]?.map((x) => x.count),
        backgroundColor: "#E6E8EC",
      },
    ],
  };
  return (
    <div>
      <Bar options={options} data={data} />
    </div>
  );
};

export default BarChart;
