import React, { useEffect, useState } from "react";
import { Modal, Input, ModalHeader, ModalBody } from "reactstrap";
import "../events.scss";
import drop from "../../../../assets/img/gallery-add.png";
import { createEvent } from "../../../../store/events/eventActions";
import { useDispatch, useSelector } from "react-redux";
import DatePickerCustom from "../../../../core/components/DatePicker/DatePickerReact";
import EventBannerModal from "./eventBannerModal";
import axios from "axios";
import moment from "moment";
import { editEvent } from "../../../../store/events/eventActions";
import Autocomplete from "react-autocomplete";
import DiscardEventModal from "./DiscardModal";
import { getLocationsData } from "../../../../store/connections/connectionsActions";
import { GetAllUsers } from "../../../../store/getAllUsers/allUsersAction";
import { getIndustryData } from "../../../../store/master/masterActions";
import EventPreview from "./previewEvent";
import Loader from "../../../../core/components/Loaders/ComponentLoader";
import ShareModal from "../../../../core/components/sharePost/sharePost";
import placeholderUser from "../../../../assets/img/placeholder_user.jpg";
import { toast } from "react-toastify";
import TimePicker from "rc-time-picker";
import "rc-time-picker/assets/index.css";
import AppLoader from "../../../../core/components/Loaders/appLoader/appLoader";

const CreateEventModal = (props) => {
  const {
    modal,
    toggle,
    title,
    editEventData,
    setEditEventData,
    successfulStatus,
    source,
    pageName,
    createdAt,
    pageID,
    pageIcon,
    setSuccessModal,
  } = props;

  const { industryData } = useSelector((state) => state.master);
  const { isLoading } = useSelector((state) => state.loader);
  const [timeErrorCheck, setTimeErrorCheck] = useState(true);

  var today = new Date();
  let h = (today.getHours() < 10 ? "0" : "") + today.getHours();
  let m = (today.getMinutes() < 10 ? "0" : "") + today.getMinutes();

  const defaultMoment = moment().set({
    hour: today.getHours(),
    minute: today.getMinutes(),
    second: 0, // Assuming you want seconds to be zero in the default time
  });

  const token = localStorage.getItem("accessToken");

  const [uploadImage, setUploadedImage] = useState("");
  const [bannerModal, setBannerModal] = useState(false);
  const [croppedImage, setCroppedImage] = useState(
    editEventData ? editEventData.media : null
  );
  const [previewModal, setPreviewModal] = useState(false);
  const [textLength, setTextLength] = useState(
    editEventData?.description
      ? editEventData?.description?.split(" ")?.length
      : 0
  );
  const [formStatus, setFormStatus] = useState(false);
  const [speakerInput, setSpeakerInput] = useState("");
  const [speakerData, setSpeakerData] = useState("");
  const [speakerArray, setSpeakerArray] = useState(
    editEventData
      ? editEventData?.Speakers?.map((speaker) => ({
          id: speaker.user_id,
          first_name: speaker.user.first_name,
          last_name: speaker.user.first_name,
          profile_img: speaker.user.profile_img,
        }))
      : []
  );
  const [speakerList, setSpeakerList] = useState([]);
  const [discardModalStatus, setDiscardModalStatus] = useState(false);
  const [locationList, setLocationList] = useState([]);
  const [locationInput, setLocationInput] = useState(
    editEventData ? editEventData?.location : ""
  );
  const [industryInput, setIndustryInput] = useState(
    editEventData ? editEventData?.industry : ""
  );
  const [openShareModal, setOpenShareModal] = useState(false);
  const [shareData, setShareData] = useState([]);
  const [indusOpen, setIndusOpen] = useState(false);
  const [locationOpen, setLocationOpen] = useState(false);
  const [speakerOpen, setSpeakerOpen] = useState(false);

  const [eventData, setEventData] = useState(
    editEventData
      ? {
          id: editEventData.id,
          title: editEventData.title,
          privacy_id: 1,
          description: editEventData.description,
          industry: editEventData.industry,
          event_time: editEventData.event_time
            ? moment(editEventData.event_time).format("HH:mm")
            : null,
          event_date: editEventData.event_time
            ? moment(editEventData.event_time).utc().format("YYYY-MM-DD")
            : null,
          event_type: editEventData.event_type,
          event_status: "",
          event_link: editEventData.event_link,
          media: editEventData.media,
          speakers: "",
          location: editEventData.location,
          address: editEventData.address,
        }
      : {
          title: "",
          privacy_id: 1,
          description: "",
          industry: "",
          //event_time: `${h}:${m}`,
          event_time: defaultMoment,
          event_date: moment().format("YYYY-MM-DD"),
          event_type: "virtual",
          event_status: "",
          event_link: "",
          media: null,
          speakers: "",
          location: "",
          address: "",
        }
  );

  const dispatch = useDispatch();
  // Timer Logic Implemented

  const [timeError, setTimeError] = useState("");
  const handleTime = (time) => {
    if (!time) {
      return;
    }
    const formattedTimes = moment(time._d).format("HH:mm");

    const momentObject = moment(eventData.event_date, "YYYY-MM-DD");

    const jsDateObject = momentObject.toDate();
    let currentTimeFormatted = moment(today);
    let formattedTime = moment(moment().format("L") + " " + formattedTimes);
    if (
      !momentObject.isSameOrAfter(currentTimeFormatted) &&
      formattedTime.diff(currentTimeFormatted) < 0
    ) {
      setTimeError("Please select a time after the current time");
      // setEventData({
      //   ...eventData,
      //   event_time: "",
      // })
      setEventData({ ...eventData, event_time: "" });
    } else {
      setTimeError("");
      setEventData({
        ...eventData,
        event_time: time,
      });
      console.log({ ...eventData, event_time: time, timeError });
    }
  };

  //console.log("example");

  useEffect(() => {
    if (locationInput) {
      dispatch(getLocationsData(locationInput))
        .then((res) => {
          if (res?.data) {
            setLocationList(res?.data);
          } else setLocationList([]);
        })
        .catch(() => {
          setLocationList([]);
        });
    }
  }, [locationInput]);

  useEffect(() => {
    if (speakerInput) {
      dispatch(GetAllUsers({ search: speakerInput.val }))
        .then((res) => {
          if (res?.success === true) {
            setSpeakerList(res?.data);
          } else setSpeakerList([]);
        })
        .catch(() => {
          setSpeakerList([]);
        });
    }
  }, [speakerInput]);

  useEffect(() => {
    dispatch(getIndustryData());
  }, []);

  const checkDiscardChanges = () => {
    if (
      eventData?.title ||
      eventData?.description ||
      eventData?.industry ||
      eventData?.event_link
    ) {
      return true;
    } else return false;
  };

  function wordLimit(limit) {
    var val = eventData.description;
    var words = val?.split(/\s+/);
    var legal = "";
    if (words && words.length > 0) {
      for (let i = 0; i < words.length; i++) {
        if (i < limit) {
          legal += words[i] + " ";
        }
        if (i >= limit) {
          setEventData({ ...eventData, description: legal.trim() });
        }
      }
    }
    // for (let i = 0; i < words.length; i++) {
    //   if (i < limit) {
    //     legal += words[i] + " ";
    //   }
    //   if (i >= limit) {
    //     setEventData({ ...eventData, description: legal.trim() });
    //   }
    // }
  }
  function getWordCount(str) {
    return str?.split(" ").filter(function (n) {
      return n !== "";
    }).length;
  }

  const isDuplicate = () => {
    var containsId = speakerArray.find((x) => x.id === speakerData.id);
    return containsId;
  };

  const addSpeaker = () => {
    if (!isDuplicate() && speakerData) {
      setSpeakerArray([...speakerArray, speakerData]);
      setSpeakerData("");
      setSpeakerInput("");
    }
  };

  const removeSpeaker = (speakerId) => {
    let filteredArray = speakerArray.filter((item) => item.id !== speakerId);
    setSpeakerArray(filteredArray);
  };

  useEffect(() => {
    checkValidation();
  }, [eventData]);
  const checkValidation = () => {
    setFormStatus(true);
    //   if (timeError==="") {

    //     setFormStatus(true);

    // }
    console.log(eventData);
    if (
      (timeError != "" &&
        (eventData?.event_date == "" || null) &&
        eventData?.event_time == "") ||
      null
    ) {
      setFormStatus(false);
      console.log("console1");
    }

    if (!eventData?.title?.trim()) {
      setFormStatus(false);
      console.log("console2");
    }
    if (!eventData?.event_link?.trim()) {
      setFormStatus(false);
      console.log("console2");
    }
    if (!eventData.event_time) {
      setFormStatus(false);
      console.log("console3");
    }
    if (!eventData.event_date) {
      setFormStatus(false);
      console.log("console4");
    }

    if (eventData.event_type === "physical") {
      if (!eventData.location) {
        setFormStatus(false);
        console.log("console5");
      }
    }

    if (eventData.event_type === "virtual") {
      if (!eventData.event_link) {
        setFormStatus(false);
        console.log("console6");
      }
    }
  };

  //   useEffect(()=>{
  //     if(timeError!="")
  // setTimeError(false);
  //   },[timeError])

  const createEventSubmit = () => {
    let newDataFormat = {
      title: eventData.title,
      privacy_id: 1,
      description: eventData.description,
      industry: eventData.industry,
      event_time:
        eventData.event_date +
        " " +
        moment(eventData.event_time, "HH:mm:ss").format("HH:mm:ssZ"),
      event_type: eventData.event_type,
      event_status: "published",
      event_link: eventData.event_link,
      media: croppedImage,
      location: eventData?.location,
      address: eventData?.address,
      speakers: speakerArray.map((x) => {
        return {
          user_id: x.id,
        };
      }),
    };

    setShareData(newDataFormat);
    if (source === "network") {
      if (eventData.id) {
        newDataFormat.id = eventData.id;
        dispatch(editEvent(newDataFormat, token))
          .then((res) => {
            toast.success(`${eventData.title} has been updated successfully.`, {
              position: toast.POSITION.BOTTOM_LEFT,
            });
            successfulStatus(newDataFormat);
            setEditEventData("");
            toggle();
          })
          .catch((e) => {});
      } else {
        dispatch(createEvent(newDataFormat, token))
          .then((data) => {
            successfulStatus(data);
            toggle();
          })
          .catch((e) => {});
      }
    } else if (source === "home") {
      setOpenShareModal(true);
    }
  };

  const onChangePicture = (e) => {
    console.log(e.target.files);
    if (e.target.files[0]) {
      console.log(e.target.files);
      setUploadedImage(URL.createObjectURL(e.target.files[0]));
      setBannerModal(true);
    }
  };

  const onInputClick = (event) => {
    event.target.value = "";
  };

  const handleBannerPicture = async (file) => {
    const newImage = new File([file], "newEventCropped", { type: "image/png" });
    try {
      const UPLOAD_URL = `${process.env.REACT_APP_API_URL}api/v1/upload`;
      const formData = new FormData();
      const headers = {
        "Accept-Language": "en",
        "content-type": "multipart/form-data",
      };

      formData.append("image", newImage);

      await axios.post(UPLOAD_URL, formData, { headers }).then((res) => {
        console.log(res?.data?.urlsArray[0]);
        setCroppedImage(res?.data?.urlsArray[0]);
      });
    } catch (err) {
      console.log(err);
    }
  };

  const closeModal = () => {
    if (createdAt === "page") {
      toggle();
    }
    if (editEventData) {
      setEditEventData("");
    }
    if (!editEventData && checkDiscardChanges()) {
      setDiscardModalStatus(true);
    } else {
      toggle();
    }
  };

  const closeAllModal = () => {
    setDiscardModalStatus(!discardModalStatus);
    toggle();
  };

  const saveDraft = () => {
    let newDataFormat = {
      title: eventData.title,
      privacy_id: 1,
      description: eventData.description,
      industry: eventData.industry,
      event_time:
        eventData?.event_date === "" || eventData.event_time === ""
          ? null
          : eventData.event_date +
            " " +
            moment(eventData?.event_time, "HH:mm:ss").format("HH:mm:ssZ"),
      event_type: eventData?.event_type,
      event_link: eventData?.event_link,
      media: croppedImage,
      location: eventData?.location,
      address: eventData?.address,
      speakers: speakerArray
        .filter(function (e) {
          return e.id;
        })
        .map(function (e) {
          return e.id;
        }),
    };
    newDataFormat.event_status = "draft";
    dispatch(createEvent(newDataFormat, token))
      .then(() => {
        setDiscardModalStatus(!discardModalStatus);
        toggle();
      })
      .catch((e) => {});
  };

  useEffect(() => {
    console.log("dateSelected", defaultMoment);
    setEventData({
      ...eventData,
      event_time: defaultMoment,
    });
    // setScheduleTime(defaultMoment);
    setTimeError("");
  }, [eventData.event_date]);

  const [titleError, setTitleError] = useState("");

  const validTitle = () => {
    if (!eventData?.title?.trim()) {
      setTitleError("Please enter a valid title");
    } else {
      setTitleError("");
    }
  };

  return (
    <>
      {/* {isLoading && <Loader />} */}
      {isLoading && <AppLoader />}
      <div>
        <Modal
          isOpen={modal}
          size="md"
          style={{ maxWidth: "600px" }}
          className="networks-popup"
        >
          <div className="create-event">
            <ModalHeader toggle={closeModal}>
              {editEventData ? "Edit Event" : title}
            </ModalHeader>
            <div className="wrapper-class">
              <ModalBody>
                <div>
                  <input
                    type="file"
                    id="profile_pic"
                    name="profile_pic"
                    accept="image/png, image/jpeg, img/png, img/jpeg"
                    style={{ display: "none" }}
                    onChange={onChangePicture}
                    onClick={onInputClick}
                  />
                  {croppedImage ? (
                    <div className="image-preview">
                      <img src={croppedImage} alt="dropImage" />
                    </div>
                  ) : (
                    <label style={{ display: "block" }} htmlFor="profile_pic">
                      <div className="image-upload">
                        <div className="center">
                          <img src={drop} alt="dropImage" />
                          <div>+Add Banner</div>
                          <div>Recommended minimum width 520px</div>
                        </div>
                      </div>
                    </label>
                  )}

                  <Input
                    className="input-type"
                    type="text"
                    placeholder="Event Title*"
                    maxLength="80"
                    value={eventData.title}
                    onChange={(e) => {
                      setEventData({ ...eventData, title: e.target.value });
                    }}
                    onBlur={validTitle}
                  />
                  {titleError && <p className="text-danger">{titleError}</p>}

                  <div className="autocomplete-wrapper">
                    <Autocomplete
                      open={indusOpen}
                      inputProps={{ placeholder: "Industry" }}
                      value={industryInput}
                      items={industryData}
                      getItemValue={(item) => item.name}
                      shouldItemRender={(item, value) =>
                        item?.name
                          ?.toLowerCase()
                          .indexOf(value?.toLowerCase()) > -1
                      }
                      renderMenu={(items, value) => (
                        <div className="dropdown">
                          {items.length === 0
                            ? `No matches for ${value}`
                            : items}
                        </div>
                      )}
                      renderItem={(item, isHighlighted) => (
                        <div
                          className={`item ${
                            isHighlighted ? "selected-item" : ""
                          }`}
                        >
                          {item.name}
                        </div>
                      )}
                      onChange={(event, val) => {
                        if (val.length === 0) {
                          if (indusOpen) setIndusOpen(false);
                        } else {
                          if (!indusOpen) setIndusOpen(true);
                        }
                        setIndustryInput(val);
                      }}
                      onSelect={(val, obj) => {
                        setEventData({ ...eventData, industry: val });
                        setIndustryInput(val);
                        setIndusOpen(false);
                      }}
                      wrapperStyle={{}}
                    />
                  </div>

                  <div className="title">
                    <div className="row">
                      <div className="col-md-7 col-12">
                        <DatePickerCustom
                          value={
                            eventData.event_date
                              ? new Date(eventData.event_date)
                              : ""
                          }
                          //                   onChange={(date) => {
                          //                     date
                          //                       ?
                          //                       const formattedDate = moment(date).format("YYYY-MM-DD");
                          // console.log("Formatted Date:", formattedDate);
                          //                        setEventData({
                          //                           ...eventData,
                          //                           event_date: moment(date).format("YYYY-MM-DD"),
                          //                         })
                          //                       : setEventData({ ...eventData, event_date: "" });
                          //                   }}

                          onChange={(date) => {
                            if (date) {
                              const formattedDate =
                                moment(date).format("YYYY-MM-DD");
                              console.log("Formatted Date:", formattedDate);

                              setEventData({
                                ...eventData,
                                event_date: formattedDate,
                                //event_time: `${h}:${m}`,
                              });
                              //setTimeErrorCheck(false)
                              setTimeError("");
                            } else {
                              setEventData({ ...eventData, event_date: "" });
                            }
                          }}
                          placeholder="Date*"
                          min={today}
                        />
                      </div>
                      <div className="col-md-5 col-12">
                        <TimePicker
                          style={{ fontSize: "15px" }}
                          showSecond={false}
                          value={eventData?.event_time || null}
                          className="customTimePicker"
                          onChange={(selectedTime) => {
                            handleTime(selectedTime);
                            setTimeErrorCheck(false);
                          }}
                          use12Hours
                          placeholder="Select Time"
                        />

                        {/* <input
                          type="time"
                          className="input-type"
                          name="time"
                          id="time"
                          required="required"
                          placeholder="Time*"
                          value={eventData.event_time}
                          onChange={(e) => {
                            handleTime(e.target.value);
                            // setTimeErrorCheck(false)
                          }}
                        /> */}
                        {timeError && (
                          <p style={{ color: "#0432a3", fontSize: "10px" }}>
                            {timeError}
                          </p>
                        )}
                      </div>
                    </div>
                  </div>
                  <Input
                    className="input-type"
                    type="textarea"
                    value={eventData.description}
                    onKeyUp={wordLimit(200)}
                    maxLength="1200"
                    placeholder="Description"
                    onChange={(e) => {
                      setEventData({
                        ...eventData,
                        description: e.target.value,
                      });
                      setTextLength(
                        getWordCount(e.target.value) > 200
                          ? 200
                          : getWordCount(e.target.value)
                      );
                    }}
                  />
                  <p className="title-count">{textLength}/200</p>
                  <div className="label">
                    <div
                      className={
                        eventData?.event_type === "virtual"
                          ? "btn-active text-white"
                          : "round"
                      }
                      onClick={() =>
                        setEventData({ ...eventData, event_type: "virtual" })
                      }
                    >
                      <span>Virtual</span>
                    </div>
                    <div
                      className={
                        eventData?.event_type === "physical"
                          ? "btn-active text-white"
                          : "round"
                      }
                      onClick={() =>
                        setEventData({ ...eventData, event_type: "physical" })
                      }
                    >
                      <span>Physical</span>
                    </div>
                  </div>

                  <Input
                    className="input-type"
                    type="text"
                    placeholder={
                      eventData?.event_type === "virtual"
                        ? "Paste the meeting link here*"
                        : "Paste the meeting link here"
                    }
                    maxLength="80"
                    value={eventData.event_link}
                    onChange={(e) => {
                      setEventData({
                        ...eventData,
                        event_link: e.target.value,
                      });
                    }}
                  />
                  <Input
                    className="input-type"
                    type="text"
                    placeholder="Address"
                    maxLength="50"
                    value={eventData.address}
                    onChange={(e) =>
                      setEventData({ ...eventData, address: e.target.value })
                    }
                  />
                  <div className="speaker">
                    <div className="autocomplete-wrapper">
                      <Autocomplete
                        open={locationOpen}
                        inputProps={{
                          placeholder:
                            eventData?.event_type === "physical"
                              ? "Location*"
                              : "Location",
                        }}
                        value={locationInput}
                        items={locationList}
                        getItemValue={(item) => item.name}
                        shouldItemRender={(item, value) =>
                          item.name.toLowerCase().indexOf(value.toLowerCase()) >
                          -1
                        }
                        renderMenu={(items, value) => (
                          <div className="dropdown">
                            {items.length === 0
                              ? `No matches for ${value}`
                              : items}
                          </div>
                        )}
                        renderItem={(item, isHighlighted) => (
                          <div
                            className={`item ${
                              isHighlighted ? "selected-item" : ""
                            }`}
                          >
                            {item.name}
                          </div>
                        )}
                        onChange={(event, val) => {
                          if (val.length === 0) {
                            if (locationOpen) setLocationOpen(false);
                          } else {
                            if (!locationOpen) setLocationOpen(true);
                          }
                          setLocationInput(val);
                        }}
                        onSelect={(val, obj) => {
                          setLocationInput(val);
                          setEventData({ ...eventData, location: obj.name });
                          setLocationOpen(false);
                        }}
                        wrapperStyle={{}}
                      />
                    </div>
                  </div>

                  <div className="speaker">
                    <div className="autocomplete-wrapper">
                      <Autocomplete
                        open={speakerOpen}
                        inputProps={{ placeholder: "Speaker" }}
                        value={speakerInput.val}
                        items={speakerList}
                        getItemValue={(item) =>
                          item.first_name + " " + item.last_name
                        }
                        // shouldItemRender={(item, value) =>
                        //   item.first_name.toLowerCase().indexOf(value.toLowerCase()) >
                        //   -1
                        // }
                        renderMenu={(items, value) => (
                          <div className="dropdown">
                            {items.length === 0
                              ? `No matches for ${value}`
                              : items}
                          </div>
                        )}
                        renderItem={(item, isHighlighted) => (
                          <div
                            className={`item ${
                              isHighlighted ? "selected-item" : ""
                            }`}
                            style={{ display: "flex" }}
                          >
                            <img
                              src={
                                item.profile_img
                                  ? item.profile_img
                                  : placeholderUser
                              }
                              alt="dropdown_pic"
                              className="image-fit mr-10"
                              style={{ height: "36px", width: "36px" }}
                            />
                            <div>
                              <div className="name-heading-drop ">
                                {item.first_name} {item.last_name}
                              </div>
                              <div className="sub-heading-drop">
                                {item?.profile?.profile_headline ? (
                                  item?.profile?.profile_headline
                                ) : (
                                  <></>
                                )}
                              </div>
                            </div>
                          </div>
                        )}
                        onChange={(event, val) => {
                          if (val.length === 0) {
                            if (speakerOpen) setSpeakerOpen(false);
                          } else {
                            if (!speakerOpen) setSpeakerOpen(true);
                          }
                          setSpeakerInput({ val });
                        }}
                        onSelect={(val, obj) => {
                          setSpeakerInput({ val });
                          setSpeakerData(obj);
                          setSpeakerOpen(false);
                        }}
                        wrapperStyle={{}}
                      />

                      <div className="add" onClick={addSpeaker}></div>
                    </div>
                  </div>

                  <div className="preview-button">
                    <div className="imgaes">
                      {speakerArray?.map((data, idx) => {
                        if (idx < 8) {
                          return (
                            <div className="preview-img" key={idx}>
                              <img
                                src={
                                  data.profile_img
                                    ? data.profile_img
                                    : placeholderUser
                                }
                                className="image-fit"
                                alt=""
                                onClick={() =>
                                  window.open(
                                    `${window.location.origin}/profile/${data?.id}`
                                  )
                                }
                              />
                              <div
                                className="close"
                                onClick={() => {
                                  removeSpeaker(data?.id);
                                }}
                              >
                                X
                              </div>
                            </div>
                          );
                        } else return null;
                      })}
                      {speakerArray?.length > 8 && (
                        <div className="preview-img">
                          <span className="count-img">
                            +{speakerArray?.length - 8}
                          </span>
                        </div>
                      )}
                    </div>
                    <span
                      className="preview"
                      onClick={() => setPreviewModal(!previewModal)}
                    >
                      Preview
                    </span>
                  </div>
                </div>
              </ModalBody>
              <div className="mt-30 mb-20 ml-30 mr-30">
                {formStatus ? (
                  <div
                    className="btn btn-blue d-block"
                    onClick={createEventSubmit}
                  >
                    {source === "home"
                      ? "Next Step"
                      : editEventData
                      ? "Update Event"
                      : "Create Event"}
                  </div>
                ) : (
                  <div className="btn disabled-btn-blue d-block">
                    {source === "home"
                      ? "Next Step"
                      : editEventData
                      ? "Update Event"
                      : "Create Event"}
                  </div>
                )}
              </div>
            </div>
          </div>
        </Modal>
        {previewModal && (
          <EventPreview
            modal={previewModal}
            toggle={() => setPreviewModal(!previewModal)}
            title="Preview Event"
            eventData={eventData}
            bannerImage={croppedImage}
          />
        )}

        {bannerModal && (
          <EventBannerModal
            modal={bannerModal}
            toggle={() => setBannerModal(!bannerModal)}
            title="Edit Banner"
            uploadImage={uploadImage}
            croppedImage={croppedImage}
            setCroppedImage={setCroppedImage}
            handleBannerPicture={handleBannerPicture}
            setBannerModal={setBannerModal}
          />
        )}

        {discardModalStatus && (
          <DiscardEventModal
            modal={discardModalStatus}
            toggle={closeAllModal}
            close={closeAllModal}
            title="Discard Changes."
            saveDraft={saveDraft}
            isDash={false}
          />
        )}

        {openShareModal && (
          <ShareModal
            modal={openShareModal}
            toggle={() => setOpenShareModal(!openShareModal)}
            title="Share"
            type="event"
            shareData={shareData}
            source="home"
            toggleCase={toggle}
            atriclePageName={pageName}
            createdAt={createdAt}
            pageID={pageID}
            pageIcon={pageIcon}
          />
        )}
      </div>
    </>
  );
};

export default CreateEventModal;
