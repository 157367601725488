import {
  GET_SUGGESTEDTAG_LIST,
  GET_FOLLOWEDTAG_LIST,
  GET_MYTAG_LIST,
  FOLLOW_HASHTAG,
  LOAD_MORE_SUGGESTEDTAG_LIST,
  LOAD_MORE_FOLLOWEDTAG_LIST,
  GET_MORE_MYTAG_LIST
} from "../types";
import { hashtagApi } from "../../api/hashtagApi";
import { initLoad } from "../loader/loaderActions";
import { stopLoad } from "../loader/loaderActions";
import { hashtagPostFeedUpdate, hashtagFollowFeedUpdate } from "../dashboard/dashboardActions";

export const getHashtagData = (query, currentCount) => (dispatch) => {
  dispatch(initLoad());
  return hashtagApi.getHashtagList(query, currentCount).then(
    (res) => {
      if (query === "suggested") {
        if (currentCount === 0) {
        dispatch({
          type: GET_SUGGESTEDTAG_LIST,
          payload: res.data,
        });
      }
        else {
          dispatch({
            type: LOAD_MORE_SUGGESTEDTAG_LIST,
            payload: res.data,
          });
        }
      } else if (query === "followed") {
        if (currentCount === 0) {
        dispatch({
          type: GET_FOLLOWEDTAG_LIST,
          payload: res.data,
        });
      }
      else {
        dispatch({
          type: LOAD_MORE_FOLLOWEDTAG_LIST,
          payload: res.data,
        });
      }
      } else if (query === "my") {
        if (currentCount === 0) {
        dispatch({
          type: GET_MYTAG_LIST,
          payload: res.data,
        });
      }
      else {
        dispatch({
          type: GET_MORE_MYTAG_LIST,
          payload: res.data,
        });
      }
      }
      dispatch(stopLoad());
      return Promise.resolve(res);
    },
    (error) => {
      dispatch(stopLoad());
      return Promise.reject(error);
    }
  );
};

export const followHashtag = (hashtagId, hashtagName, type, tabs) => (dispatch) => {
  dispatch(initLoad());
  let data ={
    hashtag_name: hashtagName
  }
  return hashtagApi.followHashtag(data).then(
    (res) => {
      dispatch({
        type: FOLLOW_HASHTAG,
        payload: {id:hashtagId, type:type, tab: tabs}
      });

      dispatch(stopLoad());
      return Promise.resolve(res);
    },
    (error) => {
      dispatch(stopLoad());
      return Promise.reject(error);
    }
  );
};

  export const getHashTagPostFeed = (data) => (dispatch) => {
    return hashtagApi.getHashTagPostFeed(data).then(
      (res) => {
        dispatch(hashtagPostFeedUpdate(res));
        return Promise.resolve(res);
      },
      (error) => {
        return Promise.reject(error);
      }
    );
  };

  export const followHashTagFeed = (hashtagName, type) => (dispatch) => {
    dispatch(initLoad());
    let data ={
      hashtag_name: hashtagName
    }
    console.log(hashtagName)
    return hashtagApi.followHashtag(data).then(
      (res) => {
        dispatch(hashtagFollowFeedUpdate(type));
        dispatch(stopLoad());
        return Promise.resolve(res);
      },
      (error) => {
        dispatch(stopLoad());
        return Promise.reject(error);
      }
    );
      
  };
