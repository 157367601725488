import React, { useState, useRef } from "react";
import { Document, Page } from "react-pdf";
import Slider from "react-slick";
import { Modal, ModalBody, ModalHeader, Button } from "reactstrap";
import AppLoader from "../../core/components/Loaders/appLoader/appLoader";
import RightArrowIcon from "../../assets/img/whiteRightarrow.svg";
import LeftArrowIcon from "../../assets/img/whiteLeftArrow.svg";
const settings = {
  dots: true,
  infinite: false,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  arrows: false,
};

export const Resume = ({ previewResumeModal, toggle, url, isLoading }) => {
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  const [error, setError] = useState(null);
  const sliderRef = useRef(null);

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
    setError(null);
  }

  function onDocumentLoadError(error) {
    setError(error.message);
  }

  const next = () => {
    if (pageNumber < numPages) {
      setPageNumber(pageNumber + 1);
      sliderRef.current.slickGoTo(pageNumber); // Go to the next page
    }
  };

  const previous = () => {
    if (pageNumber > 1) {
      setPageNumber(pageNumber - 1);
      sliderRef.current.slickGoTo(pageNumber - 2); // Go to the previous page
    }
  };

  return (
    <Modal
      isOpen={previewResumeModal}
      backdrop={true}
      size="xl"
      className="cv-preview-modal"
      centered
    >
      <ModalHeader toggle={() => toggle()}></ModalHeader>
      <ModalBody style={{ maxHeight: "fit-content", overflowY: "visible" }}>
        {isLoading ? (
          <div className="position-relative">
            <AppLoader />
          </div>
        ) : (
          <div className="d-flex justify-content-center align-items-center preview_model">
            <Document
              file={url}
              onLoadSuccess={onDocumentLoadSuccess}
              onLoadError={onDocumentLoadError}
            />
            {error && <p>{error}</p>}
            <div style={{ position: "relative", width: "100%" }}>
              {numPages && (
                <div className="d-flex justify-content-center align-items-center">
                  <Button
                    onClick={previous}
                    disabled={pageNumber <= 1}
                    className="bg-transparent border-0"
                  >
                    <img src={RightArrowIcon} width={25} />
                  </Button>
                  <div
                    style={{
                      width: "100%",
                      maxWidth: "calc(450px + 150px)",
                      height: "100%",
                      margin: "0 15px",
                    }}
                  >
                    <Slider
                      ref={sliderRef}
                      {...settings}
                      afterChange={(index) => setPageNumber(index + 1)}
                    >
                      {[...Array(numPages)].map((_, index) => (
                        <div key={index} style={{ border: "1px solid #ddd" }}>
                          <div
                            style={{
                              padding: "0 5px",
                              width: "100%",
                              height: "100%",
                              marginInline: "auto",
                            }}
                          >
                            <Document
                              file={url}
                              onLoadSuccess={onDocumentLoadSuccess}
                              onLoadError={onDocumentLoadError}
                              className={"pdfFullWidth"}
                            >
                              <Page
                                pageNumber={index + 1}
                                width={600}
                                // height={400}
                              />
                            </Document>
                          </div>
                        </div>
                      ))}
                    </Slider>
                    <p className="text-center text-white">
                      {pageNumber} / {numPages}
                    </p>
                  </div>
                  <Button
                    onClick={next}
                    disabled={pageNumber >= numPages}
                    className="bg-transparent  border-0"
                  >
                    <img src={LeftArrowIcon} width={25} />
                  </Button>
                </div>
              )}
            </div>
          </div>
        )}
      </ModalBody>
    </Modal>
  );
};
