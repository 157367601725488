import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom"
import { useLocation } from "react-router-dom";
import { getLandingPageData } from "../../../../store/landing/landingAction";

var options = [
  {
    name: "About",
    route: "/about",
    routeMap: ["/about"]
  },
  {
    name: "Legal Terms",
    route: "/legal",
    routeMap: ["/legal"]
  },
  {
    name: "Community Guidelines",
    route: "/community",
    routeMap: ["/community"]
  },
  {
    name: "Help Center",
    route: "/help",
    routeMap: ["/help"]
  }
];

export default function LoginFooter() {

  const [sectionActive, setSectionActive] = useState("");
  const { pathname } = useLocation();
   const dispatch = useDispatch()

   const { secondFooter } = useSelector((state) => state.landingPage);

  useEffect(() => {
    for (var i = 0; i < options.length; i++) {
      if (options[i]?.route?.replace('/','') === (pathname?.split('/')[1])) {
        setSectionActive(options[i].name);
        break;
      }
    }
  }, [pathname]);
  useEffect(() => {
    dispatch(getLandingPageData({ title: "footer-2" }))
  }, [])
  

  return (
    <div className='loginfooter'>
      <div className="container footer">

        {/* <div className="line mt-3"></div> */}

        <div className="row py-2">

          <div className="col-lg-6 my-3">
            <p>{secondFooter?.title?.replace('YYYY', new Date().getFullYear())}</p>
          </div>

          <div className="col-lg-6 my-3 ">
            <ul>
              {secondFooter?.body?.map((option, idx) => {
                return (
                  <li
                    className={option.title === sectionActive ? "active" : "inactive"}
                    key={idx}
                  >
                    <Link to={`/${option.router_link}`}>
                      {option.title}
                    </Link>
                  </li>
                );
              })}
            </ul>
          </div>

        </div>
      </div>
    </div>
  )
}
