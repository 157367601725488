import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import { viewStatistics } from "../../../store/dashboard/dashboardActions";
import "./ViewStaticsModel.scss";

const ViewStaticsModel = ({ toggle, type, openStaticsModel, title, id }) => {
  const [activeTab, setActiveTab] = useState("views");
  const dispatch = useDispatch();
  const [limit, setLimit] = useState(10);
  const [skip, setSkip] = useState(0);
  const handleData = (view_type) => {
    setActiveTab(view_type);
    setSkip(0);
    dispatch(viewStatistics(view_type, id, type, limit, skip));
  };

  const { statisticsData } = useSelector((state) => state.dashboard);

  console.log(statisticsData, "statisticsData");
  useEffect(() => {
    dispatch(viewStatistics(activeTab, id, type, limit, skip));
  }, []);

  useEffect(() => {
    dispatch(viewStatistics(activeTab, id, type, limit, skip));
  }, [skip]);

  return (
    <>
      <Modal
        size="lg"
        isOpen={openStaticsModel}
        //   style={{ maxWidth: "600px" }}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="gradient-background"
      >
        <ModalHeader toggle={toggle} className="modal-view-statistics-title ">
          Statistics
        </ModalHeader>
        <ModalBody>
          <h5 className="model-view-statistics-body-title mb-20">{title}</h5>

          <div className="tabs d-flex justify-content-between align-items-center mt-4 tab-border">
            <div
              className={
                activeTab == "views"
                  ? "text-primary d-flex active-tab"
                  : "text-secondary d-flex"
              }
              onClick={() => handleData("views")}
            >
              <span className="tab-list-item">Views</span>
              <span className={activeTab == "views" ? "d-flex" : "d-none"}>
                ({statisticsData?.items})
              </span>
            </div>
            <div
              className={
                activeTab == "likes"
                  ? "text-primary d-flex active-tab"
                  : "text-secondary d-flex"
              }
              onClick={() => handleData("likes")}
            >
              <p className="tab-list-item">Likes</p>
              <span className={activeTab == "likes" ? "d-flex" : "d-none"}>
                ({statisticsData?.items})
              </span>
            </div>
            <div
              className={
                activeTab == "comments"
                  ? "text-primary d-flex active-tab"
                  : "text-secondary d-flex"
              }
              onClick={() => handleData("comments")}
            >
              <p className="tab-list-item">Comments</p>
              <span className={activeTab == "comments" ? "d-flex" : "d-none"}>
                ({statisticsData?.items})
              </span>
            </div>
            <div
              className={
                activeTab == "shares"
                  ? "text-primary d-flex active-tab"
                  : "text-secondary d-flex"
              }
              onClick={() => handleData("shares")}
            >
              <p className="tab-list-item">Shares</p>
              <span className={activeTab == "shares" ? "d-flex" : "d-none"}>
                ({statisticsData?.items})
              </span>
            </div>
          </div>

          <div className="">
            {statisticsData?.data?.map((ele) => {
              return (
                <>
                  <div className="d-flex mt-4">
                    <img
                      src={
                        activeTab == "comments"
                          ? ele?.user?.profile_img
                          : ele?.User?.profile_img
                      }
                      width={40}
                      height={40}
                      className="rounded-circle"
                    />
                    <div className="userInfo mx-4">
                      <span className="fw-bold">
                        {activeTab == "comments"
                          ? ele?.user?.first_name
                          : ele?.User?.first_name}
                      </span>
                      <span className="fw-bold">
                        {activeTab == "comments"
                          ? ele?.user?.last_name
                          : ele?.User?.last_name}
                      </span>
                      <p>{activeTab == "comments" ? ele?.comment : ""}</p>
                    </div>
                  </div>
                </>
              );
            })}
            {console.log(
              statisticsData?.items,
              "statisticsData?.data?.length == statisticsData?.items"
            )}
            <div
              className={
                statisticsData?.data?.length == statisticsData?.items
                  ? "d-none"
                  : "d-flex justify-content-center align-items-center"
              }
            >
              <p className="text-primary" onClick={() => setSkip(skip + 10)}>
                View More
              </p>
            </div>
          </div>
        </ModalBody>
      </Modal>
    </>
  );
};

export default ViewStaticsModel;
