import React, { useState, useEffect } from "react";
import PostJob from "./PostJob";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import { toast } from "react-toastify";

export default function PostJobContainer() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [convertedContent, setConvertedContent] = useState(null);
  const [dbFormat, setDbFormat] = useState("");
  const [previewModal, setPreviewModal] = useState(false);
  const [successfulModal, setSuccessfulModal] = useState(false);

  return (
    <PostJob
      convertedContent={convertedContent}
      setConvertedContent={setConvertedContent}
      dbFormat={dbFormat}
      setDbFormat={setDbFormat}
      navigate={navigate}
      previewModal={previewModal}
      setPreviewModal={setPreviewModal}
      successfulModal={successfulModal}
      setSuccessfulModal={setSuccessfulModal}
    />
  );
}
