import {
  GET_SUGGESTED_NEWSLETTERS,
  GET_SUBSCRIBED_NEWSLETTERS,
  GET_MY_NEWSLETTERS,
  GET_DRAFT_NEWSLETTER,
  CREATE_MY_NEWSLETTERS,
  SUBSCRIBE_MY_NEWSLETTERS,
  GET_NEWSLETTER_DETAIL,
  SUBSCRIBE_DETAILED_NEWSLETTER,
  EDIT_MY_NEWSLETTER,
  EDIT_MY_NEWSLETTER_DETAILS,
  GET_COMMENT_LIST,
  COMMENT_NEWSLETTER,
  UNSUBSCRIBE_MY_NEWSLETTERS,
  GET_PAGE_NEWSLETTERS,
  LIKE_NEWSLETTER,
  UNLIKE_NEWSLETTER,
  GET_MORE_NEWSLETTER_COMMENT_LIST,
  LOAD_MORE_GET_MY_NEWSLETTERS,
  LOAD_MORE_GET_SUGGESTED_NEWSLETTERS,
  LOAD_MORE_GET_SUBSCRIBED_NEWSLETTERS,
  LOAD_MORE_GET_DRAFT_NEWSLETTER,
  EDITOR_IMAGE_UPDATE_URL_NEWSLETTER,
} from "../types";

export const initialState = {
  suggested_newsletters: [],
  page_newsletters: [],
  subscribed_newsletters: [],
  my_newsletters: [],
  drafts: [],
  newsletter_details: [],
  comment_list: [],
  editorImgUrlsNews: [],
};

const newsletter = (state = initialState, action) => {
  switch (action.type) {
    case GET_SUGGESTED_NEWSLETTERS:
      console.log(action.payload, "action.payload");
      return { ...state, suggested_newsletters: action.payload };
    case LOAD_MORE_GET_SUGGESTED_NEWSLETTERS:
      return {
        ...state,
        suggested_newsletters: [
          ...state.subscribed_newsletters,
          ...action.payload,
        ],
      };

    case GET_SUBSCRIBED_NEWSLETTERS:
      return { ...state, subscribed_newsletters: action.payload };
    case LOAD_MORE_GET_SUBSCRIBED_NEWSLETTERS:
      return {
        ...state,
        subscribed_newsletters: [
          ...state.subscribed_newsletters,
          ...action.payload,
        ],
      };

    case LOAD_MORE_GET_SUGGESTED_NEWSLETTERS:
      return {
        ...state,
        suggested_newsletters: [
          ...state.suggested_newsletters,
          ...action.payload,
        ],
      };
    case GET_MY_NEWSLETTERS:
      return { ...state, my_newsletters: action.payload };
    case LOAD_MORE_GET_MY_NEWSLETTERS:
      return {
        ...state,
        my_newsletters: [...state.my_newsletters, ...action.payload],
      };
    case GET_PAGE_NEWSLETTERS:
      return { ...state, page_newsletters: action.payload };

    case GET_DRAFT_NEWSLETTER:
      return { ...state, drafts: action.payload };
    case LOAD_MORE_GET_DRAFT_NEWSLETTER:
      return {
        ...state,
        drafts: [...state.drafts, ...action.payload],
      };

    case CREATE_MY_NEWSLETTERS:
      return {
        ...state,
        my_newsletters: [action.payload, ...state.my_newsletters],
      };

    case EDIT_MY_NEWSLETTER:
      return {
        ...state,
        my_newsletters: state.my_newsletters.map((item) =>
          item.id === action.payload.id ? { ...item, ...action.payload } : item
        ),
        ...state,
        newsletter_details: [action.payload],
      };

    case EDIT_MY_NEWSLETTER_DETAILS:
      return { ...state, newsletter_details: [action.payload] };

    case SUBSCRIBE_MY_NEWSLETTERS:
      return {
        ...state,
        suggested_newsletters: state.suggested_newsletters.map((item) =>
          item.id === action.payload
            ? { ...item, subscribed: { status: true } }
            : item
        ),
        subscribed_newsletters: state.subscribed_newsletters.map((item) =>
          item.id === action.payload
            ? { ...item, subscribed: { status: true } }
            : item
        ),
        page_newsletters: state.page_newsletters.map((item) =>
          item.id === action.payload
            ? { ...item, subscribed: { status: true } }
            : item
        ),
        my_newsletters: state.my_newsletters.map((item) =>
          item.id === action.payload
            ? { ...item, subscribed: { status: true } }
            : item
        ),
      };

    case UNSUBSCRIBE_MY_NEWSLETTERS:
      return {
        ...state,
        suggested_newsletters: state.suggested_newsletters.map((item) =>
          item.id === action.payload
            ? { ...item, subscribed: { status: false } }
            : item
        ),
        subscribed_newsletters: state.subscribed_newsletters.map((item) =>
          item.id === action.payload
            ? { ...item, subscribed: { status: false } }
            : item
        ),
        page_newsletters: state.page_newsletters.map((item) =>
          item.id === action.payload
            ? { ...item, subscribed: { status: false } }
            : item
        ),
        my_newsletters: state.my_newsletters.map((item) =>
          item.id === action.payload
            ? { ...item, subscribed: { status: false } }
            : item
        ),
      };

    case GET_NEWSLETTER_DETAIL:
      return { ...state, newsletter_details: action.payload };

    case GET_COMMENT_LIST:
      return { ...state, comment_list: action.payload };

    case GET_MORE_NEWSLETTER_COMMENT_LIST:
      return {
        ...state,
        comment_list: {
          ...state.comment_list,
          data: [...state.comment_list.data, ...action.payload.data],
        },
      };

    case COMMENT_NEWSLETTER:
      return {
        ...state,
        comment_list: {
          ...state.comment_list,
          data: [action.payload, ...state.comment_list.data],
          items: state.comment_list.items + 1,
        },
      };

    case SUBSCRIBE_DETAILED_NEWSLETTER:
      return {
        ...state,
        newsletter_details: state.newsletter_details.map((item) =>
          item.id === action.payload
            ? { ...item, subscribed: { status: true } }
            : item
        ),
      };

    case LIKE_NEWSLETTER:
      return {
        ...state,
        newsletter_details: state.newsletter_details.map((item) =>
          item.id === action.payload.reaction_type_id
            ? { ...item, like_check: true, like_count: item.like_count + 1 }
            : item
        ),
      };

    case UNLIKE_NEWSLETTER:
      return {
        ...state,
        newsletter_details: state.newsletter_details.map((item) =>
          item.id === action.payload.reaction_type_id
            ? { ...item, like_check: false, like_count: item.like_count - 1 }
            : item
        ),
      };
    case EDITOR_IMAGE_UPDATE_URL_NEWSLETTER:
      return {
        ...state,
        editorImgUrlsNews: [...state.editorImgUrlsNews, ...action.payload],
      };

    case EDITOR_IMAGE_UPDATE_URL_NEWSLETTER:
      return {
        ...state,
        editorImgUrlsNews: [],
      };

    default:
      return state;
  }
};

export default newsletter;
