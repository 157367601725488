import axios from "axios";
import { call } from "./apiCall";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const setSession = (accessToken) => {
  if (accessToken) {
    localStorage.setItem("accessTpToken", accessToken);
    // axios.defaults.headers.common.Authorization = `Bearer ${accessToken}`
  } else {
    localStorage.removeItem("accessTpToken");
    delete axios.defaults.headers.common.Authorization;
  }
};

class PortalApi {
  getTalentPoolDetail(params) {
    // let token = localStorage.getItem("accessToken");
    return new Promise((resolve, reject) => {
      (async () => {
        const url = isNaN(params)
          ? `api/v1/get-portal-detail?url=${params}`
          : `api/v1/get-portal-detail?id=${params}`;
        try {
          const res = await call("get", url, null, null, null);
          if (res.data.success === true) {
            resolve(res.data);
          }
          if (res.data.success === false) {
            resolve(res.data);
          }
        } catch (err) {
          console.log(err);
          reject(err);
        }
      })();
    });
  }

  getTPDetailByToken() {
    let token = localStorage.getItem("accessTpToken");
    return new Promise((resolve, reject) => {
      (async () => {
        try {
          const res = await call(
            "get",
            `api/v1/portal/detail-by-token`,
            null,
            null,
            token
          );
          if (res.data.success === true) {
            resolve(res.data);
          }
          if (res.data.success === false) {
            resolve(res.data);
          }
        } catch (err) {
          console.log(err);
          reject(err);
        }
      })();
    });
  }

  login(data, status) {
    return new Promise((resolve, reject) => {
      (async () => {
        try {
          const res = await call(
            "post",
            `api/v1/auth/portal/login?np=${status}`,
            null,
            data
          );
          if (res.data.success === true) {
            const accessToken = res?.data?.token;
            setSession(accessToken);
            resolve(res.data);
          }
          if (res.data.success === false) {
            resolve(res.data);
          }
        } catch (err) {
          console.log(err);
          reject(err);
        }
      })();
    });
  }

  loginNP(emailName, password, registration_type, rememberMe) {
    return new Promise((resolve, reject) => {
      let loginData = {
        email: emailName,
        password: password,
        registration_type: registration_type,
        remember_me: rememberMe,
      };

      (async () => {
        try {
          const res = await call("post", "api/v1/auth/login", null, loginData);
          if (res.data.success === true) {
            const accessToken = res?.data?.token;
            setSession(accessToken);
            resolve(res.data);
          }
          if (res.data.success === false) {
            resolve(res.data);
          }
        } catch (err) {
          console.log(err);
          reject(err);
        }
      })();
    });
  }

  register(signUpData) {
    return new Promise((resolve, reject) => {
      (async () => {
        try {
          const res = await call(
            "post",
            "api/v1/auth/portal/signup",
            null,
            signUpData
          );
          console.log(res.data);
          const accessToken = res?.data?.token;
          setSession(accessToken);
          resolve(res.data);
        } catch (err) {
          console.log(err);
          reject(err);
        }
      })();
    });
  }

  verifyPortalOtp(email) {
    return new Promise((resolve, reject) => {
      let token = localStorage.getItem("accessTpToken");
      (async () => {
        try {
          const res = await call(
            "patch",
            "api/v1/auth/portal/verify-otp",
            null,
            email,
            token
          );
          console.log(res.data);
          resolve(res.data);
        } catch (err) {
          console.log(err);
          reject(err);
        }
      })();
    });
  }

  logout() {
    return new Promise((resolve, reject) => {
      let token = localStorage.getItem("accessToken");
      (async () => {
        try {
          const res = await call(
            "delete",
            "api/v1/auth/logout",
            null,
            null,
            token
          );
          localStorage.clear();
          resolve(res.data);
        } catch (err) {
          console.log(err);
          reject(err);
        }
      })();
    });
  }

  // getConnectionTypeList(type) {
  //   return new Promise((resolve, reject) => {
  //     (async () => {
  //       try {
  //         const res = await call(
  //           "get",
  //           type? `api/v1/feed/connection?type=${type}`:  `api/v1/feed/connection`,
  //           null,
  //           null,
  //           token
  //         );
  //         if (res.data.success === true) {
  //           resolve(res.data);
  //         }
  //         if (res.data.success === false) {
  //           resolve(res.data);
  //         }
  //       } catch (err) {
  //         console.log(err);
  //         reject(err);
  //       }
  //     })();
  //   });
  // }

  getSuggestedPeopleList(params) {
    let token = localStorage.getItem("accessToken");
    return new Promise((resolve, reject) => {
      (async () => {
        try {
          const res = await call(
            "get",
            `api/v1/user/users-list`,
            params,
            null,
            token
          );
          if (res.data.success === true) {
            resolve(res.data);
          }
          if (res.data.success === false) {
            resolve(res.data);
          }
        } catch (err) {
          console.log(err);
          reject(err);
        }
      })();
    });
  }

  acceptConnectionRequest(id, request_type) {
    let token = localStorage.getItem("accessToken");
    return new Promise((resolve, reject) => {
      (async () => {
        try {
          const res = await call(
            "patch",
            `api/v1/feed/connection/${id}/${request_type}`,
            null,
            null,
            token
          );
          if (res.data.success === true) {
            resolve(res.data);
          }
        } catch (err) {
          console.log(err);
          reject(err);
        }
      })();
    });
  }

  createFollower(id) {
    let token = localStorage.getItem("accessToken");
    return new Promise((resolve, reject) => {
      (async () => {
        try {
          const res = await call(
            "post",
            `api/v1/feed/follow/${id}`,
            null,
            null,
            token
          );
          if (res.data.success === true) {
            resolve(res.data);
          }
        } catch (err) {
          console.log(err);
          reject(err);
        }
      })();
    });
  }

  createUnfollower(id) {
    let token = localStorage.getItem("accessToken");
    return new Promise((resolve, reject) => {
      (async () => {
        try {
          const res = await call(
            "delete",
            `api/v1/feed/unfollow/${id}`,
            null,
            null,
            token
          );
          if (res.data.success === true) {
            resolve(res.data);
          }
        } catch (err) {
          console.log(err);
          reject(err);
        }
      })();
    });
  }
  removeConnection(id) {
    let token = localStorage.getItem("accessToken");
    return new Promise((resolve, reject) => {
      (async () => {
        try {
          const res = await call(
            "delete",
            `api/v1/feed/remove-connection/${id}`,
            null,
            null,
            token
          );
          if (res.data.success === true) {
            resolve(res.data);
            toast.success(res.data.message, { position: "bottom-left" });
          }
        } catch (err) {
          console.log(err);
          reject(err);
        }
      })();
    });
  }

  blockUser(id) {
    let token = localStorage.getItem("accessToken");
    return new Promise((resolve, reject) => {
      (async () => {
        try {
          const res = await call(
            "post",
            `api/v1/user/block-user`,
            null,
            { user_id: id },
            token
          );
          if (res.data.success === true) {
            resolve(res.data);
          }
        } catch (err) {
          console.log(err);
          reject(err);
        }
      })();
    });
  }

  unblockUser(id) {
    let token = localStorage.getItem("accessToken");
    return new Promise((resolve, reject) => {
      (async () => {
        try {
          const res = await call(
            "delete",
            `api/v1/user/unblock-user/${id}`,
            null,
            null,
            token
          );
          if (res.data.success === true) {
            resolve(res.data);
          }
        } catch (err) {
          console.log(err);
          reject(err);
        }
      })();
    });
  }

  subscribe(data) {
    return new Promise((resolve, reject) => {
      (async () => {
        try {
          const res = await call("post", "api/v1/portal/subscribe", null, data);
          resolve(res.data);
        } catch (err) {
          console.log(err);
          reject(err);
        }
      })();
    });
  }

  getMemebersList(id, query) {
    return new Promise((resolve, reject) => {
      let token = localStorage.getItem("accessToken");
      (async () => {
        try {
          const res = await call(
            "get",
            `api/v1/portal/member-list/${id}`,
            query,
            null,
            token
          );
          if (res.data.success === true) {
            resolve(res.data);
          }
          if (res.data.success === false) {
            resolve(res.data);
          }
        } catch (err) {
          console.log(err);
          reject(err);
        }
      })();
    });
  }
}

export const portalApi = new PortalApi();
