import React, { useState } from "react";
import { Document, Page, pdfjs } from "react-pdf";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "react-pdf/dist/esm/Page/AnnotationLayer.css";
import "react-pdf/dist/Page/TextLayer.css";

// Configure the worker
pdfjs.GlobalWorkerOptions.workerSrc = `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;

const settings = {
  dots: true,
  infinite: false,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
};

function ThumbnailResumePreview(props) {
  const { url } = props;
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  const [error, setError] = useState(null);

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
    setError(null);
  }

  function onDocumentLoadError(error) {
    setError(error.message);
  }

  return (
    <div className="d-flex">
      <Document
        file={url}
        onLoadSuccess={onDocumentLoadSuccess}
        onLoadError={onDocumentLoadError}
      />
      {error && <p>{error}</p>}
      <div>
        {numPages && (
          <div style={{ width: "290px", height: "400px" }}>
            <Slider
              {...settings}
              afterChange={(index) => setPageNumber(index + 1)}
            >
              {[...Array(numPages)].map((_, index) => (
                <div key={index} style={{ border: "1px solid red" }}>
                  <div
                    style={{ padding: "0 5px", width: "100%", height: "100%" }}
                  >
                    <Document
                      file={url}
                      onLoadSuccess={onDocumentLoadSuccess}
                      onLoadError={onDocumentLoadError}
                    >
                      <Page pageNumber={index + 1} width={290} height={400} />
                    </Document>
                  </div>
                </div>
              ))}
            </Slider>
          </div>
        )}
      </div>
    </div>
  );
}

export default ThumbnailResumePreview;
