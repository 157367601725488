import {
  GET_SUGGESTED_PEOPLE,
  GET_MY_CONNECTION,
  GET_PENDING_INVITATIONS,
  ACCEPT_CONNECTION_REQUEST,
  REJECT_CONNECTION_REQUEST,
  GET_SENT_INVITATIONS_PENDING,
  WITHDRAW_CONNECTION_REQUEST,
  SEND_CONNECT_REQUEST,
  GET_MORE_SUGGESTED_PEOPLE,
  GET_UNFOLLOWED_USERS
} from "../types";

import { peopleApi } from "../../api/peopleApi";
import { initPageLoad } from "../loader/loaderActions";
import { stopPageLoad } from "../loader/loaderActions";

export const getpeopleList = (status, type, params) => (dispatch) => {
  dispatch(initPageLoad());
  return peopleApi.getpeopleList(params).then((peopleData) => {
    if (status==="pending" && type==="received"){
    dispatch({
      type: GET_PENDING_INVITATIONS,
      payload: peopleData?.data,
    }); }
    else if (status==="pending" && type==="sent"){
      dispatch({
        type: GET_SENT_INVITATIONS_PENDING,
        payload: peopleData?.data,
      });
    }
    else if (status==="confirm"){
      dispatch({
        type: GET_MY_CONNECTION,
        payload: peopleData?.data,
      }); }
    // else 
    // {
    //   dispatch({
    //     type: GET_SUGGESTED_PEOPLE,
    //     payload: peopleData?.data,
    //   });
    // }
    dispatch(stopPageLoad());
    return Promise.resolve(peopleData.data);
  });
};


export const getSuggestedPeopleList = (params) => (dispatch) => {
  dispatch(initPageLoad());
  return peopleApi.getSuggestedPeopleList(params).then((peopleData) => {
    if(params.skip === 0) {
      dispatch({
        type: GET_SUGGESTED_PEOPLE,
        payload: peopleData?.data,
      });
    }
    else {
      dispatch({
        type: GET_MORE_SUGGESTED_PEOPLE,
        payload: peopleData?.data,
      })
    }
    
    dispatch(stopPageLoad());
    return Promise.resolve(peopleData);
  });
};

export const getUnfollowedUserList = (params) => (dispatch) => {
  dispatch(initPageLoad());
  return peopleApi.getUnfollowerUsers(params).then((userData) =>
  
  { 
    console.log("userData",userData)
     dispatch({
      type: GET_UNFOLLOWED_USERS,
      payload: userData?.data,
    })
    dispatch(stopPageLoad())
    return Promise.resolve(userData.data);
  });
};
export const acceptConnectionRequest = (id, request_type) => (dispatch) => {
    dispatch(initPageLoad());
    return peopleApi.acceptConnectionRequest(id, request_type).then((peopleData) => {
      if(request_type==="confirm"){
      dispatch({
        type: ACCEPT_CONNECTION_REQUEST,
        payload: id,
      });
    }
    else if (request_type==="reject") {
      dispatch({
        type: REJECT_CONNECTION_REQUEST,
        payload: id,
      });
    }
    else if (request_type==="cancel") {
      dispatch({
        type: WITHDRAW_CONNECTION_REQUEST,
        payload: id,
      });
    }
      dispatch(stopPageLoad());
      return Promise.resolve(peopleData.data);
    });
  };

  export const sendRequest = (id) => (dispatch) => {
    dispatch({
      type: SEND_CONNECT_REQUEST,
      payload: id,
    });
  };
