import React, { useEffect, useState } from "react";
import { Input, Modal, ModalBody, ModalHeader } from "reactstrap";
import { useDispatch } from "react-redux";
import { GetAllUsers } from "../../../../store/getAllUsers/allUsersAction";

import "./selectOption.scss";
import { getConnectionsData } from "../../../../store/connections/connectionsActions";
const SelectReceipent = (props) => {
  const { modal, toggle, title, userName, nextModal, backModal, isVisible } =
    props;

  const dispatch = useDispatch();
  const [arr, setArr] = useState([]);
  const [selected, setSelected] = useState([]);
  const [input, setInput] = useState("");

  const handleChange = (e) => {
    setInput(e.target.value);
    if (e.target.value != "") {
      dispatch(getConnectionsData({ search: e.target.value })).then((res) => {
        if (res && res.data) {
          let newData = res.data.filter((element) => {
            let data = true;
            for (let index = 0; index < selected?.length; index++) {
              if (selected[index]?.id == element.id) {
                data = false;
                break;
              }
            }
            return data;
          });
          setArr(newData);
        }
      });
    } else setArr([]);
  };

  const selectItem = (item) => {
    setSelected((selected) => [...selected, item]);
    setInput("");
    setArr([]);
  };

  const removeItem = (item) => {
    setSelected((current) =>
      current.filter((selected) => selected.id !== item.id)
    );
  };

  return (
    <div className="topDiv">
      <Modal
        isOpen={modal}
        size="lg"
        style={{ maxWidth: isVisible ? "574px" : "600px", top: "55px" }}
      >
        <ModalHeader style={{ padding: "16px 30px" }} toggle={toggle}>
          {title}
        </ModalHeader>
        <ModalBody
        //  className="selectReceipentBody"
        >
          <div className="selectrecpt">
            <input
              value={input}
              className="receipientInput form-control"
              onChange={(e) => handleChange(e)}
              disabled={selected.length}
            />
            <div className="row selectRecptRow">
              {selected.length > 0 &&
                selected.map((item, index) => {
                  return (
                    <div
                      key={index}
                      className="col col-sm-6 col-md-4 col-lg-4 col-xl-4"
                    >
                      <div className="selectedReceipient">
                        <p className="card-control-text-oneline">
                          {item.first_name} {item.last_name}
                        </p>
                        <p
                          onClick={() => removeItem(item)}
                          className="curserPointer mt-0"
                        >
                          X
                        </p>
                      </div>
                    </div>
                  );
                })}
            </div>
            <div
            // className="selectedReceipientSearched"
            >
              <div className="row showreclist curserPointer">
                {arr &&
                  arr.length > 0 &&
                  arr.map((item, index) => (
                    <div
                      key={index}
                      className="row contain"
                      onClick={() => selectItem(item)}
                    >
                      <div className="col-2">
                        <img
                          className="img-fluid listImg"
                          src={
                            item?.profile_img
                              ? item?.profile_img
                              : "http://upload.wikimedia.org/wikipedia/commons/thumb/b/b6/Image_created_with_a_mobile_phone.png/640px-Image_created_with_a_mobile_phone.png"
                          }
                          alt="img"
                        />
                      </div>
                      <div className="col-10">
                        <p className="username">
                          {item?.first_name} {item?.last_name}
                        </p>
                        {item?.profile?.highestExperience && (
                          <small>
                            {item?.profile?.highestExperience?.title} at{" "}
                            {item?.profile?.highestExperience?.company_name}
                          </small>
                        )}
                      </div>
                    </div>
                  ))}
              </div>
              <div className="row buttonRow">
                <div className="col-4 buttonCol">
                  {isVisible ? (
                    <></>
                  ) : (
                    <button className="btn secondarybtn" onClick={backModal}>
                      Back
                    </button>
                  )}
                  {isVisible ? (
                    <button
                      className="btn btn-blue ms-5"
                      onClick={() => nextModal(selected)}
                    >
                      Done
                    </button>
                  ) : (
                    <button
                      className="btn btn-blue"
                      onClick={() => nextModal(selected)}
                    >
                      Next
                    </button>
                  )}
                </div>
              </div>
            </div>
          </div>
        </ModalBody>
      </Modal>
    </div>
  );
};

export default SelectReceipent;
