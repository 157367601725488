import {
  Modal,
  ModalBody,
  Input,
  Label,
  FormGroup,
  ModalHeader,
} from "reactstrap";
import React, { useEffect, useState } from "react";
import { GetAllUsers } from "../../../../store/getAllUsers/allUsersAction";
import { useDispatch, useSelector } from "react-redux";
import PageAdminRoll from "./PageAdminRoll";
import ConfirmPassword from "./ConfirmPassword";
import placeholderUser from "../../../../assets/img/placeholder_user.jpg";
import Loader from "../../../../core/components/Loaders/ComponentLoader";
import AppLoader from "../../../../core/components/Loaders/appLoader/appLoader";
import InfiniteScroll from "react-infinite-scroll-component";

const AddPageAdmin = (props) => {
  const {
    modal,
    toggle,
    title,
    setAddPageAdminModal,
    setRoll,
    selected,
    setSelected,
    addAdmin,
    pageAdminRoll,
    setPageAdminRoll,
    isTransferPage,
    pageID,
  } = props;

  const dispatch = useDispatch();
  const { isPageLoading } = useSelector((state) => state.loader);
  const [arr, setArr] = useState([]);
  const [input, setInput] = useState("");
  const [passModal, setPassModal] = useState(false);
  const [currentCount, setCurrentCount] = useState(0);

  const handleNext = () => {
    setCurrentCount(currentCount + 10);
    dispatch(
      GetAllUsers({ search: input, skip: currentCount, limit: 10 })
    ).then((res) => {
      let newData = res.data.filter((element) => {
        let data = true;
        for (let index = 0; index < selected?.length; index++) {
          if (selected[index]?.id == element.id) {
            data = false;
            break;
          }
        }
        return data;
      });
      setArr(newData);
    });
  };

  const handleChange = (e) => {
    setInput(e.target.value);
    if (e.target.value != "") {
      dispatch(GetAllUsers({ search: e.target.value })).then((res) => {
        let newData = res?.data?.filter((element) => {
          setCurrentCount(currentCount + 10);
          let data = true;
          for (let index = 0; index < selected?.length; index++) {
            if (selected[index]?.id == element.id) {
              data = false;
              break;
            }
          }
          return data;
        });
        setArr(newData);
      });
    } else setArr([]);
  };

  const selectItem = (item) => {
    setSelected(item);
    {
      isTransferPage ? setPassModal(true) : setPageAdminRoll(true);
    }
    setInput("");
    setArr([]);
  };
  //   useEffect(() => {
  //    {pageAdminRoll && toggle()}
  //   }, [pageAdminRoll])

  return (
    <>
      {isPageLoading && <AppLoader />}
      <div>
        <Modal
          isOpen={modal}
          size="lg"
          style={{ maxWidth: "600px" }}
          className="topDiv inviteconnections gradient-background"
          centered
        >
          <ModalHeader className="header" toggle={toggle}>
            {title}
          </ModalHeader>
          <ModalBody>
            <div className="container">
              <div className="row mb-2">
                <div className="col-12"></div>
              </div>

              <div className="row">
                <div className="col-12">
                  <input
                    value={input}
                    className="form-control"
                    onChange={(e) => handleChange(e)}
                  />
                </div>
              </div>
              <div className="selectrecpt" id="scrollableDiv">
                <div className="row showreclist curserPointer">
                  <InfiniteScroll
                    dataLength={currentCount}
                    next={handleNext}
                    hasMore={true}
                    style={{ overflow: "hidden" }}
                    loader={<></>}
                    endMessage={
                      <p style={{ textAlign: "center" }}>
                        <b>Yay! You have seen it all</b>
                      </p>
                    }
                    scrollableTarget="scrollableDiv"
                  >
                    {arr?.length > 0 &&
                      arr.map((item, index) => (
                        <div
                          key={index}
                          className="row contain"
                          onClick={() => selectItem(item)}
                        >
                          <div className="col-2">
                            <img
                              className="img-fluid listImg"
                              src={
                                item?.profile_img
                                  ? item?.profile_img
                                  : placeholderUser
                              }
                              alt="img"
                            />
                          </div>
                          <div className="col-10">
                            <p className="username">
                              {item?.first_name?.slice(0, 12) +
                                (item.first_name?.length > 12 ? "..." : "") +
                                " " +
                                item?.last_name?.slice(0, 10) +
                                (item?.last_name?.length > 10 ? "..." : "")}
                            </p>
                            {item?.profile?.profile_headline && (
                              <small>{item?.profile?.profile_headline}</small>
                            )}
                          </div>
                        </div>
                      ))}
                  </InfiniteScroll>
                </div>
              </div>
            </div>
          </ModalBody>
        </Modal>

        {pageAdminRoll && (
          <PageAdminRoll
            modal={pageAdminRoll}
            toggle={() => setPageAdminRoll(!pageAdminRoll)}
            title="Add Page Admin"
            selected={selected}
            setAddPageAdminModal={setAddPageAdminModal}
            setRoll={setRoll}
            setSelected={setSelected}
            addAdmin={addAdmin}
          />
        )}
        {passModal && (
          <ConfirmPassword
            modal={passModal}
            selected={selected}
            toggle={() => setPassModal(!passModal)}
            title="Enter Password"
            pageID={pageID}
            setAddPageAdminModal={setAddPageAdminModal}
          />
        )}
      </div>
    </>
  );
};

export default AddPageAdmin;
