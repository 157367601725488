import React from "react";
import Layout from "../Layout/Layout";

const data = [
  {
    description: "You Noble Page Subscription Successful",
    date: "Today",
    amount: "$99",
    status: "Success",
  },
  {
    description: "Received Cashback Bonus",
    date: "Yesterday",
    amount: "$99",
    status: "Success",
  },

  {
    description: "Money Transfer failed to John Wick",
    date: "Yesterday",
    amount: "$8",
    status: "Failed",
  },
  {
    description: "You Noble Page Subscription Successful",
    date: "Today",
    amount: "$55",
    status: "Success",
  },
  {
    description: "Received Cashback Bonus",
    date: "Yesterday",
    amount: "$79",
    status: "Success",
  },
  {
    description: "Received Cashback Bonus",
    date: "Yesterday",
    amount: "$99",
    status: "Failed",
  },

  {
    description: "Money Transfer failed to John Wick",
    date: "Yesterday",
    amount: "$8",
    status: "Failed",
  },

  {
    description: "Money Transfer failed to John Wick",
    date: "Yesterday",
    amount: "$-78",
    status: "Failed",
  },
  {
    description: "You Noble Page Subscription Successful",
    date: "Today",
    amount: "$99",
    status: "Success",
  },
  {
    description: "Received Cashback Bonus",
    date: "Yesterday",
    amount: "$99",
    status: "Success",
  },

  {
    description: "Money Transfer failed to John Wick",
    date: "Yesterday",
    amount: "$8",
    status: "Failed",
  },
];

export default function WalletHistory() {
  return (
    <Layout>
      <div className="col-lg-9">
        <div className="networks mt-4">
          <div className="main-center">
            <div className="wrapper p-20">
              <div className="row mb-3">
                <div className="col-12">
                  <h4 className="wallet_header">Wallet History</h4>
                </div>
              </div>
              <div className="line"></div>
              <div className="mt-20">
                {data.map((data, index) => {
                  return (
                    <>
                      <div className="mt-20 mb-20">
                        <div className="row">
                          <div className="col-9">
                            <p className="wallet_description">
                              {data.description}
                            </p>
                            <p className="wallet_subdescription">
                              {data.date}, 9:40 AM
                            </p>
                          </div>
                          <div className="col-3 align_end">
                            <p
                              className={
                                data.status === "Success"
                                  ? "wallet_amount fw-700 f-green"
                                  : "wallet_amount fw-700 f-red"
                              }
                            >
                              {data.amount}
                            </p>
                            <p
                              className={
                                data.status === "Success"
                                  ? "wallet_amount f-green"
                                  : "wallet_amount f-red"
                              }
                            >
                              {data.status}
                            </p>
                          </div>
                        </div>
                      </div>
                    </>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
}
