import Layout from "../Layout/Layout";
import React from "react";
import Advertisement from "../../dashboard/Advertisement";
import InfiniteScroll from "react-infinite-scroll-component";
import Refer from "../../../core/components/layout/Sidebar/Refer";
import add from "../../../assets/img/add-icon.svg";
import tick from "../../../assets/img/tick-icon.svg";
import { Link } from "react-router-dom";
import unfollow from "../../../assets/img/withd.png";
import Ad from "../../../core/components/layout/Sidebar/Ad";
import Chat from "../../../pages/chat/chat";
import UnfollowIcon from "../../../assets/img/realCross.png";

export default function Hashtag(props) {
  const {
    tabs,
    setTabs,
    suggestedTagList,
    follow_hashtag,
    followedTagList,
    myTagList,
    currentCount,
    loadMoreData,
  } = props;
  return (
    <Layout>
      <div className="col-lg-6">
        <div className="main-center mt-4">
          <div className="wrapper">
            <div className="container p-3">
              <div className="overflow">
                <ul className="nav nav-tabs" role="tablist">
                  <li className="nav-item">
                    <a
                      className="nav-link active"
                      data-bs-toggle="tab"
                      href="#suggested_tags"
                      onClick={() => setTabs("suggested_tags")}
                    >
                      Suggested Hastags
                    </a>
                  </li>
                  <li className="nav-item">
                    <a
                      className="nav-link"
                      data-bs-toggle="tab"
                      href="#followed_tags"
                      onClick={() => setTabs("followed_tags")}
                    >
                      Followed Hashtags({followedTagList?.length})
                    </a>
                  </li>
                  <li className="nav-item">
                    <a
                      className="nav-link"
                      data-bs-toggle="tab"
                      href="#my_tags"
                      onClick={() => setTabs("my_tags")}
                    >
                      My Hashtags
                    </a>
                  </li>
                </ul>
              </div>

              <div className="tab-content mt-20">
                <div id="suggested_tags" className="container tab-pane active">
                  <div className="networks">
                    <div className="main-center">
                      <div className="wrapper">
                        {/* Hashtags  */}
                        <div className="hashtag ">
                          <InfiniteScroll
                            dataLength={currentCount}
                            next={loadMoreData}
                            hasMore={true}
                            style={{ overflow: "visible" }}
                            // loader={<h4>Loading...</h4>}
                          >
                            <div className="row">
                              {suggestedTagList?.map((data, index) => {
                                return (
                                  <div className="col-lg-6" key={index}>
                                    <div className="card">
                                      <div className="mt-30 mr-20 ml-20 mb-20">
                                        <div className="d-flex gap-3 mb-20">
                                          <div className="hash"></div>
                                          <div className="header">
                                            <h4 className="text-container-120">
                                              <Link
                                                to={`/hashtag/feed/?keyword=${data?.name}`}
                                              >
                                                {data?.name}
                                              </Link>
                                            </h4>
                                            <p>
                                              {data?.post_count}
                                              {data?.post_count > 1
                                                ? " posts"
                                                : " post"}
                                            </p>
                                          </div>
                                        </div>
                                        {data?.follow_type === undefined ? (
                                          <div
                                            className="btn btn-light text-blue"
                                            onClick={() =>
                                              follow_hashtag(
                                                data?.id,
                                                data?.name,
                                                "follow"
                                              )
                                            }
                                          >
                                            <span className="mr-10">
                                              <img src={add} alt="" />
                                            </span>
                                            Follow
                                          </div>
                                        ) : (
                                          <div
                                            className="btn btn-light text-blue"
                                            onClick={() =>
                                              follow_hashtag(
                                                data?.id,
                                                data?.name,
                                                "unfollow"
                                              )
                                            }
                                          >
                                            <span className="mr-10">
                                              <img
                                                src={UnfollowIcon}
                                                alt=""
                                                className="followicon"
                                                width={15}
                                              />
                                            </span>
                                            Unfollow
                                          </div>
                                        )}
                                      </div>
                                    </div>
                                  </div>
                                );
                              })}
                            </div>
                          </InfiniteScroll>
                        </div>
                        {/* Hashtags  */}
                      </div>
                    </div>
                  </div>
                </div>
                <div id="followed_tags" className="container tab-pane fade">
                  <div className="networks">
                    <div className="main-center">
                      <div className="wrapper">
                        <div className="hashtag ">
                          <InfiniteScroll
                            dataLength={currentCount}
                            next={loadMoreData}
                            hasMore={true}
                            style={{ overflow: "visible" }}
                            // loader={<h4>Loading...</h4>}
                          >
                            <div className="row mt-20">
                              {followedTagList?.map((data, index) => {
                                return (
                                  <div className="col-lg-6" key={index}>
                                    <div className="card">
                                      <div className="mt-30 mr-20 ml-20 mb-20">
                                        <div className="d-flex gap-3 mb-20">
                                          <div className="hash"></div>
                                          <div className="header">
                                            <h4 className="text-container-120">
                                              <Link
                                                to={`/hashtag/feed/?keyword=${data?.name}`}
                                              >
                                                {data?.name}
                                              </Link>
                                            </h4>
                                            <p>
                                              {data?.post_count}{" "}
                                              {data?.post_count > 1
                                                ? "posts"
                                                : "post"}
                                            </p>
                                          </div>
                                        </div>
                                        {data?.follow_type === undefined ||
                                        data?.follow_type === true ? (
                                          <div
                                            className="btn btn-light text-blue"
                                            onClick={() =>
                                              follow_hashtag(
                                                data?.id,
                                                data?.name,
                                                "unfollow"
                                              )
                                            }
                                          >
                                            <img
                                              src={UnfollowIcon}
                                              style={{ marginRight: "6px" }}
                                              width={15}
                                            />
                                            Unfollow
                                          </div>
                                        ) : (
                                          <div
                                            className="btn btn-light text-blue"
                                            //  onClick={() =>
                                            //    follow_hashtag(data?.id, data?.name, "follow")
                                            //  }
                                          >
                                            <span className="mr-10">
                                              <img src={add} alt="" />
                                            </span>
                                            Follow
                                          </div>
                                        )}
                                      </div>
                                    </div>
                                  </div>
                                );
                              })}
                            </div>
                          </InfiniteScroll>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div id="my_tags" className="container tab-pane fade">
                  <div className="networks">
                    <div className="main-center">
                      <div className="wrapper">
                        <div className="hashtag ">
                          <div className="row mt-20">
                            {myTagList?.map((data, index) => {
                              return (
                                <div className="col-lg-6" key={index}>
                                  <div className="card">
                                    <div className="mt-30 mr-20 ml-20 mb-20">
                                      <div className="d-flex gap-3 mb-20">
                                        <div className="hash"></div>
                                        <div className="header">
                                          <h4 className="text-container-120">
                                            <Link
                                              to={`/hashtag/feed/?keyword=${data?.name}`}
                                            >
                                              {data?.name}
                                            </Link>
                                          </h4>
                                          <p>
                                            {data?.post_count}{" "}
                                            {data?.post_count > 1
                                              ? "posts"
                                              : "post"}
                                          </p>
                                        </div>
                                      </div>
                                      {data?.follow_type === undefined ? (
                                        <div
                                          className="btn btn-light text-blue"
                                          onClick={() =>
                                            follow_hashtag(
                                              data?.id,
                                              data?.name,
                                              "follow"
                                            )
                                          }
                                        >
                                          <span className="mr-10">
                                            <img src={add} alt="" />
                                          </span>
                                          Follow
                                        </div>
                                      ) : (
                                        <div
                                          className="btn btn-light text-blue"
                                          onClick={() =>
                                            follow_hashtag(
                                              data?.id,
                                              data?.name,
                                              "unfollow"
                                            )
                                          }
                                        >
                                          <img
                                            src={UnfollowIcon}
                                            style={{ marginRight: "6px" }}
                                            width={15}
                                          />
                                          Unfollow
                                        </div>
                                      )}
                                    </div>
                                  </div>
                                </div>
                              );
                            })}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="col-lg-3">
        <div className="dashboard mt-4">
          <div className="middle">
            <Advertisement />
          </div>

          <div style={{ marginTop: "26px" }}>
            <Ad />
          </div>
          <div className="mt-4">
            <Refer />
          </div>
        </div>
      </div>
      <Chat />
    </Layout>
  );
}
