import React, { useEffect, useState } from "react";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import editPencil from "../../assets/img/edit-pencil.svg";
import deleteLeadIcon from "../../assets/img/deleteLeadIcon.svg";
import { useDispatch, useSelector } from "react-redux";
import {
  DeleteJobAlert,
  editJobAlert,
  getJobAlert,
} from "../../store/jobs/jobAction";
import moment from "moment";
import DeleteModal from "../../core/components/modal/DeleteModal";
import { toast } from "react-toastify";
import AppLoader from "../../core/components/Loaders/appLoader/appLoader";
import Loader from "../../core/components/Loaders/ComponentLoader";

const JobAlertModal = (props) => {
  const { modal, toggle } = props;
  const dispatch = useDispatch();
  const { jobAlters } = useSelector((state) => state.jobs);
  const [isEditAlert, setIsEditAlert] = useState(false);
  const [closeModal, setCloseModal] = useState(false);
  const [selectedJobId, setSelectedJobId] = useState(false);
  const [frequencyDate, setFrequencyDate] = useState();
  const [customDate, setCustomDate] = useState(2);
  const [customDateError, setCustomDateError] = useState();
  const [singleAlertData, setSingleAlertData] = useState();
  const [notificationText, setNotificationText] = useState();
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    setLoading(true);
    dispatch(getJobAlert())
      .then((res) => setLoading(false))
      .catch((err) => setLoading(false));
  }, []);

  useEffect(() => {
    if (singleAlertData) {
      setFrequencyDate(singleAlertData?.frequency);
      setNotificationText(singleAlertData?.notification_by);
    }
  }, [singleAlertData]);

  const getTime = (date) => {
    let result = moment(date).fromNow();
    const now = moment();
    const hours = now.diff(date, "hours");
    const days = now.diff(date, "days");
    const weeks = now.diff(date, "weeks");
    if (hours >= 22 && hours <= 47) {
      result = `1 day ago`;
    } else if (days >= 7) {
      if (days <= 13) {
        result = `1 week ago`;
      } else if (days > 13 && days <= 25) {
        result = `${weeks} weeks ago`;
      }
    }
    return result;
  };

  const capitalizeFirstLetter = (string) => {
    return string?.charAt(0)?.toUpperCase() + string?.slice(1);
  };
  const deleteJobAlert = () => {
    dispatch(DeleteJobAlert(selectedJobId)).then((res) => {
      if (res?.success) {
        toast.success(res.message, {
          position: toast.POSITION.BOTTOM_LEFT,
        });
      }
    });
  };

  const handleCustom = (e) => {
    const value = e.target.value;
    // Ensure the entered value is a number and between 1 and 999
    if (/^\d+$/.test(value) && value >= 1 && value <= 999) {
      setCustomDate(value);
      setCustomDateError("");
    } else {
      setCustomDate("");
      setCustomDateError("Please enter a valid number between 1 and 999.");
    }
  };

  const handleDaily = (e) => {
    if (e.target.checked) {
      setFrequencyDate(1);
    }
  };
  const handleThreeDays = (e) => {
    if (e.target.checked) {
      setFrequencyDate(3);
    }
  };
  const handleWeekly = (e) => {
    if (e.target.checked) {
      setFrequencyDate(7);
    }
  };

  const handleCustomDays = (e) => {
    if (e.target.checked) {
      setFrequencyDate(customDate);
    }
  };
  const handleBoth = (e) => {
    if (e.target.checked) {
      setNotificationText("both");
    }
  };
  const handleEmail = (e) => {
    if (e.target.checked) {
      setNotificationText("email");
    }
  };
  const handleNotification = (e) => {
    if (e.target.checked) {
      setNotificationText("notification");
    }
  };

  useEffect(() => {
    console.log("frequencyDate", frequencyDate);
  }, [frequencyDate]);

  const handleEditAlert = () => {
    const data = {
      frequency: frequencyDate,
      notification_by: notificationText,
    };
    dispatch(editJobAlert(data, singleAlertData?.id)).then((res) => {
      if (res?.success) {
        setIsEditAlert(false);
        toast.success(res.message, {
          position: toast.POSITION.BOTTOM_LEFT,
        });
      }
    });
  };

  if (!isEditAlert) {
    return (
      <Modal
        isOpen={modal}
        toggle={toggle}
        size="md"
        style={{ maxWidth: "544px" }}
        centered
      >
        <div className="job-alerts-pop-up search-default-pop-up">
          <ModalHeader toggle={() => toggle()}>Job Alerts</ModalHeader>
          <ModalBody>
            {loading ? (
              <Loader />
            ) : (
              <div className="search-default-pop-up-inner">
                {jobAlters?.map((item, index) => (
                  <div className="job-alerts-list-outer" key={index}>
                    <div className="left-list">
                      <h4>{capitalizeFirstLetter(item?.search)}</h4>
                      {item?.location && <h5>{item?.location}</h5>}
                      <span className="time">
                        Posted {getTime(item?.updatedAt)}
                      </span>
                      {(item?.employment_type || item?.workspace_type) && (
                        <span className="filters d-flex">
                          Filters:
                          <ul
                            className="d-flex"
                            // style={{ listStyle: "initial" }}
                          >
                            <li className="mx-1">{item?.employment_type}</li>
                            {item?.workspace_type && (
                              <li>• {item?.workspace_type}</li>
                            )}
                          </ul>
                          {/* <br /> */}
                        </span>
                      )}
                      <span>
                        {" "}
                        {item?.frequency == 1 &&
                          `Frequency: Daily via ${
                            item?.notification_by === "both"
                              ? "email and notification"
                              : item?.notification_by
                          }`}
                        {item?.frequency > 1 &&
                          `Frequency: ${item?.frequency} days via ${
                            item?.notification_by === "both"
                              ? "email and notification"
                              : item?.notification_by
                          }`}
                      </span>
                    </div>
                    <div className="right-edit d-flex ">
                      <span
                        className="edit"
                        onClick={() => {
                          setIsEditAlert(item?.id);
                          setSingleAlertData(item);
                        }}
                      >
                        <img src={editPencil} alt="Image" />
                      </span>
                      <span
                        className="delete mx-1"
                        onClick={() => {
                          setCloseModal(true);
                          setSelectedJobId(item?.id);
                        }}
                      >
                        <img src={deleteLeadIcon} alt="Image" />
                      </span>
                    </div>
                  </div>
                ))}
                <div className="blue-btn-outer end">
                  <button className="blue-btn" onClick={() => toggle()}>
                    Done
                  </button>
                </div>
              </div>
            )}
          </ModalBody>
          {closeModal && (
            <DeleteModal
              modal={DeleteModal}
              toggle={() => setCloseModal(!closeModal)}
              title="Delete Confirmation"
              deleteConfirm={() => {
                deleteJobAlert();
              }}
              text="Are you want to sure to delete this job alert?"
            />
          )}
        </div>
      </Modal>
    );
  } else {
    return (
      <Modal
        isOpen={modal}
        toggle={() => setIsEditAlert(false)}
        size="md"
        style={{ maxWidth: "544px" }}
        centered
      >
        <div className="job-alerts-pop-up search-default-pop-up">
          <ModalHeader toggle={() => setIsEditAlert(false)}>
            Manage job alert
          </ModalHeader>

          <ModalBody>
            <div className="search-default-pop-up-inner">
              <div className="job-alerts-list-outer">
                <div className="left-list">
                  <h4>{capitalizeFirstLetter(singleAlertData?.search)}</h4>
                  {singleAlertData?.location && (
                    <h5>{singleAlertData?.location}</h5>
                  )}
                  <div className="radio-list">
                    <h5 className="text-secondary">Alert frequency</h5>
                    <div className="radio-list-item">
                      <input
                        style={{ height: "24px" }}
                        type="radio"
                        className="form-check-input"
                        name="radio"
                        id="daily"
                        checked={frequencyDate == 1 ? true : false}
                        onChange={(e) => {
                          handleDaily(e);
                        }}
                      />
                      <label for="daily">Daily</label>
                    </div>
                    <div className="radio-list-item">
                      <input
                        style={{ height: "24px" }}
                        type="radio"
                        className="form-check-input"
                        name="radio"
                        id="every"
                        checked={frequencyDate == 3 ? true : false}
                        onChange={(e) => handleThreeDays(e)}
                      />
                      <label for="every">Every 3 days</label>
                    </div>
                    <div className="radio-list-item">
                      <input
                        style={{ height: "24px" }}
                        type="radio"
                        className="form-check-input"
                        name="radio"
                        id="custom"
                        checked={
                          frequencyDate != 1 &&
                          frequencyDate != 3 &&
                          frequencyDate != 7
                            ? true
                            : false
                        }
                        onChange={(e) => handleCustomDays(e)}
                      />
                      <label for="custom">
                        Custom{" "}
                        <input
                          type="number"
                          className="days"
                          min="1"
                          max="999"
                          pattern="\d{1,3}"
                          value={customDate}
                          onChange={(e) => handleCustom(e)}
                        />{" "}
                        days
                      </label>
                    </div>
                    {customDateError && (
                      <div
                        className="error-message"
                        style={{
                          color: "red",
                          paddingLeft: "10px",
                          paddingTop: "10px",
                        }}
                      >
                        {customDateError}
                      </div>
                    )}
                    <div className="radio-list-item">
                      <input
                        style={{ height: "24px" }}
                        type="radio"
                        className="form-check-input"
                        name="radio"
                        id="weekly"
                        checked={frequencyDate == 7 ? true : false}
                        onChange={(e) => handleWeekly(e)}
                      />
                      <label for="weekly">Weekly</label>
                    </div>
                  </div>

                  <div className="radio-list">
                    <h5 className="text-secondary">Get notified via</h5>
                    <div className="radio-list-item">
                      <input
                        style={{ height: "24px" }}
                        type="radio"
                        className="form-check-input"
                        name="notified"
                        id="email"
                        checked={notificationText === "both" ? true : false}
                        onChange={(e) => handleBoth(e)}
                      />
                      <label for="notification">Email and notification</label>
                    </div>
                    <div className="radio-list-item">
                      <input
                        style={{ height: "24px" }}
                        type="radio"
                        className="form-check-input"
                        name="notified"
                        id="email"
                        checked={notificationText === "email" ? true : false}
                        onChange={(e) => handleEmail(e)}
                      />
                      <label for="email">Email</label>
                    </div>
                    <div className="radio-list-item">
                      <input
                        style={{ height: "24px" }}
                        type="radio"
                        className="form-check-input"
                        name="notified"
                        id="notification"
                        checked={
                          notificationText === "notification" ? true : false
                        }
                        onChange={(e) => handleNotification(e)}
                      />
                      <label for="notification">Notification</label>
                    </div>
                  </div>
                </div>
              </div>
              <div className="blue-btn-outer end">
                <button
                  className="white-btn"
                  onClick={() => setIsEditAlert(false)}
                >
                  Back
                </button>
                <button className="blue-btn" onClick={() => handleEditAlert()}>
                  Save
                </button>
              </div>
            </div>
          </ModalBody>
        </div>
      </Modal>
    );
  }
};

export default JobAlertModal;
