import React from "react";
import logo from "../../../assets/img/np-logo.svg";
import blog from "../../../assets/img/blog.png";
import { useSelector } from "react-redux";

export default function BlogHeader({blogData}) {
  const { isWebView } = useSelector((state) => state.auth);
  

  return (
    <div className="bgNew">
      <div className="container">
        <div className="row">
          <div className="col-sm-8 margin-top">
            <h1>Blogs</h1>
            <p style={{marginRight:"30px", marginTop:"30px"}}>
            {blogData?.subtitle}
            </p>
          </div>
          <div className="col-sm-4">
            {isWebView ? <></> : <img src={blog} alt="" className="bg-image" />}
          </div>
        </div>
      </div>
      <div className="n-logo">
        <img src={logo} alt="" width="100%" />
      </div>
    </div>
  );
}
