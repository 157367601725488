import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import ShoppingIcon from "../../../../assets/img/shopping.svg";
import ProductServiceModel from "../../../dashboard/components/ProductServiceModel";
const PageProduct = ({ productData, isPageEdit, pageID }) => {
  const navigate = useNavigate();
  const [edit, setEdit] = useState(false);
  const [data, setData] = useState({});
  console.log(productData);
  return (
    <>
      <div className="container">
        {productData?.map((ele) => {
          return (
            <div className="py-2">
              {isPageEdit ? (
                <div className="d-flex  w-100 p-2">
                  <div
                    className="d-flex justify-content-center align-items-center rounded p-4"
                    style={{
                      background: "#0432A31A",
                    }}
                  >
                    <img src={ShoppingIcon} className="rounded" />
                  </div>
                  <div className="d-flex justify-content-between w-100">
                    <div className="mx-4">
                      <h5>{ele?.title}</h5>
                      {ele.link?.length && (
                        <Link to={ele.link[0]}>
                          {ele.link[0]?.length > 35
                            ? ele.link[0]?.slice(0, 35) + "..."
                            : ele.link[0]}
                        </Link>
                      )}
                    </div>
                    <div
                      style={{
                        border: "1px solid #0432A3",
                        height: "fit-content",
                        borderRadius: "80px",
                      }}
                      className="bg-transparent p-2"
                    >
                      <button
                        onClick={() => {
                          setEdit(true);
                          setData(ele);
                        }}
                        className="bg-transparent"
                        style={{ color: "#0432A3" }}
                      >
                        Manage
                      </button>
                    </div>
                  </div>
                </div>
              ) : (
                <>
                  <div className="p-2">
                    <div className="d-flex align-items-center w-100 ">
                      <div className="d-flex justify-content-center align-items-center rounded ">
                        <img
                          src={ele?.icon}
                          className="rounded"
                          width={100}
                          height={100}
                        />
                      </div>
                      <div className="d-flex justify-content-between w-100">
                        <div className="mx-4">
                          <h5>{ele?.title}</h5>
                          <p className="text-capitalize">{ele?.category}</p>
                        </div>
                        <div
                          style={{
                            border: "1px solid #0432A3",
                            height: "fit-content",
                            borderRadius: "80px",
                          }}
                          className="bg-transparent p-2"
                        >
                          <a href={ele?.link[0]} target={"_blank"}>
                            Visit Product
                          </a>
                        </div>
                      </div>
                    </div>

                    <p className="mt-2 mb-3">{ele.about}</p>
                    <Link to={ele.link[0]}>{ele.link[0]}</Link>

                    <div className="d-flex justify-content-between align-items-center mt-3">
                      {ele?.images?.map((ele) => {
                        return (
                          <>
                            <img
                              src={ele}
                              width={120}
                              height={80}
                              className="rounded"
                            />
                          </>
                        );
                      })}
                    </div>
                  </div>
                </>
              )}

              <div
                className="mt-3"
                style={{ width: "100%", height: "2px", background: "#D1DFFF" }}
              ></div>
            </div>
          );
        })}
      </div>

      {edit && (
        <ProductServiceModel
          modal={edit}
          toggle={() => setEdit(!edit)}
          page_id={pageID}
          isProductEdit={edit}
          productData={data}
        />
      )}
    </>
  );
};

export default PageProduct;
