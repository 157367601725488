import React from "react";
import Layout from "../../Layout/Layout";
import moment from "moment";
import CreateEventModal from "../components/createEvent";
import ShareModal from "../../../../core/components/sharePost/sharePost";
import placeholderUser from "../../../../assets/img/placeholder_user.jpg";
import DeleteModal from "../../../../core/components/modal/DeleteModal";
import link from "../../../../assets/img/link.svg";
import share from "../../../../assets/img/share-rec.svg";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";

const EventDetails = (props) => {
  const {
    event_detail,
    openCreateModal,
    setOpenCreateModal,
    editEventData,
    setEditEventData,
    user,
    attendHandle,
    openShareModal,
    setOpenShareModal,
    shareData,
    setShareData,
    cancelledEvent,
    pinHandleRelatedEvent,
    navigate,
    copyToClipboard,
    cancelModal,
    setCancelModal,
    dropDownRef,
    shareDropdown,
    setShareDropdown,
  } = props;

  return (
    <React.Fragment>
      <Layout>
        <div className="col-lg-9">
          <div className="main-center event-details">
            <div className="wrapper">
              <div className="box-img">
                <div className="img">
                  <img
                    src={event_detail[0]?.media}
                    className="img_event_detail"
                    alt=""
                  />
                </div>
                <div className="calendar">
                  <div className="day">
                    {moment(event_detail[0]?.event_time).format("dddd")}
                  </div>
                  <div className="date">
                    {moment(event_detail[0]?.event_time).format("ll")}{" "}
                  </div>
                </div>
              </div>
            </div>

            <div className="wrapper mt-26 p-20">
              <div className="section ">
                <div className="heading">
                  <div className="row">
                    <div className="col-lg-6 mb-3">
                      <h2>{event_detail[0]?.title}</h2>
                    </div>
                    {console.log(event_detail)}
                    <div className="col-lg-6 mb-3">
                      <div className="float-lg-end">
                        <div className="d-flex gap-3">
                          {user?.data?.id !== event_detail[0]?.User?.id && (
                            <div>
                              {event_detail[0]?.Attend_Event?.status ? (
                                <div
                                  className="btn btn-light text-blue px-4"
                                  onClick={() =>
                                    attendHandle(
                                      event_detail[0]?.id,
                                      "attendFalse"
                                    )
                                  }
                                >
                                  Leave Event
                                </div>
                              ) : (
                                <div
                                  className="btn px-4"
                                  onClick={() =>
                                    attendHandle(
                                      event_detail[0]?.id,
                                      "attendTrue"
                                    )
                                  }
                                >
                                  Attend
                                </div>
                              )}
                            </div>
                          )}

                          <div className="bell-icon"></div>
                          <div
                            ref={dropDownRef}
                            className="share-icon"
                            onClick={() => setShareDropdown(!shareDropdown)}
                          >
                            <div className="drop">
                              {shareDropdown && (
                                <div className="dropDownBox">
                                  <ul>
                                    <li>
                                      <img src={share} alt="" />{" "}
                                      <span
                                        onClick={() => {
                                          setOpenShareModal(!openShareModal);
                                          setShareData(event_detail[0]);
                                        }}
                                      >
                                        Share on feed
                                      </span>
                                    </li>
                                    <li>
                                      <img src={link} alt="" />{" "}
                                      <span
                                        onClick={() => {
                                          navigator.clipboard.writeText(
                                            window.location.href
                                          );
                                          toast.success(
                                            "Copied link to clipboard!",
                                            {
                                              position:
                                                toast.POSITION.BOTTOM_LEFT,
                                            }
                                          );
                                          setShareDropdown(false);
                                        }}
                                      >
                                        Copy event link
                                      </span>
                                    </li>
                                  </ul>
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="virtual">
                  <div className="row">
                    <div className="col-lg-12">
                      <div className="btn">
                        {event_detail[0]?.event_type === "physical"
                          ? "Physical"
                          : "Virtual"}
                      </div>
                      {event_detail[0]?.event_link ? (
                        <div className="link">
                          {event_detail[0]?.event_link}
                          <span
                            className="copy"
                            onClick={() =>
                              copyToClipboard(event_detail[0]?.event_link)
                            }
                          ></span>
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                </div>
                <div className="content">
                  <div className="row mt-20">
                    <div className="col-lg-12">
                      <div className="content">
                        <p>{event_detail[0]?.description}</p>
                        <div className="work"></div>
                      </div>
                      <div className="technology mt-20 mb-20">
                        <ul>
                          <li>
                            <span className="task"></span>
                            {event_detail[0]?.industry}
                          </li>
                          <li>
                            <span className="calender"></span>
                            {moment(event_detail[0]?.event_time).format(
                              "ddd, MMM DD, YYYY"
                            )}{" "}
                            at{" "}
                            {moment(event_detail[0]?.event_time).format(
                              "hh:mm A z"
                            )}
                          </li>
                          <li>
                            <span className="location"></span>
                            {event_detail[0]?.location}
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row events-add ml-5">
                  <div className="col-lg-6">
                    <div className="people-pic">
                      <div className="d-flex me-2">
                        <div className="profiles-img">
                          {event_detail[0]?.Event_Participant?.map(
                            (data, idx) => {
                              return (
                                idx < 3 && (
                                  <span key={idx}>
                                    <img
                                      src={
                                        data?.user?.profile_img
                                          ? data?.user?.profile_img
                                          : placeholderUser
                                      }
                                      className="image-fit"
                                      alt=""
                                    />
                                  </span>
                                )
                              );
                            }
                          )}
                          {event_detail[0]?.Event_Participant?.length > 3 ? (
                            <span>
                              +{event_detail[0]?.Event_Participant?.length - 3}
                            </span>
                          ) : (
                            <span>
                              {event_detail[0]?.Event_Participant?.length}
                            </span>
                          )}
                        </div>
                      </div>

                      <div>
                        {" attending"}
                        {/* {event_detail[0]?.Event_Participant?.length > 1
                          ? "are attending"
                          : "is attending"
                          } */}
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="float-xl-end">
                      {user?.data?.id === event_detail[0]?.User?.id &&
                      event_detail[0]?.event_status === "published" ? (
                        <div
                          className="btn"
                          onClick={() => setCancelModal(!cancelModal)}
                        >
                          Cancel Event
                        </div>
                      ) : event_detail[0]?.event_status === "cancelled" ? (
                        <div className=" btn label-input100 text-danger">
                          Cancelled
                        </div>
                      ) : (
                        <></>
                      )}
                      {user?.data?.id === event_detail[0]?.User?.id && (
                        <div
                          className={
                            event_detail[0]?.event_status === "cancelled"
                              ? " btn border-btn-square-not-allowed"
                              : "btn border-btn-square"
                          }
                          onClick={
                            event_detail[0]?.event_status === "cancelled"
                              ? null
                              : () => {
                                  setOpenCreateModal(!openCreateModal);
                                  setEditEventData(event_detail[0]);
                                }
                          }
                        >
                          Edit Event
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-lg-12 mt-3">
                    <h4 className="related text-dark">Related events</h4>
                    <div className="networks">
                      <div className="wrapper">
                        <div className="events">
                          <div className="row mt-20">
                            {event_detail[0]?.Related_Events?.map(
                              (item, idx) => (
                                <div className="col-lg-4" key={idx}>
                                  <div className="card pb-3">
                                    <div className="img-section">
                                      <div className="card-img">
                                        <img
                                          src={
                                            item?.media
                                              ? item.media
                                              : "https://img.freepik.com/free-photo/pleasant-looking-serious-man-stands-profile-has-confident-expression-wears-casual-white-t-shirt_273609-16959.jpg"
                                          }
                                          className="event-card-image"
                                          alt=""
                                        />
                                      </div>
                                      {item?.media_type === "physical" ? (
                                        <div className="tag tag-color-physical">
                                          Physical
                                        </div>
                                      ) : (
                                        <div className="tag tag-color-virtual">
                                          Virtual
                                        </div>
                                      )}
                                      {item?.Pinned_Event?.status ? (
                                        <div
                                          className="unpin"
                                          onClick={() =>
                                            pinHandleRelatedEvent(
                                              event_detail[0].id,
                                              item.id,
                                              "pinFalse"
                                            )
                                          }
                                        ></div>
                                      ) : (
                                        <div
                                          className="pin"
                                          onClick={() =>
                                            pinHandleRelatedEvent(
                                              event_detail[0].id,
                                              item.id,
                                              "pinTrue"
                                            )
                                          }
                                        ></div>
                                      )}
                                    </div>
                                    <div className="mr-20 ml-20 ">
                                      <div className="header mt-30 card-event-control">
                                        <h4
                                          onClick={() => {
                                            navigate(`/eventdetail/${item.id}`);
                                          }}
                                        >
                                          {item.title}
                                        </h4>
                                      </div>
                                      <div className="date">
                                        <div className="calender-icon"></div>{" "}
                                        {moment(item?.event_time).format(
                                          "ddd, MMM DD, YYYY"
                                        )}{" "}
                                        at{" "}
                                        {moment(item?.event_time).format(
                                          "hh:mm A z"
                                        )}
                                      </div>
                                      <div className="location">
                                        <div className="icon"></div>
                                        <span className="card-control-text-oneline">
                                          {item?.location
                                            ?.split(",")
                                            ?.splice(-1)}
                                        </span>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              )
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <ToastContainer />
          </div>
        </div>
        <ToastContainer />
      </Layout>
      {openCreateModal && (
        <CreateEventModal
          modal={openCreateModal}
          toggle={() => setOpenCreateModal(!openCreateModal)}
          title="Create Event"
          editEventData={editEventData}
          setEditEventData={setEditEventData}
          source="network"
        />
      )}
      {openShareModal && (
        <ShareModal
          modal={openShareModal}
          toggle={() => setOpenShareModal(!openShareModal)}
          title="Share"
          shareData={shareData}
          type="event"
          source="eventDetails"
        />
      )}
      {cancelModal && (
        <DeleteModal
          modal={DeleteModal}
          toggle={() => setCancelModal(!cancelModal)}
          title="Cancel Event"
          deleteConfirm={() => cancelledEvent(event_detail[0]?.id)}
          text="Are you want to sure to cancel this event?"
        />
      )}
    </React.Fragment>
  );
};
export default EventDetails;
