import React, { useState } from "react";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import tickIcon from "../../../../assets/img/tick-icon.svg";
import pagePlaceholder from "../../../../assets/img/page-placeholder.svg";
import confidential from "../../../../assets/img/confidentialIcon.svg";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getJobs,
  shortListCandidateForJobs,
} from "../../../../store/jobs/jobAction";
import { toast } from "react-toastify";
import InfiniteScroll from "react-infinite-scroll-component";

const ShortlistModal = (props) => {
  const { modal, toggle, shortListUser } = props;

  const [shortlistedFor, setShortlistedFor] = useState([]);
  const dispatch = useDispatch();
  const { getMyJobs } = useSelector((state) => state.jobs);
  const [currentMyJobCount, setCurrentMyJobCount] = useState(0);
  const [totalMyJobCount, setTotalMyJobCount] = useState(0);
  const [isMoreMyJob, setIsMoreMyJob] = useState();
  const loadMoreMyJob = () => {
    setTimeout(() => {
      setCurrentMyJobCount(currentMyJobCount + 10);
    }, 1000);
  };
  useEffect(() => {
    if (getMyJobs?.length < totalMyJobCount) setIsMoreMyJob(true);
    else setIsMoreMyJob(false);
  }, [currentMyJobCount, totalMyJobCount]);

  useEffect(() => {
    dispatch(
      getJobs(
        {
          filter: "my",
          shortlist_id: shortListUser,
          status: "active",
          limit: 10,
          skip: currentMyJobCount,
        },
        currentMyJobCount,
        "my"
      )
    ).then((res) => {
      setTotalMyJobCount(res.totalItem);
    });
  }, [currentMyJobCount]);

  const handleSubmit = () => {
    if (shortlistedFor.length < 1) {
      toast.error("Please select jobs", {
        position: toast.POSITION.BOTTOM_LEFT,
      });
      return;
    } else {
      const data = {
        job_ids: shortlistedFor,
        user_id: shortListUser,
      };
      dispatch(shortListCandidateForJobs(data)).then((res) => {
        if (res.success) {
          toast.success(res.message, {
            position: toast.POSITION.BOTTOM_LEFT,
          });
        }
        toggle();
      });
    }
  };

  const handleCheck = (id) => {
    setShortlistedFor([...shortlistedFor, id]);
  };

  const clearAll = () => {
    setShortlistedFor([]);
    document
      .querySelectorAll("input[type=checkbox]")
      .forEach((el) => (el.checked = false));
  };

  const handleUnchecked = (id) => {
    setShortlistedFor(shortlistedFor.filter((el, i) => el !== id));
  };

  // useEffect(() => {
  //   console.log("shortlistedFor", shortlistedFor.length,shortlistedFor);
  // }, [shortlistedFor]);

  return (
    <Modal size="md" isOpen={modal} style={{ maxWidth: "600px" }} centered>
      <div className="shortlist-modal">
        <ModalHeader toggle={() => toggle()}>
          Shortlist this Candidate for
        </ModalHeader>
        <ModalBody>
          <div className="shortlist-main">
            <span onClick={() => clearAll()}>Clear all selections</span>
            <div id="scrollableDiv">
              <InfiniteScroll
                dataLength={getMyJobs?.length ? getMyJobs?.length : 0}
                next={loadMoreMyJob}
                hasMore={isMoreMyJob}
                style={{ overflow: "visible" }}
                scrollableTarget="scrollableDiv"
              >
                <>
                  {getMyJobs?.map((item, index) => (
                    <div
                      key={index}
                      className="details d-flex justify-content-between align-items-center mb-20 mt-20"
                    >
                      {item?.shortlistedJob ? (
                        <img src={tickIcon} alt="tickIcon" />
                      ) : (
                        <input
                          type="checkbox"
                          className="user-checkbox"
                          name="user"
                          id="check-box"
                          // id={i.id}
                          onChange={(e) => {
                            if (shortlistedFor.length >= 10) {
                              toast.error("Can't select more than 10 jobs", {
                                position: toast.POSITION.BOTTOM_LEFT,
                              });
                              e.target.checked = false;
                              return;
                            } else {
                              if (e.target.checked) handleCheck(item?.id);
                              else handleUnchecked(item?.id);
                            }
                          }}
                        />
                      )}
                      <div
                        className="d-flex gap-4 align-items-center"
                        style={{ width: "90%" }}
                      >
                        <div className="">
                          <img
                            src={
                              item?.company_visible
                                ? item?.company?.icon
                                  ? item?.company?.icon
                                  : pagePlaceholder
                                : confidential
                            }
                            // src={i?.profile_img ? i?.profile_img : placeholderUser}
                            className="round-image"
                            alt="profile_icon"
                          />
                        </div>
                        <div className="names">
                          <h6>{item?.title}</h6>
                          {/* <p>{i?.profile?.profile_headline} </p> */}
                          <p>
                            {item?.company_name}
                            <span
                              style={{ fontSize: "15px", color: "#676a79" }}
                            >
                              .
                            </span>
                            &nbsp;{item?.company_location}
                          </p>
                        </div>
                      </div>
                    </div>
                  ))}
                </>
              </InfiniteScroll>
            </div>
            <div className="button mt-30 text-center">
              <button className="btn btn-blue" onClick={() => handleSubmit()}>
                <div className="d-flex align-items-center">Done</div>
              </button>
            </div>
          </div>
        </ModalBody>
      </div>
    </Modal>
  );
};

export default ShortlistModal;
