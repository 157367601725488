import React, { useState, useEffect } from "react";
import Navbar from "../../../pages/navbar/navbar";
import "./PostJob.scss";
import EditorBox from "./components/JobEditor";
import backArrow from "../../../assets/img/backArrow.svg";
import closeButton from "../../../assets/img/Closeicon.svg";
import JobPreview from "./components/JobPreview";
import JobPostSuccessful from "./components/SucessfulModal";
import { useLocation } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import { FaCircle } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import {
  createJobPostAction,
  updateJobForm,
} from "../../../store/jobs/jobAction";
import TagsInput from "../../../core/components/HashTags/tagsInput";
import { getLocationsData } from "../../../store/connections/connectionsActions";
import Autocomplete from "react-autocomplete";
import { Input } from "reactstrap";

import plus from "../../../assets/img/plus-blue.svg";
import { call } from "../../../api/apiCall";
import Chat from "../../../pages/chat/chat";

export default function PostJob(props) {
  // console.log(props);
  const { user } = useSelector((state) => state?.auth);
  const isWorker = user?.data?.worker;

  const [screeningOptions, setScreeningOptions] = useState([
    {
      label: "Education",
      questions:
        "Have you completed the following level of education: [Degree]?",
      selected: false,
      dynamic_key: "Degree",
      ideal_type: "number",
      ideal_value: "1",
    },
    {
      label: "Language",
      questions: "What is your level of proficiency in Language?",
      selected: false,
      dynamic_key: "Language",
      ideal_type: "langauage",
      ideal_value: "Converstaitional",
    },
    {
      label: "Urgent Hiring Needed",
      questions:
        "We must fill this position urgently. Can you start immediately?",
      selected: false,
      dynamic_key: null,
      ideal_type: "boolean",
      ideal_value: "true",
    },
    {
      label: "Remote Work",
      questions: "Are you comfortable working in a remote setting?",
      selected: false,
      dynamic_key: null,
      ideal_type: "boolean",
      ideal_value: "true",
    },
    {
      label: "Onsite",
      questions: "Are you comfortable working Onsite project?",
      selected: false,
      dynamic_key: null,
      ideal_type: "boolean",
      ideal_value: "true",
    },
    {
      label: "Location",
      questions: "Are you ready to relocate?",
      selected: false,
      dynamic_key: null,
      ideal_type: "boolean",
      ideal_value: "true",
    },
    {
      label: "Hybrid Work",
      questions: "Are you comfortable with working in Hybrid mode?",
      selected: false,
      dynamic_key: null,
      ideal_type: "boolean",
      ideal_value: "true",
    },
    {
      label: "Work Experience",
      questions: "Do you have any work experience?",
      selected: false,
      dynamic_key: null,
      ideal_type: "number",
      ideal_value: "1",
    },

    // {Are you available to start immediately?
    //   label: 'Custom',
    //   questions: '',
    //   selected: false,
    //   // dynamic_key: null,
    //   ideal_type: "boolean",
    //   ideal_value: true
    // },
  ]);

  const [jobscreeningQuestions, setJobScreeningQuestions] = useState([]);
  const [searchFieldValue, setSearchFieldValue] = useState("");
  const [locationsAPIData, setLocationsAPIData] = useState([]);
  const [selectedJobType, setSelectedJobType] = useState("");
  const [selectedSalary, setSelectedSalary] = useState("");
  const [degreeList, setDegreeList] = useState("");

  const [locationOpen, setLocationOpen] = useState(false);
  const [input, setInput] = useState("");

  const [pageDetails, setPageDetails] = useState({
    location: searchFieldValue,
  });
  const [showAddInput, setShowAddInput] = useState();
  const [arr, setArr] = useState([]);
  const [selected, setSelected] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [jobDescription, setJobDescription] = useState();
  const [skillsError, setSkillsError] = useState("");
  const [locationError, setLocationError] = useState("");
  const [selectedMinExperience, setSelectedMinExperience] = useState("0");
  const [selectedMaxExperience, setSelectedMaxExperience] = useState(0);
  const [experienceError, setExperienceError] = useState("");
  const [minexperienceError, setMinExperienceError] = useState("");
  const [maxExperienceError, setMaxExperienceError] = useState("");

  const [salaryError, setSalaryError] = useState("");
  const [minsalaryError, setMinsalaryError] = useState("");
  const [addSkillsError, setAddSkillsError] = useState("");
  const [selectedApplyOption, setSelectedApplyOption] = useState("smartApply");
  const [externalLink, setExternalLink] = useState("");
  const [placeJobOnWorkerPool, setPlaceJobOnWorkerPool] = useState(false);

  const [selectedMinSalary, setSelectedMinSalary] = useState("");
  const [selectedMaxSalary, setSelectedMaxSalary] = useState("");
  const [coverLetterSelected, setCoverLetterSelected] = useState(false);
  const [selectedLocation, setSelectedLocation] = useState("");

  const [createdJobData, setCreatedJobData] = useState();
  console.log(createdJobData, "createdJobData");
  useEffect(() => {
    (async () => {
      try {
        const res = await call("get", "api/v1/degree", null, null);
        setDegreeList(res.data);
      } catch (err) {
        console.log(err);
      }
    })();
  }, []);

  const handleMinSalaryChange = (e) => {
    setSelectedMinSalary(e.target.value);
    setSalaryError("");
    const selectedLabel = e.target.options[e.target.selectedIndex].text;
    setSelectedMinSalaryLabel(selectedLabel);
    const selectedMin = parseInt(e.target.value, 10);
    const selectedMax = parseInt(selectedMaxSalary, 10);

    if (selectedMax < selectedMin) {
      setSalaryError(
        "Max salary should be greater than or equal to Min salary"
      );
      console.log(salaryError);
    } else {
      setSalaryError("");
    }
    //handleValidation();
    setMinsalaryError("");
  };

  const handleMaxSalaryChange = (e) => {
    setSalaryError("");
    setSelectedMaxSalary(e.target.value);
    //handleValidation();
    //const selectedMax = e.target.value;
    // console.log(selectedMax);

    handleValidation();

    const selectedMin = parseInt(selectedMinSalary, 10);
    const selectedMax = parseInt(e.target.value, 10);

    if (selectedMax < selectedMin) {
      setSalaryError(
        "Max salary should be greater than or equal to Min salary"
      );
      console.log(salaryError);
    } else {
      setSalaryError("");
    }
    const selectedLabel = e.target.options[e.target.selectedIndex].text;
    setSelectedMaxSalaryLabel(selectedLabel);
    // setSalaryError("");
    // handleValidation();
  };

  // useEffect(()=>{
  //   handleValidation();
  // },[selectedMaxSalary]);

  const handleCheckboxChange = () => {
    setPlaceJobOnWorkerPool(!placeJobOnWorkerPool);
  };

  //trying to get data from RecruiterModal.js

  const location = useLocation();
  const {
    title,
    company,
    listImage,
    employmentType,
    companyLocation,
    companyVisible,
    companyId,
    numberOfVacancies,
    workspacetype,
  } = location.state || {};

  console.log(location?.state);

  // const handleInputChange = (field, value) => {
  //   setFormData({
  //     ...formData,
  //     [field]: value,
  //   });
  // };
  const [screeningError, setScreeningError] = useState("");

  const handleScreeningQuestion = (index, data) => {
    setScreeningError("");
    let questions = jobscreeningQuestions.slice();
    if (questions[index])
      questions[index] = {
        ...questions[index],
        [data.field]: data.value,
      };
    setJobScreeningQuestions(questions);
  };

  const handleIdealTypeQuestion = (index, ideal_type, ideal_value) => {
    setScreeningError("");
    let questions = jobscreeningQuestions.slice();
    if (questions[index])
      questions[index] = {
        ...questions[index],
        ["ideal_type"]: ideal_type,
        ["ideal_value"]: ideal_value,
      };
    setJobScreeningQuestions(questions);
  };

  const AddScreeningQuestion = (index, data) => {
    let options = screeningOptions.slice();
    if (index < options?.length) {
      if (options[index])
        options[index] = {
          ...options[index],
          selected: true,
        };
      setScreeningOptions(options);
    }

    setJobScreeningQuestions([
      ...jobscreeningQuestions,
      {
        label: data.label,
        questions: data.questions,
        dynamic_key: data.dynamic_key,
        dynamic_value: null,
        ideal_type: data.ideal_type,
        ideal_value: data.ideal_value,
        required: true,
      },
    ]);
  };

  const removeScreeningQuestion = (index) => {
    let questions = jobscreeningQuestions.slice();
    let optionIndex = screeningOptions.findIndex(
      (value) => value.label === jobscreeningQuestions[index].label
    );
    let options = screeningOptions.slice();
    if (options[optionIndex])
      options[optionIndex] = {
        ...options[optionIndex],
        selected: false,
      };
    setScreeningOptions(options);

    questions.splice(index, 1);
    setJobScreeningQuestions(questions);
  };

  const dispatch = useDispatch();
  // let token = window.localStorage.getItem("accessToken");
  const {
    convertedContent,
    setConvertedContent,
    dbFormat,
    setDbFormat,
    navigate,
    previewModal,
    setPreviewModal,
    successfulModal,
    setSuccessfulModal,
  } = props;

  const handleChange = (e) => {
    const trimmedValue = e.target.value.trim(); // Trim the input value

    setInput(trimmedValue);

    if (trimmedValue !== "") {
      setArr([trimmedValue]);
    } else {
      setArr([]);
    }
  };

  //navigate to Recruiter Modal

  const handleBackButtonClick = () => {
    console.log("Back button is clicked");
    dispatch(updateJobForm({ jobType: "edit" }));
    navigate("/jobs", {
      state: {
        isPost: true,
        title: title,
        company: company,
        listImage: listImage,
        employmentType: employmentType,
        companyLocation: companyLocation,
        companyVisible: companyVisible,
        companyId: companyId,
        numberOfVacancies: numberOfVacancies,
        workspacetype: workspacetype,
      },
    });
  };

  const [skillsLengthError, setSkillsLengthError] = useState("");
  const handleKeyDown = (event) => {
    if (event.key === "Enter" && event.target.value != "") {
      console.log("selected..", selected.length);
      if (selected.length < 10) {
        setSelected((selected) => [...selected, event.target.value]);
        setInput("");
        setArr([]);
        setShowAddInput(false);
        setSkillsLengthError("");
      } else {
        setShowAddInput(false);
        setSkillsLengthError("Cannot add more than 10 skills");
        setInput("");
      }
    }
  };

  useEffect(() => {
    if (selected.length > 0) {
      setSkillsError("");
    }
  }, [selected, selectedMinSalary, selectedMaxSalary]);

  const removeItem = (item) => {
    setSelected((current) => current.filter((selected) => selected !== item));
    if (selected.length < 12) {
      setSkillsLengthError("");
    }
    console.log("remove item", selected.length);
  };

  const handleValidation = () => {
    let isValid = true;
    if (selected.length === 0) {
      setSkillsError("Please add at least one skill.");
      isValid = false;
    } else {
      setSkillsError("");
    }

    if (!selectedLocation) {
      setLocationError("Location must not be empty.");
      isValid = false;
    } else {
      setLocationError("");
    }

    if (!selectedMinExperience) {
      setMinExperienceError("Please select an minimum experience level.");
      isValid = false;
      return;
    } else {
      setMinExperienceError("");
    }

    if (!selectedMinSalary || selectedMinSalary === "") {
      setMinsalaryError("Please select minimum a salary level.");
      isValid = false;
      return;
    } else {
      setMinsalaryError("");
    }

    if (!selectedMaxSalary || selectedMaxSalary === "") {
      setSalaryError("Please select maximum a salary level.");
      isValid = false;
      return;
    } else {
      setSalaryError("");
    }

    // Validate add skills

    console.log(selected);
    if (selected.length === 0) {
      setAddSkillsError("Please add at least one skill.");
      isValid = false;
    } else {
      setAddSkillsError("");
    }

    return isValid;
  };

  const [selectedMinExp, setSelectedMinExp] = useState("");
  const [selectedMaxExp, setSelectedMaxExp] = useState("");

  const [selectedMinSalaryLabel, setSelectedMinSalaryLabel] = useState("");
  const [selectedMaxSalaryLabel, setSelectedMaxSalaryLabel] = useState("");

  const handleMinExperienceChange = (e) => {
    setSelectedMinExperience(e.target.value);
    const selectedLabel = e.target.options[e.target.selectedIndex].text;
    const selectedMinExp = parseInt(e.target.value, 10);
    const selectedMaxExp = parseInt(selectedMaxExperience, 10);

    if (selectedMaxExperience) {
      if (selectedMaxExp < selectedMinExp) {
        setExperienceError("Max experience level should be greater than Min");
      } else {
        setExperienceError("");
      }
    }
    setSelectedMinExp(selectedLabel);
    setMinExperienceError("");
  };

  const handleMaxExperienceChange = (e) => {
    setMaxExperienceError("");
    setExperienceError("");
    setSelectedMaxExperience(e.target.value);
    // handleValidation();
    const selectedMinExp = parseInt(selectedMinExperience, 10);
    const selectedMaxExp = parseInt(e.target.value, 10);

    if (selectedMaxExp < selectedMinExp) {
      setExperienceError("Max experience level should be greater than Min");
    } else {
      setExperienceError("");
    }
    const selectedLabel = e.target.options[e.target.selectedIndex].text;
    setSelectedMaxExp(selectedLabel);
  };

  const getLocations = ({ value }) => {
    if (value)
      dispatch(getLocationsData(value))
        .then((response) => {
          if (response.data) {
            setLocationsAPIData(response.data);
          } else setLocationsAPIData([]);
        })
        .catch((err) => {
          setLocationsAPIData([]);
        });
    else setLocationsAPIData([]);
  };

  const collectFormData = () => {
    const data = {
      title: title,
      company_name: company,
      company_id: typeof companyId == "object" ? companyId?.val : companyId,
      company_location: companyLocation,
      company_visible: companyVisible,
      vacancies: numberOfVacancies,
      workspace_type: workspacetype,
      employment_type: employmentType,
      description: convertedContent,
      skills: selected,
      job_location: selectedLocation,
      wp_job: placeJobOnWorkerPool,
      min_experience: selectedMinExperience,
      max_experience: selectedMaxExperience,
      min_salary: selectedMinSalary,
      max_salary: selectedMaxSalary,
      cover_letter: coverLetterSelected,
    };

    let error = "";

    if (selectedApplyOption === "smartApply") {
      data.apply_smart = true;
      let smart_apply_questions = [];
      for (let i = 0; i < jobscreeningQuestions.length; i++) {
        let {
          questions,
          ideal_type,
          ideal_value,
          required,
          dynamic_key,
          dynamic_value,
        } = jobscreeningQuestions[i];
        if (dynamic_key && !dynamic_value) {
          error = `Please select ${dynamic_key}`;
          break;
        }
        if (!questions) {
          error = `Question ${i + 1} is not valid.`;
          break;
        }
        if (
          ideal_type == typeof ideal_value ||
          (ideal_type == "number" && !ideal_value && ideal_value !== 0)
        ) {
          error = `Question ${i + 1} is not have valid ideal answer.`;
          break;
        }

        if (dynamic_key) {
          questions = questions?.replace(`[${dynamic_key}]`, dynamic_value);
        }

        smart_apply_questions.push({
          question: questions,
          ideal_type,
          ideal_value,
          required,
        });
      }

      data.smart_apply_questions = smart_apply_questions;

      if (data.smart_apply_questions?.length < jobscreeningQuestions?.length) {
        return { data: false, error };
      }
      if (data.smart_apply_questions?.length < 3) {
        return { data: false, error: "Minimum 3 screening questions required" };
      }
    } else {
      data.apply_smart = false;
      if (
        !/^(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/.test(
          externalLink
        )
      )
        return { data: false, error: "External link is not valid" };
      data.apply_link = externalLink;
    }
    return { data, error: "" };
  };

  const handlePostJob = () => {
    const { data, error } = collectFormData();
    console.log(data);
    setScreeningError(error);
    if (data && !error) {
      dispatch(createJobPostAction(data))
        .then((data) => {
          if (data.success) {
            toast.success("Job Created Successfully!", {
              position: toast.POSITION.BOTTOM_LEFT,
            });
            setSuccessfulModal(!successfulModal);
            setCreatedJobData(data.data);

            // navigate("/jobs");
          }
        })
        .catch((e) => {});
    } else
      toast.error(error, {
        position: toast.POSITION.BOTTOM_LEFT,
      });
  };

  const handleCountine = () => {
    if (handleValidation()) {
      setCurrentPage(currentPage + 1);
    } else return;
  };

  return (
    <div className="post-jobs bg-job">
      <Navbar isJobs={false} />
      <div className="container mt-4">
        <div className="row justify-content-center">
          <div className="col-lg-9">
            {currentPage === 1 && (
              <div className="bg-white mt-30">
                <div className="d-flex p-4">
                  <img
                    src={backArrow}
                    alt="back_arrow"
                    onClick={handleBackButtonClick}
                    style={{ cursor: "pointer" }}
                  />
                  <div className="heading ml-10 black-color">
                    1 of 2: Job Details
                  </div>
                </div>
                <div className="line"></div>
                <div className="p-4 sub-heading mt-20">
                  Add a job description
                </div>
                <div className="job-editor">
                  <EditorBox
                    convertedContent={convertedContent}
                    setConvertedContent={setConvertedContent}
                    dbFormat={dbFormat}
                    setDbFormat={setDbFormat}
                    value={jobDescription}
                    onChange={(e) => setJobDescription(e.target.value)}
                  />
                  {/* {description1Error && (
                    <div className="error-message" style={{ color: 'red' }}>{description1Error}</div>
                  )} */}
                </div>
                <div className="px-4 pt-4 pb-2 sub-heading mt-10">
                  Add skills
                </div>
                <span className="px-4 sub-content">
                  Add skill keywords to make your job more visible to the right
                  candidates (Select up to 10)
                </span>
                <div
                  className="d-flex mx-4"
                  style={{ flexWrap: "wrap", gap: "8px" }}
                >
                  {selected.length > 0 &&
                    selected.map((item, index) => (
                      <div
                        key={index}
                        className="add-skills-recruiter"
                        style={{ maxWidth: "100%" }}
                      >
                        <div
                          style={{
                            display: "flex",
                            alignItems: "normal",
                            overflow: "hidden",
                          }}
                        >
                          <p
                            style={{
                              margin: "0",
                              overflow: "hidden",
                              textOverflow: "ellipsis",
                              whiteSpace: "nowrap",
                              maxWidth: "670px",
                            }}
                          >
                            {item}
                          </p>
                          <span
                            onClick={() => removeItem(item)}
                            className="curserPointer mb-1 remove-icon"
                            // style={{ marginTop: "4px" }}
                          >
                            X
                          </span>
                        </div>
                      </div>
                    ))}
                  {showAddInput ? (
                    <>
                      <input
                        autoFocus
                        value={input}
                        className="addSkillInput form-control"
                        onChange={(e) => handleChange(e)}
                        onKeyDown={(e) => handleKeyDown(e)}
                        onBlur={() => setShowAddInput(false)}
                        style={{ width: "100%" }}
                      />
                    </>
                  ) : (
                    <div
                      className="add-skills"
                      onClick={() => setShowAddInput(true)}
                    >
                      <div
                        className="add-skills-recruiter-btn"
                        style={{ color: "#0432A3", fontWeight: "400" }}
                      >
                        Add skill +
                      </div>
                    </div>
                  )}
                </div>

                {skillsError && (
                  <div
                    className="error-message"
                    style={{
                      color: "red",
                      marginLeft: "24px",
                      marginTop: "10px",
                    }}
                  >
                    {skillsError}
                  </div>
                )}
                {skillsLengthError && (
                  <div
                    className="error-message"
                    style={{
                      color: "red",
                      marginLeft: "24px",
                      marginTop: "10px",
                    }}
                  >
                    {skillsLengthError}
                  </div>
                )}
                <div className="location px-4 pt-4 pb-2">
                  <div className="sub-heading">Job Location</div>
                  <div className="autocompletejob-wrapper1">
                    <Autocomplete
                      open={locationOpen}
                      value={searchFieldValue}
                      items={locationsAPIData}
                      getItemValue={(item) => item.name}
                      shouldItemRender={() => locationsAPIData}
                      renderMenu={(items, value) => (
                        <div
                          className="dropdown custom-scrollbar"
                          style={{ width: "44.2%" }}
                        >
                          {items.length === 0 ? (
                            <span style={{ color: "red" }}>
                              No matches for {value}
                            </span>
                          ) : (
                            items
                          )}
                        </div>
                      )}
                      renderItem={(item, isHighlighted) => (
                        <div
                          className={`item ${
                            isHighlighted ? "selected-item" : ""
                          }`}
                        >
                          {item.name}
                        </div>
                      )}
                      onChange={(e, val) => {
                        setSelectedLocation("");
                        if (val.length === 0) {
                          if (locationOpen) setLocationOpen(false);
                        } else {
                          if (!locationOpen) setLocationOpen(true);
                        }
                        setSearchFieldValue(e.target.value);
                        // handleValidation();
                        getLocations(e.target);
                        setLocationError("");
                      }}
                      onSelect={(value, obj) => {
                        setSearchFieldValue(value);
                        setPageDetails({
                          ...pageDetails,
                          location: obj.name,
                        });
                        setSelectedLocation(value);
                        setLocationOpen(false);
                        setLocationError("");
                      }}
                      wrapperStyle={{}}
                    />
                  </div>
                  {locationError && (
                    <div className="error-message" style={{ color: "red" }}>
                      {locationError}
                    </div>
                  )}
                </div>
                <div className="row p-4">
                  <div className="sub-heading">Experience</div>
                  <div className="col-md-4">
                    <div>
                      <label className="my-2">Min Experience</label>
                      <Input
                        type="select"
                        onChange={handleMinExperienceChange}
                        value={selectedMinExperience}
                      >
                        <option value="" disabled selected>
                          Select Min Experience
                        </option>
                        <option value="0">Fresher</option>
                        <option value="1">1 year</option>
                        <option value="2">2 years</option>
                        <option value="3">3 years</option>
                        <option value="4">4 years</option>
                        <option value="5">5 years</option>
                        <option value="6">6 years</option>
                        <option value="7">7 years</option>
                        <option value="8">8 years</option>
                        <option value="9">9 years</option>
                        <option value="10">10 years</option>
                        <option value="11">11 years</option>
                        <option value="12">12 years</option>
                        <option value="13">13 years</option>
                        <option value="14">14 years</option>
                        <option value="15">15 years</option>
                      </Input>
                    </div>
                    {minexperienceError && (
                      <div className="error-message" style={{ color: "red" }}>
                        {minexperienceError}
                      </div>
                    )}
                  </div>
                  <div className="col-md-4">
                    <div>
                      <label className="my-2">Max Experience</label>
                      <Input
                        type="select"
                        onChange={(e) => handleMaxExperienceChange(e)}
                        value={selectedMaxExperience}
                        // onBlur={()=>handleValidation()}
                      >
                        <option value="0" disabled selected>
                          Select Max Experience
                        </option>
                        <option value="1">1 year</option>
                        <option value="2">2 years</option>
                        <option value="3">3 years</option>
                        <option value="4">4 years</option>
                        <option value="5">5 years</option>
                        <option value="6">6 years</option>
                        <option value="7">7 years</option>
                        <option value="8">8 years</option>
                        <option value="9">9 years</option>
                        <option value="10">10 years</option>
                        <option value="11">11 years</option>
                        <option value="12">12 years</option>
                        <option value="13">13 years</option>
                        <option value="14">14 years</option>
                        <option value="15">15 years</option>
                      </Input>
                    </div>
                    {experienceError && (
                      <div className="error-message" style={{ color: "red" }}>
                        {experienceError}
                      </div>
                    )}
                  </div>
                  <div className="col-md-4"></div>
                  <div className="row mt-3">
                    <div className="sub-heading">Salary</div>
                  </div>
                  <div className="col-md-4">
                    <div>
                      <label className="my-2">Min Salary</label>
                      <Input
                        type="select"
                        // onChange={handleMinSalaryChange,handleValidation()}
                        onChange={(e) => {
                          handleMinSalaryChange(e);
                        }}
                        value={selectedMinSalary}
                      >
                        <option value="" disabled selected>
                          Select Min Salary
                        </option>
                        <option value="100000">1 Lacs</option>
                        <option value="500000">5 Lacs</option>
                        <option value="1000000">10 Lacs</option>
                        <option value="1500000">15 Lacs</option>
                        <option value="2000000">20 Lacs</option>
                        <option value="2500000">25 Lacs</option>
                        <option value="3000000">30 Lacs</option>
                        <option value="3500000">35 Lacs</option>
                        <option value="4000000">40 Lacs</option>
                        <option value="4500000">45 Lacs</option>
                        <option value="5000000">50 Lacs</option>
                      </Input>
                    </div>
                    {minsalaryError && (
                      <div className="error-message" style={{ color: "red" }}>
                        {minsalaryError}
                      </div>
                    )}
                  </div>
                  <div className="col-md-4">
                    <div>
                      <label className="my-2">Max Salary</label>
                      <Input
                        type="select"
                        onChange={handleMaxSalaryChange}
                        value={selectedMaxSalary}
                      >
                        <option value="" disabled selected>
                          Select Max Salary
                        </option>
                        <option value="100000">1 Lacs</option>
                        <option value="500000">5 Lacs</option>
                        <option value="1000000">10 Lacs</option>
                        <option value="1500000">15 Lacs</option>
                        <option value="2000000">20 Lacs</option>
                        <option value="2500000">25 Lacs</option>
                        <option value="3000000">30 Lacs</option>
                        <option value="3500000">35 Lacs</option>
                        <option value="4000000">40 Lacs</option>
                        <option value="4500000">45 Lacs</option>
                        <option value="5000000">50 Lacs</option>
                      </Input>
                    </div>
                    {salaryError && (
                      <div className="error-message" style={{ color: "red" }}>
                        {salaryError}
                      </div>
                    )}
                  </div>
                </div>
                {isWorker && (
                  <div className="location px-4 pt-4 pb-2 d-flex align-items-center gap-2">
                    <Input
                      type="checkbox"
                      className="d-block"
                      checked={placeJobOnWorkerPool}
                      onChange={handleCheckboxChange}
                      style={{ width: "20px", height: "20px" }}
                    />
                    <span
                      style={{
                        marginTop: "4px",
                        color: "#01052D",
                        fontSize: "15px",
                        fontWeight: "600",
                      }}
                    >
                      Place Job on WorkerPool
                    </span>
                  </div>
                )}

                <div className="d-flex justify-content-between align-items-center p-4">
                  <span
                    className="fw-600 blue-color curserPointer"
                    onClick={() => setPreviewModal(!previewModal)}
                  >
                    Preview
                  </span>

                  <div
                    className="btn btn-blue"
                    style={{ width: "127px" }}
                    onClick={() => {
                      handleCountine();
                    }}
                  >
                    Continue
                  </div>
                </div>
              </div>
            )}

            {currentPage === 2 && (
              <div className="bg-white mt-30">
                <div className="d-flex p-4">
                  <img
                    src={backArrow}
                    alt="back_arrow"
                    onClick={() => setCurrentPage(currentPage - 1)}
                    style={{ cursor: "pointer" }}
                  />
                  <div className="heading ml-10 black-color">
                    2 of 2: Job Details
                  </div>
                </div>
                <div className="line"></div>
                <div className="p-4 mt-20">
                  <div className="d-flex gap-5">
                    <div className="d-flex gap-2">
                      <input
                        style={{
                          height: "20px",
                          width: "20px",
                          marginTop: "2.5px",
                        }}
                        type="radio"
                        name="paymentcard"
                        onChange={() => {
                          setSelectedApplyOption("smartApply");
                          setScreeningError("");
                        }}
                        checked={selectedApplyOption === "smartApply"}
                      />
                      <span className="heading dark-blue-color">
                        Smart Apply
                      </span>
                    </div>
                    <div className="d-flex gap-2">
                      <input
                        style={{
                          height: "20px",
                          width: "20px",
                          marginTop: "2.5px",
                        }}
                        type="radio"
                        name="paymentcard"
                        onChange={() => {
                          setSelectedApplyOption("externalLink");
                          setScreeningError("");
                        }}
                        checked={selectedApplyOption === "externalLink"}
                      />
                      <span className="heading dark-blue-color">
                        Link of Job Portal
                      </span>
                    </div>
                  </div>
                </div>
                {selectedApplyOption === "smartApply" && (
                  <>
                    <div className="px-4 sub-heading">
                      Add screening questions
                    </div>
                    <p
                      className="px-4 mb-30 grey-color"
                      style={{ marginTop: "10px" }}
                    >
                      We recommend adding 3 or more questions. Applicants must
                      answer each question.
                    </p>

                    {jobscreeningQuestions.map((questionBox, index) =>
                      questionBox.label === "Custom" ? (
                        <div className="screening-box pb-4 mb-20">
                          <div className="box-bar d-flex justify-content-between align-items-center px-4 py-1">
                            <span>Write a custom screening question.</span>
                            <img
                              style={{ cursor: "pointer" }}
                              src={closeButton}
                              alt="close-button"
                              onClick={() => removeScreeningQuestion(index)}
                            />
                          </div>
                          <div>
                            <Input
                              type="text"
                              placeholder="Add Questions Here"
                              value={questionBox.questions}
                              onChange={(e) =>
                                handleScreeningQuestion(index, {
                                  field: "questions",
                                  value: e.target.value,
                                })
                              }
                            />
                          </div>

                          <div
                            className="d-flex"
                            style={{
                              paddingLeft: "25px",
                              gap: "72px",
                              alignItems: "flex-end",
                            }}
                          >
                            <div>
                              <span className="d-block">Response Type </span>
                              <select
                                style={{
                                  padding: "10px",
                                  marginTop: "10px",
                                  width: "267px",
                                  height: "40px",
                                  borderRadius: "4px",
                                  // background:
                                  //   "linear-gradient(0deg, #D1DFFF, #D1DFFF), linear-gradient(0deg, #FFFFFF, #FFFFFF)",
                                }}
                                value={questionBox.ideal_type}
                                onChange={(e) => {
                                  handleIdealTypeQuestion(
                                    index,
                                    e.target.value,
                                    e.target.value == "boolean" ? "true" : 0
                                  );
                                }}
                              >
                                <option value="boolean">Yes/No</option>
                                <option value="number">Numeric</option>
                              </select>
                            </div>

                            <div>
                              <span className="d-block fw-bold text-dark">
                                Ideal Answer{" "}
                              </span>
                              {questionBox.ideal_type == "number" && (
                                <input
                                  type="number"
                                  style={{
                                    padding: "10px",
                                    marginTop: "10px",
                                    marginRight: "10px",
                                    width: "57px",
                                    height: "40px",
                                    borderRadius: "4px",
                                    // background:
                                    //   "linear-gradient(0deg, #D1DFFF, #D1DFFF), linear-gradient(0deg, #FFFFFF, #FFFFFF)",
                                  }}
                                  value={questionBox.ideal_value}
                                  onChange={(e) =>
                                    handleScreeningQuestion(index, {
                                      field: "ideal_value",
                                      value: e.target.value,
                                    })
                                  }
                                />
                              )}
                              {questionBox.ideal_type == "boolean" && (
                                <select
                                  style={{
                                    padding: "10px",
                                    marginTop: "10px",
                                    height: "40px",
                                    width: "90px",
                                    borderRadius: "4px",

                                    // background:
                                    //   "linear-gradient(0deg, #D1DFFF, #D1DFFF), linear-gradient(0deg, #FFFFFF, #FFFFFF)",
                                  }}
                                  value={questionBox.ideal_value}
                                  onChange={(e) =>
                                    handleScreeningQuestion(index, {
                                      field: "ideal_value",
                                      value: e.target.value,
                                    })
                                  }
                                >
                                  <option value="true">Yes</option>
                                  <option value="false">No</option>
                                </select>
                              )}

                              {questionBox.ideal_type === "number" && (
                                <span>Minimum</span>
                              )}
                            </div>
                            <div
                              style={{
                                marginLeft: "auto",
                                marginRight: "25px",
                                marginBottom: "8px",
                              }}
                            >
                              <span className="d-block"> </span>
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                }}
                              >
                                <input
                                  type="checkbox"
                                  style={{
                                    padding: "10px",
                                    marginRight: "10px",

                                    height: "20px",
                                    width: "20px",
                                    accentColor: "rgb(4, 50, 163)",
                                    // background:
                                    //   "linear-gradient(0deg, #D1DFFF, #D1DFFF), linear-gradient(0deg, #FFFFFF, #FFFFFF)",
                                  }}
                                  checked={questionBox.required}
                                  onChange={(e) =>
                                    handleScreeningQuestion(index, {
                                      field: "required",
                                      value: e.target.checked,
                                    })
                                  } // Use e.target.checked
                                />
                                <span style={{ fontSize: "15px" }}>
                                  Must have qualification
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                      ) : (
                        <div className="screening-box pb-4 mb-20">
                          <div className="box-bar d-flex justify-content-between align-items-center px-4 py-1">
                            <span className="text-dark">
                              {questionBox.questions}
                            </span>
                            <img
                              style={{ cursor: "pointer" }}
                              src={closeButton}
                              alt="close-button"
                              onClick={() => removeScreeningQuestion(index)}
                            />
                          </div>
                          <div
                            className="d-flex"
                            style={{
                              paddingLeft: "25px",
                              gap: "72px",
                              alignItems: "flex-end",
                            }}
                          >
                            {questionBox.dynamic_key === "Degree" && (
                              <div>
                                <span className="d-block text-dark fw-bold">
                                  {questionBox.dynamic_key}{" "}
                                </span>
                                <select
                                  style={{
                                    padding: "10px",
                                    marginTop: "10px",
                                    width: "267px",
                                    height: "40px",
                                    borderRadius: "4px",
                                    // background:
                                    //   "linear-gradient(0deg, #D1DFFF, #D1DFFF), linear-gradient(0deg, #FFFFFF, #FFFFFF)",
                                  }}
                                  value={questionBox.dynamic_value || null}
                                  onChange={(e) =>
                                    handleScreeningQuestion(index, {
                                      field: "dynamic_value",
                                      value: e.target.value,
                                    })
                                  }
                                >
                                  <option default value={""}>
                                    None
                                  </option>
                                  {degreeList?.data?.map((item, index) => {
                                    return (
                                      <option key={index} value={item.name}>
                                        {item.name}
                                      </option>
                                    );
                                  })}
                                </select>

                                {/* {screeningError && (
                                  <div style={{ color: "red" }}>
                                    {screeningError}
                                  </div>
                                )} */}
                              </div>
                            )}
                            {questionBox.dynamic_key === "Language" && (
                              <div>
                                <span className="d-block text-dark fw-bold">
                                  {questionBox.dynamic_key}{" "}
                                </span>
                                <select
                                  style={{
                                    padding: "10px",
                                    marginTop: "10px",
                                    width: "267px",
                                    height: "40px",
                                    borderRadius: "4px",

                                    // background:
                                    //   "linear-gradient(0deg, #D1DFFF, #D1DFFF), linear-gradient(0deg, #FFFFFF, #FFFFFF)",
                                  }}
                                  value={questionBox.dynamic_value || null}
                                  onChange={(e) =>
                                    handleScreeningQuestion(index, {
                                      field: "dynamic_value",
                                      value: e.target.value,
                                    })
                                  }
                                >
                                  <option value={""}>None</option>
                                  <option value="English">English</option>
                                  <option value="Hindi">Hindi</option>
                                </select>
                              </div>
                            )}

                            <div>
                              <span className="d-block text-dark fw-bold">
                                Ideal Answer{" "}
                              </span>
                              {questionBox.ideal_type == "number" && (
                                <input
                                  type="number"
                                  style={{
                                    padding: "10px",
                                    marginTop: "10px",
                                    marginRight: "10px",
                                    width: "57px",
                                    height: "40px",
                                    borderRadius: "4px",
                                    // background:
                                    //   "linear-gradient(0deg, #D1DFFF, #D1DFFF), linear-gradient(0deg, #FFFFFF, #FFFFFF)",
                                  }}
                                  value={questionBox.ideal_value}
                                  onChange={(e) =>
                                    handleScreeningQuestion(index, {
                                      field: "ideal_value",
                                      value: e.target.value,
                                    })
                                  }
                                />
                              )}
                              {questionBox.ideal_type == "boolean" && (
                                <select
                                  style={{
                                    padding: "10px",
                                    marginTop: "10px",
                                    width: "267px",
                                    height: "40px",
                                    borderRadius: "4px",
                                    // background:
                                    //   "linear-gradient(0deg, #D1DFFF, #D1DFFF), linear-gradient(0deg, #FFFFFF, #FFFFFF)",
                                  }}
                                  value={questionBox.ideal_value}
                                  onChange={(e) =>
                                    handleScreeningQuestion(index, {
                                      field: "ideal_value",
                                      value: e.target.value,
                                    })
                                  }
                                >
                                  <option value="true">Yes</option>
                                  <option value="false">No</option>
                                </select>
                              )}

                              {questionBox.ideal_type == "langauage" && (
                                <select
                                  style={{
                                    padding: "10px",
                                    marginTop: "10px",
                                    width: "165px",
                                    // background:
                                    //   "linear-gradient(0deg, #D1DFFF, #D1DFFF), linear-gradient(0deg, #FFFFFF, #FFFFFF)",
                                    height: "40px",
                                    borderRadius: "4px",
                                  }}
                                  value={questionBox.ideal_value}
                                  onChange={(e) =>
                                    handleScreeningQuestion(index, {
                                      field: "ideal_value",
                                      value: e.target.value,
                                    })
                                  }
                                >
                                  {/* <option value={null}>None</option> */}
                                  <option value="Conversational">
                                    Conversational
                                  </option>
                                  <option value="Professional">
                                    Professional
                                  </option>
                                  <option value="Native or Bilingual">
                                    Native or Bilingual
                                  </option>
                                </select>
                              )}

                              {questionBox.ideal_type === "number" && (
                                <span>Minimum</span>
                              )}
                            </div>
                            <div
                              style={{
                                marginLeft: "auto",
                                marginRight: "25px",
                                marginBottom: "8px",
                              }}
                            >
                              <span className="d-block"> </span>
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                }}
                              >
                                <input
                                  type="checkbox"
                                  style={{
                                    padding: "10px",
                                    marginRight: "10px",

                                    height: "20px",
                                    width: "20px",
                                    accentColor: "rgb(4, 50, 163)",
                                    // background:
                                    //   "linear-gradient(0deg, #D1DFFF, #D1DFFF), linear-gradient(0deg, #FFFFFF, #FFFFFF)",
                                  }}
                                  checked={questionBox.required}
                                  onChange={(e) =>
                                    handleScreeningQuestion(index, {
                                      field: "required",
                                      value: e.target.checked,
                                    })
                                  } // Use e.target.checked
                                />
                                <span style={{ fontSize: "15px" }}>
                                  Must have qualification
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                      )
                    )}

                    {screeningError && (
                      <div
                        className="px-4 text-center"
                        style={{ color: "red" }}
                      >
                        {screeningError}
                      </div>
                    )}

                    <div className="line my-4"></div>

                    <div className="px-4 sub-heading">
                      Add screening questions:
                    </div>
                    <div className="px-4 mt-3">
                      {screeningOptions.map((option, index) => (
                        <span
                          key={index}
                          style={{
                            border: "rgba(4, 50, 163, 1) 1px solid",
                            padding: "10px",
                            marginRight: "10px",
                            color: "rgba(4, 50, 163, 1)",
                            display: "inline-block",
                            borderRadius: "20px",
                            cursor: option.selected ? "not-allowed" : "pointer",
                            opacity: option.selected ? 0.5 : 1,
                            marginBottom: "8px",
                          }}
                          onClick={() => {
                            !option.selected &&
                              AddScreeningQuestion(index, option);
                          }}
                        >
                          {option.label} +
                        </span>
                      ))}
                      <span
                        // key={}
                        style={{
                          border: "rgba(4, 50, 163, 1) 1px solid",
                          padding: "10px",
                          marginRight: "10px",
                          color: "rgba(4, 50, 163, 1)",
                          display: "inline-block",
                          borderRadius: "20px",
                          cursor: "pointer",
                          opacity: 1,
                        }}
                        onClick={() => {
                          AddScreeningQuestion(null, {
                            label: "Custom",
                            questions: "",
                            selected: false,
                            dynamic_key: null,
                            ideal_type: "boolean",
                            ideal_value: "true",
                          });
                        }}
                      >
                        Custom Questions
                      </span>
                    </div>

                    <div className="check px-4 d-flex align-items-center">
                      <input
                        type="checkbox"
                        id="lettercheck"
                        name="lettercheck"
                        className="mt-3"
                        value={coverLetterSelected}
                        onChange={(e) =>
                          setCoverLetterSelected(e.target.checked)
                        }
                        style={{
                          width: "20px",
                          height: "20px",
                          accentColor: "#0432a3",
                        }}
                      />
                      <label
                        htmlFor="lettercheck"
                        className="mt-4 ms-2 text-secondary"
                        style={{ fontSize: "15px" }}
                      >
                        Candidates must attach a cover letter with job
                        application
                      </label>
                    </div>
                  </>
                )}
                {selectedApplyOption === "externalLink" && (
                  <>
                    <div className="px-4 sub-heading">Enter External Link</div>
                    <div className="px-4 mt-2">
                      <Input
                        type="text"
                        placeholder="Enter external link..."
                        value={externalLink}
                        onChange={(e) => setExternalLink(e.target.value)}
                      />
                    </div>
                    {screeningError && (
                      <div className="px-4 py-2" style={{ color: "red" }}>
                        {screeningError}
                      </div>
                    )}
                  </>
                )}

                <div className="d-flex justify-content-between align-items-center p-4">
                  <span
                    className="fw-600 blue-color curserPointer"
                    onClick={() => setPreviewModal(!previewModal)}
                  >
                    Preview
                  </span>
                  <div>
                    <span
                      className="fw-600 blue-color curserPointer me-3 btn btn-light"
                      onClick={() => setCurrentPage(currentPage - 1)}
                    >
                      Back
                    </span>
                    <div
                      className="btn btn-blue"
                      onClick={() => handlePostJob()}
                    >
                      Post Job
                    </div>
                  </div>
                  {/* <div
                    className="btn btn-blue"
                    onClick={() => setPreviewModal(!previewModal)}
                  >
                    Preview
                  </div>
                </div>
              </div>)}
            {/* Step 2 Form End */}
                </div>
                {/* <div className="col-lg-3">
                  <div className="mt-1">
                    <span className="fw-600">User Interface Designer</span>
                  </div>
                  <div className="mt-1">
                    <span className="fw-600">Verint.Kerala, India (Remote)</span>
                  </div>
                  <div className="mt-1">
                    <span className="fw-600">Saved as Draftstart</span>
                  </div>
                </div> */}
              </div>
            )}

            {previewModal && (
              <JobPreview
                title={title}
                company={company}
                listImage={listImage}
                company_location={companyLocation}
                company_visible={companyVisible}
                vacancies={numberOfVacancies}
                workspace_type={workspacetype}
                selectedMinExp={selectedMinExp}
                selectedMaxExp={selectedMaxExp}
                selectedMinSalaryLabel={selectedMinSalaryLabel}
                selectedMaxSalaryLabel={selectedMaxSalaryLabel}
                employment_type={employmentType}
                description={convertedContent}
                addSkills={selected}
                // experience={selectedExperience}
                jobType={selectedJobType}
                salary={selectedSalary}
                modal={previewModal}
                handlePostJob={handlePostJob}
                toggle={() => setPreviewModal(!previewModal)}
              />
            )}

            {successfulModal && (
              <JobPostSuccessful
                modal={successfulModal}
                toggle={() => setSuccessfulModal(!successfulModal)}
                createdJobData={createdJobData}
              />
            )}
          </div>
          <div className="col-lg-3">
            <div className="mt-30">
              <div className="d-flex flex-column justify-content-center gap-2">
                <h5 className="post-job-title">{title}</h5>
                <div className="d-flex flex-row  gap-2">
                  <p
                    className="org-name d-flex card-control-text-oneline"
                    style={{ fontSize: "13px" }}
                  >
                    {company}{" "}
                    <p className="location">
                      {companyLocation} ({workspacetype})
                    </p>
                  </p>
                  {/* <p className="post-job-location m-0">{company}</p>

                  <p className="post-job-location m-0">
                    <FaCircle size={7} /> {companyLocation} ({workspacetype})
                  </p> */}
                </div>
                {/* <p className="post-job-saved-text">
                  Saved as <span className="post-job-draft-text">Draft</span>
                </p> */}
              </div>
            </div>
          </div>
        </div>
      </div>
      <ToastContainer />
      <Chat />
    </div>
  );
}
