import React, { useEffect, useState } from "react";
import Events from "./Events";
import {
  attendEvents,
  getSuggestedEvents,
} from "../../../store/events/eventActions";
import { useDispatch, useSelector } from "react-redux";
import { pinnedEvents } from "../../../store/events/eventActions";
import { useNavigate } from "react-router-dom";

export default function EventsContainer() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { user } = useSelector((state) => state.auth);
  const { isPageLoading } = useSelector((state) => state.loader);

  const { suggested_events } = useSelector((state) => state.events);
  const { pinned_events } = useSelector((state) => state.events);
  const { my_events } = useSelector((state) => state.events);
  const { my_drafts } = useSelector((state) => state.events);
  const { upcoming_events } = useSelector((state) => state.events);
  const { history_events } = useSelector((state) => state.events);

  const token = localStorage.getItem("accessToken");

  const [tabs, setTabs] = useState("suggested_events");
  const [searchKey, setSearchKey] = useState("");
  const [newSuggestedEvents, setNewSuggestedEvents] = useState([]);
  const [newPinnedEvents, setNewPinnedEvents] = useState([]);
  const [newMyEvents, setNewMyEvents] = useState([]);
  const [newMyDrafts, setNewMyDrafts] = useState([]);
  const [newUpcomingEvents, setNewUpcomingEvents] = useState([]);
  const [newHistoryEvents, setNewHistoryEvents] = useState([]);
  const [openCreateModal, setOpenCreateModal] = useState(false);
  const [openShareModal, setOpenShareModal] = useState(false);
  const [shareData, setShareData] = useState("");
  const [successModal, setSuccessModal] = useState(false);
  const [editEventData, setEditEventData] = useState("");
  const [popupShareData, setPopupShareData] = useState("");
  const [currentCount, setCurrentCount] = useState(0);
  const [totalCount, setTotalCount] = useState(0);

  useEffect(() => {
    if (tabs === "suggested_events") {
      let query = "suggested";
      dispatch(
        getSuggestedEvents(
          token,
          { type: query, skip: currentCount, limit: 12 },
          currentCount
        )
      )
    } else if (tabs === "pinned_events") {
      let query = "pinned";
      dispatch(
        getSuggestedEvents(
          token,
          { type: query, skip: currentCount, limit: 12,search: searchKey },
          currentCount
        )
      )
    } else if (tabs === "my_events") {
      let query = "myEvent";
      dispatch(
        getSuggestedEvents(
          token,
          { type: query, skip: currentCount, limit: 12 },
          currentCount
        )
      )
    } else if (tabs === "my_drafts") {
      let query = "myDraft";
      dispatch(
        getSuggestedEvents(
          token,
          { type: query, skip: currentCount, limit: 12 },
          currentCount
        )
      )
    } else if (tabs === "upcoming_events") {
      let query = "upcoming";
      if(searchKey===""){
        dispatch(
          getSuggestedEvents(
            token,
            { type: query, skip: currentCount, limit: 12 , search: searchKey,},
            currentCount
          )
        )
      }
      
    } else if (tabs === "history_events") {
      let query = "history";
      dispatch(
        getSuggestedEvents(
          token,
          { type: query, skip: currentCount, limit: 12 },
          currentCount
        )
      )
    }
  }, [tabs, currentCount]);

  useEffect(() => {
   setCurrentCount(0);
  }, [tabs]);

 // useEffect(()=>)

  const pinHandle = (id, type) => {
    dispatch(pinnedEvents(id, token, type));
  };

  const loadMoreData = () => {
    setCurrentCount(currentCount + 12);
  };

  const attendHandle = (id, type) => {
    dispatch(attendEvents(id, token, type));
  };

  // const attendFilter = (data) => {
  //     console.log(data)
  //     let filterCheck = data?.Event_Participant?.some(function (value) {
  //         return value.user_id === user?.data?.id
  //      });
  //     return filterCheck;
  // }

  const filterSearchData = () => {
    if (tabs === "suggested_events") {
      const result = suggested_events.filter((events) =>
        events?.title.toString().toLowerCase().includes(searchKey.toLowerCase())
      );
      setNewSuggestedEvents(result);
    } else if (tabs === "upcoming_events") {
      // const result = upcoming_events.filter((events) =>
      //   events?.title.toString().toLowerCase().includes(searchKey.toLowerCase())
      // );
      // setNewUpcomingEvents(result);
      dispatch(
        getSuggestedEvents(
          token,
          { type: "upcoming", skip: currentCount, limit: 12,search:searchKey },
          currentCount
        )
      )
    } else if (tabs === "history_events") {
      const result = history_events.filter((events) =>
        events?.title.toString().toLowerCase().includes(searchKey.toLowerCase())
      );
      setNewHistoryEvents(result);
    } else if (tabs === "pinned_events") {
      // const result = pinned_events.filter((events) =>
      //   events?.title.toString().toLowerCase().includes(searchKey.toLowerCase())
      // );
      // setNewPinnedEvents(result);
      dispatch(
        getSuggestedEvents(
          token,
          { type:"pinned", skip: currentCount, limit: 12 ,search:searchKey},
          currentCount,
        )
      )

    } else if (tabs === "my_events") {
      // const result = my_events.filter(
      //   (events) =>
      //     events?.title
      //       .toString()
      //       .toLowerCase()
      //       .includes(searchKey.toLowerCase())
      //   //  || offers?.quantity.toLowerCase().includes(searchKey)
      // );
      // setNewMyEvents(result);
      dispatch(
        getSuggestedEvents(
          token,
          { type:"myEvent", skip: currentCount, limit: 12 ,search:searchKey},
          currentCount,
        )
      )
    }

    else if (tabs === "my_drafts") {
      // const result = pinned_events.filter((events) =>
      //   events?.title.toString().toLowerCase().includes(searchKey.toLowerCase())
      // );
      // setNewPinnedEvents(result);
      dispatch(
        getSuggestedEvents(
          token,
          { type:"myDraft", skip: currentCount, limit: 12 ,search:searchKey},
          currentCount,
        )
      )

    }
  };

  useEffect(() => {
    setNewSuggestedEvents(suggested_events);
  }, [suggested_events]);

  useEffect(() => {
    setNewPinnedEvents(pinned_events);
  }, [pinned_events]);

  useEffect(() => {
    setNewMyEvents(my_events);
  }, [my_events]);

  useEffect(() => {
    setNewMyDrafts(my_drafts);
  }, [my_drafts]);

  useEffect(() => {
    setNewUpcomingEvents(upcoming_events);
  }, [upcoming_events]);

  useEffect(() => {
    setNewHistoryEvents(history_events);
  }, [history_events]);

  const readMore = (id) => {
    navigate(`/eventdetail/${id}`);
  };

  const successfulStatus = (shareData) => {
    setPopupShareData(shareData);
    setSuccessModal(!successModal);
  };
  return (
    <Events
      newSuggestedEvents={newSuggestedEvents}
      pinHandle={pinHandle}
      setTabs={setTabs}
      newPinnedEvents={newPinnedEvents}
      newMyEvents={newMyEvents}
      readMore={readMore}
      openCreateModal={openCreateModal}
      setOpenCreateModal={setOpenCreateModal}
      tabs={tabs}
      newMyDrafts={newMyDrafts}
      searchKey={searchKey}
      setSearchKey={setSearchKey}
      filterSearchData={filterSearchData}
      openShareModal={openShareModal}
      setOpenShareModal={setOpenShareModal}
      shareData={shareData}
      setShareData={setShareData}
      attendHandle={attendHandle}
      newUpcomingEvents={newUpcomingEvents}
      newHistoryEvents={newHistoryEvents}
      successfulStatus={successfulStatus}
      successModal={successModal}
      setSuccessModal={setSuccessModal}
      isPageLoading={isPageLoading}
      editEventData={editEventData}
      setEditEventData={setEditEventData}
      popupShareData={popupShareData}
      currentCount={currentCount}
      setCurrentCount={setCurrentCount}
      loadMoreData={loadMoreData}
      totalCount={totalCount}
      user = {user}
    />
  );
}
