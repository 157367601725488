//*Dropzone.js*//

import React, { useState } from "react";
import { useDropzone } from "react-dropzone";
import drop from "../../../assets/img/gallery-add.png";
import edit from "../../../assets/img/edit.svg";

function PageDropZone({ open, handleBannerPicture, bannerImage }) {
  const [files, setFiles] = useState([]);

  const { getRootProps, getInputProps, isDragActive, acceptedFiles } =
    useDropzone({
      multiple: false,
      accept: {
        "image/*": [],
      },
      onDrop: (acceptedFiles) => {
        setFiles(
          acceptedFiles.map((file) =>
            Object.assign(file, {
              preview: URL.createObjectURL(file),
            })
          )
        );
        acceptedFiles.map((file) => {
          handleBannerPicture(file, "backgroundImg");
        });
      },
    });

  const thumbs = files.map((file) => (
    <div key={file.name}>
      <div>
        <img
          src={file.preview}
          alt=""
          // Revoke data uri after image is loaded
          onLoad={() => {
            URL.revokeObjectURL(file.preview);
          }}
        />
      </div>
    </div>
  ));
  return (
    <div {...getRootProps({ className: "dropzone" })}>
      <input className="input-zone" {...getInputProps()} />
      <div className="drag-img">
        {bannerImage ? (
          <>
            <img
              src={bannerImage}
              width="100%"
              height="100%"
              className="create-page-icon"
            />
            <div className="create-page-edit">
              <img src={edit} alt="" />
            </div>
          </>
        ) : (
          <div className="text-center">
            <div className="img"></div>
            <div className="text">
              <p>
                Drag and drop or{" "}
                <span style={{ cursor: "pointer" }}>browse</span> your files
              </p>
              <p>Recommended size is mimimum or above 1920x1080 pixels.</p>
            </div>
          </div>
        )}
      </div>
      {/* <div className="text-center">
        {files.length!==0 || bannerImage ?
        <div className="image-upload">
           <img src={bannerImage} className="image_drag_drop" alt="articleBanner"/>
        </div>
        :
      <div className="image-upload ">
     { isDragActive ? (
          <p className="dropzone-content">
            Release to drop the files here
          </p>):
      <div className="center">
                  <img src={drop} alt="drag-n-drop"/>
                  <div>
                       Drag and drop or browse your files
                  </div>
                </div> }
          {/* Drag’n’drop some files here, or click to select files */}
      {/* </div>}
      </div>
      <aside>
      </aside> */}
    </div>
  );
}

export default PageDropZone;
