import React, { useEffect, useState } from "react";
import {
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
} from "reactstrap";
import GoldIcon from "../../../assets/img/gold.svg";
import { call } from "../../../api/apiCall";
import AttachIcon from "../../../assets/img/Paperclip.svg";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import dustbin from "../../../assets/img/deleteIcon.svg";
import placeholderUser from "../../../assets/img/placeholder_user.jpg";
import { useDispatch, useSelector } from "react-redux";
import { loadUser } from "../../../store/auth/authActions";
const NpMailModal = ({ modal, toggle, profileData }) => {
  const [npData, setNpData] = useState({
    subject: null,
    message: "",
    reciever_id: profileData?.id,
    attachments: [],
  });
  const [error, setError] = useState("");
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state?.auth);
  const handleChange = (e) => {
    setNpData({ ...npData, [e.target.name]: e.target.value });
  };

  const sendMail = async (e) => {
    e.preventDefault();
    try {
      if (!npData?.message) setError("Message is required");
      else {
        const token = localStorage.getItem("accessToken");
        const res = await call(
          "post",
          "api/v1/user/send-np-mail",
          null,
          npData,
          token
        );
        if (res?.data?.success === true) {
          // alert(res?.data?.message);
          toast.success(res?.data?.message, {
            position: toast.POSITION.BOTTOM_LEFT,
          });
          dispatch(loadUser(() => {}));
          setNpData({
            subject: "",
            message: "",
            attachments: [],
          });
          toggle();
        }
      }
    } catch (err) {
      toast.error(err?.response?.data?.message || err?.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
      console.log(err);
    }
  };

  const [submittedFileData, setSubmittedFileData] = useState([]);
  useEffect(() => {
    console.log(submittedFileData);
  }, [submittedFileData]);
  const handleAttachment = async (e) => {
    //console.log("file selected",e.target.files)
    //console.log("file selected",e.target.files.file.name);
    console.log("no. of files selected", e.target.files);

    const validExtensions = [
      "jpg",
      "jpeg",
      "png",
      "gif",
      "pdf",
      "ppt",
      "pptx",
      "txt",
      "doc",
      "docx",
      "xls",
      "xlsx",
    ];
    const files = e.target.files;
    const fileNames = [];
    if (files && files.length > 0) {
      for (let i = 0; i < files.length; i++) {
        const file = files[i];
        const fileName = file.name;
        const fileExtension = fileName.split(".").pop().toLowerCase();

        // Check for valid extension
        if (!validExtensions.includes(fileExtension)) {
          toast.warn(`File '${fileName}' has an invalid extension`, {
            position: toast.POSITION.BOTTOM_LEFT,
          });
          return; // Skip further processing
        }
        // Check for duplicate file names
        if (
          submittedFileData.includes(fileName) ||
          fileNames.includes(fileName)
        ) {
          toast.warn(`File '${fileName}' is already attached`, {
            position: toast.POSITION.BOTTOM_LEFT,
          });
          return; // Skip further processing
        }

        fileNames.push(fileName); // Add the file name to the list of attached files
      }
    }

    // const files=e.target.files;
    //    if(files && files.length>0){
    //  for (let i=0;i<files.length;i++){
    //    const file=files[i];
    //    const fileName=file.name;
    //    fileNames.push(fileName);
    //    console.log("fileNames",fileNames);
    //  }
    // }

    if (!e.target.files.length)
      toast.warn("Please choose file", {
        position: toast.POSITION.BOTTOM_LEFT,
      });
    else if (e.target.files.length > 5)
      toast.warn("Please choose upto 5 files only", {
        position: toast.POSITION.BOTTOM_LEFT,
      });
    else {
      let tempimage = [];
      Array.from(e.target.files).map((file) => {
        tempimage.push({
          file,
        });
      });

      try {
        const UPLOAD_URL = `${process.env.REACT_APP_API_URL}api/v1/upload`;
        const formData = new FormData();
        const headers = {
          "Accept-Language": "en",
          "content-type": "multipart/form-data",
        };

        for (var i = 0; i < e.target.files.length; i++) {
          formData.append("image", tempimage[i].file);
        }
        await axios.post(UPLOAD_URL, formData, { headers }).then((res) => {
          setSubmittedFileData([...submittedFileData, ...fileNames]);
          let { filesArray, urlsArray } = res?.data;
          for (let index = 0; index < filesArray.length; index++) {
            console.log("files");
            setNpData((value) => ({
              ...value,
              attachments: [
                ...value?.attachments,
                {
                  path: urlsArray[index],
                  cid: urlsArray[index],
                },
              ],
            }));
          }
        });
      } catch (err) {
        //  e.target.files = ''
        if (err?.response?.data?.message === "File too large") {
          // alert("File is too large, please upload another file");
          toast.warn("File is too large, please upload another file", {
            position: toast.POSITION.BOTTOM_LEFT,
          });

          return;
        }
      }
    }
  };

  const handleRemoveAttachment = (i) => {
    const data = npData?.attachments.filter((ele, index) => index != i);
    setNpData({ ...data, attachments: data });
  };

  return (
    <>
      {/* Seeking Model */}
      <Modal
        size="lg"
        isOpen={modal}
        // style={{ maxWidth: "600px" }}
        toggle={toggle}
        className="job-prefrence-modal gradient-background"
        centered
      >
        <div className="modal-content1">
          {/* <ModalHeader toggle={toggleSeekingModal}>Modal Title</ModalHeader> */}
          <ModalHeader
            className="dicardModalHeader text-white"
            toggle={toggle}
            // style={{
            //   background:
            //     "linear-gradient(120.5deg, #5B2E92 0%, #29409C 50.49%, #0F91D2 100%)",
            //   padding: "30px",
            // }}
          >
            <h3>
              <>
                <span
                  className="name"
                  style={{ color: "rgba(255, 255, 255, 1)" }}
                >
                  NP Mail
                </span>{" "}
              </>
            </h3>
          </ModalHeader>
          <ModalBody>
            <div>
              <div className="d-flex">
                <div className="">
                  <img
                    src={
                      profileData?.profile_img
                        ? profileData.profile_img
                        : placeholderUser
                    }
                    height={90}
                    width={90}
                    className="rounded-circle"
                  />
                </div>
                <div className="mx-3 mt-2">
                  <h4
                    className="text-dark text-capitalize"
                    style={{ fontWeight: "600" }}
                  >
                    {profileData?.first_name} {profileData?.last_name}
                  </h4>
                  <h6
                    className="mt-2 fw-thin text-capitalize"
                    style={{ color: "#676A79", fontWeight: "400" }}
                  >
                    {profileData?.profile_headline || "Profile Headline"}
                  </h6>
                </div>
              </div>
              <div
                className="d-flex justify-content-between align-items-center p-2 mt-3"
                style={{ backgroundColor: "#EEF0F259" }}
              >
                <div className="d-flex align-items-center">
                  {user?.data?.membership?.id > 1 && (
                    <>
                      <img src={GoldIcon} />
                      <span className="mx-2">Premium member</span>
                    </>
                  )}
                </div>
                <h6 className="text-dark">
                  Use {user?.data?.np_mail_count || 0} of{" "}
                  {user?.data?.np_mail_credit || 0} Np mail credits
                </h6>
              </div>

              <div className="mt-4">
                <form>
                  <FormGroup>
                    <Label for="subject">Subject</Label>
                    <input
                      type="text"
                      style={{ border: "1px solid #d1dfff" }}
                      name="subject"
                      value={npData?.subject}
                      placeholder="Optional"
                      onChange={(e) => handleChange(e)}
                    />
                  </FormGroup>
                  <FormGroup>
                    <Label for="message">Message</Label>
                    <textarea
                      className="w-100 p-2"
                      style={{ border: "1px solid #d1dfff" }}
                      rows={10}
                      name="message"
                      value={npData?.message}
                      placeholder="Write message"
                      onChange={(e) => handleChange(e)}
                    />
                    {error && <p className="text-danger">{error}</p>}
                  </FormGroup>
                  <FormGroup>
                    <label for="attachment">
                      <img src={AttachIcon} />
                    </label>
                    <input
                      type="file"
                      id="attachment"
                      className="hidden"
                      multiple
                      onChange={(e) => handleAttachment(e)}
                      accept="jpg|jpeg|png|gif|JPG|JPEG|PNG|GIF|pdf|ppt|pptx|txt|^text\/plain|doc|docx|xls|xlsx"
                    />
                  </FormGroup>
                  <div>
                    {npData?.attachments?.map((ele, i) => {
                      return (
                        <>
                          <div className="d-flex justify-content-between align-items-center p-2 bg-secondary-subtle mt-2">
                            <p>{ele?.path}</p>
                            <img
                              src={dustbin}
                              height={20}
                              width={20}
                              onClick={() => handleRemoveAttachment(i)}
                            />
                          </div>
                        </>
                      );
                    })}
                  </div>
                  <button
                    className="text-white w-100 p-3 rounded fw-bolder mt-3"
                    style={{ background: "#0432a3" }}
                    onClick={(e) => sendMail(e)}
                  >
                    Send
                  </button>
                </form>
              </div>
            </div>
          </ModalBody>
        </div>
      </Modal>
      <ToastContainer />
    </>
  );
};

export default NpMailModal;
