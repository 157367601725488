import { FOOTER_FIRST, FOOTER_SECOND, FOOTER_THIRD } from "../types";

export const initialState = {
    firstFooter: [],
    secondFooter:[],
    thirdFooter:[]
}

const landingPageReducer = (state = initialState, action) => {
    switch (action.type) {
      case FOOTER_FIRST:
        return {
          ...state,
          firstFooter: action.payload,
        };
        case FOOTER_SECOND:
        return {
          ...state,
          secondFooter: action.payload,
        };
        case FOOTER_THIRD:
        return {
          ...state,
          thirdFooter: action.payload,
        };
        default:
            return state;
    }
}

export default landingPageReducer;