//*Dropzone.js*//

import React, { useState } from "react";
import { useDropzone } from "react-dropzone";
import drop from "../../../../assets/img/Cloud.png";
import { toast } from "react-toastify";

function Dropzone({ handleBannerPicture }) {
  const [files, setFiles] = useState([]);

  const { getRootProps, getInputProps, isDragActive, acceptedFiles } =
    useDropzone({
      multiple: true,
      accept: {
        "application/pdf": [],
        "image/*": [],
      },
      onDrop: (acceptedFiles) => {
        handleBannerPicture(acceptedFiles);
      },
      onDropRejected: (fileRejections) => {
        alert("File rejected");
      },
    });

  //  const files = acceptedFiles.map(file => <li key={file.path}>{file.path}</li>);
  return (
    <>
      <div className="text-center mt-3 background-drag-and-drop">
        <div {...getRootProps({ className: "dropzone" })}>
          <input className="input-zone" {...getInputProps()} />
          <div className="image-upload p-5 ">
            {isDragActive ? (
              <p className="dropzone-content">Release to drop the files here</p>
            ) : (
              <div className="center d-flex justify-content-center align-items-center flex-column">
                <img src={drop} alt="drag-n-drop" height={20} width={20} />
                <span className="mx-2 text-dark">
                  Drag and drop or click to upload
                </span>
              </div>
            )}
            {/* Drag’n’drop some files here, or click to select files */}
          </div>
        </div>
      </div>
      <aside></aside>
    </>
  );
}

export default Dropzone;
