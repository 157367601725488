import { call } from "./apiCall";

class MemebersApi {
  getMemebers(query) {
    return new Promise((resolve, reject) => {
      let token = localStorage.getItem("accessToken");
      (async () => {
        try {
          const res = await call(
            "get",
            `api/v1/group/member-list`,
            query,
            null,
            token
          );
          if (res.data.success === true) {
            resolve(res.data);
          }
          if (res.data.success === false) {
            resolve(res.data);
          }
        } catch (err) {
          console.log(err);
          reject(err);
        }
      })();
    });
  }

  withdraw(data) {
    let token = localStorage.getItem("accessToken");
    return new Promise((resolve, reject) => {
      (async () => {
        try {
          const res = await call(
            "patch",
            `api/v1/group-request`,
            null,
            data,
            token
          )
          console.log(res.data)
          resolve(res.data)
        } catch (err) {
          console.log(err)
          reject(err)
          alert(err?.response?.data?.message)
        }
      })()
    })
  }

}





export const memebersApi = new MemebersApi();
