import React from "react";
import "./backgroundScreening.scss";
import AggrementContent from "./AggrementContent";
import BackgroundHeader from "./header";
import { useNavigate } from "react-router-dom";
import Navbar from "../../pages/navbar/navbar";
import Footer from "./footer";
export const BackgroundServiceScreen = () => {
  const navigate = useNavigate();
  return (
    <>
      <Navbar isJobs={false} />
      <div className="background_service_screen">
        <BackgroundHeader />
        <div className="container mt-3">
          <h4 className="text-center text-dark">
            Background Screening Services Agreement
          </h4>
          <AggrementContent />
          <p className="mt-4">
            By clicking "Proceed with Screening", you affirm your agreement to
            these terms, including NobelPage’s Legal Terms encompassing the
            Terms of Use, Privacy Policy, Cookie Policy, and Community
            Guidelines, as well as GBS’s Terms of Use and Privacy Policy. You
            understand that this constitutes a legally binding commitment and
            that you are authorizing GBS to execute the screening services as
            described.
          </p>
          <button
            className="mt-4 btn btn-blue"
            onClick={() => navigate("/background-report")}
          >
            Proceed with Screening
          </button>
          <Footer />
        </div>
      </div>
    </>
  );
};
