import React from "react";
import help from "../../../assets/img/help-bg.svg";
import logo from "../../../assets/img/np-logo.svg";
import { useDispatch, useSelector } from "react-redux";
import { getFAQSearch } from "../../../store/help/helpAction";
import { useState } from "react";
import { useEffect } from "react";
import { Link } from "react-router-dom";

const HelpCenterSearch = (props) => {
  const { helpData } = props;
  const [searchList, setSearchList] = useState([]);
  const [dropdown, setDropdown] = useState(false);
  const [filter, setFilter] = useState("");
  const dispatch = useDispatch();
  const list = useSelector((state) => state.help.searchList);

  useEffect(() => {
    if (list && list?.length > 0) {
      setSearchList(list);
    }
  }, [list]);

  useEffect(() => {
    document.addEventListener("mousedown", clickOutsideAdmin);
    return () => {
      document.removeEventListener("mousedown", clickOutsideAdmin);
    };
  }, []);

  const clickOutsideAdmin = (e) => {
    if (e.toElement && e?.toElement.className === "list") {
      return;
    }
    setDropdown(false);
  };

  const handleSearchChange = (e) => {
    if (e === "") {
      setSearchList([]);
      setDropdown(false);
    } else {
      setDropdown(true);
      dispatch(getFAQSearch({ search: e, filter: filter || undefined }));
    }
  };

  return (
    <div className="bg">
      <div className="container">
        <div className="row">
          <div className="col-sm-8 margin-top">
            <h1>{helpData?.title || "Help Center"}</h1>
            <p style={{ marginTop: "30px" }}>{helpData?.subtitle}</p>

            <div className="boxes">
              <div className="search" style={{ width: "230px" }}>
                <select
                  className="form-select"
                  style={{
                    lineHeight: "2.0rem",
                    borderRadius: "0rem",
                    padding: "0.3rem 2.25rem 0.375rem 0.75rem",
                  }}
                  value={filter}
                  onChange={(e) => setFilter(e?.target?.value || "")}
                >
                  <option defaultValue value={""}>
                    NobelPage Help
                  </option>
                  <option value="learning">Learning</option>
                  <option value="marketing">Marketing Solutions</option>
                  <option value="recruiter">Recruiter</option>
                  <option value="sales-insight">Sales Insights</option>
                  <option value="sales-navigator">Sales Navigator</option>
                  <option value="talent-hub">Talent Hub</option>
                  <option value="talent-insight">Talent Insights</option>
                  <option value="coperate-biling">Corporate Billing</option>
                </select>
                {/* <select>
                  <option>NobelPage Help</option>
                  <option>NobelPage Help</option>
                  <option>NobelPage Help</option>
                  <option>NobelPage Help</option>
                </select> */}
              </div>

              <div className="search-box">
                <input
                  type="text"
                  placeholder="How can we help?"
                  onChange={(e) => handleSearchChange(e.target.value)}
                />
                <div className="search-img"></div>
                {dropdown &&
                  (searchList.length > 0 ? (
                    <div className="search-list-box">
                      <ul>
                        {searchList.map((item) => (
                          <Link to={`/help/question/${item.id}`}>
                            <li
                              className="list"
                              onClick={() => setDropdown(!dropdown)}
                            >
                              {item.question}
                            </li>
                          </Link>
                        ))}
                      </ul>
                    </div>
                  ) : (
                    <div className="search-list-box">
                      <ul>
                        <li className="list">No Result Found</li>
                      </ul>
                    </div>
                  ))}
              </div>
            </div>
          </div>
          <div className="col-sm-4">
            <img src={help} alt="" className="bg-image" />
          </div>
        </div>
      </div>
      <div className="n-logo">
        <img src={logo} alt="" width="100%" />
      </div>
    </div>
  );
};

export default HelpCenterSearch;
