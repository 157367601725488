// import React, { useState, useEffect } from "react";
// import "./hashTags.scss";
// import Autocomplete from "react-autocomplete";
// import { useDispatch } from "react-redux";
// import { getHashTagList } from "../../../store/master/masterActions";

// function TagsInput(props) {
//   const { tags, setTags, placeholder, isCampaign } = props;

//   const [textInput, setTextInput] = useState("");
//   const [hashtagList, setHashtagList] = useState([]);
//   const [tagsOpen, setTagsOpen] = useState(false);
//   const [hastTagMessage, setHashTagMessage] = useState("");

//   const dispatch = useDispatch();

//   function handleKeyDown(e) {
//     // console.log("ev", e.keyCode!== 32);
//     // If user did not press enter key, return
//     if (e.key !== "Enter") return;
//     // if(e.key !== 'Enter' || e.keyCode!== 32 ) return
//     // Get the value of the input
//     const value = e.target.value;
//     // If the value is empty, return
//     if (!value.trim()) return;
//     // Add the value to the tags array
//     setHashTagMessage("");
//     setTags([...tags, value]);
//     // Clear the input
//     e.target.value = "";
//     setTextInput("");
//   }
//   function removeTag(index) {
//     setTags(tags.filter((el, i) => i !== index));
//   }

//   useEffect(() => {
//     if (textInput) {
//       dispatch(getHashTagList({ search: textInput.val }))
//         .then((res) => {
//           setHashtagList(res?.data);
//         })
//         .catch(() => {
//           setHashtagList([]);
//         });
//     }
//   }, [textInput]);

//   // console.log(hashtagList);
//   return (
//     <div className="tags-input-container">
//       {tags?.map((tag, index) => (
//         <div className="tag-item" key={index}>
//           <span className="text">{tag}</span>
//           <span className="close" onClick={() => removeTag(index)}>
//             &times;
//           </span>
//         </div>
//       ))}
//       {/* <input type="text" onKeyDown={handleKeyDown} className="tags-input" placeholder="Type somthing" /> */}
//       <div className="autocomplete-wrapper1">
//         <Autocomplete
//           //inputProps={{ placeholder: "Speaker" }}
//           open={tagsOpen}
//           value={textInput.val}
//           items={hashtagList}
//           getItemValue={(item) => item.name}
//           shouldItemRender={(item, value) =>
//             item.name.toLowerCase().indexOf(value.toLowerCase()) > -1
//           }
//           renderMenu={(items, value) => (
//             <div className="dropdown">
//               {/* {items.length === 0 && textInput !== ""
//                 ? `No matches for ${value}`
//                 : items} */}
//               {items}
//             </div>
//           )}
//           renderItem={(item, isHighlighted) => (
//             <div className={`item ${isHighlighted ? "selected-item" : ""}`}>
//               {item.name}
//             </div>
//           )}
//           onChange={(event, val) => {
//             setTextInput({ val });
//             if (val.length === 0) {
//               if (tagsOpen) setTagsOpen(false);
//             } else {
//               if (!tagsOpen) setTagsOpen(true);
//             }
//           }}
//           onSelect={(val, obj) => {
//             setTextInput({ val });
//             setTags([...tags, val]);
//             setTextInput("");
//             setTagsOpen(false);
//           }}
//           renderInput={(params) => {
//             return (
//               <input
//                 {...params}
//                 type="text"
//                 onKeyDown={handleKeyDown}
//                 className="tags-input"
//                 placeholder={placeholder}
//               />
//             );
//           }}
//           wrapperStyle={{}}
//         />
//       </div>
//     </div>
//   );
// }

// export default TagsInput;

import React, { useState, useEffect } from "react";
import "./hashTags.scss";
import Autocomplete from "react-autocomplete";
import { useDispatch } from "react-redux";
import { getHashTagList } from "../../../store/master/masterActions";

function TagsInput(props) {
  const { tags, setTags, placeholder, isCampaign } = props;

  const [textInput, setTextInput] = useState("");
  const [hashtagList, setHashtagList] = useState([]);
  const [tagsOpen, setTagsOpen] = useState(false);
  const [hastTagMessage, setHashTagMessage] = useState("");

  const dispatch = useDispatch();

  function handleKeyDown(e) {
    if (e.key !== "Enter") return;
    const value = e.target.value.trim();
    if (!value || tags.includes(value)) return;
    setHashTagMessage("");
    setTags([...tags, value]);
    e.target.value = "";
    setTextInput("");
  }

  function removeTag(index) {
    setTags(tags.filter((el, i) => i !== index));
  }

  useEffect(() => {
    if (textInput) {
      dispatch(getHashTagList({ search: textInput }))
        .then((res) => {
          setHashtagList(res?.data || []);
        })
        .catch(() => {
          setHashtagList([]);
        });
    }
  }, [textInput, dispatch]);

  return (
    <div className="tags-input-container">
      {tags?.map((tag, index) => (
        <div className="tag-item" key={index}>
          <span className="text">{tag}</span>
          <span className="close" onClick={() => removeTag(index)}>
            &times;
          </span>
        </div>
      ))}
      <div className="autocomplete-wrapper1">
        <Autocomplete
          open={tagsOpen}
          value={textInput}
          items={hashtagList}
          getItemValue={(item) => item.name}
          shouldItemRender={(item, value) =>
            item.name.toLowerCase().indexOf(value.toLowerCase()) > -1
          }
          renderMenu={(items, value) => <div className="dropdown">{items}</div>}
          renderItem={(item, isHighlighted) => (
            <div className={`item ${isHighlighted ? "selected-item" : ""}`}>
              {item.name}
            </div>
          )}
          onChange={(event, val) => {
            setTextInput(val);
            if (val.length === 0) {
              if (tagsOpen) setTagsOpen(false);
            } else {
              if (!tagsOpen) setTagsOpen(true);
            }
          }}
          onSelect={(val) => {
            if (!tags.includes(val)) {
              setTags([...tags, val]);
            }
            setTextInput("");
            setTagsOpen(false);
          }}
          renderInput={(params) => (
            <input
              {...params}
              type="text"
              onKeyDown={handleKeyDown}
              className="tags-input"
              placeholder={placeholder}
            />
          )}
          wrapperStyle={{}}
        />
      </div>
    </div>
  );
}

export default TagsInput;
