import React, { useEffect, useReducer, useState } from "react";
import {
  Button,
  Form,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "reactstrap";
import "./RecruiterModals.scss";
import { useNavigate } from "react-router-dom";
import { getAllCompanyList } from "../../../../store/profile/profileActions";
import { useDispatch, useSelector } from "react-redux";
import buld from "../../../../assets/img/circle-building.svg";
import Autocomplete from "react-autocomplete";
import { getLocationsData } from "../../../../store/connections/connectionsActions";
import { updateJobForm } from "../../../../store/jobs/jobAction";

const RecruiterModals = ({
  toggle,
  isOpen,
  title: propTitle,
  isPageJobs,
  pageID,
  pageName,
  pageIcon,
}) => {
  const navigate = useNavigate();

  const {
    jobType,
    jobTitle,
    jobWorkspacetype,
    jobCompany,
    jobListImage,
    jobEmploymentType,
    jobCompanyLocation,
    jobCompanyId,

    jobNumberOfVacancies,
  } = useSelector((state) => state.jobs);
  const dispatch = useDispatch();

  const [title, setTitle] = useState();
  const [titleError, setTitleError] = useState("");
  const [searchFieldValue, setSearchFieldValue] = useState("");
  const [locationsAPIData, setLocationsAPIData] = useState([]);
  const [locationOpen, setLocationOpen] = useState(false);

  const [pageDetails, setPageDetails] = useState({
    location: searchFieldValue,
  });

  const [errorCompany, setErrorCompany] = useState("");
  const [workspacetype, setWorkspaceType] = useState("On-site");
  const [companyLocationError, setCompanyLocationError] = useState("");
  const [employmentType, setEmploymentType] = useState("Full-time");
  const [numberOfVacancies, setNumberOfVacancies] = useState("");
  const [numberOfVacanciesError, setNumberOfVacanciesError] = useState("");

  const [companyList, setCompanyList] = useState([]);

  const [memberInput, setMemberInput] = useState(
    isPageJobs ? { val: pageName } : null
  );

  const [listImage, setListImage] = useState(
    isPageJobs ? { val: pageIcon } : null
  );
  const [companyId, setCompanyId] = useState(
    isPageJobs ? { val: pageID } : null
  );
  const [companyVisible, setCompanyVisible] = useState(true);

  const [expData, setExpData] = useState({});
  //Check modal
  const [isOnModal, setIsOnModal] = useState(true);
  //Validation state for company name
  const [isCompanyValid, setIsCompanyValid] = useState(true);
  const [isValidCompanySelectedError, setIsValidCompanySelectedError] =
    useState("");

  const getLocations = ({ value }) => {
    if (value)
      dispatch(getLocationsData(value))
        .then((response) => {
          if (response.data) {
            setLocationsAPIData(response.data);
          } else setLocationsAPIData([]);
        })
        .catch((err) => {
          setLocationsAPIData([]);
        });
    else setLocationsAPIData([]);
  };

  const handleWorkspaceTypeChange = (e) => {
    setWorkspaceType(e.target.value);
  };

  const handleEmploymentType = (e) => {
    setEmploymentType(e.target.value);
  };

  const handleNumberOfVacancies = (e) => {
    const value = e.target.value;

    if (/^\d+$/.test(value) && value >= 1 && value <= 999) {
      setNumberOfVacancies(value);
      setNumberOfVacanciesError("");
    } else {
      setNumberOfVacancies("");
      setNumberOfVacanciesError(
        "Please enter a valid number between 1 and 999."
      );
    }
  };

  useEffect(() => {
    if (jobType === "edit") {
      setTitle(jobTitle);
      setWorkspaceType(jobWorkspacetype);
      setMemberInput(jobCompany);
      setListImage(jobListImage);
      setEmploymentType(jobEmploymentType);
      setSearchFieldValue(jobCompanyLocation);
      setCompanyId(jobCompanyId?.val);
      setNumberOfVacancies(jobNumberOfVacancies);
    }
    return () => {
      setTitle("");
      setWorkspaceType("");
      setMemberInput("");
      setListImage("");
      setEmploymentType("");
      setSearchFieldValue("");
      setCompanyId("");
      setNumberOfVacancies("");
    };
  }, [jobType]);

  useEffect(() => {
    if (memberInput) {
      dispatch(getAllCompanyList({ search: memberInput.val }))
        .then((res) => {
          if (res?.success === true) {
            setCompanyList(res?.data);
          } else setCompanyList([]);
        })
        .catch(() => {
          setCompanyList([]);
        });
    }
  }, [memberInput]);

  const titleRegex = /^[a-zA-Z0-9\s]+$/;

  const handleValidation = () => {
    let isValid = true;
    let trimmedTitle;
    if (title) {
      trimmedTitle = title.trim();
    }
    if (
      !trimmedTitle ||
      trimmedTitle.length < 3 ||
      !trimmedTitle.match(titleRegex)
    ) {
      setTitleError(
        "Title must not be empty, should be at least 3 characters long, and should not contain special characters or blank spaces."
      );
      isValid = false;
    } else {
      setTitleError("");
    }

    if (!searchFieldValue) {
      setCompanyLocationError("Company location must not be empty.");
      isValid = false;
    } else {
      setCompanyLocationError("");
    }
    if (!numberOfVacancies) {
      setNumberOfVacanciesError("Please select the Number of Vacancies.");
      isValid = false;
    } else {
      setNumberOfVacanciesError("");
    }

    if (
      (!expData.company_name || !expData.company_name.trim()) &&
      !memberInput
    ) {
      setErrorCompany("The company name is mandatory.");
      setIsValidCompanySelectedError("");
      isValid = false;
      return;
    } else {
      setErrorCompany("");
    }
    if (!isValidCompanySelectedError == "") {
      isValid = false;
    }

    return isValid;
  };

  const handleGetStarted = () => {
    if (handleValidation()) {
      let formData = {
        jobTitle: title,
        jobWorkspacetype: workspacetype,
        jobCompany: memberInput,
        jobListImage: listImage,
        jobEmploymentType: employmentType,
        jobCompanyLocation: searchFieldValue,

        jobCompanyId: companyId?.val,
        jobNumberOfVacancies: numberOfVacancies,
      };
      dispatch(updateJobForm(formData));
      setTitle("");
      setWorkspaceType("");
      console.log({
        companyId,
        memberInput,
      });
      navigate("/post-job", {
        state: {
          title: title,
          company: memberInput.val,
          employmentType: employmentType,
          companyId: companyId,
          workspacetype: workspacetype,
          companyLocation: searchFieldValue,
          companyVisible: companyVisible,
          numberOfVacancies: numberOfVacancies,
          listImage: listImage,
        },
      });
    }
  };

  return (
    <>
      <Modal
        isOpen={isOpen}
        toggle={toggle}
        className="post-job-modal gradient-background"
        size="lg"
        style={{ maxWidth: "540px" }}
        centered
      >
        <ModalHeader toggle={() => toggle()}>
          Find a great hire, fast
        </ModalHeader>
        <span className="para-text">Rated #1 in delivering quality hires</span>
        <ModalBody>
          <form>
            <div className="title mb-3">
              <Label>Title</Label>
              <Input
                className="custom-cursor-style"
                style={{ color: "gray" }}
                type="text"
                value={title}
                onChange={(e) => {
                  setTitle(e.target.value);
                  setTitleError("");
                }}
                placeholder="Title"
              />
              {titleError && (
                <div className="error-message" style={{ color: "red" }}>
                  {titleError}
                </div>
              )}
            </div>
            <div className="title mb-3">
              <Label>Company</Label>
              <div className="company-custom-dropdown mb-20">
                <div className="autocomplete-wrapper custom-cursor-style">
                  <Autocomplete
                    value={memberInput?.val}
                    items={companyList}
                    getItemValue={(item) => item?.name}
                    renderMenu={(items, value) =>
                      items?.length === 0 ? (
                        <></>
                      ) : (
                        <div className="dropdown">{items} </div>
                      )
                    }
                    renderItem={(item, isHighlighted) => (
                      <div
                        className={`item ${
                          isHighlighted ? "selected-item" : ""
                        }`}
                        style={{ display: "flex" }}
                      >
                        <img
                          src={item.icon ? item?.icon : buld}
                          alt="dropdown_pic"
                          className="image-fit mr-10"
                          style={{ height: "36px", width: "36px" }}
                        />
                        <div>
                          <div className="name-heading-drop ">{item?.name}</div>
                        </div>
                      </div>
                    )}
                    onChange={(event, val) => {
                      console.log(val, event);
                      setMemberInput({ val });
                      setCompanyId(null);
                      setExpData({
                        ...expData,
                        company_name: val,
                        page_id: null,
                      });
                      if (val.trim().length > 0) {
                        setErrorCompany("");
                      }
                      const isValueFromDropdown =
                        companyList &&
                        companyList.some((item) => item.name === val);
                      setIsCompanyValid(isValueFromDropdown);
                      setIsValidCompanySelectedError(
                        isValueFromDropdown
                          ? ""
                          : "Please select a company from the dropdown"
                      );
                    }}
                    onSelect={(val, obj) => {
                      console.log(obj);
                      console.log(obj, val);
                      setMemberInput({ val });
                      setListImage(obj.icon);
                      setCompanyId(obj.id);

                      setExpData({
                        ...expData,
                        company_name: val,
                        page_id: obj.id,
                      });

                      setIsValidCompanySelectedError("");
                      setErrorCompany("");
                    }}
                    wrapperStyle={{}}
                    renderInput={(params) => {
                      return (
                        <div class="fake-input">
                          <input
                            {...params}
                            type="text"
                            placeholder="Company name"
                          />
                          <img
                            src={listImage ? listImage : buld}
                            alt=""
                            style={{
                              height: "36px",
                              width: "36px",
                              borderRadius: "25px",
                            }}
                          />
                        </div>
                      );
                    }}
                  />
                </div>
                {errorCompany && (
                  <p className="error-message" style={{ color: "red" }}>
                    {errorCompany}
                  </p>
                )}
                {isValidCompanySelectedError && (
                  <p
                    className="error-message"
                    style={{
                      color: "red",
                      fontWeight: "500 !important",
                      fontSize: "14px !important",
                    }}
                  >
                    {isValidCompanySelectedError}
                  </p>
                )}
              </div>
              <div className="bottom-txt" style={{ display: "flex" }}>
                <>
                  <Input
                    type="checkbox"
                    value={companyVisible}
                    onChange={() => setCompanyVisible(!companyVisible)}
                  />
                  <p
                    style={{
                      fontSize: "14px",
                      fontWeight: "400",
                      marginLeft: "7px",
                    }}
                  >
                    Mark Confidential Company
                  </p>{" "}
                </>
              </div>
            </div>
            <div className="title mb-3">
              <Label>Workspace type</Label>
              <Input
                type="select"
                value={workspacetype}
                onChange={handleWorkspaceTypeChange}
              >
                <option value="On-site">On-site</option>
                <option value="Hybrid">Hybrid</option>
                <option value="Remote">Remote</option>
              </Input>
            </div>
            <div className="title mb-3">
              <Label>Company location</Label>
              <div className="company-custom-dropdown mb-20">
                <div className="autocomplete-wrapper">
                  <div className="search">
                    <Autocomplete
                      open={locationOpen}
                      value={searchFieldValue}
                      items={locationsAPIData}
                      getItemValue={(item) => item.name}
                      shouldItemRender={() => locationsAPIData}
                      renderMenu={(items, value) => (
                        <div className="dropdown">
                          {items.length === 0
                            ? `No matches for ${value}`
                            : items}
                        </div>
                      )}
                      renderItem={(item, isHighlighted) => (
                        <div
                          className={`item ${
                            isHighlighted ? "selected-item" : ""
                          }`}
                        >
                          {item.name}
                        </div>
                      )}
                      onChange={(e, val) => {
                        if (val.length === 0) {
                          if (locationOpen) setLocationOpen(false);
                        } else {
                          if (!locationOpen) setLocationOpen(true);
                        }
                        setSearchFieldValue(e.target.value);
                        getLocations(e.target);
                      }}
                      onSelect={(value, obj) => {
                        setCompanyLocationError("");
                        setSearchFieldValue(value);
                        setPageDetails({
                          ...pageDetails,
                          location: obj.name,
                        });

                        setLocationOpen(false);
                      }}
                      wrapperStyle={{}}
                      renderInput={(params) => {
                        return (
                          <div class="fake-input">
                            <input
                              {...params}
                              type="text"
                              placeholder="location"
                            />
                          </div>
                        );
                      }}
                    />
                  </div>
                </div>
                {companyLocationError && (
                  <div className="error-message" style={{ color: "red" }}>
                    {companyLocationError}
                  </div>
                )}
              </div>
            </div>
            <div className="title mb-3">
              <Label>Employment type</Label>
              <Input
                type="select"
                b
                onChange={handleEmploymentType}
                value={employmentType}
              >
                <option value="Full-time">Full-time</option>
                <option value="Part-time">Part-time</option>
                <option value="Contract">Contract</option>
                <option value="Temporary">Temporary</option>
                <option value="Other">Other</option>
                <option value="Volunteer">Volunteer</option>
                <option value="Internship">Internship</option>
              </Input>
            </div>
            <div className="title mb-3">
              <Label>Number of Vacancies</Label>
              <Input
                placeholder="Number of vacancies"
                type="number"
                name="numberOfVacancies"
                value={numberOfVacancies}
                onChange={handleNumberOfVacancies}
                min="1"
                max="999"
              />
              {numberOfVacanciesError && (
                <div className="error-message" style={{ color: "red" }}>
                  {numberOfVacanciesError}
                </div>
              )}
            </div>
            <div className="startedbutton">
              <div className="btn btn-blue" onClick={handleGetStarted}>
                Get started
              </div>
            </div>
          </form>
        </ModalBody>
      </Modal>
    </>
  );
};

export default RecruiterModals;
