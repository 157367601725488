import React, { useState, useEffect } from "react";
import { call } from "../../../../api/apiCall";
import {
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
} from "reactstrap";
import { addlanguagedetails } from "../../../../store/profile/profileActions";
import { useDispatch, useSelector } from "react-redux";
import Dropdown from "../../../../core/components/DropDown/Dropdown";
import Autocomplete from "react-autocomplete";

const AddCourseModal = (props) => {
  const { modal, toggle, editLanguageData, setEditLanguageData } = props;
  const [errorMessage, setErrorMessage] = useState("");
  const [proficiencyList, setProficiencyList] = useState("");
  const [languageList, setLanguageList] = useState([]);
  const { isWebView } = useSelector((state) => state.auth);
  const [languageInput, setLanguageInput] = useState(
    editLanguageData.name ? editLanguageData.name : ""
  );
  const [expData, setExpData] = useState(
    editLanguageData
      ? {
          id: editLanguageData.id,
          name: editLanguageData.name,
          proficiency: editLanguageData.proficiency,
        }
      : {
          name: "",
          proficiency: "",
        }
  );

  const dispatch = useDispatch();

  useEffect(() => {
    (async () => {
      try {
        const res = await call(
          "get",
          "api/v1/language-proficiency",
          null,
          null
        );
        setProficiencyList(res.data);
      } catch (err) {
        console.log(err);
      }
    })();
  }, []);

  useEffect(() => {
    (async () => {
      try {
        const res = await call("get", "api/v1/locale", null, null);
        setLanguageList(res.data.data);
      } catch (err) {
        console.log(err);
      }
    })();
  }, []);

  const handleSubmit = () => {
    let newData = expData;
    Object.keys(newData).forEach((key) => {
      if (!newData[key]) delete newData[key];
    });
    if (expData?.name?.trim()) {
      let expDataformat = {
        languages: [newData],
      };
      var token = localStorage.getItem("accessToken");
      dispatch(addlanguagedetails(expDataformat, token))
        .then(() => {
          if (editLanguageData) {
            setEditLanguageData();
          }
          toggle();
        })
        .catch((e) => {
          console.log(e);
        });
    } else setErrorMessage("The field is Mandatory.");
  };

  const CloseModal = () => {
    if (editLanguageData) {
      setEditLanguageData();
    }
    toggle();
  };

  return (
    <div>
      <Modal
        isOpen={modal}
        size="lg"
        style={{ maxWidth: "500px" }}
        className="gradient-background"
        centered
      >
        <div className="popup">
          <div className="add-expriece">
            <ModalHeader toggle={CloseModal} className="py-3">
              {editLanguageData ? "Edit Language" : "Add Language"}
            </ModalHeader>
            <ModalBody className="pt-0">
              <p className="mb-2">* Indicates Required</p>
              <form>
                <div className="title">
                  <label>Language*</label>
                  {/* <Input
                    type="text"
                    placeholder=""
                    maxLength="50"
                    value={expData.name}
                    onClick={(e)=>setErrorMessage("")}
                    onChange={(e) =>
                      setExpData({ ...expData, name: e.target.value })
                    }
                  /> */}
                  {/* {isWebView ?
                    <Dropdown
                      label='Please Select'
                      data={languageList && languageList.data.map(item => ({
                        value: item.name,
                        id: item.id
                      }))}
                      dynamicPlaceHolder={expData.name}
                      handleSelect={(value) => {
                        setExpData({ ...expData, name: value.value })
                      }}
                    />
                    :
                    <Input
                      value={expData.name}
                      id="associatedwith"
                      name="select"
                      type="select"
                      onClick={(e) => setErrorMessage("")}
                      onChange={(e) =>
                        setExpData({ ...expData, name: e.target.value })
                      }
                    style={{color:"#676A79"}}
                    >
                      <option value="" >Please Select</option>
                      {languageList?.data?.map((item, index) => {
                        return <option key={index} value={item.name}>{item.name}</option>;
                      })}
                    </Input> */}
                  <div className="autocomplete-wrapper">
                    <Autocomplete
                      inputProps={{
                        placeholder: "Language",
                      }}
                      value={languageInput}
                      items={languageList}
                      getItemValue={(item) => item.name}
                      shouldItemRender={(item, value) =>
                        item.name.toLowerCase().indexOf(value.toLowerCase()) >
                        -1
                      }
                      renderMenu={(items, value) => (
                        <div className="dropdown">
                          {items.length === 0
                            ? `No matches for ${value}`
                            : items}
                        </div>
                      )}
                      renderItem={(item, isHighlighted) => (
                        <div
                          className={`item ${
                            isHighlighted ? "selected-item" : ""
                          }`}
                        >
                          {item.name}
                        </div>
                      )}
                      onChange={(obj, val) => {
                        setLanguageInput(val);
                        setExpData({ ...expData, name: obj.name });
                      }}
                      onSelect={(val, obj) => {
                        setLanguageInput(val);
                        setExpData({ ...expData, name: obj.name });
                      }}
                      wrapperStyle={{}}
                    />
                  </div>
                  {/* } */}

                  {errorMessage && (
                    <label className="label-input100 text-danger">
                      {errorMessage}
                    </label>
                  )}
                </div>
                <div className="title">
                  <FormGroup>
                    <Label for="proficiency">Proficiency</Label>

                    {/* {isWebView ?
                      <Dropdown
                        label='Please Select'
                        data={proficiencyList && proficiencyList.data.map(item => ({
                          value: item.name,
                          id: item.id
                        }))}
                        dynamicPlaceHolder={expData.proficiency}
                        handleSelect={(value) => {
                          setExpData({ ...expData, proficiency: value.value })
                        }}
                      />
                      : */}
                    <Input
                      value={expData.proficiency}
                      id="associatedwith"
                      name="select"
                      type="select"
                      style={{ color: "#676A79" }}
                      onChange={(e) =>
                        setExpData({ ...expData, proficiency: e.target.value })
                      }
                    >
                      <option selected="">Please Select</option>
                      {proficiencyList?.data?.map((item, index) => {
                        return (
                          <option key={index} value={item.name}>
                            {item.name}
                          </option>
                        );
                      })}
                    </Input>
                    {/* } */}
                  </FormGroup>
                </div>
                <div className="text-center">
                  <div
                    className={
                      isWebView ? "btnWebView btn-blue" : "btn btn-blue"
                    }
                    style={{ marginTop: "15px" }}
                    onClick={handleSubmit}
                  >
                    Done
                  </div>
                </div>
              </form>
            </ModalBody>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default AddCourseModal;
