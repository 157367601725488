import React, { useState } from "react";
import { Input, Modal, ModalBody, ModalHeader } from "reactstrap";

import { useDispatch, useSelector } from "react-redux";
import {
  getLanguageList,
  getUserSetting,
  updateSettingDetails,
} from "../../../store/setting/SettingAction";
import { useEffect } from "react";

const LanguageModal = (props) => {
  const { modal, toggle, title, data } = props;

  const langLalue = data.settings[0].value;
  const { LanguageListData } = useSelector((state) => state.setting);
  const dispatch = useDispatch();
  const [language, setLanguage] = useState(langLalue);

  const { user } = useSelector((state) => state.auth);
  const [responseMsg, setResponseMsg] = useState("");

  useEffect(() => {
    dispatch(getLanguageList());
  }, []);
  const updateMode = (e) => {
    try {
      setLanguage(e.target.value);
      let token = user?.token;
      let updateData = {
        setting: [
          {
            setting_master_id: 1,
            value: e.target.value,
          },
        ],
      };
      dispatch(updateSettingDetails(updateData, token)).then((res) => {
        dispatch(getUserSetting(0, token));
        setResponseMsg(res.message);
        setTimeout(() => {
          setResponseMsg(false);
        }, 3000);
      });
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <div>
      <Modal isOpen={modal} size="lg" style={{ maxWidth: "500px" }}>
        <div className="popup">
          <div className="setting-popup">
            <ModalHeader toggle={toggle} id="account-modal">{title}</ModalHeader>
            <ModalBody>
              <div className="box">
                <p>Select the default language you use on NobelPage</p>
                <select
                  id="exampleSelect"
                  name="select"
                  className="form-select my-3 width-50"
                  value={language}
                  onChange={(e) => updateMode(e)}
                  // disabled={true}
                >
                  <option>English</option>
                  {/* {LanguageListData?.data?.map((item, index) => {
                    return (
                      <option key={index} value={item.name}>
                        {item.name}
                      </option>
                    );
                  })} */}
                </select>
                <p>
                At the moment, only English is available. More languages are coming soon! 
However, for posts and feed, there is a translate message option available.
                  {/* <span>Learn More</span> */}
                </p>
                {responseMsg && (
                  <label className="label-input100 text-success">
                    {responseMsg}
                  </label>
                )}
              </div>
            </ModalBody>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default LanguageModal;
