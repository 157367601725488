import React, { useState } from "react";
import Layout from "../Layout/Layout";
import add from "../../../assets/img/add-icon.svg";
import UnfollowIcon from "../../../assets/img/realCross.png";
import { useNavigate } from "react-router";
import CreatePageModal from "./components/CreatePageModal";
import { useDispatch, useSelector } from "react-redux";
import ellipse3 from "../../../../src/assets/img/Ellipse3.png";
import {
  followPage,
  getAllPages,
  unFollowPage,
} from "../../../store/pages/pagesAction";
import { useEffect } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import nopreview from "../../../assets/img/no-preview.png";
import pagePlaceholder from "../../../assets/img/page-placeholder.svg";

import access from "../../../assets/img/access.png";
import { ToastContainer } from "react-toastify";
import AppLoader from "../../../core/components/Loaders/appLoader/appLoader";
import {
  initPageLoad,
  stopPageLoad,
} from "../../../store/loader/loaderActions";

const Pages = (props) => {
  const { createModal, setCreateModal } = props;
  const [currentPage, setCurrentPage] = useState(1);
  const [followingPages, setFollowingPages] = useState(false);
  const [myPages, setMyPages] = useState(false);
  const [searchKey, setSearchKey] = useState("");
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { pageData } = useSelector((state) => state.pages);
  const { isPageLoading } = useSelector((state) => state.loader);
  //  const [searchPage,setSearchPage] = useState("")

  const navigateToPageAdmin = (id) => {
    navigate(`/pageadminview/${id}`);
  };

  const getSuggestedPages = () => {
    setCurrentPage(1);
    dispatch(initPageLoad());
    dispatch(getAllPages({ search: searchKey ? searchKey : "", page: 1 }))
      .then(() => dispatch(stopPageLoad()))
      .catch(() => dispatch(stopPageLoad()));
    setFollowingPages(false);
    setMyPages(false);
  };
  const getFollowingPages = () => {
    setCurrentPage(1);
    setFollowingPages(true);
    setMyPages(false);
    dispatch(initPageLoad());
    dispatch(
      getAllPages({
        filter: "following",
        search: searchKey ? searchKey : "",
        page: 1,
      })
    )
      .then(() => dispatch(stopPageLoad()))
      .catch(() => dispatch(stopPageLoad()));
  };
  const getMyPages = () => {
    setCurrentPage(1);
    setMyPages(true);
    setFollowingPages(false);
    dispatch(initPageLoad());
    dispatch(
      getAllPages({ filter: "my", search: searchKey ? searchKey : "", page: 1 })
    )
      .then(() => dispatch(stopPageLoad()))
      .catch(() => dispatch(stopPageLoad()));
  };
  const handleFollow = (pageID, is_following) => {
    if (is_following) {
      dispatch(unFollowPage(pageID));
    } else {
      dispatch(followPage(pageID));
    }
  };
  const handleNext = () => {
    setCurrentPage(currentPage + 1);
    if (followingPages) {
      dispatch(
        getAllPages({
          page: currentPage,
          filter: "following",
          search: searchKey ? searchKey : "",
        })
      );
    } else if (myPages) {
      dispatch(
        getAllPages({
          page: currentPage,
          filter: "my",
          search: searchKey ? searchKey : "",
        })
      );
    } else
      dispatch(
        getAllPages({ page: currentPage, search: searchKey ? searchKey : "" })
      );
  };

  useEffect(() => {
    getSuggestedPages();
  }, []);
  useEffect(() => {
    if (!searchKey) {
      if (followingPages) {
        getFollowingPages();
      } else if (myPages) {
        getMyPages();
      } else getSuggestedPages();
    }
  }, [searchKey]);

  const filterSearchData = () => {
    if (followingPages) {
      dispatch(
        getAllPages({ filter: "following", search: searchKey ? searchKey : "" })
      );
    } else if (myPages) {
      dispatch(
        getAllPages({ filter: "my", search: searchKey ? searchKey : "" })
      );
    } else
      dispatch(getAllPages({ search: searchKey ? searchKey : "" })).then(
        (res) => {
          // console.log(res);
        }
      );
  };

  //Search on Enter Button

  const handleKeyDown = (e) => {
    // Check if the pressed key is "Enter" (key code 13)
    if (e.key === "Enter") {
      filterSearchData(searchKey);
    }
  };

  return (
    <Layout>
      <div className="col-lg-9">
        <div className="main-center mt-4">
          <div className="search">
            <input
              type="text"
              placeholder="Search Page"
              onChange={(e) => setSearchKey(e.target.value)}
              onKeyDown={handleKeyDown}
            />
            <div
              className="search-icon"
              onClick={() => filterSearchData()}
            ></div>
          </div>
          {isPageLoading && (
            <div className="d-flex justify-content-center align-items-center">
              <AppLoader styleProps={{ position: "fixed", left: "60%" }} />
            </div>
          )}
          <div className="wrapper mt-20">
            <div className="container p-3">
              <div className="overflow add-cursor">
                <ul className="nav nav-tabs" role="tablist">
                  <li className="nav-item">
                    <a
                      className="nav-link active "
                      data-bs-toggle="tab"
                      onClick={() => getSuggestedPages()}
                    >
                      Suggested Pages
                    </a>
                  </li>
                  <li className="nav-item">
                    <a
                      className="nav-link"
                      data-bs-toggle="tab"
                      onClick={() => getFollowingPages()}
                    >
                      Following Pages
                    </a>
                  </li>
                  <li className="nav-item">
                    <a
                      className="nav-link"
                      data-bs-toggle="tab"
                      onClick={() => getMyPages()}
                    >
                      My Pages
                    </a>
                  </li>
                  <li className="nav-item">
                    <div
                      className="end"
                      onClick={() => {
                        setCreateModal(!createModal);
                      }}
                    >
                      + Create Page
                    </div>
                  </li>
                </ul>
              </div>
              <div className="tab-content mt-20">
                <div id="home" className="container tab-pane active">
                  <InfiniteScroll
                    dataLength={currentPage}
                    next={handleNext}
                    hasMore={true}
                  >
                    <div className="row mt-20">
                      <div className="pages">
                        <div className="networks">
                          <div className="wrapper">
                            <div className="groups">
                              <div className="groups-card ">
                                <div className="row">
                                  {pageData?.data ? (
                                    pageData?.data?.map((data, index) => {
                                      return (
                                        <div key={index} className="col-lg-4">
                                          <div className="card card-height-page ">
                                            <div
                                              className="card-img bg"
                                              style={{ height: "138px" }}
                                            >
                                              {/* <img
                                                src={
                                                  data.background_img
                                                    ? data.background_img
                                                    : nopreview
                                                }
                                                className="img-fluid"
                                                alt=""
                                              /> */}
                                              {data.background_img ? (
                                                <img
                                                  src={data.background_img}
                                                  className="img-fluid"
                                                  alt="page_background"
                                                />
                                              ) : (
                                                <img alt="" />
                                              )}
                                              <div className="circle-icon">
                                                <img
                                                  src={
                                                    data?.icon
                                                      ? data?.icon
                                                      : pagePlaceholder
                                                  }
                                                  onClick={() =>
                                                    navigateToPageAdmin(data.id)
                                                  }
                                                  className="pageicon"
                                                  alt="page_icon"
                                                />
                                              </div>
                                            </div>
                                            <div className="ml-20 mb-20 mr-20">
                                              <div className="header">
                                                <h4
                                                  className="card-event-control"
                                                  onClick={() =>
                                                    navigateToPageAdmin(data.id)
                                                  }
                                                >
                                                  {data.name}
                                                </h4>
                                                <div className="text">
                                                  <div className="d-flex align-items-center justify-content-center">
                                                    <div className="groupicon"></div>
                                                    {/* <p>
                                                      {data.followers_count}{" "}
                                                      {data.followers_count > 1
                                                        ? "followers"
                                                        : "follower"}
                                                    </p> */}
                                                    <div>
                                                      {data.followers_count >
                                                      999
                                                        ? `${Math.floor(
                                                            data.followers_count /
                                                              1000
                                                          )}k followers`
                                                        : `${
                                                            data.followers_count
                                                          } ${
                                                            data.followers_count >
                                                            1
                                                              ? "followers"
                                                              : "follower"
                                                          }`}
                                                    </div>
                                                  </div>
                                                </div>
                                                {myPages && data.is_admin ? (
                                                  <div className="text">
                                                    <div className="d-flex align-items-center justify-content-center">
                                                      <img
                                                        src={access}
                                                        alt="access"
                                                        className="accessicon"
                                                      />
                                                      <p>{data.is_admin}</p>
                                                    </div>
                                                  </div>
                                                ) : (
                                                  <></>
                                                )}
                                              </div>
                                              <div className="text card-page-text-control mb-20">
                                                <p>{data.description}</p>
                                                {data.hashtags && (
                                                  <div className="link">
                                                    {data.hashtags.map(
                                                      (hastag, index) =>
                                                        "#" + hastag
                                                    )}
                                                  </div>
                                                )}
                                              </div>
                                              {myPages ? (
                                                <div
                                                  className="btn btn-light btn-margin-bottom"
                                                  onClick={() =>
                                                    navigateToPageAdmin(data.id)
                                                  }
                                                >
                                                  <span>View</span>
                                                </div>
                                              ) : (
                                                <div
                                                  className="btn btn-light btn-margin-bottom"
                                                  onClick={() => {
                                                    handleFollow(
                                                      data.id,
                                                      data.is_following
                                                    );
                                                  }}
                                                >
                                                  {data.is_following ? (
                                                    <span>
                                                      <img
                                                        src={UnfollowIcon}
                                                        alt=""
                                                        className="followicon"
                                                        width={15}
                                                      />
                                                      {"   "}
                                                      Unfollow
                                                    </span>
                                                  ) : (
                                                    <span>
                                                      <img
                                                        src={add}
                                                        alt=""
                                                        className="followicon"
                                                      />
                                                      {"   "}
                                                      Follow
                                                    </span>
                                                  )}
                                                </div>
                                              )}
                                            </div>
                                          </div>
                                        </div>
                                      );
                                    })
                                  ) : (
                                    // <div className="text-center mt-20 mb-20">
                                    //   <div
                                    //     className="btn btn-blue"
                                    //     onClick={() =>
                                    //       setCreateModal(!createModal)
                                    //     }
                                    //   >
                                    //     Create Page
                                    //   </div>
                                    // </div>
                                    <div className="row ">
                                      <p
                                        style={{ textAlign: "center" }}
                                        className="my-30"
                                      >
                                        <b>No pages available</b>
                                      </p>
                                    </div>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </InfiniteScroll>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {createModal && (
        <CreatePageModal
          modal={createModal}
          toggle={() => setCreateModal(!createModal)}
          getMyPages={getMyPages}
        />
      )}
      <ToastContainer />
    </Layout>
  );
};

export default Pages;
