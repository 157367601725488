import React, { useEffect, useState } from "react";
import { VideoSidebar } from "../../../../../core/components/layout/Sidebar/VideoSidebar";
import Sidebar from "../../../../../core/components/layout/Sidebar/Sidebar";
import { useDispatch, useSelector } from "react-redux";
import {
  deleteRecommendation,
  getRecommendationData,
  updateRecommendation,
} from "../../../../.././store/recommendation/recommendationAction";
import placeholderUser from "../../../../../assets/img/placeholder_user.jpg";
import add from "../../../../../assets/img/add-icon.svg";
import edit from "../../../../../assets/img/edit.svg";
import del from "../../../../../assets/img/Trash can.svg";
import moment from "moment";
import { useNavigate, useParams } from "react-router-dom";
import GiveRecommendationModal from "./giveRecommendation";
import { FormGroup, Input } from "reactstrap";
import WriteRecommendationModal from "./writeRecommendation";
import { toast } from "react-toastify";
import "./Recommend.scss";
import DeleteModal from "../../../../../core/components/modal/DeleteModal";
import SidebarFooter from "../../../../setting/SidebarFooter";
import Edit from "../../../../../assets/img/edits.svg";
import give from "../../../../../assets/img/give.svg";
import request from "../../../../..//assets/img/request.svg";
import { initLoad, stopLoad } from "../../../../../store/loader/loaderActions";
import AppLoader from "../../../../../core/components/Loaders/appLoader/appLoader";

export default function Recomendation(props) {
  const params = useParams();
  const navigate = useNavigate();
  const {
    profileData,
    deleteIntroVideo,
    isAllowedToEdit,
    askRecommendation,
    setAskRecommendation,
    experienceData,
    giveRecommendation,
    setGiveRecommendation,
    writeRecommendationModal,
    setWriteRecommendationModal,
    isEditRecommend,
    setIsEditRecommend,
    writeTitle,
    setWriteTitle,
    memberView,
    addIntroModal,
    setAddIntroModal,
  } = props;

  const { recommendation } = useSelector((state) => state.recommendation);
  const { isLoading } = useSelector((state) => state.loader);
  const { user } = useSelector((state) => state.auth);
  const [deleteModal, setDeleteModal] = useState(false);
  const [deleteItem, setDeleteItem] = useState();
  const [tabs, setTabs] = useState("Received");
  const [recommendPendingUser, setRecommendPendingUser] = useState([]);
  const [recommendId, setRecommendId] = useState();
  const [recommendationText, setRecommendationText] = useState();
  const [recommendStatus, setRecommendStatus] = useState("initial");
  const [showAllRecommend, setShowAllRecommend] = useState(true);
  const [readMore, setReadMore] = useState(false);
  const [recommendationData, setRecommendationData] = useState(recommendation);

  let id = params.id;

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(
      getRecommendationData("received", { type: "received", user_id: id })
    );
  }, []);

  useEffect(() => {
    if (tabs === "Given") {
      dispatch(initLoad());
      dispatch(getRecommendationData("given", { type: "given", user_id: id }))
        .then(() => dispatch(stopLoad()))
        .catch(() => dispatch(stopLoad()));
      setShowAllRecommend(true);
    } else if (tabs === "Received") {
      dispatch(initLoad());
      dispatch(
        getRecommendationData("received", { type: "received", user_id: id })
      )
        .then(() => dispatch(stopLoad()))
        .catch(() => dispatch(stopLoad()));
      setShowAllRecommend(true);
    } else if (tabs === "Pending") {
      dispatch(initLoad());
      dispatch(
        getRecommendationData("pending", { type: "pending", user_id: id })
      )
        .then(() => dispatch(stopLoad()))
        .catch(() => dispatch(stopLoad()));
      setShowAllRecommend(true);
    }
  }, [tabs, params.id]);

  const getTrueRecommend = () => {
    const newRecom = recommendation?.filter((item) => {
      return item.status !== false;
    });
    setRecommendationData(newRecom);
  };

  useEffect(() => {
    if (tabs === "Received" && !isEditRecommend) {
      getTrueRecommend();
      setShowAllRecommend(false);
    } else setShowAllRecommend(true);
  }, [recommendation, tabs, isEditRecommend]);

  const updateToggle = (event, item) => {
    if (event.target.checked) {
      let data = {
        id: item?.id,
        status: true,
        recommendation_status: "confirm",
      };
      dispatch(updateRecommendation(data));
    } else {
      let dataF = {
        id: item?.id,
        status: false,
        recommendation_status: "confirm",
      };
      dispatch(updateRecommendation(dataF));
    }
  };

  const deleteRecommend = (id) => {
    dispatch(deleteRecommendation({ id })).then((res) => {
      toast.success(res.message, {
        position: toast.POSITION.BOTTOM_LEFT,
      });
    });
  };

  const addToProfile = (item) => {
    let data = {
      id: item?.id,
      recommendation_status: "confirm",
      status: true,
    };
    dispatch(updateRecommendation(data)).then((res) => {
      toast.success(res.message, {
        position: toast.POSITION.BOTTOM_LEFT,
      });
    });
  };
  const recommend = (item) => {
    setRecommendPendingUser(item?.toUser);
    setWriteRecommendationModal(true);
    setRecommendId(item?.id);
    setWriteTitle(`Write ${item?.toUser?.first_name} a recommendation`);
  };

  function truncate(str, n) {
    return str.length > n ? str.slice(0, n - 1) + "..." : str;
  }

  const editRecieved = (item) => {
    setRecommendPendingUser(item?.fromUser);
    setWriteRecommendationModal(true);
    setRecommendId(item?.id);
    setRecommendationText(
      `Hi ${item?.fromUser?.first_name},can you please revise your recommendation for me? Specifically,could you change…`
    );

    setRecommendStatus("revised");
    setWriteTitle(
      `Ask ${truncate(item?.fromUser?.first_name, 25)} for a revision`
    );
  };

  const editGiven = (item) => {
    setRecommendPendingUser(item?.toUser);
    setWriteRecommendationModal(true);
    setRecommendationText(item?.recommendation);
    setRecommendId(item?.id);
    setWriteTitle(
      `Revise your recommendation for  ${item?.toUser?.first_name}`
    );
  };

  const revise = (item) => {
    setRecommendPendingUser(item?.toUser);
    setWriteRecommendationModal(true);
    setRecommendationText("");
    setRecommendId(item?.id);
    setRecommendStatus("initial");
    setWriteTitle(`Revise your recommendation for ${item?.toUser?.first_name}`);
  };

  return (
    <div className="row">
      {isLoading && <AppLoader />}
      <div className="col-lg-9">
        <div className="recomendation mb-30">
          <div className="head_flex_rec">
            <div className="heading">
              <h6 className="text-dark">Recommendations</h6>
            </div>
            <div className="d-flex gap-2 align-items-center float-xl-end ">
              {user?.data?.id == id && (
                <>
                  <div
                    className="btn btn-light h-40 text-blue "
                    style={{
                      width: "100px",
                      height: "24px",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      // Add a minimum height if needed
                      minHeight: "24px",
                    }}
                    onClick={() => setAskRecommendation(!askRecommendation)}
                  >
                    <img
                      style={{
                        height: "14px",
                        width: "14px",
                        marginRight: "5px",
                      }}
                      src={request}
                      alt="add"
                      title="Give Recommendation"
                      role="tooltip"
                    />
                    <p>Request</p>
                  </div>
                  {user?.data?.membership?.name == "Starter" ? (
                    <></>
                  ) : (
                    <div
                      className="add"
                      onClick={() => setGiveRecommendation(!giveRecommendation)}
                    >
                      <div
                        className="btn btn-light h-30 text-blue"
                        style={{
                          width: "75px",
                          height: "24px",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          // Add a minimum height if needed
                          minHeight: "24px",
                        }}
                      >
                        <img
                          style={{
                            height: "14px",
                            width: "14px",
                            marginRight: "5px",
                          }}
                          src={give}
                          alt="add"
                          title="Give Recommendation"
                          role="tooltip"
                        />
                        Give
                      </div>
                    </div>
                  )}

                  {!memberView && (
                    <div className="pencil-edit">
                      <img
                        style={{ height: "24px", width: "24px" }}
                        src={Edit}
                        alt="edit"
                        onClick={() => {
                          setIsEditRecommend(!isEditRecommend);
                          setShowAllRecommend(true);
                        }}
                      />
                    </div>
                  )}
                </>
              )}
            </div>
          </div>
          <div className="d-flex mt-30 gap-3">
            <div
              className={tabs === "Received" ? "circle-blue" : "borders"}
              onClick={() => {
                setTabs("Received");
                setShowAllRecommend(true);
              }}
            >
              Received
            </div>
            {user?.data?.membership?.name == "Starter" ? (
              <></>
            ) : (
              <div
                className={tabs === "Given" ? "circle-blue" : "borders"}
                onClick={() => {
                  setTabs("Given");
                  setShowAllRecommend(true);
                }}
              >
                Given
              </div>
            )}
            {isEditRecommend && user?.data?.id == id && !memberView && (
              <div
                className={tabs === "Pending" ? "circle-blue" : "borders"}
                onClick={() => {
                  setTabs("Pending");
                  setShowAllRecommend(true);
                }}
              >
                Pending
              </div>
            )}
          </div>
          {showAllRecommend ? (
            <div>
              {recommendation?.length ? (
                recommendation?.map((item, index) => {
                  return (
                    <div className="row mt-30" key={index}>
                      <div className="col-md-4 col-lg-4 mb-3">
                        <div className="row" style={{ display: "flex" }}>
                          <div className="col-2">
                            <div className="profile-img_rec">
                              {tabs === "Given" ||
                              (user?.data?.id === item?.fromUser?.id &&
                                tabs === "Pending") ? (
                                <img
                                  src={
                                    item?.toUser?.profile_img
                                      ? item?.toUser?.profile_img
                                      : placeholderUser
                                  }
                                  alt=""
                                  className="image-fit"
                                  onClick={() =>
                                    navigate(`/profile/${item?.toUser?.id}`)
                                  }
                                />
                              ) : (
                                <img
                                  src={
                                    item?.fromUser?.profile_img
                                      ? item?.fromUser?.profile_img
                                      : placeholderUser
                                  }
                                  alt=""
                                  className="image-fit"
                                  onClick={() =>
                                    navigate(`/profile/${item?.fromUser?.id}`)
                                  }
                                />
                              )}
                            </div>
                          </div>
                          <div className="col-10">
                            <div className="details ml-20 ">
                              <div className="row">
                                {tabs === "Given" ||
                                (user?.data?.id === item?.fromUser?.id &&
                                  tabs === "Pending") ? (
                                  <div className="d-flex  align-items-center justify-content-between">
                                    <h6
                                      className="card-control-text-oneline me-2"
                                      style={{
                                        lineHeight: "30px",
                                      }}
                                      onClick={() =>
                                        navigate(`/profile/${item?.toUser?.id}`)
                                      }
                                    >
                                      {item?.toUser?.first_name}{" "}
                                      {item?.toUser?.last_name}
                                    </h6>
                                    {item?.recommendation_status ===
                                    "pending" ? (
                                      <button
                                        className=""
                                        style={{
                                          background: "#19bcf4",
                                          padding: "0px 5px",
                                          width: "max-content",
                                          color: "white",
                                        }}
                                      >
                                        {user?.data?.id === item?.fromUser?.id
                                          ? "Requested"
                                          : "Sent"}
                                      </button>
                                    ) : item?.recommendation_status ===
                                        "initial" && tabs === "Pending" ? (
                                      <button
                                        className=""
                                        style={{
                                          background: "#19bcf4",
                                          padding: "0px 5px",
                                          width: "max-content",
                                          color: "white",
                                        }}
                                      >
                                        Recieved
                                      </button>
                                    ) : item?.recommendation_status ===
                                        "revised" && tabs === "Pending" ? (
                                      <button
                                        className=""
                                        style={{
                                          background: "#19bcf4",
                                          padding: "0px 5px",
                                          width: "max-content",
                                          color: "white",
                                        }}
                                      >
                                        Revised
                                      </button>
                                    ) : (
                                      <></>
                                    )}
                                  </div>
                                ) : (
                                  <div className="d-flex  align-items-center  justify-content-between">
                                    <h6
                                      className="card-control-text-oneline me-2"
                                      style={{
                                        lineHeight: "30px",
                                      }}
                                      onClick={() =>
                                        navigate(
                                          `/profile/${item?.fromUser?.id}`
                                        )
                                      }
                                    >
                                      {item?.fromUser?.first_name}{" "}
                                      {item?.fromUser?.last_name}
                                    </h6>

                                    {item?.recommendation_status ===
                                    "pending" ? (
                                      <button
                                        className=""
                                        style={{
                                          background: "#19bcf4",
                                          padding: "0px 5px",
                                          width: "max-content",
                                          color: "white",
                                        }}
                                      >
                                        {user?.data?.id === item?.fromUser?.id
                                          ? "Requested"
                                          : "Sent"}
                                      </button>
                                    ) : item?.recommendation_status ===
                                        "initial" && tabs === "Pending" ? (
                                      <button
                                        className=""
                                        style={{
                                          background: "#19bcf4",
                                          padding: "0px 5px",
                                          width: "max-content",
                                          color: "white",
                                        }}
                                      >
                                        Recieved
                                      </button>
                                    ) : item?.recommendation_status ===
                                        "revised" && tabs === "Pending" ? (
                                      <button
                                        className=""
                                        style={{
                                          background: "#19bcf4",
                                          padding: "0px 5px",
                                          width: "max-content",
                                          color: "white",
                                        }}
                                      >
                                        Revised
                                      </button>
                                    ) : (
                                      <></>
                                    )}
                                  </div>
                                )}
                                {/* {item?.recommendation_status === "pending" ? (
                                  <button
                                    className=""
                                    style={{
                                      background: "#19bcf4",

                                      width: "max-content",
                                      color: "white",
                                    }}
                                  >
                                    {user?.data?.id === item?.fromUser?.id
                                      ? "Requested"
                                      : "Sent"}
                                  </button>
                                ) : item?.recommendation_status === "initial" &&
                                  tabs === "Pending" ? (
                                  <button
                                    className=""
                                    style={{
                                      background: "#19bcf4",

                                      width: "max-content",
                                      color: "white",
                                    }}
                                  >
                                    Recieved
                                  </button>
                                ) : item?.recommendation_status === "revised" &&
                                  tabs === "Pending" ? (
                                  <button
                                    className=""
                                    style={{
                                      background: "#19bcf4",

                                      width: "max-content",
                                      color: "white",
                                    }}
                                  >
                                    Revised
                                  </button>
                                ) : (
                                  <></>
                                )} */}
                              </div>
                              <p className="card-control-text-twoline">
                                {item?.fromUser?.profile?.profile_headline ? (
                                  item?.fromUser?.profile?.profile_headline
                                ) : (
                                  <></>
                                )}
                              </p>
                              <p>
                                {moment(item?.createdAt).format("MMM DD, YYYY")}
                              </p>
                              {item?.recommendation_status === "pending" &&
                              tabs === "Pending" ? (
                                <div className="mt-2 w-75">
                                  {user?.data?.id === item?.fromUser?.id ? (
                                    <div
                                      className="borders"
                                      style={{
                                        textAlign: "center",
                                        padding: "3px 11px",
                                        height: "27px",
                                        width: "fit-content",
                                      }}
                                      onClick={() => recommend(item)}
                                    >
                                      {" "}
                                      Recommend{" "}
                                    </div>
                                  ) : (
                                    ""
                                  )}
                                </div>
                              ) : item?.recommendation_status === "initial" &&
                                tabs === "Pending" ? (
                                <div
                                  className="borders mt-2"
                                  style={{
                                    textAlign: "center",
                                    padding: "3px 11px",
                                    height: "27px",
                                    width: "fit-content",
                                  }}
                                  onClick={() => addToProfile(item)}
                                >
                                  Add to profile
                                </div>
                              ) : item?.recommendation_status === "revised" &&
                                tabs === "Pending" ? (
                                <div
                                  className="borders mt-2 w-75"
                                  style={{
                                    textAlign: "center",
                                    padding: "3px 11px",
                                    height: "27px",
                                    width: "fit-content",
                                  }}
                                  onClick={() => revise(item)}
                                >
                                  Revised
                                </div>
                              ) : (
                                <></>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className=" col-md-6 ps-5 col-lg-6">
                        <div className="details ">
                          <p className="desc">
                            {item?.recommendation?.length > 100 && !readMore
                              ? item?.recommendation.slice(0, 100) + "..."
                              : item?.recommendation}
                          </p>
                          {item?.recommendation?.length < 100 ? (
                            ""
                          ) : item?.recommendation?.length > 100 &&
                            !readMore ? (
                            <p
                              className="read_more_rec"
                              onClick={() => setReadMore(!readMore)}
                            >
                              Read more
                            </p>
                          ) : (
                            <p
                              className="read_more_rec"
                              onClick={() => setReadMore(!readMore)}
                            >
                              Show less
                            </p>
                          )}
                        </div>
                      </div>
                      <div className=" col-md-2 col-lg-2">
                        {isEditRecommend && !memberView && (
                          <div className="d-flex gap-2 justify-content-end">
                            {tabs === "Received" && (
                              <FormGroup switch>
                                <Input
                                  type="switch"
                                  role="switch"
                                  checked={item.status}
                                  onClick={(e) => {
                                    updateToggle(e, item);
                                  }}
                                />
                              </FormGroup>
                            )}
                            {tabs === "Received" ? (
                              <div className="pencil-edit">
                                <img
                                  src={edit}
                                  alt="edit"
                                  onClick={() => editRecieved(item)}
                                />
                              </div>
                            ) : tabs === "Given" ? (
                              <div className="pencil-edit">
                                <img
                                  src={edit}
                                  alt="edit"
                                  title="Revise Recommendation"
                                  role="tooltip"
                                  onClick={() => editGiven(item)}
                                />
                              </div>
                            ) : (
                              <></>
                            )}

                            {tabs === "Given" || tabs === "Pending" ? (
                              <div
                                onClick={() => {
                                  setDeleteModal(!deleteModal);
                                  setDeleteItem(item?.id);
                                }}
                                className="delete"
                                // onClick={() => deleteRecommend(item?.id)}
                              >
                                <img src={del} alt="delete" />
                              </div>
                            ) : (
                              <></>
                            )}
                          </div>
                        )}
                        {/* <div className="details ">
                    <p>{item?.recommendation}</p>
                    <p className="read_more_rec">Read more</p>
                  </div> */}
                      </div>
                    </div>
                  );
                })
              ) : (
                <div className="mt-4 ms-2">No Data Available</div>
              )}
            </div>
          ) : (
            <div>
              {recommendationData?.length ? (
                recommendationData?.map((item, index) => {
                  return (
                    <div className="row mt-30" key={index}>
                      <div className=" col-md-4 col-lg-4 mb-3">
                        <div className="row" style={{ display: "flex" }}>
                          <div className="col-2">
                            <div className="profile-img_rec">
                              <img
                                src={
                                  item?.fromUser?.profile_img
                                    ? item?.fromUser?.profile_img
                                    : placeholderUser
                                }
                                alt=""
                                className="image-fit"
                                onClick={() =>
                                  navigate(`/profile/${item?.fromUser?.id}`)
                                }
                              />
                            </div>
                          </div>
                          <div className="col-10">
                            <div className="details ml-20">
                              <div style={{ display: "flex", gap: "10px" }}>
                                <h6
                                  className="card-control-text-oneline"
                                  style={{
                                    lineHeight: "25.5px",
                                  }}
                                  onClick={() =>
                                    navigate(`/profile/${item?.fromUser?.id}`)
                                  }
                                >
                                  {item?.fromUser?.first_name}{" "}
                                  {item?.fromUser?.last_name}
                                </h6>
                              </div>
                              <p className="card-control-text-twoline">
                                {item?.fromUser?.profile?.profile_headline ? (
                                  item?.fromUser?.profile?.profile_headline
                                ) : (
                                  <></>
                                )}
                              </p>
                              <p>
                                {moment(item?.createdAt).format("MMM DD, YYYY")}
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className=" col-md-6 ps-5 col-lg-6">
                        <div className="details ">
                          <p className="desc">
                            {item?.recommendation?.length > 100 && !readMore
                              ? item?.recommendation.slice(0, 100) + "..."
                              : item?.recommendation}
                          </p>
                          {item?.recommendation?.length < 100 ? (
                            ""
                          ) : item?.recommendation?.length > 100 &&
                            !readMore ? (
                            <p
                              className="read_more_rec"
                              onClick={() => setReadMore(!readMore)}
                            >
                              Read more
                            </p>
                          ) : (
                            <p
                              className="read_more_rec"
                              onClick={() => setReadMore(!readMore)}
                            >
                              Show less
                            </p>
                          )}
                        </div>
                      </div>
                      <div className=" col-md-2 col-lg-2">
                        {/* {isEditRecommend && (
                      <div className="d-flex gap-2">
                        {tabs === "Received" && (
                          <FormGroup switch>
                            <Input
                              type="switch"
                              role="switch"
                              checked={item.status}
                              onClick={(e) => {
                                updateToggle(e, item);
                              }}
                            />
                          </FormGroup>
                        )}
                        {tabs === "Received" ? (
                          <div className="pencil-edit">
                            <img
                              src={edit}
                              alt="edit"
                              onClick={() => editRecieved(item)}
                            />
                          </div>
                        ) : tabs === "Given" ? (
                          <div className="pencil-edit">
                            <img
                              src={edit}
                              alt="edit"
                              title="Revise Recommendation"
                              role="tooltip"
                              onClick={() => editGiven(item)}
                            />
                          </div>
                        ) : (
                          <></>
                        )}

                        {tabs === "Given" || tabs === "Pending" ? (
                          <div
                            onClick={() => {
                              setDeleteModal(!deleteModal);
                              setDeleteItem(item?.id);
                            }}
                            className="delete"
                            // onClick={() => deleteRecommend(item?.id)}
                          >
                            <img src={del} alt="delete" />
                          </div>
                        ) : (
                          <></>
                        )}
                      </div>
                    )} */}
                        {/* <div className="details ">
                    <p>{item?.recommendation}</p>
                    <p className="read_more_rec">Read more</p>
                  </div> */}
                      </div>
                    </div>
                  );
                })
              ) : (
                <div className="mt-4 ms-2">No Data Available</div>
              )}
            </div>
          )}
        </div>
      </div>
      <div className="col-lg-3">
        <div className="mb-20">
          <VideoSidebar
            URLData={profileData?.intro_video}
            deleteIntroVideo={deleteIntroVideo}
            isAllowedToEdit={isAllowedToEdit}
            addIntroModal={addIntroModal}
            setAddIntroModal={setAddIntroModal}
          />
        </div>
        <div className="mb-20">
          <Sidebar />
        </div>
        <div className="sidebar-sticky mb-20">
          <SidebarFooter />
        </div>
      </div>

      {askRecommendation && (
        <GiveRecommendationModal
          modal={askRecommendation}
          title="Ask a recommendation"
          askingRecommendation={true}
          toggle={() => setAskRecommendation(!askRecommendation)}
          experienceData={experienceData}
          writeRecommendationModal={writeRecommendationModal}
          setWriteRecommendationModal={setWriteRecommendationModal}
          writeTitle={writeTitle}
          setWriteTitle={setWriteTitle}
          user={user}
        />
      )}
      {giveRecommendation && (
        <GiveRecommendationModal
          modal={giveRecommendation}
          title="Give a recommendation"
          askingRecommendation={false}
          toggle={() => setGiveRecommendation(!giveRecommendation)}
          experienceData={experienceData}
          writeRecommendationModal={writeRecommendationModal}
          setWriteRecommendationModal={setWriteRecommendationModal}
          writeTitle={writeTitle}
          setWriteTitle={setWriteTitle}
          user={user}
        />
      )}
      {writeRecommendationModal && (
        <WriteRecommendationModal
          modal={writeRecommendationModal}
          title={writeTitle}
          askingRecommendation={false}
          toggle={() => setWriteRecommendationModal(!writeRecommendationModal)}
          memberData={recommendPendingUser}
          recommendfromPending={true}
          recommendId={recommendId}
          recommendationText={recommendationText}
          recommendStatus={recommendStatus}
        />
      )}
      {deleteModal && (
        <DeleteModal
          modal={DeleteModal}
          toggle={() => setDeleteModal(!deleteModal)}
          title="Delete Confirmation"
          deleteConfirm={() => deleteRecommend(deleteItem)}
          text="Are you sure you want to delete this?"
        />
      )}
    </div>
  );
}
