import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import InsightsModal from "../../../../routes/mynetwork/Pages/components/InsightsModal";
import { getAllPagesInsights } from "../../../../store/pages/pagesAction";

export const Insight = (props) => {
  const { followers, getPageInsights, insightData, isPositive, pageID } = props;
  const [insightmodal, setInsightmodal] = useState(false);
  const [followerData, setFollowerData] = useState();
  const [reachedData, setReachedData] = useState();
  const [engagementData, setEngagementData] = useState();
  const [showText, setShowText] = useState("");
  const dispatch = useDispatch();
  const getFollowersInsights = () => {
    getPageInsights("follower");
    setShowText("Page Followers");
  };
  const getReachedInsights = () => {
    getPageInsights("reached");
    setShowText("People Reached");
  };
  const getEngageInsights = () => {
    getPageInsights("engagement");
    setShowText("People Engaged");
  };
  useEffect(() => {
    if (pageID) {
      dispatch(getAllPagesInsights({ page_id: pageID, type: "follower" })).then(
        (res) => {
          setFollowerData(res.data);
        }
      );
      dispatch(getAllPagesInsights({ page_id: pageID, type: "reached" })).then(
        (res) => {
          setReachedData(res.data);
        }
      );
      dispatch(
        getAllPagesInsights({ page_id: pageID, type: "engagement" })
      ).then((res) => {
        setEngagementData(res.data);
      });
    }
  }, [pageID]);

  return (
    <div>
      <div className="insight-side">
        <div className="bg-white b-5 ">
          <h2 className="p-20 text-dark">Insights</h2>
          <div className="line"></div>
          <div className="p-20">
            <div className="box">
              <div className="row">
                <div className="col-8">
                  <h6>People Reached</h6>
                  <p>{reachedData?.totalCount}</p>
                </div>
                <div className="col-4">
                  <small
                    className={
                      Math.sign(reachedData?.percentage) == 1
                        ? "growth"
                        : "decline"
                    }
                  >
                    <span
                      className={
                        Math.sign(reachedData?.percentage) == 1 ? "up" : "down"
                      }
                    ></span>
                    {Math.abs(reachedData?.percentage)}%
                  </small>
                  <button
                    className="btn"
                    onClick={() => {
                      setInsightmodal(true);
                      getReachedInsights();
                    }}
                  >
                    More info{" "}
                  </button>
                </div>
              </div>
              <div className="line my-3"></div>

              <div className="row">
                <div className="col-8">
                  <h6>People Engagements</h6>
                  <p>{engagementData?.totalCount}</p>
                </div>
                <div className="col-4">
                  <small
                    className={
                      Math.sign(engagementData?.percentage) == 1
                        ? "growth"
                        : "decline"
                    }
                  >
                    <span
                      className={
                        Math.sign(engagementData?.percentage) == 1
                          ? "up"
                          : "down"
                      }
                    ></span>
                    {Math.abs(engagementData?.percentage)}%
                  </small>
                  <button
                    className="btn"
                    onClick={() => {
                      setInsightmodal(true);
                      getEngageInsights();
                    }}
                  >
                    More info{" "}
                  </button>
                </div>
              </div>
              <div className="line my-3"></div>

              <div className="row">
                <div className="col-8">
                  <h6>Page Followers</h6>
                  <p>{followerData?.totalCount}</p>
                </div>
                <div className="col-4">
                  <small
                    className={
                      Math.sign(followerData?.percentage) == 1
                        ? "growth"
                        : "decline"
                    }
                  >
                    <span
                      className={
                        Math.sign(followerData?.percentage) == 1 ? "up" : "down"
                      }
                    ></span>
                    {Math.abs(followerData?.percentage)}%
                  </small>
                  <button
                    className="btn"
                    onClick={() => {
                      setInsightmodal(true);
                      getFollowersInsights();
                    }}
                  >
                    More info
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {insightmodal && (
        <InsightsModal
          modal={insightmodal}
          toggle={() => setInsightmodal(!insightmodal)}
          title="Insights"
          insightData={insightData}
          getFollowersInsights={getFollowersInsights}
          getReachedInsights={getReachedInsights}
          getEngageInsights={getEngageInsights}
          showText={showText}
          setShowText={setShowText}
          isPositive={isPositive}
        />
      )}
    </div>
  );
};
