import {INITIALIZE_THE_LOADER, STOP_THE_LOADER, INITIALIZE_THE_PAGE_LOADER, STOP_THE_PAGE_LOADER, INIT_SETTING_PAGE_LOADER, STOP_SETTING_PAGE_LOADER} from "../types";

export const initialState = {
isLoading:false,
isSettingPageLoad:false
};

const loader = (state = initialState, action) => {
 switch (action.type) {
  
  case INITIALIZE_THE_LOADER:
   return { ...state, isLoading: true };

   case STOP_THE_LOADER:
    return { ...state, isLoading: false };

  case INITIALIZE_THE_PAGE_LOADER:
   return { ...state, isPageLoading: true };

  case STOP_THE_PAGE_LOADER:
    return { ...state, isPageLoading: false };
  
  case INIT_SETTING_PAGE_LOADER:
    return { ...state, isSettingPageLoad: true };
  
    case STOP_SETTING_PAGE_LOADER:
      return { ...state, isSettingPageLoad: false };
 
   default:
   return state;
 }
};

export default loader;