import { dashboardApi } from "../../api/dashboardAPI";
import {
  CREATE_POST_FAIL,
  CREATE_POST_SUCCESS,
  FAIL_GET_POST_DETAILS,
  GET_POST_DETAILS,
  FAIL_POST_POLL,
  POST_POLL,
  GET_CELEBRATION_TYPES,
  VOTES_RESULT_FAIL,
  VOTES_RESULT,
  UPDATE_POST_SUCCESS,
  UPDATE_POST_FAIL,
  DELETE_POST_SUCCESS,
  DELETE_POST_FAIL,
  MERGE_SHARE_DATA,
  SAVE_POST,
  SAVE_POST_FAIL,
  DELETE_SAVED_POST,
  DELETE_SAVED_POST_FAIL,
  GET_PAGE_DETAILS,
  GET_PAGE_DETAILS_FAIL,
  ADD_COMMENT,
  DELETE_COMMENT,
  ADD_COMMENT_FAIL,
  GET_COMMENT,
  GET_COMMENT_FAIL,
  GET_REPORT_TYPE,
  FAIL_GET_REPORT_TYPE,
  LIKE_REACTION_DATA,
  LIKE_REACTION_COMMENT_DATA,
  SUBMIT_REPORT,
  DELETE_REACTION_DATA,
  GET_POST_DATA_DETAIL,
  ADD_MORE_COMMENT,
  ADD_COMMENT_REPLY,
  DELETE_COMMENT_REPLY,
  HIDE_USER_POST,
  DELETE_REACTION_COMMENT_DATA,
  DELETE_REACTION_REPLY_DATA,
  REPOST_POST,
  GET_MORE_COMMENT_REPLY,
  LIKE_REACTION_REPLY_DATA,
  UPDATE_HOME_CONNECTION_STATUS,
  GET_HASHTAG_POST_FEED,
  FOLLOW_HASHTAG_POST_FEED,
  UNFOLLOW_HASHTAG_POST_FEED,
  FILTER_UPDATE_MY_POST_LIST,
  COMMENT_STATUS,
  COMMENT_STATUS_FALSE,
  COMMENT_STATUS_TRUE,
  PIN_COMMENT,
  BLOCK_COMMENT,
  UPDATE_COMMENT,
  UNPIN_COMMENT,
  AS_A_PAGE_ROLE,
  UPDATE_COMMENT_REPLY,
  GET_STATISTICS,
  GET_MORE_STATISTICS,
  POSTVIEWLOG_SUCCESS,
  POSTVIEWCOUNT_SUCCESS,
  GET_AD_LIST,
} from "../types";
import { initPageLoad } from "../loader/loaderActions";
import { stopPageLoad } from "../loader/loaderActions";
import { toast } from "react-toastify";

export const createPost =
  (postDatas, token, callback) => (dispatch, getState) => {
    const { postData } = getState().dashboard;
    return dashboardApi.createPost(postDatas, token).then(
      (response) => {
        {
          postDatas.feed_status === "published"
            ? dispatch({
                type: GET_POST_DETAILS,
                payload: {
                  ...postData,
                  data: [response.data, ...postData.data],
                },
              })
            : dispatch({
                type: CREATE_POST_SUCCESS,
                payload: postDatas,
              });
          if (callback) {
            callback();
          }
        }
        return Promise.resolve(response);
      },
      (error) => {
        dispatch({
          type: CREATE_POST_FAIL,
        });
        console.log(error);
        return Promise.reject(error);
      }
    );
  };

export const activityFeedList = (response, params) => (dispatch, getState) => {
  // dispatch({
  //   type: UPDATE_HOME_CONNECTION_STATUS,
  //   payload: { data: data, post_id: post_id },
  // });
  const { postData } = getState().dashboard;
  dispatch(initPageLoad());
  dispatch({
    type: GET_POST_DETAILS,
    payload:
      postData && params?.skip && params?.limit
        ? { ...response, data: [...postData.data, ...response.data] }
        : response,
  });
  dispatch(stopPageLoad());
};

// export const getPostDetailsbyId = (token, params) => (dispatch) => {
//   return dashboardApi.getPostDetails(token, params).then(
//     (response) => {
//       dispatch({
//         type: GET_POST_DATA_DETAIL,
//         payload: response,
//       });
//       return Promise.resolve(response);
//     },
//     (error) => {
//       console.log(error);
//       return Promise.reject(error);
//     }
//   );
//   }

export const deletePost = (_id, token) => (dispatch, getState) => {
  const { postData } = getState().dashboard;
  return dashboardApi.deletePost(_id, token).then(
    (response) => {
      let data = postData?.data.filter((ele) => {
        if (ele?.Repost) {
          if (ele.Repost.id !== _id) {
            return ele;
          }
        } else if (ele.id !== _id) {
          return ele;
        }
      });
      dispatch({
        type: DELETE_POST_SUCCESS,
        payload: data,
      });
      return Promise.resolve(response);
    },
    (error) => {
      dispatch({
        type: DELETE_POST_FAIL,
      });
      console.log(error);
      return Promise.reject(error);
    }
  );
};

export const updatePost = (postDatas, token, type, callback) => (dispatch) => {
  return dashboardApi.updatePost(postDatas, token).then(
    (response) => {
      if (type === "schedulePost" || type === "draftPost") {
        dispatch({
          type: FILTER_UPDATE_MY_POST_LIST,
          payload: response.data,
        });
      } else
        dispatch({
          type: UPDATE_POST_SUCCESS,
          payload: response.data,
        });
      if (callback) {
        callback();
      }

      // dispatch({
      //   type: UPDATE_POST_SUCCESS,
      //   payload: postDatas,
      // });
      // if (callback) {
      //   callback();
      // }
      return Promise.resolve(response);
    },
    (error) => {
      dispatch({
        type: UPDATE_POST_FAIL,
      });
      console.log(error);
      return Promise.reject(error);
    }
  );
};

export const updateComment =
  (token, commentID, commentText, postID, replyId, type) => (dispatch) => {
    return dashboardApi.updateComment(token, commentID, commentText).then(
      (response) => {
        if (type === "reply") {
          console.log(commentID, replyId);
          dispatch({
            type: UPDATE_COMMENT_REPLY,
            payload: {
              commentID: commentID,
              commentText: commentText,
              postID: postID,
              replyId: replyId,
            },
          });
        } else {
          dispatch({
            type: UPDATE_COMMENT,
            payload: {
              commentID: commentID,
              commentText: commentText,
              postID: postID,
            },
          });
        }
        return Promise.resolve(response);
      },
      (error) => {
        console.log(error);
        return Promise.reject(error);
      }
    );
  };

export const getPostDetails = (token, params) => (dispatch, getState) => {
  const { postData } = getState().dashboard;
  dispatch(initPageLoad());
  return dashboardApi.getPostDetails(token, params).then(
    (response) => {
      dispatch({
        type: GET_POST_DETAILS,
        // payload: response
        payload:
          postData && params?.skip && params?.limit
            ? { ...response, data: [...postData.data, ...response.data] }
            : response,
      });
      dispatch(stopPageLoad());
      return Promise.resolve(response);
    },
    (error) => {
      dispatch({
        type: FAIL_GET_POST_DETAILS,
      });
      console.log(error);
      dispatch(stopPageLoad());
      return Promise.reject(error);
    }
  );
};

export const getPageDetails = (token, params) => (dispatch) => {
  // const { postData } = getState().dashboard;
  return dashboardApi.getPageDetails(token, params).then(
    (response) => {
      dispatch({
        type: GET_PAGE_DETAILS,
        payload: response,
        // payload: postData
        //   ? { ...response, data: [...postData.data, ...response.data] }
        //   : response,
      });
      return Promise.resolve(response);
    },
    (error) => {
      dispatch({
        type: GET_PAGE_DETAILS_FAIL,
      });
      console.log(error);
      return Promise.reject(error);
    }
  );
};
export const postViewLog = (postData) => (dispatch) => {
  return dashboardApi.postViewlog(postData).then((response) => {
    dispatch({
      type: POSTVIEWLOG_SUCCESS,
      payload: response.data,
    });
    dispatch({
      type: POSTVIEWCOUNT_SUCCESS,
      payload: postData.post_id,
    });
    return Promise.resolve(response);
  });
};

export const getCelebrationTypes = () => (dispatch) => {
  return dashboardApi.getCelebrationTypes().then(
    (response) => {
      dispatch({
        type: GET_CELEBRATION_TYPES,
        payload: response,
      });
      return Promise.resolve(response);
    },
    (error) => {
      console.log(error);
      return Promise.reject(error);
    }
  );
};

export const votePoll = (token, pollData, postId) => (dispatch) => {
  return dashboardApi.votePoll(pollData, token).then(
    (response) => {
      dispatch({
        type: POST_POLL,
        payload: { pollData: pollData, postId: postId },
      });
      return Promise.resolve(response);
    },
    (error) => {
      dispatch({
        type: FAIL_POST_POLL,
      });
      console.log(error);
      return Promise.reject(error);
    }
  );
};

export const votesResultAction = (token, postId) => (dispatch) => {
  return dashboardApi.votesResult(token, postId).then(
    (response) => {
      dispatch({
        type: VOTES_RESULT,
        payload: response,
      });
      return Promise.resolve(response);
    },
    (error) => {
      dispatch({
        type: VOTES_RESULT_FAIL,
      });
      console.log(error);
      return Promise.reject(error);
    }
  );
};

export const mergeShareData = (data) => (dispatch) => {
  dispatch({
    type: MERGE_SHARE_DATA,
    payload: data,
  });
};

export const savePost = (id, token) => (dispatch) => {
  return dashboardApi.savePost(id, token).then(
    (response) => {
      dispatch({
        type: SAVE_POST,
        payload: id,
      });
      return Promise.resolve(response);
    },
    (error) => {
      dispatch({
        type: SAVE_POST_FAIL,
      });
      console.log(error);
      return Promise.reject(error);
    }
  );
};

export const savePostDelete = (postDatas, token) => (dispatch) => {
  return dashboardApi.removeSavePost(postDatas, token).then(
    (response) => {
      dispatch({
        type: DELETE_SAVED_POST,
        payload: postDatas,
      });
      return Promise.resolve(response);
    },
    (error) => {
      dispatch({
        type: DELETE_SAVED_POST_FAIL,
      });
      console.log(error);
      return Promise.reject(error);
    }
  );
};

export const addComments = (data, token) => (dispatch) => {
  return dashboardApi.addComment(data, token).then(
    (response) => {
      dispatch({
        type: ADD_COMMENT,
        payload: { response: response.data, post_id: data },
      });
      return Promise.resolve(response);
    },
    (error) => {
      dispatch({
        type: ADD_COMMENT_FAIL,
      });
      console.log(error);
      return Promise.reject(error);
    }
  );
};

export const deleteCommentData = (data, token, repliesCount) => (dispatch) => {
  return dashboardApi.deleteCommentData(data, token).then(
    (response) => {
      dispatch({
        type: DELETE_COMMENT,
        payload: { post_id: data, reply_count: repliesCount },
      });
      return Promise.resolve(response);
    },
    (error) => {
      console.log(error);
      return Promise.reject(error);
    }
  );
};

export const pinComment = (data, token, unPin) => (dispatch) => {
  return dashboardApi.pinComment(data, token).then((response) => {
    if (unPin == true) {
      console.log("unpin here");
      dispatch({
        type: UNPIN_COMMENT,
        payload: data,
      });
    } else
      dispatch({
        type: PIN_COMMENT,
        payload: data,
      });
    console.log("pin here");
    return Promise.resolve(response);
  });
};

export const addCommentReply = (data, token) => (dispatch) => {
  return dashboardApi.addComment(data, token).then((response) => {
    dispatch({
      type: ADD_COMMENT_REPLY,
      payload: { response: response.data, post_id: data },
    });
    return Promise.resolve(response);
  });
};

export const deleteReplyData = (data, commentId, token) => (dispatch) => {
  return dashboardApi.deleteCommentData(data, token).then(
    (response) => {
      dispatch({
        type: DELETE_COMMENT_REPLY,
        payload: { post_id: data, commentId: commentId },
      });
      return Promise.resolve(response);
    },
    (error) => {
      console.log(error);
      return Promise.reject(error);
    }
  );
};

export const getAllComments = (token, query) => (dispatch) => {
  return dashboardApi.getComment(token, query).then(
    (response) => {
      if (query.skip === 0) {
        dispatch({
          type: GET_COMMENT,
          payload: { response: response, post_id: query },
        });
      } else {
        dispatch({
          type: ADD_MORE_COMMENT,
          payload: { response: response, post_id: query },
        });
      }
      return Promise.resolve(response);
    },
    (error) => {
      dispatch({
        type: GET_COMMENT_FAIL,
      });
      console.log(error);
      return Promise.reject(error);
    }
  );
};

export const get_Report_type = (token) => (dispatch, getState) => {
  return dashboardApi.get_Report_type(token).then(
    (response) => {
      dispatch({
        type: GET_REPORT_TYPE,
        payload: response,
      });
      return Promise.resolve(response);
    },
    (error) => {
      dispatch({
        type: FAIL_GET_REPORT_TYPE,
      });
      console.log(error);
      return Promise.reject(error);
    }
  );
};

export const homeConnectionStatus = (data, post_id) => (dispatch) => {
  dispatch({
    type: UPDATE_HOME_CONNECTION_STATUS,
    payload: { data: data, post_id: post_id },
  });
};

export const submit_report = (data) => (dispatch) => {
  return dashboardApi.submitReport(data).then((response) => {
    dispatch({
      type: SUBMIT_REPORT,
      payload: response,
    });
    return Promise.resolve(response);
  });
};
export const block_comment = (data) => (dispatch) => {
  return dashboardApi.blockCommentReport(data).then((response) => {
    dispatch({
      type: BLOCK_COMMENT,
      payload: response,
    });
    return Promise.resolve(response);
  });
};

export const hide_user_post = (post_id) => (dispatch) => {
  return dashboardApi.hideUserPost(post_id).then((response) => {
    dispatch({
      type: HIDE_USER_POST,
      payload: post_id,
    });
    return Promise.resolve(response);
  });
};

export const repost_post = (postData, type) => (dispatch) => {
  return dashboardApi.repostPost(postData).then((response) => {
    if (type === "savedPost") {
      return;
    } else {
      dispatch({
        type: REPOST_POST,
        payload: response.data,
      });
    }
    return Promise.resolve(response);
  });
};

export const likeReactionData = (data, type) => (dispatch) => {
  if (type === "deleteReaction") {
    dispatch({
      type: DELETE_REACTION_DATA,
      payload: data,
    });
  } else {
    dispatch({
      type: LIKE_REACTION_DATA,
      payload: data,
    });
  }
};

export const likeReactionCommentData =
  (data, id, type, commentId) => (dispatch) => {
    console.log("type", type);
    if (type === "reply") {
      dispatch({
        type: LIKE_REACTION_REPLY_DATA,
        payload: { data: data, post_id: id, comment_id: commentId },
      });
    } else if (type === "deleteReaction") {
      dispatch({
        type: DELETE_REACTION_COMMENT_DATA,
        payload: { data: data, post_id: id },
      });
    } else if (type === "deleteReactionReply") {
      dispatch({
        type: DELETE_REACTION_REPLY_DATA,
        payload: { data: data, post_id: id, comment_id: commentId },
      });
    } else {
      dispatch({
        type: LIKE_REACTION_COMMENT_DATA,
        payload: { data: data, post_id: id },
      });
    }
  };

export const getAllCommentReply = (token, query) => (dispatch) => {
  return dashboardApi.getComment(token, query).then((response) => {
    if (query.skip === 0) {
      dispatch({
        type: GET_COMMENT,
        payload: { response: response, post_id: query },
      });
    } else {
      dispatch({
        type: GET_MORE_COMMENT_REPLY,
        payload: { response: response, post_id: query },
      });
    }
    return Promise.resolve(response);
  });
};

export const hashtagPostFeedUpdate = (data) => (dispatch) => {
  dispatch({
    type: GET_HASHTAG_POST_FEED,
    payload: data,
  });
};

export const hashtagFollowFeedUpdate = (type) => (dispatch) => {
  if (type === "follow") {
    dispatch({
      type: FOLLOW_HASHTAG_POST_FEED,
      payload: type,
    });
  } else if (type === "unfollow") {
    dispatch({
      type: UNFOLLOW_HASHTAG_POST_FEED,
      payload: type,
    });
  }
};

export const comment_status = (postId, type) => (dispatch) => {
  dashboardApi.commentStatus(postId).then((res) => {
    toast.success(res.message, {
      position: toast.POSITION.BOTTOM_LEFT,
    });
    if (type === "commentTrue") {
      dispatch({
        type: COMMENT_STATUS_TRUE,
        payload: postId,
      });
    } else if (type === "commentFalse") {
      dispatch({
        type: COMMENT_STATUS_FALSE,
        payload: postId,
      });
    }
  });
};

export const as_a_page = (postId, type, pageData) => (dispatch) => {
  dispatch({
    type: AS_A_PAGE_ROLE,
    payload: { postId, type, pageData },
  });
};

export const viewStatistics =
  (view_type, view_type_id, type, limit, skip) => (dispatch) => {
    return dashboardApi
      .getViewStatistics(view_type, view_type_id, type, limit, skip)
      .then((response) => {
        console.log(skip, "skip");
        if (skip === 0) {
          dispatch({
            type: GET_STATISTICS,
            payload: response,
          });
        } else {
          console.log("comeHere");
          dispatch({
            type: GET_MORE_STATISTICS,
            payload: response,
          });
        }
        return Promise.resolve(response);
      });
  };

export const getAds = () => (dispatch) => {
  return dashboardApi
    .getAdsList()
    .then((response) => {
      dispatch({
        type: GET_AD_LIST,
        payload: response,
      });
      return Promise.resolve(response);
    })
    .catch((error) => {
      return Promise.reject(error);
    });
};

// export const as_a_main_role = (postId, type, pageData) => (dispatch) => {
//   dispatch({
//     type: AS_A_MAIN_ROLE,
//     payload: { postId, type, pageData },
//   });
// };
