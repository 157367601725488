import React from "react";
import image from "../../../../assets/img/nobel-page.png";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

export default function Footers() {
  const navigate = useNavigate();
  return (
    <div>
      <section className="nobel-page">
        <div className="container" style={{ marginTop: "40px" }}>
          <div className="row category-footers">
            <div className="col-lg-6 footer-logo">
              <img src={image} alt="" />
            </div>
            <div className="col-lg-6">
              <div className="d-flex justify-content-between flex-wrap gap-5">
                <ul>
                  <h4 className="category-title">General</h4>
                  <li>
                    {" "}
                    <Link to="/signup">Sign Up</Link>
                  </li>
                  <li>
                    <Link to="/about">About</Link>
                  </li>
                  <li>
                    <Link to="/help">Help Center</Link>
                  </li>
                  <li
                    onClick={() => {
                      window.scrollTo(0, 0);
                      toast.warning("Please Login/Signup to access further", {
                        position: toast.POSITION.BOTTOM_LEFT,
                      });
                      navigate("/", {
                        state: { navigateto: "careers" },
                      });
                    }}
                    className="careers"
                  >
                    Careers
                  </li>
                </ul>
                <ul>
                  <h4 className="category-title">Browse NobelPage</h4>
                  <li
                    onClick={() => {
                      window.scrollTo(0, 0);
                      toast.warning("Please Login/Signup to access further", {
                        position: toast.POSITION.BOTTOM_LEFT,
                      });
                      navigate("/", {
                        state: { navigateto: "jobs" },
                      });
                    }}
                    className="careers"
                  >
                    Jobs
                  </li>
                  <li
                    onClick={() => {
                      window.scrollTo(0, 0);
                      toast.warning("Please Login/Signup to access further", {
                        position: toast.POSITION.BOTTOM_LEFT,
                      });
                      navigate("/", {
                        state: { navigateto: "interview-preperation" },
                      });
                    }}
                  >
                    Interview Preparation
                  </li>
                  <li>
                    <Link to="/mobile">Mobile</Link>
                  </li>
                  {/* <li
                    onClick={() =>
                      alert(
                        "This feature will be implmented in upcoming milestones"
                      )
                    }
                  >
                    TalentPool
                  </li> */}
                  <li>
                    <Link
                      to="https://nobelpageworkerpoolstage.dev.vinove.com/"
                      className="nav-item nav-link"
                    >
                      WorkerPool
                    </Link>
                  </li>
                  <li
                    onClick={() => {
                      window.scrollTo(0, 0);
                      toast.success("Please Login/Signup to access further", {
                        position: toast.POSITION.BOTTOM_LEFT,
                      });
                      navigate("/", {
                        state: { navigateto: "lead_management" },
                      });
                    }}
                    className="lead-manage"
                  >
                    Lead Management
                  </li>
                </ul>
                <ul>
                  <h4 className="category-title">Directories</h4>
                  <li>
                    <Link to="/directory/members">Members</Link>
                  </li>
                  <li>
                    <Link to="/directory/jobs">Jobs</Link>
                  </li>
                  <li>
                    <Link to="/directory/companies">Companies</Link>
                  </li>
                  <li>
                    <Link to="/directory/posts">Posts</Link>
                  </li>
                  <li>
                    <Link to="/directory/articles">Articles</Link>
                  </li>
                  <li>
                    <Link to="/directory/newsletters">Newsletters</Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div className="container">
          <div className="row">{/* <div className="line"></div> */}</div>
        </div>
      </section>
    </div>
  );
}
