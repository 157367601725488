import { call } from "./apiCall";

class WalletAPI {
  sendRefer(data) {
    return new Promise((resolve, reject) => {
      const token = localStorage.getItem("accessToken");
      (async () => {
        try {
          const res = await call(
            "post",
            `api/v1/user/send-invitation`,
            null,
            data,
            token
          );
          resolve(res.data);
        } catch (err) {
          console.log(err);
          reject(err);
        }
      })();
    });
  }

  getRefer() {
    return new Promise((resolve, reject) => {
      const token = localStorage.getItem("accessToken");
      (async () => {
        try {
          const res = await call(
            "get",
            `api/v1/user/referral-info`,
            null,
            null,
            token
          );
          resolve(res.data);
        } catch (err) {
          console.log(err);
          reject(err);
        }
      })();
    });
  }

  getTransaction() {
    return new Promise((resolve, reject) => {
      const token = localStorage.getItem("accessToken");
      (async () => {
        try {
          const res = await call(
            "get",
            `api/v1/user/user-transaction`,
            null,
            null,
            token
          );
          resolve(res.data);
        } catch (err) {
          console.log(err);
          reject(err);
        }
      })();
    });
  }
}

export const walletAPI = new WalletAPI();
