import axios from "axios";
import React from "react";
import { useRef } from "react";
import { useState } from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useParams } from "react-router";
import DOMPurify from "dompurify";
import {
  deletePost,
  getPageDetails,
  getPostDetails,
  get_Report_type,
  hide_user_post,
  postViewLog,
  repost_post,
  savePost,
  savePostDelete,
} from "../../../../store/dashboard/dashboardActions";
import {
  addPageAdmin,
  addPageURL,
  DeletePageAdmin,
  getAllPages,
  getAllPagesInsights,
  getPageAdmins,
  getPagesPermission,
  getsinglePage,
  sendInvitationPage,
  updatePageData,
  followPage,
  unFollowPage,
  getPageProductList,
  getPageFollowers,
} from "../../../../store/pages/pagesAction";
import { reactionsData } from "../../../../store/reactionPost/reactionAction";
import PageAdminDetails from "./PageAdminDetails";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { getSuggestedPeopleList } from "../../../../store/people/peopleActions";
import { sendConnectionRequest } from "../../../../store/connections/connectionsActions";
import {
  getOthersEvents,
  pinnedEvents,
} from "../../../../store/events/eventActions";
import { getSuggestedArticles } from "../../../../store/articles/articleActions";
import { initLoad, stopLoad } from "../../../../store/loader/loaderActions";
import { getOthersNewsletters } from "../../../../store/newsletter/newsletterActions";
import moment from "moment";
import AppLoader from "../../../../core/components/Loaders/appLoader/appLoader";

const PageAdminDetailsContainer = (props) => {
  const { id } = useParams();
  const { state } = useLocation();
  const paramsURL = isNaN(id);
  const dispatch = useDispatch();
  const [createModal, setCreateModal] = useState(false);
  const [pageData, setPageData] = useState();
  const [pageID, setPageID] = useState();
  const [pagePostData, setPagePostData] = useState([]);
  const [isSeeMore, setIsSeeMore] = useState(true);
  const [invitemodal, setInvitemodal] = useState(false);
  const [pageAdminmodal, setPageAdminmodal] = useState(false);
  const [editPageData, setEditPageData] = useState("");
  const [croppedImage, setCroppedImage] = useState("");
  const [uploadImage, setUploadedImage] = useState("");
  const [bannerModal, setBannerModal] = useState(false);
  const [iconPhotoModal, setIconPhotoModal] = useState(false);
  const [exploreUrl, setExploreUrl] = useState("");
  const [isEdit, setIsEdit] = useState();
  const [isPageEdit, setIsPageEdit] = useState(false);
  const [pageOwner, setPageOwner] = useState("");
  const [isPagePosts, setISPagePosts] = useState(false);
  const [insightData, setInsightData] = useState();
  const [isPositive, setIsPositive] = useState();
  const [pageNavURL, setPageNavURL] = useState();
  //page post
  const [currentCount, setCurrentCount] = useState(10);
  const [dropDown, setDropDown] = useState(-1);
  const [isSaved, setSaved] = useState(-1);
  const dropRef = useRef(null);
  const [byUserId, setByUserId] = useState(0);
  const [isReaction, setIsReaction] = useState(-1);
  const [isComments, setIsComments] = useState(-1);
  const [isViewReaction, setViewReaction] = useState(false);
  const [postId, setPostID] = useState();
  const [createPostModal, setCreatePostModal] = useState(false);
  const [updateData, setUpdateData] = useState();
  const [createPostClick, setCreatePostClick] = useState("");
  const [isDataAvailable, setDataAvailable] = useState(false);
  const [draftDetails, setDraftDetails] = useState();
  const [reportModal, setReportModal] = useState(false);
  const [pageReportModal, setPageReportModal] = useState(false);
  const [dropDownShare, setDropDownShare] = useState(-1);
  const [commentBoxStatus, setCommentBoxStatus] = useState([]);
  const [createTalentModal, setCreateTalentModal] = useState(false);
  const [tpSuccessModal, setTpSuccessModal] = useState(false);
  const [talentPoolData, setTalentPoolData] = useState();
  const [roleShareModal, setRoleShareModal] = useState(false);
  const [rolePageId, setRolePageId] = useState(false);
  const [dropDownRepost, setDropDownRepost] = useState(-1);
  const [productModel, setProductModel] = useState(false);
  let token = window.localStorage.getItem("accessToken");

  const { getPageDetailData } = useSelector((state) => state.dashboard);
  console.log(getPageDetailData);

  //page permission
  const { pagePermission } = useSelector((state) => state.pages);
  const { productData } = useSelector((state) => state.pages);
  // page people
  const { pageFollowers } = useSelector((state) => state.pages);
  // const { suggested_people } = useSelector((state) => state.people);
  //page article
  const [articleModal, setArticleModal] = useState(false);
  const [updatedArticlesList, setUpdatedArticlesList] = useState([]);
  const [updatedJobsList, setUpdatedJobsList] = useState([]);
  const { page_articles } = useSelector((state) => state.article);
  const { page_jobs } = useSelector((state) => state.jobs);
  // page event
  const [eventModal, setEventModal] = useState(false);
  const { page_events } = useSelector((state) => state.events);
  // page newsletter
  const [newsletterModal, setNewsletterModal] = useState(false);
  const { page_newsletters } = useSelector((state) => state.newsletter);
  const toggleSeeMore = () => {
    setIsSeeMore(!isSeeMore);
  };
  const { user } = useSelector((state) => state.auth);
  const [loading, setLoading] = useState(false);
  const dropRefShare = useRef(null);
  const dropRefRepost = useRef(null);
  const [shareData, setShareData] = useState("");
  const [openShareModal, setOpenShareModal] = useState(false);
  const [selectedUserId, setSelectedUserId] = useState(true);
  const [manageFeedStatus, setManageFeedStatus] = useState(false);

  useEffect(() => {
    !paramsURL ? singlePageDetails() : singlePageDetailsbyURL();
    if (pageID) pagePostDetails();
    window.scrollTo(0, 0);
    getPagesPermsn(id);

    if (state?.newsletter) setNewsletterModal(true);
  }, [pageID]);

  useEffect(() => {
    if (pageData?.Created_by?.id === user?.data?.id) {
      setIsPageEdit(true);
    }
    setPageOwner(
      pageData?.Created_by.first_name + " " + pageData?.Created_by.last_name
    );
    if (pageData?.description?.length > 150) {
      setIsSeeMore(true);
    }
    if (pageData?.custom_button === true) {
      setExploreUrl(pageData.custom_button_url);
    }
  }, [pageData]);

  useEffect(() => {
    document.addEventListener("mousedown", clickOutsideAdmin);
    return () => {
      document.removeEventListener("mousedown", clickOutsideAdmin);
    };
  }, [dropDown, dropDownShare, dropDownRepost]);

  const getPagesPermsn = () => {
    dispatch(getPagesPermission(id));
  };

  const postViewCounter = (id) => {
    const payload = {
      view_type: "post",
      post_id: id,
    };
    dispatch(postViewLog(payload));
  };

  const clickOutsideAdmin = (e) => {
    if (e.toElement && e?.toElement.className === "shareListing") {
      return;
    }
    if (e.toElement && e?.toElement.className === "listing") {
      return;
    }
    if (e.toElement && e?.toElement.className === "clickDots") {
      return;
    }
    if (dropRef?.current?.contains(e.target) && dropDown > -1) {
      return;
    }
    if (dropRefRepost?.current?.contains(e.target) && dropDownRepost > -1) {
      return;
    }
    setDropDown(-1);
    setDropDownShare(-1);
    setDropDownRepost(-1);
  };

  useEffect(() => {
    setUpdatedArticlesList(page_articles);
  }, [page_articles]);

  useEffect(() => {
    setUpdatedJobsList(page_jobs);
  }, [page_jobs]);
  const getPageArticles = () => {
    let query = "page";
    dispatch(getSuggestedArticles(token, query, { page_id: parseInt(pageID) }));
  };
  const getPageEvents = () => {
    let query = "page";
    dispatch(getOthersEvents(query, { page_id: parseInt(pageID) }));
  };
  const getPageNewsletters = () => {
    let query = "page";
    dispatch(getOthersNewsletters(query, { page_id: parseInt(pageID) }));
  };

  const [productCount, setProductCount] = useState(0);

  const getProductList = () => {
    dispatch(getPageProductList(parseInt(pageID)));
  };

  function createMarkup(html) {
    return {
      __html: DOMPurify.sanitize(html),
    };
  }

  const pinHandle = (id, type) => {
    dispatch(pinnedEvents(id, token, type));
  };

  const singlePageDetails = () => {
    setLoading(true);
    dispatch(getsinglePage({ id: id })).then((res) => {
      setLoading(false);
      setPageData(res.data[0]);
      setPageNavURL(res.data[0].page_url);
      setPageID(res.data[0].id);
    });
  };
  const singlePageDetailsbyURL = () => {
    setLoading(true);
    dispatch(getsinglePage({ page_url: id })).then((res) => {
      console.log(res.data[0]?.id);
      setLoading(false);
      setPageData(res.data[0]);
      setPageNavURL(res.data[0].page_url);
      setPageID(res.data[0].id);
    });
  };

  const sendPageInvitation = (data) => {
    dispatch(sendInvitationPage(data)).then((res) => {
      setInvitemodal(false);
      toast.success("Page Invitation sent successfully!", {
        position: toast.POSITION.BOTTOM_LEFT,
      });
    });
  };

  const addPageAdminRole = (data) => {
    dispatch(addPageAdmin(data)).then((res) => {
      if (res.success === false) alert(res.message);
    });
  };

  const EditPageURL = (data) => {
    dispatch(addPageURL(data)).then((res) => {
      toast.success(res.message, {
        position: toast.POSITION.BOTTOM_LEFT,
      });
    });
  };

  const getPageAdminDetails = () => {
    dispatch(getPageAdmins(pageID));
  };
  const getPageInsights = (type) => {
    dispatch(getAllPagesInsights({ page_id: id, type: type })).then((res) => {
      setInsightData(res.data);
    });
  };
  const deletePageAdminDetail = (data) => {
    dispatch(DeletePageAdmin(data));
  };
  const suggestedPeoplePage = () => {
    dispatch(getPageFollowers(id));
  };
  const sendRequest = (id) => {
    dispatch(sendConnectionRequest(id));
  };

  const pagePostDetails = (filter = null) => {
    // setCurrentCount(currentCount + 10);
    // dispatch(
    //   getPostDetails(token, {
    //     shared_by: id,
    //     shared_by_type: "page",
    //     // skip: currentCount,
    //     limit: 10,
    //   })
    // ).then((res) => {
    //   console.log("res", res);
    //   setPagePostData(res.data);
    // });
    dispatch(initLoad());
    dispatch(
      getPostDetails(token, {
        shared_by: pageID,
        shared_by_type: "page",
        filter: filter || null,
      })
    ).then((res) => {
      dispatch(stopLoad());
      setPagePostData(res.data);
      setISPagePosts(true);
    });
  };
  useEffect(() => {
    if (pageData?.background_img) {
      try {
        dispatch(
          updatePageData(
            { background_img: `${pageData?.background_img}` },
            pageID
          )
        );
      } catch (error) {
        console.log("error", error);
      }
    }
  }, [pageData?.background_img]);

  useEffect(() => {
    if (croppedImage) {
      try {
        dispatch(updatePageData({ icon: `${croppedImage}` }, pageID));
      } catch (error) {
        console.log("error", error);
      }
    }
  }, [croppedImage]);

  const uploadIcon = async (e, type) => {
    if (e.target?.files?.length || e.dataTransfer?.files?.length) {
      try {
        const UPLOAD_URL = `${process.env.REACT_APP_API_URL}api/v1/upload`;
        const formData = new FormData();
        const headers = {
          "Accept-Language": "en",
          "content-type": "multipart/form-data",
        };
        formData.append(
          "image",
          e.target?.files[0] || e.dataTransfer?.files[0]
        );
        await axios.post(UPLOAD_URL, formData, { headers }).then((res) => {
          if (type === "backgroundImg") {
            pageData.background_img = res.data.urlsArray[0];
            setPageData({ ...pageData });
          } else {
            setCroppedImage(res.data.urlsArray[0]);
            pageData.icon = res.data.urlsArray[0];
            setPageData({ ...pageData });
          }
        });
      } catch (err) {
        console.log(err);
      }
    } else {
      alert("PLease choose file");
    }
  };

  const handleBannerPicture = async (file, type) => {
    const newImage = new File([file], "newGroupCropped", { type: "image/png" });
    try {
      const UPLOAD_URL = `${process.env.REACT_APP_API_URL}api/v1/upload`;
      const formData = new FormData();
      const headers = {
        "Accept-Language": "en",
        "content-type": "multipart/form-data",
      };

      formData.append("image", newImage);

      await axios.post(UPLOAD_URL, formData, { headers }).then((res) => {
        if (type === "backgroundImg") {
          // setCroppedImage(res.data.urlsArray[0])
          pageData.background_img = res.data.urlsArray[0];
          setPageData({ ...pageData });
        } else {
          setCroppedImage(res.data.urlsArray[0]);
        }
      });
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    dispatch(getAllPages({ filter: "my" }));
    dispatch(getPageDetails(token));
  }, []);

  const handleRemovePicture = (type) => {
    if (type === "backgroundImg") {
      pageData.background_img = "";
      setPageData({ ...pageData });
      dispatch(
        updatePageData(
          { background_img: `${pageData?.background_img}` },
          pageID
        )
      );
    } else {
      pageData.icon = "";
      setPageData({ ...pageData });
      setCroppedImage("");

      try {
        dispatch(updatePageData({ icon: `${pageData?.icon}` }, pageID));
      } catch (error) {
        console.log("error", error);
      }
    }
  };

  const onChangePicture = (e) => {
    if (e.target.files[0]) {
      setUploadedImage(URL.createObjectURL(e.target.files[0]));
      setBannerModal(true);
    }
  };

  const createExploreLink = () => {
    window.open(exploreUrl, "exploremore");
  };

  // page post methods
  const editPost = (postData) => {
    if (postData.Celebration) {
      //alert("Cannot edit celebration post and feed docs ,docs");
      toast.error("Cannot edit celebration post and feed docs ,docs", {
        position: toast.POSITION.BOTTOM_LEFT,
      });
    } else {
      setIsEdit(postData.id);
      setCreatePostModal(!createPostModal);
      setUpdateData(postData);
      setDropDown(-1);
    }
  };

  function privacy_type(privacy_id) {
    if (privacy_id === 1) return " Public";
    if (privacy_id === 2) return " Connection";
    return " Group";
  }

  const delete_post = (_id) => {
    dispatch(deletePost(_id, token));
    setDropDown(-1);
  };
  const save_post = (id, i) => {
    dispatch(savePost(id, token)).then((response) => {
      toast.success("Post Saved", {
        position: toast.POSITION.BOTTOM_LEFT,
      });
    });
  };
  const removeSavePost = (id) => {
    dispatch(savePostDelete(id, token)).then((response) => {
      toast.success("Post Removed", {
        position: toast.POSITION.BOTTOM_LEFT,
      });
    });
  };

  const handleLikes = (item, index) => {
    if (isReaction === index) {
      setIsReaction(-1);
    } else {
      if (item?.reaction?.byUser?.type) {
        handleReaction(item, item?.reaction?.byUser?.type, "deleteReaction");
      } else {
        setIsReaction(index);
      }
    }
  };

  // function handleReaction(user, reacted, type) {
  //   let data = {
  //     post_id: user.id,
  //     type: reacted,
  //     id: byUserId !== 0 ? byUserId : user?.reaction?.byUser?.id,
  //   };
  //   dispatch(reactionsData(data, type)).then((res) => {
  //     console.log("lklklk",res);
  //     setByUserId(res.data[0].id);
  //   });
  //   setIsReaction(!isReaction);
  // }

  function handleReaction(item, reacted, type) {
    let data = {
      post_id: item.id,
      type: reacted,
      // id: byUserId !== 0 ? byUserId : item?.reaction?.byUser?.id,
    };
    if (item?.as_a_page) {
      Object.assign(data, { page_id: item?.as_a_page?.id });
    } else if (isPagePosts && selectedUserId) {
      Object.assign(data, { page_id: item?.Page?.id });
    }
    dispatch(reactionsData(data, type)).then((res) => {
      setByUserId(res.data[0]?.id);
    });
    // setIsReaction(!isReaction);
    // dispatch({type: LIKED_DASHBOARD_POST, payload: data});
  }

  const hideUserPost = (id) => {
    dispatch(hide_user_post(id)).then((reponse) => {
      toast.success("Post Hide Sucessfully!", {
        position: toast.POSITION.BOTTOM_LEFT,
      });
    });
    setDropDown(-1);
  };

  const repostPost = (id) => {
    let repost_data = {
      type: "repost",
      shared_id: id,
      privacy_id: 1,
      shared_by_type: isPageEdit || manageFeedStatus ? "page" : "user",
      shared_by: isPageEdit || manageFeedStatus ? pageID : user?.data?.id,
    };
    dispatch(repost_post(repost_data)).then((res) => {
      setDropDownShare(-1);
      toast.success("Reposted successfully!", {
        position: toast.POSITION.BOTTOM_LEFT,
      });
    });
  };

  const likeResult = (arr1, arr2) => {
    if (arr1 === undefined || arr1 === null) {
      let array3 = arr2;
      return array3;
    } else {
      let arr4 = [{ type: arr1 }];
      let array3 = arr4?.concat(
        arr2?.filter((bo) =>
          arr4?.every((ao) => ao?.type !== bo?.type && bo.page_id !== null)
        )
      );
      return array3;
    }
  };

  const closeAllModal = () => {
    setCreatePostModal(false);
    // toggle();
  };

  const handleFollow = (pageID, is_following) => {
    if (is_following) {
      dispatch(unFollowPage(pageID)).then((res) => {
        toast.success("Page unfollowed successfuly!", {
          position: toast.POSITION.BOTTOM_LEFT,
        });
        setPageData({ ...pageData, is_following: false });
      });
    } else {
      dispatch(followPage(pageID)).then((res) => {
        toast.success("Page followed successfuly!", {
          position: toast.POSITION.BOTTOM_LEFT,
        });
        setPageData({ ...pageData, is_following: true });
      });
    }
  };

  console.log(productData);

  if (loading) return <AppLoader />;
  else
    return (
      <PageAdminDetails
        pageAdminData={pageData}
        handleFollow={handleFollow}
        singlePageDetails={singlePageDetails}
        pagePostDetails={pagePostDetails}
        pagePostData={pagePostData}
        isSeeMore={isSeeMore}
        toggleSeeMore={toggleSeeMore}
        invitemodal={invitemodal}
        setInvitemodal={setInvitemodal}
        pageAdminmodal={pageAdminmodal}
        setPageAdminmodal={setPageAdminmodal}
        getPageInsights={getPageInsights}
        insightData={insightData}
        isEdit={isEdit}
        setIsEdit={setIsEdit}
        setIsPageEdit={setIsPageEdit}
        isPageEdit={isPageEdit}
        editPageData={editPageData}
        setEditPageData={setEditPageData}
        createModal={createModal}
        setCreateModal={setCreateModal}
        uploadIcon={uploadIcon}
        handleBannerPicture={handleBannerPicture}
        handleRemovePicture={handleRemovePicture}
        onChangePicture={onChangePicture}
        croppedImage={croppedImage}
        setCroppedImage={setCroppedImage}
        iconPhotoModal={iconPhotoModal}
        setIconPhotoModal={setIconPhotoModal}
        uploadImage={uploadImage}
        setBannerModal={setBannerModal}
        bannerModal={bannerModal}
        createExploreLink={createExploreLink}
        pageID={pageID}
        sendPageInvitation={sendPageInvitation}
        addPageAdminRole={addPageAdminRole}
        getPageAdminDetails={getPageAdminDetails}
        deletePageAdminDetail={deletePageAdminDetail}
        EditPageURL={EditPageURL}
        currentCount={currentCount}
        isPositive={isPositive}
        setIsPositive={setIsPositive}
        setPageNavURL={setPageNavURL}
        pageNavURL={pageNavURL}
        singlePageDetailsbyURL={singlePageDetailsbyURL}
        // page people
        suggested_people={pageFollowers}
        suggestedPeoplePage={suggestedPeoplePage}
        sendRequest={sendRequest}
        // page article
        updatedArticlesList={updatedArticlesList}
        updatedJobsList={updatedJobsList}
        page_articles={page_articles}
        page_jobs={page_jobs}
        createMarkup={createMarkup}
        getPageArticles={getPageArticles}
        //page event
        page_events={page_events}
        pinHandle={pinHandle}
        getPageEvents={getPageEvents}
        // page Newsletters
        getPageNewsletters={getPageNewsletters}
        page_newsletters={page_newsletters}
        // page post vaiables
        dropDown={dropDown}
        setDropDown={setDropDown}
        editPost={editPost}
        delete_post={delete_post}
        isSaved={isSaved}
        setSaved={setSaved}
        dropRef={dropRef}
        privacy_type={privacy_type}
        save_post={save_post}
        removeSavePost={removeSavePost}
        handleLikes={handleLikes}
        handleReaction={handleReaction}
        isComments={isComments}
        setIsComments={setIsComments}
        isReaction={isReaction}
        isViewReaction={isViewReaction}
        setViewReaction={setViewReaction}
        postId={postId}
        setPostID={setPostID}
        pageOwner={pageOwner}
        isPagePosts={isPagePosts}
        createPostModal={createPostModal}
        setCreatePostModal={setCreatePostModal}
        updateData={updateData}
        setUpdateData={setUpdateData}
        createPostClick={createPostClick}
        setCreatePostClick={setCreatePostClick}
        closeAllModal={closeAllModal}
        isDataAvailable={isDataAvailable}
        setDataAvailable={setDataAvailable}
        draftDetails={draftDetails}
        setDraftDetails={setDraftDetails}
        setReportModal={setReportModal}
        reportModal={reportModal}
        pageReportModal={pageReportModal}
        setPageReportModal={setPageReportModal}
        dropDownRepost={dropDownRepost}
        setDropDownRepost={setDropDownRepost}
        dropRefRepost={dropRefRepost}
        // suggested_people={suggested_people}
        // sendRequest={sendRequest}
        hideUserPost={hideUserPost}
        commentBoxStatus={commentBoxStatus}
        setCommentBoxStatus={setCommentBoxStatus}
        dropDownShare={dropDownShare}
        setDropDownShare={setDropDownShare}
        repostPost={repostPost}
        likeResult={likeResult}
        selectedUserId={selectedUserId}
        setSelectedUserId={setSelectedUserId}
        // other modals
        articleModal={articleModal}
        setArticleModal={setArticleModal}
        eventModal={eventModal}
        setEventModal={setEventModal}
        newsletterModal={newsletterModal}
        setNewsletterModal={setNewsletterModal}
        postViewCounter={postViewCounter}
        user={user}
        openShareModal={openShareModal}
        setOpenShareModal={setOpenShareModal}
        shareData={shareData}
        setShareData={setShareData}
        pagePermission={pagePermission}
        manageFeedStatus={manageFeedStatus}
        setManageFeedStatus={setManageFeedStatus}
        // talent pool
        createTalentModal={createTalentModal}
        setCreateTalentModal={setCreateTalentModal}
        tpSuccessModal={tpSuccessModal}
        setTpSuccessModal={setTpSuccessModal}
        talentPoolData={talentPoolData}
        setTalentPoolData={setTalentPoolData}
        roleShareModal={roleShareModal}
        setRoleShareModal={setRoleShareModal}
        getPageDetailData={getPageDetailData}
        rolePageId={rolePageId}
        setRolePageId={setRolePageId}
        productModel={productModel}
        setProductModel={setProductModel}
        getProductList={getProductList}
        productCount={productCount}
        setProductCount={setProductCount}
        productData={productData}
      />
    );
};

export default PageAdminDetailsContainer;
