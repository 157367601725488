import { call } from "./apiCall"


class AllUsersApi {
 getAllUserDetails(data) {
    return new Promise( (resolve, reject) => {
        const token = localStorage.getItem("accessToken");
          (async () => {
            try {
                const res = await call(
                    "get",
                    `api/v1/user/users-list`,
                    data,
                    null,
                    token
                )
                if(res.data.success===true){
                    resolve(res.data)
              }
              if(res.data.success===false){
                resolve(res.data)
              }

            } catch (err) {
                console.log(err)
                reject(err)
            }
        })()
    })
}
}

 export const allUsersApi = new AllUsersApi()