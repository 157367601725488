import { Navigate, useLocation, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getTalentPoolDetails, getTalentPoolDetailsByToken } from "../store/portal/portalAction";
import { useEffect } from "react";

const TPPrivateRoute = ({ children }) => {
  const location = useLocation();
  const { isLoggedIn ,talentPoolDataByToken, talentPoolData} = useSelector((state) => state.portal);
  const { param } = useParams();
  const token = localStorage.getItem("accessTpToken");
  // const poratlId = localStorage.getItem("poratlId");
   const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getTalentPoolDetailsByToken());
    if(param){
      dispatch(getTalentPoolDetails(param));
    }
  }, []);
  if (!token || talentPoolData?.id!=talentPoolDataByToken?.id) {
    return (
      <Navigate to={`/tp/portal/${param}/login`} state={{ from: location }} />
    );
  }
  return children;
};

export default TPPrivateRoute;
