import moment from "moment";
import React, { useEffect, useState } from "react";
import TimePicker from "rc-time-picker";
import "rc-time-picker/assets/index.css";
import DatePickerCustom from "../../../../core/components/DatePicker/DatePickerReact";

const ScheduleCamp = (props) => {
  const {
    scheduleDates,
    selectedScheduleDate,
    setIsTimeError,
    scheduleDate,
    setScheduleDate,
    scheduleTime,
    setScheduleTime,
    today,
    scheduleCheck,
    campaignFeatureDetail,
    setCampaignFeatureDetail,
    isEdit,
    campaign_status,
  } = props;

  const [timeError, setTimeError] = useState("");
  var d = new Date();
  const defaultMoment = moment().set({
    hour: d.getHours(),
    minute: d.getMinutes(),
    second: 0, // Assuming you want seconds to be zero in the default time
  });

  const todaysDate = new Date();
  const formattedTodaysDate = moment(todaysDate).format("YYYY-MM-DD");

  const handleDateChange = (event) => {
    setScheduleDate(event.target.value);
    // const formattedTimess = moment(defaultMoment._d).format("HH:mm");
    setScheduleTime(defaultMoment);

    setCampaignFeatureDetail({
      ...campaignFeatureDetail,
    });
  };

  const handleTime = (time) => {
    if (!time) {
      return;
    }
    var today = new Date();
    const formattedTimes = moment(time._d).format("HH:mm");

    const momentObject = moment(selectedScheduleDate, "YYYY-MM-DD");

    let currentTimeFormatted = moment(today);
    let formattedTime = moment(moment().format("L") + " " + formattedTimes);

    if (
      !momentObject.isSameOrAfter(currentTimeFormatted) &&
      formattedTime.diff(currentTimeFormatted) <= 0
    ) {
      setTimeError("Please select a time after the current time");
      setIsTimeError(true);
      setScheduleTime("");
    } else {
      setTimeError("");
      setIsTimeError(false);
      setScheduleTime(time);
    }
  };

  useEffect(() => {
    if (!isEdit) setScheduleTime(defaultMoment);
    setTimeError("");
    setIsTimeError(false);
  }, [selectedScheduleDate]);

  return (
    <div>
      <form>
        <div className="my-3">
          <div className="row">
            <div className="col-6">
              <div
                className={
                  scheduleCheck
                    ? "input-group"
                    : "input-group padding-left-case"
                }
              >
                <input
                  type="date"
                  className="form-control"
                  min={formattedTodaysDate}
                  value={selectedScheduleDate}
                  onChange={(event) => handleDateChange(event)}
                  disabled={!scheduleCheck}
                  //defaultValue={formattedTodaysDate}
                />
              </div>
            </div>
            <div className="col-4">
              <div
                id="featureTimepicker"
                className={
                  scheduleCheck
                    ? "input-group"
                    : "input-group padding-left-case"
                }
                style={{ width: "150px" }}
              >
                <TimePicker
                  showSecond={false}
                  value={scheduleTime}
                  defaultValue={defaultMoment}
                  className="scheduleFeatureCustomTimePicker"
                  onChange={(selectedTime) => {
                    handleTime(selectedTime);
                    //setTimeErrorCheck(false);
                  }}
                  use12Hours
                  placeholder="Select Time"
                  disabled={!scheduleCheck}
                />
              </div>
              {timeError && (
                <p style={{ color: "rgb(4, 50, 163)", fontSize: "10px" }}>
                  {" "}
                  {timeError}
                </p>
              )}
            </div>
          </div>
        </div>
      </form>
      <p className="mt-3">
        This campaign Ends on{" "}
        <span className="dark-blue-color">
          {
            // campaignFeatureDetail.end_date
            //   ? moment(campaignFeatureDetail.end_date).format("MMM DD, YYYY")
            //   :
            moment(selectedScheduleDate).add(30, "d").format("MMM DD, YYYY")
          }
        </span>{" "}
        at{" "}
        <span className="dark-blue-color">
          {moment(scheduleTime, "HH:mm:ss").format("hh:mm A")}
        </span>
      </p>
      {/* )} */}
    </div>
  );
};

export default ScheduleCamp;
