import {GET_ALL_ACTIVITY, GET_ACTIVITY_MEDIA, GET_ALL_POSTS, GET_ALL_ARTICLES} from "../types";

export const initialState = {
 allActivity:[],
 allMedia:[],
 allPosts:[],
 allArticles:[]
};

const activity = (state = initialState, action) => {
 switch (action.type) {
  
  case GET_ALL_ACTIVITY:
   return { ...state, allActivity: [...state.allActivity, ...action.payload.data] };

   case GET_ALL_POSTS:
    return { ...state, allPosts: [...state.allPosts, ...action.payload.data] };

    case GET_ALL_ARTICLES:
    return { ...state, allArticles: [...state.allArticles, ...action.payload.data]};

   case GET_ACTIVITY_MEDIA:
    return { ...state, allMedia: action.payload };
 
   default:
   return state;
 }
};

export default activity;