import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import backIcon from "../../../assets/img/Back_Icons.svg";
import moment from "moment";
import { Input } from "reactstrap";
import { Link, useLocation, useNavigate } from "react-router-dom";
import CustomModel from "./CustomModal";
import Navbar from "../../../pages/navbar/navbar";
const OrderPreview = () => {
  const navigate = useNavigate();
  const { state } = useLocation();
  const { authorization } = useSelector((state) => state.backgroundScreen);
  const [model, setmodel] = useState(false);
  useEffect(() => {
    if (!state) navigate("/background-service");
  }, []);

  const [confirm, setConfirm] = useState(true);
  const [error, setError] = useState(false);

  console.log(state);

  const downloadPdf = (pdfUrl) => {
    // Create a temporary anchor element
    const link = document.createElement("a");
    link.href = pdfUrl;
    link.setAttribute("download", "document.pdf");
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <>
      <Navbar isJobs={false} />
      <div className="px-5 py-3 bg-white">
        <div className="container">
          <div className="d-flex ">
            <img
              src={backIcon}
              width={25}
              onClick={() => navigate("/background-create-order")}
            />
            <h4 className="mx-3">Order Preview</h4>
          </div>
          <div className="mt-4 border">
            <div
              className="p-3 border-bottom"
              style={{ background: "#F8F8FA" }}
            >
              <h6 className="text-dark">Select your Package</h6>
            </div>
            <div className="mt-2 p-3">
              <div className="row">
                <div className="col-3 d-flex justify-content-between align-items-baseline">
                  <p className="text-dark">Selected Packages</p>
                  <p className="text-dark">:</p>
                </div>
                <div className="col-9">
                  {state?.data?.employment?.map((ele) => {
                    return (
                      <>
                        <div className="d-flex justify-content-between align-items-end mt-2">
                          <div className="d-flex">
                            <p className="text-dark fw-bold">Employment</p>
                            <span className="mx-1">({ele?.title})</span>
                          </div>
                          <p className="text-dark fw-bold">$59</p>
                        </div>
                      </>
                    );
                  })}
                  {state?.data?.education?.map((ele) => {
                    return (
                      <>
                        <div className="d-flex justify-content-between align-items-end mt-2">
                          <div className="d-flex ">
                            <p className="text-dark fw-bold">Education</p>
                            <span className="mx-1">({ele?.school_name})</span>
                          </div>
                          <p className="text-dark fw-bold">$59</p>
                        </div>
                      </>
                    );
                  })}
                  {state && (
                    <>
                      {Object.keys(state?.data?.criminal || {}).length > 0 && (
                        <div className="d-flex justify-content-between align-items-end mt-2">
                          <div className="d-flex">
                            <p className="text-dark fw-bold">
                              Criminal/ WorldScan
                            </p>
                          </div>
                          <p className="text-dark fw-bold">$59</p>
                        </div>
                      )}
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>

          <div className="mt-4 border">
            <div
              className="p-3  border-bottom"
              style={{ background: "#F8F8FA" }}
            >
              <h6 className="text-dark">Basic information</h6>
            </div>
            <div className="mt-2 p-3">
              <div className="row">
                <div className="col-6">
                  <div className="d-flex justify-content-between align-items-baseline">
                    <div className="d-flex justify-content-between align-items-baseline w-50">
                      <p className="text-dark">First Name</p>
                      <p className="text-dark">:</p>
                    </div>
                    <div className="w-50">
                      <p
                        className="mx-1 text-dark"
                        style={{ wordBreak: "break-all" }}
                      >
                        {state?.data?.basicInfo?.info?.first_name || "-"}
                      </p>
                    </div>
                  </div>
                  <div className="d-flex justify-content-between align-items-baseline">
                    <div className="d-flex justify-content-between align-items-baseline w-50">
                      <p className="text-dark">Middle Name</p>
                      <p className="text-dark">:</p>
                    </div>
                    <div className="w-50">
                      <p
                        className="mx-1 text-dark"
                        style={{ wordBreak: "break-all" }}
                      >
                        {state?.data?.basicInfo?.info?.middle_name || "-"}
                      </p>
                    </div>
                  </div>
                  <div className="d-flex justify-content-between align-items-baseline">
                    <div className="d-flex justify-content-between align-items-baseline w-50">
                      <p className="text-dark">Last Name</p>
                      <p className="text-dark">:</p>
                    </div>
                    <div className="w-50">
                      <p
                        className="mx-1 text-dark"
                        style={{ wordBreak: "break-all" }}
                      >
                        {state?.data?.basicInfo?.info?.last_name || "-"}
                      </p>
                    </div>
                  </div>
                  {/* <div className="d-flex justify-content-between align-items-baseline">
                  <div className="d-flex justify-content-between align-items-baseline">
                    <p className="text-dark">Name Type</p>
                    <p className="text-dark">:</p>
                  </div>
                  <div className="">
                    <p className="text-dark">{state?.data?.basicInfo?.info?.name_type || "-"}</p>
                  </div>
                </div> */}
                  <div className="d-flex justify-content-between align-items-baseline">
                    <div className="d-flex justify-content-between align-items-baseline w-50">
                      <p className="text-dark">
                        SSN
                        <span style={{ fontSize: "10px" }}>
                          (NON-USA Applicants, enter 000-00-0000*)
                        </span>
                      </p>
                      <p className="text-dark">:</p>
                    </div>
                    <div className="w-50">
                      <p className="mx-1 text-dark">
                        {state?.data?.basicInfo?.ssn || "-"}
                      </p>
                    </div>
                  </div>

                  <div className="d-flex justify-content-between align-items-baseline">
                    <div className="d-flex justify-content-between align-items-baseline w-50">
                      <p className="text-dark">Date of Birth</p>
                      <p className="text-dark">:</p>
                    </div>
                    <div className="w-50">
                      <p className="mx-1 text-dark">
                        {moment(state?.data?.basicInfo?.dob).format(
                          "DD/MM/YYYY"
                        ) || "-"}
                      </p>
                    </div>
                  </div>

                  <div className="d-flex justify-content-between align-items-baseline">
                    <div className="d-flex justify-content-between align-items-baseline w-50">
                      <p className="text-dark">Nationality</p>
                      <p className="text-dark">:</p>
                    </div>
                    <div className="w-50">
                      <p className="mx-1 text-dark">
                        {state?.data?.basicInfo?.nationality || "-"}
                      </p>
                    </div>
                  </div>

                  <div className="d-flex justify-content-between align-items-baseline">
                    <div className="d-flex justify-content-between align-items-baseline w-50">
                      <p className="text-dark">Country of Residence</p>
                      <p className="text-dark">:</p>
                    </div>
                    <div className="w-50">
                      <p className="mx-1 text-dark">
                        {state?.data?.basicInfo?.residence || "-"}
                      </p>
                    </div>
                  </div>

                  <div className="d-flex justify-content-between align-items-baseline">
                    <div className="d-flex justify-content-between align-items-baseline w-50">
                      <p className="text-dark">City</p>
                      <p className="text-dark">:</p>
                    </div>
                    <div className="w-50">
                      <p className="mx-1 text-dark">
                        {state?.data?.basicInfo?.city || "-"}
                      </p>
                    </div>
                  </div>

                  <div className="d-flex justify-content-between align-items-baseline">
                    <div className="d-flex justify-content-between align-items-baseline w-50">
                      <p className="text-dark">
                        Address
                        <span style={{ fontSize: "10px" }}>
                          (NON-USA Applicants, type your FULL ADDRESS with
                          CITY,.. )
                        </span>
                      </p>
                      <p className="text-dark">:</p>
                    </div>
                    <div className="w-50">
                      <p
                        className="mx-1 text-dark"
                        style={{ wordBreak: "break-all" }}
                      >
                        {state?.data?.basicInfo?.address || "-"}
                      </p>
                    </div>
                  </div>

                  <div className="d-flex justify-content-between align-items-baseline">
                    <div className="d-flex justify-content-between align-items-baseline w-50">
                      <p className="text-dark">Applicant Phone</p>
                      <p className="text-dark">:</p>
                    </div>
                    <div className="w-50">
                      <p className="mx-1 text-dark">
                        {state?.data?.basicInfo?.applicant_phone || "-"}
                      </p>
                    </div>
                  </div>
                </div>

                <div className="col-6">
                  <div className="d-flex justify-content-between align-items-baseline">
                    <div className="d-flex justify-content-between align-items-baseline w-50">
                      <p className="text-dark">First Name 1</p>
                      <p className="text-dark">:</p>
                    </div>
                    <div className="w-50">
                      <p
                        className="mx-1 text-dark"
                        style={{ wordBreak: "break-all" }}
                      >
                        {state?.data?.basicInfo?.otherInfo?.first_name || "-"}
                      </p>
                    </div>
                  </div>
                  <div className="d-flex justify-content-between align-items-baseline">
                    <div className="d-flex justify-content-between align-items-baseline w-50">
                      <p className="text-dark">Middle Name 1</p>
                      <p className="text-dark">:</p>
                    </div>
                    <div className="w-50">
                      <p
                        className="mx-1 text-dark"
                        style={{ wordBreak: "break-all" }}
                      >
                        {state?.data?.basicInfo?.otherInfo?.middle_name || "-"}
                      </p>
                    </div>
                  </div>
                  <div className="d-flex justify-content-between align-items-baseline">
                    <div className="d-flex justify-content-between align-items-baseline w-50">
                      <p className="text-dark">Last Name 1</p>
                      <p className="text-dark">:</p>
                    </div>
                    <div className="w-50">
                      <p
                        className="mx-1 text-dark"
                        style={{ wordBreak: "break-all" }}
                      >
                        {state?.data?.basicInfo?.otherInfo?.last_name || "-"}
                      </p>
                    </div>
                  </div>
                  {/* <div className="d-flex justify-content-between align-items-baseline">
                  <div className="d-flex justify-content-between align-items-baseline">
                    <p className="text-dark">Name Type</p>
                    <p className="text-dark">:</p>
                  </div>
                  <div className="">
                    <p className="text-dark">{state?.data?.basicInfo?.info?.name_type || "-"}</p>
                  </div>
                </div> */}
                  <div className="d-flex justify-content-between align-items-baseline">
                    <div className="d-flex justify-content-between align-items-baseline w-50">
                      <p className="text-dark">Gender</p>
                      <p className="text-dark">:</p>
                    </div>
                    <div className="w-50">
                      <p className="mx-1 text-dark">
                        {state?.data?.basicInfo?.gender || "-"}
                      </p>
                    </div>
                  </div>

                  <div className="d-flex justify-content-between align-items-baseline">
                    <div className="d-flex justify-content-between align-items-baseline w-50">
                      <p className="text-dark">Country of Birth</p>
                      <p className="text-dark">:</p>
                    </div>
                    <div className="w-50">
                      <p className="mx-1 text-dark">
                        {state?.data?.basicInfo?.country || "-"}
                      </p>
                    </div>
                  </div>

                  <div className="d-flex justify-content-between align-items-baseline">
                    <div className="d-flex justify-content-between align-items-baseline w-50">
                      <p className="text-dark">
                        Previous / Additional Nationality{" "}
                      </p>
                      <p className="text-dark">:</p>
                    </div>
                    <div className="w-50">
                      <p className="mx-1 text-dark">
                        {state?.data?.basicInfo?.additional_nationality || "-"}
                      </p>
                    </div>
                  </div>

                  <div className="d-flex justify-content-between align-items-baseline">
                    <div className="d-flex justify-content-between align-items-baseline w-50">
                      <p className="text-dark">State / Province</p>
                      <p className="text-dark">:</p>
                    </div>
                    <div className="w-50">
                      <p className="mx-1 text-dark">
                        {state?.data?.basicInfo?.state || "-"}
                      </p>
                    </div>
                  </div>

                  <div className="d-flex justify-content-between align-items-baseline">
                    <div className="d-flex justify-content-between align-items-baseline w-50">
                      <p className="text-dark">Zip/Postal Code</p>
                      <p className="text-dark">:</p>
                    </div>
                    <div className="w-50">
                      <p className="mx-1 text-dark">
                        {state?.data?.basicInfo?.zip || "-"}
                      </p>
                    </div>
                  </div>

                  <div className="d-flex justify-content-between align-items-baseline">
                    <div className="d-flex justify-content-between align-items-baseline w-50">
                      <p className="text-dark">Applicant Email</p>
                      <p className="text-dark">:</p>
                    </div>
                    <div className="w-50">
                      <p className="mx-1 text-dark">
                        {state?.data?.basicInfo?.applicant_email || "-"}
                      </p>
                    </div>
                  </div>
                </div>

                <div className="row">
                  {state?.data?.basicInfo?.document?.map((ele, index) => {
                    return (
                      <div
                        className="col-6 p-2 bg-white mt-2 mb-2 p-2"
                        key={index}
                      >
                        <div className="w-100 d-flex justify-content-between align-items-center">
                          <div className="w-100 d-flex justify-content-between align-items-center mx-2">
                            <div className="d-flex align-items-center">
                              <p
                                className="m-0 p-1"
                                style={{ background: "#BDE7FF" }}
                              >
                                {ele?.name?.split(".")?.pop()?.toUpperCase() ||
                                  "Pdf"}
                              </p>
                              <p className="m-0 mx-3">{ele?.name}</p>
                            </div>

                            <p
                              className="text-secondary"
                              style={{ fontSize: "12px", paddingBottom: 0 }}
                            >
                              {(ele?.size / 1024).toFixed(2) + " KB"}
                            </p>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          </div>

          {state?.data?.employment?.map((ele, i) => {
            return (
              <>
                <div className="mt-4 border">
                  <div
                    className="p-3 border-bottom"
                    style={{ background: "#F8F8FA" }}
                  >
                    <h6 className="text-dark">Employment [#{i + 1}]</h6>
                  </div>
                  <div className="mt-2 p-3">
                    <div className="row">
                      <div className="col-6">
                        <div className="d-flex justify-content-between align-items-baseline">
                          <div className="d-flex justify-content-between align-items-baseline w-50">
                            <p className="text-dark">Company Name</p>
                            <p className="text-dark">:</p>
                          </div>
                          <div className="w-50">
                            <p
                              className="mx-1 text-dark"
                              style={{ wordBreak: "break-all" }}
                            >
                              {ele?.company_name || "-"}
                            </p>
                          </div>
                        </div>
                        <div className="d-flex justify-content-between align-items-baseline">
                          <div className="d-flex justify-content-between align-items-baseline w-50">
                            <p className="text-dark">Name of Contact</p>
                            <p className="text-dark">:</p>
                          </div>
                          <div className="w-50">
                            <p
                              className="mx-1 text-dark"
                              style={{ wordBreak: "break-all" }}
                            >
                              {ele?.contact_name || "-"}
                            </p>
                          </div>
                        </div>
                        <div className="d-flex justify-content-between align-items-baseline">
                          <div className="d-flex justify-content-between align-items-baseline w-50">
                            <p className="text-dark">Contact Email</p>
                            <p className="text-dark">:</p>
                          </div>
                          <div className="w-50">
                            <p className="mx-1 text-dark">
                              {ele?.email || "-"}
                            </p>
                          </div>
                        </div>
                        {/* <div className="d-flex justify-content-between align-items-baseline">
                  <div className="d-flex justify-content-between align-items-baseline">
                    <p className="text-dark">Name Type</p>
                    <p className="text-dark">:</p>
                  </div>
                  <div className="">
                    <p className="text-dark">{state?.data?.basicInfo?.info?.name_type || "-"}</p>
                  </div>
                </div> */}
                        <div className="d-flex justify-content-between align-items-baseline">
                          <div className="d-flex justify-content-between align-items-baseline w-50">
                            <p className="text-dark">phone #</p>
                            <p className="text-dark">:</p>
                          </div>
                          <div className="w-50">
                            <p className="mx-1 text-dark">
                              {ele?.phone || "-"}
                            </p>
                          </div>
                        </div>

                        <div className="d-flex justify-content-between align-items-baseline">
                          <div className="d-flex justify-content-between align-items-baseline w-50">
                            <p className="text-dark">Company Web Address</p>
                            <p className="text-dark">:</p>
                          </div>
                          <div className="w-50">
                            <p
                              className="mx-1 text-dark"
                              style={{ wordBreak: "break-all" }}
                            >
                              {ele?.company_address || "-"}
                            </p>
                          </div>
                        </div>

                        <div className="d-flex justify-content-between align-items-baseline">
                          <div className="d-flex justify-content-between align-items-baseline w-50">
                            <p className="text-dark">
                              Your Title/ Position with Employer{" "}
                            </p>
                            <p className="text-dark">:</p>
                          </div>
                          <div className="w-50">
                            <p
                              className="mx-1 text-dark"
                              style={{ wordBreak: "break-all" }}
                            >
                              {ele?.position || "-"}
                            </p>
                          </div>
                        </div>

                        <div className="d-flex justify-content-between align-items-baseline">
                          <div className="d-flex justify-content-between align-items-baseline w-50">
                            <p className="text-dark">Start Date</p>
                            <p className="text-dark">:</p>
                          </div>
                          <div className="w-50">
                            <p className="mx-1 text-dark">
                              {ele?.start_date || "-"}
                            </p>
                          </div>
                        </div>

                        <div className="d-flex justify-content-between align-items-baseline">
                          <div className="d-flex justify-content-between align-items-baseline w-50">
                            <p className="text-dark">Comments</p>
                            <p className="text-dark">:</p>
                          </div>
                          <div className="w-50">
                            <p
                              className="mx-1 text-dark"
                              style={{ wordBreak: "break-all" }}
                            >
                              {ele?.comment || "-"}
                            </p>
                          </div>
                        </div>

                        <div className="d-flex justify-content-between align-items-baseline">
                          <div className="d-flex justify-content-between align-items-baseline w-50">
                            <p className="text-dark">Separation type</p>
                            <p className="text-dark">:</p>
                          </div>
                          <div className="w-50">
                            <p className="mx-1 text-dark">
                              {ele?.separation_type || "-"}
                            </p>
                          </div>
                        </div>
                      </div>

                      <div className="col-6">
                        <div className="d-flex justify-content-between align-items-baseline">
                          <div className="d-flex justify-content-between align-items-baseline w-50">
                            <p className="text-dark">Country</p>
                            <p className="text-dark">:</p>
                          </div>
                          <div className="w-50">
                            <p className="mx-1 text-dark">
                              {ele?.country || "-"}
                            </p>
                          </div>
                        </div>

                        <div className="d-flex justify-content-between align-items-baseline">
                          <div className="d-flex justify-content-between align-items-baseline w-50">
                            <p className="text-dark">State / Province</p>
                            <p className="text-dark">:</p>
                          </div>
                          <div className="w-50">
                            <p className="mx-1 text-dark">
                              {ele?.state || "-"}
                            </p>
                          </div>
                        </div>
                        <div className="d-flex justify-content-between align-items-baseline">
                          <div className="d-flex justify-content-between align-items-baseline w-50">
                            <p className="text-dark">City</p>
                            <p className="text-dark">:</p>
                          </div>
                          <div className="w-50">
                            <p className="mx-1 text-dark">{ele?.city || "-"}</p>
                          </div>
                        </div>
                        <div className="d-flex justify-content-between align-items-baseline">
                          <div className="d-flex justify-content-between align-items-baseline w-50">
                            <p className="text-dark">Zip/Postal Code</p>
                            <p className="text-dark">:</p>
                          </div>
                          <div className="w-50">
                            <p className="mx-1 text-dark">{ele?.zip || "-"}</p>
                          </div>
                        </div>

                        <div className="d-flex justify-content-between align-items-baseline">
                          <div className="d-flex justify-content-between align-items-baseline w-50">
                            <p className="text-dark">Current Employer?</p>
                            <p className="text-dark">:</p>
                          </div>
                          <div className="w-50">
                            <p className="mx-1 text-dark">
                              {ele?.is_current ? "Y" : "N"}
                            </p>
                          </div>
                        </div>
                        <div className="d-flex justify-content-between align-items-baseline">
                          <div className="d-flex justify-content-between align-items-baseline w-50">
                            <p className="text-dark">Employee ID</p>
                            <p className="text-dark">:</p>
                          </div>
                          <div className="w-50">
                            <p
                              className="mx-1 text-dark"
                              style={{ wordBreak: "break-all" }}
                            >
                              {ele?.employeeId || "-"}
                            </p>
                          </div>
                        </div>
                        <div className="d-flex justify-content-between align-items-baseline">
                          <div className="d-flex justify-content-between align-items-baseline w-50">
                            <p className="text-dark">End Date</p>
                            <p className="text-dark">:</p>
                          </div>
                          <div className="w-50">
                            <p className="mx-1 text-dark">
                              {ele?.end_date || "-"}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="row">
                      {ele?.document?.map((ele, index) => {
                        return (
                          <div
                            className="col-6 p-2 bg-white mt-2 mb-2 p-2"
                            key={index}
                          >
                            <div className="w-100 d-flex justify-content-between align-items-center">
                              <div className="w-100 d-flex justify-content-between align-items-center mx-2">
                                <div className="d-flex align-items-center">
                                  <p
                                    className="m-0 p-1"
                                    style={{ background: "#BDE7FF" }}
                                  >
                                    {ele?.name
                                      ?.split(".")
                                      ?.pop()
                                      ?.toUpperCase() || "Pdf"}
                                  </p>
                                  <p
                                    className="m-0 mx-3"
                                    style={{ cursor: "pointer" }}
                                    onClick={() =>
                                      window.open(ele?.url, "_blank")
                                    }
                                  >
                                    {ele?.name}
                                  </p>
                                </div>

                                <p
                                  className="text-secondary"
                                  style={{ fontSize: "12px", paddingBottom: 0 }}
                                >
                                  {(ele?.size / 1024).toFixed(2) + " KB"}
                                </p>
                              </div>
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                </div>
              </>
            );
          })}

          {state?.data?.education?.map((ele, i) => {
            return (
              <>
                <div className="mt-4 border">
                  <div
                    className="p-3 border-bottom"
                    style={{ background: "#F8F8FA" }}
                  >
                    <h6 className="text-dark">Education [#{i + 1}]</h6>
                  </div>
                  <div className="mt-2 p-3">
                    <div className="row">
                      <div className="col-6">
                        <div className="d-flex justify-content-between align-items-baseline">
                          <div className="d-flex justify-content-between align-items-baseline w-50">
                            <p className="text-dark">School Name</p>
                            <p className="text-dark">:</p>
                          </div>
                          <div className="w-50">
                            <p
                              className="mx-1 text-dark"
                              style={{ wordBreak: "break-all" }}
                            >
                              {ele?.school_name || "-"}
                            </p>
                          </div>
                        </div>

                        <div className="d-flex justify-content-between align-items-baseline">
                          <div className="d-flex justify-content-between align-items-baseline w-50">
                            <p className="text-dark">Contact Email</p>
                            <p className="text-dark">:</p>
                          </div>
                          <div className="w-50">
                            <p className="mx-1 text-dark">
                              {ele?.email || "-"}
                            </p>
                          </div>
                        </div>

                        <div className="d-flex justify-content-between align-items-baseline">
                          <div className="d-flex justify-content-between align-items-baseline w-50">
                            <p className="text-dark">phone</p>
                            <p className="text-dark">:</p>
                          </div>
                          <div className="w-50">
                            <p className="mx-1 text-dark">
                              {ele?.phone || "-"}
                            </p>
                          </div>
                        </div>

                        <div className="d-flex justify-content-between align-items-baseline">
                          <div className="d-flex justify-content-between align-items-baseline w-50">
                            <p className="text-dark">Web Address</p>
                            <p className="text-dark">:</p>
                          </div>
                          <div className="w-50">
                            <p
                              className="mx-1 text-dark"
                              style={{ wordBreak: "break-all" }}
                            >
                              {ele?.address || "-"}
                            </p>
                          </div>
                        </div>

                        <div className="d-flex justify-content-between align-items-baseline">
                          <div className="d-flex justify-content-between align-items-baseline w-50">
                            <p className="text-dark">
                              Roll Number / Student ID{" "}
                            </p>
                            <p className="text-dark">:</p>
                          </div>
                          <div className="w-50">
                            <p
                              className="mx-1 text-dark"
                              style={{ wordBreak: "break-all" }}
                            >
                              {ele?.student_id || "-"}
                            </p>
                          </div>
                        </div>

                        <div className="d-flex justify-content-between align-items-baseline">
                          <div className="d-flex justify-content-between align-items-baseline w-50">
                            <p className="text-dark">Major</p>
                            <p className="text-dark">:</p>
                          </div>
                          <div className="w-50">
                            <p
                              className="mx-1 text-dark"
                              style={{ wordBreak: "break-all" }}
                            >
                              {ele?.major || "-"}
                            </p>
                          </div>
                        </div>

                        <div className="d-flex justify-content-between align-items-baseline">
                          <div className="d-flex justify-content-between align-items-baseline w-50">
                            <p className="text-dark">Attend From</p>
                            <p className="text-dark">:</p>
                          </div>
                          <div className="w-50">
                            <p className="mx-1 text-dark">
                              {moment(ele?.start_date).format("DD/MM/YYYY") ||
                                "-"}
                            </p>
                          </div>
                        </div>

                        <div className="d-flex justify-content-between align-items-baseline">
                          <div className="d-flex justify-content-between align-items-baseline w-50">
                            <p className="text-dark">Graduated?</p>
                            <p className="text-dark">:</p>
                          </div>
                          <div className="w-50">
                            <p className="mx-1 text-dark">
                              {ele?.graduated ? "Y" : "N"}
                            </p>
                          </div>
                        </div>

                        <div className="d-flex justify-content-between align-items-baseline">
                          <div className="d-flex justify-content-between align-items-baseline w-50">
                            <p className="text-dark">Highest Achieved?</p>
                            <p className="text-dark">:</p>
                          </div>
                          <div className="w-50">
                            <p className="mx-1 text-dark">
                              {ele?.highest_achieved ? "Y" : "N"}
                            </p>
                          </div>
                        </div>

                        <div className="d-flex justify-content-between align-items-baseline">
                          <div className="d-flex justify-content-between align-items-baseline w-50">
                            <p className="text-dark">Comment</p>
                            <p className="text-dark">:</p>
                          </div>
                          <div className="w-50">
                            <p className="mx-1 text-dark">
                              {ele?.comment || "-"}
                            </p>
                          </div>
                        </div>
                      </div>

                      <div className="col-6">
                        <div className="d-flex justify-content-between align-items-baseline">
                          <div className="d-flex justify-content-between align-items-baseline w-50">
                            <p className="text-dark">Campus</p>
                            <p className="text-dark">:</p>
                          </div>
                          <div className="w-50">
                            <p
                              className="mx-1 text-dark"
                              style={{ wordBreak: "break-all" }}
                            >
                              {ele?.campus || "-"}
                            </p>
                          </div>
                        </div>

                        <div className="d-flex justify-content-between align-items-baseline">
                          <div className="d-flex justify-content-between align-items-baseline w-50">
                            <p className="text-dark">Country</p>
                            <p className="text-dark">:</p>
                          </div>
                          <div className="w-50">
                            <p className="mx-1 text-dark">
                              {ele?.country || "-"}
                            </p>
                          </div>
                        </div>

                        <div className="d-flex justify-content-between align-items-baseline">
                          <div className="d-flex justify-content-between align-items-baseline w-50">
                            <p className="text-dark">State / Province</p>
                            <p className="text-dark">:</p>
                          </div>
                          <div className="w-50">
                            <p className="mx-1 text-dark">
                              {ele?.state || "-"}
                            </p>
                          </div>
                        </div>
                        <div className="d-flex justify-content-between align-items-baseline">
                          <div className="d-flex justify-content-between align-items-baseline w-50">
                            <p className="text-dark">City</p>
                            <p className="text-dark">:</p>
                          </div>
                          <div className="w-50">
                            <p className="mx-1 text-dark">{ele?.city || "-"}</p>
                          </div>
                        </div>
                        <div className="d-flex justify-content-between align-items-baseline">
                          <div className="d-flex justify-content-between align-items-baseline w-50">
                            <p className="text-dark">Zip/Postal Code</p>
                            <p className="text-dark">:</p>
                          </div>
                          <div className="w-50">
                            <p className="mx-1 text-dark">{ele?.zip || "-"}</p>
                          </div>
                        </div>

                        <div className="d-flex justify-content-between align-items-baseline">
                          <div className="d-flex justify-content-between align-items-baseline w-50">
                            <p className="text-dark">Degree</p>
                            <p className="text-dark">:</p>
                          </div>
                          <div className="w-50">
                            <p
                              className="mx-1 text-dark"
                              style={{ wordBreak: "break-all" }}
                            >
                              {ele?.degree || "-"}
                            </p>
                          </div>
                        </div>
                        <div className="d-flex justify-content-between align-items-baseline">
                          <div className="d-flex justify-content-between align-items-baseline w-50">
                            <p className="text-dark">Attend To</p>
                            <p className="text-dark">:</p>
                          </div>
                          <div className="w-50">
                            <p className="mx-1 text-dark">
                              {moment(ele?.end_date).format("DD/MM/YYYY") ||
                                "-"}
                            </p>
                          </div>
                        </div>
                        <div className="d-flex justify-content-between align-items-baseline">
                          <div className="d-flex justify-content-between align-items-baseline w-50">
                            <p className="text-dark">GPA</p>
                            <p className="text-dark">:</p>
                          </div>
                          <div className="w-50">
                            <p className="mx-1 text-dark">{ele?.gpa || "-"}</p>
                          </div>
                        </div>

                        <div className="d-flex justify-content-between align-items-baseline">
                          <div className="d-flex justify-content-between align-items-baseline w-50">
                            <p className="text-dark">Transcript (ToR)</p>
                            <p className="text-dark">:</p>
                          </div>
                          <div className="w-50">
                            <p className="mx-1 text-dark">
                              {ele?.transcript ? "Y" : "N"}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="row">
                      {ele?.document?.map((ele, index) => {
                        return (
                          <div
                            className="col-6 p-2 bg-white mt-2 mb-2 p-2"
                            key={index}
                          >
                            <div className="w-100 d-flex justify-content-between align-items-center">
                              <div className="w-100 d-flex justify-content-between align-items-center mx-2">
                                <div className="d-flex align-items-center">
                                  <p
                                    className="m-0 p-1"
                                    style={{ background: "#BDE7FF" }}
                                  >
                                    {ele?.name
                                      ?.split(".")
                                      ?.pop()
                                      ?.toUpperCase() || "Pdf"}
                                  </p>
                                  <p
                                    className="m-0 mx-3"
                                    style={{ cursor: "pointer" }}
                                    onClick={() =>
                                      window.open(ele?.url, "_blank")
                                    }
                                  >
                                    {ele?.name}
                                  </p>
                                </div>

                                <p
                                  className="text-secondary"
                                  style={{ fontSize: "12px", paddingBottom: 0 }}
                                >
                                  {(ele?.size / 1024).toFixed(2) + " KB"}
                                </p>
                              </div>
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                </div>
              </>
            );
          })}

          {Object.keys(state?.data?.criminal || {}).length > 0 && (
            <>
              <div className="mt-4 border">
                <div
                  className="p-3 border-bottom"
                  style={{ background: "#F8F8FA" }}
                >
                  <h6 className="text-dark">Criminal / WorldScan</h6>
                </div>

                <div className="mt-2 p-3">
                  <div className="row">
                    <div className="col-6">
                      <div className="d-flex justify-content-between align-items-baseline">
                        <div className="d-flex justify-content-between align-items-baseline w-50">
                          <p className="text-dark">Country of Residence</p>
                          <p className="text-dark">:</p>
                        </div>
                        <div className="w-50">
                          <p className="mx-1 text-dark">
                            {state?.data?.criminal?.residence || "-"}
                          </p>
                        </div>
                      </div>
                    </div>

                    <div className="col-6">
                      <div className="d-flex justify-content-between align-items-baseline">
                        <div className="d-flex justify-content-between align-items-baseline w-50">
                          <p className="text-dark">Nationality</p>
                          <p className="text-dark">:</p>
                        </div>
                        <div className="w-50">
                          <p className="mx-1 text-dark">
                            {state?.data?.criminal?.nationality || "-"}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </>
          )}

          {Object.keys(authorization || {}).length > 0 && (
            <>
              <div className="mt-4 border">
                <div
                  className="p-3 border-bottom"
                  style={{ background: "#F8F8FA" }}
                >
                  <h6 className="text-dark">Authorization Form</h6>
                </div>

                <div className="col-12 p-2 bg-white mt-2 mb-2 p-2 w-100">
                  <div className="w-100 d-flex justify-content-between align-items-center">
                    <div className="w-100 d-flex justify-content-between align-items-center mx-2">
                      <div className="d-flex align-items-center">
                        <p
                          className="m-0 p-1"
                          style={{ background: "#BDE7FF" }}
                        >
                          Pdf
                        </p>
                        <p
                          className="m-0 mx-3 pointer"
                          style={{ cursor: "pointer" }}
                          onClick={() =>
                            window.open(
                              authorization?.authorization_form_pdf,
                              "_blank"
                            )
                          }
                        >
                          {authorization?.authorization_form_pdf?.slice(36)}
                        </p>
                      </div>
                    </div>
                    <div className="">
                      <button
                        onClick={() =>
                          downloadPdf(authorization?.authorization_form_pdf)
                        }
                      >
                        {" "}
                        Download
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </>
          )}

          <div className="mt-5 d-flex align-items-center">
            <Input
              type="checkbox"
              checked={confirm}
              onChange={(e) => {
                if (e.target.checked) {
                  setConfirm(true);
                  setError(false);
                } else {
                  setConfirm(false);
                  setError(true);
                }
              }}
              style={{ width: "20px", height: "20px", margin: 0 }}
            />{" "}
            <span className="mx-2 text-dark">
              I hereby confirm, that all the submitted and filled-in data are
              true, correct, and accurate, and that all required attachments
              have been provided.
            </span>
          </div>
          {error && (
            <p className="text-danger">
              Please confirm That data which provided is correct or not
            </p>
          )}
          <div className="d-flex justify-content-center align-items-center mt-3">
            <button
              className="py-2 px-5 rounded bg-white fw-bold"
              style={{ border: "1px solid #0432A3", color: "#0432A3" }}
              onClick={() =>
                navigate("/background-create-order", { state: { edit: true } })
              }
            >
              Back to edit
            </button>
            <button
              className="btn btn-blue mx-3"
              onClick={() => {
                !error && setmodel(true);
              }}
            >
              Continue to payment
            </button>
          </div>
        </div>

        {model && (
          <CustomModel
            modal={model}
            toggle={() => setmodel(!model)}
            title={"Continue to payment?"}
            message={
              "By proceeding to payment, you confirm that you have reviewed your order carefully and that all provided data are accurate and true."
            }
            handleSubmit={() =>
              navigate("/screening-payment", { state: { data: state?.data } })
            }
            buttonTitle={"Continue"}
          />
        )}
      </div>
    </>
  );
};

export default OrderPreview;
