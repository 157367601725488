import {
  GET_SUGGESTED_GROUPS,
  GET_JOINED_GROUPS,
  GET_PENDING_GROUPS,
  GET_MY_GROUPS,
  CREATE_MY_GROUP,
  EDIT_MY_GROUP,
  GET_GROUP_DETAIL,
  JOIN_GROUP,
  UNJOIN_GROUP,
  WITHDRAW_GROUP_REQUEST,
  LOAD_MORE_GET_JOINED_GROUPS,
  LOAD_MORE_GET_SUGGESTED_GROUPS,
  LOAD_MORE_GET_PENDING_GROUPS,
  LOAD_MORE_GET_MY_GROUPS,
  GET_RECIEVED_GROUPS,
  LOAD_MORE_GET_RECIEVED_GROUPS,
  ACCEPET_GROUP,
  LEAVE_GROUP,
  JOIN_GROUP_FROM_DETAIL,
  CLEAR_SUGGESTED_GROUPS,
} from "../types";

export const initialState = {
  suggested_groups: [],
  joined_groups: [],
  pending_groups: [],
  recieved_groups: [],
  my_groups: [],
  group_detail: [],
};

const groups = (state = initialState, action) => {
  switch (action.type) {
    case GET_SUGGESTED_GROUPS:
      return { ...state, suggested_groups: action.payload };

    case CLEAR_SUGGESTED_GROUPS:
      return { ...state, suggested_groups: [] };

    case LOAD_MORE_GET_SUGGESTED_GROUPS:
      return {
        ...state,
        suggested_groups: [...state.suggested_groups, ...action.payload],
      };

    case GET_JOINED_GROUPS:
      return { ...state, joined_groups: action.payload };
    case LOAD_MORE_GET_JOINED_GROUPS:
      return {
        ...state,
        joined_groups: [...state.joined_groups, ...action.payload],
      };

    case GET_PENDING_GROUPS:
      return { ...state, pending_groups: action.payload };
    case LOAD_MORE_GET_PENDING_GROUPS:
      return {
        ...state,
        pending_groups: [...state.pending_groups, ...action.payload],
      };

    case GET_RECIEVED_GROUPS:
      return { ...state, recieved_groups: action.payload };
    case LOAD_MORE_GET_RECIEVED_GROUPS:
      return {
        ...state,
        recieved_groups: [...state.recieved_groups, ...action.payload],
      };

    case GET_MY_GROUPS:
      return { ...state, my_groups: action.payload };
    case LOAD_MORE_GET_MY_GROUPS:
      return {
        ...state,
        my_groups: [...state.my_groups, ...action.payload],
      };

    case CREATE_MY_GROUP:
      return { ...state, my_groups: [action.payload, ...state.my_groups] };

    case EDIT_MY_GROUP:
      console.log(action.payload);
      return {
        ...state,
        my_groups: state?.my_groups?.map((item) =>
          item?.id === action.payload[0]?.id
            ? { ...item, ...action?.payload[0] }
            : item
        ),
        group_detail: action.payload,
      };

    case GET_GROUP_DETAIL:
      return { ...state, group_detail: action.payload };

    case JOIN_GROUP:
      return {
        ...state,
        suggested_groups: state.suggested_groups.map((item) =>
          item.id === action.payload ? { ...item, is_joined: "pending" } : item
        ),
        group_detail: state.group_detail.map((item) =>
          item.id === action.payload ? { ...item, is_joined: "pending" } : item
        ),
      };

    case JOIN_GROUP_FROM_DETAIL:
      return {
        ...state,
        suggested_groups: state.suggested_groups.map((item) =>
          item.id === action.payload ? { ...item, is_joined: "pending" } : item
        ),
      };

    case ACCEPET_GROUP:
      return {
        ...state,
        recieved_groups: state.recieved_groups.filter(
          (item) => item.id !== action.payload.group_id
        ),
      };

    case WITHDRAW_GROUP_REQUEST:
      return {
        ...state,
        pending_groups: state.pending_groups.filter(
          (item) => item.id !== action.payload.group_id
        ),
        group_detail: state.group_detail.map((item) =>
          item.id === action.payload.group_id
            ? { ...item, is_joined: null }
            : item
        ),
      };

    case LEAVE_GROUP:
      return {
        ...state,
        joined_groups: state.joined_groups.filter(
          (item) => item.id !== action.payload
        ),
        group_detail: state.group_detail.map((item) =>
          item.id === action.payload ? { ...item, is_joined: null } : item
        ),
      };

    case UNJOIN_GROUP:
      return {
        ...state,
        suggested_groups: state.suggested_groups.map((item) =>
          item.id === action.payload ? { ...item, is_joined: false } : item
        ),
      };

    default:
      return state;
  }
};

export default groups;
