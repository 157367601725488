import React, { useState, useEffect } from "react";
import { Input, Modal, ModalBody, ModalHeader } from "reactstrap";
import placeholderUser from "../../../../../assets/img/placeholder_user.jpg";
import { useDispatch } from "react-redux";
import { call } from "../../../../.././api/apiCall";
import {
  addRecommendation,
  askRecommendation,
  updateRecommendation,
} from "../../../../../store/recommendation/recommendationAction";
import { toast } from "react-toastify";

const WriteRecommendationModal = (props) => {
  const {
    modal,
    toggle,
    togglePrev,
    memberData,
    experienceData,
    title,
    askingRecommendation,
    recommendfromPending,
    recommendId,
    recommendationText,
    recommendStatus,
    recommendPendingUser,
  } = props;

  const [relationshipList, setRelationshipList] = useState([]);
  const [send, setSend] = useState(false);
  const [recommendationData, setRecommendationData] = useState({
    to: memberData?.id,
    relationship_id: "",
    recommendation: "",
    position: "",
  });

  const [askRecommendationData, setAskRecommendationData] = useState({
    from: memberData?.id,
    relationship_id: "",
    recommendation: "",
    position: "",
  });

  const [pendingRecommendationData, setPendingRecommendationData] = useState({
    id: recommendId,
    recommendation_status: recommendStatus,
    status: false,
    recommendation: recommendationText ? recommendationText : "",
  });

  const token = localStorage.getItem("accessToken");

  const dispatch = useDispatch();

  useEffect(() => {
    if (memberData?.id) {
      (async () => {
        try {
          const res = await call(
            "get",
            `api/v1/recomendation-type/${memberData?.id}`,
            null,
            null,
            token
          );
          setRelationshipList(res?.data);
        } catch (err) {
          console.log(err);
        }
      })();
    }
  }, []);

  useEffect(() => {
    if (
      recommendationData.relationship_id &&
      recommendationData.recommendation &&
      recommendationData.position
    ) {
      setSend(true);
    } else setSend(false);
  }, [recommendationData]);
  useEffect(() => {
    if (recommendfromPending && pendingRecommendationData.recommendation) {
      setSend(true);
    }
  }, [recommendationData]);

  const handleSubmit = () => {
    if (!send) {
      alert("Please fill all required fields");
    } else if (askingRecommendation) {
      dispatch(askRecommendation(askRecommendationData)).then((res) => {
        toast.success(res.message, {
          position: toast.POSITION.BOTTOM_LEFT,
        });
      });
      togglePrev();
      toggle();
    } else if (recommendfromPending) {
      dispatch(updateRecommendation(pendingRecommendationData)).then((res) => {
        toast.success(res.message, {
          position: toast.POSITION.BOTTOM_LEFT,
        });
      });
      toggle();
    } else
      dispatch(addRecommendation(recommendationData)).then((res) => {
        toast.success(res.message, {
          position: toast.POSITION.BOTTOM_LEFT,
        });
        togglePrev();
        toggle();
      });
  };

  return (
    <div>
      <Modal
        isOpen={modal}
        size="lg"
        className="fix-width gradient-background"
        style={{ width: "574px", height: "648px" }}
      >
        <div className="popup">
          <div className="add-skill">
            <ModalHeader toggle={toggle}>{title}</ModalHeader>
            <ModalBody>
              <form>
                <div className="title">
                  <label>
                    This recommendation will appear on{" "}
                    {memberData?.first_name
                      ? memberData?.first_name?.toLowerCase()
                      : recommendPendingUser?.first_name?.toLowerCase()}
                    's profile
                  </label>
                </div>
                <div className="mt-4" style={{ display: "flex" }}>
                  <div>
                    <img
                      src={
                        memberData?.profile_img
                          ? memberData?.profile_img
                          : placeholderUser
                      }
                      alt=""
                      style={{
                        objectFit: "cover",
                        borderRadius: "55%",
                        height: "50px",
                        width: "50px",
                      }}
                    />
                  </div>

                  <div className="ml-10 mt-10">
                    <h6 className="card-control-text-oneline">
                      {memberData?.first_name} {memberData?.last_name}
                    </h6>
                  </div>
                </div>
                <div className="title mt-10">
                  <label>*Indicates required</label>
                </div>
                {!recommendfromPending && (
                  <div>
                    <div className="title mt-20 mb-1">
                      <label>Relationship*</label>
                    </div>
                    <Input
                      value={recommendationData?.relationship_id}
                      id="relationship"
                      name="select"
                      type="select"
                      onChange={(e) => {
                        setRecommendationData({
                          ...recommendationData,
                          relationship_id: e.target.value,
                        });
                        setAskRecommendationData({
                          ...askRecommendationData,
                          relationship_id: e.target.value,
                        });
                      }}
                    >
                      <option value="">Select</option>
                      {relationshipList?.data?.map((item, index) => {
                        return (
                          <option key={index} value={item.id}>
                            {item.type}
                          </option>
                        );
                      })}
                    </Input>
                    <div className="title mt-20 mb-1">
                      <label>Position at the time*</label>
                    </div>
                    <Input
                      //   value={expData.field_of_study}
                      id="fieldStudySelect"
                      name="select"
                      type="select"
                      onChange={(e) => {
                        setRecommendationData({
                          ...recommendationData,
                          position: e.target.value,
                        });
                        setAskRecommendationData({
                          ...askRecommendationData,
                          position: e.target.value,
                        });
                      }}
                    >
                      <option value="">Select</option>
                      {experienceData?.map((item, index) => {
                        return Object.values(item)?.map((item2, index) => {
                          return Object.values(item2)?.map((item3, index) => {
                            return (
                              <option
                                key={index}
                                value={
                                  item3.title + " at " + item3.company_name
                                }
                              >
                                {item3.title} at {item3.company_name}
                              </option>
                            );
                          });
                        });
                      })}
                    </Input>
                  </div>
                )}
                <div className="title mt-20 mb-1">
                  <label>Add recommendation*</label>
                </div>
                <Input
                  type="textarea"
                  placeholder=""
                  maxLength="200"
                  value={
                    pendingRecommendationData.recommendation
                      ? pendingRecommendationData.recommendation
                      : ""
                  }
                  onChange={(e) => {
                    setRecommendationData({
                      ...recommendationData,
                      recommendation: e.target.value,
                    });
                    setAskRecommendationData({
                      ...askRecommendationData,
                      recommendation: e.target.value,
                    });
                    setPendingRecommendationData({
                      ...pendingRecommendationData,
                      recommendation: e.target.value,
                    });
                  }}
                />
                <div className="right-align-button">
                  <div className="title mt-4">
                    <label>2/2</label>
                  </div>
                  <div style={{ marginTop: "15px" }}>
                    {!recommendfromPending && (
                      <div className="btn btn-outline mr-10" onClick={toggle}>
                        Back
                      </div>
                    )}

                    <div
                      className={
                        "btn " + (send ? "btn-blue" : "disabled-btn-blue")
                      }
                      onClick={handleSubmit}
                    >
                      Send
                    </div>
                  </div>
                </div>
              </form>
            </ModalBody>
            <div style={{ padding: "5px" }}></div>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default WriteRecommendationModal;
