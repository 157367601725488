import Hashtag from "./Hashtag";
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getHashtagData } from "../../../store/hashtag/hashtagActions";
import { followHashtag } from "../../../store/hashtag/hashtagActions";
import { toast } from "react-toastify";
import { deletePost, hide_user_post, repost_post, savePost, savePostDelete } from "../../../store/dashboard/dashboardActions";
import { reactionsData } from "../../../store/reactionPost/reactionAction";

export default function HashtagContainer(){

    const dispatch = useDispatch();

    const { suggestedTagList } = useSelector((state) => state.hashtag);
    const { followedTagList } = useSelector((state) => state.hashtag);
    const { myTagList } = useSelector((state) => state.hashtag);

    const [tabs, setTabs] = useState("suggested_tags");
    const [currentCount, setCurrentCount]= useState(0);

    useEffect(()=>{
        let query = "followed";
        dispatch(getHashtagData(query, currentCount)); 
    },[])

    useEffect (()=>{
        if (tabs==="suggested_tags"){
            let query = "suggested";
        dispatch(getHashtagData(query, currentCount));
        }
        else if (tabs==="followed_tags") {
             let query = "followed";
        dispatch(getHashtagData(query, currentCount)); 
        }
        else if (tabs==="my_tags") {
            let query = "my";
        dispatch(getHashtagData(query, currentCount)); 
        }

    }, [tabs, currentCount])

    const loadMoreData = () => {
        setCurrentCount(currentCount + 10);
      };

    useEffect (()=>{
        setCurrentCount(0);
    }, [tabs])

    const follow_hashtag = (hashtagId, hashtagName, type) => {
        dispatch(followHashtag(hashtagId, hashtagName, type, tabs))
    }




    return(
        <Hashtag
        tabs={tabs}
        setTabs={setTabs}
        suggestedTagList={suggestedTagList}
        followedTagList={followedTagList}
        myTagList={myTagList}
        follow_hashtag={follow_hashtag}
        currentCount={currentCount}
        setCurrentCount={setCurrentCount}
        loadMoreData={loadMoreData}
        />
    )
}