import React from "react";
import "../../dashboard.scss";
const PollComponent = ({ data, editPoll, deletePoll, isEdit }) => {
  console.log(data);
  return (
    <div>
      <div className="pollContainer">
        <div className="pollContainerRow">
          <p className="descriptionOverFlowSpan">{data.question}</p>
          {/* Code to edit and Delete Poll at time of Creation(view in CreatePostmodal) */}
          {/* {!(isEdit >0)? (
          <div
           className="pollContainerIcons"
           >
            <div onClick={editPoll}>
              <img src={edit} alt="" />
            </div>
            <div onClick={deletePoll}>
              <img src={deleteIcon} alt="" />
            </div>
          </div>
          ) :""} */}
        </div>
        {data?.option ? (
          <div className="row btn-div">
            {data?.option &&
              data?.option.map((item, i) => {
                return (
                  <div className="col-6" key={i}>
                    <button className="btn btn-border-blue pollButton mb-4 ">
                      {item.title}
                    </button>
                  </div>
                );
              })}
          </div>
        ) : (
          <div className="row btn-div">
            {data?.options &&
              data?.options.map((item, i) => {
                return (
                  <div className="col-6" key={i}>
                    <button className="btn btn-border-blue pollButton mb-4">
                      {item.title}
                    </button>
                  </div>
                );
              })}
          </div>
        )}
        <div className="mini_row">
          <p>0 votes</p>

          <p>
            {data?.poll_duration
              ? Number(data?.poll_duration) / 24
              : Number(data?.duration) / 24}{" "}
            days left
          </p>
        </div>
      </div>
    </div>
  );
};

export default PollComponent;
