import React, { useEffect, useState } from "react";
import logo from "../../../assets/img/np-logo.svg";
import info from "../../../assets/img/info.svg";
import signupimage from "../../../assets/img/signup-portal.svg";
import pass from "../../../assets/img/shield-security.svg";
import "./ChangePassword.scss";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import FormInput from "../../../core/components/Inputs/FormInput";
import { call } from "../../../api/apiCall";
import { useDispatch, useSelector } from "react-redux";
import { getTalentPoolDetails, getTalentPoolDetailsByToken } from "../../../store/portal/portalAction";
import { toast } from "react-toastify";

export default function ResetPassword() {
  const [showMsg, setShowMsg] = useState(false);
  const dispatch = useDispatch();
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [passErrorMessage, setPassErrorMessage] = useState("");
  const [confirmPassErrorMessage, setConfirmPassErrorMessage] = useState("");
  const { talentPoolData, talentPoolDataByToken } = useSelector((state) => state.portal);
  const { param } = useParams();
  const toggleShow = () => setShowMsg((value) => !value);
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    if (location && location.state && location.state.token) {
      console.log(location.state.token);
    } else {
      navigate("/error");
    }
  });
  useEffect(() => {
    dispatch(getTalentPoolDetails(param));
    dispatch(getTalentPoolDetailsByToken())
  }, []);
  useEffect(() => {
    if (talentPoolData?.id===talentPoolDataByToken?.id) navigate(`/tp/portal/${param}/dashboard`);
  },[talentPoolDataByToken,talentPoolData]);

  const validation = () => {
    let passwordValid =
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;
    if (!password) {
      setPassErrorMessage("Please Enter Your Password");
      return;
    }
    if (!passwordValid.test(password)) {
      setPassErrorMessage(
        'The password should be minimum 8 characters long and contain at least one : Upper and Lower Case Alphabet, Number, Special Character. The special characters that can be used are [!""#$%&\'()*+,-./:;<=>?@[]^_`{|}~]'
      );
      return;
    }
    if (!(password === confirmPassword)) {
      setConfirmPassErrorMessage("Passwords do not match");
      return;
    }

    handleSubmit();
  };
  const handelPassChange = (e) => {
    setPassword(e.target.value);
    setPassErrorMessage("");
    setConfirmPassErrorMessage("");
  };
  const handelConfirmPassChange = (e) => {
    setConfirmPassword(e.target.value);
    setConfirmPassErrorMessage("");
  };
  const handleSubmit = async () => {
    let resetPassData = {
      newPassword: password,
    };

    try {
      const res = await call(
        "post",
        "api/v1/auth/portal/reset-password",
        null,
        { ...resetPassData, id: parseInt(param) },
        location.state.token
      );
      if (res.data.success === true) {
        toast.success("Password has been changed successfully.");
        navigate(`/tp/portal/${param}/login`);
      }
      if (res.data.success === false) {
        alert(res.data.message);
      }
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <div>
      <div className="login-portal" style={{ height: "100vh",background:"white" }}>
        <div className="row" style={{margin:"auto",width:"100%"}}>
          <div className="col-lg-4 forgot-left" style={{ height: "100vh" }}>
            <img
              src={
                talentPoolData?.background_img
                  ? talentPoolData?.background_img
                  : signupimage
              }
              alt="signupimage"
              className="center"
            />
          </div>
          <div className="col-lg-8 right" style={{display:"flex"}}>
            <div className="forgot " style={{margin:"auto"}}>
              <form className="auth-form dark-blue-color">
                <h2>Change Password</h2>
                <p>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc
                  vulputate libero et velit interdum
                </p>

                <FormInput
                  label={
                    <>
                      New Password{" "}
                      <span>
                        <img
                          src={info}
                          alt=""
                          onMouseOver={toggleShow}
                          onMouseLeave={toggleShow}
                        />
                      </span>
                    </>
                  }
                  maxlength="24"
                  type="password"
                  placeholder="Enter Password"
                  error={passErrorMessage}
                  onChange={handelPassChange}
                  containerClassName={passErrorMessage ? "error-form" : ""}
                />
                {showMsg == true && (
                  <div className="info">
                    <h6>Pasword Requirements</h6>
                    <ul className="p-0">
                      <li>
                        Must contain both uppercase and lowercase characters
                        (e.g., a-z and A-Z)
                      </li>
                      <li>Must contain at least one number (e.g., 0-9).</li>
                      <li>Must be between 6-24 character</li>
                    </ul>
                    <div className="box"></div>
                    <div className="angle"></div>
                  </div>
                )}
                <FormInput
                  label="Confirm Password"
                  type="password"
                  placeholder="Retype Password"
                  maxlength="24"
                  onChange={handelConfirmPassChange}
                  error={confirmPassErrorMessage}
                  containerClassName={
                    confirmPassErrorMessage ? "error-form" : ""
                  }
                />
                <div className="text-center d-grid mt-3">
                  <div
                    className="btn btn-blue"
                    style={{ background: `${talentPoolData?.theme}` }}
                    onClick={validation}
                  >
                    Change Password
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
        <div className="minHeight"></div>
      </div>
    </div>
  );
}
