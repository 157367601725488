import React, { useState } from "react";
import { useDropzone } from "react-dropzone";
import drop from "../../../../assets/img/gallery-add.png";
import edit from "../../../../assets/img/edit.svg";

function DropzoneGroups({ handleBannerPicture, croppedBackgroundImage }) {
  const [files, setFiles] = useState([]);

  const { getRootProps, getInputProps, isDragActive, acceptedFiles } =
    useDropzone({
      multiple: false,
      accept: {
        "image/*": [],
      },
      onDrop: (acceptedFiles) => {
        setFiles(
          acceptedFiles.map((file) =>
            Object.assign(file, {
              preview: URL.createObjectURL(file),
            })
          )
        );
        acceptedFiles.map((file) => {
          handleBannerPicture(file, "background");
        });
      },
    });

  return (
    <div {...getRootProps({ className: "dropzone" })}>
      <input className="input-zone" {...getInputProps()} />
      <div className="drag-img">
        {files.length !== 0 || croppedBackgroundImage ? (
          <div className="img">
            <img
              src={croppedBackgroundImage}
              className="w-100"
              alt="groupsBanner"
            />
            <div
              className="create-page-edit"
              style={{ position: "absolute", top: "3.08px", right: "0.95px" }}
            >
              <img src={edit} alt="" />
            </div>
          </div>
        ) : (
          <div className="text-center">
            <div className="img"></div>
            {isDragActive ? (
              <p className="dropzone-content">Release to drop the files here</p>
            ) : (
              <div className="text">
                <p>
                  Drag and drop or{" "}
                  <span className="blue-txt">
                    <u style={{ cursor: "pointer" }}>browse</u>
                  </span>{" "}
                  your files
                </p>
                <p>Recommended size is mimimum or above 1920x1080 pixels.</p>
              </div>
            )}
          </div>
        )}
      </div>

      <aside></aside>
    </div>
  );
}

export default DropzoneGroups;
