import React from "react";

export const Archive = ({
  isMembership,
  setAwayMessageModal,
  setScheduleMeetingModal,
  setAwayMessageCheckModal,
  setFilterType,
}) => {
  return (
    <div className="archive-side">
      <div className="bg-white mt-26 p-20">
        <ul>
          <li onClick={() => setFilterType("archive")}>Archive</li>
          <li onClick={() => setFilterType("request")}>Request Inbox</li>
          <li onClick={() => setFilterType("block")}>Blocked Messages</li>
          <li onClick={() => setFilterType("spam")}>Spam Messages</li>
          <li onClick={() => setFilterType("unread")}>Unread messages</li>
          <li
            className={isMembership ? "" : "opacity-25"}
            onClick={() =>
              !isMembership
                ? setAwayMessageCheckModal(true)
                : setAwayMessageModal(true)
            }
          >
            Set Away Message
          </li>
          <li
            className={isMembership ? "" : "opacity-25"}
            onClick={() =>
              !isMembership
                ? setAwayMessageCheckModal(true)
                : setScheduleMeetingModal(true)
            }
          >
            Schedule a video meeting
          </li>
        </ul>
      </div>
    </div>
  );
};
