import {
  GET_SUGGESTED_NEWSLETTERS,
  GET_MY_NEWSLETTERS,
  GET_SUBSCRIBED_NEWSLETTERS,
  CREATE_MY_NEWSLETTERS,
  GET_DRAFT_NEWSLETTER,
  SUBSCRIBE_MY_NEWSLETTERS,
  GET_NEWSLETTER_DETAIL,
  SUBSCRIBE_DETAILED_NEWSLETTER,
  EDIT_MY_NEWSLETTER,
  EDIT_MY_NEWSLETTER_DETAILS,
  LIKE_NEWSLETTER,
  COMMENT_NEWSLETTER,
  GET_COMMENT_LIST,
  GET_MORE_NEWSLETTER_COMMENT_LIST,
  UNSUBSCRIBE_MY_NEWSLETTERS,
  GET_PAGE_NEWSLETTERS,
  UNLIKE_NEWSLETTER,
  LOAD_MORE_GET_MY_NEWSLETTERS,
  LOAD_MORE_GET_SUBSCRIBED_NEWSLETTERS,
  LOAD_MORE_GET_DRAFT_NEWSLETTER,
  LOAD_MORE_GET_SUGGESTED_NEWSLETTERS,
} from "../types";
import { newsletterApi } from "../../api/newsletterApi";
import { initLoad, initPageLoad, stopPageLoad } from "../loader/loaderActions";
import { stopLoad } from "../loader/loaderActions";

export const getSuggestedNewsletters =
  (data, query, params, currentCount, noReaload) => (dispatch) => {
    return newsletterApi.getNewsletters(data, params).then(
      (newsletters) => {
        console.log("noReaload", noReaload);
        {
          noReaload ? <></> : dispatch(initPageLoad());
        }
        if (query === "my") {
          if (currentCount === 0) {
            dispatch({
              type: GET_MY_NEWSLETTERS,
              payload: newsletters.data,
            });
            return Promise.resolve(newsletters);
          } else
            dispatch({
              type: LOAD_MORE_GET_MY_NEWSLETTERS,
              payload: newsletters.data,
            });
          dispatch(stopPageLoad());
          return Promise.resolve(newsletters);
        } else if (query === "subscribed") {
          if (currentCount == 0) {
            dispatch({
              type: GET_SUBSCRIBED_NEWSLETTERS,
              payload: newsletters.data,
            });
            return Promise.resolve(newsletters);
          } else {
            dispatch({
              type: LOAD_MORE_GET_SUBSCRIBED_NEWSLETTERS,
              payload: newsletters.data,
            });
            return Promise.resolve(newsletters);
          }
        } else if (query === "draft") {
          if (currentCount == 0) {
            dispatch({
              type: GET_DRAFT_NEWSLETTER,
              payload: newsletters.data,
            });
            return Promise.resolve(newsletters);
          } else {
            dispatch({
              type: LOAD_MORE_GET_DRAFT_NEWSLETTER,
              payload: newsletters.data,
            });
            return Promise.resolve(newsletters);
          }
        } else {
          if (currentCount == 0) {
            console.log(newsletters, "data");
            dispatch({
              type: GET_SUGGESTED_NEWSLETTERS,
              payload: newsletters.data,
            });
            return Promise.resolve(newsletters);
          } else {
            dispatch({
              type: LOAD_MORE_GET_SUGGESTED_NEWSLETTERS,
              payload: newsletters.data,
            });
            return Promise.resolve(newsletters);
          }
        }
      },
      (error) => {
        console.log(error);
        return Promise.reject(error);
      }
    );
  };

export const getOthersNewsletters = (query, params) => (dispatch) => {
  newsletterApi.getOthersNewsletters(params).then((events) => {
    if (query === "page") {
      dispatch({
        type: GET_PAGE_NEWSLETTERS,
        payload: events.data,
      });
    }
  });
};

export const createNewsletter = (data, token) => (dispatch) => {
  dispatch(initLoad());
  return newsletterApi.createNewsletter(data, token).then((newsletters) => {
    dispatch({
      type: CREATE_MY_NEWSLETTERS,
      payload: data,
    });
    dispatch(stopLoad());
    return Promise.resolve(newsletters.data);
  });
};

export const editNewsletter = (data, token) => (dispatch) => {
  dispatch(initLoad());
  return newsletterApi.editNewsletter(data, token).then((newsletter) => {
    dispatch({
      type: EDIT_MY_NEWSLETTER,
      payload: newsletter.data,
    });
    dispatch(stopLoad());
    return Promise.resolve(true);
  });
};

export const subscribeNewsletter = (id, token) => (dispatch) => {
  newsletterApi.subscribeNewsletter(id, token).then(() => {
    dispatch({
      type: SUBSCRIBE_MY_NEWSLETTERS,
      payload: id,
    });
  });
};

export const unsubscribeNewsletter = (id, token) => (dispatch) => {
  newsletterApi.subscribeNewsletter(id, token).then(() => {
    dispatch({
      type: UNSUBSCRIBE_MY_NEWSLETTERS,
      payload: id,
    });
  });
};

export const getNewsletterDetail = (id, token) => (dispatch) => {
  newsletterApi.getNewsletterDetail(id, token).then((newsletters) => {
    dispatch({
      type: GET_NEWSLETTER_DETAIL,
      payload: newsletters.data,
    });
  });
};

export const subscribeDetailedNewsletter = (id, token) => (dispatch) => {
  newsletterApi.subscribeNewsletter(id, token).then(() => {
    dispatch({
      type: SUBSCRIBE_DETAILED_NEWSLETTER,
      payload: id,
    });
  });
};

export const likeNewsletter = (data, type) => (dispatch) => {
  dispatch(initLoad());
  newsletterApi.likeNewsletter(data).then((response) => {
    if (type === "like") {
      dispatch({
        type: LIKE_NEWSLETTER,
        payload: data,
      });
    } else if (type === "unlike") {
      dispatch({
        type: UNLIKE_NEWSLETTER,
        payload: data,
      });
    }
    dispatch(stopLoad());
  });
};

export const commentNewsletter = (data) => (dispatch) => {
  dispatch(initLoad());
  newsletterApi.commentNewsletter(data).then((response) => {
    dispatch({
      type: COMMENT_NEWSLETTER,
      payload: response.data,
    });

    dispatch(stopLoad());
  });
};

export const getCommentDetails = (data) => (dispatch) => {
  dispatch(initLoad());
  newsletterApi.getCommentDetails(data).then((response) => {
    if (data.skip === 0) {
      dispatch({
        type: GET_COMMENT_LIST,
        payload: response,
      });
    } else {
      dispatch({
        type: GET_MORE_NEWSLETTER_COMMENT_LIST,
        payload: response,
      });
    }
    dispatch(stopLoad());
  });
};
