import React, { useState } from "react";
import JobCard from "./JobCard";
import Carousel from "react-multi-carousel";
import "./PortalDashboard.scss";

const CardCarousel = (props) => {
  const { themeColor, featuredJobs, param,isLoggedIn } = props;
  const responsive = {
    desktop: { breakpoint: { max: 3000, min: 1024 }, items: 2 },
    mobile: { breakpoint: { max: 464, min: 0 }, items: 1 },
    tablet: { breakpoint: { max: 1024, min: 464 }, items: 2 },
  };
  return (
    //     <div
    //       id="carouselExampleControls"
    //       class="carousel slide"
    //       data-bs-ride="carousel"
    //     >
    //       <div class="carousel-inner">
    //        {/* {featuredJobs?.map((item, index)=>
    //        <div class="carousel-item active" key={index}>
    //         <div class="card-group">
    //         <JobCard themeColor={themeColor} jobs={item} param={param}/>
    //           </div>
    //         </div>)} */}
    //         {featuredJobs?.map((item, index) => (
    //   <div className={`carousel-item ${index === 0 ? 'active' : ''}`} key={index}>
    //     <div className="card-group">
    //       <JobCard themeColor={themeColor} jobs={item} param={param}/>
    //     </div>
    //   </div>
    // ))}

    //         {/* <div class="carousel-item">
    //         <div class="card-group">
    //         <JobCard themeColor={themeColor} />
    //           <JobCard themeColor={themeColor} />
    //           </div>
    //         </div> */}
    //         {/* <div class="carousel-item">
    //           <JobCard themeColor={themeColor} />
    //         </div> */}
    //       </div>
    //       <button
    //         class="carousel-control-prev"
    //         type="button"
    //         data-bs-target="#carouselExampleControls"
    //         data-bs-slide="prev"
    //       >
    //         <span class="carousel-control-prev-icon" aria-hidden="true"></span>
    //         <span class="visually-hidden">Previous</span>
    //       </button>
    //       <button
    //         class="carousel-control-next"
    //         type="button"
    //         data-bs-target="#carouselExampleControls"
    //         data-bs-slide="next"
    //       >
    //         <span class="carousel-control-next-icon" aria-hidden="true"></span>
    //         <span class="visually-hidden">Next</span>
    //       </button>
    //     </div>

    <div id="main-slide" className="carousel slide" data-ride="carousel">
      <div className="carousel-inner job-card-carousel">
        <Carousel
          responsive={responsive}
          additionalTransfrom={0}
          arrows
          centerMode={false}
          // className="larger-image-view"
          dotListClass=""
          draggable
          focusOnSelect={false}
          infinite={false}
          itemClass=""
          keyBoardControl
          minimumTouchDrag={80}
          renderButtonGroupOutside={false}
          renderDotsOutside={false}
          showDots={false}
          sliderClass=""
          slidesToSlide={1}
          swipeable
        >
          {featuredJobs?.map((item, index) => {
            return (
              <div
                key={index}
                className="carousel-item active img-crousle"
                // onClick={() => setShowTags(!showTags)}
              >
                {<JobCard 
                // themeColor={themeColor} jobs={item} param={param}
                themeColor={themeColor}
                jobs={item}
                param={param}
                isLoggedIn={isLoggedIn}
                 />}
              </div>
            );
          })}
        </Carousel>
      </div>
    </div>
  );
};

export default CardCarousel;
