import React, { useState, useEffect, useRef } from "react";
import { Modal, Input, ModalHeader } from "reactstrap";
import EditorBox from "./Editor";
import "../newsletter.scss";
//import "../../Articles/ArticleDetails/ArticleDetails.scss";
import NewsletterPreviewModal from "./newsletterPreview";
import { editNewsletter } from "../../../../store/newsletter/newsletterActions";
import { createNewsletter } from "../../../../store/newsletter/newsletterActions";
import { useDispatch, useSelector } from "react-redux";
import Dropzone from "../../Articles/components/DragNDrop";
import axios from "axios";
import Loader from "../../../../core/components/Loaders/ComponentLoader";
import { getIndustryData } from "../../../../store/master/masterActions";
import Autocomplete from "react-autocomplete";
import DiscardNewsletterModal from "./DiscardModal";
import ShareModal from "../../../../core/components/sharePost/sharePost";
import { toast } from "react-toastify";
import AppLoader from "../../../../core/components/Loaders/appLoader/appLoader";
import placeholderUser from "../../../../assets/img/placeholder_user.jpg";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import Editor from "ckeditor5-custom-build/build/ckeditor";
import { call } from "../../../../api/apiCall";
const CreateNewsletterModal = (props) => {
  const {
    modal,
    toggle,
    title,
    editNewsletterData,
    setEditNewsletterData,
    successfulStatus,
    source,
    pageName,
    createdAt,
    pageID,
    pageIcon,
  } = props;

  const token = localStorage.getItem("accessToken");
  const modalRef = useRef(null);

  const { isLoading } = useSelector((state) => state.loader);
  const { industryData } = useSelector((state) => state.master);
  const [convertedContent, setConvertedContent] = useState(
    editNewsletterData?.content ? editNewsletterData?.content : ""
  );
  const [openShareModal, setOpenShareModal] = useState(false);
  const [shareData, setShareData] = useState([]);
  const [previewModal, setPreviewModal] = useState(false);
  const [textLength, setTextLength] = useState(
    editNewsletterData?.title ? editNewsletterData.title.split(" ").length : 0
  );
  const [dbFormat, setDbFormat] = useState(
    editNewsletterData?.content ? editNewsletterData?.content : ""
  );
  const [bannerImage, setBannerImage] = useState(
    editNewsletterData ? editNewsletterData?.media : ""
  );
  const [hasText, setHasText] = useState(false);
  const [onlySpaces, setOnlySpaces] = useState(false);
  const [formStatus, setFormStatus] = useState(false);
  const [industryInput, setIndustryInput] = useState(
    editNewsletterData ? editNewsletterData?.industry : ""
  );
  const [discardModalStatus, setDiscardModalStatus] = useState(false);

  const [newsletterData, setNewsletterData] = useState(
    editNewsletterData
      ? {
          id: editNewsletterData?.id,
          title: editNewsletterData?.title,
          industry: editNewsletterData?.industry,
          content: editNewsletterData?.content,
          media: editNewsletterData?.media,
          privacy_id: 1,
          hashtags: ["string"],
        }
      : {
          title: "",
          industry: "",
          content: "",
          media: "",
          privacy_id: 1,
          hashtags: ["string"],
        }
  );

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getIndustryData());
  }, []);

  function wordLimit(limit) {
    var val = newsletterData.title;
    var words = val.split(/\s+/);
    var legal = "";
    for (let i = 0; i < words.length; i++) {
      if (i < limit) {
        legal += words[i] + " ";
      }
      if (i >= limit) {
        setNewsletterData({ ...newsletterData, title: legal.trim() });
      }
    }
  }
  function getWordCount(str) {
    return str.split(" ").filter(function (n) {
      return n != "";
    }).length;
  }

  useEffect(() => {
    checkValidation();
  }, [newsletterData.title, newsletterData.industry, hasText, onlySpaces]);

  const checkValidation = () => {
    setFormStatus(true);
    if (!newsletterData.title.trim()) {
      setFormStatus(false);
    }
    // if (!newsletterData.industry.trim()) {
    //   setFormStatus(false);
    // }
    if (!hasText) {
      setFormStatus(false);
    }

    if (onlySpaces) {
      setFormStatus(false);
    }
  };

  const publishNewsletter = () => {
    console.log(convertedContent);
    let newDataFormat = {
      title: newsletterData.title,
      industry: newsletterData.industry,
      content: convertedContent,
      media: bannerImage,
      newsletter_status: "published",
      privacy_id: 1,
      hashtags: ["string"],
    };

    setShareData(newDataFormat);

    if (source === "network") {
      if (newsletterData.id) {
        newDataFormat.id = newsletterData.id;
        dispatch(editNewsletter(newDataFormat, token))
          .then(() => {
            toast.success("Updated Successfully.", {
              position: toast.POSITION.BOTTOM_LEFT,
            });
            toggle();
          })
          .catch((e) => {});
      } else {
        dispatch(createNewsletter(newDataFormat, token))
          .then((data) => {
            successfulStatus(data);
            toggle();
          })
          .catch((e) => {});
      }
    } else if (source === "home") {
      setOpenShareModal(true);
    }
  };

  const handleBannerPicture = async (file) => {
    console.log(file);
    try {
      const UPLOAD_URL = `${process.env.REACT_APP_API_URL}api/v1/upload`;
      const formData = new FormData();
      const headers = {
        "Accept-Language": "en",
        "content-type": "multipart/form-data",
      };

      formData.append("image", file);

      await axios.post(UPLOAD_URL, formData, { headers }).then((res) => {
        setBannerImage(res?.data?.urlsArray[0]);
        console.log(res?.data?.urlsArray[0]);
      });
    } catch (err) {
      console.log(err);
    }
  };

  const deletePicture = () => {
    setBannerImage("");
  };

  const checkDiscardChanges = () => {
    if (
      newsletterData?.title ||
      newsletterData?.content ||
      newsletterData?.industry
    ) {
      return true;
    } else return false;
  };

  const closeModal = () => {
    if (createdAt === "page") {
      toggle();
    }
    if (editNewsletterData) {
      setEditNewsletterData("");
    }
    console.log(checkDiscardChanges());
    if (!editNewsletterData && checkDiscardChanges()) {
      setDiscardModalStatus(true);
    } else {
      toggle();
    }
  };

  const closeAllModal = () => {
    setDiscardModalStatus(!discardModalStatus);
    toggle();
  };

  const saveDraft = () => {
    const newDataFormat = {
      title: newsletterData.title,
      industry: newsletterData.industry,
      content: convertedContent,
      media: bannerImage,
      privacy_id: 1,
      // hashtags:hashTagData?.map(s => s.slice(1))
    };

    newDataFormat.newsletter_status = "draft";
    dispatch(createNewsletter(newDataFormat, token))
      .then(() => {
        setDiscardModalStatus(!discardModalStatus);
        toggle();
      })
      .catch((e) => {});
  };

  const { user } = useSelector((state) => state?.auth);

  console.log(user);

  // const [convertedContent, setConvertedContent] = useState(null);
  // const [hasText, setHasText] = useState(false);

  function handleQuery(query) {
    return new Promise((resolve) => {
      setTimeout(() => {
        call(
          "get",
          `api/v1/user/mention-list`,
          { search: query, limit: 10, skip: 0 },
          null,
          token
        ).then((res) => {
          if (res.data.success && res.data.data.length > 0) {
            const myData = res.data.data.map((ele) => {
              return { ...ele, id: `@${ele.display}`, userId: ele.id };
            });
            resolve(myData);
          } else {
            // resolve(['@Barney', '@Lily', '@Marry Ann', '@Marshall', '@Robin', '@Ted'])
            resolve([]);
          }
        });
      }, 100);
    });
  }

  function handleHashQuery(query) {
    return new Promise((resolve) => {
      setTimeout(() => {
        call(
          "get",
          `api/v1/feed/mention-hash`,
          { search: query, limit: 10, skip: 0 },
          null,
          token
        ).then((res) => {
          if (res.data.success && res.data.data.length > 0) {
            const myData = res.data.data.map((ele) => {
              return { ...ele, id: `#${ele.display}`, userId: ele.id };
            });
            resolve(myData);
          } else {
            // resolve(['@Barney', '@Lily', '@Marry Ann', '@Marshall', '@Robin', '@Ted'])
            resolve([{ id: `#${query}`, userId: null }]);
          }
        });
      }, 100);
    });
  }

  function customItemRenderer(item) {
    const itemElement = document.createElement("div");
    itemElement.id = `mention-list-item-id-${item.userId}`;
    itemElement.classList.add("render-ele");

    const img = document.createElement("img");
    img.classList.add("item-img");
    img.setAttribute("src", item.profile_img);
    img.setAttribute("alt", "DP Image");
    img.setAttribute("width", "40px");
    img.setAttribute("height", "40px");

    const displayBox = document.createElement("div");
    displayBox.classList.add("info-block");

    const displayElement = document.createElement("span");
    displayElement.classList.add("custom-item");
    displayElement.textContent = `${item.display} `;

    const usernameElement = document.createElement("span");
    usernameElement.classList.add("custom-item-username");
    usernameElement.textContent = item?.profile?.profile_headline || "";

    displayBox.appendChild(displayElement);
    displayBox.append(usernameElement);

    itemElement.appendChild(img);
    itemElement.appendChild(displayBox);

    return itemElement;
  }

  function MentionCustomization(editor) {
    // The upcast converter will convert view <a class="mention" href="" data-user-id="">
    // elements to the model 'mention' text attribute.
    editor.conversion.for("upcast").elementToAttribute({
      view: {
        name: "a",
        key: "data-mention",
        classes: "mention",
        attributes: {
          href: true,
          "data-user-id": true,
        },
      },
      model: {
        key: "mention",
        value: (viewItem) => {
          // The mention feature expects that the mention attribute value
          // in the model is a plain object with a set of additional attributes.
          // In order to create a proper object use the toMentionAttribute() helper method:
          const mentionAttribute = editor.plugins
            .get("Mention")
            .toMentionAttribute(viewItem, {
              // Add any other properties that you need.
              link: viewItem.getAttribute("href"),
              userId: viewItem.getAttribute("data-user-id"),
            });

          return mentionAttribute;
        },
      },
      converterPriority: "high",
    });

    // Downcast the model 'mention' text attribute to a view <a> element.
    editor.conversion.for("downcast").attributeToElement({
      model: "mention",
      view: (modelAttributeValue, { writer }) => {
        // Do not convert empty attributes (lack of value means no mention).
        if (!modelAttributeValue) {
          return;
        }

        let className, url;

        if (modelAttributeValue.id.split("")[0] == "#") {
          className = "mention hash-mention";
          let query = modelAttributeValue.id;
          let newQuery = query.split("").slice(1).join("");
          url = `${process.env.REACT_APP_WEB_STAGE_URL}hashtag/feed/?keyword=${newQuery}`;
        } else {
          className = "mention user-mention";
          url = `${process.env.REACT_APP_WEB_STAGE_URL}profile/${modelAttributeValue.userId}`;
        }

        return writer.createAttributeElement(
          "a",
          {
            class: className,
            "data-mention": modelAttributeValue.id,
            "data-user-id": modelAttributeValue.userId,
            href: url,
          },
          {
            // Make mention attribute to be wrapped by other attribute elements.
            priority: 20,
            // Prevent merging mentions together.
            id: modelAttributeValue.uid,
          }
        );
      },
      converterPriority: "high",
    });
  }

  useEffect(() => {
    const modalScroll = modalRef?.current?._element?.firstChild?.firstChild;
    if (modalScroll?.addEventListener) {
      const handleScroll = () => {
        const editorController = document.querySelector(".ck-editor__top");
        const scrollPosition = modalScroll?.scrollTop;

        // console.log("scrollPosition", scrollPosition);
        const transitionTop = () => {
          if (window.innerWidth > 767) {
            if (scrollPosition <= 0) {
              return `${125}px`;
            } else if (scrollPosition > 0 && scrollPosition <= 100) {
              return `${125 - scrollPosition}px`;
            } else {
              return "24px";
            }
          } else {
            if (scrollPosition <= 100) {
              return `${180 - scrollPosition}px`;
            } else {
              return "75px";
            }
          }
        };
        editorController.style.transition = "top 0.05s";
        editorController.style.top = transitionTop();
      };

      modalScroll?.addEventListener("scroll", handleScroll);

      return () => {
        modalScroll?.removeEventListener("scroll", handleScroll);
      };
    }
  }, [modalRef?.current]);

  return (
    <>
      {/* {isLoading && <Loader />} */}
      {isLoading && <AppLoader />}
      <div>
        <Modal
          zIndex={10}
          isOpen={modal}
          size="lg"
          style={{ maxWidth: "1320px" }}
          ref={modalRef}
        >
          <div className="sfffs">
            <div className="create-newsletter">
              <ModalHeader toggle={closeModal}>{title}</ModalHeader>
              <div
                className="wrapper-class"
                style={{ position: "relative", marginTop: "5px" }}
              >
                <div className="fixHeaderBox" style={{ position: "sticky" }}>
                  <div className="row justify-content-between align-items-center">
                    <div className="col-10">
                      <span
                        style={{
                          display: "flex",
                          alignItems: "center",
                          width: "200px",
                        }}
                      >
                        <img
                          src={
                            pageIcon
                              ? pageIcon
                              : user?.data?.profile_img
                              ? user?.data?.profile_img
                              : placeholderUser
                          }
                          className="image-fit"
                          alt=""
                          style={{ width: "36px", height: "36px" }}
                        />
                        <h6 className="name card-control-text-oneline mx-2">
                          {pageName ||
                            user?.data?.first_name +
                              " " +
                              user?.data?.last_name}
                        </h6>
                      </span>
                    </div>
                    <div className="col-2 d-flex justify-content-end">
                      {formStatus ? (
                        <div
                          className="btn btn-blue d-block"
                          style={{
                            width: "100px",
                            height: "34px",
                            padding: "7px 19px",
                          }}
                          onClick={publishNewsletter}
                        >
                          {
                            // source === "home"
                            //   ? "Next Step"
                            //   : editNewsletterData
                            //   ? "Next"
                            //   :
                            "Next"
                          }
                        </div>
                      ) : (
                        <div
                          className="btn disabled-btn-blue d-block"
                          style={{
                            width: "100px",
                            height: "34px",
                            padding: "7px 19px",
                          }}
                        >
                          {
                            // source === "home"
                            //   ? "Next Step"
                            //   : editNewsletterData
                            //   ? "Next"
                            //   :
                            "Next"
                          }
                        </div>
                      )}
                    </div>
                  </div>
                </div>

                <span className="article-imagebox ">
                  <Dropzone
                    handleBannerPicture={handleBannerPicture}
                    bannerImage={bannerImage}
                    deletePicture={deletePicture}
                  />
                </span>
                {/* <div className="autocomplete-wrapper">
                  <Autocomplete
                    inputProps={{ placeholder: "Industry" }}
                    value={industryInput}
                    items={industryData}
                    getItemValue={(item) => item.name}
                    shouldItemRender={(item, value) =>
                      item.name.toLowerCase().indexOf(value.toLowerCase()) > -1
                    }
                    renderMenu={(items, value) => (
                      <div className="dropdown">
                        {items.length === 0 ? `No matches for ${value}` : items}
                      </div>
                    )}
                    renderItem={(item, isHighlighted) => (
                      <div
                        className={`item ${
                          isHighlighted ? "selected-item" : ""
                        }`}
                      >
                        {item.name}
                      </div>
                    )}
                    onChange={(event, val) => setIndustryInput(val)}
                    onSelect={(val, obj) => {
                      setNewsletterData({ ...newsletterData, industry: val });
                      setIndustryInput(val);
                    }}
                    wrapperStyle={{}}
                  />
                </div> */}
                <div className="dropzone writenTextBox ">
                  <div style={{ marginBottom: "20px" }}></div>
                  <Input
                    className="input-type"
                    type="text"
                    style={{ border: "none", fontSize: "30px" }}
                    value={newsletterData.title}
                    onKeyUp={wordLimit(20)}
                    placeholder="Add Title*"
                    onChange={(e) => {
                      setNewsletterData({
                        ...newsletterData,
                        title: e.target.value,
                      });
                      setTextLength(
                        getWordCount(e.target.value) > 20
                          ? 20
                          : getWordCount(e.target.value)
                      );
                    }}
                  />
                  {/* <p className="title-count">{textLength}/20</p> */}
                  <div
                    // className="create-newsLetter"
                    style={{ marginTop: "2rem" }}
                  >
                    {/* <EditorBox
                      convertedContent={convertedContent}
                      setConvertedContent={setConvertedContent}
                      setDbFormat={setDbFormat}
                      setHasText={setHasText}
                      setOnlySpaces={setOnlySpaces}
                      dbFormat={dbFormat}
                    /> */}

                    <CKEditor
                      editor={Editor}
                      config={{
                        placeholder: "Write Here...",
                        extraPlugins: [MentionCustomization],
                        mention: {
                          feeds: [
                            {
                              marker: "@",
                              feed: handleQuery,
                              itemRenderer: customItemRenderer,
                            },
                            {
                              marker: "#",
                              feed: handleHashQuery,
                            },
                          ],
                        },
                      }}
                      onReady={(editor) => {
                        editor.editing.view.change((writer) => {
                          writer.setStyle(
                            "width",
                            "100%",
                            editor.editing.view.document.getRoot()
                          );
                          writer.setStyle(
                            "height",
                            "500px",
                            editor.editing.view.document.getRoot()
                          );
                        });
                        // You can store the "editor" and use when it is needed.
                        console.log("Editor is ready to use!", editor);
                      }}
                      data={convertedContent}
                      onChange={(event, editor) => {
                        setConvertedContent(editor.getData());
                        setNewsletterData({
                          ...newsletterData,
                          content: editor.getData(),
                        });
                        setHasText(true);
                      }}
                      onBlur={(event, editor) => {
                        console.log("Blur.", editor);
                      }}
                      onFocus={(event, editor) => {
                        console.log("Focus.", editor);
                      }}
                      onInit={(editor) => {
                        editor.editing.view.change((writer) => {
                          writer.setStyle(
                            "height",
                            "500px",
                            editor.editing.view.document.getRoot()
                          );
                        });
                      }}
                    />
                  </div>
                </div>
                <div className="preview-button">
                  {" "}
                  <span
                    className="preview"
                    onClick={() => setPreviewModal(!previewModal)}
                  >
                    Preview
                  </span>
                </div>
              </div>
            </div>
          </div>
        </Modal>
        {previewModal && (
          <NewsletterPreviewModal
            modal={previewModal}
            toggle={() => setPreviewModal(!previewModal)}
            title="Preview Newsletter"
            convertedContent={convertedContent}
            newsletterData={newsletterData}
            bannerImage={bannerImage}
            pageIcon={pageIcon}
            pageName={pageName}
          />
        )}
        {discardModalStatus && (
          <DiscardNewsletterModal
            modal={discardModalStatus}
            toggle={closeAllModal}
            close={closeAllModal}
            title="Discard Changes."
            saveDraft={saveDraft}
          />
        )}
        {openShareModal && (
          <ShareModal
            modal={openShareModal}
            toggle={() => setOpenShareModal(!openShareModal)}
            title="Share"
            type="newsletter"
            shareData={shareData}
            source="home"
            toggleCase={toggle}
            atriclePageName={pageName}
            createdAt={createdAt}
            pageID={pageID}
            pageIcon={pageIcon}
          />
        )}
      </div>
    </>
  );
};

export default CreateNewsletterModal;
