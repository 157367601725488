import React, { useEffect } from "react";
import "../pages.scss";
//"../../../../assets/img/edit-icon.svg
import AdIcon from "../../../../assets/img/ads.svg";
import caseIcon from "../../../../assets/img/briefcase2.svg";
import editIcon1 from "../../../../assets/img/edit-icon.svg";
import threeDots from "../../../../assets/img/three_dots.svg";
import { useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import InfiniteScroll from "react-infinite-scroll-component";
import JobModal from "../../../JobSearch/JobModal";
//../components/RecentJobSearches/RecentJobSearches
import { getAllCompanyList } from "../../../../store/profile/profileActions";
// import RecentJobSearches from "../components/RecentJobSearches/RecentJobSearches";
import Chat from "../../../../pages/chat/chat";
import NpMailModal from "../../../../core/components/modal/NpMailModal";
import { FormGroup, Input } from "reactstrap";
import {
  updateJobForm,
  CreateJobAlert,
  DeleteJobAlert,
  createSearchHistory,
  getJobs,
  getJobsDetail,
  jobViewlog,
} from "../../../../store/jobs/jobAction";
import { useLocation, useNavigate } from "react-router-dom";
import RecentJobSearches from "../../../Jobs/components/RecentJobSearches/RecentJobSearches";
import JobCard from "../../../Jobs/components/JobCard/JobCard";
import RecruiterModals from "../../../Jobs/Recruiter/RecruiterModals/RecruiterModals";

const PageJobs = ({ pageID }) => {
  const location = useLocation();
  const isPost = location.state?.isPost;
  const navigate = useNavigate();
  const { getAllJobs, getFeaturedJobs } = useSelector((state) => state.jobs);
  const [showRecruiterModal, setShowRecruiterModal] = useState(false);
  const { isLoading } = useSelector((state) => state.loader);
  const { user } = useSelector((state) => state.auth);
  const [currentCount, setCurrectCount] = useState(0);
  const [totalCount, setTotalCount] = useState(0);
  const [locationOpen, setLocationOpen] = useState(false);
  const [searchFieldValue, setSearchFieldValue] = useState(null);
  const [locationsAPIData, setLocationsAPIData] = useState([]);
  const [searchKey, setSearchKey] = useState("");
  const [datePosted, setDatePosted] = useState(null);
  const [experienceValue, setExperienceValue] = useState(null);
  const [employmentValue, setEmploymentValue] = useState(null);
  const [workSpaceValue, setWorkSpaceValue] = useState(null);
  const [salaryEstimation, setSalaryEstimation] = useState(null);
  const [smartApply, setSmartApply] = useState(false);

  const [isJobAlert, setIsJobAlert] = useState();
  const [locationDetails, setLocationDetails] = useState({
    location: searchFieldValue,
  });
  const [searchValue, setSearchValue] = useState("");
  const [memberInput, setMemberInput] = useState({});
  const [companyList, setCompanyList] = useState([]);
  const [listImage, setListImage] = useState("");
  const [hasMore, setHasMore] = useState(true);
  // const [educationRequirements, setEducationRequirements] = useState("");
  // const [keySkills, setKeySkills] = useState("");
  const [JobActive, setJobActive] = useState(false);
  const [connectText, setConnectText] = useState("Connect");
  const [selectedJob, setSelectedJob] = useState();
  const [npMailModal, setNpMailModal] = useState(false);
  const [jobType, setJobType] = useState("");
  const dispatch = useDispatch();
  const { state } = useLocation();

  const openRecruiterModal = () => {
    setShowRecruiterModal(!showRecruiterModal);
    dispatch(updateJobForm({ jobType: "new" }));
  };
  useEffect(() => {
    // dispatch(getJobs({ limit: 10, skip: currentCount }, currentCount)).then(
    //   (res) => {
    //     setTotalCount(res?.totalItem);
    //     setIsJobAlert(res?.jobAlert);
    //   }
    // );

    if (state?.isOpenCreatePost) {
      openRecruiterModal();
    }
    if (state?.isFeatured) {
      getFilteredJobs();
    } else if (pageID) {
      setJobType("");
      dispatch(
        getJobs(
          {
            company_id: pageID,
            limit: 10,
            skip: currentCount,
            filter: "recommended",
          },
          currentCount
        )
      ).then((res) => {
        setTotalCount(res?.totalItem);
        setIsJobAlert(res?.jobAlert);
      });
    } else {
      setJobType("");
      dispatch(
        getJobs(
          { limit: 10, skip: currentCount, filter: "recommended" },
          currentCount
        )
      ).then((res) => {
        setTotalCount(res?.totalItem);
        setIsJobAlert(res?.jobAlert);
      });
    }
  }, []);
  useEffect(() => {
    if (memberInput) {
      dispatch(getAllCompanyList({ search: memberInput.val }))
        .then((res) => {
          if (res?.success === true) {
            setCompanyList(res?.data);
          } else setCompanyList([]);
        })
        .catch(() => {
          setCompanyList([]);
        });
    }
  }, [memberInput]);

  useEffect(() => {
    if (getAllJobs?.length < totalCount) setHasMore(true);
    else setHasMore(false);
  }, [currentCount, totalCount]);
  useEffect(() => {
    if (searchKey) {
      setJobType("");
      setCurrectCount(0);
      dispatch(
        getJobs(
          {
            limit: 10,
            skip: 0,
            search: searchKey,
            location: searchFieldValue,
            date_posted: datePosted,
            company: memberInput?.val ? memberInput?.val : null,
            experience: experienceValue,
            employment_type: employmentValue,
            workspace_type: workSpaceValue,
            salary: salaryEstimation,
            smart_apply: smartApply,
          },
          currentCount
        )
      ).then((res) => {
        setTotalCount(res?.totalItem);
        setIsJobAlert(res?.jobAlert);
      });
    } else {
      setCurrectCount(0);
      if (!state?.isFeatured) {
        setJobType("");
        dispatch(
          getJobs({ limit: 10, skip: 0, filter: "recommended" }, currentCount)
        ).then((res) => {
          setTotalCount(res?.totalItem);
          setIsJobAlert(res?.jobAlert);
        });
      } else getFilteredJobs();
    }
  }, [
    searchKey,
    searchFieldValue,
    memberInput,
    datePosted,
    experienceValue,
    employmentValue,
    workSpaceValue,
    salaryEstimation,
    smartApply,
  ]);

  const {
    title,
    company,
    // listImage,
    employmentType,
    companyLocation,
    companyVisible,
    companyId,
    numberOfVacancies,
    workspacetype,
  } = location.state || {};

  const getFilteredJobs = () => {
    setJobType("featured");
    setCurrectCount(0);
    dispatch(
      getJobs(
        { limit: 30, skip: currentCount, filter: "featured" },
        currentCount,
        "featured"
      )
    ).then((res) => {
      // setCardData(res?.data);
      setTotalCount(res?.totalItem);
      setIsJobAlert(res?.jobAlert);
    });
  };

  const handleSwitchChange = (e) => {
    const data = {
      search: searchKey ? searchKey : null,
      workspace_type: workSpaceValue ? workSpaceValue : null,
      employment_type: employmentValue ? employmentValue : null,
      experience: experienceValue ? experienceValue : null,
      company: memberInput ? memberInput?.value : null,
      date_posted: datePosted ? datePosted : null,
      location: searchFieldValue ? searchFieldValue : null,
    };
    if (e.target.checked) {
      dispatch(CreateJobAlert(data)).then((res) => {
        if (res?.success) {
          setIsJobAlert({ id: res?.data?.id });
          toast.success(res.message, {
            position: toast.POSITION.BOTTOM_LEFT,
          });
        }
      });
    } else {
      dispatch(DeleteJobAlert(isJobAlert?.id)).then((res) => {
        if (res?.success) {
          toast.success(res.message, {
            position: toast.POSITION.BOTTOM_LEFT,
          });
          setIsJobAlert(false);
        }
      });
    }
  };
  const handleSearchKey = (search) => {
    if (search)
      dispatch(createSearchHistory({ search, type: "job", type_id: null }));
    setSearchKey(search);
  };

  const loadMore = () => {
    const newSkip = currentCount + 10;
    const skipforsearch = currentCount + 100;

    if (searchKey) {
      dispatch(
        getJobs({ limit: 100, skip: skipforsearch, search: searchKey }, newSkip)
      ).then((res) => {
        setTotalCount(res.totalItem);
        setCurrectCount(newSkip);
        setIsJobAlert(res?.jobAlert);
      });
    } else
      dispatch(getJobs({ limit: 10, skip: newSkip }, newSkip)).then((res) => {
        setTotalCount(res.totalItem);
        setCurrectCount(newSkip);
        setIsJobAlert(res?.jobAlert);
      });
  };

  const JobCheck = () => {
    setJobActive(!JobActive);
  };

  const handleJobCardClick = (jobData) => {
    dispatch(getJobsDetail(jobData?.id)).then((res) => {
      try {
        dispatch(jobViewlog(jobData?.id));
      } catch (error) {
        console.log(error);
      }
      setSelectedJob(res?.data);
      window.scrollTo(0, 0);
    });
  };

  return (
    <>
      {/* <div className="left-sidebar bg-white">  */}
      {/* <div className=" leftTop-sidebar mb-20">  */}
      <div
        className="d-flex justify-content-between top-buttons"
        style={{
          whiteSpace: "nowrap",
          overflow: "hidden",
          textOverflow: "ellipsis",
        }}
      >
        {/* <div className="d-flex justify-content-betweens">
                            <img src={editIcon1} alt="my_jobs" />{" "}
                            <span
                                className="sub-text ml-10"
                                // onClick={() => navigate("/post-job")}
                                onClick={() => openRecruiterModal()}
                            >
                                Post a Job
                            </span>
                        </div> */}
        {/* <div
                            className="d-flex justify-content-betweens"
                            onClick={() => navigate("/myjobs")}
                        >
                            <img src={caseIcon} alt="my_jobs" />{" "}
                            <span className="sub-text ml-10">My Jobs</span>
                        </div> */}
        {/* <div
                            className="d-flex justify-content-betweens"
                            onClick={() => getFilteredJobs()}
                        >
                            <img src={AdIcon} alt="featured_jobs" />
                            <span
                                className="sub-text ml-10"
                                style={{ color: jobType == "featured" ? "black" : "unset" }}
                            >
                                Featured Jobs
                            </span>
                        </div> */}
        {/* <div className="d-flex justify-content-betweens">
                            <img
                                src={threeDots}
                                alt="my_jobs"
                                onClick={() => alert("Required Figma")}
                                style={{ height: "15px", marginTop: "3px" }}
                            />{" "}
                        </div> */}
        {/* </div> */}
        {/* {searchKey && (
                        <div className="job-alert">
                            <div>
                                <p className="search-value">
                                    {searchKey}
                                    {searchFieldValue && ` in ${searchFieldValue}`}
                                </p>

                                <p className="search-num">{totalCount} search result</p>
                            </div>
                            <div className="d-flex gap-3">
                                <p>Alert on</p>
                                <FormGroup switch>
                                    <Input
                                        type="switch"
                                        role="switch"
                                        checked={isJobAlert ? true : false}
                                        onChange={(e) => handleSwitchChange(e)}
                                    />
                                </FormGroup>
                            </div>
                        </div>
                    )} */}
        {/* {!searchKey && !jobType && (
                        <>
                            <RecentJobSearches />
                        </>
                    )} */}
      </div>
      {/* {!searchKey && !jobType && (
                    <span className="job-recommend">Recommended for you</span>
                )} */}
      <InfiniteScroll
        dataLength={
          jobType == "featured" ? getFeaturedJobs?.length : getAllJobs?.length
        }
        next={() => loadMore()}
        hasMore={hasMore}
        style={{ overflow: "hidden" }}
      >
        {(
          jobType === "featured" ? getFeaturedJobs?.length : getAllJobs?.length
        ) ? (
          <div>
            {jobType !== "featured"
              ? getAllJobs?.map((jobData, idx) => (
                  <JobCard
                    key={idx}
                    onClick={() => handleJobCardClick(jobData)}
                    jobData={jobData}
                    selectedJob={selectedJob}
                  />
                ))
              : getFeaturedJobs?.map((jobData, idx) => (
                  <JobCard
                    key={idx}
                    onClick={() => handleJobCardClick(jobData)}
                    jobData={jobData}
                    selectedJob={selectedJob}
                  />
                ))}
          </div>
        ) : (
          <div className="row">
            <p style={{ textAlign: "center" }} className="my-30">
              <b>No jobs</b>
            </p>
          </div>
        )}
      </InfiniteScroll>
      {/* </div> */}
      <ToastContainer />
      <Chat />
      {showRecruiterModal && (
        <RecruiterModals
          toggle={() => openRecruiterModal()}
          isOpen={showRecruiterModal}
          title={title}
          company={company}
          // listImage,
          employmentType={employmentType}
          companyLocation={companyLocation}
          companyVisible={companyVisible}
          companyId={companyId}
          numberOfVacancies={numberOfVacancies}
          workspacetype={workspacetype}
        />
      )}
      {JobActive && (
        <JobModal
          jobDetail={selectedJob}
          id={selectedJob?.id}
          toggle={() => JobCheck()}
          modal={JobActive}
          setSelectedJob={setSelectedJob}
          selectedJob={selectedJob}
        />
      )}
      {npMailModal && (
        <NpMailModal
          modal={npMailModal}
          toggle={() => setNpMailModal(!npMailModal)}
          profileData={{
            id: selectedJob?.user?.id,
            first_name: selectedJob?.user?.first_name,
            last_name: selectedJob?.user?.last_name,
            profile_img: selectedJob?.user?.profile_img,
            profile_headline: selectedJob?.user?.profile?.profile_headline,
          }}
        />
      )}
    </>
  );
};

export default PageJobs;
