import { call } from "./apiCall";

class CampaignAPI {
  createCampaign(postData) {
    return new Promise((resolve, reject) => {
      const token = localStorage.getItem("accessToken");
      (async () => {
        try {
          const res = await call(
            postData.id ? "patch" : "post",
            `api/v1/feed/campaign`,
            null,
            postData,
            token
          );
          resolve(res.data);
        } catch (err) {
          console.log(err);
          reject(err);
        }
      })();
    });
  }
  getCampaign(params) {
    return new Promise((resolve, reject) => {
      const token = localStorage.getItem("accessToken");
      (async () => {
        try {
          const res = await call(
            "get",
            `api/v1/feed/campaign`,
            params,
            null,
            token
          );
          if (res.data.success === true) {
            resolve(res.data);
          }
          if (res.data.success === false) {
            resolve(res.data);
          }
        } catch (err) {
          console.log(err);
          reject(err);
        }
      })();
    });
  }

  getCampaignStatistics(params) {
    return new Promise((resolve, reject) => {
      const token = localStorage.getItem("accessToken");
      (async () => {
        try {
          const res = await call(
            "get",
            `api/v1/feed/campaign-statistics`,
            params,
            null,
            token
          );
          if (res.data.success === true) {
            resolve(res.data);
          }
          if (res.data.success === false) {
            resolve(res.data);
          }
        } catch (err) {
          console.log(err);
          reject(err);
        }
      })();
    });
  }

  getCampaignLogCount(campaign_id) {
    let token = localStorage.getItem("accessToken");
    return new Promise((resolve, reject) => {
      (async () => {
        try {
          const res = await call(
            "get",
            `api/v1/feed/feed-count?type=campaign&&campaign_id=${campaign_id}`,
            null,
            null,
            token
          );
          if (res.data.success === true) {
            resolve(res.data);
          }
          if (res.data.success === false) {
            resolve(res.data);
          }
        } catch (err) {
          console.log(err);
          reject(err);
        }
      })();
    });
  }

  addFeedback(data) {
    return new Promise((resolve, reject) => {
      const token = localStorage.getItem("accessToken");
      (async () => {
        try {
          const res = await call(
            "post",
            `api/v1/feed/add-feedback`,
            null,
            data,
            token
          );
          resolve(res.data);
        } catch (err) {
          console.log(err);
          reject(err);
        }
      })();
    });
  }

  deleteCampaign(id) {
    return new Promise((resolve, reject) => {
      const token = localStorage.getItem("accessToken");
      (async () => {
        try {
          const res = await call(
            "delete",
            `api/v1/feed/campaign/${id}`,
            null,
            null,
            token
          );
          resolve(res.data);
        } catch (err) {
          console.log(err);
          reject(err);
        }
      })();
    });
  }
}

export const campaignAPI = new CampaignAPI();
