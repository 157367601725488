import React, { useEffect, useState } from "react";
import "./GifPickerStyles.css";
import { call } from "../../../api/apiCall";

const GiphyPicker = ({ onSelect }) => {
  const [gifs, setGifs] = useState([]);
  const [search, setSearch] = useState("");

  useEffect(() => {
    fetchGifs(search);
  }, [search]);

  const fetchGifs = async (search) => {
    try {
      const res = await call("get", `api/v1/gifs`, null, null);
      setGifs(res?.data?.data || []);
    } catch (error) {
      console.error("Error fetching GIFs:", error);
    }
  };

  const handleSelect = (selectedGif) => {
    console.log("Selected GIF:", selectedGif);
    onSelect(selectedGif);
  };

  return (
    <div className="giphy-picker-container">
      <input
        type="text"
        value={search}
        onChange={(e) => setSearch(e.target.value)}
        className="giphy-picker-input"
        placeholder="Search Gif..."
      />
      {/* <button onClick={handleSearch} className="giphy-picker-button">
        Search
      </button> */}
      {gifs?.length ? (
        <div className="giphy-picker-results">
          {gifs.map((gif) => (
            <img
              key={gif.id}
              src={gif.url}
              alt={gif.title}
              className="giphy-picker-image"
              onClick={() => handleSelect(gif)}
            />
          ))}
        </div>
      ) : (
        <div className="no-data">No Gifs Found</div>
      )}
    </div>
  );
};

export default GiphyPicker;
