import React, { useEffect, useState } from "react";
import backIcon from "../../../assets/img/Back_Icons.svg";
import card1 from "../../../assets/img/Credit1.svg";
import card2 from "../../../assets/img/Credit2.svg";
import FormInput from "../../../core/components/Inputs/FormInput";
import DatePickerCustom from "../../../core/components/DatePicker/DatePickerReact";
import { Input } from "reactstrap";
import { useSelector } from "react-redux";
import { profileApi } from "../../../api/profileAPI";
import CustomModel from "./CustomModal";
import checkIcon from "../../../assets/img/checkGreen.svg";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Navbar from "../../../pages/navbar/navbar";
const ScreeningPayment = () => {
  const [confirmPayment, setConfirmPayment] = useState(false);
  const token = localStorage.getItem("accessToken");
  const backgroundData = useSelector((state) => state.backgroundScreen);

  const Tax = () => {
    const education = state?.data?.education?.length * 54;
    const employment = state?.data?.employment?.length * 54;
    const criminal = Object.keys(state?.data?.criminal || {}).length ? 54 : 0;
    return parseInt(
      Math.round(((education + employment + criminal) * 18) / 100).toFixed(2)
    );
  };

  const { state } = useLocation();
  useEffect(() => {
    if (!state) navigate("/background-service");
  }, []);

  const totalAmount = () => {
    const education = state?.data?.education?.length * 54;
    const employment = state?.data?.employment?.length * 54;
    const criminal = Object.keys(state?.data?.criminal || {}).length ? 54 : 0;
    const tax = Tax();
    const total = education + employment + criminal + tax;
    const discount = Math.round(total * 0.2).toFixed(2);
    return parseInt(total - discount);
  };

  const handleSubmit = async () => {
    const processedBackgroundData = JSON.parse(JSON.stringify(backgroundData));

    if (
      processedBackgroundData.basicInfo &&
      processedBackgroundData.basicInfo.document
    ) {
      processedBackgroundData.basicInfo.document =
        processedBackgroundData.basicInfo.document.map((doc) => doc?.url);
    }

    if (Array.isArray(processedBackgroundData.education)) {
      processedBackgroundData.education = processedBackgroundData.education.map(
        (edu) => {
          if (edu.document) {
            edu.document = edu.document.map((doc) => doc?.url);
          }
          return edu;
        }
      );
    }

    if (Array.isArray(processedBackgroundData.employment)) {
      processedBackgroundData.employment =
        processedBackgroundData.employment.map((emp) => {
          if (emp.document) {
            emp.document = emp.document.map((doc) => doc?.url);
          }
          return emp;
        });
    }
    delete backgroundData?.screeningList;
    delete processedBackgroundData.screeningList;
    const res = await profileApi.postBackgroundDetail(
      {
        ...processedBackgroundData,
        payment: {
          tax: 18,
          discount: 20,
          totalAmount: totalAmount(),
        },
      },
      token
    );

    if (res && res.success) {
      setConfirmPayment(true);
    }
  };

  const navigate = useNavigate();
  return (
    <>
      <Navbar isJobs={false} />

      <div className="px-5 py-3 bg-white">
        <div className="container">
          <div className="d-flex ">
            <img
              src={backIcon}
              width={25}
              onClick={() =>
                navigate("/order-preview", {
                  state: { data: state?.data },
                })
              }
            />
            <h4 className="mx-3">Payment</h4>
          </div>
          <div className="row">
            <div className="col-8">
              <div className="mt-4 border">
                <div className="p-3" style={{ background: "#F8F8FA" }}>
                  <h6>Payment Method</h6>
                </div>
                <form className="p-2">
                  <div className="row">
                    <div className="col-12">
                      <FormInput label={"Cardholder Name"} />
                    </div>
                    <div className="row">
                      <div className="col-6">
                        <FormInput label={"Card Number"} />
                      </div>
                      <div className="col-3 mt-3">
                        <label className="form-label">Date</label>
                        <DatePickerCustom />
                      </div>
                      <div className="col-3">
                        <FormInput label={"CVV"} />
                      </div>
                    </div>
                  </div>
                  <div className="mt-3">
                    <Input type="checkbox" /> <span>Save this card?</span>
                  </div>
                </form>

                <div className="mt-4 border-top">
                  <div className="p-3" style={{ background: "#F8F8FA" }}>
                    <h6>Saved Cards</h6>
                  </div>
                  <form className="p-2">
                    <div className="row p-2">
                      <div className="col-12 d-flex justify-content-between align-items-center">
                        <div className="d-flex align-items-center">
                          <Input
                            type="radio"
                            name="card"
                            style={{ width: "25px", height: "25px" }}
                          />
                          <div className="d-flex flex-column mx-3">
                            <p>Ending in ...6547</p>
                            <span>Exp date 12/22</span>
                          </div>
                        </div>
                        <div className="">
                          <img src={card1} width={50} />
                        </div>
                      </div>
                      <hr />
                      <div className="col-12 d-flex justify-content-between align-items-center">
                        <div className="d-flex align-items-center">
                          <Input
                            type="radio"
                            name="card"
                            style={{ width: "25px", height: "25px" }}
                          />
                          <div className="d-flex flex-column mx-3">
                            <p>Ending in ...6547</p>
                            <span>Exp date 12/22</span>
                          </div>
                        </div>
                        <div className="">
                          <img src={card2} width={50} />
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>

            <div className="col-4">
              <div className="mt-4">
                <div className="row">
                  <div className="col-6">
                    <p className="text-dark fw-bold">Employment Check</p>
                  </div>
                  <div className="col-6 text-end">
                    ${state?.data?.employment.length * 54}.00
                  </div>
                </div>
                <div className="row mt-3">
                  <div className="col-6">
                    <p className="text-dark fw-bold">Education Check</p>
                  </div>
                  <div className="col-6 text-end">
                    ${state?.data?.education.length * 54}.00
                  </div>
                </div>
                <div className="row mt-3">
                  <div className="col-6">
                    <p className="text-dark fw-bold">Criminal / WorldScan</p>
                  </div>
                  <div className="col-6 text-end">
                    $
                    {Object.keys(state?.data?.criminal || {}).length
                      ? "54"
                      : "0"}
                    .00
                  </div>
                </div>
                <div className="row mt-3">
                  <div className="col-6">
                    <p className="fw-bold">Tax(18%)</p>
                  </div>
                  <div className="col-6 text-end">${Tax()}</div>
                </div>

                <div className="row mt-3">
                  <div className="col-6">
                    <p className="fw-bold">Discount</p>
                  </div>
                  <div className="col-6 text-end">
                    -${Math.round(totalAmount() * 0.2).toFixed(2)}
                  </div>
                </div>

                <div
                  className="mt-3"
                  style={{
                    width: "100%",
                    height: "1px",
                    background: "#D1DFFF",
                  }}
                ></div>

                <div className="row mt-3">
                  <div className="col-6">
                    <p className="text-dark fw-bold">Total</p>
                  </div>
                  <div className="col-6 text-end">$ {totalAmount()}</div>
                </div>

                <p className="mt-4">
                  Sorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc
                  vulputate libero et velit interdum, ac aliquet odio mattis.
                  Class aptent taciti sociosqu ad litora torquent per conubia
                  nostra, per
                  <span>
                    <Link to="/">Terms and Conditions</Link>
                    <Link to="/">& Privacy Policy</Link>
                  </span>
                </p>

                <button
                  className="btn btn-blue mt-3 w-100"
                  onClick={handleSubmit}
                >
                  Confirm and Pay
                </button>
              </div>
            </div>
          </div>
        </div>

        {confirmPayment && (
          <CustomModel
            modal={confirmPayment}
            toggle={() => setConfirmPayment(!confirmPayment)}
            title={"Confirmation"}
            message={
              "An email with detailed information is en route. Our team will begin processing, and we'll reach out if any additional info is required. Expect approximately 10 working days. You can monitor your screening request status in your screening dashboard. We're redirecting you there now!"
            }
            handleSubmit={() => navigate("/background-report")}
            buttonTitle={"Back to dashboard"}
            bodyTitle={"Payment Confirmed"}
            bodyImage={checkIcon}
          />
        )}
      </div>
    </>
  );
};

export default ScreeningPayment;
