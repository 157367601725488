import React, { useState, useEffect } from "react";
import "./explore.scss";
import Explore from "./Explore.jsx";
import { getExploreData } from "../../store/explore/exploreActions";
import { useDispatch } from "react-redux";
import { getLandingPageData } from "../../store/landing/landingAction";

function ExploreContainer() {
  const [APIdata, setAPIdata] = useState([]);
  const [tabs, setTabs] = useState("");
  const [subTab, setSubTab] = useState("");
  const [tabsList, setTabsList] = useState([]);
  const [exploreData, setExploreData] = useState();

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getLandingPageData({ title: "explore" })).then((res) => {
      setExploreData(res);
    });
  }, []);

  useEffect(() => {
    let data = tabs ? { category_id: tabs } : null;
    setSubTab("");
    dispatch(getExploreData(data))
      .then((resp) => {
        setAPIdata(resp.data);
        if (!tabs) {
          setTabsList(resp.data);
        }
      })
      .catch((err) => {
        console.log("error!!", err);
      });
  }, [tabs]);

  return (
    <>
      <Explore
        APIdata={APIdata}
        tabs={tabs}
        setTabs={setTabs}
        tabsList={tabsList}
        exploreData={exploreData}
        subTab={subTab}
        setSubTab={setSubTab}
      />
    </>
  );
}

export default ExploreContainer;
