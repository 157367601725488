import React from "react";
import moment from "moment";
import CreateEventModal from "../components/createEvent";
import './EventDetailsWebView.scss';

const EventDetailsWebView = (props) => {
  const {
    event_detail,
    openCreateModal,
    setOpenCreateModal,
    editEventData,
    webViewEditEventDataHandler,
    id,
    attendHandle,
    user,
    webViewShareEventDataHandler,
    pinHandleRelatedEvent,
    webViewRedirectNetworksection,
    webViewHandlerCopyLink
  } = props


  return (
    // <div className="main-bg">
    <div className="eventdetail-webview">
      {/* <div className="container"> */}
      <div className="d-flex">
        <div className="d-flex">
          <div className="main-center event-details">
            <div className="wrapper border">
              <div className="wrapper">
                <div className="box-img">
                  {/* <div className="img"> */}
                  {event_detail[0]?.media &&
                    <img
                      src={event_detail[0].media}
                      className="img-fluid"
                      alt=""
                    />}
                  {/* </div> */}
                  <div className="calendar">
                    <div className="day">
                      {moment(event_detail[0]?.event_time).format("dddd")}
                    </div>
                    <div className="date">
                      {moment(event_detail[0]?.event_time).format("MMM D")}{" "}
                    </div>
                  </div>
                </div>
              </div>
              <div className="section p-2 bg-white">

                <div className="heading">
                  <div className="d-flex flex-column">
                    <div className="col-lg-6 mb-2">
                      <h2>{event_detail[0]?.title}</h2>
                    </div>
                    <div className="virtual">
                      <div className="d-flex">
                        <div className="col-12">
                          <div className="btn">
                            {event_detail[0]?.event_type} Event
                          </div>
                          {event_detail[0]?.event_link ? (
                            <div className="link" onClick={() => webViewHandlerCopyLink({ link: event_detail[0]?.event_link })}>
                              {event_detail[0]?.event_link}
                              <span className="copy"></span>
                            </div>
                          ) : (
                            ""
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-12 mt-2">
                      <div className="d-flex gap-2 justify-content-between align-items-center">
                        {user?.data?.id === event_detail[0]?.User?.id
                          ? (<div className="btn eventBtn" onClick={() => webViewEditEventDataHandler({ id })}>
                            Edit Event
                          </div>)
                          : event_detail[0]?.Attend_Event?.status
                            ? (<div className="btn-light btn eventBtn" onClick={() => attendHandle(event_detail[0]?.id, "attendFalse")}>
                              Attending
                            </div>)
                            : (<div className="btn eventBtn" onClick={() => attendHandle(event_detail[0]?.id, "attendTrue")}>
                              Attend
                            </div>)}
                        <div className="d-flex">
                          <div className=" btn border-btn bell-icon" style={{ border: "2px solid #0432a3" }}></div>
                          <div className="share-icon btn ms-2"
                            onClick={() => webViewShareEventDataHandler({ id })}
                            style={{ border: "2px solid #0432a3" }}></div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="content">
                  <div className="d-flex mt-2">
                    <div className="col-lg-12">
                      <div className="content">
                        <p className="description-text">{event_detail[0]?.description}</p>
                        {/* <div className="work">#Works</div> */}
                      </div>
                      <div className="technology mt-2 mb-2">
                        <ul>
                          {event_detail[0]?.industry && <li>
                            <span className="task"></span>
                            <span>{event_detail[0]?.industry}</span>
                          </li>}
                          {event_detail[0]?.event_time && <li>
                            <span className="calender"></span>
                            <span>{moment(event_detail[0]?.event_time).format(
                              "ddd, MMM DD, YYYY"
                            )}{" "}
                              at{" "}
                              {moment(event_detail[0]?.event_time).format(
                                "hh:mm A z"
                              )}</span>
                          </li>}
                          {event_detail[0]?.location && <li>
                            <span className="location"></span>
                            <span>{event_detail[0]?.location}</span>
                          </li>}
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="d-flex events-add">
                  <div className="col-lg-6">
                    <div className="people-pic" style={{ position: 'relative', left: '20px' }}>
                      <div className="d-flex me-2">
                        <div className="profiles-img">
                          {event_detail[0]?.Event_Participant.slice(0, 4)?.map(
                            (data, idx) => {
                              return (
                                <span key={idx}>
                                  <img
                                    src={data?.user?.profile_img ? data.user.profile_img : "https://img.freepik.com/free-photo/pleasant-looking-serious-man-stands-profile-has-confident-expression-wears-casual-white-t-shirt_273609-16959.jpg"}
                                    className="image-fit"
                                    alt=""
                                  />
                                </span>
                              );
                            }
                          )}

                          {event_detail[0]?.Event_Participant?.length > 4 ? (
                            <span>
                              +
                              {Object.keys(event_detail[0]?.Event_Participant)
                                .length - 4}
                            </span>
                          ) : (
                            <></>
                          )}
                        </div>
                      </div>

                      <div>
                        {" "}
                        {event_detail[0]?.Event_Participant?.length > 0 ? (
                          " Attending"
                        ) : (
                          <></>
                        )}
                      </div>

                    </div>
                  </div>

                </div>

                <div className="d-flex">
                  <div className="col-lg-12">
                    <h4 className="related mt-2 mb-2">Related events</h4>
                    <div className="networks">
                      <div className="wrapper">
                        <div className="events">
                          <div className="d-flex flex-wrap event-wrapper">

                            {event_detail[0]?.Related_Events?.map((item, idx) => (
                              <div className="col-6 mb-2 card-content" key={idx}>
                                <div className="card col-12">
                                  <div className="img-section">
                                    <div className="card-img">
                                      {item?.media &&
                                        <img
                                          src={item.media}
                                          className="img-fluid"
                                          alt=""
                                        />}
                                    </div>
                                    <div className="tag" style={{ color: item?.event_type === 'physical' ? "#F68A18" : "#0432A3" }}>{item?.event_type}</div>
                                    {item?.Pinned_Event?.status ? (
                                      <div
                                        className="unpin"
                                        onClick={() =>
                                          pinHandleRelatedEvent(
                                            event_detail[0].id,
                                            item.id,
                                            "pinFalse"
                                          )
                                        }
                                      ></div>
                                    ) : (<div className="pin"
                                      onClick={() =>
                                        pinHandleRelatedEvent(
                                          event_detail[0].id,
                                          item.id,
                                          "pinTrue"
                                        )
                                      }></div>)}
                                  </div>
                                  <div className="details-section me-2 ms-2 mt-2 mb-2">
                                    <div className="header">
                                      <h4 onClick={() => webViewRedirectNetworksection({ id: item.id, type: "event" })}>{item?.title}</h4>
                                    </div>
                                    {item?.event_time &&
                                      <div className="date">
                                        <div className="calender-icon"></div>
                                        <span>
                                          {moment(item?.event_time).format(
                                            "ddd, MMM DD, YYYY"
                                          )}{" "}
                                          at{" "}
                                          {moment(item?.event_time).format(
                                            "hh:mm A z"
                                          )}
                                        </span>
                                      </div>}
                                    {item?.location &&
                                      <div className="location">
                                        <div className="icon"></div>
                                        <span>
                                          {item?.location}
                                        </span>
                                      </div>}

                                    <div className="people-pic" style={{ marginTop: 'auto' }}>
                                      <div className="d-flex me-2">
                                        <div className="d-flex">
                                          {item?.Event_Participant.slice(0, 4)?.map(
                                            (data, idx) => {
                                              return (
                                                <span key={idx}>
                                                  <img
                                                    src={data?.user?.profile_img ? data.user.profile_img : "https://img.freepik.com/free-photo/pleasant-looking-serious-man-stands-profile-has-confident-expression-wears-casual-white-t-shirt_273609-16959.jpg"}
                                                    className="image-fit"
                                                    alt=""
                                                  />
                                                </span>
                                              );
                                            }
                                          )}

                                          {item?.Event_Participant?.length > 4 ? (
                                            <span>
                                              +
                                              {Object.keys(item?.Event_Participant)
                                                .length - 4}
                                            </span>
                                          ) : (
                                            <></>
                                          )}
                                        </div>
                                      </div>

                                      <div>
                                        {" "}
                                        {item?.Event_Participant?.length > 0 ? (
                                          " Attending"
                                        ) : (
                                          <></>
                                        )}
                                      </div>
                                      {/* <div className="d-flex" style={{ position: 'relative', left: '15px' }}>

                                          <span>
                                            <img
                                              src="https://img.freepik.com/free-photo/pleasant-looking-serious-man-stands-profile-has-confident-expression-wears-casual-white-t-shirt_273609-16959.jpg"
                                              className="image-fit"
                                              alt=""
                                            />
                                          </span>
                                          <span>
                                            <img
                                              src="https://img.freepik.com/free-photo/pleasant-looking-serious-man-stands-profile-has-confident-expression-wears-casual-white-t-shirt_273609-16959.jpg"
                                              className="image-fit"
                                              alt=""
                                            />
                                          </span>
                                          <span>
                                            <img
                                              src="https://img.freepik.com/free-photo/pleasant-looking-serious-man-stands-profile-has-confident-expression-wears-casual-white-t-shirt_273609-16959.jpg"
                                              className="image-fit"
                                              alt=""
                                            />
                                          </span>
                                          <span>
                                            <img
                                              src="https://img.freepik.com/free-photo/pleasant-looking-serious-man-stands-profile-has-confident-expression-wears-casual-white-t-shirt_273609-16959.jpg"
                                              className="image-fit"
                                              alt=""
                                            />
                                          </span>

                                          <span>+40</span>

                                        </div>

                                        <div className="ps-3 subText">Attending</div> */}

                                    </div>

                                    {/* <div className="d-flex align-items-center mt-2 mb-2">
                                      {item?.Attend_Event?.status ?
                                        <div className="btn btn-light text-blue" onClick={() =>
                                          attendHandle(item.id, "attendFalse")}>
                                          Attending
                                        </div>
                                        :
                                        <div className="btn btn-light text-blue" onClick={() => attendHandle(item.id, "attendTrue")}>
                                          Attend
                                        </div>
                                      }
                                      <div onClick={() => webViewShareEventDataHandler({ id: item.id })} className="share-icon"></div>
                                    </div> */}
                                  </div>
                                </div>
                              </div>
                            ))}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div >
          {/* </div > */}

          {openCreateModal && (
            <CreateEventModal
              modal={openCreateModal}
              toggle={() => setOpenCreateModal(!openCreateModal)}
              title="Create Event"
              editEventData={editEventData}
            />
          )}
        </div >
      </div >
    </div >
    // </div>

  );
};
export default EventDetailsWebView;
