import React from "react";

const Prizing = (props) => {
  const { headingData } = props;
  return (
    <div className="accordion accordion-flush" id="accordion">
      {headingData?.map((item, index) => {
        return (
          <div className="accordion-item" key={index}>
            <div id={`heading${index}`}>
              <h5 className="accordion-header">
                <button
                  className="accordion-button collapsed"
                  data-bs-toggle="collapse"
                  data-bs-target={`#collapse${index}`}
                  aria-expanded="false"
                  aria-controls={`collapse${index}`}
                  style={{ fontSize: "30px" }}
                >
                  {item.heading}
                </button>
              </h5>
            </div>
            <div
              id={`collapse${index}`}
              className={
                "accordion-collapse collapse " + (index == 0 ? "show" : "")
              }
              aria-labelledby={`heading${index}`}
              data-bs-parent="#accordion"
            >
              <div
                className="accordion-body under-prize accordion-flush"
                id="child1"
              >
                {item?.data.map((itm, idx) => {
                  return (
                    <div className="accordion-item">
                      <div
                        className="accordion-header"
                        style={{ fontWeight: "bolder" }}
                      >
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target={`#collapseOne${itm.id}`}
                          style={{ fontSize: "19px" }}
                        >
                          {itm?.question}
                        </button>
                      </div>
                      <div
                        className="accordion-body accordion-collapse collapse in"
                        data-bs-parent={`#child${itm.id}`}
                        id={`collapseOne${itm.id}`}
                        style={{ fontSize: "15px", fontWeight: "400px" }}
                      >
                        {"    "}
                        {itm?.answer}
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default Prizing;
