import React from "react";
import "./loginPortal.scss";
import signupimage from "../../../assets/img/signup-portal.svg";
import logo from "../../../assets/img/logowithname.svg";
import divider from "../../../assets/img/Or-divider.svg";
import email from "../../../assets/img/a.svg";
import pass from "../../../assets/img/shield-security.svg";
import pagePlaceholder from "../../../assets/img/page-placeholder.svg";
import { Link } from "react-router-dom";
import FormInput from "../../../core/components/Inputs/FormInput";
import tickIcon from "../../../assets/img/Check-pass.svg";
import clap from "../../../assets/img/welcome_clapping.svg"
import LoginModal from "./LoginModal";
import { ToastContainer } from "react-toastify";
const LoginPortal = (props) => {
  const {
    data,
    param,
    error,
    formData,
    navigate,
    handleChange,
    handleSubmit,
    handleValidation,
    loginWithNp,
    setLoginWithNp,
    handleNPSubmit,
    errorMessage,
  } = props;

  return (
    <div className="login-portal">
      <div className="col-4 left">
        <img
          src={data?.background_img || signupimage}
          alt="signupimage"
          className="center"
        />
      </div>
      <div className="col-8 right" style={{ backgroundColor: "white", overflow:"auto" }}>
        <div className="me-5 text-end" style={{ marginTop: "47px" }}>
          <p>
            Not registered ?
            <Link
              to={`/tp/portal/${param}/signup`}
              style={{ marginLeft: "4px" }}
            >
              <span
                // className="font-700"
                style={{ color: "#0432a3" }}
              >
                Create an account
              </span>
            </Link>
          </p>
        </div>
        <div className="login-form">
          <form className="auth-form">
            <p>
              Welcome back! {" "}
              <img src={clap} alt="clap"/>
            </p>
            <h3 className="form-heading">Login to your account</h3>
            <h4 className="sub-heading my-4">
              Login with{" "}
              <img
                className="nobalaccount"
                src={logo}
                alt="logo"
                onClick={() => setLoginWithNp(true)}
              />{" "}
            </h4>
            <img src={divider} alt="divider" className="mb-3" />
            <h4 className="text-dark">Login</h4>
            {errorMessage && (
              <label className="label-input100 text-danger">
                {errorMessage}
              </label>
            )}
            <FormInput
              icon={
                error?.email === "" && formData?.email.length > 1
                  ? tickIcon
                  : ""
              }
              label="Email"
              name="email"
              type="email"
              onChange={(e) => handleChange(e)}
              placeholder="Enter Email"
              onBlur={() => handleValidation("email")}
              error={error?.email}
              imageCustomClass={error?.email === "" ? "img-prop" : ""}
              value={formData?.email}
            />
            <FormInput
              label="Password"
              name="password"
              type="password"
              onChange={(e) => handleChange(e)}
              placeholder="Enter Password"
              onBlur={() => handleValidation("password")}
              error={error?.password}
              value={formData?.password}
            />
            <div className="text-center d-grid mt-3">
              <button
                type="submit"
                className="btn btn-orange"
                style={{ background: `${data?.theme}` }}
                // onMouseDown={submitSignUpData}
                onClick={(e) => handleSubmit(e)}
              >
                Login
              </button>
            </div>
            <div className="text-start d-grid mt-3 mb-4">
              <Link
                to={`/tp/portal/${param}/forget`}
                style={{ color: "#0432a3" }}
                className="mb-2"
              >
                Forgot Password?
              </Link>
            </div>
            <div
              className="d-flex justify-content-center mt-4"
              style={{ height: "40px", alignItems: "center" }}
            >
              <img
                src={data?.icon ? data?.icon : pagePlaceholder}
                alt="companny-logo"
                style={{ width: "50px", height: "50px", borderRadius: "50%" }}
              />
              <h4 className="card-control-text-oneline ms-2">
                {data?.company_name}
              </h4>
            </div>
          </form>
        </div>
        <ToastContainer/>
      </div>
      {loginWithNp && (
        <LoginModal
          handleNPSubmit={handleNPSubmit}
          modal={loginWithNp}
          toggle={() => setLoginWithNp(!loginWithNp)}
          data={data}
        />
      )}
    </div>
  );
};

export default LoginPortal;
