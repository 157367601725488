import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
//import ImageCropper from "../ImageCrop/BackGroundCropper";
import React, { useState, useCallback } from 'react'
// const [inputImg, setInputImg] = useState("");
import Cropper from 'react-easy-crop'
import "../ImageCrop/background.scss"
import getCroppedImg from '../ImageCrop/cropImage'

const ImageModal = (props) => {

  const {croppedImage, setCroppedImage, toggle} = props
  // const onChangePicture = (e) => {
  //     if (e.target.files[0]) {
  //       console.log("picture: ", e.target.files);
  //       const reader = new FileReader();
  //       reader.addEventListener("load", () => {
  //         setInputImg(reader.result);
  //       });
  //     //   setFormData((prevState) => ({
  //     //     ...prevState,
  //     //     [input]: e.target.files[0],
  //     //   }));

  //       reader.readAsDataURL(e.target.files[0]);
  //     }
  //   };

  //  <input
  //                             type="file"
  //                             id="profile_pic"
  //                             name="profile_pic"
  //                             style={{ display: "none" }}
  //                             onChange={onChangePicture}
  //                            />
  //                              <label htmlFor="profile_pic">
  //                               </label>
  const [crop, setCrop] = useState({ x: 0, y: 0 })
  const [zoom, setZoom] = useState(1)
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null)

  const dogImg="https://img.huffingtonpost.com/asset/5ab4d4ac2000007d06eb2c56.jpeg?cache=sih0jwle4e&ops=1910_1000"
  
  const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels)
  }, [])

  const showCroppedImage = useCallback(async () => {
    try {
      const croppedImage = await getCroppedImg(
        dogImg,
        croppedAreaPixels
      )
      console.log('donee', { croppedImage })
      setCroppedImage(croppedImage)
      toggle();
    } catch (e) {
      console.error(e)
    }
  }, [croppedAreaPixels])

  // const onClose = useCallback(() => {
  //   setCroppedImage(null)
  // }, [])

  return (
    <Modal
    size="xl"
      isOpen={props.modal}
      toggle={toggle}
      className="modal-style image-cropper"
    >
      <ModalHeader toggle={toggle}>Background Photo</ModalHeader>
      <ModalBody>
      <div>
        <div style={{height:"300px" ,width:"100%"}}>
            <Cropper
          image="https://promo.com/tools/image-resizer/static/Pattern_image-8c050053eab884e51b8599607865d112.jpg"
          crop={crop}
          zoom={zoom}
          aspect={3 / 2}
          minZoom={0.01}
          onCropChange={setCrop}
          onCropComplete={onCropComplete}
          cropSize={{ width: 900, height: 250 }}
          onZoomChange={setZoom}
          objectFit="horizontal-cover"
          style={{
            containerStyle: {
              width: "100%",
              height: "300px",
              backgroundColor: "#3f51b5",
              padding:0
            },
          }}
        /></div>
        <div className='sub-heading-slider'>
          <span>Zoom</span>
          <span>{zoom}</span>
        </div>
        <div className="controls">
        <input
          type="range"
          value={zoom}
          min={1}
          max={4}
          step={0.1}
          aria-labelledby="Zoom"
          onChange={(e) => {
            setZoom(e.target.value)
          }}
          className="zoom-range"
        />
      </div>
      </div>
   
        <div className="buttons">
          <span>Remove Photo</span>
          <div className="btns">
            <div className="btn btn-blue transparent">Change Photo</div>
            <div className="btn btn-blue"  onClick={showCroppedImage}>Apply</div>
          </div>
        </div>
      </ModalBody>
    </Modal>
  );
};

export default ImageModal;
