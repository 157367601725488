import moment from 'moment';
import React, { useEffect } from 'react'
import InfiniteScroll from 'react-infinite-scroll-component';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router';
import { getSuggestedArticles } from '../../../../store/articles/articleActions';
import placeholderUser from "../../../../assets/img/placeholder_user.jpg";

const PageArticle = (props) => {
    const {updatedArticlesList,createMarkup} = props;
    const navigate = useNavigate()
    const readMore = (id) => {
        navigate(`/articledetail/${id}`)
    }
    
  return (
    <div>
         <div id="suggested_articles" className="container tab-pane active newsletter">
                  {/* <InfiniteScroll
                    dataLength={currentCount}
                    next={loadMoreData}
                    hasMore={true}
                    loader={<h4>Loading...</h4>}
                  > */}
                    <div className="row main-center">
                      {updatedArticlesList?.length !== 0 ? (
                        updatedArticlesList.map((data) => {
                          return (
                            <div className="col-lg-12 mt-2 wrapper" key={data.id}>
                              <div className="card overflow-hidden card-height-article">
                                <div className="">
                                  <div className="img card-image-square">
                                    <img
                                      src={data?.media}
                                      className="card-image-square"
                                      alt="newsletter-card"
                                    />
                                  </div>
                                </div>
                                <div className="card-header">
                                  <div className="d-flex align-items-center gap-3">
                                    <div className="profile-img">
                                      <img
                                        src={data?.user?.profile_img ? data?.user?.profile_img : placeholderUser}
                                        className="image-fit listImg" alt=""
                                      />
                                    </div>
                                    <span className="name">
                                     
                                      {data?.user?.first_name.slice(0, 21) +
                        (data?.user?.first_name.length > 12 ? "... " : " ")} 
                                      {data?.user?.last_name.slice(0, 22) +
                        (data?.user?.last_name.length > 12 ? "... " : " ")}
                                    </span>
                                  </div>
                                  <div className="date">
                                    <div className="d-flex">
                                      <span className="calender-icon"></span>
                                      <div className="days ml-5">
                                        {" "}
                                        <span>
                                          {moment(data?.createdAt).format(
                                            "ddd, MMM DD, YYYY"
                                          )}{" "}
                                          at{" "}
                                          {moment(data?.createdAt).format(
                                            "hh:mm A z"
                                          )}
                                        </span>
                                      </div>
                                    </div>
                                  </div>
                                  <h3 className="card-text-control">
                                    {data.title}
                                  </h3>
                                </div>
                                <div className="card-body">
                                  <div className="card-text-control">
                                  {data?.content && (
                                    <div className="card-control-text-twoline"
                                      dangerouslySetInnerHTML={createMarkup(
                                        data?.content
                                      )}
                                    ></div>
                                  )}
                                  </div>
                                  <div
                                    className="read"
                                    onClick={() => {
                                      readMore(data.id);
                                    }}
                                  >
                                    Read more
                                  </div>
                                </div>
                              </div>
                            </div>
                          );
                        })
                      ) : (
                        <div className="col-lg-6 mt-2">No Data Available</div>
                      )}
                    </div>
                  {/* </InfiniteScroll> */}
                </div>
    </div>
  )
}

export default PageArticle