import {
  REGISTER_SUCCESS,
  REGISTER_FAIL,
  LOGIN_SUCCESS,
  LOGIN_FAIL,
  LOGOUT,
  INIT_FAIL,
  INIT_SUCCESS,
  UPDATE_USER_DATA,
  UPDATE_MEMBERSHIP_DATA
} from "../types";

const initialState = {
  isLoggedIn: false,
  user: {},
  isWebView: false,
  navto: null,
}; //need to change after token expiry set

export default function (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case REGISTER_SUCCESS:
      //    localStorage.setItem('user', JSON.stringify(action.payload.user))
      return {
        ...state,
        isLoggedIn: true,
        user: payload.user,
      };
    case REGISTER_FAIL:
      return {
        ...state,
        isLoggedIn: false,
      };
    case LOGIN_SUCCESS:
      const { user } = action.payload;
      //    localStorage.setItem('user', JSON.stringify(user))
      return {
        ...state,
        isLoggedIn: true,
        user: payload.user,
      };
    case LOGIN_FAIL:
      return {
        ...state,
        isLoggedIn: false,
        user: null,
      };

    case INIT_SUCCESS:
      return {
        ...state,
        isLoggedIn: true,
        user: payload.user,
        isWebView: payload.isWebView,
      };

    case UPDATE_USER_DATA:
      return {
        ...state,
        user: {
          ...state.user,
          data: {
            ...state.user.data,
            first_name: action.payload.first_name,
            last_name: action.payload.last_name,
          },
        },
      };
    case LOGOUT:
      return {
        ...state,
        isLoggedIn: false,
        user: null,
      };

    case INIT_SUCCESS:
      return {
        ...state,
        isLoggedIn: true,
        user: payload.user,
        isWebView: payload.isWebView,
      };

    case UPDATE_MEMBERSHIP_DATA:
      return {
        ...state,
        user: {
          ...state.user,
          data: {
            ...state.user.data,
            ...payload
          },
        },
      };
    default:
      return state;
  }
}
