import { profileApi } from "../../api/profileAPI";
import {
  ADD_EMPLOYMENT,
  ADD_OTHER_INFO,
  ADD_EDUCATION,
  ADD_CRIMINAL,
  UPDATE_BASIC_INFO,
  UPDATE_EMPLOYMENT,
  UPDATE_EDUCATION,
  UPDATE_CRIMINAL,
  UPDATE_AUTHORIZATION,
  REMOVE_OTHER_INFO,
  REMOVE_PERTICULAR_EMPLOYMENT,
  REMOVE_PERTICULAR_EDUCATION,
  REMOVE_ALL_EMPLOYMENT,
  REMOVE_ALL_EDUCATION,
  REMOVE_CRIMINAL,
  CLEAR_DATA,
  GET_SCREENING_LIST_SUCCESS,
} from "../types";

export const addOtherInfo = () => (dispatch) => {
  dispatch({
    type: ADD_OTHER_INFO,
  });
};
export const removeOtherInfo = () => (dispatch) => {
  dispatch({
    type: REMOVE_OTHER_INFO,
  });
};

export const addEmployment = (payload) => (dispatch) => {
  dispatch({
    type: ADD_EMPLOYMENT,
    payload,
  });
};

export const addEducation = (payload) => (dispatch) => {
  dispatch({
    type: ADD_EDUCATION,
    payload,
  });
};

export const addCriminal = () => (dispatch) => {
  dispatch({
    type: ADD_CRIMINAL,
  });
};

export const removeCriminal = () => (dispatch) => {
  dispatch({
    type: REMOVE_CRIMINAL,
  });
};

export const updateBasicInfo = (payload) => (dispatch) => {
  dispatch({
    type: UPDATE_BASIC_INFO,
    payload,
  });
};

export const updateEmployment = (data, index) => (dispatch) => {
  dispatch({
    type: UPDATE_EMPLOYMENT,
    payload: {
      data,
      index,
    },
  });
};

export const updateEducation = (data, index) => (dispatch) => {
  dispatch({
    type: UPDATE_EDUCATION,
    payload: {
      data,
      index,
    },
  });
};

export const updateCriminal = (payload) => (dispatch) => {
  dispatch({
    type: UPDATE_CRIMINAL,
    payload,
  });
};
export const updateAuthorization = (payload) => (dispatch) => {
  dispatch({
    type: UPDATE_AUTHORIZATION,
    payload,
  });
};

export const removePerticularEmployment = (id) => (dispatch) => {
  dispatch({
    type: REMOVE_PERTICULAR_EMPLOYMENT,
    payload: { id },
  });
};

export const removePerticularEducation = (id) => (dispatch) => {
  dispatch({
    type: REMOVE_PERTICULAR_EDUCATION,
    payload: { id },
  });
};

export const removeAllEmployment = () => (dispatch) => {
  dispatch({
    type: REMOVE_ALL_EMPLOYMENT,
  });
};

export const removeAllEducation = () => (dispatch) => {
  dispatch({
    type: REMOVE_ALL_EDUCATION,
  });
};

export const clearData = () => (dispatch) => {
  dispatch({
    type: CLEAR_DATA,
  });
};

export const getScreeningRequest = (params) => (dispatch) => {
  return profileApi.getBackgroundList(params).then(
    (data) => {
      console.log(data?.data);
      dispatch({
        type: GET_SCREENING_LIST_SUCCESS,
        payload: data?.data,
      });

      return Promise.resolve(data);
    },
    (error) => {
      console.log(error);
      return Promise.reject(error);
    }
  );
};
