import React from "react";
import adBarImage from "../../../../../assets/img/adbar.png";

import placeholderUser from "../../../../../assets/img/placeholder_user.jpg";
import card from "../../../../../assets/img/adbar.png";
import like from "../../../../../assets/img/like.svg";
import { useSelector } from "react-redux";

const AdPreview = ({ desc, selectedFeed, campaignDetail }) => {
  const { user } = useSelector((state) => state.auth);
  return (
    <div className="adpreview">
      <h5> Ad Preview</h5>
      <div className="dashboard-view">
        <div className="main-post ">
          <div className="main-Post-div ">
            <div
              className="prof-img"
              //   onClick={() => navigate(`/profile/${item?.user_id}`)}
            >
              <img
                src={
                  user?.data?.profile_img
                    ? user?.data?.profile_img
                    : placeholderUser
                }
                alt=""
                className="img-fluid"
              />
            </div>
            <div className="details">
              {/* {item?.Page ? (
                <>
                  <h4
                    className="userNameTextWrap"
                    onClick={() => navigate(`/pageadminview/${item?.Page?.id}`)}
                  >
                    {item?.Page?.name}
                  </h4>
                  <p>{item?.Page?.industry}</p>
                </>
              ) : ( */}
              <>
                <h4
                  className="userNameTextWrap"
                  // onClick={() => navigate(`/profile/${item?.user_id}`)}
                >
                  {user?.data?.first_name + " " + user?.data?.last_name}
                  {/* Andrew Ferguson */}
                </h4>
                {/* {item?.User?.profile?.profile_headline ? (
                    <p className=" card-control-text-twoline maxWid">
                      {item?.User?.profile?.profile_headline}
                    </p>
                  ) : (
                    ""
                  )} */}
              </>
              {/* )} */}

              <p>
                <span>Promoted</span>
                <span className="ml-5">&#x2022;</span>
                <span>
                  {/* <>{privacy_type(item?.privacy_id)}</> */}
                  <>Public</>
                </span>
              </p>
            </div>
          </div>
        </div>
        <div
          id="descriptionOverFlow"
          className="text form-control border-none"
          style={{ padding: "0px" }}
        >
          <span className="descriptionOverFlowSpan despan">
            {/* {item?.description ? getHashtagText(item?.description) : ""} */}
            {desc?.length > 200 ? desc?.slice(0, 150) + "..." : desc}
          </span>
          {/* implemented soon */}
          {/* {item?.type === "article" || item?.type === "newsletter" ? (
              <div className="share-article-post">
                <div className="box mt-20">
                  <div className="row">
                    <div className="col-lg-12 p-0">
                      <div className="boxe-img">
                        <img
                          src={item?.SharedDetails?.media}
                          className="img-fit"
                          alt=""
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row py-4">
                    <div className="col-sm-9">
                      <div className="text">
                        {item?.type === "article" ? (
                          <h6
                            onClick={() =>
                              navigate(
                                `/articledetail/${item?.SharedDetails?.id}`
                              )
                            }
                          >
                            {item?.SharedDetails?.title}
                          </h6>
                        ) : (
                          <h6
                            onClick={() =>
                              navigate(
                                `/newsletterdetail/${item?.SharedDetails?.id}`
                              )
                            }
                          >
                            {item?.SharedDetails?.title}
                          </h6>
                        )}
                        <span>3 min to read</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              ""
            )}
            {item?.type === "event" ? (
              <div className="share-event-post">
                <div className="box mt-20">
                  <div className="row">
                    <div className="col-lg-12 p-0">
                      <div className="boxe-img">
                        <img
                          src={item?.SharedDetails?.media}
                          className="img-fit"
                          alt=""
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row py-4">
                    <div className="col-sm-8">
                      <div className="text">
                        <h6>{item?.SharedDetails?.title}</h6>
                        <p className="my-2">
                          {moment
                            .utc(item?.SharedDetails?.event_time)
                            .format("dddd MMM DD")}
                          ,{" "}
                          {moment
                            .utc(item?.SharedDetails?.event_time)
                            .format("hh:mm A (UTC 5.30)")}
                        </p>
                        <span>
                          Event created by{" "}
                          {item?.SharedDetails?.User?.first_name}{" "}
                          {item?.SharedDetails?.User?.last_name}
                        </span>
                        <button className="btn btn-blue mt-2">
                          {item?.SharedDetails?.event_type === "virtual"
                            ? "Virtual"
                            : "Physical"}
                        </button>
                      </div>
                    </div>
                    <div className="col-sm-4 mt-2">
                      <div className="float-sm-end me-2">
                        <button
                          className="btn btn-light me-0"
                          onClick={() =>
                            navigate(`/eventdetail/${item?.SharedDetails?.id}`)
                          }
                        >
                          View Event
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              ""
            )}
             {item?.Celebration
              ? createLink(item?.Celebration?.celebration_tag)
              : ""}
              </span>
              {item?.Celebration ? (
          <ShowCelebration
            celebrationData={item?.Celebration}
            description={item?.description}
            home="true"
          />
        ) : (
          ""
        )}
        {item?.Poll ? (
          <ViewPollComp pollData={item?.Poll} postId={item?.id} />
        ) : (
          ""
        )} */}
          <div className="translate">
            <span>translate</span>
          </div>
          {/* {item?.Feed_Documents && ( */}
          <div className="post-img row mt-2">
            {
              <div className="main-view">
                {/* <ShowDasboardImage
                  item={item}
                  index={index}
                  userName={userName}
                  privacy_type={privacy_type}
                  commentBoxStatus={commentBoxStatus}
                  setCommentBoxStatus={setCommentBoxStatus}
                  dropRefShare={dropRefShare}
                  dropDownShare={dropDownShare}
                  setDropDownShare={setDropDownShare}
                  podType={type}
                  repostPost={repostPost}
                  getHashtagText={getHashtagText}
                  handleReaction={handleReaction}
                  likeResult={likeResult}
                  sendRequest={sendRequest}
                  peopleFollowRequest={peopleFollowRequest}
                  peopleUnFollowRequest={peopleUnFollowRequest}
                  handlePageFollow={handlePageFollow}
                  handlePageUnFollow={handlePageUnFollow}
                  getTime={getTime}
                  postViewCounter={postViewCounter}
                /> */}
                <img
                  src={
                    campaignDetail?.type === "page"
                      ? selectedFeed?.icon
                        ? selectedFeed?.icon
                        : adBarImage
                      : selectedFeed?.Feed_Documents?.length > 0
                      ? selectedFeed?.Feed_Documents[0]?.document_url
                      : adBarImage
                  }
                  alt=""
                  className="img-fluid"
                />
              </div>
            }
          </div>
          <div className="p-2">
            <div className="d-flex justify-content-between align-items-center">
              <div className="">
                <h5 className="m-0 card-control-text-oneline">
                  {campaignDetail?.name?.length > 25
                    ? campaignDetail?.name?.slice(0, 25) + "..."
                    : campaignDetail?.name}
                </h5>
                <p
                  className="text-secondary card-control-text-oneline"
                  style={{ fontSize: "10px" }}
                >
                  {campaignDetail?.action_link?.length > 30
                    ? campaignDetail?.action_link?.slice(0, 30) + "..."
                    : campaignDetail?.action_link}
                </p>
              </div>
              {campaignDetail?.action_link && (
                <button
                  className="btn p-2"
                  style={{ border: "1px solid #0432A3", color: "#0432A3" }}
                >
                  <a
                    style={{ color: "#0432A3" }}
                    href={campaignDetail?.action_link_label}
                  >
                    {campaignDetail?.action_link_label || "Label"}
                  </a>
                </button>
              )}
            </div>
          </div>
        </div>
        {/* <div className="flex mt-3">
          <div className="d-flex">
            <div className="like me-4">
              <span>
                <img src={like} alt="" /> Like (1)
              </span>
            </div>
            <div className="comment me-2">
              <span>Comment (1)</span>
            </div>
            <div className="share">
              <span>Share (1)</span>
            </div>
          </div>
        </div> */}
      </div>
    </div>
  );
};

export default AdPreview;
