import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { profileApi } from "../../api/profileAPI";
import backIcon from "../../assets/img/Back_Icons.svg";
import moment from "moment";
const BackgroundReportInfo = () => {
  const { state } = useLocation();
  const navigate = useNavigate();
  const token = localStorage.getItem("accessToken");
  const [data, setData] = useState({});
  const backgroundInfo = async () => {
    const res = await profileApi.getBackgroundList({ id: state }, token);
    if (res?.success) setData(res?.data[0]);
  };
  useEffect(() => {
    if (!state) navigate("/background-report");
    else {
      backgroundInfo();
    }
  }, []);

  const status = (status) => {
    if (status == "pending")
      return (
        <p
          className="p-1 rounded"
          style={{ background: "#FE980033", color: "#FE9800", width:"fit-content" }}
        >
          Pending
        </p>
      );

    if (status == "Verified")
      return (
        <p
          className="p-1 rounded"
          style={{ background: "#169B0033", color: "#169B00", width:"fit-content" }}
        >
          Verified
        </p>
      );
    if (status == "Discrepancy")
      return (
        <p
          className="p-1 rounded"
          style={{ background: "#FE1E0033", color: "#FE1E00", width:"fit-content" }}
        >
          Discrepancy
        </p>
      );
  };


  return (
    <>
      <div className="px-5 py-3 bg-white">
        <div className="container">
          <div className="d-flex ">
            <img
              src={backIcon}
              width={25}
              onClick={() => navigate("/background-report")}
            />
            <h4 className="mx-3 text-uppercase">
              Report For - {data?.user?.first_name} {data?.user?.last_name}
            </h4>
          </div>
          <div className="">
            <div className="row">
              <div className="col-7">
                <div className="mt-4 border">
                  <div
                    className="p-3 border-bottom"
                    style={{ background: "#F8F8FA" }}
                  >
                    <h6 className="text-dark">SUBJECT INFORMATION</h6>
                  </div>
                  <div className="mt-2 p-3">
                    <label htmlFor="" className="form-label">
                      DEMOGRAPHICS
                    </label>
                    <div className="d-flex justify-content-between align-items-baseline">
                      <div className="d-flex justify-content-between align-items-baseline w-50">
                        <p className="text-dark">Name(LFM)</p>
                        <p className="text-dark">:</p>
                      </div>
                      <div className="w-50">
                        <p
                          className="mx-1 text-dark"
                          style={{ wordBreak: "break-all" }}
                        >
                          {data?.basicInfo?.info?.first_name},{" "}
                          {data?.basicInfo?.info?.last_name}
                        </p>
                      </div>
                    </div>

                    <div className="d-flex justify-content-between align-items-baseline">
                      <div className="d-flex justify-content-between align-items-baseline w-50">
                        <p className="text-dark">
                          Address
                          <span style={{ fontSize: "10px" }}>
                            (NON-USA Applicants, type your FULL ADDRESS<br></br> 
                            with CITY, ZIP CODE, COUNTRY here)
                          </span>
                        </p>
                        <p className="text-dark">:</p>
                      </div>
                      <div className="w-50">
                        <p
                          className="mx-1 text-dark"
                          style={{ wordBreak: "break-all" }}
                        >
                          {data?.basicInfo?.address || "-"}
                        </p>
                        <p
                          className="mx-1 text-dark"
                          style={{ wordBreak: "break-all" }}
                        >
                          {data?.basicInfo?.zip}
                        </p>
                      </div>
                    </div>

                    <div className="d-flex justify-content-between align-items-baseline">
                      <div className="d-flex justify-content-between align-items-baseline w-50">
                        <p className="text-dark">Phone</p>
                        <p className="text-dark">:</p>
                      </div>
                      <div className="w-50">
                        <p
                          className="mx-1 text-dark"
                          style={{ wordBreak: "break-all" }}
                        >
                          {data?.basicInfo?.applicant_phone || "-"}
                        </p>
                      </div>
                    </div>

                    <div className="d-flex justify-content-between align-items-baseline">
                      <div className="d-flex justify-content-between align-items-baseline w-50">
                        <p className="text-dark">Email</p>
                        <p className="text-dark">:</p>
                      </div>
                      <div className="w-50">
                        <p
                          className="mx-1 text-dark"
                          style={{ wordBreak: "break-all" }}
                        >
                          {data?.basicInfo?.applicant_email || "-"}
                        </p>
                      </div>
                    </div>

                    <div className="d-flex justify-content-between align-items-baseline">
                      <div className="d-flex justify-content-between align-items-baseline w-50">
                        <p className="text-dark">Nationality</p>
                        <p className="text-dark">:</p>
                      </div>
                      <div className="w-50">
                        <p
                          className="mx-1 text-dark"
                          style={{ wordBreak: "break-all" }}
                        >
                          {data?.basicInfo?.nationality || "-"}
                        </p>
                      </div>
                    </div>

                    <div className="d-flex justify-content-between align-items-baseline">
                      <div className="d-flex justify-content-between align-items-baseline w-50">
                        <p className="text-dark">Country of Residence</p>
                        <p className="text-dark">:</p>
                      </div>
                      <div className="w-50">
                        <p
                          className="mx-1 text-dark"
                          style={{ wordBreak: "break-all" }}
                        >
                          {data?.basicInfo?.residence || "-"}
                        </p>
                      </div>
                    </div>

                    <div className="d-flex justify-content-between align-items-baseline">
                      <div className="d-flex justify-content-between align-items-baseline w-50">
                        <p className="text-dark">Date of Birth</p>
                        <p className="text-dark">:</p>
                      </div>
                      <div className="w-50">
                        <p
                          className="mx-1 text-dark"
                          style={{ wordBreak: "break-all" }}
                        >
                          {moment(data?.basicInfo?.dob).format("DD/MM/YYYY") ||
                            "-"}
                        </p>
                      </div>
                    </div>

                    <div className="d-flex justify-content-between align-items-baseline">
                      <div className="d-flex justify-content-between align-items-baseline w-50">
                        <p className="text-dark">Gender</p>
                        <p className="text-dark">:</p>
                      </div>
                      <div className="w-50">
                        <p
                          className="mx-1 text-dark"
                          style={{ wordBreak: "break-all", textTransform:"capitalize" }}
                        >
                          {data?.basicInfo?.gender || "-"}
                        </p>
                      </div>
                    </div>

                    <label className="form-label mt-3">
                      LOCAL AND NATIONAL IDENTIFICATION
                    </label>
                  </div>

                  {data?.employment?.map((ele, i) => {
                    return (
                      <>
                        <div className="mt-2 p-3">
                          <label htmlFor="" className="form-label">
                            EMPLOYMENT #{i + 1}
                          </label>
                          <div className="d-flex justify-content-between align-items-baseline">
                            <div className="d-flex justify-content-between align-items-baseline w-50">
                              <p className="text-dark">Company Name</p>
                              <p className="text-dark">:</p>
                            </div>
                            <div className="w-50">
                              <p
                                className="mx-1 text-dark"
                                style={{ wordBreak: "break-all" }}
                              >
                                {ele?.company_name || "-"},{" "}
                              </p>
                            </div>
                          </div>

                          <div className="d-flex justify-content-between align-items-baseline">
                            <div className="d-flex justify-content-between align-items-baseline w-50">
                              <p className="text-dark">Contact Name</p>
                              <p className="text-dark">:</p>
                            </div>
                            <div className="w-50">
                              <p
                                className="mx-1 text-dark"
                                style={{ wordBreak: "break-all" }}
                              >
                                {ele?.contact_name || "-"}
                              </p>
                            </div>
                          </div>

                          <div className="d-flex justify-content-between align-items-baseline">
                            <div className="d-flex justify-content-between align-items-baseline w-50">
                              <p className="text-dark">Phone</p>
                              <p className="text-dark">:</p>
                            </div>
                            <div className="w-50">
                              <p
                                className="mx-1 text-dark"
                                style={{ wordBreak: "break-all" }}
                              >
                                {ele?.phone || "-"}
                              </p>
                            </div>
                          </div>

                          <div className="d-flex justify-content-between align-items-baseline">
                            <div className="d-flex justify-content-between align-items-baseline w-50">
                              <p className="text-dark">Email</p>
                              <p className="text-dark">:</p>
                            </div>
                            <div className="w-50">
                              <p
                                className="mx-1 text-dark"
                                style={{ wordBreak: "break-all" }}
                              >
                                {ele?.email || "-"}
                              </p>
                            </div>
                          </div>

                          <div className="d-flex justify-content-between align-items-baseline">
                            <div className="d-flex justify-content-between align-items-baseline w-50">
                              <p className="text-dark">
                                Your Title/ Position with Employer
                              </p>
                              <p className="text-dark">:</p>
                            </div>
                            <div className="w-50">
                              <p
                                className="mx-1 text-dark"
                                style={{ wordBreak: "break-all" }}
                              >
                                {ele?.position || "-"}
                              </p>
                            </div>
                          </div>

                          <div className="d-flex justify-content-between align-items-baseline">
                            <div className="d-flex justify-content-between align-items-baseline w-50">
                              <p className="text-dark">Start Date</p>
                              <p className="text-dark">:</p>
                            </div>
                            <div className="w-50">
                              <p
                                className="mx-1 text-dark"
                                style={{ wordBreak: "break-all" }}
                              >
                                {moment(ele?.start_date).format("DD/MM/YYYY") ||
                                  "-"}
                              </p>
                            </div>
                          </div>

                          <div className="d-flex justify-content-between align-items-baseline">
                            <div className="d-flex justify-content-between align-items-baseline w-50">
                              <p className="text-dark">End Date</p>
                              <p className="text-dark">:</p>
                            </div>
                            <div className="w-50">
                              <p
                                className="mx-1 text-dark"
                                style={{ wordBreak: "break-all" }}
                              >
                                {ele?.end_date
                                  ? moment(ele?.end_date).format("DD/MM/YYYY")
                                  : "-"}
                              </p>
                            </div>
                          </div>

                          <div className="d-flex justify-content-between align-items-baseline">
                            <div className="d-flex justify-content-between align-items-baseline w-50">
                              <p className="text-dark">Is Current</p>
                              <p className="text-dark">:</p>
                            </div>
                            <div className="w-50">
                              <p
                                className="mx-1 text-dark"
                                style={{ wordBreak: "break-all" }}
                              >
                                {ele?.is_current ? "Y" : "N"}
                              </p>
                            </div>
                          </div>

                          <div className="d-flex justify-content-between align-items-baseline">
                            <div className="d-flex justify-content-between align-items-baseline w-50">
                              <p className="text-dark">Screening Status</p>
                              <p className="text-dark">:</p>
                            </div>
                            <div className="w-50">
                              <p
                                className="mx-1 text-dark"
                                style={{ wordBreak: "break-all" }}
                              >
                                {status(ele?.screening_status || "pending")}
                              </p>
                            </div>
                          </div>
                        </div>
                      </>
                    );
                  })}

                  {data?.education?.map((ele, i) => {
                    return (
                      <>
                        <div className="mt-2 p-3">
                          <label htmlFor="" className="form-label">
                            EDUCATION #{i + 1}
                          </label>
                          <div className="d-flex justify-content-between align-items-baseline">
                            <div className="d-flex justify-content-between align-items-baseline w-50">
                              <p className="text-dark">School Name</p>
                              <p className="text-dark">:</p>
                            </div>
                            <div className="w-50">
                              <p
                                className="mx-1 text-dark"
                                style={{ wordBreak: "break-all" }}
                              >
                                {ele?.school_name || "-"},{" "}
                              </p>
                            </div>
                          </div>

                          <div className="d-flex justify-content-between align-items-baseline">
                            <div className="d-flex justify-content-between align-items-baseline w-50">
                              <p className="text-dark">Campus</p>
                              <p className="text-dark">:</p>
                            </div>
                            <div className="w-50">
                              <p
                                className="mx-1 text-dark"
                                style={{ wordBreak: "break-all" }}
                              >
                                {ele?.campus || "-"}
                              </p>
                            </div>
                          </div>

                          <div className="d-flex justify-content-between align-items-baseline">
                            <div className="d-flex justify-content-between align-items-baseline w-50">
                              <p className="text-dark">Phone</p>
                              <p className="text-dark">:</p>
                            </div>
                            <div className="w-50">
                              <p
                                className="mx-1 text-dark"
                                style={{ wordBreak: "break-all" }}
                              >
                                {ele?.phone || "-"}
                              </p>
                            </div>
                          </div>

                          <div className="d-flex justify-content-between align-items-baseline">
                            <div className="d-flex justify-content-between align-items-baseline w-50">
                              <p className="text-dark">Email</p>
                              <p className="text-dark">:</p>
                            </div>
                            <div className="w-50">
                              <p
                                className="mx-1 text-dark"
                                style={{ wordBreak: "break-all" }}
                              >
                                {ele?.email || "-"}
                              </p>
                            </div>
                          </div>

                          <div className="d-flex justify-content-between align-items-baseline">
                            <div className="d-flex justify-content-between align-items-baseline w-50">
                              <p className="text-dark">Degree</p>
                              <p className="text-dark">:</p>
                            </div>
                            <div className="w-50">
                              <p
                                className="mx-1 text-dark"
                                style={{ wordBreak: "break-all" }}
                              >
                                {ele?.degree || "-"}
                              </p>
                            </div>
                          </div>

                          <div className="d-flex justify-content-between align-items-baseline">
                            <div className="d-flex justify-content-between align-items-baseline w-50">
                              <p className="text-dark">Start Date</p>
                              <p className="text-dark">:</p>
                            </div>
                            <div className="w-50">
                              <p
                                className="mx-1 text-dark"
                                style={{ wordBreak: "break-all" }}
                              >
                                {moment(ele?.start_date).format("DD/MM/YYYY") ||
                                  "-"}
                              </p>
                            </div>
                          </div>

                          <div className="d-flex justify-content-between align-items-baseline">
                            <div className="d-flex justify-content-between align-items-baseline w-50">
                              <p className="text-dark">End Date</p>
                              <p className="text-dark">:</p>
                            </div>
                            <div className="w-50">
                              <p
                                className="mx-1 text-dark"
                                style={{ wordBreak: "break-all" }}
                              >
                                {ele?.end_date
                                  ? moment(ele?.end_date).format("DD/MM/YYYY")
                                  : "-"}
                              </p>
                            </div>
                          </div>

                          <div className="d-flex justify-content-between align-items-baseline">
                            <div className="d-flex justify-content-between align-items-baseline w-50">
                              <p className="text-dark">Major</p>
                              <p className="text-dark">:</p>
                            </div>
                            <div className="w-50">
                              <p
                                className="mx-1 text-dark"
                                style={{ wordBreak: "break-all" }}
                              >
                                {ele?.major || "-"}
                              </p>
                            </div>
                          </div>

                          <div className="d-flex justify-content-between align-items-baseline">
                            <div className="d-flex justify-content-between align-items-baseline w-50">
                              <p className="text-dark">Screening Status</p>
                              <p className="text-dark">:</p>
                            </div>
                            <div className="w-50">
                              <p
                                className="mx-1 text-dark"
                                style={{ wordBreak: "break-all" }}
                              >
                                {status(ele?.screening_status || "pending")}
                              </p>
                            </div>
                          </div>
                        </div>
                      </>
                    );
                  })}

                  {data?.criminal?.residence ? 
                  <>
                  <div className="mt-2 p-3">
                    <label htmlFor="" className="form-label">
                      CRIMINALITY
                    </label>
                    <div className="d-flex justify-content-between align-items-baseline">
                      <div className="d-flex justify-content-between align-items-baseline w-50">
                        <p className="text-dark">Nationality</p>
                        <p className="text-dark">:</p>
                      </div>
                      <div className="w-50">
                        <p
                          className="mx-1 text-dark"
                          style={{ wordBreak: "break-all" }}
                        >
                          {data?.criminal?.nationality || "-"}
                        </p>
                      </div>
                    </div>

                    <div className="d-flex justify-content-between align-items-baseline">
                      <div className="d-flex justify-content-between align-items-baseline w-50">
                        <p className="text-dark">Residence</p>
                        <p className="text-dark">:</p>
                      </div>
                      <div className="w-50">
                        <p
                          className="mx-1 text-dark"
                          style={{ wordBreak: "break-all" }}
                        >
                          {data?.criminal?.residence || "-"}
                        </p>
                      </div>
                    </div>

                    <div className="d-flex justify-content-between align-items-baseline">
                      <div className="d-flex justify-content-between align-items-baseline w-50">
                        <p className="text-dark">Screening Status</p>
                        <p className="text-dark">:</p>
                      </div>
                      <div className="w-50">
                        <p
                          className="mx-1 text-dark"
                          style={{ wordBreak: "break-all" }}
                        >
                          {status(data?.criminal?.screening_status || "pending")}
                        </p>
                      </div>
                    </div>
                  </div>
                </>: <></>}
                </div>
              </div>

              <div className="col-5">
                <div className="mt-4 border">
                  <div
                    className="p-3 border-bottom"
                    style={{ background: "#F8F8FA" }}
                  >
                    <h6 className="text-dark">PAYMENT INFORMATION</h6>
                  </div>
                  <div className="mt-2 p-3">
                    <div className="d-flex justify-content-between align-items-baseline">
                      <div className="d-flex justify-content-between align-items-baseline w-50">
                        <p className="text-dark">Employment Check</p>
                        <p className="text-dark">:</p>
                      </div>
                      <div className="w-50">
                        <p
                          className="mx-1 text-dark"
                          style={{ wordBreak: "break-all" }}
                        >
                          ${data?.employment?.length * 54 || 0}
                        </p>
                      </div>
                    </div>

                    <div className="d-flex justify-content-between align-items-baseline">
                      <div className="d-flex justify-content-between align-items-baseline w-50">
                        <p className="text-dark">Education Check</p>
                        <p className="text-dark">:</p>
                      </div>
                      <div className="w-50">
                        <p
                          className="mx-1 text-dark"
                          style={{ wordBreak: "break-all" }}
                        >
                          ${data?.education?.length * 54 || 0}
                        </p>
                      </div>
                    </div>

                    <div className="d-flex justify-content-between align-items-baseline">
                      <div className="d-flex justify-content-between align-items-baseline w-50">
                        <p className="text-dark">Criminal Check</p>
                        <p className="text-dark">:</p>
                      </div>
                      <div className="w-50">
                        <p
                          className="mx-1 text-dark"
                          style={{ wordBreak: "break-all" }}
                        >
                          $
                          {data?.criminal && Object.keys(data?.criminal).length
                            ? 54
                            : 0}
                        </p>
                      </div>
                    </div>

                    <div className="d-flex justify-content-between align-items-baseline">
                      <div className="d-flex justify-content-between align-items-baseline w-50">
                        <p className="text-dark">Tax</p>
                        <p className="text-dark">:</p>
                      </div>
                      <div className="w-50">
                        <p
                          className="mx-1 text-dark"
                          style={{ wordBreak: "break-all" }}
                        >
                          {data?.payment?.tax || 18}%
                        </p>
                      </div>
                    </div>

                    <div className="d-flex justify-content-between align-items-baseline">
                      <div className="d-flex justify-content-between align-items-baseline w-50">
                        <p className="text-dark">Discount</p>
                        <p className="text-dark">:</p>
                      </div>
                      <div className="w-50">
                        <p
                          className="mx-1 text-dark"
                          style={{ wordBreak: "break-all" }}
                        >
                          {data?.payment?.discount || 20}%
                        </p>
                      </div>
                    </div>

                    <div className="d-flex justify-content-between align-items-baseline">
                      <div className="d-flex justify-content-between align-items-baseline w-50">
                        <p className="text-dark">Total Payment</p>
                        <p className="text-dark">:</p>
                      </div>
                      <div className="w-50">
                        <p
                          className="mx-1 text-dark"
                          style={{ wordBreak: "break-all" }}
                        >
                          ${data?.payment?.totalAmount || 436}
                        </p>
                      </div>
                    </div>

                    <div className="d-flex justify-content-between align-items-baseline">
                      <div className="d-flex justify-content-between align-items-baseline w-50">
                        <p className="text-dark">Payment Date</p>
                        <p className="text-dark">:</p>
                      </div>
                      <div className="w-50">
                        <p
                          className="mx-1 text-dark"
                          style={{ wordBreak: "break-all" }}
                        >
                          {moment(data?.createdAt).format("DD/MM/YYYY")}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="mt-4 border ">
                  <div
                    className="p-3 border-bottom"
                    style={{ background: "#F8F8FA" }}
                  >
                    <h6 className="text-dark">Documents</h6>
                  </div>
                  <div className="mt-2 p-3">
                    {data?.basicInfo?.document?.map((ele) => {
                      return <p>{ele.slice(35)}</p>;
                    })}

                    {data?.employment?.map((ele) => {
                      return ele?.document?.map((e) => {
                        return <p>{e.slice(35)}</p>;
                      });
                    })}

                    {data?.education?.map((ele) => {
                      return ele?.document?.map((e) => {
                        return <p>{e.slice(35)}</p>;
                      });
                    })}
                  </div>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-12">
                <div className="mt-4 border">
                  <div
                    className="p-3 border-bottom"
                    style={{ background: "#F8F8FA" }}
                  >
                    <h6 className="text-dark">ORDER INFORMATION</h6>
                  </div>
                  <div className="mt-2 p-3">
                    <div className="d-flex  align-items-baseline">
                      <div className="d-flex justify-content-between align-items-baseline ">
                        <p className="text-dark">Order Number</p>
                        <p className="text-dark mx-3">:</p>
                      </div>
                      <div className="">
                        <p
                          className="mx-1 text-dark"
                          style={{ wordBreak: "break-all" }}
                        >
                          {data?.orderNumber || "-"}
                        </p>
                      </div>
                    </div>

                    <div className="d-flex align-items-baseline">
                      <div className="d-flex justify-content-between align-items-baseline">
                        <p className="text-dark">Remote Order Number</p>
                        <p className="text-dark mx-3">:</p>
                      </div>
                      <div className="">
                        <p
                          className="mx-1 text-dark"
                          style={{ wordBreak: "break-all" }}
                        >
                          {data?.remoteOrderNumber || "-"}
                        </p>
                      </div>
                    </div>

                    <div className="d-flex  align-items-baseline">
                      <div className="d-flex justify-content-between align-items-baseline ">
                        <p className="text-dark">Order Date</p>
                        <p className="text-dark mx-3">:</p>
                      </div>
                      <div className="">
                        <p
                          className="mx-1 text-dark"
                          style={{ wordBreak: "break-all" }}
                        >
                          {moment(data?.createdAt).format("DD/MM/YYYY") || "-"}
                        </p>
                      </div>
                    </div>

                    <div className="d-flex  align-items-baseline">
                      <div className="d-flex justify-content-between align-items-baseline ">
                        <p className="text-dark">Summery</p>
                        <p className="text-dark mx-3">:</p>
                      </div>
                      <div className="">
                        <p
                          className="mx-1 text-dark"
                          style={{ wordBreak: "break-all" }}
                        >
                          Request for{" "}
                          {data?.employment?.length > 0 && "Employment "}
                          {data?.education?.length > 0 && "Educational "}
                          {data?.criminal?.residence && "Background "}
                          Verification
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default BackgroundReportInfo;
