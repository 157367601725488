import React, { useState } from "react";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import "../profile.scss";
import { useDispatch, useSelector } from "react-redux";
import { updateUserDetails } from "../../../../store/profile/profileActions";

const EditAbout = (props) => {
  const { title, modal, toggle, aboutData } = props;
  const [textLength, setTextLength] = useState(
    aboutData ? aboutData.split(/\+/g).join("").length : 0
  );
  const { isWebView } = useSelector((state) => state.auth);

  const [data, setData] = useState(aboutData ? aboutData : "");

  const dispatch = useDispatch();

  const handleSubmit = () => {
    var token = localStorage.getItem("accessToken");
    let formatAboutData = {
      about: data,
    };
    dispatch(updateUserDetails(formatAboutData, token))
      .then(() => {
        toggle();
      })
      .catch((e) => {
        console.log(e);
      });
  };

  return (
    <div>
      <Modal
        size="xl"
        className="contact-info"
        toggle={toggle}
        isOpen={modal}
        centered
      >
        <div className="popup">
          <div className="edit-about">
            <ModalHeader style={{ border: "none" }} toggle={toggle}>
              {title}
            </ModalHeader>
            <ModalBody className="modal-body">
              <p>
                Edit your 'About' section with a concise, keyword-rich
                description reflecting your experience <br /> and personality.
                Capture attention and showcase your unique qualities
                effectively.
              </p>
              <div>
                <textarea
                  className="edit-textarea"
                  maxlength="200"
                  placeholder="Description"
                  value={data}
                  onChange={(e) => {
                    setData(e.target.value);
                    setTextLength(
                      e.target.value.trim() === "" ? 0 : e.target.value.length
                    );
                  }}
                ></textarea>
                <p className="left">{textLength}/200</p>
              </div>
              <div className="savebutton" style={{ textAlign: "center" }}>
                <div
                  className={isWebView ? "btnWebView btn-blue" : "btn btn-blue"}
                  style={{ width: "100px" }}
                  onClick={handleSubmit}
                >
                  Apply
                </div>
              </div>
            </ModalBody>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default EditAbout;
