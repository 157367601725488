import { call } from "./apiCall";

class NotificationApi {
  getNoitfication(params) {
    return new Promise((resolve, reject) => {
      let token = localStorage.getItem("accessToken");
      (async () => {
        try {
          const res = await call(
            "get",
            `api/v1/notification/notifications`,
            params,
            null,
            token
          );
          if (res.data.success === true) {
            resolve(res.data);
          }
          if (res.data.success === false) {
            resolve(res.data);
          }
        } catch (err) {
          console.log(err);
          reject(err);
        }
      })();
    });
  }

  updateNotification(id, data) {
    return new Promise((resolve, reject) => {
      let token = localStorage.getItem("accessToken");
      (async () => {
        try {
          const res = await call(
            "patch",
            `api/v1/notification/update/${id}`,
            null,
            data,
            token
          );
          if (res.data.success === true) {
            resolve(res.data.data);
          }
          if (res.data.success === false) {
            resolve(res.data);
          }
        } catch (err) {
          console.log(err);
          reject(err);
        }
      })();
    });
  }

  updateAsUnreadNotification() {
    return new Promise((resolve, reject) => {
      let token = localStorage.getItem("accessToken");
      (async () => {
        try {
          const res = await call(
            "patch",
            `api/v1/notification//update/all/asunread`,
            null,
            null,
            token
          );
          console.log("notificationResponse", res.data);
          resolve(res.data);
        } catch (err) {
          console.log(err);
          reject(err);
        }
      })();
    });
  }
  updateAsreadNotification() {
    return new Promise((resolve, reject) => {
      let token = localStorage.getItem("accessToken");
      (async () => {
        try {
          const res = await call(
            "patch",
            `api/v1/notification/update/all/asread`,
            null,
            null,
            token
          );
            resolve(res.data);
        } catch (err) {
          console.log(err);
          reject(err);
        }
      })();
    });
  }

  deleteNotification(id) {
    return new Promise((resolve, reject) => {
      let token = localStorage.getItem("accessToken");
      (async () => {
        try {
          const res = await call(
            "delete",
            `api/v1/notification/delete/${id}`,
            null,
            null,
            token
          );
          resolve(res);
        } catch (err) {
          console.log(err);
          reject(err);
        }
      })();
    });
  }
}

export const notificationApi = new NotificationApi();
