import moment from "moment";
import React, { useState } from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import ShareModal from "../../../../core/components/sharePost/sharePost";
import { attendEvents } from "../../../../store/events/eventActions";
import placeholderUser from "../../../../assets/img/placeholder_user.jpg";
import CreateEventModal from "../../Events/components/createEvent";
import EventSuccessfulModal from "../../Events/components/SuccessfulModal";

const PageEvent = (props) => {
  const { page_events, pinHandle, pageName, pageID, createdAt, pageIcon } =
    props;
  const token = localStorage.getItem("accessToken");
  const [openShareModal, setOpenShareModal] = useState(false);
  const [isOwner, setIsOwner] = useState(false);
  const [shareData, setShareData] = useState("");
  const [editEventData, setEditEventData] = useState("");
  const [openCreateModal, setOpenCreateModal] = useState(false);
  const [popupShareData, setPopupShareData] = useState("");
  const [successModal, setSuccessModal] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.auth);
  const readMore = (id) => {
    navigate(`/eventdetail/${id}`);
  };
  const attendHandle = (id, type) => {
    dispatch(attendEvents(id, token, type));
  };
  const successfulStatus = (shareData) => {
    setPopupShareData(shareData);
    setSuccessModal(!successModal);
  };
  useEffect(() => {
    page_events.map((data) => {
      if (user?.data?.id == data?.User?.id) {
        setIsOwner(true);
      }
    });
  }, [page_events]);

  return (
    <div>
      <div id="suggested_events" className="container tab-pane active">
        <div className="row">
          <div className="networks newsletter">
            <div className="main-center">
              <div className="wrapper">
                <div className="events">
                  <div className="row mt-20">
                    {page_events.map((data, index) => {
                      return (
                        <div className="col-md-6" key={index}>
                          <div className="card">
                            <div className="img-section">
                              <div className="card-img">
                                <img
                                  src={data?.media}
                                  className="event-card-image"
                                  alt=""
                                />
                              </div>
                              <div
                                className={
                                  data.event_type === "physical"
                                    ? "tag tag-color-physical"
                                    : "tag tag-color-virtual"
                                }
                              >
                                {data.event_type === "physical"
                                  ? "Physical"
                                  : "Virtual"}
                              </div>
                              {isOwner ? (
                                <></>
                              ) : (
                                <div>
                                  {data?.Pinned_Event?.status ? (
                                    <div
                                      className="unpin"
                                      onClick={() =>
                                        pinHandle(data.id, "pinFalse")
                                      }
                                    ></div>
                                  ) : (
                                    <div
                                      className="pin"
                                      onClick={() =>
                                        pinHandle(data.id, "pinTrue")
                                      }
                                    ></div>
                                  )}
                                </div>
                              )}
                            </div>
                            <div className="mr-10 ml-10 ">
                              <div className="header mt-30">
                                <h4
                                  className="card-event-control"
                                  onClick={() => {
                                    readMore(data.id);
                                  }}
                                >
                                  {data.title}
                                </h4>
                              </div>
                              <div className="date">
                                <div className="calender-icon"></div>{" "}
                                {moment(data?.event_time).format(
                                  "ddd, MMM DD, YYYY"
                                )}{" "}
                                at{" "}
                                {moment(data?.event_time).format("hh:mm A z")}
                              </div>
                              {data.location ? (
                                <div className="location">
                                  <div className="icon"></div>
                                  <span className="card-control-text-oneline">
                                    {data?.location?.split(",")?.splice(-1)}
                                  </span>
                                </div>
                              ) : (
                                <div className="location">
                                  <div className="icon"></div>
                                  <span className="card-control-text-oneline">
                                    Virtual Event
                                  </span>
                                </div>
                              )}
                              <div className="people-pic">
                                <div className="d-flex">
                                  <div className="profiles-img">
                                    {data?.Event_Participant?.map(
                                      (data, idx) => {
                                        return (
                                          idx < 3 && (
                                            <span key={idx}>
                                              <img
                                                src={
                                                  data?.user?.profile_img
                                                    ? data?.user?.profile_img
                                                    : placeholderUser
                                                }
                                                className="image-fit"
                                                alt=""
                                              />
                                            </span>
                                          )
                                        );
                                      }
                                    )}
                                    {data?.Event_Participant?.length > 3 ? (
                                      <span>
                                        +{data?.Event_Participant?.length}
                                      </span>
                                    ) : (
                                      <span>
                                        +{data?.Event_Participant?.length}
                                      </span>
                                    )}
                                  </div>
                                </div>

                                <div>are attending</div>
                              </div>
                              <div className="d-flex">
                                {isOwner ? (
                                  <div
                                    className="btn btn-light text-blue"
                                    onClick={() => {
                                      setOpenCreateModal(!openCreateModal);
                                      setEditEventData(data);
                                    }}
                                  >
                                    Edit
                                  </div>
                                ) : (
                                  <div>
                                    {data.Attend_Event?.status ? (
                                      <div
                                        className="btn btn-light text-blue"
                                        onClick={() =>
                                          attendHandle(data.id, "attendFalse")
                                        }
                                      >
                                        {" "}
                                        Leave Event{" "}
                                      </div>
                                    ) : (
                                      <div
                                        className="btn btn-light text-blue"
                                        onClick={() =>
                                          attendHandle(data.id, "attendTrue")
                                        }
                                      >
                                        {" "}
                                        Attend{" "}
                                      </div>
                                    )}
                                  </div>
                                )}
                                <div
                                  className="share-icon"
                                  onClick={() => {
                                    setOpenShareModal(!openShareModal);
                                    setShareData(data);
                                  }}
                                ></div>
                              </div>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {openCreateModal && (
        <CreateEventModal
          modal={openCreateModal}
          toggle={() => setOpenCreateModal(!openCreateModal)}
          title="Create Event"
          successfulStatus={successfulStatus}
          editEventData={editEventData}
          setEditEventData={setEditEventData}
          source="network"
        />
      )}
      {openShareModal && (
        <ShareModal
          modal={openShareModal}
          toggle={() => setOpenShareModal(!openShareModal)}
          title="Share"
          shareData={shareData}
          type="event"
          atriclePageName={pageName}
          createdAt={createdAt}
          pageID={pageID}
          pageIcon={pageIcon}
          toggleSuccessful={() => setOpenShareModal(!openShareModal)}
        />
      )}
      {successModal && (
        <EventSuccessfulModal
          modal={successModal}
          toggle={() => setSuccessModal(!successModal)}
          title=""
          shareData={popupShareData}
        />
      )}
    </div>
  );
};

export default PageEvent;
