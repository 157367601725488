import React, { useState } from "react";
import { Input, Label, Modal, ModalBody, ModalHeader, Form } from "reactstrap";
import editPencil from "../../../../../assets/img/edit-pencil.svg";
import eye from "../../../../../assets/img/eye.svg";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import {
  getQuestionaryList,
  sendSmartInterview,
} from "../../../../../store/jobs/jobAction";
import DatePickerCustom from "../../../../../core/components/DatePicker/DatePickerReact";
import CreateInterviewTopic from "./CreateInterviewTopic";
import moment from "moment";
import { toast } from "react-toastify";
import TimePicker from "rc-time-picker";
import "rc-time-picker/assets/index.css";
import AppLoader from "../../../../../core/components/Loaders/appLoader/appLoader";
import {
  initPageLoad,
  stopPageLoad,
} from "../../../../../store/loader/loaderActions";
import { useNavigate } from "react-router";

const SendSmartInterview = (props) => {
  const {
    modal,
    toggle,
    setSmartInterviewSuccessModal,
    selectedUser,
    isLoading,
  } = props;
  const dispatch = useDispatch();
  const { questionaryList } = useSelector((state) => state.jobs);
  const { user } = useSelector((state) => state.auth);
  const memberShipSelected = user?.data?.membership?.name;
  const navigate = useNavigate();
  var d = new Date() || new Date(selectedUser?.interview_date);
  let h = (d.getHours() < 10 ? "0" : "") + d.getHours();
  let m = (d.getMinutes() < 10 ? "0" : "") + d.getMinutes();
  const defaultMoment = moment().set({
    hour: d.getHours(),
    minute: d.getMinutes(),
    second: 0, // Assuming you want seconds to be zero in the default time
  });

  var today = new Date();
  const [scheduleDate, setScheduleDate] = useState(
    selectedUser?.interview_date
      ? new Date(selectedUser?.interview_date)
      : new Date()
  );
  const [scheduleTime, setScheduleTime] = useState(defaultMoment);
  const [timeError, setTimeError] = useState("");
  const [createSetModal, setCreateSetModal] = useState(false);
  const [isEditSets, setIsEditSets] = useState(false);
  const [setName, setSetName] = useState("");
  const [scheduledData, setScheduledData] = useState();
  const [timeErrorCheck, setTimeErrorCheck] = useState(true);

  useEffect(() => {
    dispatch(initPageLoad());
    dispatch(getQuestionaryList({ type: "set" }))
      .then(() => dispatch(stopPageLoad()))
      .catch(() => dispatch(stopPageLoad()));
    setSetName(selectedUser?.interview_set);
    // .then((res) => {
    //   console.log("shot res", res);
    //   // setTotalCount(res?.count);
    // });
  }, []);
  useEffect(() => {
    setScheduledData(
      moment(scheduleDate).format("YYYY-MM-DD") +
        " " +
        moment(scheduleTime, "HH:mm:ss").format("HH:mm:ssZ")
    );
  }, [scheduleDate, scheduleTime]);

  const handleSelect = (e, name) => {
    if (e.target.checked) setSetName(name);
  };

  const handleView = (name) => {
    setCreateSetModal(true);
    setIsEditSets(false);
    // setSetName(name);
    // console.log("setName",name);
    // if (setName) {
    dispatch(initPageLoad());
    dispatch(getQuestionaryList({ type: "questions", set: name }))
      .then(() => dispatch(stopPageLoad()))
      .catch(() => dispatch(stopPageLoad()));
    // }
  };
  const handleContinue = () => {
    if (timeError) {
      return;
    }
    const data = {
      interview_set: setName,
      interview_date: scheduledData,
      shortlist_id: selectedUser?.id,
    };
    dispatch(sendSmartInterview(data)).then((res) => {
      if (res.success) {
        // toast.success("Interview sent successfully", {
        //   position: toast.POSITION.BOTTOM_LEFT,
        // });
        setSmartInterviewSuccessModal(true);
      }
    });
  };
  //handleDate logic implemented
  const handleDateChange = (date) => {
    setScheduleDate(date);

    // Calculate the current time when the date is selected
    const formattedTimess = moment(defaultMoment._d).format("HH:mm");
    setScheduleTime(defaultMoment);
    setTimeError("");
    setTimeErrorCheck(false);
  };

  useEffect(() => {
    setScheduledData(
      moment(scheduleDate).format("YYYY-MM-DD") +
        " " +
        moment(scheduleTime, "HH:mm:ss").format("HH:mm:ssZ")
    );
  }, [scheduleTime]);

  useEffect(() => {
    setScheduleTime(defaultMoment);
    setTimeError("");
  }, [scheduleDate]);

  //Timer Logic implemented
  //const [timeError, setTimeError] = useState("");
  const handleTime = (time) => {
    if (!time) {
      return;
    }
    var today = new Date();
    // let formattedDate=moment(today).format("YYYY-MM-DD");
    const formattedTimes = moment(time._d).format("HH:mm");
    // console.log('timeSelected',time._d);
    const momentObject = moment(scheduleDate, "YYYY-MM-DD");
    const jsDateObject = momentObject.toDate();

    let currentTimeFormatted = moment(today);
    let formattedTime = moment(moment().format("L") + " " + formattedTimes);

    if (
      !momentObject.isSameOrAfter(currentTimeFormatted) &&
      formattedTime.diff(currentTimeFormatted) <= 0
    ) {
      setTimeError("Please select a time after the current time");
      setScheduleTime("");
    } else {
      setTimeError("");
      setScheduleTime(time);
    }
  };

  return (
    <Modal
      isOpen={modal}
      toggle={toggle}
      size="md"
      style={{ maxWidth: "544px" }}
      centered
    >
      <div className="modal-radio-view-edit default-recruiter-modal">
        <ModalHeader toggle={() => toggle()}>Select Topic</ModalHeader>
        <ModalBody>
          {isLoading && <AppLoader />}
          <p>
            Set up, save your questionnaire topics for and request a smart
            interview.
          </p>
          <div className="mt-4 radio-outer">
            <Form>
              <div className="date-time-box d-flex mb-5 row">
                {/* <Input
                type="date"
                  value={scheduleDate}
                  onChange={(date) => {
                    setScheduleDate(date);
                    console.log(date);
                  }}
                  min={today}
                  leftAlign={true}
                /> */}
                <div className="col-5">
                  <DatePickerCustom
                    value={scheduleDate}
                    // onChange={(date) => {
                    //   setScheduleDate(date);
                    // }}
                    onChange={(date) => handleDateChange(date)}
                    min={new Date()}
                    // leftAlign={true}
                  />
                </div>
                <div className="col-4">
                  <TimePicker
                    //style={{ fontSize: "14px !important",height:"37px !important"}}
                    showSecond={false}
                    value={scheduleTime}
                    className="customTimePicker"
                    onChange={(selectedTime) => {
                      handleTime(selectedTime);
                      setTimeErrorCheck(false);
                    }}
                    use12Hours
                    placeholder="Select Time"
                  />
                  {timeError && (
                    <p style={{ color: "#0432a3", fontSize: "10px" }}>
                      {timeError}
                    </p>
                  )}

                  {/* <Input
                    type="time"
                    id="time"
                    style={{
                      height: "37px",
                      border: "1px solid #d1dfff",
                      borderRadius: "4px",
                      cursor: "pointer",
                      color: "#212529",
                    }}
                    value={scheduleTime}
                    onChange={(event) => setScheduleTime(event.target.value)}
                  /> */}
                </div>
              </div>
              {console.log("questionaryList", questionaryList?.length)}
              {questionaryList?.length > 0 ? (
                <>
                  {questionaryList?.map((item, index) => (
                    <div
                      className="d-flex justify-content-between mt-3"
                      key={index}
                    >
                      <Label className="d-flex">
                        <Input
                          type="radio"
                          name="general"
                          checked={item?.set === setName}
                          onChange={(e) => handleSelect(e, item?.set)}
                        />{" "}
                        <h5 className="set-name">{item?.set}</h5>
                      </Label>
                      <div className="d-flex view-edit-box">
                        <span className="view-btn">
                          {" "}
                          <img
                            src={eye}
                            alt="Icon"
                            onClick={() => handleView(item?.set)}
                          />
                        </span>
                      </div>
                    </div>
                  ))}
                </>
              ) : memberShipSelected === "Scout" ? (
                <p
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    fontWeight: "400",
                    color: "#0432a3",
                    cursor: "pointer",
                  }}
                  onClick={() =>
                    navigate(`/profile/${user?.data?.id}`, {
                      state: { isOpenCreateQus: true },
                    })
                  }
                >
                  Please create question set
                </p>
              ) : (
                <p
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    fontWeight: "400",
                  }}
                >
                  Upgrade your membership to create questions
                </p>
              )}
              {questionaryList?.length > 0 && (
                <div className="btn-outer text-center mt-4">
                  <div
                    className="btn btn-blue"
                    onClick={() => {
                      handleContinue();
                      // setSmartInterviewSuccessModal(true);
                    }}
                  >
                    Continue
                  </div>
                </div>
              )}
            </Form>
          </div>
        </ModalBody>
      </div>
      {createSetModal && (
        <CreateInterviewTopic
          modal={createSetModal}
          toggle={() => setCreateSetModal(!createSetModal)}
          isEditSets={isEditSets}
          openCreate={true}
          setName={setName}
        />
      )}
    </Modal>
  );
};

export default SendSmartInterview;
