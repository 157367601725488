import { React, useEffect, useState } from "react";
import { call } from "../../api/apiCall";
import Community from "./Community";
import CommunityWebView from "./CommunityWebView";

export default function CommunityContainer() {
  const [data, setData] = useState({});
  const [mode, setMode] = useState("");

  useEffect(() => {
    let params = new URL(document.location).searchParams;
    let param = params.get("mode");
    setMode(param);
  }, []);

  useEffect(() => {
    communityService();
  }, []);

  function communityService() {
    new Promise((resolve, reject) => {
      (async () => {
        try {
          const res = await call(
            "get",
            "api/v1/get-static-pages?title=community_guidelines",
            null,
            null,
            null
          );

          if (res.data.success === true) {
            resolve(res.data);
            setData(res.data.data);
            console.log("Success_res", res.data.data);
          }
          if (res.data.success === false) {
            console.log("fail_res", res);
            resolve(res.data);
          }
        } catch (err) {
          console.log(err);
          reject(err);
        }
      })();
    });
  }
  if (mode === "mobile") {
    return <CommunityWebView data={data} />;
  }
  return <Community data={data} />;
}
