import { call } from "./apiCall";

class ExploreAPI {
  explore(data) {
    console.log(data);
    return new Promise((resolve, reject) => {
      // const token = localStorage.getItem("accessToken");
      (async () => {
        try {
          const res = await call(
            "post",
            "api/v1/explores-list/",
            null,
            data,
            null
          );

          if (res.data.success === true) {
            resolve(res.data);
          }
          if (res.data.success === false) {
            resolve(res.data);
          }
        } catch (err) {
          console.log(err);
          reject(err);
        }
      })();
    });
  }

  exploreDetails(id) {
    return new Promise((resolve, reject) => {
      // const token = localStorage.getItem("accessToken");
      (async () => {
        try {
          const res = await call(
            "get",
            `api/v1/explore/${id}`,
            null,
            null,
            null
          );

          if (res.data.success === true) {
            console.log("Success_res", res);
            resolve(res.data);
          }
          if (res.data.success === false) {
            console.log("fail_res", res);
            resolve(res.data);
          }
        } catch (err) {
          console.log(err);
          reject(err);
        }
      })();
    });
  }
}

export const exploreAPI = new ExploreAPI();
