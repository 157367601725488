import React, { useState } from "react";
import Layout from "../Layout/Layout";
import defaultGroupIcon from "../../../assets/img/default_group_icon.svg";
import ellipse2 from "../../../assets/img/Ellipse2.png";
import ellipse3 from "../../../assets/img/Ellipse3.png";
import CreateGroupModal from "./components/createGroup";
import Loader from "../../../core/components/Loaders/ComponentLoader";
import AppLoader from "../../../core/components/Loaders/appLoader/appLoader";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer, toast } from "react-toastify";
import nopreview from "../../../assets/img/no-preview.png";
import DeleteModal from "../../../core/components/modal/DeleteModal";
import InfiniteScroll from "react-infinite-scroll-component";
import join from "../../../assets/img/join.png";
import ignore from "../../../assets/img/ignore.png";
import accept from "../../../assets/img/Check.png";
import reject from "../../../assets/img/reject.png";
import { useSelector } from "react-redux";
export default function Groups(props) {
  const {
    tabs,
    setTabs,
    searchKey,
    setSearchKey,
    filterSearchData,
    newSuggestedGroups,
    newJoinedGroups,
    newPendingGroups,
    newRecievedGroups,
    newMyGroups,
    openCreateModal,
    setOpenCreateModal,
    readMore,
    joinHandle,
    acceptHandle,
    rejectHandle,
    ignoreHandle,
    isPageLoading,
    editGroupData,
    setEditGroupData,
    withdrawRequestGroup,
    removeModal,
    setRemoveModal,
    withdrawGroupId,
    setWithdrawGroupId,
    currentCount,
    loadMoreData,
    isLoadMore,
    setIsLoadMore,
    leaveHandle,
  } = props;

  const [groupActive, setGroupActive] = useState(false);
  const [reciveGroupActive, setReciveGroupActive] = useState(false);
  //Search on Enter Button
  const [rejectModal, setRejectModal] = useState(false);
  const [rejectId, setRejectId] = useState("");

  const [acceptModal, setAcceptModal] = useState(false);
  const [acceptId, setAcceptId] = useState("");

  const [ignoreModal, setIgnoreModal] = useState(false);
  const [ignoreId, setIgnoreId] = useState("");

  const [confirmDeleteModal, setConfirmDeleteModal] = useState(false);
  const [leaveGroupID, setLeaveGroupID] = useState();
  const { user } = useSelector((state) => state.auth);
  const handleKeyDown = (e) => {
    // Check if the pressed key is "Enter" (key code 13)
    if (e.key === "Enter") {
      filterSearchData(searchKey);
    }
  };

  return (
    <>
      <Layout>
        <div className="col-lg-9">
          <div className="main-center mt-4">
            <div className="search">
              <input
                type="text"
                placeholder="Search Groups"
                onChange={(e) => setSearchKey(e.target.value)}
                onKeyDown={handleKeyDown}
              />
              <div
                className="search-icon"
                onClick={() => filterSearchData(searchKey)}
              ></div>
            </div>
            {isPageLoading && (
              <div className="d-flex justify-content-center align-items-center">
                <AppLoader styleProps={{ position: "fixed", left: "60%" }} />
              </div>
            )}
            <div className="wrapper mt-20">
              <div className="container p-3">
                <div className="overflow">
                  <ul className="nav nav-tabs" role="tablist">
                    <li className="nav-item">
                      <a
                        className="nav-link active"
                        data-bs-toggle="tab"
                        href="#suggested_groups"
                        onClick={() => {
                          setTabs("suggested_groups");
                          setIsLoadMore(true);
                        }}
                      >
                        Suggested Groups
                      </a>
                    </li>
                    <li className="nav-item">
                      <a
                        className="nav-link"
                        data-bs-toggle="tab"
                        onClick={() => {
                          setTabs("joined_groups");
                          setIsLoadMore(true);
                        }}
                        href="#joined_groups"
                      >
                        {" "}
                        Joined Groups
                      </a>
                    </li>
                    <li className="nav-item">
                      <a
                        className="nav-link"
                        data-bs-toggle="tab"
                        onClick={() => {
                          setTabs("pending_groups");
                          setGroupActive(true);
                          setReciveGroupActive(false);
                          setIsLoadMore(true);
                        }}
                        href="#pending_groups"
                      >
                        Group Request
                      </a>
                    </li>
                    <li className="nav-item">
                      <a
                        className="nav-link"
                        data-bs-toggle="tab"
                        onClick={() => {
                          setTabs("my_groups");
                          setIsLoadMore(true);
                        }}
                        href="#my_groups"
                      >
                        My Groups
                      </a>
                    </li>
                    <li className="nav-item">
                      <div
                        className="end"
                        onClick={() => setOpenCreateModal(!openCreateModal)}
                      >
                        + Create Group
                      </div>
                    </li>
                  </ul>
                </div>

                <div className="tab-content mt-20">
                  <div
                    id="suggested_groups"
                    className="container tab-pane active"
                  >
                    <InfiniteScroll
                      dataLength={newSuggestedGroups?.length}
                      next={loadMoreData}
                      hasMore={isLoadMore ? true : false}
                      // loader={<h4>Loading...</h4>}
                      // endMessage={
                      //   <p style={{ textAlign: "center" }}>
                      //     <b>Yay! You have seen it all</b>
                      //   </p>
                      // }
                    >
                      <div className="row">
                        <div className="pages">
                          <div className="networks">
                            <div className="wrapper">
                              <div className="groups ">
                                {newSuggestedGroups ? (
                                  <div className="row ">
                                    {newSuggestedGroups.map((data) => {
                                      return (
                                        <div className="col-lg-4">
                                          <div className="groups-card">
                                            <div className="card card-height-groups">
                                              <div
                                                className="card-img"
                                                onClick={() => {
                                                  readMore(data.id);
                                                }}
                                                style={{ cursor: "pointer" }}
                                              >
                                                {data?.background_img ? (
                                                  <img
                                                    src={data?.background_img}
                                                    className="img-fluid"
                                                    alt=""
                                                    onClick={() => {
                                                      readMore(data.id);
                                                    }}
                                                  />
                                                ) : (
                                                  <></>
                                                )}
                                                <div className="circle-icon">
                                                  <img
                                                    onClick={() => {
                                                      readMore(data.id);
                                                    }}
                                                    src={
                                                      data?.icon
                                                        ? data.icon
                                                        : defaultGroupIcon
                                                    }
                                                    className="circle-icon-group-size"
                                                    alt=""
                                                  />
                                                </div>
                                              </div>
                                              <div className="mr-20 ml-20 mb-20">
                                                <div className="header">
                                                  <h4
                                                    onClick={() => {
                                                      readMore(data.id);
                                                    }}
                                                  >
                                                    {data?.name}
                                                  </h4>
                                                  <div className="text">
                                                    <div className="d-flex align-items-center justify-content-center">
                                                      {data?.type ===
                                                      "public" ? (
                                                        <>
                                                          <div className="globe"></div>
                                                          <p>Public group</p>
                                                        </>
                                                      ) : (
                                                        <>
                                                          <div className="privateIcon"></div>
                                                          <p>Private group</p>
                                                        </>
                                                      )}
                                                    </div>
                                                  </div>
                                                  <p className="group-head-p">
                                                    <span>
                                                      {data?.members_count}
                                                    </span>
                                                    {data?.members_count > 1
                                                      ? "Members"
                                                      : "Member"}
                                                    &nbsp;&nbsp;&nbsp;
                                                    <span>
                                                      {data?.post_count}
                                                    </span>
                                                    Post / day
                                                  </p>
                                                </div>
                                                <div className="text">
                                                  <p className="card-control-text-fourline">
                                                    {data?.description}
                                                  </p>
                                                  <div className="link card-control-text-oneline">
                                                    {data?.hashtags?.map(
                                                      (data, idx) => {
                                                        return (
                                                          <span key={idx}>
                                                            #{data.name} &nbsp;
                                                          </span>
                                                        );
                                                      }
                                                    )}
                                                  </div>
                                                </div>
                                                {data?.members_count ===
                                                data?.members_limit ? (
                                                  <div className="btn btn-light disabled-btn-blue btn-margin-bottom">
                                                    Join
                                                  </div>
                                                ) : data?.is_joined ===
                                                    "pending" ||
                                                  data?.is_joined ===
                                                    "recieved" ? (
                                                  <div
                                                    className="btn btn-light btn-margin-bottom"
                                                    onClick={() => {
                                                      alert(
                                                        "Please wait for group admin response"
                                                      );
                                                    }}
                                                  >
                                                    Requested{" "}
                                                  </div>
                                                ) : (
                                                  <div
                                                    className="btn btn-light btn-margin-bottom"
                                                    onClick={() =>
                                                      joinHandle(
                                                        data.id,
                                                        "joinTrue"
                                                      )
                                                    }
                                                  >
                                                    <div>
                                                      <img
                                                        style={{
                                                          marginRight: "9px",
                                                        }}
                                                        src={join}
                                                        alt="join"
                                                      />
                                                      Join
                                                    </div>
                                                  </div>
                                                )}
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      );
                                    })}
                                  </div>
                                ) : (
                                  <div className="row">
                                    <p
                                      style={{ textAlign: "center" }}
                                      className="my-30"
                                    >
                                      <b>No suggested groups</b>
                                    </p>
                                  </div>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </InfiniteScroll>
                  </div>
                  <div id="joined_groups" className="container tab-pane fade">
                    <InfiniteScroll
                      dataLength={newJoinedGroups?.length}
                      next={loadMoreData}
                      hasMore={isLoadMore ? true : false}
                      // loader={<h4>Loading...</h4>}
                      // endMessage={
                      //   <p style={{ textAlign: "center" }}>
                      //     <b>Yay! You have seen it all</b>
                      //   </p>
                      // }
                    >
                      <div className="row mt-20">
                        <div className="pages">
                          <div className="networks">
                            <div className="wrapper">
                              <div className="groups ">
                                {newJoinedGroups ? (
                                  <div className="row ">
                                    {newJoinedGroups?.map((data) => {
                                      return (
                                        <div className="col-lg-4">
                                          <div className="groups-card">
                                            <div className="card card-height-groups">
                                              <div
                                                className="card-img"
                                                onClick={() => {
                                                  readMore(data.id);
                                                }}
                                                style={{ cursor: "pointer" }}
                                              >
                                                {data?.background_img ? (
                                                  <img
                                                    src={data?.background_img}
                                                    className="img-fluid"
                                                    alt=""
                                                  />
                                                ) : (
                                                  <></>
                                                )}
                                                <div className="circle-icon">
                                                  <img
                                                    src={
                                                      data?.icon
                                                        ? data.icon
                                                        : defaultGroupIcon
                                                    }
                                                    className="circle-icon-group-size"
                                                    alt=""
                                                  />
                                                </div>
                                              </div>
                                              <div className="mr-20 ml-20 mb-20">
                                                <div className="header">
                                                  <h4
                                                    onClick={() => {
                                                      readMore(data.id);
                                                    }}
                                                  >
                                                    {data?.name}
                                                  </h4>
                                                  <div className="text">
                                                    <div className="d-flex align-items-center justify-content-center">
                                                      {data?.type ===
                                                      "public" ? (
                                                        <>
                                                          <div className="globe"></div>
                                                          <p>Public group</p>
                                                        </>
                                                      ) : (
                                                        <>
                                                          <div className="privateIcon"></div>
                                                          <p>Private group</p>
                                                        </>
                                                      )}
                                                    </div>
                                                  </div>
                                                  <p className="group-head-p">
                                                    <span>
                                                      {data?.members?.length}
                                                    </span>
                                                    {data?.members?.length > 1
                                                      ? "Members"
                                                      : "Member"}
                                                    &nbsp;&nbsp;&nbsp;
                                                    <span>
                                                      {data?.post_count}
                                                    </span>
                                                    Post / day
                                                  </p>
                                                </div>
                                                <div className="text">
                                                  <p className="card-control-text-fourline">
                                                    {data?.description}
                                                  </p>
                                                  <div className="link card-control-text-oneline">
                                                    {data?.hashtags?.map(
                                                      (data, idx) => {
                                                        return (
                                                          <span key={idx}>
                                                            #{data.name} &nbsp;
                                                          </span>
                                                        );
                                                      }
                                                    )}
                                                  </div>
                                                </div>
                                                {data?.admin.id ===
                                                user?.data?.id ? (
                                                  <div
                                                    className="btn btn-light btn-margin-bottom"
                                                    onClick={() =>
                                                      readMore(data?.id)
                                                    }
                                                  >
                                                    View{" "}
                                                  </div>
                                                ) : data?.members_count ===
                                                  data?.members_limit ? (
                                                  <div
                                                    className="btn btn-light disabled-btn-blue btn-margin-bottom"
                                                    onClick={() =>
                                                      toast.warn(
                                                        "Can't join more members",
                                                        {
                                                          position:
                                                            toast.POSITION
                                                              .BOTTOM_LEFT,
                                                        }
                                                      )
                                                    }
                                                  >
                                                    Join
                                                  </div>
                                                ) : data?.is_joined ===
                                                  "confirm" ? (
                                                  <div
                                                    className="btn btn-light btn-margin-bottom"
                                                    onClick={() => {
                                                      setLeaveGroupID(data?.id);
                                                      setConfirmDeleteModal(
                                                        true
                                                      );
                                                    }}
                                                  >
                                                    Leave{" "}
                                                  </div>
                                                ) : data?.is_joined ===
                                                    "pending" ||
                                                  data?.is_joined ===
                                                    "recieved" ? (
                                                  <div
                                                    className="btn btn-light btn-margin-bottom"
                                                    onClick={() => {
                                                      alert(
                                                        "Please wait for group admin response"
                                                      );
                                                    }}
                                                  >
                                                    Requested{" "}
                                                  </div>
                                                ) : (
                                                  <div
                                                    className="btn btn-light btn-margin-bottom"
                                                    onClick={() =>
                                                      joinHandle(
                                                        data.id,
                                                        "joinTrue"
                                                      )
                                                    }
                                                  >
                                                    Join
                                                  </div>
                                                )}
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      );
                                    })}
                                  </div>
                                ) : (
                                  <div className="row">
                                    <p
                                      style={{ textAlign: "center" }}
                                      className="my-30"
                                    >
                                      <b>Not joined any group</b>
                                    </p>
                                  </div>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </InfiniteScroll>
                  </div>
                  <div id="pending_groups" className="container tab-pane fade">
                    <div className="row mb-6">
                      <div className="col-12">
                        <div
                          className={
                            groupActive ? "btn blue-btn" : "btn border-btn"
                          }
                          style={{ borderRadius: "12px" }}
                          onClick={() => {
                            setTabs("pending_groups");
                            setGroupActive(true);
                            setReciveGroupActive(false);
                            setIsLoadMore(true);
                          }}
                        >
                          Sent Invitations
                        </div>
                        <div
                          className={
                            reciveGroupActive
                              ? "btn blue-btn"
                              : "btn border-btn"
                          }
                          style={{ borderRadius: "12px" }}
                          // onClick={() => setTabs("my_drafts")}
                          onClick={() => {
                            setTabs("recieved_groups");
                            setReciveGroupActive(true);
                            setGroupActive(false);
                            setIsLoadMore(true);
                          }}
                        >
                          Received Invitations
                        </div>
                      </div>
                    </div>
                    {groupActive && (
                      <InfiniteScroll
                        dataLength={currentCount}
                        next={loadMoreData}
                        hasMore={newPendingGroups ? true : false}
                        // loader={<h4>Loading...</h4>}
                        // endMessage={
                        //   <p style={{ textAlign: "center" }}>
                        //     <b>Yay! You have seen it all</b>
                        //   </p>
                        // }
                      >
                        <div className="row mt-20">
                          <div className="pages">
                            <div className="networks">
                              <div className="wrapper">
                                <div className="groups ">
                                  {newPendingGroups ? (
                                    <div className="row ">
                                      {newPendingGroups?.map((data) => {
                                        return (
                                          <div className="col-lg-4">
                                            <div className="groups-card">
                                              <div className="card card-height-groups">
                                                <div
                                                  className="card-img"
                                                  onClick={() => {
                                                    readMore(data.id);
                                                  }}
                                                  style={{ cursor: "pointer" }}
                                                >
                                                  {data?.background_img ? (
                                                    <img
                                                      src={data?.background_img}
                                                      className="img-fluid"
                                                      alt=""
                                                    />
                                                  ) : (
                                                    <></>
                                                  )}
                                                  <div className="circle-icon">
                                                    <img
                                                      src={
                                                        data?.icon
                                                          ? data.icon
                                                          : defaultGroupIcon
                                                      }
                                                      className="circle-icon-group-size"
                                                      alt=""
                                                    />
                                                  </div>
                                                </div>
                                                <div className="mr-20 ml-20 mb-20">
                                                  <div className="header">
                                                    <h4
                                                      onClick={() => {
                                                        readMore(data.id);
                                                      }}
                                                    >
                                                      {data?.name}
                                                    </h4>
                                                    <div className="text">
                                                      <div className="d-flex align-items-center justify-content-center">
                                                        {data?.type ===
                                                        "public" ? (
                                                          <>
                                                            <div className="globe"></div>
                                                            <p>Public group</p>
                                                          </>
                                                        ) : (
                                                          <>
                                                            <div className="privateIcon"></div>
                                                            <p>Private group</p>
                                                          </>
                                                        )}
                                                      </div>
                                                    </div>
                                                    <p className='className="group-head-p'>
                                                      <span>
                                                        {data?.members?.length}
                                                      </span>
                                                      {data?.members?.length > 1
                                                        ? "Members"
                                                        : "Member"}
                                                      &nbsp;&nbsp;&nbsp;
                                                      <span>
                                                        {data?.post_count}
                                                      </span>
                                                      Post / day
                                                    </p>
                                                  </div>
                                                  <div className="text">
                                                    <p className="card-control-text-fourline">
                                                      {data?.description}
                                                    </p>
                                                    <div className="link card-control-text-oneline">
                                                      {data?.hashtags?.map(
                                                        (data, idx) => {
                                                          return (
                                                            <span key={idx}>
                                                              #{data.name}{" "}
                                                              &nbsp;
                                                            </span>
                                                          );
                                                        }
                                                      )}
                                                    </div>
                                                  </div>

                                                  <div
                                                    className="btn btn-light btn-margin-bottom"
                                                    onClick={() => {
                                                      setRemoveModal(
                                                        !removeModal
                                                      );
                                                      setWithdrawGroupId(
                                                        data.id
                                                      );
                                                    }}
                                                  >
                                                    Cancel Request{" "}
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        );
                                      })}
                                    </div>
                                  ) : (
                                    <div className="row">
                                      <p
                                        style={{ textAlign: "center" }}
                                        className="my-30"
                                      >
                                        <b>No group request</b>
                                      </p>
                                    </div>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </InfiniteScroll>
                    )}
                    {reciveGroupActive && (
                      <InfiniteScroll
                        dataLength={currentCount}
                        next={loadMoreData}
                        hasMore={newPendingGroups ? true : false}
                      >
                        <div className="row mt-20">
                          <div className="pages">
                            <div className="networks">
                              <div className="wrapper">
                                <div className="groups ">
                                  {newRecievedGroups ? (
                                    <div className="row ">
                                      {newRecievedGroups?.map((data) => {
                                        return (
                                          <div className="col-lg-4">
                                            <div className="groups-card">
                                              <div className="card card-height-groups">
                                                <div
                                                  className="card-img"
                                                  onClick={() => {
                                                    readMore(data.id);
                                                  }}
                                                  style={{ cursor: "pointer" }}
                                                >
                                                  {data?.background_img ? (
                                                    <img
                                                      src={data?.background_img}
                                                      className="img-fluid"
                                                      alt=""
                                                    />
                                                  ) : (
                                                    <></>
                                                  )}
                                                  <div className="circle-icon">
                                                    <img
                                                      src={
                                                        data?.icon
                                                          ? data.icon
                                                          : defaultGroupIcon
                                                      }
                                                      className="circle-icon-group-size"
                                                      alt=""
                                                    />
                                                  </div>
                                                </div>
                                                <div className="mr-20 ml-20 mb-20">
                                                  <div className="header">
                                                    <h4
                                                      onClick={() => {
                                                        readMore(data.id);
                                                      }}
                                                    >
                                                      {data?.name}
                                                    </h4>
                                                    <div className="text">
                                                      <div className="d-flex align-items-center justify-content-center">
                                                        {data?.type ===
                                                        "public" ? (
                                                          <>
                                                            <div className="globe"></div>
                                                            <p>Public group</p>
                                                          </>
                                                        ) : (
                                                          <>
                                                            <div className="privateIcon"></div>
                                                            <p>Private group</p>
                                                          </>
                                                        )}
                                                      </div>
                                                    </div>
                                                    <p className='className="group-head-p'>
                                                      <span>
                                                        {data?.members?.length}
                                                      </span>
                                                      {data?.members?.length > 1
                                                        ? "Members"
                                                        : "Member"}
                                                      &nbsp;&nbsp;&nbsp;
                                                      <span>
                                                        {data?.post_count}
                                                      </span>
                                                      Post / day
                                                    </p>
                                                  </div>
                                                  <div className="text">
                                                    <p className="card-control-text-fourline">
                                                      {data?.description}
                                                    </p>
                                                    <div className="link card-control-text-oneline">
                                                      {data?.hashtags?.map(
                                                        (data, idx) => {
                                                          return (
                                                            <span key={idx}>
                                                              #{data.name}{" "}
                                                              &nbsp;
                                                            </span>
                                                          );
                                                        }
                                                      )}
                                                    </div>
                                                  </div>
                                                  <div
                                                    className="d-flex"
                                                    style={{
                                                      justifyContent: "center",
                                                      marginTop: "100px",
                                                    }}
                                                  >
                                                    <div
                                                      className="btn-width btn btn-light "
                                                      onClick={() => {
                                                        setAcceptModal(true);
                                                        setAcceptId(data.id);
                                                      }}
                                                    >
                                                      <img
                                                        src={accept}
                                                        alt="Acept"
                                                        width="20px"
                                                        height="20px"
                                                      />
                                                    </div>
                                                    <div
                                                      className="btn-width btn btn-light "
                                                      onClick={() => {
                                                        setRejectModal(true);
                                                        setRejectId(data.id);
                                                      }}
                                                    >
                                                      <img
                                                        src={reject}
                                                        alt="Reject"
                                                        width="20px"
                                                        height="20px"
                                                      />{" "}
                                                    </div>
                                                    <div
                                                      className=" btn-width btn btn-light "
                                                      onClick={() => {
                                                        setIgnoreModal(true);
                                                        setIgnoreId(data.id);
                                                      }}
                                                    >
                                                      <img
                                                        src={ignore}
                                                        width="20px"
                                                        height="20px"
                                                        alt="Ignore"
                                                      />
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        );
                                      })}
                                    </div>
                                  ) : (
                                    <div className="row">
                                      <p
                                        style={{ textAlign: "center" }}
                                        className="my-30"
                                      >
                                        <b>No group request</b>
                                      </p>
                                    </div>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </InfiniteScroll>
                    )}
                  </div>
                  <div id="my_groups" className="container tab-pane fade">
                    <InfiniteScroll
                      dataLength={newMyGroups?.length}
                      next={loadMoreData}
                      hasMore={isLoadMore ? true : false}
                      // loader={<h4>Loading...</h4>}
                      // endMessage={
                      //   <p style={{ textAlign: "center" }}>
                      //     <b>Yay! You have seen it all</b>
                      //   </p>
                      // }
                    >
                      <div className="row ">
                        <div className="pages">
                          <div className="networks">
                            <div className="wrapper">
                              <div className="groups ">
                                {newMyGroups ? (
                                  <div className="row ">
                                    {newMyGroups?.map((data) => {
                                      return (
                                        <div className="col-lg-4">
                                          <div className="groups-card">
                                            <div className="card card-height-groups">
                                              <div
                                                className="card-img"
                                                onClick={() => {
                                                  readMore(data.id);
                                                }}
                                                style={{ cursor: "pointer" }}
                                              >
                                                {data?.background_img ? (
                                                  <img
                                                    src={data?.background_img}
                                                    className="img-fluid"
                                                    alt=""
                                                  />
                                                ) : (
                                                  <></>
                                                )}
                                                <div className="circle-icon">
                                                  <img
                                                    src={
                                                      data?.icon
                                                        ? data.icon
                                                        : defaultGroupIcon
                                                    }
                                                    className="circle-icon-group-size"
                                                    alt=""
                                                  />
                                                </div>
                                              </div>
                                              <div className="mr-20 ml-20 mb-20">
                                                <div className="header">
                                                  <h4
                                                    onClick={() => {
                                                      readMore(data.id);
                                                    }}
                                                  >
                                                    {data?.name}
                                                  </h4>
                                                  <div className="text">
                                                    <div className="d-flex align-items-center justify-content-center">
                                                      {data?.type ===
                                                      "public" ? (
                                                        <>
                                                          <div className="globe"></div>
                                                          <p>Public group</p>
                                                        </>
                                                      ) : (
                                                        <>
                                                          <div className="privateIcon"></div>
                                                          <p>Private group</p>
                                                        </>
                                                      )}
                                                    </div>
                                                  </div>
                                                  <p className="group-head-p">
                                                    <span>
                                                      {data?.members_count}
                                                    </span>
                                                    {data?.members_count > 1
                                                      ? "Members"
                                                      : "Member"}
                                                    &nbsp;&nbsp;&nbsp;
                                                    <span>
                                                      {data?.post_count}
                                                    </span>
                                                    Post / day
                                                  </p>
                                                </div>
                                                <div className="text">
                                                  <p className="card-control-text-fourline">
                                                    {data?.description}
                                                  </p>
                                                  <div className="link card-control-text-oneline">
                                                    {data?.hashtags?.map(
                                                      (data, idx) => {
                                                        return (
                                                          <span key={idx}>
                                                            #{data.name} &nbsp;
                                                          </span>
                                                        );
                                                      }
                                                    )}
                                                  </div>
                                                </div>
                                                <div
                                                  className="btn btn-light btn-margin-bottom"
                                                  onClick={() => {
                                                    setOpenCreateModal(
                                                      !openCreateModal
                                                    );
                                                    setEditGroupData(data);
                                                  }}
                                                >
                                                  Edit
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      );
                                    })}
                                  </div>
                                ) : (
                                  <div className="row">
                                    <p
                                      style={{ textAlign: "center" }}
                                      className="my-30"
                                    >
                                      <b>No created groups</b>
                                    </p>
                                  </div>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </InfiniteScroll>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <ToastContainer />
        </div>
        {openCreateModal && (
          <CreateGroupModal
            modal={openCreateModal}
            toggle={() => setOpenCreateModal(!openCreateModal)}
            title="Create Group"
            editGroupData={editGroupData}
            setEditGroupData={setEditGroupData}
          />
        )}
        {removeModal && (
          <DeleteModal
            modal={DeleteModal}
            toggle={() => setRemoveModal(!removeModal)}
            title="Withdraw Confirmation"
            deleteConfirm={() => {
              withdrawRequestGroup(withdrawGroupId);
            }}
            text="Are you want to sure withdraw request?"
          />
        )}

        {acceptModal && (
          <DeleteModal
            modal={DeleteModal}
            toggle={() => setAcceptModal(!acceptModal)}
            title="Accept Request Confirmation"
            deleteConfirm={() => {
              acceptHandle(acceptId);
            }}
            text="Are you want to Accept request?"
          />
        )}

        {ignoreModal && (
          <DeleteModal
            modal={DeleteModal}
            toggle={() => setIgnoreModal(!ignoreModal)}
            title="Ignore Request Confirmation"
            deleteConfirm={() => {
              ignoreHandle(ignoreId);
            }}
            text="Are you want to Ignore request?"
          />
        )}

        {rejectModal && (
          <DeleteModal
            modal={DeleteModal}
            toggle={() => setRejectModal(!rejectModal)}
            title="Reject Request Confirmation"
            deleteConfirm={() => {
              rejectHandle(rejectId);
            }}
            text="Are you want to sure Reject request?"
          />
        )}
        {confirmDeleteModal && (
          <DeleteModal
            modal={DeleteModal}
            toggle={() => setConfirmDeleteModal(!confirmDeleteModal)}
            title="Leave Confirmation"
            deleteConfirm={() => {
              leaveHandle(leaveGroupID);
            }}
            text="Are you want to sure leave this group?"
          />
        )}
      </Layout>
    </>
  );
}
