import { call } from "./apiCall";

class NewsletterApi {
  getNewsletters(token, query) {
    return new Promise((resolve, reject) => {
      let token = localStorage.getItem("accessToken");
      (async () => {
        try {
          const res = await call(
            "get",
            // query? `api/v1/feed/newsletter-list?type=${query}`: 
             `api/v1/feed/newsletter-list`,
            query,
            null,
            token
          );
          if (res.data.success === true) {
            resolve(res.data);
          }
          if (res.data.success === false) {
            resolve(res.data);
          }
        } catch (err) {
          console.log(err);
          reject(err);
        }
      })();
    });
  }

  getOthersNewsletters( params) {
    let token = localStorage.getItem("accessToken");
      return new Promise((resolve, reject) => {
        (async () => {
          try {
            const res = await call(
              "get",
             `api/v1/feed/newsletter-list`,
              params,
              null,
              token
            );
            if (res.data.success === true) {
              resolve(res.data);
            }
            if (res.data.success === false) {
              resolve(res.data);
            }
          } catch (err) {
            console.log(err);
            reject(err);
          }
        })();
      });
    
  }

  createNewsletter(data, token) {
    return new Promise( (resolve, reject) => {
        (async () => {
            try {
                const res = await call(
                    "post",
                    "api/v1/feed/newsletter",
                    null,
                    data,
                    token
                )
                console.log(res.data)
                resolve(res.data)
            } catch (err) {
                console.log(err)
                reject(err)

            }
        })()
    })
}

editNewsletter(data, token) {
  return new Promise( (resolve, reject) => {
      (async () => {
          try {
              const res = await call(
                  "patch",
                  "api/v1/feed/newsletter",
                  null,
                  data,
                  token
              )
              console.log(res.data)
              resolve(res.data)
          } catch (err) {
              console.log(err)
              reject(err)

          }
      })()
  })
}


subscribeNewsletter(id, token) {
  return new Promise( (resolve, reject) => {
      (async () => {
          try {
              const res = await call(
                  "post",
                  `api/v1/feed/subscribe-newsletter/${id}`,
                  null,
                  null,
                  token
              )
              console.log(res.data)
              resolve(res.data)
          } catch (err) {
              console.log(err)
              reject(err)
          }
      })()
  })
}

getNewsletterDetail(id, token) {
  return new Promise( (resolve, reject) => {
      (async () => {
          try {
              const res = await call(
                  "get",
                  `api/v1/feed/newsletter-list?id=${id}`,
                  null,
                  null,
                  token
              )
              console.log(res.data)
              resolve(res.data)
          } catch (err) {
              console.log(err)
              reject(err)
          }
      })()
  })
}

likeNewsletter(data) {
  let token = localStorage.getItem("accessToken");
  return new Promise( (resolve, reject) => {
      (async () => {
          try {
              const res = await call(
                  "post",
                  `api/v1/feed/reaction`,
                  null,
                  data,
                  token
              )
              console.log(res.data)
              resolve(res.data)
          } catch (err) {
              console.log(err)
              reject(err)
          }
      })()
  })
}

commentNewsletter(data) {
  let token = localStorage.getItem("accessToken");
  return new Promise( (resolve, reject) => {
      (async () => {
          try {
              const res = await call(
                  "patch",
                  `api/v1/feed/comment`,
                  null,
                  data,
                  token
              )
              console.log(res.data)
              resolve(res.data)
          } catch (err) {
              console.log(err)
              reject(err)
          }
      })()
  })
}

getCommentDetails(data) {
    let token = localStorage.getItem("accessToken");
    return new Promise((resolve, reject) => {
      (async () => {
        try {
          const res = await call(
            "get",
            `api/v1/feed/comments`,
            data,
            null,
            token
          );
          console.log(res.data)
          resolve(res.data);  
        } catch (err) {
          console.log(err);
          reject(err);
        }
      })();
    });
  }
  
}

export const newsletterApi = new NewsletterApi();
