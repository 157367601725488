import React, { useState } from "react";
import { Modal, ModalBody } from "reactstrap";
import featureIcon from "../../../../assets/img/feature.svg";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

const JobPostSuccessful = (props) => {
  const { modal, toggle, title, createdJobData, talentPool } = props;
  const navigate = useNavigate();
  const shareUrl = `${process.env.REACT_APP_WEB_STAGE_URL}jobdetails/${createdJobData?.id}`

  const handleClose = () => {
    toggle();
    if (!talentPool) navigate("/jobs");
  };
  const handleShare = () => {
    // Get the text field
    var copyText = document.getElementById("myInput");

    // Select the text field
    copyText.select();
    copyText.setSelectionRange(0, 99999); // For mobile devices

    // Copy the text inside the text field
    navigator.clipboard.writeText(copyText.value);

    // Alert the copied text
    toast.success("Job Detail url coppied!", {
      position: toast.POSITION.BOTTOM_LEFT,
    });
  };

  return (
    <Modal size="md" isOpen={modal} style={{ maxWidth: "600px" }} centered>
      <ModalBody>
        <div className="event-create-popup">
          <div className="check"></div>
          <div className="text-center">
            <h4>Your Job was created Successfully</h4>
            <div className="line mt-20 mb-20"></div>
            <div className="button mt-30">
              <button className="btn btn-light text-blue" onClick={handleClose}>
                Close
              </button>
              <button className="btn btn-blue ml-2">
                <div
                  className="d-flex align-items-center"
                  onClick={() => {
                    // navigate('/jobdetails/'
                    // )
                    handleShare();
                  }}
                >
                  <i className="ci-share"></i>Share
                  <input
                    type="text"
                    value={shareUrl}
                    id="myInput"
                    style={{ display: "none" }}
                  />
                </div>
              </button>
              <button className="btn btn-blue ml-2">
                <div
                  className="d-flex align-items-center gap-2"
                  onClick={() => {
                    navigate("/createcampaign", {
                      state: {
                        isFeatured: true,
                        jobId: createdJobData?.id,
                      },
                    });
                  }}
                >
                  <img src={featureIcon} alt="feature" />
                  <span>Feature</span>
                </div>
              </button>
            </div>
          </div>
        </div>
      </ModalBody>
    </Modal>
  );
};

export default JobPostSuccessful;
