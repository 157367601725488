import React, { useRef, useState, useEffect } from "react";
import edit from "../../../../assets/img/edit.svg";
import "react-toastify/dist/ReactToastify.css";
import { call } from "../../../../api/apiCall";
import Loader from "../../Loaders/ComponentLoader";
import { initLoad, stopLoad } from "../../../../store/loader/loaderActions";
import { useDispatch, useSelector } from "react-redux";
import { ToastContainer } from "react-toastify";
import { WEB_URL } from "../../../../config";
import {
  getUserSetting,
  updateSettingDetails,
} from "../../../../store/setting/SettingAction";
import { toast } from "react-toastify";
import { useParams } from "react-router-dom";

const EditUrl = (props) => {
  const {
    EditPageURL,
    pageID,
    pageNavURL,
    title,

    staticUrlText,
    handleSubmit,
    editURL,
    setEditURL,
    textURL,
    setTextURL,
    pageNavURLStatus,
    setPageNavURLStatus,
    urlErrorMessage,
    setUrlErrorMessage,
    checkUrlExist,
    isEnable,
    setIsEnable,
    urlCanEdit,
    updatePageStatus,
  } = props;

  const params = useParams();
  const inputRef = useRef();
  const validation = (text) => {
    let textValid = /^(?=.*[a-zA-Z])[a-zA-Z0-9]+$/;
    if (!textValid.test(text)) {
      setUrlErrorMessage(
        "Please enter a valid URL (only alphanumeric and underscores allowed)"
      );
      setIsEnable(true);
    } else {
      setUrlErrorMessage("");
      setIsEnable(false);
    }
  };

  const toggleInput = () => {
    setEditURL(false);
    inputRef?.current?.focus();
  };
  const handleInput = (e) => {
    setTextURL(e.target.value);
    // setIsEnable(false)
    validation(e.target.value);
    // setErrorMessage("");
    // setPageNavURLStatus("");
  };

  useEffect(() => {
    if (textURL !== pageNavURL) {
      checkUrlExist();
    }
    setPageNavURLStatus("");
  }, [textURL]);

  const handleCancel = () => {
    setEditURL(!editURL);
    setPageNavURLStatus("");
    setTextURL(pageNavURL);
  };

  return (
    <>
      {/* {isLoading && <Loader />} */}
      <React.Fragment>
        <div className="insight-side">
          <div className="bg-white b-5 ">
            <div className="row p-20">
              <div className="col-8">
                <h2 className="text-dark">{title}</h2>
              </div>
              {urlCanEdit && (
                <div className="col-4">
                  <div className="float-end">
                    <div className="edit" onClick={() => toggleInput()}>
                      <img src={edit} alt="edit" />
                    </div>
                  </div>
                </div>
              )}
            </div>

            <div className="line"></div>
            {/* {textURL &&  */}
            <>
              {updatePageStatus && (
                <a
                  href={`${staticUrlText}${textURL}`}
                  className="text-blue-500"
                  style={{
                    wordWrap: "break-word",
                    display: "block",
                    padding: "13px",
                  }}
                >
                  {`https://nobelpagestage.dev.vinove.com${staticUrlText}${
                    textURL || pageNavURL || ""
                  }`}
                </a>
              )}

              {!updatePageStatus && (
                <a
                  href={`${staticUrlText}${textURL}`}
                  className="text-blue-500"
                  style={{
                    wordWrap: "break-word",
                    display: "block",
                    padding: "13px",
                  }}
                >
                  {`https://nobelpagestage.dev.vinove.com${staticUrlText}${
                    textURL || pageNavURL || pageID || ""
                  }`}
                </a>
              )}
            </>
            {/* } */}
            <div className="p-20">
              <div className="row ">
                <div className="col-12 setting-popup ">
                  {!editURL ? (
                    <div className="modal-body" style={{ padding: "0px" }}>
                      <div className="text-box position-relative">
                        <input
                          className="link border-none"
                          style={{ width: "75%" }}
                          value={textURL || ""}
                          onChange={(e) => handleInput(e)}
                        />
                        <div
                          className={
                            pageNavURLStatus === "available"
                              ? "available"
                              : "unAvailable" + " position-absolute"
                          }
                          style={{
                            right: "4px",
                            color:
                              pageNavURLStatus === "avilable" ? "green" : "red",
                          }}
                        >
                          {pageNavURLStatus}
                        </div>
                      </div>
                      {urlErrorMessage && (
                        <label className="label-input100 text-danger">
                          {urlErrorMessage}
                        </label>
                      )}
                      <p>
                        Note: Please do not use spaces, symbols, numbers or
                        special characters.
                      </p>
                    </div>
                  ) : (
                    <></>
                  )}
                  {!editURL ? (
                    <div className="button">
                      <button
                        className="btn text-blue"
                        onClick={() => {
                          handleCancel();
                        }}
                      >
                        Cancel
                      </button>
                      <button
                        disabled={isEnable}
                        className=" btn btn-blue"
                        // onClick={() => checkProfileLink()}
                        onClick={() => handleSubmit()}
                      >
                        Save
                      </button>
                    </div>
                  ) : (
                    <></>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    </>
  );
};

export default EditUrl;
