import React, { useState } from "react";
import logo from "../../assets/img/np-logo.svg";
//import emailimg from "../../assets/img/a.svg";
import { Link, useNavigate } from "react-router-dom";
import Header from "../../core/components/layout/Header/Header";
import Heading from "../../core/components/layout/Heading";
import Footer from "../../core/components/layout/footer/Footer";
import FormInput from "../../core/components/Inputs/FormInput";
import "./Forget.scss";
import { call } from "../../api/apiCall";

export default function Forgot() {
  const [email, setEmail] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [showErrorMessage, setShowErrorMessage] = useState("");

  const navigate = useNavigate();
  const handleSubmit = async () => {
    let forgotPassData = {
      email: email,
    };
    try {
      const res = await call(
        "post",
        "api/v1/auth/forgot-password",
        null,
        forgotPassData
      );

      if (res.data.success === true) {
        //  console.log("forgot res", res.data.message);

        navigate("/otp", { state: { email: email } });
      }
      // if (res.response.data.success === false) {
      else {
        console.log("forgot res unsuccess", res);
        return;
      }
    } catch (err) {
      console.log(err);
      if (err.response.data.message === "No record found.")
        setShowErrorMessage(
          `We couldn't find any account associated with
          ${email}. Please try with a registered email.`
        );
      else setShowErrorMessage(err.response.data.message);
    }
  };

  const handelChangeEmail = (e) => {
    setEmail(e.target.value);
    setErrorMessage("");
  };

  const validation = () => {
    let emailvalid =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (!email) {
      setErrorMessage("Please enter your email address.");
    } else if (!emailvalid.test(email)) {
      setErrorMessage("Please enter a valid email address.");
    } else {
      handleSubmit();
    }
  };

  return (
    <div>
      <Header />
      <div className="banner">
        <div className="container">
          <div className="row">
            <div className="col-lg-6">
              <Heading />
              <h1 style={{ fontSize: "48px", color: "white" }}> Your Professional Networking Platform!</h1>
              <p style={{ fontSize: "16px", color: "white" }}>Connect, collaborate, and grow with professionals worldwide. Explore opportunities, showcase your skills, and stay ahead in your industry. Join us today and unlock your potential. Here Skills win over Bias!</p>
            </div>
            <div className="col-lg-6">
              <div className="forgot" style={{ marginTop: "60px" }}>
                <form
                  className="auth-form"
                  onSubmit={(e) => {
                    e.preventDefault();
                  }}
                >
                  <h2 className="dark-blue-color">Forgot Password?</h2>
                  <p style={{ fontSize: "14px" }}>
                    Enter your registered email address below, and we'll send
                    you a one-time password (OTP) to reset your password
                  </p>

                  <FormInput
                    type="email"
                    label="Email"
                    //icon={emailimg}
                    style={{ paddingInline: "10px" }}
                    onChange={handelChangeEmail}
                    placeholder="Enter Email"
                    error={errorMessage}
                    onClick={() => {
                      setShowErrorMessage("");
                    }}
                    containerClassName={errorMessage ? "error-form" : ""}
                  />
                  <span className="use-phone-text">
                    {/* <Link to="/phone">Use phone number </Link> */}
                  </span>
                  {/* <div className="text-center d-grid mt-2">
                    <div className="btn btn-blue" onClick={validation}>
                      Send OTP
                    </div>
                  </div> */}
                  {/*                   
                  <div className="btn btn-blue" onClick={validation}>
                  </div> */}
                  <Link to="/">
                    <div style={{ color: "rgb(4, 50, 163)", fontWeight: "bolder", marginTop: "10px", cursor: "pointer" }}>Back</div>

                  </Link>


                  <div className="text-center d-grid mt-3">
                    <div className="btn btn-blue" onClick={validation}>
                      Send OTP
                    </div>
                  </div>
                  {showErrorMessage && (
                    <label className="label-input100 text-danger">
                      {showErrorMessage}
                    </label>
                  )}
                </form>
              </div>
            </div>
          </div>
          <div className="minHeight"></div>
        </div>
        <div className="n-logo">
          <img src={logo} alt="" width="100%" />
        </div>
      </div>
      <Footer />
    </div>
  );
}
