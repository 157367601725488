import { GET_BLOCKED_USERS } from "../types";
import { settingApi } from "../../api/settingApi";
import { peopleApi } from "../../api/peopleApi";

export const getBlockList = (token, search = null) => (dispatch) => {
    return settingApi.getBlockList(token, search).then(
      (response) => {
        dispatch({
            type: GET_BLOCKED_USERS,
            payload: response
          });
        return Promise.resolve(response);
      },
      (error) => {
        console.log(error);
        return Promise.reject(error);
      }
    );
}

export const block_user = (id) => (dispatch) => {
  return peopleApi.blockUser(id).then((response) => {
    return Promise.resolve(response);
  });
};

export const unblockUser = (id) => (dispatch) =>{
  return peopleApi.unblockUser(id).then((response) => {
    return Promise.resolve(response);
  });
}

export const updateBlockSetting = (id, data) => (dispatch) =>{
  return peopleApi.updateBlock(id,data).then((response) => {
    return Promise.resolve(response);
  });
}
   
      