import React, { useEffect } from "react";
import "../../dashboard.scss";
import "./poll.scss"
import {
  votePoll,
  votesResultAction,
} from "../../../.././store/dashboard/dashboardActions";
import { useDispatch, useSelector } from "react-redux";
import { useState } from "react";
import ViewVotes from "./viewPollModal";
import moment from "moment";
import { Progress } from "reactstrap";

export default function ViewPollComp(props) {
  const dispatch = useDispatch();
  const [isVoted, setIsVoted] = useState(false);
  const [isId, setIsId] = useState(-1);
  const [viewPoll, setViewPoll] = useState(false);
  const [firstOption, setFirstOption] = useState();
  const [totalVoteCount, setTotalVoteCount] = useState(0);

  // const [voteData, setVoteData] = useState();
  // const { votesResult } = useSelector((state) => state.dashboard);

  useEffect(() => {
    if (props.pollData.Poll_voted) {
      setIsVoted(true);
    }
  }, []);

  useEffect(() => {
    votePercent();
    getTime(props.pollData.endTime)
  }, [props.pollData]);

  const votePercent = () => {
    let totalVoteCount = 0;
    props.pollData.options.map((vote) => {
      setTotalVoteCount((totalVoteCount += vote.vote_count));
    });
  };

  const getTime = (date) => {
    const now = moment();
    const hours = now.diff(date, 'hours');
    const days = now.diff(date, 'days');
    const weeks = now.diff(date, 'weeks');   
    if (hours >=0 && days >=0) {
    setIsVoted(true);
    }
  }

  const voted_notVoted = (item, i) => {
    if (isVoted) {
      return (
        <div>
            <div className="font10  vote-percent">
              {totalVoteCount ?
               <div className="text-end">
                {Math.floor((item?.vote_count / totalVoteCount) * 100)}%
              </div> :
              <div className="text-end">
              0 %
            </div>
              }
              <Progress
                value={totalVoteCount ?
                   Math.floor((item?.vote_count / totalVoteCount)* 100):0}
              > 
              {item?.title}
                </Progress>
            </div>
        </div>
      );
    } else {
      return (
        <button
          className={
            (props?.pollData.Poll_voted?.option_id &&
              props?.pollData.Poll_voted?.option_id == item.id) ||
            isId === i
              ? "btn-poll"
              : "btn-poll"
          }
          onClick={() => {
            vote_Poll(item.id, i);
          }}
        >
          {item?.title}
        </button>
      );
    }
  };

  const getVoteData = (pollId) => {
    // let token = window.localStorage.getItem("accessToken");
    // dispatch(votesResultAction(token, pollId))
    // setVoteData(votesResult?.data);
  };

  const vote_Poll = (option_id, index) => {
    const token = localStorage.getItem("accessToken");
    let data = {
      poll_id: props.pollData.id,
      option_id: option_id,
    };
    dispatch(votePoll(token, data,props.postId));
    setIsVoted(true);
    setIsId(index);
  };
  return (
    <div>
      <div className="pollBoxContainer">
        <p className="descriptionOverFlowSpan">{props.pollData.question}</p>
        <div className="row btn-div question">
          {props.pollData?.options?.map((item, i) => {
            return (
              <div className="col-12 mt-2" key={i}>
                {voted_notVoted(item, i)}
                {/* {isVoted || props.pollData.Poll_voted ? (
                  <div
                  className={
                    props?.pollData.Poll_voted?.option_id == item.id
                      ? "row btn btn-border-blue selectedBlue"
                      : "row btn btn-border-blue"
                  }
                >
                  <div className="col-6 pollTitle">{item.title}</div>
                  <div className="col-12 font10">
                    {item.vote_count}
                    {" votes"}
                  </div>
                </div>
                ) : (
                  <button
                  className="btn btn-border-blue pollButton"
                  onClick={() => {
                    vote_Poll(item.id);
                  }}
                >
                  {item.title}
                </button>
                )} */}
              </div>
            );
          })}
        </div>
        {/* <p>{item.vote_count} votes</p> */}
        <div className="mini_row">
          {props?.pollData?.duration ? (
            <>
              <p>
                {moment(props?.pollData?.endTime).fromNow(true)}
                {/* {props?.pollData?.duration} */} left
              </p>
              {/* <p>&#x2022;</p> */}
              <p
                className="curserPointer"
                onClick={() => {
                  setViewPoll(!viewPoll);
                  getVoteData(props.pollData.id);
                  setFirstOption(props.pollData.options[0].id);
                  votePercent();
                }}
              >
                View Results
              </p>
            </>
          ) : (
            ""
          )}
        </div>
      </div>
      {viewPoll && (
        <ViewVotes
          modal={viewPoll}
          toggle={() => setViewPoll(!viewPoll)}
          title="Votes"
          pollId={props.pollData.id}
          firstOption={firstOption}
          // voteData={voteData}
        />
      )}
    </div>
  );
}
