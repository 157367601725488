import {
  CLEAR_SEARCH_HISTORY,
  CLOSE_JOB_SUCCESS,
  CREATE_JOB_ALERT,
  CREATE_JOB_POST_FAIL,
  CREATE_JOB_POST_LOADING,
  CREATE_JOB_POST_SUCCESS,
  CREATE_JOB_QUESTIONARY,
  DELETE_JOB_ALERT,
  DELETE_QUESTIONARY_SET,
  DELETE_SEARCH_HISTORY,
  EDIT_JOB_ALERT,
  FAIL_SHORTLISTED_CANDIDATE_LIST,
  FAIL_SHORTLISTED_VIEW_CANDIDATE_LIST,
  GET_ALL_APPLICANTS_DETAILS_BYID,
  GET_ALL_JOBS,
  GET_ALL_JOBS_APPLICANTS_DETAILS,
  GET_ALL_JOBS_DETAILS,
  GET_ALL_JOBS_MESSAGES,
  GET_APPLIED_JOBS,
  GET_CLOSED_JOBS,
  GET_FEATURED_JOBS,
  GET_INPROGRESS_JOBS,
  GET_JOB_ALERT,
  GET_MY_JOBS,
  GET_QUESTIONARY_LIST,
  GET_QUESTIONS,
  GET_SAVED_JOBS,
  GET_SENT_INTERVIEW_JOBS,
  JOB_PREFERENCE,
  LOAD_MORE_APPLIED_JOBS,
  LOAD_MORE_CLOSED_JOBS,
  LOAD_MORE_FEATURED_JOBS,
  LOAD_MORE_GET_ALL_JOBS,
  LOAD_MORE_GET_MY_JOBS,
  LOAD_MORE_GET_QUESTIONARY_LIST,
  LOAD_MORE_INPROGRESS_JOBS,
  LOAD_MORE_SAVED_JOBS,
  LOAD_MORE_SENT_INTERVIEW_JOBS,
  LOAD_MORE_SHORTLISTED_CANDIDATE_LIST,
  LOAD_MORE_SHORTLISTED_VIEW_CANDIDATE_LIST,
  MORE_SEARCH_HISTORY,
  SAVE_JOB,
  SEARCH_HISTORY,
  SEND_SMART_INTERVIEW,
  SHORTLISTED_CANDIDATE_LIST,
  SHORTLISTED_VIEW_CANDIDATE_LIST,
  SMART_APPLY_POST,
  SMART_APPLY_POST_FAIL,
  UPDATE_APPLICANTS_FAIL,
  UPDATE_APPLICANTS_SUCCESS,
  UPDATE_JOB_DESCERPTION,
  UPDATE_JOB_DETAIL_FAIL,
  UPDATE_JOB_DETAIL_SUCCESS,
  UPDATE_JOB_MESSAGE,
  UPDATE_JOB_QUESTIONARY,
  UPDATE_JOB_FORM,
  GET_ALL_FEATURED_JOBS,
  LOAD_MORE__ALL_FEATURED_JOBS,
  ADD_JOB_FEEDBACK,
} from "../types";

export const initialState = {
  postJobData: {},
  loading: false,
  error: null,
  questionaryList: [],
  getAllJobs: [],
  getMyJobs: [],
  getAJob: {},
  getFeaturedJobs: [],
  getAllFeaturedJobs: [],
  getSavedJobs: [],
  getAppliedJobs: [],
  getSentInterviewJobs: [],
  getInProgressJobs: [],
  getClosedJobs: [],
  jobAlters: [],
  searchHistory: [],
  viewShortListed: [],
  jobType: "",
  jobTitle: "",
  jobWorkspacetype: "On-site",
  jobCompany: {},
  jobListImage: "",
  jobEmploymentType: "Full-time",
  jobCompanyLocation: "",
  jobCompanyId: null,
  jobNumberOfVacancies: "",
};
const jobPostReducer = (state = initialState, action) => {
  switch (action.type) {
    case CREATE_JOB_POST_SUCCESS:
      return {
        ...state,
        jobData: action.payload,
      };
    case CREATE_JOB_POST_LOADING:
      return {
        ...state,
        loading: action.payload,
      };
    case CREATE_JOB_POST_FAIL:
      return {
        ...state,
        error: action.payload,
      };
    // case LOAD_MORE_GET_ALL_JOBS:
    //   return {
    //     ...state,
    //     getAllJobs: [...state.getAllJobs, action.payload],
    //   };
    case GET_ALL_JOBS:
      return {
        ...state,
        getAllJobs: action.payload,
      };
    case LOAD_MORE_GET_ALL_JOBS:
      return {
        ...state,
        getAllJobs: [...state.getAllJobs, ...action.payload],
      };

    case GET_MY_JOBS:
      return {
        ...state,
        getMyJobs: action.payload,
      };
    case LOAD_MORE_GET_MY_JOBS:
      return {
        ...state,
        getMyJobs: [...state.getMyJobs, ...action.payload],
      };
    case GET_FEATURED_JOBS:
      console.log(action.payload);
      return {
        ...state,
        getFeaturedJobs: action.payload,
      };
    case LOAD_MORE_FEATURED_JOBS:
      return {
        ...state,
        getFeaturedJobs: [...state.getFeaturedJobs, ...action.payload],
      };

    case GET_ALL_FEATURED_JOBS:
      return {
        ...state,
        getAllFeaturedJobs: action.payload,
      };
    case LOAD_MORE__ALL_FEATURED_JOBS:
      return {
        ...state,
        getAllFeaturedJobs: [...state.getAllFeaturedJobs, ...action.payload],
      };

    case GET_SAVED_JOBS:
      return {
        ...state,
        getSavedJobs: action.payload,
      };
    case LOAD_MORE_SAVED_JOBS:
      return {
        ...state,
        getSavedJobs: [...state.getSavedJobs, ...action.payload],
      };
    case GET_APPLIED_JOBS:
      return {
        ...state,
        getAppliedJobs: action.payload,
      };
    case LOAD_MORE_APPLIED_JOBS:
      return {
        ...state,
        getAppliedJobs: [...state.getAppliedJobs, ...action.payload],
      };
    case GET_SENT_INTERVIEW_JOBS:
      return {
        ...state,
        getSentInterviewJobs: action.payload,
      };
    case LOAD_MORE_SENT_INTERVIEW_JOBS:
      return {
        ...state,
        getSentInterviewJobs: [
          ...state.getSentInterviewJobs,
          ...action.payload,
        ],
      };
    case GET_INPROGRESS_JOBS:
      return {
        ...state,
        getInProgressJobs: action.payload,
      };
    case LOAD_MORE_INPROGRESS_JOBS:
      return {
        ...state,
        getInProgressJobs: [...state.getInProgressJobs, ...action.payload],
      };
    case GET_CLOSED_JOBS:
      return {
        ...state,
        getClosedJobs: action.payload,
      };
    case LOAD_MORE_CLOSED_JOBS:
      return {
        ...state,
        getClosedJobs: [...state.getClosedJobs, ...action.payload],
      };
    case GET_ALL_JOBS_DETAILS:
      return {
        ...state,
        getAJob: action.payload,
      };

    case SAVE_JOB:
      return {
        ...state,

        getAllJobs: state.getAllJobs.map((item) =>
          item.id === action.payload
            ? { ...item, savedJob: { status: !item?.savedJob?.status } }
            : item
        ),

        getFeaturedJobs: state.getFeaturedJobs.map((item) =>
          item.id === action.payload
            ? { ...item, savedJob: { status: !item?.savedJob?.status } }
            : item
        ),
      };
    case GET_ALL_JOBS_APPLICANTS_DETAILS:
      return {
        ...state,
        getApplicantsDetail: action.payload,
      };
    case GET_ALL_APPLICANTS_DETAILS_BYID:
      return {
        ...state,
        getApplicantsById: action.payload,
      };
    case UPDATE_APPLICANTS_SUCCESS:
      return {
        ...state,
        getApplicantsDetail: action.payload,
      };
    case UPDATE_APPLICANTS_FAIL:
      return {
        ...state,
        error: action.payload,
      };
    case GET_ALL_JOBS_MESSAGES:
      return {
        ...state,
        getJobsMessageData: action.payload,
      };

    // case UPDATE_JOB_MESSAGE:
    //   return {
    //     ...state,
    //     getJobsMessageData: action.payload,
    //   };

    case CLOSE_JOB_SUCCESS:
      return {
        ...state,
        getAJob: {
          ...state.getAJob,
          expiry_date: action.payload.data.expiry_date,
        },
      };

    case UPDATE_JOB_DESCERPTION:
      return {
        ...state,
        getAJob: {
          ...state.getAJob,
          description: action.payload.data.description,
        },
      };

    case UPDATE_JOB_DETAIL_SUCCESS:
      return {
        ...state,
        getAJob: action.payload,
      };

    case UPDATE_JOB_DETAIL_FAIL:
      return {
        ...state,
        error: action.payload,
      };

    case SHORTLISTED_CANDIDATE_LIST:
      return {
        ...state,
        shortlisedList: action.payload.data,
      };
    case LOAD_MORE_SHORTLISTED_CANDIDATE_LIST:
      return {
        ...state,
        shortlisedList: [...state.shortlisedList, ...action.payload.data],
      };
    case FAIL_SHORTLISTED_CANDIDATE_LIST:
      return {
        ...state,
        shortlisedList: [],
      };
    case SHORTLISTED_VIEW_CANDIDATE_LIST:
      return {
        ...state,
        viewShortListed: action.payload.data,
      };

    case FAIL_SHORTLISTED_VIEW_CANDIDATE_LIST:
      return {
        ...state,
        viewShortListed: [],
      };
    case LOAD_MORE_SHORTLISTED_VIEW_CANDIDATE_LIST:
      return {
        ...state,
        viewShortListed: [...state.viewShortListed, ...action.payload.data],
      };

    case SEND_SMART_INTERVIEW:
      return {
        ...state,
        viewShortListed: state?.viewShortListed?.map((shortlist) => {
          if (action.payload?.shortlist_id == shortlist?.id) {
            shortlist.interview_set = action.payload?.interview_set;
            shortlist.interview_date = action.payload?.interview_date;
          }
          return shortlist;
        }),
      };
    case CREATE_JOB_QUESTIONARY:
      // if(state.questionaryList)
      return {
        ...state,
        questionaryList: [...state.questionaryList, action.payload],
      };
    // else
    // return {
    //   ...state,
    //   // questionaryList: [...state.questionaryList ,action.payload],
    //   questionaryList: action.payload
    // };
    case GET_QUESTIONARY_LIST:
      return {
        ...state,
        questionaryList: action.payload,
      };
    case GET_QUESTIONS:
      return {
        ...state,
        questionsList: action.payload,
      };
    case LOAD_MORE_GET_QUESTIONARY_LIST:
      return {
        ...state,
        questionaryList: [...state.questionaryList, ...action.payload],
      };
    case DELETE_QUESTIONARY_SET:
      return {
        ...state,
        questionaryList: state.questionaryList?.filter(
          (x) => x.set !== action.payload
        ),
      };

    case UPDATE_JOB_QUESTIONARY:
      return {
        ...state,
        // questionaryList: state.questionaryList?.map((x) =>
        //   x.id == action.payload.setId ? { data: action.payload.data } : x
        // ),
        questionaryList: action.payload.data,
      };
    case SMART_APPLY_POST:
      const job = state?.getAllJobs?.map((ele) => {
        if (ele.id == action.payload.job_id)
          ele.appliedJob = {
            job_id: action.payload?.id,
            apply_status: "pending",
          };
        return ele;
      });
      // console.log(job, "jobjob");
      return {
        ...state,
        // smartApply: [...state?.smartApply, action.payload],
        getAJob: {
          ...state.getAJob,
          appliedJob: {
            job_id: action.payload?.job_id,
            apply_status: "pending",
          },
        },
        getAllJobs: job,
      };

    case SMART_APPLY_POST_FAIL:
      return {
        ...state,
        error: action.payload,
      };

    case CREATE_JOB_ALERT:
      return {
        ...state,
        jobAlters: [...state.jobAlters, action.payload],
      };
    case GET_JOB_ALERT:
      return {
        ...state,
        jobAlters: action.payload,
      };
    case EDIT_JOB_ALERT:
      return {
        ...state,
        jobAlters: state.jobAlters.map((item) =>
          item.id === action.payload.id ? action.payload : item
        ),
      };
    case DELETE_JOB_ALERT:
      return {
        ...state,
        jobAlters: state.jobAlters.filter((item) => item.id !== action.payload),
      };

    case JOB_PREFERENCE:
      return {
        ...state,
        jobPreference: action.payload,
      };

    case SEARCH_HISTORY:
      return {
        ...state,
        searchHistory: action.payload,
      };

    case MORE_SEARCH_HISTORY:
      return {
        ...state,
        searchHistory: [...state.searchHistory, ...action.payload],
      };

    case DELETE_SEARCH_HISTORY:
      return {
        ...state,
        searchHistory: state.searchHistory.filter(
          (item) => item.id !== action.payload
        ),
      };

    case CLEAR_SEARCH_HISTORY:
      return {
        ...state,
        searchHistory: [],
      };
    case UPDATE_JOB_FORM:
      return {
        ...state,
        ...action.payload,
      };

    case ADD_JOB_FEEDBACK:
      return {
        ...state,
        getAJob: {
          ...state?.getAJob,
          Feedback: {
            type: action?.payload?.type,
            type_id: action?.payload?.type_id,
          },
        },
      };

    default:
      return state;
  }
};
export default jobPostReducer;
