import { connectionsAPI } from "../../api/connectionsAPI";

import {
  GET_CONNECTIONS_SUCCESS,
  GET_CONNECTIONS_FAIL,
  GET_FOLLOWERS_SUCCESS,
  GET_FOLLOWERS_FAIL,
  GET_FOLLOWING_SUCCESS,
  GET_FOLLOWING_FAIL,
  GET_LOCATIONS_SUCCESS,
  GET_LOCATIONS_FAIL,
  GET_FILTER_SUCCESS,
  GET_FILTER_FAIL,
  GET_SCHOOL_SUCCESS,
  GET_SCHOOL_FAIL,
  GET_CURRENT_COMPANY_SUCCESS,
  GET_CURRENT_COMPANY_FAIL,
  SEND_CONNECT_REQUEST,
} from "../types";

import { initPageLoad } from "../loader/loaderActions";
import { stopPageLoad } from "../loader/loaderActions";
import { connectionStatus } from "../profile/profileActions";
import { sendRequest } from "../people/peopleActions";
import { homeConnectionStatus } from "../dashboard/dashboardActions";

export const getConnectionsData = (searchData, user_id) => (dispatch) => {
  return connectionsAPI.getConnections(searchData, user_id).then(
    (data) => {
      // console.log(data);
      dispatch({
        type: GET_CONNECTIONS_SUCCESS,
        payload: { user: data },
      });

      return Promise.resolve(data);
    },
    (error) => {
      console.log(error);
      dispatch({
        type: GET_CONNECTIONS_FAIL,
      });
      return Promise.reject(error);
    }
  );
};

export const getFollowersData = (searchData, user_id) => (dispatch) => {
  return connectionsAPI.getFollowers(searchData, user_id).then(
    (data) => {
      // console.log(data);
      dispatch({
        type: GET_FOLLOWERS_SUCCESS,
        payload: { user: data },
      });

      return Promise.resolve(data);
    },
    (error) => {
      console.log(error);
      dispatch({
        type: GET_FOLLOWERS_FAIL,
      });
      return Promise.reject(error);
    }
  );
};

export const getFollowingData = (searchData, user_id) => (dispatch) => {
  return connectionsAPI.getFollowing(searchData, user_id).then(
    (data) => {
      dispatch({
        type: GET_FOLLOWING_SUCCESS,
        payload: { user: data },
      });

      return Promise.resolve(data);
    },
    (error) => {
      console.log(error);
      dispatch({
        type: GET_FOLLOWING_FAIL,
      });
      return Promise.reject(error);
    }
  );
};

export const getLocationsData = (searchKey) => (dispatch) => {
  return connectionsAPI.getLocations(searchKey).then(
    (data) => {
      // console.log(data);
      dispatch({
        type: GET_LOCATIONS_SUCCESS,
        payload: { user: data },
      });

      return Promise.resolve(data);
    },
    (error) => {
      console.log(error);
      dispatch({
        type: GET_LOCATIONS_FAIL,
      });
      return Promise.reject(error);
    }
  );
};

export const getSchoolData = (searchKey) => (dispatch) => {
  return connectionsAPI.getSchools(searchKey).then(
    (data) => {
      // console.log(data);
      dispatch({
        type: GET_SCHOOL_SUCCESS,
        payload: { user: data },
      });

      return Promise.resolve(data);
    },
    (error) => {
      console.log(error);
      dispatch({
        type: GET_SCHOOL_FAIL,
      });
      return Promise.reject(error);
    }
  );
};

export const getCurrentCompanyData = () => (dispatch) => {
  return connectionsAPI.getCurrentCompany().then(
    (data) => {
      // console.log(data);
      dispatch({
        type: GET_CURRENT_COMPANY_SUCCESS,
        payload: { user: data },
      });

      return Promise.resolve(data);
    },
    (error) => {
      console.log(error);
      dispatch({
        type: GET_CURRENT_COMPANY_FAIL,
      });
      return Promise.reject(error);
    }
  );
};

export const getFilterData = (searchKey) => (dispatch) => {
  return connectionsAPI.getFilter(searchKey).then(
    (data) => {
      // console.log(data);
      dispatch({
        type: GET_FILTER_SUCCESS,
        payload: { user: data },
      });

      return Promise.resolve(data);
    },
    (error) => {
      console.log("GET_FILTER_FAIL", error);
      dispatch({
        type: GET_FILTER_FAIL,
      });
      return Promise.reject(error);
    }
  );
};

export const sendConnectionRequest = (id, mode, post_id) => (dispatch) => {
  // dispatch(initPageLoad());
  return connectionsAPI.sendConnectionRequest(id).then(
    (data) => {
      if (mode === "home") {
        dispatch(homeConnectionStatus("pending", post_id));
      } else {
        dispatch(connectionStatus("pending"));
      }
      dispatch(sendRequest(id));
      // dispatch(stopPageLoad());
      return Promise.resolve(data);
    },
    (error) => {
      console.log(error);
      dispatch(stopPageLoad());
      return Promise.reject(error);
    }
  );
};
