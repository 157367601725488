import React, { useEffect, useState } from "react";
import Wallet from "./Wallet";
import { useNavigate, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { get_refer_info, getUserTransaction } from "../../store/wallet/walletActions";
import { getAddOns, getLocation, getUserAddons } from "../../store/membership/membershipActions";

export default function WalletContainer() {
  const { refer_data } = useSelector((state) => state.wallet);
  console.log(refer_data);
  const { user } = useSelector((state) => state.auth);

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const {state} = useLocation()

  const [cardModal, setCardModal] = useState(false);
  const [referModal, setReferModal] = useState(false);
  const [referToggle, setReferToggle] = useState(false);
  const [addOnModal, setAddOnModal] = useState(state?.isAddOns ? true : false)
  const [countryFound, setCountryFound] = useState(false)
  const [location, setLocation] = useState("")
  const [addonData, setAddonData] = useState("")
  const [addons, setAddons] = useState([])
  const [transactions, setTransactions] = useState([])
  const [update, setUpdate] = useState(false)

  useEffect(() => {
    if(countryFound && user?.data?.membership?.id){
      dispatch(get_refer_info());
      dispatch(getAddOns({membership_id: user?.data?.membership?.id, country: location})).then((res) => {
        setAddonData(res)
      })
    }
    
  }, [countryFound, user]);

  useEffect(() => {
    dispatch(getLocation()).then((res) => {
      setLocation(res?.country_name);
      setCountryFound(true)
    });
    
  }, []);

  useEffect(() => {
    dispatch(getUserTransaction()).then((res) => {
      setTransactions(res.data)
    })
    .catch((error) => {
      console.log(error)
    })

    dispatch(getUserAddons()).then((res) => {
      setAddons(res.data)
    })
    .catch((error) => {
      console.log(error)
    })
  }, [update])

  return (
    <Wallet
      cardModal={cardModal}
      setCardModal={setCardModal}
      navigate={navigate}
      refer_data={refer_data}
      referModal={referModal}
      setReferModal={setReferModal}
      referToggle={referToggle}
      setReferToggle={setReferToggle}
      user={user}
      addOnModal = {addOnModal}
      setAddOnModal = {setAddOnModal}
      addonData = {addonData}
      addons = {addons}
      transactions = {transactions}
      update = {update}
      setUpdate = {setUpdate}
    />
  );
}
