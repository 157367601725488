import {
  GET_RECEIVED_RECOMMENDATION,
  GET_GIVEN_RECOMMENDATION,
  ADD_RECOMMENDATION,
  ASK_RECOMMENDATION,
  GET_PENDING_RECOMMENDATION,
  TOGGLE_UPDATE_RECOMMENDATION,
  DELETE_RECOMMENDATION,
} from "../types";

export const initialState = {
  recommendation: [],
};

const recommendation = (state = initialState, action) => {
  switch (action.type) {
    case GET_GIVEN_RECOMMENDATION:
      return { ...state, recommendation: action.payload };

    case GET_RECEIVED_RECOMMENDATION:
      return {
        ...state,
        recommendation: action.payload,
      };
    case GET_PENDING_RECOMMENDATION:
      return {
        ...state,
        recommendation: action.payload,
      };
    case TOGGLE_UPDATE_RECOMMENDATION:
      return {
        ...state,
        recommendation: state.recommendation.map((x) =>
          x.id === action.payload.id
            ? {
                ...x,
                recommendation_status: "confirm",
                status: action.payload.status,
              }
            : x
        ),
      };
    case DELETE_RECOMMENDATION:
      {
        console.log("payload", action.payload);
      }
      return {
        ...state,
        recommendation: state.recommendation?.filter((item) => {
          return item.id !== action.payload.id;
        }),
      };

    default:
      return state;
  }
};

export default recommendation;
