import React, { useState } from "react";
import { Modal, ModalHeader, ModalBody } from "reactstrap";
import axios from "axios";
import { useDispatch } from "react-redux";
import { uploadResume } from "../../../../store/profile/profileActions";

export default function AddResumeModal(props) {
  const { modal, toggle } = props;

  const dispatch = useDispatch();

  const [resume, setResume] = useState();
  const [urlData, setUrlData] = useState();
  const [fileName, setFileName] = useState();

  const onChangeResume = async (e) => {
    console.log(e.target.files);
    if (e.target.files[0]) {
      setResume(URL.createObjectURL(e.target.files[0]));
    }

    let tempimage = [];
    let type = "file";
    Array.from(e.target.files).map((file) => {
      return tempimage.push({ type, file });
    });
    try {
      const UPLOAD_URL = `${process.env.REACT_APP_API_URL}api/v1/upload`;
      const formData = new FormData();
      const headers = {
        "Accept-Language": "en",
        "content-type": "multipart/form-data",
      };

      for (var i = 0; i < tempimage.length; i++) {
        formData.append("image", tempimage[i].file);
      }

      console.log(formData);
      await axios.post(UPLOAD_URL, formData, { headers }).then((res) => {
        console.log(res?.data?.urlsArray[0]);
        setFileName(res?.data?.filesArray[0].originalname);
        setUrlData(res?.data?.urlsArray[0]);
      });
    } catch (err) {
      console.log(err);
    }
  };

  const handleSubmit = () => {
    let token = localStorage.getItem("accessToken");
    let data = {
      resume_url: urlData,
      resume_name: fileName,
    };
    dispatch(uploadResume(data, token)).then((response) => {
      toggle();
    });
  };

  return (
    <div>
      <Modal
        isOpen={modal}
        size="xl"
        toggle={toggle}
        className="fix-width gradient-background"
        centered
      >
        <div className="popup">
          <div className="add-skill">
            <ModalHeader toggle={toggle} className="py-3">
              Add Resume/CV
            </ModalHeader>
            <ModalBody style={{ padding: "0px 30px 0px 30px" }}>
              <form>
                <div className="title pt-3">
                  <label>
                    By selecting this option, you can upload resume according to
                    your preference.
                  </label>
                </div>
                <div
                  // style={{height:"500px"}}
                  className="mt-10"
                >
                  {fileName ? (
                    <div className="card-control-text-oneline">{fileName}</div>
                  ) : (
                    <div></div>
                  )}
                </div>
                <div className="add-media">
                  {resume ? (
                    <div
                      className="btn btn-blue mb-20"
                      style={{ marginTop: "15px" }}
                      onClick={handleSubmit}
                    >
                      Submit
                    </div>
                  ) : (
                    <label
                      className="btn btn-blue mb-20"
                      style={{ color: "#fff", marginTop: "15px" }}
                      htmlFor="upload"
                    >
                      <input
                        type="file"
                        id="upload"
                        name="upload"
                        accept="application/pdf,application/vnd.ms-excel"
                        style={{ display: "none" }}
                        onChange={onChangeResume}
                        //   onClick={onInputClick}
                      />
                      <div>Upload Resume</div>
                    </label>
                  )}
                </div>
              </form>
            </ModalBody>
          </div>
        </div>
      </Modal>
    </div>
  );
}
