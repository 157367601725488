import React, { useState } from "react";
import Header from "../../core/components/layout/Header/Header";
import LoginFooter from "../../core/components/layout/footer/LoginFooter";
import lamp from "../../assets/img/lamp-on.svg";
import profile from "../../assets/img/user.svg";
import ticket from "../../assets/img/ticket-discount.svg";
import security from "../../assets/img/security.svg";
import brifcase from "../../assets/img/briefcase.svg";
import user from "../../assets/img/profile-add.svg";
import { Collapse, CardBody, Card } from "reactstrap";
import { Link } from "react-router-dom";
import "./Help.scss";
import ContactUsModal from "./components/ContactUsModal";
import HelpCenterSearch from "./components/HelpCenterSearch";
import Prizing from "./components/Prizing";

export default function Help(props, args) {
  const {
    questions,
    collapse,
    data,
    subcollapse,
    toggle,
    modals,
    modal,
    handleSubmit,
    formData,
    handleChange,
    handlefileChange,
    type,
    handleClose,
    prizeData,
    prizeUnderData,
    helpData,
    headingData,
    customSub,
    setCustomSub,
  } = props;

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  const [subcollapsed, setSubCollapsed] = useState(false);

  // const subtoggled = () => setSubCollapsed(!subcollapsed);

  return (
    <>
      <div>
        <Header />
        <div className="help">
          <HelpCenterSearch helpData={helpData} />
          <div className="context">
            <section className="padding">
              <div className="container">
                <div className="row">
                  <div className="col-lg-12">
                    <div className="wrapper">
                      <div className="heading mb-3">
                        <h2>NobelPage Frequently Asked Questions</h2>
                      </div>
                      <div className="heading mb-3">
                        <h3>Popular Questions</h3>
                      </div>
                      <div className="list">
                        {/* <h5>Popular Questions</h5> */}
                        <ul>
                          {questions.map((item, id) => {
                            return (
                              <li key={id}>
                                {/* <Link to={``}>Ram</Link> */}
                                <Link to={`/help/question/${item.id}`}>
                                  <h6
                                    style={{
                                      fontSize: "inherit",
                                      lineHeight: "inherit",
                                    }}
                                    className="card-control-text-oneline"
                                    title={item.question}
                                  >
                                    {item.question}
                                  </h6>
                                </Link>
                              </li>
                            );
                          })}
                        </ul>
                      </div>
                    </div>
                    <div className="wrapper">
                      <div className="heading mt-30">
                        <h5>Recommended Topics</h5>
                      </div>
                      <div className="main-box row">
                        <div className="box col-lg-4">
                          <div
                            className="text-center"
                            style={{ cursor: "pointer" }}
                          >
                            <Link
                              to={`/help/question/topic`}
                              state={{ tag: "basic" }}
                            >
                              <div className="icon">
                                <img src={lamp} alt="" />
                              </div>
                              <div className="content">
                                <p>Basics</p>
                              </div>
                            </Link>
                          </div>
                        </div>
                        <div className="box col-lg-4">
                          <div
                            className="text-center"
                            style={{ cursor: "pointer" }}
                          >
                            <Link
                              to={`/help/question/topic`}
                              state={{ tag: "profile" }}
                            >
                              <div className="icon">
                                <img src={profile} alt="" />
                              </div>
                              <div className="content">
                                <p>Your Profile</p>
                              </div>
                            </Link>
                          </div>
                        </div>
                        <div className="box col-lg-4">
                          <div
                            className="text-center"
                            style={{ cursor: "pointer" }}
                          >
                            <Link
                              to={`/help/question/topic`}
                              state={{ tag: "subscription" }}
                            >
                              <div className="icon">
                                <img src={ticket} alt="" />
                              </div>
                              <div className="content">
                                <p>Subscription Billing</p>
                              </div>
                            </Link>
                          </div>
                        </div>
                        <div className="box col-lg-4">
                          <div
                            className="text-center"
                            style={{ cursor: "pointer" }}
                          >
                            <Link
                              to={`/help/question/topic`}
                              state={{ tag: "privacy" }}
                            >
                              <div className="icon">
                                <img src={security} alt="" />
                              </div>
                              <div className="content">
                                <p>Data and Privacy</p>
                              </div>
                            </Link>
                          </div>
                        </div>
                        <div className="box col-lg-4">
                          <div
                            className="text-center"
                            style={{ cursor: "pointer" }}
                          >
                            <Link
                              to={`/help/question/topic`}
                              state={{ tag: "job_search" }}
                            >
                              <div className="icon">
                                <img src={brifcase} alt="" />
                              </div>
                              <div className="content">
                                <p>Search and Apply for Jobs</p>
                              </div>
                            </Link>
                          </div>
                        </div>
                        <div className="box col-lg-4">
                          <div
                            className="text-center"
                            style={{ cursor: "pointer" }}
                          >
                            <Link
                              to={`/help/question/topic`}
                              state={{ tag: "connection" }}
                            >
                              <div className="icon">
                                <img src={user} alt="" />
                              </div>
                              <div className="content">
                                <p>Connections</p>
                              </div>
                            </Link>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="accordian">
                      <Prizing headingData={headingData} />
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="bottom my-2">
                  <ul>
                    <li onClick={modals}>Contact us</li>
                    {/* <li>
                      <Link to="/help" onClick={scrollToTop}>FAQ</Link>
                    </li> */}
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="line mt-3"></div>
        <LoginFooter />
      </div>
      <ContactUsModal
        modal={modal}
        modals={modals}
        args={args}
        handleSubmit={handleSubmit}
        formData={formData}
        handleChange={handleChange}
        handlefileChange={handlefileChange}
        type={type}
        handleClose={handleClose}
        customSub={customSub}
        setCustomSub={setCustomSub}
      />
    </>
  );
}
