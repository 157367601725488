import React from "react";
import edit from "../../../../assets/img/edit-icon.svg";
import slash from "../../../../assets/img/slash.svg";
import cross_circle from "../../../../assets/img/cross_circle.svg";
import deleteIcon from "../../../../assets/img/delete.svg";
import pin from "../../../../assets/img/pin-grey.png";
import DeleteModal from "../../../../core/components/modal/DeleteModal";

const DropDowns = (props) => {
  const {
    dropDownComm,
    index,
    comment,
    user,
    userId,
    item,
    dropRefComment,
    editComment,
    setCloseCommentModal,
    closeCommentModal,
    setCommentDeleteId,
    setReportModal,
    reportModal,
    setReportType,
    setReportCommentId,
    setPostID,
    replies,
    setCloseReplyModal,
    closeReplyModal,
    setDropDownComm,
    setDropDownReply,
    pinCommentToTop,
    setReplyDeleteId,
    isReplyDD,
    confirmBlockModal,
    setConfirmBlockModal,
    setBlockCommentId,
    setBlockUserId,
    blockComment,
    setRepliesCount,
  } = props;

  const commentDelete = () => {
    setCloseCommentModal(!closeCommentModal);
    setCommentDeleteId(comment.id);
    setDropDownComm(-1);
  };
  const replyDelete = () => {
    setCloseReplyModal(!closeReplyModal);
    setReplyDeleteId(replies.id);
    setCommentDeleteId(comment.id);
    setDropDownReply(-1);
  };

  const commentBlock = (userId) => {
    setBlockCommentId(item.id);
    setBlockUserId(userId);
    setDropDownComm(-1);
    setConfirmBlockModal(!confirmBlockModal);
  };

  return (
    <div>
      {!isReplyDD ? (
        <div className="drop">
          {dropDownComm === index && (
            <div className="box">
              {comment?.user?.id === user?.data?.id &&
              userId === item?.user_id ? (
                <ul id="dropdownBox" ref={dropRefComment}>
                  <li className="listing" onClick={() => editComment(comment)}>
                    <img src={edit} alt="" />
                    Edit
                  </li>
                  <li
                    className="listing"
                    onClick={() => {
                      commentDelete();
                      setRepliesCount(comment?.reply_count);
                    }}
                  >
                    <img src={deleteIcon} alt="" />
                    Delete
                  </li>
                  <li
                    onClick={() => {
                      pinCommentToTop(comment?.id);
                    }}
                    className="listing"
                  >
                    <img
                      src={pin}
                      alt=""
                      style={{
                        width: "15px",
                        height: "17px",
                        paddingTop: "2px",
                      }}
                    />{" "}
                    Pin to top
                  </li>
                </ul>
              ) : comment?.user?.id === user?.data?.id ? (
                <ul id="dropdownBox" ref={dropRefComment}>
                  <li className="listing" onClick={() => editComment(comment)}>
                    <img src={edit} alt="" />
                    Edit
                  </li>
                  <li
                    className="listing"
                    onClick={() => {
                      //   setCloseCommentModal(!closeCommentModal);
                      //   setCommentDeleteId(comment.id);
                      commentDelete();
                    }}
                  >
                    <img src={deleteIcon} alt="" />
                    Delete
                  </li>
                </ul>
              ) : userId === item?.user_id ? (
                <ul id="dropdownBox" ref={dropRefComment}>
                  <li
                    className="listing"
                    onClick={() => {
                      commentDelete();
                    }}
                  >
                    <img src={deleteIcon} alt="" />
                    Delete
                  </li>
                  <li
                    className="listing"
                    onClick={() => commentBlock(comment?.user?.id)}
                  >
                    <img src={cross_circle} alt="" />
                    Block {comment?.user?.first_name} {comment?.user?.last_name}
                  </li>
                  <li
                    onClick={() => {
                      setReportModal(!reportModal);
                      setPostID(item?.id);
                      setReportType("comment");
                      setReportCommentId(comment?.id);
                    }}
                    className="listing"
                  >
                    <img src={slash} alt="" /> Report
                  </li>
                  <li
                    onClick={() => {
                      pinCommentToTop(comment?.id);
                      // setPostID(comment?.id);
                    }}
                    className="listing"
                  >
                    <img
                      src={pin}
                      alt=""
                      style={{
                        width: "17px",
                        height: "17px",
                        paddingTop: "2px",
                      }}
                    />{" "}
                    Pin to top
                  </li>
                </ul>
              ) : (
                <ul id="dropdownBox" ref={dropRefComment}>
                  <li
                    onClick={() => {
                      setReportModal(!reportModal);
                      setPostID(item?.id);
                      setReportType("comment");
                      setReportCommentId(comment?.id);
                    }}
                    className="listing"
                  >
                    <img src={slash} alt="" /> Report
                  </li>
                </ul>
              )}
            </div>
          )}
        </div>
      ) : (
        <div className="drop">
          {dropDownComm === index && (
            <div className="box">
              {replies?.user?.id === user?.data?.id &&
              userId === item?.user_id ? (
                <ul id="dropdownBox" ref={dropRefComment}>
                  <li className="listing" onClick={() => editComment(replies)}>
                    <img src={edit} alt="" />
                    Edit
                  </li>
                  <li
                    className="listing"
                    onClick={() => {
                      replyDelete();
                    }}
                  >
                    <img src={deleteIcon} alt="" />
                    Delete
                  </li>
                  <li
                    onClick={() => {
                      pinCommentToTop(comment?.id);
                      //   setPostID(comment?.id);
                    }}
                    className="listing"
                  >
                    <img
                      src={pin}
                      alt=""
                      style={{
                        width: "17px",
                        height: "17px",
                        paddingTop: "2px",
                      }}
                    />{" "}
                    Pin to top
                  </li>
                </ul>
              ) : replies?.user?.id == user?.data?.id ? (
                <ul id="dropdownBox" ref={dropRefComment}>
                  <li className="listing" onClick={() => editComment(replies)}>
                    <img src={edit} alt="" />
                    Edit
                  </li>
                  <li
                    className="listing"
                    onClick={() => {
                      // setCloseCommentModal(!closeCommentModal);
                      // setCommentDeleteId(comment.id);
                      replyDelete();
                    }}
                  >
                    <img src={deleteIcon} alt="" />
                    Delete
                  </li>
                </ul>
              ) : userId === item?.user_id ? (
                <ul id="dropdownBox" ref={dropRefComment}>
                  <li
                    className="listing"
                    onClick={() => {
                      // setCloseCommentModal(!closeCommentModal);
                      // setCommentDeleteId(comment.id);
                      replyDelete();
                    }}
                  >
                    <img src={deleteIcon} alt="" />
                    Delete
                  </li>
                  <li
                    className="listing card-control-text-oneline"
                    // onClick={() => editPost(item)}
                  >
                    <img src={cross_circle} alt="" />
                    Block {replies?.user?.first_name} {replies?.user?.last_name}
                  </li>
                  <li
                    onClick={() => {
                      setReportModal(!reportModal);
                      setReportType("comment");
                      setReportCommentId(replies?.id);
                    }}
                    className="listing"
                  >
                    <img src={slash} alt="" /> Report
                  </li>
                  <li
                    onClick={() => {
                      pinCommentToTop(comment?.id);
                      // setPostID(comment?.id);
                    }}
                    className="listing"
                  >
                    <img
                      src={pin}
                      alt=""
                      style={{
                        width: "17px",
                        height: "17px",
                        paddingTop: "2px",
                      }}
                    />{" "}
                    Pin to top
                  </li>
                </ul>
              ) : (
                <ul id="dropdownBox" ref={dropRefComment}>
                  <li
                    onClick={() => {
                      setReportModal(!reportModal);
                      setReportType("comment");
                      setReportCommentId(comment?.id);
                    }}
                    className="listing"
                  >
                    <img src={slash} alt="" /> Report
                  </li>
                </ul>
              )}
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default DropDowns;
