import React, { useEffect, useState } from "react";
import Campaign from "./Campaign";
import { getCampaign } from "../../../store/campaign/campaignActions";
import { useDispatch, useSelector } from "react-redux";
import { getSuggestedArticles } from "../../../store/articles/articleActions";

export default function CampaignContainer() {
  const dispatch = useDispatch();
  const { campaignList, featuredCampaignList } = useSelector(
    (state) => state.campaign
  );
  const [tabs, setTabs] = useState("all");
  const [currentCount, setCurrentCount] = useState(0);
  const [totalCount, setTotalCount] = useState(0);
  const [hasMore, setHasMore] = useState(true);

  const { suggested_articles } = useSelector((state) => state.article);

  useEffect(() => {
    let token = window.localStorage.getItem("accessToken");
    dispatch(
      getSuggestedArticles(
        token,
        "suggested",
        { type: "suggested", skip: 0, limit: 10 },
        true
      )
    );
  }, []);
  useEffect(() => {
    if (campaignList?.length < totalCount) setHasMore(true);
    else setHasMore(false);
  }, [totalCount]);

  useEffect(() => {
    setCurrentCount(0);
    dispatch(
      getCampaign({ type: tabs, skip: currentCount, limit: 10 }, currentCount)
    )
      .then((res) => {
        setTotalCount(res.totalCount);
      })
      .catch((e) => {
        console.log("error", e);
      });
  }, [tabs]);

  // useEffect(() => {
  //   dispatch(
  //     getCampaign({ type: tabs, limit: 10, skip: currentCount }, currentCount)
  //   )
  //     .then(() => {})
  //     .catch((e) => {});
  // }, []);

  useEffect(() => {
    dispatch(
      getCampaign({ type: tabs, skip: currentCount, limit: 10 }, currentCount)
    )
      .then((res) => {
        setTotalCount(res.totalCount);
      })
      .catch((e) => {
        console.log("error", e);
      });
  }, [currentCount]);

  const loadMoreData = () => {
    setCurrentCount(currentCount + 10);
  };

  return (
    <Campaign
      campaignList={campaignList}
      featuredCampaignList={featuredCampaignList}
      hasMore={hasMore}
      tabs={tabs}
      setTabs={setTabs}
      suggested_articles={suggested_articles}
      currentCount={currentCount}
      loadMoreData={loadMoreData}
    />
  );
}
