import React from "react";
import trash from "../../assets/img/Trash can.svg";
import edit from "../../assets/img/edit.svg";
import bul from "../../assets/img/buildings.svg";
import degree from "../../assets/img/degree.svg";
import logo from "../../assets/img/logo.svg";
import placeholderUser from "../../assets/img/placeholder_user.jpg";
import "./intro.scss";
import { Link } from "react-router-dom";
import AddExperModal from "../profile-pages/profile/components/AddExperModal";
import AddEducationModal from "../profile-pages/profile/components/AddEducationModal";
import AddContactInfoModal from "../profile-pages/profile/components/AddContactInfoModal";
import BackgroundPhotoModal from "../profile-pages/profile/components/BackgroundPhotoModal";
import ProfilePhotoModal from "../profile-pages/profile/components/ProfilePhotoModal";
import { Input } from "reactstrap";
import DeleteModal from "../../core/components/modal/DeleteModal";
import CustomProfileModal from "../setting/components/CustomProfileModal";

export default function Intro(props) {
  const {
    addExpModal,
    setAddExpModal,
    addContactInfoModal,
    setAddContactInfoModal,
    educationModal,
    setEducationModal,
    pageData,
    setPageData,
    newExperienceData,
    deleteExperience,
    deleteEducation,
    imageToggle,
    handleSubmit,
    errorMessage,
    profileData,
    setErrorMessage,
    backgroundPhotoModal,
    setBackgroundPhotoModal,
    handleBannerPicture,
    profilePhotoModal,
    setProfilePhotoModal,
    handleRemovePicture,
    removeExpModal,
    setRemoveExpModal,
    removeEduModal,
    setRemoveEduModal,
    removeEduId,
    setRemoveEduId,
    removeExpId,
    setRemoveExpId,
    customProfileModal,
    setCustomProfileModal,
  } = props;

  console.log(pageData);
  return (
    <div className="intro">
      <div className="container">
        <nav className="navbar">
          <Link to="/dashboard">
            <img src={logo} alt="" />
          </Link>
        </nav>
        <div className="row">
          <div className="col-lg-12 mt-3">
            <div className="text-center">
              <h1>Welcome to NobelPage</h1>
              <p>
                Start by setting up your profile shortly. Unlock opportunities,
                connect with professionals, and showcase your skills in no time.
                Your next adventure awaits!
              </p>
            </div>
            <form className="my-5">
              <div className="cover">
                <label>Update Your Cover Image</label>
                <div className="banner">
                  {profileData?.background_img ? (
                    <img
                      src={profileData?.background_img}
                      className="img-fluid-banner"
                      style={{ borderRadius: "5px" }}
                      alt=""
                    />
                  ) : (
                    <></>
                  )}
                  <div
                    className="edit"
                    onClick={() =>
                      setBackgroundPhotoModal(!backgroundPhotoModal)
                    }
                  >
                    <img src={edit} alt="" />
                  </div>
                </div>
              </div>
              <div className="profiles">
                <label>Update Your Profile Picture</label>
                <div className="img-circle">
                  <div className="img-profile">
                    <img
                      src={
                        profileData?.profile_img
                          ? profileData.profile_img
                          : placeholderUser
                      }
                      alt=""
                      className="image-fit"
                    />
                  </div>
                  <div className="edit">
                    <img
                      src={edit}
                      alt=""
                      onClick={() => setProfilePhotoModal(!profilePhotoModal)}
                    />
                  </div>
                </div>
              </div>
              <div className="headline mb-3">
                <label>Headline</label>
                <Input
                  type="text"
                  placeholder="Eg: Co-founder of Biliz technologies"
                  value={pageData.profile_headline}
                  maxLength={50}
                  onClick={(e) => setErrorMessage("")}
                  onChange={(e) =>
                    setPageData({
                      ...pageData,
                      profile_headline: e.target.value,
                    })
                  }
                />
                {errorMessage && (
                  <label className="label-input100 text-danger">
                    {errorMessage}
                  </label>
                )}
              </div>
              <div className="exper mb-3">
                <label>Experience</label>
                {newExperienceData.map((item, index) => (
                  <div key={index}>
                    {Object.values(item).map((item2, index) => (
                      <div key={index}>
                        {item2.map((item3, index) => (
                          <div className="box" key={index}>
                            <div className="icon mx-2">
                              <img src={bul} alt="" />
                            </div>

                            <div className="icon right">
                              <img
                                onClick={() => {
                                  setRemoveExpModal(!removeExpModal);
                                  setRemoveExpId(item3);
                                }}
                                src={trash}
                                alt=""
                              />
                            </div>
                            <div className="text">
                              <span className="experienceClass">
                                {item3.title} at {item3.company_name}
                              </span>
                            </div>
                          </div>
                        ))}
                      </div>
                    ))}
                  </div>
                ))}

                <span
                  onClick={() => {
                    setAddExpModal(!addExpModal);
                  }}
                  className="addButton"
                >
                  + Add new position
                </span>

                <div className="check">
                  <input
                    type="checkbox"
                    id="experienceCheck"
                    name="experienceCheck"
                    checked={pageData?.experienceCheck}
                    onChange={(e) =>
                      setPageData({
                        ...pageData,
                        experienceCheck: e.target.checked,
                      })
                    }
                  />
                  <label htmlFor="experienceCheck">
                    Show current position in my intro
                  </label>
                </div>
              </div>
              <div className="educa exper mb-3">
                <label>Education</label>
                {profileData?.educations?.map((item, index) => (
                  <div className="box" key={index}>
                    <div className="icon mx-2">
                      <img src={degree} alt="" />
                    </div>
                    <div className="text">
                      <span>{item.school_name}</span>
                    </div>
                    <div className="icon right">
                      <img
                        onClick={() => {
                          setRemoveEduModal(!removeEduModal);
                          setRemoveEduId(item);
                        }}
                        src={trash}
                        alt=""
                      />
                    </div>
                  </div>
                ))}

                <span
                  className="addButton"
                  onClick={() => {
                    setEducationModal(!educationModal);
                  }}
                >
                  + Add new education
                </span>
                <div className="check">
                  <input
                    type="checkbox"
                    id="educationCheck"
                    name="educationCheck"
                    checked={pageData?.educationCheck}
                    onChange={(e) =>
                      setPageData({
                        ...pageData,
                        educationCheck: e.target.checked,
                      })
                    }
                  />
                  <label htmlFor="educationCheck">
                    Show current position in my intro
                  </label>
                </div>
              </div>
              <div className="contact mb-3">
                <label>Contact information</label>
                <p>Add or edit your profile URL, email, and more</p>
                <span
                  className="addButton"
                  onClick={() => {
                    setAddContactInfoModal(!addContactInfoModal);
                  }}
                >
                  + Add contact info
                </span>
              </div>
              <div className="buttons">
                <Link to="/dashboard">&gt;&gt; Skip </Link>
                <div className="btn btn-blue" onClick={handleSubmit}>
                  Next
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
      {addExpModal && (
        <AddExperModal
          modal={addExpModal}
          toggle={() => setAddExpModal(!addExpModal)}
          editExperienceData={null}
          setEditExperienceData={null}
        />
      )}

      {educationModal && (
        <AddEducationModal
          modal={educationModal}
          toggle={() => setEducationModal(!educationModal)}
          title="Add Education"
          editEducationData={null}
          setEditEducationData={null}
        />
      )}

      {addContactInfoModal && (
        <AddContactInfoModal
          modal={addContactInfoModal}
          toggle={() => {
            setAddContactInfoModal(!addContactInfoModal);
          }}
          title="Edit Contact Info"
          country={profileData?.country}
          countryCodeValue={profileData?.country?.code}
          state={profileData?.state?.id}
          city={profileData?.city?.id}
          location={profileData?.location}
          pincode={profileData?.pin_code}
          phone={profileData?.phone}
          address={profileData?.address}
          dob={profileData?.dob}
          nationality={profileData?.nationality}
          email={profileData?.email}
          profileUrl={profileData?.profile_url}
          website={profileData?.website}
          customProfileModal={customProfileModal}
          setCustomProfileModal={setCustomProfileModal}
        />
      )}
      {backgroundPhotoModal && (
        <BackgroundPhotoModal
          modal={backgroundPhotoModal}
          toggle={() => setBackgroundPhotoModal(!backgroundPhotoModal)}
          title="Background Photo"
          editBackgroundImage={profileData?.background_img}
          handleBannerPicture={handleBannerPicture}
          handleRemovePicture={handleRemovePicture}
        />
      )}
      {profilePhotoModal && (
        <ProfilePhotoModal
          modal={profilePhotoModal}
          toggle={() => setProfilePhotoModal(!profilePhotoModal)}
          title="Profile Photo"
          editProfileImage={profileData?.profile_img}
          handleBannerPicture={handleBannerPicture}
          handleRemovePicture={handleRemovePicture}
        />
      )}
      {removeEduModal && (
        <DeleteModal
          modal={DeleteModal}
          toggle={() => setRemoveEduModal(!removeEduModal)}
          title="Delete Confirmation"
          deleteConfirm={() => {
            deleteEducation(removeEduId);
          }}
          text="Are you want to sure to delete this?"
        />
      )}
      {removeExpModal && (
        <DeleteModal
          modal={DeleteModal}
          toggle={() => setRemoveExpModal(!removeExpModal)}
          title="Delete Confirmation"
          deleteConfirm={() => {
            deleteExperience(removeExpId);
          }}
          text="Are you want to sure to delete this?"
        />
      )}

      {customProfileModal && (
        <CustomProfileModal
          modal={customProfileModal}
          toggle={() => setCustomProfileModal(!customProfileModal)}
          title="Custom Profile"
          profileURL={profileData?.profile_url}
        />
      )}
    </div>
  );
}
