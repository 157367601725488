import React, { useEffect, useState } from "react";
import { Input, Modal, ModalBody, ModalHeader } from "reactstrap";
import moment from "moment";
import DatePickerCustom from "../../../core/components/DatePicker/DatePickerReact";
import DatePicker from "react-datepicker";
//import TimePicker from 'react-time-picker';
//import "react-time-picker/dist/TimePicker.css";
//import "react-clock/dist/Clock.css";
import TimePicker from "rc-time-picker";
import "rc-time-picker/assets/index.css";
import rectangleImage from "../../../assets/img/RectanglepayBycard.png";

const ScheduleModal = (props) => {
  const { modal, toggle, title, setScheduledData, setScheduleMessage } = props;
  var d = new Date();
  let h = (d.getHours() < 10 ? "0" : "") + d.getHours();
  let m = (d.getMinutes() < 10 ? "0" : "") + d.getMinutes();
  //  `${h}:${m}`
  const defaultMoment = moment().set({
    hour: d.getHours(),
    minute: d.getMinutes(),
    second: 0, // Assuming you want seconds to be zero in the default time
  });

  console.log(defaultMoment);
  var today = new Date();
  const [scheduleDate, setScheduleDate] = useState(today);
  const [scheduleTime, setScheduleTime] = useState();
  const [todayDate, setTodayDate] = useState(today);
  const [timeErrorCheck, setTimeErrorCheck] = useState(true);

  useEffect(() => {
    const currentMoment = moment();
    setScheduleTime(currentMoment);
  }, []);

  useEffect(() => {
    setTodayDate(moment(todayDate).format("YYYY-MM-DD"));
    console.log(todayDate);
  }, [scheduleDate]);

  useEffect(() => {
    setScheduledData(
      moment(scheduleDate).format("YYYY-MM-DD") +
        " " +
        moment(scheduleTime, "HH:mm:ss").format("HH:mm:ssZ")
    );

    console.log(
      "scheduleDate",
      moment(scheduleDate).format("YYYY-MM-DD"),
      scheduleDate
    );
  }, [scheduleDate, scheduleTime]);

  // Timer Logic Implemented

  const [timeError, setTimeError] = useState("");
  const handleTime = (time) => {
    if (!time) {
      return;
    }
    var today = new Date();
    console.log(today);
    // let formattedDate=moment(today).format("YYYY-MM-DD");
    const formattedTimes = moment(time._d).format("HH:mm");
    console.log("timeSelected", formattedTimes);
    // console.log('timeSelected',time._d);
    const momentObject = moment(scheduleDate, "YYYY-MM-DD");
    const jsDateObject = momentObject.toDate();

    let currentTimeFormatted = moment(today);
    console.log({ jsDateObject, momentObject, currentTimeFormatted });
    let formattedTime = moment(moment().format("L") + " " + formattedTimes);

    if (
      !momentObject.isSameOrAfter(currentTimeFormatted) &&
      formattedTime.diff(currentTimeFormatted) <= 0
    ) {
      setTimeError("Please select a time after the current time");
      setScheduleTime("");
      console.log(timeError);
    } else {
      console.log("entered else");
      setTimeError("");
      console.log(time);
      setScheduleTime(time);
      console.log(timeError);
    }
  };

  //Handle Date Change
  const handleDateChange = (date) => {
    setScheduleDate(date);

    // Calculate the current time when the date is selected

    console.log(date);
    console.log(defaultMoment);
    const formattedTimess = moment(defaultMoment._d).format("HH:mm");
    console.log(formattedTimess);
    setScheduleTime(defaultMoment);
    setTimeErrorCheck(false);
  };

  useEffect(() => {
    console.log("dateSelected", defaultMoment);
    setScheduleTime(defaultMoment);
    setTimeError("");
  }, [scheduleDate]);

  return (
    <div>
      <Modal
        isOpen={modal}
        className="create-post-header gradient-background"
        size="lg"
        style={{ maxWidth: "600px", top: "55px" }}
      >
        <ModalHeader toggle={toggle}>{title}</ModalHeader>
        <ModalBody style={{ overflow: "inherit" }}>
          <div className="row create-post">
            <h5>
              Easily schedule your posts for a later time hassle-free! You can
              find this post under 'Scheduled Posts' in the Post section under
              'My Network'.
            </h5>
            <div className="shedule">
              <h6>Schedule Date and Time</h6>
              <form className="my-3">
                <div className="row">
                  <div className="col-4">
                    <div className="input-group">
                      {/* <input
                        type="date"
                        className="form-control"
                        min={today}
                        value={scheduleDate}
                        onChange={(event) =>
                          setScheduleDate(event.target.value)
                        }
                      /> */}

                      {/* <DatePickerCustom
                        value={scheduleDate}
                        onChange={(date) =>{setScheduleDate(date)
                        console.log(date)
                          console.log(`${h}:${m}`)
                          setScheduleTime(`${h}:${m}`)  
                          setTimeErrorCheck(false)              } }
                        min={today}
                        leftAlign={true}
                      /> */}

                      <DatePickerCustom
                        value={scheduleDate}
                        onChange={(date) => handleDateChange(date)}
                        min={today}
                        leftAlign={true}
                      />
                    </div>
                  </div>
                  <div className="col-4">
                    <div className="input-group">
                      <TimePicker
                        //style={{ fontSize: "14px !important",height:"37px !important"}}
                        showSecond={false}
                        // defaultValue={defaultMoment}
                        value={scheduleTime}
                        className="customTimePicker"
                        onChange={(selectedTime) => {
                          handleTime(selectedTime);
                          setTimeErrorCheck(false);
                        }}
                        use12Hours
                        placeholder="Select Time"
                      />
                      {/* <div className="example-custom-input-left">
                       
                      </div> */}

                      {/* <input
                        type="time"
                        className="form-control"
                        name="time"
                        id="time"
                        value={scheduleTime}
                        onChange={(event) =>{handleTime(event.target.value);setTimeErrorCheck(false)} }
                      /> */}
                      {timeError && !timeErrorCheck && (
                        <p style={{ color: "#0432a3", fontSize: "10px" }}>
                          {timeError}
                        </p>
                      )}
                    </div>
                    {/* <div className="input-group">
                     <TimePicker
        onChange={handleTime}
        value={scheduleTime}
        disableClock={true} // Set to true if you want to disable the clock-style interface
      />
                  
                     </div>
                 */}
                  </div>
                </div>
              </form>
              {scheduleDate && (
                <p className="pt-4">
                  This post is scheduled for the date{" "}
                  <span className="dark-blue-color">
                    {moment(scheduleDate).format("MMM DD,YYYY")}
                  </span>{" "}
                  at{" "}
                  <span className="dark-blue-color">
                    {moment(scheduleTime, "HH:mm:ss").format("hh:mm A")}
                  </span>
                </p>
              )}
            </div>
            <div className="text-end py-30">
              {!timeError ? (
                <div
                  className="btn btn-blue"
                  onClick={() => {
                    toggle();
                    setScheduleMessage({
                      scheduleDate: scheduleDate,
                      scheduleTime: scheduleTime,
                    });
                  }}
                >
                  Ok
                </div>
              ) : (
                <div
                  className="btn btn-blue"
                  style={{ opacity: "0.5", cursor: "not-allowed" }}
                >
                  Ok
                </div>
              )}
            </div>
          </div>
        </ModalBody>
      </Modal>
    </div>
  );
};

export default ScheduleModal;
