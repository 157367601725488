import { call } from "./apiCall"

class ConnectionsAPI {
    getConnections(searchData, user_id) {
        return new Promise( (resolve, reject) => {
            const token = localStorage.getItem("accessToken");
            (async () => {
                try {
                    const res = await call(
                        "get",
                        user_id ? `api/v1/feed/connection?user_id=${user_id}` : `api/v1/feed/connection`,
                        searchData,
                        null,
                        token
                    )

                    if(res.data.success===true){
                        resolve(res.data)
                  }
                  if(res.data.success===false){
                    resolve(res.data)
                  }

                } catch (err) {
                    console.log(err)
                    reject(err)
                }
            })()
        })
    }

    getFollowers(searchData, user_id) {
        return new Promise( (resolve, reject) => {
            const token = localStorage.getItem("accessToken");
            (async () => {
                try {
                    const res = await call(
                        "get",
                        user_id ? `api/v1/feed/follower?user_id=${user_id}` : `api/v1/feed/follower`,
                        searchData,
                        null,
                        token
                    )

                    if(res.data.success===true){
                        resolve(res.data)
                  }
                  if(res.data.success===false){
                    resolve(res.data)
                  }

                } catch (err) {
                    console.log(err)
                    reject(err)
                }
            })()
        })
    }
 
    getFollowing(searchData, user_id) {
        return new Promise( (resolve, reject) => {
            const token = localStorage.getItem("accessToken");
            (async () => {
                try {
                    const res = await call(
                        "get",
                        user_id ? `api/v1/feed/following?user_id=${user_id}` : `api/v1/feed/following`,
                        searchData,
                        null,
                        token
                    )

                    if(res.data.success===true){
                        resolve(res.data)
                  }
                  if(res.data.success===false){
                    resolve(res.data)
                  }

                } catch (err) {
                    console.log(err)
                    reject(err)
                }
            })()
        })
    }

    getLocations(searchKey) {
        return new Promise( (resolve, reject) => {
            (async () => {
                try {
                    const res = await call(
                        "get",
                        `api/v1/location?search=${searchKey}`,
                        null,
                        null,
                        null
                    )

                    if(res.data.success===true){
                        resolve(res.data)
                  }
                  if(res.data.success===false){
                    resolve(res.data)
                  }

                } catch (err) {
                    console.log(err)
                    reject(err)
                }
            })()
        })
    }

    getSchools(searchKey) {
        return new Promise( (resolve, reject) => {
            const token = localStorage.getItem("accessToken");
            (async () => {
                try {
                    const res = await call(
                        "get",
                        `api/v1/school`,
                        null,
                        null,
                        token
                    )

                    if(res.data.success===true){
                        resolve(res.data)
                  }

                    if(res.data.success===false){
                        resolve(res.data)
                    }

                } catch (err) {
                    console.log(err)
                    reject(err)
                }
            })()
        })
    }

    getCurrentCompany() {
        return new Promise( (resolve, reject) => {
            const token = localStorage.getItem("accessToken");
            (async () => {
                try {
                    const res = await call(
                        "get",
                        'api/v1/company',
                        null,
                        null,
                        token
                    )

                    if(res.data.success===true){
                        resolve(res.data)
                  }
                  if(res.data.success===false){
                    resolve(res.data)
                  }

                } catch (err) {
                    console.log(err)
                    reject(err)
                }
            })()
        })
    }

    getFilter(searchData) {
        return new Promise( (resolve, reject) => {
            (async () => {
                try {
                    const token = localStorage.getItem("accessToken");
                    const res = await call(
                        "get",
                        `api/v1/feed/connection`,
                        searchData,
                        null,
                        token
                    )

                    if(res.data.success===true){
                        resolve(res.data)
                  }
                  if(res.data.success===false){
                    resolve(res.data)
                  }

                } catch (err) {
                    console.log(err)
                    reject(err)
                }
            })()
        })
    }

    sendConnectionRequest(id) {
        return new Promise( (resolve, reject) => {
            (async () => {
                try {
                    const token = localStorage.getItem("accessToken");
                    const res = await call(
                        "post",
                        `api/v1/feed/connect-request/${id}`,
                        null,
                        null,
                        token
                    )

                    if(res.data.success===true){
                        resolve(res.data)
                  }
                  if(res.data.success===false){
                    resolve(res.data)
                  }

                } catch (err) {
                    console.log(err)
                    reject(err)
                }
            })()
        })
    }
}

export const connectionsAPI = new ConnectionsAPI()
