import React from "react";
import "../network.scss";
import Layout from "../Layout/Layout";
import "../../dashboard/dashboard.scss";
import Advertisement from "../../dashboard/Advertisement";
import Refer from "../../../core/components/layout/Sidebar/Refer";
import Ad from "../../../core/components/layout/Sidebar/Ad";
import PostOnDashboard from "../../dashboard/components/postComponent/PostOnDashboard";
import CreatePostModal from "../../dashboard/components/createPost/CreatePostModal";
import ViewReactionModal from "../../dashboard/components/viewReactionModal";
import InfiniteScroll from "react-infinite-scroll-component";
import ReportModal from "../../dashboard/components/reportTypeModal";
import { ToastContainer } from "react-toastify";
import DeleteModal from "../../../core/components/modal/DeleteModal";
import RoleChangeModal from "../../dashboard/components/RoleChangeModal";
import PostOnDashboardContainer from "../../dashboard/components/postComponent/postOnDashboard.container";
import ShareModal from "../../../core/components/sharePost/sharePost";

export default function Posts(props) {
  const {
    pageState,
    setPageState,
    postData,
    handleNext,
    user,
    userName,
    createPostModal,
    setCreatePostModal,
    userId,
    handleReaction,
    postId,
    setPostID,
    isViewReaction,
    setViewReaction,
    createPostClick,
    privacy_type,
    dropDown,
    setDropDown,
    discardModalStatus,
    setDiscardModalStatus,
    closeAllModal,
    delete_post,
    editPost,
    updateData,
    isEdit,
    setIsEdit,
    postNow,
    dropRef,
    setDraftDetails,
    setDataAvailable,
    isDataAvailable,
    removeSavePost,
    save_post,
    getHashtagText,
    dropRefShare,
    dropDownShare,
    setDropDownShare,
    createLink,
    repostPost,
    likeResult,
    commentBoxStatus,
    setCommentBoxStatus,
    getTime,
    reportModal,
    setReportModal,
    hideUserPost,
    closeModal,
    setCloseModal,
    deleteId,
    setDeleteId,
    roleShareModal,
    setRoleShareModal,
    getPageDetailData,
    dropDownRepost,
    setDropDownRepost,
    dropRefRepost,
    rolePageId,
    setRolePageId,
    openShareModal,
    setOpenShareModal,
    shareData,
    setShareData,
    setByUserId,
  } = props;

  return (
    <Layout>
      <div className="col-lg-6">
        <div className="main-center mt-4">
          <div className="wrapper">
            <div className="container p-20">
              <div className="">
                <ul
                  className="nav nav-tabs"
                  role="tablist"
                  style={{ border: "0px" }}
                >
                  <li className="nav-item">
                    <a
                      className="nav-link active"
                      data-bs-toggle="tab"
                      href="#home"
                      onClick={() => {
                        setPageState("published");
                      }}
                    >
                      My Posts
                    </a>
                  </li>
                  <li
                    className="nav-item"
                    onClick={() => {
                      setPageState("saved");
                    }}
                  >
                    <a className="nav-link" data-bs-toggle="tab" href="#menu1">
                      Saved Posts
                    </a>
                  </li>
                  <li className="nav-item">
                    <a
                      className="nav-link"
                      data-bs-toggle="tab"
                      href="#menu2"
                      onClick={() => {
                        setPageState("scheduled");
                      }}
                    >
                      Scheduled
                    </a>
                  </li>
                  <li className="nav-item">
                    <a
                      className="nav-link"
                      data-bs-toggle="tab"
                      href="#menu3"
                      onClick={() => {
                        setPageState("draft");
                      }}
                    >
                      Draft
                    </a>
                  </li>
                </ul>
              </div>
              <div
                className="line"
                style={{
                  marginLeft: "-21px",
                  marginRight: "-21px",
                }}
              ></div>

              <div className="tab-content">
                <div id="home" className="container tab-pane active">
                  <div className="row">
                    {pageState === "published" ? (
                      <InfiniteScroll
                        dataLength={
                          postData?.data?.length ? postData?.data?.length : 0
                        }
                        next={handleNext}
                        hasMore={
                          postData?.count === 0
                            ? false
                            : postData?.count <= postData?.data?.length
                            ? false
                            : true
                        }
                        style={{ overflow: "visible" }}
                        loader={
                          <p style={{ textAlign: "center" }} className="my-30">
                            Loading...
                          </p>
                        }
                        endMessage={
                          <p style={{ textAlign: "center" }} className="my-30">
                            <b>No further posts</b>
                          </p>
                        }
                      >
                        {postData?.data?.map((item, index) => (
                          <div>
                            {/* <PostOnDashboard
                              user={user}
                              item={item}
                              index={index}
                              dropDown={dropDown}
                              userId={userId}
                              editPost={editPost}
                              dropRef={dropRef}
                              dropRefShare={dropRefShare}
                              getHashtagText={getHashtagText}
                              createLink={createLink}
                              privacy_type={privacy_type}
                              save_post={save_post}
                              removeSavePost={removeSavePost}
                              setDropDown={setDropDown}
                              handleReaction={handleReaction}
                              isViewReaction={isViewReaction}
                              setViewReaction={setViewReaction}
                              setPostID={setPostID}
                              userName={userName}
                              type="myPost"
                               reportModal={reportModal}
                               setReportModal={setReportModal}
                                hideUserPost={hideUserPost}
                              commentBoxStatus={commentBoxStatus}
                              setCommentBoxStatus={setCommentBoxStatus}
                              dropDownShare={dropDownShare}
                              setDropDownShare={setDropDownShare}
                              repostPost={repostPost}
                              likeResult={likeResult}
                              getTime={getTime}
                              closeModal={closeModal}
                              setCloseModal={setCloseModal}
                              setDeleteId={setDeleteId}
                              dropDownRepost={dropDownRepost}
                              setDropDownRepost={setDropDownRepost}
                              dropRefRepost={dropRefRepost}
                              roleShareModal={roleShareModal}
                              setRoleShareModal={setRoleShareModal}
                              getPageDetailData={getPageDetailData}
                            /> */}
                            <PostOnDashboardContainer
                              item={item}
                              index={index}
                              userId={userId}
                              editPost={editPost}
                              isViewReaction={isViewReaction}
                              setViewReaction={setViewReaction}
                              setPostID={setPostID}
                              userName={userName}
                              type="myPost"
                              reportModal={reportModal}
                              setReportModal={setReportModal}
                              commentBoxStatus={commentBoxStatus}
                              setCommentBoxStatus={setCommentBoxStatus}
                              closeModal={closeModal}
                              setCloseModal={setCloseModal}
                              setDeleteId={setDeleteId}
                              roleShareModal={roleShareModal}
                              setRoleShareModal={setRoleShareModal}
                              setRolePageId={setRolePageId}
                              openShareModal={openShareModal}
                              setOpenShareModal={setOpenShareModal}
                              shareData={shareData}
                              setShareData={setShareData}
                              setByUserId={setByUserId}
                            />
                            <div
                              className="line mt-20"
                              style={{
                                marginLeft: "-32px",
                                marginRight: "-32px",
                              }}
                            ></div>
                          </div>
                        ))}
                      </InfiniteScroll>
                    ) : (
                      <></>
                    )}
                  </div>
                </div>
                <div id="menu1" className="container tab-pane fade">
                  <div className="row">
                    {pageState === "saved" ? (
                      <InfiniteScroll
                        dataLength={
                          postData?.data?.length ? postData?.data?.length : 0
                        }
                        next={handleNext}
                        hasMore={
                          postData?.count === 0
                            ? false
                            : postData?.count <= postData?.data?.length
                            ? false
                            : true
                        }
                        style={{ overflow: "visible" }}
                        loader={
                          <p style={{ textAlign: "center" }} className="my-30">
                            Loading...
                          </p>
                        }
                        endMessage={
                          postData?.data?.length == 0 ? (
                            <p
                              style={{ textAlign: "center" }}
                              className="my-30"
                            >
                              <b>No saved posts</b>
                            </p>
                          ) : (
                            <></>
                          )
                        }
                      >
                        {postData?.data?.map((item, index) => (
                          <div>
                            <PostOnDashboardContainer
                              item={item}
                              index={index}
                              userId={userId}
                              editPost={editPost}
                              isViewReaction={isViewReaction}
                              setViewReaction={setViewReaction}
                              setPostID={setPostID}
                              userName={userName}
                              type="savedPost"
                              reportModal={reportModal}
                              setReportModal={setReportModal}
                              commentBoxStatus={commentBoxStatus}
                              setCommentBoxStatus={setCommentBoxStatus}
                              closeModal={closeModal}
                              setCloseModal={setCloseModal}
                              setDeleteId={setDeleteId}
                              roleShareModal={roleShareModal}
                              setRoleShareModal={setRoleShareModal}
                              setRolePageId={setRolePageId}
                              openShareModal={openShareModal}
                              setOpenShareModal={setOpenShareModal}
                              shareData={shareData}
                              setShareData={setShareData}
                              setByUserId={setByUserId}
                            />
                            {/* <div
                              className="line mt-20"
                              style={{
                                marginLeft: "-32px",
                                marginRight: "-32px",
                              }}
                            ></div> */}
                          </div>
                        ))}
                      </InfiniteScroll>
                    ) : (
                      <></>
                    )}
                  </div>
                </div>
                <div id="menu2" className="container tab-pane fade">
                  <div className="row">
                    {pageState === "scheduled" ? (
                      <InfiniteScroll
                        dataLength={
                          postData?.data?.length ? postData?.data?.length : 0
                        }
                        next={handleNext}
                        hasMore={
                          postData?.count === 0
                            ? false
                            : postData?.count <= postData?.data?.length
                            ? false
                            : true
                        }
                        style={{ overflow: "visible" }}
                        loader={
                          <p style={{ textAlign: "center" }} className="my-30">
                            Loading...
                          </p>
                        }
                        endMessage={
                          <p style={{ textAlign: "center" }} className="my-30">
                            <b>No further scheduled posts</b>
                          </p>
                        }
                      >
                        {postData?.data?.map((item, index) => (
                          <div>
                            <PostOnDashboardContainer
                              item={item}
                              index={index}
                              userId={userId}
                              editPost={editPost}
                              isViewReaction={isViewReaction}
                              setViewReaction={setViewReaction}
                              setPostID={setPostID}
                              userName={userName}
                              type="scheduledPost"
                              reportModal={reportModal}
                              setReportModal={setReportModal}
                              commentBoxStatus={commentBoxStatus}
                              setCommentBoxStatus={setCommentBoxStatus}
                              closeModal={closeModal}
                              setCloseModal={setCloseModal}
                              setDeleteId={setDeleteId}
                              roleShareModal={roleShareModal}
                              setRoleShareModal={setRoleShareModal}
                              setRolePageId={setRolePageId}
                              openShareModal={openShareModal}
                              setOpenShareModal={setOpenShareModal}
                              shareData={shareData}
                              setShareData={setShareData}
                              postNow={postNow}
                              setByUserId={setByUserId}
                            />
                            {/* <PostOnDashboard
                              item={item}
                              index={index}
                              dropDown={dropDown}
                              userId={userId}
                              editPost={editPost}
                              dropRef={dropRef}
                              dropRefShare={dropRefShare}
                              getHashtagText={getHashtagText}
                              createLink={createLink}
                              privacy_type={privacy_type}
                              save_post={save_post}
                              removeSavePost={removeSavePost}
                              setDropDown={setDropDown}
                              handleReaction={handleReaction}
                              isViewReaction={isViewReaction}
                              setViewReaction={setViewReaction}
                              setPostID={setPostID}
                              userName={userName}
                              type="scheduledPost"
                              //  reportModal={reportModal}
                              //  setReportModal={setReportModal}
                              //   hideUserPost={hideUserPost}
                              commentBoxStatus={commentBoxStatus}
                              setCommentBoxStatus={setCommentBoxStatus}
                              dropDownShare={dropDownShare}
                              setDropDownShare={setDropDownShare}
                              repostPost={repostPost}
                              likeResult={likeResult}
                              getTime={getTime}
                              postNow={postNow}
                              closeModal={closeModal}
                              setCloseModal={setCloseModal}
                              setDeleteId={setDeleteId}
                            /> */}
                            <div
                              className="line mt-20"
                              style={{
                                marginLeft: "-32px",
                                marginRight: "-32px",
                              }}
                            ></div>
                          </div>
                        ))}
                      </InfiniteScroll>
                    ) : (
                      <></>
                    )}
                  </div>
                </div>
                <div id="menu3" className="container tab-pane fade">
                  <div className="row">
                    {pageState === "draft" ? (
                      <InfiniteScroll
                        dataLength={
                          postData?.data?.length ? postData?.data?.length : 0
                        }
                        next={handleNext}
                        hasMore={
                          postData?.count === 0
                            ? false
                            : postData?.count <= postData?.data?.length
                            ? false
                            : true
                        }
                        style={{ overflow: "visible" }}
                        loader={
                          <p style={{ textAlign: "center" }} className="my-30">
                            Loading...
                          </p>
                        }
                        endMessage={
                          <p style={{ textAlign: "center" }} className="my-30">
                            <b>No further posts</b>
                          </p>
                        }
                      >
                        {postData?.data?.map((item, index) => (
                          <div>
                            <PostOnDashboardContainer
                              item={item}
                              index={index}
                              userId={userId}
                              editPost={editPost}
                              isViewReaction={isViewReaction}
                              setViewReaction={setViewReaction}
                              setPostID={setPostID}
                              userName={userName}
                              type="draftPost"
                              reportModal={reportModal}
                              setReportModal={setReportModal}
                              commentBoxStatus={commentBoxStatus}
                              setCommentBoxStatus={setCommentBoxStatus}
                              closeModal={closeModal}
                              setCloseModal={setCloseModal}
                              setDeleteId={setDeleteId}
                              roleShareModal={roleShareModal}
                              setRoleShareModal={setRoleShareModal}
                              setRolePageId={setRolePageId}
                              openShareModal={openShareModal}
                              setOpenShareModal={setOpenShareModal}
                              shareData={shareData}
                              setShareData={setShareData}
                              postNow={postNow}
                              setByUserId={setByUserId}
                            />
                            {/* <PostOnDashboard
                              item={item}
                              index={index}
                              dropDown={dropDown}
                              userId={userId}
                              editPost={editPost}
                              dropRef={dropRef}
                              dropRefShare={dropRefShare}
                              getHashtagText={getHashtagText}
                              createLink={createLink}
                              privacy_type={privacy_type}
                              save_post={save_post}
                              removeSavePost={removeSavePost}
                              setDropDown={setDropDown}
                              handleReaction={handleReaction}
                              isViewReaction={isViewReaction}
                              setViewReaction={setViewReaction}
                              setPostID={setPostID}
                              userName={userName}
                              type="draftPost"
                              //  reportModal={reportModal}
                              //  setReportModal={setReportModal}
                              //   hideUserPost={hideUserPost}
                              commentBoxStatus={commentBoxStatus}
                              setCommentBoxStatus={setCommentBoxStatus}
                              dropDownShare={dropDownShare}
                              setDropDownShare={setDropDownShare}
                              setDeleteId={setDeleteId}
                              closeModal={closeModal}
                              setCloseModal={setCloseModal}
                              repostPost={repostPost}
                              likeResult={likeResult}
                              getTime={getTime}
                              postNow={postNow}
                            /> */}
                            <div
                              className="line mt-20"
                              style={{
                                marginLeft: "-32px",
                                marginRight: "-32px",
                              }}
                            ></div>
                          </div>
                        ))}
                      </InfiniteScroll>
                    ) : (
                      <></>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="col-lg-3">
        <div className="mt-4">
          <div className="advertisement_sidebar">
            <Advertisement />
          </div>
          <div className="wrappers mt-4">
            <Ad />
          </div>
        </div>
        <div className=" middle sidebar-sticky mt-4">
          <Refer />
        </div>
      </div>
      {isViewReaction && (
        <ViewReactionModal
          modal={isViewReaction}
          toggle={() => setViewReaction(!isViewReaction)}
          title="View Reactions"
          // userName ={userName}
          postId={postId}
        />
      )}

      {createPostModal && (
        <CreatePostModal
          modal={createPostModal}
          toggle={() => {
            setCreatePostModal(!createPostModal);
            if (!createPostModal) {
              setDiscardModalStatus(true);
            }
          }}
          toggleOff={() => {
            setCreatePostModal(false);
          }}
          title="Create Post"
          userName={userName}
          userId={userId}
          createPostClick={createPostClick}
          discardModalStatus={discardModalStatus}
          setDiscardModalStatus={setDiscardModalStatus}
          closeAllModal={closeAllModal}
          updateData={updateData}
          isEdit={isEdit}
          setIsEdit={setIsEdit}
          setDraftDetails={setDraftDetails}
          isDataAvailable={isDataAvailable}
          setDataAvailable={setDataAvailable}
          type={pageState}
          setCommentBoxStatus={setCommentBoxStatus}
        />
      )}

      {reportModal && (
        <ReportModal
          modal={reportModal}
          toggle={() => setReportModal(!reportModal)}
          post_id={postId}
        />
      )}
      {closeModal && (
        <DeleteModal
          modal={DeleteModal}
          toggle={() => setCloseModal(!closeModal)}
          title="Delete Confirmation"
          deleteConfirm={() => {
            delete_post(deleteId);
          }}
          text="Are you want to sure to delete this?"
        />
      )}
      {roleShareModal && (
        <RoleChangeModal
          modal={roleShareModal}
          toggle={() => setRoleShareModal(!roleShareModal)}
          title="Post, like, comment and share "
          postId={postId}
          getPageDetailData={getPageDetailData}
          rolePageId={rolePageId}
        />
      )}
      {openShareModal && (
        <ShareModal
          modal={openShareModal}
          toggle={() => setOpenShareModal(!openShareModal)}
          title="Share"
          shareData={shareData}
          type="shared_post"
          source="dashboard"
          toggleSuccessful={() => setOpenShareModal(!openShareModal)}
        />
      )}
      <ToastContainer />
    </Layout>
  );
}
