import React from "react";
import "./Input.scss";
import PhoneInput from "react-phone-input-2";
import callIcon from "../../../assets/img/call.svg";
const MobileInput = ({
  label,
  containerClassName,
  inputClass,
  error,
  inputType,
  labelImage,
  required,
  tooltipMessage,
  labelStyle,
  ...otherProps
}) => {
  return (
    <div className={`${containerClassName}`}>
      <label
        style={
          labelStyle ? labelStyle : { fontWeight: "350", fontSize: "17px" }
        }
        htmlFor="exampleInputEmail1"
        className="form-label d-flex align-items-center"
      >
        {labelImage && (
          <button
            type="button"
            className="bg-transparent border-0"
            data-toggle="tooltip"
            data-placement="top"
            title={tooltipMessage}
          >
            <img src={labelImage} width={20} className="me-2" />
          </button>
        )}{" "}
        {label}
        {required && <span className="text-danger">*</span>}
      </label>
      <div className="d-flex form-control p-0">
        {inputType === "page" ? (
          <></>
        ) : (
          <img src={callIcon} alt="" className="px-2" />
        )}
        <PhoneInput
          inputClass={`w-100 ps-5 border-none ${inputClass}`}
          inputStyle={{
            border: "none",
          }}
          buttonClass="bg-white"
          buttonStyle={{ border: "none" }}
          containerClass=""
          countryCodeEditable={false}
          disableCountryGuess
          country={"us"}
          {...otherProps}
        />
      </div>
      {error && <label className="label-input100 text-danger">{error}</label>}
    </div>
  );
};

export default MobileInput;
