import { SHARE_MY_CONTENT } from "../types";
import { shareApi } from "../../api/shareApi";


export const shareContent = (data, token) => (dispatch) => {
  return shareApi.shareContent(data, token).then((shareData) => {
    console.log(shareData?.data)
      dispatch({
        type: SHARE_MY_CONTENT,
        payload: shareData
       });
       return Promise.resolve(shareData?.data);
 },
 (error) => {
    console.log(error);
    return Promise.reject(error);
  })
}

