import React, { useState } from "react";
import { useEffect } from "react";
import {
  Modal,
  ModalBody,
  Input,
  Label,
  FormGroup,
  ModalHeader,
} from "reactstrap";
import info from "../../../../assets/img/info.svg";
import placeholderUser from "../../../../assets/img/placeholder_user.jpg";
import ConfirmPassword from "./ConfirmPassword";
const PageAdminRoll = (props) => {
  const {
    modal,
    toggle,
    title,
    selected,
    setAddPageAdminModal,
    setRoll,
    roll,
    setSelected,
    addAdmin,
  } = props;
  const [isAdmin, setIsAdmin] = useState(false);
  const [isCM, setIsCM] = useState(false);
  const [isHM, setIsHM] = useState(false);

  useEffect(() => {
    if (roll === "Admin") {
      setIsAdmin(true);
    }
    if (roll === "Content Manager") {
      setIsCM(true);
    }
    if (roll === "HR Manager") {
      setIsHM(true);
    }
  }, []);

  const closeModals = () => {
    addAdmin();
    setAddPageAdminModal(false);
    toggle();
  };
  const closeModalsWTData = () => {
    setSelected("");
    setRoll("");
    setAddPageAdminModal(false);
    toggle();
  };

  const updateRoll = (e, roll) => {
    setRoll(roll);
    if (e.target.checked && roll === "Admin") {
      setIsAdmin(true);
      setIsCM(false);
      setIsHM(false);
    } else if (e.target.checked && roll === "Content Manager") {
      setIsCM(true);
      setIsHM(false);
      setIsAdmin(false);
    } else if (e.target.checked && roll === "HR Manager") {
      setIsHM(true);
      setIsCM(false);
      setIsAdmin(false);
    }
  };
  const remove = () => {
    setSelected("");
    toggle();
  };
  return (
    <Modal
      isOpen={modal}
      size="lg"
      style={{ maxWidth: "600px", maxHeight: "420px" }}
      className="topDiv gradient-background"
      centered
    >
      <ModalHeader className="header" toggle={closeModalsWTData}>
        {title}
      </ModalHeader>
      <ModalBody>
        <div className="grey-bg bg-white admin-roll">
          {selected && (
            <div className="row mb-2">
              <div className="col-lg-2">
                <div className="img">
                  <img
                    src={
                      selected?.profile_img
                        ? selected?.profile_img
                        : placeholderUser
                    }
                    className="image-fit profile-Img"
                    alt="profile image"
                  />
                </div>
              </div>
              <div className="col-lg-8">
                <div className="text">
                  <p>
                    {" "}
                    <strong>
                      {selected.first_name.slice(0, 12) +
                        (selected.first_name.length > 12 ? "..." : "") +
                        " " +
                        selected.last_name.slice(0, 10) +
                        (selected.last_name.length > 10 ? "..." : "")}
                    </strong>
                  </p>
                  {selected?.profile?.profile_headline && (
                    <p>
                      {selected.profile?.profile_headline.slice(0, 30) +
                        (selected.profile?.profile_headline > 30 ? "..." : "")}
                    </p>
                  )}
                </div>
              </div>
              {roll?.length <= 0 ? (
                <div className="col-lg-2">
                  <div className="float-sm-end">
                    <div className="text-blue remove" onClick={() => remove()}>
                      <strong>Remove</strong>
                    </div>
                  </div>
                </div>
              ) : (
                <></>
              )}
            </div>
          )}
          <div className="line"></div>
          <div className="row mt-20">
            <h6>
              Assign an admin role
              <span>
                {" "}
                <img src={info} alt="" />
              </span>
            </h6>
          </div>
          <div className="row">
            <div className="col-12">
              <form className="mt-30">
                <div className="d-flex gap-3 mb-20">
                  <input
                    type="radio"
                    style={{ width: "20px", height: "20px" }}
                    name="roll"
                    onClick={(e) => {
                      updateRoll(e, "Admin");
                    }}
                    checked={isAdmin}
                  />
                  <div className="details">
                    <p>
                      <strong>Admin</strong>
                    </p>
                    <p>
                      Admins have rights to manage everything except deleting or
                      transferring the page.
                    </p>
                  </div>
                </div>
                <div className="d-flex gap-3 mb-20">
                  <input
                    type="radio"
                    style={{ width: "20px", height: "20px" }}
                    name="roll"
                    onClick={(e) => {
                      updateRoll(e, "Content Manager");
                    }}
                    checked={isCM}
                  />
                  <div className="details">
                    <p>
                      <strong>Content Manager</strong>
                    </p>
                    <p>
                      Content Manager acts as marketing and can edit and publish
                      content on your page.
                    </p>
                  </div>
                </div>
                <div className="d-flex gap-3 mb-20">
                  <input
                    type="radio"
                    style={{ width: "20px", height: "20px" }}
                    name="roll"
                    checked={isHM}
                    onClick={(e) => {
                      updateRoll(e, "HR Manager");
                    }}
                  />
                  <div className="details">
                    <p>
                      <strong>HR Manager</strong>
                    </p>
                    <p>
                      HR Manager can manage your job ads and the Talent Pool.
                    </p>
                  </div>
                </div>
                <div className="float-end mt-20">
                  <button
                    className="btn btn-light text-blue me-4"
                    onClick={() => toggle()}
                  >
                    Back
                  </button>
                  <button
                    className="btn btn-blue"
                    onClick={() => closeModals()}
                  >
                    Save
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </ModalBody>
    </Modal>
  );
};

export default PageAdminRoll;
