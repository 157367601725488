import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import "react-phone-input-2/lib/style.css";
import { useDispatch, useSelector } from "react-redux";
import { register } from "../../store/auth/authActions";
import { call } from "../../api/apiCall";
import Signup from "./Signup.jsx";
import { Navigate, useLocation } from "react-router-dom";

import {
  PhoneNumberUtil,
  PhoneNumberFormat as PNF,
} from "google-libphonenumber";
import { useEffect } from "react";
import { getLandingPageData } from "../../store/landing/landingAction";
import { toast } from "react-toastify";

export default function SignupContainer() {
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [emailName, setEmailName] = useState("");
  const [password, setPassword] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [errorEmail, setErrorEmail] = useState("");
  const [errorFirstName, setErrorFirstName] = useState("");
  const [errorLastName, setErrorLastName] = useState("");
  const [errorPassword, setErrorPassword] = useState("");
  const [errorPhoneNumber, setErrorPhoneNumber] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [phoneCount, setPhoneCount] = useState("");
  const phoneUtil = PhoneNumberUtil.getInstance();
  const [data, setData] = useState("")

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  let params = new URL(document.location).searchParams;
  let referId = params.get("referId");
  useEffect(() => {
    dispatch(getLandingPageData({ title: "home" })).then((res) => {
      setData(res);})
    },[])

  const emailExist = (emailName) => {
    return new Promise((resolve, reject) => {
      (async () => {
        try {
          const res = await call(
            "get",
            `api/v1/Auth/email-exist/${emailName}`,
            null,
            null,
            null
          );
          resolve(true);
        } catch (err) {
          resolve(false);
        }
      })();
    });
  };

  const phoneExist = (phoneNumber) => {
    return new Promise((resolve, reject) => {
      (async () => {
        try {
          const res = await call(
            "get",
            `api/v1/auth/phone-exist/${phoneNumber}`,
            null,
            null,
            null
          );
          resolve(true);
        } catch (err) {
          resolve(false);
        }
      })();
    });
  };

  const [emailexistence, setEmailExistence] = useState(true);
  const [phoneExistence, setPhoneExistence] = useState(true);

  const checkSignUpValidation = (type) => {
    let emailValid =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    let stringName = /^[a-zA-Z\s._]+$/;
    let stringNameLast = /^[a-zA-Z\s._]+$/;
    let passwordValid =
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!""#$%&'()*+,-./:;<=>?@[\]^_`{|}~])[A-Za-z\d!""#$%&'()*+,-./:;<=>?@[\]^_`{|}~]{8,}$/;

    let formIsValid = true;

    if (type === "all" || type === "emailName") {
      if (!emailName.trim()) {
        setErrorEmail("Please enter your email.");
        formIsValid = false;
      } else if (!emailValid.test(emailName.trim())) {
        setErrorEmail("Please enter your email in a valid format.");
        formIsValid = false;
      } else if (emailName.trim()) {
        emailExist(emailName).then((response) => {
          if (response === false) {
            setErrorEmail("Email already exists");
            setEmailExistence(false);
            formIsValid = false;
          } else {
            setErrorEmail("");
            setEmailExistence(true);
          }
        });
      } else {
        setErrorEmail("");
      }
    }
    if (type === "all" || type === "firstName") {
      if (!firstName.trim()) {
        setErrorFirstName("Enter your first name.");
        formIsValid = false;
      } else if (!stringName.test(firstName.trim())) {
        setErrorFirstName("First name is invalid.");
        formIsValid = false;
      } else if (firstName.length > 50) {
        setErrorFirstName("Max 50 characters allowed.");
        formIsValid = false;
      } else {
        setErrorFirstName("");
      }
    }
    if (type === "all" || type === "lastName") {
      if (!lastName.trim()) {
        setErrorLastName("Enter your last name.");
        formIsValid = false;
        return;
      } else if (!stringNameLast.test(lastName.trim())) {
        setErrorLastName("Last name is invalid.");
        formIsValid = false;
        return;
      } else if (lastName.length > 50) {
        setErrorLastName("Max 50 characters allowed.");
        formIsValid = false;
        return;
      } else {
        setErrorLastName("");
      }
    }
    if (type === "all" || type === "password") {
      if (!password) {
        setErrorPassword("Please enter your password");
        formIsValid = false;
        return;
      } else if (!passwordValid.test(password.trim())) {
        setErrorPassword(
          'The password should be minimum 8 characters long and contain at least one : Upper and Lower Case Alphabet, Number, Special Character. The special characters that can be used are [!""#$%&\'()*+,-./:;<=>?@[]^_`{|}~]'
        );
        formIsValid = false;
        return;
      } else {
        setErrorPassword("");
      }
    }
    if (type === "all" || type === "phoneNumber") {
      if (phoneNumber.length === 0) {
        setErrorPhoneNumber("Please enter the phone number.");
        formIsValid = false;
        return;
      } else if (phoneNumber.length < 10) {
        setErrorPhoneNumber("Please enter the valid number.");
        formIsValid = false;
        return;
      } else if (!phoneCountValidation()) {
        setErrorPhoneNumber("Please enter the valid number.");
        formIsValid = false;
        return;
      } else if (phoneNumber) {
        phoneExist(phoneNumber).then((response) => {
          if (response === false) {
            setErrorPhoneNumber("Phone already exist.");
            formIsValid = false;
            setPhoneExistence(false);
            return;
          } else {
            setErrorPhoneNumber("");
            setPhoneExistence(true);
          }
        });
      } else {
        setErrorPhoneNumber("");
      }
    }

    return formIsValid;
  };

  const phoneCountValidation = () => {
    let num = JSON.stringify(phoneNumber);
    let code = JSON.stringify(phoneCount);
    const number = phoneUtil.parse(num, code);

    //console.log(phoneUtil.isValidNumber(number));
    return phoneUtil.isValidNumber(number);
  };

  const handleFormSubmit = () => {
    let fcm_token = localStorage.getItem("firebase_token");
    let signUpData = {
      first_name: firstName,
      last_name: lastName,
      email: emailName,
      password: password,
      phone: phoneNumber,
      registration_type: "email",
      fcm_token
    };
    if (referId) Object.assign(signUpData, { refer_id: referId });
    dispatch(register(signUpData))
      .then((res) => {
        console.log("resTest", res);
        setTimeout(()=>{
          
          toast.success("Please verify your email", {
            position: toast.POSITION.BOTTOM_LEFT,
          });
        navigate("/otp",{state:{isSignup:true, email:signUpData.email}});
        },2000)
      })
      .catch((e) => {
        let message =
          (e.response && e.response.data && e.response.data.message) ||
          e.message ||
          e.toString();
        setErrorMessage(message);
      });
  };

  const handleEnterSubmit = (e) => {
    if (e.key === "Enter") {
      submitSignUpData(e);
    }
  };

  const submitSignUpData = (e) => {
    e.preventDefault();
    setErrorMessage("");
    if (checkSignUpValidation("all") && emailexistence && phoneExistence) {
      handleFormSubmit();
    }
  };

  const { isLoggedIn } = useSelector((state) => state.auth);

  if (isLoggedIn) {
    return location?.state?.from?.pathname === "/signup" ? (
     // <Navigate to="/dashboard" />
     console.log("navigate")
     
    ) : (
      <Navigate
        to={location?.state?.from?.pathname}
        state={{ from: location }}
      />
    );
  }

  console.log(referId);

  return (
    <Signup
      submitSignUpData={submitSignUpData}
      handleFormSubmit={handleFormSubmit}
      checkSignUpValidation={checkSignUpValidation}
      emailExist={emailExist}
      setFirstName={setFirstName}
      setLastName={setLastName}
      setEmailName={setEmailName}
      setPassword={setPassword}
      phoneNumber={phoneNumber}
      setPhoneNumber={setPhoneNumber}
      errorEmail={errorEmail}
      errorFirstName={errorFirstName}
      errorLastName={errorLastName}
      errorPassword={errorPassword}
      errorPhoneNumber={errorPhoneNumber}
      errorMessage={errorMessage}
      setPhoneCount={setPhoneCount}
      handleEnterSubmit={handleEnterSubmit}
      data={data}
    />
  );
}
