import moment from "moment";
import React, { useRef } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

export let combinedRef;
const CombinedTemplate = ({ userData, memberShip, combinedRef }) => {
  combinedRef = useRef();
  // const { profileData: userData } = useSelector((state) => state.profile);

  const bg_color = {
    background: userData?.cv_color || "#0432A3",
  };
  const headColor = {
    color: userData?.cv_color || "#0432A3",
  };
  const skillBackColor = {
    background: userData?.cv_skillColor || "#0432A3",
  };
  // const interestBackColor = {
  //   background: userData?.cv_interestColor || "#0432A3",
  // };

  return (
    <>
      <div
        className={
          userData?.membership?.id > 1 ? "fourth show" : "fourth hidden"
        }
        style={{ height: "550px" }}
      >
        <div
          ref={combinedRef}
          className={
            userData?.membership?.id > 1 ? "resume-4 show" : "resume-4 hidden"
          }
          style={{ height: memberShip == 1 && "550px" }}
        >
          <div className={memberShip > 1 ? "d-none" : "lock"}>
            <Link to="/membership">
              <div className="lock-img"></div>
              <p>Unlock More Features</p>
            </Link>
          </div>
          <div className="col-12">
            <div className="row">
              <div className="col-6 position-relative">
                <div className="blue" style={bg_color}>
                  <h2>
                    {userData?.first_name || "John"}{" "}
                    {userData?.last_name || "Smith"}
                  </h2>
                  <h3>{userData?.profile_headline || "Designation"}</h3>
                  <p>
                    {userData?.about ||
                      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book."}
                  </p>
                </div>

                <div className="profile-img">
                  <img
                    src={
                      userData?.profile_img ||
                      "http://upload.wikimedia.org/wikipedia/commons/thumb/b/b6/Image_created_with_a_mobile_phone.png/640px-Image_created_with_a_mobile_phone.png"
                    }
                    className="image-fit"
                  />
                </div>
              </div>
              <div className="col-6">
                <div className="list">
                  <ul>
                    <li>
                      <i className="message-icon"></i>
                      {userData?.email || "John@gmail.com"}
                    </li>
                    <li>
                      <i className="mobile-icon"></i>
                      {userData?.phone || "+97 55555 55555"}
                    </li>
                    <li>
                      <i className="loction-icon"></i>
                      {userData?.address || "Ajman, UAE"}
                    </li>
                    <li>
                      <i className="nobel-icon"></i>
                      {userData?.profile_url || "nobel.com/john"}
                    </li>
                    <li>
                      <i className="linkdin-icon"></i>
                      {userData?.linkedin_url || "linkedin.com/john"}
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-6">
                <div>
                  <div className="skills right">
                    <div className="heading">
                      <h3 style={headColor}>
                        <div className="work-icon"></div>Work Experience
                      </h3>
                    </div>
                  </div>
                  {userData?.experiences?.length > 0 ? (
                    userData?.experiences?.map((ele) => {
                      return (
                        <>
                          <div className="work">
                            <h3> {ele?.company_name}</h3>
                            <div className="row description">
                              <div className="col-6">
                                <p>
                                  {moment(ele?.start_date)
                                    ?.utc()
                                    ?.format("MMM YYYY")}
                                  {" to"}{" "}
                                  {ele?.is_current
                                    ? "Present"
                                    : ele?.end_date
                                    ? moment(ele?.end_date)
                                        ?.utc()
                                        ?.format("MMM YYYY")
                                    : "No Date"}
                                </p>
                              </div>
                              <div className="col-6">
                                <div className="float-end">
                                  <p>{ele?.location}</p>
                                </div>
                              </div>
                            </div>
                            <h5>Tasks and duties</h5>
                            <ul className="mb-3">
                              <li>{ele?.description}</li>
                            </ul>
                          </div>
                        </>
                      );
                    })
                  ) : (
                    <>
                      <div className="work">
                        <h3>Webmate Solutions</h3>
                        <div className="row description">
                          <div className="col-6">
                            <p>2019 to Present, California</p>
                          </div>
                          <div className="col-6">
                            <div className="float-end"></div>
                          </div>
                        </div>
                        <h5>Tasks and duties</h5>
                        <ul className="mb-3">
                          <li>
                            Lorem Ipsum is simply dummy text of the printing and
                            typesetting industry. Lorem Ipsum has been the
                            industry's standard dummy text ever since the 1500s,
                            when an unknown printer took a galley of type and
                            scrambled it to make a type specimen book.
                          </li>
                          <li>
                            Lorem Ipsum is simply dummy text of the printing and
                            typesetting industry.
                          </li>
                          <li>
                            Lorem Ipsum has been the industry's standard dummy
                            text ever since the 1500s, when an unknown printer
                            took a galley of type and scrambled it to make a
                            type specimen book.
                          </li>
                        </ul>
                      </div>
                      <div className="work mt-3">
                        <h3>Webmate Solutions</h3>
                        <div className="row description">
                          <div className="col-6">
                            <p>2019 to Present, California</p>
                          </div>
                          <div className="col-6">
                            <div className="float-end"></div>
                          </div>
                        </div>
                        <h5>Tasks and duties</h5>
                        <ul className="mb-3">
                          <li>
                            Lorem Ipsum is simply dummy text of the printing and
                            typesetting industry. Lorem Ipsum has been the
                            industry's standard dummy text ever since the 1500s,
                            when an unknown printer took a galley of type and
                            scrambled it to make a type specimen book.
                          </li>
                          <li>
                            Lorem Ipsum is simply dummy text of the printing and
                            typesetting industry.
                          </li>
                          <li>
                            Lorem Ipsum has been the industry's standard dummy
                            text ever since the 1500s, when an unknown printer
                            took a galley of type and scrambled it to make a
                            type specimen book.
                          </li>
                        </ul>
                      </div>
                    </>
                  )}
                </div>
                <div>
                  <div className="skills right">
                    <div className="heading">
                      <h3 style={headColor}>
                        <div className="icon"></div>Education
                      </h3>
                    </div>
                  </div>
                  {userData?.educations?.length > 0 ? (
                    userData?.educations?.map((ele) => {
                      return (
                        <>
                          <div className="work">
                            <h3> {ele?.degree}</h3>
                            <div className="row description">
                              <div className="col-6">
                                <p>{ele?.school_name}</p>
                              </div>
                              <div className="col-6">
                                <div className="float-end"></div>
                              </div>
                            </div>
                            <h5>
                              {moment(ele?.start_date)
                                ?.utc()
                                ?.format("MMM YYYY")}
                              {" to"}{" "}
                              {ele?.end_date
                                ? moment(ele?.end_date)
                                    ?.utc()
                                    ?.format("MMM YYYY")
                                : "No Date"}
                            </h5>
                          </div>
                        </>
                      );
                    })
                  ) : (
                    <>
                      <div className="work">
                        <h3>MBA</h3>
                        <div className="row description">
                          <div className="col-6">
                            <p>University of cambridge</p>
                          </div>
                          <div className="col-6">
                            <div className="float-end">
                              <p>California, CA, USA</p>
                            </div>
                          </div>
                        </div>
                        <h5>2019 to 2020</h5>
                      </div>
                    </>
                  )}
                </div>
              </div>
              <div className="col-6">
                <div className="bg">
                  <div className="skills">
                    <div className="heading">
                      <h3 style={headColor}>
                        <div className="icon"></div>Skills
                      </h3>
                    </div>
                    <ul>
                      {userData?.exps_and_skills?.length > 0 ? (
                        userData?.exps_and_skills?.map((ele) => {
                          return (
                            <li className="text-white" style={skillBackColor}>
                              {ele?.skills ? ele?.skills : ele}
                            </li>
                          );
                        })
                      ) : (
                        <>
                          <li>Photoshop</li>
                          <li>Illustrator</li>
                          <li>After effect</li>
                          <li>microsoft office</li>
                          <li>Adobe illustrator</li>
                          <li>Autocad</li>
                          <li>3D Character blending</li>
                          <li>Sketching</li>
                        </>
                      )}
                    </ul>
                  </div>

                  <div className="skills mt-20">
                    <div className="heading">
                      <h3 style={headColor}>
                        <div className="language-icon"></div>Languages
                      </h3>
                    </div>
                    <div className="pl">
                      {userData?.languages?.length > 0 ? (
                        userData?.languages?.map((ele) => {
                          return (
                            <div className="mt-2">
                              <h4>{ele?.name}</h4>
                              <p>{ele?.proficiency}</p>
                            </div>
                          );
                        })
                      ) : (
                        <>
                          <div className="mt-2">
                            <h4>English</h4>
                            <p>Native</p>
                          </div>
                          <div className="mt-2">
                            <h4>Arabic</h4>
                            <p>Elementary</p>
                          </div>
                        </>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CombinedTemplate;
