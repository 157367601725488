import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getAllPublicNewsletters } from "../../../store/directory/directoryAction";
import { useState } from "react";
import NewsletterDirectory from "./NewsletterDirectory";
import AppLoader from "../../../core/components/Loaders/appLoader/appLoader";

const NewsletterDirectoryContainer = () => {
  const dispatch = useDispatch();
  const [searchKey, setSearchKey] = useState("");
  const [currentCount, setCurrentCount] = useState(0);
  const [totalCount, setTotalCount] = useState(500);
  const [searchAlphabaticly, setSearchAlphabaticly] = useState(false);
  const { publicNewslettersList } = useSelector((state) => state.directory);
  const { isPageLoading } = useSelector((state) => state.loader);

  useEffect(() => {
    if (searchKey.length > 0) {
      SearchByText();
    } else if (searchAlphabaticly) {
      SearchAlphabaticly(searchAlphabaticly);
    } else SearchAllNewsletters();
  }, [currentCount]);

  const SearchByText = () => {
    dispatch(
      getAllPublicNewsletters({
        skip: 0,
        search: searchKey ? searchKey : "",
        limit: "300",
      })
    ).then((res) => setTotalCount(res.count));
  };

  const SearchAlphabaticly = (text) => {
    setSearchAlphabaticly(text);
    dispatch(
      getAllPublicNewsletters({ skip: 0, alpha_search: text, limit: "300" })
    ).then((res) => setTotalCount(res.count));
  };

  const SearchAllNewsletters = () => {
    dispatch(
      getAllPublicNewsletters({ skip: currentCount, limit: "500" })
    ).then((res) => setTotalCount(res.count));
  };

  useEffect(() => {
    if (!searchKey) {
      SearchAllNewsletters();
    }
  }, [searchKey]);

  const loadMoreData = () => {
    setCurrentCount(currentCount + 500);
  };

  const handleEnterSubmit = (e) => {
    if (e.key === "Enter") {
      SearchByText();
    }
  };
  if(isPageLoading)
  return <AppLoader/>
   else
  return (
    <NewsletterDirectory
      publicNewslettersList={publicNewslettersList}
      searchKey={searchKey}
      setSearchKey={setSearchKey}
      SearchByText={SearchByText}
      SearchAlphabaticly={SearchAlphabaticly}
      SearchAllNewsletters={SearchAllNewsletters}
      loadMoreData={loadMoreData}
      totalCount={totalCount}
      handleEnterSubmit={handleEnterSubmit}
    />
  );
};

export default NewsletterDirectoryContainer;
