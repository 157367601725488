import { forwardRef, useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "./datepickerStyles.css";

const ExampleCustomInput = forwardRef((props, ref) => (
  <input
    ref={ref}
    {...props}
  ></input>
));


export default function YearPickerCustom(props) {
   const {onChange, value, onClick, min, max, placeholder, onBlur} = props


  return (
    <DatePicker
      selected={value}
      onChange={onChange}
      className="example-custom-input"
      customInput={<ExampleCustomInput />}
      dayClassName={() => "example-datepicker-day-class"}
      popperClassName="example-datepicker-class"
      placeholderText={placeholder}
      onClick={onClick}
      showYearPicker
      dateFormat="yyyy"
      yearItemNumber={10}
      maxDate={max}
      onBlur={onBlur}
      //maxDate={new Date("02-29-2020")}
   //    isClearable
    />
  );
}
