import React, { useEffect, useState } from "react";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import BarChart from "../../../../core/components/Chatrs/BarChart";
import arrow from "../../../../assets/img/arrowUp.svg";
import arrowdown from "../../../../assets/img/Arrow_Down_Red.png";
const InsightsModal = (props) => {
  const {
    modal,
    toggle,
    title,
    insightData,
    getFollowersInsights,
    getReachedInsights,
    getEngageInsights,
    showText,
    isPositive,
  } = props;

  return (
    <div>
      <Modal
        isOpen={modal}
        size="lg"
        style={{ maxWidth: "600px", maxHeight: "411px" }}
        className="gradient-background"
        centered
      >
        <ModalHeader toggle={toggle}>{title}</ModalHeader>

        <div className="admin-popup">
          <div className="container">
            <div className="row">
              <div className="col-12 insight-body">
                <p className="fs-6 mt-3 mb-4 nav-tabs p-3">
                  Useful Page insights and statistics
                </p>
                <div className="newsletter  ">
                  <div className="main-center">
                    <div className="wrapper mt-30">
                      <ul
                        className="nav nav-tabs justify-content-evenly"
                        role="tablist"
                      >
                        <li
                          className="nav-item nav-insight-item"
                          style={{ padding: "0 28px 0 0px" }}
                        >
                          <a
                            className={
                              showText === "People Reached"
                                ? "nav-link active text-dark"
                                : "nav-link text-dark"
                            }
                            data-bs-toggle="tab"
                            href="#home"
                            onClick={() => {
                              getReachedInsights();
                            }}
                          >
                            People Reached
                          </a>
                        </li>
                        <li className="nav-item ">
                          <a
                            className={
                              showText === "People Engaged"
                                ? "nav-link active"
                                : "nav-link"
                            }
                            data-bs-toggle="tab"
                            href="#home"
                            onClick={() => {
                              getEngageInsights();
                            }}
                          >
                            People Engagements
                          </a>
                        </li>
                        <li className="nav-item">
                          <a
                            className={
                              showText === "Page Followers"
                                ? "nav-link active"
                                : "nav-link"
                            }
                            data-bs-toggle="tab"
                            href="#home"
                            onClick={() => {
                              getFollowersInsights();
                            }}
                          >
                            Page Followers
                          </a>
                        </li>
                      </ul>
                      <ModalBody>
                        <div className="tab-content mt-20 px-3">
                          <div id="home" className="container tab-pane active">
                            <div className="suggestion mt-30">
                              <div className="row mb-3">
                                <div className="col-12">
                                  <h4 className="mb-2 text-dark">
                                    {insightData?.totalCount} {showText}
                                  </h4>

                                  {isPositive == 1 ? (
                                    <p>
                                      <span className="growth">
                                        <img
                                          src={arrow}
                                          alt="arrowup"
                                          className="pb-1"
                                        />{" "}
                                        {insightData?.percentage}%{" "}
                                      </span>{" "}
                                      over last month{" "}
                                    </p>
                                  ) : (
                                    <p>
                                      <span className="decline">
                                        <img
                                          src={arrowdown}
                                          alt="arrowdown"
                                          className="pb-1"
                                        />{" "}
                                        {insightData?.percentage}%{" "}
                                      </span>
                                      over last month
                                    </p>
                                  )}

                                  {insightData && (
                                    <BarChart
                                      insightData={insightData?.insight}
                                    />
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                          {/* <div id="menu1" className="container tab-pane fade">
                            <h3>Menu 1</h3>
                            <p>
                              Ut enim ad minim veniam, quis nostrud exercitation
                              ullamco laboris nisi ut aliquip ex ea commodo
                              consequat.
                            </p>
                          </div>
                          <div id="menu2" className="container tab-pane fade">
                            <h3>Menu 2</h3>
                            <p>
                              Sed ut perspiciatis unde omnis iste natus error
                              sit voluptatem accusantium doloremque laudantium,
                              totam rem aperiam.
                            </p>
                          </div> */}
                        </div>
                      </ModalBody>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default InsightsModal;
