import React from 'react';
import "../../network.scss"
import moment from 'moment';
import { Modal, ModalHeader } from "reactstrap";

export default function EventPreview(props) {

    const {
        modal, 
        toggle, 
        title,
        eventData,
        bannerImage
    } = props;

    return (
        
        <div>
        <Modal isOpen={modal} size="lg" style={{ maxWidth: "920px" }}   className="networks-popup">
         
        <div className="create-event">
              <ModalHeader toggle={toggle}>
                {title}
              </ModalHeader>
              <div className="main-center event-details">
            <div className="wrapper">
              <div className="box-img" style={{margin:"20px"}}>
                <div className="img">
                  {bannerImage ?
                    <img
                              className="img_event_detail"
                              src={bannerImage}
                              alt=""
                              style={{borderRadius:"5px"}}
                            /> : <></> }
                </div>
                <div className="calendar">
                  <div className="day">
                    {eventData?.event_date ? moment(eventData?.event_date).format("dddd") : moment().format("dddd")}
                  </div>
                  <div className="date">
                    {eventData?.event_date ? moment(eventData?.event_date).format("ll") : moment().format("ll")}{" "}
                  </div>
                </div>
              </div>
            </div>

            <div className="wrapper p-20">
              <div className="section ">
                <div className="heading">
                  <div className="row">
                    <div className="col-lg-6 mb-3">
                      <h2>{eventData?.title}</h2>
                    </div>
                    <div className="col-lg-6 mb-3">
                      <div className="float-lg-end">
                        <div className="d-flex gap-3">
                          <div className="bell-icon"></div>
                          <div className="share-icon"></div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="virtual">
                  <div className="row">
                    <div className="col-lg-12">
                      <div className="btn">{eventData?.event_type==="virtual"? "Virtual" : "Physical"}</div>
                      {eventData?.event_link ? (
                        <div className="link">
                          {eventData?.event_link}
                          <span className="copy"></span>
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                </div>
                <div className="content">
                  <div className="row mt-20">
                    <div className="col-lg-12">
                      <div className="content">
                        <p>{eventData?.description}</p>
                      </div>
                      <div className="technology mt-20 mb-20">
                        <ul>
                          <li>
                            <span className="task"></span>
                            {eventData?.industry}
                          </li>
                          <li>
                            <span className="calender"></span>
                            {eventData?.event_date && eventData.event_time && <>
                            {moment(eventData?.event_date).format(
                              "ddd, MMM DD, YYYY"
                            )}{" "}
                            at{" "}
                            { moment(eventData.event_time, "HH:mm:ss").format("hh:mm A")}
                            </>}
                          </li>
                          <li>
                            <span className="location"></span>
                            {eventData?.location}
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row events-add">
                  <div className="col-lg-6">
                    <div className="people-pic">
                      <div className="d-flex me-2">
                        <div className="profiles-img">
                          {/* {event_detail[0]?.Event_Participant?.map(
                            (data, idx) => {
                              return (
                                <span key={idx}>
                                  <img
                                    src="https://img.freepik.com/free-photo/pleasant-looking-serious-man-stands-profile-has-confident-expression-wears-casual-white-t-shirt_273609-16959.jpg"
                                    className="image-fit"
                                    alt=""
                                  />
                                </span>
                              );
                            }
                          )} */}
{/*                     
                          {event_detail[0]?.Event_Participant?.length > 5 ? (
                            <span>
                              +
                              {Object.keys(event_detail[0]?.Event_Participant)
                                .length - 5}
                            </span>
                          ) : (
                            <></>
                          )} */}
                        </div>
                      </div>

                      {/* <div>
                        {" "}
                        {event_detail[0]?.Event_Participant?.length > 0 ? (
                          "are attending"
                        ) : (
                          <></>
                        )}
                      </div> */}
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="float-xl-end">
                    <div
                        className="btn border-btn"
                        onClick={() => toggle()}
                      >
                        Go Back to Edit
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row">
                  {/* <div className="col-lg-12">
                    <h4 className="related">Related events</h4>
                    <div className="networks">
                      <div className="wrapper">
                        <div className="events">
                          <div className="row mt-20">
                            <div className="col-lg-4">
                              <div className="card">
                                <div className="img-section">
                                  <div className="card-img">
                                    <img
                                      src="https://img.freepik.com/free-photo/pleasant-looking-serious-man-stands-profile-has-confident-expression-wears-casual-white-t-shirt_273609-16959.jpg"
                                      className="img-fluid"
                                      alt=""
                                    />
                                  </div>
                                  <div className="tag">Physical</div>
                                  <div className="pin"></div>
                                </div>
                                <div className="mr-20 ml-20 ">
                                  <div className="header mt-30">
                                    <h4>Bone thugs-n-harmony</h4>
                                  </div>
                                  <div className="date">
                                    <div className="calender-icon"></div> Mon,
                                    Sep 25, 2020 at 9:30 AM
                                  </div>
                                  <div className="location">
                                    <div className="icon"></div>London
                                  </div>
                                  <div className="people-pic">
                                    <div className="d-flex">
                                      <span>
                                        <img
                                          src="https://img.freepik.com/free-photo/pleasant-looking-serious-man-stands-profile-has-confident-expression-wears-casual-white-t-shirt_273609-16959.jpg"
                                          className="image-fit"
                                          alt=""
                                        />
                                      </span>

                                      <div className="profiles-img">
                                        <span>
                                          <img
                                            src="https://img.freepik.com/free-photo/pleasant-looking-serious-man-stands-profile-has-confident-expression-wears-casual-white-t-shirt_273609-16959.jpg"
                                            className="image-fit"
                                            alt=""
                                          />
                                        </span>
                                        <span>
                                          <img
                                            src="https://img.freepik.com/free-photo/pleasant-looking-serious-man-stands-profile-has-confident-expression-wears-casual-white-t-shirt_273609-16959.jpg"
                                            className="image-fit"
                                            alt=""
                                          />
                                        </span>
                                        <span>
                                          <img
                                            src="https://img.freepik.com/free-photo/pleasant-looking-serious-man-stands-profile-has-confident-expression-wears-casual-white-t-shirt_273609-16959.jpg"
                                            className="image-fit"
                                            alt=""
                                          />
                                        </span>
                                        <span>+40</span>
                                      </div>
                                    </div>

                                    <div>are attending</div>
                                  </div>
                                  <div className="d-flex">
                                    <div className="btn btn-light text-blue">
                                      Attend
                                    </div>
                                    <div className="share-icon"></div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="col-lg-4">
                              <div className="card">
                                <div className="img-section">
                                  <div className="card-img">
                                    <img
                                      src="https://img.freepik.com/free-photo/pleasant-looking-serious-man-stands-profile-has-confident-expression-wears-casual-white-t-shirt_273609-16959.jpg"
                                      className="img-fluid"
                                      alt=""
                                    />
                                  </div>
                                  <div className="tag">Physical</div>
                                  <div className="pin"></div>
                                </div>
                                <div className="mr-20 ml-20 ">
                                  <div className="header mt-30">
                                    <h4>Bone thugs-n-harmony</h4>
                                  </div>
                                  <div className="date">
                                    <div className="calender-icon"></div> Mon,
                                    Sep 25, 2020 at 9:30 AM
                                  </div>
                                  <div className="location">
                                    <div className="icon"></div>London
                                  </div>
                                  <div className="people-pic">
                                    <div className="d-flex">
                                      <span>
                                        <img
                                          src="https://img.freepik.com/free-photo/pleasant-looking-serious-man-stands-profile-has-confident-expression-wears-casual-white-t-shirt_273609-16959.jpg"
                                          className="image-fit"
                                          alt=""
                                        />
                                      </span>

                                      <div className="profiles-img">
                                        <span>
                                          <img
                                            src="https://img.freepik.com/free-photo/pleasant-looking-serious-man-stands-profile-has-confident-expression-wears-casual-white-t-shirt_273609-16959.jpg"
                                            className="image-fit"
                                            alt=""
                                          />
                                        </span>
                                        <span>
                                          <img
                                            src="https://img.freepik.com/free-photo/pleasant-looking-serious-man-stands-profile-has-confident-expression-wears-casual-white-t-shirt_273609-16959.jpg"
                                            className="image-fit"
                                            alt=""
                                          />
                                        </span>
                                        <span>
                                          <img
                                            src="https://img.freepik.com/free-photo/pleasant-looking-serious-man-stands-profile-has-confident-expression-wears-casual-white-t-shirt_273609-16959.jpg"
                                            className="image-fit"
                                            alt=""
                                          />
                                        </span>
                                        <span>+40</span>
                                      </div>
                                    </div>

                                    <div>are attending</div>
                                  </div>
                                  <div className="d-flex">
                                    <div className="btn btn-light text-blue">
                                      Attend
                                    </div>
                                    <div className="share"></div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="col-lg-4">
                              <div className="card">
                                <div className="img-section">
                                  <div className="card-img">
                                    <img
                                      src="https://img.freepik.com/free-photo/pleasant-looking-serious-man-stands-profile-has-confident-expression-wears-casual-white-t-shirt_273609-16959.jpg"
                                      className="img-fluid"
                                      alt=""
                                    />
                                  </div>
                                  <div className="tag">Physical</div>
                                  <div className="pin"></div>
                                </div>
                                <div className="mr-20 ml-20 ">
                                  <div className="header mt-30">
                                    <h4>Bone thugs-n-harmony</h4>
                                  </div>
                                  <div className="date">
                                    <div className="calender-icon"></div> Mon,
                                    Sep 25, 2020 at 9:30 AM
                                  </div>
                                  <div className="location">
                                    <div className="icon"></div>London
                                  </div>
                                  <div className="people-pic">
                                    <div className="d-flex">
                                      <span>
                                        <img
                                          src="https://img.freepik.com/free-photo/pleasant-looking-serious-man-stands-profile-has-confident-expression-wears-casual-white-t-shirt_273609-16959.jpg"
                                          className="image-fit"
                                          alt=""
                                        />
                                      </span>

                                      <div className="profiles-img">
                                        <span>
                                          <img
                                            src="https://img.freepik.com/free-photo/pleasant-looking-serious-man-stands-profile-has-confident-expression-wears-casual-white-t-shirt_273609-16959.jpg"
                                            className="image-fit"
                                            alt=""
                                          />
                                        </span>
                                        <span>
                                          <img
                                            src="https://img.freepik.com/free-photo/pleasant-looking-serious-man-stands-profile-has-confident-expression-wears-casual-white-t-shirt_273609-16959.jpg"
                                            className="image-fit"
                                            alt=""
                                          />
                                        </span>
                                        <span>
                                          <img
                                            src="https://img.freepik.com/free-photo/pleasant-looking-serious-man-stands-profile-has-confident-expression-wears-casual-white-t-shirt_273609-16959.jpg"
                                            className="image-fit"
                                            alt=""
                                          />
                                        </span>
                                        <span>+40</span>
                                      </div>
                                    </div>

                                    <div>are attending</div>
                                  </div>
                                  <div className="d-flex">
                                    <div className="btn btn-light text-blue">
                                      Attend
                                    </div>
                                    <div className="share-icon"></div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div> */}
                </div>
              </div>
            </div>
          </div>
                </div>
          
          </Modal>
    </div>
      
    )

}