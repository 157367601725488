import React, { useEffect } from "react";
import Navbar from "../../pages/navbar/navbar";
import "./Jobs.scss";
import AdIcon from "../../assets/img/ads.svg";
import caseIcon from "../../assets/img/briefcase2.svg";
import editIcon from "../../assets/img/edit-icon.svg";
import threeDots from "../../assets/img/three_dots.svg";
import walletIcon from "../../assets/img/wallet-job.svg";
import locationIcon from "../../assets/img/location-job.svg";
import expIcon from "../../assets/img/exp-job.svg";
import noblePage from "../../assets/img/noble-job.jpg";
import placeholderUser from "../../assets/img/placeholder_user.jpg";
import connectIcon from "../../assets/img/connectIcon.svg";
import JobCard from "./components/JobCard/JobCard";
import pagePlaceholder from "../../assets/img/page-placeholder.svg";
import saveIcons from "../../assets/img/saveJob.svg";
import { useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import RecruiterModals from "./Recruiter/RecruiterModals/RecruiterModals";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  CreateJobAlert,
  DeleteJobAlert,
  addJobFeedback,
  applyForJob,
  createSearchHistory,
  getJobs,
  getJobsDetail,
  jobViewlog,
  saveJob,
  updateJobForm,
} from "../../store/jobs/jobAction";
import moment from "moment";
import AppLoader from "../../core/components/Loaders/appLoader/appLoader";
import buld from "../../assets/img/circle-building.svg";
import InfiniteScroll from "react-infinite-scroll-component";
import JobModal from "../JobSearch/JobModal";
import DOMPurify from "dompurify";
import Autocomplete from "react-autocomplete";
import {
  getLocationsData,
  sendConnectionRequest,
} from "../../store/connections/connectionsActions";
import { FormGroup, Input } from "reactstrap";
import { getAllCompanyList } from "../../store/profile/profileActions";
import RecentJobSearches from "./components/RecentJobSearches/RecentJobSearches";
import { GetTime, createMarkup } from "../../core/components/Utility/Utility";
import Chat from "../../pages/chat/chat";
import unfollow from "../../assets/img/unfoll.png";
import add from "../../assets/img/add-icon.svg";
import { followPage, unFollowPage } from "../../store/pages/pagesAction";
import NpMailModal from "../../core/components/modal/NpMailModal";
import confidential from "../../assets/img/confidentialIcon.svg";
import { campaignAPI } from "../../api/campaignAPI";
import ApplyLinkIcon from "../../assets/img/apply_link.svg";
const dropdownOptions = {
  experience: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15],
  dates: ["all", "month", "week", "hour"],
  employmentType: [
    "Full-time",
    "Part-time",
    "Contract",
    "Temporary",
    "Other",
    "Volunteer",
    "Internship",
  ],
  workSpace: ["On-site", "Hybrid", "Remote"],
  onlyNational: ["Yes", "No"],
};

export default function Jobs(props) {
  // const { navigate } = props;
  const location = useLocation();
  const isPost = location.state?.isPost;
  const navigate = useNavigate();
  const { getAllJobs, getFeaturedJobs } = useSelector((state) => state.jobs);
  const [showRecruiterModal, setShowRecruiterModal] = useState(false);
  const { isLoading } = useSelector((state) => state.loader);
  const { user } = useSelector((state) => state.auth);
  const [currentCount, setCurrectCount] = useState(0);
  const [totalCount, setTotalCount] = useState(0);
  const [locationOpen, setLocationOpen] = useState(false);
  const [searchFieldValue, setSearchFieldValue] = useState(null);
  const [locationsAPIData, setLocationsAPIData] = useState([]);
  const [searchKey, setSearchKey] = useState("");
  const [datePosted, setDatePosted] = useState(null);
  const [experienceValue, setExperienceValue] = useState(null);
  const [employmentValue, setEmploymentValue] = useState(null);
  const [workSpaceValue, setWorkSpaceValue] = useState(null);
  const [salaryEstimation, setSalaryEstimation] = useState(null);
  const [smartApply, setSmartApply] = useState(false);

  const [isJobAlert, setIsJobAlert] = useState();
  const [locationDetails, setLocationDetails] = useState({
    location: searchFieldValue,
  });
  const [searchValue, setSearchValue] = useState("");
  const [memberInput, setMemberInput] = useState({});
  const [companyList, setCompanyList] = useState([]);
  const [listImage, setListImage] = useState("");
  const [hasMore, setHasMore] = useState(true);
  // const [educationRequirements, setEducationRequirements] = useState("");
  // const [keySkills, setKeySkills] = useState("");
  const [JobActive, setJobActive] = useState(false);
  const [connectText, setConnectText] = useState("Connect");
  const [selectedJob, setSelectedJob] = useState();
  const [npMailModal, setNpMailModal] = useState(false);

  const dispatch = useDispatch();
  const { state } = useLocation();

  useEffect(() => {
    if (isPost) {
      setShowRecruiterModal(true);
    }
  }, [isPost]);
  const menuStyle = {
    fontSize: "14px",
    fontWeight: 500,
  };
  const inputProps = {
    placeholder: "Location",
    style: {
      fontSize: "14px",
      fontWeight: 500,
    },
  };

  useEffect(() => {
    // dispatch(getJobs({ limit: 10, skip: currentCount }, currentCount)).then(
    //   (res) => {
    //     setTotalCount(res?.totalItem);
    //     setIsJobAlert(res?.jobAlert);
    //   }
    // );

    if (state?.isOpenCreatePost) {
      openRecruiterModal();
    }
    if (state?.isFeatured) {
      getFilteredJobs();
    } else {
      setJobType("");
      dispatch(
        getJobs(
          { limit: 10, skip: currentCount, filter: "recommended" },
          currentCount
        )
      ).then((res) => {
        setTotalCount(res?.totalItem);
        setIsJobAlert(res?.jobAlert);
      });
    }
  }, []);
  useEffect(() => {
    if (memberInput) {
      dispatch(getAllCompanyList({ search: memberInput.val }))
        .then((res) => {
          if (res?.success === true) {
            setCompanyList(res?.data);
          } else setCompanyList([]);
        })
        .catch(() => {
          setCompanyList([]);
        });
    }
  }, [memberInput]);

  const {
    title,
    company,
    // listImage,
    employmentType,
    companyLocation,
    companyVisible,
    companyId,
    numberOfVacancies,
    workspacetype,
  } = location.state || {};

  const [jobType, setJobType] = useState("");
  const getFilteredJobs = () => {
    setJobType("featured");
    setCurrectCount(0);
    dispatch(
      getJobs(
        { limit: 30, skip: currentCount, filter: "featured" },
        currentCount,
        "featured"
      )
    ).then((res) => {
      // setCardData(res?.data);
      setTotalCount(res?.totalItem);
      setIsJobAlert(res?.jobAlert);
    });
  };

  useEffect(() => {
    if (getAllJobs?.length > 0)
      // setSelectedJob(cardData[0]);
      dispatch(getJobsDetail(getAllJobs[0]?.id)).then((res) => {
        try {
          dispatch(jobViewlog(getAllJobs[0]?.id));
        } catch (error) {
          console.log(error);
        }
        setSelectedJob(res?.data);
      });
  }, [getAllJobs]);
  useEffect(() => {
    if (getAllJobs?.length < totalCount) setHasMore(true);
    else setHasMore(false);
  }, [currentCount, totalCount]);
  useEffect(() => {
    if (searchKey) {
      setJobType("");
      setCurrectCount(0);
      dispatch(
        getJobs(
          {
            limit: 10,
            skip: 0,
            search: searchKey,
            location: searchFieldValue,
            date_posted: datePosted,
            company: memberInput?.val ? memberInput?.val : null,
            experience: experienceValue,
            employment_type: employmentValue,
            workspace_type: workSpaceValue,
            salary: salaryEstimation,
            smart_apply: smartApply,
          },
          currentCount
        )
      ).then((res) => {
        setTotalCount(res?.totalItem);
        setIsJobAlert(res?.jobAlert);
      });
    } else {
      setCurrectCount(0);
      if (!state?.isFeatured) {
        setJobType("");
        dispatch(
          getJobs({ limit: 10, skip: 0, filter: "recommended" }, currentCount)
        ).then((res) => {
          setTotalCount(res?.totalItem);
          setIsJobAlert(res?.jobAlert);
        });
      } else getFilteredJobs();
    }
  }, [
    searchKey,
    searchFieldValue,
    memberInput,
    datePosted,
    experienceValue,
    employmentValue,
    workSpaceValue,
    salaryEstimation,
    smartApply,
  ]);

  const handleJobCardClick = (jobData) => {
    dispatch(getJobsDetail(jobData?.id)).then((res) => {
      try {
        dispatch(jobViewlog(jobData?.id));
      } catch (error) {
        console.log(error);
      }
      setSelectedJob(res?.data);
      window.scrollTo(0, 0);
    });
  };

  const openRecruiterModal = () => {
    setShowRecruiterModal(!showRecruiterModal);
    dispatch(updateJobForm({ jobType: "new" }));
  };

  const JobCheck = () => {
    setJobActive(!JobActive);
  };
  const loadMore = () => {
    const newSkip = currentCount + 10;
    const skipforsearch = currentCount + 100;

    if (searchKey) {
      dispatch(
        getJobs({ limit: 100, skip: skipforsearch, search: searchKey }, newSkip)
      ).then((res) => {
        setTotalCount(res.totalItem);
        setCurrectCount(newSkip);
        setIsJobAlert(res?.jobAlert);
      });
    } else
      dispatch(getJobs({ limit: 10, skip: newSkip }, newSkip)).then((res) => {
        setTotalCount(res.totalItem);
        setCurrectCount(newSkip);
        setIsJobAlert(res?.jobAlert);
      });
  };

  const handleSave = (id) => {
    setSelectedJob({
      ...selectedJob,
      savedJob: {
        ...selectedJob?.savedJob,
        status: !selectedJob?.savedJob?.status,
      },
    });
    const res = dispatch(saveJob(id));
    if (res.success) {
      toast.success(res.message, {
        position: toast.POSITION.BOTTOM_LEFT,
      });
    }
  };
  const handleFollow = (pageID, is_following) => {
    setSelectedJob({
      ...selectedJob,
      company_is_following: !selectedJob?.company_is_following,
    });
    if (is_following) {
      dispatch(unFollowPage(pageID));
    } else {
      dispatch(followPage(pageID));
    }
  };

  const getLocations = ({ value }) => {
    if (value)
      dispatch(getLocationsData(value))
        .then((response) => {
          if (response.data) {
            setLocationsAPIData(response.data);
          } else setLocationsAPIData([]);
        })
        .catch((err) => {
          setLocationsAPIData([]);
        });
    else setLocationsAPIData([]);
  };

  // const applyForTheJob = (jobId) => {
  //   dispatch(applyForJob(jobId))
  //     .then((res) => {
  //       if (res.success) {
  //         toast.success(res.message, {
  //           position: toast.POSITION.BOTTOM_LEFT,
  //         });
  //         setSelectedJob({
  //           ...selectedJob,
  //           appliedJob: true,
  //         });
  //         if (selectedJob?.apply_link) {
  //           const link = document.createElement("a");
  //           link.href = selectedJob?.apply_link;
  //           link.target = "_blank";
  //           link.rel = "noopener noreferrer";

  //           document.body.appendChild(link);

  //           link.click();

  //           document.body.removeChild(link);
  //         }
  //       }
  //     })
  //     .catch((error) => {
  //       toast.error(error?.response?.data?.message, {
  //         position: toast.POSITION.BOTTOM_LEFT,
  //       });
  //     });
  // };

  const applyForTheJob = (jobId) => {
    dispatch(applyForJob(jobId))
      .then((res) => {
        if (res.success) {
          toast.success(res.message, {
            position: toast.POSITION.BOTTOM_LEFT,
          });
          setSelectedJob({
            ...selectedJob,
            appliedJob: true,
          });
          if (selectedJob?.apply_link) {
            const fullURL =
              selectedJob?.apply_link.startsWith("http://") ||
              selectedJob?.apply_link.startsWith("https://")
                ? selectedJob?.apply_link
                : `http://${selectedJob?.apply_link}`;
            window.open(fullURL, "_blank");
          }
        }
      })
      .catch((error) => {
        toast.error(error?.response?.data?.message, {
          position: toast.POSITION.BOTTOM_LEFT,
        });
      });
  };

  const handleExperienceChange = (value) => {
    setCurrectCount(0);
    setExperienceValue(value);
  };

  const handleCompanySearch = (val) => {
    setCurrectCount(0);
    setSearchValue(val.val);
  };
  const handleDatePosted = (value) => {
    setCurrectCount(0);
    setDatePosted(value);
  };
  const handleJobType = (value) => {
    setCurrectCount(0);
    setEmploymentValue(value);
  };
  const handleWorkSpace = (value) => {
    setCurrectCount(0);
    setWorkSpaceValue(value);
  };

  const handleSalaryType = (value) => {
    setCurrectCount(0);
    setSalaryEstimation(value);
  };
  const handleSmartApply = (e) => {
    if (e.target.checked) {
      setCurrectCount(0);
      setSmartApply(true);
    } else {
      setCurrectCount(0);
      setSmartApply(false);
    }
  };

  const sendRequest = (id) => {
    dispatch(sendConnectionRequest(id)).then((res) => {
      setConnectText("Pending");
      toast.success(res.message, {
        position: toast.POSITION.BOTTOM_LEFT,
      });
    });
  };

  const handleSwitchChange = (e) => {
    const data = {
      search: searchKey ? searchKey : null,
      workspace_type: workSpaceValue ? workSpaceValue : null,
      employment_type: employmentValue ? employmentValue : null,
      experience: experienceValue ? experienceValue : null,
      company: memberInput ? memberInput?.value : null,
      date_posted: datePosted ? datePosted : null,
      location: searchFieldValue ? searchFieldValue : null,
    };
    if (e.target.checked) {
      dispatch(CreateJobAlert(data)).then((res) => {
        if (res?.success) {
          setIsJobAlert({ id: res?.data?.id });
          toast.success(res.message, {
            position: toast.POSITION.BOTTOM_LEFT,
          });
        }
      });
    } else {
      dispatch(DeleteJobAlert(isJobAlert?.id)).then((res) => {
        if (res?.success) {
          toast.success(res.message, {
            position: toast.POSITION.BOTTOM_LEFT,
          });
          setIsJobAlert(false);
        }
      });
    }
  };

  const handleSearchKey = (search) => {
    if (search)
      dispatch(createSearchHistory({ search, type: "job", type_id: null }));
    setSearchKey(search);
  };

  const handleFeedback = async (answer, id) => {
    dispatch(addJobFeedback(answer, id)).then((res) => {
      if (res?.success) {
        setSelectedJob({
          ...selectedJob,
          Feedback: {
            type: "job",
            type_id: selectedJob?.id,
          },
        });
        toast.success(res.message, {
          position: toast.POSITION.BOTTOM_LEFT,
        });
      }
    });
  };

  return (
    <div className="jobs">
      <Navbar isJobs={true} setSearchKey={(value) => handleSearchKey(value)} />
      <div className="container mt-4">
        {isLoading && <AppLoader />}
        <div className="row" style={{ marginRight: "0px" }}>
          {searchKey && (
            <div className="bg-white job-search-bar col-lg-12">
              <div className="filter-option">
                <ul className="row p-0 ">
                  <li className="col-md-2 autocomplete-wrapper pe-0">
                    <div className="search ">
                      <Autocomplete
                        open={locationOpen}
                        inputProps={inputProps}
                        value={searchFieldValue}
                        items={locationsAPIData}
                        getItemValue={(item) => item.name}
                        shouldItemRender={() => locationsAPIData}
                        renderMenu={(items, value) => (
                          <div className="dropdown dropdown-location">
                            {items.length === 0
                              ? `No matches for ${value}`
                              : items}
                          </div>
                        )}
                        renderItem={(item, isHighlighted) => (
                          <div
                            className={`item ${
                              isHighlighted ? "selected-item" : ""
                            }`}
                          >
                            {item.name}
                          </div>
                        )}
                        onChange={(e, val) => {
                          if (val.length === 0) {
                            if (locationOpen) setLocationOpen(false);
                          } else {
                            if (!locationOpen) setLocationOpen(true);
                          }
                          setSearchFieldValue(e.target.value);
                          getLocations(e.target);
                        }}
                        onSelect={(value, obj) => {
                          setSearchFieldValue(value);
                          setLocationDetails({
                            ...locationDetails,
                            location: obj.name,
                          });
                          setLocationOpen(false);
                        }}
                        wrapperStyle={{}}
                        menuStyle={menuStyle}
                      />
                    </div>
                  </li>
                  <li className="col-md-1 px-0">
                    <select
                      className="form-select"
                      style={{
                        width: "123px",
                        padding: "0.375rem 1.05rem 0.375rem 0.75rem",
                      }}
                      placeholder="Date posted"
                      onChange={(e) => handleDatePosted(e.target.value)}
                    >
                      <option default value="">
                        Date posted
                      </option>
                      {dropdownOptions?.dates?.map((option, index) => (
                        <option key={index} value={option}>
                          {option?.charAt(0).toUpperCase() + option.slice(1)}
                        </option>
                      ))}
                    </select>
                  </li>
                  <li className="col-md-2 autocomplete-wrapper current-company">
                    <Autocomplete
                      value={memberInput.val}
                      items={companyList}
                      getItemValue={(item) => item.name}
                      renderMenu={(items, value) =>
                        items.length === 0 ? (
                          <></>
                        ) : (
                          <div className="dropdown">{items} </div>
                        )
                      }
                      renderItem={(item, isHighlighted) => (
                        <div
                          className={`item ${
                            isHighlighted ? "selected-item" : ""
                          }`}
                          style={{ display: "flex" }}
                        >
                          <img
                            src={item.icon ? item.icon : buld}
                            alt="dropdown_pic"
                            className="image-fit mr-10"
                            style={{ height: "36px", width: "36px" }}
                          />
                          <div>
                            <div className="name-heading-drop ">
                              {item.name}
                            </div>
                          </div>
                        </div>
                      )}
                      onChange={(event, val) => {
                        setMemberInput({ val });
                      }}
                      onSelect={(val, obj) => {
                        console.log(val, obj);
                        setMemberInput({ val });
                        // setSearchValue({ val })
                        handleCompanySearch({ val });
                        setListImage(obj.icon);
                        // setCompanyId(obj.id);
                        // setExpData({
                        //   company_name: val,
                        //   page_id: obj.id,
                        // });
                      }}
                      wrapperStyle={{}}
                      renderInput={(params) => {
                        return (
                          <div class="fake-input">
                            <input
                              {...params}
                              type="text"
                              placeholder="Company"
                            />
                            {/* <img
                              src={companyId ? listImage : buld}
                              alt=""
                              style={{ height: "36px", width: "36px" }}
                            /> */}
                          </div>
                        );
                      }}
                    />
                  </li>
                  <li className="col-md-1 px-0">
                    <select
                      className="form-select"
                      style={{ width: "123px" }}
                      onChange={(e) => handleExperienceChange(e.target.value)}
                    >
                      <option defaultdisabled value="0">
                        Experience
                      </option>
                      {dropdownOptions?.experience?.map((option, index) => (
                        <option key={index} value={option}>
                          {option === 0 ? "Freshers" : option}{" "}
                          {option === 0 ? "" : option > 1 ? " Years" : " Year"}
                        </option>
                      ))}
                    </select>
                  </li>

                  <li className="col-md-2 px-0">
                    <select
                      className="form-select"
                      style={{
                        width: "155px",
                        paddingLeft: "0px",
                        paddingRight: "0px",
                      }}
                      onChange={(e) => handleJobType(e.target.value)}
                    >
                      <option default value="">
                        Employment Type
                      </option>
                      {dropdownOptions.employmentType.map((option, index) => (
                        <option key={index} value={option}>
                          {option}
                        </option>
                      ))}
                    </select>
                  </li>
                  <li className="col-md-2 px-0">
                    <select
                      className="form-select"
                      style={{
                        width: "155px",
                        paddingLeft: "0px",
                        paddingRight: "0px",
                      }}
                      onChange={(e) => handleWorkSpace(e.target.value)}
                    >
                      <option default value="ascending">
                        Workspace Type
                      </option>
                      {dropdownOptions.workSpace.map((option, index) => (
                        <option key={index} value={option}>
                          {option}
                        </option>
                      ))}
                    </select>
                  </li>

                  <li className="col-md-1 ps-0">
                    <Input
                      type="number"
                      placeholder="Salary Estimation"
                      onChange={(e) => handleSalaryType(e.target.value)}
                      style={{
                        WebkitAppearance: "none",
                        MozAppearance: "textfield",
                      }}
                    />
                  </li>
                  <li className="smart-apply">
                    {/* <Input
                      type="checkbox"
                      placeholder="Smart"
                      onChange={(e) => handleSalaryType(e.target.value)}
                      style={{ WebkitAppearance: 'none', MozAppearance: 'textfield' }}
                    /> */}
                    <div className="d-flex checkbox">
                      <div className="form-check">
                        <input
                          type="checkbox"
                          className="form-check-input"
                          id="exampleCheck1"
                          onChange={(e) => handleSmartApply(e)}
                          // onClick={() => setRememberMe(!rememberMe)}
                        />
                        <label
                          className="form-check-label"
                          htmlFor="exampleCheck1"
                        >
                          Smart Apply
                        </label>
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          )}
        </div>
        <div className="row">
          <div className="left-sidebar bg-white col-lg-5">
            <div className=" leftTop-sidebar mb-20" style={{ padding: "0px" }}>
              <div
                className="d-flex justify-content-between top-buttons"
                style={{
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  padding: "0px 30px",
                  borderBottom: 0,
                }}
              >
                {user?.data?.membership?.name == "Starter" ? (
                  <></>
                ) : (
                  <div className="d-flex justify-content-betweens">
                    <img src={editIcon} alt="my_jobs" />{" "}
                    <span
                      className="sub-text ml-10"
                      // onClick={() => navigate("/post-job")}
                      onClick={() => openRecruiterModal()}
                    >
                      Post a Job
                    </span>
                  </div>
                )}
                <div
                  className="d-flex justify-content-betweens"
                  onClick={() => navigate("/myjobs")}
                >
                  <img src={caseIcon} alt="my_jobs" />{" "}
                  <span className="sub-text ml-10">My Jobs</span>
                </div>
                <div
                  className="d-flex justify-content-betweens"
                  onClick={() => getFilteredJobs()}
                >
                  <img src={AdIcon} alt="featured_jobs" />
                  <span
                    className="sub-text ml-10"
                    style={{ color: jobType == "featured" ? "black" : "unset" }}
                  >
                    Featured Jobs
                  </span>
                </div>
                <div className="d-flex justify-content-betweens">
                  <img
                    src={threeDots}
                    alt="my_jobs"
                    onClick={() => alert("Required Figma")}
                    style={{ height: "15px", marginTop: "3px" }}
                  />{" "}
                </div>
              </div>
              {searchKey && (
                <div
                  className="job-alert px-4 py-2"
                  style={isJobAlert ? {} : { background: "#0432A3" }}
                >
                  <div>
                    <p
                      className={
                        isJobAlert
                          ? "search-value text-secondary"
                          : "search-value text-white"
                      }
                    >
                      {searchKey?.charAt(0).toUpperCase() + searchKey?.slice(1)}
                      {searchFieldValue && ` in ${searchFieldValue}`}
                    </p>

                    <p
                      className={
                        isJobAlert
                          ? "search-num text-secondary pb-0"
                          : "search-num text-white pb-0"
                      }
                    >
                      {totalCount} search result
                    </p>
                  </div>
                  <div className="d-flex gap-3 align-items-center">
                    <p
                      className={
                        isJobAlert
                          ? "search-num text-secondary"
                          : "search-num text-white"
                      }
                    >
                      {isJobAlert ? "Alert on" : "Set alert"}
                    </p>
                    <FormGroup switch>
                      <Input
                        type="switch"
                        role="switch"
                        checked={isJobAlert ? true : false}
                        onChange={(e) => handleSwitchChange(e)}
                      />
                    </FormGroup>
                  </div>
                </div>
              )}
              {!searchKey && !jobType && (
                <>
                  <RecentJobSearches />
                </>
              )}
            </div>
            {!searchKey && !jobType && (
              <span className="job-recommend">Recommended for you</span>
            )}
            <InfiniteScroll
              dataLength={
                jobType == "featured"
                  ? getFeaturedJobs?.length
                  : getAllJobs?.length
              }
              next={() => loadMore()}
              hasMore={hasMore}
              style={{ overflow: "hidden" }}
            >
              {(
                jobType === "featured"
                  ? getFeaturedJobs?.length
                  : getAllJobs?.length
              ) ? (
                <div>
                  {jobType !== "featured"
                    ? getAllJobs?.map((jobData, idx) => (
                        <JobCard
                          key={idx}
                          onClick={() => handleJobCardClick(jobData)}
                          jobData={jobData}
                          selectedJob={selectedJob}
                        />
                      ))
                    : getFeaturedJobs?.map((jobData, idx) => (
                        <JobCard
                          key={idx}
                          onClick={() => handleJobCardClick(jobData)}
                          jobData={jobData}
                          selectedJob={selectedJob}
                        />
                      ))}
                </div>
              ) : (
                <div className="row">
                  <p style={{ textAlign: "center" }} className="my-30">
                    <b>No jobs</b>
                  </p>
                </div>
              )}
            </InfiniteScroll>
          </div>
          <div className="col-lg-7">
            <div className="bg-white p-4 main-content">
              {(
                selectedJob && jobType === "featured"
                  ? getFeaturedJobs?.length
                  : getAllJobs?.length
              ) ? (
                <>
                  <div className="d-flex justify-content-between">
                    <div>
                      <h3 className="main-header mb-2">{selectedJob?.title}</h3>
                      <span className="sub-header ">
                        {selectedJob?.company_visible
                          ? selectedJob?.company_name
                          : "Confidential"}{" "}
                        &#x2022; {selectedJob?.employment_type} &#x2022;{" "}
                        {GetTime(selectedJob?.createdAt)}{" "}
                      </span>
                    </div>
                    <img
                      src={
                        selectedJob?.company_visible
                          ? selectedJob?.company?.icon
                            ? selectedJob?.company?.icon
                            : pagePlaceholder
                          : confidential
                      }
                      alt="company_logo"
                      style={{
                        height: "50px",
                        width: "50px",
                        borderRadius: "50%",
                      }}
                    />
                  </div>
                  <div className="row mt-20">
                    <div className="col-sm-6">
                      <div className="d-flex">
                        <img src={expIcon} alt="exp_icon" />
                        <span className="ml-10">
                          {selectedJob?.min_experience}{" "}
                          {selectedJob?.max_experience ? "-" : ""}{" "}
                          {selectedJob?.max_experience} years
                        </span>
                      </div>
                      <div className="d-flex mt-10">
                        <img src={walletIcon} alt="salary_icon" />
                        <span className="ml-10">
                          ₹ {selectedJob?.min_salary}{" "}
                          {selectedJob?.max_salary ? "-" : ""}{" "}
                          {selectedJob?.max_salary} P.A.
                        </span>
                      </div>
                      <div className="d-flex mt-10">
                        <img src={locationIcon} alt="location_icon" />
                        <span className="ml-10">
                          {selectedJob?.job_location}
                        </span>
                      </div>
                    </div>
                    <div className="col-sm-6">
                      <div className="btn-job">
                        <div
                          className="btn btn-light blue-color"
                          onClick={() => {
                            handleSave(selectedJob?.id);
                          }}
                        >
                          {selectedJob?.savedJob?.status ? "Unsave" : "Save"}
                        </div>
                        {user?.data?.id !== selectedJob?.user?.id && (
                          <>
                            {selectedJob?.appliedJob ? (
                              <div className="btn btn-light blue-color">
                                Applied
                              </div>
                            ) : selectedJob?.apply_smart ? (
                              <div
                                className="btn btn-blue ml-20"
                                onClick={() => {
                                  setJobActive(true);
                                }}
                              >
                                <img
                                  src={noblePage}
                                  alt="noblepage_logo"
                                  className="mr-10"
                                />
                                <span>Smart Apply</span>
                              </div>
                            ) : selectedJob?.apply_link ? (
                              <div
                                className="btn btn-blue ml-20"
                                onClick={() => {
                                  window.open(
                                    selectedJob?.apply_link,
                                    "_blank"
                                  );
                                  applyForTheJob(selectedJob?.id);
                                }}
                              >
                                <img
                                  src={ApplyLinkIcon}
                                  alt="noblepage_logo"
                                  className="mr-10"
                                />
                                <span>Apply</span>
                              </div>
                            ) : (
                              <div
                                className="btn btn-blue ml-20"
                                onClick={() => {
                                  applyForTheJob(selectedJob?.id);
                                }}
                              >
                                <span>Apply</span>
                              </div>
                            )}
                          </>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="job-profile-box">
                    <div className="row">
                      <div className="col-md-6">
                        <div
                          className="user-post"
                          style={{
                            display: "flex",
                            alignItems: "flex-start",
                            justifyContent: "space-between",
                          }}
                        >
                          <div
                            className="prof-img"
                            style={{ marginRight: "10px" }}
                          >
                            <img
                              src={
                                selectedJob?.user?.profile_img
                                  ? selectedJob?.user?.profile_img
                                  : placeholderUser
                              }
                              alt="placeholder"
                            />
                          </div>
                          <div style={{ flex: 1, overflow: "hidden" }}>
                            <div className="d-flex align-items-center">
                              <h4
                                className="poster-name card-control-text-oneline"
                                style={{
                                  marginBottom: "5px",
                                  // whiteSpace: "nowrap",
                                  overflow: "hidden",
                                  textOverflow: "ellipsis",
                                  cursor: "pointer",
                                }}
                                onClick={() =>
                                  navigate(`/profile/${selectedJob?.user?.id}`)
                                }
                              >
                                {selectedJob?.user?.first_name +
                                  " " +
                                  selectedJob?.user?.last_name}
                                {selectedJob?.user
                                  ?.profile_verified_percentage === 100 && (
                                  <div className="varify">
                                    <div className="checkMark"></div>
                                  </div>
                                )}
                              </h4>
                              {selectedJob?.user?.badge == "hiring" && (
                                <span
                                  className="mx-1 px-3 py-1"
                                  style={{
                                    border: "1px solid #8430C8",
                                    color: "#8430C8",
                                    fontSize: "10px",
                                    borderRadius: "5px",
                                  }}
                                >
                                  Hiring
                                </span>
                              )}
                              {console.log(selectedJob?.user)}
                              {selectedJob?.user?.badge == "seeking" && (
                                <span
                                  className="px-2 py-1 mx-1 rounded"
                                  style={{
                                    color: "#f68a18",
                                    border: "1px solid #f68a18",
                                    fontSize: "10px",
                                  }}
                                >
                                  Seeking
                                </span>
                              )}
                            </div>
                            <p
                              className="designation-name card-control-text-oneline"
                              style={{
                                marginBottom: "5px",
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                              }}
                            >
                              {selectedJob?.user?.profile?.profile_headline}{" "}
                              {selectedJob?.company_name
                                ? `at ${selectedJob?.company_name}`
                                : ""}
                            </p>
                            <p
                              className="sub-title"
                              style={{ marginBottom: "5px" }}
                            >
                              Job Poster
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-6">
                        {user?.data?.id !== selectedJob?.user?.id && (
                          <div className="d-flex justify-content-end">
                            {selectedJob?.user?.connection_status ? (
                              <div
                                className="btn blue-color"
                                onClick={() => navigate("/message")}
                              >
                                Message
                              </div>
                            ) : (
                              <>
                                <div
                                  className="btn blue-color"
                                  onClick={() =>
                                    // alert(
                                    //   "This funtionality will be implemented in future milestones"
                                    // )
                                    setNpMailModal(true)
                                  }
                                >
                                  Send NPMail
                                </div>
                                <div
                                  className="btn btn-light blue-color"
                                  onClick={() =>
                                    sendRequest(selectedJob?.user?.id)
                                  }
                                >
                                  <img
                                    src={connectIcon}
                                    alt="noblepage_logo"
                                    className="icon-small mr-10"
                                  />
                                  <span>{connectText}</span>
                                </div>
                              </>
                            )}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="description mt-30">
                    <h3 className="main-header">Job Description</h3>
                    <div
                      dangerouslySetInnerHTML={createMarkup(
                        selectedJob?.description
                      )}
                      style={{ wordBreak: "break-all" }}
                      className="my-3"
                    ></div>
                  </div>
                  {console.log(selectedJob)}
                  {!Object.keys(selectedJob?.Feedback || {})?.length && (
                    <div className="job-helpful">
                      <h5>Was this Job helpful?</h5>
                      <div className="d-flex" style={{ cursor: "pointer" }}>
                        <h5
                          onClick={() => handleFeedback(true, selectedJob?.id)}
                        >
                          Yes
                        </h5>
                        <span
                          className="ml-5 mr-5"
                          style={{ marginTop: "2px" }}
                        >
                          {" / "}
                        </span>
                        <h5
                          onClick={() => handleFeedback(false, selectedJob?.id)}
                        >
                          {" No"}
                        </h5>
                      </div>
                    </div>
                  )}
                  {selectedJob?.company_visible && selectedJob?.company_id && (
                    <div className="job-about-comapny">
                      <h3 className="main-header mb-3">About Company</h3>
                      <div className="row">
                        <div className="col-md-9">
                          <div className="comapny-details">
                            <div className="prof-img">
                              <img
                                src={
                                  selectedJob?.company_visible
                                    ? selectedJob?.company?.icon
                                      ? selectedJob?.company?.icon
                                      : pagePlaceholder
                                    : confidential
                                }
                                style={{
                                  paddingBottom: "2px",
                                  paddingRight: "3px",
                                }}
                                alt="placeholder"
                              />
                            </div>
                            <div style={{ flex: 1, overflow: "hidden" }}>
                              <h4
                                className="poster-name card-control-text-oneline"
                                style={{
                                  marginBottom: "5px",
                                  // whiteSpace: "nowrap",
                                  overflow: "hidden",
                                  textOverflow: "ellipsis",
                                  cursor: "pointer",
                                }}
                                onClick={() =>
                                  navigate(
                                    `/pageadminview/${selectedJob?.company_id}`
                                  )
                                }
                              >
                                {selectedJob?.company_name}
                              </h4>
                              <p
                                className="designation-name card-control-text-oneline"
                                style={{
                                  marginBottom: "5px",
                                  overflow: "hidden",
                                  textOverflow: "ellipsis",
                                }}
                              >
                                {selectedJob?.company?.location}
                                {selectedJob?.company?.location &&
                                selectedJob?.company_followers_count > 0
                                  ? ","
                                  : ""}{" "}
                                {selectedJob?.company_followers_count}{" "}
                                {selectedJob?.company_followers_count > 1
                                  ? " followers"
                                  : " follower"}
                              </p>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-3">
                          <div
                            className="btn btn-light btn-margin-bottom follow-btn"
                            style={{ float: "right" }}
                            onClick={() => {
                              handleFollow(
                                selectedJob?.company_id,
                                selectedJob?.company_is_following
                              );
                            }}
                          >
                            {selectedJob?.company_is_following ? (
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "space-between",
                                  gap: "10px",
                                }}
                              >
                                <img
                                  src={unfollow}
                                  alt=""
                                  className="followicon"
                                />{" "}
                                <span>Unfollow</span>
                              </div>
                            ) : (
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "space-between",
                                  gap: "10px",
                                }}
                              >
                                <img src={add} alt="" className="followicon" />{" "}
                                <span>Follow</span>
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                      <div className="row mb-3">
                        <p className="card-control-text-oneline">
                          {selectedJob?.company?.description}
                        </p>
                      </div>
                    </div>
                  )}
                </>
              ) : (
                <div className="row">
                  <p style={{ textAlign: "center" }} className="my-30">
                    <b>No jobs</b>
                  </p>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      <ToastContainer />
      <Chat />
      {showRecruiterModal && (
        <RecruiterModals
          toggle={() => openRecruiterModal()}
          isOpen={showRecruiterModal}
          title={title}
          company={company}
          // listImage,
          employmentType={employmentType}
          companyLocation={companyLocation}
          companyVisible={companyVisible}
          companyId={companyId}
          numberOfVacancies={numberOfVacancies}
          workspacetype={workspacetype}
        />
      )}
      {JobActive && (
        <JobModal
          jobDetail={selectedJob}
          id={selectedJob?.id}
          toggle={() => JobCheck()}
          modal={JobActive}
          setSelectedJob={setSelectedJob}
          selectedJob={selectedJob}
        />
      )}
      {npMailModal && (
        <NpMailModal
          modal={npMailModal}
          toggle={() => setNpMailModal(!npMailModal)}
          profileData={{
            id: selectedJob?.user?.id,
            first_name: selectedJob?.user?.first_name,
            last_name: selectedJob?.user?.last_name,
            profile_img: selectedJob?.user?.profile_img,
            profile_headline: selectedJob?.user?.profile?.profile_headline,
          }}
        />
      )}
    </div>
  );
}
