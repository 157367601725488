import React, { useState, useEffect } from "react";
import Jobs from "./Jobs";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {} from "../../store/leads/leadsActions";

import { toast } from "react-toastify";

export default function JobsContainer() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  return <Jobs navigate={navigate} />;
}
