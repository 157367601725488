import React from "react";
import { Link, useNavigate } from "react-router-dom";
import "./ArticlesFeatured.scss";
import nopreview from "../../../../assets/img/no-preview.png";

export default function ArticlesFeatured(props) {
  const { articles_list } = props;

  console.log(articles_list);
  const navigate = useNavigate();
  return (
    <div className="mt-20 bg-white px-3 py-1" style={{ borderRadius: "5px" }}>
      <div className="featured-article my-4">
        <h4 className="mb-3 ">Featured Articles</h4>
        <div className="line"></div>
        <div className="mt-3">
          {articles_list?.map((item, index) => {
            if (index < 5) {
              return (
                <div key={index} className="box">
                  <div className="img">
                    {item?.media ? (
                      <img src={item?.media} alt="" />
                    ) : (
                      <img src={null} alt="" />
                    )}
                    {/* <img src={item?.media ? item?.media : nopreview} alt="" /> */}
                  </div>
                  <div className="text">
                    <Link to={`/articledetail/${item?.id}`}>
                      <h6
                        className="card-control-text-twoline"
                        style={{ wordBreak: "break-word" }}
                      >
                        {item.title}
                      </h6>
                    </Link>
                    <span>12,932 viewers</span>
                  </div>
                </div>
              );
            }
          })}
          {/* <div className="mt-3 ">
            <Link to={"/articles"}>
              <span>See all</span>
            </Link>
          </div> */}
          <div className="btn btn-light mt-3 text-blue d-block">
            <span
              style={{ fontSize: "15px", color: "#0432a3", fontWeight: 600 }}
              onClick={() => navigate("/articles")}
            >
              See all
            </span>
          </div>
        </div>
      </div>
    </div>
  );
}
