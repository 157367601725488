import React from "react";
import "../../network.scss";
import Layout from "../../Layout/Layout";
import moment from "moment";
import ShareModal from "../../../../core/components/sharePost/sharePost";
import CreateNewsletterModal from "../components/CreateNewsletter";
import placeholderUser from "../../../../assets/img/placeholder_user.jpg";
import arrowdown from "../../../../assets/img/arrow-down-black.svg";
import noPreview from "../../../../assets/img/no-preview.png";
import link from "../../../../assets/img/link.svg";
import share from "../../../../assets/img/share-rec.svg";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";
import ViewStaticsModel from "../../ViewStatics/ViewStaticsModel";

export default function NewsletterDetails(props) {
  const {
    openShareModal,
    setOpenShareModal,
    openEditNewsletter,
    setOpenEditNewsletter,
    editNewsletterData,
    setEditNewsletterData,
    newsletter_details,
    createMarkup,
    subscribeHandle,
    user,
    likeHandler,
    commentHandler,
    commentData,
    setCommentData,
    comment_list,
    navigate,
    divRef,
    executeScroll,
    loadMoreComments,
    total_comment,
    dropDownRef,
    shareDropdown,
    setShareDropdown,
    setOpenStaticsModel,
    openStaticsModel,
  } = props;
  return (
    <Layout>
      <div className="col-lg-9">
        <div className="article mt-4 mb-4">
          <div className="wrapper bg-white">
            <div className="box-img">
              <img
                src={
                  newsletter_details[0]?.media
                    ? newsletter_details[0]?.media
                    : null
                }
                className="newsletter-image"
                alt=""
              />
            </div>
            <div className="row">
              <div className="col-4 col-sm-2 col-md-2">
                <div className="side-icon">
                  <div className="d-flex align-items-center">
                    <span
                      className={
                        newsletter_details[0]?.like_check === true
                          ? "like-blue"
                          : "like"
                      }
                      onClick={
                        newsletter_details[0]?.like_check === true
                          ? () =>
                              likeHandler(newsletter_details[0]?.id, "unlike")
                          : () => likeHandler(newsletter_details[0]?.id, "like")
                      }
                    ></span>
                    <span>{newsletter_details[0]?.like_count}</span>
                  </div>
                  <div className="d-flex align-items-center">
                    <span className="comment" onClick={executeScroll}></span>
                    <span>{total_comment}</span>
                  </div>
                  <div className="d-flex align-items-center">
                    <span
                      className="reply"
                      onClick={() => {
                        setOpenShareModal(!openShareModal);
                      }}
                    ></span>
                    <span>{newsletter_details[0]?.shared_count}</span>
                  </div>
                </div>
              </div>
              <div className="col-8 col-sm-10 col-md-10">
                <div className="mr-20">
                  <div className="d-flex justify-content-between align-items-center  ">
                    <div className="profiles mb-20 mt-20 ">
                      <div
                        className="img"
                        onClick={() =>
                          navigate(
                            `/profile/${newsletter_details[0]?.user?.user_id}`
                          )
                        }
                      >
                        <img
                          src={
                            newsletter_details[0]?.user?.profile_img
                              ? newsletter_details[0]?.user?.profile_img
                              : placeholderUser
                          }
                          className="image-fit"
                          alt=""
                          style={{ width: "36px", cursor: "pointer" }}
                        />
                      </div>

                      <div className="details">
                        <h6
                          onClick={() =>
                            navigate(
                              `/profile/${newsletter_details[0]?.user?.user_id}`
                            )
                          }
                          className="card-control-text-oneline"
                        >
                          {newsletter_details[0]?.user?.first_name}{" "}
                          {newsletter_details[0]?.user?.last_name}
                        </h6>
                        <p className="card-control-text-oneline">
                          {user?.data?.profile_headline ? (
                            <>
                              {user?.data?.profile_headline}
                              {/* {user?.data?.highestExperience?.title +
                                " at " +
                                user?.data?.highestExperience?.company_name} */}
                            </>
                          ) : (
                            ""
                          )}
                        </p>
                      </div>
                    </div>
                    {user?.data?.id !==
                      newsletter_details[0]?.user?.user_id && (
                      <div>
                        {newsletter_details[0]?.subscribed?.status === true ? (
                          <div className="subscribe ml-10">
                            <div className="btn btn-light btn-blue-color">
                              Subscribed
                            </div>
                          </div>
                        ) : (
                          <div className="subscribe ml-10">
                            <div
                              className="btn btn-light btn-blue-color"
                              onClick={() => {
                                subscribeHandle(newsletter_details[0]?.id);
                              }}
                            >
                              Subscribe
                            </div>
                          </div>
                        )}
                      </div>
                    )}
                  </div>
                  <div className="d-flex gap-5 mb-20">
                    <div className="hrs">
                      {moment(newsletter_details[0]?.createdAt)
                        .startOf("minute")
                        .fromNow()}
                    </div>
                    {user?.data?.id ===
                      newsletter_details[0]?.user?.user_id && (
                      <>
                        <div
                          className="hrs"
                          onClick={() => {
                            setOpenEditNewsletter(!openEditNewsletter);
                            setEditNewsletterData(newsletter_details[0]);
                          }}
                        >
                          <div className="text-blue">
                            <div className="edit-icon"></div>Edit Newsletter
                          </div>
                        </div>
                        <div
                          className="hrs"
                          onClick={() => {
                            setOpenStaticsModel(!openStaticsModel);
                            console.log(openStaticsModel);
                          }}
                        >
                          <div className="text-blue">
                            <div className="static-icon"></div>View statistics
                          </div>
                        </div>
                      </>
                    )}
                  </div>
                  <div className="text">
                    <h4>{newsletter_details[0]?.title}</h4>

                    {newsletter_details[0]?.content && (
                      <div
                        dangerouslySetInnerHTML={createMarkup(
                          newsletter_details[0]?.content
                        )}
                        style={{ wordBreak: "break-all" }}
                      ></div>
                    )}
                  </div>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-4 col-sm-2 col-md-2"></div>
              <div className="col-8 col-sm-10 col-md-10">
                <div className="mr-20">
                  {/* <div className="translate">translate</div> */}
                  <div className="comments">
                    <div className="d-flex  align-items-center mt-20 mb-20">
                      <div className="col d-flex align-items-center gap-3">
                        <div
                          className="like"
                          style={{ display: "flex", alignItems: "center" }}
                        >
                          <span
                            className={
                              newsletter_details[0]?.like_check === true
                                ? "like-blue-comment"
                                : "like-comment"
                            }
                          ></span>{" "}
                          {newsletter_details[0]?.like_check === true ? (
                            <span className="text-comment blue-like">
                              Liked ({newsletter_details[0]?.like_count})
                            </span>
                          ) : (
                            <span className="text-comment">
                              {newsletter_details[0]?.like_count > 1 ? (
                                <>
                                  Likes ({newsletter_details[0]?.like_count}){" "}
                                </>
                              ) : (
                                <>Like ({newsletter_details[0]?.like_count}) </>
                              )}
                            </span>
                          )}
                        </div>
                        <div
                          className="like"
                          style={{ display: "flex", alignItems: "center" }}
                        >
                          <div className="comment"></div>
                          <span className="text-comment">
                            {total_comment > 1 ? (
                              <>Comments ({total_comment})</>
                            ) : (
                              <>Comment ({total_comment})</>
                            )}
                          </span>
                        </div>
                      </div>
                      <div className="col ">
                        <div className="float-sm-end ">
                          <div ref={dropDownRef}>
                            <div
                              className="d-flex align-items-center"
                              ref={dropDownRef}
                              onClick={() => setShareDropdown(!shareDropdown)}
                            >
                              <div className="share"></div>{" "}
                              <span className="text-comment">{` Share${
                                newsletter_details[0]?.shared_count !== 1
                                  ? "s"
                                  : ""
                              } (${
                                newsletter_details[0]?.shared_count
                              })`}</span>
                            </div>
                            <div className="drop">
                              {shareDropdown && (
                                <div className="dropDownBox">
                                  <ul>
                                    <li>
                                      <img src={share} alt="" />{" "}
                                      <span
                                        onClick={() => {
                                          setOpenShareModal(!openShareModal);
                                        }}
                                      >
                                        Share on feed
                                      </span>
                                    </li>
                                    <li>
                                      <img src={link} alt="" />{" "}
                                      <span
                                        onClick={() => {
                                          navigator.clipboard.writeText(
                                            window.location.href
                                          );
                                          toast.success(
                                            "Copied link to clipboard!",
                                            {
                                              position:
                                                toast.POSITION.BOTTOM_LEFT,
                                            }
                                          );
                                          setShareDropdown(false);
                                        }}
                                      >
                                        Copy newsletter link
                                      </span>
                                    </li>
                                  </ul>
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="d-flex gap-2 mb-20">
                      <div className="profile-img">
                        <img
                          src={
                            user?.data?.profile_img
                              ? user?.data?.profile_img
                              : placeholderUser
                          }
                          className="image-fit"
                          alt=""
                        />
                      </div>
                      <div className="col" ref={divRef}>
                        <div className="input">
                          <input
                            type="text"
                            className="text-black"
                            value={commentData}
                            onKeyDown={commentHandler(
                              newsletter_details[0]?.id
                            )}
                            onChange={(e) => {
                              setCommentData(e.target.value);
                            }}
                            placeholder="Share your thoughts..."
                          />
                        </div>
                      </div>
                    </div>
                    <div className="mb-3">
                      {total_comment > 1 ? (
                        <p className="comment-title">
                          Comments ({total_comment})
                        </p>
                      ) : (
                        <p className="comment-title">
                          Comment ({total_comment})
                        </p>
                      )}
                    </div>

                    {comment_list?.length > 0 ? (
                      <>
                        {comment_list?.map((data, idx) => (
                          <div className="row mb-20">
                            <div className="col-1">
                              <div
                                className="profiles mb-10 mt-10 "
                                style={{ gap: "10px" }}
                              >
                                <div className="img">
                                  <img
                                    src={
                                      data?.user?.profile_img
                                        ? data?.user?.profile_img
                                        : placeholderUser
                                    }
                                    className="image-fit"
                                    style={{ width: "36px", cursor: "pointer" }}
                                    alt=""
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="col-11 pl-col">
                              <div className="comment-text-box">
                                <div className="comment-content">
                                  <div
                                    style={{
                                      display: "flex",
                                      justifyContent: "space-between",
                                    }}
                                  >
                                    <div>
                                      <h5 className="card-control-text-oneline">
                                        {data?.user?.first_name}{" "}
                                        {data?.user?.last_name}
                                      </h5>
                                      <p className="card-control-text-oneline">
                                        {user?.data?.highestExperience ? (
                                          <>
                                            {user?.data?.highestExperience
                                              ?.title +
                                              " at " +
                                              user?.data?.highestExperience
                                                ?.company_name}
                                          </>
                                        ) : (
                                          ""
                                        )}
                                      </p>
                                    </div>
                                    <div className="time">
                                      <span>
                                        {" "}
                                        {moment(data.createdAt).fromNow(true)}
                                        {" ago"}
                                      </span>
                                    </div>
                                  </div>
                                </div>
                                <p className="mt-10">{data?.comment}</p>
                              </div>
                            </div>
                          </div>
                        ))}
                        {total_comment === comment_list.length ? (
                          <></>
                        ) : (
                          <div className="load-text mb-20">
                            <span onClick={() => loadMoreComments()}>
                              View more comments{"  "}
                              <img src={arrowdown} alt="arrow" />
                            </span>
                          </div>
                        )}
                      </>
                    ) : (
                      <div className="mb-20 text-comment">
                        No Comments. Be the first to add comment.
                      </div>
                    )}
                  </div>
                  <div className="line mb-20"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <ToastContainer />
      </div>
      {openShareModal && (
        <ShareModal
          modal={openShareModal}
          toggle={() => setOpenShareModal(!openShareModal)}
          title="Share"
          shareData={newsletter_details[0]}
          type="newsletter"
          source="newsletterDetails"
        />
      )}
      {openEditNewsletter && (
        <CreateNewsletterModal
          modal={openEditNewsletter}
          toggle={() => setOpenEditNewsletter(!openEditNewsletter)}
          title="Edit NewsLetter"
          editNewsletterData={editNewsletterData}
          setEditNewsletterData={setEditNewsletterData}
          source="network"
        />
      )}
      {openStaticsModel && (
        <ViewStaticsModel
          toggle={() => setOpenStaticsModel(!openStaticsModel)}
          openStaticsModel={openStaticsModel}
          type="newsletter"
          title={newsletter_details[0]?.title}
          id={newsletter_details[0]?.id}
        />
      )}
    </Layout>
  );
}
