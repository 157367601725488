import ComingSoonDetailPage from "./mynetwork/Pages/ComingSoon/ComingSoonDetailPage";

const routeNames = {
  HOME: "/",
  ABOUT: "/about",
  BANNER: "/banner",
  FORGET: "/forgot",
  PHONE: "/phone",
  SIGNUP: "/signup",
  OTP: "/otp",
  CHANGE_PASSWORD: "/change-password",
  LEGAL: "/legal",
  COMMUNITY: "/community",
  HELP: "/help",
  IMPORTANTQUESTION: "/help/question/:id",
  FEEDBACK: "/feedback",
  DIRECTORY: "/directory/companies",
  MEMBERDIRECTORY: "/directory/members",
  ARTICLEDIRECTORY: "/directory/articles",
  NEWSLETTERDIRECTORY: "/directory/newsletters",
  POSTDIRECTORY: "/directory/posts",
  COMPANYDETAIL: "/directory/companydetail/:id",
  MEMBERDETAIL: "/directory/memberdetail/:id",
  ARTICLEPUBLICDETAIL: "/directory/articledetail",
  NEWSLETTERPUBLICDETAIL: "/directory/newsletterdetail",
  POSTPUBLICDETAIL: "/directory/postdetail",
  JOBDIRECTORY: "/directory/jobs",
  JOBPUBLICDETAIL: "/directory/jobdetail/:id",
  INTRO: "/intro",
  MEMBERSHIP: "/membership",
  MEMBERSHIPPLAN: "/membership/price",
  BLOG: "/blog",
  BLOGDETAIL: "/blogdetail/:id",
  BLOGDETAILWEBVIEW: "/blogdetail-webview/:id",
  EXPLORE: "/explore",
  EXPLOREDETAILS: "/exploredetails",
  EXPLOREDETAILSWEBVIEW: "/exploredetails-webview",
  MOBILE: "/mobile",
  DASHBOARD: "/dashboard",
  ERROR: "/error",
  PROFILE: "/profile/:id",
  PROFILE_VIEWS: "/profile-views",
  PEOPLE: "/people",
  SETTING: "/setting",
  MESSAGE: "/message",
  NEWSLETTER: "/newsletter",
  PAGEADMINVIEW: "/pageadminview/:id",
  CREATECAMPAIGN: "/createcampaign",
  EVENTDETAILS: "/eventdetail/:id",
  GROUPDETAILS: "/groupdetails/:id",
  ARTICLE: "/articles",
  CREATEARTICLENEW: "/createarticlenew",
  INTRO_WEBVIEW: "/intro-webview",
  DASHBOARD_WEBVIEW: "/dashboard-webview",
  CONNECTIONS: "/connections",
  NETWORK: "/network",
  MYPOSTS: "/posts",
  PAGES: "/pages",
  MYEVENTS: "/event",
  GROUPS: "/groups",
  PEOPLES: "/peoples",
  INVITATION: "/invitations",
  HASHTAG: "/hashtag",
  JOBDETAILS: "/jobdetails/:id",
  APPLICATIONNOBEL: "/applicationnobel/:id",
  NEWSLETTERDETAIL: "/newsletterdetail/:id",
  ARTICLEDETAIL: "/articledetail/:id",
  PROFILEVIEWDEMO: "/profile-demo",
  CV: "/cv",
  POSTPAGEVIEW: "/postpageview/:postId",
  POSTDETAILS: "/postdetails/:postId",
  SAMPLETESTING: "/sampletesting",
  COMMENTDETAIL: "/commentdetails/:postId/:index",
  CAMPAIGN: "/campaign",
  CONTACTINFO: "/contactinfo/:id",
  HASHTAGPOSTS: "/hashtag/feed",
  LISTINGPAGE: "/help/question/topic",

  LEADS: "/leads-management",
  WALLET: "/wallet",
  WALLETHISTORY: "/wallet-history",
  PORTALWELCOME: "/portal-welcome",
  PORTALSIGNUP: "/tp/portal/:param/signup",
  PORTALLOGIN: "/tp/portal/:param/login",
  PORTALFORGOT: "/tp/portal/:param/forget",
  VERIFYOTP: "/tp/portal/:param/verify-otp",
  PORTALDASHBOARD: "/tp/portal/:param/dashboard",
  RESETPASSWORD: "/tp/portal/:param/reset-password",
  PORTALJOBDETAILS: "/portal/job/details",
  PORTALEDITPROFILE: "/tp/portal/:param/profile/edit",
  // TALENTPOOLDASHBOARD: "/talent-pool/dashboard",
  // PORTALEDITPROFILE: "/portal/profile/edit",
  TALENTPOOLDASHBOARD: "/tp/:id/dashboard",
  APPLICANTDETAILS: "/tp/:id/applicant/details/:applicant_id",
  TPMEMBEREDTAILS: "/tp/:id/member/details/:memberId",
  JOBS: "/jobs",
  POSTJOB: "/post-job",
  INTERVIEWPREPERATION: "/interview-preperation",
  NOTIFICATIONS: "/notifications",
  MYJOBS: "/myjobs",
  JOBSSEARCH: "/jobs/search",
  JOBMODAL: "/jobs/modal",
  RECRUITER: "/recruiter",
  RECRUITERMODALS: "/recruiter/modals",
  SHORTLISTEDCANDIDATES: "/shortlist/candidates",
  SMARTINTERVIEW: "/recruiter/smart-interview",
  CHECKAUTO: "/check/autocomplete",
  HIRINGMODALS: "/hiring/hiring-modals",
  SETTINGMODALS: "/setting/modals",
  GIVESMARTINTERVIEW: "/recuriter/give-interview",
  WATCHSMARTINTERVIEW: "/recuriter/watch-interview",
  OTP2FA: "/2fa-verification",
  BACKGROUNDSERVICESCREEN: "/background-service",
  BACKGROUNDREPORTSCREEN: "/background-report",
  CREATEORDER: "/background-create-order",
  ORDERPREVIEW: "/order-preview",
  SCREENINGPAYMENT: "/screening-payment",
  REPORTINFO: "/report-info",
  PAGEJOBS: "/page-jobs",
  COMINGSOONDETAILPAGE: "/coming-soon-detail-page",
  SEARCH: "/search",
  CREATENEWSLETTERNEW: "/create-newsletter",
  CREATEARTICLEMOBILE: "/mobile/create-article/:source/:token",
  CREATENEWSLETTERMOBILE: "/mobile/create-newsletter",
};

export default routeNames;
