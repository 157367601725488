import { call } from "./apiCall"


class MasterApi {
 getIndustryData() {
    return new Promise( (resolve, reject) => {
        const token = localStorage.getItem("accessToken");
          (async () => {
            try {
                const res = await call(
                    "get",
                    `api/v1/industry`,
                    null,
                    null,
                    token
                )
                if(res.data.success===true){
                    resolve(res.data)
              }
              if(res.data.success===false){
                resolve(res.data)
              }

            } catch (err) {
                console.log(err)
                reject(err)
            }
        })()
    })
}
getHashTagList(searchData) {
  return new Promise( (resolve, reject) => {
      const token = localStorage.getItem("accessToken");
        (async () => {
          try {
              const res = await call(
                  "get",
                  `api/v1/hashtag`,
                  searchData,
                  null,
                  token
              )
              if(res.data.success===true){
                  resolve(res.data)
            }
            if(res.data.success===false){
              resolve(res.data)
            }

          } catch (err) {
              console.log(err)
              reject(err)
          }
      })()
  })
}
}

 export const masterApi = new MasterApi()