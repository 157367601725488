import React from "react";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import EditorBox from "./Editor";
import { useState } from "react";

const AddPolicyModal = (props) => {
  const { modal, toggle, title, content, field, handleChange } = props;
  const [convertedContent, setConvertedContent] = useState(null);
  const [dbFormat, setDbFormat] = useState(content ? content : "");
  const [hasText, setHasText] = useState(false);
  const [onlySpaces, setOnlySpaces] = useState(false);

  const handleSave = () => {
    handleChange(field, dbFormat);
    toggle();
  };

  return (
    <div>
      <Modal isOpen={modal} style={{ width: "588px" }}>
        <div className="create-talent-pool">
          <ModalHeader toggle={toggle} className="text-dark">
            {title}
          </ModalHeader>
          <ModalBody>
            <div className="talent_pool_privacy">
              <EditorBox
                convertedContent={convertedContent}
                setConvertedContent={setConvertedContent}
                setDbFormat={setDbFormat}
                setHasText={setHasText}
                setOnlySpaces={setOnlySpaces}
                dbFormat={dbFormat}
              />
            </div>
            <div className="savebutton" style={{ padding: 0 }}>
              <div
                className="btn btn-blue mt-5 px-5"
                style={{ textAlign: "center" }}
                onClick={() => handleSave()}
              >
                {content ? "Update" : "Next"}
              </div>
            </div>
          </ModalBody>
        </div>
      </Modal>
    </div>
  );
};

export default AddPolicyModal;
