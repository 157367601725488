import React from "react";
import { Modal, ModalHeader, ModalBody } from "reactstrap";

export default function AddSection(props) {
  const {
    modal,
    toggle,
    title,
    editAbout,
    setEditAbout,
    educationModal,
    setEducationModal,
    addExpModal,
    setAddExpModal,
    addSkillModal,
    setAddSkillModal,
    languageModal,
    setLanguageModal,
    courseModal,
    setCourseModal,
    licenseCertificateModal,
    setLicenseCertificateModal,
    awardsHonorsModal,
    setAwardsHonorsModal,
    giveRecommendation,
    setGiveRecommendation,
    addIntroModal,
    setAddIntroModal,
    addResumeModal,
    setAddResumeModal,
    isWebView,
    webViewHandlerRecommendationAddIcon,
    webViewHandlerResumeUpload,
    webViewHandlerIntroVideoUpload,
    id,
  } = props;
  return (
    <div>
      <Modal
        isOpen={modal}
        toggle={toggle}
        className="add-section fix-width gradient-background"
        centered
      >
        <ModalHeader toggle={toggle} id="account-modal">
          {title}
        </ModalHeader>
        <ModalBody>
          <ul>
            <li>
              <div
                onClick={() => {
                  setEditAbout(!editAbout);
                  toggle();
                }}
              >
                Add About
              </div>
            </li>
            <div className="line"></div>
            {/* {isWebView ? <></> : <> */}
            <li>
              <div
                onClick={() => {
                  isWebView
                    ? webViewHandlerResumeUpload({ id: id })
                    : setAddResumeModal(!addResumeModal);
                  toggle();
                }}
              >
                Add Resume/CV
              </div>
            </li>
            <div className="line"></div>
            {/* </>} */}
            <li>
              <div
                onClick={() => {
                  isWebView
                    ? webViewHandlerIntroVideoUpload({ id: id })
                    : setAddIntroModal(!addIntroModal);
                  toggle();
                }}
              >
                Add Intro video
              </div>
            </li>
            <div className="line"></div>
            <li>
              <div
                onClick={() => {
                  setEducationModal(!educationModal);
                  toggle();
                }}
              >
                Add Education
              </div>
            </li>
            <div className="line"></div>
            <li>
              <div
                onClick={() => {
                  setAddExpModal(!addExpModal);
                  toggle();
                }}
              >
                Add Experience
              </div>
            </li>
            <div className="line"></div>
            <li>
              <div
                onClick={() => {
                  setAwardsHonorsModal(!awardsHonorsModal);
                  toggle();
                }}
              >
                Add Honors & Awards
              </div>
            </li>
            <div className="line"></div>
            <li>
              <div
                onClick={() => {
                  setAddSkillModal(!addSkillModal);
                  toggle();
                }}
              >
                Add Skills
              </div>
            </li>
            <div className="line"></div>
            <li>
              <div
                onClick={() => {
                  isWebView
                    ? webViewHandlerRecommendationAddIcon({ id: 0 })
                    : setGiveRecommendation(!giveRecommendation);
                  toggle();
                }}
              >
                Add Recommendations
              </div>
            </li>
            <div className="line"></div>
            <li>
              <div
                onClick={() => {
                  setLicenseCertificateModal(!licenseCertificateModal);
                  toggle();
                }}
              >
                Add Licenses & Certifications
              </div>
            </li>
            <div className="line"></div>
            <li>
              <div
                onClick={() => {
                  setCourseModal(!courseModal);
                  toggle();
                }}
              >
                Add Courses
              </div>
            </li>
            <div className="line"></div>
            <li>
              <div
                onClick={() => {
                  setLanguageModal(!languageModal);
                  toggle();
                }}
              >
                Add Language
              </div>
            </li>
          </ul>
        </ModalBody>
        <div style={{ padding: "5px" }}></div>
      </Modal>
    </div>
  );
}
