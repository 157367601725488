import React from "react";
import image from "../../assets/img/nobel.svg";
import "./Directory.scss";
import { useNavigate } from "react-router-dom";

const Directory = (props) => {
  const {
    companyData,
    SearchCompany,
    setSearchKey,
    SearchAlphabaCompany,
    SearchAllCompany,
    handleEnterSubmit,
  } = props;
  const navigate = useNavigate();

  const navigateToCompanyDetail = (id) => {
    navigate(`/directory/companydetail/${id}`);
  };

  return (
    <div className="directory">
      <div className="header">
        <div className="container">
          <div className="row">
            <div className="col-lg-8">
              <img
                src={image}
                alt=""
                style={{ cursor: "pointer" }}
                onClick={() => navigate("/")}
              />
            </div>
            <div className="col-lg-4">
              <div className="search">
                <input
                  type="text"
                  placeholder="Search"
                  onChange={(e) => setSearchKey(e.target.value)}
                  onKeyDown={(e) => handleEnterSubmit(e)}
                />
                <div
                  className="search-img"
                  onClick={() => SearchCompany()}
                ></div>
              </div>
            </div>
          </div>
        </div>
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <h1>Directory / Companies</h1>
              <p className="mt-3">
              Browse through our directory to discover talented individuals, entrepreneurs, and industry experts from around the world.
              </p>
            </div>
          </div>
        </div>
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="browse">
                <h3>Browse By</h3>
                <ul className="mb-4 mt-3">
                  <li onClick={() => SearchAlphabaCompany("A")}>A</li>
                  <li onClick={() => SearchAlphabaCompany("B")}>B</li>
                  <li onClick={() => SearchAlphabaCompany("C")}>C</li>
                  <li onClick={() => SearchAlphabaCompany("D")}>D</li>
                  <li onClick={() => SearchAlphabaCompany("E")}>E</li>
                  <li onClick={() => SearchAlphabaCompany("F")}>F</li>
                  <li onClick={() => SearchAlphabaCompany("G")}>G</li>
                  <li onClick={() => SearchAlphabaCompany("H")}>H</li>
                  <li onClick={() => SearchAlphabaCompany("I")}>I</li>
                  <li onClick={() => SearchAlphabaCompany("J")}>J</li>
                  <li onClick={() => SearchAlphabaCompany("K")}>K</li>
                  <li onClick={() => SearchAlphabaCompany("L")}>L</li>
                  <li onClick={() => SearchAlphabaCompany("M")}>M</li>
                  <li onClick={() => SearchAlphabaCompany("N")}>N</li>
                  <li onClick={() => SearchAlphabaCompany("O")}>O</li>
                  <li onClick={() => SearchAlphabaCompany("P")}>P</li>
                  <li onClick={() => SearchAlphabaCompany("Q")}>Q</li>
                  <li onClick={() => SearchAlphabaCompany("R")}>R</li>
                  <li onClick={() => SearchAlphabaCompany("S")}>S</li>
                  <li onClick={() => SearchAlphabaCompany("T")}>T</li>
                  <li onClick={() => SearchAlphabaCompany("U")}>U</li>
                  <li onClick={() => SearchAlphabaCompany("V")}>V</li>
                  <li onClick={() => SearchAlphabaCompany("W")}>W</li>
                  <li onClick={() => SearchAlphabaCompany("X")}>X</li>
                  <li onClick={() => SearchAlphabaCompany("Y")}>Y</li>
                  <li onClick={() => SearchAlphabaCompany("Z")}>Z</li>
                  {/* <li
                    onClick={() =>  SearchAllCompany()}
                  >
                    All
                  </li> */}
                </ul>
              </div>
              <div className="community">
                {companyData ? (
                  <div className="row list">
                    {companyData?.map((item, index) => {
                      return (
                        <div
                          key={index}
                          className="col-lg-4 list-name card-control-text-oneline"
                          onClick={() => navigateToCompanyDetail(item?.id)}
                        >
                          {item?.name}
                        </div>
                      );
                    })}
                  </div>
                ) : (
                  <>
                    <h6 className="d-flex justify-content-center">
                      Not found any companies directory
                    </h6>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Directory;
