import {
  GET_TALENT_POOL_DETAIL,
  GET_TALENT_POOL_DETAIL_BY_TOKEN,
  GET_TALENT_POOL_DETAIL_BY_TOKEN_FAIL,
  GET_TALENT_POOL_DETAIL_FAIL,
  LOGIN_FAIL,
  LOGIN_SUCCESS,
  LOGOUT_PORTAL,
  REGISTER_FAIL,
  REGISTER_SUCCESS,
} from "../types";

const initialState = {
  talentPoolData: {},
  talentPoolDataByToken: {},
  portal: {},
  isLoggedIn: false,
  user: {},
};

export default function (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case GET_TALENT_POOL_DETAIL:
      return {
        ...state,
        talentPoolData: { ...state.talentPoolData, ...payload },
      };
    case GET_TALENT_POOL_DETAIL_FAIL:
      return {
        ...state,
        talentPoolData: {},
      };

    case GET_TALENT_POOL_DETAIL_BY_TOKEN:
      return {
        ...state,
        isLoggedIn: true,
        talentPoolDataByToken: { ...state.talentPoolDataByToken, ...payload },
      };
    case GET_TALENT_POOL_DETAIL_BY_TOKEN_FAIL:
      return {
        ...state,
        isLoggedIn: false,
        talentPoolDataByToken: {},
      };
    case REGISTER_SUCCESS:
      return {
        ...state,
        isLoggedIn: true,
        user: payload.data,
      };
    case REGISTER_FAIL:
      return {
        ...state,
        isLoggedIn: false,
      };
    case LOGIN_SUCCESS:
      return {
        ...state,
        isLoggedIn: true,
        // user: payload.data,
      };
    case LOGIN_FAIL:
      return {
        ...state,
        isLoggedIn: false,
        user: null,
      };
    case LOGOUT_PORTAL:
      localStorage.removeItem("accessTpToken")
      return {
        ...state,
        isLoggedIn: false,
        user: null,
      };
    default:
      return state;
  }
}
