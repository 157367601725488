import React from "react";
import { Input, Modal, ModalBody, ModalHeader } from "reactstrap";
import placeholderUser from "../../../../assets/img/placeholder_user.jpg";
import info from "../../../../assets/img/info.svg";
import card from "../../../../assets/img/Credit-card1.png";
import headerImg from "../../../../assets/img/RectanglepayBycard.png";
import moment from "moment";

const Payment = (props) => {
  const {
    modal,
    toggle,
    title,
    createCampaign,
    scheduleCheck,
    campaignDetail,
    selectedFeed,
    totalAmount,
    scheduleTime,
  } = props;
  console.log(campaignDetail, selectedFeed, "fff");
  return (
    <Modal
      isOpen={modal}
      size="lg"
      style={{ maxWidth: "600px", maxHeight: "420px" }}
      className="topDiv gradient-background"
    >
      <ModalHeader
        className="header"
        toggle={toggle}
        style={{
          backgroundImage: `url(${headerImg})`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          color: "white",
        }}
      >
        {title}
      </ModalHeader>
      <ModalBody>
        <div className="grey-bg bg-white payment">
          {/* {selected && ( */}
          <div className="row mb-2">
            <p className="mb-3">Campaign Details</p>
            <div className="col-lg-2">
              <div className="img">
                <img
                  style={{ height: "70px", width: "80px", borderRadius: 0 }}
                  src={
                    campaignDetail?.type === "page"
                      ? selectedFeed?.icon
                        ? selectedFeed?.icon
                        : `https://nobelpageapi.dev.vinove.com/Of5zE_1723532542811.png`
                      : selectedFeed?.Feed_Documents?.length > 0
                      ? selectedFeed?.Feed_Documents[0]?.document_url
                      : `http://localhost:3000/static/media/adbar.529a9a477bcbbc9681c2.png`
                  }
                  // src={
                  //   selected?.profile_img
                  //     ? selected?.profile_img
                  //     : placeholderUser
                  // }
                  className="image-fit profile-Img"
                  alt="profileimage"
                />
              </div>
            </div>
            <div className="col-lg-8">
              <div className="text">
                <p className="text-dark">
                  {" "}
                  <strong>{campaignDetail?.name}</strong>
                </p>
                <p>
                  Starts{" "}
                  {moment(campaignDetail?.start_date).format("ddd, MMM Do,")}
                  {moment(scheduleTime, "HH:mm:ss").format("hh:mm A")}
                </p>
                <p>
                  Ends {moment(campaignDetail?.end_date).format("ddd, MMM Do,")}
                  {moment(scheduleTime, "HH:mm:ss").format("hh:mm A")}
                </p>
                {/* {selected?.profile?.profile_headline && (
                    <p>
                      {selected.profile?.profile_headline.slice(0, 30) +
                        (selected.profile?.profile_headline > 30 ? "..." : "")}
                    </p>
                  )} */}
              </div>
            </div>
            <div className="col-lg-2">
              <div className="float-sm-end">
                <div
                  className="text-blue remove curserPointer"
                  onClick={() => toggle()}
                >
                  <strong>Edit</strong>
                </div>
              </div>
            </div>
          </div>
          {/* )} */}
          <div className="row">
            <div className="check">
              <Input
                type="checkbox"
                id="coupanCheck"
                name="coupanCheck"
                // checked={pageData?.experienceCheck}
                // onClick={(e) =>
                //   setPageData({
                //     ...pageData,
                //     experienceCheck: e.target.checked,
                //   })
                // }
              />
              <label htmlFor="couponCheck">I have a coupon code </label>
            </div>
          </div>
          <div className="row">
            <div className="col-7"></div>
            <div className="col-5">
              <div className="float-sm-end">
                <div className="campaign-cost">
                  <p>Campaign cost: ${totalAmount}</p>
                </div>
                <div className="total-cost">
                  <strong className="text-dark">
                    Total Amount: ${totalAmount}
                  </strong>
                </div>
              </div>
            </div>
          </div>
          <div className="payment-card">
            <div className="header">
              <p>Payment Method</p>
            </div>
            <div className="card-details d-flex align-items-center">
              <div className="col-9 d-flex align-items-center my-3">
                <div className="radio m-3">
                  <input
                    type="radio"
                    name="paymentcard"
                    //   onChange={(e) => updatePrivacy(e, "publicMode")}
                    //   checked={publicMode}
                  />
                </div>
                <div>
                  <h5>Ending in:...6372</h5>
                  <p>Exp. Date: 12/22</p>
                </div>
              </div>
              <div className="col-3">
                <div className="float-sm-end">
                  <img src={card} className="card-image" alt="credit-card" />
                </div>
              </div>
            </div>
            <div className="line"></div>
            <div className="card-details d-flex align-items-center">
              <div className="col-9 d-flex align-items-center my-3">
                <div className="radio m-3">
                  <input
                    type="radio"
                    name="paymentcard"
                    //   onChange={(e) => updatePrivacy(e, "publicMode")}
                    //   checked={publicMode}
                  />
                </div>
                <div>
                  <h5>Ending in:...6372</h5>
                  <p>Exp. Date: 12/22</p>
                </div>
              </div>
              <div className="col-3">
                <div className="float-sm-end">
                  <img src={card} className="card-image" alt="creditimage" />
                </div>
              </div>
            </div>
          </div>
          <div className="mt-2">
            <span
              className="addcard"
              onClick={() =>
                alert(
                  "This functionality will be implemented in future milestone"
                )
              }
            >
              + Add New Card
            </span>
          </div>
          <div className="text-center my-3">
            <div
              className="btn btn-blue"
              onClick={
                scheduleCheck === true
                  ? () => {
                      createCampaign("scheduled", "page");
                      toggle();
                    }
                  : () => {
                      createCampaign("published", "page");
                      toggle();
                    }
              }
            >
              {" "}
              Pay Now
            </div>
          </div>
        </div>
      </ModalBody>
    </Modal>
  );
};

export default Payment;
