import React from "react";
import {
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown,
} from "reactstrap";
import "./Dropdown.css";
import dd from "../../../assets/img/drop-down.svg";
const Dropdown = ({
  label,
  data,
  dynamicPlaceHolder,
  handleSelect,
  handleChange,
}) => {
  return (
    <UncontrolledDropdown>
      <DropdownToggle
        style={{
          color: "#212529",
          fontWeight: "400",
          background: "transparent",
          width: "100%",
          display: "flex",
          justifyContent: "space-Between",
          alignItems: "center",
          fontSize: "1rem",
          lineHeight: " 1.5",
          backgroundColor: "#fff",
          border: "1px solid #ced4da",
          padding: "10px 15px",
          textOverflow: "ellipsis",
          overflow: "hidden",
          wordBreak: "break-word",
        }}
        onKeyDown={(e) => handleChange(e)}
      >
        {dynamicPlaceHolder ? dynamicPlaceHolder : label}
        <img src={dd} alt="" />
      </DropdownToggle>
      <DropdownMenu
        style={{
          width: "100%",
          maxHeight: "350px",
          overflow: "auto",
        }}
      >
        {data &&
          data.map((item, index) => {
            return (
              <DropdownItem
                style={{
                  display: "flex",
                  width: "100%",
                }}
                key={index}
                value={item}
                onClick={() => handleSelect(item)}
              >
                {item.value}
              </DropdownItem>
            );
          })}
      </DropdownMenu>
    </UncontrolledDropdown>
  );
};

export default Dropdown;
