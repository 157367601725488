import React, { useEffect, useState } from "react";
import "./RecentJobSearches.scss";
import closeIcon2 from "../../../../assets/img/closeIcon2.svg";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  clearSearchHistory,
  deleteSearchHistory,
  getSearchHistory,
} from "../../../../store/jobs/jobAction";
import DeleteModal from "../../../../core/components/modal/DeleteModal";
import bgImgForJob from "../../../../assets/img/bgImgForJob.png";

export default function RecentJobSearches(props) {
  const {} = props;
  const dispatch = useDispatch();
  const { searchHistory } = useSelector((state) => state.jobs);

  const [isViewMore, setIsViewMore] = useState(false);
  const [clearHistoryModel, setClearHistoryModel] = useState(false);

  useEffect(() => {
    dispatch(getSearchHistory({ limit: 3, skip: 0, type: "job" }))
      .then((res) => {
        if (res?.data?.length < res?.totalCount) setIsViewMore(true);
      })
      .catch((err) => {});
  }, []);

  const handleViewMore = () => {
    dispatch(
      getSearchHistory({ limit: 3, skip: searchHistory.length, type: "job" })
    )
      .then((res) => {
        if (searchHistory?.length + res?.data?.length < res?.totalCount)
          setIsViewMore(true);
        else setIsViewMore(false);
      })
      .catch((err) => {});
  };

  const handleDelete = (id) => {
    dispatch(deleteSearchHistory(id));
  };

  const handleClear = () => {
    dispatch(clearSearchHistory());
  };

  const [alertOn, setAlertOn] = useState(false);

  return (
    <div className="recent-job-search-card-layout">
      <div className="bg-white pb-4" style={{ padding: "10px 30px" }}>
        <div className="bg-white pt-3 pb-1">
          <div
            className="d-flex justify-content-between job-heading-box"
            style={{ marginBottom: "0px" }}
          >
            <span className="job-heading">Recent job searches</span>
            {searchHistory?.length > 0 && (
              <span
                className="clear"
                style={{ cursor: "pointer" }}
                onClick={() => setClearHistoryModel(!clearHistoryModel)}
              >
                Clear
              </span>
            )}
          </div>
        </div>

        <div className="main-area">
          {searchHistory?.length ? (
            <>
              {searchHistory?.map(({ id, search, job_count }) => {
                return (
                  <>
                    <div className="card-list">
                      <div className="d-flex justify-content-between">
                        <div className="job-profile">
                          {search}{" "}
                          {job_count ? (
                            <span className="new">{job_count} new</span>
                          ) : (
                            ""
                          )}
                          <div className="location">India</div>
                        </div>
                        <div
                          className="cross-btn"
                          style={{ cursor: "pointer" }}
                          onClick={() => handleDelete(id)}
                        >
                          <img
                            src={closeIcon2}
                            alt="Icon"
                            style={{ height: "24px", width: "24px" }}
                          />
                        </div>
                      </div>
                    </div>
                  </>
                );
              })}
            </>
          ) : (
            <div className="">No Search History</div>
          )}

          {isViewMore && searchHistory?.length > 0 && (
            <div className="view-more-btn" onClick={() => handleViewMore()}>
              <Link>View more</Link>
            </div>
          )}
        </div>
      </div>
      {
        <DeleteModal
          modal={clearHistoryModel}
          toggle={() => setClearHistoryModel(!clearHistoryModel)}
          title={"Clear History"}
          deleteConfirm={() => handleClear()}
          text={"Are you sure, you want to clear the history?"}
        />
      }
    </div>
  );
}

{
  /* <div className="card-list">
          <div className="d-flex justify-content-between">
            <div className="job-profile">
              Senior Product Designer <span className="new">35 new</span>
              <div className="location">India</div>
            </div>
            <div className="cross-btn">
              <img src={closeIcon2} alt="Icon" />
            </div>
          </div>
        </div>
        <div className="card-list">
          <div className="d-flex justify-content-between">
            <div className="job-profile">
              Senior Product Designer
              <div className="location">India</div>
            </div>
            <div className="cross-btn">
              <img src={closeIcon2} alt="Icon" />
            </div>
          </div>
        </div>
        <div className="card-list">
          <div className="d-flex justify-content-between">
            <div className="job-profile">
              Senior Product Designer
              <div className="location">India</div>
            </div>
            <div className="cross-btn">
              <img src={closeIcon2} alt="Icon" />
            </div>
          </div>
        </div> */
}
