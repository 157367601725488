import React from "react";
import { useState } from "react";
import { useDispatch } from "react-redux";
import DropDowns from "./DropDowns";
import like from "../../../../assets/img/like_1.svg";
import likeGrey from "../../../../assets/img/like_grey.svg";
import hand from "../../../../assets/img/clapping 3.svg";
import heart from "../../../../assets/img/ant-design_heart-filled.svg";
import hat from "../../../../assets/img/hat 1.svg";
import bulb from "../../../../assets/img/idea_1.svg";
import emoj from "../../../../assets/img/smile_1.svg";
import surp from "../../../../assets/img/facebook-reactions_1.svg";
import placeholderUser from "../../../../assets/img/placeholder_user.jpg";
import pin from "../../../../assets/img/pin-grey.png";
import { updateComment } from "../../../../store/dashboard/dashboardActions";
import { useEffect } from "react";
import { toast } from "react-toastify";
import {
  abbreviateNumber,
  autoDetect,
  handleTranslate,
} from "../../../../core/components/Utility/Utility";
import { Spinner } from "reactstrap";

const ReplyData = (props) => {
  const {
    showAllReplies,
    index,
    replies,
    item,
    getTime,
    dropRefReplies,
    setIsReplyDD,
    isReplyDD,
    setDropDownReply,
    setDropDownComm,
    comment,
    user,
    userId,
    setCloseCommentModal,
    closeCommentModal,
    setCommentDeleteId,
    setReportModal,
    reportModal,
    reportType,
    setReportType,
    reportCommentId,
    setReportCommentId,
    setPostID,
    pinCommentToTop,
    UnpinComment,
    setReplyDeleteId,
    setCloseReplyModal,
    closeReplyModal,
    setConfirmBlockModal,
    setBlockCommentId,
    setBlockUserId,
    blockComment,
    dropDownReply,
    confirmBlockModal,
    handleCommentsReaction,
    handleReply,
    handleReplySubmit,
    setShowReplyEmoji,
    setReplyId,
    showReplyEmoji,
    replyBoxStatus,
    setReplyBoxStatus,
    reply,
  } = props;

  console.log(replies?.reaction?.count);
  const [isCommentEdit, setIsCommentEdit] = useState(false);
  const [textReply, setTextReply] = useState(replies?.comment);
  const [editReplyId, setEditReplyId] = useState();
  const [isTranslated, setIsTranslated] = useState();
  const [translatedText, setTraslatedText] = useState();
  const [detectedLanguage, setDetectedLanguage] = useState();
  const dispatch = useDispatch();

  const editComment = (commentData) => {
    setEditReplyId(commentData?.id);
    setIsCommentEdit(!isCommentEdit);
    setDropDownComm(-1);
    setDropDownReply(-1);
  };

  useEffect(() => {
    setTextReply(replies?.comment);
    autoDetect(replies?.comment, setDetectedLanguage);
  }, [replies]);
  useEffect(() => {
    document.getElementById(editReplyId)?.focus();
  }, [editReplyId]);

  const handleEdit = (event, postID, replyId) => {
    let token = window.localStorage.getItem("accessToken");
    if (event.key === "Enter") {
      dispatch(
        updateComment(
          token,
          comment?.id,
          { comment: textReply },
          postID,
          replyId,
          "reply"
        )
      ).then((res) => {
        toast.success(res.message, {
          position: toast.POSITION.BOTTOM_LEFT,
        });
      });
      setIsCommentEdit(false);
    } else {
      setTextReply(event.target.value);
    }
  };

  return (
    <div>
      <div className="reply" key={index}>
        <div className="row commentDiv">
          <div className="col-1">
            <div className="prof-img">
              {replies?.Page ? (
                <img
                  src={
                    replies?.Page?.icon ? replies?.Page?.icon : placeholderUser
                  }
                  alt=""
                />
              ) : (
                <img
                  src={
                    replies?.user?.profile_img
                      ? replies?.user?.profile_img
                      : placeholderUser
                  }
                  alt=""
                />
              )}
            </div>
          </div>
          <div className="col-11" style={{ position: "relative" }}>
            <div
              className="msg-box"
              // style={{ width: "86%" }}
            >
              <div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <div
                    style={{
                      width: "62%",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    {replies?.Page ? (
                      <h5 className="card-control-text-oneline">
                        {replies?.Page?.name}
                      </h5>
                    ) : (
                      <h5 className="card-control-text-oneline">
                        {replies?.user?.first_name} {replies?.user?.last_name}
                      </h5>
                    )}
                    {item?.pinned_comment === replies.id ? (
                      <div className="mb-2 ms-3">
                        <img
                          src={pin}
                          alt=""
                          style={{
                            width: "17px",
                            height: "17px",
                            padding: "2px",
                          }}
                        />
                        <span className="pin-text">Pinned</span>
                      </div>
                    ) : (
                      <></>
                    )}
                  </div>
                  <div className="time">
                    <span>{getTime(replies.createdAt)}</span>
                  </div>
                  <div
                    ref={dropRefReplies}
                    className="threedots"
                    onClick={() => {
                      setIsReplyDD(!isReplyDD);
                      if (dropDownReply === index) {
                        setDropDownReply(-1);
                        setDropDownComm(-1);
                      } else {
                        setDropDownReply(index);
                        setDropDownComm(-1);
                      }
                    }}
                  >
                    <span className="clickDots">&#x2022;</span>
                    <span className="clickDots">&#x2022;</span>
                    <span className="clickDots">&#x2022;</span>
                  </div>
                  <DropDowns
                    dropDownComm={dropDownReply}
                    index={index}
                    comment={comment}
                    user={user}
                    userId={userId}
                    item={item}
                    dropRefComment={dropRefReplies}
                    editComment={editComment}
                    setCloseCommentModal={setCloseCommentModal}
                    closeCommentModal={closeCommentModal}
                    setCommentDeleteId={setCommentDeleteId}
                    setReportModal={setReportModal}
                    reportModal={reportModal}
                    reportType={reportType}
                    setReportType={setReportType}
                    reportCommentId={reportCommentId}
                    setReportCommentId={setReportCommentId}
                    setPostID={setPostID}
                    isReplyDD={isReplyDD}
                    pinCommentToTop={pinCommentToTop}
                    UnpinComment={UnpinComment}
                    replies={replies}
                    setReplyDeleteId={setReplyDeleteId}
                    setCloseReplyModal={setCloseReplyModal}
                    closeReplyModal={closeReplyModal}
                    setDropDownReply={setDropDownReply}
                    confirmBlockModal={confirmBlockModal}
                    setConfirmBlockModal={setConfirmBlockModal}
                    setBlockCommentId={setBlockCommentId}
                    setBlockUserId={setBlockUserId}
                    blockComment={blockComment}
                    setDropDownComm={setDropDownComm}
                  />
                </div>
                {/* <p>{replies.comment}</p> */}
                {isCommentEdit ? (
                  <input
                    className="commentClass"
                    id={editReplyId}
                    value={textReply}
                    style={{
                      border: "none",
                      background: "rgba(238, 240, 242, 0.35)",
                      width: "100%",
                      marginTop: "4px",
                      marginBottom: "8px",
                      wordBreak: "break-all",
                    }}
                    disabled={!isCommentEdit}
                    onBlur={() => setIsCommentEdit(false)}
                    onChange={(event) => setTextReply(event.target.value)}
                    onKeyDown={(e) => {
                      handleEdit(e, item?.id, replies?.id);
                    }}
                  />
                ) : (
                  <div
                    className="commentClass"
                    id={editReplyId}
                    style={{
                      border: "none",
                      background: "rgba(238, 240, 242, 0.35)",
                      marginTop: "4px",
                      marginBottom: "8px",
                      wordBreak: "break-all",
                    }}
                    role="textbox"
                    aria-multiline="true"
                  >
                    {replies.comment}
                  </div>
                )}
                {detectedLanguage != user?.data?.content_language && (
                  <span
                    onClick={() =>
                      handleTranslate(
                        replies.comment,
                        replies.id,
                        setIsTranslated,
                        setTraslatedText,
                        user?.data?.content_language
                      )
                    }
                  >
                    translate
                  </span>
                )}
                {isTranslated === replies.id && (
                  <div
                    className="commentClass"
                    //  id={editCommentId}
                    //  ref={inputComment}
                    style={{
                      border: "none",
                      background: "rgba(238, 240, 242, 0.35)",
                      marginTop: "4px",
                      marginBottom: "8px",
                      wordBreak: "break-all",
                    }}
                    role="textbox"
                    aria-multiline="true"
                  >
                    {translatedText || (
                      <Spinner
                        style={{
                          margin: "0.5rem",
                          height: "1.5rem",
                          width: "1.5rem",
                          border:
                            "var(--bs-spinner-border-width) solid  #0432a3",
                          borderRightColor: "transparent",
                        }}
                      >
                        Loading...
                      </Spinner>
                    )}
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className="flex" style={{ marginLeft: "52px" }}>
            <div className="like_main_comment">
              <div className="likes_comment">
                {replies?.reaction?.user?.type === "Like" ? (
                  <img src={like} alt="" />
                ) : (
                  <img
                    src={like}
                    alt=""
                    onClick={() => {
                      handleCommentsReaction(
                        replies,
                        "Like",
                        "reply",
                        comment?.id
                      );
                    }}
                  />
                )}
                {replies?.reaction?.byUser?.type === "Love" ? (
                  <img src={heart} alt="" />
                ) : (
                  <img
                    src={heart}
                    alt=""
                    onClick={() => {
                      handleCommentsReaction(
                        replies,
                        "Love",
                        "reply",
                        comment?.id
                      );
                    }}
                  />
                )}
                {replies?.reaction?.byUser?.type === "Respect" ? (
                  <img src={hat} alt="" />
                ) : (
                  <img
                    src={hat}
                    alt=""
                    onClick={() => {
                      handleCommentsReaction(
                        replies,
                        "Respect",
                        "reply",
                        comment?.id
                      );
                    }}
                  />
                )}
                {replies?.reaction?.byUser?.type === "Celebrate" ? (
                  <img src={hand} alt="" />
                ) : (
                  <img
                    src={hand}
                    alt=""
                    onClick={() => {
                      handleCommentsReaction(
                        replies,
                        "Celebrate",
                        "reply",
                        comment?.id
                      );
                    }}
                  />
                )}
                {replies?.reaction?.byUser?.type === "Insightful" ? (
                  <img src={bulb} alt="" />
                ) : (
                  <img
                    src={bulb}
                    alt=""
                    onClick={() => {
                      handleCommentsReaction(
                        replies,
                        "Insightful",
                        "reply",
                        comment?.id
                      );
                    }}
                  />
                )}
                {replies?.reaction?.byUser?.type === "Curious" ? (
                  <img src={emoj} alt="" />
                ) : (
                  <img
                    src={emoj}
                    alt=""
                    onClick={() => {
                      handleCommentsReaction(
                        replies,
                        "Curious",
                        "reply",
                        comment?.id
                      );
                    }}
                  />
                )}
                {replies?.reaction?.user?.type === "Surprise" ? (
                  <img src={surp} alt="" />
                ) : (
                  <img
                    src={surp}
                    alt=""
                    onClick={() => {
                      handleCommentsReaction(
                        replies,
                        "Surprise",
                        "reply",
                        comment?.id
                      );
                    }}
                  />
                )}
              </div>

              {item?.as_a_page ? (
                replies.reaction.byUsers?.find(
                  ({ page_id }) => page_id === item?.as_a_page?.id
                ) ? (
                  replies?.reaction?.byUsers.map((reactionDel) =>
                    // console.log(item?.as_a_page?.id, reactionDel?.page_id)
                    item?.as_a_page?.id === reactionDel?.page_id ? (
                      reactionDel?.type === "Like" ? (
                        <span
                          onClick={() =>
                            handleCommentsReaction(
                              replies,
                              "Like",
                              "reply",
                              comment?.id,
                              "deleteReaction"
                            )
                          }
                          style={{ color: "#0432A3" }}
                        >
                          <img src={like} alt="" /> Like
                        </span>
                      ) : reactionDel?.type === "Celebrate" ? (
                        <span
                          onClick={() =>
                            handleCommentsReaction(
                              replies,
                              "Celebrate",
                              comment?.id,
                              "deleteReaction"
                            )
                          }
                          style={{ color: "#198754" }}
                        >
                          <img src={hand} alt="" /> Celebrate
                        </span>
                      ) : reactionDel?.type === "Curious" ? (
                        <span
                          onClick={() =>
                            handleCommentsReaction(
                              replies,
                              "Curious",
                              comment?.id,
                              "deleteReaction"
                            )
                          }
                          style={{ color: "#ffc107" }}
                        >
                          <img src={emoj} alt="" /> Curious
                        </span>
                      ) : reactionDel?.type === "Love" ? (
                        <span
                          onClick={() =>
                            handleCommentsReaction(
                              replies,
                              "Love",
                              comment?.id,
                              "deleteReaction"
                            )
                          }
                          style={{ color: "#dc3545" }}
                        >
                          <img src={heart} alt="" /> Love
                        </span>
                      ) : reactionDel?.type === "Respect" ? (
                        <span
                          onClick={() =>
                            handleCommentsReaction(
                              replies,
                              "Respect",
                              comment?.id,
                              "deleteReaction"
                            )
                          }
                          style={{ color: "#DF6B00" }}
                        >
                          <img src={hat} alt="" /> Respect
                        </span>
                      ) : reactionDel?.type === "Insightful" ? (
                        <span
                          onClick={() =>
                            handleCommentsReaction(
                              replies,
                              "Insightful",
                              comment?.id,
                              "deleteReaction"
                            )
                          }
                          style={{ color: "#3F2DAF" }}
                        >
                          <img src={bulb} alt="" /> Insightful
                        </span>
                      ) : reactionDel?.type === "Surprise" ? (
                        <span
                          onClick={() =>
                            handleCommentsReaction(
                              replies,
                              "Surprise",
                              comment?.id,
                              "deleteReaction"
                            )
                          }
                          style={{ color: "#ffc107" }}
                        >
                          <img src={surp} alt="" /> Surprise
                        </span>
                      ) : comment?.reaction?.byUsers === null ? (
                        <span>
                          <img src={likeGrey} alt="" /> <span>Like</span>
                        </span>
                      ) : (
                        <></>
                      )
                    ) : (
                      <></>
                    )
                  )
                ) : (
                  <span>
                    <img src={likeGrey} alt="" /> <span>Like</span>
                  </span>
                )
              ) : replies?.reaction?.user?.type === "Like" ? (
                <span
                  onClick={() =>
                    handleCommentsReaction(
                      replies,
                      "Like",
                      "deleteReactionReply",
                      comment?.id
                    )
                  }
                  style={{ color: "#0432A3" }}
                >
                  <img src={like} alt="" /> Like
                </span>
              ) : replies?.reaction?.user?.type === "Celebrate" ? (
                <span
                  onClick={() =>
                    handleCommentsReaction(
                      replies,
                      "Celebrate",
                      "deleteReactionReply",
                      comment?.id
                    )
                  }
                  style={{ color: "#198754" }}
                >
                  <img src={hand} alt="" /> Celebrate
                </span>
              ) : replies?.reaction?.user?.type === "Curious" ? (
                <span
                  onClick={() =>
                    handleCommentsReaction(
                      replies,
                      "Curious",
                      "deleteReactionReply",
                      comment?.id
                    )
                  }
                  style={{ color: "#ffc107" }}
                >
                  <img src={emoj} alt="" /> Curious
                </span>
              ) : replies?.reaction?.user?.type === "Love" ? (
                <span
                  onClick={() =>
                    handleCommentsReaction(
                      replies,
                      "Love",
                      "deleteReactionReply",
                      comment?.id
                    )
                  }
                  style={{ color: "#dc3545" }}
                >
                  <img src={heart} alt="" /> Love
                </span>
              ) : replies?.reaction?.user?.type === "Respect" ? (
                <span
                  onClick={() =>
                    handleCommentsReaction(
                      replies,
                      "Respect",
                      "deleteReactionReply",
                      comment?.id
                    )
                  }
                  style={{ color: "#dc3545" }}
                >
                  <img src={hat} alt="" /> Respect
                </span>
              ) : replies?.reaction?.user?.type === "Insightful" ? (
                <span
                  onClick={() =>
                    handleCommentsReaction(
                      replies,
                      "Insightful",
                      "deleteReactionReply",
                      comment?.id
                    )
                  }
                  style={{ color: "#3F2DAF" }}
                >
                  <img src={bulb} alt="" /> Insightful
                </span>
              ) : replies?.reaction?.user?.type === "Surprise" ? (
                <span
                  onClick={() =>
                    handleCommentsReaction(
                      replies,
                      "Surprise",
                      "deleteReactionReply",
                      comment?.id
                    )
                  }
                  style={{ color: "#ffc107" }}
                >
                  <img src={surp} alt="" /> Surprise
                </span>
              ) : replies?.reaction?.user === null ? (
                <span>Like</span>
              ) : (
                <span>Like</span>
              )}
              {replies?.reaction?.count > 0 && (
                <span style={{ fontSize: "12px", marginLeft: "5px" }}>
                  ({abbreviateNumber(replies?.reaction?.count)})
                </span>
              )}
            </div>
            <div className="reply curserPointer">
              <span
                onClick={() => {
                  setReplyBoxStatus((prevState) => [
                    ...prevState,
                    { id: comment?.id, status: true },
                  ]);
                  document.getElementById(`replyinput${comment?.id}`)?.focus();
                }}
              >
                Reply
              </span>
            </div>
            {/* {replies?.user?.id === user?.data?.id && (
                        <div className="reply curserPointer">
                          <span
                            onClick={() => {
                              setCloseReplyModal(!closeReplyModal);
                              setReplyDeleteId(replies.id);
                              setCommentDeleteId(comment.id);
                            }}
                          >
                            <img src={deleteIcon} alt="del_icon" />
                          </span>
                        </div>
                      )} */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ReplyData;
