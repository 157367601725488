import { reactionApi } from "../../api/reactionAPI";

import {
    REACTION_POST,REACTION_POST_FAIL,GET_REACTION_POST,GET_REACTION_POST_FAIL
  } from "../types";
  import { likeReactionData, likeReactionCommentData } from "../dashboard/dashboardActions";
import { toast } from "react-toastify";

  export const reactionsData = (data, type) => (dispatch) => {
    return reactionApi.reactionsData(data).then(
      (res) => {
        dispatch(likeReactionData(data, type)
        );
  
        return Promise.resolve(res);
      },
      (error) => {
        console.log(error);
        toast.error(error.response.data.message, {
          position: toast.POSITION.BOTTOM_LEFT,
        });
        dispatch({
          type: REACTION_POST_FAIL,
        });
        return Promise.reject(error);
      }
    );
  };

  export const getAllReactions = (data, type) => (dispatch) => {
    return reactionApi.getAllReactions(data,type).then(
      (res) => {
        dispatch({
          type: REACTION_POST,
          payload: data,
        });
  
        return Promise.resolve(res);
      },
      (error) => {
        console.log(error);
        dispatch({
          type: REACTION_POST_FAIL,
        });
        return Promise.reject(error);
      }
    );
  };
 

  export const reactionsCommentData = (data, id, type, commentId) => (dispatch) => {
    return reactionApi.reactionsCommentsData(data).then(
      (res) => {
        dispatch(likeReactionCommentData(data, id, type, commentId)
        );
  
        return Promise.resolve(res);
      },
      (error) => {
        console.log(error);
        dispatch({
          type: REACTION_POST_FAIL,
        });
        return Promise.reject(error);
      }
    );
  };
