import React, { useState } from "react";
import NpMailNotificationModal from "./NpMailNotificationModal";

const NpMailNotification = ({ notifications }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const npmails = notifications.filter((ele) => ele.type == "user");
  const handleToggle = () => {
    setIsModalOpen(!isModalOpen);
  };

  return (
    <div
      className="np-mail-side"
      style={{
        boxShadow: "0px 2px 4px rgba(171, 190, 209, 0.6)",
        marginTop: "10px",
        borderRadius: "5px",
      }}
    >
      <div className="bg-white mt-26">
        <h2 className="p-20" style={{ marginLeft: "5px" }}>
          NPMail ({npmails.length})
        </h2>
        <div className="line"></div>
        <div className="details p-20">
          {npmails.length < 4 &&
            npmails.map((notification, index) => (
              <div
                className="row mb-2"
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-around",
                }}
                key={index}
              >
                <div className="col-lg-3 col-md-1 col-sm-1 col-3">
                  <div className="profile-img">
                    <img
                      src={notification?.User?.profile_img}
                      className="image-fit"
                      alt=""
                    />
                  </div>
                </div>
                <div className="col-lg-9 col-9">
                  <h3 className="truncate-notification">{`${notification?.User?.first_name} ${notification?.User?.last_name}`}</h3>
                  <p>Sent you a NPMail</p>
                </div>
              </div>
            ))}

          {npmails.length > 3 && (
            <div className="text-center">
              <button onClick={handleToggle}>
                See more <i className="arrow down"></i>
              </button>
              {/* <ShortlistModal modal={isModalOpen} toggle={handleToggle}/> */}
              <NpMailNotificationModal
                modal={isModalOpen}
                toggle={handleToggle}
                notifications={notifications}
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default NpMailNotification;
