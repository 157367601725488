import React from "react";
import { useNavigate } from "react-router-dom";
import placeholderUser from "../../../../assets/img/placeholder_user.jpg";
import add from "../../../../assets/img/add-icon.svg";
import users from "../../../../assets/img/users.svg";
import "./PeopleYouMayKnow.scss";

export default function PeopleYouMayKnow(props) {
  const { suggested_people, sendRequest } = props;

  const navigate = useNavigate();

  return (
    <div className="people-know">
      <h6>People you may know</h6>
      {suggested_people?.map((data, index) => {
        if (index < 4) {
          return (
            <div className="d-plex mb-3" key={index}>
              <div className="row">
                <div className="col-9">
                  <div className="d-flex ">
                    <div
                      className="img"
                      onClick={() => navigate(`/profile/${data?.id}`)}
                    >
                      <img
                        src={
                          data?.profile_img
                            ? data?.profile_img
                            : placeholderUser
                        }
                        alt=""
                      />
                    </div>
                    <div className="div">
                      <h6
                        onClick={() => navigate(`/profile/${data?.id}`)}
                        className="card-control-text-twoline"
                      >
                        {data?.first_name} {data?.last_name}
                      </h6>
                      <p className="card-control-text-oneline">
                        {data?.profile?.profile_headline}
                      </p>
                    </div>
                  </div>
                </div>
                {data?.connection_status === "Pending" ? (
                  <div className="col-2">
                    <div className="plus">
                      <img src={users} alt="connection" />
                    </div>
                  </div>
                ) : (
                  <div className="col-3">
                    <div className="plus" onClick={() => sendRequest(data?.id)}>
                      <img src={add} alt="addIcon" />
                    </div>
                  </div>
                )}
              </div>
            </div>
          );
        }
        return <></>;
      })}

      <div className="btn btn-light mt-1 text-blue d-block">
        <span style={{ fontSize: "15px" }} onClick={() => navigate("/peoples")}>
          See all
        </span>
      </div>
    </div>
  );
}
