import { call } from "./apiCall";

class RecuriterSearchAPI {
  getRecuriterSearchList(query) {
    let token = localStorage.getItem("accessToken");
    return new Promise((resolve, reject) => {
      (async () => {
        try {
          const res = await call(
            "get",
            `api/v1/job/search-candidate`,
            query,
            null,
            token
          );
          // if (res.data.success === true) {
          resolve(res.data);
          // }
          //   if (res.data.success === false) {
          //     resolve(res.data);
          //   }
        } catch (err) {
          console.log(err);
          reject(err);
        }
      })();
    });
  }

  reequestCandidateDetails(data) {
    let token = localStorage.getItem("accessToken");
    return new Promise((resolve, reject) => {
      (async () => {
        try {
          const res = await call(
            "post",
            `api/v1/job/view-candidate`,
            null,
            data,
            token
          );
          if (res.data.success === true) {
            resolve(res.data);
          }
          //   if (res.data.success === false) {
          //     resolve(res.data);
          //   }
        } catch (err) {
          console.log(err);
          reject(err);
        }
      })();
    });
  }

  getShortListCounts() {
    let token = localStorage.getItem("accessToken");
    return new Promise((resolve, reject) => {
      (async () => {
        try {
          const res = await call(
            "get",
            `api/v1/feed/feed-count?type=shortlist`,
            null,
            null,
            token
          );
          if (res.data.success === true) {
            resolve(res.data);
          }
          if (res.data.success === false) {
            resolve(res.data);
          }
        } catch (err) {
          console.log(err);
          reject(err);
        }
      })();
    });
  }
}

export const recuriterSearchAPI = new RecuriterSearchAPI();
