import React from "react";
import {
  Button,
  Form,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "reactstrap";
import { toast } from "react-toastify"; // Import toast from react-toastify
import "../../Recruiter/RecruiterModals/RecruiterModals.scss";
import ellipse1 from "../../../../assets/img/Ellipse-1.png";
import orgLogoSmall from "../../../../assets/img/orgLogoSmall.png";
import mastercardSmall from "../../../../assets/img/mastercardSmall.png";
import visacrdSmall from "../../../../assets/img/visacrdSmall.png";
import editPencil from "../../../../assets/img/edit-pencil.svg";
import eye from "../../../../assets/img/eye.svg";
import checkMark from "../../../../assets/img/check-mark.png";
import deleteLeadIcon from "../../../../assets/img/deleteLeadIcon.svg";
import { useNavigate } from "react-router-dom";
import { SHORTLIST_CANDIDATE_FOR_JOBS } from "../../../../store/types";
import { useDispatch } from "react-redux";

const PaymentForShortlist = (props) => {
  const {
    modal,
    toggle,
    requestCandidateData,
    setCheckAll,
    handleAllUncheck,
    shortlistedFor,
  } = props;
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const confirmAndPay = () => {
    // Check if any payment card is selected
    const paymentCardSelected = document.querySelector(
      'input[type="radio"]:checked'
    );
    if (!paymentCardSelected) {
      // If no card is selected, show toast message
      toast.error("Please select a payment method.");
      return; // Exit function early
    }

    // If a card is selected, proceed with confirming and paying
    requestCandidateData();
    toggle();
    setCheckAll(false);
    handleAllUncheck();
    //navigate("/shortlist/candidates");
    dispatch({
      type: SHORTLIST_CANDIDATE_FOR_JOBS,
    });
  };

  return (
    <Modal
      isOpen={modal}
      toggle={toggle}
      size="md"
      style={{ maxWidth: "544px" }}
      centered
    >
      <div className="modal-radio-payment default-recruiter-modal">
        <ModalHeader toggle={toggle}>Payment</ModalHeader>
        <ModalBody>
          <p>
            To view {shortlistedFor?.length} Candidates Profile Please pay{" "}
            <span>${shortlistedFor?.length}</span>
          </p>
          <div className="mt-4 radio-outer">
            <h5>Payment Method</h5>
            <Form>
              <div className="form-box">
                <Label>
                  <div className="radio-inner d-flex justify-content-between">
                    <div className="d-flex">
                      <Input
                        type="radio"
                        name="radio"
                        style={{ backgroundColor: "none" }}
                      />
                      <div className="end-code">
                        Ending in: ...6372
                        <span class="exp-date">Exp. date: 12/22</span>
                      </div>
                    </div>
                    <img src={visacrdSmall} alt="" />
                  </div>
                </Label>
                <Label>
                  <div className="radio-inner d-flex justify-content-between">
                    <div className="d-flex">
                      <Input
                        type="radio"
                        name="radio"
                        style={{ backgroundColor: "none" }}
                      />
                      <div className="end-code">
                        Ending in: ...6372
                        <span class="exp-date">Exp. date: 12/22</span>
                      </div>
                    </div>
                    <img src={mastercardSmall} alt="" />
                  </div>
                </Label>
              </div>
              <div className="btn-outer text-center mt-4">
                <div className="btn btn-blue" onClick={confirmAndPay}>
                  Confirm & Pay Now
                </div>
              </div>
            </Form>
          </div>
        </ModalBody>
      </div>
    </Modal>
  );
};

export default PaymentForShortlist;
