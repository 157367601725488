import React, { useState, useEffect } from "react";
import { Modal, Input, ModalHeader } from "reactstrap";
import EditorBox from "./Editor";
import "../newsletter.scss";
//import "../../Articles/ArticleDetails/ArticleDetails.scss";
import NewsletterPreviewModal from "./newsletterPreview";
import { editNewsletter } from "../../../../store/newsletter/newsletterActions";
import { createNewsletter } from "../../../../store/newsletter/newsletterActions";
import { useDispatch, useSelector } from "react-redux";
import Dropzone from "../../Articles/components/DragNDrop";
import axios from "axios";
import Loader from "../../../../core/components/Loaders/ComponentLoader";
import { getIndustryData } from "../../../../store/master/masterActions";
import Autocomplete from "react-autocomplete";
import DiscardNewsletterModal from "./DiscardModal";
import ShareModal from "../../../../core/components/sharePost/sharePost";
import { toast } from "react-toastify";
import AppLoader from "../../../../core/components/Loaders/appLoader/appLoader";
import Navbar from "../../../../pages/navbar/navbar";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import Editor from "ckeditor5-custom-build/build/ckeditor";
import placeholderUser from "../../../../assets/img/placeholder_user.jpg";
import NewsletterSuccessfulModal from "./newsletterSuccessfulModal";

const CreateNewsletter = (props) => {
  const {
    modal,
    toggle,
    title,
    editNewsletterData,
    setEditNewsletterData,
    successfulStatus,
    source,
    pageName,
    createdAt,
    pageID,
    pageIcon,
  } = props;

  const token = localStorage.getItem("accessToken");

  const { isLoading } = useSelector((state) => state.loader);
  const { user } = useSelector((state) => state.auth);
  const { industryData } = useSelector((state) => state.master);
  const [convertedContent, setConvertedContent] = useState(null);
  const [openShareModal, setOpenShareModal] = useState(false);
  const [shareData, setShareData] = useState([]);
  const [previewModal, setPreviewModal] = useState(false);
  const [textLength, setTextLength] = useState(
    editNewsletterData?.title ? editNewsletterData.title.split(" ").length : 0
  );
  const [dbFormat, setDbFormat] = useState(
    editNewsletterData?.content ? editNewsletterData?.content : ""
  );
  const [bannerImage, setBannerImage] = useState(
    editNewsletterData ? editNewsletterData?.media : ""
  );
  const [hasText, setHasText] = useState(false);
  const [onlySpaces, setOnlySpaces] = useState(false);
  const [formStatus, setFormStatus] = useState(false);
  const [successModal, setSuccessModal] = useState(false);
  const [popupShareData, setPopupShareData] = useState("");
  const [industryInput, setIndustryInput] = useState(
    editNewsletterData ? editNewsletterData?.industry : ""
  );
  const [discardModalStatus, setDiscardModalStatus] = useState(false);

  const [newsletterData, setNewsletterData] = useState(
    editNewsletterData
      ? {
          id: editNewsletterData?.id,
          title: editNewsletterData?.title,
          industry: editNewsletterData?.industry,
          content: "",
          media: editNewsletterData?.media,
          privacy_id: 1,
          hashtags: ["string"],
        }
      : {
          title: "",
          industry: "",
          content: "",
          media: "",
          privacy_id: 1,
          hashtags: ["string"],
        }
  );

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getIndustryData());
  }, []);

  function wordLimit(limit) {
    var val = newsletterData.title;
    var words = val.split(/\s+/);
    var legal = "";
    for (let i = 0; i < words.length; i++) {
      if (i < limit) {
        legal += words[i] + " ";
      }
      if (i >= limit) {
        setNewsletterData({ ...newsletterData, title: legal.trim() });
      }
    }
  }
  function getWordCount(str) {
    return str.split(" ").filter(function (n) {
      return n != "";
    }).length;
  }

  useEffect(() => {
    checkValidation();
  }, [newsletterData.title, newsletterData.industry, hasText, onlySpaces]);

  const checkValidation = () => {
    setFormStatus(true);
    if (!newsletterData.title.trim()) {
      setFormStatus(false);
    }
    // if (!newsletterData.industry.trim()) {
    //   setFormStatus(false);
    // }
    if (!hasText) {
      setFormStatus(false);
    }

    if (onlySpaces) {
      setFormStatus(false);
    }
  };

  const publishNewsletter = () => {
    let newDataFormat = {
      title: newsletterData.title,
      industry: newsletterData.industry,
      content: convertedContent,
      media: bannerImage,
      newsletter_status: "published",
      privacy_id: 1,
      hashtags: ["string"],
    };

    setShareData(newDataFormat);

    if (source === "network" || !source) {
      if (newsletterData.id) {
        newDataFormat.id = newsletterData.id;
        dispatch(editNewsletter(newDataFormat, token))
          .then(() => {
            toast.success("Updated Successfully.", {
              position: toast.POSITION.BOTTOM_LEFT,
            });
            toggle();
          })
          .catch((e) => {});
      } else {
        dispatch(createNewsletter(newDataFormat, token))
          .then((data) => {
            setPopupShareData(data);
            setSuccessModal(!successModal);
          })
          .catch((e) => {});
      }
    } else if (source === "home") {
      setOpenShareModal(true);
    }
  };

  const handleBannerPicture = async (file) => {
    console.log(file);
    try {
      const UPLOAD_URL = `${process.env.REACT_APP_API_URL}api/v1/upload`;
      const formData = new FormData();
      const headers = {
        "Accept-Language": "en",
        "content-type": "multipart/form-data",
      };

      formData.append("image", file);

      await axios.post(UPLOAD_URL, formData, { headers }).then((res) => {
        setBannerImage(res?.data?.urlsArray[0]);
        console.log(res?.data?.urlsArray[0]);
      });
    } catch (err) {
      console.log(err);
    }
  };

  const deletePicture = () => {
    setBannerImage("");
  };

  const checkDiscardChanges = () => {
    if (
      newsletterData?.title ||
      newsletterData?.content ||
      newsletterData?.industry
    ) {
      return true;
    } else return false;
  };

  const closeModal = () => {
    if (createdAt === "page") {
      toggle();
    }
    if (editNewsletterData) {
      setEditNewsletterData("");
    }
    console.log(checkDiscardChanges());
    if (!editNewsletterData && checkDiscardChanges()) {
      setDiscardModalStatus(true);
    } else {
      toggle();
    }
  };

  const closeAllModal = () => {
    setDiscardModalStatus(!discardModalStatus);
    toggle();
  };

  const saveDraft = () => {
    const newDataFormat = {
      title: newsletterData.title,
      industry: newsletterData.industry,
      content: dbFormat,
      media: bannerImage,
      privacy_id: 1,
      // hashtags:hashTagData?.map(s => s.slice(1))
    };

    newDataFormat.newsletter_status = "draft";
    dispatch(createNewsletter(newDataFormat, token))
      .then(() => {
        setDiscardModalStatus(!discardModalStatus);
        toggle();
      })
      .catch((e) => {});
  };

  useEffect(() => {
    const handleScroll = () => {
      const fixEditor = document.querySelectorAll(".create-newsletter");
      const editorControler = document.querySelectorAll(".ck-editor__top");
      let scrollPosition = window.scrollY;
      fixEditor.forEach((editor) => {
        if (scrollPosition > 250) {
          editor.classList.add("sticky-header");
        } else {
          editor.classList.remove("sticky-header");
        }
      });
      editorControler.forEach((controller) => {
        controller.style.transition = "top 0.05s";
        if (window.innerWidth > 767) {
          if (scrollPosition >= 0 && scrollPosition <= 250) {
            controller.style.top = `${125 - scrollPosition}px`;
          } else {
            controller.style.top = "20px";
          }
        }
        if (window.innerWidth < 767) {
          if (scrollPosition >= 0 && scrollPosition <= 250) {
            controller.style.top = `${180 - scrollPosition}px`;
          } else {
            controller.style.top = "80px";
          }
        }
      });
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <>
      {isLoading && <AppLoader />}
      <Navbar isJobs={false} />
      <div className="main-center mt-1 pt-4 bg-white">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="create-newsletter">
                <div className="wrapper-class px-0">
                  <div className="fixHeaderBox">
                    <div className="row justify-content-between align-items-center">
                      <div className="col-10">
                        <span
                          style={{
                            display: "flex",
                            alignItems: "center",
                            gap: "10px",
                          }}
                        >
                          <img
                            src={
                              user?.data?.profile_img
                                ? user?.data?.profile_img
                                : placeholderUser
                            }
                            className="image-fit"
                            alt=""
                            style={{ width: "36px", height: "36px" }}
                          />
                          <h6 className="name card-control-text-oneline">
                            {user?.data?.first_name} {user?.data?.last_name}
                          </h6>
                        </span>
                      </div>
                      <div className="col-2 d-flex justify-content-end">
                        {formStatus ? (
                          <div
                            className="btn btn-blue d-block"
                            style={{
                              width: "100px",
                              height: "34px",
                              padding: "7px 19px",
                            }}
                            onClick={publishNewsletter}
                          >
                            {
                              // source === "home"
                              //   ? "Next Step"
                              //   : editNewsletterData
                              //   ? "Next"
                              //   :
                              "Next"
                            }
                          </div>
                        ) : (
                          <div
                            className="btn disabled-btn-blue d-block"
                            style={{
                              width: "100px",
                              height: "34px",
                              padding: "7px 19px",
                            }}
                          >
                            {
                              // source === "home"
                              //   ? "Next Step"
                              //   : editNewsletter
                              //   ? "Next"
                              //   :
                              "Next"
                            }
                          </div>
                        )}
                      </div>
                    </div>
                  </div>

                  <span className="article-imagebox">
                    <Dropzone
                      handleBannerPicture={handleBannerPicture}
                      bannerImage={bannerImage}
                      deletePicture={deletePicture}
                    />
                  </span>
                  {/* <div className="autocomplete-wrapper">
                  <Autocomplete
                    inputProps={{ placeholder: "Industry" }}
                    value={industryInput}
                    items={industryData}
                    getItemValue={(item) => item.name}
                    shouldItemRender={(item, value) =>
                      item.name.toLowerCase().indexOf(value.toLowerCase()) > -1
                    }
                    renderMenu={(items, value) => (
                      <div className="dropdown">
                        {items.length === 0 ? `No matches for ${value}` : items}
                      </div>
                    )}
                    renderItem={(item, isHighlighted) => (
                      <div
                        className={`item ${
                          isHighlighted ? "selected-item" : ""
                        }`}
                      >
                        {item.name}
                      </div>
                    )}
                    onChange={(event, val) => setIndustryInput(val)}
                    onSelect={(val, obj) => {
                      setNewsletterData({ ...newsletterData, industry: val });
                      setIndustryInput(val);
                    }}
                    wrapperStyle={{}}
                  />
                </div> */}
                  <div className="dropzone writenTextBox">
                    <div style={{ marginBottom: "20px" }}></div>
                    <Input
                      className="input-type"
                      type="text"
                      style={{ border: "none", fontSize: "30px" }}
                      value={newsletterData.title}
                      onKeyUp={wordLimit(20)}
                      placeholder="Add Title*"
                      onChange={(e) => {
                        setNewsletterData({
                          ...newsletterData,
                          title: e.target.value,
                        });
                        setTextLength(
                          getWordCount(e.target.value) > 20
                            ? 20
                            : getWordCount(e.target.value)
                        );
                      }}
                    />
                    {/* <p className="title-count">{textLength}/20</p> */}
                    <div className="create-newsLetter">
                      {/* <EditorBox
                  convertedContent={convertedContent}
                  setConvertedContent={setConvertedContent}
                  setDbFormat={setDbFormat}
                  setHasText={setHasText}
                  setOnlySpaces={setOnlySpaces}
                  dbFormat={dbFormat}
                /> */}
                      <CKEditor
                        editor={Editor}
                        config={{ placeholder: "Write Here..." }}
                        onReady={(editor) => {
                          editor.editing.view.change((writer) => {
                            writer.setStyle(
                              "width",
                              "100%",
                              editor.editing.view.document.getRoot()
                            );
                            writer.setStyle(
                              "height",
                              "500px",
                              editor.editing.view.document.getRoot()
                            );
                          });
                          // You can store the "editor" and use when it is needed.
                          console.log("Editor is ready to use!", editor);
                        }}
                        onChange={(event, editor) => {
                          setConvertedContent(editor.getData());
                          setHasText(true);
                        }}
                        onBlur={(event, editor) => {
                          console.log("Blur.", editor);
                        }}
                        onFocus={(event, editor) => {
                          console.log("Focus.", editor);
                        }}
                        onInit={(editor) => {
                          editor.editing.view.change((writer) => {
                            writer.setStyle(
                              "height",
                              "500px",
                              editor.editing.view.document.getRoot()
                            );
                          });
                        }}
                      />
                    </div>
                  </div>
                  <div className="preview-button">
                    {" "}
                    <span
                      className="preview"
                      onClick={() => setPreviewModal(!previewModal)}
                    >
                      Preview
                    </span>
                  </div>
                  {/* <div className="row mt-30 mb-20">
                  <div className="col-12">
                    {formStatus ? (
                      <div
                      className="btn btn-blue d-block" style={{
                        width: "10%",
                        float: "right",
                        height: "34px",
                        marginTop: "-728px",
                        padding: "7px 19px",
                      }}
                        onClick={publishNewsletter}
                      >
                        {source === "home"
                          ? "Next Step"
                          : editNewsletterData
                          ? "Next"
                          : "Next"}
                      </div>
                    ) : (
                      <div className="btn disabled-btn-blue d-block" style={{
                        width: "10%",
                        float: "right",
                        marginTop: "-728px",
                        height: "34px",
                        padding: "7px 19px" ,
                      }}>
                        {source === "home"
                          ? "Next Step"
                          : editNewsletterData
                          ? "Next"
                          : "Next"}
                      </div>
                    )}
                  </div>
                </div> */}
                </div>
              </div>
            </div>
          </div>
          {previewModal && (
            <NewsletterPreviewModal
              modal={previewModal}
              toggle={() => setPreviewModal(!previewModal)}
              title="Preview Newsletter"
              convertedContent={convertedContent}
              newsletterData={newsletterData}
              bannerImage={bannerImage}
              pageIcon={pageIcon}
              pageName={pageName}
            />
          )}
          {discardModalStatus && (
            <DiscardNewsletterModal
              modal={discardModalStatus}
              toggle={closeAllModal}
              close={closeAllModal}
              title="Discard Changes."
              saveDraft={saveDraft}
            />
          )}
          {openShareModal && (
            <ShareModal
              modal={openShareModal}
              toggle={() => setOpenShareModal(!openShareModal)}
              title="Share"
              type="newsletter"
              shareData={shareData}
              source="home"
              toggleCase={toggle}
              atriclePageName={pageName}
              createdAt={createdAt}
              pageID={pageID}
              pageIcon={pageIcon}
            />
          )}
          {successModal && (
            <NewsletterSuccessfulModal
              modal={successModal}
              toggle={() => setSuccessModal(!successModal)}
              title=""
              shareData={popupShareData}
            />
          )}
        </div>
      </div>
    </>
  );
};

export default CreateNewsletter;
