import React from "react";
import moment from "moment/moment";
import { useNavigate } from "react-router-dom";

function RelatedExplores(props) {
  const { title, thumbnail_url, createdAt,index,id } = props.relatedExploresData;

  const navigate = useNavigate()

  return (
    <div className="col-lg-4">
     <div className="thumbnail">
        <div className="img">
          <img src={thumbnail_url} className="img-fluid" alt="" />
        </div>
        <div className="p-3">
          <div className="text" onClick={()=>{navigate(`/exploredetails?id=${id}`);navigate(0)}}>
            <h6>{title}</h6>
          </div>
          <div className="main-texts mt-2">
            <p>{moment(createdAt).format("MMMM Do YYYY")}</p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default RelatedExplores;
