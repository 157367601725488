import { combineReducers } from "redux";
import auth from "./auth/authReducers";
import message from "./auth/messageReducers";
import profile from "./profile/profileReducers";
import setting from "./setting/settingReducers";
import blog from "./blog/blogReducers";
import dashboard from "./dashboard/dashboardReducers";
import reactions from "./reactionPost/reactionReducer";
import newsletter from "./newsletter/newsletterReducers";
import getAllUsers from "./getAllUsers/allUserReducer";
import connection from "./connections/connectionsReducer";
import activity from "./activity/activityReducers";
import pages from "./pages/pagesReducer";
import events from "./events/eventReducers";
import groups from "./groups/groupsReducers";
import loader from "./loader/loaderReducers";
import article from "./articles/articleReducers";
import master from "./master/masterReducers";
import people from "./people/peopleReducers";
import hashtag from "./hashtag/hashtagReducers";
import recommendation from "./recommendation/recommendationReducers";
import help from "./help/helpReducer";
import directory from "./directory/directoryReducer";
import campaign from "./campaign/campaignReducer";
import leads from "./leads/leadsReducers";
import wallet from "./wallet/walletReducers";
import membership from "./membership/membershipReducers";
import blockedUsers from "./blockUserList/blockedUsersReducers";
import recuriter from "./recuriterSearch/recuriterReducer";
import memberList from "./memberGroup/memberReducer";
import jobs from "./jobs/jobReducer";
import portal from "./portal/portalReducer";
import talentPool from "./talentPool/talentPoolReducer";
import notification from "./notification/notificationReducer";
import backgroundScreen from "./backgroundScreening/backgroundScreeningReducer";
import chat from "./message/messageReducers";
import xmpp from "./message/xmppReducers";
import cv from "./cv/cvReducers";

import landingPage from "./landing/landingReducer";
export default combineReducers({
  auth,
  message,
  loader,
  profile,
  setting,
  blog,
  dashboard,
  reactions,
  newsletter,
  article,
  hashtag,
  events,
  getAllUsers,
  activity,
  connection,
  pages,
  groups,
  master,
  people,
  recommendation,
  help,
  directory,
  campaign,
  leads,
  wallet,
  membership,
  blockedUsers,
  memberList,
  jobs,
  recuriter,
  portal,
  talentPool,
  notification,
  backgroundScreen,
  chat,
  xmpp,
  landingPage,
  cv,
});
