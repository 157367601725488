import React, { useState, useEffect, useRef } from "react";
import lea from "../../../../assets/img/lead.svg";
import veri from "../../../../assets/img/verify.svg";
import res from "../../../../assets/img/resume.svg";
import cam from "../../../../assets/img/campa.svg";
import bad from "../../../../assets/img/badge.svg";
import workerpool from "../../../../assets/img/WorkerPoolFigmaUpdated.svg";
import questionary from "../../../../assets/img/questionary-icon.svg";
import { useDispatch, useSelector } from "react-redux";
// import { getSinglMember } from "../../../store/directory/directoryAction";
import { getSinglMember } from "../../../../store/directory/directoryAction";

import {
  Button,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  FormGroup,
  Input,
  Label,
} from "reactstrap";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import HiringModals from "../../../../routes/Hiring/HiringModal";
import { getJobs, getQuestionaryList } from "../../../../store/jobs/jobAction";
import CreateInterviewTopic from "../../../../routes/Jobs/Recruiter/SmartInterview/Components/CreateInterviewTopic";
import SeekingModals from "../../../../routes/seeking/seekingModal";
import RequestScreeningModel from "./RequestScreeningModel";
export default function Sidebar(props) {
  const navigate = useNavigate();
  const { id } = useParams();
  const dispatch = useDispatch();
  const { profileData, isSeekingBadgeModal } = props;
  const [showModal1, setShowModal1] = useState(false);
  const [currentCount, setCurrentCount] = useState(0);
  const { singleMemberData } = useSelector((state) => state.directory);
  const { getAllJobs } = useSelector((state) => state.jobs);
  const [totalCount, setTotalCount] = useState(0);
  const [createQuestionaryModal, setCreateQuestionaryModal] = useState();
  // console.log(singleMemberData, "singleMemberData11");
  const { isLoading } = useSelector((state) => state.loader);
  const { user } = useSelector((state) => state.auth);
  const state = useLocation();
  const openModal1 = () => {
    setShowModal1(true);
  };
  const closeModal1 = () => {
    setShowModal1(false);
  };
  const memberShipSelected = user?.data?.membership?.name;
  useEffect(() => {
    dispatch(getQuestionaryList({ type: "set" }));
    if (state?.state?.isOpenCreateQus) setCreateQuestionaryModal(true);
  }, []);

  useEffect(() => {
    dispatch(
      getJobs({
        filter: "my",
        skip: currentCount,
        limit: "5",
      })
    ).then((res) => setTotalCount(res.totalCount));
  }, [currentCount]);

  //check active link
  const handleLinkClick = (linkName) => {
    setActiveLink(linkName);
    // Add logic to handle the link click
  };
  const [activeLink, setActiveLink] = useState(null);
  const modalRef = useRef(null);

  const [seekingModel, setSeekingModel] = useState(false);
  const toggleSeekingModal = () => {
    setSeekingModel(!seekingModel);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        showModal1 &&
        modalRef.current &&
        !modalRef.current.contains(event.target)
      ) {
        closeModal1();
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [showModal1, closeModal1]);

  const [modalOpen4, setModalOpen4] = useState(false);
  const [hiringModel, setHiringModel] = useState(false);
  const toggleModal4 = () => {
    setModalOpen4(!modalOpen4);
  };

  const handleSwitchChange = () => {
    if (profileData) {
      setModalOpen4(false);
    } else {
      setModalOpen4(!modalOpen4);
    }
  };

  const loadMoreData = () => {
    setCurrentCount(currentCount + 5);
  };

  const [screeningModel, setScreeningModel] = useState(false);

  useEffect(() => {
    if (isSeekingBadgeModal) {
      toggleSeekingModal();
      handleLinkClick("seeking");
    }
  }, [isSeekingBadgeModal]);

  return (
    <>
      <div>
        <div className="sidebar ">
          <div className="box ">
            <div className="badg">
              <ul>
                <li
                  onClick={() => openModal1()}
                  style={{ cursor: "pointer" }}
                  className="curserPointer text-dark"
                >
                  <i>
                    <img src={bad} alt="" />
                  </i>
                  Set Badge
                </li>
                {showModal1 && (
                  <div className="badge-modal" ref={modalRef}>
                    <div className="modal-content1">
                      <button onClick={closeModal1}>x</button>
                      <div
                        className="title"
                        style={{
                          cursor: "pointer",
                          color: activeLink === "seeking" ? "#0432a3" : "",
                        }}
                        onClick={() => {
                          toggleSeekingModal();
                          handleLinkClick("seeking");
                        }}
                      >
                        Seeking new job
                      </div>
                      <p>
                        Show recruiters and others that you’re seeking for job
                      </p>
                      {user?.data?.membership?.name == "Starter" ? (
                        <></>
                      ) : (
                        <>
                          <div
                            className="title"
                            style={{
                              cursor: "pointer",
                              color: activeLink === "hiring" ? "#0432a3" : "",
                            }}
                            onClick={() => {
                              setHiringModel(true);
                              handleLinkClick("hiring");
                            }}
                          >
                            Hiring
                          </div>
                          <p>
                            Share that you’re hiring and attract qualified
                            candidates
                          </p>
                        </>
                      )}
                    </div>
                  </div>
                )}
                {/* {singleMemberData?.resume_url && ( */}
                <li
                  onClick={() => {
                    navigate("/cv");
                  }}
                  className="curserPointer text-dark"
                >
                  <i>
                    <img src={res} alt="" />
                  </i>
                  {/* <a
                      href={singleMemberData?.resume_url}
                      target="_blank"
                      rel="noopener noreferrer"
                      style={{ color: "#575757" }}
                    > */}{" "}
                  My Resume / CV
                  {/* </a> */}
                </li>
                {/* )} */}
                <li
                  onClick={() => setScreeningModel(true)}
                  className="curserPointer text-dark"
                >
                  <i>
                    <img src={veri} alt="" />
                  </i>{" "}
                  Request Screening
                </li>
                <li
                  onClick={() => navigate("/campaign")}
                  className="curserPointer text-dark"
                >
                  <i>
                    <img src={cam} alt="" />
                  </i>
                  Campaign Manager
                </li>
                {memberShipSelected != "Starter" && (
                  <li
                    onClick={() => navigate("/leads-management")}
                    className="curserPointer text-dark"
                  >
                    <i>
                      <img src={lea} alt="" />
                    </i>{" "}
                    Lead Management
                  </li>
                )}
                {memberShipSelected === "Scout" && (
                  <li
                    onClick={() => setCreateQuestionaryModal(true)}
                    className="curserPointer text-dark"
                  >
                    <i>
                      <img src={questionary} alt="questionary" />
                    </i>
                    Create Questionnaire
                  </li>
                )}
                <li
                  onClick={() => navigate("/recruiter")}
                  className="curserPointer text-dark"
                >
                  <i>
                    <img src={lea} alt="" />
                  </i>
                  Recruiter Search
                </li>
                <li className="curserPointer text-dark">
                  <div className="d-flex align-items-center justify-content-between">
                    <p
                      onClick={() =>
                        profileData &&
                        window.open(
                          "https://nobelpageworkerpoolstage.dev.vinove.com/"
                        )
                      }
                    >
                      <i>
                        <img src={workerpool} alt="" />
                      </i>
                      WorkerPool
                    </p>
                    <FormGroup switch>
                      <Input
                        type="switch"
                        role="switch"
                        checked={profileData ? true : modalOpen4}
                        onChange={handleSwitchChange}
                        onClick={() => {
                          profileData &&
                            window.open(
                              "https://nobelpageworkerpoolstage.dev.vinove.com/worker/profile-dashboard"
                            );
                        }}
                      />
                    </FormGroup>
                  </div>
                </li>
                <Modal
                  isOpen={modalOpen4}
                  toggle={toggleModal4}
                  className="modal-dialog-centered modal-md"
                  style={{ maxWidth: "575px" }}
                >
                  <ModalHeader
                    toggle={toggleModal4}
                    className="customHeaderModal fw-bold"
                  >
                    Create WorkerPool profile
                  </ModalHeader>
                  <ModalBody>
                    <p>
                      If you're a worker (blue-collar, pink-collar, nanny, etc.)
                      seeking an efficient and easy way to connect with
                      recruiters or directly with individuals as an independent
                      and discover relevant opportunities.
                    </p>
                    <button
                      onClick={() =>
                        window.open(
                          "https://nobelpageworkerpoolstage.dev.vinove.com/worker/account-type/"
                        )
                      }
                      className="btn btn-blue text-light"
                    >
                      Create Worker Profile
                    </button>
                  </ModalBody>
                </Modal>
              </ul>
            </div>
          </div>
        </div>
      </div>

      {/* Add Job Preferences Modal */}
      {/* {showModal2 && (
      <div className="job-prefrence-modal">
        <div className="modal-content1">
          <button onClick={closeModal2}>x</button>
          <h3>Add Job Preferences</h3>
          <form>
            <div className="input-box">
              <label>Job title</label>
              <input type="text" />
            </div>
            <div className="input-box">
              <label>Workspace</label>
              <div className="checkbox-box">
                <input type="checkbox" id="site" />
                <label for="site">On-site</label>
                <input type="checkbox" id="hybrid" />
                <label for="hybrid">Hybrid </label>
                <input type="checkbox" id="remote" />
                <label for="remote">Remote </label>
              </div>
            </div>
            <div className="input-box">
              <label>Job Location</label>
              <input type="text" />
            </div>
            <div className="input-box">
              <label>Job type</label>
              <div className="checkbox-box">
                <input type="checkbox" id="full-time" />
                <label for="full-time">Full-time</label>
                <input type="checkbox" id="part-time" />
                <label for="part-time">Part-time </label>
                <input type="checkbox" id="temporary" />
                <label for="temporary">Temporary </label>
                <input type="checkbox" id="internship" />
                <label for="internship">Internship </label>
                <input type="checkbox" id="freelancing" />
                <label for="freelancing">Freelancing </label>
              </div>
            </div>
            <div className="radio-box">
              <label className="title">Choose who sees you’re seeking for job</label>
              <input type="radio" id="public" name="radio" value="public" />
              <label for="public">Public</label>
              <input type="radio" id="network" name="radio" value="network" />
              <label for="network">My Network</label>
              <input type="radio" id="scouts" name="radio" value="scouts" />
              <label for="Scouts">Scouts only</label>
            </div>
          </form>
          <div className="submit-btn-outer">
            <input type="submit" value="Add to profile" className="submit-btn" />
          </div>
        </div>
      </div>
    )} */}

      {seekingModel && (
        <SeekingModals
          seekingModel={seekingModel}
          toggleSeekingModal={toggleSeekingModal}
          isLoading={isLoading}
          activeLink={activeLink}
          setActiveLink={setActiveLink}
        />
      )}

      {hiringModel && (
        <HiringModals
          modal={hiringModel}
          toggle={() => setHiringModel(!hiringModel)}
          loadMoreData={loadMoreData}
          totalCount={totalCount}
          setTotalCount={setTotalCount}
          // getAllJobs={getAllJobs}
        />
      )}
      {createQuestionaryModal && (
        <CreateInterviewTopic
          modal={createQuestionaryModal}
          toggle={() => setCreateQuestionaryModal(!createQuestionaryModal)}
          isEditSets={true}
          openCreate={false}
        />
      )}

      {screeningModel && (
        <RequestScreeningModel
          model={screeningModel}
          toggle={() => setScreeningModel(!screeningModel)}
        />
      )}
    </>
  );
}

Sidebar.defaultProps = {
  isSeekingBadgeModal: false,
};
