import React from "react";
import Header from "../../core/components/layout/Header/Header";
import LoginFooter from "../../core/components/layout/footer/LoginFooter";
import logo from "../../assets/img/np-logo.svg";
import "./Legal.scss";
import legal from "../../assets/img/legal_terms.png";
import accessbility from "../../assets/img/legal-access.png";

export default function Legal(props) {
  const { data, myRef, tabs, setTabs } = props;

  return (
    <div>
      <Header />
      <div className="legal">
        <div className="bg">
          <div className="container">
            <div className="row">
              <div className="col-sm-8 margin-top">
                <h1>{data?.title}</h1>
                <p style={{ marginTop: "30px" }}>{data?.subtitle} </p>
              </div>

              <div className="col-sm-4">
                <img src={legal} alt="" className="bg1" />
              </div>
            </div>
          </div>
          <div className="n-logo">
            <img src={logo} alt="" width="100%" />
          </div>
        </div>
        <section>
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div
                  className="acc"
                  style={{
                    display: "flex",
                    borderBottom: "1px solid #D1DFFF",
                    justifyContent: "space-between",
                  }}
                >
                  {data?.body?.map((item) => (
                    <li
                      key={item?.id}
                      style={{ listStyle: "none" }}
                      className="nav-item"
                      ref={myRef}
                    >
                      <a
                        className={
                          tabs === item?.title
                            ? "nav-link nav-link-active"
                            : "nav-link"
                        }
                        onClick={() => setTabs(item?.title)}
                        href={`#${item?.title}`}
                      >
                        {item?.title}
                      </a>
                      {/* {tabs === item?.title && item?.title === "Accessibility" && (
                        <div className="blue-line"></div>
                      )} */}
                    </li>
                  ))}
                </div>
                {data?.body?.map((item, i) => (
                  <div className="tab-content mt-20" key={i}>
                    <div
                      id={`${item?.title}`}
                      className={
                        tabs === item?.title
                          ? "container tab-pane active"
                          : "container tab-pane"
                      }
                    >
                      <div
                        className="text  my-5"
                        id={`id${item?.id}`}
                        key={item?.id}
                      >
                        <h6>{item?.title}</h6>
                        {item?.body ? (
                          <div className="content">
                            <p
                              dangerouslySetInnerHTML={{
                                __html: item?.body,
                              }}
                            />
                          </div>
                        ) : (
                          <>
                            <p>...</p>
                          </>
                        )}
                        {/* <p>{item?.body}</p> */}
                        {/* {item?.title === "Accessibility" && (
                          <img
                            className="mt-3"
                            src={accessbility}
                            alt="access-img"
                          />
                        )} */}
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </section>
      </div>
      <div className="line mt-3"></div>
      <LoginFooter />
    </div>
  );
}
