import React, { useState, useEffect, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, FormGroup, Label, Input } from "reactstrap";
import setttingNobelLogo from '../../../assets/img/setttingNobelLogo.png'
import SearchSettings from "../components/SearchSettings";

const SettingMembership = (props) => {
  const { isActive, profileData, handleChangeMembershipStatus, handleCancelChange, isDone } = props;
  const AccountSearchList = [
    { id: "1", name: "Membership info" },
    { id: "2", name: "Compare memberships" },
    { id: "3", name: "Add-ons package" }
  ];

  function monthDiff(d1, d2) {
    if (d1 > d2) {
      let temp = d1;
      d1 = d2;
      d2 = temp;
    }
    let year1 = d1.getFullYear();
    let month1 = d1.getMonth();
    let year2 = d2.getFullYear();
    let month2 = d2.getMonth();
    let yearDifference = year2 - year1;
    let monthDifference = month2 - month1;
    let totalMonthsDifference = yearDifference * 12 + monthDifference;

    return totalMonthsDifference
  }

  function dateFormatter(date) {
    const monthNames = ["Jan", "Feb", "Mar", "Apr",
      "May", "Jun", "Jul", "Aug",
      "Sep", "Oct", "Nov", "Dec"];

    const day = date.getDate();

    const monthIndex = date.getMonth();
    const monthName = monthNames[monthIndex];

    const year = date.getFullYear();

    return `${day} ${monthName} ${year}`;
  }

  const upgradeableMemberships = ["Nobel", "Nobel Plus", "Scout"]

  const [modalOpen1, setModalOpen1] = useState(false)
  const [selectedProfile, setSelectedProfile] = useState([]);
  const [defaultValue, setDefaultValue] = useState("");
  const [results, setResults] = useState([]);
  const currentMembershipInfo = useMemo(() => {
    if (profileData?.membership?.id != 1) {
      const months = monthDiff(new Date(profileData?.membership_expiry), new Date(profileData?.membership_start))
      let planType
      if (months == 12) {
        planType = "Annual Plan"
      } else {
        planType = "Monthly Plan"
      }
      const startDate = dateFormatter(new Date(profileData?.membership_start))
      const endDate = dateFormatter(new Date(profileData?.membership_expiry))
      return {
        planType, startDate, endDate, months
      }
    } else {
      return null
    }
  }, [profileData])
  const navigate = useNavigate();
  const toggleModal1 = () => {
    setModalOpen1(!modalOpen1);
  };

  const handleChange = (e) => {
    const { target } = e;
    if (!target.value.trim()) return setResults([]);

    const filteredValue = AccountSearchList.filter((AccountSearchList) =>
      AccountSearchList.name.toLowerCase().includes(target.value.toLowerCase())
    );
    setResults(filteredValue);
  };

  useEffect(() => {
    if (selectedProfile.name === "Membership info") {
      setModalOpen1(true);
      setDefaultValue("");
    }
    if (selectedProfile.name === "Compare memberships") {
      navigate("/membership", { state: { isCompare: true } })
      setDefaultValue("");
    }
    if (selectedProfile.name === "Add-ons package") {
      navigate("/membership", { state: { isAddOns: true } })
      setDefaultValue("");
    }
  }, [selectedProfile]);

  return (
    <div>
      <div className="row">
        <div className="col-lg-6">
          <div className="heading">
            <h1>Membership and Subscriptions</h1>
          </div>
        </div>
        <div className="col-lg-6">
          <div className="search">
            <SearchSettings
              results={results}
              value={selectedProfile?.name}
              renderItem={(item) => <p>{item.name}</p>}
              onChange={handleChange}
              defaultValue={defaultValue}
              setDefaultValue={setDefaultValue}
              onSelect={(item) => setSelectedProfile(item)}
            />
          </div>
        </div>
      </div>

      <div className="content">
        <div className="profile-information">
          <ul>
            <li
              className={isActive ? "active" : null}
              onClick={() => setModalOpen1(true)}
            >
              Membership info
            </li>
            <li
              className={isActive ? "active" : null}
              onClick={() => { navigate("/membership", { state: { isCompare: true } }) }}
            >
              Compare memberships
            </li>
            <li
              className={isActive ? "active" : null}
              onClick={() => navigate("/wallet", { state: { isAddOns: true } })}
            >
              Add-ons package
            </li>
          </ul>
        </div>
        <div className="line"></div>
        <div className="profile-information">
          <h4>Payment and Cards</h4>
          <ul>
            <li onClick={() => alert("This featue wille be implemented in upcoming milestones")}>
              Saved Cards
            </li>
            <li onClick={() => alert("This featue wille be implemented in upcoming milestones")}>
              Transactions
            </li>
          </ul>
        </div>
      </div>

      <Modal isOpen={modalOpen1} toggle={toggleModal1} className='nobel-plus-account-modal setting-modal-default-modal' centered>
        <ModalHeader toggle={toggleModal1} id="membership-modal">
          <img src={setttingNobelLogo} alt='Logo' />
        </ModalHeader>
        <ModalBody>
          <div className='d-flex justify-content-between'>
            <div className='left'>
              <h3>{`${profileData?.membership?.name}`} Account</h3>
              {currentMembershipInfo ? <h6>{currentMembershipInfo.planType}</h6> : <h6>Basic Plan</h6>}
              {currentMembershipInfo ? <h6>{`${currentMembershipInfo.startDate} - ${currentMembershipInfo.endDate}`}</h6> : <h6>Validity: Unlimited</h6>}
            </div>
            <div className='right'>
              <div style={{ display: "flex", flexDirection: "column", rowGap: "10px" }}>
                {upgradeableMemberships.includes(profileData?.membership?.name) ? <button disabled={profileData?.next_membership != null} className='gray-blue-btn' onClick={() => navigate('/membership')}>{"Change Membership"}</button> : <></>}
                {profileData?.membership?.name != "Starter" && profileData?.is_membership_active ? <button disabled={profileData?.next_membership != null} className='gray-blue-btn' onClick={() => handleChangeMembershipStatus()}>{"Cancel Membership"}</button> : profileData?.membership?.name == "Starter" && !profileData?.is_membership_active ? <button className='gray-blue-btn' onClick={() => navigate('/membership')}>{"Buy Membership"}</button> : profileData?.membership?.name != "Starter" && !profileData?.is_membership_active ? <button disabled={profileData?.next_membership != null} className='gray-blue-btn' onClick={() => handleChangeMembershipStatus()}>{"Activate Membership"}</button> :  <></>}
              </div>
            </div>
          </div>
        </ModalBody>
        <ModalFooter className='footer-txt'>
          {profileData?.next_membership != null ?
            <>
            <div className="d-flex justify-content-between">
              <div className="left">
                <p>Next Membership: {profileData?.next_membership?.name}</p>
                <p className='gray-light400'>Your next membership will begin <br></br>on {currentMembershipInfo.endDate}</p>
                
              </div>
              <div className="right">
              <button className='gray-blue-btn' disabled={isDone} onClick={() => handleCancelChange()}>{"Cancel Change"}</button>
              </div>
              
            </div>
            <p className="gray-light400">(Note: If you want to cancel or change membership, cancel the upcoming change first)</p>
            </>
            :
            <div>
              {profileData?.is_membership_active ? 
              <>
              <p>Your membership is ACTIVE.</p>
              <p className='gray-light400'>Your membership will be automatically renewed {currentMembershipInfo.months == 12 ? "annually." : "monthly."}</p>  
              </> :
              <>
              <p>Your membership is INACTIVE.</p>
              <p className='gray-light400'>Your membership will not be auto-renewed.</p>  
              </>}
                
            </div>
          }

        </ModalFooter>
      </Modal>
    </div>
  );
};

export default SettingMembership;
