import { membershipAPI } from "../../api/membershipAPI";
import {
  GET_MEMBERSHIP_TYPES,
  GET_MEMBERSHIP_BY_ID,
  GET_MEMBERSHIP_FEATURES,
} from "../types";

export const getMembershipType = (data) => (dispatch) => {
  return membershipAPI.getMembershipList(data).then(
    (response) => {
      dispatch({
        type: GET_MEMBERSHIP_TYPES,
        payload: response,
      });
      return Promise.resolve(response);
    },
    (error) => {
      console.log(error);
      return Promise.reject(error);
    }
  );
};

export const getMembershipById = (data) => (dispatch) => {
  return membershipAPI.getMembershipId(data).then(
    (response) => {
      dispatch({
        type: GET_MEMBERSHIP_BY_ID,
        payload: response.data,
      });
      return Promise.resolve(response);
    },
    (error) => {
      console.log(error);
      return Promise.reject(error);
    }
  );
};

export const getAddOns = (data) => () => {
  return membershipAPI.getAddOn(data).then(
    (response) => {
      return Promise.resolve(response);
    },
    (error) => {
      console.log(error);
      return Promise.reject(error);
    }
  );
}

export const getUserAddons = () => () => {
  return membershipAPI.userAddon().then(
    (response) => {
      return Promise.resolve(response)
    }, 
    (error) => {
      return Promise.reject(error)
    }
  )
}

export const getMembershipFeature = (data) => (dispatch) => {
  return membershipAPI.getMembershipFeature(data).then(
    (response) => {
      dispatch({
        type: GET_MEMBERSHIP_FEATURES,
        payload: response,
      });
      return Promise.resolve(response);
    },
    (error) => {
      console.log(error);
      return Promise.reject(error);
    }
  );
};

export const getMembershipImage = () => (dispatch) => {
  return membershipAPI.getMembershipImage().then(
    (response) => {
      return Promise.resolve(response);
    },
    (error) => {
      console.log(error);
      return Promise.reject(error);
    }
  );
};

export const getLocation = () => (dispatch) => {
  return membershipAPI.getLocation().then(
    (response) => {
      return Promise.resolve(response);
    },
    (error) => {
      console.log(error);
      return Promise.reject(error);
    }
  );
};

export const buyAddOn = (data) => (dispatch) => {
  return membershipAPI.buyAddons(data).then((res) =>{
    return Promise.resolve(res)
  })
  .catch((error) => {
    return Promise.reject(error)
  })
}
