import {
  GET_ALL_ACTIVITY,
  GET_ACTIVITY_MEDIA,
  GET_ALL_POSTS,
  GET_ALL_ARTICLES,
} from "../types";
import { activityApi } from "../../api/activityAPI";
import { activityFeedList } from "../dashboard/dashboardActions";

export const allActivityAction = (payload, params, type) => (dispatch) => {
  activityApi.getActivityDetails(payload, params).then((data) => {
    if (type === "posts") {
      dispatch(activityFeedList(data, params));
    } else if (type === "articles") {
      dispatch(activityFeedList(data, params));
    } else dispatch(activityFeedList(data, params));
  });
};

export const getActivityMedia = (payload, params) => (dispatch) => {
  activityApi.getActivityMedia(payload, params).then((data) => {
    dispatch({
      type: GET_ACTIVITY_MEDIA,
      payload: data.data,
    });
  });
};
