import React from "react";

function ProfileViewCount(props) {
  return (
    <div>
      <div className="line mt-30"></div>
      <div className="p-20">
        <h2>{props.profileViewersCount}</h2>
        <p>Profile Views</p>
      </div>
      <div className="line pb-30"></div>
    </div>
  );
}

export default ProfileViewCount;
