import React, { useEffect, useState } from "react";
import Navbar from "../../../pages/navbar/navbar";
import "./Campaign.scss";
import Ad from "../../../core/components/layout/Sidebar/Ad";
import Refer from "../../../core/components/layout/Sidebar/Refer";
import promotion from "../../../assets/img/promotion_img.png";
import grow from "../../../assets/img/up.svg";
import dgrow from "../../../assets/img/d-grow.svg";
import { useNavigate } from "react-router";
import LineChart from "../../../core/components/Chatrs/LineChart";
import moment from "moment";
import Chat from "../../../pages/chat/chat";
import { Link } from "react-router-dom";
import jobPromot from "../../../assets/img/job_promot.svg";
import InfiniteScroll from "react-infinite-scroll-component";
import ToastContainer from "rsuite/esm/toaster/ToastContainer";
import { useDispatch, useSelector } from "react-redux";
import AppLoader from "../../../core/components/Loaders/appLoader/appLoader";
import "../../../core/components/Loaders/appLoader/appLoader.scss";
//import logo from "../../../../assets/img/logo-blue.png";
import logo from "../../../assets/img/NpLoader-1.gif";
import {
  deleteCampaign,
  getCampaign,
} from "../../../store/campaign/campaignActions";
import { campaignAPI } from "../../../api/campaignAPI";
import { toast } from "react-toastify";
import editIcon from "../../../assets/img/edit.svg";
import deleteIcon from "../../../assets/img/deleteIcon.svg";
import ConfirmationModal from "../../../core/components/modal/ConfirmationModal";
import promotionIcon from "../../../assets/img/promotion_img.png";
export default function Campaign(props, setIsLoading) {
  const {
    campaignList,
    featuredCampaignList,
    tabs,
    setTabs,
    suggested_articles,
    loadMoreData,
    currentCount,
    hasMore,
  } = props;

  const navigate = useNavigate();
  const { isPageLoading } = useSelector((state) => state.loader);
  const [campaignData, setCampaignData] = useState([]);
  const [campaignLogs, setcampaignLogs] = useState("");
  const [pageLoading, setPageLoading] = useState(false); // Add loading state
  const [selectedCampaign, setSelectedCampaign] = useState("");
  const [loading2, setLoading2] = useState(false);
  const [selectCampaignTab, setSelectCampaignTab] = useState("view");
  const [loading3, setLoading3] = useState(false);
  const handleLoader = () => {
    setLoading2(true);
    setTimeout(() => {
      setLoading2(false);
    }, 2000);
  };

  const handleLoader2 = () => {
    setLoading3(true);
    setTimeout(() => {
      setLoading3(false);
    }, 2000);
  };
  const dispatch = useDispatch();

  const getCampaigns = async () => {
    try {
      const res = await campaignAPI.getCampaign({
        type: "publishAndCompleted",
        limit: 100,
      });

      if (res?.success) {
        setCampaignData(res?.data);
        setSelectedCampaign(res?.data[0]?.id);
        setSelectCampaignTab("view");
      }
    } catch (err) {
      console.log(err);
    }
  };

  const getCampaignLogs = async () => {
    try {
      const res = await campaignAPI.getCampaignLogCount(selectedCampaign);
      if (res?.success) {
        setcampaignLogs(res?.data);
      }
    } catch (err) {
      console.log(err);
    }
  };
  useEffect(() => {
    getCampaigns();
  }, []);

  useEffect(() => {
    if (selectedCampaign) getCampaignLogs();
  }, [selectedCampaign]);

  const removeCampaign = (id) => {
    dispatch(deleteCampaign(id))
      .then((res) => {
        setDeleteId("");
        setDeleteModal(false);
        toast.success("Campaign Deleted Successfully", {
          position: toast.POSITION.BOTTOM_LEFT,
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const [deleteModel, setDeleteModal] = useState(false);
  const [deleteId, setDeleteId] = useState("");

  return (
    <div className="campaign bg-campaign">
      <Navbar isJobs={false} />
      {isPageLoading || (loading3 && <AppLoader />)}
      <div className="container mt-20">
        <div className="row">
          <div className="col-lg-9 ">
            <div className="main-box bg-white mb-20">
              <div className="heading-row">
                <div style={{ width: "40%" }}>
                  <h2>Campaigns</h2>
                  {campaignData?.length > 0 && (
                    <div className="my-2">
                      <div className="boxes">
                        <p>
                          <select
                            onChange={(e) => {
                              setSelectedCampaign(e.target.value);
                              handleLoader2();
                            }}
                            className="form-select"
                          >
                            {campaignData?.map((ele) => {
                              return (
                                <option value={ele?.id}>{ele?.name}</option>
                              );
                            })}
                          </select>
                        </p>
                      </div>
                    </div>
                  )}
                </div>

                <div
                  className="btn btn-blue"
                  style={{ height: "fit-content" }}
                  onClick={() =>
                    navigate("/createcampaign", {
                      state: {
                        isPropmt: true,
                      },
                    })
                  }
                >
                  Promote and Feature
                </div>
              </div>

              <div className="row px-3 px-sm-5 mt-20 mb-20 d-flex align-items-center">
                <div className="col-lg-3 my-2">
                  <div
                    className={
                      selectCampaignTab == "view" ? "active-box" : "boxes"
                    }
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      setSelectCampaignTab("view");
                      handleLoader2();
                    }}
                  >
                    <h4 className="black-color">
                      {campaignLogs?.view?.total_count}
                    </h4>
                    <p>
                      Total Views
                      <span
                        className={
                          campaignLogs?.view?.count_diff > 0 ? "grow" : "d-grow"
                        }
                      >
                        <img
                          src={
                            campaignLogs?.view?.count_diff > 0 ? grow : dgrow
                          }
                          alt=""
                        />{" "}
                        {campaignLogs?.view?.count_diff}
                      </span>
                    </p>
                  </div>
                </div>
                <div className="col-lg-3 my-2">
                  <div
                    className={
                      selectCampaignTab == "comment" ? "active-box" : "boxes"
                    }
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      setSelectCampaignTab("comment");
                      handleLoader2();
                    }}
                  >
                    <h4 className="black-color">
                      {campaignLogs?.comment?.total_count}
                    </h4>
                    <p>
                      Comments
                      <span
                        className={
                          campaignLogs?.comment?.count_diff > 0
                            ? "grow"
                            : "d-grow"
                        }
                      >
                        <img
                          src={
                            campaignLogs?.comment?.count_diff > 0 ? grow : dgrow
                          }
                          alt=""
                        />{" "}
                        {campaignLogs?.comment?.count_diff}
                      </span>
                    </p>
                  </div>
                </div>
                <div className="col-lg-3 my-2">
                  <div
                    className={
                      selectCampaignTab == "likes" ? "active-box" : "boxes"
                    }
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      setSelectCampaignTab("likes");
                      handleLoader2();
                    }}
                  >
                    <h4 className="black-color">
                      {campaignLogs?.like?.total_count}
                    </h4>
                    <p>
                      Likes
                      <span
                        className={
                          campaignLogs?.like?.count_diff > 0 ? "grow" : "d-grow"
                        }
                      >
                        <img
                          src={
                            campaignLogs?.like?.count_diff > 0 ? grow : dgrow
                          }
                          alt=""
                        />{" "}
                        {campaignLogs?.like?.count_diff}
                      </span>
                    </p>
                  </div>
                </div>
              </div>
              <div className="graph-area">
                {campaignData && campaignData.length > 0 ? (
                  <LineChart
                    campaign_id={selectedCampaign}
                    type={selectCampaignTab}
                  />
                ) : (
                  <h5 style={{ textAlign: "center" }}>
                    You don't have any live campaign yet.
                  </h5>
                )}
              </div>
              <div className="tabs-area mt-20">
                <div className="overflow_hidden">
                  <ul className="nav nav-tabs" role="tablist">
                    <li className="nav-item">
                      <a
                        className="nav-link active"
                        data-bs-toggle="tab"
                        href="#all"
                        onClick={() => {
                          handleLoader();
                          setTabs("all");
                        }}
                      >
                        All Campaigns
                      </a>
                    </li>
                    <li
                      className="nav-item"
                      onClick={() => {
                        handleLoader();
                        setTabs("completed");
                      }}
                    >
                      <a
                        className="nav-link"
                        data-bs-toggle="tab"
                        href="#completed"
                      >
                        Completed
                      </a>
                    </li>
                    <li className="nav-item">
                      <a
                        className="nav-link"
                        data-bs-toggle="tab"
                        href="#featured"
                        onClick={() => {
                          handleLoader();
                          setTabs("featured");
                        }}
                      >
                        Featured Jobs
                      </a>
                    </li>
                    <li className="nav-item">
                      <a
                        className="nav-link"
                        data-bs-toggle="tab"
                        href="#published"
                        onClick={() => {
                          handleLoader();
                          setTabs("published");
                        }}
                      >
                        Published
                      </a>
                    </li>
                    <li className="nav-item">
                      <a
                        className="nav-link"
                        data-bs-toggle="tab"
                        href="#scheduled"
                        onClick={() => {
                          setTabs("scheduled");
                        }}
                      >
                        Scheduled
                      </a>
                    </li>
                    <li className="nav-item">
                      <a
                        className="nav-link"
                        data-bs-toggle="tab"
                        href="#draft"
                        onClick={() => {
                          setTabs("draft");
                        }}
                      >
                        Draft
                      </a>
                    </li>
                  </ul>
                </div>

                <div className="tab-content mt-20 pb-20">
                  {loading2 && (
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        background: "transparent",
                      }}
                    >
                      <img
                        src={logo}
                        style={{
                          height: "45px",
                          width: "45px",
                          borderRadius: "50%",
                        }}
                        alt="logo"
                      />
                      {/* <div className="mt-10">Loading...</div> */}
                    </div>
                  )}
                  <div id="all" className="container tab-pane fade show active">
                    <InfiniteScroll
                      dataLength={campaignList?.length}
                      next={loadMoreData}
                      hasMore={hasMore}
                    >
                      <div className="row">
                        {campaignList?.map((data, index) => {
                          return (
                            <div className="listing" key={index}>
                              <div className="card-left">
                                <img
                                  src={
                                    data?.type == "job"
                                      ? promotionIcon
                                      : data?.Post?.Feed_Documents[0]
                                          ?.document_url
                                  }
                                  alt="campaign_picture"
                                />

                                <div className="heading">
                                  <p>{data.name}</p>
                                  <span className="para">
                                    Starts{" "}
                                    {moment(data.start_date).format("llll")}
                                  </span>
                                </div>
                              </div>
                              <div
                                className="card-right"
                                style={{ alignItems: "center" }}
                              >
                                <div>
                                  <div
                                    className={
                                      new Date(data?.end_date) > new Date()
                                        ? data.campaign_status
                                        : "completed"
                                    }
                                  >
                                    {new Date(data?.end_date) > new Date()
                                      ? data.campaign_status
                                      : "completed"}
                                  </div>
                                </div>
                                <div>
                                  {data.name === "Job Promotion" ? (
                                    <div
                                      className="btn"
                                      onClick={() =>
                                        navigate("/createcampaign", {
                                          state: {
                                            id: data.id,
                                            type: data.type,
                                            type_id: data.type_id,
                                            isFeatured: true,
                                          },
                                        })
                                      }
                                    >
                                      <img
                                        src={editIcon}
                                        alt="edit"
                                        width={20}
                                      />
                                    </div>
                                  ) : (
                                    <div
                                      className="btn"
                                      onClick={() =>
                                        navigate("/createcampaign", {
                                          state: {
                                            id: data.id,
                                            type: data.type,
                                            type_id: data.type_id,
                                            isPublished: true,
                                            status:
                                              new Date(data?.end_date) >
                                              new Date()
                                                ? data.campaign_status
                                                : "completed",
                                          },
                                        })
                                      }
                                    >
                                      <img
                                        src={editIcon}
                                        alt="edit"
                                        width={20}
                                      />
                                    </div>
                                  )}
                                </div>
                                <div>
                                  {new Date(data?.end_date) < new Date() ||
                                  data.campaign_status == "scheduled" ||
                                  data.campaign_status == "draft" ? (
                                    <div
                                      className="btn"
                                      onClick={() => {
                                        setDeleteId(data?.id);
                                        setDeleteModal(true);
                                      }}
                                    >
                                      <img
                                        src={deleteIcon}
                                        alt="edit"
                                        width={20}
                                      />
                                    </div>
                                  ) : (
                                    <div className="btn">
                                      <div style={{ width: "20px" }}></div>
                                    </div>
                                  )}
                                </div>
                              </div>
                            </div>
                          );
                        })}
                      </div>
                    </InfiniteScroll>
                  </div>

                  <div id="completed" className="container tab-pane fade">
                    <InfiniteScroll
                      dataLength={campaignList?.length}
                      next={loadMoreData}
                      hasMore={hasMore}
                    >
                      <>
                        {campaignList?.map((data, index) => {
                          return (
                            <div className="listing" key={index}>
                              <div className="card-left">
                                <img
                                  src={
                                    data?.type == "job"
                                      ? promotionIcon
                                      : data?.Post?.Feed_Documents[0]
                                          ?.document_url
                                  }
                                  alt="campaign_picture"
                                />

                                <div className="heading">
                                  <p>{data.name}</p>
                                  <span className="para">
                                    Starts{" "}
                                    {moment(data.start_date).format("llll")}
                                  </span>
                                </div>
                              </div>
                              <div
                                className="card-right"
                                style={{ alignItems: "center" }}
                              >
                                <div>
                                  <div className="completed">Completed</div>
                                </div>
                                <div>
                                  {data.name === "Job Promotion" ? (
                                    <div
                                      className="btn"
                                      onClick={() =>
                                        navigate("/createcampaign", {
                                          state: {
                                            id: data.id,
                                            type: data.type,
                                            type_id: data.type_id,
                                            isFeatured: true,
                                          },
                                        })
                                      }
                                    >
                                      <img
                                        src={editIcon}
                                        alt="edit"
                                        width={20}
                                      />
                                    </div>
                                  ) : (
                                    <div>
                                      <div
                                        className="btn"
                                        onClick={() =>
                                          navigate("/createcampaign", {
                                            state: {
                                              id: data.id,
                                              type: data.type,
                                              type_id: data.type_id,
                                              isPublished: true,
                                              status:
                                                new Date(data?.end_date) >
                                                new Date()
                                                  ? data.campaign_status
                                                  : "completed",
                                            },
                                          })
                                        }
                                      >
                                        <img
                                          src={editIcon}
                                          alt="edit"
                                          width={20}
                                        />
                                      </div>
                                      <div
                                        className="btn"
                                        onClick={() => {
                                          setDeleteId(data?.id);
                                          setDeleteModal(true);
                                        }}
                                      >
                                        <img
                                          src={deleteIcon}
                                          alt="edit"
                                          width={20}
                                        />
                                      </div>
                                    </div>
                                  )}
                                </div>
                              </div>
                            </div>
                          );
                        })}
                      </>
                    </InfiniteScroll>
                  </div>
                  <div id="featured" className="container tab-pane fade">
                    <InfiniteScroll
                      dataLength={campaignList?.length}
                      next={loadMoreData}
                      hasMore={hasMore}
                    >
                      <div className="row">
                        {campaignList?.map((data, index) => {
                          return (
                            <div className="listing" key={index}>
                              <div className="card-left">
                                <img
                                  src={
                                    data?.Post?.Feed_Documents[0]
                                      ?.document_url || promotionIcon
                                  }
                                  alt="campaign_picture"
                                />

                                <div className="heading">
                                  <p>{data.name}</p>
                                  <span className="para">
                                    Starts{" "}
                                    {moment(data.start_date).format("llll")}
                                  </span>
                                </div>
                              </div>
                              <div
                                className="card-right"
                                style={{ alignItems: "center" }}
                              >
                                <div>
                                  <div className={data.campaign_status}>
                                    {data.campaign_status}
                                  </div>
                                </div>
                                <div>
                                  <div
                                    className="btn"
                                    onClick={() =>
                                      navigate("/createcampaign", {
                                        state: {
                                          id: data.id,
                                          type: data.type,
                                          type_id: data.type_id,
                                          isFeatured: true,
                                        },
                                      })
                                    }
                                  >
                                    <img src={editIcon} alt="edit" width={20} />
                                  </div>
                                </div>
                              </div>
                            </div>
                          );
                        })}
                      </div>
                    </InfiniteScroll>
                  </div>
                  {campaignList.length < 0 && (
                    <div className="row">
                      <p style={{ textAlign: "center" }} className="my-30">
                        <b>No campaign available</b>
                      </p>
                    </div>
                  )}
                  <div id="published" className="container tab-pane fade">
                    <InfiniteScroll
                      dataLength={campaignList?.length}
                      next={loadMoreData}
                      hasMore={hasMore}
                    >
                      <div className="row">
                        {campaignList?.map((data, index) => {
                          return (
                            <div className="listing" key={index}>
                              <div className="card-left">
                                <img
                                  src={
                                    data?.type == "job"
                                      ? promotionIcon
                                      : data?.Post?.Feed_Documents[0]
                                          ?.document_url
                                  }
                                  alt="campaign_picture"
                                />

                                <div className="heading">
                                  <p>{data.name}</p>
                                  <span className="para">
                                    Starts{" "}
                                    {moment(data.start_date).format("llll")}
                                  </span>
                                </div>
                              </div>
                              <div
                                className="card-right"
                                style={{ alignItems: "center" }}
                              >
                                <div>
                                  <div className={data.campaign_status}>
                                    {data.campaign_status}
                                  </div>
                                </div>
                                <div>
                                  <div
                                    className="btn"
                                    onClick={() =>
                                      navigate("/createcampaign", {
                                        state: {
                                          id: data.id,
                                          type: data.type,
                                          type_id: data.type_id,
                                          isPublished: true,
                                          status:
                                            new Date(data?.end_date) >
                                            new Date()
                                              ? data.campaign_status
                                              : "completed",
                                        },
                                      })
                                    }
                                  >
                                    <img src={editIcon} alt="edit" width={20} />
                                  </div>
                                </div>
                              </div>
                            </div>
                          );
                        })}
                      </div>
                    </InfiniteScroll>
                  </div>
                  <div id="scheduled" className="container tab-pane fade">
                    <InfiniteScroll
                      dataLength={campaignList?.length}
                      next={loadMoreData}
                      hasMore={hasMore}
                    >
                      <>
                        {campaignList?.map((data, index) => {
                          return (
                            <div className="listing" key={index}>
                              <div className="card-left">
                                <img
                                  src={
                                    data?.type == "job"
                                      ? promotionIcon
                                      : data?.Post?.Feed_Documents[0]
                                          ?.document_url
                                  }
                                  alt="campaign_picture"
                                />

                                <div className="heading">
                                  <p>{data.name}</p>
                                  <span className="para">
                                    Starts{" "}
                                    {moment(data.start_date).format("llll")}
                                  </span>
                                </div>
                              </div>
                              <div
                                className="card-right"
                                style={{ alignItems: "center" }}
                              >
                                <div>
                                  <div className={data.campaign_status}>
                                    {data.campaign_status}
                                  </div>
                                </div>
                                <div>
                                  {data.name === "Job Promotion" ? (
                                    <div
                                      className="btn"
                                      onClick={() =>
                                        navigate("/createcampaign", {
                                          state: {
                                            id: data.id,
                                            type: data.type,
                                            type_id: data.type_id,
                                            isFeatured: true,
                                          },
                                        })
                                      }
                                    >
                                      <img
                                        src={editIcon}
                                        alt="edit"
                                        width={20}
                                      />
                                    </div>
                                  ) : (
                                    <div>
                                      <div
                                        className="btn"
                                        onClick={() =>
                                          navigate("/createcampaign", {
                                            state: {
                                              id: data.id,
                                              type: data.type,
                                              type_id: data.type_id,
                                              isPublished: true,
                                              status:
                                                new Date(data?.end_date) >
                                                new Date()
                                                  ? data.campaign_status
                                                  : "completed",
                                            },
                                          })
                                        }
                                      >
                                        <img
                                          src={editIcon}
                                          alt="edit"
                                          width={20}
                                        />
                                      </div>

                                      <div
                                        className="btn"
                                        onClick={() => {
                                          setDeleteId(data?.id);
                                          setDeleteModal(true);
                                        }}
                                      >
                                        <img
                                          src={deleteIcon}
                                          alt="edit"
                                          width={20}
                                        />
                                      </div>
                                    </div>
                                  )}
                                </div>
                              </div>
                            </div>
                          );
                        })}
                      </>
                    </InfiniteScroll>
                  </div>
                  <div id="draft" className="container tab-pane fade">
                    <InfiniteScroll
                      dataLength={campaignList?.length}
                      next={loadMoreData}
                      hasMore={hasMore}
                    >
                      <div className="row">
                        {campaignList?.map((data, index) => {
                          return (
                            <div className="listing" key={index}>
                              <div className="card-left">
                                <img
                                  src={
                                    data?.type == "job"
                                      ? "https://images.unsplash.com/photo-1589802829985-817e51171b92?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxleHBsb3JlLWZlZWR8Nnx8fGVufDB8fHx8&w=1000&q=80"
                                      : data?.Post?.Feed_Documents[0]
                                          ?.document_url
                                  }
                                  alt="campaign_picture"
                                />

                                <div className="heading">
                                  <p>{data.name}</p>
                                  <span className="para">
                                    Starts{" "}
                                    {moment(data.start_date).format("llll")}
                                  </span>
                                </div>
                              </div>
                              <div
                                className="card-right"
                                style={{ alignItems: "center" }}
                              >
                                <div>
                                  <div className={data.campaign_status}>
                                    {data.campaign_status}
                                  </div>
                                </div>
                                <div>
                                  {data.name === "Job Promotion" ? (
                                    <div
                                      className="btn"
                                      onClick={() =>
                                        navigate("/createcampaign", {
                                          state: {
                                            id: data.id,
                                            type: data.type,
                                            type_id: data.type_id,
                                            isFeatured: true,
                                          },
                                        })
                                      }
                                    >
                                      <img
                                        src={editIcon}
                                        alt="edit"
                                        width={20}
                                      />
                                    </div>
                                  ) : (
                                    <div>
                                      <div
                                        className="btn"
                                        onClick={() =>
                                          navigate("/createcampaign", {
                                            state: {
                                              id: data.id,
                                              type: data.type,
                                              type_id: data.type_id,
                                              isPublished: true,
                                              status:
                                                new Date(data?.end_date) >
                                                new Date()
                                                  ? data.campaign_status
                                                  : "completed",
                                            },
                                          })
                                        }
                                      >
                                        <img
                                          src={editIcon}
                                          alt="edit"
                                          width={20}
                                        />
                                      </div>
                                      <div
                                        className="btn"
                                        onClick={() => {
                                          setDeleteId(data?.id);
                                          setDeleteModal(true);
                                        }}
                                      >
                                        <img
                                          src={deleteIcon}
                                          alt="edit"
                                          width={20}
                                        />
                                      </div>
                                    </div>
                                  )}
                                </div>
                              </div>
                            </div>
                          );
                        })}
                      </div>
                    </InfiniteScroll>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-3 ">
            <div className="sidebar">
              <div>
                <Ad />
              </div>
              <div className="mt-20">
                <Refer />
              </div>
              <div
                className="mt-20 bg-white px-3 py-1"
                style={{ borderRadius: "5px" }}
              >
                <div className="article my-4">
                  <h4 className="mb-3 ">Articles for you</h4>
                  <div className="line"></div>
                  <div className="mt-3">
                    {suggested_articles?.map((item, index) => {
                      if (index < 6) {
                        return (
                          <div key={index} className="box">
                            <div className="img">
                              {item?.media ? (
                                <img src={item?.media} alt="" />
                              ) : (
                                <img src={null} alt="" />
                              )}
                            </div>
                            <div className="text">
                              <Link to={`/articledetail/${item?.id}`}>
                                <h6
                                  className="card-control-text-twoline"
                                  style={{ wordBreak: "break-word" }}
                                >
                                  {item.title}
                                </h6>
                              </Link>
                              <span>12,932 viewers</span>
                            </div>
                          </div>
                        );
                      }
                    })}
                    <div className="mt-3 ">
                      <Link to={"/articles"}>
                        <span>See all</span>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {deleteModel && (
          <ConfirmationModal
            modal={deleteModel}
            toggle={() => setDeleteModal(!deleteModel)}
            title="Delete Campaign"
            Confirm={() => {
              removeCampaign(deleteId);
            }}
            text="Are you want to sure to delete campaign?"
          />
        )}
      </div>
      <Chat />
      <ToastContainer />
    </div>
  );
}
