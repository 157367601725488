import React from 'react'

export default function Npmail() {
  return (
    <div className='np-mail-side'>
      <div className="bg-white mt-26">
        <h2 className='p-20'>NPMail (1)</h2>
        <div className="line"></div>
        <div className="details p-20">
          <div className="row mb-2">
            <div className="col-lg-3 col-md-1 col-sm-1 col-3">
              <div className="profile-img">
                <img
                  src="https://img.freepik.com/free-photo/pleasant-looking-serious-man-stands-profile-has-confident-expression-wears-casual-white-t-shirt_273609-16959.jpg"
                  className="image-fit"
                  alt=""
                />
              </div>
            </div>
            <div className="col-lg-9 col-9">
              <h3>Ashique Rahman</h3>
              <p>Sent you a NPMail</p>
            </div>
          </div>
          <div className="row mb-2">
            <div className="col-lg-3 col-md-1 col-sm-1 col-3">
              <div className="profile-img">
                <img
                  src="https://img.freepik.com/free-photo/pleasant-looking-serious-man-stands-profile-has-confident-expression-wears-casual-white-t-shirt_273609-16959.jpg"
                  className="image-fit"
                  alt=""
                />
              </div>
            </div>
            <div className="col-lg-9 col-9">
              <h3 className='light-font'>Muhammed Shabeer</h3>
              <p>Sent you a NPMail</p>
            </div>
          </div>
          <div className="row mb-2">
            <div className="col-lg-3 col-md-1 col-sm-1 col-3">
              <div className="profile-img">
                <img
                  src="https://img.freepik.com/free-photo/pleasant-looking-serious-man-stands-profile-has-confident-expression-wears-casual-white-t-shirt_273609-16959.jpg"
                  className="image-fit"
                  alt=""
                />
              </div>
            </div>
            <div className="col-lg-9 col-9">
              <h3 className='light-font'>Arunlal</h3>
              <p>Sent you a NPMail</p>
            </div>
          </div>
          
          <div className="text-center">
            <button>See more <i className="arrow down"></i></button>
          </div>
        </div>
      </div>
    </div>
  )
}
