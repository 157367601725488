import React from "react";
import error from "../../assets/img/error.png";
import "./error.scss";
import logo from "../../assets/img/np-logo.svg";
import { Link } from "react-router-dom";
import Footer from "../../core/components/layout/footer/Footer";

export default function Error() {
  //const { firstFooter} = useSelector((state) => state.landingPage)
  return (
    <div className="error">
      <div className="container">
        <div className="row">
          <div className="col-lg-12">
            <div className="text-center">
              <div className="img mt-5">
                <img src={error} className="object-fit-cover" alt="error" />
              </div>
              <p className="mt-5 mb-3">
                Oops! It looks like you've stumbled upon a page that doesn't exist. Don't worry, it happens to the best of us.<br/> Thank you for visiting NobelPage!
              </p>
              <a className="go " href="/">
                Go Back
              </a>
              <div className="logo mt-5">
                <img src={logo} width="55px" alt="logo" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="footer mb-3">
        <div>
          <footer>
            <div className="container">
              <div class="line mt-3" style={{ padding: "10px" }}></div>
              <div className="py-3" style={{ marginTop: "-18px" }}>
                <div className="text-center">
                  <ul>
                    <Footer/>
                  
                  {/* <li className="hide me-0">{firstFooter?.title?.replace('YYYY', new Date().getFullYear())} </li>
                
               {firstFooter?.body?.map((item, idx)=>  */}
               <>
                    <span className="disc-size">&#x2022;</span>
                    <li>
                      <Link to="/legal">
                        <span>Terms of Use</span>
                      </Link>
                    </li>
                    <span className="disc-size">&#x2022;</span>
                    <li>
                      <Link to="/legal">
                        <span>Privacy Policy</span>
                      </Link>
                    </li>
                    <span className="disc-size">&#x2022;</span>
                    <li>
                      <Link to="/legal">
                        <span>Cookie Policy</span>
                      </Link>
                    </li>
                    <span className="disc-size">&#x2022;</span>
                    <li>
                      <Link to="/legal">
                        <span>Copyright Policy</span>
                      </Link>
                    </li>
                    <span className="disc-size">&#x2022;</span>
                    <li className="ms-2 me-0">
                      <Link to="/legal">
                        <span>Branding Policy</span>
                      </Link>
                    </li>
                    <span className="disc-size">&#x2022;</span>
                    <li>
                      <Link to="/community">
                        <span>Community Guidelines</span>
                      </Link>
                    </li>
                    </>
                    {/* )} */}
                  </ul>
                </div>
              </div>
            </div>
          </footer>
        </div>
      </div>
    </div>
  );
}
