import React, { useEffect, useState } from "react";
import Article from "./Article";
import { useDispatch, useSelector } from "react-redux";
import { getSuggestedArticles } from "../../../store/articles/articleActions";
import { useNavigate } from "react-router-dom";
import DOMPurify from "dompurify";

export default function ArticleContainer() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { suggested_articles } = useSelector((state) => state.article);
  const { my_articles } = useSelector((state) => state.article);
  const { drafts } = useSelector((state) => state.article);

  const [createModal, setCreateModal] = useState(false);
  const [searchKey, setSearchKey] = useState(null);
  const [updatedArticlesList, setUpdatedArticlesList] = useState([]);
  const [newMyArticles, setNewMyArticles] = useState([]);
  const [newDrafts, setNewDrafts] = useState([]);
  const [currentCount, setCurrentCount] = useState(0);
  const [totalCount, setTotalCount] = useState(0);
  const [successModal, setSuccessModal] = useState(false);
  const [popupShareData, setPopupShareData] = useState("");
  const [searchVal, setSearchVal] = useState(0);

  const [tabs, setTabs] = useState("suggested_articles");
  const token = localStorage.getItem("accessToken");
  useEffect(() => {
    if (tabs === "suggested_articles") {
      let query = "suggested";
      if (searchKey === "") {
        dispatch(
          getSuggestedArticles(
            token,
            query,
            { type: "suggested", skip: currentCount, limit: 10 },
            currentCount
          )
        ).then((res) => {
          setTotalCount(res?.count);
        });
      } else {
        dispatch(
          getSuggestedArticles(
            token,
            query,
            {
              type: "suggested",
              skip: currentCount,
              limit: 10,
              search: searchKey,
            },
            currentCount
          )
        ).then((res) => {
          setTotalCount(res?.count);
        });
      }
    } else if (tabs === "my_articles") {
      let query = "my";
      dispatch(
        getSuggestedArticles(
          token,
          query,
          { type: "my", skip: currentCount, limit: 10 },
          currentCount
        )
      ).then((res) => {
        setTotalCount(res?.count);
      });
    } else if (tabs === "my_drafts") {
      let query = "draft";
      dispatch(
        getSuggestedArticles(
          token,
          query,
          { type: "draft", skip: currentCount, limit: 10 },
          currentCount
        )
      ).then((res) => {
        setTotalCount(res?.count);
      });
    }
  }, [tabs, currentCount, searchVal]);

  useEffect(() => {
    setCurrentCount(0);
  }, [tabs]);

  useEffect(() => {
    setUpdatedArticlesList(suggested_articles);
  }, [suggested_articles]);

  useEffect(() => {
    setNewMyArticles(my_articles);
  }, [my_articles]);

  useEffect(() => {
    setNewDrafts(drafts);
  }, [drafts]);

  const filterSearchData = () => {
    console.log(searchKey, "searchkey");
    if (tabs === "suggested_articles") {
      // const result = suggested_articles.filter((articles) =>
      //   articles?.title
      //     .toString()
      //     .toLowerCase()
      //     .includes(searchKey.toLowerCase())
      // );
      // setUpdatedArticlesList(result);
      dispatch(
        getSuggestedArticles(
          token,
          "suggested",
          {
            type: "suggested",
            skip: currentCount,
            limit: 10,
            search: searchKey,
          },
          currentCount
        )
      ).then((res) => {
        setTotalCount(res?.count);
      });
    } else if (tabs === "my_articles") {
      // const result = my_articles.filter((articles) =>
      //   articles?.title
      //     .toString()
      //     .toLowerCase()
      //     .includes(searchKey.toLowerCase())
      // );
      // setNewMyArticles(result);
      dispatch(
        getSuggestedArticles(
          token,
          "my",
          { type: "my", skip: currentCount, limit: 10, search: searchKey },
          currentCount
        )
      ).then((res) => {
        setTotalCount(res?.count);
      });
    } else if (tabs === "my_drafts") {
      // const result = drafts.filter((articles) =>
      //   articles?.title
      //     .toString()
      //     .toLowerCase()
      //     .includes(searchKey.toLowerCase())
      // );
      // setNewDrafts(result);

      dispatch(
        getSuggestedArticles(
          token,
          "draft",
          { type: "draft", skip: currentCount, limit: 10, search: searchKey },
          currentCount
        )
      ).then((res) => {
        setTotalCount(res?.count);
      });
    }
  };

  const loadMoreData = () => {
    setCurrentCount(currentCount + 10);
  };

  const readMore = (id) => {
    navigate(`/articledetail/${id}`);
  };

  function createMarkup(html) {
    return {
      __html: DOMPurify.sanitize(html),
    };
  }

  const successfulStatus = (shareData) => {
    setPopupShareData(shareData);
    setSuccessModal(!successModal);
  };

  const searchArticle = (searchKey) => {
    setCurrentCount(0);
    setSearchVal(!searchVal);
  };

  return (
    <Article
      suggested_articles={suggested_articles}
      createModal={createModal}
      setCreateModal={setCreateModal}
      filterSearchData={filterSearchData}
      searchKey={searchKey}
      setSearchKey={setSearchKey}
      updatedArticlesList={updatedArticlesList}
      tabs={tabs}
      setTabs={setTabs}
      readMore={readMore}
      newMyArticles={newMyArticles}
      newDrafts={newDrafts}
      currentCount={currentCount}
      loadMoreData={loadMoreData}
      createMarkup={createMarkup}
      successfulStatus={successfulStatus}
      popupShareData={popupShareData}
      successModal={successModal}
      setSuccessModal={setSuccessModal}
      navigate={navigate}
      searchArticle={searchArticle}
      totalCount={totalCount}
    />
  );
}
