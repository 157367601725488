import React, { useState, useEffect } from "react";
import { Input, Modal, ModalBody, ModalHeader } from "reactstrap";
import { addskillsdetails } from "../../../../store/profile/profileActions";
import { useDispatch, useSelector } from "react-redux";

const AddSkillModal = (props) => {
  const {
    modal,
    toggle,
    editSkillsData,
    setEditSkillsData,
    educationData,
    experienceData,
  } = props;
  const [errorMessage, setErrorMessage] = useState("");
  const [peopleInfo, setPeopleInfo] = useState("");
  const [educationInfo, setEducationInfo] = useState("");
  const { isWebView } = useSelector((state) => state.auth);

  useEffect(() => {
    if (editSkillsData) {
      const obj = Object.fromEntries(
        editSkillsData?.experience_id?.map((key) => [
          key,
          {
            id: key,
          },
        ])
      );

      const obj2 = Object.fromEntries(
        editSkillsData?.education_id?.map((key) => [
          key,
          {
            id: key,
          },
        ])
      );
      setPeopleInfo(obj);
      setEducationInfo(obj2);
    }
  }, []);

  // console.log(obj);

  const [skillData, setSkillData] = useState(
    editSkillsData
      ? {
          id: editSkillsData?.id,
          skills: editSkillsData?.skills,
          education_id: editSkillsData?.education_id,
          experience_id: editSkillsData?.experience_id,
        }
      : { skills: "", education_id: [], experience_id: [] }
  );

  const dispatch = useDispatch();

  const handleSubmit = () => {
    if (skillData?.skills?.trim()) {
      var token = localStorage.getItem("accessToken");
      let skillDataformat = {
        exps_and_skills: [skillData],
      };
      dispatch(addskillsdetails(skillDataformat, token))
        .then(() => {
          if (editSkillsData) {
            setEditSkillsData();
          }
          toggle();
        })
        .catch((e) => {
          console.log(e);
          alert("Backend Side Validation =>" + e.response.data.message);
        });
    } else setErrorMessage("The field is Mandatory.");
  };

  const CloseModal = () => {
    if (editSkillsData) {
      setEditSkillsData();
    }
    toggle();
  };

  const handleCheckboxChange = (item) => (event) => {
    setPeopleInfo((state) => ({
      ...state,
      [item.id]: state[item.id]
        ? null
        : {
            id: item.id,
          },
    }));

    if (event.target.checked) {
      setSkillData((prevState) => ({
        ...skillData,
        experience_id: [...prevState.experience_id, item.id],
      }));
    } else {
      setSkillData((prevState) => ({
        ...skillData,
        experience_id: prevState.experience_id.filter(
          (item1) => item1 !== item.id
        ),
      }));
    }
  };

  const handleCheckboxEducation = (item) => (event) => {
    setEducationInfo((state) => ({
      ...state,
      [item.id]: state[item.id]
        ? null
        : {
            id: item.id,
          },
    }));

    if (event.target.checked) {
      setSkillData((prevState) => ({
        ...skillData,
        education_id: [...prevState.education_id, item.id],
      }));
    } else {
      setSkillData((prevState) => ({
        ...skillData,
        education_id: prevState.education_id.filter(
          (item1) => item1 !== item.id
        ),
      }));
    }
  };

  return (
    <div>
      <Modal
        isOpen={modal}
        size="lg"
        className="fix-width gradient-background"
        centered
      >
        <div className="popup">
          <div className="add-skill">
            <ModalHeader toggle={CloseModal}>
              {editSkillsData ? "Edit Skills" : "Add Skills"}
            </ModalHeader>
            <ModalBody>
              <form>
                <div className="title">
                  <label className="text-dark">Skill*</label>
                  <Input
                    type="text"
                    placeholder="Enter Title"
                    value={skillData.skills}
                    onClick={(e) => setErrorMessage("")}
                    maxLength="50"
                    onChange={(e) =>
                      setSkillData({ ...skillData, skills: e.target.value })
                    }
                  />
                  {errorMessage && (
                    <label className="label-input100 text-danger">
                      {errorMessage}
                    </label>
                  )}
                </div>
                <div>
                  <p className="head">Where you put this skill to use</p>

                  <p className="exper">Experience</p>
                  {experienceData &&
                    experienceData.map((item, index) => {
                      return Object.values(item)[0].map((item3, index) => {
                        return (
                          <div className="d-flex mb-3">
                            <Input
                              className="me-2"
                              type="checkbox"
                              name={item3.id}
                              checked={peopleInfo[item3.id]}
                              onChange={handleCheckboxChange(item3)}
                            />
                            <p className="options">
                              {item3.title} at {item3.company_name}
                            </p>
                          </div>
                        );
                      });
                    })}

                  <p className="exper">Education</p>
                  {educationData?.map((item, index) => (
                    <div className="d-flex">
                      <Input
                        className="me-2"
                        type="checkbox"
                        value={item.id}
                        checked={educationInfo[item.id]}
                        onChange={handleCheckboxEducation(item)}
                      />
                      <p className="options">
                        {item.school_name
                          ? item.school_name
                          : "No School Added"}
                      </p>
                    </div>
                  ))}
                </div>
                <div className="add-media">
                  <div
                    className={
                      isWebView ? "btnWebView btn-blue" : "btn btn-blue"
                    }
                    style={{ marginTop: "15px" }}
                    onClick={handleSubmit}
                  >
                    Save
                  </div>
                </div>
              </form>
            </ModalBody>
            <div style={{ padding: "5px" }}></div>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default AddSkillModal;
