import React from "react";
import { useNavigate } from "react-router-dom";
import "./Connection.scss";
import default_profile from "../../../../src/assets/img/placeholder_user.jpg";
import unfollow from "../../../assets/img/withd.png";
import message from "../../../assets/img/bluemessage.svg";

function Connection(props) {
  const {
    first_name,
    last_name,
    profile,
    profile_img,
    shared_connection,
    user_id,
  } = props.connectionsAPIData;
  const { isFollowing } = props;
  const navigate = useNavigate();

  const messageClick = () => {
    alert("This feature will be implemented in the future milestone");
  };

  // Helper function to truncate text
  const truncateText = (text, maxLength) => {
    if (!text) return "";
    return text.length > maxLength ? `${text.slice(0, maxLength)}...` : text;
  };

  // Apply truncation logic to first and last names
  const truncatedFirstName =
    first_name.length > 10 ? truncateText(first_name, 10) : first_name;
  const truncatedLastName =
    first_name.length <= 10 && last_name.length > 10
      ? truncateText(last_name, 10)
      : last_name.length <= 10
      ? last_name
      : "";

  // Apply truncation logic to city and country names
  const truncatedCity = truncateText(profile?.city?.name, 10);
  const truncatedCountry = truncateText(profile?.country?.name, 10);

  return (
    <div className="row mt-30 mb-30" style={{ alignItems: "center" }}>
      <div className="col-lg-1 col-md-1 col-sm-1 col-12 me-3 mb-2">
        <div className="profile-img">
          <img
            src={profile_img ? profile_img : default_profile}
            alt="Profile"
            className="image-fit"
            onClick={() => navigate(`/profile/${user_id}`)}
          />
        </div>
      </div>
      <div className="col-lg-8 col-md-8 col-sm-7 col-12 mb-3 ml-20">
        <div className="details">
          <h2 onClick={() => navigate(`/profile/${user_id}`)}>
            {truncatedFirstName} {truncatedLastName}
          </h2>
          {profile?.designation && (
            <p>{truncateText(profile?.profile_headline, 10)}</p>
          )}
          <p>
            {profile?.city?.name && profile?.country?.name && (
              <>
                {truncatedCity}, {truncatedCountry}
              </>
            )}
          </p>
          {shared_connection && shared_connection.length > 0 && (
            <p>
              <span className="people-icon"></span>
              <span style={{ marginLeft: "5px" }}>
                {truncateText(shared_connection[0].first_name, 10)}{" "}
                {truncateText(shared_connection[0].last_name, 10)} is a shared
                connection
              </span>
            </p>
          )}
        </div>
      </div>
      <div className="col-lg-2 col-md-2 col-sm-3 col-12 ">
        <div className="float-xl-end d-flex" onClick={messageClick}>
          {isFollowing ? (
            <>
              <div
                className="btn btn-light d-flex"
                style={{ gap: "6px", marginBottom: "5px", marginRight: "5px" }}
              >
                <img src={message} alt="Message Icon" /> Message
              </div>
              <div
                className="btn btn-light d-flex"
                style={{ gap: "6px", marginBottom: "5px" }}
              >
                <img
                  style={{ width: "12px", height: "12px", marginTop: "4px" }}
                  src={unfollow}
                  alt="Unfollow Icon"
                />{" "}
                Unfollow
              </div>
            </>
          ) : (
            <div className="btn btn-light d-flex" style={{ gap: "6px" }}>
              <img src={message} alt="Message Icon" /> Message
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default Connection;
