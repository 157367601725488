import React, { useState, useEffect } from "react";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import placeholderUser from "../../../assets/img/placeholder_user.jpg";
import { useNavigate } from "react-router";
import { useSelector } from "react-redux";
import { WEB_URL } from "../../../config";

const ReferInfo = (props) => {
  const { modal, toggle, refer_data } = props;

  const navigate = useNavigate();

  const { user } = useSelector((state) => state.auth);

  return (
    <div>
      <Modal isOpen={modal} size="lg" style={{ width: "550px" }} centered>
        <div className="popup">
          <ModalHeader toggle={toggle}>Refer Info</ModalHeader>
          <ModalBody className="m-0 px-16 py-0 mb-4">
            <div className="p-2 mt-20">
              <span>Refer Link</span> -{" "}
              <span className="blue-color">
                {WEB_URL}signup?referId={user?.data?.id}
              </span>
            </div>
            <div className="p-2 gap-4 d-flex  mt-1">
              <p className="text green-color">
                Success Invite: {refer_data.success}
              </p>
              <p className="mt-0 text red-color">
                Remaining: {refer_data.remaining}
              </p>
            </div>
            <div
              className="grey-bg p-2 bg-white add-scroll"
              style={{ maxHeight: "600px" }}
            >
              {refer_data &&
                refer_data?.results?.map((data, index) => {
                  return (
                    <div
                      className="d-flex mb-4 mt-4"
                      style={{ alignItems: "center" }}
                      key={index}
                    >
                      <div className="img">
                        <img
                          src={
                            data?.profile_img
                              ? data?.profile_img
                              : placeholderUser
                          }
                          style={{ height: "64px", width: "64px" }}
                          alt="profile img"
                          className="curserPointer"
                          onClick={() => navigate(`/profile/${data?.id}`)}
                        />
                      </div>

                      <div className="text">
                        <h6 className="ml-20">
                          <span
                            className="curserPointer"
                            onClick={() => navigate(`/profile/${data?.id}`)}
                          >
                            {data?.first_name + " " + data?.last_name}
                          </span>
                        </h6>
                        {data?.profile?.profile_headline && (
                          <p>{data?.profile?.profile_headline}</p>
                        )}
                      </div>
                    </div>
                  );
                })}
            </div>
          </ModalBody>
        </div>
      </Modal>
    </div>
  );
};

export default ReferInfo;
