import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { UAParser } from "ua-parser-js";
import { Input, Modal, ModalHeader, ModalBody } from "reactstrap";
import { useSelector, useDispatch } from "react-redux";
import {
  updateSettingDetails,
  getUserSetting,
} from "../../../store/setting/SettingAction";
import SearchSettings from "../components/SearchSettings";
import FormInput from "../../../core/components/Inputs/FormInput";
import MobileInput from "../../../core/components/Inputs/MobileInput";
// import email from "../../assets/img/a.svg";
import emailIcon from "../../../assets/img/a.svg";

// import profile from "../../assets/img/profile-circle.svg";
import pass from "../../../assets/img/shield-security.svg";
import {
  addSecondaryEmail,
  deleteAllSessions,
  deleteSession,
  getEmailList,
  getSessionList,
  updatePass,
} from "../../../store/setting/SettingAction";
import loggedSession1 from "../../../assets/img/loggedSession1.png";
import loggedSession2 from "../../../assets/img/loggedSession2.png";
import loggedSession3 from "../../../assets/img/loggedSession3.png";
import loggedSession4 from "../../../assets/img/loggedSession4.png";
import { toast } from "react-toastify";

const Security = (props) => {
  const AccountSearchList = [
    { id: "1", name: "Email addresses" },
    { id: "2", name: "Phone numbers" },
    { id: "3", name: "Change password" },
    { id: "4", name: "Signed in locations" },
    { id: "5", name: "Two-factor authentication" },
  ];
  const { isActive } = props;
  const dispatch = useDispatch();
  const token = localStorage.getItem("accessToken");
  const [selectedProfile, setSelectedProfile] = useState([]);
  const [defaultValue, setDefaultValue] = useState("");
  const [results, setResults] = useState([]);
  const [modalOpen1, setModalOpen1] = useState(false);
  const [modalOpen2, setModalOpen2] = useState(false);
  const [modalOpen3, setModalOpen3] = useState(false);
  const [modalOpen4, setModalOpen4] = useState(false);
  const [modalOpen5, setModalOpen5] = useState(false);
  const [emailList, setEmailList] = useState([]);
  const [sessions, setSessions] = useState([]);
  const [currentSession, setCurrentSession] = useState("");
  const [addEmailForm, setAddEmailForm] = useState(false);
  const [errorEmail, setErrorEmail] = useState("");
  const [errorPassword, setErrorPassword] = useState("");
  const [password, setPassword] = useState("");
  const [emailName, setEmailName] = useState("");
  const [pass1, setPass1] = useState("");
  const [pass2, setPass2] = useState("");
  const [pass3, setPass3] = useState("");
  const [pass1Error, setPass1Error] = useState("");
  const [pass2Error, setPass2Error] = useState("");
  const [pass3Error, setPass3Error] = useState("");
  const [twoFactorSetting, setTwoFactorSetting] = useState("");
  const { secondaryEmailList, sessionList, settingData } = useSelector(
    (state) => state.setting
  );
  const { profileData } = useSelector((state) => state.profile);

  const toggleModal1 = () => {
    setModalOpen1(!modalOpen1);
  };

  const toggleModal2 = () => {
    setModalOpen2(!modalOpen2);
  };

  const toggleModal3 = () => {
    setModalOpen3(!modalOpen3);
  };

  const toggleModal4 = () => {
    setModalOpen4(!modalOpen4);
  };

  const toggleModal5 = () => {
    setModalOpen5(!modalOpen5);
  };

  const handleChange = (e) => {
    const { target } = e;
    if (!target.value.trim()) return setResults([]);

    const filteredValue = AccountSearchList.filter((AccountSearchList) =>
      AccountSearchList.name.toLowerCase().includes(target.value.toLowerCase())
    );
    setResults(filteredValue);
  };

  const checkSignUpValidation = (type) => {
    let emailValid =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    let passwordValid =
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!""#$%&'()*+,-./:;<=>?@[\]^_`{|}~])[A-Za-z\d!""#$%&'()*+,-./:;<=>?@[\]^_`{|}~]{8,}$/;

    let formIsValid = true;

    if (type === "all" || type === "emailName") {
      if (!emailName.trim()) {
        setErrorEmail("Please enter your email.");
        formIsValid = false;
      } else if (!emailValid.test(emailName.trim())) {
        setErrorEmail("Please enter your email in a valid format.");
        formIsValid = false;
      } else {
        setErrorEmail("");
      }
    }

    if (type === "all" || type === "password") {
      if (!password) {
        setErrorPassword("Please enter your password");
        formIsValid = false;
        return;
      } else if (!passwordValid.test(password.trim())) {
        setErrorPassword(
          'The password should be minimum 8 characters long and contain at least one : Upper and Lower Case Alphabet, Number, Special Character. The special characters that can be used are [!""#$%&\'()*+,-./:;<=>?@[]^_`{|}~]'
        );
        formIsValid = false;
        return;
      } else {
        setErrorPassword("");
      }
    }

    return formIsValid;
  };

  useEffect(() => {
    if (selectedProfile.id === "1") {
      setModalOpen1(true);
      setDefaultValue("");
    }
    if (selectedProfile.id === "2") {
      setModalOpen2(true);
      setDefaultValue("");
    }
    if (selectedProfile.id === "3") {
      setModalOpen4(true);
      setDefaultValue("");
    }

    if (selectedProfile.id === "4") {
      setModalOpen3(true);
      setDefaultValue("");
    }

    if (selectedProfile.id === "5") {
      setModalOpen5(true);
      setDefaultValue("");
    }
  }, [selectedProfile]);

  const checkPassChangeValidation = (type) => {
    let passwordValid =
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!""#$%&'()*+,-./:;<=>?@[\]^_`{|}~])[A-Za-z\d!""#$%&'()*+,-./:;<=>?@[\]^_`{|}~]{8,}$/;

    let formIsValid = true;

    if (type === "all" || type === "pass1") {
      if (!pass1) {
        setPass1Error("Please enter your password");
        formIsValid = false;
      } else if (!passwordValid.test(pass1.trim())) {
        setPass1Error(
          'The password should be minimum 8 characters long and contain at least one : Upper and Lower Case Alphabet, Number, Special Character. The special characters that can be used are [!""#$%&\'()*+,-./:;<=>?@[]^_`{|}~]'
        );
        formIsValid = false;
      } else {
        setPass1Error("");
      }
    }

    if (type === "all" || type === "pass2") {
      if (!pass2) {
        setPass2Error("Please enter your new password");
        formIsValid = false;
      } else if (!passwordValid.test(pass2.trim())) {
        setPass2Error(
          'The password should be minimum 8 characters long and contain at least one : Upper and Lower Case Alphabet, Number, Special Character. The special characters that can be used are [!""#$%&\'()*+,-./:;<=>?@[]^_`{|}~]'
        );
        formIsValid = false;
      } else {
        setPass2Error("");
      }
    }

    if (type === "all" || type === "pass3") {
      if (!pass3) {
        setPass3Error("Please confirm your new password");
        formIsValid = false;
      } else if (!passwordValid.test(pass3.trim())) {
        setPass3Error(
          'The password should be minimum 8 characters long and contain at least one : Upper and Lower Case Alphabet, Number, Special Character. The special characters that can be used are [!""#$%&\'()*+,-./:;<=>?@[]^_`{|}~]'
        );
        formIsValid = false;
      } else if (pass2 !== pass3) {
        setPass3Error("Passwords do not match.");
        formIsValid = false;
      } else {
        setPass3Error("");
      }
    }

    return formIsValid;
  };

  const handleAddEmail = () => {
    if (checkSignUpValidation("all")) {
      const bodyData = {
        secondaryEmail: emailName,
        password: password,
      };

      dispatch(addSecondaryEmail(bodyData, token))
        .then((res) => {
          setAddEmailForm(false);
          setEmailName("");
          setPassword("");
          dispatch(getEmailList(token));
          if (res.success) {
            toast.success(res.message, {
              position: toast.POSITION.BOTTOM_LEFT,
            });
          } else {
            toast.error(res.message, { position: toast.POSITION.BOTTOM_LEFT });
          }
        })
        .catch((error) => {
          toast.error(error.response.data.message, {
            position: toast.POSITION.BOTTOM_LEFT,
          });
        });
    } else {
      console.log("Validation Error");
    }
  };

  const handleSingleDelete = (targetId) => {
    dispatch(deleteSession(token, targetId))
      .then((res) => {
        dispatch(getSessionList(token));
        if (res.success) {
          toast.success(res.message, { position: toast.POSITION.BOTTOM_LEFT });
        } else {
          toast.error(res.message, { position: toast.POSITION.BOTTOM_LEFT });
        }
      })
      .catch((error) => {
        toast.error(error.response.data.message, {
          position: toast.POSITION.BOTTOM_LEFT,
        });
      });
  };

  const handleAllDelete = () => {
    let sessionIds = [];
    for (let i = 0; i < sessions.length; i++) {
      sessionIds.push(sessions[i].id);
    }

    const bodyData = {
      sessionIds,
    };

    dispatch(deleteAllSessions(token, bodyData))
      .then((res) => {
        dispatch(getSessionList(token));
        if (res.success) {
          toast.success(res.message, { position: toast.POSITION.BOTTOM_LEFT });
        } else {
          toast.error(res.message, { position: toast.POSITION.BOTTOM_LEFT });
        }
      })
      .catch((error) => {
        toast.error(error.response.data.message, {
          position: toast.POSITION.BOTTOM_LEFT,
        });
      });
  };

  const handleTwoFactorChange = (value) => {
    let targetValue;
    if (value) {
      targetValue = "1";
    } else {
      targetValue = "0";
    }

    const updatedArray = twoFactorSetting.map((obj, i) => {
      return { ...obj, value: targetValue };
    });
    setTwoFactorSetting(updatedArray);
  };

  const handleTwoFactorSave = () => {
    let updatedItems = [];

    for (let i = 0; i < twoFactorSetting.length; i++) {
      let myObj = {
        setting_master_id: twoFactorSetting[i].id,
        value: twoFactorSetting[i].value,
      };
      updatedItems.push(myObj);
    }

    let updateData = {
      setting: updatedItems,
    };

    setModalOpen5(false);
    dispatch(updateSettingDetails(updateData, token)).then((res) => {
      dispatch(getUserSetting(0, token));
      if (res.success) {
        toast.success("Two-factor Authentication Activated.", {
          position: toast.POSITION.BOTTOM_LEFT,
          toastId: "visibility",
        });
      }
    });
  };

  const handlePassChange = () => {
    if (checkPassChangeValidation("all")) {
      const bodyData = {
        oldPass: pass1,
        newPass: pass3,
      };

      dispatch(updatePass(token, bodyData))
        .then((res) => {
          setModalOpen4(!modalOpen4);
          setPass1("");
          setPass2("");
          setPass3("");
          if (res.success) {
            toast.success(res.message, {
              position: toast.POSITION.BOTTOM_LEFT,
            });
          } else {
            toast.error(res.message, { position: toast.POSITION.BOTTOM_LEFT });
          }
        })
        .catch((error) => {
          toast.error(error.response.data.message, {
            position: toast.POSITION.BOTTOM_LEFT,
          });
        });
    }
  };

  useEffect(() => {
    if (secondaryEmailList) {
      setEmailList(secondaryEmailList.data);
    }

    if (sessionList) {
      let current = sessionList.data[0];
      let targetList = sessionList.data;
      targetList.shift();
      console.log(current);
      console.log(targetList);
      setCurrentSession(current);
      setSessions(targetList);
    }

    console.log(settingData?.data[45]);

    if (settingData) {
      setTwoFactorSetting(settingData?.data[45]?.settings);
    }
  }, [secondaryEmailList, sessionList, settingData]);

  let mySession;
  if (currentSession && currentSession !== "") {
    mySession = UAParser(currentSession.user_agent);
  }

  return (
    <div>
      <div className="row">
        <div className="col-lg-6">
          <div className="heading">
            <h1>Security and Privacy</h1>
          </div>
        </div>
        <div className="col-lg-6">
          <div className="search">
            <SearchSettings
              results={results}
              value={selectedProfile?.name}
              renderItem={(item) => <p>{item.name}</p>}
              onChange={handleChange}
              defaultValue={defaultValue}
              setDefaultValue={setDefaultValue}
              onSelect={(item) => setSelectedProfile(item)}
            />
          </div>
        </div>
      </div>
      <div className="content">
        <div className="profile-information">
          <h4>Account Access</h4>
          <ul>
            <li
              className={isActive ? "active" : null}
              onClick={() => toggleModal1()}
            >
              Email addresses
            </li>
            <li
              className={isActive ? "active" : null}
              onClick={() => toggleModal2()}
            >
              Phone numbers
            </li>
            <li
              className={isActive ? "active" : null}
              onClick={() => toggleModal4()}
            >
              Change password
            </li>
            <li
              className={isActive ? "active" : null}
              onClick={() => toggleModal3()}
            >
              Signed in locations
            </li>
            <li
              className={isActive ? "active" : null}
              onClick={() => toggleModal5()}
            >
              Two-factor authentication
            </li>
          </ul>
        </div>
        <div className="line"></div>
        <div className="profile-information">
          <h4>Data Privacy</h4>
          <ul>
            <li
              onClick={() =>
                alert("This featue wille be implemented in upcoming milestones")
              }
            >
              Manage my activity
            </li>
            <li
              onClick={() =>
                alert("This featue wille be implemented in upcoming milestones")
              }
            >
              Clear search history data
            </li>
            <li
              onClick={() =>
                alert("This featue wille be implemented in upcoming milestones")
              }
            >
              Demographic info
            </li>
            <li
              onClick={() =>
                alert("This featue wille be implemented in upcoming milestones")
              }
            >
              Data Research (social, economic and workplace)
            </li>
          </ul>
        </div>
      </div>
      <Modal
        isOpen={modalOpen1}
        toggle={toggleModal1}
        className="add-email-account-modal setting-modal-default-modal"
      >
        <ModalHeader toggle={toggleModal1}>
          <h4>Emails you've added</h4>
        </ModalHeader>
        <ModalBody>
          <p className="gray-light400">Primary</p>
          <p className="gray-light600">
            {profileData ? profileData.email : ""}
          </p>
          {emailList.length > 0 ? (
            <div
              style={{
                backgroundColor: "#d8e0fd",
                padding: "10px",
                borderRadius: "15px",
                marginTop: "10px",
              }}
            >
              {emailList.length > 0 ? (
                <p className="gray-light400">Secondary</p>
              ) : (
                <></>
              )}
              {emailList && emailList.length > 0 ? (
                emailList.map((ele) => {
                  return (
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                      }}
                    >
                      <p className="gray-light600">{ele.email}</p>
                      <p className="gray-light600">
                        {ele.is_verified ? "Verified" : "Not verified"}
                      </p>
                    </div>
                  );
                })
              ) : (
                <></>
              )}
            </div>
          ) : (
            <></>
          )}
          {addEmailForm ? (
            <>
              <FormInput
                label="Email"
                icon={emailIcon}
                type="email"
                onChange={(e) => setEmailName(e.target.value)}
                placeholder="Enter Seconday E-mail"
                onBlur={() => checkSignUpValidation("emailName")}
                error={errorEmail}
                // onKeyDown={(e) => handleEnterSubmit(e)}
                containerClassName={errorEmail ? "error-form" : ""}
              />
              <FormInput
                label="Password"
                icon={pass}
                type="password"
                onChange={(e) => setPassword(e.target.value)}
                onBlur={() => checkSignUpValidation("password")}
                placeholder="Enter Password"
                // onKeyDown={(e) => handleEnterSubmit(e)}
                error={errorPassword}
                containerClassName={errorPassword ? "error-form" : ""}
              />
              <div
                className="text-end"
                style={{
                  marginTop: "30px",
                  display: "flex",
                  justifyContent: "flex-start",
                  columnGap: "10px",
                }}
              >
                <div
                  className="btn btn-outline"
                  onClick={() => setAddEmailForm(false)}
                >
                  Close
                </div>
                <div className="btn btn-blue" onClick={() => handleAddEmail()}>
                  Save
                </div>
              </div>
            </>
          ) : (
            <></>
          )}
          {!addEmailForm ? (
            <button
              className="gray-blue-btn with-border"
              onClick={() => setAddEmailForm(true)}
            >
              Add email address
            </button>
          ) : (
            <></>
          )}
        </ModalBody>
      </Modal>
      <Modal
        isOpen={modalOpen2}
        toggle={toggleModal2}
        className="phone-numbers-modal setting-modal-default-modal"
      >
        <ModalHeader toggle={toggleModal2}>
          <h4>Phone Numbers </h4>
        </ModalHeader>
        <ModalBody>
          <p className="gray-light400">
            Want to add your number now? It's a handy way to reset your password
            if you have problems.
          </p>
          <p className="gray-light400">
            Your phone number helps us keep your account secure by adding an
            additional layer of verification. It also helps others, who already
            have your phone number, discover and connect with you. You can
            always decide how you want your phone number used
          </p>
          <div className="input-box">
            <label className="gray-light600">Phone Nmber</label>
            <Input type="number" placeholder="Enter Phone Number" />
          </div>
          <div className="input-box">
            <label className="gray-light600">
              Enter your NobelPage password
            </label>
            <Input type="password" placeholder="Enter Password" />
          </div>
          <div className="button-outer text-center">
            <button className="btn btn-blue">Send Code</button>
          </div>
        </ModalBody>
      </Modal>
      <Modal
        isOpen={modalOpen3}
        toggle={toggleModal3}
        className="logged-location-modal setting-modal-default-modal"
      >
        <ModalHeader toggle={toggleModal3}>
          <h4>Logged in locations</h4>
        </ModalHeader>
        <ModalBody>
          <p className="gray-light600 mt-0">
            You're signed in to {sessions.length + 1} locations
          </p>
          <p className="gray-light400">
            Manage your active sessions and sign-ins.
          </p>
          <h5>Current location</h5>
          <div className="d-flex">
            <img
              src={
                mySession && mySession.os.name == "Windows"
                  ? loggedSession1
                  : mySession && mySession.os.name == "Android"
                  ? loggedSession3
                  : loggedSession4
              }
              alt="Icon"
            />
            <div className="system-location">
              <h6>
                {currentSession && currentSession.location
                  ? currentSession.location
                  : "N/A"}
              </h6>
              <div className="os-system">
                {mySession && mySession.os.name} on{" "}
                {mySession && mySession.browser.name}
                <span className="ip-address">
                  IP Address: {currentSession && currentSession.ip_address}
                </span>
              </div>
            </div>
          </div>
          {sessions && sessions.length > 0 ? (
            <>
              <h5>Other active locations</h5>
              <button
                className="btn btn-blue"
                onClick={() => handleAllDelete()}
              >
                Log out from all these locations
              </button>
            </>
          ) : (
            <></>
          )}
          {sessions && sessions.length > 0 ? (
            sessions.map((ele) => {
              const { browser, os } = UAParser(ele.user_agent);
              return (
                <div className="d-flex justify-content-between list-item">
                  <div className="system-location">
                    <div className="d-flex">
                      <img
                        src={
                          os.name == "Windows"
                            ? loggedSession1
                            : os.name == "Android"
                            ? loggedSession3
                            : loggedSession4
                        }
                        alt="Icon"
                      />
                      <div>
                        <h6>{ele.location ? ele.location : "N/A"}</h6>
                        <div className="os-system">
                          {os.name} on {browser.name}
                          <span className="ip-address">
                            IP Address: {ele.ip_address}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <p
                    className="logout-btn"
                    onClick={() => handleSingleDelete(ele.id)}
                  >
                    Log out
                  </p>
                </div>
              );
            })
          ) : (
            <></>
          )}
          {/* <div className='d-flex justify-content-between list-item'>
            <div className='system-location'>
              <div className='d-flex'>
                <img src={loggedSession3} alt='Icon' />
                <div>
                  <h6>Calicut, Kerala, India</h6>
                  <div className='os-system'>
                    MacOS on Safari
                    <span className='ip-address'>IP Address: 103.119.255.168</span>
                  </div>
                </div>
              </div>
            </div>
            <button to='/'>Log out</button>
          </div>
          <div className='d-flex justify-content-between list-item'>
            <div className='system-location'>
              <div className='d-flex'>
                <img src={loggedSession4} alt='Icon' />
                <div>
                  <h6>Calicut, Kerala, India</h6>
                  <div className='os-system'>
                    MacOS on Safari
                    <span className='ip-address'>IP Address: 103.119.255.168</span>
                  </div>
                </div>
              </div>
            </div>
            <button to='/'>Log out</button>
          </div> */}
        </ModalBody>
      </Modal>
      <Modal
        isOpen={modalOpen4}
        toggle={toggleModal4}
        className="change-password-modal setting-modal-default-modal"
      >
        <ModalHeader toggle={toggleModal4}>
          <h4>Change Password</h4>
        </ModalHeader>
        <ModalBody>
          <p className="gray-light400">
            Enter the email address associated with the account you wish to
            merge into the account you are keeping.
          </p>
          <div className="input-box">
            <FormInput
              label="Current Password"
              icon={pass}
              type="password"
              onChange={(e) => setPass1(e.target.value)}
              onBlur={() => checkPassChangeValidation("pass1")}
              placeholder="Enter Current Password"
              // onKeyDown={(e) => handleEnterSubmit(e)}
              error={pass1Error}
              containerClassName={pass1Error ? "error-form" : ""}
            />
          </div>
          <div className="input-box">
            <FormInput
              label="New Password"
              icon={pass}
              type="password"
              onChange={(e) => setPass2(e.target.value)}
              onBlur={() => checkPassChangeValidation("pass2")}
              placeholder="Enter New Password"
              // onKeyDown={(e) => handleEnterSubmit(e)}
              error={pass2Error}
              containerClassName={pass2Error ? "error-form" : ""}
            />
          </div>
          <div className="input-box">
            <FormInput
              label="Confrim New Password"
              icon={pass}
              type="password"
              onChange={(e) => setPass3(e.target.value)}
              onBlur={() => checkPassChangeValidation("pass3")}
              placeholder="Enter Password"
              // onKeyDown={(e) => handleEnterSubmit(e)}
              error={pass3Error}
              containerClassName={pass3Error ? "error-form" : ""}
            />
          </div>
          <div className="button-outer d-flex flex-column">
            {/* <button className='add-btn'>Forgot Password</button> */}
            <button
              className="btn btn-blue mt-3"
              onClick={() => handlePassChange()}
            >
              Update Password
            </button>
          </div>
        </ModalBody>
      </Modal>
      <Modal
        isOpen={modalOpen5}
        toggle={toggleModal5}
        className="phone-numbers-modal setting-modal-default-modal"
      >
        <ModalHeader toggle={toggleModal5}>
          <h4>Two Factor Authentication</h4>
        </ModalHeader>
        <ModalBody>
          <p className="gray-light400 mt-0">
          Enhance security by managing two-factor authentication.
          </p>
          <div className="d-flex align-items-center justify-content-between">
            <h6 className="gray-light600">Enable Two Factor Authentication</h6>
            <div className="switch-box">
              <label className="switch">
                <input
                  type="checkbox"
                  checked={Boolean(
                    twoFactorSetting?.length &&
                      parseInt(twoFactorSetting[0]?.value)
                  )}
                  onChange={(e) => handleTwoFactorChange(e.target.checked)}
                />
                <span className="slider round"></span>
              </label>
            </div>
          </div>
          <p className="gray-light400">
          Two-factor authentication adds an extra layer of security to your account by requiring a second form of verification in addition to your password.
          </p>
          <div className="input-box">
            <label className="gray-light600">Phone Number</label>
            <MobileInput
              value={profileData && profileData.phone}
              placeholder="Number"
              inputType="page"
              disabled
            />
          </div>
          <div className="button-outer text-center">
            <button
              className="btn btn-blue"
              onClick={() => handleTwoFactorSave()}
            >
              Save
            </button>
          </div>
        </ModalBody>
      </Modal>
    </div>
  );
};

export default Security;
