import React from "react";
import TextInput from "react-autocomplete-input";
import "react-autocomplete-input/dist/bundle.css";
import GifModal from "../../../../core/components/common/GifModal";
import celebBackground from "../../../../assets/img/celebBackground.jpg";
import { useNavigate } from "react-router-dom";

const ShowCelebration = (props) => {
  const {
    celebrationData,
    nextModal,
    home,
    handleRequestOptions,
    hashtagList,
    textAreaRef,
    image_text,
  } = props;
  // console.log(celebrationData, "celebrationData");
  const navigate = useNavigate();

  return (
    <>
      {home === "true" ? (
        <div className="form-control border-none showcelebpost px-0">
          {celebrationData.media ? (
            <div className="form-control border-none showcelebpost px-0">
              <img src={celebrationData?.media} alt="thumbnail" width="100%" />
            </div>
          ) : (
            <ul className="imageList celebrationPost">
              <li>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <h3
                    style={{
                      alignSelf: "center",
                      textAlign: "center",
                      color: "black",
                    }}
                  >
                    {image_text}
                  </h3>
                  <div
                    className=""
                    style={{
                      // marginTop: "170px",
                      // marginLeft: "-200px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      width: "100%",
                    }}
                  >
                    {celebrationData?.celebration_tag?.length > 0 &&
                      celebrationData?.celebration_tag
                        ?.slice(0, 2)
                        ?.map((item, index) => {
                          if (
                            !item ||
                            !item.user ||
                            !item.user.first_name ||
                            !item.user.last_name
                          ) {
                            return null; // Skip rendering if item or necessary properties are missing
                          }
                          return (
                            <p
                              key={index}
                              className=" ml-2 mb-3 selectedReceipient border-0 bg-transparent"
                            >
                              <span
                                className="card-control-text-twoline"
                                onClick={() => {
                                  navigate(`/profile/${item.user_id}`);
                                }}
                              >
                                {item.user.first_name +
                                  " " +
                                  item.user.last_name}
                              </span>
                            </p>
                          );
                        })}
                    {celebrationData?.celebration_tag?.length > 2 && (
                      <p className="col-2 ml-2 mb-3 selectedReceipient">
                        <span className="card-control-text-oneline">
                          {celebrationData?.celebration_tag?.length - 2} +
                        </span>
                      </p>
                    )}
                  </div>
                </div>
                <div className="celebImages">
                  <img
                    src={celebrationData?.Celebrate_Type?.media}
                    alt="celebration_image"
                  />
                </div>
              </li>
            </ul>
          )}
          {/* <div className="row">
            {celebrationData?.celebration_tag?.map((item, index) => {
              if (
                !item ||
                !item.user ||
                !item.user.first_name ||
                !item.user.last_name
              ) {
                return null; // Skip rendering if item or necessary properties are missing
              }
              return (
                <p
                  key={index}
                  className="fs-5 col-3 ml-2 mb-3 selectedReceipient"
                >
                  <span className="card-control-text-oneline">
                    {item.user.first_name + " " + item.user.last_name}
                  </span>
                </p>
              );
            })}
          </div> */}
        </div>
      ) : (
        <div className="form-control border-none">
          <div className="col-12 create-post">
            <TextInput
              trigger={["#"]}
              options={{ "#": hashtagList }}
              ref={textAreaRef}
              className="form-control border-none"
              value={celebrationData?.title}
              onRequestOptions={handleRequestOptions}
              onChange={(e, val) => {
                if (!val) {
                  nextModal(e);
                }
              }}
              onSelect={(val, obj) => {
                nextModal(val);
              }}
            />
          </div>
          {celebrationData?.media ? (
            <div className="form-control border-none showcelebpost px-0">
              <img
                src={celebrationData?.media}
                alt="celebration_image"
                width="100%"
              />
            </div>
          ) : (
            <ul className="imageList celebrationPost">
              <li>
                <h3
                  style={{
                    alignSelf: "center",
                    textAlign: "center",
                    color: "black",
                  }}
                >
                  {image_text}
                </h3>
                <div className="celebImages">
                  <img src={celebrationData?.images} alt="images" />
                </div>
              </li>
            </ul>
          )}
          {console.log(celebrationData, "celebrationData")}
          <div className="row ">
            {celebrationData?.celebration_tags?.length > 0 &&
              celebrationData?.celebration_tags?.map((item, index) => (
                <p
                  key={index}
                  className="fs-5 col-3 ml-2 mb-3 selectedReceipient "
                >
                  <span className=" card-control-text-oneline">
                    {" "}
                    {item.first_name + " " + item.last_name}
                  </span>
                </p>
              ))}
          </div>
        </div>
      )}
    </>
  );
};

export default ShowCelebration;
