import { call } from "./apiCall"


class HashtagApi {

 getHashtagList(query, currentCount) {
    return new Promise( (resolve, reject) => {
        const token = localStorage.getItem("accessToken");
          (async () => {
            try {
                const res = await call(
                    "get",
                    query? `api/v1/hashtag?filter=${query}&limit=${20}&skip=${currentCount}`:  `api/v1/hashtag`,
                    null,
                    null,
                    token
                )
                if(res?.data?.success===true){
                    resolve(res?.data)
              }
              if(res?.data?.success===false){
                resolve(res?.data)
              }

            } catch (err) {
                console.log(err)
                reject(err)
            }
        })()
    })
}

followHashtag(data) {
  return new Promise( (resolve, reject) => {
      const token = localStorage.getItem("accessToken");
        (async () => {
          try {
              const res = await call(
                  "post",
                  `api/v1/feed/hashtag`,
                  null,
                  data,
                  token
              )
              if(res.data.success===true){
                  resolve(res.data)
            }
            if(res.data.success===false){
              resolve(res.data)
            }

          } catch (err) {
              console.log(err)
              reject(err)
          }
      })()
  })
}

getHashTagPostFeed(data) {
  return new Promise( (resolve, reject) => {
      const token = localStorage.getItem("accessToken");
        (async () => {
          try {
              const res = await call(
                  "get",
                  `api/v1/feed/hashtag/${data.keyword}?limit=${data.limit}&skip=${data.skip}`,
                  null,
                  null,
                  token
              )
              if(res.data.success===true){
                  resolve(res.data)
            }
            if(res.data.success===false){
              resolve(res.data)
            }

          } catch (err) {
              console.log(err)
              reject(err)
          }
      })()
  })
}
}

 export const hashtagApi = new HashtagApi()