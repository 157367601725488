import { GET_INDUSTRY_DATA, GET_HASHTAG_LIST } from "../types";
import { masterApi } from "../../api/masterApi";

export const getIndustryData = () => (dispatch) => {
    return masterApi.getIndustryData().then(
      (res) => {
        dispatch({
          type:  GET_INDUSTRY_DATA,
          payload: res.data,
        });
  
        return Promise.resolve(res);
      },
      (error) => {
        return Promise.reject(error);
      }
    );
  };

  export const getHashTagList = (searchData) => (dispatch) => {
    return masterApi.getHashTagList(searchData).then(
      (res) => {
        dispatch({
          type:  GET_HASHTAG_LIST,
          payload: res.data,
        });
  
        return Promise.resolve(res);
      },
      (error) => {
        return Promise.reject(error);
      }
    );
  };

      