import React from 'react';

function Tick(props) {
    return (
        <svg 
            width="12" 
            height="9" 
            viewBox="0 0 12 9" 
            fill="none" 
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path 
                d="M10.6654 1.5L4.2487 7.91667L1.33203 5" 
                stroke={props.color || "#008C39"} 
                strokeWidth="1.5" 
                strokeLinecap="round" 
                strokeLinejoin="round" 
            />
        </svg>
    )
}

export default Tick;