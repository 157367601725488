import React from "react";
import "./createOrder.scss";
import ArrowIcon from "../../../assets/img/blackDownArrow.svg";
import PackageForm from "./PackageForm";
import BasicInfoForm from "./BasicInfoForm";
import CriminalForm from "./CriminalForm";
import AuthorizationForm from "./AuthorizationForm";
import EmploymentForm from "./EmploymentForm";
import EducationForm from "./EducationForm";
import Navbar from "../../../pages/navbar/navbar";
import Footer from "../footer";
import backIcon from "../../../assets/img/Back_Icons.svg";
import { useNavigate } from "react-router-dom";
import { ToastContainer } from "react-toastify";
const CreateOrder = ({
  step,
  setStep,
  user,
  completedStep,
  setCompletedStep,
  activeStep,
  setActiveStep,
  data,
  // setData,
  selectedCountry,
  setSelectedCountry,
  setSelectedState,
  selectedState,
  editMode,
}) => {
  const navigate = useNavigate();
  console.log(editMode);
  return (
    <>
      <Navbar isJobs={false} />
      <div className="container mt-2 mb-2">
        <div className="d-flex ">
          <img
            src={backIcon}
            width={25}
            onClick={() => navigate("/background-report")}
          />
          <h4 className="mx-3 font-normal">Create Order</h4>
        </div>
      </div>
      <div className="bg-white create_order">
        <header className="background_screen_header">
          <div className="container">
            <div className="pt-4" style={{ width: "70%" }}>
              <h4 className="text-white w-100">
                Unlock Your Potential with NobelPage Verification and Earn Truth
                Badges!
              </h4>
              <p className="text-white mt-3">
                Step into the spotlight with NobelPage's exclusive verification
                services, powered by our esteemed globally accepted partners.
                It's time to amplify your profile and stand out with the power
                of transparency and trust.
              </p>
            </div>
          </div>
        </header>
        <div className="container">
          <div className="crousal_container mt-3 p-3">
            <div className="">
              <div
                className="d-flex justify-content-between align-items-center w-100 border-bottom p-3 p-2"
                style={
                  activeStep == 1
                    ? { background: "#0432A30D" }
                    : { background: "transparent" }
                }
              >
                <div className="d-flex align-items-center">
                  <div
                    className="stepper d-flex justify-content-center align-items-center px-3 py-2"
                    style={
                      activeStep == 1
                        ? { background: "#0432A333" }
                        : { background: "#676A7933" }
                    }
                  >
                    <p
                      className="fw-bold"
                      style={
                        activeStep == 1
                          ? { color: "#0432A3" }
                          : { color: "#676A79" }
                      }
                    >
                      Step 1
                    </p>
                  </div>
                  <h6 className="mx-4 text-dark">Select your package</h6>
                </div>
                <button
                  className="border rounded-circle p-1"
                  onClick={(e) => {
                    activeStep != 1 ? setActiveStep(1) : setActiveStep(0);
                  }}
                  disabled={!completedStep?.includes("step1")}
                >
                  <img src={ArrowIcon} width={25} />
                </button>
              </div>
              {activeStep == 1 && (
                <PackageForm
                  experience={user?.experiences}
                  education={user?.educations}
                  // step={step}
                  // setStep={setStep}
                  completedStep={completedStep}
                  setCompletedStep={setCompletedStep}
                  activeStep={activeStep}
                  setActiveStep={setActiveStep}
                  data={data}
                  user={user}
                  // setData={setData}
                />
              )}
            </div>
            {Object.keys(data?.basicInfo).length > 0 && (
              <>
                <div
                  className=""
                  style={
                    activeStep == 2
                      ? { background: "#0432A30D" }
                      : { background: "transparent" }
                  }
                >
                  <div className="d-flex justify-content-between align-items-center w-100 border-bottom p-3 p-2">
                    <div className="d-flex align-items-center">
                      <div
                        className="stepper d-flex justify-content-center align-items-center px-3 py-2"
                        style={
                          activeStep == 2
                            ? { background: "#0432A333" }
                            : { background: "#676A7933" }
                        }
                      >
                        <p
                          className="fw-bold"
                          style={
                            activeStep == 2
                              ? { color: "#0432A3" }
                              : { color: "#676A79" }
                          }
                        >
                          Step 2
                        </p>
                      </div>
                      <h6 className="mx-4 text-dark">Basic Information</h6>
                    </div>
                    <button
                      className="border rounded-circle p-1"
                      onClick={(e) => {
                        activeStep != 2 ? setActiveStep(2) : setActiveStep(0);
                      }}
                      disabled={!completedStep?.includes("step2")}
                    >
                      <img src={ArrowIcon} width={25} />
                    </button>
                  </div>
                </div>
                {activeStep == 2 && (
                  <BasicInfoForm
                    user={user}
                    // step={step}
                    // setStep={setStep}
                    completedStep={completedStep}
                    setCompletedStep={setCompletedStep}
                    activeStep={activeStep}
                    setActiveStep={setActiveStep}
                    data={data}
                    editMode={editMode}
                    // setData={setData}
                    // selectedCountry={selectedCountry}
                    // setSelectedCountry={setSelectedCountry}
                    // setSelectedState={setSelectedState}
                    // selectedState={selectedState}
                  />
                )}
              </>
            )}
            {data?.employment?.map((ele, i) => {
              return (
                <>
                  <div
                    className=""
                    style={
                      activeStep == i + 3
                        ? { background: "#0432A30D" }
                        : { background: "transparent" }
                    }
                  >
                    <div className="d-flex justify-content-between align-items-center w-100 border-bottom p-3 p-2">
                      <div className="d-flex align-items-center">
                        <div
                          className="stepper d-flex justify-content-center align-items-center px-3 py-2"
                          style={
                            activeStep == i + 3
                              ? { background: "#0432A333" }
                              : { background: "#676A7933" }
                          }
                        >
                          <p
                            className="fw-bold"
                            style={
                              activeStep == i + 3
                                ? { color: "#0432A3" }
                                : { color: "#676A79" }
                            }
                          >
                            Step {i + 3}
                          </p>
                        </div>
                        <h6 className="mx-4 text-dark">Employment[#{i + 1}]</h6>
                      </div>
                      <button
                        className="border rounded-circle p-1"
                        onClick={(e) => {
                          activeStep != i + 3
                            ? setActiveStep(i + 3)
                            : setActiveStep(0);
                        }}
                        disabled={!completedStep?.includes(`step${i + 3}`)}
                      >
                        <img src={ArrowIcon} width={25} />
                      </button>
                    </div>
                  </div>
                  {activeStep == i + 3 && (
                    <EmploymentForm
                      user={user}
                      ele={ele}
                      index={i}
                      // step={step}
                      // setStep={setStep}
                      completedStep={completedStep}
                      setCompletedStep={setCompletedStep}
                      activeStep={activeStep}
                      setActiveStep={setActiveStep}
                      data={data}
                      editMode={editMode}

                      // setData={setData}
                    />
                  )}
                </>
              );
            })}

            {data?.education?.map((ele, i) => {
              return (
                <>
                  <div
                    className=""
                    style={
                      activeStep == data?.employment.length + i + 3
                        ? { background: "#0432A30D" }
                        : { background: "transparent" }
                    }
                  >
                    <div className="d-flex justify-content-between align-items-center w-100 border-bottom p-3 p-2">
                      <div className="d-flex align-items-center">
                        <div
                          className="stepper d-flex justify-content-center align-items-center px-3 py-2"
                          style={
                            activeStep == data?.employment.length + i + 3
                              ? { background: "#0432A333" }
                              : { background: "#676A7933" }
                          }
                        >
                          <p
                            className="fw-bold"
                            style={
                              activeStep == data?.employment.length + i + 3
                                ? { color: "#0432A3" }
                                : { color: "#676A79" }
                            }
                          >
                            Step {data?.employment.length + i + 3}
                          </p>
                        </div>
                        <h6 className="mx-4 text-dark">Education[#{i + 1}]</h6>
                      </div>
                      <button
                        className="border rounded-circle p-1"
                        onClick={(e) => {
                          activeStep != data?.employment?.length + i + 3
                            ? setActiveStep(data?.employment?.length + i + 3)
                            : setActiveStep(0);
                        }}
                        disabled={
                          !completedStep?.includes(
                            `step${data?.employment?.length + i + 3}`
                          )
                        }
                      >
                        <img src={ArrowIcon} width={25} />
                      </button>
                    </div>
                  </div>
                  {activeStep == data?.employment?.length + i + 3 && (
                    <EducationForm
                      user={user}
                      ele={ele}
                      index={i}
                      // step={step}
                      // setStep={setStep}
                      completedStep={completedStep}
                      setCompletedStep={setCompletedStep}
                      activeStep={activeStep}
                      setActiveStep={setActiveStep}
                      data={data}
                      editMode={editMode}

                      // setData={setData}
                    />
                  )}
                </>
              );
            })}
            {Object.keys(data?.criminal).length > 0 && (
              <>
                <div
                  className=""
                  style={
                    activeStep ==
                    data?.employment?.length + data?.education?.length + 3
                      ? { background: "#0432A30D" }
                      : { background: "transparent" }
                  }
                >
                  <div className="d-flex justify-content-between align-items-center w-100 border-bottom p-3 p-2">
                    <div className="d-flex align-items-center">
                      <div
                        className="stepper d-flex justify-content-center align-items-center px-3 py-2"
                        style={
                          activeStep ==
                          data?.employment?.length + data?.education?.length + 3
                            ? { background: "#0432A333" }
                            : { background: "#676A7933" }
                        }
                      >
                        <p
                          className="fw-bold"
                          style={
                            activeStep ==
                            data?.employment?.length +
                              data?.education?.length +
                              3
                              ? { color: "#0432A3" }
                              : { color: "#676A79" }
                          }
                        >
                          Step{" "}
                          {data?.employment?.length +
                            data?.education?.length +
                            3}
                        </p>
                      </div>
                      <h6 className="mx-4 text-dark">Criminal / WorldScan</h6>
                    </div>
                    <button
                      className="border rounded-circle p-1"
                      onClick={(e) => {
                        activeStep !=
                        data?.employment?.length + data?.education?.length + 3
                          ? setActiveStep(
                              data?.employment?.length +
                                data?.education?.length +
                                3
                            )
                          : setActiveStep(0);
                      }}
                      disabled={
                        !completedStep?.includes(
                          `step${
                            data?.employment?.length +
                            data?.education?.length +
                            3
                          }`
                        )
                      }
                    >
                      <img src={ArrowIcon} width={25} />
                    </button>
                  </div>
                </div>
                {activeStep ==
                  data?.education.length + data?.employment.length + 3 && (
                  <CriminalForm
                    user={user}
                    // step={step}
                    // setStep={setStep}
                    completedStep={completedStep}
                    setCompletedStep={setCompletedStep}
                    activeStep={activeStep}
                    setActiveStep={setActiveStep}
                    data={data}
                    editMode={editMode}

                    // setData={setData}
                  />
                )}
              </>
            )}
            {Object.keys(data?.authorization).length > 0 && (
              <>
                <div
                  className=""
                  style={
                    activeStep ==
                    (Object.keys(data?.criminal).length > 0
                      ? data?.employment?.length + data?.education?.length + 4
                      : data?.employment?.length + data?.education?.length + 3)
                      ? { background: "#0432A30D" }
                      : { background: "transparent" }
                  }
                >
                  <div className="d-flex justify-content-between align-items-center w-100 border-bottom p-3 p-2">
                    <div className="d-flex align-items-center">
                      <div
                        className="stepper d-flex justify-content-center align-items-center px-3 py-2"
                        style={
                          activeStep ===
                          (Object.keys(data?.criminal).length > 0
                            ? data?.employment?.length +
                              data?.education?.length +
                              4
                            : data?.employment?.length +
                              data?.education?.length +
                              3)
                            ? { background: "#0432A333" }
                            : { background: "#676A7933" }
                        }
                      >
                        <p
                          className="fw-bold"
                          style={
                            activeStep ===
                            (Object.keys(data?.criminal).length > 0
                              ? data?.employment?.length +
                                data?.education?.length +
                                4
                              : data?.employment?.length +
                                data?.education?.length +
                                3)
                              ? { color: "#0432A3" }
                              : { color: "#676A79" }
                          }
                        >
                          Step{" "}
                          {Object.keys(data?.criminal).length > 0
                            ? data?.employment?.length +
                              data?.education?.length +
                              4
                            : data?.employment?.length +
                              data?.education?.length +
                              3}
                        </p>
                      </div>
                      <h6 className="mx-4 text-dark">
                        Authorization and Privacy Confirmation
                      </h6>
                    </div>
                    <button
                      className="border rounded-circle p-1"
                      onClick={() => {
                        const isCriminalDataExists =
                          Object.keys(data?.criminal).length > 0;
                        const stepLength =
                          data?.employment?.length + data?.education?.length;
                        const nextStep = isCriminalDataExists
                          ? stepLength + 4
                          : stepLength + 3;

                        activeStep === nextStep
                          ? setActiveStep(nextStep)
                          : setActiveStep(0);
                      }}
                      disabled={
                        !completedStep?.includes(
                          `step${
                            Object.keys(data?.authorization).length > 0
                              ? data?.employment?.length +
                                data?.education?.length +
                                4
                              : data?.employment?.length +
                                data?.education?.length +
                                3
                          }`
                        )
                      }
                    >
                      <img src={ArrowIcon} width={25} />
                    </button>
                  </div>
                </div>
                {activeStep ===
                  (Object.keys(data?.criminal).length > 0
                    ? data?.employment?.length + data?.education?.length + 4
                    : data?.employment?.length +
                      data?.education?.length +
                      3) && (
                  <AuthorizationForm
                    data={data}
                    editMode={editMode}
                    user={user}
                  />
                )}
              </>
            )}
          </div>
          <Footer />
          <ToastContainer />
        </div>
      </div>
    </>
  );
};

export default CreateOrder;
