import React from "react";
import { Modal, ModalHeader } from "reactstrap";
import "../newsletter.scss";
import DOMPurify from "dompurify";
import { useSelector } from "react-redux";
import moment from "moment";
import placeholderUser from "../../../../assets/img/placeholder_user.jpg";
import noPreview from "../../../../assets/img/no-preview.png";
import { useNavigate } from "react-router-dom";

const NewsletterPreviewModal = (props) => {
  const {
    modal,
    toggle,
    title,
    convertedContent,
    newsletterData,
    bannerImage,
    pageIcon,
    pageName,
  } = props;

  const { user } = useSelector((state) => state.auth);

  function createMarkup(html) {
    return {
      __html: DOMPurify.sanitize(html),
    };
  }

  const navigate = useNavigate();

  return (
    <div>
      <Modal isOpen={modal} size="lg">
        <div className="create-post">
          <ModalHeader toggle={toggle}>{title}</ModalHeader>
          <div className="article">
            <div className="wrapper bg-white">
              <div className="box-container mt-10 ml-10 mr-10 ">
                <img
                  src={bannerImage ? bannerImage : noPreview}
                  className="newsletter-image"
                  alt=""
                />
              </div>
              <div className="row">
                <div className="col-lg-12">
                  <div className="mr-30 ml-30">
                    <div className="d-flex justify-content-between align-items-center  ">
                      <div className="profiles mb-20 mt-20 ">
                        <div
                          className="img"
                          onClick={() => navigate(`/profile/${user?.data?.id}`)}
                        >
                          <img
                            src={
                              user?.data?.profile_img
                                ? user?.data?.profile_img
                                : placeholderUser
                            }
                            className="image-fit curserPointer"
                            style={{ width: "36px" }}
                            alt=""
                          />
                        </div>
                        <div className="details">
                          <h6
                            className="card-control-text-oneline"
                            onClick={() =>
                              navigate(`/profile/${user?.data?.id}`)
                            }
                          >
                            {pageName
                              ? pageName
                              : `${user?.data?.first_name} ${" "} ${
                                  user?.data?.last_name
                                }`}
                          </h6>
                          <p className="card-control-text-twoline">
                            {user?.data?.profile_headline ? (
                              <>{user?.data?.profile_headline}</>
                            ) : (
                              ""
                            )}
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="d-flex gap-5 mb-20">
                      <div className="hrs">
                        {moment().startOf("minute").fromNow()}
                      </div>
                    </div>
                    <div className="text">
                      <h4>{newsletterData?.title}</h4>
                      <div
                        dangerouslySetInnerHTML={createMarkup(convertedContent)}
                      ></div>
                    </div>
                  </div>
                  <div className="line mr-30 ml-30 mb-20"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default NewsletterPreviewModal;
