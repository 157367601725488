import React from 'react'
import ReactPaginate from 'react-paginate'
import styles from './pagination.module.css';
import left from "../../../assets/img/circleleft-arrow.svg"
import right from "../../../assets/img/circleright-arrow.svg"

export default function Pagination({ handlePageClick, pageCount, pageRangeDisplayed }) {
 return (
  <>
   <ReactPaginate
    breakLabel="..."
    nextLabel={<img src={right} style={{height:"39px"}} />}
    onPageChange={handlePageClick}
    pageRangeDisplayed={pageRangeDisplayed || 6}
    pageCount={pageCount}
    previousLabel={<img src={left} style={{height:"39px"}}/>}
    renderOnZeroPageCount={null}
    containerClassName={styles.pagination}
    pageLinkClassName={styles.pageNum}
    previousLinkClassName={styles.previousicon}
    nextLinkClassName={styles.nexticon}
    activeLinkClassName={styles.pageNum}
   />
  </>
 )
}
