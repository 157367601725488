import React from 'react';

function Close(props) {
    return (
        <svg 
            width="15" 
            height="15" 
            viewBox="0 0 15 15" 
            fill="none" 
            xmlns="http://www.w3.org/2000/svg" 
            {...props}
        >
            <path 
                d="M11.25 3.75L3.75 11.25" 
                stroke={props.color || "#D9534F"}
                stroke-width="0.9375" 
                stroke-linecap="round" 
                stroke-linejoin="round" 
            />
            <path 
                d="M3.75 3.75L11.25 11.25" 
                stroke={props.color || "#D9534F"}
                stroke-width="0.9375" 
                stroke-linecap="round" 
                stroke-linejoin="round" 
            />
        </svg>

    )
}

export default Close;