import {
  GET_SUGGESTED_ARTICLES,
  GET_MY_ARTICLES,
  GET_DRAFT_ARTICLE,
  CREATE_MY_ARTICLE,
  GET_ARTICLE_DETAIL,
  EDIT_MY_ARTICLE,
  EDIT_MY_ARTICLE_DETAILS,
  LOAD_MORE_MY_ARTICLES,
  LOAD_MORE_SUGGESTED_ARTICLES,
  LOAD_MORE_DRAFT_ARTICLE,
  GET_PAGE_ARTICLES,
  LIKE_ARTICLE,
  UNLIKE_ARTICLE,
  COMMENT_ARTICLE,
  GET_ARTICLE_COMMENT_LIST,
  GET_MORE_ARTICLE_COMMENT_LIST,
} from "../types";
import { articleApi } from "../../api/articleApi";
import { initPageLoad } from "../loader/loaderActions";
import { stopPageLoad } from "../loader/loaderActions";

export const getSuggestedArticles =
  (data, query, params, currentCount) => (dispatch) => {
    dispatch(initPageLoad());

    return articleApi.getArticles(data, params).then(
      (articles) => {
        if (query === "my") {
          if (currentCount === 0) {
            dispatch({
              type: GET_MY_ARTICLES,
              payload: articles.data,
            });
            return Promise.resolve(articles);
          } else
            dispatch({
              type: LOAD_MORE_MY_ARTICLES,
              payload: articles.data,
            });
          dispatch(stopPageLoad());
          return Promise.resolve(articles);
        } else if (query === "draft") {
          if (currentCount === 0) {
            dispatch({
              type: GET_DRAFT_ARTICLE,
              payload: articles.data,
            });
            return Promise.resolve(articles);
          } else
            dispatch({
              type: LOAD_MORE_DRAFT_ARTICLE,
              payload: articles.data,
            });
          dispatch(stopPageLoad());
          return Promise.resolve(articles);
        } else if (query === "page") {
          dispatch({
            type: GET_PAGE_ARTICLES,
            payload: articles.data,
          });
          dispatch(stopPageLoad());
          return Promise.resolve(articles);
        } else {
          if (currentCount === 0) {
            dispatch({
              type: GET_SUGGESTED_ARTICLES,
              payload: articles.data,
            });
            return Promise.resolve(articles);
          } else
            dispatch({
              type: LOAD_MORE_SUGGESTED_ARTICLES,
              payload: articles.data,
            });
          dispatch(stopPageLoad());
          return Promise.resolve(articles);
        }
      },
      (error) => {
        console.log(error);
        return Promise.reject(error);
      }
    );
  };

export const createArticle = (data, token) => (dispatch) => {
  dispatch(initPageLoad());
  return articleApi.createArticle(data, token).then((article) => {
    dispatch({
      type: CREATE_MY_ARTICLE,
      payload: data,
    });
    dispatch(stopPageLoad());
    return Promise.resolve(article.data);
  });
};

export const editArticle = (data, token) => (dispatch) => {
  dispatch(initPageLoad());
  return articleApi.editArticle(data, token).then((article) => {
    dispatch({
      type: EDIT_MY_ARTICLE,
      payload: article.data,
    });
    dispatch({
      type: EDIT_MY_ARTICLE_DETAILS,
      payload: article.data,
    });
    dispatch(stopPageLoad());
    return Promise.resolve(true);
  });
};

export const getArticleDetail = (id, token) => (dispatch) => {
  dispatch(initPageLoad());
  articleApi.getArticleDetail(id, token).then((article) => {
    dispatch({
      type: GET_ARTICLE_DETAIL,
      payload: article.data,
    });

    dispatch(stopPageLoad());
  });
};

export const likeArticle = (data, type) => (dispatch) => {
  dispatch(initPageLoad());
  articleApi.likeArticle(data).then((response) => {
    if (type === "like") {
      dispatch({
        type: LIKE_ARTICLE,
        payload: data,
      });
    } else if (type === "unlike") {
      dispatch({
        type: UNLIKE_ARTICLE,
        payload: data,
      });
    }
    dispatch(stopPageLoad());
  });
};

export const commentArticle = (data) => (dispatch) => {
  dispatch(initPageLoad());
  articleApi.commentArticle(data).then((response) => {
    dispatch({
      type: COMMENT_ARTICLE,
      payload: response.data,
    });

    dispatch(stopPageLoad());
  });
};

export const getCommentDetails = (data) => (dispatch) => {
  dispatch(initPageLoad());
  articleApi.getCommentDetails(data).then((response) => {
    if (data.skip === 0) {
      dispatch({
        type: GET_ARTICLE_COMMENT_LIST,
        payload: response,
      });
    } else {
      dispatch({
        type: GET_MORE_ARTICLE_COMMENT_LIST,
        payload: response,
      });
    }
    dispatch(stopPageLoad());
  });
};
