import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  savePost,
  savePostDelete,
  repost_post,
  hide_user_post,
  comment_status,
  postViewLog,
} from "../../../../store/dashboard/dashboardActions";
import {
  profile_follower,
  profile_unfollower,
  remove_connection,
} from "../../../../store/profile/profileActions";
import { reactionsData } from "../../../../store/reactionPost/reactionAction";
import { sendConnectionRequest } from "../../../../store/connections/connectionsActions";
import { followPage, unFollowPage } from "../../../../store/pages/pagesAction";
import moment from "moment";
import PostOnDashboard from "./PostOnDashboard";
import { toast } from "react-toastify";

const PostOnDashboardContainer = (props) => {
  const {
    item,
    index,
    isPagePosts,
    type,
    postNow,
    getPermissions,
    commentStatus,
    setCommentStatus,
    reportModal,
    setReportModal,
    reportType,
    setReportType,
    reportCommentId,
    setReportCommentId,
    roleShareModal,
    setRoleShareModal,
    setRolePageId,
    closeModal,
    setCloseModal,
    commentBoxStatus,
    setCommentBoxStatus,
    openShareModal,
    setOpenShareModal,
    setDeleteId,
    setShareData,
    editPost,
    setPostID,
    setByUserId,
    userId,
    userName,
    isViewReaction,
    setViewReaction,
  } = props;

  const [dropDown, setDropDown] = useState(-1);
  const [dropDownShare, setDropDownShare] = useState(-1);
  const [dropDownRepost, setDropDownRepost] = useState(-1);

  const dispatch = useDispatch();
  const dropRef = useRef(null);
  const dropRefShare = useRef(null);
  const dropRefRepost = useRef(null);

  const { user } = useSelector((state) => state.auth);

  const peopleFollowRequest = (id, mode, post_id) => {
    dispatch(profile_follower(id, mode, post_id));
  };
  const peopleUnFollowRequest = (id, mode, post_id) => {
    dispatch(profile_unfollower(id, mode, post_id));
  };
  const peopleRemoveConnection = (id, mode, post_id) => {
    dispatch(remove_connection(id, mode, post_id));
  };
  const handlePageFollow = (pageID, mode, post_id) => {
    dispatch(followPage(pageID, mode, post_id));
  };
  const handlePageUnFollow = (pageID, mode, post_id) => {
    dispatch(unFollowPage(pageID, mode, post_id));
  };

  //   const editPost = (postData) => {
  //     if (postData.Celebration) {
  //       alert("Cannot edit celebration post and feed docs ,docs");
  //     } else {
  //       setIsEdit(postData.id);
  //       setCreatePostModal(!createPostModal);
  //       setCreatePostClick("");
  //       setUpdateData(postData);
  //       setDropDown(-1);
  //     }
  //   };

  const handleCommentStatus = (postData, type) => {
    dispatch(comment_status(postData, type));
    setDropDown(-1);
  };

  const save_post = (id, i) => {
    let token = window.localStorage.getItem("accessToken");
    dispatch(savePost(id, token)).then((response) => {
      toast.success("Post Saved", {
        position: toast.POSITION.BOTTOM_LEFT,
      });
    });
  };

  const removeSavePost = (id) => {
    let token = window.localStorage.getItem("accessToken");
    dispatch(savePostDelete(id, token)).then((response) => {
      toast.success("Post Removed", {
        position: toast.POSITION.BOTTOM_LEFT,
      });
    });
  };

  function privacy_type(privacy_id) {
    if (privacy_id === 1) return " Public";
    if (privacy_id === 2) return " Connection";
    if (privacy_id === 3) return " Group";
    if (privacy_id === 4) return " Private";
  }

  useEffect(() => {
    document.addEventListener("mousedown", clickOutsideAdmin);
    return () => {
      document.removeEventListener("mousedown", clickOutsideAdmin);
    };
  }, [dropDown, dropDownShare, dropDownRepost]);

  const clickOutsideAdmin = (e) => {
    if (e.toElement && e?.toElement.className === "shareListing") {
      return;
    }
    if (e.toElement && e?.toElement.className === "listing") {
      return;
    }
    if (e.toElement && e?.toElement.className === "clickDots") {
      return;
    }
    if (dropRef?.current?.contains(e.target) && dropDown > -1) {
      return;
    }
    if (dropRefRepost?.current?.contains(e.target) && dropDownRepost > -1) {
      return;
    }
    setDropDown(-1);
    setDropDownShare(-1);
    setDropDownRepost(-1);
  };

  function handleReaction(item, reacted, type) {
    let data = {
      post_id: item.id,
      type: reacted,
      // id: byUserId !== 0 ? byUserId : item?.reaction?.byUser?.id,
    };
    if (item?.as_a_page) {
      Object.assign(data, { page_id: item?.as_a_page?.id });
    }
    dispatch(reactionsData(data, type)).then((res) => {
      setByUserId(res?.data[0]?.id);
    });
    // dispatch({type: LIKED_DASHBOARD_POST, payload: data});
  }

  const getHashtagText = (content) =>
    content
      ?.replace(/\n#/g, " \n#")
      .replace(/\n/g, " \n")
      ?.split(" ")
      ?.map((str, index) => {
        if ((str.startsWith("#") || str.startsWith("\n#")) && str.length > 1) {
          let keyword = str.startsWith("#")
            ? str.substring(1)
            : str.substring(2);
          return (
            <a
              href={`/hashtag/feed/?keyword=${keyword}`}
              className="text-blue-500"
              key={index}
            >
              {str}{" "}
            </a>
          );
        }
        return str + " ";
      });

  const createLink = (content) =>
    content?.map((str, index) => {
      return (
        <a
          href={`/profile/${str.user_id}`}
          className="text-blue-500"
          key={index}
        >
          {str?.user?.first_name} {str?.user?.last_name}{" "}
        </a>
      );
    });

  const sendRequest = (id, mode, post_id) => {
    dispatch(sendConnectionRequest(id, mode, post_id)).then((res) => {
      toast.success(res.message, {
        position: toast.POSITION.BOTTOM_LEFT,
      });
    });
  };

  const hideUserPost = (id) => {
    dispatch(hide_user_post(id)).then((reponse) => {
      toast.success("Post Hide Sucessfully!", {
        position: toast.POSITION.BOTTOM_LEFT,
      });
    });
    setDropDown(-1);
  };

  const postViewCounter = (id) => {
    const payload = {
      view_type: "post",
      post_id: id,
    };
    dispatch(postViewLog(payload));
  };

  const repostPost = (id, type, asPageId) => {
    // let repost_data = {
    //   type: "repost",
    //   shared_id: id,
    //   privacy_id: 1,
    // };
    let repost_data = {
      type: "repost",
      shared_id: id,
      privacy_id: 1,
      shared_by_type: type === "asPage" ? "page" : "user",
      shared_by: type === "asPage" ? asPageId : user?.data?.id,
    };
    dispatch(repost_post(repost_data)).then((res) => {
      setDropDownShare(-1);
      toast.success("Reposted successfully!", {
        position: toast.POSITION.BOTTOM_LEFT,
      });
    });
  };

  const likeResult = (arr1, arr2, type = []) => {
    let array3 = [];
    if (arr1 === undefined || arr1 === null) {
      array3 = arr2;
    } else {
      let arr4 = [{ type: arr1 }];
      array3 = arr4?.concat(
        arr2?.filter((bo) =>
          arr4?.every((ao) => ao?.type !== bo?.type && bo?.page_id !== null)
        )
      );
    }
    array3 = array3?.map((ao) => ({ type: ao?.type }));
    if (type?.length > 0) {
      array3 = array3?.concat(
        type?.filter((bo) => array3?.every((ao) => ao?.type !== bo?.type))
      );
    }
    return array3;
  };

  const getTime = (date) => {
    let result = moment(date).fromNow();
    const now = moment();
    const hours = now.diff(date, "hours");
    const days = now.diff(date, "days");
    const weeks = now.diff(date, "weeks");
    if (hours >= 22 && hours <= 47) {
      result = `1 day ago`;
    } else if (days >= 7) {
      if (days <= 13) {
        result = `1 week ago`;
      } else if (days > 13 && days <= 25) {
        result = `${weeks} weeks ago`;
      }
    }
    return result;
  };

  return (
    <PostOnDashboard
      item={item}
      index={index}
      userId={userId}
      user={user}
      handleReaction={handleReaction}
      isPagePosts={isPagePosts}
      userName={userName}
      setPostID={setPostID}
      isViewReaction={isViewReaction}
      setViewReaction={setViewReaction}
      privacy_type={privacy_type}
      dropDown={dropDown}
      setDropDown={setDropDown}
      editPost={editPost}
      save_post={save_post}
      removeSavePost={removeSavePost}
      dropRef={dropRef}
      dropRefShare={dropRefShare}
      getHashtagText={getHashtagText}
      createLink={createLink}
      reportModal={reportModal}
      setReportModal={setReportModal}
      reportType={reportType}
      setReportType={setReportType}
      reportCommentId={reportCommentId}
      setReportCommentId={setReportCommentId}
      hideUserPost={hideUserPost}
      commentBoxStatus={commentBoxStatus}
      setCommentBoxStatus={setCommentBoxStatus}
      dropDownShare={dropDownShare}
      setDropDownShare={setDropDownShare}
      type={type}
      repostPost={repostPost}
      likeResult={likeResult}
      sendRequest={sendRequest}
      peopleFollowRequest={peopleFollowRequest}
      peopleUnFollowRequest={peopleUnFollowRequest}
      peopleRemoveConnection={peopleRemoveConnection}
      handlePageFollow={handlePageFollow}
      handlePageUnFollow={handlePageUnFollow}
      getTime={getTime}
      postNow={postNow}
      commentStatus={commentStatus}
      setCommentStatus={setCommentStatus}
      handleCommentStatus={handleCommentStatus}
      closeModal={closeModal}
      setCloseModal={setCloseModal}
      setDeleteId={setDeleteId}
      postViewCounter={postViewCounter}
      openShareModal={openShareModal}
      setOpenShareModal={setOpenShareModal}
      setShareData={setShareData}
      getPermissions={getPermissions}
      dropDownRepost={dropDownRepost}
      setDropDownRepost={setDropDownRepost}
      dropRefRepost={dropRefRepost}
      roleShareModal={roleShareModal}
      setRoleShareModal={setRoleShareModal}
      setRolePageId={setRolePageId}
    />
  );
};

export default PostOnDashboardContainer;
