import React, { useState, useEffect } from "react";
import Groups from "./Groups";
import { useDispatch, useSelector } from "react-redux";
import {
  acceptGroups,
  getSuggestedGroups,
  ignoreGroups,
  leaveGroup,
  rejectGroups,
} from "../../../store/groups/groupsActions";
import { useNavigate } from "react-router";
import {
  joinGroups,
  withdrawRequest,
} from "../../../store/groups/groupsActions";
import { toast } from "react-toastify";

export default function GroupsContainer() {
  const { suggested_groups } = useSelector((state) => state.groups);
  const { joined_groups } = useSelector((state) => state.groups);
  const { pending_groups } = useSelector((state) => state.groups);
  const { my_groups } = useSelector((state) => state.groups);
  const { recieved_groups } = useSelector((state) => state.groups);
  const { isPageLoading } = useSelector((state) => state.loader);

  const token = localStorage.getItem("accessToken");

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [tabs, setTabs] = useState("suggested_groups");
  const [searchKey, setSearchKey] = useState("");
  const [newSuggestedGroups, setNewSuggestedGroups] = useState([]);
  const [newJoinedGroups, setNewJoinedGroups] = useState([]);
  const [newPendingGroups, setNewPendingGroups] = useState([]);
  const [newRecievedGroups, setNewRecievedGroups] = useState([]);
  const [newMyGroups, setNewMyGroups] = useState([]);
  const [openCreateModal, setOpenCreateModal] = useState(false);
  const [editGroupData, setEditGroupData] = useState("");
  const [removeModal, setRemoveModal] = useState(false);
  const [withdrawGroupId, setWithdrawGroupId] = useState();
  const [currentCount, setCurrentCount] = useState(1);
  const [isLoadMore, setIsLoadMore] = useState(true);

  useEffect(() => {
    if(!isLoadMore){return}
    else
   { 
    if (tabs === "suggested_groups") {
      let query = null;
      dispatch(
        getSuggestedGroups(query, { page: currentCount }, currentCount)
      ).then((res) =>{
      if(res){setIsLoadMore(true)}
      else setIsLoadMore(false)
    }
      );
    } else if (tabs === "joined_groups") {
      let query = "joined";
      dispatch(
        getSuggestedGroups(
          query,
          { filter: "joined", page: currentCount },
          currentCount
        )
      ).then((res) =>{
        if(res){setIsLoadMore(true)}
        else setIsLoadMore(false)
      });
    } else if (tabs === "pending_groups") {
      let query = "pending";
      dispatch(
        getSuggestedGroups(
          query,
          { filter: "sent", page: currentCount },
          currentCount
        )
      ).then((res) =>{
        if(res){setIsLoadMore(true)}
        else setIsLoadMore(false)
      });
    } else if (tabs === "recieved_groups") {
      let query = "recieved";
      dispatch(
        getSuggestedGroups(
          query,
          { filter: "recieved", page: currentCount },
          currentCount
        )
      ).then((res) =>{
      if(res){setIsLoadMore(true)}
      else setIsLoadMore(false)
    })
    } else if (tabs === "my_groups") {
      let query = "my";
      dispatch(
        getSuggestedGroups(
          query,
          { filter: "my", page: currentCount },
          currentCount
        )
      );
    }}
  }, [tabs, currentCount]);

  useEffect(() => {
    setCurrentCount(1);
  }, [tabs]);

  const loadMoreData = () => {
    setCurrentCount(currentCount + 1);
  };

  const filterSearchData = () => {
    setCurrentCount(1);
    if (tabs === "suggested_groups") {
      const result = suggested_groups.filter((groups) =>
        groups?.name.toString().toLowerCase().includes(searchKey.toLowerCase())
      );
      setNewSuggestedGroups(result);
    } else if (tabs === "joined_groups") {
      const result = joined_groups.filter((groups) =>
        groups?.name.toString().toLowerCase().includes(searchKey.toLowerCase())
      );
      setNewJoinedGroups(result);
    } else if (tabs === "pending_groups") {
      const result = pending_groups.filter((groups) =>
        groups?.name.toString().toLowerCase().includes(searchKey.toLowerCase())
      );
      setNewPendingGroups(result);
    } else if (tabs === "recieved_groups") {
      const result = recieved_groups.filter((groups) =>
        groups?.name.toString().toLowerCase().includes(searchKey.toLowerCase())
      );
      setNewRecievedGroups(result);
    } else if (tabs === "my_groups") {
      const result = my_groups.filter((groups) =>
        groups?.name.toString().toLowerCase().includes(searchKey.toLowerCase())
      );
      setNewMyGroups(result);
    }
  };

  useEffect(() => {
    setNewSuggestedGroups(suggested_groups);
  }, [suggested_groups]);

  useEffect(() => {
    setNewJoinedGroups(joined_groups);
  }, [joined_groups]);

  useEffect(() => {
    setNewPendingGroups(pending_groups);
  }, [pending_groups]);

  useEffect(() => {
    setNewRecievedGroups(recieved_groups);
  }, [recieved_groups]);

  useEffect(() => {
    setNewMyGroups(my_groups);
  }, [my_groups]);

  const readMore = (id) => {
    navigate(`/groupdetails/${id}`);
  };

  const joinHandle = (id, type) => {
    dispatch(joinGroups(id, type));
  };

  const acceptHandle = (id) => {
    const data = {
      group_id: id,
      request_status: "confirm",
    };
    dispatch(acceptGroups(data)).then((response) =>
      toast.success("Request Accepted Successfull!", {
        position: toast.POSITION.BOTTOM_LEFT,
      })
    );
  };

  const rejectHandle = (id) => {
    const data = {
      group_id: id,
      request_status: "reject",
    };
    dispatch(acceptGroups(data)).then((response) =>
      toast.success("Request Rejected Successfull!", {
        position: toast.POSITION.BOTTOM_LEFT,
      })
    );
  };

  const ignoreHandle = (id) => {
    const data = {
      group_id: id,
      request_status: "ignore",
    };
    dispatch(acceptGroups(data)).then((response) =>
      toast.success("Request Ignored Successfull!", {
        position: toast.POSITION.BOTTOM_LEFT,
      })
    );
  };

  const withdrawRequestGroup = (groupId) => {
    const data = {
      group_id: groupId,
      request_status: "cancel",
    };
    dispatch(withdrawRequest(data)).then((response) =>
      toast.success("Request Withdraw Successfull!", {
        position: toast.POSITION.BOTTOM_LEFT,
      })
    );
  };
  const leaveHandle = (id) => {
    dispatch(leaveGroup(id)).then((response) => {
      if (response.success) {
        toast.success(response.message, {
          position: toast.POSITION.BOTTOM_LEFT,
        });
      }
    });
  };

  const AcceptRequestGroup = (groupId) => {
    const data = {
      group_id: groupId,
      request_status: "cancel",
    };
    dispatch(withdrawRequest(data)).then((response) =>
      toast.success("Request Withdraw Successfull!", {
        position: toast.POSITION.BOTTOM_LEFT,
      })
    );
  };
  

  return (
    <Groups
      tabs={tabs}
      setTabs={setTabs}
      searchKey={searchKey}
      setSearchKey={setSearchKey}
      filterSearchData={filterSearchData}
      newSuggestedGroups={newSuggestedGroups}
      newJoinedGroups={newJoinedGroups}
      newPendingGroups={newPendingGroups}
      newRecievedGroups={newRecievedGroups}
      newMyGroups={newMyGroups}
      openCreateModal={openCreateModal}
      setOpenCreateModal={setOpenCreateModal}
      readMore={readMore}
      joinHandle={joinHandle}
      acceptHandle={acceptHandle}
      rejectHandle={rejectHandle}
      ignoreHandle={ignoreHandle}
      isPageLoading={isPageLoading}
      editGroupData={editGroupData}
      setEditGroupData={setEditGroupData}
      withdrawRequestGroup={withdrawRequestGroup}
      removeModal={removeModal}
      setRemoveModal={setRemoveModal}
      withdrawGroupId={withdrawGroupId}
      setWithdrawGroupId={setWithdrawGroupId}
      currentCount={currentCount}
      loadMoreData={loadMoreData}
      isLoadMore={isLoadMore}
      setIsLoadMore={setIsLoadMore}
      leaveHandle={leaveHandle}
    />
  );
}
