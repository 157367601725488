import React, { useEffect, useRef, useState } from "react";
import { useLocation } from "react-router-dom";
import "./membership.scss";

import tick from "../../assets/img/green-tick.svg";
import HeaderBackground from "../../assets/img/BecomeAMemberHeader.jpg";
import cross from "../../assets/img/cross.png";
import location from "../../assets/img/location.svg";
import Footer from "../../core/components/layout/footer/Footer";
import MembershipDetails from "./MembershipDetails";
import Navbar from "../../pages/navbar/navbar";
import { useDispatch, useSelector } from "react-redux";
import {
  getMembershipType,
  getMembershipById,
  getMembershipFeature,
  getLocation,
} from "../../store/membership/membershipActions";
import MemberShipCards from "./MemberShipCards";
import Header from "../../core/components/layout/Header/Header";
import BlackHeader from "../../core/components/layout/Header/BlackHeader";

export default function Membership(props) {
  const [detailPage, setDetailPage] = useState(false);
  const [membershipPlanName, setMembershipPlanName] = useState("");
  const [countryLocation, setCountryLocation] = useState("");
  const [isKnowMore, setIsKnowMore] = useState(false);

  const [isCurrent, setIsCurrent] = useState(true);
  const [price, setPrice] = useState("");
  const [duration, setDuration] = useState(null);
  const [start, setStart] = useState(null);

  const { isLoggedIn } = useSelector((state) => state.auth);
  const { user } = useSelector((state) => state.auth);

  const { membershipTypes } = useSelector((state) => state.membership);
  const { membershipDetails } = useSelector((state) => state.membership);
  const { membershipFeatures } = useSelector((state) => state.membership);
  const [isValid, setIsValid] = useState(false);
  const dispatch = useDispatch();
  const compareRef = useRef();
  const { state } = useLocation();

  const handleMore = (planName, id) => {
    setIsValid(false);
    // console.log(planDetail)
    setPrice("");
    setIsCurrent(true);
    setDuration(null);
    setStart(null);
    if (countryLocation)
      dispatch(
        getMembershipById({ membership_id: id, country: countryLocation })
      ).then(() => {
        setDetailPage(true);
        setMembershipPlanName(planName);
      });
    else
      dispatch(getMembershipById({ membership_id: id })).then(() => {
        setDetailPage(true);
        setMembershipPlanName(planName);
      });
  };

  useEffect(() => {
    dispatch(getLocation()).then((res) => {
      setCountryLocation(res?.country_name);
    });
  }, []);

  useEffect(() => {
    if (countryLocation) {
      dispatch(getMembershipType({ country: countryLocation })).then(
        (res) => {}
      );
      dispatch(getMembershipFeature({ country: countryLocation })).then(
        (res) => {}
      );
    }
  }, [countryLocation]);

  return (
    <div>
      {isLoggedIn ? <Navbar isJobs={false} /> : <BlackHeader />}
      {/* <Header/> */}
      {/* <MembershipHeader/> */}
      <div className="majority">
        <div className="container">
          <div className="text-center my-3">
            {/* <div
                  className="image-container"
                  style={{
                    position: "relative",
                    width: "100%",
                    height: "auto",
                    marginLeft: "-14px",
                  }}
                >
                  <img
                    src={HeaderBackground}
                    alt=""
                    style={{
                      width: "100%",
                      height: "auto",
                      marginLeft: "12px",
                    }}
                  />
                  <div
                    className="text-overlay"
                    style={{
                      position: "absolute",
                      top: "50%",
                      left: "50%",
                      transform: "translate(-50%, -50%)",
                      textAlign: "center",
                      color: "white",
                      width: "100%",
                    }}
                  >
                    <h1>
                      Join the millions of NobelPage members using Premium to
                      get ahead.
                    </h1>
                  </div>
                </div> */}

            <div
              style={{
                padding: `30px 30px 20px`,
                background: `linear-gradient(
      120.5deg,
      #5b2e92 0%,
      #29409c 50.49%,
      #0f91d2 100%
    )`,
              }}
            >
              <h1 className="text-light">
                Join the millions of NobelPage members using Premium to get
                ahead.
              </h1>
            </div>

            {/* <div class="image-container" 
               style={{ width: '100%', height: 'auto',marginLeft:"-14px" }}>
                 <img src={HeaderBackground} alt="" />
                 <div class="text-overlay" 
        //          style={{
        //   position: 'absolute',
        //   top: '20%',
        //   left: '50%',
        //   transform: 'translate(-50%, -50%)',
        //   textAlign: 'center',
        //   color: 'white',
        // }}
        > 
                <h1 
               >
                  Join the majority of NobelPage members using Premium to get
                  ahead.
                </h1>
                </div>
              </div> */}
            {/* <p className="mt-2">
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc
                  vulputate libero et velit interdum, ac aliquet odio mattis.
                </p> */}
          </div>

          {/* style={{
          color:'#FFFFFF',
          fontSize: '30px',
          fontWeight: 'bold',
          textShadow: '2px 2px 4px rgba(0, 0, 0, 0.8)',
          whiteSpace: 'nowrap', 
          overflow: 'hidden', 
          textOverflow: 'ellipsis',
          maxWidth: '100%', 
            
          }} */}

          <div className="row" style={{ gap: "15px", padding: "10px" }}>
            {/* <div className="col-lg-12"> */}
            {/* <div className="main-box"> */}
            {membershipTypes?.data?.map((plan, index) => {
              return (
                <MemberShipCards
                  plan={plan}
                  index={index}
                  isUserPlan={
                    user?.data?.membership?.name === plan?.name ? true : false
                  }
                  membershipPlanName={membershipPlanName}
                  detailPage={detailPage}
                  handleMore={handleMore}
                  isKnowMore={isKnowMore}
                  setIsKnowMore={setIsKnowMore}
                />
              );
            })}
          </div>
          {detailPage && (
            <MembershipDetails
              isValid={isValid}
              setIsValid={setIsValid}
              membershipPlanName={membershipPlanName}
              isAddOns={state?.isAddOns ? state?.isAddOns : false}
              membershipDetails={membershipDetails}
              user={user}
              location={membershipTypes?.location}
              types={membershipTypes}
              isCurrent={isCurrent}
              setIsCurrent={setIsCurrent}
              price={price}
              setPrice={setPrice}
              duration={duration}
              setDuration={setDuration}
              start={start}
              setStart={setStart}
            />
          )}
          <div
            className="row mt-3  p-2"
            style={{ marginBottom: "5rem", padding: "10px" }}
            ref={compareRef}
          >
            <div className="col-lg-12 my-4" style={{ paddingLeft: "8px" }}>
              <h1 className="mb-30" style={{ marginLeft: "-7px" }}>
                Compare Plans and Features
              </h1>
              {user?.data && (
                <p className="mt-30" style={{ marginLeft: "-4px" }}>
                  Your Location :
                  <img src={location} alt="location" className="mx-1" />
                  <span className="text-grey">
                    {/* {user?.data ? <b>{user?.data?.location}</b> : "No Location"} */}
                    {membershipTypes?.location}
                  </span>
                </p>
              )}
            </div>
            <table className="table ">
              <thead className="thead-dark">
                <tr style={{ border: "none" }} className="spacer">
                  <th scope="col" style={{ border: "none" }}>
                    <span className="package_head">Packages</span>
                  </th>
                  <th
                    scope="col"
                    className="text-center"
                    style={{ border: "none" }}
                  >
                    <span className="package_head">Starter</span>
                  </th>
                  <th
                    scope="col"
                    className="text-center"
                    style={{ border: "none" }}
                  >
                    <span className="package_head">Nobel</span>
                  </th>
                  <th
                    scope="col"
                    className="text-center"
                    style={{ border: "none" }}
                  >
                    <span className="package_head">Nobel Plus</span>
                  </th>
                  <th className="text-center" style={{ border: "none" }}>
                    <span className="package_head"> Scout </span>
                  </th>
                </tr>
              </thead>
              <tbody>
                {/* <tr>
                  <td className="width">
                    <p>Fees Monthly</p>
                  </td>
                  <td className="text-center">
                    <p>Free</p>
                  </td>
                  <td className="text-center ">
                    <p className="mb-0">
                      Cat I: 17.99$
                      <img
                        src={info}
                        className="mt-0 ms-2 mb-1"
                        alt=""
                        data-bs-toggle="tooltip"
                        data-bs-placement="top"
                        title={cat1}
                      />
                    </p>
                    <p className="my-0">
                      Cat II: 13.99${" "}
                      <img
                        src={info}
                        className="mt-0 ms-2 mb-1"
                        alt=""
                        data-bs-toggle="tooltip"
                        data-bs-placement="top"
                        title={cat2}
                      />
                    </p>
                    <p className="my-0">
                      Cat III: 9.99${" "}
                      <img
                        src={info}
                        className="mt-0 ms-2 mb-1"
                        alt=""
                        data-bs-toggle="tooltip"
                        data-bs-placement="top"
                        title={cat3}
                      />
                    </p>
                    <p className="my-0">Promotion applicable</p>
                  </td>
                  <td className="text-center">
                    <p className="mb-0">
                      Cat I: 29.99${" "}
                      <img
                        src={info}
                        className="mt-0 ms-2 mb-1"
                        alt=""
                        data-bs-toggle="tooltip"
                        data-bs-placement="top"
                        title={cat1}
                      />
                    </p>
                    <p className="my-0">
                      Cat II: 23.99${" "}
                      <img
                        src={info}
                        className="mt-0 ms-2 mb-1"
                        alt=""
                        data-bs-toggle="tooltip"
                        data-bs-placement="top"
                        title={cat2}
                      />
                    </p>
                    <p className="my-0">
                      Cat III: 17.99${" "}
                      <img
                        src={info}
                        className="mt-0 ms-2 mb-1"
                        alt=""
                        data-bs-toggle="tooltip"
                        data-bs-placement="top"
                        title={cat3}
                      />
                    </p>
                    <p className="my-0">Promotion applicable</p>
                  </td>
                  <td className="text-center">
                    <p className="mb-0">Cat I: 49.99$</p>
                    <p className="my-0">Cat II: 39.99$</p>
                    <p className="my-0">Cat III: 29.99$</p>
                    <p className="my-0">Promotion applicable</p>
                  </td>
                </tr> */}
                {membershipFeatures?.data?.map((features, index) => (
                  <tr key={index}>
                    <td className="width">
                      <p>{features?.name}</p>
                    </td>
                    {features?.Feauture_Values?.map((data, index) => (
                      <td className="text-center">
                        <img
                          src={data[0].checkmark === true ? tick : cross}
                          alt="tick"
                        />
                      </td>
                    ))}
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}
