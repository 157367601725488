import { call } from "./apiCall";

class EventsApi {
  getevents(token, query) {
    return new Promise((resolve, reject) => {
      let token = localStorage.getItem("accessToken");
      (async () => {
        try {
          const res = await call(
            "get",
            `api/v1/feed/event`,
            query,
            null,
            token
          );
          if (res.data.success === true) {
            resolve(res.data);
          }
          if (res.data.success === false) {
            resolve(res.data);
          }
        } catch (err) {
          console.log(err);
          reject(err);
        }
      })();
    });
  }

  getOthersEvents(params) {
    console.log(params);
    let token = localStorage.getItem("accessToken");
    return new Promise((resolve, reject) => {
      (async () => {
        try {
          const res = await call(
            "get",
            token ? `api/v1/feed/event` : `api/v1/public/event-list`,
            params,
            null,
            token ? token : null
          );
          if (res.data.success === true) {
            resolve(res.data);
          }
          if (res.data.success === false) {
            resolve(res.data);
          }
        } catch (err) {
          console.log(err);
          reject(err);
        }
      })();
    });
  }

  createEvent(data, token) {
    return new Promise((resolve, reject) => {
      (async () => {
        try {
          const res = await call(
            "post",
            "api/v1/feed/event",
            null,
            data,
            token
          );
          // console.log(res.data)
          resolve(res.data);
        } catch (err) {
          console.log(err);
          reject(err);
          alert(err?.response?.data?.message);
        }
      })();
    });
  }

  editEvent(data, token) {
    return new Promise((resolve, reject) => {
      (async () => {
        try {
          const res = await call(
            "patch",
            "api/v1/feed/event",
            null,
            data,
            token
          );
          // console.log(res.data)
          resolve(res.data);
        } catch (err) {
          console.log(err);
          reject(err);
          alert(err?.response?.data?.message);
        }
      })();
    });
  }

  attendEvents(id, token) {
    return new Promise((resolve, reject) => {
      (async () => {
        try {
          const res = await call(
            "post",
            `api/v1/feed/participant-event/${id}`,
            null,
            null,
            token
          );
          // console.log(res.data)
          resolve(res.data);
        } catch (err) {
          console.log(err);
          reject(err);
        }
      })();
    });
  }

  pinnedEvents(id, token) {
    return new Promise((resolve, reject) => {
      (async () => {
        try {
          const res = await call(
            "post",
            `api/v1/feed/pinned-event/${id}`,
            null,
            null,
            token
          );
          // console.log(res.data)
          resolve(res.data);
        } catch (err) {
          console.log(err);
          reject(err);
        }
      })();
    });
  }

  getEventDetail(id, token) {
    return new Promise((resolve, reject) => {
      (async () => {
        try {
          const res = await call(
            "get",
            `api/v1/feed/event?id=${id}`,
            null,
            null,
            token
          );
          // console.log(res.data)
          resolve(res.data);
        } catch (err) {
          console.log(err);
          reject(err);
        }
      })();
    });
  }

  cancelEvent(id, token) {
    return new Promise((resolve, reject) => {
      (async () => {
        try {
          const res = await call(
            "DELETE",
            `api/v1/feed/cancel-event/${id}`,
            null,
            null,
            token
          );
          // console.log(res.data)
          resolve(res.data);
        } catch (err) {
          console.log(err);
          reject(err);
          alert(err?.response?.data?.message);
        }
      })();
    });
  }
}

export const eventsApi = new EventsApi();
