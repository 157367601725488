import React, { useEffect, useState } from "react";
import image from "../../../assets/img/nobel.svg";
// import "./Directory.scss";
import { useNavigate } from "react-router-dom";
import InfiniteScroll from "react-infinite-scroll-component";
import { useDispatch, useSelector } from "react-redux";
import { getAllPublicJobs } from "../../../store/directory/directoryAction";
import AppLoader from "../../../core/components/Loaders/appLoader/appLoader";

const JobsDirectory = (props) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [searchKey, setSearchKey] = useState("");
  const [currentCount, setCurrentCount] = useState(0);
  const [totalCount, setTotalCount] = useState(0);
  const [searchAlphabaticly, setSearchAlphabaticly] = useState(false);
  const { publicJobList } = useSelector((state) => state.directory);
  const { isPageLoading } = useSelector((state) => state.loader);
  useEffect(() => {
    if (searchKey.length > 0) {
      SearchByText();
    } else if (searchAlphabaticly) {
      SearchAlphabaticly(searchAlphabaticly);
    } else SearchAllJobs();
  }, [currentCount]);

  const SearchByText = () => {
    dispatch(
      getAllPublicJobs({
        skip: currentCount,
        search: searchKey ? searchKey : "",
        limit: "20",
      })
    ).then((res) => setTotalCount(res.totalItems));
  };

  const SearchAlphabaticly = (text) => {
    setSearchAlphabaticly(text);
    dispatch(
        getAllPublicJobs({ skip: currentCount, search: text, limit: "20" })
    ).then((res) => setTotalCount(res.totalItems));
  };

  const SearchAllJobs = () => {
    dispatch(getAllPublicJobs({ skip: currentCount, limit: "20" })).then(
      (res) => setTotalCount(res.totalItems)
    );
  };

  useEffect(() => {
    if (!searchKey) {
      SearchAllJobs();
    }
  }, [searchKey]);

  const loadMoreData = () => {
    setCurrentCount(currentCount + 20);
  };

  const handleEnterSubmit = (e) => {
    if (e.key === "Enter") {
      SearchByText();
    }
  };

  const navigateToJobDetail = (id) => {
    navigate(`/directory/jobdetail/${id}`);
  };

  if(isPageLoading)
return <AppLoader/>
 else
 return (
    <div className="directory">
      <div className="header">
        <div className="container my-sm-5 my-4">
          <div className="row">
            <div className="col-lg-8">
              <img
                src={image}
                alt=""
                style={{ cursor: "pointer" }}
                onClick={() => navigate("/")}
              />
            </div>
            <div className="col-lg-4">
              <div className="search">
                <input
                  type="text"
                  placeholder="Search"
                  onChange={(e) => setSearchKey(e.target.value)}
                  onKeyDown={(e) => handleEnterSubmit(e)}
                />
                <div
                  className="search-img"
                  onClick={() => {
                    setCurrentCount(0);
                    SearchByText();
                  }}
                ></div>
              </div>
            </div>
          </div>
        </div>
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <h1>Directory / Jobs</h1>
              <p className="mt-3">
              Browse through our directory to discover talented individuals, entrepreneurs, and industry experts from around the world.
              </p>
            </div>
          </div>
        </div>
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="browse">
                <h3>Browse By</h3>
                <ul className="mb-4 mt-3">
                  <li
                    onClick={() => {
                      setCurrentCount(0);
                      SearchAlphabaticly("A");
                    }}
                  >
                    A
                  </li>
                  <li
                    onClick={() => {
                      setCurrentCount(0);
                      SearchAlphabaticly("B");
                    }}
                  >
                    B
                  </li>
                  <li
                    onClick={() => {
                      setCurrentCount(0);
                      SearchAlphabaticly("C");
                    }}
                  >
                    C
                  </li>
                  <li
                    onClick={() => {
                      setCurrentCount(0);
                      SearchAlphabaticly("D");
                    }}
                  >
                    D
                  </li>
                  <li
                    onClick={() => {
                      setCurrentCount(0);
                      SearchAlphabaticly("E");
                    }}
                  >
                    E
                  </li>
                  <li
                    onClick={() => {
                      setCurrentCount(0);
                      SearchAlphabaticly("F");
                    }}
                  >
                    F
                  </li>
                  <li
                    onClick={() => {
                      setCurrentCount(0);
                      SearchAlphabaticly("G");
                    }}
                  >
                    G
                  </li>
                  <li
                    onClick={() => {
                      setCurrentCount(0);
                      SearchAlphabaticly("H");
                    }}
                  >
                    H
                  </li>
                  <li
                    onClick={() => {
                      setCurrentCount(0);
                      SearchAlphabaticly("I");
                    }}
                  >
                    I
                  </li>
                  <li
                    onClick={() => {
                      setCurrentCount(0);
                      SearchAlphabaticly("J");
                    }}
                  >
                    J
                  </li>
                  <li
                    onClick={() => {
                      setCurrentCount(0);
                      SearchAlphabaticly("K");
                    }}
                  >
                    K
                  </li>
                  <li
                    onClick={() => {
                      setCurrentCount(0);
                      SearchAlphabaticly("L");
                    }}
                  >
                    L
                  </li>
                  <li
                    onClick={() => {
                      setCurrentCount(0);
                      SearchAlphabaticly("M");
                    }}
                  >
                    M
                  </li>
                  <li
                    onClick={() => {
                      setCurrentCount(0);
                      SearchAlphabaticly("N");
                    }}
                  >
                    N
                  </li>
                  <li
                    onClick={() => {
                      setCurrentCount(0);
                      SearchAlphabaticly("O");
                    }}
                  >
                    O
                  </li>
                  <li
                    onClick={() => {
                      setCurrentCount(0);
                      SearchAlphabaticly("P");
                    }}
                  >
                    P
                  </li>
                  <li
                    onClick={() => {
                      setCurrentCount(0);
                      SearchAlphabaticly("Q");
                    }}
                  >
                    Q
                  </li>
                  <li
                    onClick={() => {
                      setCurrentCount(0);
                      SearchAlphabaticly("R");
                    }}
                  >
                    R
                  </li>
                  <li
                    onClick={() => {
                      setCurrentCount(0);
                      SearchAlphabaticly("S");
                    }}
                  >
                    S
                  </li>
                  <li
                    onClick={() => {
                      setCurrentCount(0);
                      SearchAlphabaticly("T");
                    }}
                  >
                    T
                  </li>
                  <li
                    onClick={() => {
                      setCurrentCount(0);
                      SearchAlphabaticly("U");
                    }}
                  >
                    U
                  </li>
                  <li
                    onClick={() => {
                      setCurrentCount(0);
                      SearchAlphabaticly("V");
                    }}
                  >
                    V
                  </li>
                  <li
                    onClick={() => {
                      setCurrentCount(0);
                      SearchAlphabaticly("W");
                    }}
                  >
                    W
                  </li>
                  <li
                    onClick={() => {
                      setCurrentCount(0);
                      SearchAlphabaticly("X");
                    }}
                  >
                    X
                  </li>
                  <li
                    onClick={() => {
                      setCurrentCount(0);
                      SearchAlphabaticly("Y");
                    }}
                  >
                    Y
                  </li>
                  <li
                    onClick={() => {
                      setCurrentCount(0);
                      SearchAlphabaticly("Z");
                    }}
                  >
                    Z
                  </li>
                  {/* <li
                    onClick={() =>  SearchAllCompany()}
                  >
                    All
                  </li> */}
                </ul>
              </div>
              <InfiniteScroll
                dataLength={publicJobList?.length ? publicJobList?.length : 0}
                next={loadMoreData}
                hasMore={
                  totalCount === 0
                    ? true
                    : totalCount <= publicJobList?.length
                    ? false
                    : true
                }
                style={{ overflow: "visible" }}
              >
                <div className="community">
                  <div className="row list mb-10">
                    {publicJobList?.map((item, index) => {
                      return (
                        <div
                          key={index}
                          className="col-lg-4 list-name card-control-text-oneline"
                          onClick={() => navigateToJobDetail(item?.id)}
                        >
                          {item?.title}
                        </div>
                      );
                    })}
                  </div>
                </div>
              </InfiniteScroll>
              <div className="line mt-20 mb-20"> </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default JobsDirectory;
