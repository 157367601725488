import { call } from "./apiCall";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

class PageApi {
  getPagesList(query) {
    let token = localStorage.getItem("accessToken");
    return new Promise((resolve, reject) => {
      (async () => {
        try {
          const res = await call("get", `api/v1/page-list`, query, null, token);
          if (res?.data?.success === true) {
            resolve(res?.data);
          }
          if (res?.data?.success === false) {
            reject(res);
          }
        } catch (err) {
          console.log(err);
          reject(err);
        }
      })();
    });
  }

  createPage(data) {
    return new Promise((resolve, reject) => {
      let token = localStorage.getItem("accessToken");
      (async () => {
        try {
          const res = await call("post", "api/v1/page", null, data, token);
          resolve(res.data);
        } catch (err) {
          console.log(err);
          reject(err);
        }
      })();
    });
  }

  updatePage(data, pageId) {
    return new Promise((resolve, reject) => {
      let token = localStorage.getItem("accessToken");
      (async () => {
        try {
          const res = await call(
            "patch",
            `api/v1/page/${pageId}`,
            null,
            data,
            token
          );
          resolve(res.data);
        } catch (err) {
          console.log(err);
          reject(err);
        }
      })();
    });
  }

  followPage(pageId) {
    return new Promise((resolve, reject) => {
      (async () => {
        let token = localStorage.getItem("accessToken");
        try {
          const res = await call(
            "patch",
            `api/v1/page/follow/${pageId}`,
            {},
            {},
            token
          );
          resolve(res.data);
        } catch (err) {
          console.log(err);
          reject(err);
        }
      })();
    });
  }
  unFollowPage(pageId) {
    return new Promise((resolve, reject) => {
      let token = localStorage.getItem("accessToken");
      (async () => {
        try {
          const res = await call(
            "delete",
            `api/v1/page/unfollow/${pageId}`,
            {},
            {},
            token
          );

          resolve(res.data);
          // toast.success(res.data.message,{ position: 'bottom-left'} );
        } catch (err) {
          console.log(err);
          reject(err);
        }
      })();
    });
  }

  pageInvitation(data) {
    return new Promise((resolve, reject) => {
      (async () => {
        let token = localStorage.getItem("accessToken");
        try {
          const res = await call(
            "post",
            `api/v1/user/send-invitation`,
            null,
            data,
            token
          );
          resolve(res.data);
        } catch (err) {
          console.log(err);
          reject(err);
        }
      })();
    });
  }

  getPageAdminList(pageId) {
    return new Promise((resolve, reject) => {
      let token = localStorage.getItem("accessToken");
      (async () => {
        try {
          const res = await call(
            "get",
            `api/v1/page-admin/${pageId}`,
            null,
            null,
            token
          );
          resolve(res.data);
        } catch (err) {
          console.log(err);
          reject(err);
        }
      })();
    });
  }

  getPageFollowerList(pageId) {
    return new Promise((resolve, reject) => {
      let token = localStorage.getItem("accessToken");
      (async () => {
        try {
          const res = await call(
            "get",
            token
              ? `api/v1/page/followers/${pageId}`
              : `api/v1/public/page/followers/${pageId}`,
            null,
            null,
            token ? token : null
          );
          resolve(res.data);
        } catch (err) {
          console.log(err);
          reject(err);
        }
      })();
    });
  }
  deletePageAdminList(data) {
    return new Promise((resolve, reject) => {
      let token = localStorage.getItem("accessToken");
      (async () => {
        try {
          const res = await call(
            "delete",
            `api/v1/page-admin`,
            null,
            data,
            token
          );
          resolve(res.data);
        } catch (err) {
          console.log(err);
          reject(err);
        }
      })();
    });
  }

  addPageAdminList(data) {
    return new Promise((resolve, reject) => {
      (async () => {
        let token = localStorage.getItem("accessToken");
        try {
          const res = await call(
            "post",
            `api/v1/add_page_admin`,
            null,
            data,
            token
          );
          resolve(res.data);
        } catch (err) {
          console.log(err);
          reject(err);
        }
      })();
    });
  }

  transferPageAdmin(pageId, userId) {
    return new Promise((resolve, reject) => {
      (async () => {
        let token = localStorage.getItem("accessToken");
        try {
          const res = await call(
            "patch",
            `api/v1/transfer-page/${pageId}/${userId}`,
            null,
            null,
            token
          );
          resolve(res.data);
        } catch (err) {
          console.log(err);
          reject(err);
        }
      })();
    });
  }
  confirmPassTransferPage(data) {
    return new Promise((resolve, reject) => {
      (async () => {
        let token = localStorage.getItem("accessToken");
        try {
          const res = await call(
            "post",
            "api/v1/auth/verify-password",
            null,
            data,
            token
          );
          resolve(res.data);
        } catch (err) {
          console.log(err);
          reject(err);
        }
      })();
    });
  }
  addPageURL(data) {
    return new Promise((resolve, reject) => {
      (async () => {
        let token = localStorage.getItem("accessToken");
        try {
          const res = await call(
            "patch",
            `api/v1/add-page-url`,
            null,
            data,
            token
          );
          resolve(res.data);
        } catch (err) {
          console.log(err);
          reject(err);
        }
      })();
    });
  }

  getPagesInsightsList(query) {
    let token = localStorage.getItem("accessToken");
    return new Promise((resolve, reject) => {
      (async () => {
        try {
          const res = await call(
            "get",
            `api/v1/page-insight`,
            query,
            null,
            token
          );
          if (res.data.success === true) {
            resolve(res.data);
          }
          if (res.data.success === false) {
            resolve(res.data);
          }
        } catch (err) {
          console.log(err);
          reject(err);
        }
      })();
    });
  }

  getPagePermissions(pageId) {
    return new Promise((resolve, reject) => {
      let token = localStorage.getItem("accessToken");
      (async () => {
        try {
          const res = await call(
            "get",
            `api/v1/page/permissions/${pageId}`,
            null,
            null,
            token
          );
          resolve(res.data);
        } catch (err) {
          console.log(err);
          reject(err);
        }
      })();
    });
  }

  createTalentPool(data) {
    return new Promise((resolve, reject) => {
      let token = localStorage.getItem("accessToken");
      (async () => {
        try {
          const res = await call(
            "post",
            `api/v1/portal/create`,
            null,
            data,
            token
          );
          resolve(res.data);
        } catch (err) {
          console.log(err);
          reject(err);
        }
      })();
    });
  }

  updateTalentPool(id, data) {
    return new Promise((resolve, reject) => {
      let token = localStorage.getItem("accessToken");
      (async () => {
        try {
          const res = await call(
            "patch",
            `api/v1/portal/update/${id}`,
            null,
            data,
            token
          );
          resolve(res.data);
        } catch (err) {
          console.log(err);
          reject(err);
        }
      })();
    });
  }

  getTalentPool(id) {
    return new Promise((resolve, reject) => {
      let token = localStorage.getItem("accessToken");
      (async () => {
        try {
          const res = await call(
            "get",
            `api/v1/portal/${id}`,
            null,
            null,
            token
          );
          resolve(res.data);
        } catch (err) {
          console.log(err);
          reject(err);
        }
      })();
    });
  }

  getProductList(page_id, query) {
    let token = localStorage.getItem("accessToken");
    return new Promise((resolve, reject) => {
      (async () => {
        try {
          const res = await call(
            "get",
            `api/v1/product-list/${page_id}`,
            query,
            null,
            token
          );
          if (res?.data?.success === true) {
            resolve(res?.data);
          }
          if (res?.data?.success === false) {
            reject(res);
          }
        } catch (err) {
          console.log(err);
          reject(err);
        }
      })();
    });
  }

  createProduct(data) {
    return new Promise((resolve, reject) => {
      let token = localStorage.getItem("accessToken");
      (async () => {
        try {
          const res = await call(
            "post",
            "api/v1/add-product",
            null,
            data,
            token
          );
          resolve(res.data);
        } catch (err) {
          console.log(err);
          reject(err);
        }
      })();
    });
  }

  updateProduct(data, id) {
    return new Promise((resolve, reject) => {
      let token = localStorage.getItem("accessToken");
      (async () => {
        try {
          const res = await call(
            "patch",
            `api/v1/edit-product/${id}`,
            null,
            data,
            token
          );
          resolve(res.data);
        } catch (err) {
          console.log(err);
          reject(err);
        }
      })();
    });
  }
}

export const pageApi = new PageApi();
