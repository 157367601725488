import {
  CREATE_CONVERSATION,
  CREATE_GROUP_CONVERSATION,
  GET_CONVERSATION,
  GET_GROUP_CONVERSATION,
  REMOVE_CONVERSATION,
  REMOVE_GROUP_CONVERSATION,
  UPDATE_CONVERSATION,
  UPDATE_GROUP_CONVERSATION,
  UPDATE_UNREAD,
} from "../types";

export const initialState = {
  conversations: [],
  // groupConversations: [],
};

const message = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case GET_CONVERSATION:
      return {
        ...state,
        conversations: payload?.data?.rooms || [],
      };

    case CREATE_CONVERSATION:
      return {
        ...state,
        conversations: [
          payload,
          ...state.conversations?.filter((c) => c?._id !== payload?._id),
        ],
      };

    case UPDATE_CONVERSATION:
      const conversation = state?.conversations?.find(
        (c) => c?._id === payload?._id
      );
      return {
        ...state,
        conversations: [
          { ...conversation, ...payload },
          ...state.conversations?.filter((c) => c?._id !== payload?._id),
        ],
      };
    case REMOVE_CONVERSATION:
      return {
        ...state,
        conversations: [
          ...state.conversations?.filter((c) => c?.id !== payload?.id),
        ],
      };

    case UPDATE_UNREAD:
      return {
        ...state,
        conversations: state.conversations?.map((c) =>
          c?.code === payload?.roomCode
            ? {
                ...c,
                users: c?.users?.map((obj) =>
                  obj?.user?.user_id == payload?.user_id
                    ? { ...obj, unread: 0 }
                    : obj
                ),
              }
            : c
        ),
      };

    case GET_GROUP_CONVERSATION:
      return {
        ...state,
        conversations: payload?.data?.rooms || [],
      };

    case CREATE_GROUP_CONVERSATION:
      return {
        ...state,
        conversations: [
          payload,
          ...state.conversations?.filter((c) => c?.id !== payload?.id),
        ],
      };

    case UPDATE_GROUP_CONVERSATION:
      return {
        ...state,
        conversations: [
          payload,
          ...state.conversations?.filter((c) => c?.id !== payload?.id),
        ],
      };
    case REMOVE_GROUP_CONVERSATION:
      return {
        ...state,
        conversations: [
          ...state.conversations?.filter((c) => c?.id !== payload?.id),
        ],
      };

    default:
      return state;
  }
};

export default message;
