import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getAllCompany,
  getAllMembers,
  getAllPublicPosts,
} from "../../../store/directory/directoryAction";
import { useState } from "react";
import PeopleDirectory from "./PostsDirectory";
import PostsDirectory from "./PostsDirectory";
import AppLoader from "../../../core/components/Loaders/appLoader/appLoader";

const PostDirectoryContainer = () => {
  const dispatch = useDispatch();
  const [searchKey, setSearchKey] = useState("");
  const [currentCount, setCurrentCount] = useState(0);
  const [totalCount, setTotalCount] = useState(0);
  const [searchAlphabaticly, setSearchAlphabaticly] = useState(false);
  const { publicPostList } = useSelector((state) => state.directory);
  const { isPageLoading } = useSelector((state) => state.loader);

  useEffect(() => {
    if (searchKey.length > 0) {
      SearchByText();
    } else if (searchAlphabaticly) {
      SearchAlphabaticly(searchAlphabaticly);
    } else SearchAllNewsletters();
  }, [currentCount]);

  const SearchByText = () => {
    dispatch(
      getAllPublicPosts({
        skip: currentCount,
        search: searchKey ? searchKey : "",
        limit: "300",
      })
    ).then((res) => setTotalCount(res.totalItems));
  };

  const SearchAlphabaticly = (text) => {
    setSearchAlphabaticly(text);
    dispatch(
      getAllPublicPosts({ skip: currentCount, search: text, limit: "300" })
    ).then((res) => setTotalCount(res.totalItems));
  };

  const SearchAllNewsletters = () => {
    dispatch(getAllPublicPosts({ skip: currentCount, limit: "500" })).then(
      (res) => setTotalCount(res.totalItems)
    );
  };

  useEffect(() => {
    if (!searchKey) {
      SearchAllNewsletters();
    }
  }, [searchKey]);

  const loadMoreData = () => {
    setCurrentCount(currentCount + 20);
  };

  const handleEnterSubmit = (e) => {
    if (e.key === "Enter") {
      SearchByText();
    }
  };

  if (isPageLoading) return <AppLoader />;
  else
    return (
      <PostsDirectory
        publicPostList={publicPostList}
        searchKey={searchKey}
        setSearchKey={setSearchKey}
        SearchByText={SearchByText}
        SearchAlphabaticly={SearchAlphabaticly}
        SearchAllNewsletters={SearchAllNewsletters}
        loadMoreData={loadMoreData}
        totalCount={totalCount}
        setCurrentCount={setCurrentCount}
        handleEnterSubmit={handleEnterSubmit}
      />
    );
};

export default PostDirectoryContainer;
