import React, { useEffect, useState, useRef } from "react";
import "../../network.scss";
import DOMPurify from "dompurify";
import { useParams, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import NewsletterDetails from "./NewsletterDetails";
import {
  likeNewsletter,
  commentNewsletter,
  getCommentDetails,
  getNewsletterDetail,
  subscribeDetailedNewsletter,
} from "../../../../store/newsletter/newsletterActions";
import moment from "moment";

export default function NewsletterDetailsContainer() {
  const { id } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const divRef = useRef(null);
  const dropDownRef = useRef(null);

  const [openShareModal, setOpenShareModal] = useState(false);
  const [openEditNewsletter, setOpenEditNewsletter] = useState(false);
  const [editNewsletterData, setEditNewsletterData] = useState("");
  const [commentData, setCommentData] = useState("");
  const [currentCount, setCurrentCount] = useState(0);
  const [isReaction, setIsReaction] = useState(-1);
  const [shareDropdown, setShareDropdown] = useState(false);

  const { newsletter_details } = useSelector((state) => state.newsletter);
  const { comment_list } = useSelector((state) => state.newsletter);
  const { user } = useSelector((state) => state.auth);
  const token = localStorage.getItem("accessToken");
  const [openStaticsModel, setOpenStaticsModel]= useState(false);


  
  useEffect(() => {
    dispatch(getNewsletterDetail(id, token));
  }, []);

  useEffect(() => {
    dispatch(
      getCommentDetails({
        type_id: id,
        type: "newsletter",
        limit: 5,
        skip: currentCount,
      })
    );
  }, [currentCount]);

  useEffect(() => {
    document.addEventListener("mousedown", clickOutsideDropdown);
    return () => {
      document.removeEventListener("mousedown", clickOutsideDropdown);
    };
  }, [shareDropdown]);

  function createMarkup(html) {
    return {
      __html: DOMPurify.sanitize(html),
    };
  }

  const subscribeHandle = (id) => {
    const token = localStorage.getItem("accessToken");
    dispatch(subscribeDetailedNewsletter(id, token));
  };

  const likeHandler = (id, type) => {
    let data = {
      reaction_type: "newsletter",
      reaction_type_id: id,
    };
    dispatch(likeNewsletter(data, type));
    setIsReaction(-1);
  };

  const commentHandler = (id) => (e) => {
    if (e.key === "Enter") {
      let data = {
        comment: commentData,
        type: "newsletter",
        type_id: id,
      };
      dispatch(commentNewsletter(data));
      setCommentData("");
    }
  };

  const executeScroll = () => divRef.current.scrollIntoView();

  const loadMoreComments = () => {
    setCurrentCount(currentCount + 5);
  };

  const clickOutsideDropdown = (e) => {
    if (dropDownRef?.current?.contains(e.target)) {
      return;
    }
    setShareDropdown(false);
  };

  return (
    <NewsletterDetails
      openShareModal={openShareModal}
      setOpenShareModal={setOpenShareModal}
      openEditNewsletter={openEditNewsletter}
      setOpenEditNewsletter={setOpenEditNewsletter}
      editNewsletterData={editNewsletterData}
      setEditNewsletterData={setEditNewsletterData}
      createMarkup={createMarkup}
      subscribeHandle={subscribeHandle}
      newsletter_details={newsletter_details}
      user={user}
      likeHandler={likeHandler}
      commentHandler={commentHandler}
      commentData={commentData}
      setCommentData={setCommentData}
      comment_list={comment_list?.data}
      total_comment={comment_list?.items}
      navigate={navigate}
      divRef={divRef}
      executeScroll={executeScroll}
      loadMoreComments={loadMoreComments}
      dropDownRef={dropDownRef}
      shareDropdown={shareDropdown}
      setShareDropdown={setShareDropdown}
      setOpenStaticsModel={setOpenStaticsModel}
      openStaticsModel={openStaticsModel}
    />
  );
}
