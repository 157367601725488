import {
  GET_ALL_COMPANY,
  GET_ALL_MEMBERS,
  GET_MORE_PUBLIC_ARTICLE_LIST,
  GET_MORE_PUBLIC_JOB_LIST,
  GET_MORE_PUBLIC_MEMBERS,
  GET_MORE_PUBLIC_NEWSLETTER_LIST,
  GET_MORE_PUBLIC_POST_LIST,
  GET_PUBLIC_ARTICLE_DETAILS,
  GET_PUBLIC_ARTICLE_LIST,
  GET_PUBLIC_JOB_DETAILS,
  GET_PUBLIC_JOB_LIST,
  GET_PUBLIC_NEWSLETTER_DETAILS,
  GET_PUBLIC_NEWSLETTER_LIST,
  GET_PUBLIC_POST_DETAILS,
  GET_PUBLIC_POST_LIST,
  GET_SINGLE_COMPANY,
  GET_SINGLE_MEMBER,
} from "../types";
export const initialState = {
  companyData: [],
  singleCompanyData: [],
  publicMembersList: [],
  singleMemberData: [],
  publicArticlesList: [],
  singlePublicArticleData: [],
  publicNewslettersList: [],
  singlePublicNewsletterData: [],
  publicPostList: [],
  singlePublicPostData: [],
  publicJobList:[],
  singlePublicJobData:[]
};

const directory = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case GET_ALL_COMPANY:
      return {
        ...state,
        companyData: payload,
      };

    case GET_SINGLE_COMPANY:
      return {
        ...state,
        singleCompanyData: payload,
      };

    case GET_ALL_MEMBERS:
      return {
        ...state,
        publicMembersList: payload,
      };

    case GET_MORE_PUBLIC_MEMBERS:
      return {
        ...state,
        publicMembersList: [...state.publicMembersList, ...payload],
      };

    case GET_SINGLE_MEMBER:
      return {
        ...state,
        singleMemberData: payload,
      };

    case GET_PUBLIC_ARTICLE_LIST:
      return {
        ...state,
        publicArticlesList: payload,
      };

    case GET_MORE_PUBLIC_ARTICLE_LIST:
      return {
        ...state,
        publicArticlesList: [...state.publicArticlesList, ...payload],
      };

    case GET_PUBLIC_ARTICLE_DETAILS:
      return {
        ...state,
        singlePublicArticleData: payload,
      };
    case GET_PUBLIC_NEWSLETTER_LIST:
      return {
        ...state,
        publicNewslettersList: payload,
      };

    case GET_MORE_PUBLIC_NEWSLETTER_LIST:
      return {
        ...state,
        publicNewslettersList: [...state.publicNewslettersList, ...payload],
      };

    case GET_PUBLIC_NEWSLETTER_DETAILS:
      return {
        ...state,
        singlePublicNewsletterData: payload,
      };

    case GET_PUBLIC_POST_LIST:
      return {
        ...state,
        publicPostList: payload,
      };

    case GET_MORE_PUBLIC_POST_LIST:
      return {
        ...state,
        publicPostList: [...state.publicPostList, ...payload],
      };

    case GET_PUBLIC_POST_DETAILS:
      return {
        ...state,
        singlePublicPostData: payload,
      };

      case GET_PUBLIC_JOB_LIST:
        return {
          ...state,
          publicJobList: payload,
        };
  
      case GET_MORE_PUBLIC_JOB_LIST:
        return {
          ...state,
          publicJobList: [...state.publicJobList, ...payload],
        };
        case GET_PUBLIC_JOB_DETAILS:
          return {
            ...state,
            singlePublicJobData: payload,
          };
    default:
      return state;
  }
};

export default directory;
