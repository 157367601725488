import React, { useEffect, useState } from "react";
import Autocomplete from "react-autocomplete";
import { useDispatch, useSelector } from "react-redux";
import { Input, Label } from "reactstrap";
import { toast } from "react-toastify";
import moment from "moment";
import ScheduleFeatureCamp from "./ScheduleFeatureCamp";
import TagsInput from "../../../../core/components/HashTags/tagsInput";
import { getLocationsData } from "../../../../store/connections/connectionsActions";
import { useLocation, useNavigate } from "react-router-dom";
import {
  getCampaign,
  postCampaign,
} from "../../../../store/campaign/campaignActions";
import { getJobs } from "../../../../store/jobs/jobAction";
import PaymentFeature from "./PaymentFeature";
import JobPreview from "./preview/JobPreview";
import { getIndustryData } from "../../../../store/master/masterActions";

const FeatureJob = (props) => {
  const navigate = useNavigate();
  const location = useLocation();
  const stateId = location?.state?.id;
  const {
    paymentModal,
    setPaymentModal,
    selectedJobId,
    setSelectedJobId,
    membership,
  } = props;

  const dispatch = useDispatch();
  var today = new Date().toISOString().split("T")[0];
  const { getAllJobs } = useSelector((state) => state.jobs);
  const [duration, setDuration] = useState(1);
  const [savedStatus, setSavedStatus] = useState("payment");
  const [hashText, setHashText] = useState([]);
  const [scheduleDate, setScheduleDate] = useState(today);
  const [scheduleTime, setScheduleTime] = useState();
  const [featureCheck, setFeatureCheck] = useState(true);
  const [locationList, setLocationList] = useState([]);
  const [locationInput, setLocationInput] = useState();
  const [locationOpen1, setLocationOpen1] = useState(false);
  const [locationOpen2, setLocationOpen2] = useState(false);
  const [locationOpen3, setLocationOpen3] = useState(false);
  const [locationOpen4, setLocationOpen4] = useState(false);
  const [locationOpen5, setLocationOpen5] = useState(false);
  const [isTimeError, setIsTimeError] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const { user } = useSelector((state) => state?.auth);

  const [typeId, setTypeId] = useState(null);
  const [campaignFeatureDetail, setCampaignFeatureDetail] = useState({
    name: "",
    campaign_status: "published",
    start_date: scheduleDate ? scheduleDate : new Date().format("YYYY-MM-DD"),
    // end_date: moment(scheduleDate ? scheduleDate : new Date())
    //   .add(30, "d")
    //   .format("YYYY-MM-DD"),
    end_date: moment(scheduleDate ? scheduleDate : new Date())
      .add(7, "d")
      .format("YYYY-MM-DD"),
    //   +
    // " " +
    // moment(scheduleTime, "HH:mm:ss").format("HH:mm:ssZ"),
    type: "job",
    type_id: "",
    industry: [],
    region: [],
    gender: "male",
    age_from: 15,
    age_to: 45,
    hashtags: hashText,
    is_wp: true,
    // is_adBar: true,
  });

  useEffect(() => {
    dispatch(getIndustryData());
    dispatch(
      getJobs({
        filter: "my",
        limit: 100,
      })
    );
  }, []);
  const handleJobSelection = (e) => {
    setJobError("");
    const selectedId = e.target.value;
    setSelectedJobId(selectedId);
    const type = selectedId;
    setTypeId(type);
  };
  const inputLocationArr = [
    {
      id: 1,
      value: "",
    },
  ];

  const [locationArr, setLocationArr] = useState(inputLocationArr);

  const inputArr = [
    {
      value: "",
    },
  ];
  const [arr, setArr] = useState(inputArr);
  const addInput = () => {
    setArr((s) => {
      return [
        ...s,
        {
          value: "",
        },
      ];
    });
  };

  const { industryData } = useSelector((state) => state.master);
  const [selectedIndustry, setSelectedIndustry] = useState([]);
  const [filtertedIndustryList, setFiltertedIndustryList] =
    useState(industryData);
  const [scheduleCheck, setScheduleCheck] = useState(false);
  const [jobError, setJobError] = useState("");

  const addLocation = () => {
    setLocationArr((s) => {
      return [
        ...s,
        {
          type: "text",
          value: "",
        },
      ];
    });
  };
  const getDaysDiff = (startDate, EndDate) => {
    const date1 = new Date(startDate);
    const date2 = new Date(EndDate);
    const diffTime = Math.abs(date2 - date1);
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
    setDuration(diffDays);
  };

  useEffect(() => {
    setFiltertedIndustryList(industryData);
  }, [industryData]);

  useEffect(() => {
    const removeDuplicates = filtertedIndustryList.filter((el) => {
      return selectedIndustry?.indexOf(el.name) == -1;
    });
    if (selectedIndustry?.length > 0)
      setFiltertedIndustryList(removeDuplicates);
    else setFiltertedIndustryList(industryData);
  }, [selectedIndustry]);
  useEffect(() => {
    if (stateId) {
      dispatch(getCampaign({ id: stateId }))
        .then((response) => {
          setIsEdit(true);
          setCampaignFeatureDetail({
            ...campaignFeatureDetail,
            id: response.data[0].id,
            // name: response.data[0].name,
            description: response.data[0].description,
            campaign_status: response.data[0].campaign_status,
            start_date: moment(response.data[0].start_date).format(
              "YYYY-MM-DD"
            ),
            end_date: moment(response.data[0].end_date).format("YYYY-MM-DD"),
            type: response.data[0].type,
            type_id: response.data[0].type_id,
            industry: response.data[0].industry,
            age_from: response.data[0].age_from,
            age_to: response.data[0].age_to,
            gender: response.data[0].gender,
            hashtags: response.data[0].hashtags.map((e) => {
              return e.name;
            }),
            action_link: response.data[0].action_link,
            action_link_label: response.data[0].action_link_label,
            is_adBar: response.data[0].is_adBar,
            is_wp: response.data[0].is_wp,
          });
          setScheduleDate(
            moment(response.data[0].start_date).format("YYYY-MM-DD")
          );
          setScheduleTime(moment(response.data[0].start_date));
          if (response.data[0].campaign_status === "scheduled") {
            setScheduleCheck(true);
          }
          setHashText(
            response.data[0].hashtags.map((e) => {
              return e.name;
            })
          );
          setSelectedJobId(response.data[0]?.type_id);
          getDaysDiff(response.data[0].start_date, response.data[0].end_date);
          var newObj = [
            {
              value: "",
            },
          ];
          for (var i = 0; i < response.data[0].industry?.length; i++) {
            var temp = {};
            temp["value"] = response.data[0].industry[i];
            if (temp.value) newObj.push(temp);
          }

          var newObj1 = [
            {
              value: "",
            },
          ];

          for (var i = 0; i < response.data[0].region?.length; i++) {
            var temp = {};
            temp["value"] = response.data[0].region[i];
            if (temp.value) newObj1.push(temp);
          }

          var newArray = newObj.filter((item) => item.value !== "");
          var newArray1 = newObj1.filter((item) => item.value !== "");
          setArr(response.data[0].industry?.length > 0 ? newArray : newObj);
          setLocationArr(
            response.data[0].region?.length > 0 ? newArray1 : newObj1
          );
        })
        .catch((e) => {});
    }
  }, []);

  useEffect(() => {
    if (location?.state?.jobId) {
      setSelectedJobId(location?.state?.jobId);
      setTypeId(location?.state?.jobId);
    }
  }, [getAllJobs]);

  useEffect(() => {
    if (locationInput) {
      dispatch(getLocationsData(locationInput))
        .then((res) => {
          if (res?.data) {
            const removeDuplicates = res?.data.filter((el) => {
              const isDuplicate = locationArr.find((x) => x.value === el.name);
              return el.name !== isDuplicate?.value;
            });
            setLocationList(removeDuplicates);
          } else setLocationList([]);
        })
        .catch(() => {
          setLocationList([]);
        });
    }
  }, [locationArr]);

  const checkValidation = (type) => {
    let formIsValid = true;
    if (type === "job" || "all")
      if (!selectedJobId) {
        setJobError("Please select a job");
        formIsValid = false;
        return;
      }
    return formIsValid;
  };

  const createCampaign = (status, type) => {
    if (checkValidation("all")) {
      let newSubmitData = {
        name: "Job Promotion",
        start_date: scheduleDate
          ? scheduleDate +
            " " +
            moment(scheduleTime, "HH:mm:ss").format("HH:mm:ssZ")
          : campaignFeatureDetail.start_date,
        end_date: scheduleDate
          ? moment(scheduleDate ? scheduleDate : new Date())
              .add(7, "d")
              .format("YYYY-MM-DD") +
            " " +
            moment(scheduleTime, "HH:mm:ss").format("HH:mm:ssZ")
          : campaignFeatureDetail?.end_date,
        type: type,
        type_id: typeId || selectedJobId,
        industry: arr
          .filter(function (e) {
            return e.value !== "";
          })
          .map((e) => {
            return e.value;
          }),
        region: locationArr
          .filter(function (e) {
            return e.value !== "";
          })
          .map((e) => {
            return e.value;
          }),
        gender: campaignFeatureDetail.gender,
        hashtags: hashText,
        age_from: campaignFeatureDetail.age_from,
        age_to: campaignFeatureDetail.age_to,
        campaign_status: status,
        is_wp: campaignFeatureDetail.is_wp,
      };
      if (campaignFeatureDetail.id) {
        newSubmitData.id = campaignFeatureDetail.id;
        dispatch(postCampaign(newSubmitData))
          .then((data) => {
            if (data.status)
              setTimeout(
                () =>
                  toast.success(
                    status === "draft"
                      ? "Saved Successfully"
                      : status === "scheduled"
                      ? "Scheduled Successfully"
                      : "Published Successfully!",
                    {
                      position: toast.POSITION.BOTTOM_LEFT,
                    }
                  ),
                2000
              );
            if (status === "published" || status === "scheduled") {
              navigate("/campaign");
            }
          })
          .catch((e) => {});
      } else
        dispatch(postCampaign(newSubmitData))
          .then((data) => {
            setCampaignFeatureDetail({
              ...campaignFeatureDetail,
              id: data.data.id,
            });
            setTimeout(
              () =>
                toast.success(
                  status === "draft"
                    ? "Saved Successfully"
                    : status === "scheduled"
                    ? "Scheduled Successfully"
                    : "Published Successfully",
                  {
                    position: toast.POSITION.BOTTOM_LEFT,
                  }
                ),
              2000
            );
            navigate("/campaign");
          })
          .catch((error) => {
            console.log("error", error);
          });
    }
  };

  const totalAmount = () => {
    const day_price =
      membership?.flatMap((featureValuesArray) =>
        featureValuesArray?.filter(
          (item) =>
            item?.membership_id === user?.data?.membership?.id &&
            item?.d_type == "price"
        )
      )[0]?.value || 0;

    return Number(day_price);
  };
  return (
    <>
      <div className="create-camp" style={{ marginLeft: "8px" }}>
        <div className="camp-slider" style={{ padding: "0px" }}>
          <h5>Period</h5>
          <p>Featuring a job is only possible for a period of 30 days.</p>
        </div>
        <div className="schedule">
          <div className="check">
            <div className="d-flex mt-4">
              <input
                type="checkbox"
                id="experienceCheck"
                name="experienceCheck"
                // className="mt-3"
                onChange={(e) => setScheduleCheck(e.target.checked)}
                checked={scheduleCheck}
              />
              <label htmlFor="educationCheck" className="me-4 ms-2">
                Schedule Start Date
              </label>
            </div>

            <ScheduleFeatureCamp
              scheduleDate={campaignFeatureDetail?.end_date}
              setIsTimeError={setIsTimeError}
              selectedScheduleDate={scheduleDate}
              setScheduleDate={setScheduleDate}
              scheduleTime={scheduleTime}
              setScheduleTime={setScheduleTime}
              today={today}
              scheduleCheck={scheduleCheck}
              campaignFeatureDetail={campaignFeatureDetail}
              setCampaignFeatureDetail={setCampaignFeatureDetail}
              duration={duration}
              isEdit={isEdit}
            />
          </div>
        </div>
        <div className="camp-form featurecamp-form">
          <form
          // className={editPageData ? "" : "mt-7"}
          >
            <Label>Select Job</Label>
            <Input
              type="select"
              name="select"
              id="exampleSelect"
              onChange={handleJobSelection}
              value={selectedJobId}
              // style={{backgroundImage:`url('../../../')`}}
              //  onBlur={() => checkValidation("job")}
            >
              <option value="">Select</option>
              {getAllJobs?.map((job) => (
                <option
                  key={job.id}
                  value={job.id}
                  title={`${job.title} at ${job.company_name}`}
                >
                  {job.title?.length > 30
                    ? job.title?.substring(0, 30) + "..."
                    : job.title}{" "}
                  at{" "}
                  {job.company_name?.length > 30
                    ? job.company_name?.substring(0, 30) + "..."
                    : job.company_name}
                </option>
              ))}
            </Input>
            {jobError && (
              <label className="label-input100 text-danger">{jobError}</label>
            )}

            <div className="row my-2 industry">
              <div className="col-sm-6 autocomplete-wrapper">
                <Label>Industry</Label>
                {arr.map((item, i) => {
                  return (
                    <div style={{ marginBottom: "10px" }}>
                      <Autocomplete
                        value={item.value}
                        items={filtertedIndustryList}
                        getItemValue={(item) => item.name}
                        shouldItemRender={(item, value) =>
                          item.name.toLowerCase().indexOf(value.toLowerCase()) >
                          -1
                        }
                        renderMenu={(items, value) => (
                          <div className="dropdown">
                            {items.length === 0 ? "" : items}
                          </div>
                        )}
                        renderItem={(item, isHighlighted) => (
                          <div
                            className={`item ${
                              isHighlighted ? "selected-item" : ""
                            }`}
                          >
                            {item.name}
                          </div>
                        )}
                        onChange={(event, val) => {
                          setArr((s) => {
                            const newArr = s.slice();
                            newArr[i].value = val;
                            return newArr;
                          });
                          setSelectedIndustry((s) => {
                            const newArr = s.slice();
                            newArr[i] = val;
                            return newArr;
                          });
                        }}
                        onSelect={(val, obj) => {
                          setArr((s) => {
                            const newArr = s.slice();
                            newArr[i].value = val;
                            return newArr;
                          });
                          setSelectedIndustry([...selectedIndustry, val]);
                        }}
                        wrapperStyle={{}}
                      />
                    </div>
                  );
                })}
                {arr?.length < 5 && (
                  <span
                    className="addButton"
                    onClick={() => {
                      addInput();
                    }}
                  >
                    + Add Another Industry
                  </span>
                )}
              </div>
              <div className="col-sm-6 autocomplete-wrapper">
                <Label>Region</Label>
                {locationArr.map((item, i) => {
                  return (
                    <div style={{ marginBottom: "10px" }}>
                      <Autocomplete
                        open={
                          i === 0
                            ? locationOpen1
                            : i === 1
                            ? locationOpen2
                            : i === 2
                            ? locationOpen3
                            : i === 3
                            ? locationOpen4
                            : locationOpen5
                        }
                        value={item.value}
                        items={locationList}
                        getItemValue={(item) => item.name}
                        shouldItemRender={(item, value) =>
                          item.name.toLowerCase().indexOf(value.toLowerCase()) >
                          -1
                        }
                        renderMenu={(items, value) => (
                          <div className="dropdown">
                            {items.length === 0 ? "" : items}
                          </div>
                        )}
                        renderItem={(item, isHighlighted) => (
                          <div
                            className={`item ${
                              isHighlighted ? "selected-item" : ""
                            }`}
                          >
                            {item.name}
                          </div>
                        )}
                        onChange={(event, val) => {
                          if (val.length === 0) {
                            if (i === 0 && locationOpen1) {
                              setLocationOpen1(false);
                            } else if (i === 1 && locationOpen2) {
                              setLocationOpen2(false);
                            } else if (i === 2 && locationOpen3) {
                              setLocationOpen3(false);
                            } else if (i === 4 && locationOpen4) {
                              setLocationOpen3(false);
                            }
                          } else {
                            if (i === 0 && !locationOpen1)
                              setLocationOpen1(true);
                            else if (i === 1 && !locationOpen2)
                              setLocationOpen2(true);
                            else if (i === 2 && !locationOpen3)
                              setLocationOpen3(true);
                            else if (i === 3 && !locationOpen4)
                              setLocationOpen4(true);
                            else if (i === 4 && !locationOpen5)
                              setLocationOpen5(true);
                          }
                          setLocationInput(val);
                          setLocationArr((s) => {
                            const newArr = s.slice();
                            newArr[i].value = val;
                            return newArr;
                          });
                        }}
                        onSelect={(val, obj) => {
                          setLocationInput(val);
                          setLocationArr((s) => {
                            const newArr = s.slice();
                            newArr[i].value = val;
                            return newArr;
                          });
                          setLocationOpen1(false);
                          setLocationOpen2(false);
                          setLocationOpen3(false);
                          setLocationOpen4(false);
                        }}
                        wrapperStyle={{}}
                      />
                    </div>
                  );
                })}
                {locationArr?.length < 5 && (
                  <span
                    className="addButton"
                    onClick={() => {
                      addLocation();
                    }}
                  >
                    + Add Another Region
                  </span>
                )}
              </div>
            </div>
            <div className="row gender">
              <div className="col-6">
                <Label for="exampleSelect1">Gender</Label>
                <div
                  className={
                    !campaignFeatureDetail.gender
                      ? "btn blue-btn ms-3"
                      : "btn border-btn ms-3 border-end-0 rounded-start"
                  }
                  style={{ padding: "8px 16px" }}
                  onClick={() =>
                    setCampaignFeatureDetail({
                      ...campaignFeatureDetail,
                      gender: null,
                    })
                  }
                >
                  All
                </div>
                <div
                  className={
                    campaignFeatureDetail.gender === "male"
                      ? "btn blue-btn"
                      : "btn border-btn border-end-0 border-start-0"
                  }
                  onClick={() =>
                    setCampaignFeatureDetail({
                      ...campaignFeatureDetail,
                      gender: "male",
                    })
                  }
                >
                  Men
                </div>
                <div
                  className={
                    campaignFeatureDetail.gender === "female"
                      ? "btn blue-btn"
                      : "btn border-btn border-start-0 rounded-end"
                  }
                  style={{ padding: "8px 16px" }}
                  onClick={() =>
                    setCampaignFeatureDetail({
                      ...campaignFeatureDetail,
                      gender: "female",
                    })
                  }
                >
                  Women
                </div>
              </div>
              <div className="col-6 age">
                <div className="">
                  <div className="row row-cols-auto">
                    <div className="col-2 w-0">
                      <Label for="exampleSelect1">Age</Label>
                    </div>
                    <div className="col-2 year ">
                      <Input
                        type="select"
                        value={campaignFeatureDetail.age_from}
                        name="select"
                        id="exampleSelect"
                        onChange={(e) =>
                          setCampaignFeatureDetail({
                            ...campaignFeatureDetail,
                            age_from: e.target.value,
                          })
                        }
                        // onBlur={() => validation("size")}
                      >
                        <option value={15}>15</option>
                        <option value={25}>25</option>
                        <option value={35}>35 </option>
                      </Input>
                    </div>
                    <h4 className="dot">.</h4>
                    <div className="col-2 month">
                      <Input
                        type="select"
                        value={campaignFeatureDetail.age_to}
                        name="select"
                        id="exampleSelect"
                        onChange={(e) =>
                          setCampaignFeatureDetail({
                            ...campaignFeatureDetail,
                            age_to: e.target.value,
                          })
                        }
                      >
                        <option value={45}>45</option>
                        <option value={55}>55</option>
                        <option value={65}>65</option>
                      </Input>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row my-2 hashtag">
              <div className="col-sm-12">
                <Label className="mt-0">Hashtag</Label>
                <TagsInput
                  tags={hashText}
                  setTags={setHashText}
                  isCampaign={true}
                />
              </div>
            </div>
            {/* <div className="check">
              <input
                type="checkbox"
                id="experienceCheck"
                name="experienceCheck"
                checked={campaignFeatureDetail.is_wp}
                onClick={(e) =>
                  setCampaignFeatureDetail({
                    ...campaignFeatureDetail,
                    is_wp: e.target.checked,
                  })
                }
              />
              <label htmlFor="educationCheck">Place on the WorkerPool</label>
            </div>
            <div className="placetext">
              <p>
                By selecting this option, your ad will be also featured in our
                WorkerPool page and app. Please note that this feature is only
                recommended for job posts!
              </p>
            </div> */}
            <div className="row">
              <div className="footer d-flex mt-0">
                <div
                  className="mt-3"
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    width: "100%",
                    alignItems: "center",
                  }}
                >
                  <div>
                    <h4 className="text-dark">
                      Total Amount: ${totalAmount()}
                    </h4>
                  </div>
                  <div style={{ display: "flex" }}>
                    {!isTimeError ? (
                      <div>
                        <div
                          className="btn border-btn ms-3"
                          // onClick={() => setSavedStatus("save")}
                          onClick={() => createCampaign("draft", "job")}
                          style={{
                            backgroundColor: "white",
                            color: "rgb(4, 50, 163)",
                            width: "94.55px",
                            height: "42.6px",
                            border: "1px solid rgb(4, 50, 163)",
                          }}
                        >
                          Save
                        </div>
                        <div
                          className="btn blue-btn me-2"
                          style={{
                            backgroundColor: "rgb(4, 50, 163)",
                            color: "white",
                            marginLeft: "20px",
                            width: "190px",
                            height: "42.6px",
                          }}
                          onClick={() => {
                            setSavedStatus("payment");
                            setPaymentModal(true);
                          }}
                        >
                          Complete Payment
                        </div>
                      </div>
                    ) : (
                      <div style={{ marginLeft: "190px" }}>
                        <div
                          className="btn border-btn ms-3"
                          style={{
                            opacity: "0.5",
                            cursor: "not-allowed",
                            backgroundColor: "white",
                            color: "rgb(4, 50, 163)",
                            marginLeft: "20px",
                            width: "94.55px",
                            height: "42.6px",
                            border: "1px solid rgb(4, 50, 163)",
                          }}
                        >
                          Save
                        </div>
                        <div
                          className="btn blue-btn me-2"
                          style={{
                            opacity: "0.5",
                            cursor: "not-allowed",
                            backgroundColor: "rgb(4, 50, 163)",
                            color: "white",
                            marginLeft: "20px",
                            width: "190px",
                            height: "42.6px",
                          }}
                        >
                          Complete Payment
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>

        {paymentModal && (
          <PaymentFeature
            modal={paymentModal}
            toggle={() => setPaymentModal(!paymentModal)}
            title="Pay by card"
            featureCheck={featureCheck}
            setFeatureCheck={setFeatureCheck}
            createCampaign={createCampaign}
            scheduleCheck={scheduleCheck}
            scheduleDate={scheduleDate}
            scheduleTime={scheduleTime}
            selectedJobId={selectedJobId}
            totalAmount={totalAmount()}
          />
        )}
      </div>
    </>
  );
};

export default FeatureJob;
