import io from "socket.io-client";
import { fromEvent } from "rxjs";

export const ChatEvent = {
  CONNECT: "connection",
  DISCONNECT: "disconnect",
  JOIN: "join",
  MESSAGE: "message",
  UNREAD: "unread",
  LEAVE: "leave",
  ROOM_DELETE: "room delete",
  TYPING: "typing",
  STOP_TYPING: "stopTyping",
  UPDATE_MESSAGE: "updateMessage",
  DELETE_MESSAGE: "deleteMessage",
};
export const MessageStatus = {
  SENT: "sent",
  DELIVERED: "delivered",
  SEEN: "seen",
  UNSENT: "unsent",
  ERROR: "error",
};

class SocketService {
  socket = {};
  WEB_SOCKET_URL = process.env.REACT_APP_SOCKET_URL;

  init() {
    console.log("Initializing Socket Service");
    this.socket = io(this.WEB_SOCKET_URL);
    return this;
  }

  getRoomName(members, user_id) {
    const otherMembers = members?.filter(
      (member) => member?.user?.user_id !== user_id
    );
    if (otherMembers?.length === 1) {
      return `${otherMembers[0].user.firstName} ${otherMembers[0].user.lastName}`;
    } else if (otherMembers?.length === 2) {
      return `${otherMembers[0].user.firstName}  ${otherMembers[0].user.lastName}, ${otherMembers[1].user.firstName}  ${otherMembers[1].user.lastName}`;
    } else if (otherMembers?.length > 2) {
      return `${otherMembers[0].user.firstName}  ${
        otherMembers[0].user.lastName
      }, ${otherMembers[1].user.firstName}  ${
        otherMembers[1].user.lastName
      }, and ${otherMembers.length - 2} others`;
    } else {
      return "Untitled Group";
    }
  }

  join({ user, room }, isFirst = false) {
    console.log(`${user} joined ${room}`);
    this.socket.emit(ChatEvent.JOIN, { user, room, isFirst });
  }

  send(message) {
    console.log("Sending Message: " + message);
    this.socket.emit(ChatEvent.MESSAGE, message);
  }

  updateUnread(unread, roomCode, user) {
    this.socket.emit(ChatEvent.UNREAD, { unread, roomCode, user });
  }

  onJoin() {
    return fromEvent(this.socket, ChatEvent.JOIN);
  }

  onLeave() {
    return fromEvent(this.socket, ChatEvent.LEAVE);
  }

  onRoomDelete() {
    return fromEvent(this.socket, ChatEvent.ROOM_DELETE);
  }

  onMessage() {
    return fromEvent(this.socket, ChatEvent.MESSAGE);
  }

  updateMessage(messageId, roomCode, newMessage) {
    this.socket.emit(ChatEvent.UPDATE_MESSAGE, {
      messageId,
      roomCode,
      message: newMessage,
    });
  }

  onUpdateMessage() {
    return fromEvent(this.socket, ChatEvent.UPDATE_MESSAGE);
  }

  deleteMessage(messageId, roomCode) {
    this.socket.emit(ChatEvent.DELETE_MESSAGE, {
      messageId,
      roomCode,
    });
  }

  onDeleteMessage() {
    return fromEvent(this.socket, ChatEvent.DELETE_MESSAGE);
  }

  disconnect() {
    this.socket.disconnect();
  }

  emitTyping(roomCode, username) {
    this.socket.emit(ChatEvent.TYPING, { roomCode, username });
  }

  emitStopTyping(roomCode, username) {
    this.socket.emit(ChatEvent.STOP_TYPING, { roomCode, username });
  }

  onTyping() {
    return fromEvent(this.socket, ChatEvent.TYPING);
  }

  onStopTyping() {
    return fromEvent(this.socket, ChatEvent.STOP_TYPING);
  }
}

export default new SocketService();
