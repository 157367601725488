import { call } from "./apiCall";

class ArticleApi {
  getArticles(token, params) {
    return new Promise((resolve, reject) => {
      (async () => {
        try {
          const res = await call(
            "get",
           `api/v1/feed/article-list`,
            params,
            null,
            token
          );
          if (res.data.success === true) {
            resolve(res.data);
          }
          if (res.data.success === false) {
            resolve(res.data);
          }
        } catch (err) {
          console.log(err);
          reject(err);
        }
      })();
    });
  }

  createArticle(data, token) {
    return new Promise( (resolve, reject) => {
        (async () => {
            try {
                const res = await call(
                    "post",
                    "api/v1/feed/article",
                    null,
                    data,
                    token
                )
                console.log(res.data)
                resolve(res.data)
            } catch (err) {
                console.log(err)
                reject(err)

            }
        })()
    })
}

editArticle(data, token) {
  return new Promise( (resolve, reject) => {
      (async () => {
          try {
              const res = await call(
                  "patch",
                  "api/v1/feed/article",
                  null,
                  data,
                  token
              )
              console.log(res.data)
              resolve(res.data)
          } catch (err) {
              console.log(err)
              reject(err)

          }
      })()
  })
}


getArticleDetail(id, token) {
  return new Promise( (resolve, reject) => {
      (async () => {
          try {
              const res = await call(
                  "get",
                  `api/v1/feed/article-list?id=${id}`,
                  null,
                  null,
                  token
              )
              console.log(res.data)
              resolve(res.data)
          } catch (err) {
              console.log(err)
              reject(err)
          }
      })()
  })
}

likeArticle(data) {
  let token = localStorage.getItem("accessToken");
  return new Promise( (resolve, reject) => {
      (async () => {
          try {
              const res = await call(
                  "post",
                  `api/v1/feed/reaction`,
                  null,
                  data,
                  token
              )
              console.log(res.data)
              resolve(res.data)
          } catch (err) {
              console.log(err)
              reject(err)
          }
      })()
  })
}

commentArticle(data) {
  let token = localStorage.getItem("accessToken");
  return new Promise( (resolve, reject) => {
      (async () => {
          try {
              const res = await call(
                  "patch",
                  `api/v1/feed/comment`,
                  null,
                  data,
                  token
              )
              console.log(res.data)
              resolve(res.data)
          } catch (err) {
              console.log(err)
              reject(err)
          }
      })()
  })
}

getCommentDetails(data) {
    let token = localStorage.getItem("accessToken");
    return new Promise((resolve, reject) => {
      (async () => {
        try {
          const res = await call(
            "get",
            `api/v1/feed/comments`,
            data,
            null,
            token
          );
          console.log(res.data)
          resolve(res.data);  
        } catch (err) {
          console.log(err);
          reject(err);
        }
      })();
    });
  }
  
}

export const articleApi = new ArticleApi();
