import React, { useEffect } from "react";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import { useDispatch, useSelector } from "react-redux";
import { getPageDetails } from "../../../store/dashboard/dashboardActions";
import placeholderUser from "../../../assets/img/placeholder_user.jpg";
import pagePlaceholder from "../../../assets/img/page-placeholder.svg";
import { as_a_page } from "../../../store/dashboard/dashboardActions";
import { useNavigate } from "react-router-dom";
const ChangePageModel = (props) => {
  const { modal, toggle, title, getPageDetailData } = props;

  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.auth);

  const updatePublish = (event, i, type) => {
    // if (event.target.checked) {
    //   if (type === "user") {
    //     if (isPagePosts) {
    //       setSelectedUserId(false);
    //     }
    //     dispatch(as_a_page(postId, "page", null));
    //     setTimeout(() => toggle(), 500);
    //   } else if (type === "page") {
    //     // setRoleData(i)
    //     if (isPagePosts) {
    //       setSelectedUserId(true);
    //     }
    //     dispatch(as_a_page(postId, "page", i));
    //     setTimeout(() => toggle(), 1000);
    //   }
    // }
  };
  const navigate = useNavigate();
  return (
    <div>
      <Modal
        isOpen={modal}
        className="create-post-header"
        size="lg"
        style={{ maxWidth: "600px" }}
      >
        <ModalHeader toggle={toggle}>{title}</ModalHeader>
        <ModalBody className="publishModalClass mb-20">
          <div className="row create-post">
            <h5 className="mb-3">Select any page to create a newsletter</h5>
            {/* {getPageDetailData?.data?.Users?.map((i, ind) => {
              return (
                <div
                  key={ind}
                  className="details d-flex justify-content-between align-items-center mb-20 mt-20"
                  id={i.id}
                >
                  <div className="d-flex gap-4" style={{ width: "90%" }}>
                    <div className="">
                      <img
                        src={i?.profile_img ? i?.profile_img : placeholderUser}
                        className="round-image"
                        alt="profile_icon"
                      />
                    </div>
                    <div className="names">
                      <h6>
                        {i.first_name} {i.last_name}
                      </h6>
                      <p>{i?.profile?.profile_headline} </p>
                    </div>
                  </div>

                  <input
                    type="radio"
                    name="user"
                    id={i.id}
                    onClick={(e) => {
                      updatePublish(e, i, "user");
                    }}
                    // checked={i.id === rolePageId ? true : false}
                  />
                </div>
              );
            })} */}

            {getPageDetailData?.data?.Pages?.map((item, index) => {
              return (
                <div
                  key={index}
                  className="details d-flex justify-content-between align-items-center mb-20"
                  id={item.id}
                >
                  <div
                    className="d-flex gap-4 align-items-center"
                    style={{ width: "90%" }}
                  >
                    <div className="">
                      <img
                        src={item.icon ? item.icon : pagePlaceholder}
                        className="round-image"
                        alt="img "
                      />
                    </div>
                    <div className="names">
                      <h6>{item.name}</h6>
                      <p>{item.industry}</p>
                    </div>
                  </div>

                  <input
                    type="radio"
                    name="user"
                    id={item.id}
                    onClick={(e) =>
                      navigate(`/pageadminview/${item?.id}`, {
                        state: {
                          newsletter: true,
                        },
                      })
                    }
                  />
                </div>
              );
            })}
          </div>
        </ModalBody>
      </Modal>
    </div>
  );
};

export default ChangePageModel;
