import { REFER_MY_FRIEND, GET_REFER_DATA } from "../types";
import { walletAPI } from "../../api/walletAPI";
import { toast } from "react-toastify";

export const sendReferLink = (data) => (dispatch) => {
  return walletAPI.sendRefer(data).then(
    (response) => {
      dispatch({
        type: REFER_MY_FRIEND,
      });
      return Promise.resolve(response);
    },
    (error) => {
      console.log(error);
      if(error.response.data.message==="Please enter correct Email Address")
      toast.error("Please enter correct email address", {
        position: toast.POSITION.BOTTOM_LEFT,
      }); else
      toast.error(error.response.data.message, {
        position: toast.POSITION.BOTTOM_LEFT,
      });
      return Promise.reject(error);
    }
  );
};

export const get_refer_info = (data) => (dispatch) => {
  return walletAPI.getRefer(data).then(
    (response) => {
      dispatch({
        type: GET_REFER_DATA,
        payload: response?.data,
      });
      return Promise.resolve(response);
    },
    (error) => {
      console.log(error);
      return Promise.reject(error);
    }
  );
};

export const getUserTransaction = (data) => (dispatch) => {
  return walletAPI.getTransaction().then(
    (response) => {
      return Promise.resolve(response);
    },
    (error) => {
      console.log(error);
      return Promise.reject(error);
    }
  );
};

