import React, { useState } from 'react';
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import InfiniteScroll from "react-infinite-scroll-component";
import pagePlaceholder from "../../assets/img/page-placeholder.svg";

const NpMailNotificationModal = ({modal, toggle,notifications}) => {
    const [currentNpMailCount, setCurrentNpMailCount] = useState(0);
    const [isMoreNpMail, setIsMoreNpMail] = useState();
    const loadMoreMyJob = () => {
        setTimeout(() => {
            setCurrentNpMailCount(currentNpMailCount + 10);
        }, 1000);
      };
  return (
    <Modal size="md" isOpen={modal} style={{ maxWidth: "600px" }} centered>
    <div className="shortlist-modal">
      <ModalHeader toggle={() => toggle()}>
        All np-mails
      </ModalHeader>
      <ModalBody>
        <div className="shortlist-main" >
          {/* <span onClick={() => clearAll()}>Clear all selections</span> */}
          <div id="scrollableDiv">
          {/* <InfiniteScroll
            // dataLength={getMyJobs?.length ? getMyJobs?.length : 0}
            next={loadMoreMyJob}
            hasMore={isMoreMyJob}
            style={{ overflow: "visible" }}
            scrollableTarget="scrollableDiv"
          > */}
            <>
              {notifications
            .filter((el) => el.type == "user")
            .map((notification, index) => (
                <div
                  key={index}
                  className="details d-flex justify-content-between align-items-center mb-20 mt-20"
                >
                 
                  <div
                    className="d-flex gap-4 align-items-center"
                    style={{ width: "90%" }}
                  >
                    <div className="">
                      <img
                        src={
                          notification?.User?.profile_img
                            ? notification?.User?.profile_img
                            : pagePlaceholder
                        }
                       
                        className="round-image"
                        alt="profile_icon"
                      />
                    </div>
                    <div className="names">
                      <h6>{`${notification?.User?.first_name} ${notification?.User?.last_name}`}</h6>
                     
                      <p>
                      Sent you a NPMail
                      </p>
                    </div>
                  </div>
                </div>
              ))}
            </>
          {/* </InfiniteScroll> */}
          </div>
         </div>
      </ModalBody>
    </div>
  </Modal>
  )
}

export default NpMailNotificationModal;
