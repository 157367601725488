import React, { useEffect, useState } from "react";
import Refer from "../../../../core/components/layout/Sidebar/Refer";
import Sidebar from "../../../../core/components/layout/Sidebar/Sidebar";
import SidebarFooter from "../../../setting/SidebarFooter";
import { VideoSidebar } from "../../../../core/components/layout/Sidebar/VideoSidebar";
import {
  FacebookEmbed,
  InstagramEmbed,
  LinkedInEmbed,
  XEmbed,
} from "react-social-media-embed";
import { useDispatch, useSelector } from "react-redux";
import { getUserSetting } from "../../../../store/setting/SettingAction";
import SocialMediaModal from "../../../setting/components/SocialMediaModal";

export default function SocialMedia(props) {
  const {
    profileData,
    deleteIntroVideo,
    isAllowedToEdit,
    addIntroModal,
    setAddIntroModal,
  } = props;

  const [tab, setTab] = useState("facebook");
  const token = localStorage.getItem("accessToken");
  const dispatch = useDispatch();
  const [socialLinks, setSocialLinks] = useState({});
  const [socialSettings, setSocialSettings] = useState(null);
  const [socialMediaModal, setSocialMediaModal] = useState(false);

  useEffect(() => {
    if (profileData)
      dispatch(getUserSetting(3, token, profileData?.id)).then((res) => {
        const settings = res?.data?.length ? res.data[0]?.settings : [];
        setSocialSettings(res?.data?.length ? res.data[0] : null);
        if (settings?.length) {
          setSocialLinks(
            settings.reduce((acc, setting) => {
              acc[setting.key] = setting.value;
              return acc;
            }, {}) || {}
          );
          setTab(settings.find((s) => s.value == 1)?.key?.split("_")[0]);
        }
      });
  }, [profileData]);

  return (
    <div className="social-media mb-5">
      <div className="row">
        <div className="col-lg-3">
          <Refer />
        </div>
        <div className="col-lg-6">
          <div className="social">
            <div className="box">
              <div className="row align-items-center p-3">
                <div className="col-lg-9">
                  <div className="d-flex gap-3">
                    {socialLinks.facebook_toggle == 1 && (
                      <div
                        className="social-tabs d-flex gap-1"
                        onClick={() => setTab("facebook")}
                      >
                        <div className="icon">
                          <div className="facebook"></div>
                        </div>
                        <p>Facebook</p>
                      </div>
                    )}
                    {socialLinks.instagram_toggle == 1 && (
                      <div
                        className="social-tabs d-flex gap-1"
                        onClick={() => setTab("instagram")}
                      >
                        <div className="icon">
                          <div className="instagram"></div>
                        </div>
                        <p>Instagram</p>
                      </div>
                    )}
                    {socialLinks.twitter_toggle == 1 && (
                      <div
                        className="social-tabs d-flex gap-1"
                        onClick={() => setTab("twitter")}
                      >
                        <div className="icon">
                          <div className="twitter"></div>
                        </div>
                        <p>Twitter</p>
                      </div>
                    )}
                    {socialLinks.linkedin_toggle == 1 && (
                      <div
                        className="social-tabs d-flex gap-1"
                        onClick={() => setTab("linkedin")}
                      >
                        <div className="icon">
                          <div className="linkdin"></div>
                        </div>
                        <p>Linkedin</p>
                      </div>
                    )}
                  </div>
                </div>
                {isAllowedToEdit() && (
                  <div className="col-lg-3">
                    <div className="float-xl-end">
                      <div
                        className="share"
                        onClick={() => setSocialMediaModal(true)}
                      ></div>
                    </div>
                  </div>
                )}
              </div>
              <div className="line"></div>
              <div className="row p-3">
                {/* <div className="more">See more on Facebook</div> */}
                <div className="main-box">
                  {socialLinks?.facebook_toggle == 1 &&
                    tab == "facebook" &&
                    (socialLinks?.facebook_link ? (
                      <div
                        style={{ display: "flex", justifyContent: "center" }}
                      >
                        <FacebookEmbed
                          url={socialLinks.facebook_link}
                          width={540}
                          style={{ overflow: "unset" }}
                        />
                      </div>
                    ) : (
                      <div
                        style={{ display: "flex", justifyContent: "center" }}
                      >
                        <p>
                          Facebook link is not available, Please add a link.
                        </p>
                      </div>
                    ))}

                  {socialLinks.instagram_toggle == 1 &&
                    tab == "instagram" &&
                    (socialLinks.instagram_link ? (
                      <div
                        style={{ display: "flex", justifyContent: "center" }}
                      >
                        <InstagramEmbed
                          url={socialLinks.instagram_link}
                          width={540}
                          style={{ overflow: "unset" }}
                        />
                      </div>
                    ) : (
                      <div
                        style={{ display: "flex", justifyContent: "center" }}
                      >
                        <p>
                          Instagram link is not available, Please add a link.
                        </p>
                      </div>
                    ))}

                  {socialLinks.twitter_toggle == 1 &&
                    tab == "twitter" &&
                    (socialLinks.twitter_link ? (
                      <div
                        style={{ display: "flex", justifyContent: "center" }}
                      >
                        <XEmbed
                          url={socialLinks.twitter_link}
                          width={540}
                          style={{ overflow: "unset" }}
                        />
                      </div>
                    ) : (
                      <div
                        style={{ display: "flex", justifyContent: "center" }}
                      >
                        <p>Twitter link is not available, Please add a link.</p>
                      </div>
                    ))}

                  {socialLinks.linkedin_toggle == 1 &&
                    tab == "linkedin" &&
                    (socialLinks.linkedin_link ? (
                      <div
                        style={{ display: "flex", justifyContent: "center" }}
                      >
                        <LinkedInEmbed
                          url={socialLinks.linkedin_link}
                          width={540}
                          style={{ overflow: "unset" }}
                        />
                      </div>
                    ) : (
                      <div
                        style={{ display: "flex", justifyContent: "center" }}
                      >
                        <p>
                          Linkedin link is not available, Please add a link.
                        </p>
                      </div>
                    ))}

                  {socialLinks?.facebook_toggle != 1 &&
                    socialLinks?.instagram_toggle != 1 &&
                    socialLinks?.twitter_toggle != 1 &&
                    socialLinks?.linkedin_toggle != 1 && (
                      <div
                        style={{ display: "flex", justifyContent: "center" }}
                      >
                        {isAllowedToEdit() ? (
                          <p>
                            You have't linked any Social Media channel yet,
                            please link one.
                          </p>
                        ) : (
                          <p>User have't linked any Social Media channel yet</p>
                        )}
                      </div>
                    )}
                </div>
              </div>
            </div>
          </div>
          {socialMediaModal && socialSettings && (
            <SocialMediaModal
              modal={socialMediaModal}
              toggle={() => setSocialMediaModal(!socialMediaModal)}
              title="Social Link"
              data={socialSettings}
              centered={true}
            />
          )}
        </div>
        <div className="col-lg-3">
          <div className="mb-20">
            <VideoSidebar
              URLData={profileData?.intro_video}
              deleteIntroVideo={deleteIntroVideo}
              isAllowedToEdit={isAllowedToEdit}
              addIntroModal={addIntroModal}
              setAddIntroModal={setAddIntroModal}
            />
          </div>
          <div className="mb-20">
            <Sidebar />
          </div>
          <div className="sidebar-sticky mb-20">
            <SidebarFooter />
          </div>
        </div>
      </div>
    </div>
  );
}
