import React from "react";
import { Modal, ModalBody, ModalHeader } from "reactstrap";

const VideoViewerModal = (props) => {
  const { modal, toggle, URLData } = props;

  return (
    <Modal
      size="md"
      isOpen={modal}
      style={{ maxWidth: "600px" }}
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <ModalHeader toggle={toggle}></ModalHeader>
      <ModalBody>
        <div>
          <video controls width="100%" controlsList="nodownload">
            <source src={URLData} />
          </video>
        </div>
      </ModalBody>
    </Modal>
  );
};

export default VideoViewerModal;
