import React from "react";
import arrowright from "../assets/img/Arrow-right.svg";
import arrowleft from "../assets/img/Arrow-left.svg";
import arrowleftdisable from "../assets/img/Arrow-left-disable.svg";
import arrowrightdisable from "../assets/img/Arrow-right-disable.svg";
import slide from "../assets/img/slide.svg";
import activeslide from "../assets/img/active-slide.svg";
import { useState } from "react";
import { useEffect } from "react";

const PaginationSlider = (props) => {
  const {
    paginationData,
    pageCount,
    setCurrentPage,
    currentPage,
  } = props;

  const handleClick = (idx) => {
     setCurrentPage(idx);
  };
  const handlePrevClick = () => {
    if(currentPage>0)
    setCurrentPage(currentPage - 1);
  else
  return
  };
  const handleNextClick = () => {
    if(currentPage < pageCount-1)
    setCurrentPage(currentPage + 1);
  else 
  return
  };
  return (
    <div>
      <nav aria-label="Page navigation example landing-page-slider">
        <ul className="pagination">
          <li
            className={
              "page-item" + (currentPage == 0 ? " disabled" : "")
            }
            onClick={() => handlePrevClick()}
          >
            <span className="page-link" aria-label="Previous">
              <img src={currentPage == 0 ?arrowleftdisable:arrowleft} alt="arrow" />
            </span>
          </li>
        { paginationData?.map((item, index)=>  
          <li className="page-item" onClick={() => handleClick(index)} key={index}>
          <span className="page-link">
            <img src={currentPage==index?activeslide:slide} alt="arrow" />
          </span>
        </li>

        ) }
          <li
            className={
              "page-item" + (currentPage >= pageCount-1 ? " disabled" : "")
            }
            onClick={() => handleNextClick()}
          >
            <span className="page-link" aria-label="Next">
              {/* <span aria-hidden="true">&raquo;</span>
              <span className="sr-only">Next</span> */}
              <img src={currentPage >= pageCount-1 ?arrowrightdisable:arrowright} alt="arrow" />
            </span>
          </li>
        </ul>
      </nav>
    </div>
  );
};

export default PaginationSlider;
