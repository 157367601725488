import React, { useEffect, useState } from "react";
import { Input, Modal, ModalBody, ModalHeader } from "reactstrap";

import { useDispatch } from "react-redux";
import poll from "../../../../assets/img/polling.svg";
import dmoj from "../../../../assets/img/emojee.svg";
import bx from "../../../../assets/img/bx_image.svg";
import bj from "../../../../assets/img/bi_camera-video.svg";
import paper from "../../../../assets/img/Paperclip.svg";
import party from "../../../../assets/img/party 1.svg";
import photo from "../../../../assets/img/photo.svg";
import "./selectOption.scss";
import { getCelebrationTypes } from "../../../../store/dashboard/dashboardActions";
import rectangleImage from "../../../../assets/img/RectanglepayBycard.png";
const SelectOptionModal = (props) => {
  const { modal, toggle, title, userName, nextModal } = props;
  const [listData, setListData] = useState([]);

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getCelebrationTypes()).then((res) => {
      setListData(res.data);
      console.log("gif", res.data);
    });
  }, []);

  return (
    <div className="topDiv">
      <Modal
        isOpen={modal}
        size="lg"
        style={{
          maxWidth: "600px",
          maxHeight: "calc(45vh - 150px);",
          top: "55px",
        }}
        className="gradient-background"
        // centered
      >
        <ModalHeader toggle={toggle}>
          {title}
          {/* <div className="icon-expand"></div> */}
        </ModalHeader>
        <ModalBody>
          {listData.map((item, index) => {
            return (
              <div
                key={index}
                className="selectDiv"
                onClick={() => nextModal(item)}
              >
                <h5>{item.title}</h5>
                <small>{item?.sub_title}</small>
              </div>
            );
          })}
        </ModalBody>
      </Modal>
    </div>
  );
};

export default SelectOptionModal;
