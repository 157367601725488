import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import GroupDetails from "./GroupDetails";
import { useDispatch } from "react-redux";
import { useParams, useNavigate } from "react-router";
import {
  addGroupURL,
  getGroupDetail,
  getGroupDetailByURL,
} from "../../../../store/groups/groupsActions";
import {
  getPageDetails,
  getPostDetails,
} from "../../../../store/dashboard/dashboardActions";
import moment from "moment";
import { getAllMemeber } from "../../../../store/memberGroup/memberAction";
import { toast } from "react-toastify";

export default function GroupsDetailsContainer() {
  const { user } = useSelector((state) => state.auth);
  const { postData } = useSelector((state) => state.dashboard);
  const { group_detail } = useSelector((state) => state.groups);
  const [roleShareModal, setRoleShareModal] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id } = useParams();
  const token = localStorage.getItem("accessToken");
  const paramsURL = isNaN(id);
  console.log("paramsURL", paramsURL);
  useEffect(() => {
    !paramsURL ? singleGroupDetails() : singleGroupDetailsbyURL();
  }, [id]);

  const singleGroupDetails = () => {
    dispatch(getGroupDetail(id, token));
  };
  const singleGroupDetailsbyURL = () => {
    dispatch(getGroupDetailByURL(id, token));
  };

  const EditGroupURL = (data) => {
    dispatch(addGroupURL(data)).then((res) => {
      toast.success(res.message, {
        position: toast.POSITION.BOTTOM_LEFT,
      });
    });
  };

  useEffect(() => {
    let token = window.localStorage.getItem("accessToken");
    if (group_detail[0]) {
      dispatch(
        getPostDetails(token, {
          shared_by: group_detail[0]?.id,
          shared_by_type: "group",
          limit: 10,
        })
      );
      setISPagePosts(true);
      getMembers();
    }
  }, [group_detail[0]]);

  const [openCreateModal, setOpenCreateModal] = useState(false);
  const [editGroupData, setEditGroupData] = useState("");
  const [createPostModal, setCreatePostModal] = useState(false);
  const [isDataAvailable, setDataAvailable] = useState(false);
  const [addMemberModal, setAddMemberModal] = useState(false);
  const [createPostClick, setCreatePostClick] = useState("");
  const [showMemberModal, setShowMemberModal] = useState(false);
  const [isGroupMember, setIsGroupMember] = useState();
  const [memberData, setMemberData] = useState();
  const [rolePageId, setRolePageId] = useState(false);
  const [isSticky, setIsSticky] = useState(false);

  function privacy_type(privacy_id) {
    if (privacy_id === 1) return " Public";
    if (privacy_id === 2) return " Connection";
    return " Group";
  }

  useEffect(() => {
    const handleScroll = () => {
      const sidebar = document.querySelector(".group-details-sidebar");
      const sidebarRect = sidebar.getBoundingClientRect();
      //   console.log(sidebarRect.top + sidebarRect.height < window.innerHeight);
      if (sidebarRect.top + sidebarRect.height < window.innerHeight) {
        setIsSticky(true);
        sidebar.style.top = `${sidebarRect.top}px`;
      } else setIsSticky(false);
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    dispatch(getPageDetails(token));
  }, []);

  const getTime = (date) => {
    let result = moment(date).fromNow();
    const now = moment();
    const days = now.diff(date, "days");
    const weeks = now.diff(date, "weeks");
    if (days >= 7) {
      if (days <= 13) {
        result = `a week ago`;
      } else if (days > 13 && days <= 25) {
        result = `${weeks} weeks ago`;
      }
    }
    return result;
  };

  const likeResult = (arr1, arr2) => {
    if (arr1 === undefined || arr1 === null) {
      let array3 = arr2;
      return array3;
    } else {
      let arr4 = [{ type: arr1 }];
      let array3 = arr4?.concat(
        arr2?.filter((bo) =>
          arr4?.every((ao) => ao?.type !== bo?.type && bo?.page_id !== null)
        )
      );
      return array3;
    }
  };

  const getMembers = () => {
    dispatch(getAllMemeber({ group_id: group_detail[0]?.id })).then((res) => {
      setMemberData(res.data);
    });
  };

  const memberExist = memberData?.some(
    (member) => user?.data?.id === member?.user_id
  );

  useEffect(() => {
    if (memberExist) {
      // Member exists
      setIsGroupMember(true);
    } else {
      // Member does not exist
      setIsGroupMember(false);
    }
  }, [memberData, memberExist]);

  const { getPageDetailData } = useSelector((state) => state.dashboard);
  console.log(getPageDetailData);
  const [selectedUserId, setSelectedUserId] = useState(true);
  const [isPagePosts, setISPagePosts] = useState(false);
  return (
    <GroupDetails
      group_detail={group_detail}
      openCreateModal={openCreateModal}
      setOpenCreateModal={setOpenCreateModal}
      editGroupData={editGroupData}
      setEditGroupData={setEditGroupData}
      user={user}
      postData={postData}
      createPostClick={createPostClick}
      setCreatePostClick={setCreatePostClick}
      getTime={getTime}
      getMembers={getMembers}
      privacy_type={privacy_type}
      likeResult={likeResult}
      memberData={memberData}
      createPostModal={createPostModal}
      setCreatePostModal={setCreatePostModal}
      isDataAvailable={isDataAvailable}
      setDataAvailable={setDataAvailable}
      groupId={id}
      navigate={navigate}
      addMemberModal={addMemberModal}
      setAddMemberModal={setAddMemberModal}
      showMemberModal={showMemberModal}
      setShowMemberModal={setShowMemberModal}
      isSticky={isSticky}
      EditGroupURL={EditGroupURL}
      singleGroupDetailsbyURL={singleGroupDetailsbyURL}
      isGroupMember={isGroupMember}
      rolePageId={rolePageId}
      setRolePageId={setRolePageId}
      getPageDetailData={getPageDetailData}
      setSelectedUserId={setSelectedUserId}
      isPagePosts={isPagePosts}
      roleShareModal={roleShareModal}
      setRoleShareModal={setRoleShareModal}
      selectedUserId={selectedUserId}
    />
  );
}
