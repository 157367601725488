import React, { useEffect, useState } from "react";
import moment from "moment/moment";
import { useDispatch } from "react-redux";
import {
  getExploreData,
  getExploreDetailsData,
} from "../../../store/explore/exploreActions";
import RelatedExplores from "../RelatedExplores/RelatedExplores.jsx";
import Header from "../../../core/components/layout/Header/Header";
import LoginFooter from "../../../core/components/layout/footer/LoginFooter";
import logo from "../../../assets/img/np-logo.svg";
import heart from "../../../assets/img/heart.svg";
import hand from "../../../assets/img/hand.svg";
import like from "../../../assets/img/black-like.svg";
import comment from "../../../assets/img/comment.svg";
import share from "../../../assets/img/share.svg";
import compu from "../../../assets/img/computer.png";
import thumb from "../../../assets/img/thumbnail.png";
import explore from "../../../assets/img/explore.png";
import "./ExploreDetails.scss";
import { useLocation, useNavigate } from "react-router";
import { getLandingPageData } from "../../../store/landing/landingAction";

export default function ExploreDetails() {
  const [exploreData, setExploreData] = useState();
  const [exploreDetailsData, setExploreDetailsData] = useState([]);
  const [relatedExploresData, setRelatedExploresData] = useState([]);
  const [authorExploreData, setAuthorExploreData] = useState([]);
  const [tabsList, setTabsList] = useState([]);
  const ID = window.location.search.substr(4);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(getExploreData())
      .then((resp) => {
        setTabsList(resp.data);
      })
      .catch((err) => {
        console.log("error!!", err);
      });
    dispatch(getLandingPageData({ title: "explore" })).then((res) => {
      setExploreData(res);
    });
  }, []);

  useEffect(() => {
    dispatch(getExploreDetailsData(ID))
      .then((resp) => {
        console.log(resp);
        setExploreDetailsData(resp.data);
        setRelatedExploresData(resp.relatedExplores);
        setAuthorExploreData(resp.authorExplores);
      })
      .catch((err) => {
        console.log("error!!", err);
      });
  }, []);
  const filteredData = authorExploreData?.filter((item, idx) => {
    return idx < 3;
  });

  const filterRelatedData = relatedExploresData?.filter((item, idx) => {
    return idx < 2;
  });

  const { title, image_url, description, createdAt } = exploreDetailsData;

  useEffect(()=>{
   console.log("Explore Deatils Data..",exploreDetailsData); 
  },[exploreDetailsData])

  return (
    <div>
      <Header />
      <div className="blog">
        <div className="bg">
          <div className="container">
            <div className="row">
              <div className="col-sm-8 margin-top">
                <div className="top-center">
                  <h1>{exploreData?.title}</h1>
                  <p style={{ marginTop: "30px" }}>{exploreData?.subtitle}</p>
                </div>
              </div>
              <div className="col-sm-4">
                <img src={explore} alt="" className="bg-image" />
              </div>
            </div>
          </div>
          <div className="n-logo">
            <img src={logo} alt="" width="100%" />
          </div>
        </div>
        <div className="explore-details blog-details mt-55">
          <div className="container">
            <div className="row">
              <div className="col-lg-8">
                <div className="me-sm-3">
                  <h2 className="mb-4">{title}</h2>
                  <div className="img">
                    <img src={image_url} className="img-fluid" alt="" />
                    <div className="d-flex">
                      <div className="publish">
                        <span>
                          Published on {moment(createdAt).format("MMM Do YYYY")}
                        </span>
                      </div>
                      <div className="follow">
                        <span onClick={() => navigate("/")}>+ Follow</span>
                      </div>
                    </div>
                    <div className="line"></div>
                  </div>
                  <div className="content ">
                    <p>{description}</p>
                    {/* commented code */}

                    {/* <div className="comments">
                    <i className="heart">
                      <img src={heart} alt="" />
                    </i>
                    <i className="mx-1">
                      <img src={hand} alt="" />
                    </i>
                    <span>John wick and 846 others . 3 Comments</span>
                  </div> */}
                    <div className="line mt-4"></div>
                    {/* <div className="likes">
                    <div className="">
                      <span><img src={like} alt=""/> Like</span>
                    </div>
                    <div className="comment">
                      <span><img src={comment} alt=""/> Comment</span>
                    </div>
                    <div className="share">
                      <span><img src={share} alt=""/> Share</span>
                    </div>
                  </div> */}
                    {/* <div className="">
                    <div className="pro">
                      <div className="d-flex">
                        <div className="profile-img">
                          <img
                            src={compu}
                            alt=""
                            className="img-fluid2 rounded"
                          />
                        </div>
                        <div className="name">
                          <h6>Anielle Guzman</h6>
                        </div>
                      </div>
                      <div className="month">
                        <span>5 mo</span>
                      </div>
                    </div>
                    <div className="content">
                      <p>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                        Etiam eu turpis molestie, dictum est a, mattis tellus.
                        Sed dignissim, metus nec fringilla accumsan, risus sem
                        sollicitudin lacus, ut interdum tellus elit sed risus.
                        Maecenas eget condimentum velit, sit amet feugiat lectus
                      </p>
                      <div className="like">
                        <span>Like</span>
                        <span>Reply</span>
                      </div>
                    </div>
                  </div> */}
                  </div>
                  <div className="article">
                    <h5 className="mb-4">More articles by this author</h5>
                    <div className="row">
                      {/* {relatedExploresData[0]?.explores?.map((item) => (
                        <RelatedExplores relatedExploresData={item} />
                      ))} */}
                      {filteredData?.map((item, index) => (
                        <RelatedExplores
                          relatedExploresData={item}
                          index={index}
                        />
                      ))}
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 other-viewed">
                <h5>Others also viewed</h5>
                {filterRelatedData[0]?.explores?.map((item) => (
                  <div className="box-1">
                    <div className="img" style={{ height: "80px" }}>
                      <img
                        src={item.thumbnail_url}
                        className="img-fluid"
                        alt=""
                        style={{ height: "80px" }}
                      />
                    </div>
                    <h6
                      className="card-control-text-twoline"
                      onClick={() => {
                        navigate(`/exploredetails?id=${item.id}`);
                        navigate(0);
                      }}
                    >
                      {item.title}
                    </h6>
                    <p className="card-control-text-twoline">
                      {item.description}
                    </p>
                  </div>
                ))}
                {/* <div className="box-1">
                  <div className="img">
                    <img src={thumb} className="img-fluid" alt="" />
                  </div>
                  <h6>What Are Legitimate Streams of Passive Income?</h6>
                  <p>Lorem ipsum dolor sit amet, consectetur adipiscing . 3W</p>
                </div> */}
                <div className="explore">
                  <h6>Explore topics</h6>
                  <ul className="mt-2">
                    {tabsList.map((item, i) => (
                      <li
                        key={i}
                        onClick={() => {
                          navigate(`/explore?category_id=${item.category_id}`);
                        }}
                      >
                        <span>{item.name}</span>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <LoginFooter />
    </div>
  );
}
