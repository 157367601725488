import React, { useEffect } from "react";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import { useDispatch, useSelector } from "react-redux";
import { getPageDetails } from "../../../store/dashboard/dashboardActions";
import placeholderUser from "../../../assets/img/placeholder_user.jpg";
import pagePlaceholder from "../../../assets/img/page-placeholder.svg";
const PublishAsModal = (props) => {
  const {
    modal,
    toggle,
    title,
    publishUserId,
    setPublishUserId,
    setPageName,
    updateData,
    getPageDetailData,
    atriclePageName,
    createdAt,
    setSharedByType,
    sharedByType,
    setProfileImg,
    pageID,
  } = props;

  const dispatch = useDispatch();
  // const { getPageDetailData } = useSelector((state) => state.dashboard);
  const { user } = useSelector((state) => state.auth);

  useEffect(() => {
    if (updateData?.shared_by_type === "page") {
      setPublishUserId(updateData?.shared_by);
    } else if (updateData?.shared_by_type === "user") {
      setPublishUserId(user?.data?.id);
    }

    if (sharedByType === "user") {
      setPublishUserId(user?.data?.id);
    }
  }, []);

  useEffect(() => {
    if (createdAt === "page") {
      setPageName(atriclePageName);
      setPublishUserId(pageID);
      setSharedByType("page");
    }
  }, []);

  // useEffect(() => {
  //   setTimeout(function () {
  //     let scrollDiv = document.getElementById(publishUserId)?.offsetTop;
  //     scrollDiv?.scrollIntoView({
  //       behavior: "smooth",
  //       block: "nearest",
  //       inline: "start",
  //     });
  //   }, 2000);
  // }, [publishUserId]);

  useEffect(() => {
    setTimeout(function () {
      let scrollDiv = document.getElementById(publishUserId);
      if (scrollDiv) {
        scrollDiv.scrollIntoView({
          behavior: "smooth",
          block: "nearest",
          inline: "start",
        });
      }
    }, 2000);
  }, [publishUserId]);

  const updatePublish = (event, i, type) => {
    if (event.target.checked) {
      if (type === "user") {
        setPublishUserId(i.id);
        setSharedByType("user");
        setPageName(`${i.first_name} ${" "} ${i.last_name}`);
        setProfileImg(i.profile_img);
        setTimeout(() => toggle(), 500);
      } else if (type === "page") {
        setPublishUserId(i.id);
        setSharedByType("page");
        setPageName(i.name);
        setProfileImg(i.icon);
        setTimeout(() => toggle(), 500);
      }
    }
  };

  return (
    <div>
      <Modal
        isOpen={modal}
        className="create-post-header"
        size="lg"
        style={{ maxWidth: "600px" }}
      >
        <ModalHeader style={{ color: "#000" }} toggle={toggle}>
          {title}
        </ModalHeader>
        <ModalBody className="publishModalClass mb-20">
          <div className="row create-post">
            {/* <h5>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</h5> */}
            {getPageDetailData?.data?.Users?.map((i, ind) => {
              return (
                <>
                  <p>
                    Select whether you're publishing as yourself or as a page
                    from the below:
                  </p>
                  <div
                    key={ind}
                    className="details d-flex justify-content-between align-items-center mb-20 mt-20"
                    id={i.id}
                  >
                    <div className="d-flex gap-4" style={{ width: "90%" }}>
                      <div className="">
                        <img
                          src={
                            i?.profile_img ? i?.profile_img : placeholderUser
                          }
                          className="round-image"
                          alt="profile_icon"
                        />
                      </div>
                      <div className="names">
                        <h6>
                          {i.first_name} {i.last_name}
                        </h6>
                        <p>{i?.profile?.profile_headline} </p>
                      </div>
                    </div>

                    <input
                      type="radio"
                      name="user"
                      id={i.id}
                      onClick={(e) => {
                        updatePublish(e, i, "user");
                      }}
                      checked={i.id === publishUserId ? true : false}
                    />
                  </div>
                </>
              );
            })}

            {getPageDetailData?.data?.Pages?.map((item, index) => {
              return (
                <div
                  key={index}
                  className="details d-flex justify-content-between align-items-center mb-20"
                  id={item.id}
                >
                  <div
                    className="d-flex gap-4 align-items-center "
                    style={{ width: "90%" }}
                  >
                    <div className="">
                      <img
                        src={item.icon ? item.icon : pagePlaceholder}
                        className="round-image"
                        alt="page_icon"
                      />
                    </div>
                    <div className="names">
                      <h6>{item.name}</h6>
                      <p>{item.industry}</p>
                    </div>
                  </div>

                  <input
                    type="radio"
                    name="user"
                    id={item.id}
                    checked={item.id === publishUserId ? true : false}
                    onClick={(e) => updatePublish(e, item, "page")}
                  />
                </div>
              );
            })}
          </div>
        </ModalBody>
      </Modal>
    </div>
  );
};

export default PublishAsModal;
