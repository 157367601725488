import React from "react";
import { FormGroup, Input } from "reactstrap";

const NotifyToggle = (props) => {
  const { expData, setExpData, isInBackgroundProcess } = props;
  return (
    <div className="box" style={{ marginBottom: "10px" }}>
      <h6>Notify network</h6>
      <FormGroup check>
        <div className="form-check form-switch">
          <Input
            type="checkbox"
            role="switch"
            checked={expData?.notify_network}
            onChange={(e) => {
              setExpData({ ...expData, notify_network: e.target.checked });
            }}
            disabled={isInBackgroundProcess}
          />
        </div>
      </FormGroup>
    </div>
  );
};

export default NotifyToggle;
