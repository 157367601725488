import { Modal, ModalHeader, ModalBody } from "reactstrap";
import React from "react";
import "../events.scss";
import { isDate } from "moment";

const DiscardEventModal = (props) => {
  const { modal, toggle, title, saveDraft, close, isDash } = props;

  // const closeButton = () => {
  //   toggle();
  // }

  return (
    <Modal
      size="md"
      isOpen={modal}
      style={{ maxWidth: "600px" }}
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="gradient-background"
    >
      <ModalHeader className="dicardModalHeader" toggle={toggle}>
        {title}
      </ModalHeader>
      <ModalBody>
        <div className="event-create-popup">
          <p>Are you sure you want to discard the changes you have made?</p>
          <div className="text-center mt-30">
            <button className="btn btn-light text-blue" onClick={close}>
              Close
            </button>
            <button className="btn btn-blue ml-2" onClick={saveDraft}>
              Save as draft
            </button>
          </div>
        </div>
      </ModalBody>
    </Modal>
  );
};

export default DiscardEventModal;
