import React, { useEffect, useState } from "react";
import "./Recruiter.scss";
import Navbar from "../../../pages/navbar/navbar";
import backArrow from "../../../assets/img/backArrow.svg";
import { Button } from "reactstrap";
import { Link, useLocation, useNavigate } from "react-router-dom";
import DeleteModal from "../../../core/components/modal/DeleteModal";
import { useDispatch } from "react-redux";
import {
  getQuestionaryList,
  rejectSmartInterview,
  sendSmartInterview,
} from "../../../store/jobs/jobAction";

const SmartInterview = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { state } = useLocation();

  const [confirmationModal, setConfirmationModal] = useState(false);
  const [questionaires, setQuestionaires] = useState(false);

  useEffect(() => {
    dispatch(
      getQuestionaryList({
        type: "questions",
        set: state?.interview_set,
        user_id: state?.interview_by,
      })
    ).then((res) => {
      setQuestionaires(res.map((obj) => obj?.question));
    });
  }, []);

  const handleNotinterested = () => {
    dispatch(rejectSmartInterview(state?.id)).then((res) => {
      if (res.success) {
        navigate("/myjobs", {
          state: { subTab: "Smart Interview Sent" },
        });
      }
    });
  };

  return (
    <>
      <Navbar isJobs={false} />

      <div className="smart-interview-page">
        <div className="container">
          <span
            className="back-btn"
            onClick={() =>
              navigate("/myjobs", { state: { subTab: "Smart Interview Sent" } })
            }
          >
            <img src={backArrow} alt="back_arrow" /> Smart Interview
          </span>
        </div>
        <section className="section-smart-interview">
          <div className="container">
            <div className="smart-interview-outer">
              <h3>Operational Interview</h3>
              {/* <h3 style={{ textTransform: "capitalize" }}>
                {state?.interview_set} Interview
              </h3> */}
              <p>
                The job poster is requesting a smart interview. By clicking
                'Start,' you agree to undergo a smart interview. Please ensure
                you are in a quiet and bright environment and that your device
                microphone and camera are working properly. You will be asked a
                set number of questions related to the job position and must
                answer within a short time frame while recording yourself. The
                scout or job poster will not be able to save this recording, and
                he or she will have only 14 days to recall your recorded
                interview.
              </p>
              <ul>
                <li>{questionaires?.length} Questions</li>
                <li>1.5 minutes per question</li>
                <li>Score in the top 30%</li>
              </ul>
              <div className="btn-outer d-sm-flex justify-content-between mt-4">
                <Button
                  className="blue-clr-btn"
                  onClick={() => handleNotinterested()}
                >
                  Not Interested
                </Button>
                {questionaires?.length ? (
                  <div>
                    <Button
                      className="gray-blue-btn mt-3 mt-sm-0"
                      onClick={() =>
                        navigate("/myjobs", {
                          state: { subTab: "Smart Interview Sent" },
                        })
                      }
                    >
                      Postpone
                    </Button>
                    <Button
                      className="btn-blue mt-3 mt-sm-0"
                      onClick={() => setConfirmationModal(!confirmationModal)}
                    >
                      Start
                    </Button>
                  </div>
                ) : (
                  <div>
                    <strong>
                      Interview Set is not available, please contact the
                      creator.{" "}
                      <Link to={`/profile/${state?.interview_by}`}>
                        <span>Click here</span>
                      </Link>{" "}
                    </strong>
                  </div>
                )}
              </div>
            </div>
          </div>
        </section>
      </div>

      {confirmationModal && (
        <DeleteModal
          modal={confirmationModal}
          toggle={() => setConfirmationModal(!confirmationModal)}
          title="Confirmation"
          deleteConfirm={() => {
            navigate("/recuriter/give-interview", {
              state: { questionaires, shortlist_id: state?.id },
            });
          }}
          text="Please ensure you are in a quiet and bright environment and that your device microphone and camera are working properly."
          isSmartInterview={true}
        />
      )}
    </>
  );
};

export default SmartInterview;
