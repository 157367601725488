import React from "react";
import { useNavigate } from "react-router-dom";
import { Modal, ModalBody, ModalHeader } from "reactstrap";

const AwayMessageCheckModal = ({
  modal,
  toggle,
  title,
  // setAwayMessageModal,
}) => {
  const navigate = useNavigate();
  return (
    <>
      <Modal
        size="xl"
        isOpen={modal}
        className={"modal-size-sm popup-message-modal gradient-background"}
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        {
          <ModalHeader className="p-3" toggle={toggle}>
            <h3
              className="d-flex gap-3 align-items-center"
              style={{ color: "rgb(255, 255, 255)", fontSize: "15px" }}
            >
              {title}
            </h3>
          </ModalHeader>
        }
        <ModalBody>
          <div className="set-away-messages-popup mt-2">
            <div className="set-away-messages-popup-outer">
              <div className="heading mb-10">
                <h5>Set away messages</h5>
              </div>
              <div className="row">
                <div className="col-lg-12">
                  <p>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                    Nunc vulputate libero et velit interdum, ac aliquet odio
                    mattis.
                  </p>
                </div>
                <div className="buttons mt-5">
                  <button
                    className="btn btn-light text-blue"
                    onClick={() => {
                      // setAwayMessageModal(true);
                      toggle();
                    }}
                  >
                    Back
                  </button>
                  <button
                    className="btn btn-blue"
                    onClick={() => navigate("/membership")}
                  >
                    Discover Our Memberships
                  </button>
                </div>
              </div>
            </div>
          </div>
        </ModalBody>
      </Modal>
    </>
  );
};
export default AwayMessageCheckModal;
