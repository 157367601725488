import React, { useEffect, useState, useRef } from "react";
import {
  getEventDetail,
  attendEvents,
  cancelEvents,
  pinnedRelatedEvents,
} from "../../../../store/events/eventActions";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import EventDetailsWebView from "./EventDetailsWebView";
import EventDetails from "./EventDetails";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

export default function EventDetailsContainer() {
  const dispatch = useDispatch();
  const { id } = useParams();
  const token = localStorage.getItem("accessToken");
  const navigate = useNavigate();
  const dropDownRef = useRef(null);

  const { isWebView } = useSelector((state) => state.auth);
  const { user } = useSelector((state) => state.auth);

  useEffect(() => {
    dispatch(getEventDetail(id, token));
  }, [id]);

  const attendHandle = (id, type) => {
    dispatch(attendEvents(id, token, type));
  };

  const cancelledEvent = (id) => {
    dispatch(cancelEvents(id, token));
  };

  const pinHandleRelatedEvent = (eid, id, type) => {
    dispatch(pinnedRelatedEvents(eid, id, token, type));
  };

  const { event_detail } = useSelector((state) => state.events);
  const [openCreateModal, setOpenCreateModal] = useState(false);
  const [editEventData, setEditEventData] = useState("");
  const [openShareModal, setOpenShareModal] = useState(false);
  const [shareData, setShareData] = useState("");
  const [cancelModal, setCancelModal] = useState(false);
  const [shareDropdown, setShareDropdown] = useState(false);

  const clickOutsideDropdown = (e) => {
    if (dropDownRef?.current?.contains(e.target)) {
      return;
    }
    setShareDropdown(false);
  };

  useEffect(() => {
    document.addEventListener("mousedown", clickOutsideDropdown);
    return () => {
      document.removeEventListener("mousedown", clickOutsideDropdown);
    };
  }, [shareDropdown]);

  const copyToClipboard = (text) => {
    navigator.clipboard
      .writeText(text)
      .then(() => {
        toast.success(`Copied text to clipboard: ${text}`, {
          position: toast.POSITION.BOTTOM_LEFT,
        });
      })
      .catch((error) => {
        console.error(`Could not copy text: ${error}`);
      });
  };

  const webViewRedirectNetworksection = (data) => {
    console.log(
      `webviewHandler-redirectNetworkSection-${JSON.stringify(data)}`
    );
  };

  const webViewHandlerCopyLink = (val) => {
    console.log(`webviewHandler-copiedLink-${JSON.stringify(val)}`)
  }

  const webViewEditEventDataHandler = (data) => {
    console.log(`webViewDataHandler-editEvent-${JSON.stringify(data)}`);
  };

  const webViewShareEventDataHandler = (data) => {
    console.log(`webViewDataHandler-shareEvent-${JSON.stringify(data)}`);
  };


  if (isWebView) {
    return (
      <EventDetailsWebView
        event_detail={event_detail}
        openCreateModal={openCreateModal}
        setOpenCreateModal={setOpenCreateModal}
        editEventData={editEventData}
        setEditEventData={setEditEventData}
        webViewEditEventDataHandler={webViewEditEventDataHandler}
        id={id}
        attendHandle={attendHandle}
        user={user}
        webViewShareEventDataHandler={webViewShareEventDataHandler}
        pinHandleRelatedEvent={pinHandleRelatedEvent}
        webViewRedirectNetworksection={webViewRedirectNetworksection}
        webViewHandlerCopyLink={webViewHandlerCopyLink}
      />
    );
  }

  return (
    <EventDetails
      event_detail={event_detail}
      openCreateModal={openCreateModal}
      setOpenCreateModal={setOpenCreateModal}
      editEventData={editEventData}
      setEditEventData={setEditEventData}
      user={user}
      attendHandle={attendHandle}
      openShareModal={openShareModal}
      setOpenShareModal={setOpenShareModal}
      shareData={shareData}
      setShareData={setShareData}
      cancelledEvent={cancelledEvent}
      pinHandleRelatedEvent={pinHandleRelatedEvent}
      navigate={navigate}
      copyToClipboard={copyToClipboard}
      cancelModal={cancelModal}
      setCancelModal={setCancelModal}
      dropDownRef={dropDownRef}
      shareDropdown={shareDropdown}
      setShareDropdown={setShareDropdown}
    />
  );
}
