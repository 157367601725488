import {
  FETCH_RESUME_PDF_REQUEST,
  FETCH_RESUME_PDF_SUCCESS,
  FETCH_RESUME_PDF_FAILURE,
  FETCH_ALL_RESUME_TYPES_PDF_REQUEST,
  FETCH_ALL_RESUME_TYPES_PDF_SUCCESS,
  FETCH_ALL_RESUME_TYPES_PDF_FAILURE,
  ADD_WORK_EXPERIENCE_IN_CV,
  EDIT_WORK_EXPERIENCE_IN_CV,
  ADD_EDUCATION_IN_CV,
  EDIT_HONORS_IN_CV,
  ADD_HONORS_IN_CV,
  EDIT_EDUCATION_IN_CV,
  ADD_LANGUAGE_IN_CV,
  EDIT_LANGUAGE_IN_CV,
  ADD_SKILLS_IN_CV,
  EDIT_SKILLS_IN_CV,
  UPDATE_BASIC_INFO_IN_CV,
  DELETE_LANGUAGE_IN_CV,
  DELETE_HONORS_IN_CV,
  DELETE_EDUCATION_IN_CV,
  DELETE_WORK_EXPERIENCE_IN_CV,
  FILL_INITIAL_DATA_IN_CV,
  FETCH_ALL_RESUME_PDFS,
} from "../types";

export const initialState = {
  cvTypes: {
    classic: null,
    functional: null,
    combined: null,
    modern: null,
  },
  templates: [],
  template: {
    classic: {
      first_name: "",
      last_name: "",
      profile_img: "",
      designation: "",
      email: "",
      phone: "",
      profile_url: "",
      address: "",
      about: "",
      linkedin_url: "",
      altranate_phone: "",
      altranate_email: "",
      experiences: [],
      educations: [],
      exps_and_skills: [],
      languages: [],
      honors_and_awards: [],
      cv_skillColor: "",
      cv_color: "",
    },
    functional: {
      first_name: "",
      last_name: "",
      profile_img: "",
      email: "",
      phone: "",
      profile_url: "",
      address: "",
      designation: "",

      about: "",
      linkedin_url: "",
      altranate_phone: "",
      altranate_email: "",
      experiences: [],
      educations: [],
      exps_and_skills: [],
      languages: [],
      honors_and_awards: [],
      cv_skillColor: "",
      cv_color: "",
    },
    combined: {
      first_name: "",
      last_name: "",
      profile_img: "",
      email: "",
      phone: "",
      profile_url: "",
      address: "",
      about: "",
      linkedin_url: "",
      designation: "",

      altranate_phone: "",
      altranate_email: "",
      experiences: [],
      educations: [],
      exps_and_skills: [],
      languages: [],
      honors_and_awards: [],
      cv_skillColor: "",
      cv_color: "",
    },
    modern: {
      first_name: "",
      last_name: "",
      profile_img: "",
      email: "",
      phone: "",
      profile_url: "",
      address: "",
      about: "",
      linkedin_url: "",
      altranate_phone: "",
      designation: "",

      altranate_email: "",
      experiences: [],
      educations: [],
      exps_and_skills: [],
      languages: [],
      honors_and_awards: [],
      cv_skillColor: "",
      cv_color: "",
    },
  },

  isLoading: false,
  error: null,
};

const cv = (state = initialState, action) => {
  switch (action.type) {
    case FILL_INITIAL_DATA_IN_CV:
      return {
        ...state,
        template: {
          classic: {
            ...state?.template?.classic,
            ...action.payload?.profileData,
          },
          functional: {
            ...state?.template?.functional,
            ...action.payload?.profileData,
          },
          modern: {
            ...state?.template?.modern,
            ...action.payload?.profileData,
          },
          combined: {
            ...state?.template?.combined,
            ...action.payload?.profileData,
          },
        },
      };
    case FETCH_RESUME_PDF_REQUEST:
      return { ...state, isLoading: true, error: null };

    case FETCH_RESUME_PDF_SUCCESS:
      const { type, url } = action.payload;
      return {
        ...state,
        cvTypes: { ...state.cvTypes, [type]: url },
        isLoading: false,
        error: null,
      };

    case FETCH_RESUME_PDF_FAILURE:
      return { ...state, isLoading: false, error: action.payload };

    case FETCH_ALL_RESUME_TYPES_PDF_REQUEST:
      return { ...state, isLoading: true, error: null };

    case FETCH_ALL_RESUME_TYPES_PDF_SUCCESS:
      const resumePdf = action?.payload?.reduce((acc, cv) => {
        const { type, url } = cv;
        acc[type] = url;
        return acc;
      }, {});
      return {
        ...state,
        cvTypes: resumePdf,
        templates: action?.payload,
        isLoading: false,
        error: null,
      };

    case UPDATE_BASIC_INFO_IN_CV:
      const { type: cvType, data } = action.payload;
      return {
        ...state,
        template: {
          ...state.template,
          [cvType]: {
            ...state.template[cvType],
            ...data,
          },
        },
      };

    case ADD_WORK_EXPERIENCE_IN_CV:
      const { type: cvTypeAddExperience, experience } = action.payload;
      return {
        ...state,
        template: {
          ...state.template,
          [cvTypeAddExperience]: {
            ...state.template[cvTypeAddExperience],
            experiences: [
              ...state.template[cvTypeAddExperience].experiences,
              experience,
            ],
          },
        },
      };

    case EDIT_WORK_EXPERIENCE_IN_CV:
      const {
        type: cvTypeEditExperience,
        experienceId,
        newExperience,
      } = action.payload;
      return {
        ...state,
        template: {
          ...state.template,
          [cvTypeEditExperience]: {
            ...state.template[cvTypeEditExperience],
            experiences: state.template[cvTypeEditExperience].experiences.map(
              (exp, i) => (i === experienceId ? newExperience : exp)
            ),
          },
        },
      };

    case DELETE_WORK_EXPERIENCE_IN_CV:
      const { type: cvTypeDeleteExperience, experienceIdToDelete } =
        action.payload;
      return {
        ...state,
        template: {
          ...state.template,
          [cvTypeDeleteExperience]: {
            ...state.template[cvTypeDeleteExperience],
            experiences: state.template[
              cvTypeDeleteExperience
            ].experiences.filter((_, i) => i !== experienceIdToDelete),
          },
        },
      };

    case ADD_EDUCATION_IN_CV:
      const { type: cvTypeAddEducation, education } = action.payload;
      return {
        ...state,
        template: {
          ...state.template,
          [cvTypeAddEducation]: {
            ...state.template[cvTypeAddEducation],
            educations: [
              ...state.template[cvTypeAddEducation].educations,
              education,
            ],
          },
        },
      };

    case EDIT_EDUCATION_IN_CV:
      const {
        type: cvTypeEditEducation,
        educationId,
        newEducation,
      } = action.payload;
      return {
        ...state,
        template: {
          ...state.template,
          [cvTypeEditEducation]: {
            ...state.template[cvTypeEditEducation],
            educations: state.template[cvTypeEditEducation].educations.map(
              (edu, i) => (i === educationId ? newEducation : edu)
            ),
          },
        },
      };

    case DELETE_EDUCATION_IN_CV:
      const { type: cvTypeDeleteEducation, educationIdToDelete } =
        action.payload;
      return {
        ...state,
        template: {
          ...state.template,
          [cvTypeDeleteEducation]: {
            ...state.template[cvTypeDeleteEducation],
            educations: state.template[cvTypeDeleteEducation].educations.filter(
              (_, i) => i !== educationIdToDelete
            ),
          },
        },
      };
    case ADD_HONORS_IN_CV:
      const { type: cvTypeAddHonor, honor } = action.payload;
      return {
        ...state,
        template: {
          ...state.template,
          [cvTypeAddHonor]: {
            ...state.template[cvTypeAddHonor],
            honors_and_awards: [
              ...state.template[cvTypeAddHonor].honors_and_awards,
              honor,
            ],
          },
        },
      };

    case EDIT_HONORS_IN_CV:
      const { type: cvTypeEditHonor, honorId, newHonor } = action.payload;
      return {
        ...state,
        template: {
          ...state.template,
          [cvTypeEditHonor]: {
            ...state.template[cvTypeEditHonor],
            honors_and_awards: state.template[
              cvTypeEditHonor
            ].honors_and_awards.map((hon, i) =>
              i === honorId ? newHonor : hon
            ),
          },
        },
      };

    case DELETE_HONORS_IN_CV:
      const { type: cvTypeDeleteHonor, honorIdToDelete } = action.payload;
      return {
        ...state,
        template: {
          ...state.template,
          [cvTypeDeleteHonor]: {
            ...state.template[cvTypeDeleteHonor],
            honors_and_awards: state.template[
              cvTypeDeleteHonor
            ].honors_and_awards.filter((_, i) => i !== honorIdToDelete),
          },
        },
      };

    case ADD_LANGUAGE_IN_CV:
      const { type: cvTypeAddLanguage, language } = action.payload;
      return {
        ...state,
        template: {
          ...state.template,
          [cvTypeAddLanguage]: {
            ...state.template[cvTypeAddLanguage],
            languages: [
              ...state.template[cvTypeAddLanguage].languages,
              language,
            ],
          },
        },
      };

    case EDIT_LANGUAGE_IN_CV:
      const {
        type: cvTypeEditLanguage,
        languageId,
        newLanguage,
      } = action.payload;
      return {
        ...state,
        template: {
          ...state.template,
          [cvTypeEditLanguage]: {
            ...state.template[cvTypeEditLanguage],
            languages: state.template[cvTypeEditLanguage].languages.map(
              (lang, i) => (i === languageId ? newLanguage : lang)
            ),
          },
        },
      };
    case DELETE_LANGUAGE_IN_CV:
      const { type: cvTypeDeleteLanguage, languageIdToDelete } = action.payload;
      return {
        ...state,
        template: {
          ...state.template,
          [cvTypeDeleteLanguage]: {
            ...state.template[cvTypeDeleteLanguage],
            languages: state.template[cvTypeDeleteLanguage].languages.filter(
              (_, i) => i !== languageIdToDelete
            ),
          },
        },
      };
    case ADD_SKILLS_IN_CV:
      const { type: cvTypeAddSkill, skill } = action.payload;
      return {
        ...state,
        template: {
          ...state.template,
          [cvTypeAddSkill]: {
            ...state.template[cvTypeAddSkill],
            exps_and_skills: [
              ...state.template[cvTypeAddSkill].exps_and_skills,
              skill,
            ],
          },
        },
      };

    case EDIT_SKILLS_IN_CV:
      const { type: cvTypeDeleteSkill, skillIdToDelete } = action.payload;
      return {
        ...state,
        template: {
          ...state.template,
          [cvTypeDeleteSkill]: {
            ...state.template[cvTypeDeleteSkill],
            exps_and_skills: state.template[
              cvTypeDeleteSkill
            ].exps_and_skills.filter((_, i) => i !== skillIdToDelete),
          },
        },
      };

    default:
      return state;
  }
};

export default cv;
