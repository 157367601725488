import React, { useState, useEffect } from 'react';
import { EditorState, convertToRaw, convertFromRaw, convertFromHTML, ContentState } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import bold from '../../../../assets/img/bold.svg';
import italic from '../../../../assets/img/italic.svg';
import underline from '../../../../assets/img/underline.svg';
import leftAlignIcon from '../../../../assets/img/left-align.svg';
import centerIcon from '../../../../assets/img/center.svg';
import OrderedListIcon from '../../../../assets/img/ordered-list.svg';
import draftToHtml from 'draftjs-to-html';


import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import "../newsletter.scss"
import { useDispatch, useSelector } from 'react-redux';
import axios from 'axios';
import { EDITOR_IMAGE_UPDATE_URL_NEWSLETTER } from '../../../../store/types';

function EditorBox(props) {
  const EditorImageUrls = useSelector((state) => state?.newsletter?.editorImgUrlsNews)
  const dispatch = useDispatch();
  const { convertedContent,
    setConvertedContent,
    setDbFormat,
    setHasText,
    setOnlySpaces,
    dbFormat } = props

  const [className, setClassName] = useState("RichEditor-editor");

  const [editorState, setEditorState] = useState(dbFormat ? () => {
    const blocksFromHTML = convertFromHTML(dbFormat)
    const contentState = ContentState.createFromBlockArray(
      blocksFromHTML.contentBlocks,
      blocksFromHTML.entityMap
    )

    return EditorState.createWithContent(contentState)
  } : () =>
    EditorState.createEmpty()
  )

  useEffect(() => {
    var contentState = editorState.getCurrentContent();

    let isEditorEmpty = !contentState.hasText();
    let currentPlainText = contentState.getPlainText()
    let lengthOfTrimmedContent = currentPlainText.trim().length;
    let isContainOnlySpaces = !isEditorEmpty && !lengthOfTrimmedContent;

    setHasText(contentState.hasText());
    setOnlySpaces(isContainOnlySpaces);

    if (!contentState.hasText()) {
      if (contentState.getBlockMap().first().getType() !== "unstyled") {
        setClassName("RichEditor--hide-placeholder");
      } else setClassName("RichEditor-editor");
    }
  }, [editorState]);

  // useEffect(() => {
  //   let html=draftToHtml(convertToRaw(editorState.getCurrentContent()));
  //   setConvertedContent(html);   //html format 

  //   const rawDraftContentState = JSON.stringify( convertToRaw(editorState.getCurrentContent()) );
  //   setDbFormat(html) //database format 

  // }, [editorState]);
  useEffect(() => {
    let html1 = draftToHtml(convertToRaw(editorState.getCurrentContent()));
    updateUrlsOfImageInHtml(html1).then((html) => {
      setConvertedContent(html); //html format
      console.log(html);
      const rawDraftContentState = JSON.stringify(
        convertToRaw(editorState.getCurrentContent())
      );
      setDbFormat(html); //database format
      console.log(convertToRaw(editorState.getCurrentContent()));

      let hashtags = convertToRaw(editorState.getCurrentContent())
        ?.blocks[0]?.text?.split(" ")
        ?.filter((v) => v.startsWith("#"));
      //setHashTagData(hashtags);
    })

  }, [editorState]);

  const updateUrlsOfImageInHtml = async (html) => {
    console.log(typeof (html))
    const length = html?.split("<img").length - 1;
    let lastindexChacked = 0;
    for (let index = 0; index < length; index++) {
      let elementStartIndex = html.indexOf("blob:");
      let elementEndIndex = html.indexOf("\" alt", elementStartIndex);
      //replace
      if (elementStartIndex != -1 && elementEndIndex != -1) {
        html = html.substring(0, elementStartIndex) + EditorImageUrls[index] + html.substring(elementEndIndex)
      }
      console.log(html, "converted html")
      // elementEndIndex = html.indexOf("\" alt",lastindexChacked);
      // lastindexChacked=elementEndIndex+1;
      console.log(elementStartIndex, elementEndIndex, "indexes of src")
    }
    // let data=""+html;
    return html;
    console.log(html, "processing html");
    // data.indexOf("src=")
  }

  const handleEditorPictureUpload = async (file) => {
    console.log(file);
    try {
      const UPLOAD_URL = `${process.env.REACT_APP_API_URL}api/v1/upload`;
      const formData = new FormData();
      const headers = {
        "Accept-Language": "en",
        "content-type": "multipart/form-data",
      };

      formData.append("image", file);

      await axios.post(UPLOAD_URL, formData, { headers }).then((res) => {
        // setBannerImage(res?.data?.urlsArray[0]);
        console.log(res?.data?.urlsArray[0], "after upload url data");
        dispatch({
          type: EDITOR_IMAGE_UPDATE_URL_NEWSLETTER,
          payload: [res?.data?.urlsArray[0]],
        })
      });
    } catch (err) {
      console.log(err);
    }
  };

  const uploadCallback = async (file) => {
    console.log(file, "sdfsdfsdf");
    await handleEditorPictureUpload(file);
    const a = URL.createObjectURL(file)
    console.log(a, "a");

    // const uploadCallback = (file) => {
    //   const a =URL.createObjectURL(file)
    return new Promise((resolve, reject) => {
      resolve({
        data: {
          link: a,
        },
      });
    });
  };


  return (
    <div className="App">
      <header className="App-header">
        {/* Rich Text Editor Example */}
      </header>
      <div className={className}>
        <Editor
          editorState={editorState}
          onEditorStateChange={setEditorState}
          handlePastedText={() => false}
          stripPastedStyles={true}
          //  wrapperClassName="wrapper-class"
          editorClassName="editor-class"
          toolbarClassName="toolbar-class"
          type="textbox"
          placeholder='Write Content here*...'
          toolbar={{
            options: ['inline', 'textAlign', 'list', 'link', 'image', 'emoji'],
            inline: {
              inDropdown: false,
              className: undefined,
              component: undefined,
              dropdownClassName: undefined,
              options: ['bold', 'italic', 'underline'],
              bold: { icon: bold, className: undefined },
              italic: { icon: italic, className: undefined },
              underline: { icon: underline, className: undefined },

            },
            textAlign: {
              inDropdown: false,
              className: undefined,
              component: undefined,
              dropdownClassName: undefined,
              options: ['left', 'center'],
              left: { icon: leftAlignIcon, className: undefined },
              center: { icon: centerIcon, className: undefined }
            },
            list: {
              inDropdown: false,
              className: undefined,
              component: undefined,
              dropdownClassName: undefined,
              options: ['ordered'],
              ordered: { icon: OrderedListIcon, className: undefined }
            },

            link: {
              inDropdown: false,
              className: undefined,
              component: undefined,
              popupClassName: undefined,
              dropdownClassName: undefined,
              showOpenOptionOnHover: true,
              defaultTargetOption: '_self',
              options: ['link'],

            },
            image: {
              urlEnabled: false,
              uploadEnabled: true,
              uploadCallback: uploadCallback,
              previewImage: true,
              alignmentEnabled: true,
              defaultSize: {
                height: "auto",
                width: "auto",
              },
              inputAccept: "image/gif,image/jpeg,image/jpg,image/png,image/svg",
            },

          }}
          mention={{}}

          hashtag={{}}
        />

      </div>
    </div>
  )
}

export default EditorBox;