import React, { useState, useEffect } from "react";
import {
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
  FormFeedback,
} from "reactstrap";
import { call } from "../../../../api/apiCall";
import { useDispatch, useSelector } from "react-redux";
import { updateContactDetails } from "../../../../store/profile/profileActions";
import moment from "moment";
import Dropdown from "../../../../core/components/DropDown/Dropdown";
import MobileInput from "../../../../core/components/Inputs/MobileInput";
import {
  PhoneNumberUtil,
  PhoneNumberFormat as PNF,
} from "google-libphonenumber";
import DatePickerCustom from "../../../../core/components/DatePicker/DatePickerReact";
import Autocomplete from "react-autocomplete";
import { getLocationsData } from "../../../../store/connections/connectionsActions";
import edit from "../../../../assets/img/edit.svg";
import postalCodes from "postal-codes-js";
import { toast } from "react-toastify";
import { WEB_URL } from "../../../../config";

const AddContactInfoModal = (props) => {
  const {
    modal,
    toggle,
    title,
    country,
    countryCodeValue,
    state,
    city,
    location,
    pincode,
    phone,
    dob,
    address,
    website,
    nationality,
    email,
    profileUrl,
    customProfileModal,
    setCustomProfileModal,
  } = props;
  const [countryList, setCountryList] = useState("");
  const [stateList, setStateList] = useState("");
  const [cityList, setCityList] = useState("");
  const [profileUrlError, setProfileUrlError] = useState("");
  // const [nationalityList, setNationalityList] = useState("");
  const { isWebView } = useSelector((state) => state.auth);
  const [showLabel, setShowLabel] = useState("");
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.auth);
  const [errorPhoneNumber, setErrorPhoneNumber] = useState("");
  const [phoneCount, setPhoneCount] = useState("");
  const phoneUtil = PhoneNumberUtil.getInstance();
  const [locationInput, setLocationInput] = useState(location ? location : "");
  const [locationList, setLocationList] = useState([]);
  const [countryCode, setCountryCode] = useState(
    countryCodeValue ? countryCodeValue : ""
  );
  const [countryInput, setCountryInput] = useState(
    nationality ? nationality.name : ""
  );
  const [errorPostCode, setErrorPostCode] = useState("");

  const [contactData, setContactData] = useState({
    phone: phone ? phone : "",
    dob: dob ? moment(dob).format("YYYY-MM-DD") : null,
    address: address ? address : "",
    nationality: nationality ? nationality : "",
    country: country ? country : null,
    state: state ? state : null,
    city: city ? city : null,
    pin_code: pincode ? pincode : "",
    location: location ? location : null,
    website: website ? website : "",
    //email:"Shubham.singh@mail.vinove.com",
  });

  useEffect(() => {
    if (phone && phone?.length > 0) {
      setContactData({
        phone: phone ? phone : "",
        dob: dob ? moment(dob).format("YYYY-MM-DD") : null,
        address: address ? address : "",
        nationality: nationality ? nationality : "",
        country: country ? country : null,
        state: state ? state : null,
        city: city ? city : null,
        pin_code: pincode ? pincode : "",
        location: location ? location : null,
        website: website ? website : "",
        //email:"Shubham.singh@mail.vinove.com",
        // profile_url:""
      });
    }
  }, [phone]);

  useEffect(() => {
    if (locationInput) {
      dispatch(getLocationsData(locationInput))
        .then((res) => {
          if (res?.data) {
            setLocationList(res?.data);
          } else setLocationList([]);
        })
        .catch(() => {
          setLocationList([]);
        });
    }
  }, [locationInput]);

  useEffect(() => {
    (async () => {
      try {
        const res = await call("get", "api/v1/country", null, null);
        setCountryList(res.data);
      } catch (err) {
        console.log(err);
      }
    })();
    setShowLabel(contactData?.nationality?.name);
  }, []);

  useEffect(() => {
    (async () => {
      try {
        const res = await call(
          "get",
          `api/v1/state?country_id=${contactData.country?.id}`,
          null,
          null
        );
        setStateList(res.data);
      } catch (err) {
        console.log(err);
      }
    })();
  }, [contactData.country]);

  useEffect(() => {
    if (contactData && contactData.state) {
      (async () => {
        try {
          const res = await call(
            "get",
            `api/v1/city?state_id=${contactData.state?.id}`,
            null,
            null
          );
          setCityList(res.data);
        } catch (err) {
          console.log(err);
        }
      })();
    }
  }, [contactData.state, contactData.country]);

  // useEffect(() => {
  //   (async () => {
  //     try {
  //       const res = await call("get", `api/v1/nationality`, null, null);
  //       setNationalityList(res?.data?.data);
  //     } catch (err) {
  //       console.log(err);
  //     }
  //   })();
  // }, []);

  const phoneExist = (phoneNumber) => {
    return new Promise((resolve, reject) => {
      (async () => {
        try {
          const res = await call(
            "get",
            `api/v1/auth/phone-exist/${phoneNumber}`,
            null,
            null,
            null
          );
          resolve(true);
        } catch (err) {
          resolve(false);
        }
      })();
    });
  };

  const checkSignUpValidation = (type) => {
    let formIsValid = true;

    if (
      (type === "all" && phone !== contactData?.phone) ||
      (type === "phoneNumber" && phone !== contactData?.phone)
    ) {
      if (contactData?.phone?.length === 0) {
        setErrorPhoneNumber("Please enter the phone number.");
        formIsValid = false;
      } else if (contactData?.phone?.length < 10) {
        setErrorPhoneNumber("Please enter the valid number.");
        formIsValid = false;
      } else if (!phoneCountValidation()) {
        setErrorPhoneNumber("Please enter the valid number.");
        formIsValid = false;
      } else if (contactData?.phone) {
        phoneExist(contactData?.phone).then((response) => {
          if (response === false) {
            setErrorPhoneNumber("Phone already exist.");
            formIsValid = false;
          } else {
            setErrorPhoneNumber("");
          }
        });
      } else {
        setErrorPhoneNumber("");
      }
    }

    if (type === "all") {
      if (postCodeValidator(contactData.pin_code, countryCode) === true) {
        setErrorPostCode("");
      } else {
        setErrorPostCode("Invalid Post Code.");
        formIsValid = false;
      }
    }

    return formIsValid;
  };

  const handleSubmit = () => {
    if (checkSignUpValidation("all")) {
      if (locationInput === "") {
      }

      var token = localStorage.getItem("accessToken");
      console.log("contactData", contactData);
      // return
      dispatch(updateContactDetails(contactData, token))
        .then(() => {
          toast.success("Profile updated successfully", {
            position: toast.POSITION.BOTTOM_LEFT,
          });
          toggle();
        })
        .catch((e) => {
          console.log(e);
        });
    }
  };

  //   const countryHandle = (e) => {
  //     let obj = JSON.parse(e.target.value);
  //     setContactData((prevState) => ({
  //       ...prevState,
  //       country: obj,
  //       state: null,
  //       city: null,
  //     }));
  //   };

  //   const stateHandle = (e) => {
  //     let obj = JSON.parse(e.target.value);
  //     setContactData((prevState) => ({
  //       ...prevState,
  //       state: obj,
  //       city: null,
  //     }));
  //   };

  //   const handleSelectedValueNationality = (value) => {
  //     setShowLabel(value.value);
  //     setContactData({
  //       ...contactData,
  //       nationality: {
  //         country_code: value.country_code,
  //         id: value.id,
  //         name: value.value,
  //       },
  //     });
  //   };

  //   const handleChange = (value) => {
  //     if (value?.key.length === 1) {
  //       setNationalityList(countryList?.data.filter((item) => (item.name.toLowerCase().startsWith(value?.key))));
  //     }
  //     else {
  //       setNationalityList(countryList?.data);
  //     }
  //   }
  // console.log(countryList)

  const phoneCountValidation = () => {
    let num = JSON.stringify(contactData.phone);
    let code = JSON.stringify(phoneCount);
    const number = phoneUtil.parse(num, code);
    return phoneUtil.isValidNumber(number);
  };

  const postCodeValidator = (pin_code, countryCode) => {
    return postalCodes.validate(countryCode, pin_code);
  };

  //Website Validation
  const isValidUrl = (url) => {
    // Regular expression for a URL validation
    const urlRegex =
      /^(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/;
    const urlWithoutProtocolRegex = /^(www\.)?[a-zA-Z0-9-]+(\.[a-zA-Z]{2,})+$/;
    return urlRegex.test(url);
  };

  const handleWebsiteChange = (e) => {
    const newWebsite = e.target.value;
    setContactData({
      ...contactData,
      website: newWebsite,
    });
  };

  const isWebsiteValid = isValidUrl(contactData.website);
  console.log(isWebsiteValid);

  return (
    <div style={{ borderRadius: "5px" }}>
      <Modal
        isOpen={modal}
        className="contact-info gradient-background"
        toggle={toggle}
        size="lg"
        style={{ maxWidth: "550px" }}
        centered
      >
        <ModalHeader toggle={toggle} id="account-modal">
          {title}
        </ModalHeader>
        <ModalBody className="modal-body" style={{ paddingBottom: "0px" }}>
          <form>
            <div className="title">
              <MobileInput
                label="Phone Number"
                onChange={(value, country, e, formattedValue) => {
                  setContactData({
                    ...contactData,
                    phone: `+${country.dialCode}-${value.slice(
                      country.dialCode.length
                    )}`,
                  });
                  setPhoneCount(country.countryCode);
                }}
                value={contactData.phone}
                error={errorPhoneNumber}
                placeholder="Number"
                inputType="page"
                containerClassName="pt-0"
              />
            </div>
            <div className="row">
              <div className="col-sm-12">
                <div className="title">
                  <label htmlFor="">Birthday</label>
                  {isWebView ? (
                    <Input
                      value={moment(contactData.dob).utc().format("YYYY-MM-DD")}
                      type="date"
                      onChange={(e) =>
                        setContactData({ ...contactData, dob: e.target.value })
                      }
                    />
                  ) : (
                    <DatePickerCustom
                      value={
                        contactData.dob
                          ? new Date(contactData.dob)
                          : moment().subtract(18, "years")._d
                      }
                      onChange={(date) => {
                        date
                          ? setContactData({
                              ...contactData,
                              dob: moment(date).format("YYYY-MM-DD"),
                            })
                          : setContactData({ ...contactData, dob: null });
                      }}
                      placeholder="Pick"
                      max={moment().subtract(18, "years")._d}
                    />
                  )}
                </div>
              </div>
            </div>
            <div className="title">
              <FormGroup>
                <Label>Address</Label>
                <Input
                  value={contactData.address}
                  onChange={(e) =>
                    setContactData({ ...contactData, address: e.target.value })
                  }
                  id="exampleText"
                  name="text"
                  type="textarea"
                  maxLength="200"
                />
              </FormGroup>
            </div>
            <div className="row">
              <div className="col-sm-12">
                {/* <div className="title">
                  <FormGroup>
                    <Label>Country/Region</Label>
                    <Input
                      id="exampleSelect"
                      name="select"
                      type="select"
                      value={JSON.stringify(contactData.country)}
                      onChange={countryHandle}
                    >
                      <option value="null">Select Country</option>
                      {countryList?.data?.map((item, index) => {
                        return (
                          <option key={index} value={JSON.stringify(item)}>
                            {item.name}
                          </option>
                        );
                      })}
                    </Input>
                  </FormGroup>
                </div> */}
                <div className="title">
                  <label>Country/Region</label>
                  <div className="autocomplete-wrapper">
                    <Autocomplete
                      inputProps={{
                        placeholder: "Location",
                      }}
                      value={locationInput}
                      items={locationList}
                      getItemValue={(item) => item.name}
                      // shouldItemRender={(item, value) =>
                      //   item.name.toLowerCase().indexOf(value.toLowerCase()) > -1
                      // }
                      renderMenu={(items, value) => (
                        <div className="dropdown">
                          {items.length === 0
                            ? `No matches for ${value}`
                            : items}
                        </div>
                      )}
                      renderItem={(item, isHighlighted) => (
                        <div
                          className={`item ${
                            isHighlighted ? "selected-item" : ""
                          }`}
                        >
                          {item.name}
                        </div>
                      )}
                      onChange={(obj, val) => {
                        setLocationInput(val);
                        setContactData({
                          ...contactData,
                          location: obj.name,
                          country: obj.country_id
                            ? { id: obj.country_id, code: obj.country_code }
                            : null,
                          state: obj.state_id ? obj.state_id : null,
                          city: obj.city_id ? obj.city_id : null,
                        });
                        setCountryCode(
                          obj.country_code ? obj.country_code : null
                        );
                      }}
                      onSelect={(val, obj) => {
                        setLocationInput(val);
                        setContactData({
                          ...contactData,
                          location: obj.name,
                          country: obj.country_id
                            ? { id: obj.country_id, code: obj.country_code }
                            : null,
                          state: obj.state_id ? obj.state_id : null,
                          city: obj.city_id ? obj.city_id : null,
                        });
                        setCountryCode(
                          obj.country_code ? obj.country_code : null
                        );
                      }}
                      wrapperStyle={{}}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-sm-6">
                <div className="title">
                  <Label for="exampleSelect">Nationality</Label>
                  {isWebView ? (
                    <div className="autocomplete-wrapper">
                      {countryList && (
                        <Autocomplete
                          inputProps={{
                            placeholder: "Nationality",
                          }}
                          value={countryInput}
                          items={countryList?.data}
                          getItemValue={(item) => item.name}
                          shouldItemRender={(item, value) =>
                            item.name
                              .toLowerCase()
                              .indexOf(value.toLowerCase()) > -1
                          }
                          renderMenu={(items, value) => (
                            <div className="dropdown">
                              {items.length === 0
                                ? `No matches for ${value}`
                                : items}
                            </div>
                          )}
                          renderItem={(item, isHighlighted) => (
                            <div
                              className={`item ${
                                isHighlighted ? "selected-item" : ""
                              }`}
                            >
                              {item.name}
                            </div>
                          )}
                          onChange={(obj, val) => {
                            setCountryInput(val);
                            setContactData({
                              ...contactData,
                              nationality: obj,
                            });
                          }}
                          onSelect={(val, obj) => {
                            setCountryInput(val);
                            setContactData({
                              ...contactData,
                              nationality: obj,
                            });
                          }}
                          wrapperStyle={{}}
                        />
                      )}
                    </div>
                  ) : (
                    // <Dropdown
                    //   label="Select"
                    //   data={
                    //     nationalityList &&
                    //     nationalityList?.map((item) => ({
                    //       value: item.name,
                    //       id: item.id,
                    //       country_code: item.country_code,
                    //     }))
                    //   }
                    //   dynamicPlaceHolder={showLabel}
                    //   dropdownValue="nationality"
                    //   handleSelect={handleSelectedValueNationality}
                    //   handleChange={handleChange}
                    // />
                    <Input
                      id="exampleSelect"
                      name="select"
                      type="select"
                      style={{ color: "#676A79" }}
                      value={JSON.stringify(contactData.nationality)}
                      onChange={(e) =>
                        setContactData({
                          ...contactData,
                          nationality:
                            e.target.value && JSON.parse(e.target.value),
                        })
                      }
                    >
                      <option value="">Select Nationality</option>
                      {countryList?.data?.map((item, index) => {
                        return (
                          <option key={index} value={JSON.stringify(item)}>
                            {item.name}
                          </option>
                        );
                      })}
                    </Input>
                  )}
                </div>
              </div>
              <div className="col-sm-6">
                <div className="title">
                  <Label>Post Code</Label>
                  <Input
                    type="text"
                    placeholder=""
                    maxLength="20"
                    value={contactData.pin_code}
                    onChange={(e) => {
                      setContactData({
                        ...contactData,
                        pin_code: e.target.value,
                      });
                      if (
                        postCodeValidator(e.target.value, countryCode) === true
                      ) {
                        setErrorPostCode("");
                      } else setErrorPostCode("Invalid Post Code.");
                    }}
                    // onBlur={ ()=>}
                  />
                  {errorPostCode && (
                    <label className="label-input100 text-danger">
                      {errorPostCode}
                    </label>
                  )}
                </div>
              </div>
            </div>
            <div className="title">
              <FormGroup>
                <Label>Website</Label>
                <Input
                  type="text"
                  placeholder=""
                  // maxLength="20"
                  value={contactData.website}
                  onChange={handleWebsiteChange}
                  invalid={!isWebsiteValid}
                />
                {!isWebsiteValid && (
                  <FormFeedback>
                    Please enter a valid website URL (e.g., http://example.com
                    or www.example.com)
                  </FormFeedback>
                )}
              </FormGroup>
              {/* <p className="mt-10">Website</p>
              <Input
                  value={contactData.address}
                  onChange={(e) =>
                    setContactData({ ...contactData, address: e.target.value })
                  }
                  id="exampleText"
                  name="text"
                  type="textarea"
                  maxLength="20"
                /> */}
              {/* <span className="sub-heading-contact">+ Add Website</span> */}
              <p className="mt-10">Email</p>
              <span className="sub-heading-contact">{email}</span>
              <span style={{ position: "relative" }}>
                <p className="mt-10 d-flex">
                  Profile URL
                  <img
                    height={"28px"}
                    width={"28px"}
                    role="button"
                    src={edit}
                    alt=""
                    onClick={() => {
                      setCustomProfileModal(!customProfileModal);
                    }}
                    style={{ marginLeft: "auto" }}
                  />
                </p>
              </span>
              <div className="sub-heading-contact">
                {WEB_URL}profile/{profileUrl || user?.data?.id}
              </div>
            </div>
            {/* <div className="email">
                  <span>Profile URL</span>
                </div>
                <div className="link">
                  <a>http://www.nobelpage.com/np/alvaro_vasquez</a>
                </div> */}
          </form>
        </ModalBody>
        <div className="p-20" style={{ textAlign: "center" }}>
          <div
            className="btn btn-blue"
            onClick={(e) => {
              console.log(isWebsiteValid, "isWebsiteValid");
              if (isWebsiteValid) {
                handleSubmit(e);
              }
            }}
          >
            Save
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default AddContactInfoModal;
