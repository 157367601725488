import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import "./MessageBox.scss";
import moment from "moment/moment";
import { useDispatch, useSelector } from "react-redux";
import NewMessageBox from "./NewMessageBox";
import EmojiPicker from "emoji-picker-react";
import { initLoad, stopLoad } from "../../../../store/loader/loaderActions";
import axios from "axios";
import { toast } from "react-toastify";
import MediaView from "../MediaView/MediaView.jsx";
import {
  getMessages,
  updateConversation,
  updateConversationTypes,
  updateGroupConversation,
} from "../../../../store/message/messageActions.js";
import ReportModal from "../../../../routes/dashboard/components/reportTypeModal.js";
import placeholder from "../../../../assets/img/default_profile.png";
import groupPlaceholder from "../../../../assets/img/default_group_icon.svg";
import audio from "../../../../assets/audio/message.mp3";
import GiphyPicker from "../../../../core/components/GifPicker/GifPicker.js";
import { UPDATE_CONVERSATION, UPDATE_UNREAD } from "../../../../store/types.js";
import AppLoader from "../../../../core/components/Loaders/appLoader/appLoader.jsx";
import ConfirmationModal from "../../../../core/components/modal/ConfirmationModal.js";
// import { GetTime } from "../../../../core/components/Utility/Utility";

const GetTime = (date) => {
  moment.updateLocale("en", {
    relativeTime: {
      future: "in %s",
      past: function (value) {
        return value === "just now" ? value : value + " ago";
      },
      s: "just now",
      m: "1 min",
      mm: "%d mins",
      h: "1 hr",
      hh: "%d hrs",
      d: "1 day",
      dd: "%d days",
      M: "1 month",
      MM: "%d months",
      y: "1 yr",
      yy: "%d yrs",
    },
  });
  let result = moment(date).fromNow();
  const now = moment();
  const hours = now.diff(date, "hours");
  const days = now.diff(date, "days");
  const weeks = now.diff(date, "weeks");
  if (hours >= 22 && hours <= 47) {
    result = `1 day ago`;
  } else if (days >= 7) {
    if (days <= 13) {
      result = `1 week ago`;
    } else if (days > 13 && days <= 25) {
      result = `${weeks} weeks ago`;
    }
  }
  return result;
};

export default function MessageBox({
  activeTab,
  setActiveTab,
  activeBox,
  xmppService,
  chatService,
}) {
  const loginId = 1;
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.auth);
  const { loading } = useSelector((state) => state.loader);
  // const { messageData, typingObject } = useSelector((state) => state.xmpp);
  const [textMessage, setTextMessage] = useState("");
  const [messages, setMessages] = useState([]);
  const [groupAdmin, setGroupAdmin] = useState([]);
  const [typing, setTyping] = useState(false);
  const [showEmoji, setShowEmoji] = useState(false);
  const [showGif, setShowGif] = useState(false);
  const [topDropDownOpen, setTopDropDownOpen] = useState(false);
  const [bottomDropDownOpen, setBottomDropDownOpen] = useState(false);
  const [isImage, setIsImage] = useState(false);
  const [isVideo, setIsVideo] = useState(false);
  const [isAttachment, setIsAttachment] = useState(false);
  const [isAudio, setIsAudio] = useState(false);
  const [fileName, setFileName] = useState("");
  const [media, setMedia] = useState([]);
  const [mediaSize, setMediaSize] = useState(0);
  const messagesRef = useRef(null);
  const imgRef = useRef();
  const vidRef = useRef();
  const attachRef = useRef();
  const audioSound = new Audio(audio);

  const [mediaStream, setMediaStream] = useState(null);
  const [mediaRecorder, setMediaRecorder] = useState(null);
  const [isRecording, setIsRecording] = useState(false);
  const [timer, setTimer] = useState(0);

  const [reportModal, setReportModal] = useState(false);
  const [sendType, setSendType] = useState("enter");

  const [messageEditId, setMessageEditId] = useState(null);
  const [messageDeleteId, setMessageDeleteId] = useState(null);

  const [typingUsers, setTypingUsers] = useState([]);
  const typingTimeoutRef = useRef(null);

  const initialLimit = 20;
  const [hasMore, setHasMore] = useState(true);

  const updateUnread = useCallback(
    (roomCode) => {
      if (roomCode) chatService.updateUnread(0, roomCode, user?.data?.jid);
    },
    [chatService]
  );

  useEffect(() => {
    if (activeTab === "group") {
      setGroupAdmin(
        activeBox?.users
          ?.filter((member) => member?.isAdmin)
          ?.map((member) => member?.user?.user_id?.toString())
      );
    }

    const subscription = chatService
      .onMessage()
      .subscribe(({ newMsg, updatedRoom }) => {
        console.log("Message", newMsg);

        if (newMsg.roomCode === activeBox?.code) {
          setMessages((prevMsgs) => [...prevMsgs, newMsg]);
          if (newMsg?.user?.user_id != user?.data?.id) {
            // audioSound?.play();
            updateUnread(activeBox?.code);
          }
          dispatch({
            type: UPDATE_CONVERSATION,
            payload: {
              ...updatedRoom,
              users: updatedRoom?.users?.map((obj) =>
                obj?.user?.user_id == user?.data?.id
                  ? { ...obj, unread: 0 }
                  : obj
              ),
            },
          });
        } else {
          audioSound?.play();
          dispatch({
            type: UPDATE_CONVERSATION,
            payload: updatedRoom,
          });
        }
      });

    const typingSubscription = chatService
      .onTyping()
      .subscribe(({ username, roomCode }) => {
        if (roomCode === activeBox.code) {
          setTypingUsers((prevTypingUsers) => {
            if (
              username !== user?.data?.first_name &&
              !prevTypingUsers.includes(username)
            ) {
              return [...prevTypingUsers, username];
            }
            return prevTypingUsers;
          });
        }
      });

    const stopTypingSubscription = chatService
      .onStopTyping()
      .subscribe(({ username, roomCode }) => {
        if (roomCode === activeBox.code) {
          setTypingUsers((prevTypingUsers) =>
            prevTypingUsers.filter((name) => name !== username)
          );
        }
      });

    const updateSubscription = chatService
      .onUpdateMessage()
      .subscribe(({ newMsg }) => {
        console.log("Updated Message", newMsg);

        if (newMsg.roomCode === activeBox?.code) {
          setMessages((msgs) =>
            msgs.map((m) => (m?._id == newMsg?._id ? newMsg : m))
          );
        }
      });

    dispatch(initLoad());
    dispatch(getMessages({ roomCode: activeBox?.code, limit: initialLimit }))
      .then((res) => {
        dispatch(stopLoad());
        setHasMore(true);
        setMessages(res?.data?.messages?.reverse() || []);
        scrollToBottom();
        updateUnread(activeBox?.code);
        dispatch({
          type: UPDATE_UNREAD,
          payload: {
            user_id: user?.data?.id,
            roomCode: activeBox?.code,
          },
        });
      })
      .catch((err) => dispatch(stopLoad()));

    return () => {
      subscription.unsubscribe();
      typingSubscription.unsubscribe();
      stopTypingSubscription.unsubscribe();
      updateSubscription.unsubscribe();
    };
  }, [activeBox, user?.data?.first_name]);

  const getPreviousMessages = useCallback(() => {
    dispatch(
      getMessages({
        roomCode: activeBox?.code,
        limit: initialLimit,
        skip: messages?.length || 0,
      })
    )
      .then((res) => {
        dispatch(stopLoad());
        if (res?.data?.messages?.length == 0) {
          setHasMore(false);
        } else {
          setMessages((msgs) => [...res?.data?.messages?.reverse(), ...msgs]);
          if (messages?.length && messagesRef.current)
            messagesRef.current.scrollTop = 2000;
        }
      })
      .catch((err) => dispatch(stopLoad()));
  }, [activeBox, messages]);

  const scrollToBottom = useCallback(() => {
    if (messages?.length && messagesRef.current)
      messagesRef.current.scrollTop = messagesRef?.current?.scrollHeight;
  }, [messages]);

  // Handle scrolling
  const handleScroll = () => {
    if (!messagesRef.current || loading || !hasMore) return;
    if (messagesRef.current.scrollTop === 0) {
      getPreviousMessages();
    }
  };

  useEffect(() => {
    const chatContainer = messagesRef?.current;
    if (chatContainer) {
      chatContainer.addEventListener("scroll", handleScroll);
    }

    return () => {
      if (chatContainer) {
        chatContainer.removeEventListener("scroll", handleScroll);
      }
    };
  }, [messages, loading, hasMore]);

  const emitTyping = () => {
    if (activeBox) {
      chatService.emitTyping(activeBox.code, user?.data?.first_name);
    }
  };

  const emitStopTyping = () => {
    if (activeBox) {
      chatService.emitStopTyping(activeBox.code, user?.data?.first_name);
    }
  };

  const handleChange = (e) => {
    setTextMessage(e.target.value);

    // Clear any previous timeout
    if (typingTimeoutRef.current) {
      clearTimeout(typingTimeoutRef.current);
    }

    // Emit typing event
    emitTyping();

    // Set timeout to emit stop typing event
    typingTimeoutRef.current = setTimeout(() => {
      emitStopTyping();
    }, 2000); // 2 seconds timeout
  };

  // Handle key down
  const handleKeyDown = (e) => {
    if (e.key !== "Enter") {
      handleChange(e);
    } else {
      if (e.key === "Enter" && !e.shiftKey && sendType === "enter") {
        e.preventDefault();
        sendMessage(e.target.value);
      }
    }
  };

  // useEffect(() => {
  //   if (typingObject?.isTypingStanza) {
  //     // typingName = typingObject.senderName ? typingObject.senderName : "someone";
  //     if (
  //       activeTab === "message" &&
  //       activeBox?.User?.jid == typingObject.senderJid
  //     )
  //       setTyping(true);
  //     if (
  //       activeTab === "group" &&
  //       typingObject.senderJid !== user?.data?.jid &&
  //       activeBox?.roomJid == typingObject.groupJid
  //     )
  //       setTyping(true);

  //     setTimeout(() => {
  //       setTyping(false);
  //     }, 2000);
  //   } else setTyping(false);

  //   if (typingObject?.displayed) {
  //     let updateMessages = messages?.slice();
  //     let index = updateMessages.findIndex((data) => {
  //       return data.messageId == typingObject.messageId;
  //     });

  //     if (index > -1) {
  //       updateMessages[index].status = 2;
  //     }
  //     setMessages(updateMessages);
  //   }

  //   if (typingObject?.delivered) {
  //     setTyping(false);
  //     let updateMessages = messages?.slice();
  //     let index = updateMessages?.findIndex((data) => {
  //       return data.messageId == typingObject.messageId;
  //     });
  //     if (index > -1) {
  //       updateMessages[index].status = 1;
  //     }
  //     setMessages(updateMessages);
  //   }
  // }, [typingObject]);

  // useEffect(() => {
  //   if (messageData?.messageId) {
  //     if (
  //       activeTab === "message" &&
  //       user?.data?.jid === messageData?.to &&
  //       messageData?.sender?.jid === activeBox?.User?.jid
  //     ) {
  //       let allMessages = messages?.slice();
  //       setTyping(false);
  //       let index = allMessages.filter(
  //         (values) => values.messageId == messageData?.messageId
  //       );

  //       if (index?.length == 0) {
  //         allMessages.push(messageData);
  //         xmppService?.sendDisplayedMsgAck({
  //           senderJid: messageData.sender?.jid,
  //           messageId: messageData.messageId,
  //         });
  //         allMessages.sort(function (a, b) {
  //           return a.unixTimeStamp > b.unixTimeStamp
  //             ? 1
  //             : b.unixTimeStamp > a.unixTimeStamp
  //             ? -1
  //             : 0;
  //         });
  //         let newData = [];
  //         let allDates = [];
  //         allMessages.map((val) => {
  //           if (val.message) {
  //             if (!val.unixTimeStamp) val.unixTimeStamp = Date.now();
  //             const date = moment(parseInt(val.unixTimeStamp)).format(
  //               "DD-MMM-YYYY"
  //             );
  //             let index = allDates.filter((data) => {
  //               return data == date;
  //             });
  //             if (index.length == 0) {
  //               allDates.push(date);
  //               newData.push(date);
  //             }

  //             // val.status = 0;
  //           } else {
  //             allDates.push(val);
  //           }
  //           newData.push(val);
  //         });
  //         setMessages(newData);
  //       }
  //     } else if (
  //       activeTab === "group" &&
  //       activeBox?.roomJid === messageData?.groupJid &&
  //       messageData?.sender?.jid !== user?.data?.jid
  //     ) {
  //       let allMessages = messages?.slice();
  //       setTyping(false);
  //       let index = allMessages.filter(
  //         (values) => values.messageId == messageData?.messageId
  //       );

  //       if (index?.length == 0) {
  //         allMessages.push(messageData);
  //         xmppService?.sendDisplayedMsgAck(
  //           {
  //             senderJid: messageData.sender?.jid,
  //             messageId: messageData.messageId,
  //           },
  //           "groupchat"
  //         );
  //         allMessages.sort(function (a, b) {
  //           return a.unixTimeStamp > b.unixTimeStamp
  //             ? 1
  //             : b.unixTimeStamp > a.unixTimeStamp
  //             ? -1
  //             : 0;
  //         });
  //         let newData = [];
  //         let allDates = [];
  //         allMessages.map((val) => {
  //           if (val.message) {
  //             if (!val.unixTimeStamp) val.unixTimeStamp = Date.now();
  //             const date = moment(parseInt(val.unixTimeStamp)).format(
  //               "DD-MMM-YYYY"
  //             );
  //             let index = allDates.filter((data) => {
  //               return data == date;
  //             });
  //             if (index.length == 0) {
  //               allDates.push(date);
  //               newData.push(date);
  //             }
  //             // val.status = 0;
  //           } else {
  //             allDates.push(val);
  //           }
  //           newData.push(val);
  //         });
  //         setMessages(newData);
  //       }
  //     } else if (
  //       ((activeTab === "group" &&
  //         activeBox?.roomJid === messageData?.groupJid) ||
  //         (activeTab === "message" &&
  //           activeBox?.User?.jid === messageData?.to)) &&
  //       messageData?.sender?.jid == user?.data?.jid
  //     ) {
  //       let allMessages = messages?.slice();
  //       setTyping(false);
  //       let index = allMessages.filter(
  //         (values) => values.messageId == messageData?.messageId
  //       );

  //       if (index?.length == 0) {
  //         allMessages.push(messageData);
  //         allMessages.sort(function (a, b) {
  //           return a.unixTimeStamp > b.unixTimeStamp
  //             ? 1
  //             : b.unixTimeStamp > a.unixTimeStamp
  //             ? -1
  //             : 0;
  //         });
  //         let newData = [];
  //         let allDates = [];
  //         allMessages.map((val) => {
  //           if (val.message) {
  //             if (!val.unixTimeStamp) val.unixTimeStamp = Date.now();
  //             const date = moment(parseInt(val.unixTimeStamp)).format(
  //               "DD-MMM-YYYY"
  //             );
  //             let index = allDates.filter((data) => {
  //               return data == date;
  //             });
  //             if (index.length == 0) {
  //               allDates.push(date);
  //               newData.push(date);
  //             }

  //             // val.status = 0;
  //           } else {
  //             allDates.push(val);
  //           }
  //           newData.push(val);
  //         });
  //         setMessages(newData);
  //       }
  //     }
  //   }
  // }, [messageData]);

  // Ejaberred Send Message
  // const sendMessage = (messageText) => {
  //   if (isImage || isVideo || isAttachment || isAudio) {
  //     let newMessage = {
  //       sender: {
  //         id: user?.data?.id,
  //         name: `${user?.data?.first_name} ${user?.data?.last_name}`?.trim(),
  //         first_name: user?.data?.first_name,
  //         last_name: user?.data?.last_name,
  //         profile_img: user?.data?.profile_image,
  //         jid: user?.data?.jid,
  //       },
  //       to: activeBox?.User?.jid,
  //       mediaUrl: media?.length ? media[0] : "",
  //       mediaSize: mediaSize,
  //       mediaName: fileName,
  //     };
  //     if (isImage) {
  //       newMessage.messageType = "2";
  //       newMessage.message = "[Image] Media Message";
  //     } else if (isVideo) {
  //       newMessage.messageType = "3";
  //       newMessage.message = "[Video] Media Message";
  //     } else if (isAudio) {
  //       newMessage.messageType = "4";
  //       newMessage.message = "[Audio] Media Message";
  //     } else if (isAttachment) {
  //       newMessage.messageType = "5";
  //       newMessage.message = "[Attachment] Media Message";
  //     }
  //     // setMessages([...messages, newMessage]);
  //     dispatch(
  //       updateConversation({
  //         id: activeBox?.id,
  //         lastMessage: newMessage?.message,
  //         lastMessageBy: activeBox?.User?.id,
  //       })
  //     );
  //     setTextMessage("");
  //     setIsImage(false);
  //     setIsAttachment(false);
  //     setIsVideo(false);
  //     setIsAudio(false);
  //     setMedia([]);
  //     xmppService?.onMediaShareStanza(newMessage);
  //   } else if (messageText) {
  //     const newMessage = {
  //       sender: {
  //         id: user?.data?.id,
  //         name: `${user?.data?.first_name} ${user?.data?.last_name}`?.trim(),
  //         first_name: user?.data?.first_name,
  //         last_name: user?.data?.last_name,
  //         profile_img: user?.data?.profile_image,
  //         jid: user?.data?.jid,
  //       },
  //       to: activeBox?.User?.jid,
  //       message: messageText,
  //     };
  //     // setMessages([...messages, newMessage]);
  //     dispatch(
  //       updateConversation({
  //         id: activeBox?.id,
  //         lastMessage: newMessage?.message,
  //         lastMessageBy: activeBox?.User?.id,
  //       })
  //     );
  //     setTextMessage("");
  //     xmppService?.sendMessage(newMessage);
  //   }
  // };

  const sendMessage = (messageText) => {
    if (isImage || isVideo || isAttachment || isAudio) {
      let newMessage = {
        user: user?.data?.jid,
        roomCode: activeBox?.code,
        mediaUrl: media?.length ? media[0] : "",
        mediaSize: mediaSize,
        mediaName: fileName,
      };
      if (isImage) {
        newMessage.messageType = 2;
        newMessage.message = "[Image] Media Message";
      } else if (isVideo) {
        newMessage.messageType = 3;
        newMessage.message = "[Video] Media Message";
      } else if (isAudio) {
        newMessage.messageType = 4;
        newMessage.message = "[Audio] Media Message";
      } else if (isAttachment) {
        newMessage.messageType = 5;
        newMessage.message = "[Attachment] Media Message";
      }
      setTextMessage("");
      setIsImage(false);
      setIsAttachment(false);
      setIsVideo(false);
      setIsAudio(false);
      setMedia([]);

      chatService.send(newMessage);
    } else if (messageEditId && messageText) {
      chatService.updateMessage(messageEditId, activeBox?.code, messageText);
      setTextMessage("");
      setMessageEditId(null);
    } else if (messageText) {
      const newMessage = {
        user: user?.data?.jid,
        roomCode: activeBox?.code,
        messageType: 1,
        message: messageText,
      };

      setTextMessage("");
      chatService.send(newMessage);
      scrollToBottom();
    }
  };

  const keyup = () => {
    if (activeTab === "group") {
      const typingMessage = {
        sender: {
          id: user?.data?.id,
          first_name: user?.data?.first_name,
          last_name: user?.data?.last_name,
          profile_img: user?.data?.profile_image,
          jid: user?.data?.jid,
        },
        to: activeBox?.roomJid,
      };
      xmppService?.onTypingStanza(typingMessage, "groupchat");
    } else {
      const typingMessage = {
        sender: {
          id: user?.data?.id,
          first_name: user?.data?.first_name,
          last_name: user?.data?.last_name,
          profile_img: user?.data?.profile_image,
          jid: user?.data?.jid,
        },
        to: activeBox?.User?.jid,
      };
      xmppService?.onTypingStanza(typingMessage);
    }
  };

  const onEmojiClick = (emojiData, event) => {
    setTextMessage((m) => m + emojiData.emoji);
    // setShowEmoji(false)
  };

  const onGifSelect = (gifData) => {
    setShowGif(false);
    if (gifData) {
      setIsImage(true);
      setIsVideo(false);
      setIsAttachment(false);
      setFileName(gifData.name);
      // setMediaSize(0);
      setMedia([gifData.url]);
      setTextMessage("");
    }
  };

  const handleMediaChange = async (e, type) => {
    var fileExtension = e.target.value.split(".").pop();
    var validFile = true;
    let videoValid = /^(mp4|MP4)$/;
    let ImageValid = /^(jpg|jpeg|png|gif|JPG|JPEG|PNG|GIF)$/;
    let attachmentValid = /^(pdf|ppt|pptx|txt|^text\/plain|doc|docx|xls|xlsx)$/;
    setIsAudio(false);

    if (type == "image") {
      setIsImage(true);
      setIsVideo(false);
      setIsAttachment(false);
      if (ImageValid.test(fileExtension) == false) {
        validFile = false;
      }
    } else if (type == "video") {
      setIsVideo(true);
      setIsAttachment(false);
      setIsImage(false);

      if (videoValid.test(fileExtension) == false) {
        validFile = false;
      }
    } else if (type == "attachment") {
      setIsAttachment(true);
      setIsImage(false);
      setIsVideo(false);
      if (attachmentValid.test(fileExtension) == false) {
        validFile = false;
      }
    }
    if (validFile) {
      let tempimage = [];
      Array.from(e.target.files).map((file) => {
        tempimage.push({
          type,
          file,
        });
      });
      try {
        const UPLOAD_URL = `${process.env.REACT_APP_UPLOAD_URL}api/v1/upload`;
        const formData = new FormData();
        const headers = {
          "Accept-Language": "en",
          "content-type": "multipart/form-data",
        };

        for (var i = 0; i < tempimage.length; i++) {
          formData.append("image", tempimage[i].file);
        }
        dispatch(initLoad());
        await axios.post(UPLOAD_URL, formData, { headers }).then((res) => {
          dispatch(stopLoad());
          let { filesArray, urlsArray } = res.data;
          setFileName(filesArray[0].originalname);
          setMediaSize(filesArray[0].size);
          setMedia(urlsArray);
          setTextMessage("");
        });
        //  e.target.files = ''
      } catch (err) {
        if (err.response.data.message === "File too large") {
          // alert("File is too large, please upload another file");
          toast.warn("File is too large, please upload another file", {
            position: toast.POSITION.BOTTOM_LEFT,
          });
          dispatch(stopLoad());
          setIsImage(false);
          setIsAttachment(false);
          setIsVideo(false);
          return;
        }
        setIsImage(false);
        setIsAttachment(false);
        setIsVideo(false);
        console.log(err);
      }
    } else {
      alert("Please choose valid file type");
      toast.warn("Please choose valid file type", {
        position: toast.POSITION.BOTTOM_LEFT,
      });
      setIsImage(false);
      setIsAttachment(false);
      setIsVideo(false);
      return;
    }
  };

  const handleMediaClose = () => {
    setIsImage(false);
    setIsAttachment(false);
    setIsVideo(false);
    setIsAudio(false);
    setMedia([]);
  };

  const copyToClipboard = (text) => {
    navigator.clipboard
      .writeText(text)
      .then(() => {
        toast.success(`Message copied to clipboard: ${text}`, {
          position: toast.POSITION.BOTTOM_LEFT,
        });
      })
      .catch((error) => {
        console.error(`Could not copy message: ${error}`);
      });
  };

  const onEdit = (messageId, message) => {
    setMessageEditId(messageId);
    setTextMessage(message);
    setIsImage(false);
    setIsAttachment(false);
    setIsVideo(false);
    setIsAudio(false);
    setMedia([]);
  };

  const onDelete = () => {
    chatService.deleteMessage(messageDeleteId, activeBox?.code);
  };

  useEffect(() => {
    let intervalId;
    if (isRecording) {
      intervalId = setInterval(() => {
        setTimer((prev) => {
          if (prev > 180) {
            stopRecording();
            return 0;
          }
          return prev + 1;
        });
      }, 1000);
    } else {
      clearInterval(intervalId);
      setTimer(0);
    }
    return () => clearInterval(intervalId);
  }, [isRecording]);

  const startRecording = async () => {
    try {
      const devices = await navigator.mediaDevices.enumerateDevices();
      const hasMicrophone = devices.some(
        (device) => device.kind === "audioinput"
      );
      const hasSpeaker = devices.some(
        (device) => device.kind === "audiooutput"
      );

      if (!hasMicrophone) {
        alert("Microphone is not connected.");
        return;
      }

      if (!hasSpeaker) {
        alert("Speaker is not connected.");
        return;
      }
      const stream = await navigator.mediaDevices.getUserMedia({
        audio: true,
      });
      const recorder = new MediaRecorder(stream);
      const recordedChunks = [];

      recorder.ondataavailable = (e) => {
        recordedChunks.push(e.data);
      };

      recorder.onstop = async () => {
        const audioBlob = new Blob(recordedChunks, { type: "audio/mp3" });
        // const audioUrl = URL.createObjectURL(audioBlob);
        try {
          const formData = new FormData();
          formData.append(
            "image",
            audioBlob,
            `audio-${new Date().getTime()}.mp3`
          );
          const UPLOAD_URL = `${process.env.REACT_APP_UPLOAD_URL}api/v1/upload`;
          const headers = {
            "Accept-Language": "en",
            "content-type": "multipart/form-data",
          };
          setIsAudio(true);
          dispatch(initLoad());
          await axios.post(UPLOAD_URL, formData, { headers }).then((res) => {
            dispatch(stopLoad());
            let { filesArray, urlsArray } = res.data;
            setFileName(filesArray[0].originalname);
            setMediaSize(filesArray[0].size);
            setMedia(urlsArray);
            setTextMessage("");
          });
        } catch (error) {
          console.error("Error uploading audio:", error);
          setIsAudio(false);
          dispatch(stopLoad());
        }
      };

      recorder.start();
      setIsAttachment(false);
      setIsImage(false);
      setIsVideo(false);
      setIsRecording(true);
      setMediaStream(stream);
      setMediaRecorder(recorder);
    } catch (err) {
      console.error("Error accessing media devices", err);
    }
  };

  const stopRecording = async () => {
    try {
      console.log("Stopping recording");
      mediaRecorder.stop();
      setIsRecording(false);
      mediaStream.getTracks().forEach((track) => track.stop());
    } catch (err) {
      console.error("Error accessing media devices", err);
    }
  };

  // Handle achived, spam, unread actioned by conversation Id.
  const updateConversationType = (conversation_type) => {
    if (activeTab == "group") {
      dispatch(initLoad());
      dispatch(
        updateConversationTypes({
          conversation_id: activeBox?.id,
          type: conversation_type,
        })
      )
        .then(() => dispatch(stopLoad()))
        .catch(() => dispatch(stopLoad()));
    } else {
      dispatch(initLoad());
      dispatch(
        updateConversation({
          id: activeBox?.id,
          conversation_type,
        })
      )
        .then(() => dispatch(stopLoad()))
        .catch(() => dispatch(stopLoad()));
    }
    setTopDropDownOpen(!topDropDownOpen);
  };

  // Check away message
  const isAwayMessage = (message) => {
    if (
      message &&
      message?.message &&
      new Date(message?.startDate) <= new Date() &&
      new Date(message?.endDate) >= new Date()
    )
      return true;
    return false;
  };

  const mainArea = useMemo(() => {
    return (
      <MediaView
        data={
          media
          // "http://commondatastorage.googleapis.com/gtv-videos-bucket/sample/BigBuckBunny.mp4"
        }
        isImage={isImage}
        isVideo={isVideo}
        isAttachment={isAttachment}
        mediaName={fileName}
        isAudio={isAudio}
        handleMediaClose={handleMediaClose}
      />
    );
  }, [media]);

  const renderTypingIndicator = useMemo(() => {
    if (typingUsers.length > 0) {
      if (activeTab === "group") {
        return (
          <div className="text-black">
            {typingUsers.join(", ")}{" "}
            {typingUsers.length > 1 ? "are typing..." : "is typing..."}
          </div>
        );
      } else {
        return <div className="text-black">typing...</div>;
      }
    }
    return null;
  }, [typingUsers]);

  const renderAwayMessage = useMemo(() => {
    if (isAwayMessage(activeBox?.User?.awayMessage))
      return (
        <h6 className="away-message card-control-text-oneline">
          {activeBox?.User?.awayMessage?.message}
        </h6>
      );
    return null;
  }, [activeBox]);

  return activeTab !== "new" ? (
    activeBox ? (
      <div className="message-box-main mt-26">
        {loading && <AppLoader />}
        <div className="p-20">
          <div className="row">
            <div className="col-lg-1 col-sm-1 col-2">
              <div className="profile-img">
                <img
                  src={
                    activeTab === "group"
                      ? activeBox.roomLogo || groupPlaceholder
                      : activeBox.User?.profileImg || placeholder
                  }
                  className="image-fit"
                  alt=""
                />
                {activeTab !== "group" && (
                  <div
                    className={`status-indicator ${
                      activeBox.User?.isOnline ? "online" : "offline"
                    }`}
                  ></div>
                )}
              </div>
            </div>
            <div className="col-sm-8 col-6 d-flex align-items-center">
              <div className="details">
                <h5 className="card-control-text-oneline">
                  {activeTab === "group"
                    ? activeBox?.groupName ||
                      chatService?.getRoomName(activeBox?.users, user?.data?.id)
                    : `${activeBox?.User?.firstName} ${activeBox?.User?.lastName}`}
                </h5>
                {renderTypingIndicator || renderAwayMessage}
                {/* {typing ? (
                  <p className="text-black">
                    {activeTab == "group" && typingObject?.senderFirstName}{" "}
                    Typing..
                  </p>
                ) : activeTab !== "group" &&
                  xmppService?.isConnectedUser(activeBox?.User?.jid) ? (
                  <p className="online">Online</p>
                ) : (
                  isAwayMessage(activeBox?.User?.away_message) && (
                    <h6 className="away-message card-control-text-oneline">
                      {activeBox?.User?.away_message?.message}
                    </h6>
                  )
                )} */}
              </div>
            </div>
            <div className="col-sm-3 col-4">
              <div className="float-end gap-2 d-flex">
                <div className="icon-call"></div>
                <div className="icon-video"></div>
                <div className="dot">
                  <div onClick={() => setTopDropDownOpen(!topDropDownOpen)}>
                    <span>&bull;</span>
                    <span>&bull;</span>
                    <span>&bull;</span>
                  </div>
                  {topDropDownOpen && (
                    <div className="drops-list">
                      <ul>
                        {activeBox?.conversation_type == "archived" ? (
                          <li onClick={() => updateConversationType(null)}>
                            Remove from archive
                          </li>
                        ) : (
                          <li
                            onClick={() => updateConversationType("archived")}
                          >
                            Add to archive
                          </li>
                        )}
                        {activeBox?.conversation_type == "spam" ? (
                          <li onClick={() => updateConversationType(null)}>
                            Remove from spam
                          </li>
                        ) : (
                          <li onClick={() => updateConversationType("spam")}>
                            Move to spam
                          </li>
                        )}
                        {activeBox?.conversation_type == "unread" ? (
                          <li onClick={() => updateConversationType(null)}>
                            Mark as read
                          </li>
                        ) : (
                          <li onClick={() => updateConversationType("unread")}>
                            Mark as unread
                          </li>
                        )}

                        <li>Block</li>
                        <li
                          onClick={() => {
                            setReportModal(true);
                            setTopDropDownOpen(!topDropDownOpen);
                          }}
                        >
                          Report
                        </li>
                      </ul>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="line"></div>
        <div className="chatting">
          <div className="p-20">
            {activeTab === "message" && (
              <div
                className="messages-list"
                style={showEmoji || showGif ? { height: "30vh" } : {}}
                ref={messagesRef}
              >
                {messages?.length ? (
                  messages.map((message, index) =>
                    message?.message ? (
                      message?.user?.user_id === user?.data?.id ? (
                        <div key={index} className={"right-side mt-3"}>
                          <div className="box">
                            <div className="dot">
                              <div className="dropdown">
                                {[2, 3, 4, 5].includes(message?.messageType) ? (
                                  <ul>
                                    <li>Forward</li>
                                    <li>
                                      <a
                                        className="text-reset"
                                        href={message?.mediaUrl}
                                        download={true}
                                        target="_blank"
                                      >
                                        Download
                                      </a>
                                    </li>
                                    <li>Delete</li>
                                  </ul>
                                ) : (
                                  <ul>
                                    <li>Forward</li>
                                    <li
                                      onClick={() =>
                                        copyToClipboard(message?.message || "")
                                      }
                                    >
                                      Copy
                                    </li>
                                    <li
                                      onClick={() =>
                                        onEdit(message?._id, message?.message)
                                      }
                                    >
                                      Edit
                                    </li>
                                    <li
                                      onClick={() =>
                                        setMessageDeleteId(message?._id)
                                      }
                                    >
                                      Delete
                                    </li>
                                  </ul>
                                )}
                              </div>
                              <span>&bull;</span>
                              <span>&bull;</span>
                              <span>&bull;</span>
                            </div>
                            <div
                              className={
                                message?.status == "delivered"
                                  ? "msg delivered"
                                  : message?.status == "seen"
                                  ? "msg displayed"
                                  : "msg"
                              }
                            >
                              {message?.messageType === 1 ? (
                                <p>{message.message}</p>
                              ) : message?.messageType === 2 ? (
                                <MediaView
                                  isImage={true}
                                  data={message?.mediaUrl}
                                />
                              ) : message?.messageType === 3 ? (
                                <MediaView
                                  isVideo={true}
                                  data={message?.mediaUrl}
                                />
                              ) : message?.messageType === 4 ? (
                                <MediaView
                                  isAudio={true}
                                  data={message?.mediaUrl}
                                />
                              ) : message?.messageType === 5 ? (
                                <MediaView
                                  isAttachment={true}
                                  mediaName={message?.mediaName}
                                  data={message?.mediaUrl}
                                />
                              ) : (
                                <p>{"..."}</p>
                              )}
                            </div>
                          </div>
                          <div className="time">
                            {GetTime(new Date(message?.updatedAt))}
                            {message.isEdited && (
                              <span className="px-2">Edited</span>
                            )}
                          </div>
                        </div>
                      ) : (
                        <div key={index} className={"left-side mt-3"}>
                          <div className="box">
                            <div className="msg">
                              {message?.messageType == 1 ? (
                                <p>{message.message}</p>
                              ) : message?.messageType == 2 ? (
                                <MediaView
                                  isImage={true}
                                  data={message?.mediaUrl}
                                />
                              ) : message?.messageType == 3 ? (
                                <MediaView
                                  isVideo={true}
                                  data={message?.mediaUrl}
                                />
                              ) : message?.messageType == 4 ? (
                                <MediaView
                                  isAudio={true}
                                  data={message?.mediaUrl}
                                />
                              ) : message?.messageType == 5 ? (
                                <MediaView
                                  isAttachment={true}
                                  mediaName={message?.mediaName}
                                  data={message?.mediaUrl}
                                />
                              ) : (
                                <p>{"..."}</p>
                              )}
                            </div>
                            <div className="dot">
                              <div className="dropdown">
                                {[2, 3, 4, 5].includes(message?.messageType) ? (
                                  <ul>
                                    <li>Forward</li>
                                    <li>
                                      <a
                                        className="text-reset"
                                        href={message?.mediaUrl}
                                        download={true}
                                        target="_blank"
                                      >
                                        Download
                                      </a>
                                    </li>
                                  </ul>
                                ) : (
                                  <ul>
                                    <li>Forward</li>
                                    <li
                                      onClick={() =>
                                        copyToClipboard(message?.message || "")
                                      }
                                    >
                                      Copy
                                    </li>
                                  </ul>
                                )}
                              </div>
                              <span>&bull;</span>
                              <span>&bull;</span>
                              <span>&bull;</span>
                            </div>
                          </div>
                          <div className="time">
                            {GetTime(new Date(message.updatedAt))}
                            {message.isEdited && (
                              <span className="px-2">Edited</span>
                            )}
                          </div>
                        </div>
                      )
                    ) : (
                      <div key={index} className={"text-center p-3"}>
                        <div className="box">
                          <div className="time">
                            <p>
                              {moment().format("DD-MMM-YYYY") == message
                                ? "Today"
                                : message}
                            </p>
                          </div>
                        </div>
                      </div>
                    )
                  )
                ) : (
                  <div className="text-center">No Messages</div>
                )}
              </div>
            )}

            {activeTab === "group" && (
              <div
                className="messages-list"
                style={showEmoji || showGif ? { height: "30vh" } : {}}
                ref={messagesRef}
              >
                {messages?.length ? (
                  messages.map((message, index) =>
                    message?.message ? (
                      message?.user?.user_id == user?.data?.id ? (
                        <div key={index} className={"right-side mt-20"}>
                          <div className="box">
                            <div className="dot">
                              <div className="dropdown">
                                {[2, 3, 4, 5].includes(message?.messageType) ? (
                                  <ul>
                                    <li>Forward</li>
                                    <li>
                                      <a
                                        className="text-reset"
                                        href={message?.mediaUrl}
                                        download={true}
                                        target="_blank"
                                      >
                                        Download
                                      </a>
                                    </li>
                                    <li>Delete</li>
                                  </ul>
                                ) : (
                                  <ul>
                                    <li>Forward</li>
                                    <li
                                      onClick={() =>
                                        copyToClipboard(message?.message || "")
                                      }
                                    >
                                      Copy
                                    </li>
                                    <li
                                      onClick={() =>
                                        onEdit(message?._id, message?.message)
                                      }
                                    >
                                      Edit
                                    </li>
                                    <li
                                      onClick={() =>
                                        setMessageDeleteId(message?._id)
                                      }
                                    >
                                      Delete
                                    </li>
                                  </ul>
                                )}
                              </div>
                              <span>&bull;</span>
                              <span>&bull;</span>
                              <span>&bull;</span>
                            </div>
                            <div
                              className={
                                message?.status == "delivered"
                                  ? "msg delivered"
                                  : message?.status == "seen"
                                  ? "msg displayed"
                                  : "msg"
                              }
                            >
                              {message?.messageType === 1 ? (
                                <p>{message.message}</p>
                              ) : message?.messageType === 2 ? (
                                <MediaView
                                  isImage={true}
                                  data={message?.mediaUrl}
                                />
                              ) : message?.messageType === 3 ? (
                                <MediaView
                                  isVideo={true}
                                  data={message?.mediaUrl}
                                />
                              ) : message?.messageType === 4 ? (
                                <MediaView
                                  isAudio={true}
                                  data={message?.mediaUrl}
                                />
                              ) : message?.messageType === 5 ? (
                                <MediaView
                                  isAttachment={true}
                                  mediaName={message?.mediaName}
                                  data={message?.mediaUrl}
                                />
                              ) : (
                                <p>{"..."}</p>
                              )}
                            </div>
                          </div>
                          <div className="time">
                            {GetTime(new Date(message.updatedAt))}
                            {message.isEdited && (
                              <span className="px-2">Edited</span>
                            )}
                          </div>
                        </div>
                      ) : (
                        <div key={index} className={"left-side"}>
                          {activeTab === "group" && (
                            <div className="msg-sender">
                              <img
                                src={message?.user?.profileImg || placeholder}
                              />
                              <h5 className="card-control-text-oneline">
                                {message?.user?.firstName}
                              </h5>
                              {groupAdmin?.includes(message?.user?.user_id) && (
                                <h6>Admin</h6>
                              )}
                            </div>
                          )}
                          <div className="box">
                            <div className="msg">
                              {message?.messageType === 1 ? (
                                <p>{message.message}</p>
                              ) : message?.messageType === 2 ? (
                                <MediaView
                                  isImage={true}
                                  data={message?.mediaUrl}
                                />
                              ) : message?.messageType === 3 ? (
                                <MediaView
                                  isVideo={true}
                                  data={message?.mediaUrl}
                                />
                              ) : message?.messageType === 4 ? (
                                <MediaView
                                  isAudio={true}
                                  data={message?.mediaUrl}
                                />
                              ) : message?.messageType === 5 ? (
                                <MediaView
                                  isAttachment={true}
                                  mediaName={message.mediaName}
                                  data={message?.mediaUrl}
                                />
                              ) : (
                                <p>{"..."}</p>
                              )}
                            </div>
                            <div className="dot">
                              <div className="dropdown">
                                {[2, 3, 4, 5].includes(message?.messageType) ? (
                                  <ul>
                                    <li>Forward</li>
                                    <li>
                                      <a
                                        className="text-reset"
                                        href={message?.mediaUrl}
                                        download={true}
                                        target="_blank"
                                      >
                                        Download
                                      </a>
                                    </li>
                                    <li>Delete</li>
                                  </ul>
                                ) : (
                                  <ul>
                                    <li>Forward</li>
                                    <li
                                      onClick={() =>
                                        copyToClipboard(message?.message || "")
                                      }
                                    >
                                      Copy
                                    </li>
                                    <li>Delete</li>
                                  </ul>
                                )}
                              </div>
                              <span>&bull;</span>
                              <span>&bull;</span>
                              <span>&bull;</span>
                            </div>
                          </div>
                          <div className="time">
                            {GetTime(new Date(message.updatedAt))}
                            {message.isEdited && (
                              <span className="px-2">Edited</span>
                            )}
                          </div>
                        </div>
                      )
                    ) : (
                      <div key={index} className={"text-center p-3"}>
                        <div className="box">
                          <div className="time">
                            <p>
                              {moment().format("DD-MMM-YYYY") == message
                                ? "Today"
                                : message}
                            </p>
                          </div>
                        </div>
                      </div>
                    )
                  )
                ) : (
                  <div className="text-center">No Messages</div>
                )}
              </div>
            )}
            {showEmoji && (
              <div className="addemoji">
                <EmojiPicker
                  height={"100%"}
                  width={"100%"}
                  onEmojiClick={onEmojiClick}
                  autoFocusSearch={false}
                  Theme="light" //Control light and dark theme(will be used in upcoming milestones)
                  // searchPlaceholder=""
                />
              </div>
            )}
            {showGif && (
              <div className="addemoji">
                <GiphyPicker onSelect={onGifSelect} />
              </div>
            )}
            <div className="write w-100">
              <div className="row align-items-end w-100">
                <div className="col-lg-3 col-sm-3 px-sm-0 mb-2">
                  {messageEditId ? (
                    <div className="d-flex align-items-center opacity-50">
                      <div className="image"></div>
                      <div className="icon-video"></div>
                      <div className="attach"></div>
                      <div className="gif"></div>
                    </div>
                  ) : (
                    <div className="d-flex align-items-center">
                      <div
                        className="image"
                        onClick={() => {
                          try {
                            imgRef?.current?.click();
                          } catch (error) {
                            console.log(error);
                          }
                        }}
                      ></div>
                      <div
                        className="icon-video"
                        onClick={() => {
                          try {
                            vidRef?.current?.click();
                          } catch (error) {
                            console.log(error);
                          }
                        }}
                      ></div>
                      <div
                        className="attach"
                        onClick={() => {
                          try {
                            attachRef?.current?.click();
                          } catch (error) {
                            console.log(error);
                          }
                        }}
                      ></div>
                      <div
                        className="gif"
                        onClick={() => {
                          setShowGif(!showGif);
                          setShowEmoji(false);
                        }}
                      ></div>
                    </div>
                  )}
                </div>
                <div className="col-10 col-sm-7">
                  <div className="reply p-1">
                    {!(isAttachment || isImage || isVideo || isAudio) && (
                      <div className="d-flex align-items-center ps-4">
                        {!isRecording ? (
                          <>
                            <textarea
                              type="text"
                              placeholder="Type your message..."
                              className="form-control"
                              value={textMessage}
                              rows={1}
                              onChange={handleChange}
                              onKeyDown={handleKeyDown}
                              // onKeyUp={(e) =>
                              //   e.key !== "Enter" ? keyup() : false
                              // }
                            />
                            <div
                              className="emojee"
                              onClick={() => {
                                setShowEmoji(!showEmoji);
                                setShowGif(false);
                              }}
                            ></div>
                          </>
                        ) : (
                          <>
                            <div className="w-100">
                              <h5>Recording</h5>
                            </div>
                            <span className="">{`${String(
                              Math.floor(timer / 60)
                            ).padStart(2, "0")}:${String(timer % 60).padStart(
                              2,
                              "0"
                            )}`}</span>
                          </>
                        )}
                        {messageEditId ? (
                          <div
                            className="px-2 fs-6 cursor-pointer"
                            onClick={() => {
                              setMessageEditId(null);
                              setTextMessage("");
                            }}
                          >
                            X
                          </div>
                        ) : isRecording ? (
                          <div
                            className="stop-mic"
                            onClick={() => stopRecording()}
                          ></div>
                        ) : (
                          <div
                            className="mic"
                            onClick={() => startRecording()}
                          ></div>
                        )}
                      </div>
                    )}
                    {(isAttachment || isImage || isVideo || isAudio) &&
                      mainArea}
                  </div>
                </div>
                <div className="col-2 col-sm-2 my-2">
                  <div className="d-flex">
                    <div
                      className="send-icon"
                      onClick={() =>
                        // activeTab == "group"
                        //   ? sendGroupMessage(textMessage) :
                        sendMessage(textMessage)
                      }
                    ></div>
                    <div className="bottom-dropdown">
                      <div
                        className="dot"
                        onClick={() =>
                          setBottomDropDownOpen(!bottomDropDownOpen)
                        }
                      >
                        <span>&bull;</span>
                        <span>&bull;</span>
                        <span>&bull;</span>
                      </div>
                      {bottomDropDownOpen && (
                        <div className="drops-list">
                          <ul>
                            <li>
                              <input
                                id="send-enter"
                                type="radio"
                                name="enter-type"
                                checked={sendType === "enter"}
                                onChange={(e) => setSendType("enter")}
                              />
                              <label for="send-enter">
                                <h5>Press Enter to Send</h5>
                                <p>Press Enter will send message</p>
                              </label>
                            </li>

                            <li>
                              <input
                                id="send-click"
                                type="radio"
                                name="enter-type"
                                checked={sendType === "click"}
                                onChange={(e) => setSendType("click")}
                              />
                              <label for="send-click">
                                <h5>Click Send</h5>
                                <p>Clicking Send will be send message</p>
                              </label>
                            </li>
                          </ul>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <input
                type="file"
                ref={imgRef}
                accept="image/png, image/gif, image/jpeg"
                id="chooseImageFile"
                onChange={(e) => handleMediaChange(e, "image")}
                // onClick={(e) => (e.target.value = "")}
                // multiple
              />
              <input
                type="file"
                accept="video/mp4,video/x-m4v,video/*"
                id="chosefile2"
                ref={vidRef}
                onChange={(e) => handleMediaChange(e, "video")}
                // onClick={(e) => (e.target.value = "")}
              />
              <input
                type="file"
                accept="pdf,ppt,pptx,txt,doc,docx,xls,xlsx"
                id="chosefile3"
                ref={attachRef}
                onChange={(e) => handleMediaChange(e, "attachment")}
                // onClick={(e) => (e.target.value = "")}
              />
            </div>
          </div>
        </div>
        {reportModal && (
          <ReportModal
            modal={reportModal}
            toggle={() => setReportModal(!reportModal)}
            reportType={"conversation"}
            reportTypeId={activeBox?.id}
          />
        )}
        {messageDeleteId && (
          <ConfirmationModal
            modal={Boolean(messageDeleteId)}
            toggle={() => setMessageDeleteId(null)}
            title="Delete Message"
            Confirm={() => onDelete()}
            text="Are you want to sure to delete the message?"
          />
        )}
      </div>
    ) : (
      <div className="message-box-main mt-26 text-center p-5">
        Please start a new conversation.
      </div>
    )
  ) : (
    <NewMessageBox
      setActiveTab={setActiveTab}
      messages={messages}
      setMessages={setMessages}
      xmppService={xmppService}
      chatService={chatService}
    />
  );
}
