import { call } from "./apiCall";
class LandingAPI {

  getLandingData(params) {
    return new Promise((resolve, reject) => {
      (async () => {
        try {
          const res = await call(
            "get",
            `api/v1/get-static-pages`,
            params,
            null,
            null
          );
          resolve(res.data);
        } catch (err) {
          console.log(err);
          reject(err);
        }
      })();
    });
  }
}

export const landingApi = new LandingAPI();