import React from "react";
import { Input } from "reactstrap";
import "../../dashboard.scss";
import EmojiPicker from "emoji-picker-react";
import placeholderUser from "../../../../assets/img/placeholder_user.jpg";
import watchIcon from "../../../../assets/img/Watch-icon.svg";
import DeleteModal from "../../../../core/components/modal/DeleteModal";
import relevant from "../../../../assets/img/relevant.png";
import "./comments.scss";
import CommentData from "./CommentData";

export default function CommentsComp(props) {
  const {
    addComment,
    setAddComment,
    handleChange,
    handleSubmit,
    commentData,
    handleReplySubmit,
    handleReply,
    reply,
    showEmoji,
    setShowEmoji,
    onEmojiClick,
    user,
    handleCommentsReaction,
    loadMoreCommentData,
    commentCount,
    replyBoxStatus,
    setReplyBoxStatus,
    getCommentReply,
    totalComments,
    totalReplies,
    getTime,
    showReplyEmoji,
    setShowReplyEmoji,
    onReplyEmojiClick,
    setReplyId,
    deleteComment,
    deleteReply,
    closeCommentModal,
    setCloseCommentModal,
    commentDeleteId,
    setCommentDeleteId,
    closeReplyModal,
    setCloseReplyModal,
    replyDeleteId,
    setReplyDeleteId,
    dropDownComm,
    setDropDownComm,
    dropDownReply,
    setDropDownReply,
    dropRefComment,
    item,
    userId,
    setPostID,
    reportModal,
    setReportModal,
    reportType,
    setReportType,
    reportCommentId,
    setReportCommentId,
    inputComment,
    shortedBy,
    setShortedBy,
    shortDropDown,
    setShortDropDown,
    isReplyDD,
    setIsReplyDD,
    dropRefReplies,
    pinCommentToTop,
    UnpinComment,
    confirmBlockModal,
    setConfirmBlockModal,
    setBlockCommentId,
    setBlockUserId,
    blockComment,
    getRecentComments,
    getReleventComments,
    type,
    setRepliesCount,
    isPagePosts,
    selectedUserId,
    likeResult,
  } = props;

  return (
    <div className="comments-box-main">
      <div className="thoughts my-4">
        <div className="pro-img">
          {item?.as_a_page ? (
            <img
              src={
                item?.as_a_page?.icon ? item?.as_a_page?.icon : placeholderUser
              }
              className="user-circular-img"
              alt=""
            />
          ) : (
            <img
              src={
                isPagePosts && selectedUserId
                  ? item?.Page?.icon
                  : user?.data?.profile_img
                  ? user?.data?.profile_img
                  : placeholderUser
              }
              alt=""
            />
          )}
        </div>
        <div className="type">
          <Input
            name="addComment"
            value={addComment}
            placeholder="Share your thoughts..."
            onChange={(e) => handleChange(e)}
            onKeyDown={(e) => {
              handleSubmit(e);
            }}
          />
        </div>
        <div
          className="emojee"
          onClick={() => {
            setShowEmoji(!showEmoji);
            setShowReplyEmoji(false);
          }}
          style={{
            position: "absolute",
            right: type === "savedPost" || type === "myPost" ? "0px" : "14px",
            marginTop: "6px",
            paddingLeft: "5px",
          }}
        ></div>
        <div
          className="mt-130"
          style={{
            position: "absolute",
            marginTop: "40px",
            right: "20px",
            zIndex: "1",
          }}
        >
          {showEmoji && (
            <EmojiPicker
              height={350}
              width={400}
              onEmojiClick={onEmojiClick}
              autoFocusSearch={false}
              Theme="light"
            />
          )}
        </div>
      </div>
      {/* relevant/recent */}
      {commentData?.length > 0 && (
        <div className="short row">
          <div
            className="form-select col-3"
            onClick={() => setShortDropDown(!shortDropDown)}
            style={{ cursor: "pointer" }}
          >
            <span>{shortedBy}</span>
          </div>
          {shortDropDown && (
            <div
              className={` ${
                type === "myPost" ||
                type === "savedPost" ||
                type === "scheduledPost" ||
                type === "draftPost"
                  ? "box-network"
                  : "box"
              }`}
              style={{
                boxShadow: "1px 2px 4px #d7d7da",
                marginTop: "25px",
                padding: "5px 15px",
              }}
            >
              <ul id="dropdownBox">
                <li
                  className="listing"
                  onClick={() => {
                    setShortedBy("Most relevant");
                    setShortDropDown(false);
                    getReleventComments();
                  }}
                >
                  <img
                    src={relevant}
                    alt=""
                    style={{ width: "17px", height: "17px", paddingTop: "2px" }}
                  />
                  Most relevant
                </li>
                <li
                  className="listing"
                  onClick={() => {
                    setShortedBy("Most recent");
                    setShortDropDown(false);
                    getRecentComments();
                  }}
                >
                  <img src={watchIcon} alt="" style={{ width: "16px" }} />
                  Most recent
                </li>
              </ul>
            </div>
          )}
        </div>
      )}

      {commentData &&
        commentData?.map((comment, index) => (
          <CommentData
            comment={comment}
            index={index}
            handleReplySubmit={handleReplySubmit}
            handleReply={handleReply}
            reply={reply}
            user={user}
            handleCommentsReaction={handleCommentsReaction}
            replyBoxStatus={replyBoxStatus}
            setReplyBoxStatus={setReplyBoxStatus}
            getCommentReply={getCommentReply}
            getTime={getTime}
            showReplyEmoji={showReplyEmoji}
            setShowReplyEmoji={setShowReplyEmoji}
            onReplyEmojiClick={onReplyEmojiClick}
            setReplyId={setReplyId}
            pinCommentToTop={pinCommentToTop}
            UnpinComment={UnpinComment}
            closeCommentModal={closeCommentModal}
            setCloseCommentModal={setCloseCommentModal}
            setCommentDeleteId={setCommentDeleteId}
            closeReplyModal={closeReplyModal}
            setCloseReplyModal={setCloseReplyModal}
            setReplyDeleteId={setReplyDeleteId}
            dropDownComm={dropDownComm}
            setDropDownComm={setDropDownComm}
            dropDownReply={dropDownReply}
            setDropDownReply={setDropDownReply}
            dropRefComment={dropRefComment}
            item={item}
            userId={userId}
            setPostID={setPostID}
            reportModal={reportModal}
            setReportModal={setReportModal}
            reportType={reportType}
            setReportType={setReportType}
            reportCommentId={reportCommentId}
            setReportCommentId={setReportCommentId}
            isReplyDD={isReplyDD}
            setIsReplyDD={setIsReplyDD}
            dropRefReplies={dropRefReplies}
            confirmBlockModal={confirmBlockModal}
            setConfirmBlockModal={setConfirmBlockModal}
            setBlockCommentId={setBlockCommentId}
            setBlockUserId={setBlockUserId}
            blockComment={blockComment}
            totalReplies={totalReplies}
            type={type}
            setRepliesCount={setRepliesCount}
            setShowEmoji={setShowEmoji}
          />
        ))}
      {totalComments >
        (commentData?.length === undefined ? 0 : commentData?.length) && (
        <p className="load_more" onClick={() => loadMoreCommentData()}>
          <span className="clickDots">&#x2022;</span>
          <span className="clickDots">&#x2022;</span>
          <span className="clickDots">&#x2022;</span>{" "}
          <span>Load More Comments</span>
        </p>
      )}
      {closeCommentModal && (
        <DeleteModal
          modal={DeleteModal}
          toggle={() => setCloseCommentModal(!closeCommentModal)}
          title="Delete Confirmation"
          deleteConfirm={() => deleteComment(commentDeleteId)}
          text="Are you sure to delete this comment?"
        />
      )}

      {closeReplyModal && (
        <DeleteModal
          modal={DeleteModal}
          toggle={() => setCloseReplyModal(!closeReplyModal)}
          title="Delete Confirmation"
          deleteConfirm={() => deleteReply(replyDeleteId, commentDeleteId)}
          text="Are you sure to delete this reply?"
        />
      )}
      {confirmBlockModal && (
        <DeleteModal
          modal={confirmBlockModal}
          toggle={() => setConfirmBlockModal(!confirmBlockModal)}
          title=""
          deleteConfirm={() => blockComment()}
          text={`Would you like to block this user for comment on this post?`}
        />
      )}
    </div>
  );
}
