import React, { useEffect } from "react";
import Layout from "../Layout/Layout";
import union from "../../../assets/img/Union-blue.svg";
import AcceptIcon from "../../../assets/img/AcceptIcon.svg";
import messa from "../../../assets/img/bx_message-detail.svg";
import Loader from "../../../core/components/Loaders/ComponentLoader";
import placeholderUser from "../../../assets/img/placeholder_user.jpg";
import InfiniteScroll from "react-infinite-scroll-component";
import MutualConnectionModal from "../../../core/components/modal/mutualConnection";
import AppLoader from "../../../core/components/Loaders/appLoader/appLoader";
import DeleteModal from "../../../core/components/modal/DeleteModal";
import { Link } from "react-router-dom";

export default function Peoples(props) {
  const {
    pending_invitations,
    acceptRequest,
    suggested_people,
    my_connections,
    setTabs,
    isPageLoading,
    sendRequest,
    navigate,
    searchPeople,
    searchKey,
    setSearchKey,
    currentCount,
    loadMoreData,
    totalCount,
    mutualConnectionModal,
    setMutualConnectionModal,
    userMutualData,
    setUserMutualData,
    ignoreModal,
    setIgnoreModal,
    ignoreId,
    setIgnoreId,
  } = props;

  console.log(totalCount, my_connections?.length);

  //Search on Enter Button

  const handleKeyDown = (e) => {
    // Check if the pressed key is "Enter" (key code 13)
    if (e.key === "Enter") {
      searchPeople(searchKey);
    }
  };

  return (
    <>
      {isPageLoading && (
        <div className="d-flex justify-content-center align-items-center">
          <AppLoader styleProps={{ position: "fixed", left: "60%" }} />
        </div>
      )}
      <Layout>
        <div className="col-lg-9">
          <div className="networks mt-4">
            <div className="main-center">
              <div className="search">
                <input
                  type="text"
                  placeholder="Search People"
                  onChange={(e) => setSearchKey(e.target.value)}
                  onKeyDown={handleKeyDown}
                />
                <div
                  className="search-icon"
                  onClick={() => searchPeople(searchKey)}
                ></div>
              </div>

              <div className="wrapper mt-20">
                <div className="container p-3">
                  <div className="overflow">
                    <ul className="nav nav-tabs" role="tablist">
                      <li className="nav-item">
                        <a
                          className="nav-link active"
                          data-bs-toggle="tab"
                          href="#home"
                          onClick={() => setTabs("suggested_people")}
                        >
                          Suggested people
                        </a>
                      </li>
                      <li className="nav-item">
                        <a
                          className="nav-link"
                          data-bs-toggle="tab"
                          href="#my_connections"
                          onClick={() => {
                            setTabs("my_connections");
                            setSearchKey("");
                            searchPeople("");
                          }}
                        >
                          My Connections
                        </a>
                      </li>
                      <li className="nav-item">
                        <a
                          className="nav-link"
                          data-bs-toggle="tab"
                          href="#pending_invitation"
                          onClick={() => setTabs("pending_invitations")}
                        >
                          Pending invitations (
                          {pending_invitations?.length > 0
                            ? pending_invitations?.length
                            : 0}
                          )
                        </a>
                      </li>
                      <div className="manage-invitation">
                        <Link to="/invitations">Manage Invitations</Link>
                      </div>
                    </ul>
                  </div>

                  <div className="tab-content mt-20">
                    <div id="home" className="container tab-pane active">
                      <div className="suggestion mt-30">
                        <InfiniteScroll
                          dataLength={
                            suggested_people?.length
                              ? suggested_people?.length
                              : 0
                          }
                          next={loadMoreData}
                          hasMore={
                            totalCount === 0
                              ? true
                              : totalCount <= suggested_people?.length
                              ? false
                              : true
                          }
                          style={{ overflow: "visible" }}
                          endMessage={
                            <p
                              style={{ textAlign: "center" }}
                              className="my-30"
                            >
                              <b>List finished!</b>
                            </p>
                          }
                        >
                          <div className="row">
                            <div className="slider-box flex-wrap">
                              {suggested_people?.map((item, index) => (
                                <div
                                  className="card people-card-height"
                                  key={index}
                                >
                                  <div className="rounded-img">
                                    <div
                                      className="profile-img"
                                      onClick={() =>
                                        navigate(`/profile/${item?.id}`)
                                      }
                                    >
                                      <img
                                        src={
                                          item?.profile_img
                                            ? item?.profile_img
                                            : placeholderUser
                                        }
                                        className="image-fit"
                                        alt=""
                                      />
                                    </div>
                                  </div>
                                  <h3
                                    className="card-control-text-oneline mt-1 underlineOnHover"
                                    onClick={() =>
                                      navigate(`/profile/${item?.id}`)
                                    }
                                  >
                                    {item?.first_name} {item?.last_name}
                                  </h3>
                                  {item?.badge == "hiring" && (
                                    <div className="hiring">Hiring</div>
                                  )}
                                  {item?.badge == "seeking" && (
                                    <div className="seeking">Seeking</div>
                                  )}
                                  <div className="designation card-control-text-twoline">
                                    {item?.profile?.profile_headline ? (
                                      item?.profile?.profile_headline
                                    ) : (
                                      <></>
                                    )}
                                  </div>
                                  <div
                                    className="connections"
                                    onClick={() => {
                                      setMutualConnectionModal(
                                        !mutualConnectionModal
                                      );
                                      setUserMutualData(item);
                                    }}
                                  >
                                    {item?.shared_connection?.length}
                                    {item?.shared_connection?.length > 1
                                      ? " mutual connections"
                                      : " mutual connection"}
                                  </div>
                                  {item?.connection_status === "Pending" ? (
                                    <div className="btn btn-light text-blue btn-margin-bottom">
                                      <span>Pending</span>
                                    </div>
                                  ) : (
                                    <div
                                      className="btn btn-light text-blue d-flex gap-1 btn-margin-bottom"
                                      onClick={() => sendRequest(item?.id)}
                                    >
                                      <img src={union} alt="" /> Connect
                                    </div>
                                  )}
                                </div>
                              ))}

                              {/*  ===== DO NOT DELETE THIS HTML, WILL BE USED IN FUTURE HIRINF/SEEKING FUNCTIONALITY ==== */}

                              {/* <div className="card">
                            <div className="rounded-img">
                              <div className="profile-img">
                                <img
                                  src="https://img.freepik.com/free-photo/pleasant-looking-serious-man-stands-profile-has-confident-expression-wears-casual-white-t-shirt_273609-16959.jpg"
                                  className="image-fit"
                                  alt=""
                                />
                              </div>
                            </div>
                            <h3>Reshmika Gabbi</h3>
                            <div className="hiring">Hiring</div>
                            <div className="designation">Flutter developer</div>
                            <div className="connections">
                              3 mutual connections
                            </div>
                            <div className="btn btn-light text-blue d-flex gap-1">
                              <img src={union} /> Connect
                            </div>
                          </div>
                          <div className="card">
                            <div className="rounded-img">
                              <div className="profile-img">
                                <img
                                  src="https://img.freepik.com/free-photo/pleasant-looking-serious-man-stands-profile-has-confident-expression-wears-casual-white-t-shirt_273609-16959.jpg"
                                  className="image-fit"
                                  alt=""
                                />
                              </div>
                            </div>
                            <h3>
                              Reshmika Gabbi<span className="verify"></span>
                            </h3>

                            <div className="designation">Flutter developer</div>
                            <div className="connections mt-3">
                              3 mutual connections
                            </div>
                            <div className="btn btn-light text-blue d-flex gap-1">
                              <img src={messa} />
                              Message
                            </div>
                          </div>
                          <div className="card">
                            <div className="rounded-img">
                              <div className="profile-img">
                                <img
                                  src="https://img.freepik.com/free-photo/pleasant-looking-serious-man-stands-profile-has-confident-expression-wears-casual-white-t-shirt_273609-16959.jpg"
                                  className="image-fit"
                                  alt=""
                                />
                              </div>
                            </div>
                            <h3>Reshmika Gabbi</h3>
                            <div className="seeking">Seeking</div>
                            <div className="designation">Flutter developer</div>
                            <div className="connections">
                              3 mutual connections
                            </div>
                            <div className="btn btn-light text-blue">
                              + Follow
                            </div>
                          </div>
                          <div className="card">
                            <div className="rounded-img">
                              <div className="profile-img">
                                <img
                                  src="https://img.freepik.com/free-photo/pleasant-looking-serious-man-stands-profile-has-confident-expression-wears-casual-white-t-shirt_273609-16959.jpg"
                                  className="image-fit"
                                  alt=""
                                />
                              </div>
                            </div>
                            <h3>Reshmika Gabbi</h3>
                            <div className="hiring">Hiring</div>
                            <div className="designation">Flutter developer</div>
                            <div className="connections">
                              3 mutual connections
                            </div>
                            <div className="btn btn-light text-blue d-flex gap-1">
                              <img src={union} /> Connect
                            </div>
                          </div> */}
                            </div>
                          </div>
                        </InfiniteScroll>
                      </div>
                    </div>
                    <div
                      id="my_connections"
                      className="container tab-pane fade"
                    >
                      <div className="suggestion mt-30">
                        <InfiniteScroll
                          dataLength={
                            my_connections?.length ? my_connections?.length : 0
                          }
                          next={loadMoreData}
                          hasMore={
                            totalCount === 0
                              ? false
                              : totalCount <= my_connections?.length
                              ? false
                              : true
                          }
                          style={{ overflow: "visible" }}
                          loader={
                            my_connections ? (
                              <p>Loading...</p>
                            ) : (
                              <p style={{ textAlign: "center" }}>
                                <b>Nothing found!</b>
                              </p>
                            )
                          }
                          // endMessage={
                          //   <p
                          //     style={{ textAlign: "center" }}
                          //     className="my-30"
                          //   >
                          //     <b>List finished!</b>
                          //   </p>
                          // }
                        >
                          <div className="row">
                            <div className="slider-box flex-wrap">
                              {my_connections?.map((item, index) => (
                                <div
                                  className="card people-card-height"
                                  key={index}
                                >
                                  <div className="rounded-img">
                                    <div
                                      className="profile-img "
                                      onClick={() =>
                                        navigate(`/profile/${item?.user_id}`)
                                      }
                                    >
                                      <img
                                        src={
                                          item?.profile_img
                                            ? item?.profile_img
                                            : placeholderUser
                                        }
                                        className="image-fit"
                                        alt=""
                                      />
                                    </div>
                                  </div>
                                  <h3
                                    className="card-control-text-oneline mt-1 underlineOnHover"
                                    onClick={() =>
                                      navigate(`/profile/${item?.user_id}`)
                                    }
                                  >
                                    {item?.first_name} {item?.last_name}
                                  </h3>
                                  {item?.badge == "hiring" && (
                                    <div className="hiring">Hiring</div>
                                  )}
                                  {item?.badge == "seeking" && (
                                    <div className="seeking">Seeking</div>
                                  )}
                                  <div className="designation card-control-text-twoline">
                                    {item?.profile?.designation ? (
                                      item?.profile?.designation
                                    ) : (
                                      <></>
                                    )}
                                  </div>
                                  <div
                                    className="connections"
                                    onClick={() => {
                                      setMutualConnectionModal(
                                        !mutualConnectionModal
                                      );
                                      setUserMutualData(item);
                                    }}
                                  >
                                    {item?.shared_connection?.length}
                                    {item?.shared_connection?.length > 1
                                      ? " mutual connections"
                                      : " mutual connection"}
                                  </div>
                                  <div className="btn btn-light text-blue d-flex gap-1 btn-margin-bottom">
                                    <img src={union} alt="" /> Message
                                  </div>
                                </div>
                              ))}
                            </div>
                          </div>
                        </InfiniteScroll>
                      </div>
                    </div>
                    <div
                      id="pending_invitation"
                      className="container tab-pane fade"
                    >
                      <div className="suggestion">
                        <div className="row">
                          <div className="slider-box-network flex-wrap">
                            {pending_invitations?.length > 0 ? (
                              pending_invitations?.map((item, index) => (
                                <div
                                  className="row mt-20"
                                  style={{ alignItems: "center" }}
                                  key={index}
                                >
                                  <div className="col-lg-1 col-md-12 mb-2">
                                    <div
                                      className="profile-img"
                                      onClick={() =>
                                        navigate(`/profile/${item?.user_id}`)
                                      }
                                    >
                                      <img
                                        src={
                                          item?.profile_img
                                            ? item?.profile_img
                                            : placeholderUser
                                        }
                                        alt=""
                                        className="image-fit"
                                      />
                                    </div>
                                  </div>
                                  <div className="col-lg-6 col-md-12 mb-3 ">
                                    <div className="details ps-4 ">
                                      <h2
                                        className="card-control-text-oneline mt-1 underlineOnHover"
                                        onClick={() =>
                                          navigate(`/profile/${item?.user_id}`)
                                        }
                                      >
                                        {item?.first_name} {item?.last_name}
                                      </h2>
                                      <p>
                                        {item?.profile?.profile_headline ? (
                                          item?.profile?.profile_headline
                                        ) : (
                                          <></>
                                        )}
                                      </p>
                                      {item?.profile?.state?.name ? (
                                        <p>
                                          {item?.profile?.state?.name},{" "}
                                          {item?.profile?.country?.name}
                                        </p>
                                      ) : (
                                        item?.profile?.country?.name
                                      )}
                                      <p>
                                        {item?.shared_connection?.length > 0 ? (
                                          <>
                                            <div className="people-icon"></div>
                                            <span>
                                              {" "}
                                              {
                                                item?.shared_connection[0]
                                                  ?.first_name
                                              }{" "}
                                              {
                                                item?.shared_connection[0]
                                                  ?.last_name
                                              }{" "}
                                              is a shared connection
                                            </span>
                                          </>
                                        ) : (
                                          <></>
                                        )}
                                      </p>
                                    </div>
                                  </div>
                                  <div className="col-lg-5 col-md-12  ">
                                    <div className="d-flex float-xl-end">
                                      <div
                                        className="btn text-blue"
                                        onClick={() => {
                                          setIgnoreModal(!ignoreModal);
                                          setIgnoreId(item?.id);
                                        }}
                                      >
                                        Ignore
                                      </div>
                                      {console.log("item?.id", item?.id)}
                                      <div
                                        className="btn btn-light"
                                        onClick={() =>
                                          acceptRequest(item?.id, "confirm")
                                        }
                                      >
                                        <img
                                          src={AcceptIcon}
                                          style={{
                                            marginRight: "5px",
                                            paddingBottom: "2px",
                                          }}
                                          alt=""
                                        />
                                        Accept
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              ))
                            ) : (
                              <p style={{ textAlign: "center" }}>
                                <b>No new invitation pending</b>
                              </p>
                            )}

                            {/* {pending_invitations?.map((item, index) => (
                              <div
                                className="row mt-20"
                                style={{ alignItems: "center" }}
                                key={index}
                              >
                                <div className="col-lg-1 col-md-12 mb-2">
                                  <div
                                    className="profile-img"
                                    onClick={() =>
                                      navigate(`/profile/${item?.user_id}`)
                                    }
                                  >
                                    <img
                                      src={
                                        item?.profile_img
                                          ? item?.profile_img
                                          : placeholderUser
                                      }
                                      alt=""
                                      className="image-fit"
                                    />
                                  </div>
                                </div>
                                <div className="col-lg-6 col-md-12 mb-3 ">
                                  <div className="details ps-4 ">
                                    <h2
                                      className="card-control-text-oneline mt-1 underlineOnHover"
                                      onClick={() =>
                                        navigate(`/profile/${item?.user_id}`)
                                      }
                                    >
                                      {item?.first_name} {item?.last_name}
                                    </h2>
                                    <p>
                                      {item?.profile?.profile_headline ? (
                                         item?.profile?.profile_headline
                                      ) : (
                                        <></>
                                      )}
                                    </p>
                                    {item?.profile?.state?.name ? (
                                      <p>
                                        {item?.profile?.state?.name},{" "}
                                        {item?.profile?.country?.name}
                                      </p>
                                    ) : (
                                      item?.profile?.country?.name
                                    )}
                                    <p>
                                      {item?.shared_connection?.length > 0 ? (
                                        <>
                                          <div className="people-icon"></div>
                                          <span>
                                            {" "}
                                            {
                                              item?.shared_connection[0]
                                                ?.first_name
                                            }{" "}
                                            {
                                              item?.shared_connection[0]
                                                ?.last_name
                                            }{" "}
                                            is a shared connection
                                          </span>
                                        </>
                                      ) : (
                                        <></>
                                      )}
                                    </p>
                                  </div>
                                </div>
                                <div className="col-lg-5 col-md-12  ">
                                  <div className="d-flex float-xl-end">
                                    <div
                                      className="btn text-blue"
                                      onClick={() => {
                                        setIgnoreModal(!ignoreModal);
                                        setIgnoreId(item?.id);
                                      }}
                                    >
                                      Ignore
                                    </div>
                                    <div
                                      className="btn btn-light"
                                      onClick={() =>
                                        acceptRequest(item?.id, "confirm")
                                      }
                                    >
                                      Accept
                                    </div>
                                  </div>
                                </div>
                              </div>
                            ))} */}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {mutualConnectionModal && (
          <MutualConnectionModal
            modal={mutualConnectionModal}
            toggle={() => setMutualConnectionModal(!mutualConnectionModal)}
            listData={userMutualData}
          />
        )}
        {ignoreModal && (
          <DeleteModal
            modal={DeleteModal}
            toggle={() => setIgnoreModal(!ignoreModal)}
            title="Confirmation"
            deleteConfirm={() => {
              acceptRequest(ignoreId, "reject");
            }}
            text="Are you want to sure ignore request?"
          />
        )}
      </Layout>
    </>
  );
}
