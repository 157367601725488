import React from "react";
import "../network.scss";
import Layout from "../Layout/Layout";
import moment from "moment";
import CreateArticleModal from "./components/createArticle";
import InfiniteScroll from "react-infinite-scroll-component";
import ArticleSuccessfulModal from "./components/articleSuccessfulModal";
import placeholderUser from "../../../assets/img/placeholder_user.jpg";
import nopreview from "../../../assets/img/no-preview.png";
import { Link } from "react-router-dom";

export default function Article(props) {
  const {
    createModal,
    setCreateModal,
    filterSearchData,
    searchKey,
    setSearchKey,
    updatedArticlesList,
    tabs,
    setTabs,
    readMore,
    newMyArticles,
    currentCount,
    loadMoreData,
    createMarkup,
    successfulStatus,
    popupShareData,
    successModal,
    newDrafts,
    setSuccessModal,
    navigate,
    searchArticle,
    totalCount,
  } = props;

  //Search on Enter Button

  const handleKeyDown = (e) => {
    // Check if the pressed key is "Enter" (key code 13)
    if (e.key === "Enter") {
      filterSearchData(searchKey);
    }
  };

  return (
    <Layout>
      <div className="col-lg-9">
        <div className="main-center mt-4">
          <div className="search">
            <input
              type="text"
              placeholder="Search Articles"
              onChange={(e) => setSearchKey(e.target.value)}
              onKeyDown={handleKeyDown}
            />
            <div
              className="search-icon"
              onClick={() => filterSearchData(searchKey)}
            ></div>
          </div>
          <div className="wrapper mt-20">
            <div className="container p-3">
              <div className="overflow">
                <ul className="nav nav-tabs" role="tablist">
                  <li className="nav-item">
                    <a
                      className="nav-link active"
                      data-bs-toggle="tab"
                      onClick={() => setTabs("suggested_articles")}
                      href="#suggested_articles"
                    >
                      Suggested Articles
                    </a>
                  </li>
                  <li className="nav-item">
                    <a
                      className="nav-link"
                      data-bs-toggle="tab"
                      onClick={() => setTabs("my_articles")}
                      href="#my_articles"
                    >
                      My Articles
                    </a>
                  </li>
                  <li className="nav-item">
                    <div
                      className="end"
                      onClick={() =>
                        navigate("/createarticlenew", {
                          state: {
                            // editData,
                            source: "network",
                          },
                        })
                      }
                    >
                      + Create Article
                    </div>
                  </li>
                </ul>
              </div>

              <div className="tab-content mt-20">
                <div
                  id="suggested_articles"
                  className="container tab-pane active"
                >
                  <InfiniteScroll
                    dataLength={currentCount}
                    next={loadMoreData}
                    hasMore={
                      totalCount === 0
                        ? true
                        : totalCount <= currentCount
                        ? false
                        : true
                    }
                    // loader={<h4>Loading...</h4>}
                    // endMessage={
                    //   <p style={{ textAlign: "center" }}>
                    //     <b>Yay! You have seen it all</b>
                    //   </p>
                    // }
                  >
                    <div className="row">
                      {updatedArticlesList?.length !== 0 ? (
                        updatedArticlesList.map((data) => {
                          return (
                            <div
                              className="col-lg-6 col-md-6 mt-2"
                              key={data.id}
                            >
                              <div className="card overflow-hidden card-height-article">
                                <div className="">
                                  {/* <Link to={`/profile/${data?.user?.user_id}`}> */}
                                  <div
                                    className="img card-image-square"
                                    onClick={() =>
                                      navigate(`/articledetail/${data?.id}`)
                                    }
                                    style={{ cursor: "pointer" }}
                                  >
                                    {data?.media ? (
                                      <img
                                        src={
                                          data?.media ? data?.media : nopreview
                                        }
                                        className="card-image-square"
                                        alt="newsletter-card"
                                      />
                                    ) : (
                                      <img alt="" />
                                    )}
                                  </div>
                                  {/* </Link> */}
                                </div>
                                <div className="card-header">
                                  <div className="d-flex align-items-center gap-3">
                                    <div
                                      className="profile-img"
                                      onClick={() =>
                                        navigate(
                                          `/profile/${data?.user?.user_id}`
                                        )
                                      }
                                    >
                                      {/* <img
                                        src={
                                          data?.user?.profile_img
                                            ? data?.user?.profile_img
                                            : placeholderUser
                                        }
                                        className="image-fit"
                                        alt=""
                                        style={{ width: "36px" }}
                                      /> */}
                                      <img
                                        src={
                                          data?.user?.profile_img
                                            ? data?.user?.profile_img
                                            : placeholderUser
                                        }
                                        className="image-fit"
                                        alt=""
                                        style={{ width: "36px" }}
                                      />
                                    </div>
                                    <span
                                      className="name card-control-text-oneline"
                                      onClick={() =>
                                        navigate(
                                          `/profile/${data?.user?.user_id}`
                                        )
                                      }
                                    >
                                      {data?.user?.first_name}{" "}
                                      {data?.user?.last_name}
                                    </span>
                                  </div>
                                  <div className="date">
                                    <div className="d-flex">
                                      <span className="calender-icon"></span>
                                      <div className="days ml-5">
                                        {" "}
                                        <span>
                                          {moment(data?.createdAt).format(
                                            "ddd, MMM DD, YYYY"
                                          )}{" "}
                                          at{" "}
                                          {moment(data?.createdAt).format(
                                            "hh:mm A z"
                                          )}
                                        </span>
                                      </div>
                                    </div>
                                  </div>
                                  <h3 className="card-text-control">
                                    {data.title}
                                  </h3>
                                </div>
                                <div className="card-body">
                                  <p className="card-text-control">
                                    {data?.content && (
                                      <div
                                        className="card-control-text-twoline"
                                        dangerouslySetInnerHTML={createMarkup(
                                          data?.content
                                        )}
                                      ></div>
                                    )}
                                  </p>
                                  <div
                                    className="read"
                                    onClick={() => {
                                      readMore(data.id);
                                    }}
                                  >
                                    Read article
                                  </div>
                                </div>
                              </div>
                            </div>
                          );
                        })
                      ) : (
                        <div className="row ">
                          <p style={{ textAlign: "center" }} className="my-30">
                            <b> No article published yet.</b>
                          </p>
                        </div>
                      )}
                    </div>
                  </InfiniteScroll>
                </div>
                <div id="my_articles" className="container tab-pane fade">
                  <div className="row mb-6">
                    <div className="col-12">
                      <div
                        className={
                          tabs === "my_articles"
                            ? "btn blue-btn"
                            : "btn border-btn"
                        }
                        onClick={() => setTabs("my_articles")}
                      >
                        My Published Articles
                      </div>
                      <div
                        className={
                          tabs === "my_drafts"
                            ? "btn blue-btn"
                            : "btn border-btn"
                        }
                        onClick={() => setTabs("my_drafts")}
                      >
                        Draft
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    {tabs === "my_articles" ? (
                      newMyArticles?.length !== 0 ? (
                        newMyArticles.map((data) => {
                          return (
                            <div
                              className="col-lg-6 col-md-6 mt-20"
                              key={data.id}
                            >
                              <div className="card  card-height-article overflow-hidden">
                                {/* <Link to={`/profile/${data?.user?.user_id}`}> */}
                                <div
                                  className="img card-image-square"
                                  style={{ cursor: "pointer" }}
                                  onClick={() =>
                                    navigate(`/profile/${data?.user?.user_id}`)
                                  }
                                >
                                  {data?.media ? (
                                    <img
                                      src={
                                        data?.media ? data?.media : nopreview
                                      }
                                      className="card-image-square"
                                      alt="newsletter-card"
                                    />
                                  ) : (
                                    <img alt="" />
                                  )}
                                </div>
                                {/* </Link> */}

                                <div className="card-header">
                                  <div className="d-flex align-items-center gap-3">
                                    <div
                                      className="profile-img"
                                      onClick={() =>
                                        navigate(
                                          `/profile/${data?.user?.user_id}`
                                        )
                                      }
                                    >
                                      <img
                                        src={
                                          data?.user?.profile_img
                                            ? data?.user?.profile_img
                                            : placeholderUser
                                        }
                                        alt=""
                                        className="image-fit"
                                        style={{ width: "36px" }}
                                      />
                                    </div>
                                    <span
                                      className="name card-control-text-oneline"
                                      onClick={() =>
                                        navigate(
                                          `/profile/${data?.user?.user_id}`
                                        )
                                      }
                                    >
                                      {data?.user?.first_name}{" "}
                                      {data?.user?.last_name}
                                    </span>
                                  </div>
                                  <div className="date">
                                    <div className="d-flex">
                                      <span className="calender-icon"></span>
                                      <div className="days ml-5">
                                        {" "}
                                        <span>
                                          {moment(data?.createdAt).format(
                                            "ddd, MMM DD, YYYY"
                                          )}{" "}
                                          at{" "}
                                          {moment(data?.createdAt).format(
                                            "hh:mm A z"
                                          )}
                                        </span>
                                      </div>
                                    </div>
                                  </div>
                                  <h3 className="card-text-control">
                                    {data?.title}
                                  </h3>
                                </div>
                                <div className="card-body">
                                  <p className="card-text-control">
                                    {data?.content && (
                                      <div
                                        className="card-control-text-twoline"
                                        dangerouslySetInnerHTML={createMarkup(
                                          data?.content
                                        )}
                                      ></div>
                                    )}
                                  </p>
                                  <div
                                    className="read"
                                    onClick={() => {
                                      readMore(data?.id);
                                    }}
                                  >
                                    Read article
                                  </div>
                                </div>
                              </div>
                            </div>
                          );
                        })
                      ) : (
                        <div className="row ">
                          <p style={{ textAlign: "center" }} className="my-30">
                            <b> No article published yet.</b>
                          </p>
                        </div>
                      )
                    ) : newDrafts?.length !== 0 ? (
                      newDrafts.map((data) => {
                        return (
                          <div
                            className="col-lg-6 col-md-6 mt-20"
                            key={data.id}
                          >
                            <div className="card  card-height-article overflow-hidden">
                              <div
                                className="img card-image-square"
                                style={{ cursor: "pointer" }}
                                onClick={() =>
                                  navigate(`/profile/${data?.user?.user_id}`)
                                }
                              >
                                <img
                                  src={data?.media ? data?.media : nopreview}
                                  alt=""
                                  className="card-image-square"
                                />
                              </div>
                              <div className="card-header">
                                <div className="d-flex align-items-center gap-3">
                                  <div
                                    className="profile-img"
                                    onClick={() =>
                                      navigate(
                                        `/profile/${data?.user?.user_id}`
                                      )
                                    }
                                  >
                                    <img
                                      src={
                                        data?.user?.profile_img
                                          ? data?.user?.profile_img
                                          : placeholderUser
                                      }
                                      alt=""
                                      className="image-fit"
                                      style={{ width: "36px" }}
                                    />
                                  </div>
                                  <span
                                    className="name card-control-text-oneline"
                                    onClick={() =>
                                      navigate(
                                        `/profile/${data?.user?.user_id}`
                                      )
                                    }
                                  >
                                    {data?.user?.first_name}{" "}
                                    {data?.user?.last_name}
                                  </span>
                                </div>
                                <div className="date">
                                  <div className="d-flex">
                                    <span className="calender-icon"></span>
                                    <div className="days ml-5">
                                      {" "}
                                      <span>
                                        {moment(data?.createdAt).format(
                                          "ddd, MMM DD, YYYY"
                                        )}{" "}
                                        at{" "}
                                        {moment(data?.createdAt).format(
                                          "hh:mm A z"
                                        )}
                                      </span>
                                    </div>
                                  </div>
                                </div>
                                <h3 className="card-text-control">
                                  {data?.title}
                                </h3>
                              </div>
                              <div className="card-body">
                                <p className="card-text-control">
                                  {data?.content && (
                                    <div
                                      className="card-control-text-twoline"
                                      dangerouslySetInnerHTML={createMarkup(
                                        data?.content
                                      )}
                                    ></div>
                                  )}
                                </p>
                                <div
                                  className="read"
                                  onClick={() => {
                                    readMore(data?.id);
                                  }}
                                >
                                  <p>
                                    <b>Read article</b>
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        );
                      })
                    ) : (
                      <div className="row ">
                        <p style={{ textAlign: "center" }} className="my-30">
                          <b> No articles found</b>
                        </p>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {createModal && (
        <CreateArticleModal
          modal={createModal}
          toggle={() => setCreateModal(!createModal)}
          title="Create Article"
          source="network"
          successfulStatus={successfulStatus}
        />
      )}
      {successModal && (
        <ArticleSuccessfulModal
          modal={successModal}
          toggle={() => setSuccessModal(!successModal)}
          title=""
          shareData={popupShareData}
        />
      )}
    </Layout>
  );
}
