import { FAQ_LIST_FAIL, FAQ_LIST_SUCCESS, FAQ_SEARCH_BY_ID_SUCCESS, FAQ_SEARCH_SUCCESS, HELP_SUPPORT_CONTACT, HELP_SUPPORT_CONTACT_FAIL } from "../types";

const initialState = { faq: [], searchList: [], searchById: {} };

const help = (state = initialState, action) => {
  const { type, payload } = action

  switch (type) {

    case HELP_SUPPORT_CONTACT:
      return {
        ...state,
        isSupportAPI: true,
      }

    case HELP_SUPPORT_CONTACT_FAIL:
      return {
        ...state,
        isSupportAPI: false
      }

    case FAQ_LIST_SUCCESS:
      return { ...state, faq: payload.data }

    case FAQ_LIST_FAIL:
      return state;
    
    case FAQ_SEARCH_SUCCESS:
      return { ...state, searchList: payload.data }
    
    case FAQ_SEARCH_BY_ID_SUCCESS:
      return { ...state, searchById: payload.data[0] }

    default:
      return state
  }
}

export default help;