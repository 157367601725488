import React, { useEffect, useState } from "react";
import { Dropdown, FormGroup, Input, Label } from "reactstrap";
import JoinUsSection from "../../../core/components/Sections/JoinUsSection/JoinUsSection";
import uploadBlack from "../../../assets/img/uploadBlack.svg";
import ReactQuill from "react-quill";
import {
  initPageLoad,
  stopPageLoad,
} from "../../../store/loader/loaderActions";
import { updateUserDetails } from "../../../store/profile/profileActions";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import AppLoader from "../../../core/components/Loaders/appLoader/appLoader";

const AddCoverLatter = (props) => {
  const { activePage, setActivePage, activeTab, setActiveTab, profileData } =
    props;
  const token = localStorage.getItem("accessTpToken");
  const { talentPoolData } = useSelector((state) => state.portal);
  const { isPageLoading } = useSelector((state) => state.loader);
  const dispatch = useDispatch();
  const [formData, setFormData] = useState({
    cover_letter_subject: "",
    cover_letter_desc: "",
  });
  useEffect(() => {
    setFormData({
      cover_letter_subject: profileData?.cover_letter_subject || "",
      cover_letter_desc: profileData?.cover_letter_desc || "",
    });
  }, [profileData]);

  const [error, setError] = useState({
    cover_letter_subject: "",
    cover_letter_desc: "",
  });
  const handleChange = (field, value) => {
    setFormData({ ...formData, [field]: value });
  };
  const checkvalidation = (type) => {
    let formIsValid = true;

    if (type === "all" || type === "cover_letter_subject") {
      if (!formData?.cover_letter_subject?.trim()) {
        setError({
          ...error,
          cover_letter_subject: "Please enter the cover letter subject.",
        });
        formIsValid = false;
      } else {
        setError({ ...error, cover_letter_subject: "" });
      }
    }

    if (type === "all" || type === "cover_letter_desc") {
      if (!formData?.cover_letter_desc) {
        setError({
          ...error,
          cover_letter_desc: "Please enter the cover letter description.",
        });
        formIsValid = false;
      } else {
        setError({ ...error, cover_letter_desc: "" });
      }
    }
    return formIsValid;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (checkvalidation("all")) {
      dispatch(initPageLoad());
      dispatch(
        updateUserDetails(
          {
            cover_letter_subject: formData?.cover_letter_subject || "",
            cover_letter_desc: formData?.cover_letter_desc || "",
          },
          token
        )
      )
        .then((res) => {
          if (res?.success)
            toast.success(res?.message, { position: "bottom-left" });
          dispatch(stopPageLoad());
        })
        .catch((err) => dispatch(stopPageLoad()));
    }
  };

  return (
    <>
      {isPageLoading && <AppLoader />}
      <div className="add-cover-section">
        <div className="edit-form">
          <h3>Add Cover Letter</h3>
          <form>
            <div className="title mb-3 mt-3">
              <input
                placeholder="Subject"
                type="text"
                className="form-control"
                value={formData.cover_letter_subject}
                onChange={(e) =>
                  handleChange("cover_letter_subject", e?.target?.value)
                }
                onBlur={() => checkvalidation("cover_letter_subject")}
              />
            </div>
            {error?.cover_letter_subject && (
              <div className="error-message" style={{ color: "red" }}>
                {error?.cover_letter_subject}
              </div>
            )}
            <div class="title mb-3 mt-3">
              {/* <textarea placeholder="Description" class="form-control" /> */}
              <ReactQuill
                placeholder="Description"
                class="form-control"
                style={{ height: "250px" }}
                value={formData.cover_letter_desc}
                onChange={(value) => handleChange("cover_letter_desc", value)}
                onBlur={() => checkvalidation("cover_letter_desc")}
              />
            </div>
            {error?.cover_letter_desc && (
              <div className="error-message mt-5" style={{ color: "red" }}>
                {error?.cover_letter_desc}
              </div>
            )}
          </form>
        </div>

        <div className="save-btn-outer">
          <button
            className="save-btn"
            onClick={(e) => handleSubmit(e)}
            style={{ background: `${talentPoolData?.theme}` }}
          >
            Save
          </button>
        </div>
      </div>
    </>
  );
};

export default AddCoverLatter;
