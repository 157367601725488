import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import { getUnfollowedUserList } from "../../../store/people/peopleActions";

const PeopleYouUnfolloed = (props) => {
  const { modal, toggle } = props;
  const dispatch = useDispatch();
  const { unfollowed_users } = useSelector((state) => state.people);
  useEffect(() => {
    dispatch(getUnfollowedUserList()).then((res) => console.log("res", res));
  }, []);

  console.log("unfollowed_users", unfollowed_users);

  return (
    <div>
      <Modal isOpen={modal} size="lg" style={{ maxWidth: "500px" }}>
        <div className="popup">
          <div className="setting-popup">
            <ModalHeader toggle={toggle} id="account-modal">
              People You Unfollowed
            </ModalHeader>
            <ModalBody>
              <div style={{minHeight:"250px", marginTop:"10px"}}>
                {unfollowed_users?.rows?.map((item, index) => (
                  <div className="d-flex gap-3" key={index}>
                    <img
                      style={{
                        height: "30px",
                        width: "30px",
                        borderRadius: "50%",
                      }}
                      src={item?.following?.profile_img}
                      alt="prof"
                    />
                    <h5>
                      {item?.following?.first_name} {item?.following?.last_name}
                    </h5>
                  </div>
                ))}
              </div>
            </ModalBody>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default PeopleYouUnfolloed;
