export default ({
    control: {
      backgroundColor: '#fff',
    },
  
    input: {
      margin: 0,
    },
  
    '&singleLine': {
      control: {
        display: 'inline-block',
        width: 130,
      },
  
      highlighter: {
        padding: 1,
        border: '2px inset transparent',
      },
  
      input: {
        padding: 1,
  
        border: '2px inset',
      },
    },
  
    '&multiLine': {
      control: {
        border: 'none',
        minHeight: "100px"
      },
  
      highlighter: {
        padding: 9,
      },
  
      input: {
        padding: 9,
        minHeight: 63,
        outline: 0,
        border: 0,
        minHeight: "100px"
    },
    },
  
    suggestions: {
      list: {
        backgroundColor: 'white',
        border: '1px solid rgba(0,0,0,0.15)',
      },
  
      item: {
        padding: '5px 15px',
        borderBottom: '1px solid rgba(0,0,0,0.15)',
        '&focused': {
          color: "white",
          background: 'linear-gradient(120.5deg, #5b2e92 0%, #29409c 50.49%, #0f91d2 100%)',
        },
      },
    },
  })