import { Modal, ModalHeader, ModalBody } from "reactstrap";
import React from "react";

const AlertEditModal = (props) => {
  const { modal, toggle, title, createPost} = props;

  // const closeButton = () => {
  //   toggle();
  // }

  return (
    <Modal
      size="md"
      isOpen={modal}
      style={{ maxWidth: "600px" }}
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <ModalHeader className= "dicardModalHeader" toggle={toggle}>{title}</ModalHeader>
      <ModalBody>
        <div className="event-create-popup">
          <p>Do you want to change/edit the post which may affect the user's previous engagement?
          </p>
          <div className="text-center mt-30">
            <button className="btn btn-light text-blue" onClick={toggle}>
              No
            </button>
            <button className="btn btn-blue ml-2" onClick={createPost}>
              Yes
            </button>
          </div>
        </div>
      </ModalBody>
    </Modal>
  );
};

export default AlertEditModal;
