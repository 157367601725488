import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
} from "reactstrap";
import Dropdown from "../../../../core/components/DropDown/Dropdown";
import { addcoursesdetails } from "../../../../store/profile/profileActions";

const AddCourseModal = (props) => {
  const { modal, toggle, editCourseData, setEditCourseData, experiences } =
    props;
  const [errorMessage, setErrorMessage] = useState("");
  const { isWebView } = useSelector((state) => state.auth);
  const [showLabel, setShowLabel] = useState("");
  const [expData, setExpData] = useState(
    editCourseData
      ? {
          id: editCourseData.id,
          name: editCourseData.name,
          number: editCourseData.number,
          association_id: editCourseData.association_id,
        }
      : {
          name: "",
          number: "",
          association_id: "",
        }
  );
  const dispatch = useDispatch();
  useEffect(() => {
    let filteredLabel =
      experiences &&
      experiences
        ?.map((item) => {
          return Object.values(item)[0].map((item3) => ({
            id: item3.id,
            value: `${item3.title} at ${item3.company_name}`,
          }));
        })
        .flat(2);
    setShowLabel(
      filteredLabel?.filter((item) => item.id === expData?.association_id)[0]
        ?.value
    );
  }, []);

  const handleSubmit = () => {
    if (expData?.name?.trim()) {
      let newData = expData;
      Object.keys(newData).forEach((key) => {
        if (!newData[key]) delete newData[key];
      });
      var token = localStorage.getItem("accessToken");
      let expDataformat = {
        courses: [newData],
      };
      dispatch(addcoursesdetails(expDataformat, token))
        .then(() => {
          if (editCourseData) {
            setEditCourseData();
          }
          toggle();
        })
        .catch((e) => {
          alert(e.response.data.message);
          console.log(e);
        });
    } else setErrorMessage("The field is Mandatory.");
  };

  const CloseModal = () => {
    if (editCourseData) {
      setEditCourseData();
    }
    toggle();
  };

  const handleSelectExperience = (value) => {
    setShowLabel(value.value);
    setExpData({ ...expData, association_id: value.id });
  };
  return (
    <div>
      <Modal
        isOpen={modal}
        size="lg"
        className="fix-width gradient-background"
        centered
      >
        <div className="popup">
          <div className="add-expriece">
            <ModalHeader toggle={CloseModal} className="">
              {editCourseData ? "Edit Course" : "Add Course"}
            </ModalHeader>
            <ModalBody>
              <p className="mb-3">* Indicates Required</p>
              <form>
                <div className="title">
                  <label>Course name*</label>
                  <Input
                    type="text"
                    placeholder=""
                    maxLength="50"
                    value={expData.name}
                    onClick={(e) => setErrorMessage("")}
                    onChange={(e) =>
                      setExpData({ ...expData, name: e.target.value })
                    }
                  />
                  {errorMessage && (
                    <label className="label-input100 text-danger">
                      {errorMessage}
                    </label>
                  )}
                </div>
                <div className="title">
                  <label>Number</label>
                  <Input
                    type="text"
                    maxLength="50"
                    placeholder=""
                    value={expData.number}
                    onChange={(e) =>
                      setExpData({ ...expData, number: e.target.value })
                    }
                  />
                </div>
                <div className="title">
                  <FormGroup>
                    <Label for="associatedwith">Associated with</Label>
                    {/* {isWebView ? <Dropdown
                      label='Please select'
                      data={experiences && experiences?.map(
                        (item) => {
                          return Object.values(item).map(
                            (item2) => {
                              return item2.map((item3) => ({
                                id: item3.id,
                                value: `${item3.title} at ${item3.company_name}`
                              }))
                            }
                          )
                        }
                      ).flat(2)}
                      dynamicPlaceHolder={showLabel}
                      dropdownValue='association_id'
                      handleSelect={handleSelectExperience}
                    />
                      : */}
                    <Input
                      id="associatedwith"
                      name="select"
                      type="select"
                      style={{ color: "#676A79" }}
                      value={expData.association_id}
                      onChange={(e) =>
                        setExpData({
                          ...expData,
                          association_id: e.target.value,
                        })
                      }
                    >
                      <option value="">Please Select</option>
                      {experiences?.map((item, index) => {
                        return Object.values(item)[0].map((item3, index) => {
                          return (
                            <option key={index} value={item3.id}>
                              {item3.title} at {item3.company_name}
                            </option>
                          );
                        });
                      })}
                    </Input>
                    {/* } */}
                  </FormGroup>
                </div>
                <div className="text-center">
                  <div
                    className={
                      isWebView ? "btnWebView btn-blue" : "btn btn-blue"
                    }
                    style={{ marginTop: "15px" }}
                    onClick={handleSubmit}
                  >
                    Done
                  </div>
                </div>
              </form>
            </ModalBody>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default AddCourseModal;
