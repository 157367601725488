import {
  GET_SUGGESTED_EVENTS,
  PIN_EVENTS,
  UNPIN_EVENTS,
  GET_PINNED_EVENTS,
  GET_MY_EVENTS,
  GET_MY_DRAFTS,
  CREATE_MY_EVENT,
  EDIT_MY_EVENT,
  GET_HISTORY_EVENTS,
  GET_EVENT_DETAIL,
  GET_UPCOMING_EVENTS,
  ATTEND_EVENTS,
  UNATTEND_EVENTS,
  EDIT_MY_EVENT_DETAILS,
  DRAFT_MY_EVENT,
  GET_PAGE_EVENTS,
  CANCEL_EVENT,
  PIN_EVENTS_RELATED,
  EDIT_PAGE_EVENTS,
} from "../types";

export const initialState = {
  suggested_events: [],
  upcoming_events: [],
  pinned_events: [],
  history_events: [],
  my_events: [],
  event_detail: [],
  my_drafts: [],
  page_events: [],
};

const events = (state = initialState, action) => {
  switch (action.type) {
    case GET_SUGGESTED_EVENTS:
      if (action.payload.currentCount > 0) {
        return {
          ...state,
          suggested_events: [
            ...state.suggested_events,
            ...action.payload.events.data,
          ],
        };
      } else return { ...state, suggested_events: action.payload.events.data };

    case GET_PINNED_EVENTS:
      if (action.payload.currentCount > 0) {
        return {
          ...state,
          pinned_events: [
            ...state.pinned_events,
            ...action.payload.events.data,
          ],
        };
      } else return { ...state, pinned_events: action.payload.events.data };

    case GET_UPCOMING_EVENTS:
      if (action.payload.currentCount > 0) {
        return {
          ...state,
          upcoming_events: [
            ...state.upcoming_events,
            ...action.payload.events.data,
          ],
        };
      } else return { ...state, upcoming_events: action.payload.events.data };

    case GET_HISTORY_EVENTS:
      if (action.payload.currentCount > 0) {
        return {
          ...state,
          history_events: [
            ...state.history_events,
            ...action.payload.events.data,
          ],
        };
      } else return { ...state, history_events: action.payload.events.data };

    case GET_MY_EVENTS:
      if (action.payload.currentCount > 0) {
        return {
          ...state,
          my_events: [...state.my_events, ...action.payload.events.data],
        };
      } else return { ...state, my_events: action.payload.events.data };
    case GET_PAGE_EVENTS:
      if (action.payload.currentCount > 0) {
        return {
          ...state,
          page_events: [...state.page_events, ...action.payload?.data],
        };
      } else return { ...state, page_events: action.payload?.data };

    case GET_MY_DRAFTS:
      if (action.payload.currentCount > 0) {
        return {
          ...state,
          my_drafts: [...state.my_drafts, ...action.payload.events.data],
        };
      } else return { ...state, my_drafts: action.payload.events.data };

    case CREATE_MY_EVENT:
      return { ...state, my_events: [action.payload, ...state.my_events] };

    case DRAFT_MY_EVENT:
      return { ...state, my_drafts: [action.payload, ...state.my_drafts] };

    case EDIT_MY_EVENT:
      return {
        ...state,
        my_events: state.my_events.map((item) =>
          item.id === action.payload.id ? { ...item, ...action.payload } : item
        ),
        my_drafts: state.my_drafts.filter(
          (item) => item.id !== action.payload.id
        ),
      };
    case EDIT_PAGE_EVENTS:
      return {
        ...state,
        page_events: state.page_events.map((item) =>
          item.id === action.payload.id ? { ...item, ...action.payload } : item
        ),
      };

    case ATTEND_EVENTS:
      return {
        ...state,
        suggested_events: state.suggested_events.map((item) =>
          item.id === action.payload
            ? { ...item, Attend_Event: { status: true } }
            : item
        ),
        page_events: state.page_events.map((item) =>
          item.id === action.payload
            ? { ...item, Attend_Event: { status: true } }
            : item
        ),
        upcoming_events: state.upcoming_events.map((item) =>
          item.id === action.payload
            ? { ...item, Attend_Event: { status: true } }
            : item
        ),
        pinned_events: state.pinned_events.map((item) =>
          item.id === action.payload
            ? { ...item, Attend_Event: { status: true } }
            : item
        ),
        event_detail: state.event_detail.map((item) =>
          item.id === action.payload
            ? { ...item, Attend_Event: { status: true } }
            : item
        ),
      };

    case UNATTEND_EVENTS:
      return {
        ...state,
        suggested_events: state.suggested_events.map((item) =>
          item.id === action.payload
            ? { ...item, Attend_Event: { status: false } }
            : item
        ),
        page_events: state.page_events.map((item) =>
          item.id === action.payload
            ? { ...item, Attend_Event: { status: false } }
            : item
        ),
        upcoming_events: state.upcoming_events.map((item) =>
          item.id === action.payload
            ? { ...item, Attend_Event: { status: false } }
            : item
        ),
        pinned_events: state.pinned_events.map((item) =>
          item.id === action.payload
            ? { ...item, Attend_Event: { status: false } }
            : item
        ),
        event_detail: state.event_detail.map((item) =>
          item.id === action.payload
            ? { ...item, Attend_Event: { status: false } }
            : item
        ),
      };
    case GET_EVENT_DETAIL:
      return { ...state, event_detail: action.payload };

    case EDIT_MY_EVENT_DETAILS:
      return { ...state, event_detail: [action.payload] };

    case PIN_EVENTS:
      return {
        ...state,
        suggested_events: state.suggested_events.map((item) =>
          item.id === action.payload
            ? { ...item, Pinned_Event: { status: true } }
            : item
        ),
        page_events: state.page_events.map((item) =>
          item.id === action.payload
            ? { ...item, Pinned_Event: { status: true } }
            : item
        ),
        upcoming_events: state.upcoming_events.map((item) =>
          item.id === action.payload
            ? { ...item, Pinned_Event: { status: true } }
            : item
        ),
        pinned_events: state.pinned_events.map((item) =>
          item.id === action.payload
            ? { ...item, Pinned_Event: { status: true } }
            : item
        ),
      };

    case UNPIN_EVENTS:
      return {
        ...state,
        suggested_events: state.suggested_events.map((item) =>
          item.id === action.payload
            ? { ...item, Pinned_Event: { status: false } }
            : item
        ),
        page_events: state.page_events.map((item) =>
          item.id === action.payload
            ? { ...item, Pinned_Event: { status: false } }
            : item
        ),
        upcoming_events: state.upcoming_events.map((item) =>
          item.id === action.payload
            ? { ...item, Pinned_Event: { status: false } }
            : item
        ),
        pinned_events: state.pinned_events.map((item) =>
          item.id === action.payload
            ? { ...item, Pinned_Event: { status: false } }
            : item
        ),
      };

    case CANCEL_EVENT:
      return {
        ...state,
        event_detail: state.event_detail.map((item) =>
          item.id === action.payload
            ? { ...item, event_status: "cancelled" }
            : item
        ),
      };

    case PIN_EVENTS_RELATED:
      return {
        ...state,
        event_detail: state.event_detail.map((item) =>
          item.id === action.payload.eid
            ? {
                ...item,
                Related_Events: item.Related_Events.map((item) =>
                  action.payload.type === "pinTrue"
                    ? item.id === action.payload.id
                      ? { ...item, Pinned_Event: { status: true } }
                      : item
                    : item.id === action.payload.id
                    ? { ...item, Pinned_Event: { status: false } }
                    : item
                ),
              }
            : item
        ),
      };

    default:
      return state;
  }
};

export default events;
