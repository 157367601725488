import React from "react";
import fri from "../../../../assets/img/refer.svg";
import { Modal, ModalHeader, ModalBody } from "reactstrap";
import { useState } from "react";
import { sendReferLink } from "../../../../store/wallet/walletActions";
import { useDispatch, useSelector } from "react-redux";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { WEB_URL } from "../../../../config";
import { get_refer_info } from "../../../../store/wallet/walletActions";
import { FaCopy } from "react-icons/fa";
import rectangleImage from "../../../../assets/img/RectanglepayBycard.png";
import copyIconRef from "../../../../assets/img/copyIconRef.png";

export default function Refer() {
  const dispatch = useDispatch();

  const [referModal, setReferModal] = useState(false);
  const [emailName, setEmailName] = useState("");
  const [errorEmail, setErrorEmail] = useState("");
  const [selected, setSelected] = useState([]);
  const [existingEmails, setExistingEmails] = useState([]);

  const { user } = useSelector((state) => state.auth);

  const checkEmailValidation = () => {
    let formIsValid = true;
    let emailValid =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    if (!emailName) {
      setErrorEmail("Please enter your email.");
      formIsValid = false;
    } else if (!emailValid.test(emailName)) {
      setErrorEmail(" Please provide a valid email address format.");
      formIsValid = false;
    } else if (existingEmails.includes(emailName)) {
      setErrorEmail("This email is already mentioned.");
      formIsValid = false;
    } else {
      setErrorEmail("");
    }

    return formIsValid;
  };

  const selectItem = (item) => {
    if (checkEmailValidation()) {
      if (selected.length < 5) {
        setSelected((selected) => [...selected, item]);
        setExistingEmails((existingEmails) => [...existingEmails, item]);
        setEmailName("");
      } else {
        setErrorEmail("You can't add more than 5 emails at a time!");
        return;
      }
    }
  };
  const removeItem = (item) => {
    setSelected((current) => current.filter((selected) => selected !== item));
    setExistingEmails((existing) => existing.filter((email) => email !== item));
  };

  const handleSubmit = () => {
    let data = {
      email: selected,
      type: "invitation",
      link: `${WEB_URL}signup?referId=${user?.data?.id}`,
    };
    dispatch(sendReferLink(data)).then((response) => {
      setEmailName("");
      toast.success("Refer Link sent successfully!", {
        position: toast.POSITION.BOTTOM_LEFT,
      });
      setReferModal(false);
      setSelected([]);
      setErrorEmail("");
      setExistingEmails([]);
    });
  };

  //Method To add Copy link functionality
  const copyLink = () => {
    const referLink = `${WEB_URL}signup?referId=${user?.data?.id}`;

    // Create a temporary textarea element
    const textarea = document.createElement("textarea");
    textarea.value = referLink;

    // Append the textarea to the document
    document.body.appendChild(textarea);

    // Select the text in the textarea
    textarea.select();
    textarea.setSelectionRange(0, 99999); // For mobile devices

    // Execute the copy command
    document.execCommand("copy");

    // Remove the temporary textarea from the document
    document.body.removeChild(textarea);

    // Optionally, provide feedback to the user (e.g., show a message)
    toast.success("Link copied to clipboard!", {
      position: toast.POSITION.BOTTOM_LEFT,
    });
  };

  return (
    <>
      <div className="sidebar-refer">
        <div className="box">
          <div className="p-3">
            <div className="friend">
              <img src={fri} alt="" style={{ marginLeft: "-4px" }} />
              <div
                className="refer"
                style={{
                  backgroundColor: "white",
                  borderRadius: "15px",
                  padding: "10px",
                }}
              >
                <strong>Refer 5 </strong> Friends
              </div>
            </div>
            <div className="content-text">
              <div
                className="btn btn-light mt-1 text-blue d-block"
                style={{ fontSize: "15px", background: "white" }}
                onClick={() => {
                  setReferModal(!referModal);
                }}
              >
                Get Free Membership
              </div>
            </div>
          </div>
        </div>
      </div>
      {referModal && (
        <Modal
          size="md"
          isOpen={referModal}
          style={{ maxWidth: "600px" }}
          aria-labelledby="contained-modal-title-vcenter"
          centered
          className="gradient-background"
        >
          <ModalHeader
            className="dicardModalHeader"
            // style={{
            //   backgroundImage: `url(${rectangleImage})`,
            //   backgroundRepeat: "no-repeat",
            //   backgroundSize: "cover",
            //   color: "white",
            // }}
            toggle={() => setReferModal(!referModal)}
          >
            Refer a friend
          </ModalHeader>
          <ModalBody style={{ padding: "40px 10px 20px 14px " }}>
            <div className="event-create-popup">
              <div className="ml-30">
                <span>Refer Link</span> -{" "}
                <span className="blue-color" style={{ marginRight: "10px" }}>
                  {WEB_URL}signup?referId={user?.data?.id}
                </span>
                <img
                  src={copyIconRef}
                  alt=""
                  className="copy-icon"
                  size={20}
                  style={{
                    color: "#0432a3",
                    cursor: "pointer",
                    width: "15px",
                    height: "19px",
                  }}
                  onClick={copyLink}
                />
              </div>
              <div className="text-center mt-30 ml-30 mr-30 d-flex">
                <input
                  className="form-control form-input-input"
                  placeholder="Enter Email"
                  type="text"
                  onChange={(e) => {
                    setEmailName(e.target.value);
                    setErrorEmail("");
                  }}
                  value={emailName}
                />
                <button
                  style={{ padding: "10px 15px" }}
                  className="btn btn-blue ml-2"
                  onClick={() => selectItem(emailName)}
                >
                  +
                </button>
              </div>
              {errorEmail && (
                <label className="label-input100 text-danger ml-30">
                  {errorEmail}
                </label>
              )}
              <div className=" row mt-30 ml-30 mr-30 selectRecptRow">
                {selected.length > 0 &&
                  selected.map((item, index) => {
                    return (
                      <div
                        key={index}
                        className="col col-sm-6 col-md-4 col-lg-4 col-xl-4"
                      >
                        <div className="selectedReceipient">
                          <p
                            className="card-control-text-oneline"
                            style={{ color: "#0432A3" }}
                          >
                            {item}
                          </p>
                          <p
                            onClick={() => removeItem(item)}
                            className="curserPointer mt-0"
                          >
                            X
                          </p>
                        </div>
                      </div>
                    );
                  })}
              </div>
              <div>
                {/* <button
                  className="btn btn-blue ml-2"
                  onClick={() => handleSubmit()}
                >
                  Send
                </button> */}
                <div
                  className="text-center mt-30 ml-30 mr-30 d-flex"
                  style={{ float: "right" }}
                >
                  <button
                    style={{ width: "88px" }}
                    className="btn btn-blue"
                    onClick={() => handleSubmit()}
                  >
                    Send
                  </button>
                </div>
              </div>
            </div>
          </ModalBody>
          <div style={{ padding: "5px" }}></div>
        </Modal>
      )}
      <ToastContainer />
    </>
  );
}
