import Navbar from "../../../pages/navbar/navbar";
import Footer from "../../../core/components/layout/footer/Footer";
import NetworkSidebar from "../../mynetwork/Layout/NetworkSidebar";
import "./Layout.scss";
import Chat from "../../../pages/chat/chat";
import WalletSidebar from "./WalletSidebar";

const Layout = ({ children }) => {
  return (
    <>
      <div>
        <Navbar isJobs={false}/>
        <div className="bg-grey">
          <div className="wallet">
            <div className="container">
              <div className="row">
                <div className="col-lg-3">
                  <div className="layout-view mt-4">
                    <WalletSidebar />
                  </div>
                </div>
                {children}
              </div>
              <div className="web-footer-bottom">
                <Footer />
              </div>
            </div>
          </div>
        </div>
        <Chat />
      </div>
    </>
  );
};

export default Layout;
