import React, { useEffect, useState } from "react";
import Navbar from "../navbar/navbar";
import "./applicationnobel.scss";
import shopping from "../../assets/img/shopping-list.svg";
import close from "../../assets/img/close.svg";
import ApplicantCard from "./ApplicantCard";
import { call } from "../../api/apiCall";
import { Input } from "reactstrap";
import { useDispatch, useSelector } from "react-redux";
import {
  getApplicantsDetails,
  getDetailApplicantId,
  getJobsDetail,
  jobApplicantViewlog,
  updateApplicant,
} from "../../store/jobs/jobAction";
import { useNavigate, useParams } from "react-router-dom";
import Autocomplete from "react-autocomplete";
import { getLocationsData } from "../../store/connections/connectionsActions";
import InfiniteScroll from "react-infinite-scroll-component";
import AppLoader from "../../core/components/Loaders/appLoader/appLoader";
import { ToastContainer, toast } from "react-toastify";
import { GetTime } from "../../core/components/Utility/Utility";
import placeholderUser from "../../assets/img/placeholder_user.jpg";
import pagePlaceholder from "../../assets/img/page-placeholder.svg";
import confidential from "../../assets/img/confidentialIcon.svg";
export const ApplicationNobel = () => {
  const dispatch = useDispatch();

  const params = useParams();
  const navigate = useNavigate();
  const { getAJob, getApplicantsDetail, getApplicantsById } = useSelector(
    (state) => state.jobs
  );
  const { user } = useSelector((state) => state.auth);

  const [locationOpen, setLocationOpen] = useState(false);
  const [searchFieldValue, setSearchFieldValue] = useState("");
  const [locationsAPIData, setLocationsAPIData] = useState([]);
  const [pageDetails, setPageDetails] = useState({
    location: searchFieldValue,
  });

  const [applicants, setApplicants] = useState();
  const [hasMore, setHasMore] = useState(true);
  const [skip, setSkip] = useState(0);
  const limit = 10;
  const sortOptions = [
    { value: "asc", label: "Ascending" },
    { value: "desc", label: "Descending" },
  ];
  const experienceOptions = [
    { value: "0", label: "Experience" },
    { value: "1", label: "1 Year" },
    { value: "2", label: "2 Years" },
    { value: "3", label: "3 Years" },
    { value: "4", label: "4 Years" },
    { value: "5", label: "5 Years" },
    { value: "6", label: "6 Years" },
    { value: "7", label: "7 Years" },
    { value: "8", label: "8 Years" },
    { value: "9", label: "9 Years" },
    { value: "10", label: "10 Years" },
    { value: "11", label: "11 Years" },
    { value: "12", label: "12 Years" },
    { value: "13", label: "13 Years" },
    { value: "14", label: "14 Years" },
    { value: "15", label: "15 Years" },
    { value: "16", label: "16 Years" },
  ];

  const [selectedApplicant, setSelectedApplicant] = useState(
    getApplicantsDetail?.[0] || null
  );
  const [degreeList, setDegreeList] = useState("");
  const [expData, setExpData] = useState({
    sort_by: "",
    degree: "",
    expereience: "",
  });

  useEffect(() => {
    console.log("applicant_idapplicant_id");
    if (selectedApplicant) {
      dispatch(getDetailApplicantId(selectedApplicant.id));
      dispatch(jobApplicantViewlog(selectedApplicant.id, params?.id));
    }
  }, [selectedApplicant]);
  const handleScroll = () => {
    if (!hasMore) {
      return;
    }

    dispatch(
      getApplicantsDetails({
        job_id: params?.id,
        education: expData.degree || undefined,
        location: searchFieldValue || undefined,
        sort: expData.sort_by || undefined,
        experience: expData.expereience || undefined,
        limit,
        skip: skip + limit,
      })
    ).then((response) => {
      const newApplicants = response.data;

      if (newApplicants.length > 0) {
        setApplicants((prevApplicants) => [
          ...prevApplicants,
          ...newApplicants,
        ]);
        setSkip(skip + limit);
      } else {
        // If no more applicants are returned from the API, set hasMore to false
        setHasMore(false);
      }
    });
  };

  // Assuming getApplicantsById is an object with the provided data

  // Extracting cover letter information
  const coverLetterSubject = getApplicantsById?.cover_letter_subject || "";
  const coverLetterDescription = getApplicantsById?.cover_letter_desc || "";

  // Extracting apply_answers information
  const applyAnswers = getApplicantsById?.apply_answers || [];
  const questionAndAnswers = applyAnswers.map((answer) => {
    const question = answer?.question?.question || "";
    const answerValue = answer?.answer || "";
    return { question, answer: answerValue };
  });

  // Now you can use coverLetterSubject, coverLetterDescription, and questionAndAnswers as needed

  useEffect(() => {
    if (getApplicantsDetail) {
      setApplicants(getApplicantsDetail);
      setSelectedApplicant(getApplicantsDetail?.[0]);
    }
  }, [getApplicantsDetail]);

  const getLocations = ({ value }) => {
    if (value)
      dispatch(getLocationsData(value))
        .then((response) => {
          if (response.data) {
            setLocationsAPIData(response.data);
          } else setLocationsAPIData([]);
        })
        .catch((err) => {
          setLocationsAPIData([]);
        });
    else setLocationsAPIData([]);
  };

  useEffect(() => {
    dispatch(
      getApplicantsDetails({
        job_id: params?.id,
        education: expData.degree || undefined,
        location: searchFieldValue || undefined,
        sort: expData.sort_by || undefined,
        experience: expData.expereience || undefined,
      })
    );
  }, [expData, searchFieldValue]);

  useEffect(() => {
    dispatch(getJobsDetail(params?.id));
  }, []);

  useEffect(() => {
    (async () => {
      try {
        const res = await call("get", "api/v1/degree", null, null);
        setDegreeList(res.data);
      } catch (err) {
        console.log(err);
      }
    })();
  }, []);

  //   const handleApplicantClick = (applicant) => {
  //     setSelectedApplicant(applicant);
  //   };

  const handleShoppingClick = () => {
    dispatch(updateApplicant({ status: "shortlisted" }, selectedApplicant?.id))
      .then((response) => {
        toast.success("Shortlisted successfully", {
          position: toast.POSITION.BOTTOM_LEFT,
        });
      })
      .catch((error) => {
        console.error("Error", error);
      });
  };

  const handleCloseClick = () => {
    dispatch(updateApplicant({ status: "rejected" }, selectedApplicant?.id))
      .then((response) => {
        toast.success("Rejected successfully", {
          position: toast.POSITION.BOTTOM_LEFT,
        });
      })
      .catch((error) => {
        console.error("Error", error);
      });
  };

  const downloadResume = (resumeUrl) => {
    if (resumeUrl) {
      const link = document.createElement("a");
      link.href = resumeUrl;
      link.target = "_blank";
      link.download = "resume.pdf"; // Set the desired file name
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } else {
      console.error("No resume available for download");
    }
  };

  return (
    <div>
      <Navbar isJobs={false} />
      <div className="main-bg">
        <div className="application-nobel pt-26">
          <div className="container">
            <div className="bg-white p-20 rounded-1">
              <div className="row">
                <div className="col-lg-6">
                  <div className="top applicationnobal_detail">
                    <div className="dropdown">
                      <Input
                        id="exampleSelect"
                        name="select"
                        type="select"
                        className="asc-select border-0"
                        value={expData.sort_by}
                        onChange={(e) =>
                          setExpData({
                            ...expData,
                            sort_by: e.target.value,
                          })
                        }
                      >
                        <option value="">Sort By</option>

                        {sortOptions.map((option) => (
                          <option key={option.value} value={option.value}>
                            {option.label}
                          </option>
                        ))}
                      </Input>
                    </div>

                    <div className="col-md-3  autocomplete-wrapper pe-0">
                      <div className="application-search">
                        <div className="search">
                          <Autocomplete
                            open={locationOpen}
                            inputProps={{ placeholder: "Location" }}
                            value={searchFieldValue}
                            items={locationsAPIData}
                            getItemValue={(item) => item.name}
                            shouldItemRender={() => locationsAPIData}
                            renderMenu={(items, value) => (
                              <div className="dropdown">
                                {items.length === 0
                                  ? `No matches for ${value}`
                                  : items}
                              </div>
                            )}
                            renderItem={(item, isHighlighted) => (
                              <div
                                className={`item ${
                                  isHighlighted ? "selected-item" : ""
                                }`}
                              >
                                {item.name}
                              </div>
                            )}
                            onChange={(e, val) => {
                              if (val.length === 0) {
                                if (locationOpen) setLocationOpen(false);
                              } else {
                                if (!locationOpen) setLocationOpen(true);
                              }
                              setSearchFieldValue(e.target.value);
                              getLocations(e.target);
                            }}
                            onSelect={(value, obj) => {
                              setSearchFieldValue(value);
                              setPageDetails({
                                ...pageDetails,
                                location: obj.name,
                              });
                              setLocationOpen(false);
                            }}
                            wrapperStyle={{}}
                          />
                        </div>
                      </div>
                    </div>

                    {/* </div> */}
                    <div className="dropdown">
                      <Input
                        value={expData.expereience}
                        id="exampleSelect"
                        name="select"
                        type="select"
                        className=" border-0"
                        onChange={(e) =>
                          setExpData({
                            ...expData,
                            expereience: e.target.value,
                          })
                        }
                      >
                        {experienceOptions.map((option, index) => (
                          <option key={index} value={option.value}>
                            {option.label}
                          </option>
                        ))}
                      </Input>
                    </div>
                    <div className="dropdown">
                      <Input
                        value={expData.degree}
                        id="exampleSelect"
                        name="select"
                        className=" border-0"
                        type="select"
                        onChange={(e) =>
                          setExpData({
                            ...expData,
                            degree: e.target.value,
                          })
                        }
                      >
                        <option value="">Education</option>
                        {degreeList?.data?.map((item, index) => {
                          return (
                            <option key={index} value={item.name}>
                              {item.name}
                            </option>
                          );
                        })}
                      </Input>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 d-flex align-items-center justify-content-end">
                  <div className="float-lg-end input">
                    <ul className="d-lg-flex gap-5 mt-lg-0 mt-md-2">
                      <li
                        className="opacity0.2"
                        onClick={() => {
                          navigate("/shortlist/candidates", {
                            state: { jobId: params?.id, tab: "shotlisted" },
                          });
                        }}
                      >
                        Shortlisted Candidate
                      </li>
                      {/* <li
                        className="opacity0.2"
                        onClick={() => {
                          // alert(
                          //   "This feature will implement in upcomming milestone"
                          // );
                          navigate("/shortlist/candidates", {
                            state: { jobId: params?.id, tab: "viewCandidate" },
                          });
                        }}
                      >
                        View Candidate222
                      </li>
                      <li
                        className="opacity0.2"
                        onClick={() => {
                          // alert(
                          //   "This feature will implement in upcomming milestone"
                          // );
                          navigate("/shortlist/candidates", {
                            state: {
                              jobId: params?.id,
                              tab: "viewSmartInterview",
                            },
                          });
                        }}
                      >
                        View Smart Interview
                      </li> */}
                      {/* <li
                        className="opacity0.2"
                        style={{ marginLeft: "164px", color: "darkblue" }}
                        onClick={() => {
                          navigate("/myjobs", {
                            state: { jobId: params?.id, tab: "shotlisted" },
                          });
                        }}
                      >
                        Back to my job page
                      </li> */}
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="mt-26">
            <div className="container">
              <div className="row">
                <div className="col-lg-5">
                  <div
                    className="bg-white rounded-1 "
                    style={{
                      // border: "1px rgba(209, 223, 255, 1) solid",
                      boxShadow: "0px 0px 0px 1px rgba(0,0,0,0.1)",
                    }}
                  >
                    <div className="header p-30">
                      <h2>
                        {getAJob?.applied_count}{" "}
                        {getAJob?.applied_count > 1
                          ? "applicants"
                          : "applicant"}
                      </h2>
                      {getAJob?.user?.id === user?.data?.id ? (
                        <div
                          className="d-flex justify-content-between align-items-center"
                          onClick={() =>
                            navigate("/createcampaign", {
                              state: {
                                isFeatured: true,
                                jobId: getAJob?.id,
                              },
                            })
                          }
                        >
                          <p>Get 3x more qualified applicants</p>
                          <span className="promote">Promote job</span>
                        </div>
                      ) : (
                        <></>
                      )}
                    </div>

                    <div className="line">
                      {/* <InfiniteScroll
                                                dataLength={applicants?.length || 0}
                                                next={handleScroll}
                                                hasMore={hasMore}
                                                loader={<AppLoader />}
                                                endMessage={<p>No more applicants</p>}
                                            > */}
                      {applicants?.map((applicant, index) => (
                        <ApplicantCard
                          key={index}
                          applicant={applicant}
                          data={getApplicantsById}
                          setSelectedApplicant={setSelectedApplicant}
                          selectedApplicant={selectedApplicant}
                          //   onClick={() => handleApplicantClick(applicant)}
                          onShoppingClick={handleShoppingClick}
                          onCloseClick={handleCloseClick}
                        />
                      ))}
                      {/* </InfiniteScroll> */}
                    </div>
                  </div>
                </div>
                <div className="col-lg-7 mt-5 mt-sm-0">
                  <div
                    className="bg-white rounded-1 p-30 "
                    style={{
                      // border: "1px rgba(209, 223, 255, 1) solid",
                      boxShadow: "0px 0px 0px 1px rgba(0,0,0,0.1)",
                    }}
                  >
                    {selectedApplicant ? (
                      <>
                        <div className="details">
                          <h3></h3>
                        </div>
                        <div className="context">
                          <div className="header position-relative">
                            {/* <div className="next">
                                                            <a href=''>Next <span> &#8594;</span></a>
                                                        </div> */}
                            <div className="row">
                              <div className="col-lg-2 pe-0">
                                <div className="profile-img">
                                  <img
                                    src={
                                      getApplicantsById?.user?.profile_img
                                        ? getApplicantsById?.user?.profile_img
                                        : placeholderUser
                                    }
                                    className="image-fit"
                                    alt=""
                                  />
                                </div>
                              </div>
                              <div className="col-lg-8 ps-md-0">
                                <div className="details">
                                  <h3>
                                    {getApplicantsById?.user?.first_name}{" "}
                                    {getApplicantsById?.user?.last_name}
                                  </h3>
                                  <p>
                                    {
                                      getApplicantsById?.user?.profile
                                        ?.profile_headline
                                    }
                                  </p>
                                  <p>
                                    Applied{" "}
                                    {GetTime(getApplicantsById?.createdAt)}
                                  </p>
                                </div>
                              </div>
                            </div>
                            <div className="line mb-30 mt-30"></div>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-6">
                            <div className="header">
                              <div className="details">
                                <h3 style={{ fontSize: "19px" }}>
                                  Insights from profile
                                </h3>
                              </div>
                            </div>
                          </div>
                          <div className="col-6">
                            <div className="header">
                              <div className="float-end">
                                <span
                                  className="user-prof"
                                  onClick={() => {
                                    navigate(
                                      `/profile/${getApplicantsById?.user_id}`
                                    );
                                  }}
                                >
                                  See full profile
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="row mt-20">
                          <div className="col-lg-12">
                            {getApplicantsById?.user?.profile
                              ?.highestExperience ? (
                              <div className="details mb-30">
                                <h3>Experience</h3>
                              </div>
                            ) : null}
                          </div>
                          {getApplicantsById?.user?.profile
                            ?.highestExperience ? (
                            <>
                              <div className="col-lg-1">
                                {console.log(getApplicantsById)}
                                <div className="profile-img">
                                  {/* src={
                        getAJob?.user?.id != user?.data?.id
                          ? getAJob?.company_visible
                            ? getAJob?.company?.icon
                              ? getAJob?.company?.icon
                              : pagePlaceholder
                            : confidential
                          : getAJob?.company?.icon
                          ? getAJob?.company?.icon
                          : pagePlaceholder
                      } */}

                                  <img
                                    src={
                                      getApplicantsById?.user?.profile
                                        ?.highestExperience?.is_visible
                                        ? getApplicantsById?.user?.profile
                                            ?.highestExperience?.Page?.icon
                                          ? getApplicantsById?.user?.profile
                                              ?.highestExperience?.Page?.icon
                                          : pagePlaceholder
                                        : confidential
                                    }
                                    className="image-fit"
                                    alt=""
                                  />
                                </div>
                              </div>
                              <div className="col-lg-11 d-flex flex-column justify-content-center">
                                <div className="details mx-2">
                                  <h6>
                                    {
                                      getApplicantsById?.user?.profile
                                        ?.highestExperience?.title
                                    }
                                  </h6>
                                  <p>
                                    {
                                      getApplicantsById?.user?.profile
                                        ?.highestExperience?.company_name
                                    }{" "}
                                    |{" "}
                                    {
                                      getApplicantsById?.user?.profile
                                        ?.highestExperience?.location
                                    }{" "}
                                    | 1yr 3mos
                                  </p>
                                </div>
                              </div>
                            </>
                          ) : null}
                        </div>

                        <div className="row mt-20">
                          <div className="col-lg-12">
                            {getApplicantsById?.user?.profile
                              ?.highestEducation ? (
                              <div className="details mb-30">
                                <h3>Education</h3>
                              </div>
                            ) : null}
                          </div>
                          {getApplicantsById?.user?.profile
                            ?.highestEducation ? (
                            <>
                              <div className="col-lg-1">
                                <div className="profile-img">
                                  <img
                                    src={
                                      getApplicantsById?.user.user?.profile_img
                                        ? getApplicantsById?.user?.profile_img
                                        : pagePlaceholder
                                    }
                                    className="image-fit"
                                    alt=""
                                  />
                                </div>
                              </div>
                              <div className="col-lg-11  d-flex flex-column justify-content-center ">
                                <div className="details mx-2">
                                  <h6>{`${getApplicantsById?.user?.profile?.highestEducation?.degree} (${getApplicantsById?.user?.profile?.highestEducation?.field_of_study})`}</h6>
                                  <p>
                                    {
                                      getApplicantsById?.user?.profile
                                        ?.highestEducation?.school_name
                                    }{" "}
                                  </p>
                                </div>
                              </div>
                            </>
                          ) : null}
                        </div>

                        <div className="line mb-30 mt-30"></div>
                        <div className="row mt-20">
                          <div className="col-lg-12">
                            {coverLetterSubject ||
                            coverLetterDescription ||
                            (questionAndAnswers &&
                              questionAndAnswers.length > 0) ? (
                              <div className="details mb-30">
                                <h3>Candidate Answers</h3>
                                {coverLetterSubject && (
                                  <p className="mt-20 mb-20">
                                    <strong>{coverLetterSubject}</strong>
                                  </p>
                                )}
                                {coverLetterDescription && (
                                  <p
                                    dangerouslySetInnerHTML={{
                                      __html: coverLetterDescription,
                                    }}
                                  />
                                )}
                                {questionAndAnswers &&
                                  questionAndAnswers.length > 0 && (
                                    <>
                                      {questionAndAnswers.map((qa, index) => (
                                        <div
                                          className="mt-20 answer"
                                          key={index}
                                        >
                                          <p>
                                            <strong>{`${index + 1}. ${
                                              qa.question
                                            }`}</strong>
                                          </p>
                                          <p className="mt-2">{qa.answer}</p>
                                        </div>
                                      ))}
                                      <div className="line mt-20"></div>
                                    </>
                                  )}
                              </div>
                            ) : null}
                          </div>
                        </div>
                        <div className="row ">
                          <div className="col-lg-12">
                            {getApplicantsById?.resume ? (
                              <div className="details mb-30">
                                <h3>Resume / CV</h3>

                                <div className="resume-box mt-30">
                                  <iframe
                                    title="Resume PDF"
                                    src={`https://docs.google.com/gview?url=${encodeURIComponent(
                                      getApplicantsById?.resume
                                    )}&embedded=true`}
                                    style={{
                                      width: "100%",
                                      height: "500px",
                                      border: "none",
                                    }}
                                    onLoad={() => console.log("PDF Loaded")}
                                    onError={(e) =>
                                      console.error("Error loading PDF:", e)
                                    }
                                  ></iframe>

                                  <button
                                    className="w-100 mt-3"
                                    onClick={() =>
                                      downloadResume(getApplicantsById?.resume)
                                    }
                                  >
                                    Download Resume
                                  </button>
                                </div>
                              </div>
                            ) : null}
                          </div>
                        </div>
                      </>
                    ) : (
                      <p>Select an applicant to view details.</p>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ToastContainer />
    </div>
  );
};
