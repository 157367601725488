import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Input,
  Modal,
  ModalBody,
  ModalHeader,
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
  Row,
  Col,
} from "reactstrap";
import { votesResultAction } from "../../../../store/dashboard/dashboardActions";
import "../../dashboard.scss";
import "./poll.scss"
import placeholderUser from "../../../../assets/img/placeholder_user.jpg";
import { useNavigate } from "react-router-dom";

const ViewVotes = (props) => {
  const { modal, toggle, title, pollId, firstOption } = props;
  const dispatch = useDispatch();
  const { votesResult } = useSelector((state) => state.dashboard);
  const [currentActiveTab, setCurrentActiveTab] = useState(firstOption);
  const [currentTabId, setCurrentTabId] = useState(firstOption);
  const [showdiv, setShowDiv] = useState(false)

  const navigate = useNavigate()

  useEffect(() => {
    setCurrentTabId(firstOption);
    let token = window.localStorage.getItem("accessToken");
    dispatch(votesResultAction(token, pollId))
    // setVoteData(votesResult?.data);
  }, []);
  function toggled(tab) {
    if (currentActiveTab !== tab) setCurrentActiveTab(tab);
    setCurrentTabId(tab);
    setShowDiv(true)
  }

  return (
   
    <Modal isOpen={modal} size="lg" style={{ maxWidth: "600px", top:"55px" }}>
      <ModalHeader className="view-poll-result" style={{ padding: "16px 30px" }} toggle={toggle}>
        {title}
      </ModalHeader>
      <ModalBody className="viewPollmodal-body mt-2">
      <ul className="nav nav-tabs" role="tablist">
      {props && votesResult && votesResult?.data?.map((item, index) => (
                  <li className={`nav-item centerText curserPointer`}  key={index}>
                    <a
                      className={"nav-link reactionNavLink"}
                      data-bs-toggle="tab"
                      onClick={() => {
                        toggled(item.id);
                      }}
                    >
                      {item.title.slice(0, 12) + (item.title.length > 12 ? "..." : "")
                                    + " " +"(" + item.voters.length +")"}
                    </a>
                  </li>
      ))}
                  </ul>

       {showdiv && <div className="viewPollModalMain">
          <TabContent activeTab={currentActiveTab}>
            {votesResult?.data?.map((item, index) => (
              <TabPane key={index} tabId={item.id}>
                {currentTabId === item.id ? (
                  <>
                    {item?.voters.map((i, ind) => (
                    
                      <div key={ind} className="d-flex ">
                        
                        <div className="img">
                          <img src={i?.profile_img ? i?.profile_img : placeholderUser} alt="" />
                        </div>
                        <div className="viewPolldiv" onClick={() => navigate(`/profile/${i?.id}`)}>
                          <h6>{i.first_name}{" "}{i.last_name}</h6>
                          {i?.profile && i?.profile.profile_headline && 
                            <p>{i?.profile?.profile_headline}</p>}
                        </div>
                      </div>
                    ))}
                  </>
                ) : (
                  ""
                )}
              </TabPane>
            ))}
          </TabContent>
        </div>}
      </ModalBody>
    </Modal>
  );
};

export default ViewVotes;
