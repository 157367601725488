import moment from "moment";
import React, { useRef } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

export let modernRef;
const ModernTemplate = ({ userData, memberShip, modernRef }) => {
  modernRef = useRef();
  // const { profileData: userData } = useSelector((state) => state.profile);

  const bg_color = {
    background: userData?.cv_color || "#0432A3",
  };
  const headColor = {
    color: userData?.cv_color || "#0432A3",
  };
  const skillBackColor = {
    background: userData?.cv_skillColor || "#0432A3",
  };
  // const interestBackColor = {
  //   background: userData?.cv_interestColor || "#0432A3",
  // };

  return (
    <>
      <div
        className={memberShip > 1 ? "third show" : "third hidden"}
        style={{ height: "550px" }}
      >
        <div
          ref={modernRef}
          className={memberShip > 1 ? "resume-3 show" : "resume-3 hidden"}
          style={{ height: memberShip == 1 && "550px" }}
        >
          <div className={memberShip > 1 ? "d-none" : "lock"}>
            <Link to="/membership">
              <div className="lock-img"></div>
              <p>Unlock More Features</p>
            </Link>
          </div>
          <div className="col-12">
            <div className="row">
              <div className="col-3">
                <div className="profile-img">
                  <img
                    src={
                      userData?.profile_img ||
                      "http://upload.wikimedia.org/wikipedia/commons/thumb/b/b6/Image_created_with_a_mobile_phone.png/640px-Image_created_with_a_mobile_phone.png"
                    }
                    className="image-fit"
                  />
                </div>
              </div>
              <div className="col-5 name">
                <h2>
                  {userData?.first_name || "John"}{" "}
                  {userData?.last_name || "Smith"}
                </h2>
                <p>{userData?.profile_headline}</p>
              </div>
              <div className="col-4 list m-0">
                <ul>
                  <li>
                    <i className="message-icon"></i>
                    {userData?.email || "John@gmail.com"}
                  </li>
                  <li>
                    <i className="mobile-icon"></i>
                    {userData?.phone || "+97 55555 55555"}
                  </li>
                  <li>
                    <i className="loction-icon"></i>
                    {userData?.address || "Ajman, UAE"}
                  </li>
                  <li>
                    <i className="nobel-icon"></i>
                    {userData?.profile_url || "nobel.com/john"}
                  </li>
                  <li>
                    <i className="linkdin-icon"></i>
                    {userData?.linkedin_url || "linkedin.com/john"}
                  </li>
                </ul>
              </div>
            </div>
            <div className="row">
              <div className="col-12 para mt-3">
                <p>
                  {userData?.about ||
                    "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book."}
                </p>
              </div>
            </div>
            <div className="row">
              <div className="col-6">
                <div className="skills mb-3">
                  <div className="heading">
                    <h3 style={headColor}>Work Experience</h3>
                    <div className="line" style={bg_color}></div>
                  </div>
                </div>
                {userData?.experiences?.length > 0 ? (
                  userData?.experiences?.map((ele) => {
                    return (
                      <>
                        <div className="boder">
                          <div className="bullet"></div>
                          <div>
                            <div className="skills">
                              <div className="heading">
                                <h4 className="">{ele?.title}</h4>
                                <h4>{ele?.company_name}</h4>
                                <div className="row description">
                                  <div className="col-6">
                                    <p>
                                      {moment(ele?.start_date)
                                        ?.utc()
                                        ?.format("MMM YYYY")}
                                      {" to"}{" "}
                                      {ele?.is_current
                                        ? "Present"
                                        : ele?.end_date
                                        ? moment(ele?.end_date)
                                            ?.utc()
                                            ?.format("MMM YYYY")
                                        : "No Date"}
                                    </p>
                                  </div>
                                  <div className="col-6">
                                    <div className="float-end">
                                      <p>{ele?.location}</p>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <ul className="list">
                              <li>{ele?.description}</li>
                            </ul>
                          </div>
                        </div>
                        <br />
                      </>
                    );
                  })
                ) : (
                  <>
                    <div>
                      <div className="skills">
                        <div className="heading">
                          <h4 className="">Marketing Manager</h4>
                          <h4>Webmate Solutions</h4>
                          <div className="row description">
                            <div className="col-6">
                              <p>(2022- present)</p>
                            </div>
                            <div className="col-6">
                              <div className="float-end">
                                <p>California, CA, USA</p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <ul className="list">
                        <li>
                          Lorem Ipsum is simply dummy text of the printing and
                          typesetting industry. Lorem Ipsum has been the
                          industry's standard dummy text ever since the 1500s,
                          when an unknown printer took a galley of type and
                          scrambled it to make a type specimen book.
                        </li>
                        <li>
                          Lorem Ipsum is simply dummy text of the printing and
                          typesetting industry.
                        </li>
                        <li>
                          Lorem Ipsum has been the industry's standard dummy
                          text ever since the 1500s, when an unknown printer
                          took a galley of type and scrambled it to make a type
                          specimen book.
                        </li>
                      </ul>
                    </div>
                    <div>
                      <div className="skills">
                        <div className="heading">
                          <h4 className="mt-3">Marketing Manager</h4>
                          <h4>Webmate Solutions</h4>
                          <div className="row description">
                            <div className="col-6">
                              <p>(2022- present)</p>
                            </div>
                            <div className="col-6">
                              <div className="float-end">
                                <p>California, CA, USA</p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <ul className="list">
                        <li>
                          Lorem Ipsum is simply dummy text of the printing and
                          typesetting industry. Lorem Ipsum has been the
                          industry's standard dummy text ever since the 1500s,
                          when an unknown printer took a galley of type and
                          scrambled it to make a type specimen book.
                        </li>
                        <li>
                          Lorem Ipsum is simply dummy text of the printing and
                          typesetting industry.
                        </li>
                        <li>
                          Lorem Ipsum has been the industry's standard dummy
                          text ever since the 1500s, when an unknown printer
                          took a galley of type and scrambled it to make a type
                          specimen book.
                        </li>
                      </ul>
                    </div>
                  </>
                )}

                <div className="education mt-3">
                  <div className="skills mb-3">
                    <div className="heading">
                      <h3 style={headColor}>Education</h3>
                      <div className="line" style={bg_color}></div>
                      {userData?.educations?.length > 0 ? (
                        userData?.educations?.map((ele) => {
                          return (
                            <>
                              <div>
                                <div className="skills">
                                  <div className="heading mt-3">
                                    <h4 className="">
                                      <div className="bullet"></div>
                                      {ele?.degree}
                                    </h4>
                                    <h4>{ele?.school_name}</h4>
                                    <div className="row description">
                                      <div className="col-6">
                                        <p>
                                          {moment(ele?.start_date)
                                            ?.utc()
                                            ?.format("MMM YYYY")}
                                          {" to"}{" "}
                                          {ele?.end_date
                                            ? moment(ele?.end_date)
                                                ?.utc()
                                                ?.format("MMM YYYY")
                                            : "No Date"}
                                        </p>
                                      </div>
                                      <div className="col-6">
                                        <div className="float-end">
                                          {/* <p>California, CA, USA</p> */}
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </>
                          );
                        })
                      ) : (
                        <>
                          <div>
                            <div className="skills">
                              <div className="heading mt-3">
                                <h4 className="">
                                  <div className="bullet"></div>MBA
                                </h4>
                                <h4>University of cambridge</h4>
                                <div className="row description">
                                  <div className="col-6">
                                    <p>2019 to 2020</p>
                                  </div>
                                  <div className="col-6">
                                    <div className="float-end">
                                      <p>California, CA, USA</p>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </>
                      )}

                      <div></div>
                    </div>
                  </div>
                </div>
                {/* <div className="">
                  <div className="skills mb-3">
                    <div className="heading">
                      <h3 style={headColor}>Interests</h3>
                      <div className="line" style={bg_color}></div>
                      <div>
                        <div className="intrest mt-3">
                          <ul>
                            <li>Football</li>
                            <li>Reading</li>
                            <li>Drawing</li>
                            <li>Cricket</li>
                            <li>Swimming</li>
                            <li>Football</li>
                          </ul>
                        </div>
                      </div>
                      <div></div>
                    </div>
                  </div>
                </div> */}
              </div>
              <div className="col-6">
                <div className="skills">
                  <div className="heading">
                    <h3 style={headColor}>Skills</h3>
                    <div className="line2" style={bg_color}></div>
                  </div>
                  <ul className="my-3">
                    {userData?.exps_and_skills?.length > 0 ? (
                      userData?.exps_and_skills?.map((ele) => {
                        return (
                          <li style={skillBackColor}>
                            {ele?.skills ? ele?.skills : ele}
                          </li>
                        );
                      })
                    ) : (
                      <>
                        <li>Photoshop</li>
                        <li>Illustrator</li>
                        <li>After effect</li>
                        <li>microsoft office</li>
                        <li>Adobe illustrator</li>
                        <li>Autocad</li>
                        <li>3D Character blending</li>
                        <li>Sketching</li>
                      </>
                    )}
                  </ul>
                </div>
                <div className="skills mb-3">
                  <div className="heading">
                    <h3 style={headColor}>Honour Awards</h3>
                    <div className="line2" style={bg_color}></div>
                  </div>
                  {userData?.honors_and_awards?.length > 0 ? (
                    userData?.honors_and_awards?.map((ele) => {
                      return (
                        <>
                          <div className="text mt-2">
                            <h4>
                              {ele?.name} of the year {ele?.year}
                            </h4>
                            <p>
                              {ele?.Industry?.length > 0
                                ? ele?.Industry[0]?.name
                                : ele?.industry}
                            </p>
                          </div>
                        </>
                      );
                    })
                  ) : (
                    <>
                      <div className="text mt-2">
                        <h4>Top scorer of the year 2022</h4>
                        <p>Lorem Ipsum is simply dummy</p>
                      </div>
                      <div className="text mt-2">
                        <h4>Top scorer of the year 2022</h4>
                        <p>Lorem Ipsum is simply dummy</p>
                      </div>
                    </>
                  )}
                </div>
                <div className="skills mb-3">
                  <div className="heading">
                    <h3 style={headColor}>Languages</h3>
                    <div className="line2" style={bg_color}></div>
                  </div>
                  {userData?.languages?.length > 0 ? (
                    userData?.languages?.map((ele) => {
                      return (
                        <div className="text mt-2">
                          <h4>{ele?.name}</h4>
                          <p>{ele?.proficiency}</p>
                        </div>
                      );
                    })
                  ) : (
                    <>
                      <div className="text mt-2">
                        <h4>English</h4>
                        <p>Native</p>
                      </div>
                      <div className="text mt-2">
                        <h4>Arabic</h4>
                        <p>Elementary</p>
                      </div>
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ModernTemplate;
