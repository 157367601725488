import {
    GET_SUGGESTED_PEOPLE,
    GET_PENDING_INVITATIONS,
    ACCEPT_CONNECTION_REQUEST,
    REJECT_CONNECTION_REQUEST,
    GET_MY_CONNECTION,
    GET_SENT_INVITATIONS_PENDING,
    WITHDRAW_CONNECTION_REQUEST,
    SEND_CONNECT_REQUEST,
    GET_MORE_SUGGESTED_PEOPLE,
    GET_UNFOLLOWED_USERS
} from "../types";

export const initialState = {
    suggested_people:[],
    my_connections:[],
    pending_invitations: [],
    sent_invitations_pending:[],
    unfollowed_users:[]
};

const people = (state = initialState, action) => {
  switch (action.type) {
    case GET_PENDING_INVITATIONS:
      return { ...state, pending_invitations: action.payload };

    case GET_SENT_INVITATIONS_PENDING:
      return { ...state, sent_invitations_pending: action.payload };
    
    case GET_UNFOLLOWED_USERS:
      return{...state, unfollowed_users:action.payload}

    case ACCEPT_CONNECTION_REQUEST:
        return { ...state,
                pending_invitations: state.pending_invitations.filter(
                (item) => item.id !== action.payload
                )};

    case REJECT_CONNECTION_REQUEST:
        return { ...state, 
                pending_invitations: state.pending_invitations.filter(
                (item) => item.id !== action.payload
                )};

    case WITHDRAW_CONNECTION_REQUEST:
          return { ...state, 
                  sent_invitations_pending: state.sent_invitations_pending.filter(
                  (item) => item.id !== action.payload
                  )
                 };
    case SEND_CONNECT_REQUEST:
                  return {
                    ...state,
                    suggested_people: state.suggested_people.map((x) =>
                    x.id === action.payload ? { ...x, connection_status:"Pending" } : x)
                  };
                 
                 
    case GET_SUGGESTED_PEOPLE:
          return { ...state, suggested_people: action.payload };

    case GET_MORE_SUGGESTED_PEOPLE:
          return { ...state, suggested_people: [...state.suggested_people, ...action.payload] }

    case GET_MY_CONNECTION:
            return { ...state, my_connections: action.payload };
  
    default:
      return state;
  }
};

export default people;
