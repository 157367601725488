import React, { useEffect } from "react";
import "../../../../Jobs/components/JobCard/JobCard.scss";
import googleIcon from "../../../../../assets/img/google.jpg";
import noblePage from "../../../../../assets/img/nobelPage.svg";
import savejobIcon from "../../../../../assets/img/saveJob.svg";
import unsaveIcon from "../../../../../assets/img/unsaveJob.svg";
import pagePlaceholder from "../../../../../assets/img/page-placeholder.svg";
import { useDispatch, useSelector } from "react-redux";
import { getJobsDetail } from "../../../../../store/jobs/jobAction";
import { GetTime } from "../../../../../core/components/Utility/Utility";
const JobPreview = (props) => {
  const { selectedJobId } = props;
  const dispatch = useDispatch();
  const { getAJob } = useSelector((state) => state.jobs);
  useEffect(() => {
    dispatch(getJobsDetail(selectedJobId));
  }, [selectedJobId]);

  console.log(getAJob);

  return (
    <div className="JobPreview">
      <div
        className="job-card-layout"
        // onClick={handleClick}
      >
        <div className="job-card bg-white p-3">
          <h5 className="text-dark">Job Preview</h5>
          <div className="d-flex justify-content-between mt-3">
            <div className="d-flex mb-4">
              <img
                src={
                  getAJob?.company?.icon
                    ? getAJob?.company?.icon
                    : pagePlaceholder
                }
                alt="company_icon"
                className="img-icon"
                style={{borderRadius:"20px"}}
              />
              <div className="ml-5">
                <p className="job-title">{getAJob?.title}</p>
                <p className="text-secondary mt-0">{getAJob?.company_name}</p>
              </div>
            </div>
            <img
              src={getAJob?.savedJob?.status ? savejobIcon : unsaveIcon}
              alt="save_icon"
              className="save-icon"
            />
          </div>

          <div className="d-flex justify-content-between bottom-bar mb-4">
            <div className="ml-4">
              <span className="grey-color">
                {getAJob?.employment_type}
                {getAJob?.workspace_type ? ` | ` + getAJob?.workspace_type : ""}
              </span>
            </div>
            <span className="blue-color me-3">
              {GetTime(getAJob?.createdAt)}
            </span>
          </div>

          <div className="bottom-card" style={{ backgroundColor: "#0432A30D" }}>
            <div className="d-flex justify-content-between">
              <span className="blue-color address-text">
                {getAJob?.job_location}
              </span>
              {getAJob?.apply_smart && (
                <div className="d-flex">
                  <img src={noblePage} alt="smart_apply" />
                  <span className="blue-color address-text">Smart Apply</span>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default JobPreview;
