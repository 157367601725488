import React, { useState, useEffect } from "react";
import {
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
} from "reactstrap";
import NotifyToggle from "../../../../core/components/modal/NotifyToggle";
import { useDispatch, useSelector } from "react-redux";
import { addexperiencedetails } from "../../../../store/profile/profileActions";
import { call } from "../../../../api/apiCall";
import buld from "../../../../assets/img/circle-building.svg";
import DatePickerCustom from "../../../../core/components/DatePicker/DatePickerReact";
import moment from "moment";
import Dropdown from "../../../../core/components/DropDown/Dropdown";
import Autocomplete from "react-autocomplete";
import { getLocationsData } from "../../../../store/connections/connectionsActions";
import DeleteModal from "../../../../core/components/modal/DeleteModal";
import edit from "../../../../assets/img/edit.svg";
import { getAllCompanyList } from "../../../../store/profile/profileActions";
import AddMediaExper from "./AddMediaExper";
import { toast } from "react-toastify";
import { useRef } from "react";
import {
  initLoad,
  initPageLoad,
  stopLoad,
  stopPageLoad,
} from "../../../../store/loader/loaderActions";
import axios from "axios";

const AddExperModal = (props) => {
  const {
    modal,
    toggle,
    setEditExperienceData,
    editExperienceData,
    isAllowedToEdit,
    isInBackgroundProcess,
  } = props;
  const { isWebView } = useSelector((state) => state.auth);
  const [closeModal, setCloseModal] = useState(false);
  const [addMediaModal, setAddMediaModal] = useState(false);
  const [companyList, setCompanyList] = useState([]);
  const [image, setImage] = useState("");
  const [fileName, setFileName] = useState("");
  const [mediaDescription, setMediaDescription] = useState("");
  const [memberInput, setMemberInput] = useState(
    editExperienceData?.company_name
      ? { val: editExperienceData?.company_name }
      : ""
  );
  const [listImage, setListImage] = useState(
    editExperienceData?.Page ? editExperienceData?.Page?.icon : ""
  );
  const [companyId, setCompanyId] = useState(
    editExperienceData?.Page ? editExperienceData?.Page?.id : null
  );

  const [expData, setExpData] = useState(
    editExperienceData
      ? {
          id: editExperienceData.id,
          title: editExperienceData.title,
          employment_type: editExperienceData.employment_type,
          company_name: editExperienceData.company_name,
          location: editExperienceData.location,
          start_date: moment(editExperienceData.start_date)
            .utc()
            .format("YYYY-MM-DD"),
          end_date: editExperienceData?.end_date
            ? moment(editExperienceData.end_date).utc().format("YYYY-MM-DD")
            : editExperienceData?.end_date,
          description: editExperienceData.description,
          is_current: editExperienceData?.is_current,
          is_visible: editExperienceData?.is_visible,
          medias: editExperienceData?.medias ? editExperienceData?.medias : [],
          notify_network: false,
          country_id: "",
          state_id: "",
          city_id: "",
        }
      : {
          title: "",
          employment_type: "",
          company_name: "",
          location: "",
          start_date: "",
          end_date: null,
          description: "",
          is_current: "",
          is_visible: true,
          notify_network: false,
          medias: [],
          country_id: "",
          state_id: "",
          page_id: "",
          city_id: 392,
        }
  );

  console.log(
    isInBackgroundProcess,
    editExperienceData,
    "isInBackgroundProcess"
  );

  const [errorTitle, setErrorTitle] = useState("");
  const [errorCompany, setErrorCompany] = useState("");
  const [errorDate, setErrorDate] = useState("");
  const [companyType, setCompanyType] = useState("");
  const [selectedMediaId, setSelectedMediaId] = useState("");
  const [textLength, setTextLength] = useState(
    editExperienceData?.description
      ? editExperienceData.description.split(/\+/g).join("").length
      : 0
  );
  const [showLabel, setShowLabel] = useState("");
  const [locationInput, setLocationInput] = useState(
    editExperienceData?.location ? editExperienceData?.location : ""
  );
  const [locationList, setLocationList] = useState([]);

  const dispatch = useDispatch();

  useEffect(() => {
    if (locationInput) {
      dispatch(getLocationsData(locationInput))
        .then((res) => {
          if (res?.data) {
            setLocationList(res?.data);
          } else setLocationList([]);
        })
        .catch(() => {
          setLocationList([]);
        });
    }
  }, [locationInput]);

  useEffect(() => {
    (async () => {
      try {
        const res = await call("get", "api/v1/employment-type", null, null);
        setCompanyType(res.data);
      } catch (err) {
        console.log(err);
      }
    })();

    setShowLabel(expData.employment_type);
  }, []);

  useEffect(() => {
    if (memberInput) {
      dispatch(getAllCompanyList({ search: memberInput.val }))
        .then((res) => {
          if (res?.success === true) {
            setCompanyList(res?.data);
          } else setCompanyList([]);
        })
        .catch(() => {
          setCompanyList([]);
        });
    }
  }, [memberInput]);

  const checkValidation = (type) => {
    let formIsValid = true;

    if (type === "title" || type === "all") {
      if (!expData?.title?.trim()) {
        setErrorTitle("The Title is mandatory.");
        formIsValid = false;
      } else {
        setErrorTitle("");
      }
    }

    if (type === "company" || type === "all") {
      if (!expData.company_name.trim()) {
        setErrorCompany("The company name is mandatory.");
        formIsValid = false;
        return;
      } else {
        setErrorCompany("");
      }
    }
    if (type === "date" || type === "all") {
      if (!expData.start_date) {
        setErrorDate("The field is mandatory");
        formIsValid = false;
        return;
      } else if (expData.is_current === false && expData.start_date) {
        if (!expData.end_date) {
          setErrorDate("End Date is required.");
          formIsValid = false;
          return;
        }
      } else {
        setErrorDate("");
      }
    }

    return formIsValid;
  };

  const handleSubmit = () => {
    if (checkValidation("all")) {
      let newData = { ...expData, title: expData.title.trim() };
      Object.keys(newData).forEach((key) => {
        if (key === "is_visible") return key;
        if (key === "page_id") return key;
        else if (!newData[key]) delete newData[key];
      });
      var token = localStorage.getItem("accessToken");
      dispatch(addexperiencedetails(newData, token))
        .then(() => {
          if (editExperienceData) {
            setEditExperienceData();
          }
          toggle();
        })
        .catch((e) => {
          console.log(e);
        });
    }
  };

  const CloseModal = () => {
    if (editExperienceData) {
      setEditExperienceData();
    }
    toggle();
  };
  const imgRef = useRef();

  const handleSelectedValue = (value) => {
    setShowLabel(value.value);
    setExpData({
      ...expData,
      employment_type: value.value,
    });
  };

  const handleChange = async (e, type) => {
    var fileExtension = e.target.value.split(".").pop();
    var validFile = true;
    let fileValidation =
      /^(jpg|jpeg|png|JPG|JPEG|PNG|pdf|ppt|pptx|txt|^text\/plain|doc|docx|xls|xlsx)$/;
    if (e.target.id === "chosefile1") {
      if (fileValidation.test(fileExtension) == false) {
        validFile = false;
      }
    }
    if (validFile) {
      let tempimage = [];
      Array.from(e.target.files).map((file) => {
        tempimage.push({
          type,
          file,
        });
      });
      try {
        const UPLOAD_URL = `${process.env.REACT_APP_API_URL}api/v1/upload`;
        const formData = new FormData();
        const headers = {
          "Accept-Language": "en",
          "content-type": "multipart/form-data",
        };

        for (var i = 0; i < tempimage.length; i++) {
          formData.append("image", tempimage[i].file);
        }
        dispatch(initLoad());
        await axios.post(UPLOAD_URL, formData, { headers }).then((res) => {
          dispatch(stopLoad());
          let { filesArray, urlsArray } = res.data;
          setFileName(filesArray[0].originalname);
          setImage(urlsArray[0]);
        });
      } catch (err) {
        if (err.response.data.message === "File too large") {
          toast.warn("File is too large, please upload another file", {
            position: toast.POSITION.BOTTOM_LEFT,
          });
          dispatch(stopLoad());
        }
        console.log(err);
        toast.error(err.response.data.message, {
          position: toast.POSITION.BOTTOM_LEFT,
        });
      }
    } else {
      toast.warn("Please choose valid file type", {
        position: toast.POSITION.BOTTOM_LEFT,
      });
      return;
    }
  };
  const handleAddMedia = () => {
    setExpData({
      ...expData,
      medias: [
        ...expData?.medias,
        {
          title: fileName,
          description: mediaDescription,
          image: image,
          type: "experience",
        },
      ],
    });
    setAddMediaModal(false);
  };
  useEffect(() => {
    if (image) setAddMediaModal(true);
  }, [image]);

  return (
    <div>
      <Modal
        isOpen={modal}
        size="lg"
        className="fix-width gradient-background"
        centered
      >
        <div className="popup">
          <div className="add-expriece">
            <ModalHeader toggle={CloseModal} id="account-modal">
              {editExperienceData ? "Edit experience" : "Add experience"}
            </ModalHeader>
            <ModalBody>
              {isInBackgroundProcess && (
                <p className="text-danger mb-2">
                  Currently your request for background screening is in process
                  for that experience so you can edit description only
                </p>
              )}
              <form>
                <NotifyToggle
                  setExpData={setExpData}
                  expData={expData}
                  isInBackgroundProcess={isInBackgroundProcess}
                />
                <div className="title">
                  <label>Title</label>

                  {/* <Input
                    type="text"
                    onClick={(e) => setErrorTitle("")}
                    placeholder="Enter Title"
                    maxLength="50"
                    onBlur={() => checkValidation("title")}
                    value={expData.title}
                    onChange={(e) =>
                      setExpData({ ...expData, title: e.target.value })
                    }
                  /> */}
                  <Input
                    disabled={isInBackgroundProcess}
                    className="title-placeholder-text"
                    type="text"
                    onClick={(e) => setErrorTitle("")}
                    placeholder="Job title"
                    maxLength="50"
                    onBlur={() => checkValidation("title")}
                    value={expData.title}
                    onChange={(e) => {
                      const titleValue = e.target.value;
                      const regex = /^[a-zA-Z\s]*$/;
                      if (!regex.test(titleValue)) {
                        setErrorTitle(
                          "Special characters like #$%& are not allowed"
                        );
                      } else {
                        setErrorTitle("");
                        // const trimmedValue=titleValue.trim();
                        setExpData({ ...expData, title: titleValue });
                      }
                    }}
                    // onChange={(e) => {
                    //   const titleValue = e.target.value.trim();
                    //   const regex = /^(?!\s)[\s\S]*$/;
                    //   if (!regex.test(titleValue)) {
                    //     setErrorTitle(
                    //       "Special characters like #$%& are not allowed"
                    //     );
                    //   } else {
                    //     setErrorTitle("");
                    //     setExpData({ ...expData, title: titleValue });
                    //   }
                    // }}
                  />

                  {errorTitle && (
                    <label className="label-input100 text-danger">
                      {errorTitle}
                    </label>
                  )}
                </div>
                <div className="title">
                  <FormGroup>
                    <Label for="type">Employment type</Label>
                    {/* {false ?
                    <Dropdown
                    label='Select Type'
                    data={companyType&& companyType.data.map(item=>({
                    value:item.name,
                    id:item.id
                    }))}
                    dynamicPlaceHolder={showLabel}
                    dropdownValue='employment_type'
                    setData={setExpData}
                    handleSelect={handleSelectedValue}
                    /> 
                    : */}
                    <Input
                      value={expData.employment_type}
                      style={{ color: "#676A79", height: "40px" }}
                      id="type"
                      name="select"
                      type="select"
                      onChange={(e) =>
                        setExpData({
                          ...expData,
                          employment_type: e.target.value,
                        })
                      }
                      disabled={isInBackgroundProcess}
                    >
                      <option value="" style={{ fontSize: "15px !important" }}>
                        Select type
                      </option>
                      {companyType?.data?.map((item, index) => {
                        return (
                          <option key={index} value={item.name}>
                            {item.name}
                          </option>
                        );
                      })}
                    </Input>
                    {/* } */}
                  </FormGroup>
                </div>

                <div>
                  <label>Company name </label>
                  {/* <div className="icon">
                    <Input
                      value={expData.company_name}
                      maxLength="80"
                      onClick={(e) => setErrorCompany("")}
                      onBlur={() => checkValidation("company")}
                      placeholder="Company Name"
                      onChange={(e) =>
                        setExpData({ ...expData, company_name: e.target.value })
                      }
                    />
                    <div className="icon-img">
                      <img src={buld} alt="" />
                    </div>
                  </div> */}

                  <div className="company-custom-dropdown mb-20">
                    <div className="autocomplete-wrapper company_auto_drop">
                      <Autocomplete
                        // inputProps={{ placeholder: "Add Members" }}
                        inputProps={{
                          style: {
                            height: "40px",
                          },
                          disabled: isInBackgroundProcess,
                        }}
                        value={memberInput.val}
                        items={companyList}
                        getItemValue={(item) => item.name}
                        // shouldItemRender={(item, value) =>
                        //   item.first_name
                        //     .toLowerCase()
                        //     .indexOf(value.toLowerCase()) > -1
                        // }
                        renderMenu={(items, value) =>
                          items.length === 0 ? (
                            <></>
                          ) : (
                            <div className="dropdown">{items} </div>
                          )
                        }
                        renderItem={(item, isHighlighted) => (
                          <div
                            className={`item ${
                              isHighlighted ? "selected-item" : ""
                            }`}
                            style={{ display: "flex" }}
                          >
                            {/* <img
                              src={item.icon ? item.icon : buld}
                              
                              // expData.is_visible
                              alt="dropdown_pic"
                              className="image-fit mr-10"
                              style={{ height: "36px", width: "36px" }}
                            /> */}
                            <img
                              style={{
                                height: "35px",
                                width: "35px",
                                borderRadius: "50%",
                              }}
                              src={item?.icon || buld}
                              alt=""
                            />
                            <div>
                              <div className="name-heading-drop ">
                                {item.name}
                              </div>
                              {/* <div className="sub-heading-drop">
                              {item?.profile?.profile_headline ? (
                                item?.profile?.profile_headline
                              ) : (
                                <></>
                              )}
                            </div> */}
                            </div>
                          </div>
                        )}
                        onChange={(event, val) => {
                          setMemberInput({ val });
                          setCompanyId("");
                          setListImage("");
                          setExpData({
                            ...expData,
                            company_name: val,
                            page_id: null,
                          });
                        }}
                        onSelect={(val, obj) => {
                          setMemberInput({ val });
                          setListImage(obj.icon);
                          setCompanyId(obj.id);
                          setExpData({
                            ...expData,
                            company_name: val,
                            page_id: obj.id,
                          });
                        }}
                        wrapperStyle={{}}
                        renderInput={(params) => {
                          return (
                            <div class="fake-input">
                              <input
                                className="company-name"
                                {...params}
                                type="text"
                                placeholder="Company name"
                                // onClick={(e) => setErrorCompany("")}
                                // onBlur={() => checkValidation("company")}
                              />
                              {/* <img src={companyId ? listImage : buld} alt="" /> */}
                              <img
                                style={{ height: "35px", width: "35px" }}
                                src={listImage ? listImage : buld}
                                alt=""
                              />

                              {/* <img src={companyId ? (expData.is_visible ? listImage : buld) : null} alt="" /> */}
                            </div>
                          );
                        }}
                      />
                    </div>
                    {errorCompany && (
                      <label className="label-input100 text-danger">
                        {errorCompany}
                      </label>
                    )}
                  </div>
                </div>
                <div className="box" style={{ marginBottom: "10px" }}>
                  <h6>Mark as confidential</h6>
                  <FormGroup switch>
                    <Input
                      disabled={isInBackgroundProcess}
                      type="switch"
                      role="switch"
                      checked={!expData.is_visible}
                      // onChange={(e) => {
                      //   if (expData.is_visible === true) {
                      //     setCloseModal(true);
                      //   } else
                      //     setExpData({
                      //       ...expData,
                      //       is_visible: e.target.checked,
                      //     });
                      // }}
                      onChange={(e) => {
                        if (expData.is_visible) {
                          // Open the modal only when the switch is turned on
                          setCloseModal(true);
                        } else {
                          setExpData({
                            ...expData,
                            is_visible: true,
                          });
                        }
                      }}
                    />
                  </FormGroup>
                </div>
                <div className="title">
                  <label>Location</label>
                  {/* <Input
                    value={expData.location}
                    maxLength="80"
                    onChange={(e) =>
                      setExpData({ ...expData, location: e.target.value })
                    }
                  /> */}
                  <div className="autocomplete-wrapper location-placeholder company_auto_drop">
                    <Autocomplete
                      inputProps={{
                        placeholder: "Location",
                        style: { height: "40px" },
                        disabled: isInBackgroundProcess,
                      }}
                      value={locationInput}
                      items={locationList}
                      getItemValue={(item) => item.name}
                      // shouldItemRender={(item, value) =>
                      //   item.name.toLowerCase().indexOf(value.toLowerCase()) > -1
                      // }
                      // renderMenu={(items, value) => (
                      //   <div className="dropdown">
                      //     {items.length === 0
                      //       ? `No matches for ${value}`
                      //       : items}
                      //   </div>
                      // )}
                      renderMenu={(items, value) => {
                        const noMatches =
                          items.length === 0 && value.trim() !== "";
                        return (
                          <div className="dropdown">
                            {noMatches ? `No matches for ${value}` : items}
                          </div>
                        );
                      }}
                      renderItem={(item, isHighlighted) => (
                        <div
                          className={`item ${
                            isHighlighted ? "selected-item" : ""
                          }`}
                        >
                          {item.name}
                        </div>
                      )}
                      disabled={isInBackgroundProcess}
                      onChange={(event, val) => setLocationInput(val)}
                      onSelect={(val, obj) => {
                        setLocationInput(val);
                        setExpData({
                          ...expData,
                          location: obj.name,
                          country_id: obj.country_id ? obj.country_id : null,
                          state_id: obj.state_id ? obj.state_id : null,
                          city_id: obj.city_id ? obj.city_id : null,
                        });
                      }}
                      wrapperStyle={{}}
                    />
                  </div>
                </div>
                <div className="d-flex mb-3" style={{ alignItems: "end" }}>
                  <Input
                    className="me-2"
                    style={{
                      borderRadius: "4px",
                      width: "20px",
                      height: "20px",
                    }}
                    type="checkbox"
                    checked={expData.is_current}
                    onChange={(e) =>
                      setExpData({
                        ...expData,
                        is_current: e.target.checked,
                        end_date: "",
                      })
                    }
                    disabled={isInBackgroundProcess}
                  />
                  <p>I am currently working in this role</p>
                </div>
                <div className="row">
                  <div className="col-md-6 col-12">
                    <label htmlFor="">Start date</label>
                    {isWebView ? (
                      <Input
                        value={expData.start_date}
                        type="date"
                        onClick={(e) => setErrorDate("")}
                        onChange={(e) =>
                          setExpData({
                            ...expData,
                            start_date: e.target.value,
                            end_date: "",
                          })
                        }
                        max={new Date()}
                        disabled={isInBackgroundProcess}
                      />
                    ) : (
                      <DatePickerCustom
                        className="startDate-placeholder"
                        value={
                          expData.start_date ? new Date(expData.start_date) : ""
                        }
                        onChange={(date) => {
                          date
                            ? setExpData({
                                ...expData,
                                start_date: moment(date).format("YYYY-MM-DD"),
                                end_date: "",
                              })
                            : setExpData({
                                ...expData,
                                start_date: "",
                                end_date: "",
                              });
                        }}
                        placeholder="Pick"
                        onFocus={(e) => setErrorDate("")}
                        max={new Date()}
                        disabled={isInBackgroundProcess}
                      />
                    )}
                  </div>

                  <div className="col-md-6 col-12">
                    <label htmlFor="">End date</label>
                    {isWebView ? (
                      <Input
                        value={expData.end_date}
                        onClick={(e) => setErrorDate("")}
                        disabled={isInBackgroundProcess || expData.is_current}
                        min={
                          expData.start_date
                            ? new Date(expData.start_date)
                                .toISOString()
                                .split("T")[0]
                            : ""
                        }
                        type="date"
                        placeholder="Pick"
                        onChange={(e) =>
                          setExpData({ ...expData, end_date: e.target.value })
                        }
                        max={new Date()}
                      />
                    ) : (
                      <DatePickerCustom
                        value={
                          expData.end_date ? new Date(expData.end_date) : ""
                        }
                        onChange={(date) => {
                          date
                            ? setExpData({
                                ...expData,
                                end_date: moment(date).format("YYYY-MM-DD"),
                              })
                            : setExpData({ ...expData, end_date: "" });
                        }}
                        placeholder="Pick"
                        onFocus={(e) => setErrorDate("")}
                        disabled={
                          isInBackgroundProcess ||
                          expData.start_date === "" ||
                          expData.is_current === true
                            ? true
                            : false
                        }
                        min={
                          expData.start_date ? new Date(expData.start_date) : ""
                        }
                        max={new Date()}
                      />
                    )}
                  </div>
                </div>
                {errorDate && (
                  <label className="label-input100 text-danger">
                    {errorDate}
                  </label>
                )}
                <div className="d-flex my-3">
                  {expData.is_current && (
                    <>
                      <Input
                        className="me-2"
                        type="checkbox"
                        onChange={(e) =>
                          setExpData({
                            ...expData,
                            is_current: e.target.checked,
                          })
                        }
                        disabled={isInBackgroundProcess}
                      />
                      <p>End current position as of now</p>
                    </>
                  )}
                </div>
                <div className="title">
                  <label htmlFor="">Description</label>
                  <Input
                    type="textarea"
                    value={expData.description}
                    maxLength="200"
                    placeholder="Description"
                    onChange={(e) => {
                      setExpData({ ...expData, description: e.target.value });
                      setTextLength(
                        e.target.value.trim() === "" ? 0 : e.target.value.length
                      );
                    }}
                  />
                  <p className="left">{textLength}/200</p>
                </div>
                <div className="media">
                  <label>Media</label>
                  <p>
                    Incorporate relevant media to support your experience, if
                    available.
                  </p>
                </div>
                <div className="add-media mt-2">
                  <span
                    onClick={() => {
                      try {
                        document.getElementById("chosefile1").click();
                      } catch (error) {
                        console.log(error);
                      }
                    }}
                  >
                    + Add media
                  </span>
                  {expData?.medias?.map((item, index) => {
                    return (
                      <div className="row show-media" key={index}>
                        <div className="col-4 ms-3 show-media-image">
                          <img
                            alt="media"
                            src={item?.image}
                            className="main-image"
                          />
                          {isAllowedToEdit && (
                            <div className="edit">
                              <img
                                src={edit}
                                alt=""
                                onClick={() => {
                                  setImage(item?.image);
                                  setFileName(item?.title);
                                  setMediaDescription(item?.description);
                                  setAddMediaModal(true);
                                  setSelectedMediaId(item?.id);
                                }}
                              />
                            </div>
                          )}
                        </div>
                        <div className="col-8">
                          <p className="card-control-text-oneline">
                            {item?.title}
                          </p>
                          <p className="card-control-text-oneline">
                            {item?.description}
                          </p>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </form>
              <input
                type="file"
                ref={imgRef}
                accept="image/png, image/gif, image/jpeg"
                id="chosefile1"
                onChange={(e) => handleChange(e, "image")}
                onClick={(e) => {
                  e.target.value = "";
                  setImage("");
                  setFileName("");
                }}
              />
            </ModalBody>
            <div className="p-20" style={{ textAlign: "center" }}>
              <div
                className={isWebView ? "btnWebView btn-blue" : "btn btn-blue"}
                onClick={handleSubmit}
              >
                Save
              </div>
            </div>
          </div>
        </div>
      </Modal>
      {/* {closeModal && (
        <DeleteModal
          modal={DeleteModal}
          toggle={() => setCloseModal(!closeModal)}
          title="Hide experience"
          deleteConfirm={() => {
            setExpData({
              ...expData,
              is_visible: false,
            });
          }}
          text="Do you want to hide the experience from public?"
        />
      )} */}
      {closeModal && (
        <DeleteModal
          modal={closeModal}
          toggle={() => setCloseModal(!closeModal)}
          title="Hide experience"
          deleteConfirm={() => {
            setExpData({
              ...expData,
              is_visible: false,
            });
            // setCloseModal(false);
            // Additional logic if needed
          }}
          text="Do you want to hide the experience from public?"
        />
      )}
      {addMediaModal && (
        <AddMediaExper
          modal={addMediaModal}
          toggle={() => setAddMediaModal(!addMediaModal)}
          image={image}
          setImage={setImage}
          fileName={fileName}
          setFileName={setFileName}
          mediaDescription={mediaDescription}
          setMediaDescription={setMediaDescription}
          add={() => handleAddMedia()}
          expData={expData}
          setExpData={setExpData}
          selectedMediaId={selectedMediaId}
        />
      )}
    </div>
  );
};

export default AddExperModal;
