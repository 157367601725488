export const initialState = {
  messageData: null,
  typingObject: null,
};

const xmpp = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case "MESSAGE_DATA":
      return {
        ...state,
        messageData: payload,
      };

    case "TYPING_OBJECT":
      return {
        ...state,
        typingObject: payload,
      };

    default:
      return state;
  }
};

export default xmpp;
