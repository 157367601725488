import { call } from "./apiCall";

class GroupsApi {
  getGroups(params) {
    return new Promise((resolve, reject) => {
      let token = localStorage.getItem("accessToken");
      (async () => {
        try {
          const res = await call(
            "get",
            // query? `api/v1/group-list?filter=${1}`:
            // `api/v1/group-list?filter={my}`
            `api/v1/group-list`,
            params,
            null,
            token
          );
          if (res.data.success === true) {
            resolve(res.data);
          }
          if (res.data.success === false) {
            resolve(res.data);
          }
        } catch (err) {
          console.log(err);
          reject(err);
        }
      })();
    });
  }

  createGroups(data, token) {
    return new Promise((resolve, reject) => {
      (async () => {
        try {
          const res = await call("post", `api/v1/group`, null, data, token);
          if (res.data.success === true) {
            resolve(res.data);
          }
          if (res.data.success === false) {
            resolve(res.data);
          }
        } catch (err) {
          console.log(err);
          reject(err);
          alert(err?.response?.data?.message);
        }
      })();
    });
  }

  editGroup(id, data, token) {
    return new Promise((resolve, reject) => {
      (async () => {
        try {
          const res = await call(
            "patch",
            `api/v1/group/${id}`,
            null,
            data,
            token
          );
          console.log(res.data);
          resolve(res.data);
        } catch (err) {
          console.log(err);
          reject(err);
          alert(err?.response?.data?.message);
        }
      })();
    });
  }

  addGroupURL(data) {
    return new Promise((resolve, reject) => {
      (async () => {
        let token = localStorage.getItem("accessToken");
        try {
          const res = await call(
            "patch",
            `api/v1/add-group-url`,
            null,
            data,
            token
          );
          resolve(res.data);
        } catch (err) {
          console.log(err);
          reject(err);
        }
      })();
    });
  }

  getGroupDetail(id, token) {
    return new Promise((resolve, reject) => {
      (async () => {
        try {
          const res = await call(
            "get",
            `api/v1/group-list?id=${id}`,
            null,
            null,
            token
          );
          console.log(res.data);
          resolve(res.data);
        } catch (err) {
          console.log(err);
          reject(err);
        }
      })();
    });
  }

  getGroupDetailByUrl(groupUrl, token) {
    return new Promise((resolve, reject) => {
      (async () => {
        try {
          const res = await call(
            "get",
            `api/v1/group-list?group_url=${groupUrl}`,
            null,
            null,
            token
          );
          console.log(res.data);
          resolve(res.data);
        } catch (err) {
          console.log(err);
          reject(err);
        }
      })();
    });
  }

  joinGroups(id) {
    let token = localStorage.getItem("accessToken");
    return new Promise((resolve, reject) => {
      (async () => {
        try {
          const res = await call(
            "post",
            `api/v1/group-request/${id}`,
            null,
            null,
            token
          );
          console.log(res.data);
          resolve(res.data);
        } catch (err) {
          console.log(err);
          reject(err);
          alert(err?.response?.data?.message);
        }
      })();
    });
  }

  acceptGroups(data) {
    let token = localStorage.getItem("accessToken");
    return new Promise((resolve, reject) => {
      (async () => {
        try {
          const res = await call(
            "patch",
            `api/v1/group-request`,
            null,
            data,
            token
          );
          console.log(res.data);
          resolve(res.data);
        } catch (err) {
          console.log(err);
          reject(err);
          alert(err?.response?.data?.message);
        }
      })();
    });
  }

  withdraw(data) {
    let token = localStorage.getItem("accessToken");
    return new Promise((resolve, reject) => {
      (async () => {
        try {
          const res = await call(
            "patch",
            `api/v1/group-request`,
            null,
            data,
            token
          );
          console.log(res.data);
          resolve(res.data);
        } catch (err) {
          console.log(err);
          reject(err);
          alert(err?.response?.data?.message);
        }
      })();
    });
  }

  leaveTheGroup(id) {
    let token = localStorage.getItem("accessToken");
    return new Promise((resolve, reject) => {
      (async () => {
        try {
          const res = await call(
            "delete",
            `api/v1/leave-group/${id}`,
            null,
            null,
            token
          );
          console.log(res.data);
          resolve(res.data);
        } catch (err) {
          console.log(err);
          reject(err);
          alert(err?.response?.data?.message);
        }
      })();
    });
  }
}

export const groupsApi = new GroupsApi();
