import React, { useEffect, useState } from "react";
import { call } from "../../../api/apiCall";
import infoIcon from "../../../assets/img/infoIcon.png";
import { Input } from "reactstrap";
import Autocomplete from "react-autocomplete";
import { useDispatch } from "react-redux";
import {
  addexperiencedetails,
  getAllCompanyList,
} from "../../../store/profile/profileActions";
import buld from "../../../assets/img/circle-building.svg";
import FormInput from "../../../core/components/Inputs/FormInput";
import MobileInput from "../../../core/components/Inputs/MobileInput";
import DatePickerCustom from "../../../core/components/DatePicker/DatePickerReact";
import Dropzone from "./dragAndDrop/DragAndDrop";
import axios from "axios";
import removeIcon from "../../../assets/img/removeIcon.png";
import moment from "moment/moment";
import {
  PhoneNumberUtil,
  PhoneNumberFormat as PNF,
} from "google-libphonenumber";
import postalCodes from "postal-codes-js";
import { updateEmployment } from "../../../store/backgroundScreening/backgroundScreeningAction";
import ApiLoader from "../../../core/components/Loaders/apiLoader/apiLoader";
import InfoAlertModel from "./InfoAlertModel";
import { toast } from "react-toastify";
const EmploymentForm = ({
  user,
  ele,
  index,
  // step,
  // setStep,
  completedStep,
  setCompletedStep,
  activeStep,
  setActiveStep,
  data,
  // setFormData,
}) => {
  const [alertModel, setAlertModel] = useState(false);
  const [updateInfo, setupdateInfo] = useState(false);
  const dispatch = useDispatch();
  const [memberInput, setMemberInput] = useState("");
  const [listImage, setListImage] = useState("");
  const [companyId, setCompanyId] = useState(null);
  const [companyList, setCompanyList] = useState([]);
  const [errorCompany, setErrorCompany] = useState("");
  const [countryList, setCountryList] = useState([]);
  const [stateList, setStateList] = useState([]);
  const [cityList, seCityList] = useState([]);
  const [filesSize, setFilesSize] = useState([]);
  const [countryId, setCountryId] = useState(
    data?.employment[index]?.country_id || null
  );
  const [stateId, setStateId] = useState(
    data?.employment[index]?.state_id || null
  );
  const [phoneCount, setPhoneCount] = useState("");
  const phoneUtil = PhoneNumberUtil.getInstance();
  const [isValidCompanySelectedError, setIsValidCompanySelectedError] =
    useState("");

  const [formData, setFormData] = useState({
    id: data?.employment[index]?.id,
    title: data?.employment[index]?.title,
    company_name: data?.employment[index]?.company_name || "",
    country: data?.employment[index]?.country || "",
    contact_name: data?.employment[index]?.contact_name || "",
    state: data?.employment[index]?.state || "",
    applicable_state: data?.employment[index]?.applicable_state || false,
    city: data?.employment[index]?.city || "",
    phone: data?.employment[index]?.phone || "",
    email: data?.employment[index]?.email || "",
    zip: data?.employment[index]?.zip || "",
    company_address: data?.employment[index]?.company_address || "",
    is_current: data?.employment[index]?.is_current || false,
    position: data?.employment[index]?.position || "",
    employeeId: data?.employment[index]?.employeeId || "",
    comment: data?.employment[index]?.comment || "",
    start_date: data?.employment[index]?.start_date || "",
    end_date: data?.employment[index]?.end_date || "",
    separation_type: data?.employment[index]?.separation_type || "",
    document: data?.employment[index]?.document || [],
    country_id: data?.employment[index]?.country_id || "",
    state_id: data?.employment[index]?.state_id || "",
    city_id: data?.employment[index]?.city_id || "",
  });

  useEffect(() => {
    setFormData({
      id: data?.employment[index]?.id,
      title: data?.employment[index]?.title,
      company_name: data?.employment[index]?.company_name || "",
      country: data?.employment[index]?.country || "",
      contact_name: data?.employment[index]?.contact_name || "",
      state: data?.employment[index]?.state || "",
      applicable_state: data?.employment[index]?.applicable_state || false,
      city: data?.employment[index]?.city || "",
      phone: data?.employment[index]?.phone || "",
      email: data?.employment[index]?.email || "",
      zip: data?.employment[index]?.zip || "",
      company_address: data?.employment[index]?.company_address || "",
      is_current: data?.employment[index]?.is_current || false,
      position: data?.employment[index]?.position || "",
      employeeId: data?.employment[index]?.employeeId || "",
      comment: data?.employment[index]?.comment || "",
      start_date: data?.employment[index]?.start_date || "",
      end_date: data?.employment[index]?.end_date || "",
      separation_type: data?.employment[index]?.separation_type || "",
      document: data?.employment[index]?.document || [],
      country_id: data?.employment[index]?.country_id || "",
      state_id: data?.employment[index]?.state_id || "",
      city_id: data?.employment[index]?.city_id || "",
    });
    setCountryId(data?.employment[index]?.country_id || null);
    setStateId(data?.employment[index]?.state_id || null);
  }, []);

  useEffect(() => {
    if (memberInput) {
      dispatch(getAllCompanyList({ search: memberInput.val }))
        .then((res) => {
          if (res?.success === true) {
            setCompanyList(res?.data);
          } else setCompanyList([]);
        })
        .catch(() => {
          setCompanyList([]);
        });
    }
  }, [memberInput]);

  useEffect(() => {
    (async () => {
      try {
        const res = await call("get", "api/v1/country", null, null);
        setCountryList(res?.data?.data);
      } catch (err) {
        console.log(err);
      }
    })();
  }, []);

  useEffect(() => {
    (async () => {
      try {
        const res = await call(
          "get",
          `api/v1/state?country_id=${countryId}`,
          null,
          null
        );
        setStateList(res?.data?.data);
      } catch (err) {
        console.log(err);
      }
    })();
  }, [countryId]);

  useEffect(() => {
    (async () => {
      try {
        const res = await call(
          "get",
          formData?.applicable_state && stateId
            ? `api/v1/city?state_id=${stateId}`
            : `api/v1/city?country_id=${countryId}`,
          null,
          null
        );
        seCityList(res?.data?.data);
      } catch (err) {
        console.log(err);
      }
    })();
  }, [stateId, countryId]);

  const [loading, setLoading] = useState(false);

  const handleBannerPicture = async (files) => {
    try {
      setLoading(true);
      if (!files.length) {
        throw new Error("No files selected");
      }

      const UPLOAD_URL = `${process.env.REACT_APP_API_URL}api/v1/upload`;
      const formdata = new FormData();
      const headers = {
        "Accept-Language": "en",
        "content-type": "multipart/form-data",
      };

      for (let i = 0; i < files.length; i++) {
        formdata.append("image", files[i]);
      }

      await axios
        .post(UPLOAD_URL, formdata, { headers })
        .then((res) => {
          // Construct the array of document objects including URLs
          const documents = res?.data?.filesArray.map(
            ({ originalname, size }, index) => ({
              url: res?.data?.urlsArray[index],
              name: originalname,
              size,
            })
          );

          setFormData({
            ...formData,
            document: !formData?.document?.length
              ? documents
              : [...formData?.document, ...documents],
          });
          setLoading(false);
        })
        .catch((err) => {
          setLoading(false);
        });
    } catch (err) {
      console.log(err.message);
      setLoading(false);
    }
  };

  console.log(user);

  const handleChangeValue = (type) => {
    const expData = user?.experiences?.find(
      (ele) => data?.employment[index]?.id == ele.id
    );
    if (
      type == "company_name" &&
      expData?.company_name &&
      formData?.company_name !== expData?.company_name
    ) {
      toast.error("Company Name is different from the current experience");
    }

    if (
      type == "start_date" &&
      expData?.start_date &&
      moment(formData?.start_date).format("DD-mm-yyyy") !==
        moment(expData?.start_date).format("DD-mm-yyyy")
    ) {
      toast.error("Start Date is different from current experience");
    }

    if (
      type == "end_date" &&
      expData?.end_date &&
      moment(formData?.end_date).format("DD-mm-yyyy") !==
        moment(expData?.start_date).format("DD-mm-yyyy")
    ) {
      toast.error("End Date is different from current experience");
    }

    if (
      type == "position" &&
      expData?.title &&
      formData?.position !== expData?.title
    ) {
      toast.error("Title/ Position is different from current experience");
    }
  };

  const checkInfo = () => {
    let info = true;
    const expData = user?.experiences?.find(
      (ele) => data?.employment[index]?.id == ele.id
    );

    if (
      (expData?.company_name &&
        formData?.company_name !== expData?.company_name) ||
      (expData?.start_date &&
        moment(formData?.start_date).format("DD-mm-yyyy") !==
          moment(expData?.start_date).format("DD-mm-yyyy")) ||
      (expData?.end_date &&
        moment(formData?.end_date).format("DD-mm-yyyy") !==
          moment(expData?.start_date).format("DD-mm-yyyy"))
    ) {
      setAlertModel(true);
      info = false;
    }
    return info;
  };
  const handleSubmit = (e) => {
    e?.preventDefault();
    if (checkValidation("all")) {
      if (checkInfo()) {
        dispatch(updateEmployment(formData, index));
        if (!completedStep.includes(`step${activeStep}`))
          setCompletedStep([...completedStep, `step${activeStep}`]);
        window.scrollTo(0, 0);
        setActiveStep(activeStep + 1);
      }
    }
  };

  const handleNext = () => {
    if (checkValidation("all")) {
      dispatch(updateEmployment(formData, index));
      if (!completedStep.includes(`step${activeStep}`))
        setCompletedStep([...completedStep, `step${activeStep}`]);
      window.scrollTo(0, 0);
      setActiveStep(activeStep + 1);
    }
  };

  const [error, setError] = useState({
    company_name: "",
    country: "",
    contact_name: "",
    state: "",
    city: "",
    phone: "",
    email: "",
    company_address: "",
    position: "",
    start_date: "",
    end_date: "",
    document: "",
    student_id: "",
    zip: "",
  });
  const phoneCountValidation = () => {
    let num = JSON.stringify(formData?.phone);
    let code = JSON.stringify(phoneCount);
    const number = phoneUtil.parse(num, code);
    return phoneUtil.isValidNumber(number);
  };
  const checkValidation = (type) => {
    let isFormValid = true;
    let emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
    let nameRegex = /^[^\d\s]+$/;
    let numberRegex = /^[0-9]+$/;
    let webRegex =
      /^(https?:\/\/)?([a-zA-Z0-9]([a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?\.)+[a-zA-Z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)$/;
    if (type == "company_name" || type == "all") {
      if (!formData?.company_name) {
        setError({ ...error, company_name: "Company name is required" });
        isFormValid = false;
        return;
      } else setError({ ...error, company_name: "" });
    }

    if (type == "country" || type == "all") {
      if (!formData?.country) {
        setError({ ...error, country: "Country is required" });
        isFormValid = false;
        return;
      } else setError({ ...error, country: "" });
    }

    if (type == "contact_name" || type == "all") {
      if (!formData?.contact_name) {
        setError({ ...error, contact_name: "Contact name is required" });
        isFormValid = false;
        return;
      } else if (!nameRegex.test(formData?.contact_name)) {
        setError({ ...error, contact_name: "Please enter valid contact name" });
        isFormValid = false;
        return;
      } else setError({ ...error, contact_name: "" });
    }
    if (!formData?.applicable_state) {
      if (type == "state" || type == "all") {
        if (!formData?.state) {
          setError({ ...error, state: "State is required" });
          isFormValid = false;
          return;
        } else setError({ ...error, state: "" });
      }
    }
    if (type == "city" || type == "all") {
      if (!formData?.city) {
        setError({ ...error, city: "City is required" });
        isFormValid = false;
        return;
      } else setError({ ...error, city: "" });
    }

    if (type == "email" || type == "all") {
      if (!formData?.email) {
        setError({ ...error, email: "Email is required" });
        isFormValid = false;
        return;
      } else if (!emailRegex.test(formData?.email)) {
        setError({
          ...error,
          email: "Please enter your email in a valid format.",
        });
        isFormValid = false;
        return;
      } else setError({ ...error, email: "" });
    }
    if (type == "phone" || type == "all") {
      if (!formData?.phone) {
        setError({ ...error, phone: "Phone is required" });
        isFormValid = false;
        return;
      } else if (formData?.phone < 10) {
        setError({ ...error, phone: "Please enter the valid number." });
        isFormValid = false;
        return;
      } else if (!phoneCountValidation()) {
        setError({ ...error, phone: "Please enter the valid number." });
        isFormValid = false;
        return;
      } else setError({ ...error, phone: "" });
    }

    if (type == "position" || type == "all") {
      if (!formData?.position) {
        setError({ ...error, position: "Position is required" });
        isFormValid = false;
        return;
      } else setError({ ...error, position: "" });
    }
    if (type == "start_date" || type == "all") {
      if (!formData?.start_date) {
        setError({ ...error, start_date: "Start date is required" });
        isFormValid = false;
        return;
      } else setError({ ...error, start_date: "" });
    }
    if (type == "end_date" || type == "all") {
      if (!formData?.is_current) {
        if (!formData?.end_date) {
          setError({ ...error, end_date: "End Date is required" });
          isFormValid = false;
          return;
        } else setError({ ...error, end_date: "" });
      }
    }

    if (type == "document" || type == "all") {
      if (!formData?.document?.length) {
        setError({ ...error, document: "Documents are required" });
        isFormValid = false;
        return;
      } else setError({ ...error, document: "" });
    }

    if (type == "zip" || type == "all") {
      if (formData?.zip && !numberRegex.test(formData?.zip?.trim())) {
        setError({ ...error, zip: "Only numeric are allowed" });
        isFormValid = false;
        return;
      } else {
        setError({ ...error, zip: "" });
      }
    }

    if (formData?.company_address) {
      if (type == "company_address" || type == "all") {
        if (!formData?.company_address?.trim()) {
          setError({ ...error, company_address: "Address is required" });
          isFormValid = false;
          return;
        } else if (!webRegex.test(formData?.company_address?.trim())) {
          setError({
            ...error,
            company_address: "Please enter a valid address",
          });
          isFormValid = false;
          return;
        } else {
          setError({ ...error, company_address: "" });
        }
      }
    }
    return isFormValid;
  };

  const token = localStorage.getItem("accessToken");
  useEffect(() => {
    if (updateInfo) {
      const expData = user?.experiences?.find(
        (ele) => data?.employment[index]?.id == ele.id
      );
      dispatch(
        addexperiencedetails(
          {
            id: expData?.id,
            company_name: formData?.company_name,
            title: formData?.title,
            // position: formData?.position,
          },
          token
        )
      );
      handleNext();
      setAlertModel(false);
    }
  }, [updateInfo]);

  return (
    <div className="px-5 py-3 package_form">
      <form>
        <div className="row">
          <div className="col-6">
            <div className="title mb-3">
              <label htmlFor="name_type" className="form-label">
                <img src={infoIcon} width={20} /> Company Name{" "}
                <span className="text-danger">*</span>
              </label>
              <div className="company-custom-dropdown mb-20">
                <div className="autocomplete-wrapper custom-cursor-style">
                  <Autocomplete
                    value={formData?.company_name}
                    items={companyList}
                    inputProps={{
                      onBlur: () => {
                        checkValidation("company_name");
                        handleChangeValue("company_name");
                      },
                    }}
                    getItemValue={(item) => item.name}
                    renderMenu={(items, value) =>
                      items.length === 0 ? (
                        <></>
                      ) : (
                        <div className="dropdown">{items} </div>
                      )
                    }
                    renderItem={(item, isHighlighted) => (
                      <div
                        className={`item ${
                          isHighlighted ? "selected-item" : ""
                        }`}
                        style={{ display: "flex" }}
                      >
                        <img
                          src={item.icon || buld}
                          alt="dropdown_pic"
                          className="image-fit mr-10"
                          style={{ height: "36px", width: "36px" }}
                        />
                        <div>
                          <div className="name-heading-drop ">{item.name}</div>
                        </div>
                      </div>
                    )}
                    onChange={(event, val) => {
                      setMemberInput({ val });
                      setCompanyId(null);
                      // const updatedEmployment = [...data.employment];
                      // updatedEmployment[index] = {
                      //   ...updatedEmployment[index],
                      //   company_name: val,
                      // };
                      setFormData({
                        ...formData,
                        company_name: val,
                      });
                      if (val.trim().length > 0) {
                        setErrorCompany("");
                      }
                      // const isValueFromDropdown =
                      //   companyList &&
                      //   companyList.some((item) => item.name === val);
                      // setIsValidCompanySelectedError(
                      //   isValueFromDropdown
                      //     ? ""
                      //     : "Please select a company from the dropdown"
                      // );
                    }}
                    onSelect={(val, obj) => {
                      setMemberInput({ val });
                      setListImage(obj.icon);
                      setCompanyId(obj.id);

                      // const updatedEmployment = [...data.employment];
                      // updatedEmployment[index] = {
                      //   ...updatedEmployment[index],
                      //   company_name: val,
                      // };
                      // setFormData({
                      //   ...data,
                      //   employment: updatedEmployment,
                      // });
                      setFormData({
                        ...formData,
                        company_name: val,
                      });
                      setIsValidCompanySelectedError("");
                      setErrorCompany("");
                    }}
                    wrapperStyle={{}}
                    renderInput={(params) => {
                      return (
                        <div class="fake-input">
                          <input
                            {...params}
                            type="text"
                            placeholder="Company name"
                          />
                          <img
                            src={listImage}
                            alt=""
                            style={{
                              height: "36px",
                              width: "36px",
                              borderRadius: "25px",
                            }}
                          />
                        </div>
                      );
                    }}
                  />
                </div>
                {errorCompany && (
                  <p className="error-message" style={{ color: "red" }}>
                    {errorCompany}
                  </p>
                )}
                {isValidCompanySelectedError && (
                  <p
                    className="error-message"
                    style={{
                      color: "red",
                      fontWeight: "500 !important",
                      fontSize: "14px !important",
                    }}
                  >
                    {isValidCompanySelectedError}
                  </p>
                )}
              </div>
            </div>
          </div>
          <div className="col-6 d-flex flex-column justify-content-start">
            <label htmlFor="name_type" className="form-label">
              <img src={infoIcon} width={20} /> Country{" "}
              <span className="text-danger">*</span>
            </label>
            <Input
              type="select"
              value={formData?.country}
              onClick={() => checkValidation("country")}
              onChange={(e) => {
                setFormData({
                  ...formData,
                  country: e.target.value,
                  country_id: countryList?.find(
                    (ele) => ele?.name == e.target.value
                  )?.id,
                });
                setCountryId(
                  countryList?.find((ele) => ele?.name == e.target.value)?.id
                );
              }}
            >
              <option>Select</option>
              {countryList?.map((ele) => {
                return (
                  <>
                    <option value={ele?.name}>{ele?.name}</option>
                  </>
                );
              })}
            </Input>
            {error?.country && <p className="text-danger">{error?.country}</p>}
          </div>
        </div>

        <div className="row">
          <div className="col-6 d-flex flex-column justify-content-end">
            <FormInput
              type="text"
              label={"Name of Contact"}
              labelImage={infoIcon}
              required={true}
              value={formData?.contact_name}
              onChange={(e) => {
                // const updatedEmployment = [...data.employment];
                // updatedEmployment[index] = {
                //   ...updatedEmployment[index],
                //   contact_name: e.target.value,
                // };
                setFormData({
                  ...formData,
                  contact_name: e.target.value,
                });
              }}
              onBlur={() => checkValidation("contact_name")}
              error={error?.contact_name}
              maxLength={50}
            />
          </div>
          <div className="col-6 d-flex flex-column justify-content-end">
            <label
              htmlFor="name_type"
              className="form-label d-flex justify-content-between align-items-center"
            >
              <div className="">
                <img src={infoIcon} width={20} /> State / Province{" "}
                {!formData?.applicable_state && (
                  <span className="text-danger">*</span>
                )}
              </div>
              <div
                className="d-flex align-items-center"
                style={{ width: "30%" }}
              >
                <p
                  // style={{ marginLeft: "120px " }}
                  className="mx-2"
                >
                  Not Applicable
                </p>
                <div className="switch-box d-flex justify-content-center align-items-center">
                  <label className="switch">
                    <input
                      type="checkbox"
                      checked={formData?.applicable_state}
                      onChange={(e) => {
                        // const updatedEmployment = [...data.employment];
                        // updatedEmployment[index] = {
                        //   ...updatedEmployment[index],
                        //   applicable_state: !formData?.applicable_state,
                        // };
                        if (e.target.checked) {
                          setFormData({
                            ...formData,
                            applicable_state: true,
                            state: "",
                          });

                          setError({ ...error, state: "" });
                        } else {
                          setFormData({
                            ...formData,
                            applicable_state: false,
                          });
                        }
                      }}
                    />
                    <span className="slider round"></span>
                  </label>
                </div>
              </div>
            </label>
            <Input
              type="select"
              value={formData?.state}
              disabled={!formData?.country || formData?.applicable_state}
              onClick={() => checkValidation("state")}
              onChange={(e) => {
                // const updatedEmployment = [...data.employment];
                // updatedEmployment[index] = {
                //   ...updatedEmployment[index],
                //   state: e.target.value,
                //   state_id: stateList?.find(
                //     (ele) => ele?.name == e.target.value
                //   )?.id,
                // };
                setStateId(
                  stateList?.find((ele) => ele?.name == e.target.value)?.id
                );
                setFormData({
                  ...formData,
                  state: e.target.value,
                  state_id: stateList?.find(
                    (ele) => ele?.name == e.target.value
                  )?.id,
                });
              }}
            >
              <option>Select</option>
              {stateList?.map((ele) => {
                return (
                  <>
                    <option value={ele.name}>{ele?.name}</option>
                  </>
                );
              })}
            </Input>
            {error?.state && <p className="text-danger">{error?.state}</p>}
          </div>
        </div>

        <div className="row">
          <div className="col-6 d-flex flex-column justify-content-start">
            <FormInput
              type="email"
              label={"Contact Email"}
              required={true}
              labelImage={infoIcon}
              value={formData?.email}
              onChange={(e) => {
                // const updatedEmployment = [...data.employment];
                // updatedEmployment[index] = {
                //   ...updatedEmployment[index],
                //   email: e.target.value,
                // };
                setFormData({
                  ...formData,
                  email: e.target.value,
                });
              }}
              onBlur={() => checkValidation("email")}
              error={error?.email}
            />
          </div>
          <div className="col-6 d-flex flex-column justify-content-end">
            <label htmlFor="name_type" className="form-label">
              <img src={infoIcon} width={20} /> City{" "}
              <span className="text-danger">*</span>
            </label>
            <Input
              type="select"
              value={formData?.city}
              onClick={() => checkValidation("city")}
              onChange={(e) => {
                // const updatedEmployment = [...data.employment];
                // updatedEmployment[index] = {
                //   ...updatedEmployment[index],
                //   city: e.target.value,
                //   city_id: cityList?.find((ele) => ele?.name == e.target.value)
                //     ?.id,
                // };
                setFormData({
                  ...formData,
                  city: e.target.value,
                  city_id: cityList?.find((ele) => ele?.name == e.target.value)
                    ?.id,
                });
              }}
            >
              <option>Select</option>
              {cityList?.map((ele) => {
                return (
                  <>
                    <option value={ele?.name}>{ele?.name}</option>
                  </>
                );
              })}
            </Input>
            {error?.city && <p className="text-danger">{error?.city}</p>}
          </div>
        </div>

        <div className="row">
          <div className="col-6  d-flex flex-column justify-content-start">
            <MobileInput
              label="Phone #"
              labelImage={infoIcon}
              required={true}
              onChange={(value, country, e, formattedValue) => {
                // const updatedEmployment = [...data.employment];
                // updatedEmployment[index] = {
                //   ...updatedEmployment[index],
                //   phone: `+${country.dialCode}-${value.slice(
                //     country.dialCode.length
                //   )}`,
                // };
                setPhoneCount(country.countryCode);
                setFormData({
                  ...formData,
                  phone: `+${country.dialCode}-${value.slice(
                    country.dialCode.length
                  )}`,
                });
              }}
              value={formData?.phone}
              inputType="page"
              containerClassName="mt-3"
              onBlur={() => checkValidation("phone")}
              error={error?.phone}
            />
          </div>

          <div className="col-6 d-flex flex-column justify-content-start">
            <FormInput
              type="text"
              label={"Zip (type 00000 if not applicable)"}
              labelImage={infoIcon}
              value={formData?.zip}
              onChange={(e) => {
                // const updatedEmployment = [...data.employment];
                // updatedEmployment[index] = {
                //   ...updatedEmployment[index],
                //   zip: e.target.value,
                // };

                setFormData({
                  ...formData,
                  zip: e.target.value,
                });
              }}
              maxLength={6}
              onBlur={() => checkValidation("zip")}
              error={error?.zip}
            />
          </div>
        </div>

        <div className="row">
          <div className="col-6">
            <FormInput
              type="text"
              label={"Company Web Address"}
              labelImage={infoIcon}
              value={formData?.company_address}
              onChange={(e) => {
                setFormData({
                  ...formData,
                  company_address: e.target.value,
                });
              }}
              onBlur={() => checkValidation("company_address")}
              error={error?.company_address}
            />
          </div>
          <div className="col-6 d-flex align-items-end">
            <label
              htmlFor="name_type"
              className="form-label d-flex justify-content-between align-items-center w-100"
            >
              <div className="d-flex align-items-center w-100">
                <p
                  // style={{ marginLeft: "120px " }}
                  className="me-2"
                >
                  <img src={infoIcon} width={20} /> Current employer?
                </p>
                <div className="switch-box d-flex justify-content-center align-items-center">
                  <label className="switch">
                    <input
                      type="checkbox"
                      checked={formData?.is_current}
                      onChange={(e) => {
                        if (e.target.checked) {
                          setFormData({
                            ...formData,
                            is_current: true,
                            end_date: null,
                          });
                        } else {
                          setFormData({
                            ...formData,
                            is_current: false,
                          });
                        }
                      }}
                    />
                    <span className="slider round"></span>
                  </label>
                </div>
              </div>
            </label>
          </div>
        </div>

        <div className="row">
          <div className="col-6">
            <FormInput
              type="text"
              label={"Your Title/ Position with Employer"}
              labelImage={infoIcon}
              required={true}
              value={formData?.position}
              onBlur={() => {
                checkValidation("position");
                handleChangeValue("position");
              }}
              onChange={(e) => {
                // const updatedEmployment = [...data.employment];
                // updatedEmployment[index] = {
                //   ...updatedEmployment[index],
                //   position: e.target.value,
                // };

                setFormData({
                  ...formData,
                  position: e.target.value,
                });
              }}
              maxLength={50}
              error={error?.position}
            />
          </div>
          <div className="col-6">
            <FormInput
              type="text"
              label={"Employee Id"}
              labelImage={infoIcon}
              value={formData?.employeeId}
              onChange={(e) => {
                // const updatedEmployment = [...data.employment];
                // updatedEmployment[index] = {
                //   ...updatedEmployment[index],
                //   employeeId: e.target.value,
                // };
                setFormData({
                  ...formData,
                  employeeId: e.target.value,
                });
              }}
            />
          </div>
        </div>

        <div className="row mt-3">
          <div className="col-6 d-flex flex-column">
            <div className="d-flex">
              <label
                htmlFor="name_type"
                className="form-label"
                style={{ width: "auto" }}
              >
                <img src={infoIcon} width={20} /> Start Date{" "}
                <span className="text-danger">*</span>
              </label>
              <div className="mx-3">
                <DatePickerCustom
                  value={
                    formData?.start_date ? new Date(formData?.start_date) : null
                  }
                  onChange={(date) => {
                    // const updatedEmployment = [...data.employment];
                    // updatedEmployment[index] = {
                    //   ...updatedEmployment[index],
                    //   start_date: date
                    //     ? moment(date).format("YYYY-MM-DD")
                    //     : null,
                    // };

                    setFormData({
                      ...formData,
                      start_date: date
                        ? moment(date).format("YYYY-MM-DD")
                        : null,
                    });
                  }}
                  max={new Date()}
                  placeholder=""
                  onBlur={() => {
                    checkValidation("start_date");
                    handleChangeValue("start_date");
                  }}
                />
                {error?.start_date && (
                  <p className="text-danger">{error.start_date}</p>
                )}
              </div>
            </div>
            <div className="d-flex mt-3">
              <label
                htmlFor="name_type"
                className="form-label"
                style={{ width: "auto" }}
              >
                <img src={infoIcon} width={20} /> End Date{" "}
                {!formData?.is_current ? (
                  <span className="text-danger">*</span>
                ) : (
                  <span
                    className="text-danger"
                    style={{ paddingRight: "12px" }}
                  >
                    {" "}
                  </span>
                )}
              </label>
              <div className="mx-4">
                <DatePickerCustom
                  value={
                    formData?.end_date ? new Date(formData?.end_date) : null
                  }
                  onChange={(date) => {
                    // const updatedEmployment = [...data.employment];
                    // updatedEmployment[index] = {
                    //   ...updatedEmployment[index],
                    //   end_date: date ? moment(date).format("YYYY-MM-DD") : null,
                    // };

                    setFormData({
                      ...formData,
                      end_date: date ? moment(date).format("YYYY-MM-DD") : null,
                    });
                  }}
                  max={new Date()}
                  placeholder=""
                  onBlur={() => {
                    checkValidation("end_date");
                    handleChangeValue("end_date");
                  }}
                  disabled={formData?.is_current}
                />
                {error?.end_date && (
                  <p className="text-danger">{error.end_date}</p>
                )}
              </div>
            </div>
            <div className="mt-3">
              <label htmlFor="name_type" className="form-label">
                <img src={infoIcon} width={20} /> Seperation Type{" "}
              </label>
              <Input
                type="select"
                value={formData?.separation_type}
                onChange={(e) => {
                  // const updatedEmployment = [...data.employment];
                  // updatedEmployment[index] = {
                  //   ...updatedEmployment[index],
                  //   seperation_type: e.target.value,
                  // };
                  setFormData({
                    ...formData,
                    separation_type: e.target.value,
                  });
                }}
              >
                <option>Select</option>
                <option value="resigned">Resigned</option>
                <option value="mutual">Mutual Agreement</option>
                <option value="closed">Company Closed</option>
                <option value="terminated">Terminated</option>
              </Input>
            </div>
          </div>
          <div className="col-6">
            <label htmlFor="name_type" className="form-label">
              <img src={infoIcon} width={20} /> Comments{" "}
            </label>
            <textarea
              rows={6}
              style={{ width: "100%", padding: "10px" }}
              value={formData?.comment}
              onChange={(e) => {
                // const updatedEmployment = [...data.employment];
                // updatedEmployment[index] = {
                //   ...updatedEmployment[index],
                //   comment: e.target.value,
                // };
                setFormData({
                  ...formData,
                  comment: e.target.value,
                });
              }}
            />
          </div>
        </div>

        <div className="row mt-3">
          <div className="col-12">
            <label htmlFor="name_type" className="form-label">
              Attachments <span className="text-danger">*</span>
            </label>
            <p className="mt-2">
              Please attach your supporting employment documents for
              verification, such as the employment contract, recent payslip,
              reference letter, and/or salary certificate. It's advisable to
              attach more than one proof.
            </p>
          </div>
        </div>
        {loading ? (
          <div className="d-flex justify-content-center align-item-center">
            <ApiLoader />
          </div>
        ) : (
          <Dropzone handleBannerPicture={handleBannerPicture} />
        )}
        {formData?.document?.length == 0 && error?.document && (
          <p className="text-danger">{error?.document}</p>
        )}
        <div className="row row-cols-2 p-3">
          {formData?.document?.map((ele, index) => (
            <div className="col p-2 bg-white mt-2 mb-2" key={index}>
              <div className="d-flex justify-content-between align-items-center">
                <div className="d-flex align-items-center">
                  <p
                    className="m-0 px-2 py-1"
                    style={{ background: "#BDE7FF" }}
                  >
                    {ele?.name?.split(".")?.pop()?.toUpperCase() || "Pdf"}
                  </p>
                  <p className="m-0 mx-3">{ele?.name}</p>
                </div>
                <div className="d-flex justify-content-center align-items-center">
                  <p
                    className="mx-4 text-secondary"
                    style={{ fontSize: "12px", paddingBottom: 0 }}
                  >
                    {(ele?.size / 1024).toFixed(2) + " KB"}
                  </p>
                  <img
                    src={removeIcon}
                    alt="remove"
                    width={15}
                    onClick={() =>
                      setFormData({
                        ...formData,
                        document: formData?.document?.filter(
                          (_, i) => i !== index
                        ),
                      })
                    }
                  />
                </div>
              </div>
            </div>
          ))}
        </div>
        <div className="d-flex justify-content-center align-items-center mt-3">
          <button className="btn btn-blue" onClick={(e) => handleSubmit(e)}>
            Continue to Next Step
          </button>
        </div>
      </form>

      {alertModel && (
        <InfoAlertModel
          modal={alertModel}
          toggle={() => setAlertModel(!alertModel)}
          updateInfo={updateInfo}
          setupdateInfo={setupdateInfo}
          handleNext={handleNext}
        />
      )}
    </div>
  );
};

export default EmploymentForm;
