import React from "react";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import LadyImage from "../../../../assets/img/screeningLady.png";
import backgroundCheck from "../../../../assets/img/backgroundCheck.png";
import { useNavigate } from "react-router-dom";
const RequestScreeningModel = ({ model, toggle }) => {
  const navigate = useNavigate();
  return (
    <Modal
      isOpen={model}
      centered
      backdrop={true}
      toggle={toggle}
      className="screening_model back_ground_screening_model"
      size="xl"
    >
      <ModalHeader toggle={toggle}></ModalHeader>
      <ModalBody className="">
        <div className="main_body row">
          <div className="col-4 part_1">
            <img src={LadyImage} />
          </div>
          <div className="col-8">
            <h5 className="text-white">
              Unlock Your Professional Potential with NobelPage Verification and
              Receive Your Profile Truth Badges!
            </h5>
            <p className="mt-2 text-light">
              Step into the spotlight with NobelPage's exclusive verification
              services, powered by our esteemed globally accepted partners. It's
              time to amplify your profile and stand out with the power of
              transparency and trust.
            </p>
            <p className="text-white fw-bold mt-2">Why choose verification?</p>
            <div className=" mt-2">
              <div className="d-flex justify-content-between align-items-center">
                <div className="p-2 col-6 rounded" style={{ width: "49.5%" }}>
                  <div className="d-flex">
                    <img src={backgroundCheck} width={20} />
                    <p
                      className="text-white fw-semi-bold"
                      style={{ fontSize: "12px" }}
                    >
                      Transparency and Trust
                    </p>
                  </div>
                  <p className="mt-1 text-light" style={{ fontSize: "10px" }}>
                    Gain verified badges to showcase your authentic educational
                    and professional background, fostering transparency and
                    trust.
                  </p>
                </div>

                <div className="p-2 col-6 rounded" style={{ width: "49.5%" }}>
                  <div className="d-flex">
                    <img src={backgroundCheck} width={20} />
                    <p
                      className="text-white fw-semi-bold"
                      style={{ fontSize: "12px" }}
                    >
                      Equality and Opportunity
                    </p>
                  </div>
                  <p className="mt-1 text-light" style={{ fontSize: "10px" }}>
                    Our commitment to equal opportunity ensures that all members
                    can display their achievements on an equitable platform.
                  </p>
                </div>
              </div>

              <div className="d-flex justify-content-between align-items-center mt-2">
                <div className="p-2 col-6 rounded" style={{ width: "49.5%" }}>
                  <div className="d-flex">
                    <img src={backgroundCheck} width={20} />
                    <p
                      className="text-white fw-semi-bold"
                      style={{ fontSize: "12px" }}
                    >
                      Credibility and Confidence
                    </p>
                  </div>
                  <p className="mt-1 text-light" style={{ fontSize: "10px" }}>
                    NobelPage’s verified badges enhance your profile's
                    credibility, giving you a competitive advantage and
                    attracting more connections and opportunities.
                  </p>
                </div>

                <div className="p-2 col-6 rounded" style={{ width: "49.5%" }}>
                  <div className="d-flex">
                    <img src={backgroundCheck} width={20} />
                    <p
                      className="text-white fw-semi-bold"
                      style={{ fontSize: "12px" }}
                    >
                      Exclusive Member Discounts
                    </p>
                  </div>
                  <p className="mt-1 text-light" style={{ fontSize: "10px" }}>
                    Enjoy exclusive discounts at rates significantly below
                    market prices, exclusively available to NobelPage members.
                  </p>
                </div>
              </div>

              <div className="d-flex justify-content-between align-items-center mt-2">
                <div className="p-2 col-6 rounded" style={{ width: "49.5%" }}>
                  <div className="d-flex">
                    <img src={backgroundCheck} width={20} />
                    <p
                      className="text-white fw-semi-bold"
                      style={{ fontSize: "12px" }}
                    >
                      Efficient and Seamless
                    </p>
                  </div>
                  <p className="mt-1 text-light" style={{ fontSize: "10px" }}>
                    Experience a rapid verification process, with results
                    typically delivered within 10 days for your convenience.
                  </p>
                </div>

                <div className="p-2 col-6 rounded" style={{ width: "49.5%" }}>
                  <div className="d-flex">
                    <img src={backgroundCheck} width={20} />
                    <p
                      className="text-white fw-semi-bold"
                      style={{ fontSize: "12px" }}
                    >
                      Reliability and Assurance
                    </p>
                  </div>
                  <p className="mt-1 text-light" style={{ fontSize: "10px" }}>
                    Count on our verification service to provide reliability and
                    assurance, strengthening your professional identity.
                  </p>
                </div>
              </div>
              <p className="text-light mt-2">
                Your journey towards enhanced professional recognition begins
                here. Are you ready to elevate your presence and unlock new
                opportunities?
              </p>
              <button
                className="btn px-3 py-2 fw-bold mt-3"
                onClick={() => navigate("/background-service")}
              >
                Elevate Your Profile Now
              </button>
            </div>
          </div>
        </div>
      </ModalBody>
    </Modal>
  );
};

export default RequestScreeningModel;
