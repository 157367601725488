import React from "react";
import logo from "../../assets/img/np-logo.svg";
import "./Legal.scss";


export default function LegalWebView(props) {

  const {data, myRef, redirect} = props

  return (
    <div>
      <div className="legal">
        <div className="bg">
          <div className="container">
            <div className="row">
              <div className="col-lg-8">
              <h1>{data.title}</h1>
                <p>
                  {data.subtitle}{" "}
                </p>
              </div>
            </div>
          </div>
          <div className="n-logo">
            <img src={logo} alt="" width="100%" />
          </div>
        </div>
        <section>
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="acc">
                  <ul>
                  {data?.body?.map((item,id)=>{
               return( <li ref={myRef} key={item.id} id={`id${id}`}
                onClick={()=>{redirect(id)} }
                >{item.title}</li>)
                 })}
                  </ul>
                  <div className="line"></div>
                </div>
                {data?.body?.map((item)=>{
               return( <div className="text  my-5" id={item.id} key={item.id}>
                  <h6>{item.title}</h6>
                  <p>
                    {item.body}
                  </p>
                </div>)
                 })}
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
}
