import axios from "axios";

class Translate {
 
  TranslatedText = async (text, sourceLang, targetLang) => {
     const TRANSLATE_API_URL = `${process.env.REACT_APP_LIBRETRANSLATE_API_URL}translate`;
    try {
      const response = await axios.post(TRANSLATE_API_URL, {
        q: text,
        source: sourceLang,
        target: targetLang,
      });
      return response.data.translatedText;
    } catch (error) {
      console.error("Error translating text:", error);
      throw error;
    }
  };
  DeductText = async (text) => {
    const LIBRETRANSLATE_API_URL = `${process.env.REACT_APP_LIBRETRANSLATE_API_URL}detect`;
    try {
      const response = await axios.post(LIBRETRANSLATE_API_URL, {
        q: text,
      });

      return response.data[0].language;
    } catch (error) {
      console.error("Error translating text:", error);
      throw error;
    }
  };
}
const translateInstance = new Translate();
export default translateInstance;
