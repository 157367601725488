import { Modal, ModalHeader, ModalBody } from "reactstrap";
import React from "react";

const ConfirmationModal = (props) => {
  const {
    modal,
    toggle,
    title,
    Confirm,
    ConfirmText,
    Reject,
    RejectText,
    text,
  } = props;

  const closeButton = () => {
    Reject && Reject();
    toggle();
  };

  return (
    <Modal
      size="md"
      isOpen={modal}
      style={{ maxWidth: "450px" }}
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      {title === "" ? (
        <></>
      ) : (
        <ModalHeader className="dicardModalHeader" toggle={() => closeButton()}>
          {title}
        </ModalHeader>
      )}
      <ModalBody>
        <div className="event-create-popup">
          {<p style={{ textAlign: "center" }}>{text}</p>}
          <div className="text-center mt-30">
            <button
              className="btn btn-light text-blue"
              onClick={() => {
                closeButton();
              }}
            >
              {RejectText || "No"}
            </button>
            <button
              className="btn btn-blue ml-30"
              onClick={() => {
                Confirm();
                toggle();
              }}
            >
              {ConfirmText || "Yes"}
            </button>
          </div>
        </div>
      </ModalBody>
    </Modal>
  );
};

export default ConfirmationModal;
