import React, { useEffect, useState } from "react";
import Navbar from "../../../pages/navbar/navbar";
import CreateCamp from "./Components/CreateCamp";
import FeatureJob from "./Components/FeatureJob";
import "./CreateCampaign.scss";
import AdPreview from "./Components/preview/AdPreview";
import AdBarPreview from "./Components/preview/AdBarPreview";
import { ToastContainer } from "react-toastify";
import Chat from "../../../pages/chat/chat";
import { Link, useLocation } from "react-router-dom";
import JobPreview from "./Components/preview/JobPreview";
import {
  getLocation,
  getMembershipById,
  getMembershipFeature,
} from "../../../store/membership/membershipActions";
import { useDispatch, useSelector } from "react-redux";

const CreateCampaign = (props) => {
  const {
    checkValidation,
    tabs,
    setTabs,
    campTitle,
    campaignDetail,
    setCampaignDetail,
    nameErrorMessage,
    setNameErrorMessage,
    websiteErr,
    setWebsiteErr,
    descriptionErrorMessage,
    setDescriptionErrorMessage,
    hashText,
    setHashText,
    savedStatus,
    setSavedStatus,
    gender,
    setGender,
    industryInput,
    setIndustryInput,
    paymentModal,
    setPaymentModal,
    createCampaign,
    scheduleDate,
    setScheduleDate,
    scheduleTime,
    setScheduleTime,
    today,
    duration,
    setDuration,
    scheduleCheck,
    setScheduleCheck,
    pageDataDropdown,
    typeIdError,
    setTypeIdError,
    errorActionLink,
    setErrorActionLink,
    locationList,
    setLocationList,
    arr,
    setArr,
    addInput,
    locationArr,
    setLocationArr,
    addLocation,
    locationInput,
    setLocationInput,
    navigate,
    postDetail,
    setPostDetail,
    locationOpen1,
    setLocationOpen1,
    locationOpen2,
    setLocationOpen2,
    locationOpen3,
    setLocationOpen3,
    locationOpen4,
    setLocationOpen4,
    locationOpen5,
    setLocationOpen5,
    id,
    selectedFeed,
    setSelectedFeed,
    campaignStatus,
  } = props;

  const location = useLocation();

  const [addActive, setAddActive] = useState(true);
  const [selectedJobId, setSelectedJobId] = useState(null);
  const isFeatured = location?.state?.isFeatured;
  const isPropmt = location?.state?.isPropmt;
  const isPublished = location?.state?.isPublished;
  const selectedPostId = location?.state?.selectedPostId;
  const [campaignMembershipPlan, setCampaignMembershipPlan] = useState([]);
  const [JobMembershipPlan, setJobMembershipPlan] = useState([]);

  console.log(JobMembershipPlan, "jobmemeurhfuhuh");

  const [countryLocation, setCountryLocation] = useState("");
  useEffect(() => {
    dispatch(getLocation()).then((res) => {
      setCountryLocation(res?.country_name);
    });
  }, []);
  const dispatch = useDispatch();
  const getCampaignMembership = () => {
    dispatch(getMembershipFeature({ country: countryLocation })).then((res) => {
      setCampaignMembershipPlan(
        res?.data?.filter((ele) => ele?.id == 43)[0]?.Feauture_Values
      );
    });
  };

  const getJobmembership = () => {
    dispatch(getMembershipFeature({ country: countryLocation })).then((res) => {
      setJobMembershipPlan(
        res?.data?.filter((ele) => ele?.id == 37)[0]?.Feauture_Values
      );
    });
  };

  useEffect(() => {
    if (countryLocation) {
      getCampaignMembership();
      getJobmembership();
    }
  }, [countryLocation]);

  useEffect(() => {
    if (isPublished) {
      setTabs("create-camp");
    }
  }, [isPublished]);

  useEffect(() => {
    if (isPropmt) {
      setTabs("create-camp");
    }
  }, []);

  useEffect(() => {
    if (isFeatured) {
      setTabs("fearure_jobs");
    } else {
      setTabs("create-camp");
    }
  }, [isFeatured]);

  console.log(campaignStatus);

  return (
    <div className="create-campaign bg-campaign">
      <Navbar isJobs={false} />
      <div className="container mt-4">
        <div className="row">
          <div className="col-lg-8">
            <div className="left">
              <div className="header">
                <div className="title d-flex justify-content-between">
                  <h3 className="">{campTitle}</h3>
                  <button
                    onClick={() => navigate("/campaign")}
                    type="button"
                    className="btn-close mt-2"
                    aria-label="Close"
                  ></button>
                </div>
              </div>
              <div className="btn-tabs">
                <div className="col-12">
                  {isPropmt && (
                    <>
                      <div
                        className={
                          tabs === "create-camp"
                            ? "btn blue-btn ms-3"
                            : "btn border-btn ms-3"
                        }
                        onClick={() => {
                          setTabs("create-camp");
                          setAddActive(true);
                        }}
                      >
                        Create Campaign
                      </div>
                      <div
                        className={
                          tabs === "fearure_jobs"
                            ? "btn blue-btn"
                            : "btn border-btn"
                        }
                        onClick={() => {
                          setTabs("fearure_jobs");
                          setAddActive(false);
                        }}
                      >
                        Feature a Job
                      </div>
                    </>
                  )}
                  {isPublished && (
                    <div
                      className={
                        tabs === "create-camp"
                          ? "btn blue-btn ms-3"
                          : "btn border-btn ms-3"
                      }
                      onClick={() => setTabs("create-camp")}
                    >
                      Create Campaign
                    </div>
                  )}
                  {isFeatured && (
                    <div
                      className={
                        tabs === "fearure_jobs"
                          ? "btn blue-btn"
                          : "btn border-btn"
                      }
                      onClick={() => setTabs("fearure_jobs")}
                    >
                      Feature a Job
                    </div>
                  )}
                </div>
              </div>
              <div className="main-body">
                {tabs === "create-camp" ? (
                  <CreateCamp
                    checkValidation={checkValidation}
                    campaignDetail={campaignDetail}
                    setCampaignDetail={setCampaignDetail}
                    nameErrorMessage={nameErrorMessage}
                    setNameErrorMessage={setNameErrorMessage}
                    websiteErr={websiteErr}
                    setWebsiteErr={setWebsiteErr}
                    descriptionErrorMessage={descriptionErrorMessage}
                    setDescriptionErrorMessage={setDescriptionErrorMessage}
                    hashText={hashText}
                    setHashText={setHashText}
                    savedStatus={savedStatus}
                    setSavedStatus={setSavedStatus}
                    gender={gender}
                    setGender={setGender}
                    industryInput={industryInput}
                    setIndustryInput={setIndustryInput}
                    paymentModal={paymentModal}
                    setPaymentModal={setPaymentModal}
                    createCampaign={createCampaign}
                    scheduleDate={scheduleDate}
                    setScheduleDate={setScheduleDate}
                    scheduleTime={scheduleTime}
                    setScheduleTime={setScheduleTime}
                    today={today}
                    duration={duration}
                    setDuration={setDuration}
                    scheduleCheck={scheduleCheck}
                    setScheduleCheck={setScheduleCheck}
                    pageDataDropdown={pageDataDropdown}
                    typeIdError={typeIdError}
                    setTypeIdError={setTypeIdError}
                    errorActionLink={errorActionLink}
                    setErrorActionLink={setErrorActionLink}
                    locationList={locationList}
                    setLocationList={setLocationList}
                    arr={arr}
                    setArr={setArr}
                    addInput={addInput}
                    locationArr={locationArr}
                    setLocationArr={setLocationArr}
                    addLocation={addLocation}
                    locationInput={locationInput}
                    setLocationInput={setLocationInput}
                    postDetail={postDetail}
                    setPostDetail={setPostDetail}
                    locationOpen1={locationOpen1}
                    setLocationOpen1={setLocationOpen1}
                    locationOpen2={locationOpen2}
                    setLocationOpen2={setLocationOpen2}
                    locationOpen3={locationOpen3}
                    setLocationOpen3={setLocationOpen3}
                    locationOpen4={locationOpen4}
                    setLocationOpen4={setLocationOpen4}
                    locationOpen5={locationOpen5}
                    setLocationOpen5={setLocationOpen5}
                    tabs={tabs}
                    selectedPostId={selectedPostId || null}
                    membership={campaignMembershipPlan}
                    selectedFeed={selectedFeed}
                    setSelectedFeed={setSelectedFeed}
                    campaignStatus={campaignStatus}
                    wpMembership={JobMembershipPlan}
                  />
                ) : (
                  <FeatureJob
                    // campaignDetail={campaignDetail}
                    // setCampaignDetail={setCampaignDetail}
                    // nameErrorMessage={nameErrorMessage}
                    // setNameErrorMessage={setNameErrorMessage}
                    // hashText={hashText}
                    // setHashText={setHashText}
                    // savedStatus={savedStatus}
                    // setSavedStatus={setSavedStatus}
                    // gender={gender}
                    // setGender={setGender}
                    // scheduleDate={scheduleDate}
                    // setScheduleDate={setScheduleDate}
                    // scheduleTime={scheduleTime}
                    // setDuration={setDuration}
                    // setScheduleTime={setScheduleTime}
                    // industryInput={industryInput}
                    // setIndustryInput={setIndustryInput}
                    paymentModal={paymentModal}
                    setPaymentModal={setPaymentModal}
                    selectedJobId={selectedJobId}
                    setSelectedJobId={setSelectedJobId}
                    membership={JobMembershipPlan}
                  />
                )}
              </div>
            </div>
          </div>
          <div className="col-lg-4">
            {addActive && !isFeatured ? (
              <>
                <AdPreview
                  desc={campaignDetail.description}
                  selectedFeed={selectedFeed}
                  campaignDetail={campaignDetail}
                />
                {campaignDetail.is_adBar ? (
                  <AdBarPreview
                    name={campaignDetail.name}
                    label={campaignDetail.action_link_label}
                    selectedFeed={selectedFeed}
                    campaignDetail={campaignDetail}
                  />
                ) : (
                  <></>
                )}
              </>
            ) : (
              <>
                {selectedJobId && (
                  <Link to="/jobs">
                    <JobPreview selectedJobId={selectedJobId} />
                  </Link>
                )}
              </>
            )}
          </div>
        </div>
      </div>
      <ToastContainer />
      <Chat />
    </div>
  );
};

export default CreateCampaign;
