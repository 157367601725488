import React from "react";
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";
import exportIcon from "../../../../assets/img/exportIcon.svg";
import exportIcon2 from "../../../../assets/img/export-icon.svg";
import { useSelector } from "react-redux";
import moment from "moment";

export const ExportToExcel = ({ apiData, fileName, type }) => {
  const { user } = useSelector((state) => state.auth);
  const fileType =
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
  const fileExtension = ".xlsx";

  const exportToCSV = (apiData, fileName) => {
    const res =
      type == "wallet"
        ? apiData?.map((ele) => {
            return {
              ...ele,
              validity: user?.data?.membership_expiry,
              user_name: `${user?.data?.first_name} ${user?.data?.last_name}`,
            };
          })
        : apiData?.map((ele) => {
            return {
              ...ele,
              user_name: `${user?.data?.first_name} ${user?.data?.last_name}`,
            };
          });
    const ws = XLSX.utils.json_to_sheet(res);
    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, fileName + fileExtension);
  };

  return (
    <>
      {type == "wallet" ? (
        <img
          src={exportIcon2}
          alt="wallet"
          onClick={() =>
            exportToCSV(
              apiData,
              `NP-WalletHistory-${moment(new Date()).format("MM-DD-YYYY")}-${
                user?.data?.id
              }`
            )
          }
        />
      ) : (
        <div
          className="btn btn-blue"
          style={{ padding: "8px 30px" }}
          onClick={(e) =>
            exportToCSV(
              apiData,
              `NP-LeadManagement-${moment(new Date()).format("MM-DD-YYYY")}-${
                user?.data?.id
              }`
            )
          }
        >
          <img src={exportIcon} alt="export_icon" className="mr-20" />
          Export
        </div>
      )}
    </>
  );
};
