import React, { useEffect, useState } from "react";
import { Line } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { campaignAPI } from "../../../api/campaignAPI";

// Register Chart.js components
ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

const LineChart = ({ campaign_id, type }) => {
  const [chartData, setChartData] = useState({
    labels: [],
    datasets: [],
  });

  const daysOfWeek = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ];

  // Fetch campaign statistics
  const fetchData = async () => {
    try {
      const res = await campaignAPI?.getCampaignStatistics({
        campaign_id,
        type,
      });
      if (res?.success && Array.isArray(res.data)) {
        // Sort data by date to ensure chronological order
        const sortedData = res.data.sort(
          (a, b) => new Date(a.date) - new Date(b.date)
        );

        // Extract labels and data points
        const labels = sortedData.map((ele) => {
          const dateObj = new Date(ele.date);
          const dayName = daysOfWeek[dateObj.getDay()];
          return `${dayName} (${dateObj.toLocaleDateString()})`;
        });

        const dataPoints = sortedData.map((ele) => Number(ele.count || 0));

        // Determine the minimum count for y-axis
        const minCount = Math.min(...dataPoints, 0); // Ensure minCount is at least 0

        setChartData({
          labels,
          datasets: [
            {
              label: "Count",
              data: dataPoints,
              fill: true,
              borderColor: "#0432a3",
              backgroundColor: "rgba(4, 50, 163, 0.1)",
              tension: 0.4,
              pointBackgroundColor: "#0432A3",
              pointBorderColor: "#0432a3",
              pointRadius: 5,
              pointHoverRadius: 7,
              borderWidth: 3,
            },
          ],
        });

        // Update chart options based on data
        setOptions({
          responsive: true,
          plugins: {
            legend: {
              display: false, // Hide the legend
            },
            tooltip: {
              mode: "index",
              intersect: false,
            },
          },
          scales: {
            y: {
              beginAtZero: minCount > 0 ? minCount : true,
              min: minCount > 0 ? minCount : undefined,
              title: {
                display: true,
                text: "Count",
              },
            },
            x: {
              title: {
                display: true,
                text: "Date",
              },
            },
          },
        });
      }
    } catch (err) {
      console.error("Error fetching campaign statistics:", err);
    }
  };

  // Initialize chart options
  const [options, setOptions] = useState({
    responsive: true,
    plugins: {
      legend: {
        display: false,
      },
    },
    scales: {
      y: {
        beginAtZero: true,
      },
    },
  });

  useEffect(() => {
    if (campaign_id) {
      fetchData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [campaign_id, type]);

  return (
    <div className="w-100">
      <Line options={options} data={chartData} />
    </div>
  );
};

export default LineChart;
