import React, { useState, useEffect } from "react";
import { Input, Modal, ModalBody, ModalHeader } from "reactstrap";
import WriteRecommendationModal from "./writeRecommendation";
import { useDispatch, useSelector } from "react-redux";
import Autocomplete from "react-autocomplete";
import { GetAllUsers } from "../../../../.././store/getAllUsers/allUsersAction";
import placeholderUser from "../../../../../assets/img/placeholder_user.jpg";
import { toast } from "react-toastify";

const GiveRecommendationModal = (props) => {
  const {
    modal,
    toggle,
    experienceData,
    title,
    writeTitle,
    setWriteTitle,
    askingRecommendation,
    user,
    writeRecommendationModal,
    setWriteRecommendationModal,
  } = props;
  const [writeRecommendModal, setWriteRecommendModal] = useState(false);

  const handleNextStep = () => {
    if (memberData) setWriteRecommendModal(true);
    else toast.error("Please Select User");
  };

  const [memberInput, setMemberInput] = useState("");
  const [memberData, setMemberData] = useState("");
  const [memberList, setMemberList] = useState([]);
  const [suggestionOpen, setSuggestionOpen] = useState(false);

  const dispatch = useDispatch();

  useEffect(() => {
    askingRecommendation
      ? setWriteTitle(`Ask ${memberData?.first_name}  to recommend you`)
      : setWriteTitle(`Write ${memberData?.first_name} a recommendation`);
  }, [memberData]);

  useEffect(() => {
    if (memberInput) {
      dispatch(GetAllUsers({ search: memberInput.val }))
        .then((res) => {
          if (res?.success) {
            const filteredData = res?.data?.filter(
              (item) => item?.id != user?.data?.id
            );
            setMemberList(filteredData);
          } else setMemberList([]);
        })
        .catch(() => {
          setMemberList([]);
        });
    }
  }, [memberInput]);

  return (
    <div>
      <Modal isOpen={modal} size="lg" className="fix-width gradient-background">
        <div className="popup">
          <div className="add-skill">
            <ModalHeader toggle={toggle}>{title}</ModalHeader>
            <ModalBody>
              <form>
                <div className="title">
                  <label>Help us personalize your request</label>
                </div>
                <div className="title">
                  <label>*Indicates required</label>
                </div>
                <div className="title mt-4">
                  <label>What do you want to recommend?</label>
                </div>
                <div className="title mb-2">
                  <label>Search for people</label>
                </div>
                <div className="autocomplete-wrapper">
                  <div className="search">
                    <Autocomplete
                      open={suggestionOpen}
                      inputProps={{ placeholder: "Search" }}
                      value={memberInput.val}
                      items={memberList}
                      getItemValue={(item) => item.first_name}
                      // shouldItemRender={(item, value) =>
                      //   item.first_name
                      //     .toLowerCase()
                      //     .indexOf(value.toLowerCase()) > -1
                      // }
                      renderMenu={(items, value) => (
                        <div className="dropdown">
                          {items.length === 0
                            ? `No matches for ${value}`
                            : items}
                        </div>
                      )}
                      renderItem={(item, isHighlighted) => (
                        <div
                          className={`item d-flex align-items-center ${
                            isHighlighted ? "selected-item" : ""
                          }`}
                        >
                          <img
                            src={
                              item.profile_img
                                ? item.profile_img
                                : placeholderUser
                            }
                            alt="dropdown_pic"
                            className="image-fit mr-10"
                            style={{ height: "36px", width: "36px" }}
                          />
                          <div>
                            <div className="name-heading-drop ">
                              {item?.first_name} {item?.last_name}
                            </div>
                            <div className="sub-heading-drop">
                              {item?.profile?.profile_headline ? (
                                item?.profile?.profile_headline
                              ) : (
                                <></>
                              )}
                            </div>
                          </div>
                        </div>
                      )}
                      onChange={(event, val) => {
                        if (val.length === 0) {
                          if (suggestionOpen) setSuggestionOpen(false);
                        } else {
                          if (!suggestionOpen) setSuggestionOpen(true);
                        }
                        setMemberInput({ val });
                      }}
                      onSelect={(val, obj) => {
                        setMemberInput({ val });
                        setMemberData(obj);
                        setSuggestionOpen(false);
                        // navigate(`/profile/${obj?.id}`);
                      }}
                      wrapperStyle={{}}
                    />
                  </div>
                </div>

                <div className="right-align-button">
                  <div className="title mt-4">
                    <label>1/2</label>
                  </div>
                  <div
                    className={
                      "btn " + (memberData ? "btn-blue" : "disabled-btn-blue")
                    }
                    style={{ marginTop: "15px" }}
                    onClick={handleNextStep}
                  >
                    Continue
                  </div>
                </div>
              </form>
            </ModalBody>
          </div>
        </div>
      </Modal>

      {writeRecommendModal && (
        <WriteRecommendationModal
          modal={writeRecommendModal}
          title={writeTitle}
          toggle={() => setWriteRecommendModal(!writeRecommendModal)}
          togglePrev={toggle}
          memberData={memberData}
          experienceData={experienceData}
          askingRecommendation={askingRecommendation}
          recommendfromPending={false}
        />
      )}
    </div>
  );
};

export default GiveRecommendationModal;
