import moment from "moment";
import React from "react";
import { useState } from "react";
import TimePicker from "rc-time-picker";
import "rc-time-picker/assets/index.css";

const ScheduleCamp = (props) => {
  const {
    scheduleDate,
    setScheduleDate,
    scheduleTime,
    setScheduleTime,
    today,
    scheduleCheck,
    campaignDetail,
    setCampaignDetail,
    duration,
  } = props;

  


  return (
    <div>
      <form>
        <div className="row mt-2">
          <div className="col">
            <div
              className={
                scheduleCheck ? "input-group" : "input-group padding-left-case"
              }
            >
              <input
                type="date"
                className="form-control"
                min={today}
                value={scheduleDate}
                onChange={(event) => {
                  setScheduleDate(event.target.value);
                  setCampaignDetail({
                    ...campaignDetail,
                    end_date: moment(event.target.value)
                      .add(duration, "d")
                      .format("YYYY-MM-DD"),
                  });
                }}
                disabled={scheduleCheck ? false : true}
                // value={ moment(scheduleDate).format("MMM DD,YYYY") }
                // onChange={event => setScheduleDate(moment(event.target.value).format("MMM DD,YYYY"))}
              />
            </div>
          </div>
          <div className="col">
            <div
              className={
                scheduleCheck ? "input-group" : "input-group padding-left-case"
              }
            >
              {/* <input type="text" className="form-control" placeholder="12:46 AM"/> */}
       
               <input
                style={{ color: "#000000" }}
                type="time"
                className="form-control"
                name="time"
                id="time"
                value={scheduleTime}
                disabled={scheduleCheck ? false : true}
                onChange={(event) => setScheduleTime(event.target.value)}
              />
            </div>
          </div>
        </div>
      </form>
      {scheduleDate && (
        <p className="mt-3">
          This campaign Ends on{" "}
          <span className="dark-blue-color">
            {moment(campaignDetail.end_date).format("MMM DD,YYYY")}
          </span>{" "}
          at{" "}
          <span className="dark-blue-color">
            {moment(scheduleTime, "HH:mm:ss").format("hh:mm A")}
          </span>
        </p>
      )}
    </div>
  );
};

export default ScheduleCamp;
