import React from "react";
import resumeImg1 from "../../assets/img/resumeImg1.png";
import resumeImg2 from "../../assets/img/resumeImg2.png";
import resumeImg3 from "../../assets/img/resumeImg3.png";
import resumeImg4 from "../../assets/img/resumeImg4.png";
import checkMark from "../../assets/img/check-mark.png";
import lock from "../../assets/img/lock.svg";
import pdfFileIcon from "../../assets/img/pdfFileIcon.png";
import blogDummyImg1 from "../../assets/img/blogDummyImg1.png";
import blogDummyImg2 from "../../assets/img/blogDummyImg2.png";
import eye from "../../assets/img/eye.svg";

import "./JobSearch.scss";
import { Link } from "react-router-dom";
import { FormGroup, Input, Label } from "reactstrap";
import RecentJobSearches from "../Jobs/components/RecentJobSearches/RecentJobSearches";

const JobSearch = () => {
  return (
    <>
      <div className="container">
        <div className="row">
          <div className="col-lg-5">
            <RecentJobSearches />
          </div>
        </div>
      </div>

      <div className="personal-details-pop-up search-default-pop-up">
        <div className="search-default-pop-up-inner">
          <h3>Personal Details</h3>
          <form>
            <div className="input-form">
              <label>Email</label>
              <input type="text" placeholder="Enter Email" />
            </div>
            <div className="input-form">
              <label>Phone Nmber</label>
              <input type="number" placeholder="Enter Phone Nmber" />
            </div>
            <div className="blue-btn-outer">
              <button className="blue-btn">Next</button>
            </div>
          </form>
        </div>
      </div>

      <div className="add-resume-pop-up search-default-pop-up">
        <div className="search-default-pop-up-inner">
          <h3>Add Resume / CV</h3>
          <h4>Use NobelPage ATS-Friendly Template</h4>
          <p>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. consectetur
            adipisci
          </p>
          <Link to="/" className="blue-link">
            Edit My Resume / CV
          </Link>
          <form>
            <div className="resume-list-outer">
              <div className="resume-list">
                <span className="title">Classic</span>
                <img src={resumeImg1} alt="Image" />

                <div className="eye-checkbox">
                  <img src={eye} alt="Image" />
                  <input
                    type="checkbox"
                    className="form-check-input"
                    defaultChecked
                  />
                </div>
              </div>
              <div className="resume-list">
                <span className="title">Functional</span>
                <img src={resumeImg2} alt="Image" />
                <span className="lock-icon">
                  <img src={lock} alt="Image" />
                  <span>Unlock More Features</span>
                </span>
              </div>
              <div className="resume-list">
                <span className="title">Modern</span>
                <img src={resumeImg3} alt="Image" />
                <span className="lock-icon">
                  <img src={lock} alt="Image" />
                  <span>Unlock More Features</span>
                </span>
              </div>
              <div className="resume-list">
                <span className="title">Combined</span>
                <img src={resumeImg4} alt="Image" />
                <span className="lock-icon">
                  <img src={lock} alt="Image" />
                  <span>Unlock More Features</span>
                </span>
              </div>
            </div>

            <div className="or">
              <span>OR</span>
            </div>
            <div className="content-box">
              <p>
                Choose your resume/CV to apply for the job. <br />
                You can choose nobelpage generated resume or you can upload a
                new resume here
              </p>
            </div>

            <div className="upload-resume-list-box">
              <div className="upload-resume-list">
                <div className="checkbox">
                  <input type="checkbox" className="form-check-input" />
                </div>
                <div className="img-icon">
                  <img src={pdfFileIcon} alt="Image" />
                </div>
                <div className="img-txt">
                  <div className="head">Resume_Name1.pdf</div>
                  <div className="date">Last updated on 23/05/2022</div>
                </div>
              </div>
            </div>

            <div className="upload-btn">
              <label for="uploadresume">Upload Resume / CV</label>
              <input type="file" id="uploadresume" name="uploadresume" />
            </div>

            <div className="blue-btn-outer">
              <button className="white-btn">Back</button>
              <button className="blue-btn">Next</button>
            </div>
          </form>
        </div>
      </div>

      <div className="add-cover-pop-up search-default-pop-up">
        <div className="search-default-pop-up-inner">
          <h3>Add Cover Letter</h3>
          <form>
            <div class="input-form">
              <input placeholder="Subject" type="text" />
            </div>
            <div class="input-form">
              <textarea placeholder="Description" />
            </div>
          </form>
          <div className="blue-btn-outer">
            <button className="white-btn">Back</button>
            <button className="blue-btn">Next</button>
          </div>
        </div>
      </div>

      <div className="questions-ans-pop-up search-default-pop-up">
        <div className="search-default-pop-up-inner">
          <h3>Personal Details</h3>
          <form>
            <div className="input-form">
              <label>How many years of experience in Adobe Photoshop?</label>
              <input type="text" placeholder="" />
            </div>
            <div className="input-form">
              <label>2. How many years of experience in Adobe Photoshop?</label>
              <input type="text" placeholder="" />
            </div>
            <div className="input-form">
              <label>2. How many years of experience in Adobe Photoshop?</label>
              <input type="text" placeholder="" />
            </div>
            <div className="input-form">
              <label>2. How many years of experience in Adobe Photoshop?</label>
              <input type="text" placeholder="" />
            </div>
            <div className="input-form">
              <label>3. How many years of experience in Adobe Photoshop?</label>
              <input type="text" placeholder="" />
            </div>
            <div className="input-form">
              <label>4. How many years of experience in Adobe Photoshop?</label>
              <input type="text" placeholder="" />
            </div>
            <div className="input-form">
              <label>5. How many years of experience in Adobe Photoshop?</label>
              <input type="text" placeholder="" />
            </div>
            <div className="input-form">
              <input type="checkbox" className="form-check-input" />I agree for
              self paste and smart interviews, which appear by the job poster as
              an information.
            </div>
            <div className="blue-btn-outer">
              <button className="white-btn">Back</button>
              <button className="blue-btn">Next</button>
            </div>
          </form>
        </div>
      </div>

      <div className="application-pop-up search-default-pop-up">
        <div className="search-default-pop-up-inner">
          <div className="text-center">
            <img src={checkMark} alt="Image" />
          </div>
          <h3>Your application was sent successfully</h3>
          <div className="content-box">
            <p>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc
              vulputate <br />
              libero et velit interdum, ac aliquet odio mattis.
            </p>
            <p>
              Prepare for an Interview{" "}
              <Link to="/" className="blue-clr">
                Click Here
              </Link>
            </p>
          </div>
          <div className="img-content-box">
            <h4>Explore our blogs for more information</h4>

            <div className="img-content-column">
              <div className="img-box">
                <img src={blogDummyImg1} alt="Image" />
              </div>
              <div className="right-box">
                <h5>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc
                  vulputate
                </h5>
                <p>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc
                  vulputate libero et velit interdum, ac aliquet odio mattis.
                  Class aptent taciti sociosqu ad
                </p>
              </div>
              <div className="img-box">
                <img src={blogDummyImg2} alt="Image" />
              </div>
              <div className="right-box">
                <h5>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc
                  vulputate{" "}
                </h5>
                <p>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc
                  vulputate libero et velit interdum, ac aliquet odio mattis.
                  Class aptent taciti sociosqu ad
                </p>
              </div>
            </div>
            <Link to="/" className="explore-more-btn blue-clr">
              Explore More
            </Link>
          </div>
          <div className="blue-btn-outer end">
            <button className="blue-btn">Close</button>
          </div>
        </div>
      </div>

      <div className="job-alerts-pop-up search-default-pop-up">
        <div className="search-default-pop-up-inner">
          <div className="head">
            <h3>Manage job alert</h3>
            <span className="cls-btn">X</span>
          </div>
          <div className="job-alerts-list-outer">
            <div className="left-list">
              <h4>UI designer</h4>
              <h5>Bengaluru, Karnataka, India (within 25 miles)</h5>
              <div className="radio-list">
                <h5>Alert frequency</h5>
                <div className="radio-list-item">
                  <input
                    type="radio"
                    className="form-check-input"
                    name="radio"
                    id="daily"
                    checked
                  />
                  <label for="daily">Daily</label>
                </div>
                <div className="radio-list-item">
                  <input
                    type="radio"
                    className="form-check-input"
                    name="radio"
                    id="every"
                  />
                  <label for="every">Every 3 days</label>
                </div>
                <div className="radio-list-item">
                  <input
                    type="radio"
                    className="form-check-input"
                    name="radio"
                    id="custom"
                  />
                  <label for="custom">
                    Custom <span className="days">3</span> days
                  </label>
                </div>
                <div className="radio-list-item">
                  <input
                    type="radio"
                    className="form-check-input"
                    name="radio"
                    id="weekly"
                  />
                  <label for="weekly">Weekly</label>
                </div>
              </div>

              <div className="radio-list">
                <h5>Get notified via</h5>
                <div className="radio-list-item">
                  <input
                    type="radio"
                    className="form-check-input"
                    name="notified"
                    id="notification"
                    checked
                  />
                  <label for="notification">Email and notification</label>
                </div>
                <div className="radio-list-item">
                  <input
                    type="radio"
                    className="form-check-input"
                    name="notified"
                    id="email"
                  />
                  <label for="email">Email</label>
                </div>
                <div className="radio-list-item">
                  <input
                    type="radio"
                    className="form-check-input"
                    name="notified"
                    id="notification"
                  />
                  <label for="notification">Notification</label>
                </div>
              </div>
            </div>
          </div>
          <div className="blue-btn-outer end">
            <button className="white-btn">Back</button>
            <button className="blue-btn">Next</button>
          </div>
        </div>
      </div>

      <div className="survey-pop-up search-default-pop-up">
        <div className="search-default-pop-up-inner">
          <h3>See if you are being paid like other professionals</h3>
          <p>
            Submit your information to see thousands of salaries and get <br />
            personalized recommendations.
          </p>
          <h4>What is your most recent position?</h4>
          <FormGroup>
            <Input name="select" type="select">
              <option value="">Job title</option>
              <option value="1">Option 1</option>
              <option value="2">Option 2</option>
              <option value="3">Option 3</option>
            </Input>
          </FormGroup>
          <FormGroup>
            <Input name="select" type="select">
              <option value="">Company</option>
              <option value="1">Option 1</option>
              <option value="2">Option 2</option>
              <option value="3">Option 3</option>
            </Input>
          </FormGroup>
          <FormGroup>
            <Input name="select" type="select">
              <option value="">Location</option>
              <option value="1">Option 1</option>
              <option value="2">Option 2</option>
              <option value="3">Option 3</option>
            </Input>
          </FormGroup>

          <h4>What is your most recent position?</h4>
          <FormGroup>
            <Input name="select" type="select">
              <option value="">Basic salary</option>
              <option value="1">Option 1</option>
              <option value="2">Option 2</option>
              <option value="3">Option 3</option>
            </Input>
          </FormGroup>
          <FormGroup>
            <Input name="select" type="select">
              <option value="">Currency</option>
              <option value="1">Option 1</option>
              <option value="2">Option 2</option>
              <option value="3">Option 3</option>
            </Input>
          </FormGroup>
          <FormGroup>
            <Input name="select" type="select">
              <option value="">Pay period</option>
              <option value="1">Option 1</option>
              <option value="2">Option 2</option>
              <option value="3">Option 3</option>
            </Input>
          </FormGroup>
          <FormGroup>
            <Input name="select" type="select">
              <option value="">Any compensations?</option>
              <option value="1">Option 1</option>
              <option value="2">Option 2</option>
              <option value="3">Option 3</option>
            </Input>
          </FormGroup>
          <FormGroup>
            <Input name="select" type="select">
              <option value="">Experience</option>
              <option value="1">Option 1</option>
              <option value="2">Option 2</option>
              <option value="3">Option 3</option>
            </Input>
          </FormGroup>

          <div className="blue-btn-outer end">
            <button className="white-btn">Close</button>
            <button className="blue-btn">Compare salary</button>
          </div>
        </div>
      </div>

      <div className="thankyou-pop-up search-default-pop-up">
        <div className="search-default-pop-up-inner">
          <div className="head">
            <h2>Thank You</h2>
            <p>For being a part of this salary comparison</p>
          </div>
          <div className="range-outer">
            <div className="range low">1.2 LPA (low)</div>
            <div className="range avrg">4.3 LPA (average)</div>
            <div className="range high">6.3 LPA (high)</div>
          </div>

          <div className="pay-box">
            <span className="title">Average</span>
            <h3>4.3 LPA</h3>
            <p>
              Is the average payment in your location for your role and
              experience
            </p>
          </div>

          <div className="pay-box">
            <span className="title">Your Pay</span>
            <h3>3.3 LPA</h3>
          </div>

          <div className="blue-btn-outer">
            <button className="white-btn">Close</button>
            <button className="blue-btn">Check Another Location</button>
          </div>
        </div>
      </div>
    </>
  );
};

export default JobSearch;
