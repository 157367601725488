import {
  GET_CAMPAIGN_LIST,
  DELETE_CAMPAIGN,
  GET_FEATURED_CAMPAIGN_LIST,
  LOAD_FEATURED_MORE_CAMPAIGNS,
  LOAD_MORE_CAMPAIGNS,
} from "../types";

// import { POST_CAMPAIGN_DATA } from "../types";
export const initialState = {
  campaignData: [],
  campaignList: [],
  featuredCampaignList: [],
};

const campaign = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    // case POST_CAMPAIGN_DATA:
    //   return {
    //     ...state,
    //     companyData: payload,
    //   };

    case GET_CAMPAIGN_LIST:
      return {
        ...state,
        campaignList: payload,
      };

    case LOAD_MORE_CAMPAIGNS:
      return {
        ...state,
        campaignList: [...state.campaignList, ...action.payload],
      };
    case DELETE_CAMPAIGN:
      const data = state?.campaignList?.filter(
        (ele) => ele.id !== action.payload
      );
      return {
        ...state,
        campaignList: data,
      };
    case GET_FEATURED_CAMPAIGN_LIST:
      return {
        ...state,
        featuredCampaignList: payload,
      };

    case LOAD_FEATURED_MORE_CAMPAIGNS:
      return {
        ...state,
        featuredCampaignList: [
          ...state.featuredCampaignList,
          ...action.payload,
        ],
      };

    default:
      return state;
  }
};

export default campaign;
