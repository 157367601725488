import React from 'react'
// import rectangleImg from "../../../assets/img/RectanglepayBycard.png";
import rectangleImg from "../../../assets/img/RectanglepayBycard.png";

const GifModal = ({children}) => {
  return (
    <div>
      {children}
      <img  style={{ height: "18px",width: "525px" }}
               src={rectangleImg} alt="thumbnail" />
    </div>
  )
}

export default GifModal
