import React, { useEffect, useRef, useState } from "react";
import { Input, Label, Modal, ModalBody, ModalHeader } from "reactstrap";
import uploadwh from "../../../assets/img/upload.svg";
import nopreview from "../../../assets/img/no-preview-talent.svg";
import uploadIcon from "../../../assets/img/upload-talent.svg";
import plus from "../../../assets/img/plus-black.svg";
import edit from "../../../assets/img/edit-black.svg";
import "./CreateTalentPool.scss";
import { SketchPicker } from "react-color";
import AddPolicyModal from "./AddPolicyModal";
import SuccessModal from "./SuccessModal";
import ProfilePhotoModal from "../../profile-pages/profile/components/ProfilePhotoModal";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { CreatePortal } from "../../../store/pages/pagesAction";
import { toast } from "react-toastify";
import closeIcon from "../../../assets/img/closeIcon2.svg";
import {
  initPageLoad,
  stopLoad,
  stopPageLoad,
} from "../../../store/loader/loaderActions";
import AppLoader from "../../../core/components/Loaders/appLoader/appLoader";
import Loader from "../../../core/components/Loaders/ComponentLoader";

const CreateTalentPool = (props) => {
  const {
    modal,
    toggle,
    successModal,
    setSuccessModal,
    setTalentPoolData,
    title,
    pageDetail,
    singlePageDetails,
    handlePortalActivate,
  } = props;
  const dispatch = useDispatch();
  const [formData, setFormData] = useState({
    companyName: pageDetail?.name || "",
    email: pageDetail?.email || "",
    logo: pageDetail?.icon || null,
    brandingColor: "#F68A18",
    bannerImage: pageDetail?.background_img || null,
    privacyPolicy: "",
    cookiesPolicy: "",
    terms: "",
  });

  const [iconName, setIconName] = useState("");
  const [openColorPicker, setOpenColorPicker] = useState(false);
  const [privacyPolicyModal, setPrivacyPolicyModal] = useState(false);
  const [cookiesPolicyModal, setCookiesPolicyModal] = useState(false);
  const [termsPolicyModal, setTermsPolicyModal] = useState(false);
  const [logoChangeModal, setLogoChangeModal] = useState(false);
  const bannerFile = useRef(null);
  const { isPageLoading } = useSelector((state) => state.loader);
  const [errorCompanyName, setErrorCompanyName] = useState("");
  const [errorEmail, setErrorEmail] = useState("");
  const [errorPrivacyPolicy, setErrorPrivacyPolicy] = useState("");
  const [errorCookiesPolicy, setErrorCookiesPolicy] = useState("");
  const [errorTermsAndConditions, setErrorTermsAndConditions] = useState("");
  const handleInputChange = (field, value) => {
    setFormData({ ...formData, [field]: value });
    if (field === "privacyPolicy") validate("privacy", value);
    else if (field === "cookiesPolicy") validate("cookie", value);
    else if (field === "terms") validate("terms", value);
  };

  const validate = (type, value = null) => {
    let isValid = true;
    const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;

    if (type == "company" || type == "all") {
      if (!formData.companyName.trim()) {
        setErrorCompanyName("Company name is required");
        isValid = false;
      } else {
        setErrorCompanyName("");
      }
    }
    if (type == "email" || type == "all") {
      if (formData.email?.trim() === "" || !emailRegex.test(formData.email)) {
        setErrorEmail("Please enter a valid email address.");
        isValid = false;
      } else {
        setErrorEmail("");
      }
    }

    if (type == "privacy" || type == "all") {
      if (value !== null) {
        if (!value || value == "<p></p>\n") {
          setErrorPrivacyPolicy("Privacy policy is required");
          isValid = false;
        } else {
          setErrorPrivacyPolicy("");
        }
      } else {
        if (
          !formData.privacyPolicy.trim() ||
          formData.privacyPolicy.trim() == "<p></p>\n"
        ) {
          setErrorPrivacyPolicy("Privacy policy is required");
          isValid = false;
        } else {
          setErrorPrivacyPolicy("");
        }
      }
    }

    if (type == "cookie" || type == "all") {
      if (value !== null || value == "<p></p>\n") {
        if (!value) {
          setErrorCookiesPolicy("Cookies policy is required");
          isValid = false;
        } else {
          setErrorCookiesPolicy("");
        }
      } else {
        if (
          !formData.cookiesPolicy.trim() ||
          formData.cookiesPolicy.trim() == "<p></p>\n"
        ) {
          setErrorCookiesPolicy("Cookies policy is required");
          isValid = false;
        } else {
          setErrorCookiesPolicy("");
        }
      }
    }
    if (type == "terms" || type == "all") {
      if (value != null) {
        if (!value || value == "<p></p>\n") {
          setErrorTermsAndConditions("Terms and conditions are required");
          isValid = false;
        } else {
          setErrorTermsAndConditions("");
        }
      } else {
        if (!formData.terms.trim() || formData.terms.trim() == "<p></p>\n") {
          setErrorTermsAndConditions("Terms and conditions are required");
          isValid = false;
        } else {
          setErrorTermsAndConditions("");
        }
      }
    }

    return isValid;
  };

  const handleCreate = () => {
    if (validate("all")) {
      const payload = {
        super_admin_email: pageDetail?.Created_by?.email,
        company_id: pageDetail?.id,
        company_name: formData?.companyName,
        email: formData?.email,
        icon: formData?.logo,
        theme: formData?.brandingColor,
        background_img: formData?.bannerImage,
        privacy_policy: formData?.privacyPolicy,
        cookie_policy: formData?.cookiesPolicy,
        terms: formData?.terms,
      };
      dispatch(CreatePortal(payload))
        .then((res) => {
          if (res?.success) {
            setSuccessModal(true);
            setTalentPoolData(res?.data);
            singlePageDetails();
            toggle();
            toast.success(res?.message, {
              position: toast.POSITION.BOTTOM_LEFT,
            });
          }
        })
        .catch((err) => {
          toast.error(err?.response?.data?.message, {
            position: toast.POSITION.BOTTOM_LEFT,
          });
        });
    }
  };

  const handleLogoChange = async (file, _) => {
    const newImage = new File([file], "newLogoCropped", { type: "image/png" });

    try {
      dispatch(initPageLoad());

      const UPLOAD_URL = `${process.env.REACT_APP_API_URL}api/v1/upload`;
      const formData = new FormData();
      formData.append("image", newImage);
      const headers = {
        "Accept-Language": "en",
        "content-type": "multipart/form-data",
      };

      await axios.post(UPLOAD_URL, formData, { headers }).then((res) => {
        handleInputChange("logo", res.data.urlsArray[0]);
        console.log(res?.data, "res?.data?.filesArray[0]");
        setIconName(res?.data?.filesArray[0].filename);
      });
      dispatch(stopPageLoad());
    } catch (err) {
      dispatch(stopPageLoad());

      console.log(err);
    }
  };

  const handleBannerChange = async (e) => {
    if (e.target?.files?.length || e.dataTransfer?.files?.length) {
      dispatch(initPageLoad());
      try {
        const UPLOAD_URL = `${process.env.REACT_APP_API_URL}api/v1/upload`;
        const formData = new FormData();
        const headers = {
          "Accept-Language": "en",
          "content-type": "multipart/form-data",
        };
        formData.append(
          "image",
          e.target?.files[0] || e.dataTransfer?.files[0]
        );
        await axios.post(UPLOAD_URL, formData, { headers }).then((res) => {
          handleInputChange("bannerImage", res.data.urlsArray[0]);
        });
        dispatch(stopPageLoad());
      } catch (err) {
        dispatch(stopPageLoad());
        console.log(err);
      }
    } else {
      toast.error("Please choose file", {
        position: toast.POSITION.BOTTOM_LEFT,
      });
    }
  };

  return (
    <div>
      {isPageLoading && <Loader />}
      <Modal isOpen={modal} style={{ width: "574px" }}>
        <div className="create-talent-pool">
          <ModalHeader className="header pb-0 px-4" toggle={toggle}>
            {title}
          </ModalHeader>
          <ModalBody>
            <div className="title mb-3">
              <Label>Company Name*</Label>
              <Input
                type="text"
                placeholder="Eg: Infinite Opensource Solution LTD"
                value={formData.companyName}
                onChange={(e) =>
                  handleInputChange("companyName", e.target.value)
                }
                onBlur={() => validate("company")}
              />
              {errorCompanyName && (
                <label className="label-input100 text-danger">
                  {errorCompanyName}
                </label>
              )}
            </div>
            <div className="title mb-3">
              <Label>Email ID*</Label>
              <Input
                type="text"
                placeholder="Superadmin@gmail.com"
                value={formData.email}
                onChange={(e) => handleInputChange("email", e.target.value)}
                onBlur={() => validate("email")}
              />
              {errorEmail && (
                <label className="label-input100 text-danger">
                  {errorEmail}
                </label>
              )}
            </div>
            <div className="title mb-3">
              <Label>Upload Company logo</Label>
              <p>
                Please insert your company logo in square format, preferably in
                PNG or SVG.
              </p>
              <div>
                <input
                  type="file"
                  onChange={(e) => handleInputChange("logo", e.target.files[0])}
                />
                <div className="text-start d-flex  mt-3">
                  <button
                    type="button"
                    className="btn btn-blue"
                    style={{ padding: "6px 40px", cursor: "pointer" }}
                    onClick={() => setLogoChangeModal(true)}
                  >
                    <img src={uploadwh} alt="upload" className="me-2 pb-2" />
                    Upload
                  </button>
                  <h6 className="ms-3 mt-2" style={{ color: "#676A79" }}>
                    {iconName
                      ? iconName.length > 10
                        ? iconName.slice(0, 10) + ".png"
                        : iconName
                      : formData.logo
                      ? "Logo.png"
                      : "No file Selected"}
                  </h6>
                </div>
              </div>
            </div>
            <div className="title mb-3">
              <Label>Branding Color</Label>
              <p>Please insert your primary branding colors.</p>
              <div>
                <div className="text-start d-flex  mt-3">
                  <div className="show-color">{formData.brandingColor}</div>
                  <div
                    className="open-color-picker"
                    style={{
                      backgroundColor: formData.brandingColor,
                      cursor: "pointer",
                    }}
                    onClick={() => setOpenColorPicker(true)}
                  ></div>
                  {openColorPicker && (
                    <div>
                      <SketchPicker
                        color={formData.brandingColor}
                        onChange={(color) =>
                          handleInputChange("brandingColor", color.hex)
                        }
                        onChangeComplete={() => setOpenColorPicker(false)}
                      />
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div className="title mb-3">
              <Label>Add Banner Image</Label>
              <div>
                <div className="big-img" style={{ position: "relative" }}>
                  <img
                    src={
                      formData.bannerImage ? formData.bannerImage : nopreview
                    }
                    className="img-cover"
                    alt=""
                    style={{ width: "100%", height: "170px" }}
                  />
                  <img
                    src={uploadIcon}
                    alt="upload"
                    className="child-img"
                    style={{
                      backgroundColor: "white",
                      borderRadius: "5px",
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      bannerFile?.current?.click();
                    }}
                  />
                  <input
                    type="file"
                    accept="image/png, image/gif, image/jpeg"
                    ref={bannerFile}
                    onChangeCapture={(e) => {
                      handleBannerChange(e);
                    }}
                  />
                  <div
                    style={{ position: "absolute", top: "5px", right: "5px" }}
                  >
                    <img
                      src={closeIcon}
                      color="#fff"
                      onClick={() =>
                        setFormData({ ...formData, bannerImage: "" })
                      }
                    />
                  </div>
                </div>
              </div>
            </div>
            <div
              className="d-flex justify-content-between mt-4"
              onClick={() => setPrivacyPolicyModal(true)}
            >
              <h6 className="text-dark">Add Company's Privacy Policy*</h6>
              {formData.privacyPolicy ? (
                <img
                  src={edit}
                  alt="pluse-icon"
                  style={{ cursor: "pointer" }}
                />
              ) : (
                <img
                  src={plus}
                  alt="pluse-icon"
                  style={{ cursor: "pointer" }}
                />
              )}
            </div>
            {errorPrivacyPolicy && (
              <label className="label-input100 text-danger">
                {errorPrivacyPolicy}
              </label>
            )}
            <div className="line my-4 border-2"></div>
            <div
              className="d-flex justify-content-between mt-4"
              onClick={() => setCookiesPolicyModal(true)}
            >
              <h6 className="text-dark">Add Company's Cookies Policy*</h6>
              {formData.cookiesPolicy ? (
                <img
                  src={edit}
                  alt="pluse-icon"
                  style={{ cursor: "pointer" }}
                />
              ) : (
                <img
                  src={plus}
                  alt="pluse-icon"
                  style={{ cursor: "pointer" }}
                />
              )}
            </div>
            {errorCookiesPolicy && (
              <label className="label-input100 text-danger">
                {errorCookiesPolicy}
              </label>
            )}
            <div className="line my-4 border-2"></div>
            <div
              className="d-flex justify-content-between mt-4"
              onClick={() => setTermsPolicyModal(true)}
            >
              <h6 className="text-dark">Add Company's Terms and Conditions*</h6>
              {formData.terms ? (
                <img
                  src={edit}
                  alt="pluse-icon"
                  style={{ cursor: "pointer" }}
                />
              ) : (
                <img
                  src={plus}
                  alt="pluse-icon"
                  style={{ cursor: "pointer" }}
                />
              )}
            </div>
            {errorTermsAndConditions && (
              <label className="label-input100 text-danger">
                {errorTermsAndConditions}
              </label>
            )}
            <div className="line my-4 border-2"></div>
            <div className="savebutton">
              <div
                className="btn btn-light text-blue me-2 px-4"
                onClick={() => toggle()}
              >
                Close
              </div>
              <div
                className="btn btn-blue ms-2 px-4"
                style={{ textAlign: "center" }}
                onClick={() => handleCreate()}
              >
                Create
              </div>
            </div>
          </ModalBody>
        </div>
      </Modal>
      {privacyPolicyModal && (
        <AddPolicyModal
          modal={privacyPolicyModal}
          toggle={() => {
            setPrivacyPolicyModal(!privacyPolicyModal);
          }}
          title={"Privacy Policy"}
          content={formData.privacyPolicy}
          field={"privacyPolicy"}
          handleChange={handleInputChange}
        />
      )}
      {cookiesPolicyModal && (
        <AddPolicyModal
          modal={cookiesPolicyModal}
          toggle={() => {
            setCookiesPolicyModal(!cookiesPolicyModal);
          }}
          title={"Cookies Policy"}
          content={formData.cookiesPolicy}
          field={"cookiesPolicy"}
          handleChange={handleInputChange}
        />
      )}
      {termsPolicyModal && (
        <AddPolicyModal
          modal={termsPolicyModal}
          toggle={() => {
            setTermsPolicyModal(!termsPolicyModal);
          }}
          title={"Terms and Conditions"}
          content={formData.terms}
          field={"terms"}
          handleChange={handleInputChange}
        />
      )}
      {/* {successModal && (
        <SuccessModal
          modal={successModal}
          toggle={() => setSuccessModal(!successModal)}
          title="Talent Pool was created successfully"
          talentPoolData={talentPoolData}
        />
      )} */}
      {logoChangeModal && (
        <ProfilePhotoModal
          modal={logoChangeModal}
          toggle={() => setLogoChangeModal(!logoChangeModal)}
          title="Talent Pool Logo"
          editProfileImage={formData.logo ? formData.logo : ""}
          handleBannerPicture={handleLogoChange}
          handleRemovePicture={(_) => {
            handleInputChange("logo", "");
          }}
          // setBannerModal={setBannerModal}
        />
      )}
    </div>
  );
};

export default CreateTalentPool;
