import { forwardRef, useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "./datepickerStyles.css";

const ExampleCustomInput = forwardRef((props, ref) => (
  <input
    // value={value}
    // className="example-custom-input"
    // onClick={onClick}
    //  onChange={onChange}
    ref={ref}
    //  placeholder="Pick"
    {...props}
  ></input>
));

export default function DatePickerCustom(props) {
  const {
    onChange,
    value,
    onClick,
    disabled,
    min,
    max,
    placeholder,
    leftAlign,
    onFocus,
    onBlur,
  } = props;

  return (
    <DatePicker
      selected={value}
      onChange={onChange}
      className={
        leftAlign ? "example-custom-input-left" : "example-custom-input"
      }
      customInput={<ExampleCustomInput />}
      dayClassName={() => "example-datepicker-day-class"}
      popperClassName="example-datepicker-class"
      disabled={disabled}
      placeholderText={placeholder}
      onClick={onClick}
      minDate={min}
      maxDate={max}
      onFocus={onFocus}
      onBlur={onBlur}
      // peekNextMonth
      value={value}
      showMonthDropdown
      showYearDropdown
      dropdownMode="select"
      //value={value}
      //maxDate={new Date("02-29-2020")}
      //    isClearable
    />
  );
}
