import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getAllCompany,
  getAllMembers,
} from "../../../store/directory/directoryAction";
import { useState } from "react";
import PeopleDirectory from "./PeopleDirectory";
import AppLoader from "../../../core/components/Loaders/appLoader/appLoader";

const PeopleDirectoryContainer = () => {
  const dispatch = useDispatch();
  const [searchKey, setSearchKey] = useState("");
  const [currentCount, setCurrentCount] = useState(0);
  const [totalCount, setTotalCount] = useState(0);
  const [searchAlphabaticly, setSearchAlphabaticly] = useState(false);
  const { publicMembersList } = useSelector((state) => state.directory);
  const { isPageLoading } = useSelector((state) => state.loader);
  useEffect(() => {
    if (searchKey.length > 0) {
      SearchMembersByText();
    } else if (searchAlphabaticly) {
      SearchAlphabaMembers();
    } else SearchAllMembers();
  }, [currentCount]);

  const SearchMembersByText = () => {
    dispatch(
      getAllMembers({
        skip: 0,
        search: searchKey ? searchKey : "",
        limit: "300",
      })
    ).then((res) => setTotalCount(res.totalItems));
  };

  const SearchAlphabaMembers = (text) => {
    setSearchAlphabaticly(true);
    dispatch(getAllMembers({ skip: 0, alpha_search: text, limit: "300" })).then(
      (res) => setTotalCount(res.totalItems)
    );
  };

  const SearchAllMembers = () => {
    dispatch(getAllMembers({ skip: currentCount, limit: "500" })).then((res) =>
      setTotalCount(res.totalItems)
    );
  };

  const handleEnterSubmit = (e) => {
    if (e.key === "Enter") {
      SearchMembersByText();
    }
  };

  useEffect(() => {
    if (!searchKey) {
      SearchAllMembers();
      // SearchAlphabaCompany("A");
      // alert("This function will be implemneted in upcoming milestone")
    }
  }, [searchKey]);

  const loadMoreData = () => {
    // setTimeout(() => {
    setCurrentCount(currentCount + 20);
    // }, 2000);
  };

  if(isPageLoading)
  return <AppLoader/>
   else return (
    <PeopleDirectory
      publicMembersList={publicMembersList}
      searchKey={searchKey}
      setSearchKey={setSearchKey}
      SearchMembersByText={SearchMembersByText}
      SearchAlphabaMembers={SearchAlphabaMembers}
      SearchAllMembers={SearchAllMembers}
      loadMoreData={loadMoreData}
      totalCount={totalCount}
      handleEnterSubmit={handleEnterSubmit}
    />
  );
};

export default PeopleDirectoryContainer;
