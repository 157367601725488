import React, { useEffect, useState } from "react";
import arro from "../../../../src/assets/img/black-aarow.svg";
import "../../explore/explore.scss";
import ExploreCardsThumbnails from "../ExploreCardsThumbnails/ExploreCardsThumbnails";
import { useNavigate } from "react-router";
import { useDispatch } from "react-redux";
import { getExploreData } from "../../../store/explore/exploreActions";

function ExploreCards(props) {
  const [isShowAll, setIsShowAll] = useState(false);
  const { setTabs, tabsList, APIdata, subTab, setSubTab } = props;

  const dispatch = useDispatch();
  const [cardAPIData, setCardAPIData] = useState(APIdata?.explores);

  const filteredData = cardAPIData?.filter((item, idx) => {
    return idx < 3;
  });

  const navigate = useNavigate();
  const ID = window.location.search.substr(13);

  useEffect(() => {
    const data = { category_id: ID };
    dispatch(getExploreData(data))
      .then((resp) => {
        setCardAPIData(resp?.data[0]?.explores);
      })
      .catch((err) => {
        console.log("error!!", err);
      });
  }, [ID]);

  const subCategory = APIdata?.sub_category;

  return (
    <div className="wrapper mt-5">
      <div className="row">
        <div className="col-lg-12">
          <div className="flex">
            <h3>{APIdata?.name}</h3>
            {cardAPIData.length > 3 && (
              <div className="show">
                <div onClick={() => setIsShowAll(!isShowAll)}>
                  <span className="show-all me-3">
                    {isShowAll ? "Show Less" : "Show All"}
                  </span>
                  {!isShowAll && (
                    <span className="me-2">
                      <img src={arro} alt="Thumbnail" />
                    </span>
                  )}
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-lg-12">
          <div className="links">
            <ul>
              {subCategory?.map((item, i) => (
                <li
                  className={subTab == item?.category_id ? "active" : ""}
                  key={i}
                  onClick={() => {
                    navigate(`/explore?category_id=${item.category_id}`);
                    setSubTab(item?.category_id);
                  }}
                >
                  {/* { console.log("item", item)} */}
                  {item.name}
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
      {isShowAll ? (
        <div className="row">
          {cardAPIData?.map((item, index) => (
            <ExploreCardsThumbnails
              item={item}
              key={item.id}
              imgURL={item.image_url}
              desc={item.description}
              thumbnailURL={item.thumbnail_url}
              title={item.title}
              user={item.user}
              id={item.id}
            />
          ))}
        </div>
      ) : (
        <div className="row">
          {filteredData?.map((item, index) => (
            <ExploreCardsThumbnails
              item={item}
              key={item.id}
              imgURL={item.image_url}
              desc={item.description}
              thumbnailURL={item.thumbnail_url}
              title={item.title}
              user={item.user}
              id={item.id}
            />
          ))}
        </div>
      )}
    </div>
  );
}

export default ExploreCards;
