import React from "react";
import "./appLoader.scss";
import logo from "../../../../assets/img/NpLoader-1.gif";

export default function AppLoader({ styleProps }) {
  return (
    <div
      className="appLoader d-flex justify-content-center align-items-center"
      style={styleProps}
    >
      <img src={logo} style={{ height: "80px", width: "80px" }} alt="logo" />
    </div>
  );
}
