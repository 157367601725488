import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {
  getUserSetting,
  updateSettingDetails,
} from "../../../store/setting/SettingAction";
import { Input, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import SearchSettings from "../components/SearchSettings";
import AppLoader from "../../../core/components/Loaders/appLoader/appLoader";

const Communications = (props) => {
  const AccountSearchList = [
    { id: "1", name: "Conversations" },
    { id: "2", name: "Jobs" },
    { id: "3", name: "Network" },
    { id: "4", name: "Profile" },
    { id: "5", name: "Email" },
    { id: "6", name: "Messaging suggestions" },
    { id: "7", name: "Message notification" },
    { id: "8", name: "Who can message me" },
    { id: "9", name: "NPMail notification" },
  ];
  const [selectedProfile, setSelectedProfile] = useState([]);
  const [defaultValue, setDefaultValue] = useState("");
  const [results, setResults] = useState([]);
  const { isActive } = props;
  const navigate = useNavigate();
  const dispatch = useDispatch();
  let token = localStorage.getItem("accessToken");
  const { settingData } = useSelector((state) => state.setting);
  const { isPageLoading } = useSelector((state) => state.loader);
  const [conversationSetting, setConversationSetting] = useState([]);
  const [jobSetting, setJobSetting] = useState([]);
  const [networkSetting, setNetworkSetting] = useState([]);
  const [profileSetting, setProfileSetting] = useState([]);
  const [emailSetting, setEmailSetting] = useState([]);
  const [suggestionSetting, setSuggestionSetting] = useState([]);
  const [notificationSetting, setNotificationSetting] = useState([]);
  const [npSetting, setNpSetting] = useState([]);
  const [archiveSetting, setArchiveSetting] = useState([]);
  const [whoSetting, setWhoSetting] = useState([]);
  const [modalOpen1, setModalOpen1] = useState(false);
  const [modalOpen2, setModalOpen2] = useState(false);
  const [modalOpen3, setModalOpen3] = useState(false);
  const [modalOpen4, setModalOpen4] = useState(false);
  const [modalOpen5, setModalOpen5] = useState(false);
  const [modalOpen6, setModalOpen6] = useState(false);
  const [modalOpen7, setModalOpen7] = useState(false);
  const [modalOpen8, setModalOpen8] = useState(false);
  const [modalOpen9, setModalOpen9] = useState(false);
  const [modalOpen10, setModalOpen10] = useState(false);

  const handleChange = (e) => {
    const { target } = e;
    if (!target.value.trim()) return setResults([]);

    const filteredValue = AccountSearchList.filter((AccountSearchList) =>
      AccountSearchList.name.toLowerCase().includes(target.value.toLowerCase())
    );
    setResults(filteredValue);
  };

  useEffect(() => {
    if (selectedProfile.id === "1") {
      setModalOpen1(true);
      setDefaultValue("");
    }
    if (selectedProfile.id === "2") {
      setModalOpen2(true);
      setDefaultValue("");
    }
    if (selectedProfile.id === "3") {
      setModalOpen3(true);
      setDefaultValue("");
    }

    if (selectedProfile.id === "4") {
      setModalOpen4(true);
      setDefaultValue("");
    }

    if (selectedProfile.id === "5") {
      setModalOpen5(true);
      setDefaultValue("");
    }

    if (selectedProfile.id === "6") {
      setModalOpen6(true);
      setDefaultValue("");
    }

    if (selectedProfile.id === "7") {
      setModalOpen7(true);
      setDefaultValue("");
    }

    if (selectedProfile.id === "8") {
      setModalOpen8(true);
      setDefaultValue("");
    }

    if (selectedProfile.id === "9") {
      setModalOpen9(true);
      setDefaultValue("");
    }
  }, [selectedProfile]);

  const toggleModal1 = () => {
    setModalOpen1(!modalOpen1);
  };

  const toggleModal2 = () => {
    setModalOpen2(!modalOpen2);
  };

  const toggleModal3 = () => {
    setModalOpen3(!modalOpen3);
  };

  const toggleModal4 = () => {
    setModalOpen4(!modalOpen4);
  };

  const toggleModal5 = () => {
    setModalOpen5(!modalOpen5);
  };

  const toggleModal6 = () => {
    setModalOpen6(!modalOpen6);
  };

  const toggleModal7 = () => {
    setModalOpen7(!modalOpen7);
  };

  const toggleModal8 = () => {
    setModalOpen8(!modalOpen8);
  };

  const toggleModal9 = () => {
    setModalOpen9(!modalOpen9);
  };

  const toggleModal10 = () => {
    setModalOpen10(!modalOpen10);
  };

  const handleConversationClose = () => {
    setModalOpen1(!modalOpen1);
    setConversationSetting(settingData?.data[24]?.settings);
  };

  const handleConversationSave = () => {
    let updatedItems = [];

    for (let i = 0; i < conversationSetting?.length; i++) {
      let myObj = {
        setting_master_id: conversationSetting[i].id,
        value: conversationSetting[i]?.value,
      };
      updatedItems.push(myObj);
    }

    let updateData = {
      setting: updatedItems,
    };

    setModalOpen1(false);
    dispatch(updateSettingDetails(updateData, token)).then((res) => {
      dispatch(getUserSetting(0, token));
      if (res.success) {
        toast.success("Conversation Settings updated successfully.", {
          position: toast.POSITION.BOTTOM_LEFT,
          toastId: "comm",
        });
      }
    });
  };

  const handleConversationChange = (value) => {
    let targetValue;
    if (value) {
      targetValue = "1";
    } else {
      targetValue = "0";
    }

    const updatedArray = conversationSetting.map((obj, i) => {
      return { ...obj, value: targetValue };
    });
    setConversationSetting(updatedArray);
  };

  const handleConversationChangeEach = (value, index) => {
    let targetValue;
    if (value) {
      targetValue = "1";
    } else {
      targetValue = "0";
    }

    const updatedArray = conversationSetting.map((obj, i) => {
      if (i == index) {
        return { ...obj, value: targetValue };
      }
      return { ...obj };
    });
    setConversationSetting(updatedArray);
  };

  const handleJobClose = () => {
    setModalOpen2(!modalOpen2);
    setJobSetting(settingData?.data[25]?.settings);
  };

  const handleJobSave = () => {
    let updatedItems = [];

    for (let i = 0; i < jobSetting?.length; i++) {
      let myObj = {
        setting_master_id: jobSetting[i].id,
        value: jobSetting[i]?.value,
      };
      updatedItems.push(myObj);
    }

    let updateData = {
      setting: updatedItems,
    };

    setModalOpen2(false);
    dispatch(updateSettingDetails(updateData, token)).then((res) => {
      dispatch(getUserSetting(0, token));
      if (res.success) {
        toast.success("Job Settings updated successfully.", {
          position: toast.POSITION.BOTTOM_LEFT,
          toastId: "comm",
        });
      }
    });
  };

  const handleJobChange = (value) => {
    let targetValue;
    if (value) {
      targetValue = "1";
    } else {
      targetValue = "0";
    }

    const updatedArray = jobSetting.map((obj, i) => {
      return { ...obj, value: targetValue };
    });
    setJobSetting(updatedArray);
  };

  const handleJobChangeEach = (value, index) => {
    let targetValue;
    if (value) {
      targetValue = "1";
    } else {
      targetValue = "0";
    }

    const updatedArray = jobSetting.map((obj, i) => {
      if (i == index) {
        return { ...obj, value: targetValue };
      }
      return { ...obj };
    });
    setJobSetting(updatedArray);
  };

  const handleNetworkClose = () => {
    setModalOpen3(!modalOpen3);
    setNetworkSetting(settingData?.data[26]?.settings);
  };

  const handleNetworkSave = () => {
    let updatedItems = [];

    for (let i = 0; i < networkSetting?.length; i++) {
      let myObj = {
        setting_master_id: networkSetting[i].id,
        value: networkSetting[i]?.value,
      };
      updatedItems.push(myObj);
    }

    let updateData = {
      setting: updatedItems,
    };

    setModalOpen3(false);
    dispatch(updateSettingDetails(updateData, token)).then((res) => {
      dispatch(getUserSetting(0, token));
      if (res.success) {
        toast.success("Network Settings updated successfully.", {
          position: toast.POSITION.BOTTOM_LEFT,
          toastId: "comm",
        });
      }
    });
  };

  const handleNetworkChange = (value) => {
    let targetValue;
    if (value) {
      targetValue = "1";
    } else {
      targetValue = "0";
    }

    const updatedArray = networkSetting.map((obj, i) => {
      return { ...obj, value: targetValue };
    });
    setNetworkSetting(updatedArray);
  };

  const handleNetworkChangeEach = (value, index) => {
    let targetValue;
    if (value) {
      targetValue = "1";
    } else {
      targetValue = "0";
    }

    const updatedArray = networkSetting.map((obj, i) => {
      if (i == index) {
        return { ...obj, value: targetValue };
      }
      return { ...obj };
    });
    setNetworkSetting(updatedArray);
  };

  const handleProfileClose = () => {
    setModalOpen4(!modalOpen4);
    setProfileSetting(settingData?.data[27]?.settings);
  };

  const handleProfileSave = () => {
    let updatedItems = [];

    for (let i = 0; i < profileSetting?.length; i++) {
      let myObj = {
        setting_master_id: profileSetting[i].id,
        value: profileSetting[i]?.value,
      };
      updatedItems.push(myObj);
    }

    let updateData = {
      setting: updatedItems,
    };

    setModalOpen4(false);
    dispatch(updateSettingDetails(updateData, token)).then((res) => {
      dispatch(getUserSetting(0, token));
      if (res.success) {
        toast.success("Profile Settings updated successfully.", {
          position: toast.POSITION.BOTTOM_LEFT,
          toastId: "comm",
        });
      }
    });
  };

  const handleProfileChange = (value) => {
    let targetValue;
    if (value) {
      targetValue = "1";
    } else {
      targetValue = "0";
    }

    const updatedArray = profileSetting.map((obj, i) => {
      return { ...obj, value: targetValue };
    });
    setProfileSetting(updatedArray);
  };

  const handleProfileChangeEach = (value, index) => {
    let targetValue;
    if (value) {
      targetValue = "1";
    } else {
      targetValue = "0";
    }

    const updatedArray = profileSetting.map((obj, i) => {
      if (i == index) {
        return { ...obj, value: targetValue };
      }
      return { ...obj };
    });
    setProfileSetting(updatedArray);
  };

  const handleEmailClose = () => {
    setModalOpen5(!modalOpen5);
    setEmailSetting(settingData?.data[28]?.settings);
  };

  const handleEmailSave = () => {
    let updatedItems = [];

    for (let i = 0; i < emailSetting?.length; i++) {
      let myObj = {
        setting_master_id: emailSetting[i].id,
        value: emailSetting[i]?.value,
      };
      updatedItems.push(myObj);
    }

    let updateData = {
      setting: updatedItems,
    };

    setModalOpen5(false);
    dispatch(updateSettingDetails(updateData, token)).then((res) => {
      dispatch(getUserSetting(0, token));
      if (res.success) {
        toast.success("Email Settings updated successfully.", {
          position: toast.POSITION.BOTTOM_LEFT,
          toastId: "comm",
        });
      }
    });
  };

  const handleEmailChange = (value) => {
    let targetValue;
    if (value) {
      targetValue = "1";
    } else {
      targetValue = "0";
    }

    const updatedArray = emailSetting.map((obj, i) => {
      return { ...obj, value: targetValue };
    });
    setEmailSetting(updatedArray);
  };

  const handleEmailChangeEach = (value, index) => {
    let targetValue;
    if (value) {
      targetValue = "1";
    } else {
      targetValue = "0";
    }

    const updatedArray = emailSetting.map((obj, i) => {
      if (i == index) {
        return { ...obj, value: targetValue };
      }
      return { ...obj };
    });
    setEmailSetting(updatedArray);
  };

  const handleSuggestionClose = () => {
    setModalOpen6(!modalOpen6);
    setSuggestionSetting(settingData?.data[29]?.settings);
  };

  const handleSuggestionSave = () => {
    let updatedItems = [];

    for (let i = 0; i < suggestionSetting?.length; i++) {
      let myObj = {
        setting_master_id: suggestionSetting[i].id,
        value: suggestionSetting[i]?.value,
      };
      updatedItems.push(myObj);
    }

    let updateData = {
      setting: updatedItems,
    };

    setModalOpen6(false);
    dispatch(updateSettingDetails(updateData, token)).then((res) => {
      dispatch(getUserSetting(0, token));
      if (res.success) {
        toast.success("Messaging suggestions Settings updated successfully.", {
          position: toast.POSITION.BOTTOM_LEFT,
          toastId: "comm",
        });
      }
    });
  };

  const handleSuggestionChange = (value) => {
    let targetValue;
    if (value) {
      targetValue = "1";
    } else {
      targetValue = "0";
    }

    const updatedArray = suggestionSetting.map((obj, i) => {
      return { ...obj, value: targetValue };
    });
    setSuggestionSetting(updatedArray);
  };

  const handleNotificationClose = () => {
    setModalOpen7(!modalOpen7);
    setSuggestionSetting(settingData?.data[30]?.settings);
  };

  const handleNotificationSave = () => {
    let updatedItems = [];

    for (let i = 0; i < notificationSetting?.length; i++) {
      let myObj = {
        setting_master_id: notificationSetting[i].id,
        value: notificationSetting[i]?.value,
      };
      updatedItems.push(myObj);
    }

    let updateData = {
      setting: updatedItems,
    };

    setModalOpen7(false);
    dispatch(updateSettingDetails(updateData, token)).then((res) => {
      dispatch(getUserSetting(0, token));
      if (res.success) {
        toast.success("Message notification Settings updated successfully.", {
          position: toast.POSITION.BOTTOM_LEFT,
          toastId: "comm",
        });
      }
    });
  };

  const handleNotificationChange = (value) => {
    let targetValue;
    if (value) {
      targetValue = "1";
    } else {
      targetValue = "0";
    }

    const updatedArray = notificationSetting.map((obj, i) => {
      return { ...obj, value: targetValue };
    });
    setNotificationSetting(updatedArray);
  };

  const handleWhoClose = () => {
    setModalOpen8(!modalOpen8);
    setWhoSetting(settingData?.data[31]?.settings);
  };

  const handleArchiveClose = () => {
    setModalOpen10(!modalOpen10);
    setArchiveSetting(settingData?.data[46]?.settings);
  };

  const handleWhoSave = () => {
    let updatedItems = [];

    for (let i = 0; i < whoSetting?.length; i++) {
      let myObj = {
        setting_master_id: whoSetting[i].id,
        value: whoSetting[i]?.value,
      };
      updatedItems.push(myObj);
    }

    let updateData = {
      setting: updatedItems,
    };

    setModalOpen8(false);
    dispatch(updateSettingDetails(updateData, token)).then((res) => {
      dispatch(getUserSetting(0, token));
      if (res.success) {
        toast.success("Who can message me Settings updated successfully.", {
          position: toast.POSITION.BOTTOM_LEFT,
          toastId: "comm",
        });
      }
    });
  };

  const handleArchiveSave = () => {
    let updatedItems = [];

    for (let i = 0; i < archiveSetting?.length; i++) {
      let myObj = {
        setting_master_id: archiveSetting[i].id,
        value: archiveSetting[i]?.value,
      };
      updatedItems.push(myObj);
    }

    let updateData = {
      setting: updatedItems,
    };

    setModalOpen10(false);
    dispatch(updateSettingDetails(updateData, token)).then((res) => {
      dispatch(getUserSetting(0, token));
      if (res.success) {
        toast.success(
          "Archive Message Duration Settings updated successfully.",
          { position: toast.POSITION.BOTTOM_LEFT, toastId: "comm" }
        );
      }
    });
  };

  const handleWhoChange = (value) => {
    const updatedArray = whoSetting.map((obj, i) => {
      return { ...obj, value: value };
    });
    setWhoSetting(updatedArray);
  };

  const handleArchiveChange = (value) => {
    const updatedArray = archiveSetting.map((obj, i) => {
      return { ...obj, value: value };
    });
    setArchiveSetting(updatedArray);
  };

  const handleNpClose = () => {
    setModalOpen9(!modalOpen9);
    setNpSetting(settingData?.data[32]?.settings);
  };

  const handleNpSave = () => {
    let updatedItems = [];

    for (let i = 0; i < npSetting?.length; i++) {
      let myObj = {
        setting_master_id: npSetting[i].id,
        value: npSetting[i]?.value,
      };
      updatedItems.push(myObj);
    }

    let updateData = {
      setting: updatedItems,
    };

    setModalOpen9(false);
    dispatch(updateSettingDetails(updateData, token)).then((res) => {
      dispatch(getUserSetting(0, token));
      if (res.success) {
        toast.success("NPMail notification Settings updated successfully.", {
          position: toast.POSITION.BOTTOM_LEFT,
          toastId: "comm",
        });
      }
    });
  };

  const handleNpChange = (value) => {
    let targetValue;
    if (value) {
      targetValue = "1";
    } else {
      targetValue = "0";
    }

    const updatedArray = npSetting.map((obj, i) => {
      return { ...obj, value: targetValue };
    });
    setNpSetting(updatedArray);
  };

  useEffect(() => {
    if (settingData) {
      setConversationSetting(settingData?.data[24]?.settings);
      setJobSetting(settingData?.data[25]?.settings);
      setNetworkSetting(settingData?.data[26]?.settings);
      setProfileSetting(settingData?.data[27]?.settings);
      setEmailSetting(settingData?.data[28]?.settings);
      setSuggestionSetting(settingData?.data[29]?.settings);
      setNotificationSetting(settingData?.data[30]?.settings);
      setWhoSetting(settingData?.data[31]?.settings);
      setNpSetting(settingData?.data[32]?.settings);
      setArchiveSetting(settingData?.data[46]?.settings);
    }
  }, [settingData]);

  return (
    <>
      {isPageLoading && <AppLoader />}
      <div>
        <div className="row">
          <div className="col-lg-6">
            <div className="heading">
              <h1>Communications</h1>
            </div>
          </div>
          <div className="col-lg-6">
            <div className="search">
              <SearchSettings
                results={results}
                value={selectedProfile?.name}
                renderItem={(item) => <p>{item.name}</p>}
                onChange={handleChange}
                defaultValue={defaultValue}
                setDefaultValue={setDefaultValue}
                onSelect={(item) => setSelectedProfile(item)}
              />
            </div>
          </div>
        </div>
        <div className="content">
          <div className="profile-information">
            <h4>Notifications</h4>
            <ul>
              <li
                className={isActive ? "active" : null}
                onClick={() => toggleModal1()}
              >
                Conversations
              </li>
              <li
                className={isActive ? "active" : null}
                onClick={() => toggleModal2()}
              >
                Jobs
              </li>
              <li
                className={isActive ? "active" : null}
                onClick={() => toggleModal3()}
              >
                Network
              </li>
              <li
                className={isActive ? "active" : null}
                onClick={() => toggleModal4()}
              >
                Profile
              </li>
              <li
                className={isActive ? "active" : null}
                onClick={() => toggleModal5()}
              >
                Email
              </li>
            </ul>
          </div>
          <div className="line"></div>
          <div className="profile-information">
            <h4>Messaging</h4>
            <ul>
              <li
                className={isActive ? "active" : null}
                onClick={() => toggleModal6()}
              >
                Messaging suggestions
              </li>
              <li
                className={isActive ? "active" : null}
                onClick={() => toggleModal7()}
              >
                Message notification
              </li>
              <li
                className={isActive ? "active" : null}
                onClick={() => toggleModal8()}
              >
                Who can message me
              </li>
              <li
                className={isActive ? "active" : null}
                onClick={() => toggleModal10()}
              >
                Archive message duration
              </li>
            </ul>
          </div>
          <div className="line"></div>
          <div className="profile-information">
            <h4>NPlMail</h4>
            <ul>
              <li
                className={isActive ? "active" : null}
                onClick={() => toggleModal9()}
              >
                NPMail notification
              </li>
            </ul>
          </div>
        </div>
        <Modal
          isOpen={modalOpen1}
          className="checkbox-list-modal setting-modal-default-modal"
        >
          <ModalHeader toggle={handleConversationClose}>
            <h4 style={{ color: "white" }}>Conversations</h4>
          </ModalHeader>
          <ModalBody>
            <div className="checkbox-box">
              <ul>
                <li className="gray-light600">
                  <span className="title-list">All conversations</span>
                  <div className="switch-box">
                    <label className="switch">
                      <input
                        type="checkbox"
                        checked={Boolean(
                          conversationSetting?.length &&
                            parseInt(conversationSetting[0]?.value)
                        )}
                        onChange={(e) =>
                          handleConversationChange(e.target.checked)
                        }
                      />
                      <span className="slider round"></span>
                    </label>
                  </div>
                </li>
                <li>
                  <span className="title-list">
                    Activity on posts and comments you're mentioned in
                  </span>
                  <div className="switch-box">
                    <label className="switch">
                      <input
                        type="checkbox"
                        disabled={Boolean(
                          conversationSetting?.length &&
                            parseInt(conversationSetting[0]?.value)
                        )}
                        checked={Boolean(
                          conversationSetting?.length &&
                            parseInt(conversationSetting[1]?.value)
                        )}
                        onChange={(e) =>
                          handleConversationChangeEach(e.target.checked, 1)
                        }
                      />
                      <span className="slider round"></span>
                    </label>
                  </div>
                </li>
                <li>
                  <span className="title-list">
                    Activity on posts and comments you've responded to
                  </span>
                  <div className="switch-box">
                    <label className="switch">
                      <input
                        type="checkbox"
                        disabled={Boolean(
                          conversationSetting?.length &&
                            parseInt(conversationSetting[0]?.value)
                        )}
                        checked={Boolean(
                          conversationSetting?.length &&
                            parseInt(conversationSetting[2]?.value)
                        )}
                        onChange={(e) =>
                          handleConversationChangeEach(e.target.checked, 2)
                        }
                      />
                      <span className="slider round"></span>
                    </label>
                  </div>
                </li>
                <li>
                  <span className="title-list">
                    Comments on your job updates
                  </span>
                  <div className="switch-box">
                    <label className="switch">
                      <input
                        type="checkbox"
                        disabled={Boolean(
                          conversationSetting?.length &&
                            parseInt(conversationSetting[0]?.value)
                        )}
                        checked={Boolean(
                          conversationSetting?.length &&
                            parseInt(conversationSetting[3]?.value)
                        )}
                        onChange={(e) =>
                          handleConversationChangeEach(e.target.checked, 3)
                        }
                      />
                      <span className="slider round"></span>
                    </label>
                  </div>
                </li>
                <li>
                  <span className="title-list">Confirmation of your posts</span>
                  <div className="switch-box">
                    <label className="switch">
                      <input
                        type="checkbox"
                        disabled={Boolean(
                          conversationSetting?.length &&
                            parseInt(conversationSetting[0]?.value)
                        )}
                        checked={Boolean(
                          conversationSetting?.length &&
                            parseInt(conversationSetting[4]?.value)
                        )}
                        onChange={(e) =>
                          handleConversationChangeEach(e.target.checked, 4)
                        }
                      />
                      <span className="slider round"></span>
                    </label>
                  </div>
                </li>
                <li>
                  <span className="title-list">
                    NPMail reminders for hiring
                  </span>
                  <div className="switch-box">
                    <label className="switch">
                      <input
                        type="checkbox"
                        disabled={Boolean(
                          conversationSetting?.length &&
                            parseInt(conversationSetting[0]?.value)
                        )}
                        checked={Boolean(
                          conversationSetting?.length &&
                            parseInt(conversationSetting[5]?.value)
                        )}
                        onChange={(e) =>
                          handleConversationChangeEach(e.target.checked, 5)
                        }
                      />
                      <span className="slider round"></span>
                    </label>
                  </div>
                </li>
                <li>
                  <span className="title-list">
                    Responses to your job updates
                  </span>
                  <div className="switch-box">
                    <label className="switch">
                      <input
                        type="checkbox"
                        disabled={Boolean(
                          conversationSetting?.length &&
                            parseInt(conversationSetting[0]?.value)
                        )}
                        checked={Boolean(
                          conversationSetting?.length &&
                            parseInt(conversationSetting[6]?.value)
                        )}
                        onChange={(e) =>
                          handleConversationChangeEach(e.target.checked, 6)
                        }
                      />
                      <span className="slider round"></span>
                    </label>
                  </div>
                </li>
                <li>
                  <span className="title-list">Message nudges</span>
                  <div className="switch-box">
                    <label className="switch">
                      <input
                        type="checkbox"
                        disabled={Boolean(
                          conversationSetting?.length &&
                            parseInt(conversationSetting[0]?.value)
                        )}
                        checked={Boolean(
                          conversationSetting?.length &&
                            parseInt(conversationSetting[7]?.value)
                        )}
                        onChange={(e) =>
                          handleConversationChangeEach(e.target.checked, 7)
                        }
                      />
                      <span className="slider round"></span>
                    </label>
                  </div>
                </li>
                <li>
                  <span className="title-list">
                    Responses to your posts, comments and poll
                  </span>
                  <div className="switch-box">
                    <label className="switch">
                      <input
                        type="checkbox"
                        disabled={Boolean(
                          conversationSetting?.length &&
                            parseInt(conversationSetting[0]?.value)
                        )}
                        checked={Boolean(
                          conversationSetting?.length &&
                            parseInt(conversationSetting[8]?.value)
                        )}
                        onChange={(e) =>
                          handleConversationChangeEach(e.target.checked, 8)
                        }
                      />
                      <span className="slider round"></span>
                    </label>
                  </div>
                </li>
                <li>
                  <span className="title-list">
                    Results of poll you've created or voted on
                  </span>
                  <div className="switch-box">
                    <label className="switch">
                      <input
                        type="checkbox"
                        disabled={Boolean(
                          conversationSetting?.length &&
                            parseInt(conversationSetting[0]?.value)
                        )}
                        checked={Boolean(
                          conversationSetting?.length &&
                            parseInt(conversationSetting[9]?.value)
                        )}
                        onChange={(e) =>
                          handleConversationChangeEach(e.target.checked, 9)
                        }
                      />
                      <span className="slider round"></span>
                    </label>
                  </div>
                </li>
                <li>
                  <span className="title-list">Views of your recent posts</span>
                  <div className="switch-box">
                    <label className="switch">
                      <input
                        type="checkbox"
                        disabled={Boolean(
                          conversationSetting?.length &&
                            parseInt(conversationSetting[0]?.value)
                        )}
                        checked={Boolean(
                          conversationSetting?.length &&
                            parseInt(conversationSetting[10]?.value)
                        )}
                        onChange={(e) =>
                          handleConversationChangeEach(e.target.checked, 10)
                        }
                      />
                      <span className="slider round"></span>
                    </label>
                  </div>
                </li>
                <li>
                  <span className="title-list">
                    Your posts featured in NobelPage storylines
                  </span>
                  <div className="switch-box">
                    <label className="switch">
                      <input
                        type="checkbox"
                        disabled={Boolean(
                          conversationSetting?.length &&
                            parseInt(conversationSetting[0]?.value)
                        )}
                        checked={Boolean(
                          conversationSetting?.length &&
                            parseInt(conversationSetting[11]?.value)
                        )}
                        onChange={(e) =>
                          handleConversationChangeEach(e.target.checked, 11)
                        }
                      />
                      <span className="slider round"></span>
                    </label>
                  </div>
                </li>
              </ul>
            </div>
            <div
              className="text-end"
              style={{
                paddingTop: "20px",
                display: "flex",
                justifyContent: "flex-end",
                columnGap: "10px",
              }}
            >
              <div
                className="btn btn-outline"
                onClick={() => handleConversationClose()}
              >
                Close
              </div>
              <div
                className="btn btn-blue"
                onClick={() => handleConversationSave()}
              >
                Save
              </div>
            </div>
          </ModalBody>
        </Modal>

        <Modal
          isOpen={modalOpen2}
          className="jobs-list-modal setting-modal-default-modal"
        >
          <ModalHeader toggle={handleJobClose}>
            <h4 style={{ color: "white" }}>Jobs</h4>
          </ModalHeader>
          <ModalBody>
            <div className="checkbox-box">
              <ul>
                <li className="gray-light600">
                  <span className="title-list">All jobs</span>
                  <div className="switch-box">
                    <label className="switch">
                      <input
                        type="checkbox"
                        checked={Boolean(
                          jobSetting?.length && parseInt(jobSetting[0]?.value)
                        )}
                        onChange={(e) => handleJobChange(e.target.checked)}
                      />
                      <span className="slider round"></span>
                    </label>
                  </div>
                </li>
                <li>
                  <span className="title-list">
                    A newly posted job recommended to you
                  </span>
                  <div className="switch-box">
                    <label className="switch">
                      <input
                        type="checkbox"
                        disabled={Boolean(
                          jobSetting?.length && parseInt(jobSetting[0]?.value)
                        )}
                        checked={Boolean(
                          jobSetting?.length && parseInt(jobSetting[1]?.value)
                        )}
                        onChange={(e) =>
                          handleJobChangeEach(e.target.checked, 1)
                        }
                      />
                      <span className="slider round"></span>
                    </label>
                  </div>
                </li>
                <li>
                  <span className="title-list">
                    A newly posted job that matches your job search alert
                  </span>
                  <div className="switch-box">
                    <label className="switch">
                      <input
                        type="checkbox"
                        disabled={Boolean(
                          jobSetting?.length && parseInt(jobSetting[0]?.value)
                        )}
                        checked={Boolean(
                          jobSetting?.length && parseInt(jobSetting[2]?.value)
                        )}
                        onChange={(e) =>
                          handleJobChangeEach(e.target.checked, 2)
                        }
                      />
                      <span className="slider round"></span>
                    </label>
                  </div>
                </li>
                <li>
                  <span className="title-list">Job application reminders</span>
                  <div className="switch-box">
                    <label className="switch">
                      <input
                        type="checkbox"
                        disabled={Boolean(
                          jobSetting?.length && parseInt(jobSetting[0]?.value)
                        )}
                        checked={Boolean(
                          jobSetting?.length && parseInt(jobSetting[3]?.value)
                        )}
                        onChange={(e) =>
                          handleJobChangeEach(e.target.checked, 3)
                        }
                      />
                      <span className="slider round"></span>
                    </label>
                  </div>
                </li>
                <li>
                  <span className="title-list">Job application views</span>
                  <div className="switch-box">
                    <label className="switch">
                      <input
                        type="checkbox"
                        disabled={Boolean(
                          jobSetting?.length && parseInt(jobSetting[0]?.value)
                        )}
                        checked={Boolean(
                          jobSetting?.length && parseInt(jobSetting[4]?.value)
                        )}
                        onChange={(e) =>
                          handleJobChangeEach(e.target.checked, 4)
                        }
                      />
                      <span className="slider round"></span>
                    </label>
                  </div>
                </li>
                <li>
                  <span className="title-list">Job change confirmation</span>
                  <div className="switch-box">
                    <label className="switch">
                      <input
                        type="checkbox"
                        disabled={Boolean(
                          jobSetting?.length && parseInt(jobSetting[0]?.value)
                        )}
                        checked={Boolean(
                          jobSetting?.length && parseInt(jobSetting[5]?.value)
                        )}
                        onChange={(e) =>
                          handleJobChangeEach(e.target.checked, 5)
                        }
                      />
                      <span className="slider round"></span>
                    </label>
                  </div>
                </li>
                <li>
                  <span className="title-list">
                    Job poster verification updates
                  </span>
                  <div className="switch-box">
                    <label className="switch">
                      <input
                        type="checkbox"
                        disabled={Boolean(
                          jobSetting?.length && parseInt(jobSetting[0]?.value)
                        )}
                        checked={Boolean(
                          jobSetting?.length && parseInt(jobSetting[6]?.value)
                        )}
                        onChange={(e) =>
                          handleJobChangeEach(e.target.checked, 6)
                        }
                      />
                      <span className="slider round"></span>
                    </label>
                  </div>
                </li>
                <li>
                  <span className="title-list">
                    Jobs you may be interested in
                  </span>
                  <div className="switch-box">
                    <label className="switch">
                      <input
                        type="checkbox"
                        disabled={Boolean(
                          jobSetting?.length && parseInt(jobSetting[0]?.value)
                        )}
                        checked={Boolean(
                          jobSetting?.length && parseInt(jobSetting[7]?.value)
                        )}
                        onChange={(e) =>
                          handleJobChangeEach(e.target.checked, 7)
                        }
                      />
                      <span className="slider round"></span>
                    </label>
                  </div>
                </li>
                <li>
                  <span className="title-list">
                    New job applicants for hiring managers
                  </span>
                  <div className="switch-box">
                    <label className="switch">
                      <input
                        type="checkbox"
                        disabled={Boolean(
                          jobSetting?.length && parseInt(jobSetting[0]?.value)
                        )}
                        checked={Boolean(
                          jobSetting?.length && parseInt(jobSetting[8]?.value)
                        )}
                        onChange={(e) =>
                          handleJobChangeEach(e.target.checked, 8)
                        }
                      />
                      <span className="slider round"></span>
                    </label>
                  </div>
                </li>
                <li>
                  <span className="title-list">
                    Reminder to complete job preferences
                  </span>
                  <div className="switch-box">
                    <label className="switch">
                      <input
                        type="checkbox"
                        disabled={Boolean(
                          jobSetting?.length && parseInt(jobSetting[0]?.value)
                        )}
                        checked={Boolean(
                          jobSetting?.length && parseInt(jobSetting[9]?.value)
                        )}
                        onChange={(e) =>
                          handleJobChangeEach(e.target.checked, 9)
                        }
                      />
                      <span className="slider round"></span>
                    </label>
                  </div>
                </li>
                <li>
                  <span className="title-list">
                    Resume downloads by recruiters
                  </span>
                  <div className="switch-box">
                    <label className="switch">
                      <input
                        type="checkbox"
                        disabled={Boolean(
                          jobSetting?.length && parseInt(jobSetting[0]?.value)
                        )}
                        checked={Boolean(
                          jobSetting?.length && parseInt(jobSetting[10]?.value)
                        )}
                        onChange={(e) =>
                          handleJobChangeEach(e.target.checked, 10)
                        }
                      />
                      <span className="slider round"></span>
                    </label>
                  </div>
                </li>
                <li>
                  <span className="title-list">Salary insights</span>
                  <div className="switch-box">
                    <label className="switch">
                      <input
                        type="checkbox"
                        disabled={Boolean(
                          jobSetting?.length && parseInt(jobSetting[0]?.value)
                        )}
                        checked={Boolean(
                          jobSetting?.length && parseInt(jobSetting[11]?.value)
                        )}
                        onChange={(e) =>
                          handleJobChangeEach(e.target.checked, 11)
                        }
                      />
                      <span className="slider round"></span>
                    </label>
                  </div>
                </li>
                <li>
                  <span className="title-list">Your job search alerts</span>
                  <div className="switch-box">
                    <label className="switch">
                      <input
                        type="checkbox"
                        disabled={Boolean(
                          jobSetting?.length && parseInt(jobSetting[0]?.value)
                        )}
                        checked={Boolean(
                          jobSetting?.length && parseInt(jobSetting[12]?.value)
                        )}
                        onChange={(e) =>
                          handleJobChangeEach(e.target.checked, 12)
                        }
                      />
                      <span className="slider round"></span>
                    </label>
                  </div>
                </li>
              </ul>
            </div>
            <div
              className="text-end"
              style={{
                paddingTop: "20px",
                display: "flex",
                justifyContent: "flex-end",
                columnGap: "10px",
              }}
            >
              <div className="btn btn-outline" onClick={() => handleJobClose()}>
                Close
              </div>
              <div className="btn btn-blue" onClick={() => handleJobSave()}>
                Save
              </div>
            </div>
          </ModalBody>
        </Modal>

        <Modal
          isOpen={modalOpen3}
          className="jobs-list-modal setting-modal-default-modal"
        >
          <ModalHeader toggle={handleNetworkClose}>
            <h4 style={{ color: "white" }}>Network</h4>
          </ModalHeader>
          <ModalBody>
            <div className="checkbox-box">
              <ul>
                <li className="gray-light600">
                  <span className="title-list">All Network</span>
                  <div className="switch-box">
                    <label className="switch">
                      <input
                        type="checkbox"
                        checked={Boolean(
                          networkSetting?.length &&
                            parseInt(networkSetting[0]?.value)
                        )}
                        onChange={(e) => handleNetworkChange(e.target.checked)}
                      />
                      <span className="slider round"></span>
                    </label>
                  </div>
                </li>
                <li>
                  <span className="title-list">Accepted event invitations</span>
                  <div className="switch-box">
                    <label className="switch">
                      <input
                        type="checkbox"
                        disabled={Boolean(
                          networkSetting?.length &&
                            parseInt(networkSetting[0]?.value)
                        )}
                        checked={Boolean(
                          networkSetting?.length &&
                            parseInt(networkSetting[1]?.value)
                        )}
                        onChange={(e) =>
                          handleNetworkChangeEach(e.target.checked, 1)
                        }
                      />
                      <span className="slider round"></span>
                    </label>
                  </div>
                </li>
                <li>
                  <span className="title-list">Accepted page invitations</span>
                  <div className="switch-box">
                    <label className="switch">
                      <input
                        type="checkbox"
                        disabled={Boolean(
                          networkSetting?.length &&
                            parseInt(networkSetting[0]?.value)
                        )}
                        checked={Boolean(
                          networkSetting?.length &&
                            parseInt(networkSetting[2]?.value)
                        )}
                        onChange={(e) =>
                          handleNetworkChangeEach(e.target.checked, 2)
                        }
                      />
                      <span className="slider round"></span>
                    </label>
                  </div>
                </li>
                <li>
                  <span className="title-list">
                    Activities from an employer or coworker
                  </span>
                  <div className="switch-box">
                    <label className="switch">
                      <input
                        type="checkbox"
                        disabled={Boolean(
                          networkSetting?.length &&
                            parseInt(networkSetting[0]?.value)
                        )}
                        checked={Boolean(
                          networkSetting?.length &&
                            parseInt(networkSetting[3]?.value)
                        )}
                        onChange={(e) =>
                          handleNetworkChangeEach(e.target.checked, 3)
                        }
                      />
                      <span className="slider round"></span>
                    </label>
                  </div>
                </li>
                <li>
                  <span className="title-list">
                    Activities from your connections
                  </span>
                  <div className="switch-box">
                    <label className="switch">
                      <input
                        type="checkbox"
                        disabled={Boolean(
                          networkSetting?.length &&
                            parseInt(networkSetting[0]?.value)
                        )}
                        checked={Boolean(
                          networkSetting?.length &&
                            parseInt(networkSetting[4]?.value)
                        )}
                        onChange={(e) =>
                          handleNetworkChangeEach(e.target.checked, 4)
                        }
                      />
                      <span className="slider round"></span>
                    </label>
                  </div>
                </li>
                <li>
                  <span className="title-list">
                    Added as speaker to an event
                  </span>
                  <div className="switch-box">
                    <label className="switch">
                      <input
                        type="checkbox"
                        disabled={Boolean(
                          networkSetting?.length &&
                            parseInt(networkSetting[0]?.value)
                        )}
                        checked={Boolean(
                          networkSetting?.length &&
                            parseInt(networkSetting[5]?.value)
                        )}
                        onChange={(e) =>
                          handleNetworkChangeEach(e.target.checked, 5)
                        }
                      />
                      <span className="slider round"></span>
                    </label>
                  </div>
                </li>
                <li>
                  <span className="title-list">Birthdays in your network</span>
                  <div className="switch-box">
                    <label className="switch">
                      <input
                        type="checkbox"
                        disabled={Boolean(
                          networkSetting?.length &&
                            parseInt(networkSetting[0]?.value)
                        )}
                        checked={Boolean(
                          networkSetting?.length &&
                            parseInt(networkSetting[6]?.value)
                        )}
                        onChange={(e) =>
                          handleNetworkChangeEach(e.target.checked, 6)
                        }
                      />
                      <span className="slider round"></span>
                    </label>
                  </div>
                </li>
                <li>
                  <span className="title-list">Connection anniversaries</span>
                  <div className="switch-box">
                    <label className="switch">
                      <input
                        type="checkbox"
                        disabled={Boolean(
                          networkSetting?.length &&
                            parseInt(networkSetting[0]?.value)
                        )}
                        checked={Boolean(
                          networkSetting?.length &&
                            parseInt(networkSetting[7]?.value)
                        )}
                        onChange={(e) =>
                          handleNetworkChangeEach(e.target.checked, 7)
                        }
                      />
                      <span className="slider round"></span>
                    </label>
                  </div>
                </li>
                <li>
                  <span className="title-list">
                    Connection invitations accepted
                  </span>
                  <div className="switch-box">
                    <label className="switch">
                      <input
                        type="checkbox"
                        disabled={Boolean(
                          networkSetting?.length &&
                            parseInt(networkSetting[0]?.value)
                        )}
                        checked={Boolean(
                          networkSetting?.length &&
                            parseInt(networkSetting[8]?.value)
                        )}
                        onChange={(e) =>
                          handleNetworkChangeEach(e.target.checked, 8)
                        }
                      />
                      <span className="slider round"></span>
                    </label>
                  </div>
                </li>
                <li>
                  <span className="title-list">Education updates</span>
                  <div className="switch-box">
                    <label className="switch">
                      <input
                        type="checkbox"
                        disabled={Boolean(
                          networkSetting?.length &&
                            parseInt(networkSetting[0]?.value)
                        )}
                        checked={Boolean(
                          networkSetting?.length &&
                            parseInt(networkSetting[9]?.value)
                        )}
                        onChange={(e) =>
                          handleNetworkChangeEach(e.target.checked, 9)
                        }
                      />
                      <span className="slider round"></span>
                    </label>
                  </div>
                </li>
                <li>
                  <span className="title-list">Event approvals to attend</span>
                  <div className="switch-box">
                    <label className="switch">
                      <input
                        type="checkbox"
                        disabled={Boolean(
                          networkSetting?.length &&
                            parseInt(networkSetting[0]?.value)
                        )}
                        checked={Boolean(
                          networkSetting?.length &&
                            parseInt(networkSetting[10]?.value)
                        )}
                        onChange={(e) =>
                          handleNetworkChangeEach(e.target.checked, 10)
                        }
                      />
                      <span className="slider round"></span>
                    </label>
                  </div>
                </li>
                <li>
                  <span className="title-list">Event requests to attend</span>
                  <div className="switch-box">
                    <label className="switch">
                      <input
                        type="checkbox"
                        disabled={Boolean(
                          networkSetting?.length &&
                            parseInt(networkSetting[0]?.value)
                        )}
                        checked={Boolean(
                          networkSetting?.length &&
                            parseInt(networkSetting[11]?.value)
                        )}
                        onChange={(e) =>
                          handleNetworkChangeEach(e.target.checked, 11)
                        }
                      />
                      <span className="slider round"></span>
                    </label>
                  </div>
                </li>
                <li>
                  <span className="title-list">Event updates</span>
                  <div className="switch-box">
                    <label className="switch">
                      <input
                        type="checkbox"
                        disabled={Boolean(
                          networkSetting?.length &&
                            parseInt(networkSetting[0]?.value)
                        )}
                        checked={Boolean(
                          networkSetting?.length &&
                            parseInt(networkSetting[12]?.value)
                        )}
                        onChange={(e) =>
                          handleNetworkChangeEach(e.target.checked, 12)
                        }
                      />
                      <span className="slider round"></span>
                    </label>
                  </div>
                </li>
                <li>
                  <span className="title-list">Group approvals to join</span>
                  <div className="switch-box">
                    <label className="switch">
                      <input
                        type="checkbox"
                        disabled={Boolean(
                          networkSetting?.length &&
                            parseInt(networkSetting[0]?.value)
                        )}
                        checked={Boolean(
                          networkSetting?.length &&
                            parseInt(networkSetting[13]?.value)
                        )}
                        onChange={(e) =>
                          handleNetworkChangeEach(e.target.checked, 13)
                        }
                      />
                      <span className="slider round"></span>
                    </label>
                  </div>
                </li>
                <li>
                  <span className="title-list">Group invitations</span>
                  <div className="switch-box">
                    <label className="switch">
                      <input
                        type="checkbox"
                        disabled={Boolean(
                          networkSetting?.length &&
                            parseInt(networkSetting[0]?.value)
                        )}
                        checked={Boolean(
                          networkSetting?.length &&
                            parseInt(networkSetting[14]?.value)
                        )}
                        onChange={(e) =>
                          handleNetworkChangeEach(e.target.checked, 14)
                        }
                      />
                      <span className="slider round"></span>
                    </label>
                  </div>
                </li>
                <li>
                  <span className="title-list">Group posts for admins</span>
                  <div className="switch-box">
                    <label className="switch">
                      <input
                        type="checkbox"
                        disabled={Boolean(
                          networkSetting?.length &&
                            parseInt(networkSetting[0]?.value)
                        )}
                        checked={Boolean(
                          networkSetting?.length &&
                            parseInt(networkSetting[15]?.value)
                        )}
                        onChange={(e) =>
                          handleNetworkChangeEach(e.target.checked, 15)
                        }
                      />
                      <span className="slider round"></span>
                    </label>
                  </div>
                </li>
                <li>
                  <span className="title-list">
                    Group posts shared by members
                  </span>
                  <div className="switch-box">
                    <label className="switch">
                      <input
                        type="checkbox"
                        disabled={Boolean(
                          networkSetting?.length &&
                            parseInt(networkSetting[0]?.value)
                        )}
                        checked={Boolean(
                          networkSetting?.length &&
                            parseInt(networkSetting[16]?.value)
                        )}
                        onChange={(e) =>
                          handleNetworkChangeEach(e.target.checked, 16)
                        }
                      />
                      <span className="slider round"></span>
                    </label>
                  </div>
                </li>
                <li>
                  <span className="title-list">
                    Job changes in your network
                  </span>
                  <div className="switch-box">
                    <label className="switch">
                      <input
                        type="checkbox"
                        disabled={Boolean(
                          networkSetting?.length &&
                            parseInt(networkSetting[0]?.value)
                        )}
                        checked={Boolean(
                          networkSetting?.length &&
                            parseInt(networkSetting[17]?.value)
                        )}
                        onChange={(e) =>
                          handleNetworkChangeEach(e.target.checked, 17)
                        }
                      />
                      <span className="slider round"></span>
                    </label>
                  </div>
                </li>
                <li>
                  <span className="title-list">Group requests to join</span>
                  <div className="switch-box">
                    <label className="switch">
                      <input
                        type="checkbox"
                        disabled={Boolean(
                          networkSetting?.length &&
                            parseInt(networkSetting[0]?.value)
                        )}
                        checked={Boolean(
                          networkSetting?.length &&
                            parseInt(networkSetting[18]?.value)
                        )}
                        onChange={(e) =>
                          handleNetworkChangeEach(e.target.checked, 18)
                        }
                      />
                      <span className="slider round"></span>
                    </label>
                  </div>
                </li>
                <li>
                  <span className="title-list">
                    New connections in your network
                  </span>
                  <div className="switch-box">
                    <label className="switch">
                      <input
                        type="checkbox"
                        disabled={Boolean(
                          networkSetting?.length &&
                            parseInt(networkSetting[0]?.value)
                        )}
                        checked={Boolean(
                          networkSetting?.length &&
                            parseInt(networkSetting[19]?.value)
                        )}
                        onChange={(e) =>
                          handleNetworkChangeEach(e.target.checked, 19)
                        }
                      />
                      <span className="slider round"></span>
                    </label>
                  </div>
                </li>
                <li>
                  <span className="title-list">
                    Work anniversaries in your network
                  </span>
                  <div className="switch-box">
                    <label className="switch">
                      <input
                        type="checkbox"
                        disabled={Boolean(
                          networkSetting?.length &&
                            parseInt(networkSetting[0]?.value)
                        )}
                        checked={Boolean(
                          networkSetting?.length &&
                            parseInt(networkSetting[20]?.value)
                        )}
                        onChange={(e) =>
                          handleNetworkChangeEach(e.target.checked, 20)
                        }
                      />
                      <span className="slider round"></span>
                    </label>
                  </div>
                </li>
              </ul>
            </div>
            <div
              className="text-end"
              style={{
                paddingTop: "20px",
                display: "flex",
                justifyContent: "flex-end",
                columnGap: "10px",
              }}
            >
              <div
                className="btn btn-outline"
                onClick={() => handleNetworkClose()}
              >
                Close
              </div>
              <div className="btn btn-blue" onClick={() => handleNetworkSave()}>
                Save
              </div>
            </div>
          </ModalBody>
        </Modal>

        <Modal
          isOpen={modalOpen4}
          className="jobs-list-modal setting-modal-default-modal"
        >
          <ModalHeader toggle={handleProfileClose}>
            <h4 style={{ color: "white" }}>Profile</h4>
          </ModalHeader>
          <ModalBody>
            <div className="checkbox-box">
              <ul>
                <li className="gray-light600">
                  <span className="title-list">All Profile</span>
                  <div className="switch-box">
                    <label className="switch">
                      <input
                        type="checkbox"
                        checked={Boolean(
                          profileSetting?.length &&
                            parseInt(profileSetting[0]?.value)
                        )}
                        onChange={(e) => handleProfileChange(e.target.checked)}
                      />
                      <span className="slider round"></span>
                    </label>
                  </div>
                </li>
                <li>
                  <span className="title-list">Who viewed your profile</span>
                  <div className="switch-box">
                    <label className="switch">
                      <input
                        type="checkbox"
                        disabled={Boolean(
                          profileSetting?.length &&
                            parseInt(profileSetting[0]?.value)
                        )}
                        checked={Boolean(
                          profileSetting?.length &&
                            parseInt(profileSetting[1]?.value)
                        )}
                        onChange={(e) =>
                          handleProfileChangeEach(e.target.checked, 1)
                        }
                      />
                      <span className="slider round"></span>
                    </label>
                  </div>
                </li>
                <li>
                  <span className="title-list">Your new followers</span>
                  <div className="switch-box">
                    <label className="switch">
                      <input
                        type="checkbox"
                        disabled={Boolean(
                          profileSetting?.length &&
                            parseInt(profileSetting[0]?.value)
                        )}
                        checked={Boolean(
                          profileSetting?.length &&
                            parseInt(profileSetting[2]?.value)
                        )}
                        onChange={(e) =>
                          handleProfileChangeEach(e.target.checked, 2)
                        }
                      />
                      <span className="slider round"></span>
                    </label>
                  </div>
                </li>
                <li>
                  <span className="title-list">Your search appearances</span>
                  <div className="switch-box">
                    <label className="switch">
                      <input
                        type="checkbox"
                        disabled={Boolean(
                          profileSetting?.length &&
                            parseInt(profileSetting[0]?.value)
                        )}
                        checked={Boolean(
                          profileSetting?.length &&
                            parseInt(profileSetting[3]?.value)
                        )}
                        onChange={(e) =>
                          handleProfileChangeEach(e.target.checked, 3)
                        }
                      />
                      <span className="slider round"></span>
                    </label>
                  </div>
                </li>
                <li>
                  <span className="title-list">Your skills endorsements</span>
                  <div className="switch-box">
                    <label className="switch">
                      <input
                        type="checkbox"
                        disabled={Boolean(
                          profileSetting?.length &&
                            parseInt(profileSetting[0]?.value)
                        )}
                        checked={Boolean(
                          profileSetting?.length &&
                            parseInt(profileSetting[4]?.value)
                        )}
                        onChange={(e) =>
                          handleProfileChangeEach(e.target.checked, 4)
                        }
                      />
                      <span className="slider round"></span>
                    </label>
                  </div>
                </li>
              </ul>
            </div>
            <div
              className="text-end"
              style={{
                paddingTop: "20px",
                display: "flex",
                justifyContent: "flex-end",
                columnGap: "10px",
              }}
            >
              <div
                className="btn btn-outline"
                onClick={() => handleProfileClose()}
              >
                Close
              </div>
              <div className="btn btn-blue" onClick={() => handleProfileSave()}>
                Save
              </div>
            </div>
          </ModalBody>
        </Modal>

        <Modal
          isOpen={modalOpen5}
          className="jobs-list-modal setting-modal-default-modal"
        >
          <ModalHeader toggle={handleEmailClose}>
            <h4 style={{ color: "white" }}>E-Mail</h4>
          </ModalHeader>
          <ModalBody>
            <div className="checkbox-box">
              <ul>
                <li className="gray-light600">
                  <span className="title-list">E-Mail Notifications</span>
                  <div className="switch-box">
                    <label className="switch">
                      <input
                        type="checkbox"
                        checked={Boolean(
                          emailSetting?.length &&
                            parseInt(emailSetting[0]?.value)
                        )}
                        onChange={(e) => handleEmailChange(e.target.checked)}
                      />
                      <span className="slider round"></span>
                    </label>
                  </div>
                </li>
                <li>
                  <span className="title-list">Profile</span>
                  <div className="switch-box">
                    <label className="switch">
                      <input
                        type="checkbox"
                        disabled={Boolean(
                          emailSetting?.length &&
                            parseInt(emailSetting[0]?.value)
                        )}
                        checked={Boolean(
                          emailSetting?.length &&
                            parseInt(emailSetting[1]?.value)
                        )}
                        onChange={(e) =>
                          handleEmailChangeEach(e.target.checked, 1)
                        }
                      />
                      <span className="slider round"></span>
                    </label>
                  </div>
                </li>
                <li>
                  <span className="title-list">Jobs</span>
                  <div className="switch-box">
                    <label className="switch">
                      <input
                        type="checkbox"
                        disabled={Boolean(
                          emailSetting?.length &&
                            parseInt(emailSetting[0]?.value)
                        )}
                        checked={Boolean(
                          emailSetting?.length &&
                            parseInt(emailSetting[2]?.value)
                        )}
                        onChange={(e) =>
                          handleEmailChangeEach(e.target.checked, 2)
                        }
                      />
                      <span className="slider round"></span>
                    </label>
                  </div>
                </li>
                <li>
                  <span className="title-list">Network</span>
                  <div className="switch-box">
                    <label className="switch">
                      <input
                        type="checkbox"
                        disabled={Boolean(
                          emailSetting?.length &&
                            parseInt(emailSetting[0]?.value)
                        )}
                        checked={Boolean(
                          emailSetting?.length &&
                            parseInt(emailSetting[3]?.value)
                        )}
                        onChange={(e) =>
                          handleEmailChangeEach(e.target.checked, 3)
                        }
                      />
                      <span className="slider round"></span>
                    </label>
                  </div>
                </li>
                <li>
                  <span className="title-list">Communications</span>
                  <div className="switch-box">
                    <label className="switch">
                      <input
                        type="checkbox"
                        disabled={Boolean(
                          emailSetting?.length &&
                            parseInt(emailSetting[0]?.value)
                        )}
                        checked={Boolean(
                          emailSetting?.length &&
                            parseInt(emailSetting[4]?.value)
                        )}
                        onChange={(e) =>
                          handleEmailChangeEach(e.target.checked, 4)
                        }
                      />
                      <span className="slider round"></span>
                    </label>
                  </div>
                </li>
              </ul>
            </div>
            <div
              className="text-end"
              style={{
                paddingTop: "20px",
                display: "flex",
                justifyContent: "flex-end",
                columnGap: "10px",
              }}
            >
              <div
                className="btn btn-outline"
                onClick={() => handleEmailClose()}
              >
                Close
              </div>
              <div className="btn btn-blue" onClick={() => handleEmailSave()}>
                Save
              </div>
            </div>
          </ModalBody>
        </Modal>

        <Modal
          isOpen={modalOpen6}
          className="profile-data-modal setting-modal-default-modal"
        >
          <ModalHeader toggle={handleSuggestionClose}>
            <h4 style={{ color: "white" }}>Messaging Suggestions</h4>
          </ModalHeader>
          <ModalBody>
            <p className="gray-light400 mt-0">
            Receive notifications for the selected option if activated.
            </p>
            <div className="checkbox-box mt-3">
              <ul>
                <li>
                  <span className="gray-light600 title-list mt-3">
                    Who viewed your profile
                  </span>
                  <div className="switch-box mt-3">
                    <label className="switch">
                      <input
                        type="checkbox"
                        checked={Boolean(
                          suggestionSetting?.length &&
                            parseInt(suggestionSetting[0]?.value)
                        )}
                        onChange={(e) =>
                          handleSuggestionChange(e.target.checked)
                        }
                      />
                      <span className="slider round"></span>
                    </label>
                  </div>
                </li>
              </ul>
            </div>
            <div
              className="text-end"
              style={{
                paddingTop: "20px",
                display: "flex",
                justifyContent: "flex-end",
                columnGap: "10px",
              }}
            >
              <div
                className="btn btn-outline"
                onClick={() => handleSuggestionClose()}
              >
                Close
              </div>
              <div
                className="btn btn-blue"
                onClick={() => handleSuggestionSave()}
              >
                Save
              </div>
            </div>
          </ModalBody>
        </Modal>

        <Modal
          isOpen={modalOpen7}
          className="profile-data-modal setting-modal-default-modal"
        >
          <ModalHeader toggle={handleNotificationClose}>
            <h4 style={{ color: "white" }}>Messaging Notification</h4>
          </ModalHeader>
          <ModalBody>
            <p className="gray-light400 mt-0">
            Receive notifications for the selected option if activated.
            </p>
            <div className="checkbox-box mt-3">
              <ul>
                <li>
                  <span className="gray-light600 title-list mt-3">
                    Who viewed your profile
                  </span>
                  <div className="switch-box mt-3">
                    <label className="switch">
                      <input
                        type="checkbox"
                        checked={Boolean(
                          notificationSetting?.length &&
                            parseInt(notificationSetting[0]?.value)
                        )}
                        onChange={(e) =>
                          handleNotificationChange(e.target.checked)
                        }
                      />
                      <span className="slider round"></span>
                    </label>
                  </div>
                </li>
              </ul>
            </div>
            <div
              className="text-end"
              style={{
                paddingTop: "20px",
                display: "flex",
                justifyContent: "flex-end",
                columnGap: "10px",
              }}
            >
              <div
                className="btn btn-outline"
                onClick={() => handleNotificationClose()}
              >
                Close
              </div>
              <div
                className="btn btn-blue"
                onClick={() => handleNotificationSave()}
              >
                Save
              </div>
            </div>
          </ModalBody>
        </Modal>

        <Modal
          isOpen={modalOpen8}
          className="last-name-radio-list-modal setting-modal-default-modal"
        >
          <ModalHeader toggle={handleWhoClose}>
            <h4 style={{ color: "white" }}>Who can message me</h4>
          </ModalHeader>
          <ModalBody>
            
            <div className="radio-list-outer">
              <div className="radio-list">
                <input
                  type="radio"
                  id="checkbox1"
                  name="checkbox"
                  checked={whoSetting?.length && whoSetting[0]?.value == "1"}
                  onChange={() => handleWhoChange("1")}
                />
                <label for="checkbox1">Everyone</label>
              </div>
              <div className="radio-list">
                <input
                  type="radio"
                  id="checkbox2"
                  name="checkbox"
                  checked={whoSetting?.length && whoSetting[0]?.value == "2"}
                  onChange={() => handleWhoChange("2")}
                />
                <label for="checkbox2">My Connections</label>
              </div>
              <div className="radio-list">
                <input
                  type="radio"
                  id="checkbox3"
                  name="checkbox"
                  checked={whoSetting?.length && whoSetting[0]?.value == "3"}
                  onChange={() => handleWhoChange("3")}
                />
                <label for="checkbox3">Scouts Only</label>
              </div>
              <div className="radio-list">
                <input
                  type="radio"
                  id="checkbox4"
                  name="checkbox"
                  checked={whoSetting?.length && whoSetting[0]?.value == "4"}
                  onChange={() => handleWhoChange("4")}
                />
                <label for="checkbox4">Nobody</label>
              </div>
            </div>
            <div
              className="text-end"
              style={{
                paddingTop: "20px",
                display: "flex",
                justifyContent: "flex-end",
                columnGap: "10px",
              }}
            >
              <div className="btn btn-outline" onClick={() => handleWhoClose()}>
                Close
              </div>
              <div className="btn btn-blue" onClick={() => handleWhoSave()}>
                Save
              </div>
            </div>
          </ModalBody>
        </Modal>

        <Modal
          isOpen={modalOpen9}
          className="profile-data-modal setting-modal-default-modal"
        >
          <ModalHeader toggle={handleNpClose}>
            <h4 style={{ color: "white" }}>NPMail notification</h4>
          </ModalHeader>
          <ModalBody>
            <p className="gray-light400 mt-0">
            Receive notifications for the selected option if activated.
            </p>
            <div className="checkbox-box mt-3">
              <ul>
                <li>
                  <span className="gray-light600 title-list mt-3">
                    Enable NPMail notification
                  </span>
                  <div className="switch-box mt-3">
                    <label className="switch">
                      <input
                        type="checkbox"
                        checked={Boolean(
                          npSetting?.length && parseInt(npSetting[0]?.value)
                        )}
                        onChange={(e) => handleNpChange(e.target.checked)}
                      />
                      <span className="slider round"></span>
                    </label>
                  </div>
                </li>
              </ul>
            </div>
            <div
              className="text-end"
              style={{
                paddingTop: "20px",
                display: "flex",
                justifyContent: "flex-end",
                columnGap: "10px",
              }}
            >
              <div className="btn btn-outline" onClick={() => handleNpClose()}>
                Close
              </div>
              <div className="btn btn-blue" onClick={() => handleNpSave()}>
                Save
              </div>
            </div>
          </ModalBody>
        </Modal>

        <Modal
          isOpen={modalOpen10}
          className="last-name-radio-list-modal setting-modal-default-modal"
        >
          <ModalHeader toggle={handleArchiveClose}>
            <h4 style={{ color: "white" }}>Archive Message Duration</h4>
          </ModalHeader>
          <ModalBody>
            {/* <p className="gray-light400 mt-0">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit.
            </p> */}
            <div className="radio-list-outer">
              <div className="radio-list">
                <input
                  type="radio"
                  id="checkbox1"
                  name="checkbox"
                  checked={
                    archiveSetting?.length && archiveSetting[0]?.value == "1"
                  }
                  onChange={() => handleArchiveChange("1")}
                />
                <label for="checkbox1">7 Days</label>
              </div>
              <div className="radio-list">
                <input
                  type="radio"
                  id="checkbox2"
                  name="checkbox"
                  checked={
                    archiveSetting?.length && archiveSetting[0]?.value == "2"
                  }
                  onChange={() => handleArchiveChange("2")}
                />
                <label for="checkbox2">15 Days</label>
              </div>
              <div className="radio-list">
                <input
                  type="radio"
                  id="checkbox3"
                  name="checkbox"
                  checked={
                    archiveSetting?.length && archiveSetting[0]?.value == "3"
                  }
                  onChange={() => handleArchiveChange("3")}
                />
                <label for="checkbox3">30 Days</label>
              </div>
              <div className="radio-list">
                <input
                  type="radio"
                  id="checkbox4"
                  name="checkbox"
                  checked={
                    archiveSetting?.length && archiveSetting[0]?.value == "4"
                  }
                  onChange={() => handleArchiveChange("4")}
                />
                <label for="checkbox4">60 Days</label>
              </div>
            </div>
            <div
              className="text-end"
              style={{
                paddingTop: "20px",
                display: "flex",
                justifyContent: "flex-end",
                columnGap: "10px",
              }}
            >
              <div
                className="btn btn-outline"
                onClick={() => handleArchiveClose()}
              >
                Close
              </div>
              <div className="btn btn-blue" onClick={() => handleArchiveSave()}>
                Save
              </div>
            </div>
          </ModalBody>
        </Modal>
      </div>
    </>
  );
};

export default Communications;
