import { recuriterSearchAPI } from "../../api/recuriterSearchAPI";
import { initPageLoad, stopPageLoad } from "../loader/loaderActions";
import {
  GET_CANDIDATE_LIST,
  GET_CANDIDATE_REQUESTED_DATA,
  GET_MORE_CANDIDATE_LIST,
} from "../types";

export const getCandidateList = (params) => (dispatch) => {
  dispatch(initPageLoad());
  return recuriterSearchAPI.getRecuriterSearchList(params).then(
    (response) => {
      console.log(response);
      if (response.success) {
        if (params.skip === 0) {
          dispatch({
            type: GET_CANDIDATE_LIST,
            payload: response.data,
          });
        } else {
          dispatch({
            type: GET_MORE_CANDIDATE_LIST,
            payload: response?.data,
          });
        }
      }

      dispatch(stopPageLoad());
      return Promise.resolve(response);
    },
    (error) => {
      console.log(error);
      dispatch(stopPageLoad());
      return Promise.reject(error);
    }
  );
};
export const requestforCandidateDetails = (data) => (dispatch, getState) => {
  return recuriterSearchAPI.reequestCandidateDetails(data).then(
    (response) => {
      if (response.success) {
        dispatch({
          type: GET_CANDIDATE_REQUESTED_DATA,
          payload: response.data,
        });
      }
      return Promise.resolve(response);
    },
    (error) => {
      console.log(error);
      return Promise.reject(error);
    }
  );
};
