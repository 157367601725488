import React, { useEffect } from "react";
import Directory from "./Directory";
import { useDispatch, useSelector } from "react-redux";
import { getAllCompany } from "../../store/directory/directoryAction";
import { useState } from "react";
import AppLoader from "../../core/components/Loaders/appLoader/appLoader";

const DirectoryContainer = () => {
  const dispatch = useDispatch();
  const [searchKey, setSearchKey] = useState("");
  const { companyData } = useSelector((state) => state.directory);
  const { isPageLoading } = useSelector((state) => state.loader);
  useEffect(() => {
    //  dispatch(getAllCompany({ }));
    SearchAlphabaCompany("A");
  }, []);

  const SearchCompany = () => {
    dispatch(getAllCompany({ search: searchKey ? searchKey : "" }));
  };

  const SearchAlphabaCompany = (text) => {
    dispatch(getAllCompany({ search: text }));
  };

  const SearchAllCompany = () => {
    dispatch(getAllCompany({ category: "company", size: "500" }));
  };

  useEffect(() => {
    if (!searchKey) {
      // SearchAllCompany();
      SearchAlphabaCompany("A");
    }
  }, [searchKey]);

  const handleEnterSubmit = (e) => {
    if (e.key === "Enter") {
      SearchCompany();
    }
  };
  if (isPageLoading) return <AppLoader />;
  else
    return (
      <Directory
        companyData={companyData}
        searchKey={searchKey}
        setSearchKey={setSearchKey}
        SearchCompany={SearchCompany}
        SearchAlphabaCompany={SearchAlphabaCompany}
        SearchAllCompany={SearchAllCompany}
        handleEnterSubmit={handleEnterSubmit}
      />
    );
};

export default DirectoryContainer;
