import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { GetAllUsers } from "../../../store/getAllUsers/allUsersAction";
import placeholderUser from "../../../assets/img/placeholder_user.jpg";

const TagPeopleModal = (props) => {
  const { toggle, indexofData, setSelected, selected, setShowClickMsg } = props;
  const [filteredData, setFilteredData] = useState([]);
  const [wordEntered, setWordEntered] = useState("");

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(GetAllUsers());
    setShowClickMsg(false);

    
  }, []);

  const handleChange = (e) => {
    setWordEntered(e.target.value);
    if (e.target.value != "") {
      dispatch(GetAllUsers({ search: e.target.value })).then((res) => {
        let newData = res.data.filter((element) => {
          let data = true;
          let alreadySelectedUser = selected[indexofData.id];
          for (let index = 0; index < alreadySelectedUser?.length; index++) {
            if (alreadySelectedUser[index]?.data?.id == element.id) {
              data = false;
              break;
            }
          }
          return data;
        });
        setFilteredData(newData);
      });
    } else setFilteredData([]);
  };

  const selectItem = (item, e) => {
    try {
      var element = document.getElementById(indexofData.id);
      var position = element.getBoundingClientRect();
      var x = indexofData.x - position.x;
      var y = indexofData.y - position.y;
      let data = {
        x: x,
        y: y,
        data: item,
      };
      setSelected(data, indexofData.id);
    } catch (error) {
      console.log("error", error);
    }
  };

  const selectItemData = (item) => {
    try {
      selectItem(item);
      toggle();
    } catch (error) {
      console.log("error", error);
    }
  };

  return (
    <div
      style={{
        position: "fixed",
        color: "black",
        maxWidth: "228px",
        left: indexofData.x,
        top: indexofData.y,
        zIndex: 999,
      }}
    >
      {
        <div className="search-tag">
          <div className="searchInputs">
            <input
              type="text"
              value={wordEntered}
              onChange={handleChange}
              autoFocus
            />
          </div>
          {filteredData?.length != 0 && (
            <div className="dataResult px-2">
              {filteredData?.map((item, key) => (
                <div
                  key={key}
                  className="row"
                  onClick={(e) => {
                    selectItemData(item);
                  }}
                >
                  <div className="col-3">
                    <img
                      className="img-fluid imgList"
                      src={
                        item.profile_img ? item.profile_img : placeholderUser
                      }
                      alt="img"
                    />
                  </div>
                  <div className="col-9">
                    <h6>
                      {item.first_name} {item.last_name}
                    </h6>
                    <small>{item.designation}</small>
                  </div>
                </div>
              ))}
            </div>
          )}
        </div>
      }
    </div>
  );
};

export default TagPeopleModal;
