import React from "react";
import Header from "../../core/components/layout/Header/Header";
import Loginfooter from "../../core/components/layout/footer/LoginFooter";
import logo from "../../assets/img/np-logo.svg";
import community from "../../assets/img/community-Frame.svg";

export default function Community(props) {
  const { data } = props;

  return (
    <div>
      <Header />
      <div className="community">
        <div className="bg">
          <div className="container">
            <div className="row">
              <div className="col-sm-8 margin-top">
                <h1>{data.title}</h1>
                <p style={{ marginTop: "30px" }}>{data.subtitle} </p>
              </div>
              <div className="col-sm-4">
                <img src={community} alt="" className="bg-image" />
              </div>
            </div>
          </div>
          <div className="n-logo">
            <img src={logo} alt="" width="100%" />
          </div>
        </div>
        <div className="context">
          <section className="padding">
            <div className="container">
              <div className="row">
                <div className="col-lg-12">
                  {data?.body?.length > 0 && data?.body[0]?.body ? (
                    <div className="content">
                      <p
                        dangerouslySetInnerHTML={{
                          __html: data?.body[0]?.body,
                        }}
                      />
                    </div>
                  ) : (
                    <>
                      <p>NoblePage is loading...</p>
                    </>
                  )}

                  {/* {data?.body?.map((item,index)=>{
               return(
                  <div className="text my-5" key={index}>
                    <h6>{item.title}</h6>
                    <p>
                      {item.body}
                    </p>
                  </div>
                  )
                })} */}
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
      <div className="line mt-3"></div>
      <Loginfooter />
    </div>
  );
}
