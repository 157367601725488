import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getUserProfile } from "../../store/profile/profileActions";
import { updateUserDetails } from "../../store/profile/profileActions";
import { useNavigate } from "react-router-dom";
import { deleteUserExperience } from "../../store/profile/profileActions";
import { deleteUserEducation } from "../../store/profile/profileActions";
import { loadUser } from "../../store/auth/authActions";
import Intro from "./Intro";
import axios from "axios";

export default function IntroContainer() {
  const { profileData } = useSelector((state) => state.profile);
  const { user } = useSelector((state) => state.auth);

  const token = localStorage.getItem("accessToken");

  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    let id = user?.data?.id;
    if (id) {
      dispatch(getUserProfile(id, token));
    }
  }, [user]);

  const [addExpModal, setAddExpModal] = useState(false);
  const [educationModal, setEducationModal] = useState(false);
  const [addContactInfoModal, setAddContactInfoModal] = useState(false);
  const [errorMessage, setErrorMessage] = useState();
  const [backgroundPhotoModal, setBackgroundPhotoModal] = useState(false);
  const [profilePhotoModal, setProfilePhotoModal] = useState(false);
  const [removeExpModal, setRemoveExpModal] = useState(false);
  const [removeEduModal, setRemoveEduModal] = useState(false);
  const [removeExpId, setRemoveExpId] = useState(false);
  const [removeEduId, setRemoveEduId] = useState(false);
  const [customProfileModal, setCustomProfileModal] = useState(false);
  const [cb, setcb] = useState();
  console.log(profileData);
  const [pageData, setPageData] = useState({
    profile_headline: profileData?.profile_headline || "",
    educationCheck: false,
    educationName: "",
    experienceName: "",
    experienceCheck: false,
  });

  useEffect(() => {
    setPageData({
      profile_headline: profileData?.profile_headline || "",
      educationCheck: false,
      educationName: "",
      experienceName: "",
      experienceCheck: false,
    });
  }, [profileData]);

  const deleteExperience = (data) => {
    const expId = data.id;
    dispatch(deleteUserExperience(expId, token))
      .then(() => {})
      .catch((e) => {
        console.log(e);
      });
  };

  const deleteEducation = (data) => {
    const expId = data.id;
    dispatch(deleteUserEducation(expId, token))
      .then(() => {})
      .catch((e) => {
        console.log(e);
      });
  };

  const handleSubmit = async () => {
    if (pageData.profile_headline.trim()) {
      let formatProfileData = {
        profile_headline: pageData.profile_headline,
        education_id: profileData?.educations[0]?.id,
        experience_id: profileData?.experiences[0]?.id,
      };
      dispatch(updateUserDetails(formatProfileData, token))
        .then(() => {
          dispatch(loadUser(setcb)).then(() => {
            navigate("/dashboard");
          });
        })
        .catch((e) => {
          console.log(e);
        });
    } else setErrorMessage("The field is Mandatory");
  };

  const map = {};
  profileData?.experiences?.forEach((obj) => {
    const { company_name, value } = obj;
    if (map[company_name]) {
      map[company_name].push(obj);
    } else {
      map[company_name] = [obj];
    }
  });

  const newExperienceData = Object.entries(map).map(([key, value]) => ({
    [key]: value,
  }));

  const handleBannerPicture = async (file, type) => {
    const newImage = new File([file], "newEventCropped", { type: "image/png" });

    try {
      const UPLOAD_URL = `${process.env.REACT_APP_API_URL}api/v1/upload`;
      const formData = new FormData();
      const headers = {
        "Accept-Language": "en",
        "content-type": "multipart/form-data",
      };

      formData.append("image", newImage);

      await axios.post(UPLOAD_URL, formData, { headers }).then((res) => {
        if (type === "profile") {
          let formatProfileData = {
            profile_img: res?.data?.urlsArray[0],
          };
          dispatch(updateUserDetails(formatProfileData, token));
        } else if (type === "background") {
          let formatProfileData = {
            background_img: res?.data?.urlsArray[0],
          };
          dispatch(updateUserDetails(formatProfileData, token));
        }
      });
    } catch (err) {
      console.log(err);
    }
    // setProfilePhotoModal(false);
  };

  const handleRemovePicture = (type) => {
    if (type === "profile") {
      let formatProfileData = {
        profile_img: "",
      };
      dispatch(updateUserDetails(formatProfileData, token));
    } else if (type === "background") {
      let formatProfileData = {
        background_img: "",
      };
      dispatch(updateUserDetails(formatProfileData, token));
    }
  };

  return (
    <Intro
      addExpModal={addExpModal}
      setAddExpModal={setAddExpModal}
      addContactInfoModal={addContactInfoModal}
      setAddContactInfoModal={setAddContactInfoModal}
      educationModal={educationModal}
      setEducationModal={setEducationModal}
      pageData={pageData}
      setPageData={setPageData}
      newExperienceData={newExperienceData}
      deleteExperience={deleteExperience}
      deleteEducation={deleteEducation}
      handleSubmit={handleSubmit}
      errorMessage={errorMessage}
      setErrorMessage={setErrorMessage}
      profileData={profileData}
      backgroundPhotoModal={backgroundPhotoModal}
      setBackgroundPhotoModal={setBackgroundPhotoModal}
      handleBannerPicture={handleBannerPicture}
      profilePhotoModal={profilePhotoModal}
      setProfilePhotoModal={setProfilePhotoModal}
      handleRemovePicture={handleRemovePicture}
      removeExpModal={removeExpModal}
      setRemoveExpModal={setRemoveExpModal}
      removeEduModal={removeEduModal}
      setRemoveEduModal={setRemoveEduModal}
      removeExpId={removeExpId}
      setRemoveExpId={setRemoveExpId}
      removeEduId={removeEduId}
      setRemoveEduId={setRemoveEduId}
      customProfileModal={customProfileModal}
      setCustomProfileModal={setCustomProfileModal}
    />
  );
}
