import React, { useEffect, useState, useRef } from "react";
import HashtagPosts from "./hashtagPosts";
import { useDispatch, useSelector } from "react-redux";
import { loadUser } from "../../../store/auth/authActions";
import {
  deletePost,
  savePost,
  savePostDelete,
  createPost,
  repost_post,
  hide_user_post,
  getPageDetails,
} from "../../../store/dashboard/dashboardActions";
import {
  profile_follower,
  profile_unfollower,
} from "../../../store/profile/profileActions";
import { reactionsData } from "../../../store/reactionPost/reactionAction";
import { sendConnectionRequest } from "../../../store/connections/connectionsActions";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import {
  followPage,
  getAllPages,
  unFollowPage,
} from "../../../store/pages/pagesAction";
import {
  getHashTagPostFeed,
  followHashTagFeed,
} from "../../../store/hashtag/hashtagActions";
import "./hashtag.scss";
import moment from "moment";

export default function HashtagPostsContainer() {
  const { user } = useSelector((state) => state.auth);

  let params = new URL(document.location).searchParams;
  let keyword = params.get("keyword");

  const navigate = useNavigate();

  const [modal, setModal] = useState(false);
  const [createPostModal, setCreatePostModal] = useState(false);
  const [articleModal, setArticleModal] = useState(false);
  const [eventModal, setEventModal] = useState(false);
  const [newsletterModal, setNewsletterModal] = useState(false);
  const [byUserId, setByUserId] = useState(0);
  const [isReaction, setIsReaction] = useState(-1);
  const [clicked, setClicked] = useState({
    post_id: 0,
    user_id: 0,
    type: "",
  });
  const [draftDetails, setDraftDetails] = useState();
  const [postId, setPostID] = useState();
  const [isViewReaction, setViewReaction] = useState(false);
  const [viewMore, setViewMore] = useState(-1);
  const [createPostClick, setCreatePostClick] = useState("");
  const [currentCount, setCurrentCount] = useState(10);
  const [dropDown, setDropDown] = useState(-1);
  const [dropDownShare, setDropDownShare] = useState(-1);
  const [discardModalStatus, setDiscardModalStatus] = useState(false);
  const [updateData, setUpdateData] = useState();
  const [isEdit, setIsEdit] = useState(0);
  const [isDataAvailable, setDataAvailable] = useState(false);
  const [reportModal, setReportModal] = useState(false);
  const [commentBoxStatus, setCommentBoxStatus] = useState([]);
  const [dropDownRepost, setDropDownRepost] = useState(-1);
  const [isSaved, setSaved] = useState(-1);
  const [isComments, setIsComments] = useState(-1);
  const [selectedUserId, setSelectedUserId] = useState(true);
  const [openShareModal, setOpenShareModal] = useState(false);
  const [shareData, setShareData] = useState("");
  const [roleShareModal, setRoleShareModal] = useState(false);
  const [rolePageId, setRolePageId] = useState(false);
  const [isPostLoading, setIsPostLoading] = useState(true);
  const [closeModal, setCloseModal] = useState(false);
  const [deleteId, setDeleteId] = useState("");
  const [reportType, setReportType] = useState();
  const [reportCommentId, setReportCommentId] = useState();

  const { isWebView } = useSelector((state) => state.auth);

  const toggle = () => setModal(!modal);
  const toggleOff = () => setCreatePostModal(false);
  const dropRefRepost = useRef(null);

  const dispatch = useDispatch();
  const dropRef = useRef(null);
  const dropRefShare = useRef(null);

  const { postData } = useSelector((state) => state.dashboard);
  const { pageData } = useSelector((state) => state.pages);

  // const { suggested_articles } = useSelector((state) => state.article);
  // const { suggested_groups } = useSelector((state) => state.groups);
  // const { suggested_events } = useSelector((state) => state.events);
  // const { followedTagList } = useSelector((state) => state.hashtag);
  // const { suggested_people } = useSelector((state) => state.people);

  const token = window.localStorage.getItem("accessToken");
  const { getPageDetailData } = useSelector((state) => state.dashboard);

  useEffect(() => {
    dispatch(getAllPages({ filter: "my" }));
    dispatch(getPageDetails(token));
  }, []);

  useEffect(() => {
    let id = user?.data?.id;

    if (keyword) {
      dispatch(getHashTagPostFeed({ keyword: keyword, limit: 10, skip: 0 }));
    } else {
      navigate("/dashboard");
    }
  }, [user]);

  const closeAllModal = () => {
    setDiscardModalStatus(!discardModalStatus);
    setCreatePostModal(false);
    toggle();
  };

  const peopleFollowRequest = (id, mode, post_id) => {
    dispatch(profile_follower(id, mode, post_id));
  };
  const peopleUnFollowRequest = (id, mode, post_id) => {
    dispatch(profile_unfollower(id, mode, post_id));
  };
  const handlePageFollow = (pageID, mode, post_id) => {
    dispatch(followPage(pageID, mode, post_id));
  };
  const handlePageUnFollow = (pageID, mode, post_id) => {
    dispatch(unFollowPage(pageID, mode, post_id));
  };

  const saveDraft = () => {
    draftDetails.feed_status = "draft";
    dispatch(createPost(draftDetails, token));
    setCreatePostModal(false);
    closeAllModal();
  };

  const handleNext = () => {
    // setCurrentCount(currentCount + 10);
    // dispatch(getPostDetails(token, { skip: currentCount, limit: 10 }));
  };

  function privacy_type(privacy_id) {
    if (privacy_id === 1) return " Public";
    if (privacy_id === 2) return " Connection";
    return " Group";
  }

  useEffect(() => {
    document.addEventListener("mousedown", clickOutsideAdmin);
    return () => {
      document.removeEventListener("mousedown", clickOutsideAdmin);
    };
  }, [dropDown]);

  const clickOutsideAdmin = (e) => {
    if (e.toElement && e?.toElement.className === "listing") {
      return;
    }
    if (e.toElement && e?.toElement.className === "clickDots") {
      return;
    }
    if (dropRef?.current?.contains(e.target) && dropDown > -1) {
      return;
    }
    setDropDown(-1);
  };

  function handleReaction(user, reacted, type) {
    let data = {
      post_id: user.id,
      type: reacted,
      id: byUserId !== 0 ? byUserId : user?.reaction?.byUser?.id,
    };
    dispatch(reactionsData(data, type)).then((res) => {
      setByUserId(res.data[0].id);
    });
    setIsReaction(!isReaction);
    // dispatch({type: LIKED_DASHBOARD_POST, payload: data});
  }

  const sendRequest = (id, mode, post_id) => {
    dispatch(sendConnectionRequest(id, mode, post_id)).then((res) => {
      toast.success(res.message, {
        position: toast.POSITION.BOTTOM_LEFT,
      });
    });
  };

  const viewConnection = () => {
    navigate("/connections");
  };

  const follow_hashtag = (hashtagName, type) => {
    dispatch(followHashTagFeed(hashtagName, type));
  };

  useEffect(() => {
    document.addEventListener("mousedown", clickOutsideAdmin);
    return () => {
      document.removeEventListener("mousedown", clickOutsideAdmin);
    };
  }, [dropDown, dropDownShare, dropDownRepost]);

  const editPost = (postData) => {
    if (postData.Celebration) {
      toast.error("Cannot edit celebration post and feed docs ,docs");
      //alert("Cannot edit celebration post and feed docs ,docs");
    } else {
      setIsEdit(postData.id);
      setCreatePostModal(!createPostModal);
      setUpdateData(postData);
      setDropDown(-1);
    }
  };

  function privacy_type(privacy_id) {
    if (privacy_id === 1) return " Public";
    if (privacy_id === 2) return " Connection";
    return " Group";
  }

  const delete_post = (_id) => {
    dispatch(deletePost(_id, token));
    setDropDown(-1);
  };
  const save_post = (id, i) => {
    dispatch(savePost(id, token)).then((response) => {
      toast.success("Post Saved", {
        position: toast.POSITION.BOTTOM_LEFT,
      });
    });
  };
  const removeSavePost = (id) => {
    dispatch(savePostDelete(id, token)).then((response) => {
      toast.success("Post Removed", {
        position: toast.POSITION.BOTTOM_LEFT,
      });
    });
  };

  const handleLikes = (item, index) => {
    if (isReaction === index) {
      setIsReaction(-1);
    } else {
      if (item?.reaction?.byUser?.type) {
        handleReaction(item, item?.reaction?.byUser?.type, "deleteReaction");
      } else {
        setIsReaction(index);
      }
    }
  };

  function handleReaction(item, reacted, type) {
    let data = {
      post_id: item.id,
      type: reacted,
    };
    if (item?.as_a_page) {
      Object.assign(data, { page_id: item?.as_a_page?.id });
    }
    // else if (isPagePosts && selectedUserId) {
    //   Object.assign(data, { page_id: item?.Page?.id });
    // }
    dispatch(reactionsData(data, type)).then((res) => {
      setByUserId(res.data[0]?.id);
    });
  }

  const hideUserPost = (id) => {
    dispatch(hide_user_post(id)).then((reponse) => {
      toast.success("Post Hide Sucessfully!", {
        position: toast.POSITION.BOTTOM_LEFT,
      });
    });
    setDropDown(-1);
  };

  const repostPost = (id) => {
    let repost_data = {
      type: "repost",
      shared_id: id,
      privacy_id: 1,
      //   shared_by_type: isPageEdit || manageFeedStatus ? "page" : "user",
      //   shared_by: isPageEdit || manageFeedStatus ? pageID : user?.data?.id,
    };
    dispatch(repost_post(repost_data)).then((res) => {
      setDropDownShare(-1);
      toast.success("Reposted successfully!", {
        position: toast.POSITION.BOTTOM_LEFT,
      });
    });
  };

  const likeResult = (arr1, arr2) => {
    if (arr1 === undefined || arr1 === null) {
      let array3 = arr2;
      return array3;
    } else {
      let arr4 = [{ type: arr1 }];
      let array3 = arr4?.concat(
        arr2?.filter((bo) =>
          arr4?.every((ao) => ao?.type !== bo?.type && bo.page_id !== null)
        )
      );
      return array3;
    }
  };

  return (
    <HashtagPosts
      user={user}
      postData={postData}
      dashboardData={user?.data}
      userName={user?.data?.first_name + " " + user?.data?.last_name}
      userId={user?.data?.id}
      currentCount={currentCount}
      //hashtag post
      //  createModal={createModal}
      //  setCreateModal={setCreateModal}
      dropDown={dropDown}
      setDropDown={setDropDown}
      editPost={editPost}
      delete_post={delete_post}
      isSaved={isSaved}
      setSaved={setSaved}
      dropRef={dropRef}
      privacy_type={privacy_type}
      save_post={save_post}
      removeSavePost={removeSavePost}
      handleLikes={handleLikes}
      handleReaction={handleReaction}
      isComments={isComments}
      setIsComments={setIsComments}
      isReaction={isReaction}
      isViewReaction={isViewReaction}
      setViewReaction={setViewReaction}
      postId={postId}
      setPostID={setPostID}
      // pageOwner={pageOwner}
      //  isPagePosts={isPagePosts}
      discardModalStatus={discardModalStatus}
      setDiscardModalStatus={setDiscardModalStatus}
      isEdit={isEdit}
      createPostModal={createPostModal}
      setCreatePostModal={setCreatePostModal}
      updateData={updateData}
      setUpdateData={setUpdateData}
      createPostClick={createPostClick}
      setCreatePostClick={setCreatePostClick}
      closeAllModal={closeAllModal}
      isDataAvailable={isDataAvailable}
      setDataAvailable={setDataAvailable}
      draftDetails={draftDetails}
      setDraftDetails={setDraftDetails}
      setReportModal={setReportModal}
      reportModal={reportModal}
      dropDownRepost={dropDownRepost}
      setDropDownRepost={setDropDownRepost}
      dropRefRepost={dropRefRepost}
      // suggested_people={suggested_people}
      // sendRequest={sendRequest}
      hideUserPost={hideUserPost}
      setOpenShareModal={setOpenShareModal}
      openShareModal={openShareModal}
      commentBoxStatus={commentBoxStatus}
      setCommentBoxStatus={setCommentBoxStatus}
      dropDownShare={dropDownShare}
      setDropDownShare={setDropDownShare}
      repostPost={repostPost}
      likeResult={likeResult}
      selectedUserId={selectedUserId}
      setSelectedUserId={setSelectedUserId}
      roleShareModal={roleShareModal}
      setRoleShareModal={setRoleShareModal}
      shareData={shareData}
      setShareData={setShareData}
      pageData={pageData}
      rolePageId={rolePageId}
      setRolePageId={setRolePageId}
      closeModal={closeModal}
      setCloseModal={setCloseModal}
      deleteId={deleteId}
      setDeleteId={setDeleteId}
      reportType={reportType}
      setReportType={setReportType}
      reportCommentId={reportCommentId}
      setReportCommentId={setReportCommentId}
      //  others
      peopleFollowRequest={peopleFollowRequest}
      peopleUnFollowRequest={peopleUnFollowRequest}
      handlePageFollow={handlePageFollow}
      handlePageUnFollow={handlePageUnFollow}
      keyword={keyword}
      follow_hashtag={follow_hashtag}
      getPageDetailData={getPageDetailData}
    />
  );
}
