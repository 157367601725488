import {
  GET_LEAD_LIST,
  ADD_LEAD_GROUP,
  GET_LEADS_ACTION_LIST,
  EDIT_LEAD_DATA,
  ADD_LEAD_ACTIONS,
  DELETE_LEAD_DATA,
  SEND_LEADS_CONNECTION,
  DELETE_LEAD_GROUP,
  EDIT_LEAD_GROUP
} from "../types";

export const initialState = {
  leadsList: [],
  leadsActionList: [],
};

const leads = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case GET_LEAD_LIST:
      return {
        ...state,
        leadsList: payload,
      };

    case ADD_LEAD_GROUP:
      return {
        ...state,
        leadsList: {
          ...state.leadsList,
          group_list: [...state.leadsList.group_list, payload],
        },
      };
    
    case DELETE_LEAD_GROUP:
      const deleteIdx = state.leadsList.group_list.indexOf(payload)
      if(deleteIdx !== -1){
        const currentData = state.leadsList.group_list
        currentData.splice(deleteIdx, 1)
        const newList = state.leadsList.lead_list.map((ele) => 
          ele.group == payload ? {...ele, group: "General"} : {...ele}
        )
        return {
          ...state,
          leadsList: {
            ...state.leadsList,
            lead_list: [...newList],
            group_list: [...currentData]
          }
        }
      }else{
        return {
          ...state,
          leadsList: {
            ...state.leadsList,
            group_list: [...state.leadsList.group_list]
          }
        }
      }
    
    case EDIT_LEAD_GROUP:
      const newList = state.leadsList.lead_list.map((ele) => 
        ele.group == payload.oldName ? {...ele, group: payload.newName} : {...ele}
      )
      return {
        ...state,
        leadsList: {
          ...state.leadsList,
          lead_list: [...newList],
          group_list: [...payload.newGroups]
        }
      }
    
    case GET_LEADS_ACTION_LIST:
      return {
        ...state,
        leadsActionList: payload,
      };

    case EDIT_LEAD_DATA:
      if (action.payload.type === "group") {
        return {
          ...state,
          leadsList: {
            ...state.leadsList,
            lead_list: state.leadsList.lead_list.map((x) =>
              x.id === action.payload.id
                ? {
                    ...x,
                    group: action.payload.data.group,
                  }
                : {
                    ...x,
                  }
            ),
          },
        };
      } else if (action.payload.type === "email") {
        return {
          ...state,
          leadsList: {
            ...state.leadsList,
            lead_list: state.leadsList.lead_list.map((x) =>
              x.id === action.payload.id
                ? {
                    ...x,
                    email: action.payload.data.email,
                  }
                : {
                    ...x,
                  }
            ),
          },
        };
      } else if (action.payload.type === "phone") {
        return {
          ...state,
          leadsList: {
            ...state.leadsList,
            lead_list: state.leadsList.lead_list.map((x) =>
              x.id === action.payload.id
                ? {
                    ...x,
                    phone: action.payload.data.phone,
                  }
                : {
                    ...x,
                  }
            ),
          },
        };
      } else return;

    case DELETE_LEAD_DATA:
      return {
        ...state,
        leadsList: {
          ...state.leadsList,
          lead_list: state.leadsList.lead_list.filter(
            (item) => item.id !== action.payload
          ),
        },
      };

    case ADD_LEAD_ACTIONS:
      return {
        ...state,
        leadsList: {
          ...state.leadsList,
          lead_list: state.leadsList.lead_list.map((x) =>
            x.id === action.payload.lead_id
              ? {
                  ...x,
                  lead_status: action.payload.lead_status,
                  schedule: action.payload.schedule,
                }
              : {
                  ...x,
                }
          ),
        },
      };

    case SEND_LEADS_CONNECTION:
      return {
        ...state,
        leadsList: {
          ...state.leadsList,
          lead_list: state.leadsList.lead_list.map((x) =>
            x.user_id === action.payload
              ? {
                  ...x,
                  connection_status: "pending",
                }
              : {
                  ...x,
                }
          ),
        },
      };

    default:
      return state;
  }
};

export default leads;
