import React, { useState } from "react";
import "./CandidateComponents.scss";
import jobIcon from "../../../../assets/img/page-placeholder.svg";
import { useEffect } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import { recuriterSearchAPI } from "../../../../api/recuriterSearchAPI";
import confidential from "../../../../assets/img/confidentialIcon.svg";
import pagePlaceholder from "../../../../assets/img/page-placeholder.svg";
const LeftSideBar = (props) => {
  const {
    headerText,
    getAllJobs,
    selectedJobId,
    setSelectedJobId,
    loadMoreMyJob,
    isMoreMyJob,
    setCurrentCount,
    tab,
    setSelectedJob,
  } = props;
  console.log(tab, "tab");
  const [count, setCount] = useState({
    totalShortListed: 0,
    totalInterviewSent: 0,
    totalInterviewRecieved: 0,
  });

  const getCount = async () => {
    const res = await recuriterSearchAPI.getShortListCounts();
    if (res?.success) {
      setCount({
        totalShortListed: res?.data?.totalShortListed,
        totalInterviewSent: res?.data?.totalInterviewSent,
        totalInterviewRecieved: res?.data?.totalInterviewRecieved,
      });
    }
  };
  useEffect(() => {
    getCount();
    if (getAllJobs?.length > 0) setSelectedJobId(getAllJobs[0]?.id);
  }, [getAllJobs]);

  return (
    <div className="bg-white left-sidebar">
      {tab == "shotlisted" && (
        <div className="sidebar-header px-4 py-3 ">
          <h5 className="fw-bold text-dark">
            {count?.totalShortListed || 0} Total Shortlisted Candidates
          </h5>
          <p>A maximum of 50 shortlisted candidates per vacancy.</p>
        </div>
      )}
      {tab == "viewCandidate" && (
        <div className="sidebar-header px-4 py-3 ">
          <h5 className="fw-bold text-dark">
            {count?.totalInterviewSent || 0} Smart Interview Sent
          </h5>
          <p>Get 3x more qualified applicants</p>
        </div>
      )}
      {tab == "viewSmartInterview" && (
        <div className="sidebar-header px-4 py-3 ">
          <h5 className="fw-bold text-dark">
            {count?.totalInterviewRecieved || 0} Smart Interview Received
          </h5>
          <p>A maximum of 30 smart interviews per vacancy.</p>
        </div>
      )}
      <InfiniteScroll
        dataLength={getAllJobs?.length ? getAllJobs?.length : 0}
        next={loadMoreMyJob}
        hasMore={isMoreMyJob}
        style={{ overflow: "visible" }}
      >
        <>
          {getAllJobs?.map((item, index) => (
            <div
              className={
                "job-card " + (selectedJobId == item?.id ? "active" : "")
              }
              key={index}
              onClick={() => {
                setSelectedJobId(item?.id);
                setCurrentCount(0);
                window.scrollTo(0, 0);
                setSelectedJob(item);
              }}
            >
              <div className="d-flex justify-content-between px-4 pt-3 ">
                <div className="d-flex mb-4">
                  <img
                    src={
                      // item?.company_visible && item?.company?.icon
                      //   ? item?.company?.icon
                      //   : jobIcon

                      item?.company_visible
                        ? item?.company?.icon
                          ? item?.company?.icon
                          : pagePlaceholder
                        : confidential
                    }
                    alt="company_icon"
                    className="img-icon"
                  />
                  <div className="ml-5">
                    <h5 className="job-title">{item?.title}</h5>
                    <p className="shortlist-number">
                      Shortlisted Candidates:{" "}
                      <span className="fw-bold">
                        {item?.shortlist_count || 0}
                      </span>
                    </p>
                    {tab !== "shotlisted" && (
                      <p className="shortlist-number">
                        Smart Interview Sent:{" "}
                        <span className="fw-bold">
                          {item?.interview_watch || 0} /{" "}
                          {item?.interview_send || 0}
                        </span>
                      </p>
                    )}
                  </div>
                </div>
                {new Date() > new Date(item?.expiry_date) && (
                  <p className="text-danger fw-bold">Expired</p>
                )}
              </div>

              <div className="d-flex justify-content-between bottom-bar px-3 pb-3 ">
                <div className="ml-5">
                  <span className="blue-color" style={{ width: "50%" }}>
                    {" "}
                    {item?.employment_type}
                    {item?.workspace_type ? ` | ` + item?.workspace_type : ""}
                  </span>
                </div>
                <span
                  className="blue-color"
                  style={{ width: "50%", paddingLeft: "55px" }}
                >
                  {item?.job_location}
                </span>
              </div>
            </div>
          ))}
        </>
      </InfiniteScroll>
    </div>
  );
};

export default LeftSideBar;
