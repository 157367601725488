import React from 'react'
import "./chat.scss"
import chatIcon from "../../assets/img/arrow-chat.svg"

export default function Chat() {
  return (
    <div className="pull-right">
      <div className="chat-box">
        <div className="chat"
        onClick={() => {
          alert(
            "This feature will be implemented in the future milestone"
          );
          return;
        }}>
          <div className="wrapper">
            <div className="prof-img">
              <img src="http://upload.wikimedia.org/wikipedia/commons/thumb/b/b6/Image_created_with_a_mobile_phone.png/640px-Image_created_with_a_mobile_phone.png" />
              <div className="active"></div>
            </div>
            <div className="message">
              <span>Message </span>
            </div>
            <img src={chatIcon} alt="" />
            <div className="online">
              <span>5</span>
            </div>
          </div>
          <div className="search">
            <div className="icon"></div>
          </div>
        </div>
      </div>
    </div>
  )
}
