import { messageAPI } from "../../api/messageAPI";
import {
  CREATE_CONVERSATION,
  CREATE_GROUP_CONVERSATION,
  GET_CONVERSATION,
  GET_GROUP_CONVERSATION,
  REMOVE_CONVERSATION,
  REMOVE_GROUP_CONVERSATION,
  UPDATE_CONVERSATION,
  UPDATE_GROUP_CONVERSATION,
} from "../types";

export const createConversation = (data) => (dispatch) => {
  return messageAPI.createConversation(data).then(
    (response) => {
      dispatch({
        type: CREATE_CONVERSATION,
        payload: response?.data,
      });
      return Promise.resolve(response);
    },
    (error) => {
      console.log(error);
      return Promise.reject(error);
    }
  );
};

export const updateConversation = (data) => (dispatch) => {
  return messageAPI.updateConversation(data).then(
    (response) => {
      dispatch({
        type: UPDATE_CONVERSATION,
        payload: response?.data,
      });
      return Promise.resolve(response);
    },
    (error) => {
      console.log(error);
      return Promise.reject(error);
    }
  );
};

export const removeConversation = (data) => (dispatch) => {
  return messageAPI.deleteConversation(data).then(
    (response) => {
      dispatch({
        type: REMOVE_CONVERSATION,
        payload: response?.data,
      });
      return Promise.resolve(response);
    },
    (error) => {
      console.log(error);
      return Promise.reject(error);
    }
  );
};

export const updateGroupConversation = (data) => (dispatch) => {
  return messageAPI.updateGroupConversation(data).then(
    (response) => {
      if (data?.conversation_type === undefined)
        if (data?.id) {
          dispatch({
            type: UPDATE_GROUP_CONVERSATION,
            payload: response?.data,
          });
        } else
          dispatch({
            type: CREATE_GROUP_CONVERSATION,
            payload: response?.data,
          });
      else {
        dispatch({
          type: REMOVE_GROUP_CONVERSATION,
          payload: response?.data,
        });
      }
      return Promise.resolve(response);
    },
    (error) => {
      console.log(error);
      return Promise.reject(error);
    }
  );
};

export const getConversation = (data) => (dispatch) => {
  return messageAPI.getConversation(data).then(
    (response) => {
      if (data?.type == "group")
        dispatch({
          type: GET_GROUP_CONVERSATION,
          payload: response,
        });
      else
        dispatch({
          type: GET_CONVERSATION,
          payload: response,
        });
      return Promise.resolve(response);
    },
    (error) => {
      console.log(error);
      return Promise.reject(error);
    }
  );
};

export const getMessages = (data) => (dispatch) => {
  return messageAPI.getMessages(data).then(
    (response) => {
      return Promise.resolve(response);
    },
    (error) => {
      console.log(error);
      return Promise.reject(error);
    }
  );
};

export const updateConversationTypes = (data) => (dispatch) => {
  return messageAPI.updateConversationType(data).then(
    (response) => {
      dispatch({
        type: REMOVE_CONVERSATION,
        payload: { id: data?.conversation_id },
      });
      return Promise.resolve(response);
    },
    (error) => {
      console.log(error);
      return Promise.reject(error);
    }
  );
};

export const updateAwayMessages = (data) => (dispatch) => {
  return messageAPI.updateAwayMessage(data).then(
    (response) => {
      return Promise.resolve(response);
    },
    (error) => {
      console.log(error);
      return Promise.reject(error);
    }
  );
};

export const getAwayMessages = () => (dispatch) => {
  return messageAPI.getAwayMessage().then(
    (response) => {
      return Promise.resolve(response);
    },
    (error) => {
      console.log(error);
      return Promise.reject(error);
    }
  );
};
