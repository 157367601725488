import {
  ADD_PAGE_ADMIN,
  ADD_PAGE_URL,
  CREATE_PAGE_SUCCESS,
  DELETE_PAGE_ADMIN,
  FOLLOW_PAGE_SUCCESS,
  GET_ALL_PAGES_SUCCESS,
  GET_PAGES_PERMISSION,
  GET_PAGE_ADMINS,
  GET_PAGE_INSIGHTS,
  GET_SINGLE_PAGES_SUCCESS,
  PASS_VERIFY_SUCCESS,
  SEND_PAGE_INVITATION,
  TRANSFER_PAGE_ADMIN,
  UNFOLLOW_PAGE_SUCCESS,
  UPDATE_PAGE_SUCCESS,
  UNFOLLOW_SINGLEPAGE_SUCCESS,
  FOLLOW_SINGLEPAGE_SUCCESS,
  GET_ALL_PAGES_FAIL,
  GET_ALL_PRODUCT_LIST,
  CREATE_PAGE_PRODUCT,
  UPDATE_PAGE_PRODUCT,
  GET_PAGE_FOLLOWERS,
} from "../types";

const initialState = {
  pageData: [],
  productData: [],
};

export default function (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case CREATE_PAGE_SUCCESS:
      return {
        ...state,
        pageData: { ...state.pageData, ...action.payload },
      };
    case GET_ALL_PAGES_SUCCESS:
      return {
        ...state,
        pageData: action.payload,
      };
    case GET_ALL_PAGES_FAIL:
      return {
        ...state,
        pageData: [],
      };
    case GET_SINGLE_PAGES_SUCCESS:
      return {
        ...state,
        singlePageData: action.payload,
      };
    case UPDATE_PAGE_SUCCESS:
      return {
        ...state,
        singlePageData: {
          ...state.singlePageData,
          ["data"]: state.singlePageData?.data?.map((item) =>
            item.id === action.payload.id
              ? { ...item, ...action.payload }
              : item
          ),
        },
      };

    case GET_PAGES_PERMISSION:
      return {
        ...state,
        pagePermission: action.payload,
      };

    case ADD_PAGE_URL:
      return {
        ...state,

        singlePageData: {
          ...state.singlePageData,
          // {...state.singlePageData[0].page_url :action.payload}
        },
      };

    case GET_PAGE_ADMINS:
      return {
        ...state,
        pageAdminDetails: action.payload,
      };

    case GET_PAGE_FOLLOWERS:
      return {
        ...state,
        pageFollowers: action.payload,
      };

    case DELETE_PAGE_ADMIN:
      return {
        ...state,
        pageAdminDetails: {
          ...state.pageAdminDetails,
          data: state.pageAdminDetails?.data?.filter((item) => {
            return item.user_id !== action.payload.user_id;
          }),
        },
      };
    case ADD_PAGE_ADMIN:
      let userFound = false;
      let temp = [...state.pageAdminDetails?.data];
      if (temp) {
        for (let index = 0; index < temp.length; index++) {
          const element = temp[index];
          if (element.user_id === action.payload.user_id) {
            temp[index] = action.payload;
            userFound = true;
            break;
          }
        }
        if (userFound == false) temp.push(action.payload);
      }
      return {
        ...state,
        pageAdminDetails: {
          ...state.pageAdminDetails,
          data: temp,
        },
      };

    case PASS_VERIFY_SUCCESS:
      return {
        ...state,
        // pageData: action.payload,
      };
    case TRANSFER_PAGE_ADMIN:
      return {
        ...state,
        // pageData: action.payload,
      };

    case SEND_PAGE_INVITATION:
      return {
        ...state,
        pageData: action.payload,
      };

    case FOLLOW_PAGE_SUCCESS:
      return {
        ...state,
        pageData: {
          ...state.pageData,
          ["data"]: state.pageData.data.map((item) =>
            item.id === action.payload ? { ...item, is_following: true } : item
          ),
        },
      };
    case GET_PAGE_INSIGHTS:
      return {
        ...state,
      };
    case UNFOLLOW_PAGE_SUCCESS:
      return {
        ...state,
        pageData: {
          ...state.pageData,
          ["data"]: state.pageData.data.map((item) =>
            item.id === action.payload ? { ...item, is_following: false } : item
          ),
        },
      };
    case UNFOLLOW_SINGLEPAGE_SUCCESS:
      return {
        ...state,
        singlePageData: {
          ...state.singlePageData,
          is_following: false,
        },
      };

    case FOLLOW_SINGLEPAGE_SUCCESS:
      return {
        ...state,
        singlePageData: {
          ...state.singlePageData,
          is_following: true,
        },
      };

    case GET_ALL_PRODUCT_LIST:
      return {
        ...state,
        productData: action.payload,
      };

    case CREATE_PAGE_PRODUCT:
      console.log(
        state.productData,
        action.apyload.data,
        "state.productData, action.apyload.data"
      );
      return {
        ...state,
        productData: [...state.productData, ...action.payload.data],
      };

    case UPDATE_PAGE_PRODUCT:
      const data = state?.productData?.map((ele) => {
        if (ele.id == action.payload.id) {
          return { ...ele, ...action.payload.data };
        }
        return ele;
      });

      console.log(
        data,
        state?.productData?.map((ele) => {
          if (ele.id == action.payload.id) {
            return { ...ele, ...action.payload.data };
          }
          return ele;
        }),

        "eijeid"
      );

      return {
        ...state,
        productData: data,
      };

    default:
      return state;
  }
}
