import { call } from "./apiCall";
import axios from "axios";

class MembershipAPI {
  getMembershipList(data = {}) {
    return new Promise((resolve, reject) => {
      const token = localStorage.getItem("accessToken");
      (async () => {
        try {
          const res = await call(
            "get",
            token
              ? `api/v1/membership/types`
              : `api/v1/public/membership/types`,
            data,
            null,
            token
          );
          resolve(res.data);
        } catch (err) {
          console.log(err);
          reject(err);
        }
      })();
    });
  }

  getMembershipId(data) {
    return new Promise((resolve, reject) => {
      const token = localStorage.getItem("accessToken");
      (async () => {
        try {
          const res = await call(
            "get",
            token
              ? `api/v1/membership/types`
              : `api/v1/public/membership/types`,
            data,
            null,
            token
          );
          resolve(res.data);
        } catch (err) {
          console.log(err);
          reject(err);
        }
      })();
    });
  }

  getAddOn(data) {
    return new Promise((resolve, reject) => {
      const token = localStorage.getItem("accessToken");
      (async () => {
        try {
          const res = await call(
            "get",
            "api/v1/membership/add-ons",
            data,
            null,
            token
          );
          resolve({data: res.data.data, location: res.data.location});
        } catch (err) {
          console.log(err);
          reject(err);
        }
      })();
    });
  }

  userAddon() {
    return new Promise((resolve, reject) => {
      const token = localStorage.getItem("accessToken");
      (async () => {
        try {
          const res = await call(
            "get",
            "api/v1/membership/get-user-addons",
            null,
            null,
            token
          );
          resolve({data: res.data.data, location: res.data.location});
        } catch (err) {
          console.log(err);
          reject(err);
        }
      })();
    });
  }

  getMembershipFeature(data = {}) {
    return new Promise((resolve, reject) => {
      const token = localStorage.getItem("accessToken");
      (async () => {
        try {
          const res = await call(
            "get",
            token
              ? `api/v1/membership/features`
              : `api/v1/public/membership/features`,
            data,
            null,
            token
          );
          resolve(res.data);
        } catch (err) {
          console.log(err);
          reject(err);
        }
      })();
    });
  }

  getMembershipImage() {
    return new Promise((resolve, reject) => {
      (async () => {
        try {
          const res = await call(
            "get",
            `api/v1/get-static-pages?title=membership`,
            null,
            null,
            null
          );
          resolve(res.data);
        } catch (err) {
          console.log(err);
          reject(err);
        }
      })();
    });
  }

  getLocation() {
    return new Promise((resolve, reject) => {
      (async () => {
        try {
          const GEO_API_KEY = "f08934b1e7ce4d2abeedfd349454e63d";
          const res = await axios.get(
            `https://api.ipgeolocation.io/ipgeo?apiKey=${GEO_API_KEY}`
          );
          resolve(res.data);
        } catch (err) {
          console.log(err);
          reject(err);
        }
      })();
    });
  }

  buyAddons(data){
    const token = localStorage.getItem("accessToken");
    return new Promise((resolve, reject) => {
      (async () => {
        try {
          const res = await call(
            "post",
            `api/v1/membership/buy-addon`,
            null,
            data,
            token
          );
          resolve(res.data);
        } catch (err) {
          console.log(err);
          reject(err);
        }
      })();
    });
  }
}

export const membershipAPI = new MembershipAPI();
