import React from "react";
import { Modal, ModalBody } from "reactstrap";
import { useDispatch } from "react-redux";
import walletIcon from "../../../../assets/img/wallet-job.svg";
import locationIcon from "../../../../assets/img/location-job.svg";
import expIcon from "../../../../assets/img/exp-job.svg";
import buld from "../../../../assets/img/circle-building.svg";
import pagePlaceholder from "../../../../assets/img/page-placeholder.svg";
import confidential from "../../../../assets/img/confidentialIcon.svg";
import "../../Jobs.scss";

const JobPreview = (props) => {
  const {
    modal,
    toggle,
    isTallentPool,
    title,
    company,
    company_location,
    company_visible,
    vacancies,
    workspace_type,
    employment_type,
    description,
    addSkills,
    location,
    experience,
    jobType,
    salary,
    handlePostJob,
    listImage,
    selectedMinExp,
    selectedMaxExp,
    selectedMinSalaryLabel,
    selectedMaxSalaryLabel,
    convertedContent,
  } = props;

  console.log(props, "props");

  const dispatch = useDispatch();

  return (
    <div>
      <Modal isOpen={modal} size="lg" centered toggle={toggle}>
        <div>
          <ModalBody
            className="p-4 mb-4 jobs bg-white"
            style={{ borderRadius: "5px", minHeight: "unset" }}
          >
            <div className="p-4 main-content">
              <div className="d-flex justify-content-between">
                <div>
                  <h3 className="main-header mb-2">{props?.title}</h3>
                  <span className="sub-header ">
                    {company_visible ? props?.company : "Confidential"} &#x2022;{" "}
                    {props?.employment_type} &#x2022; Just Now{" "}
                  </span>
                </div>
                <img
                  src={
                    company_visible
                      ? listImage
                        ? listImage
                        : pagePlaceholder
                      : confidential
                  }
                  alt="company_logo"
                  style={{
                    height: "50px",
                    width: "50px",
                    borderRadius: "25px",
                  }}
                />
              </div>
              <div className="row mt-20">
                <div className="col-sm-12">
                  <div className="d-flex">
                    <img src={expIcon} alt="exp_icon" />
                    <span className="ml-10">
                      {selectedMinExp} - {selectedMaxExp}
                    </span>
                  </div>
                  <div className="d-flex mt-10">
                    <img src={walletIcon} alt="salary_icon" />
                    <span className="ml-10">
                      ₹ {selectedMinSalaryLabel} - ₹ {selectedMaxSalaryLabel}
                    </span>
                  </div>
                  <div className="d-flex mt-10">
                    <img src={locationIcon} alt="location_icon" />
                    <span className="ml-10">{company_location}</span>
                  </div>
                </div>
              </div>

              <div className="description mt-30">
                <h4 className="main-header" style={{ marginTop: "7px" }}>
                  Job Description
                </h4>
                <span
                  className="details-job"
                  style={{ wordBreak: "break-word" }}
                  dangerouslySetInnerHTML={{ __html: description }}
                />
                {/* <h4 className="sub-header mt-20 mb-20">
                  Roles and Responsibilities
                </h4>

                <h4 className="sub-header mt-20 mb-20">Job Requirements</h4>
                <span className="details-job">
                  Lorem ipsum dolor sit amet, <br /> consectetur adipiscing
                  elit. Nunc
                  <br /> vulputate libero et velit interdum, ac <br />
                  aliquet odio mattis. Class aptent <br />
                  taciti himenaeos. Curabitur
                </span>
                <h4 className="sub-header mt-20 mb-20">
                  Education Requirements
                </h4>
                <span className="details-job">
                  B.Sc in Computers, B.Tech/B.E. in Computers
                </span> */}
                <h4 className="sub-header mt-20 mb-20">Key Skills</h4>
                {/* <span className="details-job">{`${addSkills} `}</span> */}
              </div>
              <div className="post-jobs">
                <div
                  className="d-flex"
                  style={{ flexWrap: "wrap", gap: "8px" }}
                >
                  {addSkills?.length > 0 &&
                    addSkills?.map((item, index) => (
                      <div
                        key={index}
                        className="add-skills-recruiter"
                        //style={{ maxWidth: "100%" }}
                      >
                        {/* <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            overflow: "hidden",
                          }}
                        > */}
                        <p
                          style={{
                            margin: "0",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            whiteSpace: "nowrap",
                            maxWidth: "670px",
                          }}
                        >
                          {item}
                        </p>

                        {/* </div> */}
                      </div>
                    ))}
                </div>
              </div>
            </div>
            <div className="bottom-buttons">
              <div
                className={
                  "btn" +
                  (isTallentPool
                    ? " btn-org-light text-orange"
                    : " btn-light text-blue")
                }
                onClick={() => toggle()}
              >
                Close
              </div>
              {/* <div className={
                "btn" +
                (isTallentPool
                  ? " btn-orange"
                  : " btn-blue")
              }
                onClick={() => handlePostJob()}
              >Post Job</div> */}
            </div>
          </ModalBody>
        </div>
      </Modal>
    </div>
  );
};

export default JobPreview;
