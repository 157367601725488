import React from "react";
import { Modal, ModalHeader, ModalBody } from "reactstrap";
import n from "../../../../assets/img/noble_profile_logo.png";
import s from "../../../../assets/img/smartphone.svg";
import l from "../../../../assets/img/location-pin.svg";
import e from "../../../../assets/img/email 1.svg";
import b from "../../../../assets/img/wpf_birthday.svg";
import edit from "../../../../assets/img/edit.svg";
import { WEB_URL } from "../../../../config";
import "../profile.scss";

export default function Contactinfo(props) {
  const {
    modal,
    toggle,
    title,
    firstName,
    lastName,
    address,
    dob,
    phone,
    email,
    addContactInfoModal,
    setAddContactInfoModal,
    isAllowedToEdit,
    profileURL,
  } = props;

  return (
    <div>
      <Modal
        isOpen={modal}
        toggle={toggle}
        className="contact-info-popup fix-width gradient-background"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <ModalHeader style={{ alignItems: "baseline" }} toggle={toggle}>
          {firstName + " " + lastName}
        </ModalHeader>
        <ModalBody>
          <div className="box">
            <div className="d-flex justify-content-between">
              <div className="heading">
                <h6>{title}</h6>
              </div>
              {isAllowedToEdit && (
                <div className="edit">
                  <img
                    onClick={() => {
                      setAddContactInfoModal(!addContactInfoModal);
                      toggle();
                    }}
                    src={edit}
                    alt=""
                  />
                </div>
              )}
            </div>
            <div className="details">
              <div className="sub-box">
                <div className="img">
                  <img src={n} alt="" />
                </div>
                <div className="text">
                  <h6>Your Profile</h6>
                  <p>
                    {WEB_URL}profile/
                    {profileURL}
                  </p>
                </div>
              </div>
              <div className="line mb-3"></div>
              <div className="sub-box">
                <div className="img">
                  <img src={s} alt="" />
                </div>
                <div className="text">
                  <h6>Phone</h6>
                  <p>{phone ? phone : "NA"}</p>
                </div>
              </div>
              <div className="line mb-3"></div>
              <div className="sub-box">
                <div className="img">
                  <img src={l} alt="" />
                </div>
                <div className="text">
                  <h6>Address</h6>
                  <p>{address}</p>
                </div>
              </div>
              <div className="line mb-3"></div>
              <div className="sub-box">
                <div className="img">
                  <img src={e} alt="" />
                </div>
                <div className="text">
                  <h6>Email</h6>
                  <p>{email}</p>
                </div>
              </div>
              <div className="line mb-3"></div>
              <div className="sub-box">
                <div className="img">
                  <img src={b} alt="" />
                </div>
                <div className="text">
                  <h6>Birthday</h6>
                  <p>
                    {dob
                      ? new Date(dob).toLocaleString("en-us", {
                          month: "long",
                          year: "numeric",
                        })
                      : "NA"}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </ModalBody>
      </Modal>
    </div>
  );
}
