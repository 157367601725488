import NetworkSidebar from "./NetworkSidebar";
import Navbar from "../../../pages/navbar/navbar";
import Footer from "../../../core/components/layout/footer/Footer";
import { useState, useEffect } from "react";
import "./Layout.scss";

const Layout = ({ children }) => {
  const [isSticky, setIsSticky] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const sidebar = document.querySelector(".layout-view");
      const sidebarRect = sidebar.getBoundingClientRect();
      //   console.log(sidebarRect.top + sidebarRect.height < window.innerHeight);
      if (sidebarRect.top + sidebarRect.height < window.innerHeight) {
        setIsSticky(true);
        sidebar.style.top = `${sidebarRect.top}px`;
        console.log(sidebar.style.top);
      } else setIsSticky(false);
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <>
      <div>
        <Navbar isJobs={false} />
        <div className="bg-grey">
          <div className="newsletter">
            <div className="container">
              <div className="row">
                <div className="col-lg-3">
                  <div
                    className={
                      isSticky
                        ? "layout-view mt-4 sidebar-sticky-left"
                        : "layout-view mt-4"
                    }
                  >
                    <NetworkSidebar />
                  </div>
                </div>
                {children}
              </div>
              <div className="web-footer-bottom">
                <Footer />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Layout;
