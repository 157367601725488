import {
  Modal,
  ModalBody,
  Input,
  Label,
  FormGroup,
  ModalHeader,
} from "reactstrap";
import React, { useState } from "react";
import { GetAllUsers } from "../../../../store/getAllUsers/allUsersAction";
import { useDispatch, useSelector } from "react-redux";
import placeholderUser from "../../../../assets/img/placeholder_user.jpg";
import Loader from "../../../../core/components/Loaders/ComponentLoader";
import AppLoader from "../../../../core/components/Loaders/appLoader/appLoader";
import { toast } from "react-toastify";

const SearchMemberModal = (props) => {
  const { modal, toggle, title, addGroupMembers, editGroupData } = props;

  const dispatch = useDispatch();
  const [arr, setArr] = useState([]);
  const [selected, setSelected] = useState([]);
  const [selectedEmails, setSelectedEmails] = useState([]);
  const [input, setInput] = useState("");
  const { isPageLoading } = useSelector((state) => state.loader);

  const handleChange = (e) => {
    setInput(e.target.value);
    if (e.target.value !== "") {
      dispatch(GetAllUsers({ search: e.target.value })).then((res) => {
        let newData = res?.data?.filter((element) => {
          let data = true;
          for (let index = 0; index < selected?.length; index++) {
            if (selected[index]?.id === element.id) {
              data = false;
              break;
            }
          }
          return data;
        });
        setArr(newData);
      });
    } else setArr([]);
  };

  const selectItem = (item) => {
    setSelected((selected) => [...selected, item]);
    setSelectedEmails((selected) => [...selected, item.id]);
    setInput("");
    setArr([]);
  };

  const removeItem = (item) => {
    setSelected((current) =>
      current.filter((selected) => selected.id !== item.id)
    );
    setSelectedEmails((current) => current.filter((data) => data !== item.id));
  };

  const AddMember = () => {
    var membersId = editGroupData?.members.map((data) => data.user_id);
    const mergeAllMembers = [...new Set([...membersId, ...selectedEmails])];
    if (selected.length > 0) {
      const data = {
        // id: editGroupData?.id,
        name: editGroupData.name,
        icon: editGroupData.icon,
        background_img: editGroupData.background_img,
        type: editGroupData.type,
        description: editGroupData.description,
        members_limit: editGroupData.members_limit,
        industry: editGroupData.industry,
        members: mergeAllMembers,
        hashtags: ["string"],
      };
      addGroupMembers(data, "add");
    } else toast.error("Please Select User");
  };

  return (
    <>
      {isPageLoading && <AppLoader />}
      <div>
        <Modal
          isOpen={modal}
          size="lg"
          style={{ maxWidth: "600px" }}
          className="topDiv inviteconnections  view_member_model gradient-background"
        >
          <ModalHeader className="header" id="account-modal" toggle={toggle}>
            {title}
          </ModalHeader>
          <ModalBody>
            <div className="container">
              <div className="row">
                <div className="col-lg-12">
                  <input
                    value={input}
                    className="form-control w-100"
                    onChange={(e) => handleChange(e)}
                    placeholder="Search People"
                  />
                </div>
              </div>

              <div className="row mb-2">
                <div className="col-12">
                  <div className="row selectRecptRow mb-2">
                    {selected?.length > 0 &&
                      selected?.map((item, index) => {
                        return (
                          <div
                            key={index}
                            className="col col-sm-6 col-md-4 col-lg-4 col-xl-4"
                          >
                            <div
                              className="selectedReceipient  mt-2"
                              style={{ maxWidth: "100%" }}
                            >
                              <p>
                                <img
                                  className="img-fluid selectedImg"
                                  src={
                                    item?.profile_img
                                      ? item?.profile_img
                                      : placeholderUser
                                  }
                                  alt="img"
                                />
                              </p>
                              <p
                                className="card-control-text-oneline"
                                style={{
                                  marginTop: "0px",
                                  paddingLeft: "2px",
                                }}
                              >
                                {item?.first_name.slice(0, 6) +
                                  (item.first_name.length > 6 ? "..." : "") +
                                  " " +
                                  item.last_name.slice(0, 5) +
                                  (item.last_name.length > 5 ? "..." : "")}
                              </p>
                              <p
                                style={{ marginTop: "0px" }}
                                onClick={() => removeItem(item)}
                                className="curserPointer"
                              >
                                X
                              </p>
                            </div>
                          </div>
                        );
                      })}
                  </div>
                </div>
              </div>

              <div className="selectrecpt pe-4 mt-3">
                <div className="row showreclist curserPointer">
                  {arr &&
                    arr?.length > 0 &&
                    arr?.map((item, index) => (
                      <div
                        key={index}
                        className="row contain"
                        onClick={() => selectItem(item)}
                      >
                        <div className="col-2">
                          <img
                            className="img-fluid listImg"
                            src={
                              item?.profile_img
                                ? item?.profile_img
                                : placeholderUser
                            }
                            alt="img"
                          />
                        </div>
                        <div className="col-10">
                          <p className="username">
                            {item?.first_name?.slice(0, 12) +
                              (item?.first_name?.length > 12 ? "..." : "") +
                              " " +
                              item?.last_name?.slice(0, 10) +
                              (item?.last_name?.length > 10 ? "..." : "")}
                          </p>
                          {item?.profile?.profile_headline && (
                            <small>{item?.profile?.profile_headline}</small>
                          )}
                        </div>
                      </div>
                    ))}
                </div>
              </div>

              <div className="row">
                <div className="col-lg-12">
                  <div className="d-flex align-items-center justify-content-center mt-3">
                    <button
                      className={"btn btn-blue invite-btn ms-1 ml-20 "}
                      onClick={() => AddMember()}
                    >
                      Add Members
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </ModalBody>
        </Modal>
      </div>
    </>
  );
};

export default SearchMemberModal;
