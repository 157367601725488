import React, { useEffect, useState } from "react";
import "./seekingModal.scss";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import { getLocationsData } from "../../store/connections/connectionsActions";
import { useDispatch, useSelector } from "react-redux";
import Autocomplete from "react-autocomplete";
import { toast } from "react-toastify";
import { jobPreference, updateJobPreference } from "../../store/jobs/jobAction";
import AppLoader from "../../core/components/Loaders/appLoader/appLoader";
import { updateUserDetails } from "../../store/profile/profileActions";
import { getUserSetting, updateSettingDetails, updateSettingWTLoadingDetails } from "../../store/setting/SettingAction";

const SeekingModals = (props) => {
  const { seekingModel, toggleSeekingModal, isLoading, setActiveLink } = props;
  const dispatch = useDispatch();
  const token = localStorage.getItem("accessToken");
  const { settingData } = useSelector((state) => state.setting);
  const [locationOpen, setLocationOpen] = useState(false);
  const [locationsAPIData, setLocationsAPIData] = useState([]);
  const [visibilityValue,setVisibilityValue] = useState("")

  // Job Preferences
  const [preferenceFormData, setPreferenceFormData] = useState({
    job_title: "",
    workspace_type: [],
    employment_type: [],
    location: "",
  });

  useEffect(() => {
    dispatch(jobPreference()).then((response) => {
      if (response?.data) setPreferenceFormData(response.data);
    });
    dispatch(getUserSetting(0, token));
  }, []);

  useEffect(() => {
    console.log("settingData",settingData)
    if(settingData)
    setVisibilityValue(settingData?.data[11].settings[5].value);
  }, [settingData])

  console.log("visibity value",visibilityValue);
  

  const handleInputChange = (field, value) => {
    setPreferenceFormData((prevData) => {
      return {
        ...prevData,
        [field]: value,
      };
    });
  };

  const handleWorkspaceChange = (e) => {
    let workspace_type = preferenceFormData.workspace_type.slice();
    let index = workspace_type.indexOf(e.target.name);
    if (e?.target?.checked && index == -1) workspace_type.push(e.target.name);
    else if (index != -1) workspace_type.splice(index, 1);
    handleInputChange("workspace_type", workspace_type);
  };

  const handleEmployementChange = (e) => {
    let employment_type = preferenceFormData.employment_type.slice();
    let index = employment_type.indexOf(e.target.name);
    if (e?.target?.checked && index == -1) employment_type.push(e.target.name);
    else if (index != -1) employment_type.splice(index, 1);
    handleInputChange("employment_type", employment_type);
  };

  const getLocations = ({ value }) => {
    if (value)
      dispatch(getLocationsData(value))
        .then((response) => {
          if (response.data) {
            setLocationsAPIData(response.data);
          } else setLocationsAPIData([]);
        })
        .catch((err) => {
          setLocationsAPIData([]);
        });
    else setLocationsAPIData([]);
  };

  const handleValueChange = (value) => {
    console.log("value",value);
    const updateData = {
      setting:[ {
        setting_master_id: 32,
        value: `${value}`,
      }],
    };
    setVisibilityValue(`${value}`)

    dispatch(updateSettingWTLoadingDetails(updateData, token)).then((res) => {
      if (res.success) {
        toast.success("Profile Viewing Settings updated successfully.", {
          position: toast.POSITION.BOTTOM_LEFT,
          toastId: "visibility",
        });
      }
    });
  };

  const handleSubmit = () => {
    const { job_title, workspace_type, employment_type, location } =
      preferenceFormData;
    const requestData = {
      job_title,
      workspace_type,
      employment_type,
      location,
    };
    dispatch(updateJobPreference(requestData))
      .then((data) => {
        if (data.success) {
          toast.success("Job Preference Updated Successfully...", {
            position: toast.POSITION.BOTTOM_LEFT,
          });
          dispatch(updateUserDetails({ badge: "seeking" }, token));
          toggleSeekingModal();
        }
      })
      .catch((e) => {
        toast.error(e.message, {
          position: toast.POSITION.BOTTOM_LEFT,
        });
      });
  };

  return (
    <>
      {/* Seeking Model */}
      <Modal
        size="md"
        isOpen={seekingModel}
        style={{ maxWidth: "600px" }}
        toggle={toggleSeekingModal}
        className="job-prefrence-modal gradient-background"
        centered
      >
        <div className="modal-content1">
          {/* <ModalHeader toggle={toggleSeekingModal}>Modal Title</ModalHeader> */}
          <ModalHeader
            className="dicardModalHeader"
            toggle={toggleSeekingModal}
            style={{
              padding: "30px",
            }}
          >
            <h3>
              <>
                <span
                  className="name"
                  style={{ color: "rgba(255, 255, 255, 1)" }}
                >
                  Add Job Preferences
                </span>{" "}
              </>
            </h3>
          </ModalHeader>
          <ModalBody>
            <div className="">
              {isLoading && <AppLoader />}
              <div className="modal-content1">
                {/* <button onClick={toggleSeekingModal}>x</button> */}
                {/* <h3>Add Job Preferences</h3> */}
                <form>
                  <div className="input-box mt-0">
                    <label>Job title</label>
                    <input
                      type="text"
                      value={preferenceFormData.job_title}
                      onChange={(e) =>
                        handleInputChange("job_title", e.target.value)
                      }
                      placeholder="Title"
                    />
                  </div>
                  <div className="input-box">
                    <label>Workspace</label>
                    <div className="checkbox-box">
                      <input
                        type="checkbox"
                        id="site"
                        name="On-site"
                        checked={preferenceFormData.workspace_type.includes(
                          "On-site"
                        )}
                        onChange={(e) => handleWorkspaceChange(e)}
                      />
                      <label for="site">On-site</label>
                      <input
                        type="checkbox"
                        id="hybrid"
                        name="Hybrid"
                        checked={preferenceFormData.workspace_type.includes(
                          "Hybrid"
                        )}
                        onChange={(e) => handleWorkspaceChange(e)}
                      />
                      <label for="hybrid">Hybrid </label>
                      <input
                        type="checkbox"
                        id="remote"
                        name="Remote"
                        checked={preferenceFormData.workspace_type.includes(
                          "Remote"
                        )}
                        onChange={(e) => handleWorkspaceChange(e)}
                      />
                      <label for="remote">Remote </label>
                    </div>
                  </div>
                  <div className="input-box">
                    <label>Job Location</label>
                    <div className="company-custom-dropdown mb-20">
                      <div className="autocomplete-wrapper">
                        <div className="search">
                          <Autocomplete
                            open={locationOpen}
                            value={preferenceFormData.location}
                            items={locationsAPIData}
                            getItemValue={(item) => item.name}
                            shouldItemRender={() => locationsAPIData}
                            renderMenu={(items, value) => (
                              <div className="dropdown">
                                {items.length === 0
                                  ? `No matches for ${value}`
                                  : items}
                              </div>
                            )}
                            renderItem={(item, isHighlighted) => (
                              <div
                                className={`item ${
                                  isHighlighted ? "selected-item" : ""
                                }`}
                              >
                                {item.name}
                              </div>
                            )}
                            onChange={(e, val) => {
                              if (val.length === 0) {
                                if (locationOpen) setLocationOpen(false);
                              } else {
                                if (!locationOpen) setLocationOpen(true);
                              }
                              handleInputChange("location", e.target.value);
                              getLocations(e.target);
                            }}
                            onSelect={(value, obj) => {
                              handleInputChange("location", value);
                              setLocationOpen(false);
                            }}
                            wrapperStyle={{}}
                          />
                        </div>
                      </div>
                      {/* {errorData.location && (
                        <div className="error-message" style={{ color: "red" }}>
                          {errorData.location}
                        </div>
                      )} */}
                    </div>
                  </div>
                  <div className="input-box">
                    <label>Job type</label>
                    <div className="checkbox-box">
                      <input
                        type="checkbox"
                        id="full-time"
                        name="Full-time"
                        checked={preferenceFormData.employment_type.includes(
                          "Full-time"
                        )}
                        onChange={(e) => handleEmployementChange(e)}
                      />
                      <label for="full-time">Full-time</label>
                      <input
                        type="checkbox"
                        id="part-time"
                        name="Part-time"
                        checked={preferenceFormData.employment_type.includes(
                          "Part-time"
                        )}
                        onChange={(e) => handleEmployementChange(e)}
                      />
                      <label for="part-time">Part-time </label>
                      <input
                        type="checkbox"
                        id="contract"
                        name="Contract"
                        checked={preferenceFormData.employment_type.includes(
                          "Contract"
                        )}
                        onChange={(e) => handleEmployementChange(e)}
                      />
                      <label for="contract">Contract </label>
                      <input
                        type="checkbox"
                        id="temporary"
                        name="Temporary"
                        checked={preferenceFormData.employment_type.includes(
                          "Temporary"
                        )}
                        onChange={(e) => handleEmployementChange(e)}
                      />
                      <label for="temporary">Temporary </label>
                      <input
                        type="checkbox"
                        id="other"
                        name="Other"
                        checked={preferenceFormData.employment_type.includes(
                          "Other"
                        )}
                        onChange={(e) => handleEmployementChange(e)}
                      />
                      <label for="other">Other </label>
                      <input
                        type="checkbox"
                        id="volunteer"
                        name="Volunteer"
                        checked={preferenceFormData.employment_type.includes(
                          "Volunteer"
                        )}
                        onChange={(e) => handleEmployementChange(e)}
                      />
                      <label for="volunteer">Volunteer </label>
                      <input
                        type="checkbox"
                        id="internship"
                        name="Internship"
                        checked={preferenceFormData.employment_type.includes(
                          "Internship"
                        )}
                        onChange={(e) => handleEmployementChange(e)}
                      />
                      <label for="internship">Internship </label>
                    </div>
                  </div>
                  <div className="radio-box">
                    <label className="title">
                      Choose who sees you’re seeking for job
                    </label>
                    {/*  */}
                    <div className="radio-box">
                      <input
                        type="radio"
                        id="public"
                        name="radio"
                        value="public"
                        style={{
                          width: "15px",
                          height: "15px",
                          marginTop: "3px",
                        }}
                        checked={visibilityValue=="1"?true:false}
                        onClick={() => handleValueChange(1)}
                      />
                      <label htmlFor="public">Public</label>

                      <input
                        type="radio"
                        id="network"
                        name="radio"
                        value="network"
                        style={{
                          width: "15px",
                          height: "15px",
                          marginTop: "3px",
                        }}
                        checked={visibilityValue=="2"?true:false}
                        onClick={() => handleValueChange(2)}
                      />
                      <label htmlFor="network">My Network</label>

                      <input
                        type="radio"
                        id="scouts"
                        name="radio"
                        value="scouts"
                        style={{
                          width: "15px",
                          height: "15px",
                          marginTop: "3px",
                        }}
                        checked={visibilityValue=="3"?true:false}
                        onClick={() => handleValueChange(3)}
                      />
                      <label htmlFor="scouts">Scouts only</label>
                    </div>
                  </div>
                </form>
                <div className="submit-btn-outer">
                  <input
                    type="submit"
                    value="Add to profile"
                    className="submit-btn"
                    onClick={() => handleSubmit()}
                  />
                </div>
              </div>
            </div>
          </ModalBody>
          {/* <ModalFooter>
            <Button color="secondary" onClick={toggleSeekingModal}>
              Cancel
            </Button>
            <Button color="primary" onClick={toggleSeekingModal}>
              Save
            </Button>
          </ModalFooter> */}
        </div>
      </Modal>
    </>
  );
};
export default SeekingModals;
