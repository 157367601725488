import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import "./setting.scss";
import copyrightLogo from "../../../src/assets/img/nobel.svg";
import { useDispatch, useSelector } from "react-redux";
import { getLandingPageData } from "../../store/landing/landingAction";

export default function SidebarFooter(props) {
  const { isNoBackground } = props;

  const { thirdFooter } = useSelector((state) => state.landingPage);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getLandingPageData({ title: "footer-3" }));
  }, []);

  const customStyle = {
    fontWeight: "400",
    /* font-size: 15px; */
    lineHeight: "22px",
    color: "#676a79",
    fontSize: "9.5px",
  };

  return (
    <div
      className={
        "px-3 py-3 border-radius-5 secondary-sidebar " +
        (isNoBackground ? "no-background-color" : "")
      } style={{marginBottom: "10px"}}
    >
      <div className="bottom-custom">
        <div className="links">
          <ul>
            {thirdFooter?.body?.map((item, idx) => (
              <a
                href={`${process.env.REACT_APP_WEB_STAGE_URL}${item?.router_link}`}
                key={idx}
                target="_blank"
                rel="noopener noreferrer"
              >
                <li className="card-control-text-oneline" style={customStyle}>
                  {idx !== 0 && " • "} {item?.title}
                </li>
              </a>
            ))}
            <br />
          </ul>
          {/* <ul>
            
          </ul> */}
          <div
            className="copy-right d-flex align-items-center"
            style={{ marginTop: "0px", marginLeft: "-2px" }}
          >
            <img
              src={thirdFooter?.media ? thirdFooter?.media : copyrightLogo}
              alt="logo"
              style={{ height: "30px", width: "100px" }}
            />
            <p>
              {" "}
              {thirdFooter?.title?.replace("YYYY", new Date().getFullYear())}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}
