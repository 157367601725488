import { call } from "./apiCall"

class BlogApi {
 getAll(payload) {
  return new Promise((resolve, reject) => {
   (async () => {
    try {
     const res = await call(
      "post",
      `api/v1/admin/blogs-list/`,
      null,
      payload,
      null
     )
     if (res.data.success === true) {
      resolve(res.data)
     }
     if (res.data.success === false) {
      resolve(res.data)
     }
    } catch (err) {
     console.log(err)
     reject(err)
    }
   })()
  })
 }

 getById(id) {
    return new Promise((resolve, reject) => {
     (async () => {
      try {
       const res = await call(
        "get",
        `api/v1/admin/blog/${id}`,
        null,
        null,
        null
       )
       if (res.data.success === true) {
        resolve(res.data)
       }
       if (res.data.success === false) {
        resolve(res.data)
       }
      } catch (err) {
       console.log(err)
       reject(err)
      }
     })()
    })
   }

  getBlogCategory() {
    return new Promise((resolve, reject) => {
     (async () => {
      try {
       const res = await call(
        "get",
        `api/v1/category`,
        null,
        null,
        null
       )
       if (res.data.success === true) {
        resolve(res.data)
       }
       if (res.data.success === false) {
        resolve(res.data)
       }
      } catch (err) {
       console.log(err)
       reject(err)
      }
     })()
    })
   }
}

export const blogApi = new BlogApi()
