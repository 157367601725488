import React, { useEffect, useRef, useState } from "react";
import { call } from "../../api/apiCall";
import Legal from "./Legal";
import LegalWebView from "./LegalWebView";
import { getLandingPageData } from "../../store/landing/landingAction";
import { useDispatch } from "react-redux";


export default function LegalContainer() {

  const [data,setData]= useState({});
  const myRef = useRef(null);
  const [mode,setMode] = useState("");
  const [tabs, setTabs] = useState("Accessibility");
  const dispatch = useDispatch()

  useEffect(() => {  
    let params = (new URL(document.location)).searchParams;
    let param = params.get("mode");
    setMode(param)
     }, []);  

     useEffect(()=>{
      dispatch(getLandingPageData({ title: "legal_terms" })).then((res) => {
        setData(res);
      })
      },[])

  function legal() {
    new Promise( (resolve, reject) => {
    (async () => {
        try {
            const res = await call(
                "get",
                "api/v1/get-static-pages?title=legal_terms",
                null,
                null,
                null
            )

            if(res.data.success===true){
              resolve(res.data)
              setData(res.data.data)
              console.log("Success_res", res.data.data);
          }
          if(res.data.success===false){
            console.log("fail_res", res);
            resolve(res.data)
          }

        } catch (err) {
            console.log(err)
            reject(err)
        }
    })()
})
}
const redirect = (_id) =>{
  // myRef.current.scrollIntoView();
  var access = document.getElementById(_id);
access.scrollIntoView({behavior: 'smooth'}, true)
  // document.querySelector(`#id${_id}`).scrollIntoView()
}

if(mode==="mobile"){
    return ( 
        <LegalWebView
        data={data}
        myRef={myRef}
        redirect={redirect}
        />
    );
  }
  return (
    <Legal 
    data={data}
    myRef={myRef}
    redirect={redirect}
    tabs={tabs}
    setTabs={setTabs}
    />
  );
}
