import { call } from "./apiCall";

class DashboardApi {
  createPost(postData, token) {
    return new Promise((resolve, reject) => {
      (async () => {
        try {
          const res = await call(
            "post",
            `api/v1/feed/post`,
            null,
            postData,
            token
          );
          if (res.data.success === true) {
            resolve(res.data);
          }
          if (res.data.success === false) {
            resolve(res.data);
          }
        } catch (err) {
          console.log(err);
          reject(err);
        }
      })();
    });
  }

  updatePost(postData, token) {
    return new Promise((resolve, reject) => {
      (async () => {
        try {
          const res = await call(
            "patch",
            `api/v1/feed/post`,
            null,
            postData,
            token
          );
          if (res.data.success === true) {
            resolve(res.data);
          }
          if (res.data.success === false) {
            resolve(res.data);
          }
        } catch (err) {
          console.log(err);
          reject(err);
        }
      })();
    });
  }

  deletePost(_id, token) {
    return new Promise((resolve, reject) => {
      (async () => {
        try {
          const res = await call(
            "delete",
            `api/v1/feed/post/${_id}`,
            null,
            null,
            token
          );
          if (res.data.success === true) {
            resolve(res.data);
          }
          if (res.data.success === false) {
            resolve(res.data);
          }
        } catch (err) {
          console.log(err);
          reject(err);
        }
      })();
    });
  }

  getPostDetails(token, params) {
    return new Promise((resolve, reject) => {
      (async () => {
        try {
          const res = await call(
            "get",
            `api/v1/feed/posts-list`,
            params,
            null,
            token
          );
          if (res.data.success === true) {
            resolve(res.data);
          }
          if (res.data.success === false) {
            resolve(res.data);
          }
        } catch (err) {
          console.log(err);
          reject(err);
        }
      })();
    });
  }

  getPageDetails(token, params) {
    return new Promise((resolve, reject) => {
      (async () => {
        try {
          const res = await call(
            "get",
            `api/v1/feed/select-user`,
            params,
            null,
            token
          );
          if (res.data.success === true) {
            resolve(res.data);
          }
          if (res.data.success === false) {
            resolve(res.data);
          }
        } catch (err) {
          console.log(err);
          reject(err);
        }
      })();
    });
  }

  getCelebrationTypes() {
    return new Promise((resolve, reject) => {
      (async () => {
        try {
          const res = await call(
            "get",
            `api/v1/celebration-types`,
            null,
            null,
            null
          );
          if (res.data.success === true) {
            resolve(res.data);
          }
          if (res.data.success === false) {
            resolve(res.data);
          }
        } catch (err) {
          console.log(err);
          reject(err);
        }
      })();
    });
  }

  votePoll(pollData, token) {
    return new Promise((resolve, reject) => {
      (async () => {
        try {
          const res = await call(
            "post",
            `api/v1/feed/vote-poll`,
            null,
            pollData,
            token
          );
          if (res.data.success === true) {
            resolve(res.data);
          }
          if (res.data.success === false) {
            resolve(res.data);
          }
        } catch (err) {
          console.log(err);
          reject(err);
        }
      })();
    });
  }

  votesResult(token, postId) {
    return new Promise((resolve, reject) => {
      (async () => {
        try {
          const res = await call(
            "get",
            `api/v1/feed/poll-result/${postId}`,
            null,
            null,
            token
          );
          if (res.data.success === true) {
            resolve(res.data);
          }
          if (res.data.success === false) {
            resolve(res.data);
          }
        } catch (err) {
          console.log(err);
          reject(err);
        }
      })();
    });
  }

  savePost(id, token) {
    return new Promise((resolve, reject) => {
      (async () => {
        try {
          const res = await call(
            "post",
            `api/v1/feed/save-post/${id}`,
            null,
            null,
            token
          );
          if (res.data.success === true) {
            resolve(res.data);
          }
          if (res.data.success === false) {
            resolve(res.data);
          }
        } catch (err) {
          console.log(err);
          reject(err);
        }
      })();
    });
  }

  removeSavePost(_id, token) {
    return new Promise((resolve, reject) => {
      (async () => {
        try {
          const res = await call(
            "delete",
            `api/v1/feed/save-post/${_id}`,
            null,
            null,
            token
          );
          if (res.data.success === true) {
            resolve(res.data);
          }
          if (res.data.success === false) {
            resolve(res.data);
          }
        } catch (err) {
          console.log(err);
          reject(err);
        }
      })();
    });
  }

  getComment(token, query) {
    return new Promise((resolve, reject) => {
      (async () => {
        try {
          const res = await call(
            "get",
            `api/v1/feed/comments/`,
            query,
            null,
            token
          );
          if (res.data.success === true) {
            resolve(res.data);
          }
          if (res.data.success === false) {
            resolve(res.data);
          }
        } catch (err) {
          console.log(err);
          reject(err);
        }
      })();
    });
  }

  updateComment(token, commentID, commentData) {
    return new Promise((resolve, reject) => {
      (async () => {
        try {
          const res = await call(
            "patch",
            `api/v1/feed/comment/${commentID}`,
            null,
            commentData,
            token
          );
          if (res.data.success === true) {
            resolve(res.data);
          }
          if (res.data.success === false) {
            resolve(res.data);
          }
        } catch (err) {
          console.log(err);
          reject(err);
        }
      })();
    });
  }

  deleteComment(token, id) {
    return new Promise((resolve, reject) => {
      (async () => {
        try {
          const res = await call(
            "delete",
            `api/v1/feed/comments/${id}`,
            null,
            null,
            token
          );
          if (res.data.success === true) {
            resolve(res.data);
          }
          if (res.data.success === false) {
            resolve(res.data);
          }
        } catch (err) {
          console.log(err);
          reject(err);
        }
      })();
    });
  }

  addComment(data, token) {
    return new Promise((resolve, reject) => {
      (async () => {
        try {
          const res = await call(
            "patch",
            `api/v1/feed/comment`,
            null,
            data,
            token
          );
          if (res.data.success === false) {
            alert(res.data.message);
            return;
          }
          resolve(res.data);
        } catch (err) {
          console.log(err);
          reject(err);
        }
      })();
    });
  }

  pinComment(data, token) {
    return new Promise((resolve, reject) => {
      (async () => {
        try {
          const res = await call(
            "patch",
            `api/v1/feed/pinned-comment`,
            null,
            data,
            token
          );
          if (res.data.success === true) {
            resolve(res.data.message);
          }
          if (res.data.success === false) {
            resolve(res.data.message);
          }
        } catch (err) {
          console.log(err);
          reject(err);
        }
      })();
    });
  }

  deleteCommentData(data, token) {
    return new Promise((resolve, reject) => {
      (async () => {
        try {
          const res = await call(
            "delete",
            `api/v1/feed/delete-comment`,
            null,
            data,
            token
          );
          resolve(res.data);
        } catch (err) {
          console.log(err);
          reject(err);
        }
      })();
    });
  }

  get_Report_type(token) {
    return new Promise((resolve, reject) => {
      (async () => {
        try {
          const res = await call(
            "get",
            `api/v1/report-type`,
            null,
            null,
            token
          );
          if (res.data.success === true) {
            resolve(res.data);
          }
          if (res.data.success === false) {
            resolve(res.data);
          }
        } catch (err) {
          console.log(err);
          reject(err);
        }
      })();
    });
  }

  submitReport(data) {
    let token = localStorage.getItem("accessToken");
    return new Promise((resolve, reject) => {
      (async () => {
        try {
          const res = await call(
            "post",
            `api/v1/feed/report-post`,
            null,
            data,
            token
          );
          if (res.data.success === true) {
            resolve(res.data);
          }
          if (res.data.success === false) {
            resolve(res.data);
          }
        } catch (err) {
          console.log(err);
          reject(err);
        }
      })();
    });
  }

  blockCommentReport(data) {
    let token = localStorage.getItem("accessToken");
    return new Promise((resolve, reject) => {
      (async () => {
        try {
          const res = await call(
            "post",
            `api/v1/feed/block-comment`,
            null,
            data,
            token
          );
          if (res.data.success === true) {
            resolve(res.data);
          }
          if (res.data.success === false) {
            resolve(res.data);
          }
        } catch (err) {
          console.log(err);
          reject(err);
        }
      })();
    });
  }

  hideUserPost(post_id) {
    let token = localStorage.getItem("accessToken");
    return new Promise((resolve, reject) => {
      (async () => {
        try {
          const res = await call(
            "post",
            `api/v1/feed/hide-post/${post_id}`,
            null,
            null,
            token
          );
          if (res.data.success === true) {
            resolve(res.data);
          }
          if (res.data.success === false) {
            resolve(res.data);
          }
        } catch (err) {
          console.log(err);
          reject(err);
        }
      })();
    });
  }

  repostPost(postData) {
    let token = localStorage.getItem("accessToken");
    return new Promise((resolve, reject) => {
      (async () => {
        try {
          const res = await call(
            "post",
            `api/v1/feed/post`,
            null,
            postData,
            token
          );
          if (res.data.success === true) {
            resolve(res.data);
          }
          if (res.data.success === false) {
            resolve(res.data);
          }
        } catch (err) {
          console.log(err);
          reject(err);
        }
      })();
    });
  }

  commentStatus(postId) {
    let token = localStorage.getItem("accessToken");
    return new Promise((resolve, reject) => {
      (async () => {
        try {
          const res = await call(
            "patch",
            `api/v1/feed/comment-status/${postId}`,
            null,
            null,
            token
          );
          if (res.data.success === true) {
            resolve(res.data);
          }
          if (res.data.success === false) {
            resolve(res.data);
          }
        } catch (err) {
          console.log(err);
          reject(err);
        }
      })();
    });
  }

  postViewlog(postData) {
    let token = localStorage.getItem("accessToken");
    return new Promise((resolve, reject) => {
      (async () => {
        try {
          const res = await call(
            "post",
            `api/v1/user/view-log`,
            null,
            postData,
            token
          );
          if (res.data.success === true) {
            resolve(res.data);
          }
          if (res.data.success === false) {
            resolve(res.data);
          }
        } catch (err) {
          console.log(err);
          reject(err);
        }
      })();
    });
  }


  getViewStatistics(view_type, view_type_id, type, limit, skip) {
    let token = localStorage.getItem("accessToken");
    return new Promise((resolve, reject) => {
      
      (async () => {
        try {
          let url = '';
          if(view_type == "views") url = `api/v1/user/viewer-list?view_type=${type}&view_type_id=${view_type_id}&limit=${limit}&skip=${skip}`
          if(view_type == "shares") url = `api/v1/feed/share-list/${view_type_id}?type=${type}&limit=${limit}&skip=${skip}`
          if(view_type == "comments") url = `api/v1/feed/comments?type_id=${view_type_id}&type=${type}&limit=${limit}&skip=${skip}`
          if(view_type == "likes") url = `api/v1/feed/reaction/${view_type_id}?type=${type}&limit=${limit}&skip=${skip}`
          const res = await call(
            "get",
            url,
            {},
            null,
            token
          );
          if (res.data.success === true) {
            resolve(res.data);
          }
          if (res.data.success === false) {
            resolve(res.data);
          }
        } catch (err) {
          console.log(err);
          reject(err);
        }
      })();
    });
  }

  getAdsList(){
    let token = localStorage.getItem("accessToken");
    return new Promise((resolve, reject) => {
      (async () => {
        try {
          const res = await call(
            "get",
            `api/v1/feed/campaign-adbar`,
            null,
            null,
            token
          );
          if (res.data.success === true) {
            resolve(res.data);
          }
          if (res.data.success === false) {
            resolve(res.data);
          }
        } catch (err) {
          console.log(err);
          reject(err);
        }
      })();
    });
  }
}

export const dashboardApi = new DashboardApi();
