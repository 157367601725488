import React, { useState } from "react";
import { Input, Modal, ModalBody, ModalHeader } from "reactstrap";

import { useDispatch, useSelector } from "react-redux";
import {
  getUserSetting,
  updateSettingDetails,
} from "../../../store/setting/SettingAction";
import { useEffect } from "react";

const PrivateAccountModal = (props) => {
  const { modal, toggle, title, data } = props;

  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.auth);
  const [responseMsg, setResponseMsg] = useState("");
  const [privateAcc, setPrivateAcc] = useState(true);

  useEffect(() => {
    try {
      let mode = false;
      for (let index = 0; index < data.length; index++) {
        const element = data[index];
        if (element["key"] == "private_account") {
          let { value } = element.settings[0];
          if (value == 1) {
            mode = true;
          }
        }
      }

      setPrivateAcc(mode);
    } catch (error) {
      console.log(error);
    }
  }, []);

  const updateMode = async (event) => {
    try {
      let token = localStorage.getItem("accessToken");
      setPrivateAcc(event.target.checked);
      let value = 0;
      if (event.target.checked === true) {
        value = 1;
      }
      let updateData = {
        setting: [
          {
            setting_master_id: 23,
            value: value + "",
          },
        ],
      };
      dispatch(updateSettingDetails(updateData, token)).then((res) => {
        setResponseMsg(res.message);
        dispatch(getUserSetting(0, token));
        setTimeout(() => {
          setResponseMsg(false);
        }, 3000);
        alert(
          "This feature will be implemented in the future milestone"
        );
      });
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div>
      <Modal isOpen={modal} size="lg" style={{ maxWidth: "500px" }}>
        <div className="popup">
          <div className="setting-popup">
            <ModalHeader toggle={toggle} id="account-modal">{title}</ModalHeader>
            <ModalBody>
              <div className="box">
                <p>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc
                  vulputate libero et velit interdum, ac aliquet
                </p>
                <div className="d-flex justify-content-between">
                  <div className="enable">Make Private Account</div>
                  <div className="switch">
                    <label className="switch">
                      <input
                        type="checkbox"
                        onChange={(e) => updateMode(e)}
                        checked={privateAcc}
                      />
                      <span className="slider round"></span>
                    </label>
                  </div>
                </div>
                {responseMsg && (
                  <label className="label-input100 text-success">
                    {responseMsg}
                  </label>
                )}
              </div>
            </ModalBody>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default PrivateAccountModal;
