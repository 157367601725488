import { GET_BLOCKED_USERS } from "../types";

export const initialState = {};

const blockedUsers = (state = initialState, action) => {
 switch (action.type) {
  case GET_BLOCKED_USERS:
   return { ...state, blockedUsers: action.payload };
  default:
   return state;
 }
};

export default blockedUsers;