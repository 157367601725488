import React, { useRef } from "react";
import OutsideNavbar from "../../navbar/OutsideNavbar";
import { useDispatch, useSelector } from "react-redux";
import {
  getPostPublicDetail,
  getSinglCompany,
} from "../../../store/directory/directoryAction";
import add from "../../../assets/img/add-icon.svg";
import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { getExploreData } from "../../../store/explore/exploreActions";
import { useState } from "react";
import CommentsContainer from "../../../routes/dashboard/components/comments/Comments.container";
import ShowDasboardImage from "../../../routes/dashboard/components/ShowDasboardImage";
import moment from "moment";
import placeholderUser from "../../../assets/img/placeholder_user.jpg";
import like from "../../../assets/img/like.svg";
import surp from "../../../assets/img/surprised.svg";
import likeGrey from "../../../assets/img/like_grey.svg";
import hand from "../../../assets/img/hand.svg";
import heart from "../../../assets/img/heart.svg";
import bulb from "../../../assets/img/bulb.svg";
import emoj from "../../../assets/img/emoj.svg";
import share from "../../../assets/img/share.svg";
import eye from "../../../assets/img/eye.svg";
import { postViewLog } from "../../../store/dashboard/dashboardActions";

const PostPublicDetails = (props) => {
  const dispatch = useDispatch();
  const { singlePublicPostData } = useSelector((state) => state.directory);
  const [tabsList, setTabsList] = useState([]);
  const [commentDetails, setCommentDetails] = useState([]);
  const [byUserId, setByUserId] = useState(0);
  const [isReaction, setIsReaction] = useState(-1);
  const [isComments, setIsComments] = useState(-1);
  const [isViewReaction, setViewReaction] = useState(false);
  const [commentSection, setCommentSection] = useState(2);
  const [repliesSection, setRepliesSection] = useState([]);
  const [comment, setComment] = useState("");
  const [commentLike, setCommentLike] = useState(-1);
  const [userName, setUserName] = useState("");
  const [dropDownShare, setDropDownShare] = useState(-1);
  const [closeCommentModal, setCloseCommentModal] = useState(false);
  const [commentDeleteId, setCommentDeleteId] = useState();
  const [reportModal, setReportModal] = useState(false);
  const [blockModal, setBlockModal] = useState(false);
  const [commentId, setCommentId] = useState();
  const [dropDown, setDropDown] = useState(-1);

  const dropRef = useRef(null);
  const dropRefShare = useRef(null);
  const id = window.location.search.substr(4);
  const navigate = useNavigate();
  useEffect(() => {
    dispatch(getPostPublicDetail({ id: id }));
    dispatch(getExploreData()).then((resp) => {
      setTabsList(resp.data);
    });
  }, []);

  useEffect(() => {
    if (singlePublicPostData[0]?.commentData) {
      setCommentDetails(singlePublicPostData[0]?.commentData);
    }
  }, [singlePublicPostData[0]?.commentData]);

  function privacy_type(privacy_id) {
    if (privacy_id === 1) return " Public";
    if (privacy_id === 2) return " Connection";
    return " Group";
  }

  const getHashtagText = (content) =>
    content
      ?.replace(/\n#/g, " \n#")
      .replace(/\n/g, " \n")
      ?.split(" ")
      ?.map((str, index) => {
        if ((str.startsWith("#") || str.startsWith("\n#")) && str.length > 1) {
          let keyword = str.startsWith("#")
            ? str.substring(1)
            : str.substring(2);
          return (
            <a
              href={`/hashtag/feed/?keyword=${keyword}`}
              className="text-blue-500"
              key={index}
            >
              {str}{" "}
            </a>
          );
        }
        return str + " ";
      });
  const likeResult = (arr1, arr2) => {
    if (arr1 === undefined || arr1 === null) {
      let array3 = arr2;
      return array3;
    } else {
      let arr4 = [{ type: arr1 }];
      let array3 = arr4?.concat(
        arr2?.filter((bo) =>
          arr4?.every((ao) => ao?.type !== bo?.type && bo?.page_id !== null)
        )
      );
      return array3;
    }
  };

  const getTime = (date) => {
    let result = moment(date).fromNow();
    const now = moment();
    const hours = now.diff(date, "hours");
    const days = now.diff(date, "days");
    const weeks = now.diff(date, "weeks");
    if (hours >= 22 && hours <= 47) {
      result = `1 day ago`;
    } else if (days >= 7) {
      if (days <= 13) {
        result = `1 week ago`;
      } else if (days > 13 && days <= 25) {
        result = `${weeks} weeks ago`;
      }
    }
    return result;
  };

  const postViewCounter = (id) => {
    console.log("jhhjjhhj postViewCounter");
    const payload = {
      view_type: "post",
      post_id: id,
    };
    dispatch(postViewLog(payload));
  };

  return (
    <div>
      <OutsideNavbar />
      <div className="main-bg admin-bg ">
        <div className="company-details">
          <div className="container">
            <div className="row">
              <div className="col-lg-3"></div>
              <div className="col-lg-6 post-main-box">
                <div style={{ position: "relative" }}>
                  <div
                    className="post-head mb-2"
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    {singlePublicPostData[0]?.Repost ||
                    singlePublicPostData[0]?.Reaction_Activity ||
                    singlePublicPostData[0]?.Hashtag_Activity ? (
                      <div
                        ref={dropRef}
                        className="dots clickDots "
                        onClick={() => {
                          // if (dropDown === index) {
                          //   setDropDown(-1);
                          // } else {
                          //   setDropDown(index);
                          // }
                        }}
                      >
                        {/* <span className="clickDots">&#x2022;</span>
                        <span className="clickDots">&#x2022;</span>
                        <span className="clickDots">&#x2022;</span> */}
                      </div>
                    ) : (
                      <></>
                    )}
                  </div>
                  <div className="main-post ">
                    <div className="main-Post-div ">
                      <div className="prof-img" onClick={() => navigate("/")}>
                        <img
                          src={
                            singlePublicPostData[0]?.User?.profile_img
                              ? singlePublicPostData[0]?.User?.profile_img
                              : placeholderUser
                          }
                          alt=""
                          className="img-fluid"
                          style={{ width: "40px" }}
                        />
                      </div>
                      <div className="details">
                        <>
                          <h4
                            className="userNameTextWrap"
                            onClick={() => navigate("/")}
                          >
                            {singlePublicPostData[0]?.User?.first_name +
                              " " +
                              singlePublicPostData[0]?.User?.last_name}
                          </h4>
                          {singlePublicPostData[0]?.User?.profile
                            ?.profile_headline ? (
                            <p className=" card-control-text-twoline maxWid">
                              {
                                singlePublicPostData[0]?.User?.profile
                                  ?.profile_headline
                              }
                            </p>
                          ) : (
                            ""
                          )}
                        </>
                        <p>
                          <span>
                            {getTime(singlePublicPostData[0]?.updatedAt)}
                          </span>
                          <span className="ml-5">&#x2022;</span>
                          <span>
                            <>
                              {privacy_type(
                                singlePublicPostData[0]?.privacy_id
                              )}
                            </>
                          </span>
                        </p>
                      </div>
                    </div>
                    <div
                      ref={dropRef}
                      className="dots clickDots "
                      // onClick={
                      //   () => {
                      //         if (dropDown === index) {
                      //           setDropDown(-1);
                      //         } else {
                      //           setDropDown(index);
                      //         }
                      //       }
                      // }
                    >
                      <span className="clickDots">&#x2022;</span>
                      <span className="clickDots">&#x2022;</span>
                      <span className="clickDots">&#x2022;</span>
                    </div>
                  </div>
                  <div
                    id="descriptionOverFlow"
                    className="text form-control border-none"
                    style={{ padding: "0px" }}
                  >
                    <span className="descriptionOverFlowSpan despan">
                      {singlePublicPostData[0]?.description
                        ? getHashtagText(singlePublicPostData[0]?.description)
                        : ""}
                    </span>
                    {/* ) */}
                    {/* } */}
                  </div>

                  {singlePublicPostData[0]?.Feed_Documents && (
                    <div className="post-img row">
                      {
                        <div className="main-view">
                          <ShowDasboardImage
                            item={singlePublicPostData[0]}
                            userName={userName}
                            // privacy_type={privacy_type}
                            // commentBoxStatus={commentBoxStatus}
                            // setCommentBoxStatus={setCommentBoxStatus}
                            // dropRefShare={dropRefShare}
                            // dropDownShare={dropDownShare}
                            // setDropDownShare={setDropDownShare}
                            // podType={type}
                            // repostPost={repostPost}
                            getHashtagText={getHashtagText}
                            // handleReaction={handleReaction}
                            likeResult={likeResult}
                            // sendRequest={sendRequest}
                            // peopleFollowRequest={peopleFollowRequest}
                            // peopleUnFollowRequest={peopleUnFollowRequest}
                            // handlePageFollow={handlePageFollow}
                            // handlePageUnFollow={handlePageUnFollow}
                            getTime={getTime}
                            postViewCounter={postViewCounter}
                            source="publicDirectory"
                          />
                          {singlePublicPostData[0].view_count > 0 && (
                            <div
                              className={
                                singlePublicPostData[0]?.Feed_Documents[0]
                                  ?.document_type === "video"
                                  ? "image-video-view-count"
                                  : "image-view-count"
                              }
                            >
                              <img
                                width={"20px"}
                                className="me-1"
                                src={eye}
                                alt="view_icon"
                              />
                              {singlePublicPostData[0].view_count}
                            </div>
                          )}
                        </div>
                      }
                    </div>
                  )}
                  <div className="flex mt-3">
                    <div className="d-flex">
                      <div
                        className="like_main_comment_post"
                        onClick={() => navigate("/")}
                      >
                        {/* <div className="likes_comment_post">
                  {singlePublicPostData[0]?.reaction?.byUser?.type === "Like" ? (
                    <img src={like} alt="" />
                  ) : (
                    <img
                      src={like}
                      alt=""
                      onClick={
                        type === "groupPost"
                          ? () =>
                              alert(
                                "This feature will be implemented in upcoming milestones"
                              )
                          : () => {
                              handleReaction(singlePublicPostData[0], "Like");
                            }
                      }
                    />
                  )}
                  {singlePublicPostData[0]?.reaction?.byUser?.type === "Love" ? (
                    <img src={heart} alt="" />
                  ) : (
                    <img
                      src={heart}
                      alt=""
                      onClick={
                        type === "groupPost"
                          ? () =>
                              alert(
                                "This feature will be implemented in upcoming milestones"
                              )
                          : () => {
                              handleReaction(singlePublicPostData[0], "Love");
                            }
                      }
                    />
                  )}
                  {singlePublicPostData[0]?.reaction?.byUser?.type === "Celebrate" ? (
                    <img src={hand} alt="" />
                  ) : (
                    <img
                      src={hand}
                      alt=""
                      onClick={
                        type === "groupPost"
                          ? () =>
                              alert(
                                "This feature will be implemented in upcoming milestones"
                              )
                          : () => {
                              handleReaction(singlePublicPostData[0], "Celebrate");
                            }
                      }
                    />
                  )}
                  {item?.reaction?.byUser?.type === "Insightful" ? (
                    <img src={bulb} alt="" />
                  ) : (
                    <img
                      src={bulb}
                      alt=""
                      onClick={
                        type === "groupPost"
                          ? () =>
                              alert(
                                "This feature will be implemented in upcoming milestones"
                              )
                          : () => {
                              // handleReaction(item, "Insightful");
                            }
                      }
                    />
                  )}
                  {singlePublicPostData[0]?.reaction?.byUser?.type === "Curious" ? (
                    <img src={emoj} alt="" />
                  ) : (
                    <img
                      src={emoj}
                      alt=""
                      onClick={
                        type === "groupPost"
                          ? () =>
                              alert(
                                "This feature will be implemented in upcoming milestones"
                              )
                          : () => {
                              // handleReaction(item, "Curious");
                            }
                      }
                    />
                  )}
                  {singlePublicPostData[0]?.reaction?.byUser?.type === "Surprise" ? (
                    <img src={surp} alt="" />
                  ) : (
                    <img
                      src={surp}
                      alt=""
                      onClick={
                        type === "groupPost"
                          ? () =>
                              alert(
                                "This feature will be implemented in upcoming milestones"
                              )
                          : () => {
                          
                            }
                      }
                    />
                  )}
                </div> */}
                        <div className="like me-4">
                          {singlePublicPostData[0]?.reaction?.byUser?.type ===
                          "Like" ? (
                            <span
                              onClick={() =>
                                // handleReaction(item, "Like", "deleteReaction")
                                navigate("/")
                              }
                              style={{ color: "#0432A3" }}
                            >
                              <img src={like} alt="" /> Like
                            </span>
                          ) : singlePublicPostData[0]?.reaction?.byUser
                              ?.type === "Celebrate" ? (
                            <span
                              onClick={() => navigate("/")}
                              style={{ color: "#198754" }}
                            >
                              <img src={hand} alt="" /> Celebrate
                            </span>
                          ) : singlePublicPostData[0]?.reaction?.byUser
                              ?.type === "Curious" ? (
                            <span
                              onClick={() => navigate("/")}
                              style={{ color: "#ffc107" }}
                            >
                              <img src={emoj} alt="" /> Curious
                            </span>
                          ) : singlePublicPostData[0]?.reaction?.byUser
                              ?.type === "Love" ? (
                            <span
                              onClick={() => navigate("/")}
                              style={{ color: "#dc3545" }}
                            >
                              <img src={heart} alt="" /> Love
                            </span>
                          ) : singlePublicPostData[0]?.reaction?.byUser
                              ?.type === "Insightful" ? (
                            <span
                              onClick={() => navigate("/")}
                              style={{ color: "#3F2DAF" }}
                            >
                              <img src={bulb} alt="" /> Insightful
                            </span>
                          ) : singlePublicPostData[0]?.reaction?.byUser
                              ?.type === "Surprise" ? (
                            <span
                              onClick={() => navigate("/")}
                              style={{ color: "#ffc107" }}
                            >
                              <img src={surp} alt="" /> Surprise
                            </span>
                          ) : singlePublicPostData[0]?.reaction?.byUser ===
                            null ? (
                            <span>
                              <img src={likeGrey} alt="" /> <span>Like</span>
                            </span>
                          ) : (
                            <></>
                          )}
                        </div>
                      </div>
                      <div
                        className="others viewReactionsType"
                        // onClick={() => {
                        //   setViewReaction(!isViewReaction);
                        //   setPostID(singlePublicPostData[0]?.id);
                        // }}
                      >
                        <div className="curserPointer">
                          {singlePublicPostData[0]?.reaction?.count < 1 ? (
                            singlePublicPostData[0]?.reaction?.byUser?.type ===
                            "Like" ? (
                              <img src={like} alt="" />
                            ) : singlePublicPostData[0]?.reaction?.byUser
                                ?.type === "Celebrate" ? (
                              <img src={hand} alt="" />
                            ) : singlePublicPostData[0]?.reaction?.byUser
                                ?.type === "Love" ? (
                              <img src={heart} alt="" />
                            ) : singlePublicPostData[0]?.reaction?.byUser
                                ?.type === "Curious" ? (
                              <img src={emoj} alt="" />
                            ) : singlePublicPostData[0]?.reaction?.byUser
                                ?.type === "Insightful" ? (
                              <img src={bulb} alt="" />
                            ) : singlePublicPostData[0]?.reaction?.byUser
                                ?.type === "Surprise" ? (
                              <img src={surp} alt="" />
                            ) : (
                              ""
                            )
                          ) : (
                            likeResult(
                              singlePublicPostData[0]?.reaction?.byUser?.type,
                              singlePublicPostData[0]?.reaction?.type
                            )?.map((item, idx) =>
                              idx < 3 ? (
                                <>
                                  {item?.type === "Like" ? (
                                    <img src={like} alt="" />
                                  ) : item?.type === "Celebrate" ? (
                                    <img src={hand} alt="" />
                                  ) : item?.type === "Love" ? (
                                    <img src={heart} alt="" />
                                  ) : item?.type === "Curious" ? (
                                    <img src={emoj} alt="" />
                                  ) : item?.type === "Insightful" ? (
                                    <img src={bulb} alt="" />
                                  ) : item?.type === "Surprise" ? (
                                    <img src={surp} alt="" />
                                  ) : (
                                    ""
                                  )}{" "}
                                </>
                              ) : (
                                ""
                              )
                            )
                          )}
                          <span>
                            <span className="txt" onClick={() => navigate("/")}>
                              {singlePublicPostData[0]?.reaction?.count > 0 ? (
                                singlePublicPostData[0].reaction.count
                              ) : (
                                <></>
                              )}
                            </span>
                          </span>
                        </div>
                      </div>
                    </div>
                    <div className="d-flex" style={{ gap: "12px" }}>
                      {singlePublicPostData[0]?.comment_status ? (
                        <div
                          className="comment curserPointer"
                          onClick={() => navigate("/")}
                        >
                          <img src={comment} alt="" />
                          <span>
                            {singlePublicPostData[0]?.comment_count > 1
                              ? " Comments "
                              : " Comment "}
                            {singlePublicPostData[0]?.comment_count > 0 &&
                              `(${singlePublicPostData[0]?.comment_count})`}
                          </span>
                        </div>
                      ) : (
                        <></>
                      )}
                      {/* <div
                className="share"
                ref={dropRefRepost}
                onClick={
                  type === "groupPost"
                    ? () =>
                        alert(
                          "This feature will be implemented in upcoming milestones"
                        )
                    : () => {
                        if (dropDownRepost === index) {
                          setDropDownRepost(-1);
                        } else {
                          setDropDownRepost(index);
                        }
                      }
                }
              >
                <img src={repost} alt="" />
                <span style={{ marginLeft: "4px" }}>Repost</span>
                <div className="drop">
                  {dropDownRepost === index && (
                    <div className="box box-repost">
                      <ul ref={dropRefRepost}>
                        <li
                          className="shareListing"
                          onClick={() => repostPost(singlePublicPostData[0]?.id, type)}
                        >
                          <img src={repost} alt="" /> Repost
                        </li>
                        <li
                          className="shareListing"
                          onClick={() => {
                            setOpenShareModal(!openShareModal);
                            setShareData(singlePublicPostData[0]);
                          }}
                          style={{ marginLeft: "2px", gap: "17px" }}
                        >
                          <img src={edit} alt="" /> Repost with own thoughts
                        </li>
                      </ul>
                    </div>
                  )}
                </div>
              </div> */}
                      <div
                        className="share"
                        ref={dropRefShare}
                        onClick={() => navigate("/")}
                      >
                        <img src={share} alt="" />
                        <span>
                          {singlePublicPostData[0]?.shared_count > 1
                            ? " Shares "
                            : " Share "}
                          {singlePublicPostData[0]?.shared_count > 0 &&
                            `(${singlePublicPostData[0]?.shared_count})`}
                        </span>
                        <div className="drop">
                          {/* {dropDownShare === index && (
                    <div className="box box-share">
                      <ul ref={dropRefShare}>
                        <li
                          className="shareListing"
                          onClick={() => {
                            navigate("/")
                          }}
                        >
                          <img src={sendIcon} alt="" /> Send this post
                        </li>
                      </ul>
                    </div>
                  )} */}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {/* {commentBoxStatus?.find((x) => x.id === index)?.status === true ? (
        <CommentsContainer
          postId={item}
          type={type}
          dropDown={dropDown}
          setDropDown={setDropDown}
          item={item}
          userId={userId}
          setPostID={setPostID}
          reportModal={reportModal}
          setReportModal={setReportModal}
          reportType={reportType}
          setReportType={setReportType}
          reportCommentId={reportCommentId}
          setReportCommentId={setReportCommentId}
        />
      ) : (
        ""
      )} */}
              </div>
              <div className="col-lg-3"></div>
              {/* <div className="col-lg-4 other-viewed"> */}
              {/* <div className="explore">
                  <h6>Explore topics</h6>
                  <ul className="mt-2">
                    {tabsList.map((item, i) => (
                      <li
                        key={i}
                        onClick={() => {
                          navigate(`/explore?category_id=${item.category_id}`);
                        }}
                      >
                        <span>{item.name}</span>
                      </li>
                    ))}
                  </ul>
                </div> */}
              {/* </div> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PostPublicDetails;
