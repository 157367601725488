import React from "react";
import { Input, Modal, ModalBody, ModalHeader } from "reactstrap";
import placeholderUser from "../../../../assets/img/placeholder_user.jpg";
import people from "../../../../assets/img/people.svg";
import "./Visibility.scss";
import { useEffect } from "react";
import { useSelector } from "react-redux";

const SelectGroup = (props) => {
  const {
    modal,
    toggle,
    title,
    setGroupName,
    groupId,
    setGroupId,
    joined_groups,
    setVisibiltyModal,
    setPrivacyId,
  } = props;
  useEffect(() => {
    setVisibiltyModal(false);
  }, []);
 
  const {my_groups} = useSelector((state)=>state.groups);
  const updatePublish = (i) => {
    setGroupName(i.name);
    setGroupId(i.id);
    toggle();
  };
  const handleClose = () => {
    setPrivacyId(1);
    setVisibiltyModal(false);
    toggle();
  };
  return (
    <div>
      <Modal
        isOpen={modal}
        className="create-post-header"
        size="lg"
        style={{ maxWidth: "600px", top: "55px" }}
      >
        <ModalHeader toggle={handleClose}>{title}</ModalHeader>
        <ModalBody className="publishModalClass mb-20">
          <div className="row create-post select-group">
            <h5>Select an option:</h5>
            {my_groups?.map((item, index) => {
              return (
                <div
                  key={index}
                  className="details d-flex justify-content-between align-items-center mb-20 mt-20"
                >
                  <div className="d-flex gap-4" style={{ width: "90%" }}>
                    <div className="">
                      <img
                        src={item.icon ? item.icon : placeholderUser}
                        className="round-image"
                        alt="group_img"
                      />
                    </div>
                    <div className="names">
                      <h6>{item.name}</h6>
                      {item.type === "public" ? (
                        <div className="d-flex align-items-center">
                          <div className="globe"></div>
                          <p>{item.type}</p>
                        </div>
                      ) : (
                        <div className="d-flex align-items-center">
                          <div className="privateIcon"></div>
                          <p>{item.type}</p>
                        </div>
                      )}
                    </div>
                  </div>

                  <input
                    type="radio"
                    name="user"
                    id={index}
                    checked={item.id === groupId ? true : false}
                    onClick={(e) => updatePublish(item)}
                  />
                </div>
              );
            })}
          </div>
        </ModalBody>
      </Modal>
    </div>
  );
};

export default SelectGroup;
