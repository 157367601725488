import { GET_REFER_DATA } from "../types";

export const initialState = {
  refer_data: [],
};

const wallet = (state = initialState, action) => {
  switch (action.type) {
    case GET_REFER_DATA:
      return { ...state, refer_data: action.payload };

    default:
      return state;
  }
};

export default wallet;
