import React from "react";
import pagePlaceholder from "../../../../assets/img/page-placeholder.svg";
import { Link } from "react-router-dom";
const SimilarPage = ({ list }) => {
  return (
    <>
      <div className="mt-5">
        <p className="fw-bold mb-1 text-dark">Similar Pages</p>
        <div className="line mb-3"></div>
        {list?.data?.map((ele) => {
          return (
            <>
              <div className="d-flex mt-3">
                <img
                  src={ele?.icon || pagePlaceholder}
                  alt="logo"
                  width={35}
                  height={35}
                  style={{ borderRadius: "50%" }}
                />
                <div className="mx-2">
                  <h6 className="text-dark">{ele?.name}</h6>
                  <p style={{ fontSize: "10px" }}>
                    {ele?.followers_count > 1
                      ? ele?.followers_count + " Followers"
                      : ele?.followers_count + " Follower"}
                  </p>
                </div>
              </div>
            </>
          );
        })}
        {list?.count > list?.data?.length && (
          <div className="mt-3">
            <Link to="/" style={{ color: "#0432A3", fontWeight: 600 }}>
              See more
            </Link>
          </div>
        )}
      </div>
    </>
  );
};

export default SimilarPage;
