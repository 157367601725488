import React from "react";
import { call } from "./apiCall";

class REACTIONAPI{
    reactionsData(data){
        return new Promise( (resolve, reject) => {
            const token = localStorage.getItem("accessToken");
            (async () => {
                try {
                    const res = await call(
                        "post",
                        "api/v1/feed/reaction",
                        null,
                        data,
                        token
                    )

                    if(res.data.success===true){
                        resolve(res.data)
                  }
                  if(res.data.success===false){
                    resolve(res.data)
                  }

                } catch (err) {
                    console.log(err)
                    reject(err)
                }
            })()
        })
    }
    getAllReactions(post_id,type){
        // /api/v1/feed/reaction/:post_id
        // console.log(token)
        const token = localStorage.getItem("accessToken");
        return new Promise( (resolve, reject) => {
              (async () => {
                try {
                    const res = await call(
                        "get",
                        `api/v1/feed/reaction/${post_id}`,
                        type,
                        null,
                        token
                    )
                    if(res.data.success===true){
                        resolve(res.data)
                  }
                  if(res.data.success===false){
                    resolve(res.data)
                  }

                } catch (err) {
                    console.log(err)
                    reject(err)
                }
            })()
        })
    }

    reactionsCommentsData(data){
        return new Promise( (resolve, reject) => {
            const token = localStorage.getItem("accessToken");
            (async () => {
                try {
                    const res = await call(
                        "post",
                        "api/v1/feed/reaction",
                        null,
                        data,
                        token
                    )

                    if(res.data.success===true){
                        resolve(res.data)
                  }
                  if(res.data.success===false){
                    resolve(res.data)
                  }

                } catch (err) {
                    console.log(err)
                    reject(err)
                }
            })()
        })
    }


}

export const reactionApi= new REACTIONAPI()