import React, { useEffect } from "react";
import PortalDashboard from "./PortalDashboard";
import { useDispatch } from "react-redux";
import { getTalentPoolDetails, getTalentPoolDetailsByToken } from "../../../store/portal/portalAction";
import { useParams } from "react-router-dom";

function PortalDashboardContainer() {
  const dispatch = useDispatch();
  const { param } = useParams();

  useEffect(() => {
    // dispatch(getTalentPoolDetailsByToken());
    dispatch(getTalentPoolDetails(param));
    dispatch(getTalentPoolDetailsByToken());
  }, []);
  return <PortalDashboard />;
}

export default PortalDashboardContainer;
