import React from "react";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import alertIcon from "../../../assets/img/Warning.png";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
const InfoAlertModel = ({
  modal,
  toggle,
  updateInfo,
  setupdateInfo,
  handleNext,
}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  return (
    <>
      <Modal size="md" isOpen={modal} style={{ maxWidth: "450px" }} centered  
        className="back_ground_screening_model"
      
      >
        <ModalHeader
          className="dicardModalHeader text-white d-flex justify-content-start  align-items-center"
          toggle={toggle}
          style={{
            background:
              "linear-gradient(120.5deg, #5B2E92 0%, #29409C 50.49%, #0F91D2 100%)",
          }}
        >
          <img src={alertIcon} width={25} className="mx-2" /> Important Notice
        </ModalHeader>
        <ModalBody>
          <div className="event-create-popup">
            <p>
              We've noticed that the information you've provided doesn't match
              your NobelPage profile. This discrepancy may result in rejection
              or the failure to receive the truth badge. We recommend that you
              update your NobelPage profile with this information.
            </p>
            <p className="mt-2">
              Simply click "Yes, update" to automatically synchronize your data.
              If you choose not to update, click "Ignore," but please be aware
              that this choice is at your own risk. Note that your profile will
              be locked during the process, and any changes made may affect your
              truth badge status.
            </p>
            <div className="d-flex justify-content-center align-items-center mt-3">
              <button
                className="btn  text-blue px-3 py-2"
                style={{ background: "#0432A30D" }}
                onClick={() => {
                  toggle();
                  handleNext();
                }}
              >
                Ignore
              </button>

              <button
                className="btn btn-blue mx-3 px-3 py-2"
                onClick={() => {
                  setupdateInfo(true);
                }}
              >
                Yes Update
              </button>
            </div>
          </div>
        </ModalBody>
      </Modal>
    </>
  );
};

export default InfoAlertModel;
