import moment from 'moment/moment'
import React from 'react'
import { Link } from 'react-router-dom'
import nopreview from "../../../assets/img/no-preview.png";
import './Card.scss'

export default function Card({data}) {
  
 return ( 
   <div className="thumbnailCard" key={data.id}>
    <div className="img">
    <Link to={`/blogdetail/${data.id}`}>
    <img src={data.thumbnail_url?data.thumbnail_url:nopreview} className="img-fluid" alt='' />
      </Link>
    
    </div>
    <div className="main-content p-3 mb-2">
     <div className="caption">
      <div className="left">
       <div className="profile-image">
        <img src={data.User.profile_img} alt='' />
       </div>
       <div className="text">
        <span>{`${data.User?.first_name} ${data.User?.last_name}`}</span>
       </div>
      </div>
      <div className="language">
       <span>EN</span>
      </div>
     </div>
     <div className="dates">
      <span>{moment(data.createdAt).format('LLLL')}</span>
     </div>
     <div className="text">
      <h6>{data.title}</h6>
     </div>
     <div className="main-text">
      <p>{data.description}</p>
     </div>
     <div className="">
      <Link to={`/blogdetail/${data.id}`}>
       <p style={{fontWeight:"600",marginTop:"8px",fontSize:"14px"}}> Read more</p>
      
      </Link>
     </div>
    </div>
   </div>
 )
}
