import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import ReactPlayer from "react-player";
import tag from "../../../assets/img/tag.svg";
import DocView from "../../../core/components/DocView/DocView";
import TagPeopleModal from "./TagPeopleModal";
function ShowImageData({
  data,
  id,
  isImage,
  isVideo,
  isText,
  selected,
  seclectedUser,
  handleRemove,
  handleClose,
  fileName,
  isEdit,
  tagDetails,
}) {
  const [tagModal, setTagModal] = useState(false);
  const [clickTag, setClickTag] = useState(false);
  const [showClickMsg, setShowClickMsg] = useState(false);
  const [indexOfClick, setIndexOfClick] = useState({ x: 0, y: 0, id: "" });
  const showPeopleTag = (event, id) => {
    try {
      var x = event.clientX;
      var y = event.clientY;
      setIndexOfClick({ x: x, y: y, id: id });
      setTagModal(!tagModal);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    setClickTag(false);
    setShowClickMsg(false);
  }, [data]);

  return (
    <div style={{ width: "100%" }} className="mb-20 box-img">
      {isImage && (
        <img
          onClick={(e) => (clickTag ? showPeopleTag(e, id) : "")}
          id={id}
          src={data}
          alt="thumbnail"
          width="100%"
          height="100%"
          className="p-2 main-img"
        />
      )}
      {isVideo && (
        <ReactPlayer
          url={data}
          playing={false}
          controls={true}
          width="520px"
          height="380px"
        />
        // <video controls width="100%">
        //   <source src={data} />
        // </video>
      )}
      {isText && <DocView docURL={data} />}

      {selected ? (
        <div className="left-side">
          {selected[id] &&
            selected[id].map((user, index) => (
              <div
                key={index}
                className=""
                role="tooltip"
                style={{
                  position: "absolute",
                  // left:user.x - 60,
                  // top:user.y,
                  left:
                    user.x <= 40
                      ? "-20px"
                      : user.x >= 430
                      ? "385px"
                      : user.x - 60,
                  top: user.y >= 270 ? "270px" : user.y,
                  width: "max-content",
                }}
              >
                <span
                  className={
                    user.x <= 40
                      ? "small-tooltip-right"
                      : user.x >= 430
                      ? "small-tooltip-left"
                      : "small-tooltip"
                  }
                  //  style={{ left: user.x<=40 ? '11%' : user.x>=430 ? '89%' : '50%'}}
                >
                  {user?.data?.first_name?.slice(0, 12) +
                    (user?.data?.first_name?.length > 12 ? "..." : "") +
                    " " +
                    user?.data?.last_name?.slice(0, 10) +
                    (user?.data?.last_name?.length > 10 ? "..." : "")}
                  <span onClick={() => handleRemove(user.data.id, id)}>X</span>
                </span>
              </div>
            ))}
        </div>
      ) : (
        <></>
      )}
      <div className="right-side">
        {isImage && (
          <div
            className="tag"
            onClick={() => {
              setClickTag(true);
              setShowClickMsg(true);
            }}
          >
            <img src={tag} alt="" /> Tag People
          </div>
        )}
        {isEdit ? (
          <></>
        ) : (
          <div className="close" onClick={() => handleClose(data)}>
            X
          </div>
        )}
        {tagModal && (
          <TagPeopleModal
            modal={tagModal}
            toggle={() => {
              setTagModal(!tagModal);
            }}
            indexofData={indexOfClick}
            setSelected={seclectedUser}
            selected={selected}
            setShowClickMsg={setShowClickMsg}
          />
        )}
      </div>
      <div className="click-anywhere">
        {showClickMsg && <div className="tag">Click anywhere to Tag</div>}
      </div>
    </div>
  );
}

export default ShowImageData;
