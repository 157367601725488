import React, { useEffect } from "react";
import ExploreCards from "./ExploreCards/ExploreCards.jsx";
import Header from "../../core/components/layout/Header/Header";
import LoginFooter from "../../core/components/layout/footer/LoginFooter.js";
import logo from "../../../src/assets/img/np-logo.svg";
import explore from "../../assets/img/explore.png";
import "./explore.scss";
import { useNavigate } from "react-router";

export default function Explore({
  APIdata,
  tabs,
  setTabs,
  tabsList,
  exploreData,
  subTab, 
  setSubTab
}) {
  const navigate = useNavigate();
  const ID = window.location.search.substr(13);

  useEffect(() => {
    if(ID)
    setTabs(ID);
  }, []);

  return (
    <>
      <div className="explore">
        <Header />
        <div className="bgNew">
          <div className="container">
            <div className="row">
              <div className="col-sm-8 margin-top">
                <div className="top-center">
                  <h1>{exploreData?.title}</h1>
                  <p style={{ marginTop: "30px" }}>{exploreData?.subtitle}</p>
                </div>
              </div>
              <div className="col-sm-4">
                <img src={explore} alt="" className="bg-image" />
              </div>
            </div>
          </div>
          <div className="n-logo">
            <img src={logo} alt="" width="100%" />
          </div>
        </div>
        <div className="explore">
          <div className="container">
            <div className="row">
              <div className="mt-100 mb-5">
                <div className="container">
                  <div className="row">
                    <div className="col-lg-12">
                      <div className="links">
                        <ul>
                          {tabsList.map((item, i) => (
                            <li
                              className={
                                tabs == item.category_id ? "active" : ""
                              }
                              key={i}
                              onClick={() => {
                                navigate(
                                  `/explore?category_id=${item.category_id}`
                                );
                                setTabs(item.category_id)
                              }}
                            >
                              {item.name}
                            </li>
                          ))}
                        </ul>
                      </div>
                      <div className="line my-3"></div>
                      {APIdata.map((item, i) => (
                        <ExploreCards
                          key={i}
                          APIdata={item}
                          setTabs={setTabs}
                          tabsList={tabsList}
                          subTab={subTab}
                           setSubTab ={setSubTab}
                        />
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <LoginFooter />
        </div>
      </div>
    </>
  );
}
