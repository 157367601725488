import React, { useState } from "react";
import Autocomplete from "react-autocomplete";
import { useSelector } from "react-redux";
import { Input, Label } from "reactstrap";
import FormInput from "../../../../core/components/Inputs/FormInput";
import TagsInput from "../../../../core/components/HashTags/tagsInput";
import info from "../../../../assets/img/info.svg";
import ScheduleCamp from "./ScheduleCamp";
import { Slider } from "rsuite";
import "rsuite/dist/rsuite.min.css";
import Payment from "./Payment";
import moment from "moment";
import { useEffect } from "react";

const CreateCamp = (props) => {
  const {
    checkValidation,
    campaignDetail,
    setCampaignDetail,
    nameErrorMessage,
    setNameErrorMessage,
    websiteErr,
    setWebsiteErr,
    hashText,
    setHashText,
    savedStatus,
    setSavedStatus,
    gender,
    setGender,
    industryInput,
    setIndustryInput,
    descriptionErrorMessage,
    setDescriptionErrorMessage,
    paymentModal,
    setPaymentModal,
    createCampaign,
    scheduleDate,
    setScheduleDate,
    scheduleTime,
    setScheduleTime,
    today,
    duration,
    setDuration,
    scheduleCheck,
    setScheduleCheck,
    pageDataDropdown,
    typeIdError,
    setTypeIdError,
    errorActionLink,
    setErrorActionLink,
    locationList,
    setLocationList,
    arr,
    setArr,
    addInput,
    locationArr,
    setLocationArr,
    addLocation,
    locationInput,
    setLocationInput,
    postDetail,
    setPostDetail,
    locationOpen1,
    setLocationOpen1,
    locationOpen2,
    setLocationOpen2,
    locationOpen3,
    setLocationOpen3,
    locationOpen4,
    setLocationOpen4,
    locationOpen5,
    setLocationOpen5,
    selectedPostId,
    membership,
    selectedFeed,
    setSelectedFeed,
    campaignStatus,
    wpMembership,
  } = props;
  const { industryData } = useSelector((state) => state.master);
  const [selectedIndustry, setSelectedIndustry] = useState([]);
  const [currentPostId, setCurrentPostId] = useState(selectedPostId);
  const [filtertedIndustryList, setFiltertedIndustryList] =
    useState(industryData);

  useEffect(() => {
    setFiltertedIndustryList(industryData);
  }, [industryData]);

  useEffect(() => {
    const removeDuplicates = filtertedIndustryList.filter((el) => {
      return selectedIndustry?.indexOf(el.name) == -1;
    });
    if (selectedIndustry?.length > 0)
      setFiltertedIndustryList(removeDuplicates);
    else setFiltertedIndustryList(industryData);
  }, [selectedIndustry]);
  useEffect(() => {
    setCampaignDetail({ ...campaignDetail, hashtags: hashText });
  }, [hashText]);

  useEffect(() => {
    if (selectedPostId) {
      setCampaignDetail({
        ...campaignDetail,
        type: "post",
        type_id: selectedPostId,
      });
    }
  }, [selectedPostId]);
  const { user } = useSelector((state) => state?.auth);
  console.log(wpMembership, membership, duration);
  const totalAmount = () => {
    const day_price =
      membership?.flatMap((featureValuesArray) =>
        featureValuesArray?.filter(
          (item) =>
            item?.membership_id === user?.data?.membership?.id &&
            item?.type?.charAt(0) == duration
        )
      )[0]?.value || 0;

    const check_price =
      membership?.flatMap((featureValuesArray) =>
        featureValuesArray?.filter(
          (item) =>
            item?.membership_id === user?.data?.membership?.id &&
            item?.d_type == "each_price"
        )
      )[0]?.value || 0;

    const wp_check_price =
      wpMembership?.flatMap((featureValuesArray) =>
        featureValuesArray?.filter(
          (item) =>
            item?.membership_id === user?.data?.membership?.id &&
            item?.d_type == "each_price"
        )
      )[0]?.value || 0;

    if (campaignDetail?.is_adBar && campaignDetail.is_wp)
      return (
        Number(day_price) +
        (Number(check_price) + Number(wp_check_price)) * duration
      ).toFixed(2);
    else if (campaignDetail?.is_adBar)
      return (Number(day_price) + Number(check_price) * duration).toFixed(2);
    else if (campaignDetail?.is_wp)
      return (Number(day_price) + Number(wp_check_price) * duration).toFixed(2);
    else return Number(day_price);
  };
  console.log(campaignStatus);
  return (
    <div className="container create-camp">
      <div className="camp-slider">
        <h5>Campaign Period</h5>
        <div>
          <Slider
            disabled={
              campaignStatus == "published" || campaignStatus == "scheduled"
                ? true
                : false
            }
            defaultValue={2}
            value={duration}
            min={1}
            step={1}
            max={5}
            graduated
            progress
            renderMark={(mark) => {
              return (
                <span className={"p-1" + (mark === 5 ? " end-margin" : "")}>
                  {mark} {mark > 1 ? "days" : "day"}
                </span>
              );
            }}
            onChange={(value) => {
              setDuration(value);
              setCampaignDetail({
                ...campaignDetail,
                end_date: moment(scheduleDate ? scheduleDate : new Date())
                  .add(value, "d")
                  .format("YYYY-MM-DD"),
              });
            }}
          />
        </div>
      </div>
      <div className="schedule">
        <div className="check">
          <input
            type="checkbox"
            id="experienceCheck"
            name="experienceCheck"
            className="mt-3"
            value={scheduleCheck}
            onChange={(e) => setScheduleCheck(e.target.checked)}
            disabled={campaignStatus == "published" ? true : false}
          />
          <label htmlFor="educationCheck" className="mt-3 me-4 ms-2">
            Schedule Start Date
          </label>
          <ScheduleCamp
            scheduleDate={scheduleDate}
            setScheduleDate={setScheduleDate}
            scheduleTime={scheduleTime}
            setScheduleTime={setScheduleTime}
            today={today}
            scheduleCheck={scheduleCheck && campaignStatus !== "published"}
            campaignDetail={campaignDetail}
            setCampaignDetail={setCampaignDetail}
            duration={duration}
          />
        </div>
      </div>
      <div className="camp-form">
        <form
        // className={editPageData ? "" : "mt-7"}
        >
          <Label>Campaign Name*</Label>
          <Input
            onChange={(e) =>
              setCampaignDetail({ ...campaignDetail, name: e.target.value })
            }
            onClick={() => setNameErrorMessage("")}
            value={campaignDetail.name}
          />
          {nameErrorMessage && (
            <label className="label-input100 text-danger">
              {nameErrorMessage}
            </label>
          )}
          <div className="row">
            <div className="col-12">
              <Label for="exampleText">Description</Label>
              <Input
                type="textarea"
                name="text"
                id="exampleText"
                onChange={(e) =>
                  setCampaignDetail({
                    ...campaignDetail,
                    description: e.target.value,
                  })
                }
                onClick={() => setDescriptionErrorMessage("")}
                value={campaignDetail.description}
              />

              {descriptionErrorMessage && (
                <label className="label-input100 text-danger">
                  {descriptionErrorMessage}
                </label>
              )}
            </div>
          </div>
          <div className="row type">
            <div className="col-3 ">
              <Label for="exampleSelect">
                Type
                <img src={info} alt="" className="ms-2" />
              </Label>
              <Input
                type="select"
                value={campaignDetail.type}
                name="select"
                id="exampleSelect"
                onChange={(e) =>
                  setCampaignDetail({ ...campaignDetail, type: e.target.value })
                }
                disabled={
                  campaignStatus == "published" ||
                  campaignStatus == "scheduled" ||
                  campaignStatus == "completed"
                    ? true
                    : false
                }
                // onBlur={() => validation("size")}
              >
                <option value={"post"}>Post</option>
                {/*  <option value={"event"}>Event</option>
                <option value={"article"}>Article </option> */}
                <option value={"page"}>Page</option>
                {/* <option value={"newsletter"}>Newsletter</option> */}
              </Input>
              {/* {sizeErrorMessage && (
                <label className="label-input100 text-danger">
                  {sizeErrorMessage}
                </label>
              )} */}
            </div>
            <div className="col-9 type-dropdown">
              <Label for="exampleSelect"></Label>

              {campaignDetail.type === "page" ? (
                <Input
                  type="select"
                  value={campaignDetail.type_id}
                  name="select"
                  id="exampleSelect"
                  disabled={
                    campaignStatus == "published" ||
                    campaignStatus == "scheduled" ||
                    campaignStatus == "completed"
                      ? true
                      : false
                  }
                  onChange={(e) => {
                    setCampaignDetail({
                      ...campaignDetail,
                      type_id: e.target.value,
                    });
                    setSelectedFeed(
                      pageDataDropdown?.find((ele) => ele.id == e.target.value)
                    );
                  }}
                  onClick={() => setTypeIdError("")}
                  // onBlur={() => validation("size")}
                >
                  <option value="">Select</option>
                  {pageDataDropdown?.map((data, index) => {
                    let showDropDown = data.name;
                    if (showDropDown.length > 50) {
                      showDropDown = `${showDropDown.substring(0, 50)}...`;
                    }
                    return (
                      <option key={index} value={data.id} title={data.name}>
                        {showDropDown}
                      </option>
                    );
                  })}
                </Input>
              ) : postDetail ? (
                <div
                  className="select-card"
                  onClick={() => {
                    setPostDetail(null);
                  }}
                >
                  <div className="d-flex m-2 card-postbar gap-3">
                    <div>
                      <img
                        src={
                          postDetail?.Feed_Documents[0]?.document_url ||
                          "https://images.unsplash.com/photo-1589802829985-817e51171b92?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxleHBsb3JlLWZlZWR8Nnx8fGVufDB8fHx8&w=1000&q=80"
                        }
                        alt="image_profile"
                        style={{ height: "40px", width: "40px" }}
                      />
                    </div>
                    <div>
                      <strong className="title">{postDetail.title} </strong>
                      <div className="card-control-text-twoline">
                        {postDetail.description}
                      </div>
                    </div>
                  </div>
                </div>
              ) : (
                <div className="post-drop">
                  {pageDataDropdown?.map((data, idx) => {
                    const isPostSelected = data.id === currentPostId;
                    console.log(data?.Feed_Documents[0]?.document_url);
                    return (
                      <div
                        className={`d-flex m-2 card-postbar ${
                          isPostSelected ? "active-card-postbar" : ""
                        }`}
                        key={data.id}
                        onClick={() => {
                          if (isPostSelected) {
                            setCurrentPostId(null);
                          } else {
                            setCurrentPostId(data.id);
                            setSelectedFeed(data);
                            setPostDetail(data);
                            setCampaignDetail({
                              ...campaignDetail,
                              type_id: data.id,
                            });
                          }
                        }}
                      >
                        <div className="left">
                          {/* <img
                            src={
                              "https://images.unsplash.com/photo-1589802829985-817e51171b92?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxleHBsb3JlLWZlZWR8Nnx8fGVufDB8fHx8&w=1000&q=80"
                            }
                            alt="image_profile"
                            style={{ height: "50px", width: "50px" }}
                          /> */}
                          <img
                            src={
                              data?.Feed_Documents[0]?.document_url ||
                              `https://images.unsplash.com/photo-1589802829985-817e51171b92?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxleHBsb3JlLWZlZWR8Nnx8fGVufDB8fHx8&w=1000&q=80`
                            }
                            alt="image_profile"
                            style={{
                              height: "50px",
                              width: "50px",
                              marginRight: "10px",
                              marginTop: "2px",
                            }}
                          />
                        </div>
                        <div className="right">
                          <strong className="title">{data.title}</strong>
                          <div>{data.description}</div>
                        </div>
                      </div>
                    );
                  })}
                </div>
              )}
              {typeIdError && (
                <label className="label-input100 text-danger">
                  {typeIdError}
                </label>
              )}
            </div>
          </div>
          <div className="row my-2 industry">
            <div className="col-sm-6 autocomplete-wrapper">
              {/* {console.log("arr", arr)} */}
              <Label>Industry</Label>
              {arr.map((item, i) => {
                return (
                  <div style={{ marginBottom: "10px" }}>
                    <Autocomplete
                      value={item.value}
                      items={filtertedIndustryList}
                      getItemValue={(item) => item.name}
                      shouldItemRender={(item, value) =>
                        item.name.toLowerCase().indexOf(value.toLowerCase()) >
                        -1
                      }
                      renderMenu={(items, value) => (
                        <div className="dropdown">
                          {items.length === 0 ? "" : items}
                        </div>
                      )}
                      renderItem={(item, isHighlighted) => (
                        <div
                          className={`item ${
                            isHighlighted ? "selected-item" : ""
                          }`}
                        >
                          {item.name}
                        </div>
                      )}
                      onChange={(event, val) => {
                        setArr((s) => {
                          const newArr = s.slice();
                          newArr[i].value = val;
                          return newArr;
                        });
                        setSelectedIndustry((s) => {
                          const newArr = s.slice();
                          newArr[i] = val;
                          return newArr;
                        });
                      }}
                      onSelect={(val, obj) => {
                        // setCampaignDetail({
                        //   ...campaignDetail,
                        //   industry: [...campaignDetail.industry, val],
                        // });
                        setArr((s) => {
                          const newArr = s.slice();
                          newArr[i].value = val;
                          return newArr;
                        });
                        setSelectedIndustry([...selectedIndustry, val]);
                      }}
                      wrapperStyle={{}}
                    />
                    {/* <span
                      style={{ textAlign: "right", color: "red" }}
                      className="addButton"
                      onClick={() => {
                        addInput();
                      }}
                    >
                      Remove
                    </span> */}
                  </div>
                );
              })}
              {arr?.length < 5 && (
                <span
                  className="addButton"
                  onClick={() => {
                    addInput();
                  }}
                >
                  + Add Another Industry
                </span>
              )}
            </div>
            <div className="col-sm-6 autocomplete-wrapper">
              {/* {console.log(locationArr)} */}
              <Label>Region</Label>
              {locationArr.map((item, i) => {
                return (
                  <div style={{ marginBottom: "10px" }}>
                    <Autocomplete
                      open={
                        i === 0
                          ? locationOpen1
                          : i === 1
                          ? locationOpen2
                          : i === 2
                          ? locationOpen3
                          : i === 3
                          ? locationOpen4
                          : locationOpen5
                      }
                      value={item.value}
                      items={locationList}
                      getItemValue={(item) => item.name}
                      shouldItemRender={(item, value) =>
                        item.name.toLowerCase().indexOf(value.toLowerCase()) >
                        -1
                      }
                      renderMenu={(items, value) => (
                        <div className="dropdown">
                          {items.length === 0 ? "" : items}
                        </div>
                      )}
                      renderItem={(item, isHighlighted) => (
                        <div
                          className={`item ${
                            isHighlighted ? "selected-item" : ""
                          }`}
                        >
                          {item.name}
                        </div>
                      )}
                      onChange={(event, val) => {
                        if (val.length === 0) {
                          if (i === 0 && locationOpen1) {
                            setLocationOpen1(false);
                          } else if (i === 1 && locationOpen2) {
                            setLocationOpen2(false);
                          } else if (i === 2 && locationOpen3) {
                            setLocationOpen3(false);
                          } else if (i === 4 && locationOpen4) {
                            setLocationOpen3(false);
                          }
                        } else {
                          if (i === 0 && !locationOpen1) setLocationOpen1(true);
                          else if (i === 1 && !locationOpen2)
                            setLocationOpen2(true);
                          else if (i === 2 && !locationOpen3)
                            setLocationOpen3(true);
                          else if (i === 3 && !locationOpen4)
                            setLocationOpen4(true);
                          else if (i === 4 && !locationOpen5)
                            setLocationOpen5(true);
                        }
                        setLocationInput(val);
                        setLocationArr((s) => {
                          const newArr = s.slice();
                          newArr[i].value = val;
                          return newArr;
                        });
                      }}
                      onSelect={(val, obj) => {
                        setLocationInput(val);
                        setLocationArr((s) => {
                          const newArr = s.slice();
                          newArr[i].value = val;
                          return newArr;
                        });
                        setLocationOpen1(false);
                        setLocationOpen2(false);
                        setLocationOpen3(false);
                        setLocationOpen4(false);
                      }}
                      wrapperStyle={{}}
                    />
                    {/* <span
                      style={{ textAlign: "right", color: "red" }}
                      className="addButton"
                      onClick={() => {
                        addInput();
                      }}
                    >
                      Remove
                    </span> */}
                  </div>
                );
              })}
              {locationArr?.length < 5 && (
                <span
                  className="addButton"
                  onClick={() => {
                    addLocation();
                  }}
                >
                  + Add Another Region
                </span>
              )}
            </div>
          </div>
          <div className="row gender ">
            <div className="col-sm-6 mt-10">
              <Label for="exampleSelect1">Gender</Label>
              <div
                className={
                  !campaignDetail.gender
                    ? "btn blue-btn ms-3"
                    : "btn border-btn ms-3 border-end-0 rounded-start"
                }
                style={{ padding: "8px 16px" }}
                onClick={() =>
                  setCampaignDetail({ ...campaignDetail, gender: null })
                }
              >
                All
              </div>
              <div
                className={
                  campaignDetail.gender === "male"
                    ? "btn blue-btn"
                    : "btn border-btn border-end-0 border-start-0"
                }
                style={{ padding: "8px 16px" }}
                onClick={() =>
                  setCampaignDetail({ ...campaignDetail, gender: "male" })
                }
              >
                Men
              </div>
              <div
                className={
                  campaignDetail.gender === "female"
                    ? "btn blue-btn"
                    : "btn border-btn border-start-0 rounded-end"
                }
                style={{ padding: "8px 16px" }}
                onClick={() =>
                  setCampaignDetail({ ...campaignDetail, gender: "female" })
                }
              >
                Women
              </div>
            </div>
            <div className="col-sm-6 age mt-10">
              <div className="">
                <div className="row row-cols-auto">
                  <div className="col-2 w-0">
                    <Label for="exampleSelect1">Age</Label>
                  </div>
                  <div className="col-2 year ">
                    <Input
                      type="select"
                      value={campaignDetail.age_from}
                      name="select"
                      id="exampleSelect"
                      onChange={(e) =>
                        setCampaignDetail({
                          ...campaignDetail,
                          age_from: e.target.value,
                        })
                      }
                      // onBlur={() => validation("size")}
                    >
                      <option value={15}>15</option>
                      <option value={25}>25</option>
                      <option value={35}>35 </option>
                    </Input>
                  </div>

                  <h4 className="dot">-</h4>
                  <div className="col-2 month">
                    <Input
                      type="select"
                      value={campaignDetail.age_to}
                      name="select"
                      id="exampleSelect"
                      onChange={(e) =>
                        setCampaignDetail({
                          ...campaignDetail,
                          age_to: e.target.value,
                        })
                      }
                      // onBlur={() => validation("size")}
                    >
                      <option value={45}>45</option>
                      <option value={55}>55</option>
                      <option value={65}>65</option>
                    </Input>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {console.log(selectedFeed)}
          <div className="row my-2 hashtag">
            <div className="col-sm-12">
              <Label className="mt-0">Hashtag</Label>
              <TagsInput
                tags={hashText}
                setTags={setHashText}
                isCampaign={true}
              />
            </div>
          </div>
          <div className="row my-2 hashtag">
            <div className="col-sm-12">
              <Label className="mt-0">Website</Label>
              <Input
                onChange={(e) =>
                  setCampaignDetail({
                    ...campaignDetail,
                    website: e.target.value,
                  })
                }
                onClick={() => setWebsiteErr("")}
                value={campaignDetail.website}
              />
              {websiteErr && (
                <label className="label-input100 text-danger">
                  {websiteErr}
                </label>
              )}
            </div>
          </div>
          <div className="row action-link ">
            <div className="col-sm-3 type">
              <Label for="exampleSelect">Call to Action Link</Label>
              <Input
                type="select"
                name="select"
                id="exampleSelect"
                onChange={(e) =>
                  setCampaignDetail({
                    ...campaignDetail,
                    action_link_label: e.target.value,
                  })
                }
                value={campaignDetail.action_link_label}
              >
                <option value={"Label"}>Label</option>
                <option value={"Visit Website"}>Visit Website</option>
                <option value={"Join"}>Join </option>
                <option value={"Learn More"}>Learn More</option>
                <option value={"Subscribe"}>Subscribe</option>
                <option value={"Click here"}>Click here</option>
                <option value={"Sign Up"}>Sign Up</option>
              </Input>
              {/* {sizeErrorMessage && (
                <label className="label-input100 text-danger">
                  {sizeErrorMessage}
                </label>
              )} */}
            </div>
            <div className="col-sm-9 type-dropdown">
              <Label for="exampleSelect"></Label>
              <Input
                type="input"
                value={campaignDetail.action_link}
                name="select"
                id="exampleSelect"
                onChange={(e) =>
                  setCampaignDetail({
                    ...campaignDetail,
                    action_link: e.target.value,
                  })
                }
                onClick={() => setErrorActionLink("")}
              >
                <option value={"Post"}></option>
              </Input>
              {errorActionLink && (
                <label className="label-input100 text-danger">
                  {errorActionLink}
                </label>
              )}
            </div>
          </div>
          <div className="row mt-3">
            <div>
              <div className="check">
                <input
                  type="checkbox"
                  id="experienceCheck"
                  name="experienceCheck"
                  checked={campaignDetail.is_adBar}
                  disabled={
                    campaignStatus == "published" ||
                    campaignStatus == "scheduled"
                      ? true
                      : false
                  }
                  onClick={(e) =>
                    setCampaignDetail({
                      ...campaignDetail,
                      is_adBar: e.target.checked,
                    })
                  }
                />
                <label htmlFor="educationCheck">Place on the Ad Bar</label>
              </div>
              <div className="placetext">
                <p>
                  By selecting this option, your ad will be additionally
                  featured on the top right ad bar.
                </p>
              </div>

              <div className="check">
                <input
                  type="checkbox"
                  id="experienceCheck"
                  name="experienceCheck"
                  checked={campaignDetail.is_wp}
                  onClick={(e) =>
                    setCampaignDetail({
                      ...campaignDetail,
                      is_wp: e.target.checked,
                    })
                  }
                />
                <label htmlFor="educationCheck">Place on the WorkerPool</label>
              </div>
              <div className="placetext">
                <p>
                  By selecting this option, your ad will be also featured in our
                  WorkerPool page and app. Please note that this feature is only
                  recommended for job posts!
                </p>
              </div>
              {/* <div className="check">
                <input
                  type="checkbox"
                  id="experienceCheck"
                  name="experienceCheck"
                  checked={campaignDetail.is_wp}
                  onClick={(e) =>
                    setCampaignDetail({
                      ...campaignDetail,
                      is_wp: e.target.checked,
                    })
                  }
                />
                <label htmlFor="educationCheck">Place on the WorkerPool</label>
              </div>
              <div className="placetext">
                <p>
                  By selecting this option, your ad will be also featured in our WorkerPool page and app.
                  Please note that this feature is only recommended for job posts!
                </p>
              </div> */}
              <div className="footer d-flex justify-content-between mt-0">
                <div className="mt-3">
                  <h4 className="text-dark">Total Amount: ${totalAmount()}</h4>
                </div>
                <div className="btns">
                  {console.log(campaignStatus)}
                  {(campaignStatus === undefined ||
                    campaignStatus === "draft") && (
                    <div
                      className={
                        savedStatus === "save"
                          ? "btn blue-btn ms-3"
                          : "btn border-btn ms-3"
                      }
                      onClick={() => createCampaign("draft", "post")}
                    >
                      Save
                    </div>
                  )}

                  {(campaignStatus === undefined ||
                    campaignStatus === "completed") && (
                    <div
                      className={
                        savedStatus === "payment"
                          ? "btn blue-btn"
                          : "btn border-btn"
                      }
                      onClick={() => {
                        if (checkValidation()) {
                          setSavedStatus("payment");
                          setPaymentModal(true);
                        }
                      }}
                    >
                      Complete Payment
                    </div>
                  )}

                  {campaignStatus !== undefined &&
                    campaignStatus !== "completed" && (
                      <div
                        className="btn blue-btn"
                        onClick={() => createCampaign(campaignStatus, "post")}
                      >
                        Update Campaign
                      </div>
                    )}
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>

      {paymentModal && (
        <Payment
          modal={paymentModal}
          toggle={() => setPaymentModal(!paymentModal)}
          title="Pay by card"
          createCampaign={createCampaign}
          scheduleCheck={scheduleCheck}
          campaignDetail={campaignDetail}
          selectedFeed={selectedFeed}
          totalAmount={totalAmount()}
          scheduleTime={scheduleTime}
        />
      )}
    </div>
  );
};

export default CreateCamp;
