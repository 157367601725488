import {
    REACTION_POST,REACTION_POST_FAIL,GET_REACTION_POST,GET_REACTION_POST_FAIL
  } from "../types";

  const initialState = [];

export default function (state = initialState, action) {
    const { type, payload } = action
  
    switch (type) {

        case REACTION_POST:
        return {
          ...state,
          isReactionApi: true,
        }

      case REACTION_POST_FAIL:
          return {
            ...state,
            isReactionApi: false
          }

          case GET_REACTION_POST:
            return {
              ...state,
              isReactionApi: true,
            }
    
          case GET_REACTION_POST_FAIL:
              return {
                ...state,
                isReactionApi: false
              }

      default:
        return state
    }
  }