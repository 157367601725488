import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { loadUser } from "../../store/auth/authActions";
import {
  getPostDetails,
  deletePost,
  savePost,
  savePostDelete,
  createPost,
  repost_post,
  hide_user_post,
  comment_status,
  getPageDetails,
  postViewLog,
} from "../../store/dashboard/dashboardActions";
import { getSuggestedArticles } from "../../store/articles/articleActions";
import { getSuggestedGroups } from "../../store/groups/groupsActions";
import { getSuggestedEvents } from "../../store/events/eventActions";
import {
  getUserProfile,
  profile_follower,
  profile_unfollower,
  remove_connection,
} from "../../store/profile/profileActions";
import { reactionsData } from "../../store/reactionPost/reactionAction";
import Dashboard from "./dashboard";
import { useNavigate, useLocation } from "react-router-dom";
import { getHashtagData } from "../../store/hashtag/hashtagActions";
import { getSuggestedPeopleList } from "../../store/people/peopleActions";
import { sendConnectionRequest } from "../../store/connections/connectionsActions";
import { ToastContainer, toast } from "react-toastify";
import {
  followPage,
  getAllPages,
  unFollowPage,
} from "../../store/pages/pagesAction";
import moment from "moment";

const DashboardContainer = () => {
  const [modal, setModal] = useState(false);
  const [dashboardData, setDashboardData] = useState();
  const [createPostModal, setCreatePostModal] = useState(false);
  const [userId, setUserId] = useState();
  const [articleModal, setArticleModal] = useState(false);
  const [eventModal, setEventModal] = useState(false);
  const [newsletterModal, setNewsletterModal] = useState(false);
  const [byUserId, setByUserId] = useState(0);
  // const [isReaction, setIsReaction] = useState(-1);
  const [clicked, setClicked] = useState({
    post_id: 0,
    user_id: 0,
    type: "",
  });

  const [draftDetails, setDraftDetails] = useState();
  const [postId, setPostID] = useState();
  const [isViewReaction, setViewReaction] = useState(false);
  const [viewMore, setViewMore] = useState(-1);
  const [createPostClick, setCreatePostClick] = useState("");
  const [currentCount, setCurrentCount] = useState(10);
  const [dropDown, setDropDown] = useState(-1);
  const [dropDownShare, setDropDownShare] = useState(-1);
  const [dropDownRepost, setDropDownRepost] = useState(-1);
  const [discardModalStatus, setDiscardModalStatus] = useState(false);
  const [updateData, setUpdateData] = useState();
  const [isEdit, setIsEdit] = useState(0);
  const [isDataAvailable, setDataAvailable] = useState(false);
  const [reportModal, setReportModal] = useState(false);
  const [reportType, setReportType] = useState();
  const [reportCommentId, setReportCommentId] = useState();
  const [commentBoxStatus, setCommentBoxStatus] = useState([]);
  const [commentStatus, setCommentStatus] = useState(true);
  const [closeModal, setCloseModal] = useState(false);
  const [deleteId, setDeleteId] = useState("");
  const [openShareModal, setOpenShareModal] = useState(false);
  const [shareData, setShareData] = useState("");
  const [roleShareModal, setRoleShareModal] = useState(false);
  const [rolePageId, setRolePageId] = useState(false);
  const [isPostLoading, setIsPostLoading] = useState(true);

  const { isWebView } = useSelector((state) => state.auth);
  const toggle = () => setModal(!modal);
  const toggleOff = () => setCreatePostModal(false);

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const dropRef = useRef(null);
  const dropRefShare = useRef(null);
  const dropRefRepost = useRef(null);

  const { user } = useSelector((state) => state.auth);
  const { postData } = useSelector((state) => state.dashboard);
  const { isPageLoading } = useSelector((state) => state.loader);

  const { suggested_articles } = useSelector((state) => state.article);
  const { suggested_groups } = useSelector((state) => state.groups);
  const { suggested_events } = useSelector((state) => state.events);
  const { followedTagList } = useSelector((state) => state.hashtag);
  const { suggested_people } = useSelector((state) => state.people);
  const { pageData } = useSelector((state) => state.pages);
  const { getPageDetailData } = useSelector((state) => state.dashboard);

  useEffect(() => {
    let token = window.localStorage.getItem("accessToken");
    // dispatch(loadUser(null));
    // dispatch(getPostDetails(token));
    dispatch(
      getSuggestedArticles(
        token,
        "suggested",
        { type: "suggested", skip: currentCount, limit: 10 },
        true
      )
    );
    dispatch(getSuggestedGroups(null));
    dispatch(
      getSuggestedEvents(token, { type: "suggested" }, { filter: "my" })
    );
    let query = "followed";
    dispatch(getHashtagData(query));
    dispatch(getSuggestedPeopleList({ type: "suggested", skip: 0, limit: 5 }));
    dispatch(getAllPages({ filter: "my" }));
    dispatch(getPageDetails(token));
  }, []);

  useEffect(() => {
    let id = user?.data?.id;
    let token = window.localStorage.getItem("accessToken");
    if (id) {
      dispatch(getUserProfile(id, token));
      dispatch(getPostDetails(token)).then((res) => {
        if (res) {
          setIsPostLoading(false);
        }
      });
    }
    setDashboardData(user?.data);
    setUserId(user?.data?.id);
  }, [user]);

  const closeAllModal = () => {
    setDiscardModalStatus(!discardModalStatus);
    setCreatePostModal(false);
    toggle();
  };

  const peopleFollowRequest = (id, mode, post_id) => {
    dispatch(profile_follower(id, mode, post_id));
  };
  const peopleUnFollowRequest = (id, mode, post_id) => {
    dispatch(profile_unfollower(id, mode, post_id));
  };
  const peopleRemoveConnection = (id, mode, post_id) => {
    dispatch(remove_connection(id, mode, post_id));
  };
  const handlePageFollow = (pageID, mode, post_id) => {
    dispatch(followPage(pageID, mode, post_id));
  };
  const handlePageUnFollow = (pageID, mode, post_id) => {
    dispatch(unFollowPage(pageID, mode, post_id));
  };

  const saveDraft = () => {
    let token = window.localStorage.getItem("accessToken");
    draftDetails.feed_status = "draft";
    dispatch(createPost(draftDetails, token));
    setCreatePostModal(false);
    closeAllModal();
  };

  const handleNext = () => {
    setCurrentCount(currentCount + 10);
    let token = window.localStorage.getItem("accessToken");
    dispatch(getPostDetails(token, { skip: currentCount, limit: 10 }));
  };

  const editPost = (postData) => {
    if (postData.Celebration) {
      alert("Cannot edit celebration post and feed docs ,docs");
    } else {
      setIsEdit(postData.id);
      setCreatePostModal(!createPostModal);
      setCreatePostClick("");
      setUpdateData(postData);
      setDropDown(-1);
    }
  };
  const handleCommentStatus = (postData, type) => {
    dispatch(comment_status(postData, type));
    setDropDown(-1);
  };

  const save_post = (id, i) => {
    let token = window.localStorage.getItem("accessToken");
    dispatch(savePost(id, token)).then((res) => {
      toast.success("Post Saved!", {
        position: toast.POSITION.BOTTOM_LEFT,
      });
    });
  };

  const removeSavePost = (id) => {
    let token = window.localStorage.getItem("accessToken");
    dispatch(savePostDelete(id, token)).then((res) => {
      toast.success("Post Removed!", {
        position: toast.POSITION.BOTTOM_LEFT,
      });
    });
  };

  function privacy_type(privacy_id) {
    if (privacy_id === 1) return " Public";
    if (privacy_id === 2) return " Connection";
    if (privacy_id === 3) return " Group";
    if (privacy_id === 4) return " Private";
  }

  const delete_post = (_id) => {
    let token = window.localStorage.getItem("accessToken");
    dispatch(deletePost(_id, token)).then((response) => {
      toast.success("Deleted successfully!", {
        position: toast.POSITION.BOTTOM_LEFT,
      });
      setDropDown(-1);
    });
  };

  useEffect(() => {
    document.addEventListener("mousedown", clickOutsideAdmin);
    return () => {
      document.removeEventListener("mousedown", clickOutsideAdmin);
    };
  }, [dropDown, dropDownShare, dropDownRepost]);

  const clickOutsideAdmin = (e) => {
    if (e.toElement && e?.toElement.className === "shareListing") {
      return;
    }
    if (e.toElement && e?.toElement.className === "listing") {
      return;
    }
    if (e.toElement && e?.toElement.className === "clickDots") {
      return;
    }
    if (dropRef?.current?.contains(e.target) && dropDown > -1) {
      return;
    }
    if (dropRefRepost?.current?.contains(e.target) && dropDownRepost > -1) {
      return;
    }
    setDropDown(-1);
    setDropDownShare(-1);
    setDropDownRepost(-1);
  };

  function handleReaction(item, reacted, type) {
    let data = {
      post_id: item.id,
      type: reacted,
      // id: byUserId !== 0 ? byUserId : item?.reaction?.byUser?.id,
    };
    if (item?.as_a_page) {
      Object.assign(data, { page_id: item?.as_a_page?.id });
    }
    dispatch(reactionsData(data, type)).then((res) => {
      setByUserId(res.data[0]?.id);
    });
    // setIsReaction(!isReaction);
    // dispatch({type: LIKED_DASHBOARD_POST, payload: data});
  }

  const sendRequest = (id, mode, post_id) => {
    dispatch(sendConnectionRequest(id, mode, post_id)).then((res) => {
      toast.success(res.message, {
        position: toast.POSITION.BOTTOM_LEFT,
      });
    });
  };

  const hideUserPost = (id) => {
    dispatch(hide_user_post(id)).then((reponse) => {
      toast.success("Post Hide Sucessfully!", {
        position: toast.POSITION.BOTTOM_LEFT,
      });
    });
    setDropDown(-1);
  };

  const postViewCounter = (id) => {
    const payload = {
      view_type: "post",
      post_id: id,
    };
    dispatch(postViewLog(payload));
  };

  const repostPost = (id, type, asPageId) => {
    let repost_data = {
      type: "repost",
      shared_id: id,
      privacy_id: 1,
      shared_by_type: type === "asPage" ? "page" : "user",
      shared_by: type === "asPage" ? asPageId : user?.data?.id,
    };
    dispatch(repost_post(repost_data)).then((res) => {
      setDropDownShare(-1);
      toast.success("Reposted successfully!", {
        position: toast.POSITION.BOTTOM_LEFT,
      });
    });
  };

  const likeResult = (arr1, arr2, type = []) => {
    let array3 = [];
    if (arr1 === undefined || arr1 === null) {
      array3 = arr2;
    } else {
      let arr4 = [{ type: arr1 }];
      array3 = arr4?.concat(
        arr2?.filter((bo) =>
          arr4?.every((ao) => ao?.type !== bo?.type && bo?.page_id !== null)
        )
      );
    }
    array3 = array3?.map((ao) => ({ type: ao?.type }));
    if (type?.length > 0) {
      array3 = array3?.concat(
        type?.filter((bo) => array3?.every((ao) => ao?.type !== bo?.type))
      );
    }
    return array3;
  };

  const viewConnection = () => {
    navigate("/connections");
  };

  return (
    <Dashboard
      user={user}
      postData={postData}
      dashboardData={dashboardData}
      userName={user?.data?.first_name + " " + user?.data?.last_name}
      userId={userId}
      createPostModal={createPostModal}
      setCreatePostModal={setCreatePostModal}
      articleModal={articleModal}
      setArticleModal={setArticleModal}
      eventModal={eventModal}
      setEventModal={setEventModal}
      newsletterModal={newsletterModal}
      setNewsletterModal={setNewsletterModal}
      handleReaction={handleReaction}
      postId={postId}
      setPostID={setPostID}
      isViewReaction={isViewReaction}
      setViewReaction={setViewReaction}
      createPostClick={createPostClick}
      setCreatePostClick={setCreatePostClick}
      handleNext={handleNext}
      currentCount={currentCount}
      privacy_type={privacy_type}
      dropDown={dropDown}
      setDropDown={setDropDown}
      discardModalStatus={discardModalStatus}
      setDiscardModalStatus={setDiscardModalStatus}
      closeAllModal={closeAllModal}
      delete_post={delete_post}
      editPost={editPost}
      updateData={updateData}
      setUpdateData={setUpdateData}
      isEdit={isEdit}
      setIsEdit={setIsEdit}
      saveDraft={saveDraft}
      isDataAvailable={isDataAvailable}
      setDataAvailable={setDataAvailable}
      draftDetails={draftDetails}
      setDraftDetails={setDraftDetails}
      toggleOff={toggleOff}
      save_post={save_post}
      removeSavePost={removeSavePost}
      dropRef={dropRef}
      dropRefShare={dropRefShare}
      viewConnection={viewConnection}
      navigate={navigate}
      suggested_articles={suggested_articles}
      suggested_groups={suggested_groups}
      suggested_events={suggested_events}
      followedTagList={followedTagList}
      setReportModal={setReportModal}
      reportModal={reportModal}
      reportType={reportType}
      setReportType={setReportType}
      reportCommentId={reportCommentId}
      setReportCommentId={setReportCommentId}
      suggested_people={suggested_people}
      sendRequest={sendRequest}
      hideUserPost={hideUserPost}
      commentBoxStatus={commentBoxStatus}
      setCommentBoxStatus={setCommentBoxStatus}
      dropDownShare={dropDownShare}
      setDropDownShare={setDropDownShare}
      repostPost={repostPost}
      likeResult={likeResult}
      peopleFollowRequest={peopleFollowRequest}
      peopleUnFollowRequest={peopleUnFollowRequest}
      peopleRemoveConnection={peopleRemoveConnection}
      handlePageFollow={handlePageFollow}
      handlePageUnFollow={handlePageUnFollow}
      commentStatus={commentStatus}
      setCommentStatus={setCommentStatus}
      handleCommentStatus={handleCommentStatus}
      closeModal={closeModal}
      setCloseModal={setCloseModal}
      deleteId={deleteId}
      setDeleteId={setDeleteId}
      isPageLoading={isPageLoading}
      postViewCounter={postViewCounter}
      openShareModal={openShareModal}
      setOpenShareModal={setOpenShareModal}
      shareData={shareData}
      setShareData={setShareData}
      pageData={pageData}
      dropDownRepost={dropDownRepost}
      setDropDownRepost={setDropDownRepost}
      dropRefRepost={dropRefRepost}
      roleShareModal={roleShareModal}
      setRoleShareModal={setRoleShareModal}
      getPageDetailData={getPageDetailData}
      rolePageId={rolePageId}
      setRolePageId={setRolePageId}
      isPostLoading={isPostLoading}
    />
  );
};

export default DashboardContainer;
