import React from "react";
import Layout from "../Layout/Layout";
import "./invitations.scss";
import backArrow from "../../../assets/img/backArrow.svg";
import placeholderUser from "../../../assets/img/placeholder_user.jpg";
import withdraw from "../../../assets/img/withd.png"
import AcceptIcon from "../../../assets/img/AcceptIcon.svg"

export default function Invitations(props) {
  const {
    setTabs,
    navigate,
    pending_invitations,
    sent_invitations_pending,
    acceptRequest,
    setSearchKey,
    searchKey,
    searchInvitation,
    filter,
    setFilter
  } = props;
  return (
    <Layout>
      <div className="col-lg-9">
        <div className="invitations">
          <div className="wrapper">
            <div className="container p-3">
              <div style={{ display: "flex" }}>
                <img
                  src={backArrow}
                  alt=""
                  onClick={() => navigate(-1)}
                  style={{ cursor: "pointer" }}
                />
                <h3 className="ml-20">Manage Invitations</h3>
              </div>
              <div className="search mt-3">
                <input
                  type="text"
                  placeholder="Search Invitations"
                  onChange={(e) => setSearchKey(e.target.value)}
                />
                <div
                  className="search-icon"
                  onClick={() => searchInvitation(searchKey)}
                ></div>
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "baseline",
                }}
              >
                <div className="overflow_hidden mt-20">
                  <ul className="nav nav-tabs" role="tablist">
                    <li className="nav-item">
                      <a
                        className="nav-link active"
                        data-bs-toggle="tab"
                        href="#received"
                        onClick={() => {
                          setTabs("received");
                        }}
                      >
                        Received
                      </a>
                    </li>
                    <li
                      className="nav-item"
                      onClick={() => {
                        setTabs("sent");
                      }}
                    >
                      <a className="nav-link" data-bs-toggle="tab" href="#sent">
                        Sent
                      </a>
                    </li>
                  </ul>
                </div>
                <select className="form-select" 
                style={{ width: "auto", textAlign:"center", fontStyle: "normal", fontWeight: "600", fontSize: "15px", lineHeight: "22px", color:"#676A79" }}
                value={filter}
                onChange={(e) =>
                  setFilter(e.target.value)
                  }>
                  <option selected value="all">ALL</option>
                  <option value="recent">MOST RECENT</option>
                  <option value="alphabetic">A TO Z LIST</option>
                </select>
              </div>
              <div className="tab-content mt-20">
                {/* <div id="received" className="container tab-pane active">
                  {
                    
                    pending_invitations?.map((data, index) => (
                    <div
                      className="row mt-30 mb-30"
                      style={{ alignItems: "center" }}
                      key={index}
                    >
                      {console.log("pending_invitations", pending_invitations)}
                      {data ? (
                        <>
                          <div className="col-lg-1 col-md-12 mb-2">
                            <div
                              className="profile-img"
                              onClick={() =>
                                navigate(`/profile/${data?.user_id}`)
                              }
                            >
                              <img
                                src={
                                  data?.profile_img
                                    ? data?.profile_img
                                    : placeholderUser
                                }
                                className="image-fit"
                                alt=""
                              />
                            </div>
                          </div>
                          <div className="col-lg-6 col-md-12 mb-3 pl-20">
                            <div className="details ps-3 ">
                              <h2
                                className="card-control-text-oneline mt-1"
                                onClick={() =>
                                  navigate(`/profile/${data?.user_id}`)
                                }
                              >
                                {data?.first_name} {data?.last_name}
                              </h2>
                              <p>
                                {data?.profile?.profile_headline ? (
                                  data?.profile?.profile_headline
                                ) : (
                                  <></>
                                )}
                              </p>
                              {data?.profile?.state?.name ? (
                                <p>
                                  {data?.profile?.state?.name},{" "}
                                  {data?.profile?.country?.name}
                                </p>
                              ) : (
                                data?.profile?.country?.name
                              )}
                              <p>
                                {data?.shared_connection?.length > 0 ? (
                                  <>
                                    <div className="people-icon"></div>
                                    <span>
                                      {" "}
                                      {
                                        data?.shared_connection[0]?.first_name
                                      }{" "}
                                      {data?.shared_connection[0]?.last_name} is
                                      a shared connection
                                    </span>
                                  </>
                                ) : (
                                  <></>
                                )}
                              </p>
                            </div>
                          </div>
                          <div className="col-lg-5 col-md-12  ">
                            <div className="d-flex float-xl-end">
                              <div
                                className="btn text-blue"
                                onClick={() =>
                                  acceptRequest(data?.id, "reject")
                                }
                              >
                                Ignore
                              </div>
                              <div
                                className="btn btn-light"
                                onClick={() =>
                                  acceptRequest(data?.id, "confirm")
                                }
                              >
                                Accept
                              </div>
                            </div>
                          </div>
                        </>
                      ) : (
                        <p>No Invitation found</p>
                      )}
                    </div>
                  ))}
                </div> */}

                <div id="received" className="container tab-pane active">
  {pending_invitations && pending_invitations.length > 0 ? (
    pending_invitations.map((data, index) => (
      <div
        className="row mt-30 mb-30"
        style={{ alignItems: "center" }}
        key={index}
      >
        {console.log("pending_invitations", pending_invitations)}
        {data ? (
          <>
            <div className="col-lg-1 col-md-12 mb-2">
              <div
                className="profile-img"
                onClick={() => navigate(`/profile/${data?.user_id}`)}
              >
                <img
                  src={data?.profile_img ? data?.profile_img : placeholderUser}
                  className="image-fit"
                  alt=""
                />
              </div>
            </div>
            <div className="col-lg-6 col-md-12 mb-3 pl-20">
              <div className="details ps-3 ">
                <h2
                  className="card-control-text-oneline mt-1"
                  onClick={() => navigate(`/profile/${data?.user_id}`)}
                >
                  {data?.first_name} {data?.last_name}
                </h2>
                <p>
                  {data?.profile?.profile_headline ? (
                    data?.profile?.profile_headline
                  ) : (
                    <></>
                  )}
                </p>
                {data?.profile?.state?.name ? (
                  <p>
                    {data?.profile?.state?.name},{" "}
                    {data?.profile?.country?.name}
                  </p>
                ) : (
                  data?.profile?.country?.name
                )}
                <p>
                  {data?.shared_connection?.length > 0 ? (
                    <>
                      <div className="people-icon"></div>
                      <span>
                        {" "}
                        {data?.shared_connection[0]?.first_name}{" "}
                        {data?.shared_connection[0]?.last_name} is a shared
                        connection
                      </span>
                    </>
                  ) : (
                    <></>
                  )}
                </p>
              </div>
            </div>
            <div className="col-lg-5 col-md-12  ">
              <div className="d-flex float-xl-end">
                <div
                  className="btn text-blue"
                  onClick={() => acceptRequest(data?.id, "reject")}
                >
                  Ignore
                </div>
                <div
                  className="btn btn-light"
                  onClick={() => acceptRequest(data?.id, "confirm")}
                >
                  <img src={AcceptIcon} style={{marginRight:"5px",paddingBottom:"2px"}} alt="" />
                  Accept
                </div>
              </div>
            </div>
          </>
        ) : (
          <p><b>No Invitation found</b></p>
        )}
      </div>
    ))
  ) : (
    <div className="post-main-box">
    <p style={{textAlign:"center"}}><b >No new invitations have been received at this time</b> </p>
    </div>
    
  )}
</div>


 {/* { console.log("sent_invitations_pending",sent_invitations_pending)} */}


                {/* <div id="sent" className="container tab-pane fade">
                  {sent_invitations_pending?.map((data, index) => (
                    <div
                      className="row mt-30 mb-30"
                      style={{ alignItems: "center" }}
                      key={index}
                    >
                     

                      <div className="col-lg-1 col-md-12 mb-2">
                        <div
                          className="profile-img"
                          onClick={() => navigate(`/profile/${data?.user_id}`)}
                        >
                          <img
                            src={
                              data?.profile_img
                                ? data?.profile_img
                                : placeholderUser
                            }
                            className="image-fit"
                            alt=""
                          />
                        </div>
                      </div>
                      <div className="col-lg-6 col-md-12 mb-3 pl-20">
                        <div className="details ps-3 ">
                          <h2
                            className="card-control-text-oneline mt-1"
                            onClick={() =>
                              navigate(`/profile/${data?.user_id}`)
                            }
                          >
                            {data?.first_name} {data?.last_name}
                          </h2>
                          <p>
                            {data?.profile?.profile_headline ? (
                              data?.profile?.profile_headline
                            ) : (
                              <></>
                            )}
                          </p>
                          {data?.profile?.state?.name ? (
                            <p>
                              {data?.profile?.state?.name},{" "}
                              {data?.profile?.country?.name}
                            </p>
                          ) : (
                            data?.profile?.country?.name
                          )}
                          <div>
                            {data?.shared_connection?.length > 0 ? (
                              <>
                                <div className="people-icon"></div>
                                <span>
                                  {" "}
                                  {data?.shared_connection[0]?.first_name}{" "}
                                  {data?.shared_connection[0]?.last_name} is a
                                  shared connection
                                </span>
                              </>
                            ) : (
                              <></>
                            )}
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-5 col-md-12">
                        <div className="d-flex float-xl-end">
                          <div
                            className="btn text-blue"
                            onClick={() => acceptRequest(data?.id, "cancel")}
                          >
                            Withdraw
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                </div> */}

                <div id="sent" className="container tab-pane fade">
  {sent_invitations_pending && sent_invitations_pending.length > 0 ? (
    sent_invitations_pending.map((data, index) => (
      <div
        className="row mt-30 mb-30"
        style={{ alignItems: "center" }}
        key={index}
      >
        <div className="col-lg-1 col-md-12 mb-2">
          <div
            className="profile-img"
            onClick={() => navigate(`/profile/${data?.user_id}`)}
          >
            <img
              src={
                data?.profile_img
                  ? data?.profile_img
                  : placeholderUser
              }
              className="image-fit"
              alt=""
            />
          </div>
        </div>
        <div className="col-lg-6 col-md-12 mb-3 pl-20">
          <div className="details ps-3 ">
            <h2
              className="card-control-text-oneline mt-1"
              onClick={() =>
                navigate(`/profile/${data?.user_id}`)
              }
            >
              {data?.first_name} {data?.last_name}
            </h2>
            <p>
              {data?.profile?.profile_headline ? (
                data?.profile?.profile_headline
              ) : (
                <></>
              )}
            </p>
            {data?.profile?.state?.name ? (
              <p>
                {data?.profile?.state?.name},{" "}
                {data?.profile?.country?.name}
              </p>
            ) : (
              data?.profile?.country?.name
            )}
            <div>
              {data?.shared_connection?.length > 0 ? (
                <>
                  <div className="people-icon"></div>
                  <span>
                    {" "}
                    {data?.shared_connection[0]?.first_name}{" "}
                    {data?.shared_connection[0]?.last_name} is a
                    shared connection
                  </span>
                </>
              ) : (
                <></>
              )}
            </div>
          </div>
        </div>
        <div className="col-lg-5 col-md-12">
          <div className="d-flex float-xl-end">
            <div
              className="btn btn-light text-blue"style={{display:"flex",gap:"3px",alignItems:"center"}}
              onClick={() => acceptRequest(data?.id, "cancel")}
            ><img src={withdraw} />
              Withdraw
            </div>
          </div>
        </div>
      </div>
    ))
  ) : (
    <p style={{textAlign:"center"}}> <b>No sent invitations pending</b></p>
  )}
</div>

              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
}
