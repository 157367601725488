import {
  GET_ALL_MEMBER_SUCCESS, WITHDRAW_MEMBER_REQUEST,
} from "../types";

const initialState = [];

export default function (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case GET_ALL_MEMBER_SUCCESS:
      return {
        ...state,
        memberData: payload,
      };

    case WITHDRAW_MEMBER_REQUEST:
      console.log(action.payload,"actionPayload", state.memberData);
      return {
        ...state,
        memberData: state.memberData.filter(
          (item) => item.user_id !== action.payload.user_id
        ),
      };


    default:
      return state;
  }
}
