import React, { useEffect, useState } from "react";
import "./unitUserList.scss";
import {
  getAllReactions,
  reactionsData,
} from "../../../store/reactionPost/reactionAction";
import Like from "../../../assets/img/like_1.svg";
import user from "../../../assets/img/user.svg";
import placeholderUser from "../../../assets/img/placeholder_user.jpg";
import Clap from "../../../assets/img/clapping 3.svg";
import hat from "../../../assets/img/hat 1.svg";
import Love from "../../../assets/img/ant-design_heart-filled.svg";
import Insightful from "../../../assets/img/idea_1.svg";
import Curious from "../../../assets/img/smile_1.svg";
import Surprise from "../../../assets/img/facebook-reactions_1.svg";
export default function UnitUserList(props) {
  const [reactionLogo, setReactionLogo] = useState();

  useEffect(() => {
    switch (props?.data?.type) {
      case "Like":
        setReactionLogo(Like);
        break;
      case "Love":
        setReactionLogo(Love);
        break;
      case "Respect":
        setReactionLogo(hat);
        break;
        case "Celebrate":
        setReactionLogo(Clap);
        break;
      case "Insightful":
        setReactionLogo(Insightful);
        break;
      case "Curious":
        setReactionLogo(Curious);
        break;
      case "Surprise":
        setReactionLogo(Surprise);
        break;
      default:
        setReactionLogo(Like);
    }
  }, []);

  return (
    <>
      {props?.data ? (
        <div className="row unitUserRow" style={{ gap: "0px" }}>
          <div className="col-2 d-flex align-items-center justify-content-end sm-sx-p-0">
            {props?.data?.Page ? (
              <img
                className="unitUserImg"
                src={
                  props?.data?.Page?.icon
                    ? props?.data?.Page?.icon
                    : placeholderUser
                }
                alt="pic"
              />
            ) : (
              <img
                className="unitUserImg"
                src={
                  props?.data?.User?.profile_img
                    ? props?.data?.User?.profile_img
                    : placeholderUser
                }
                alt="pic"
              />
            )}
          </div>
          <div
            className={
              props?.data?.User?.profile?.profile_headline
                ? "col-8"
                : "col-8 d-flex align-items-center"
            }
          >
            {props?.data?.Page ? (
              <p className="text-black fw-bold fs-6 unitUserName">
                {props?.data?.Page?.name}
              </p>
            ) : (
              <p className="text-black fw-bold fs-6 unitUserName">
                {props?.data?.User?.first_name} {props?.data?.User?.last_name}
              </p>
            )}
            {/* <p className="text-black-50 fw-light fs-6 unitUserName">Web Developer at Advantage</p> */}
            {!props?.data?.Page && (
              <>
                {props?.data?.User?.profile?.profile_headline ? (
                  <p className="text-black-50 fw-light fs-6 unitUserName">
                    {props?.data?.User?.profile?.profile_headline}
                  </p>
                ) : (
                  <></>
                )}
              </>
            )}
          </div>
          <div className="col-2 d-flex align-items-center">
            <img height={25} width={25} src={reactionLogo} alt="logo" />
          </div>
        </div>
      ) : (
        <h5>No Reactions Yet</h5>
      )}
    </>
  );
}
