import React, { useState, useRef, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
//import '../Connections/Connection/Connection.scss';
import {
  getConnectionsData,
  getFollowersData,
  getFollowingData,
  getLocationsData,
  getFilterData,
  getSchoolData,
  getCurrentCompanyData,
} from "../../store/connections/connectionsActions";
import Connection from "../Connections/Connection/Connection";
import Navbar from "../../../src/pages/navbar/navbar";
import SidebarFooter from "../setting/SidebarFooter";
import "./Connection/Connection.scss";
import Autocomplete from "react-autocomplete";
import Refer from "../../core/components/layout/Sidebar/Refer";
import { useLocation } from "react-router-dom";
import Ad from "../../core/components/layout/Sidebar/Ad";
import { Link } from "react-router-dom";
import {
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
} from "reactstrap";
import { initPageLoad, stopPageLoad } from "../../store/loader/loaderActions";
import AppLoader from "../../core/components/Loaders/appLoader/appLoader";

export default function ConnectionsContainer() {
  // one component named connection is created.
  // Data is passed for Connections, Followers, Following

  const [connectionsAPIData, setConnectionsAPIData] = useState([]);
  const [isConnectionList, setIsConnectionList] = useState(true);
  const [isConnectionCount, setIsConnectionCount] = useState(true);
  const [followerAPIData, setFollowerAPIData] = useState([]);
  const [isFollowerList, setIsFollowerList] = useState(true);
  const [isFollowerCount, setIsFollowerCount] = useState(true);
  const [followingAPIData, setFollowingAPIData] = useState([]);
  const [isFollowingList, setIsFollowingList] = useState(true);
  const [locationsAPIData, setLocationsAPIData] = useState([]);
  const [schoolsAPIData, setSchoolsAPIData] = useState([]);
  const [currentCompanyAPIData, setCurrentCompanyAPIData] = useState([]);
  const [searchFieldValue, setSearchFieldValue] = useState("");
  const [currentFilterParams, setCurrentFilterParams] = useState({});
  const [followerText, setFollowerText] = useState("Follower");
  const [connectionText, setConnectionText] = useState("Connection");
  const location = useLocation();
  const user_id = location.state?.user_id;
  const [isFollowing, setIsFollowing] = useState(false);

  const [dropDown, setDropDown] = useState(false);
  const { isPageLoading } = useSelector((state) => state.loader);

  const node = useRef();

  useEffect(() => {
    document.addEventListener("mousedown", clickOutsideDropdown);
    return () => {
      document.removeEventListener("mousedown", clickOutsideDropdown);
    };
  }, [dropDown]);
  const clickOutsideDropdown = (e) => {
    if (node?.current?.contains(e.target)) {
      // console.log("clicked inside");
      return;
    }
    // console.log("clicked outside scope");
    setDropDown(false);
  };

  //above is the dropdown logic
  const dispatch = useDispatch();
  useEffect(() => {
    getConnections({});
    getFollowers({});
    getFollowing({});
    getSchoolList();
    getCurrentCompanyList();
  }, []);

  const getConnections = (dataObj) => {
    dispatch(initPageLoad());
    dispatch(getConnectionsData(dataObj, user_id))
      .then((response) => {
        setIsConnectionCount(response.isConnectionCount);
        setIsConnectionList(response.isConnectionList);
        setConnectionsAPIData(response.data);
        dispatch(stopPageLoad());
      })
      .catch((err) => {
        dispatch(stopPageLoad());
        // console.log("error!!", err);
      });
  };

  const getFollowers = (dataObj) => {
    dispatch(initPageLoad());
    dispatch(getFollowersData(dataObj, user_id))
      .then((response) => {
        setIsFollowerCount(response.isFollowerCount);
        setIsFollowerList(response.isFollowerList);
        setFollowerAPIData(response.data);
        dispatch(stopPageLoad());
      })
      .catch((err) => {
        // // console.log("error!!", err);
        dispatch(stopPageLoad());
      });
  };

  const getFollowing = (dataObj) => {
    dispatch(initPageLoad());
    dispatch(getFollowingData(dataObj, user_id))
      .then((response) => {
        setFollowingAPIData(response.data);
        setIsFollowingList(response.isFollowingListShow);
        dispatch(stopPageLoad());
      })
      .catch((err) => {
        // console.log("error!!", err);
        dispatch(stopPageLoad());
      });
  };

  const getLocations = ({ value }) => {
    if (value)
      dispatch(getLocationsData(value))
        .then((response) => {
          if (response.data) {
            setLocationsAPIData(response.data);
          } else setLocationsAPIData([]);
        })
        .catch((err) => {
          // console.log("error!!", err);
          setLocationsAPIData([]);
        });
    else setLocationsAPIData([]);
  };

  const getSchoolList = () => {
    dispatch(getSchoolData())
      .then((response) => {
        if (response.data) setSchoolsAPIData(response.data);
        else setSchoolsAPIData([]);
      })
      .catch((err) => {
        // console.log("error!!", err);
        setSchoolsAPIData([]);
      });
  };

  const getCurrentCompanyList = () => {
    dispatch(getCurrentCompanyData())
      .then((response) => {
        if (response.data) setCurrentCompanyAPIData(response.data);
        else setCurrentCompanyAPIData([]);
      })
      .catch((err) => {
        // console.log("error!!", err);
        setCurrentCompanyAPIData([]);
      });
  };

  const handleFilter = (name, value) => {
    const tempFilterParam = {
      ...currentFilterParams,
    };

    if (name === "location_type") {
      delete tempFilterParam.location_type;
      delete tempFilterParam.location_id;
    } else if (!value) {
      delete tempFilterParam[name];
    } else {
      tempFilterParam[name] = value;
    }

    getConnections(tempFilterParam);
    getFollowers(tempFilterParam);
    getFollowing(tempFilterParam);
    setCurrentFilterParams(tempFilterParam);
  };

  useEffect(() => {
    {
      followerAPIData?.length > 1
        ? setFollowerText("Followers")
        : setFollowerText("Follower");
    }
  }, [followerAPIData]);

  useEffect(() => {
    {
      connectionsAPIData?.length > 1
        ? setConnectionText("Connections")
        : setConnectionText("Connection");
    }
  }, [connectionsAPIData]);

  return (
    <>
      <Navbar isJobs={false} />
      {isPageLoading === true ? (
        <AppLoader />
      ) : (
        <div className="main-bg" style={{ minHeight: "100vh" }}>
          <div className="people">
            <div className="container">
              <div className="row">
                <div className="col-12">
                  <div className="wrappers">
                    <ul>
                      <li className="autocomplete-wrapper">
                        <span className="active">
                          <span
                            style={{
                              color: "#01052D",
                              fontWeight: "600",
                              fontSize: "15px",
                              marginLeft: "15px",
                              marginRight: "40px",
                            }}
                          >
                            People
                          </span>
                        </span>
                      </li>
                      <strong className="vr-line"></strong>
                      <li className="autocomplete-wrapper">
                        <Autocomplete
                          inputProps={{ placeholder: "Location" }}
                          placeholder="location"
                          value={searchFieldValue}
                          items={locationsAPIData}
                          getItemValue={(item) => item.name}
                          shouldItemRender={() => locationsAPIData}
                          onChange={(e, value) => {
                            setSearchFieldValue(e.target.value);
                            getLocations(e.target);
                            !e.target.value &&
                              handleFilter("location_type", null);
                          }}
                          onSelect={(value, obj) => {
                            setSearchFieldValue(value);
                            getConnections({
                              ...currentFilterParams,
                              location_type: obj.type,
                              location_id: obj.id,
                            });
                            getFollowers({
                              ...currentFilterParams,
                              location_type: obj.type,
                              location_id: obj.id,
                            });
                            getFollowing({
                              ...currentFilterParams,
                              location_type: obj.type,
                              location_id: obj.id,
                            });
                            setCurrentFilterParams({
                              ...currentFilterParams,
                              location_type: obj.type,
                              location_id: obj.id,
                            });
                          }}
                          renderItem={(item, isHighlighted) => (
                            <div
                              className={`item ${
                                isHighlighted ? "item-highlighted" : ""
                              }`}
                              key={item.name}
                            >
                              {item.name}
                            </div>
                          )}
                        />
                      </li>

                      {/* <FormGroup>
                    
                    <Input
                    onClick={getCurrentCompanyList}
                      
                      style={{ color: "#676A79" }}
                      id="fieldStudySelect"
                      name="select"
                      type="select"
                      onChange={({ target }) => {
                          handleFilter("company_name", target.value);
                        }}
                    >
                      <option value="">Current Company</option>
                      {currentCompanyAPIData?.data?.map((item, index) => {
                        return (
                          <option className="" key={item.id} value={item.name}>
                            {item.name}
                          </option>
                        );
                      })}
                    </Input>
                   
                  </FormGroup> */}

                      {/* <div
                            ref={node}
                            className="dots"
                            onClick={() => setDropDown(!dropDown)}
                          >
                            <span>&#x2022;</span>
                            <span>&#x2022;</span>
                            <span>&#x2022;</span>
                          </div> */}
                      {/* <div ref={node}>
                          <div className="drop"    ref={node}  onClick={() => setDropDown(!dropDown)}>
                             {
                              dropDown &&  
                              <div className="box">
                              <ul>
                             
                        </ul>
                              </div>
                             }
                          </div>

                          </div> */}

                      <li onClick={getCurrentCompanyList}>
                        <select
                          className="form-select selectOptionOption"
                          style={{ width: "auto" }}
                          onChange={({ target }) => {
                            handleFilter("company_name", target.value);
                          }}
                          value={currentFilterParams?.company_name || ""}
                        >
                          <option defaultdisabled value="">
                            Current Company
                          </option>
                          {currentCompanyAPIData.map((item) => (
                            <option
                              className=""
                              key={item.id}
                              value={item.name}
                            >
                              {item.name}
                            </option>
                          ))}
                        </select>
                      </li>

                      <li onClick={getSchoolList}>
                        {/* for school */}
                        <select
                          className="form-select custom-scrollbar"
                          onChange={({ target }) => {
                            handleFilter("school_name", target.value);
                          }}
                          value={currentFilterParams?.school_name || ""}
                        >
                          <option default value="">
                            School
                          </option>
                          {schoolsAPIData.map((item, i) => (
                            <React.Fragment key={i}>
                              <option value={item.school_name}>
                                {item.school_name}
                              </option>
                              {/* {i < schoolsAPIData.length - 1 && (
        <div style={{ border: '1px solid black' }}>dsfdf</div>
      )} */}
                            </React.Fragment>
                          ))}
                        </select>
                      </li>
                      {/* <li className="" onClick={ClearFilter}>
                      Clear Filter
                    </li> */}
                      {/* for Location */}
                    </ul>
                  </div>
                </div>
              </div>
              <div className="row mt-20 pb-5">
                <div className="col-lg-9">
                  <div className="wrapper">
                    <ul className="nav nav-tabs" id="myTab" role="tablist">
                      <li className="nav-item" role="presentation">
                        <button
                          className="nav-link active"
                          id="home-tab"
                          data-bs-toggle="tab"
                          data-bs-target="#home"
                          type="button"
                          role="tab"
                          aria-controls="home"
                          aria-selected="true"
                          // onClick={() => getConnections(currentFilterParams)}
                        >
                          {connectionText}
                          <span>
                            {` (${
                              isConnectionCount === false
                                ? "Hidden"
                                : connectionsAPIData
                                ? connectionsAPIData.length
                                : 0
                            })`}
                          </span>
                        </button>
                      </li>
                      <li className="nav-item" role="presentation">
                        <button
                          className="nav-link"
                          id="profile-tab"
                          data-bs-toggle="tab"
                          data-bs-target="#profile"
                          type="button"
                          role="tab"
                          aria-controls="profile"
                          aria-selected="false"
                          // onClick={() => getFollowers(currentFilterParams)}
                        >
                          {followerText}
                          {` (${
                            isFollowerCount === false
                              ? "Hidden"
                              : followerAPIData
                              ? followerAPIData.length
                              : 0
                          })`}
                        </button>
                      </li>
                      <li className="nav-item" role="presentation">
                        <button
                          // onClick={() => getFollowing(currentFilterParams)}
                          onClick={() => {
                            setIsFollowing(true);
                          }} //}
                          className="nav-link"
                          id="contact-tab"
                          data-bs-toggle="tab"
                          data-bs-target="#contact"
                          type="button"
                          role="tab"
                          aria-controls="contact"
                          aria-selected="false"
                        >
                          Following (
                          {followingAPIData ? followingAPIData.length : 0})
                        </button>
                      </li>
                    </ul>
                    <div className="tab-content" id="myTabContent">
                      <div
                        className="tab-pane fade show active"
                        id="home"
                        role="tabpanel"
                        aria-labelledby="home-tab"
                      >
                        {isConnectionList ? (
                          connectionsAPIData?.map((item, i) => (
                            <Connection connectionsAPIData={item} key={i} />
                          ))
                        ) : (
                          <p className="mt-2">
                            User has kept the connection list private
                          </p>
                        )}
                      </div>

                      <div
                        className="tab-pane fade"
                        id="profile"
                        role="tabpanel"
                        aria-labelledby="profile-tab"
                      >
                        {isFollowerList ? (
                          followerAPIData?.map((item, i) => (
                            <Connection connectionsAPIData={item} key={i} />
                          ))
                        ) : (
                          <p className="mt-2">
                            User has kept the followers list private
                          </p>
                        )}
                      </div>
                      <div
                        className="tab-pane fade"
                        id="contact"
                        role="tabpanel"
                        aria-labelledby="contact-tab"
                      >
                        {isFollowingList ? (
                          followingAPIData?.map((item, i) => (
                            <Connection
                              connectionsAPIData={item}
                              isFollowing={isFollowing}
                              key={i}
                            />
                          ))
                        ) : (
                          <p className="mt-2">
                            User has kept the followings list private
                          </p>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-3 mt-2">
                  {/* <Refer/> */}
                  <div className=" mb-20">
                    <Ad />
                  </div>
                  {/* <Link to={"/network"}>
                  <div className="left px-3 py-3 mb-10 d-flex justify-content-center border-radius-5 parent-discover-btn">
                    <div className="discover-btn">
                      <h4>Discover more</h4>
                    </div>
                  </div>
                </Link> */}
                  <SidebarFooter />
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
