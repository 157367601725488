import Layout from "../Layout/Layout";
import React, { useEffect } from "react";
import moment from "moment";
import CreateEventModal from "./components/createEvent";
import ShareModal from "../../../core/components/sharePost/sharePost";
import EventSuccessfulModal from "./components/SuccessfulModal";
import Loader from "../../../core/components/Loaders/ComponentLoader";
import placeholderUser from "../../../assets/img/placeholder_user.jpg";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";
import nopreview from "../../../assets/img/no-preview.png";
import AppLoader from "../../../core/components/Loaders/appLoader/appLoader";
import InfiniteScroll from "react-infinite-scroll-component";
import leave from "../../../assets/img/Leave.png";
import attend from "../../../assets/img/Check.png";
import EditEventIcon from "../../../assets/img/EditEventIcon.svg";

export default function Events(props) {
  const {
    newSuggestedEvents,
    pinHandle,
    setTabs,
    newPinnedEvents,
    readMore,
    openCreateModal,
    setOpenCreateModal,
    newMyEvents,
    newMyDrafts,
    tabs,
    searchKey,
    setSearchKey,
    filterSearchData,
    openShareModal,
    setOpenShareModal,
    shareData,
    setShareData,
    attendHandle,
    newUpcomingEvents,
    newHistoryEvents,
    successfulStatus,
    successModal,
    setSuccessModal,
    isPageLoading,
    editEventData,
    setEditEventData,
    popupShareData,
    currentCount,
    setCurrentCount,
    loadMoreData,
    totalCount,
    user,
  } = props;

  //Search on Enter Button

  const handleKeyDown = (e) => {
    // Check if the pressed key is "Enter" (key code 13)
    if (e.key === "Enter") {
      filterSearchData(searchKey);
      console.log(searchKey);
    }
  };

  useEffect(() => {
    if (searchKey == "") {
      setCurrentCount(0);
    }
    filterSearchData(searchKey);
  }, [searchKey]);

  return (
    <>
      {isPageLoading && <AppLoader />}
      <Layout>
        <div className="col-lg-9">
          <div className="main-center mt-4">
            <div className="search">
              <input
                type="text"
                placeholder="Search Event"
                onChange={(e) => setSearchKey(e.target.value)}
                onKeyDown={handleKeyDown}
              />
              <div
                className="search-icon"
                onClick={() => filterSearchData(searchKey)}
              ></div>
            </div>
            <div className="wrapper mt-20">
              <div className="container p-3">
                <div className="overflow">
                  <ul className="nav nav-tabs" role="tablist">
                    <li className="nav-item">
                      <a
                        className="nav-link active"
                        data-bs-toggle="tab"
                        href="#suggested_events"
                        onClick={() => {
                          setTabs("suggested_events");
                        }}
                      >
                        Suggested Events
                      </a>
                    </li>
                    <li className="nav-item">
                      <a
                        className="nav-link"
                        data-bs-toggle="tab"
                        onClick={() => setTabs("upcoming_events")}
                        href="#upcoming_events"
                      >
                        Upcoming Events
                      </a>
                    </li>
                    <li className="nav-item">
                      <a
                        className="nav-link"
                        data-bs-toggle="tab"
                        onClick={() => setTabs("pinned_events")}
                        href="#pinned_events"
                      >
                        Pinned Events
                      </a>
                    </li>
                    <li className="nav-item">
                      <a
                        className="nav-link"
                        data-bs-toggle="tab"
                        onClick={() => setTabs("history_events")}
                        href="#history_events"
                      >
                        History
                      </a>
                    </li>
                    {user?.data?.membership?.name == "Starter" ? (
                      <></>
                    ) : (
                      <>
                        <li className="nav-item">
                          <a
                            className="nav-link"
                            data-bs-toggle="tab"
                            onClick={() => setTabs("my_events")}
                            href="#my_events"
                          >
                            My Events
                          </a>
                        </li>
                        <li className="nav-item">
                          <div
                            className="end"
                            onClick={() => setOpenCreateModal(!openCreateModal)}
                          >
                            + Create Event
                          </div>
                        </li>
                      </>
                    )}
                  </ul>
                </div>

                <div className="tab-content mt-20">
                  <div
                    id="suggested_events"
                    className="container tab-pane active"
                  >
                    {console.log(
                      "newSuggestedEvents",
                      newSuggestedEvents?.length
                    )}
                    <InfiniteScroll
                      dataLength={
                        newSuggestedEvents?.length
                          ? newSuggestedEvents?.length
                          : 0
                        // currentCount
                      }
                      next={loadMoreData}
                      hasMore={newSuggestedEvents?.length ? true : false}
                      // loader={<h4>Loading...</h4>}
                      // endMessage={
                      //   <p style={{ textAlign: "center" }}>
                      //     <b>Yay! You have seen it all</b>
                      //   </p>
                      // }
                    >
                      <div className="row">
                        <div className="networks">
                          <div className="main-center">
                            <div className="wrapper">
                              <div className="events">
                                <div className="row mt-20">
                                  {newSuggestedEvents.map((data) => {
                                    return (
                                      <div className="col-lg-4 col-md-4 col-sm-6 col-xs-1">
                                        <div className="card">
                                          <div
                                            className="img-section"
                                            style={{ cursor: "pointer" }}
                                          >
                                            <div
                                              className="card-img"
                                              style={{
                                                height: "100%",
                                                width: "100%",
                                              }}
                                              onClick={() => {
                                                readMore(data.id);
                                              }}
                                            >
                                              {/* <img
                                                src={data?.media}
                                                className="event-card-image"
                                                alt=""
                                                onClick={() => {
                                                  readMore(data.id);
                                                }}
                                                style={{cursor:"pointer"}}
                                              /> */}
                                            </div>
                                            <div
                                              className={
                                                data.event_type === "physical"
                                                  ? "tag tag-color-physical"
                                                  : "tag tag-color-virtual"
                                              }
                                            >
                                              {data.event_type === "physical"
                                                ? "Physical"
                                                : "Virtual"}
                                            </div>
                                            {data?.Pinned_Event?.status ? (
                                              <div
                                                style={{
                                                  height: "40px",
                                                  width: "40px",
                                                }}
                                                className="unpin"
                                                onClick={() =>
                                                  pinHandle(data.id, "pinFalse")
                                                }
                                              ></div>
                                            ) : (
                                              <div
                                                className="pin"
                                                onClick={() =>
                                                  pinHandle(data.id, "pinTrue")
                                                }
                                              ></div>
                                            )}
                                          </div>
                                          <div className="mr-10 ml-10 ">
                                            <div className="header mt-30">
                                              <h4
                                                className="card-event-control"
                                                onClick={() => {
                                                  readMore(data.id);
                                                }}
                                              >
                                                {data.title}
                                              </h4>
                                            </div>
                                            <div className="date">
                                              <div className="calender-icon"></div>{" "}
                                              {moment(data?.event_time).format(
                                                "ddd, MMM DD, YYYY"
                                              )}{" "}
                                              at{" "}
                                              {moment(data?.event_time).format(
                                                "hh:mm A z"
                                              )}
                                            </div>
                                            {data.location ? (
                                              <div className="location">
                                                <div className="icon"></div>
                                                <span className="card-control-text-oneline">
                                                  {data?.location
                                                    ?.split(",")
                                                    ?.splice(-1)}
                                                </span>
                                              </div>
                                            ) : (
                                              <div className="location">
                                                <div className="icon"></div>
                                                <span className="card-control-text-oneline">
                                                  No Location Added
                                                </span>
                                              </div>
                                            )}
                                            <div className="people-pic">
                                              <div className="d-flex">
                                                <div className="profiles-img">
                                                  {data?.Event_Participant?.map(
                                                    (data, idx) => {
                                                      return (
                                                        idx < 3 && (
                                                          <span key={idx}>
                                                            <img
                                                              src={
                                                                data?.user
                                                                  ?.profile_img
                                                                  ? data?.user
                                                                      ?.profile_img
                                                                  : placeholderUser
                                                              }
                                                              className="image-fit"
                                                              alt=""
                                                            />
                                                          </span>
                                                        )
                                                      );
                                                    }
                                                  )}
                                                  {data?.Event_Participant
                                                    ?.length > 3 ? (
                                                    <span>
                                                      +
                                                      {data?.Event_Participant
                                                        ?.length - 3}
                                                    </span>
                                                  ) : (
                                                    <span>
                                                      {
                                                        data?.Event_Participant
                                                          ?.length
                                                      }
                                                    </span>
                                                  )}
                                                </div>
                                              </div>

                                              <div>are attending</div>
                                            </div>
                                            <div className="d-flex">
                                              {data.Attend_Event?.status ? (
                                                <div
                                                  className="btn btn-light text-blue"
                                                  onClick={() =>
                                                    attendHandle(
                                                      data.id,
                                                      "attendFalse"
                                                    )
                                                  }
                                                >
                                                  <div>
                                                    <img
                                                      style={{
                                                        marginRight: "3%",
                                                      }}
                                                      src={leave}
                                                      alt="Description of the image"
                                                    />
                                                    Leave
                                                  </div>
                                                </div>
                                              ) : (
                                                <div
                                                  className="btn btn-light text-blue"
                                                  onClick={() =>
                                                    attendHandle(
                                                      data.id,
                                                      "attendTrue"
                                                    )
                                                  }
                                                  style={{
                                                    padding: "9px 19px",
                                                    display: "flex",
                                                    alignItems: "center",
                                                    justifyContent: "center",
                                                  }}
                                                >
                                                  <img
                                                    src={attend}
                                                    style={{
                                                      marginRight: "6%",
                                                      color: "white",
                                                      display: "block",
                                                    }}
                                                  />
                                                  <div>Attend</div>
                                                </div>
                                              )}
                                              <div
                                                className="share-icon"
                                                onClick={() => {
                                                  setOpenShareModal(
                                                    !openShareModal
                                                  );
                                                  setShareData(data);
                                                }}
                                              ></div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    );
                                  })}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </InfiniteScroll>
                  </div>
                  <div id="upcoming_events" className="container tab-pane fade">
                    <InfiniteScroll
                      dataLength={
                        //currentCount
                        newUpcomingEvents?.length
                          ? newUpcomingEvents?.length
                          : 0
                      }
                      next={loadMoreData}
                      hasMore={
                        totalCount === 0
                          ? true
                          : totalCount <= newUpcomingEvents?.length
                          ? false
                          : true
                      }
                      // hasMore={newUpcomingEvents?.length ? true : false}
                      // hasMore={ totalCount === 0
                      //         ? true
                      //         : totalCount <= newUpcomingEvents?.length
                      //         ? false
                      //         : true}
                      // hasMore={newUpcomingEvents?.data ? true : false}
                      // loader={<h4>Loading...</h4>}
                      // endMessage={
                      //   <p style={{ textAlign: "center" }}>
                      //     <b>Yay! You have seen it all</b>
                      //   </p>
                      // }
                    >
                      <div className="row">
                        <div className="networks">
                          <div className="main-center">
                            <div className="wrapper">
                              <div className="events">
                                {newUpcomingEvents.length > 0 ? (
                                  <div className="row mt-20">
                                    {newUpcomingEvents.map((data) => {
                                      return (
                                        <div className="col-lg-4 col-md-4 col-sm-6 col-xs-1">
                                          <div className="card">
                                            <div
                                              className="img-section"
                                              style={{ cursor: "pointer" }}
                                            >
                                              <div
                                                className="card-img"
                                                style={{
                                                  height: "100%",
                                                  width: "100%",
                                                }}
                                                onClick={() => {
                                                  readMore(data.id);
                                                }}
                                              >
                                                <img
                                                  src={data?.media}
                                                  className="event-card-image"
                                                  alt=""
                                                />
                                              </div>
                                              <div
                                                className={
                                                  data.event_type === "physical"
                                                    ? "tag tag-color-physical"
                                                    : "tag tag-color-virtual"
                                                }
                                              >
                                                {data.event_type === "physical"
                                                  ? "Physical"
                                                  : "Virtual"}
                                              </div>
                                              {data?.Pinned_Event?.status ? (
                                                <div
                                                  style={{
                                                    height: "40px",
                                                    width: "40px",
                                                  }}
                                                  className="unpin"
                                                  onClick={() =>
                                                    pinHandle(
                                                      data.id,
                                                      "pinFalse"
                                                    )
                                                  }
                                                ></div>
                                              ) : (
                                                <div
                                                  className="pin"
                                                  onClick={() =>
                                                    pinHandle(
                                                      data.id,
                                                      "pinTrue"
                                                    )
                                                  }
                                                ></div>
                                              )}
                                            </div>
                                            <div className="mr-10 ml-10 ">
                                              <div className="header mt-30">
                                                <h4
                                                  className="card-event-control"
                                                  onClick={() => {
                                                    readMore(data.id);
                                                  }}
                                                >
                                                  {data.title}
                                                </h4>
                                              </div>
                                              <div className="date">
                                                <div className="calender-icon"></div>{" "}
                                                {moment(
                                                  data?.event_time
                                                ).format(
                                                  "ddd, MMM DD, YYYY"
                                                )}{" "}
                                                at{" "}
                                                {moment(
                                                  data?.event_time
                                                ).format("hh:mm A z")}
                                              </div>
                                              {data.location ? (
                                                <div className="location">
                                                  <div className="icon"></div>
                                                  <span className="card-control-text-oneline">
                                                    {data?.location
                                                      ?.split(",")
                                                      ?.splice(-1)}
                                                  </span>
                                                </div>
                                              ) : (
                                                <div className="location">
                                                  <div className="icon"></div>
                                                  <span className="card-control-text-oneline">
                                                    No Location Added
                                                  </span>
                                                </div>
                                              )}
                                              <div className="people-pic">
                                                <div className="d-flex">
                                                  <div className="profiles-img">
                                                    {data?.Event_Participant?.map(
                                                      (data, idx) => {
                                                        return (
                                                          idx < 3 && (
                                                            <span key={idx}>
                                                              <img
                                                                src={
                                                                  data?.user
                                                                    ?.profile_img
                                                                    ? data?.user
                                                                        ?.profile_img
                                                                    : placeholderUser
                                                                }
                                                                className="image-fit"
                                                                alt=""
                                                              />
                                                            </span>
                                                          )
                                                        );
                                                      }
                                                    )}
                                                    {data?.Event_Participant
                                                      ?.length > 3 ? (
                                                      <span>
                                                        +
                                                        {data?.Event_Participant
                                                          ?.length - 3}
                                                      </span>
                                                    ) : (
                                                      <span>
                                                        {
                                                          data
                                                            ?.Event_Participant
                                                            ?.length
                                                        }
                                                      </span>
                                                    )}
                                                  </div>
                                                </div>

                                                <div>
                                                  {data?.Event_Participant
                                                    ?.length > 1
                                                    ? "are"
                                                    : "is"}{" "}
                                                  attending
                                                </div>
                                              </div>
                                              <div className="d-flex">
                                                {data.Attend_Event?.status ? (
                                                  <div
                                                    className="btn btn-light text-blue"
                                                    onClick={() =>
                                                      attendHandle(
                                                        data.id,
                                                        "attendFalse"
                                                      )
                                                    }
                                                  >
                                                    <div>
                                                      <img
                                                        style={{
                                                          marginRight: "3%",
                                                        }}
                                                        src={leave}
                                                        alt="Description of the image"
                                                      />
                                                      Leave
                                                    </div>
                                                  </div>
                                                ) : (
                                                  <div
                                                    className="btn btn-light text-blue"
                                                    onClick={() =>
                                                      attendHandle(
                                                        data.id,
                                                        "attendTrue"
                                                      )
                                                    }
                                                  >
                                                    <div>
                                                      <img
                                                        src={attend}
                                                        style={{
                                                          marginRight: "6px",
                                                          color: "white",
                                                        }}
                                                      />{" "}
                                                      Attend
                                                    </div>
                                                  </div>
                                                )}
                                                <div
                                                  className="share-icon"
                                                  onClick={() => {
                                                    setOpenShareModal(
                                                      !openShareModal
                                                    );
                                                    setShareData(data);
                                                  }}
                                                ></div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      );
                                    })}
                                  </div>
                                ) : (
                                  <div className="row">
                                    <p
                                      style={{ textAlign: "center" }}
                                      className="my-30"
                                    >
                                      <b>No upcomming events</b>
                                    </p>
                                  </div>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </InfiniteScroll>
                  </div>
                  <div id="pinned_events" className="container tab-pane fade">
                    <InfiniteScroll
                      dataLength={
                        //currentCount
                        newPinnedEvents?.length ? newPinnedEvents?.length : 0
                        // newPinnedEvents?.length ? newPinnedEvents?.length : 0
                      }
                      next={loadMoreData}
                      hasMore={
                        totalCount === 0
                          ? true
                          : totalCount <= newPinnedEvents?.length
                          ? false
                          : true
                      }
                      // loader={<h4>Loading...</h4>}
                      // endMessage={
                      //   <p style={{ textAlign: "center" }}>
                      //     <b>Yay! You have seen it all</b>
                      //   </p>
                      // }
                    >
                      <div className="row">
                        <div className="networks">
                          <div className="main-center">
                            <div className="wrapper">
                              <div className="events">
                                {newPinnedEvents.length > 0 ? (
                                  <div className="row mt-20">
                                    {newPinnedEvents?.map((data) => {
                                      return (
                                        <div className="col-lg-4 col-md-4 col-sm-6 col-xs-1">
                                          <div className="card">
                                            <div
                                              className="img-section"
                                              style={{ cursor: "pointer" }}
                                            >
                                              <div
                                                className="card-img"
                                                style={{
                                                  height: "100%",
                                                  width: "100%",
                                                }}
                                                onClick={() => {
                                                  readMore(data.id);
                                                }}
                                              >
                                                <img
                                                  src={data?.media}
                                                  className="event-card-image"
                                                  alt=""
                                                />
                                              </div>
                                              <div
                                                className={
                                                  data.event_type === "physical"
                                                    ? "tag tag-color-physical"
                                                    : "tag tag-color-virtual"
                                                }
                                              >
                                                {data.event_type === "physical"
                                                  ? "Physical"
                                                  : "Virtual"}
                                              </div>
                                              {data?.Pinned_Event?.status ? (
                                                <div
                                                  style={{
                                                    height: "40px",
                                                    width: "40px",
                                                  }}
                                                  className="unpin"
                                                  onClick={() =>
                                                    pinHandle(
                                                      data.id,
                                                      "pinFalse"
                                                    )
                                                  }
                                                ></div>
                                              ) : (
                                                <div
                                                  className="pin"
                                                  onClick={() =>
                                                    pinHandle(
                                                      data.id,
                                                      "pinTrue"
                                                    )
                                                  }
                                                ></div>
                                              )}
                                            </div>
                                            <div className="mr-10 ml-10 ">
                                              <div className="header mt-30">
                                                <h4
                                                  className="card-event-control"
                                                  onClick={() => {
                                                    readMore(data.id);
                                                  }}
                                                >
                                                  {data.title}
                                                </h4>
                                              </div>
                                              <div className="date">
                                                <div className="calender-icon"></div>{" "}
                                                {moment(
                                                  data?.event_time
                                                ).format(
                                                  "ddd, MMM DD, YYYY"
                                                )}{" "}
                                                at{" "}
                                                {moment(
                                                  data?.event_time
                                                ).format("hh:mm A z")}
                                              </div>
                                              {data.location ? (
                                                <div className="location">
                                                  <div className="icon"></div>
                                                  <span className="card-control-text-oneline">
                                                    {data?.location
                                                      ?.split(",")
                                                      ?.splice(-1)}
                                                  </span>
                                                </div>
                                              ) : (
                                                <div className="location">
                                                  <div className="icon"></div>
                                                  <span className="card-control-text-oneline">
                                                    No Location Added
                                                  </span>
                                                </div>
                                              )}
                                              <div className="people-pic">
                                                <div className="d-flex">
                                                  <div className="profiles-img">
                                                    {data?.Event_Participant?.map(
                                                      (data, idx) => {
                                                        return (
                                                          idx < 3 && (
                                                            <span key={idx}>
                                                              <img
                                                                src={
                                                                  data?.user
                                                                    ?.profile_img
                                                                    ? data?.user
                                                                        ?.profile_img
                                                                    : placeholderUser
                                                                }
                                                                className="image-fit"
                                                                alt=""
                                                              />
                                                            </span>
                                                          )
                                                        );
                                                      }
                                                    )}
                                                    {data?.Event_Participant
                                                      ?.length > 3 ? (
                                                      <span>
                                                        +
                                                        {data?.Event_Participant
                                                          ?.length - 3}
                                                      </span>
                                                    ) : (
                                                      <span>
                                                        {
                                                          data
                                                            ?.Event_Participant
                                                            ?.length
                                                        }
                                                      </span>
                                                    )}
                                                  </div>
                                                </div>
                                                {data?.Event_Participant
                                                  ?.length > 1
                                                  ? "are"
                                                  : "is"}{" "}
                                                attending
                                              </div>
                                              <div className="d-flex">
                                                {data?.Attend_Event?.status ? (
                                                  <div
                                                    className="btn btn-light text-blue"
                                                    onClick={() =>
                                                      attendHandle(
                                                        data.id,
                                                        "attendFalse"
                                                      )
                                                    }
                                                  >
                                                    <div>
                                                      <img
                                                        style={{
                                                          marginRight: "3%",
                                                        }}
                                                        src={leave}
                                                        alt="Description of the image"
                                                      />
                                                      Leave
                                                    </div>
                                                  </div>
                                                ) : (
                                                  <div
                                                    className="btn btn-light text-blue"
                                                    onClick={() =>
                                                      attendHandle(
                                                        data.id,
                                                        "attendTrue"
                                                      )
                                                    }
                                                    style={{
                                                      padding: "9px 19px",
                                                      display: "flex",
                                                      alignItems: "center",
                                                      justifyContent: "center",
                                                    }}
                                                  >
                                                    <img
                                                      src={attend}
                                                      style={{
                                                        marginRight: "6%",
                                                        color: "white",
                                                        display: "block",
                                                      }}
                                                    />
                                                    <div>Attend</div>
                                                  </div>
                                                )}
                                                <div
                                                  className="share-icon"
                                                  onClick={() => {
                                                    setOpenShareModal(
                                                      !openShareModal
                                                    );
                                                    setShareData(data);
                                                  }}
                                                ></div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      );
                                    })}
                                  </div>
                                ) : (
                                  <div className="row ">
                                    <p
                                      style={{ textAlign: "center" }}
                                      className="my-30"
                                    >
                                      <b>No pinned events</b>
                                    </p>
                                  </div>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </InfiniteScroll>
                  </div>
                  <div id="history_events" className="container tab-pane fade">
                    <InfiniteScroll
                      dataLength={
                        currentCount
                        // newHistoryEvents?.length ? newHistoryEvents?.length : 0
                      }
                      next={loadMoreData}
                      hasMore={newSuggestedEvents?.data ? true : false}
                    >
                      {newHistoryEvents.length > 0 ? (
                        <div className="row">
                          <div className="networks">
                            <div className="main-center">
                              <div className="wrapper">
                                <div className="events">
                                  <div className="row mt-20">
                                    {newHistoryEvents.map((data) => {
                                      return (
                                        <div className="col-lg-4 col-md-4 col-sm-6 col-xs-1">
                                          <div className="card">
                                            <div
                                              className="img-section"
                                              style={{ cursor: "pointer" }}
                                            >
                                              <div
                                                className="card-img"
                                                style={{
                                                  height: "100%",
                                                  width: "100%",
                                                }}
                                                onClick={() => {
                                                  readMore(data.id);
                                                }}
                                              >
                                                <img
                                                  src={data?.media}
                                                  className="event-card-image"
                                                  alt=""
                                                />
                                              </div>
                                              <div
                                                className={
                                                  data.event_type === "physical"
                                                    ? "tag tag-color-physical"
                                                    : "tag tag-color-virtual"
                                                }
                                              >
                                                {data.event_type === "physical"
                                                  ? "Physical"
                                                  : "Virtual"}
                                              </div>
                                              {data?.Pinned_Event?.status ? (
                                                <div
                                                  style={{
                                                    height: "40px",
                                                    width: "40px",
                                                  }}
                                                  className="unpin"
                                                  onClick={() => {
                                                    console.log("unpinned");
                                                    pinHandle(
                                                      data.id,
                                                      "pinFalse"
                                                    );
                                                  }}
                                                ></div>
                                              ) : (
                                                <div
                                                  className="pin"
                                                  onClick={() =>
                                                    pinHandle(
                                                      data.id,
                                                      "pinTrue"
                                                    )
                                                  }
                                                ></div>
                                              )}
                                            </div>
                                            <div className="mr-10 ml-10 ">
                                              <div className="header mt-30">
                                                <h4
                                                  className="card-event-control"
                                                  onClick={() => {
                                                    readMore(data.id);
                                                  }}
                                                >
                                                  {data.title}
                                                </h4>
                                              </div>
                                              <div className="date">
                                                <div className="calender-icon"></div>{" "}
                                                {moment(
                                                  data?.event_time
                                                ).format(
                                                  "ddd, MMM DD, YYYY"
                                                )}{" "}
                                                at{" "}
                                                {moment(
                                                  data?.event_time
                                                ).format("hh:mm A z")}
                                              </div>
                                              {data.location ? (
                                                <div className="location">
                                                  <div className="icon"></div>
                                                  <span className="card-control-text-oneline">
                                                    {data?.location
                                                      ?.split(",")
                                                      ?.splice(-1)}
                                                  </span>
                                                </div>
                                              ) : (
                                                <div className="location">
                                                  <div className="icon"></div>
                                                  <span className="card-control-text-oneline">
                                                    No Location Added
                                                  </span>
                                                </div>
                                              )}
                                              <div className="people-pic">
                                                <div className="d-flex">
                                                  <div className="profiles-img">
                                                    {data?.Event_Participant?.map(
                                                      (data, idx) => {
                                                        return (
                                                          idx < 3 && (
                                                            <span key={idx}>
                                                              <img
                                                                src={
                                                                  data?.user
                                                                    ?.profile_img
                                                                    ? data?.user
                                                                        ?.profile_img
                                                                    : placeholderUser
                                                                }
                                                                className="image-fit"
                                                                alt=""
                                                              />
                                                            </span>
                                                          )
                                                        );
                                                      }
                                                    )}
                                                    {data?.Event_Participant
                                                      ?.length > 3 ? (
                                                      <span>
                                                        +
                                                        {data?.Event_Participant
                                                          ?.length - 3}
                                                      </span>
                                                    ) : (
                                                      <span>
                                                        {
                                                          data
                                                            ?.Event_Participant
                                                            ?.length
                                                        }
                                                      </span>
                                                    )}
                                                  </div>
                                                </div>
                                                {data?.Event_Participant
                                                  ?.length > 1
                                                  ? "are"
                                                  : "is"}{" "}
                                                attending
                                              </div>
                                              <div className="d-flex">
                                                <div className="btn btn-light text-blue">
                                                  {" "}
                                                  Attended{" "}
                                                </div>
                                                <div
                                                  className="share-icon"
                                                  onClick={() => {
                                                    setOpenShareModal(
                                                      !openShareModal
                                                    );
                                                    setShareData(data);
                                                  }}
                                                ></div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      );
                                    })}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      ) : (
                        <div className="row ">
                          <p style={{ textAlign: "center" }} className="my-30">
                            <b>No history event </b>
                          </p>
                        </div>
                      )}
                    </InfiniteScroll>
                  </div>
                  <div id="my_events" className="container tab-pane fade">
                    <div className="networks">
                      <div className="main-center">
                        <div className="wrapper">
                          {/* //Events  */}
                          <div className="events mt-30">
                            <div className="row mb-6">
                              <div className="col-12">
                                <div
                                  className={
                                    tabs === "my_events"
                                      ? "btn blue-btn"
                                      : "btn border-btn"
                                  }
                                  onClick={() => setTabs("my_events")}
                                >
                                  Created Events
                                </div>
                                <div
                                  className={
                                    tabs === "my_drafts"
                                      ? "btn blue-btn"
                                      : "btn border-btn"
                                  }
                                  onClick={() => setTabs("my_drafts")}
                                >
                                  Draft
                                </div>
                              </div>
                            </div>
                            <InfiniteScroll
                              //  dataLength={newHistoryEvents?.length?newHistoryEvents?.length:0}
                              dataLength={
                                // tabs === "my_events"
                                //   ? newMyEvents?.length
                                //     ? newMyEvents?.length
                                //     : 0
                                //   : newMyDrafts?.length
                                //   ? newMyDrafts?.length
                                //   : 0
                                currentCount
                              }
                              next={loadMoreData}
                              hasMore={
                                (
                                  tabs === "my_events"
                                    ? newMyEvents?.data
                                    : newMyDrafts?.data
                                )
                                  ? true
                                  : false
                              }
                              // loader={<h4>Loading...</h4>}
                              // endMessage={
                              //   <p style={{ textAlign: "center" }}>
                              //     <b>Yay! You have seen it all</b>
                              //   </p>
                              // }
                            >
                              <div className="row mt-20">
                                {tabs === "my_events" ? (
                                  newMyEvents?.length !== 0 ? (
                                    newMyEvents.map((data) => {
                                      return (
                                        <div className="col-lg-4 col-md-4 col-sm-6 col-xs-1">
                                          <div className="card">
                                            <div
                                              className="img-section"
                                              onClick={() => {
                                                readMore(data.id);
                                              }}
                                              style={{ cursor: "pointer" }}
                                            >
                                              <div className="card-img">
                                                <img
                                                  src={data?.media}
                                                  className="event-card-image"
                                                  alt=""
                                                />
                                              </div>
                                              <div
                                                className={
                                                  data.event_type === "physical"
                                                    ? "tag tag-color-physical"
                                                    : "tag tag-color-virtual"
                                                }
                                              >
                                                {data.event_type === "physical"
                                                  ? "Physical"
                                                  : "Virtual"}
                                              </div>
                                            </div>
                                            <div className="mr-10 ml-10 ">
                                              <div className="header mt-30">
                                                <h4
                                                  className="card-event-control"
                                                  onClick={() => {
                                                    readMore(data.id);
                                                  }}
                                                >
                                                  {data.title}
                                                </h4>
                                              </div>
                                              <div className="date">
                                                <div className="calender-icon"></div>{" "}
                                                {moment(
                                                  data?.event_time
                                                ).format(
                                                  "ddd, MMM DD, YYYY"
                                                )}{" "}
                                                at{" "}
                                                {moment(
                                                  data?.event_time
                                                ).format("hh:mm A z")}
                                              </div>
                                              {data.location ? (
                                                <div className="location">
                                                  <div className="icon"></div>
                                                  <span className="card-control-text-oneline">
                                                    {data?.location
                                                      ?.split(",")
                                                      ?.splice(-1)}
                                                  </span>
                                                </div>
                                              ) : (
                                                <div className="location">
                                                  <div className="icon"></div>
                                                  <span className="card-control-text-oneline">
                                                    No Location Added
                                                  </span>
                                                </div>
                                              )}
                                              <div className="people-pic">
                                                <div className="d-flex">
                                                  <div className="profiles-img">
                                                    {data?.Event_Participant?.map(
                                                      (data, idx) => {
                                                        return (
                                                          <span key={idx}>
                                                            <img
                                                              src={
                                                                data?.user
                                                                  ?.profile_img
                                                                  ? data?.user
                                                                      ?.profile_img
                                                                  : placeholderUser
                                                              }
                                                              className="image-fit"
                                                              alt=""
                                                            />
                                                          </span>
                                                        );
                                                      }
                                                    )}
                                                    {data?.Event_Participant
                                                      ?.length > 3 ? (
                                                      <span>
                                                        +
                                                        {data?.Event_Participant
                                                          ?.length - 3}
                                                      </span>
                                                    ) : (
                                                      <span>
                                                        {
                                                          data
                                                            ?.Event_Participant
                                                            ?.length
                                                        }
                                                      </span>
                                                    )}
                                                  </div>
                                                </div>
                                                {data?.Event_Participant
                                                  ?.length > 1
                                                  ? "are"
                                                  : "is"}{" "}
                                                attending
                                              </div>
                                              <div className="d-flex">
                                                <div
                                                  className="btn btn-light text-blue"
                                                  onClick={() => {
                                                    setOpenCreateModal(
                                                      !openCreateModal
                                                    );
                                                    setEditEventData(data);
                                                  }}
                                                >
                                                  <img
                                                    style={{
                                                      marginRight: "10px",
                                                    }}
                                                    src={EditEventIcon}
                                                    alt="Description of the image"
                                                  />
                                                  Edit
                                                </div>
                                                <div
                                                  className="share-icon"
                                                  onClick={() => {
                                                    setOpenShareModal(
                                                      !openShareModal
                                                    );
                                                    setShareData(data);
                                                  }}
                                                ></div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      );
                                    })
                                  ) : (
                                    <div className="row ">
                                      <p
                                        style={{ textAlign: "center" }}
                                        className="my-30"
                                      >
                                        <b> No event found</b>
                                      </p>
                                    </div>
                                  )
                                ) : newMyDrafts?.length !== 0 ? (
                                  newMyDrafts?.map((data) => {
                                    return (
                                      <div className="col-lg-4 col-md-4 col-sm-6 col-xs-1">
                                        <div className="card">
                                          <div
                                            className="img-section"
                                            onClick={() => {
                                              readMore(data.id);
                                            }}
                                            style={{ cursor: "pointer" }}
                                          >
                                            <div className="card-img">
                                              <img
                                                src={data?.media}
                                                className="event-card-image"
                                                alt=""
                                              />
                                            </div>
                                            <div
                                              className={
                                                data.event_type === "physical"
                                                  ? "tag tag-color-physical"
                                                  : "tag tag-color-virtual"
                                              }
                                            >
                                              {data.event_type === "physical"
                                                ? "Physical"
                                                : "Virtual"}
                                            </div>
                                          </div>
                                          <div className="mr-10 ml-10 ">
                                            <div className="header mt-30">
                                              <h4
                                                className="card-event-control"
                                                onClick={() => {
                                                  readMore(data.id);
                                                }}
                                              >
                                                {data.title}
                                              </h4>
                                            </div>
                                            <div className="date">
                                              <div className="calender-icon"></div>{" "}
                                              {data?.event_time === null ? (
                                                "No Date Selected"
                                              ) : (
                                                <>
                                                  {moment(
                                                    data?.event_time
                                                  ).format(
                                                    "ddd, MMM DD, YYYY"
                                                  )}{" "}
                                                  at{" "}
                                                  {moment(
                                                    data?.event_time
                                                  ).format("hh:mm A z")}
                                                </>
                                              )}
                                            </div>
                                            {data.location ? (
                                              <div className="location">
                                                <div className="icon"></div>
                                                <span className="card-control-text-oneline">
                                                  {data?.location
                                                    ?.split(",")
                                                    ?.splice(-1)}
                                                </span>
                                              </div>
                                            ) : (
                                              <div className="location">
                                                <div className="icon"></div>
                                                <span className="card-control-text-oneline">
                                                  No location added
                                                </span>
                                              </div>
                                            )}
                                            <div className="people-pic">
                                              <div className="d-flex">
                                                <div className="profiles-img">
                                                  {data?.Event_Participant?.map(
                                                    (data, idx) => {
                                                      return (
                                                        <span key={idx}>
                                                          <img
                                                            src={
                                                              data?.user
                                                                ?.profile_img
                                                                ? data?.user
                                                                    ?.profile_img
                                                                : placeholderUser
                                                            }
                                                            className="image-fit"
                                                            alt=""
                                                          />
                                                        </span>
                                                      );
                                                    }
                                                  )}
                                                  {data?.Event_Participant
                                                    ?.length > 3 ? (
                                                    <span>
                                                      +
                                                      {data?.Event_Participant
                                                        ?.length - 3}
                                                    </span>
                                                  ) : (
                                                    <span>
                                                      {
                                                        data?.Event_Participant
                                                          ?.length
                                                      }
                                                    </span>
                                                  )}
                                                </div>
                                              </div>

                                              <div>attending</div>
                                            </div>
                                            <div className="d-flex">
                                              <div
                                                className="btn btn-light text-blue"
                                                onClick={() => {
                                                  setOpenCreateModal(
                                                    !openCreateModal
                                                  );
                                                  setEditEventData(data);
                                                }}
                                              >
                                                <img
                                                  style={{
                                                    marginRight: "10px",
                                                  }}
                                                  src={EditEventIcon}
                                                  alt="Description of the image"
                                                />
                                                Edit and Post
                                              </div>
                                              <div
                                                className="share-icon"
                                                onClick={() => {
                                                  setOpenShareModal(
                                                    !openShareModal
                                                  );
                                                  setShareData(data);
                                                }}
                                              ></div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    );
                                  })
                                ) : (
                                  <div className="row ">
                                    <p
                                      style={{ textAlign: "center" }}
                                      className="my-30"
                                    >
                                      <b>No event found</b>
                                    </p>
                                  </div>
                                )}
                              </div>
                            </InfiniteScroll>
                          </div>
                          {/* //Events  */}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <ToastContainer />
        </div>

        {openCreateModal && (
          <CreateEventModal
            modal={openCreateModal}
            toggle={() => setOpenCreateModal(!openCreateModal)}
            title="Create Event"
            successfulStatus={successfulStatus}
            editEventData={editEventData}
            setEditEventData={setEditEventData}
            source="network"
            setSuccessModal={setSuccessModal}
          />
        )}
        {openShareModal && (
          <ShareModal
            modal={openShareModal}
            toggle={() => setOpenShareModal(!openShareModal)}
            title="Share"
            shareData={shareData}
            type="event"
            toggleSuccessful={() => setOpenShareModal(!openShareModal)}
          />
        )}
        {successModal && (
          <EventSuccessfulModal
            modal={successModal}
            toggle={() => setSuccessModal(!successModal)}
            title=""
            shareData={popupShareData}
          />
        )}
      </Layout>
    </>
  );
}
