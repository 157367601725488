import React, { useState, useEffect } from "react";
import { Input, Modal, ModalBody, ModalHeader } from "reactstrap";
import { useDispatch, useSelector } from "react-redux";
import { addhonordetails } from "../../../../store/profile/profileActions";
import moment from "moment";
import YearPickerCustom from "../../../../core/components/DatePicker/YearPickerReact";
import { getIndustryData } from "../../../../store/master/masterActions";
import Dropdown from "../../../../core/components/DropDown/Dropdown";

const AddHonorsAwards = (props) => {
  const { modal, toggle, editHonorsAwardsData, setEditHonorsAwardsData } =
    props;
  const [errorName, setErrorName] = useState("");
  const [errorIndustry, setErrorIndustry] = useState("");
  const [errorDate, setErrorDate] = useState("");

  const { isWebView } = useSelector((state) => state.auth);
  const { industryData } = useSelector((state) => state.master);

  const [expData, setExpData] = useState(
    editHonorsAwardsData
      ? {
          id: editHonorsAwardsData.id,
          name: editHonorsAwardsData.name,
          industry: editHonorsAwardsData?.Industry?.length
            ? editHonorsAwardsData?.Industry[0]?.id
            : null,
          year: editHonorsAwardsData.year,
        }
      : {
          name: "",
          industry: "",
          year: "",
        }
  );

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getIndustryData());
  }, []);

  const checkSignUpValidation = (type) => {
    let formIsValid = true;

    if (type === "all" || type === "name") {
      if (!expData.name.trim()) {
        setErrorName("This field is mandatory.");
        formIsValid = false;
      } else {
        setErrorName("");
      }
    }
    if (type === "all" || type === "industry") {
      if (!expData.industry) {
        setErrorIndustry("This field is mandatory.");
        formIsValid = false;
      } else {
        setErrorIndustry("");
      }
    }

    if (type === "all" || type === "date") {
      if (!expData.year) {
        setErrorDate("This field is mandatory.");
        formIsValid = false;
      } else {
        setErrorDate("");
      }
    }

    return formIsValid;
  };

  const handleSubmit = () => {
    if (checkSignUpValidation("all")) {
      let expDataformat = {
        honors_and_awards: [expData],
      };
      var token = localStorage.getItem("accessToken");
      dispatch(addhonordetails(expDataformat, token))
        .then(() => {
          if (editHonorsAwardsData) {
            setEditHonorsAwardsData();
          }
          toggle();
        })
        .catch((e) => {
          console.log(e);
          alert(e.response.data.message);
        });
    }
  };

  const CloseModal = () => {
    if (editHonorsAwardsData) {
      setEditHonorsAwardsData();
    }
    toggle();
  };

  const SelectedTitle = (id, data) => {
    let dropdown = [];
    data?.map((item) => {
      dropdown.push({
        value: item?.name,
        id: item?.id,
      });
    });
    return dropdown.find((item) => item.id === id)?.value;
  };

  return (
    <div>
      <Modal isOpen={modal} size="lg" className="fix-width" centered>
        <div className="popup">
          <div className="add-expriece">
            <ModalHeader toggle={CloseModal}>
              {editHonorsAwardsData
                ? "Edit Honors or Awards"
                : "Add Honors or Awards"}
            </ModalHeader>
            <ModalBody className="m-0 px-16 py-0 mb-4">
              <form>
                <div className="title">
                  <label>Name</label>
                  <Input
                    type="text"
                    placeholder=""
                    onClick={(e) => setErrorName("")}
                    value={expData.name}
                    maxLength="50"
                    onChange={(e) =>
                      setExpData({ ...expData, name: e.target.value })
                    }
                  />
                  {errorName && (
                    <label className="label-input100 text-danger">
                      {errorName}
                    </label>
                  )}
                </div>
                <div className="title">
                  <div className="row">
                    <div className="col-md-6 col-12">
                      <label htmlFor="">Industry</label>
                      {/* {isWebView ?
                        <Dropdown
                          label='Select'
                          data={industryData.map((item) => ({
                            value: item.name,
                            id: item.id
                          }))}
                          onClick={(e) => setErrorIndustry("")}
                          dynamicPlaceHolder={SelectedTitle(expData.industry, industryData)}
                          dropdownValue='degree'
                          handleSelect={(value) => setExpData({ ...expData, industry: value.id })}
                        />
                        : */}
                      <Input
                        placeholder=""
                        name="select"
                        type="select"
                        onClick={(e) => setErrorIndustry("")}
                        value={expData.industry}
                        onChange={(e) =>
                          setExpData({ ...expData, industry: e.target.value })
                        }
                      >
                        <option value="">Please Select</option>
                        {industryData?.map((item, index) => {
                          return <option value={item.id}>{item.name}</option>;
                        })}
                      </Input>
                      {/* } */}
                      {errorIndustry && (
                        <label className="label-input100 text-danger">
                          {errorIndustry}
                        </label>
                      )}
                    </div>
                    <div className="col-md-6 col-12">
                      <label htmlFor="">Year</label>
                      {/* {isWebView ? <>
                        <Input
                          value={expData.year}
                          // type="month"
                          onChange={(e) =>
                            setExpData({ ...expData, year: moment(e.target.value).utc().format("YYYY") })
                          }
                          type="number" 
                          placeholder="YYYY" 
                          min="1970" 
                          max="2100"
                        />
                        <span className="calender"></span>
                      </>
                        : */}
                      <YearPickerCustom
                        value={expData.year ? new Date(expData.year) : ""}
                        onChange={(date) => {
                          date ? (
                            setExpData({
                              ...expData,
                              year: moment(date).format("YYYY"),
                            })
                          ) : (
                            <></>
                          );
                        }}
                        placeholder="Pick Year"
                        onClick={() => setErrorDate("")}
                        max={new Date()}
                      />
                      {/* } */}
                      {errorDate && (
                        <label className="label-input100 text-danger">
                          {errorDate}
                        </label>
                      )}
                    </div>
                  </div>
                </div>
                <div className="text-center">
                  <div
                    className={
                      isWebView ? "btnWebView btn-blue" : "btn btn-blue"
                    }
                    style={{ marginTop: "15px" }}
                    onClick={handleSubmit}
                  >
                    Save
                  </div>
                </div>
              </form>
            </ModalBody>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default AddHonorsAwards;
