import React, { useState } from 'react'
import Footer from "../../core/components/layout/footer/Footer";
import gold from "../../assets/img/gold.svg";

import "./MembershipPlan.scss"
import Navbar from '../../pages/navbar/navbar';
import { updateUserDetails } from '../../store/profile/profileActions';
import { updateCurrentPlan } from '../../store/auth/authActions';
import { useSelector, useDispatch } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { toast } from "react-toastify";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
const MembershipPlan = () => {
    const { isLoggedIn } = useSelector((state) => state.auth);
    const navigate = useNavigate()
    const token = localStorage.getItem("accessToken")
    const [isDone, setIsDone] = useState(false)
    const { state } = useLocation();
    const dispatch = useDispatch()

    const handleBuyPlan = () => {
        setIsDone(!isDone)
        if (state.isCurrent) {
            let membershipId
            if (state.membershipPlanName == "Nobel") {
                membershipId = 2
            } else if (state.membershipPlanName == "Scout") {
                membershipId = 4
            } else {
                membershipId = 3
            }

            let toAddDuration
            if (state.duration == "year") {
                toAddDuration = 12
            } else {
                toAddDuration = 1
            }

            let newStart 
            if(!state.start){
                newStart = new Date()
                newStart = newStart.toISOString()?.slice(0, 19)?.replace('T', ' ');
            }else{
                newStart = state.start
            }

            const convertDate = new Date(newStart)
            const newExpiry = new Date(convertDate.setMonth(convertDate.getMonth() + toAddDuration))
            const expiryDate = newExpiry.toISOString()?.slice(0, 19)?.replace('T', ' ');

            const data = {
                membership_id: membershipId,
                membership_expiry: expiryDate,
                membership_start: newStart,
                is_membership_active: true
            }

            const membershipData = {
                membership_expiry: expiryDate,
                membership_start: newStart,
                is_membership_active: true,
                membership : state.membershipData
            }
            dispatch(updateCurrentPlan(data, membershipData, token)).then((res) => {
                if(res.success === true){
                    toast.success("Membership Plan Upgraded", { position: toast.POSITION.BOTTOM_LEFT})
                    setTimeout(() => {
                        setIsDone(!isDone)
                        navigate('/setting')
                    }, 3000)
                }
            })
            .catch((error) => {
                toast.success("Something went wrong Please try again later", { position: toast.POSITION.BOTTOM_LEFT})
                setIsDone(!isDone)
            })
        }else{
            alert("You are not suppose to buy when you changing the cycle or downgrading.")
            navigate('/dashboard')
        }
    }
    return (
        <div>
            {isLoggedIn && <Navbar isJobs={false} />}
            <div className="billing-information">
                <div className="container">
                    <div className="row mb-4">
                        <div className="col-lg-8 my-3 billing-box ">
                            <div className="box">
                                <h4>Billing Information</h4>
                                <form>
                                    <div className="row">
                                        <div className="col-12 col-sm-6">
                                            <div class="mb-3">
                                                <label for="exampleInputEmail1" className="form-label">First Name</label>
                                                <input type="text" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" />
                                            </div>
                                        </div>
                                        <div className="col-12 col-sm-6">
                                            <div class="mb-3">
                                                <label for="exampleInputPassword1" className="form-label">Last Name</label>
                                                <input type="text" class="form-control" id="exampleInputPassword1" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-12 col-sm-6">
                                            <div class="mb-3">
                                                <label for="exampleInputEmail1" className="form-label">Country</label>
                                                <input type="text" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" />
                                            </div>
                                        </div>
                                        <div className="col-12 col-sm-6">
                                            <div class="mb-3">
                                                <label for="exampleInputPassword1" className="form-label">State/Region</label>
                                                <input type="text" class="form-control" id="exampleInputPassword1" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-12 col-sm-6">
                                            <div class="mb-3">
                                                <label for="exampleInputEmail1" className="form-label">City</label>
                                                <input type="text" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" />
                                            </div>
                                        </div>
                                        <div className="col-12 col-sm-6">
                                            <div class="mb-3">
                                                <label for="exampleInputPassword1" className="form-label">Zip Code</label>
                                                <input type="text" class="form-control" id="exampleInputPassword1" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-12">
                                            <div class="mb-3">
                                                <label className='form-label'>Phone Number</label>
                                                <select class="form-select" aria-label="Default select example">
                                                    <option selected>Open this select menu</option>
                                                    <option value="1">One</option>
                                                    <option value="2">Two</option>
                                                    <option value="3">Three</option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-12">
                                            <div class="mb-3">
                                                <label for="exampleInputPassword1" className="form-label">Billing Address</label>
                                                <textarea class="form-control" id="exampleFormControlTextarea1" rows="3"></textarea>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-12">
                                            <div className="mb-3">
                                                <label for="exampleInputPassword1" className="form-label">Biling Email</label>
                                                <input type="text" className="form-control" id="exampleInputPassword1" />
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                        <div className="col-lg-4 my-3 right-price">
                            <form>
                                <div className="mb-3">
                                    <div className="row">
                                        <div className="col-lg-8">
                                            <label className="form-label">Do you have promo code?</label>
                                            <input type="text" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" />
                                            <div class="form-text">Applied promo code</div>
                                        </div>
                                        <div className="col-lg-4 mt-4">
                                            <button type="submit" class="btn btn-light text-blue">Submit</button>
                                        </div>
                                    </div>

                                </div>
                            </form>
                            <table className='table membe'>
                                <tr>
                                    <td><img src={gold} /> {state.membershipPlanName} Membership</td>
                                    <td className='text-end'>$ 454.00</td>
                                </tr>
                                <p>This membership valid until 22/03/2043</p>
                            </table>
                            <table className='table membe'>

                                <tr>
                                    <td className='pt-2'>Tax(18%)</td>
                                    <td className='pt-2 text-end'>$65.00</td>
                                </tr>
                                <tr>
                                    <td className='pt-2'>Discount</td>
                                    <td className='pt-2 text-end'>-$21.00</td>
                                </tr>
                            </table>
                            <div className="line"></div>
                            <table className='table mt-3'>
                                <tr>
                                    <td><strong>Total</strong></td>
                                    <td className='text-end'><strong>$ 500.00</strong> / Year</td>
                                </tr>
                            </table>
                            <p className='membe'>By clicking "Confirm and Pay", you agree to NobelPage's Legal Terms including Terms of Use, Privacy Policy, Cookie Policy and Community Guidelines</p>

                            <div className="text-blue">
                                Terms and Conditions & Privacy Policy
                            </div>
                            <button className='btn btn-blue w-100 mt-5' onClick={() => handleBuyPlan()} disabled={isDone}>Confirm and Pay</button>
                        </div>
                    </div>

                    <ToastContainer />
                    <Footer />
                </div>
            </div>
        </div>
    )
}

export default MembershipPlan