import React from "react";
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import checkMark from "../../../../assets/img/check-mark.png";
import { useEffect } from "react";

const SmartSuccessModal = (props) => {
  const { modal, toggle, setSmartInterviewModal } = props;
  useEffect(() => {
    setSmartInterviewModal(false);
  }, []);

  return (
    <Modal
      isOpen={modal}
      toggle={toggle}
      size="md"
      style={{ maxWidth: "544px" }}
      centered
    >
      <div isOpen className="modal-application-pop-up default-recruiter-modal">
        <ModalBody>
          <div className="text-center">
            <img src={checkMark} alt="Icon" />
            <p className="text-dark fw-bold ">
              Smart Interview was sent succesfully
            </p>
          </div>
        </ModalBody>
        <ModalFooter className="text-center">
          <div className="btn btn-blue m-auto mt-3" onClick={() => toggle()}>
            Close
          </div>
        </ModalFooter>
      </div>
    </Modal>
  );
};

export default SmartSuccessModal;
