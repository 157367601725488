import React, { useEffect, useState } from "react";
import "./network.scss";
import Layout from "./Layout/Layout";
import arrow from "../../assets/img/arrow.svg";
import AcceptIcon from "../../assets/img/AcceptIcon.svg";
import defaultGroupIcon from "../../assets/img/default_group_icon.svg";
import placeholderUser from "../../assets/img/placeholder_user.jpg";
import tick from "../../assets/img/tick-icon.svg";
import unfollow from "../../assets/img/realCross.png";
import add from "../../assets/img/add-icon.svg";
import { useDispatch, useSelector } from "react-redux";
import { loadUser } from "../../store/auth/authActions";
import { getSuggestedPeopleList } from "../../store/people/peopleActions";
import { getSuggestedGroups } from "../../store/groups/groupsActions";
import union from "../../assets/img/Union-blue.svg";
import join from "../../assets/img/join.png";

import nopreview from "../../assets/img/no-preview.png";
import pagePlaceholder from "../../assets/img/page-placeholder.svg";
import {
  getSuggestedEvents,
  attendEvents,
  pinnedEvents,
} from "../../store/events/eventActions";
import { getSuggestedNewsletters } from "../../store/newsletter/newsletterActions";
import { acceptConnectionRequest } from "../../store/people/peopleActions";
import { getpeopleList } from "../../store/people/peopleActions";
import { joinGroups } from "../../store/groups/groupsActions";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import DOMPurify from "dompurify";
import Carousel from "react-multi-carousel";
import {
  followPage,
  getAllPages,
  unFollowPage,
} from "../../store/pages/pagesAction";
import {
  getHashtagData,
  followHashtag,
} from "../../store/hashtag/hashtagActions";
import {
  subscribeNewsletter,
  unsubscribeNewsletter,
} from "../../store/newsletter/newsletterActions";
import ShareModal from "../../core/components/sharePost/sharePost";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";
import MutualConnectionModal from "../../core/components/modal/mutualConnection";
// import { sendConnectionRequest } from "../../../store/connections/connectionsActions.js";
import { sendConnectionRequest } from "../../store/connections/connectionsActions";

export default function MyNetWork() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const token = localStorage.getItem("accessToken");

  const { suggested_groups } = useSelector((state) => state.groups);
  const { suggested_events } = useSelector((state) => state.events);
  const { suggested_articles } = useSelector((state) => state.article);
  const { suggested_newsletters } = useSelector((state) => state.newsletter);
  const { suggested_people } = useSelector((state) => state.people);
  // const { my_connections } = useSelector((state) => state.my_connections);

  const { suggestedTagList } = useSelector((state) => state.hashtag);
  const { pending_invitations } = useSelector((state) => state.people);
  const { pageData } = useSelector((state) => state.pages);
  const [openShareModal, setOpenShareModal] = useState(false);
  const [shareData, setShareData] = useState("");
  const [mutualConnectionModal, setMutualConnectionModal] = useState(false);
  const [userMutualData, setUserMutualData] = useState("");

  useEffect(() => {
    // dispatch(loadUser(null));
    dispatch(getAllPages({ page: 1, size: 3 }));
  }, []);

  useEffect(() => {
    let status = "suggested";
    dispatch(getSuggestedPeopleList({ type: "suggested", limit: 5 }));
  }, []);

  useEffect(() => {
    dispatch(getSuggestedGroups());
  }, []);

  useEffect(() => {
    let query = "suggested";
    dispatch(getSuggestedEvents(token, { type: query }));
  }, []);

  useEffect(() => {
    dispatch(getSuggestedNewsletters(null, null, { type: "suggested" }));
  }, []);

  useEffect(() => {
    dispatch(
      getpeopleList("pending", "received", {
        status: "pending",
        type: "receive",
      })
    );
  }, []);

  useEffect(() => {
    let query = "suggested";
    let currentCount = 0;
    dispatch(getHashtagData(query, currentCount));
  }, []);

  function createMarkup(html) {
    return {
      __html: DOMPurify.sanitize(html),
    };
  }

  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 4,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 3,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };

  console.log(suggested_people, "hhh");

  const sendRequest = (id) => {
    dispatch(sendConnectionRequest(id));
  };

  // const CustomRight = ({ onClick }) => (
  //   <button className="arrow right" onClick={onClick} style={{position:"absolute"}}>
  //  <div className="custom-arrow">
  //   <div className="arrows">
  //     <div className="arrow">
  //       <div className="right" id="slide-arrow-prev">
  //         <img src={arrow} alt=""/>
  //         </div>
  //         </div>
  //         </div>
  //         </div>
  //   </button>
  // );

  const CustomLeft = ({ onClick }) => (
    <button
      className="arrow right"
      onClick={onClick}
      style={{ position: "absolute" }}
    >
      <div className="custom-arrow">
        <div className="arrows">
          <div className="arrow mr-20">
            <div className="left" id="slide-arrow-next">
              <img src={arrow} alt="" />
            </div>
          </div>
        </div>
      </div>
    </button>
  );

  const acceptRequest = (id, request_type) => {
    dispatch(acceptConnectionRequest(id, request_type));
  };

  const joinHandle = (id, type) => {
    dispatch(joinGroups(id, type));
  };

  //pages
  const handleFollow = (pageID, is_following) => {
    if (is_following) {
      dispatch(unFollowPage(pageID));
    } else {
      dispatch(followPage(pageID));
    }
  };
  const navigateToPageAdmin = (id) => {
    navigate(`/pageadminview/${id}`);
  };

  const follow_hashtag = (hashtagId, hashtagName, type) => {
    dispatch(followHashtag(hashtagId, hashtagName, type, "suggested_tags"));
  };

  const subscribeHandle = (id) => {
    dispatch(subscribeNewsletter(id, token));
  };

  const UnsubscribeHandle = (id) => {
    dispatch(unsubscribeNewsletter(id, token));
  };

  const attendHandle = (id, type) => {
    dispatch(attendEvents(id, token, type));
  };

  const pinHandle = (id, type) => {
    dispatch(pinnedEvents(id, token, type));
  };

  return (
    <Layout>
      <div className="col-lg-9">
        <div className="networks mt-4">
          <div className="main-center">
            <div className="wrapper p-20">
              <div className="row mb-3">
                <div className="col-6">
                  <h4>Invitations</h4>
                </div>
                <div className="col-6">
                  <div className="float-end">
                    <div
                      className="view"
                      onClick={() => navigate("/invitations")}
                    >
                      View all
                    </div>
                  </div>
                </div>
              </div>
              {console.log(pending_invitations)}
              {pending_invitations == undefined && (
                <p className="text-center mb-2">
                  <b>No new invitations have been received at this time</b>{" "}
                </p>
              )}
              <div className="line"></div>
              {pending_invitations?.map((data, index) => {
                if (index < 3) {
                  return (
                    <div
                      className="row mt-30 mb-30"
                      style={{ alignItems: "center", wordWrap: "break-word" }}
                      key={index}
                    >
                      <div className="col-lg-1 col-md-12 mb-2">
                        <div
                          className="profile-img underlineOnHover"
                          onClick={() => navigate(`/profile/${data?.user_id}`)}
                        >
                          <img
                            src={
                              data?.profile_img
                                ? data?.profile_img
                                : placeholderUser
                            }
                            className="image-fit"
                            alt=""
                          />
                        </div>
                      </div>
                      <div className="col-lg-6 col-md-12 mb-3 pl-20">
                        <div className="details ps-3 ">
                          <h2
                            className="card-control-text-oneline mt-1 underlineOnHover"
                            onClick={() =>
                              navigate(`/profile/${data?.user_id}`)
                            }
                          >
                            {data?.first_name} {data?.last_name}
                          </h2>
                          <p className="card-control-text-oneline mt-1 underlineOnHover">
                            {data?.profile?.profile_headline ? (
                              data?.profile?.profile_headline
                            ) : (
                              <></>
                            )}
                          </p>
                          {data?.profile?.state?.name ? (
                            <p>
                              {data?.profile?.state?.name},{" "}
                              {data?.profile?.country?.name}
                            </p>
                          ) : (
                            data?.profile?.country?.name
                          )}
                          <p>
                            {data?.shared_connection?.length > 0 ? (
                              <>
                                <div className="people-icon"></div>
                                <span>
                                  {" "}
                                  {data?.shared_connection[0]?.first_name}{" "}
                                  {data?.shared_connection[0]?.last_name} is a
                                  shared connection
                                </span>
                              </>
                            ) : (
                              <></>
                            )}
                          </p>
                        </div>
                      </div>
                      <div className="col-lg-5 col-md-12  ">
                        <div className="d-flex float-xl-end">
                          <div
                            className="btn text-blue"
                            onClick={() => acceptRequest(data?.id, "reject")}
                          >
                            Ignore
                          </div>
                          <div
                            className="btn btn-light"
                            onClick={() => acceptRequest(data?.id, "confirm")}
                          >
                            <img
                              src={AcceptIcon}
                              style={{
                                marginRight: "5px",
                                paddingBottom: "2px",
                              }}
                              alt=""
                            />
                            Accept
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                }
              })}

              <div className="suggestion mt-30">
                <div className="row mb-3">
                  <div className="col-6">
                    <h4>Suggested People</h4>
                  </div>
                  <div className="col-6">
                    <div className="float-end">
                      <div
                        className="view"
                        onClick={() => navigate("/peoples")}
                      >
                        View all
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="slider-box-network">
                    <Carousel
                      responsive={responsive}
                      showDots={false}
                      slidesToSlide={1}
                      containerClass="container-with-dots"
                      itemClass="carousel-item-padding-20-px"
                      deviceType={""}
                      centerMode={true}
                      // customRightArrow={<CustomRight />}
                      // customLeftArrow={<CustomLeft />}
                    >
                      {suggested_people?.map((data) => {
                        return (
                          <div className="card people-card-height">
                            <div className="rounded-img">
                              <div
                                className="profile-img"
                                onClick={() => navigate(`/profile/${data?.id}`)}
                              >
                                <img
                                  src={
                                    data?.profile_img
                                      ? data?.profile_img
                                      : placeholderUser
                                  }
                                  className="image-fit"
                                  alt=""
                                />
                              </div>
                            </div>
                            <h3
                              className="card-control-text-oneline mt-1 underlineOnHover"
                              onClick={() => navigate(`/profile/${data?.id}`)}
                            >
                              {data?.first_name} {data?.last_name}
                            </h3>
                            {data?.badge == "hiring" && (
                              <div className="hiring">Hiring</div>
                            )}
                            {data?.badge == "seeking" && (
                              <div className="seeking">Seeking</div>
                            )}
                            <div className="designation">
                              {data?.profile?.profile_headline ? (
                                data?.profile?.profile_headline
                              ) : (
                                <></>
                              )}
                            </div>
                            <div
                              className="connections"
                              onClick={() => {
                                setMutualConnectionModal(
                                  !mutualConnectionModal
                                );
                                setUserMutualData(data);
                              }}
                            >
                              {data?.shared_connection?.length}
                              {data?.shared_connection?.length > 1
                                ? " mutual connections"
                                : " mutual connection"}
                            </div>
                            {/* <div
                              className="btn btn-light text-blue"
                              onClick={() => {
                                alert(
                                  "This feature will be implemented in the future milestone"
                                );
                              }}
                            >
                              Message
                            </div>  */}

                            {data?.connection_status === "Pending" ? (
                              <div className="btn btn-light text-blue btn-margin-bottom">
                                <span>Pending</span>
                              </div>
                            ) : (
                              <div
                                className="btn btn-light text-blue btn-margin-bottom"
                                onClick={() => sendRequest(data?.id)}
                              >
                                <img src={union} alt="" /> Connect
                              </div>
                            )}
                          </div>
                        );
                      })}
                    </Carousel>
                  </div>
                </div>
              </div>
              <div className="groups mt-30">
                <div className="row mb-3">
                  <div className="col-6">
                    <h4>Groups</h4>
                  </div>
                  <div className="col-6">
                    <div className="float-end">
                      <div className="view" onClick={() => navigate("/groups")}>
                        View all
                      </div>
                    </div>
                  </div>
                </div>
                <div className="line"></div>
                <div className="row mt-20">
                  {suggested_groups.map((data, index) => {
                    if (index < 3) {
                      return (
                        <div className="col-lg-4" key={index}>
                          <div className="groups-card">
                            <div className="card card-height-groups">
                              <div className="card-img">
                                {data?.background_img ? (
                                  <img
                                    src={data?.background_img}
                                    className="img-fluid"
                                    alt=""
                                  />
                                ) : (
                                  <></>
                                )}
                                <div className="circle-icon">
                                  <img
                                    src={
                                      data?.icon ? data.icon : defaultGroupIcon
                                    }
                                    className="circle-icon-group-size"
                                    alt=""
                                  />
                                </div>
                              </div>
                              <div className="mr-20 ml-20 mb-20">
                                <div className="header">
                                  <h4
                                    onClick={() =>
                                      navigate(`/groupdetails/${data.id}`)
                                    }
                                  >
                                    {data?.name}
                                  </h4>
                                  <div className="text">
                                    <div className="d-flex align-items-center justify-content-center">
                                      {data?.type === "public" ? (
                                        <>
                                          <div className="globe"></div>
                                          <p>Public group</p>
                                        </>
                                      ) : (
                                        <>
                                          <div className="privateIcon"></div>
                                          <p>Private group</p>
                                        </>
                                      )}
                                    </div>
                                  </div>
                                  <p className="group-head-p">
                                    <span>{data?.members?.length}</span>
                                    {data?.members?.length > 1
                                      ? "Members"
                                      : "Member"}
                                    &nbsp;&nbsp;&nbsp;
                                    <span>{data?.post_count}</span>
                                    Post / day
                                  </p>
                                </div>
                                <div className="text">
                                  <p className="card-control-text-fourline">
                                    {data?.description}
                                  </p>
                                  <div className="link card-control-text-oneline">
                                    {data?.hashtags?.map((data, idx) => {
                                      return (
                                        <span key={idx}>
                                          #{data.name} &nbsp;
                                        </span>
                                      );
                                    })}
                                  </div>
                                </div>
                                {data?.members?.length ===
                                data?.members_limit ? (
                                  <div className="btn btn-light disabled-btn-blue btn-margin-bottom">
                                    Join
                                  </div>
                                ) : data?.is_joined === "pending" ? (
                                  <div
                                    className="btn btn-light btn-margin-bottom"
                                    onClick={() => {
                                      alert(
                                        "Please wait for group admin response"
                                      );
                                    }}
                                  >
                                    Requested{" "}
                                  </div>
                                ) : (
                                  <div
                                    className="btn btn-light btn-margin-bottom"
                                    onClick={() =>
                                      joinHandle(data.id, "joinTrue")
                                    }
                                  >
                                    <div>
                                      <img
                                        style={{ marginRight: "9px" }}
                                        src={join}
                                      />
                                      Join
                                    </div>
                                  </div>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      );
                    }
                  })}
                </div>
              </div>
              {/* //Events  */}
              <div className="events mt-30">
                <div className="row mb-3">
                  <div className="col-6">
                    <h4>Events</h4>
                  </div>
                  <div className="col-6">
                    <div className="float-end">
                      <div className="view" onClick={() => navigate(`/event`)}>
                        View all
                      </div>
                    </div>
                  </div>
                </div>
                <div className="line"></div>
                <div className="row mt-20">
                  {suggested_events.map((data, index) => {
                    if (index < 3) {
                      return (
                        <div className="col-lg-4" key={index}>
                          <div className="card">
                            <div className="img-section">
                              {data?.media ? (
                                <img
                                  src={data?.media}
                                  className="img-fluid"
                                  alt=""
                                />
                              ) : (
                                <></>
                              )}

                              <div
                                className={
                                  data.event_type === "physical"
                                    ? "tag tag-color-physical"
                                    : "tag tag-color-virtual"
                                }
                              >
                                {data.event_type === "physical"
                                  ? "Physical"
                                  : "Virtual"}
                              </div>
                              {data?.Pinned_Event?.status ? (
                                <div
                                  className="unpin"
                                  onClick={() => pinHandle(data.id, "pinFalse")}
                                ></div>
                              ) : (
                                <div
                                  className="pin"
                                  onClick={() => pinHandle(data.id, "pinTrue")}
                                ></div>
                              )}
                            </div>
                            <div className="mr-10 ml-10 ">
                              <div className="header mt-30">
                                <h4
                                  className="card-event-control"
                                  onClick={() =>
                                    navigate(`/eventdetail/${data.id}`)
                                  }
                                >
                                  {data.title}
                                </h4>
                              </div>
                              <div className="date">
                                <div className="calender-icon"></div>{" "}
                                {moment(data?.event_time).format(
                                  "ddd, MMM DD, YYYY"
                                )}{" "}
                                at{" "}
                                {moment(data?.event_time).format("hh:mm A z")}
                              </div>
                              <div className="location">
                                <div className="icon"></div>
                                <span className="card-control-text-oneline">
                                  {data?.location?.split(",")?.splice(-1)}
                                </span>
                              </div>
                              <div className="people-pic">
                                <div className="d-flex">
                                  <div className="profiles-img">
                                    {data?.Event_Participant?.map(
                                      (data, idx) => {
                                        return (
                                          idx < 3 && (
                                            <span key={idx}>
                                              <img
                                                src={
                                                  data?.user?.profile_img
                                                    ? data?.user?.profile_img
                                                    : placeholderUser
                                                }
                                                className="image-fit"
                                                alt=""
                                              />
                                            </span>
                                          )
                                        );
                                      }
                                    )}
                                    {data?.Event_Participant?.length > 3 ? (
                                      <span>
                                        +{data?.Event_Participant?.length}
                                      </span>
                                    ) : (
                                      <span>
                                        +{data?.Event_Participant?.length}
                                      </span>
                                    )}
                                  </div>
                                </div>

                                <div> attended</div>
                              </div>
                              <div className="d-flex">
                                {data.Attend_Event?.status ? (
                                  <div
                                    className="btn btn-light text-blue"
                                    onClick={() =>
                                      attendHandle(data.id, "attendFalse")
                                    }
                                  >
                                    {" "}
                                    Leave Event{" "}
                                  </div>
                                ) : (
                                  <div
                                    className="btn btn-blue"
                                    onClick={() =>
                                      attendHandle(data.id, "attendTrue")
                                    }
                                  >
                                    {" "}
                                    Attend{" "}
                                  </div>
                                )}
                                <div
                                  className="share-icon"
                                  onClick={() => {
                                    setOpenShareModal(!openShareModal);
                                    setShareData(data);
                                  }}
                                ></div>
                              </div>
                            </div>
                          </div>
                        </div>
                      );
                    }
                  })}
                </div>
              </div>
              {/* //Events  */}

              {/* pages  */}
              <div className="groups mt-30">
                <div className="row mb-3">
                  <div className="col-6">
                    <h4>Pages</h4>
                  </div>
                  <div className="col-6">
                    <div className="float-end">
                      <div className="view" onClick={() => navigate("/pages")}>
                        View all
                      </div>
                    </div>
                  </div>
                </div>
                <div className="line"></div>
                <div className="row mt-20">
                  <div className="pages">
                    <div className="groups-card ">
                      <div className="row">
                        {pageData ? (
                          pageData?.data?.map((data, index) => {
                            return (
                              <div key={index} className="col-lg-4">
                                <div className="card card-height-page ">
                                  <div
                                    className="card-img"
                                    style={{ height: "138px" }}
                                  >
                                    {data?.background_img ? (
                                      <img
                                        src={data?.background_img}
                                        className="img-fluid"
                                        alt=""
                                      />
                                    ) : (
                                      <></>
                                    )}
                                    {/* <img
                                      src={
                                        data.background_img
                                          ? data.background_img
                                          : nopreview
                                      }
                                      className="img-fluid"
                                      alt=""
                                    /> */}
                                    <div className="circle-icon">
                                      <img
                                        src={
                                          data?.icon
                                            ? data?.icon
                                            : pagePlaceholder
                                        }
                                        className="pageicon"
                                        alt=""
                                      />
                                    </div>
                                  </div>
                                  <div className="ml-20 mb-20 mr-20">
                                    <div className="header">
                                      <h4
                                        className="card-event-control"
                                        onClick={() =>
                                          navigateToPageAdmin(data.id)
                                        }
                                      >
                                        {data.name}
                                      </h4>
                                      <div className="text">
                                        <div className="d-flex align-items-center justify-content-center">
                                          <div className="groupicon"></div>
                                          <p>
                                            {data.followers_count}{" "}
                                            {data.followers_count > 1
                                              ? "followers"
                                              : "follower"}
                                          </p>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="text card-page-text-control mb-20">
                                      <p>{data.description}</p>
                                      {data.hashtags && (
                                        <div className="link">
                                          {data.hashtags.map(
                                            (hastag, index) => "#" + hastag
                                          )}
                                        </div>
                                      )}
                                    </div>

                                    <div
                                      className="btn btn-light btn-margin-bottom"
                                      onClick={() => {
                                        handleFollow(
                                          data.id,
                                          data.is_following
                                        );
                                      }}
                                    >
                                      {data.is_following ? (
                                        <span>
                                          <img
                                            src={unfollow}
                                            alt=""
                                            className="followicon"
                                            width={15}
                                          />{" "}
                                          Unfollow
                                        </span>
                                      ) : (
                                        <span>
                                          <img
                                            src={add}
                                            alt=""
                                            className="followicon"
                                          />{" "}
                                          Follow
                                        </span>
                                      )}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            );
                          })
                        ) : (
                          <></>
                        )}

                        {/* <div className="col-lg-4">
                                                    <div className="card">
                                                        <div className="card-img">
                                                            <img
                                                                src="https://img.freepik.com/free-photo/pleasant-looking-serious-man-stands-profile-has-confident-expression-wears-casual-white-t-shirt_273609-16959.jpg"
                                                                className="img-fluid"
                                                                alt=""
                                                            />
                                                            <div className="circle-icon"><img src={ellipse} /></div>
                                                        </div>
                                                        <div className="ml-20 mb-20 mr-20">

                                                            <div className="header">
                                                                <h4>Bone Thugs-C-Harmony</h4>
                                                                <div className="text"><div className="d-flex align-items-center justify-content-center"><div className="globe"></div><p>Public group</p></div></div>
                                                                <p><span>563</span>Members <span>30</span>Post / day</p>
                                                            </div>
                                                            <div className="text">
                                                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc vulputate libero et velit interdum, ac aliquet odio mattis.</p>
                                                                <div className="link">#group #mebers #public</div>
                                                            </div>
                                                            <div className="btn btn-light"><span>+</span> Follow</div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-4">
                                                    <div className="card">
                                                        <div className="card-img">
                                                            <img
                                                                src="https://img.freepik.com/free-photo/pleasant-looking-serious-man-stands-profile-has-confident-expression-wears-casual-white-t-shirt_273609-16959.jpg"
                                                                className="img-fluid"
                                                                alt=""
                                                            />
                                                            <div className="circle-icon"><img src={ellipsee} /></div>
                                                        </div>
                                                        <div className="ml-20 mb-20 mr-20">

                                                            <div className="header">
                                                                <h4>Bone Thugs-C-Harmony</h4>
                                                                <div className="text"><div className="d-flex align-items-center justify-content-center"><div className="globe"></div><p>Public group</p></div></div>
                                                                <p><span>563</span>Members <span>30</span>Post / day</p>
                                                            </div>
                                                            <div className="text">
                                                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc vulputate libero et velit interdum, ac aliquet odio mattis.</p>
                                                                <div className="link">#group #mebers #public</div>
                                                            </div>
                                                            <div className="btn btn-light"><span>+</span> Follow</div>
                                                        </div>
                                                    </div>
                                                </div> */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* pages  */}

              {/* Newsletters  */}
              <div className="groups mt-30">
                <div className="row mb-3">
                  <div className="col-6">
                    <h4>Newsletters</h4>
                  </div>
                  <div className="col-6">
                    <div className="float-end">
                      <div
                        className="view"
                        onClick={() => navigate(`/newsletter`)}
                      >
                        View all
                      </div>
                    </div>
                  </div>
                </div>
                <div className="line"></div>
                <div className="row mt-20">
                  {suggested_newsletters.map((data, index) => {
                    if (index < 2) {
                      return (
                        <div className="col-lg-6 mt-2" key={index}>
                          <div className="card overflow-hidden card-height-article">
                            <div className="img card-image-square">
                              {data?.media ? (
                                <img
                                  src={data?.media}
                                  alt=""
                                  className="card-image-square"
                                />
                              ) : (
                                <></>
                              )}
                            </div>
                            <div className="card-header">
                              <div className="d-flex align-items-center gap-2">
                                <div className="profile-img">
                                  <img
                                    src={
                                      data?.user?.profile_img
                                        ? data?.user?.profile_img
                                        : placeholderUser
                                    }
                                    alt=""
                                    className="image-fit"
                                    style={{ width: "36px" }}
                                    onClick={() =>
                                      navigate(
                                        `/profile/${data?.user?.user_id}`
                                      )
                                    }
                                  />
                                </div>
                                <span
                                  className="name card-control-text-oneline"
                                  onClick={() =>
                                    navigate(`/profile/${data?.user?.user_id}`)
                                  }
                                >
                                  {data?.user?.first_name}{" "}
                                  {data?.user?.last_name}
                                </span>
                              </div>
                              <div className="date">
                                <div className="d-flex">
                                  <span className="calender-icon"></span>
                                  <div className="days ml-5">
                                    {" "}
                                    <span>
                                      {moment(data?.createdAt).format(
                                        "ddd, MMM DD, YYYY"
                                      )}{" "}
                                      at{" "}
                                      {moment(data?.createdAt).format(
                                        "hh:mm A z"
                                      )}
                                    </span>
                                  </div>
                                </div>
                                {data?.subscribed?.status ? (
                                  <div
                                    className="subscribe"
                                    onClick={() => {
                                      UnsubscribeHandle(data?.id);
                                    }}
                                  >
                                    Unsubscribe
                                  </div>
                                ) : (
                                  <div
                                    className="subscribe"
                                    onClick={() => {
                                      subscribeHandle(data?.id);
                                    }}
                                  >
                                    Subscribe
                                  </div>
                                )}
                              </div>
                              <h3 className="card-control-text-twoline">
                                {data?.title}
                              </h3>
                            </div>
                            <div className="card-body">
                              <p>
                                {data?.content && (
                                  <div
                                    className="card-control-text-twoline"
                                    dangerouslySetInnerHTML={createMarkup(
                                      data?.content
                                    )}
                                  ></div>
                                )}
                              </p>
                              <div
                                className="read"
                                onClick={() =>
                                  navigate(`/newsletterdetail/${data.id}`)
                                }
                              >
                                Read more
                              </div>
                            </div>
                          </div>
                        </div>
                      );
                    }
                  })}
                </div>
              </div>
              {/* Newsletters  */}

              {/* //Article  */}
              <div className="groups mt-30">
                <div className="row mb-3">
                  <div className="col-6">
                    <h4>Articles</h4>
                  </div>
                  <div className="col-6">
                    <div className="float-end">
                      <div
                        className="view"
                        onClick={() => navigate(`/articles`)}
                      >
                        View all
                      </div>
                    </div>
                  </div>
                </div>
                <div className="line"></div>
                <div className="row mt-20">
                  {suggested_articles.map((data, index) => {
                    if (index < 2) {
                      return (
                        <div className="col-lg-6 mt-2" key={index}>
                          <div className="card overflow-hidden card-height-article">
                            <div className="img card-image-square">
                              {data?.media ? (
                                <img
                                  src={data?.media}
                                  alt=""
                                  className="card-image-square"
                                />
                              ) : (
                                <></>
                              )}
                            </div>
                            <div className="card-header">
                              <div className="d-flex align-items-center gap-2">
                                <div
                                  className="profile-img"
                                  onClick={() =>
                                    navigate(`/profile/${data?.user?.user_id}`)
                                  }
                                >
                                  <img
                                    src={
                                      data?.user?.profile_img
                                        ? data?.user?.profile_img
                                        : placeholderUser
                                    }
                                    alt=""
                                    className="image-fit"
                                    style={{ width: "36px" }}
                                  />
                                </div>
                                <span
                                  className="name card-control-text-oneline"
                                  onClick={() =>
                                    navigate(`/profile/${data?.user?.user_id}`)
                                  }
                                >
                                  {data?.user?.first_name}{" "}
                                  {data?.user?.last_name}
                                </span>
                              </div>
                              <div className="date">
                                <div className="d-flex">
                                  <span className="calender-icon"></span>
                                  <div className="days ml-5">
                                    {" "}
                                    <span>
                                      {moment(data?.createdAt).format(
                                        "ddd, MMM DD, YYYY"
                                      )}{" "}
                                      at{" "}
                                      {moment(data?.createdAt).format(
                                        "hh:mm A z"
                                      )}
                                    </span>
                                  </div>
                                </div>
                              </div>
                              <h3 className="card-control-text-twoline">
                                {data?.title}
                              </h3>
                            </div>
                            <div className="card-body">
                              <p>
                                {data?.content && (
                                  <div
                                    className="card-control-text-twoline"
                                    dangerouslySetInnerHTML={createMarkup(
                                      data?.content
                                    )}
                                  ></div>
                                )}
                              </p>
                              <div
                                className="read"
                                onClick={() =>
                                  navigate(`/articledetail/${data.id}`)
                                }
                              >
                                Read article
                              </div>
                            </div>
                          </div>
                        </div>
                      );
                    }
                  })}
                </div>
              </div>
              {/* //Article  */}

              {/* Hashtags  */}
              <div className="hashtag mt-30">
                <div className="row mb-3">
                  <div className="col-6">
                    <h4>Hashtags</h4>
                  </div>
                  <div className="col-6">
                    <div className="float-end">
                      <div
                        className="view"
                        onClick={() => navigate(`/hashtag`)}
                      >
                        View all
                      </div>
                    </div>
                  </div>
                </div>
                <div className="line"></div>
                <div className="row mt-20">
                  {suggestedTagList?.map((data, index) => {
                    if (index < 3) {
                      return (
                        <div className="col-lg-4" key={index}>
                          <div className="card">
                            <div className="mt-30 mr-20 ml-20 mb-20">
                              <div className="d-flex gap-3 mb-20">
                                <div className="hash"></div>
                                <div className="header">
                                  <h4>{data?.name}</h4>
                                  <p>
                                    {data?.post_count}{" "}
                                    {data?.post_count > 1 ? "posts" : "post"}
                                  </p>
                                </div>
                              </div>
                              {data?.follow_type === undefined ? (
                                <div
                                  className="btn btn-light text-blue"
                                  onClick={() =>
                                    follow_hashtag(
                                      data?.id,
                                      data?.name,
                                      "follow"
                                    )
                                  }
                                >
                                  <span className="mr-10">
                                    <img src={add} alt="" />
                                  </span>
                                  Follow
                                </div>
                              ) : (
                                <div
                                  className="btn btn-light text-blue"
                                  disabled
                                  // onClick={() =>
                                  //   follow_hashtag(
                                  //     data?.id,
                                  //     data?.name,
                                  //     "unfollow"
                                  //   )
                                  // }
                                >
                                  Following
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                      );
                    }
                  })}
                </div>
              </div>
              {/* Hashtags  */}
            </div>
          </div>
        </div>
        <ToastContainer />
      </div>
      {openShareModal && (
        <ShareModal
          modal={openShareModal}
          toggle={() => setOpenShareModal(!openShareModal)}
          title="Share"
          shareData={shareData}
          type="event"
          toggleSuccessful={() => setOpenShareModal(!openShareModal)}
        />
      )}

      {mutualConnectionModal && (
        <MutualConnectionModal
          modal={mutualConnectionModal}
          toggle={() => setMutualConnectionModal(!mutualConnectionModal)}
          listData={userMutualData}
        />
      )}
    </Layout>
  );
}
