import React, { useEffect, useState } from "react";
import { Dropdown, FormGroup, Input, Label } from "reactstrap";
import JoinUsSection from "../../../core/components/Sections/JoinUsSection/JoinUsSection";
import uploadBlack from "../../../assets/img/uploadBlack.svg";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import DocsImg from "../../../assets/img/docs.svg";
import {
  updateUserDetails,
  uploadResume,
} from "../../../store/profile/profileActions";
import { useDropzone } from "react-dropzone";
import deleteIcon from "../../../assets/img/deleteIcon.svg";
import drop from "../../../assets/img/gallery-add.png";
import {
  initPageLoad,
  stopPageLoad,
} from "../../../store/loader/loaderActions";
import AppLoader from "../../../core/components/Loaders/appLoader/appLoader";
import { Link } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";

const UploadResume = (props) => {
  const { activePage, setActivePage, activeTab, setActiveTab, profileData } =
    props;
  const { isPageLoading } = useSelector((state) => state.loader);
  const { talentPoolData } = useSelector((state) => state.portal);

  const dispatch = useDispatch();
  let token = localStorage.getItem("accessTpToken");
  const [data, setData] = useState({
    resume_name: profileData?.resume_name || "",
    resume_url: profileData?.resume_url || "",
  });
  useEffect(() => {
    setData({
      resume_name: profileData?.resume_name || "",
      resume_url: profileData?.resume_url || "",
    });
  }, [profileData]);
  // const handleBannerPicture = async (file, type) => {
  //   console.log(file, "file");
  //   const newImage = new File([file], "Resume");
  //   dispatch(initPageLoad());
  //   try {
  //     const UPLOAD_URL = "";
  //     const formData = new FormData();
  //     const headers = {
  //       "Accept-Language": "en",
  //       "content-type": "multipart/form-data",
  //     };

  //     formData.append("image", newImage);

  //     await axios.post(UPLOAD_URL, formData, { headers }).then((res) => {
  //       if (res?.data?.success) {
  //         setData({
  //           ...data,
  //           resume_name: `Resume_${Math.random() * 9999}.pdf`,
  //           resume_url: res?.data?.urlsArray[0],
  //         });
  //         toast.success("File uploaded successfully", {
  //           position: "bottom-left",
  //         });
  //       }
  //       dispatch(stopPageLoad());
  //     });
  //   } catch (err) {
  //     toast.error(err?.response?.data?.message, {
  //       position: toast.POSITION.BOTTOM_LEFT,
  //     });
  //     dispatch(stopPageLoad());
  //   }
  // };

  const handleBannerPicture = async (file, type) => {
    console.log(file, "file");
    // Create a new File object with a name for the file
    const newImage = new File([file], "Resume.pdf"); // Assuming file is already in PDF format
    dispatch(initPageLoad());
    try {
      const UPLOAD_URL = `${process.env.REACT_APP_API_URL}api/v1/upload`;
      const formData = new FormData();
      const headers = {
        "Accept-Language": "en",
        // "content-type" should be "Content-Type"
        "Content-Type": "multipart/form-data",
      };

      formData.append("image", newImage);

      // Use await instead of then to handle the response
      const res = await axios.post(UPLOAD_URL, formData, { headers });
      if (res?.data?.success) {
        setData({
          ...data,
          // Generate a random file name for the resume
          resume_name: `Resume_${Math.floor(Math.random() * 10000)}.pdf`,
          resume_url: res?.data?.urlsArray[0],
        });
        toast.success("File uploaded successfully", {
          position: "bottom-left",
        });
      }
      dispatch(stopPageLoad());
    } catch (err) {
      // Use toast.POSITION.BOTTOM_LEFT instead of toast.POSITION.bottom-left
      toast.error(err?.response?.data?.message, {
        position: toast.POSITION.BOTTOM_LEFT,
      });
      dispatch(stopPageLoad());
    }
  };

  const [files, setFiles] = useState([]);

  const { getRootProps, getInputProps, isDragActive, acceptedFiles } =
    useDropzone({
      multiple: false,
      accept: {
        ".pdf": [],
      },
      onDrop: (acceptedFiles) => {
        setFiles(
          acceptedFiles.map((file) =>
            Object.assign(file, {
              preview: URL.createObjectURL(file),
            })
          )
        );
        acceptedFiles.map((file) => {
          handleBannerPicture(file);
        });
      },
      onDropRejected: (fileRejected) => {
        toast.error("Please upload only file types PDF");
      },
    });

  const handleSubmit = (e) => {
    if (data?.resume_url) {
      dispatch(initPageLoad());
      dispatch(updateUserDetails(data, token))
        .then((res) => {
          if (res?.success) {
            setActivePage("intro");
            toast.success("Resume saved successfully", {
              position: "bottom-left",
            });
          }
          dispatch(stopPageLoad());
        })
        .catch((err) => {
          setActivePage("intro");
          dispatch(stopPageLoad());
        });
    } else {
      toast.error("Please select resume first", {
        position: "bottom-left",
      });
    }
  };

  const thumbs = files.map((file) => (
    <div key={file.name}>
      <div>
        <img
          src={file.preview}
          alt=""
          onLoad={() => {
            URL.revokeObjectURL(file.preview);
          }}
        />
      </div>
    </div>
  ));
  return (
    <>
      {isPageLoading && <AppLoader />}
      <div className="upload-resume-section">
        <div className="edit-form">
          <h3>Upload Resume</h3>
          <p>Please upload your updated resume/CV in PDF format.</p>
          <p>Note: Only pdf are allowed</p>

          <form>
            <div {...getRootProps({ className: "dropzone" })}>
              <input className="input-zone" {...getInputProps()} />
              <div className="image-upload ">
                <div className="drag-box">
                  {isDragActive ? (
                    <p className="dropzone-content">
                      Release to drop the files here
                    </p>
                  ) : (
                    <>
                      <img src={uploadBlack} alt="Icon" />
                      <div className="pt-2">
                        Drag and drop or{" "}
                        <span className="blue-txt">
                          <u>browse</u>
                        </span>{" "}
                        files
                      </div>
                    </>
                  )}
                </div>
              </div>
            </div>
          </form>
          {console.log(data)}
          {data?.resume_name && (
            <div className="d-flex justify-content-between align-items-center mt-3">
              <div className="">
                <img src={DocsImg} />
                <span className="mx-2">{data?.resume_name}</span>
              </div>
              <div className="">
                <Link
                  to={`${data?.resume_url}`}
                  target="_blank"
                  style={{ color: `${talentPoolData?.theme}` }}
                >
                  View
                </Link>
              </div>
            </div>
          )}
        </div>

        <JoinUsSection />
        <div className="save-btn-outer">
          <button
            className="save-btn"
            onClick={(e) => handleSubmit(e)}
            style={{ background: `${talentPoolData?.theme}` }}
          >
            Save
          </button>
        </div>
      </div>
      <ToastContainer />
    </>
  );
};

export default UploadResume;
