import React, { useState, useEffect } from "react";
import Invitations from "./Invitations";
import { useNavigate } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { getpeopleList } from "../../../store/people/peopleActions";
import { acceptConnectionRequest } from "../../../store/people/peopleActions";

export default function InvitationsContainer() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [tabs, setTabs] = useState("received");
  const [searchKey, setSearchKey] = useState("");
  const [filter, setFilter] = useState("all")

  const { pending_invitations } = useSelector((state) => state.people);
  const { sent_invitations_pending } = useSelector((state) => state.people);

  useEffect(() => {
    if (tabs === "received") {
      if(searchKey==="")
      {dispatch(
        getpeopleList("pending", tabs, { status: "pending", type: "receive", sort:filter }))
      }
    } else if (tabs === "sent") {
        if(searchKey==="")
        {
      dispatch(
        getpeopleList("pending", tabs, { status: "pending", type: "sent", sort:filter })
      );
        }
    }
  }, [tabs,searchKey, filter]);

  const acceptRequest = (id, request_type) => {
    dispatch(acceptConnectionRequest(id, request_type));
  };

  const searchInvitation = (searchKey) => {
    if (tabs === "received") {
      dispatch(
        getpeopleList("pending", tabs, {
          status: "pending",
          search: searchKey,
          type: "receive",
        })
      );
    } else if (tabs === "sent") {
      dispatch(
        getpeopleList("pending", tabs, {
          status: "pending",
          search: searchKey,
          type: "sent",
        })
      ).then((res) => {
        console.log("res", res);
      });
    }
  };

  return (
    <Invitations
      tabs={tabs}
      setTabs={setTabs}
      navigate={navigate}
      pending_invitations={pending_invitations}
      sent_invitations_pending={sent_invitations_pending}
      acceptRequest={acceptRequest}
      searchKey={searchKey}
      setSearchKey={setSearchKey}
      searchInvitation={searchInvitation}
      filter={filter}
      setFilter={setFilter}
    />
  );
}
