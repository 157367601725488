import { helpApi } from "../../api/helpApiCall";
import {
  FAQ_LIST_FAIL,
  FAQ_LIST_SUCCESS,
  FAQ_SEARCH_BY_ID_SUCCESS,
  FAQ_SEARCH_SUCCESS,
  HELP_FAQ_FEEDBACK,
  HELP_SUPPORT_CONTACT,
  HELP_SUPPORT_CONTACT_FAIL,
} from "../types";

export const postSupportData = (suportData) => (dispatch) => {
  return helpApi.contactUS(suportData).then(
    (data) => {
      dispatch({
        type: HELP_SUPPORT_CONTACT,
        payload: suportData,
      });

      return Promise.resolve(data);
    },
    (error) => {
      console.error(error);
      dispatch({
        type: HELP_SUPPORT_CONTACT_FAIL,
      });
      alert(error.response.data.message);
      return Promise.reject(error);
    }
  );
};

export const getFAQData = (data, type) => (dispatch) => {
  return helpApi.faqsSearch({ [type]: data }).then(
    (data) => {
      dispatch({
        type: FAQ_LIST_SUCCESS,
        payload: data,
      });

      return Promise.resolve(data);
    },
    (error) => {
      console.log(error);
      dispatch({
        type: FAQ_LIST_FAIL,
      });
      return Promise.reject(error);
    }
  );
};

export const getFAQSearch = (params) => (dispatch) => {
  return helpApi.faqsSearch(params).then(
    (data) => {
      if (params?.id) {
        dispatch({
          type: FAQ_SEARCH_BY_ID_SUCCESS,
          payload: data,
        });
      } else {
        dispatch({
          type: FAQ_SEARCH_SUCCESS,
          payload: data,
        });
      }
      return Promise.resolve(data);
    },
    (error) => {
      console.log(error);
      dispatch({
        type: FAQ_LIST_FAIL,
      });
      return Promise.reject(error);
    }
  );
};

export const sendFAQFeedback = (data) => (dispatch) => {
  return helpApi.faqFeedback(data).then(
    (data) => {
      dispatch({
        type: HELP_FAQ_FEEDBACK,
        payload: data,
      });

      return Promise.resolve(data);
    },
    (error) => {
      console.error(error);
      return Promise.reject(error);
    }
  );
};
