import React, { useState, useEffect } from "react";
import LeadsManage from "./LeadsManage";
import { useDispatch, useSelector } from "react-redux";
import {
  getLeads,
  addLeadGroup,
  editLeadData,
} from "../../store/leads/leadsActions";
import { deleteLeadData } from "../../store/leads/leadsActions";
import { ExportToExcel } from "./components/ExportData/ExportToExcel";
import { getLocationsData } from "../../store/connections/connectionsActions";
import { leadConnectionStatus } from "../../store/leads/leadsActions";
import { toast } from "react-toastify";

export default function LeadsManageContainer() {
  const dispatch = useDispatch();

  const { leadsList } = useSelector((state) => state.leads);

  const [actionModal, setActionModal] = useState(false);
  const [statusModal, setStatusModal] = useState(false);
  const [leadUserData, setLeadUserData] = useState(null);
  const [groupName, setGroupName] = useState("");
  const [deleteModal, setDeleteModal] = useState(false);
  const [sortFilter, setSortFilter] = useState("ascending");
  const [statusFilter, setStatusFilter] = useState();
  const [groupFilter, setGroupFilter] = useState();
  const [searchFilter, setSearchFilter] = useState();
  const [locationsAPIData, setLocationsAPIData] = useState([]);
  const [searchFieldValue, setSearchFieldValue] = useState("");
  const [locationFilterParams, setLocationFilterParams] = useState({});
  const [emailEditId, setEmailEditId] = useState();
  const [emailEdit, setEmailEdit] = useState();
  const [phoneEditId, setPhoneEditId] = useState();
  const [phoneEdit, setPhoneEdit] = useState();
  const [errorGroupName, setErrorGroupName] = useState("");
  const [errorEmail, setErrorEmail] = useState("");
  const [errorPhone, setErrorPhone] = useState("");
  const [currentIdx, setCurrentIdx] = useState(null);
  const [editName, setEditName] = useState("");
  const { isLoggedIn } = useSelector((state) => state.auth);

  const getLeadsList = () => {
    dispatch(
      getLeads(
        groupFilter
          ? {
              limit: 20,
              skip: 0,
              sort: sortFilter || undefined,
              status: statusFilter || undefined,
              group: groupFilter || undefined,
              search: searchFilter || undefined,
              location_type: locationFilterParams.location_type || undefined,
              location_id: locationFilterParams.location_id || undefined,
            }
          : {
              limit: 20,
              skip: 0,
              sort: sortFilter || undefined,
              status: statusFilter || undefined,
              search: searchFilter || undefined,
              location_type: locationFilterParams.location_type || undefined,
              location_id: locationFilterParams.location_id || undefined,
            }
      )
    ).then((res) => {});
  };

  useEffect(() => {
    getLeadsList();
  }, [
    sortFilter,
    statusFilter,
    groupFilter,
    searchFilter,
    locationFilterParams,
  ]);

  const addGroup = () => {
    let newData = [...leadsList.group_list, groupName];
    if (groupName) {
      dispatch(addLeadGroup({ leads_group: newData }, groupName)).then(() => {
        toast.success("Group Added Successfully!", {
          position: toast.POSITION.BOTTOM_LEFT,
        });
      });
      setGroupName("");
    } else setErrorGroupName("Add Group Name!");
    // alert("Add Group Name!");
  };

  const deleteGroup = (index, data) => {
    console.log(data, "groupName");
    const currentData = [...leadsList.group_list];
    const removedData = currentData.splice(index, 1);
    dispatch(
      addLeadGroup({ leads_group: currentData }, removedData[0], "delete")
    ).then(() => {
      toast.success(
        `Group Deleted Successfully!
        Leads assigned to ${data} group are moved to General`,
        {
          position: toast.POSITION.BOTTOM_LEFT,
        }
      );
    });
  };

  const editGroup = (idx, currentName) => {
    setCurrentIdx(idx);
    setEditName(currentName);
  };

  const cancelEdit = () => {
    setCurrentIdx(null);
    setEditName("");
  };

  const confirmEdit = () => {
    if (!editName) {
      toast.error("Group name can not be empty", {
        position: toast.POSITION.BOTTOM_LEFT,
      });
    } else {
      const currentData = [...leadsList.group_list];
      const removedData = currentData.splice(currentIdx, 1, editName);
      dispatch(
        addLeadGroup(
          { leads_group: currentData },
          { oldName: removedData[0], newName: editName },
          "edit"
        )
      ).then(() => {
        toast.success("Group Edited Successfully!", {
          position: toast.POSITION.BOTTOM_LEFT,
        });
        setCurrentIdx(null);
        setEditName("");
      });
    }
  };

  const checkValidation = (data, type) => {
    let emailvalid =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    let formIsValid = true;
    if (type === "email") {
      if (!data) {
        setErrorEmail("Please enter email");
        formIsValid = false;
      } else if (!emailvalid.test(data)) {
        setErrorEmail("Please enter valid email");
        formIsValid = false;
      } else {
        setErrorEmail("");
      }
    } else if (type === "phone") {
      if (!data) {
        setErrorPhone("Please enter phone");
        formIsValid = false;
      } else if (data.length < 6 || data.length > 16) {
        setErrorPhone("Please enter valid phone");
        formIsValid = false;
      } else {
        setErrorPhone("");
      }
    }
    return formIsValid;
  };

  const editLead = (data, id, type) => {
    if (type === "group") {
      let newData = {
        group: data,
      };
      dispatch(editLeadData(newData, id, type)).then(() => {});
    } else if (type === "email") {
      let newData = {
        email: data,
      };
      if (checkValidation(data, type)) {
        dispatch(editLeadData(newData, id, type))
          .then(() => {
            toast.success("Saved Successfully!", {
              position: toast.POSITION.BOTTOM_LEFT,
            });
            setEmailEditId(null);
          })
          .catch((e) => {
            toast.error("Something went wrong!", {
              position: toast.POSITION.BOTTOM_LEFT,
            });
          });
      }
    } else if (type === "phone") {
      let newData = {
        phone: data,
      };
      if (checkValidation(data, type)) {
        dispatch(editLeadData(newData, id, type))
          .then(() => {
            toast.success("Saved Successfully!", {
              position: toast.POSITION.BOTTOM_LEFT,
            });
            setPhoneEditId(null);
          })
          .catch((e) => {
            toast.error("Something went wrong!", {
              position: toast.POSITION.BOTTOM_LEFT,
            });
          });
      }
    }
  };

  const deleteLead = (id) => {
    dispatch(deleteLeadData(id)).then(() => {
      toast.success("Lead Deleted Successfully!", {
        position: toast.POSITION.BOTTOM_LEFT,
      });
    });
  };

  const getLocations = ({ value }) => {
    if (value)
      dispatch(getLocationsData(value))
        .then((response) => {
          if (response.data) {
            setLocationsAPIData(response.data);
          } else setLocationsAPIData([]);
        })
        .catch((err) => {
          // console.log("error!!", err);
          setLocationsAPIData([]);
        });
    else setLocationsAPIData([]);
  };

  const sendConnectionHandle = (id) => {
    dispatch(leadConnectionStatus(id))
      .then((res) => {
        toast.success("connection request sent successfuly", {
          position: toast.POSITION.BOTTOM_LEFT,
        });
      })
      .catch((e) => {
        toast.success(e.response.data.message, {
          position: toast.POSITION.BOTTOM_LEFT,
        });
      });
  };

  return (
    <LeadsManage
      actionModal={actionModal}
      setActionModal={setActionModal}
      leadsList={leadsList}
      addGroup={addGroup}
      groupName={groupName}
      setGroupName={setGroupName}
      statusModal={statusModal}
      setStatusModal={setStatusModal}
      leadUserData={leadUserData}
      setLeadUserData={setLeadUserData}
      editLead={editLead}
      deleteLead={deleteLead}
      deleteModal={deleteModal}
      setDeleteModal={setDeleteModal}
      sortFilter={sortFilter}
      setSortFilter={setSortFilter}
      statusFilter={statusFilter}
      setStatusFilter={setStatusFilter}
      groupFilter={groupFilter}
      setGroupFilter={setGroupFilter}
      searchFilter={searchFilter}
      setSearchFilter={setSearchFilter}
      ExportToExcel={ExportToExcel}
      locationsAPIData={locationsAPIData}
      getLocations={getLocations}
      searchFieldValue={searchFieldValue}
      setSearchFieldValue={setSearchFieldValue}
      locationFilterParams={locationFilterParams}
      setLocationFilterParams={setLocationFilterParams}
      emailEditId={emailEditId}
      setEmailEditId={setEmailEditId}
      emailEdit={emailEdit}
      setEmailEdit={setEmailEdit}
      phoneEdit={phoneEdit}
      setPhoneEdit={setPhoneEdit}
      phoneEditId={phoneEditId}
      setPhoneEditId={setPhoneEditId}
      sendConnectionHandle={sendConnectionHandle}
      errorGroupName={errorGroupName}
      setErrorGroupName={setErrorGroupName}
      errorEmail={errorEmail}
      isLoggedIn={isLoggedIn}
      errorPhone={errorPhone}
      deleteGroup={deleteGroup}
      editGroup={editGroup}
      editName={editName}
      setEditName={setEditName}
      currentIdx={currentIdx}
      cancelEdit={cancelEdit}
      confirmEdit={confirmEdit}
    />
  );
}
