import {
  FETCH_RESUME_PDF_REQUEST,
  FETCH_RESUME_PDF_SUCCESS,
  FETCH_RESUME_PDF_FAILURE,
  FETCH_ALL_RESUME_TYPES_PDF_REQUEST,
  FETCH_ALL_RESUME_TYPES_PDF_SUCCESS,
  FETCH_ALL_RESUME_TYPES_PDF_FAILURE,
  UPDATE_BASIC_INFO_IN_CV,
  ADD_WORK_EXPERIENCE_IN_CV,
  EDIT_WORK_EXPERIENCE_IN_CV,
  ADD_EDUCATION_IN_CV,
  EDIT_HONORS_IN_CV,
  ADD_HONORS_IN_CV,
  EDIT_EDUCATION_IN_CV,
  ADD_LANGUAGE_IN_CV,
  EDIT_LANGUAGE_IN_CV,
  ADD_SKILLS_IN_CV,
  EDIT_SKILLS_IN_CV,
  DELETE_LANGUAGE_IN_CV,
  DELETE_HONORS_IN_CV,
  DELETE_EDUCATION_IN_CV,
  DELETE_WORK_EXPERIENCE_IN_CV,
  FILL_INITIAL_DATA_IN_CV,
} from "../types";
import { pdfAPI } from "../../api/pdfAPI";
import { toast } from "react-toastify";

export const fetchCvPdf = (type, data) => (dispatch) => {
  dispatch({ type: FETCH_RESUME_PDF_REQUEST });

  return pdfAPI.fetchCvPdf(type, data).then(
    (response) => {
      dispatch({
        type: FETCH_RESUME_PDF_SUCCESS,
        payload: response?.data,
      });
      return Promise.resolve(response);
    },
    (error) => {
      console.log(error);
      toast.error(error.response.data.message, {
        position: toast.POSITION.BOTTOM_LEFT,
      });
      dispatch({
        type: FETCH_RESUME_PDF_FAILURE,
        payload: error,
      });
      return Promise.reject(error);
    }
  );
};

export const fetchAllCvTypesPdf = () => (dispatch) => {
  dispatch({ type: FETCH_ALL_RESUME_TYPES_PDF_REQUEST });

  return pdfAPI.fetchAllCvTypesPdf().then(
    (response) => {
      dispatch({
        type: FETCH_ALL_RESUME_TYPES_PDF_SUCCESS,
        payload: response?.data,
      });
      return Promise.resolve(response);
    },
    (error) => {
      console.log(error);
      toast.error(error.response.data.message, {
        position: toast.POSITION.BOTTOM_LEFT,
      });
      dispatch({
        type: FETCH_ALL_RESUME_TYPES_PDF_FAILURE,
        payload: error,
      });
      return Promise.reject(error);
    }
  );
};

export const editBasicInfo = (data, type) => (dispatch) => {
  dispatch({
    type: UPDATE_BASIC_INFO_IN_CV,
    payload: { data, type },
  });
};

export const addWorkExperience = (experience, type) => (dispatch) => {
  dispatch({
    type: ADD_WORK_EXPERIENCE_IN_CV,
    payload: { experience, type },
  });
};

export const editWorkExperience =
  (newExperience, experienceId, type) => (dispatch) => {
    dispatch({
      type: EDIT_WORK_EXPERIENCE_IN_CV,
      payload: { newExperience, experienceId, type },
    });
  };

export const deleteWorkExperience =
  (experienceIdToDelete, type) => (dispatch) => {
    dispatch({
      type: DELETE_WORK_EXPERIENCE_IN_CV,
      payload: { experienceIdToDelete, type },
    });
  };

export const addEducation = (education, type) => (dispatch) => {
  dispatch({
    type: ADD_EDUCATION_IN_CV,
    payload: { education, type },
  });
};

export const editEducation =
  (newEducation, educationId, type) => (dispatch) => {
    dispatch({
      type: EDIT_EDUCATION_IN_CV,
      payload: { newEducation, educationId, type },
    });
  };

export const deleteEducation = (educationIdToDelete, type) => (dispatch) => {
  dispatch({
    type: DELETE_EDUCATION_IN_CV,
    payload: { educationIdToDelete, type },
  });
};

export const addHonors = (honor, type) => (dispatch) => {
  dispatch({
    type: ADD_HONORS_IN_CV,
    payload: { honor, type },
  });
};

export const editHonors = (newHonor, honorId, type) => (dispatch) => {
  dispatch({
    type: EDIT_HONORS_IN_CV,
    payload: { newHonor, honorId, type },
  });
};

export const deleteHonors = (honorIdToDelete, type) => (dispatch) => {
  dispatch({
    type: DELETE_HONORS_IN_CV,
    payload: { honorIdToDelete, type },
  });
};

export const addLanguage = (language, type) => (dispatch) => {
  dispatch({
    type: ADD_LANGUAGE_IN_CV,
    payload: { language, type },
  });
};

export const editLanguage = (newLanguage, languageId, type) => (dispatch) => {
  dispatch({
    type: EDIT_LANGUAGE_IN_CV,
    payload: { newLanguage, languageId, type },
  });
};

export const deleteLanguage = (languageIdToDelete, type) => (dispatch) => {
  dispatch({
    type: DELETE_LANGUAGE_IN_CV,
    payload: { languageIdToDelete, type },
  });
};
export const addSkills = (skill, type) => (dispatch) => {
  dispatch({
    type: ADD_SKILLS_IN_CV,
    payload: { skill, type },
  });
};

export const deleteSkills = (skillIdToDelete, type) => (dispatch) => {
  dispatch({
    type: EDIT_SKILLS_IN_CV,
    payload: { skillIdToDelete, type },
  });
};

export const fillInitialData = (profileData) => (dispatch) => {
  dispatch({
    type: FILL_INITIAL_DATA_IN_CV,
    payload: { profileData },
  });
};
