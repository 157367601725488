import React, { useState } from "react";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import FormInput from "../../../core/components/Inputs/FormInput";
import info from "../../../assets/img/info.svg";
import pass from "../../../assets/img/shield-security.svg";
import { toast } from "react-toastify";

const ChangePasswordModal = (props) => {
  const { modal, toggle, title } = props;
  const [showMsg, setShowMsg] = useState(false)
  const [password, setPassword] = useState("")
  const [confirmPassword, setConfirmPassword] = useState("")
  const [passErrorMessage, setPassErrorMessage] = useState("")
  const [confirmPassErrorMessage, setConfirmPassErrorMessage] = useState("")
  const toggleShow = () => setShowMsg(value => !value);
  const validation = () => {
    let passwordValid =
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;
    if (!password) {
      setPassErrorMessage("Please Enter Your Password");
      return;
    }
    if (!passwordValid.test(password)) {
      setPassErrorMessage(
        'The password should be minimum 8 characters long and contain at least one : Upper and Lower Case Alphabet, Number, Special Character. The special characters that can be used are [!""#$%&\'()*+,-./:;<=>?@[]^_`{|}~]'
      );
      return;
    }
    if (!(password === confirmPassword)) {
      setConfirmPassErrorMessage("Passwords do not match");
      return;
    }

    handleSubmit();
  };
  const handelPassChange = (e) => {
    setPassword(e.target.value);
    setPassErrorMessage("");
    setConfirmPassErrorMessage("");
  };
  const handelConfirmPassChange = (e) => {
    setConfirmPassword(e.target.value);
    setConfirmPassErrorMessage("");
  };
  const handleSubmit = async () => {
    let resetPassData = {
      newPassword: password,
    };

    try {
      toast.success("Password has been changed successfully.");
      toggle();
      // const res = await call(
      //   "post",
      //   "api/v1/auth/reset-password",
      //   null,
      //   resetPassData,
      //   location.state.token
      // );
      // if (res.data.success === true) {
      //   alert("Password has been changed successfully.");
      //   navigate("/");
      // }
      // if (res.data.success === false) {
      //   // alert(res.data.message)
      // }
    }
    catch (err) {
      console.log(err);
    }
  };

  return (
    <Modal
      isOpen={modal}
      style={{ width: "464px", left: "17%", top: "10%" }}
      aria-labelledby="contained-modal-title-vcenter"
    >
      <div className="forgot-modal">
        <ModalHeader toggle={toggle}>{title}</ModalHeader>
        <ModalBody>
          <div className="login-form">
            <form
              onSubmit={(e) => {
                e.preventDefault();
              }}
            >
              <p>
                Enter your registered email address below, and we'll send
                you a one-time password (OTP) to reset your password
              </p>

              <FormInput
                label={
                  <>
                    New Password{" "}
                    <span>
                      <img
                        src={info}
                        alt=""
                        onMouseOver={toggleShow}
                        onMouseLeave={toggleShow}
                      />
                    </span>
                  </>
                }
                icon={pass}
                maxlength="24"
                type="password"
                placeholder="Enter Password"
                error={passErrorMessage}
                onChange={handelPassChange}
                containerClassName={passErrorMessage ? "error-form" : ""}
              />
              {showMsg == true && (
                <div className="info">
                  <h6>Pasword Requirements</h6>
                  <ul className="p-0">
                    <li>
                      Must contain both uppercase and lowercase characters
                      (e.g., a-z and A-Z)
                    </li>
                    <li>Must contain at least one number (e.g., 0-9).</li>
                    <li>Must be between 6-24 character</li>
                  </ul>
                  <div className="box"></div>
                  <div className="angle"></div>
                </div>
              )}
              <FormInput
                label="Confirm Password"
                icon={pass}
                type="password"
                placeholder="Retype Password"
                maxlength="24"
                onChange={handelConfirmPassChange}
                error={confirmPassErrorMessage}
                containerClassName={confirmPassErrorMessage ? "error-form" : ""}
              />
              <div className="text-center d-grid mt-3">
                <div className="btn btn-blue" onClick={validation}>
                  Change Password
                </div>
              </div>
            </form>
          </div>
        </ModalBody>
      </div>
    </Modal>
  );
};

export default ChangePasswordModal;
