import {
  GET_SUGGESTED_EVENTS,
  GET_PINNED_EVENTS,
  GET_MY_EVENTS,
  GET_MY_DRAFTS,
  ATTEND_EVENTS,
  PIN_EVENTS,
  UNPIN_EVENTS,
  CREATE_MY_EVENT,
  EDIT_MY_EVENT,
  UNATTEND_EVENTS,
  CANCEL_EVENT,
  GET_EVENT_DETAIL,
  GET_UPCOMING_EVENTS,
  GET_HISTORY_EVENTS,
  EDIT_MY_EVENT_DETAILS,
  DRAFT_MY_EVENT,
  GET_PAGE_EVENTS,
  PIN_EVENTS_RELATED,
  EDIT_PAGE_EVENTS,
} from "../types";
import { eventsApi } from "../../api/eventsApi";
import { initPageLoad } from "../loader/loaderActions";
import { stopPageLoad } from "../loader/loaderActions";

export const getSuggestedEvents = (data, query, currentCount) => (dispatch) => {
  return eventsApi.getevents(data, query).then(
    (events) => {
      dispatch(initPageLoad());
      if (query.type === "pinned") {
        dispatch({
          type: GET_PINNED_EVENTS,
          payload: { events, currentCount },
        });
        dispatch(stopPageLoad());
        return Promise.resolve(events);
      } else if (query.type === "myEvent") {
        dispatch({
          type: GET_MY_EVENTS,
          payload: { events, currentCount },
        });
        dispatch(stopPageLoad());
        return Promise.resolve(events);
      } else if (query.type === "upcoming") {
        dispatch({
          type: GET_UPCOMING_EVENTS,
          payload: { events, currentCount },
        });
        dispatch(stopPageLoad());
        return Promise.resolve(events);
      } else if (query.type === "history") {
        dispatch({
          type: GET_HISTORY_EVENTS,
          payload: { events, currentCount },
        });
        dispatch(stopPageLoad());
        return Promise.resolve(events);
      } else if (query.type === "myDraft") {
        dispatch({
          type: GET_MY_DRAFTS,
          payload: { events, currentCount },
        });
        dispatch(stopPageLoad());
        return Promise.resolve(events);
      } else {
        dispatch({
          type: GET_SUGGESTED_EVENTS,
          payload: { events, currentCount },
        });
        dispatch(stopPageLoad());
        return Promise.resolve(events);
      }
    },
    (error) => {
      console.log(error);
      dispatch(stopPageLoad());
      return Promise.reject(error);
    }
  );
};

export const getOthersEvents = (query, params) => (dispatch) => {
  eventsApi.getOthersEvents(params).then((events) => {
    if (query == "page") {
      console.log(events);
      dispatch({
        type: GET_PAGE_EVENTS,
        payload: events,
      });
    }
  });
};

export const createEvent = (data, token) => (dispatch) => {
  dispatch(initPageLoad());
  return eventsApi.createEvent(data, token).then((event) => {
    if (data?.event_status === "published") {
      dispatch({
        type: CREATE_MY_EVENT,
        payload: event.data,
      });
    } else if (data?.event_status === "draft") {
      dispatch({
        type: DRAFT_MY_EVENT,
        payload: event.data,
      });
    }
    dispatch(stopPageLoad());
    return Promise.resolve(event.data);
  });
};

export const editEvent = (data, token) => (dispatch) => {
  dispatch(initPageLoad());
  return eventsApi.editEvent(data, token).then((event) => {
    dispatch({
      type: EDIT_MY_EVENT,
      payload: event.data,
    });
    dispatch({
      type: EDIT_MY_EVENT_DETAILS,
      payload: event.data,
    });
    dispatch({
      type: EDIT_PAGE_EVENTS,
      payload: event.data,
    });
    dispatch(stopPageLoad());
    return Promise.resolve(true);
  });
};

export const attendEvents = (id, token, type) => (dispatch) => {
  dispatch(initPageLoad());
  eventsApi.attendEvents(id, token).then(() => {
    if (type === "attendTrue") {
      dispatch({
        type: ATTEND_EVENTS,
        payload: id,
      });
      dispatch(stopPageLoad());
    } else if (type === "attendFalse") {
      dispatch({
        type: UNATTEND_EVENTS,
        payload: id,
      });
      dispatch(stopPageLoad());
    }
  });
};

export const pinnedEvents = (id, token, type) => (dispatch) => {
  eventsApi.pinnedEvents(id, token).then(() => {
    if (type === "pinTrue") {
      dispatch({
        type: PIN_EVENTS,
        payload: id,
      });
    } else if (type === "pinFalse") {
      dispatch({
        type: UNPIN_EVENTS,
        payload: id,
      });
    }
  });
};

export const pinnedRelatedEvents = (eid, id, token, type) => (dispatch) => {
  eventsApi.pinnedEvents(id, token).then(() => {
    dispatch({
      type: PIN_EVENTS_RELATED,
      payload: { eid, id, type },
    });
  });
};

export const getEventDetail = (id, token) => (dispatch) => {
  eventsApi.getEventDetail(id, token).then((event) => {
    dispatch({
      type: GET_EVENT_DETAIL,
      payload: event.data,
    });
  });
};

export const cancelEvents = (id, token) => (dispatch) => {
  return eventsApi.cancelEvent(id, token).then((event) => {
    dispatch(initPageLoad());
    dispatch({
      type: CANCEL_EVENT,
      payload: id,
    });
    dispatch(stopPageLoad());
  });
};
