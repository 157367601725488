import React from "react";
import OutsideNavbar from "../../navbar/OutsideNavbar";
import { useDispatch, useSelector } from "react-redux";
import {
  getArticlePublicDetail,
  getSinglCompany,
} from "../../../store/directory/directoryAction";
import add from "../../../assets/img/add-icon.svg";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import noPreview from "../../../assets/img/no-preview.png";
import placeholderUser from "../../../assets/img/placeholder_user.jpg";
import moment from "moment";
import DOMPurify from "dompurify";
import { ToastContainer, toast } from "react-toastify";

const ArticlePublicDetails = (props) => {
  const dispatch = useDispatch();
  const { singlePublicArticleData } = useSelector((state) => state.directory);
  const id = window.location.search.substr(4);
  const navigate = useNavigate();
  useEffect(() => {
    dispatch(getArticlePublicDetail({ id: id }));
  }, []);
  function createMarkup(html) {
    return {
      __html: DOMPurify.sanitize(html),
    };
  }
  return (
    <div>
      <OutsideNavbar />
      <div className="main-bg admin-bg ">
        <div className="company-details">
          <div className="container">
            <div className="row">
              <div className="col-lg-2"></div>
              <div className="col-lg-8">
                <div className="article mt-4">
                  <div className="wrapper bg-white">
                    <div className="box-img">
                      {/* <img
                        src={
                          singlePublicArticleData[0]?.media
                            ? singlePublicArticleData[0]?.media
                            : noPreview
                        }
                        className="newsletter-image"
                        alt=""
                      /> */}
                      {singlePublicArticleData[0]?.media ? (
                        <img
                          src={singlePublicArticleData[0]?.media}
                          className="newsletter-image"
                          alt=""
                        />
                      ) : (
                        <img src={null} className="newsletter-image" alt="" />
                      )}
                    </div>
                    <div className="row">
                      <div className="col-2 col-sm-2 col-md-2">
                        <div className="side-icon">
                          <div className="d-flex align-items-center">
                            <span
                              className="like"
                              onClick={() => {
                                window.scrollTo(0, 0);
                                toast.success(
                                  "Please Login/Signup to access further",
                                  {
                                    position: toast.POSITION.BOTTOM_LEFT,
                                  }
                                );
                                navigate("/");
                              }}
                            ></span>
                          </div>
                          <div className="d-flex align-items-center">
                            <span
                              className="comment"
                              onClick={() => {
                                window.scrollTo(0, 0);
                                toast.success(
                                  "Please Login/Signup to access further",
                                  {
                                    position: toast.POSITION.BOTTOM_LEFT,
                                  }
                                );
                                navigate("/");
                              }}
                            ></span>
                          </div>
                          <div className="d-flex align-items-center">
                            <span
                              className="reply"
                              onClick={() => {
                                window.scrollTo(0, 0);
                                toast.success(
                                  "Please Login/Signup to access further",
                                  {
                                    position: toast.POSITION.BOTTOM_LEFT,
                                  }
                                );
                                navigate("/");
                              }}
                            ></span>
                          </div>
                        </div>
                      </div>
                      <div className="col-10 col-sm-10 col-md-10">
                        <div className="mr-20">
                          <div className="d-flex justify-content-between align-items-center  ">
                            <div className="profiles mb-20 mt-20 ">
                              <div
                                className="img"
                                // onClick={() =>
                                //   navigate(
                                //     `/profile/${singlePublicArticleData[0]?.user?.user_id}`
                                //   )
                                // }
                              >
                                <img
                                  src={
                                    singlePublicArticleData[0]?.user
                                      ?.profile_img
                                      ? singlePublicArticleData[0]?.user
                                          ?.profile_img
                                      : placeholderUser
                                  }
                                  className="image-fit curserPointer"
                                  style={{ width: "36px" }}
                                  alt=""
                                />
                              </div>
                              <div className="details">
                                <h6
                                  // onClick={() =>
                                  //   navigate(
                                  //     `/profile/${singlePublicArticleData[0]?.user?.user_id}`
                                  //   )
                                  // }
                                  className="card-control-text-oneline"
                                >
                                  {singlePublicArticleData[0]?.user?.first_name}{" "}
                                  {singlePublicArticleData[0]?.user?.last_name}
                                </h6>
                              </div>
                            </div>
                          </div>
                          <div className="d-flex gap-5 mb-20">
                            <div className="hrs">
                              {moment(singlePublicArticleData[0]?.createdAt)
                                .startOf("minute")
                                .fromNow()}
                            </div>
                          </div>
                          <div className="text">
                            <h4>{singlePublicArticleData[0]?.title}</h4>

                            {singlePublicArticleData[0]?.content && (
                              <div
                                dangerouslySetInnerHTML={createMarkup(
                                  singlePublicArticleData[0]?.content
                                )}
                                style={{ wordBreak: "break-all" }}
                              ></div>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row mt-20">
                      <div className="col-2 col-sm-2 col-md-2"></div>
                      <div className="col-10 col-sm-10 col-md-10">
                        <div className="mr-20">
                          {/* <div className="translate">translate</div> */}
                          <div className="comments">
                            <div className="d-flex  align-items-center mt-20 mb-20">
                              <div className="col d-flex align-items-center gap-3">
                                <div
                                  className="like"
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                  }}
                                >
                                  <span className="like-comment"></span>{" "}
                                  <span
                                    className="text-comment"
                                    onClick={() => {
                                      window.scrollTo(0, 0);
                                      toast.success(
                                        "Please Login/Signup to access further",
                                        {
                                          position: toast.POSITION.BOTTOM_LEFT,
                                        }
                                      );
                                      navigate("/");
                                    }}
                                  >
                                    <>Like </>
                                  </span>
                                </div>
                                <div
                                  className="like"
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                  }}
                                >
                                  <div className="comment"></div>
                                  <span
                                    className="text-comment"
                                    onClick={() => {
                                      window.scrollTo(0, 0);
                                      toast.success(
                                        "Please Login/Signup to access further",
                                        {
                                          position: toast.POSITION.BOTTOM_LEFT,
                                        }
                                      );
                                      navigate("/");
                                    }}
                                  >
                                    <>Comment</>
                                  </span>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-2"></div>
          </div>
        </div>
      </div>
      <ToastContainer />
    </div>
  );
};

export default ArticlePublicDetails;
