import React, { useState, useEffect } from "react";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import { useDispatch, useSelector } from "react-redux";
import "./callAction.scss";
import {
  addLeadActions,
  getLeadActionbyId,
} from "../../../store/leads/leadsActions";
import moment from "moment";

const CallAction = (props) => {
  const { title, modal, toggle, leadUserData } = props;

  const dispatch = useDispatch();

  var d = new Date();
  let h = (d.getHours() < 10 ? "0" : "") + d.getHours();
  let m = (d.getMinutes() < 10 ? "0" : "") + d.getMinutes();
  var today = new Date();

  const [scheduleDate, setScheduleDate] = useState(today);
  const [scheduleTime, setScheduleTime] = useState(`${h}:${m}`);
  const [error, setError] = useState("");

  const [ctaData, setCtaData] = useState({
    action:
      leadUserData.lead_status === "pending"
        ? "contact"
        : leadUserData.lead_status === "qualified" ||
          leadUserData.lead_status === "unqualified"
        ? "complete"
        : leadUserData.lead_status === "scheduled"
        ? "meeting"
        : leadUserData.lead_status === "progress"
        ? "follow"
        : "",
    lead_status: leadUserData.lead_status,
    comment: "",
    lead_id: leadUserData.id,
    schedule: null,
  });

  useEffect(() => {
    setCtaData({
      ...ctaData,
      schedule:
        moment(scheduleDate).format("YYYY-MM-DD") +
        " " +
        moment(scheduleTime, "HH:mm:ss").format("HH:mm:ssZ"),
    });
    console.log("scheduleDate", moment(scheduleDate).format("YYYY-MM-DD"));
  }, [scheduleDate, scheduleTime]);

  const actionHandle = () => {
    if (ctaData.comment.trim() === "") {
      setError("Comment is required");
      return;
    } else {
      // Proceed with your form submission logic
      // Clear the error message
      setError("");
    }
    if (ctaData.action === "pending" || ctaData.action === "complete") {
      let newData = ctaData;
      Object.keys(newData).forEach((key) => {
        if (!newData[key]) delete newData[key];
      });
      dispatch(addLeadActions(newData)).then(() => {
        toggle();
      });
    } else {
      dispatch(addLeadActions(ctaData)).then(() => {
        toggle();
      });
    }
  };

  // console.log(ctaData);

  const { leadsActionList } = useSelector((state) => state.leads);

  const [viewMore, setViewMore] = useState();

  useEffect(() => {
    dispatch(getLeadActionbyId(leadUserData.id));
  }, []);

  return (
    <Modal
      size="md"
      className="contact-info fix-width gradient-background"
      toggle={toggle}
      isOpen={modal}
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <div className="popup leads-modal">
        <div className="edit-about">
          <ModalHeader style={{ border: "none" }} toggle={toggle}>
            {title}
          </ModalHeader>
          <div className="line mt-20"></div>
          <ModalBody>
            <div className="px-3">
              <div className="dark-blue-color d-flex">
                <div>
                  <span>
                    <b>Select Action: </b>
                  </span>
                </div>
                <select
                  className="form-select"
                  style={{ width: "28%" }}
                  value={ctaData.action}
                  onChange={(e) =>
                    setCtaData({
                      ...ctaData,
                      action: e.target.value,
                      lead_status:
                        e.target.value === "contact"
                          ? "pending"
                          : e.target.value === "follow"
                          ? "progress"
                          : e.target.value === "meeting"
                          ? "scheduled"
                          : "",
                    })
                  }
                >
                  <option default value="contact">
                    Contact
                  </option>
                  <option default value="meeting">
                    Meeting/Call
                  </option>
                  <option default value="follow">
                    Follow Up
                  </option>
                  <option default value="complete">
                    Complete
                  </option>
                </select>
              </div>

              <div className="mt-20">
                {ctaData.action === "meeting" || ctaData.action === "follow" ? (
                  <div className="row">
                    <div className="col-md-5">
                      <div className="input-group">
                        <input
                          type="date"
                          className="form-control"
                          min={today}
                          // value={scheduleDate}
                          // disabled={scheduleCheck ? false : true}
                          value={moment(scheduleDate).format("YYYY-MM-DD")}
                          onChange={(event) =>
                            // setCtaData({
                            //   ...ctaData,
                            //   schedule: moment(event.target.value).format(
                            //     "YYYY-MM-DD"
                            //   ),
                            // })
                            {
                              console.log(event.target.value);
                              setScheduleDate(
                                moment(event.target.value).format("YYYY-MM-DD")
                              );
                            }
                          }
                        />
                      </div>
                    </div>

                    <div className="col-md-5">
                      <div className="input-group">
                        {/* <input type="text" className="form-control" placeholder="12:46 AM"/> */}
                        <input
                          type="time"
                          className="form-control"
                          name="time"
                          id="time"
                          value={scheduleTime}
                          onChange={(event) =>
                            setScheduleTime(event.target.value)
                          }
                        />
                      </div>
                    </div>
                  </div>
                ) : ctaData.action === "complete" ? (
                  <div className="dark-blue-color d-flex">
                    <div>
                      <span>Select Status:</span>
                    </div>
                    <select
                      className="form-select"
                      style={{ width: "28%" }}
                      value={ctaData.lead_status}
                      onChange={(e) => {
                        setCtaData({
                          ...ctaData,
                          lead_status: e.target.value,
                        });
                        console.log(e.target.value, "..qualified is selected");
                      }}
                    >
                      <option value="" disabled>
                        Select status
                      </option>
                      <option value="qualified">Qualified</option>
                      <option value="unqualified">Unqualified</option>
                    </select>
                  </div>
                ) : (
                  <></>
                )}
              </div>

              <div className="dark-blue-color mt-30 mb-1">
                <b>Comment</b>
              </div>
              <div>
                <textarea
                  className="textbox-custom"
                  maxLength={150}
                  onChange={(e) =>
                    setCtaData({ ...ctaData, comment: e.target.value })
                  }
                ></textarea>
                {error && <p style={{ color: "red" }}>{error}</p>}
              </div>
            </div>
            <div></div>
            <div className="savebutton align-center mt-30">
              <div
                className={"btn btn-blue"}
                style={{ width: "100px" }}
                onClick={() => actionHandle()}
              >
                Save
              </div>
            </div>

            {leadsActionList?.length === 0 ? (
              <div className="px-3">No Status Data!</div>
            ) : (
              leadsActionList?.map((data, idx, row) => {
                return (
                  <>
                    <div
                      key={idx}
                      className=" px-3 mt-10 d-flex justify-content-between"
                    >
                      <span
                        className="blue-color"
                        style={{ textTransform: "capitalize" }}
                      >
                        <b>Call to {data.type}</b>
                      </span>
                      <span className="blue-color">
                        {moment.utc(data.createdAt).format("MMMM-DD-YYYY")}
                      </span>
                    </div>
                    <div className="px-3">
                      <span
                        className={
                          viewMore !== idx && "card-control-text-twoline"
                        }
                      >
                        {data.comment}
                      </span>
                      <div>
                        {data.comment?.length > 132 && viewMore != idx && (
                          <span
                            className="blue-color curserPointer"
                            onClick={() => setViewMore(idx)}
                          >
                            View More
                          </span>
                        )}
                      </div>
                    </div>
                    {idx + 1 === row.length ? (
                      <></>
                    ) : (
                      <div className="line"></div>
                    )}
                  </>
                );
              })
            )}
          </ModalBody>
        </div>
      </div>
    </Modal>
  );
};

export default CallAction;
