import { allUsersApi } from "../../api/getAllUsers";
import {
    GET_ALL_USER,GET_ALL_USER_FAIL,GET_ALL_USER_FILTERED,GET_ALL_USER_FILTERED_FAIL
  } from "../types";

  export const GetAllUsers = (data) => (dispatch) => {
    return allUsersApi.getAllUserDetails(data).then(
      (res) => {
        dispatch({
          type:  GET_ALL_USER,
          payload: data,
        });
  
        return Promise.resolve(res);
      },
      (error) => {
        console.log(error);
        dispatch({
          type:  GET_ALL_USER_FAIL,
        });
        return Promise.reject(error);
      }
    );
  };

  export const GetAllUsersFiltered = (data) => (dispatch) => {
    return allUsersApi.getAllUserFiltered(data).then(
      (res) => {
        dispatch({
          type:  GET_ALL_USER,
          payload: data,
        });
  
        return Promise.resolve(res);
      },
      (error) => {
        console.log(error);
        dispatch({
          type:  GET_ALL_USER_FAIL,
        });
        return Promise.reject(error);
      }
    );
  };
