import React, { useState } from "react";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import email from "../../../assets/img/a.svg";
import pass from "../../../assets/img/shield-security.svg";
import FormInput from "../../../core/components/Inputs/FormInput";
import "./PortalAuth.scss";

const LoginModal = (props) => {
  const { modal, toggle, title ,setForgotPasswordModal} = props;
 

  return (
   
      <Modal
        isOpen={modal}
        style={{ width: "464px", left: "17%", top: "10%" }}
        aria-labelledby="contained-modal-title-vcenter"
        // centered
      >
        <div className="login-modal">
          <ModalBody>
            <div className="login-form">
              <form>
                <h3>Log In </h3>
                <FormInput
                  label="Email"
                  icon={email}
                  type="email"
                  // onChange={(e) => setEmailName(e.target.value)}
                  placeholder="--type--"
                  // onBlur={() => checkSignUpValidation("emailName")}
                  // error={errorEmail}
                  containerClassName={"mt-2"}
                />
                <FormInput
                  label="Password"
                  icon={pass}
                  type="password"
                  // onChange={(e) => setPassword(e.target.value)}
                  // onBlur={() => checkSignUpValidation("password")}
                  placeholder="--type--"
                  // error={errorPassword}
                  containerClassName={"mt-2"}
                />
                <div className="d-flex login-checkbox ">
                  <div className="form-check">
                    <input
                      type="checkbox"
                      className="form-check-input"
                      id="exampleCheck1"
                    //   onClick={() => setRememberMe(!rememberMe)}
                    />
                    <label className="form-check-label" htmlFor="exampleCheck1">
                      Keep me logged in
                    </label>
                  </div>
                  <small>
                    <span className="forgot" onClick={()=>{setForgotPasswordModal(true);toggle()}}>Forgot password?</span>
                  </small>
                </div>
                <div className="text-center d-grid mt-3">
                  <button
                    type="button"
                    className="btn btn-orange"
                    // onMouseDown={submitSignUpData}
                  >
                    Login to Talent Pool
                  </button>
                </div>
              </form>
            </div>
          </ModalBody>
        </div>

      </Modal>

  );
};

export default LoginModal;
