import React, { useState } from "react";
import { useSelector } from "react-redux";
import Pages from "./Pages";

const PagesContainer = () => {
  const [createModal, setCreateModal] = useState(false);
  const { isWebView } = useSelector((state) => state.auth);

  return <Pages createModal={createModal} setCreateModal={setCreateModal} />;
};

export default PagesContainer;
