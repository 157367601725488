import {
  GET_SUGGESTED_GROUPS,
  GET_JOINED_GROUPS,
  GET_PENDING_GROUPS,
  GET_MY_GROUPS,
  CREATE_MY_GROUP,
  EDIT_MY_GROUP,
  GET_GROUP_DETAIL,
  JOIN_GROUP,
  UNJOIN_GROUP,
  WITHDRAW_GROUP_REQUEST,
  LOAD_MORE_GET_JOINED_GROUPS,
  LOAD_MORE_GET_PENDING_GROUPS,
  LOAD_MORE_GET_MY_GROUPS,
  LOAD_MORE_GET_SUGGESTED_GROUPS,
  GET_RECIEVED_GROUPS,
  LOAD_MORE_GET_RECIEVED_GROUPS,
  ACCEPET_GROUP,
  JOIN_GROUP_FROM_DETAIL,
  UNJOIN_GROUP_FROM_DETAIL,
  LEAVE_GROUP,
  ADD_GROUP_URL,
} from "../types";
import { groupsApi } from "../../api/groupsApi";
import { initPageLoad } from "../loader/loaderActions";
import { stopPageLoad } from "../loader/loaderActions";
import { toast } from "react-toastify";

export const getSuggestedGroups =
  (query, params, currentCount, isNotReload) => (dispatch) => {
    isNotReload ? <></> : dispatch(initPageLoad());
    return groupsApi.getGroups(params).then((groups) => {
      if (groups?.message === "No record found.") {
        dispatch(stopPageLoad());
        return Promise.reject(groups);
      }

      if (groups?.success) {
        if (query === "joined") {
          if (currentCount === 1 || !currentCount) {
            dispatch({
              type: GET_JOINED_GROUPS,
              payload: groups.data,
            });
            dispatch(stopPageLoad());
            return Promise.resolve(groups);
          } else
            dispatch({
              type: LOAD_MORE_GET_JOINED_GROUPS,
              payload: groups.data,
            });
          dispatch(stopPageLoad());
          return Promise.resolve(groups);
        } else if (query === "pending") {
          if (currentCount === 1 || !currentCount) {
            dispatch({
              type: GET_PENDING_GROUPS,
              payload: groups.data,
            });
            dispatch(stopPageLoad());
            return Promise.resolve(groups);
          } else
            dispatch({
              type: LOAD_MORE_GET_PENDING_GROUPS,
              payload: groups.data,
            });
          dispatch(stopPageLoad());
          return Promise.resolve(groups);
        } else if (query === "recieved") {
          if (currentCount === 1 || !currentCount) {
            dispatch({
              type: GET_RECIEVED_GROUPS,
              payload: groups.data,
            });
            dispatch(stopPageLoad());
            return Promise.resolve(groups);
          } else
            dispatch({
              type: LOAD_MORE_GET_RECIEVED_GROUPS,
              payload: groups.data,
            });
          dispatch(stopPageLoad());
          return Promise.resolve(groups);
        } else if (query === "my") {
          console.log("currentCount", currentCount);
          if (currentCount == 1 || !currentCount) {
            dispatch({
              type: GET_MY_GROUPS,
              payload: groups.data,
            });
            dispatch(stopPageLoad());
            return Promise.resolve(groups);
          } else
            dispatch({
              type: LOAD_MORE_GET_MY_GROUPS,
              payload: groups.data,
            });
          dispatch(stopPageLoad());
          return Promise.resolve(groups);
        } else {
          if (currentCount === 1 || !currentCount) {
            dispatch({
              type: GET_SUGGESTED_GROUPS,
              payload: groups.data,
            });
            dispatch(stopPageLoad());
            return Promise.resolve(groups);
          } else
            dispatch({
              type: LOAD_MORE_GET_SUGGESTED_GROUPS,
              payload: groups.data,
            });
          dispatch(stopPageLoad());
          return Promise.resolve(groups);
        }
      }
    });
  };

export const createGroup = (data, token) => (dispatch) => {
  dispatch(initPageLoad());
  return groupsApi.createGroups(data, token).then((group) => {
    dispatch({
      type: CREATE_MY_GROUP,
      payload: group.data,
    });
    dispatch(stopPageLoad());
    return Promise.resolve(true);
  });
};

export const editGroup = (id, data, token) => (dispatch) => {
  dispatch(initPageLoad());
  return groupsApi.editGroup(id, data, token).then((group) => {
    console.log(group?.data);
    dispatch({
      type: EDIT_MY_GROUP,
      payload: group.data?.data,
    });
    dispatch(stopPageLoad());
    return Promise.resolve(true);
  });
};
export const addGroupURL = (data) => (dispatch) => {
  dispatch(initPageLoad());
  return groupsApi.addGroupURL(data).then(
    (response) => {
      dispatch({
        type: ADD_GROUP_URL,
        payload: data.page_url,
      });
      dispatch(stopPageLoad());
      return Promise.resolve(response);
    },
    (error) => {
      console.log(error);
      return Promise.reject(error);
    }
  );
};

export const getGroupDetail = (id, token) => (dispatch) => {
  return groupsApi.getGroupDetail(id, token).then((group) => {
    dispatch({
      type: GET_GROUP_DETAIL,
      payload: group.data,
    });
    return Promise.resolve(group.data);
  });
};

export const getGroupDetailByURL = (url, token) => (dispatch) => {
  return groupsApi.getGroupDetailByUrl(url, token).then((group) => {
    dispatch({
      type: GET_GROUP_DETAIL,
      payload: group.data,
    });
    return Promise.resolve(group.data);
  });
};

export const joinGroups = (id, type) => (dispatch) => {
  dispatch(initPageLoad());
  return groupsApi
    .joinGroups(id)
    .then((res) => {
      if (type === "joinTrue") {
        dispatch({
          type: JOIN_GROUP,
          payload: id,
        });
        dispatch(stopPageLoad());
        return Promise.resolve(res);
      } else if (type === "joinFalse") {
        dispatch({
          type: UNJOIN_GROUP,
          payload: id,
        });
        dispatch(stopPageLoad());
        return Promise.resolve(res);
      }
      // if (from === "group-detail") {
      //   if (type === "joinTrue") {
      //     dispatch({
      //       type: JOIN_GROUP_FROM_DETAIL,
      //       payload: id,
      //     });
      //     dispatch(stopPageLoad());
      //   } else if (type === "joinFalse") {
      //     dispatch({
      //       type: UNJOIN_GROUP_FROM_DETAIL,
      //       payload: id,
      //     });
      //     dispatch(stopPageLoad());
      //   }
      // }
    })
    .catch(() => {
      dispatch(stopPageLoad());
    });
};

export const acceptGroups = (data) => (dispatch) => {
  dispatch(initPageLoad());
  return groupsApi.acceptGroups(data).then((group) => {
    dispatch({
      type: ACCEPET_GROUP,
      payload: data,
    });
    dispatch(stopPageLoad());
    return Promise.resolve(group.data);
  });
};

export const ignoreGroups = (id, type) => (dispatch) => {
  dispatch(initPageLoad());
  groupsApi
    .joinGroups(id)
    .then(() => {
      if (type === "ignore") {
        dispatch({
          type: ACCEPET_GROUP,
          payload: id,
        });
        dispatch(stopPageLoad());
      }
    })
    .catch(() => {
      dispatch(stopPageLoad());
    });
};

export const rejectGroups = (id, type) => (dispatch) => {
  dispatch(initPageLoad());
  groupsApi
    .joinGroups(id)
    .then(() => {
      if (type === "reject") {
        dispatch({
          type: ACCEPET_GROUP,
          payload: id,
        });
        dispatch(stopPageLoad());
      }
    })
    .catch(() => {
      dispatch(stopPageLoad());
    });
};

export const withdrawRequest = (data) => (dispatch) => {
  dispatch(initPageLoad());
  return groupsApi.withdraw(data).then((group) => {
    dispatch({
      type: WITHDRAW_GROUP_REQUEST,
      payload: data,
    });
    dispatch(stopPageLoad());
    return Promise.resolve(group.data);
  });
};

export const leaveGroup = (id, notReload) => (dispatch) => {
  {
    notReload ? <></> : dispatch(initPageLoad());
  }
  return groupsApi.leaveTheGroup(id).then(
    (group) => {
      dispatch({
        type: LEAVE_GROUP,
        payload: id,
      });
      dispatch(stopPageLoad());
      return Promise.resolve(group);
    },
    (error) => {
      console.log(error);
      toast.error(
        error.response.data.message,

        {
          position: toast.POSITION.BOTTOM_LEFT,
        }
      );
      return Promise.reject(error);
    }
  );
};
