import React from "react";
import { Form, Input, Modal, ModalBody, ModalHeader } from "reactstrap";
import deleteLeadIcon from "../../../../../assets/img/deleteLeadIcon.svg";
import editPencil from "../../../../../assets/img/edit-pencil.svg";
import { useState } from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  createQuestionary,
  deleteQuestionary,
  getQuestionaryList,
  updateQuestionary,
} from "../../../../../store/jobs/jobAction";
import { toast } from "react-toastify";
import DeleteModal from "../../../../../core/components/modal/DeleteModal";
import {
  initPageLoad,
  stopPageLoad,
} from "../../../../../store/loader/loaderActions";

const CreateInterviewTopic = (props) => {
  const { modal, toggle, isEditSets, openCreate, setName } = props;
  const [isCreate, setIsCreate] = useState(openCreate);
  const [setId, setSsetId] = useState();
  const [isEditQuestionary, setIsEditQuestionary] = useState();
  const [topicName, setTopicName] = useState(openCreate ? setName : "");
  const [topicError, setTopicError] = useState("");
  const [question, setQuestion] = useState("");
  const [quesError, setQuesError] = useState("");
  const [allQuestions, setAllQuestions] = useState([]);
  const [confirmDeleteModal, setConfirmDeleteModal] = useState(false);
  const [setDeleteData, setSetDeleteData] = useState(false);
  const [confirmQusDeleteModal, setConfirmQusDeleteModal] = useState(false);
  const [qusDeleteData, setQusDeleteData] = useState(false);
  const [saveText, setSaveText] = useState("Save");
  const [currentCount, setCurrentCount] = useState(0);
  const [totalCount, setTotalCount] = useState();
  const dispatch = useDispatch();
  const { questionaryList } = useSelector((state) => state.jobs);
  const validation = (type) => {
    let formIsValid = true;
    if ((type = "all" || type === "topic")) {
      if (!topicName?.trim()) {
        setTopicError("Please enter topic");
        formIsValid = false;
        return;
        // setDisable(true);
      } else if (topicName?.length > 100) {
        setTopicError("Please enter topic name less than 100 words");
        formIsValid = false;
        return;
      } else {
        setTopicError("");
      }
    }
    if (type === "ques") {
      if (question.length <= 0) {
        setQuesError("Please enter question");
        formIsValid = false;
        // setDisable(true);
      } else {
        setQuesError("");
      }
    }
    if ((type = "all")) {
      if (allQuestions.length <= 0) {
        setQuesError("Please enter question");
        formIsValid = false;
        // setDisable(true);
      } else {
        setQuesError("");
      }
    }
    return formIsValid;
  };

  useEffect(() => {
    if (allQuestions?.length > 0) {
      setQuesError("");
    }
  }, [allQuestions]);

  const handleSetDelete = () => {
    dispatch(deleteQuestionary(setDeleteData)).then((res) => {
      if (res.success) {
        toast.success(res.message, {
          position: toast.POSITION.BOTTOM_LEFT,
        });
      }
    });
  };

  const handleSaveQus = () => {
    // if (topicName) setSetNames([...setNames, topicName]);
    if (validation("all")) {
      const data = {
        set: topicName,
        questions: allQuestions,
      };
      if (topicName && allQuestions?.length > 0) {
        if (isEditQuestionary) {
          dispatch(updateQuestionary(data, setId)).then((res) => {
            if (res.success) {
              toast.success(res.message, {
                position: toast.POSITION.BOTTOM_LEFT,
              });
            }
          });
        } else
          dispatch(createQuestionary(data)).then((res) => {
            if (res.success) {
              toast.success(res.message, {
                position: toast.POSITION.BOTTOM_LEFT,
              });
            }
          });

        setTopicName("");
        setAllQuestions([]);
        setSaveText("Saving....");
        setTimeout(() => handleToggel(), 1500);
      } else return;
    }
  };

  const handleQuesDelete = () => {
    setAllQuestions(allQuestions?.filter((el, i) => el !== qusDeleteData));
  };

  const handleEditQuesDelete = (qus) => {
    setAllQuestions(allQuestions?.filter((el, i) => el !== qus));
  };

  const handleAddQus = () => {
    if (question) setAllQuestions([...allQuestions, question]);
    setQuestion("");
  };

  const handleEdit = (setName, id) => {
    setIsCreate(true);
    setSsetId(id);
    setIsEditQuestionary(true);
    setTopicName(setName);
    dispatch(getQuestionaryList({ type: "questions", set: setName })).then(
      (res) => {
        setAllQuestions(res.map((obj) => obj.question));
      }
    );
  };
  document
    .getElementById("qus-input")
    ?.addEventListener("keyup", function (event) {
      event.preventDefault();
      if (event.keyCode === 13) {
        document.getElementById("add-btn").click();
      }
    });

  const handleClose = () => {
    dispatch(initPageLoad());
    dispatch(getQuestionaryList({ type: "set" }))
      .then(() => dispatch(stopPageLoad()))
      .catch(() => dispatch(stopPageLoad()));
    toggle();
  };

  const handleToggel = () => {
    dispatch(getQuestionaryList({ type: "set" }));
    setIsCreate(!isCreate);
  };
  useEffect(() => {
    if (isCreate) setSaveText("Save");
  }, [isCreate]);

  if (!isCreate) {
    return (
      <Modal
        isOpen={modal}
        toggle={toggle}
        size="md"
        style={{ maxWidth: "574px" }}
        centered
      >
        <div className="modal-delete-topics custom-recruiter-modal">
          <ModalHeader toggle={toggle}>Select Topic</ModalHeader>
          <ModalBody>
            <p
              className="create-btn mt-0"
              onClick={() => {
                setIsCreate(true);
                setIsEditQuestionary(false);
              }}
            >
              + Create New Topic
            </p>
            {questionaryList?.map((item, index) => (
              <div className="d-flex justify-content-between mt-3 title-list-item">
                <div
                  className="title card-control-text-oneline"
                  style={{ width: "70%" }}
                >
                  {item?.set}
                </div>
                <div>
                  <span className="edit-btn me-3">
                    {" "}
                    <img
                      src={editPencil}
                      alt="Icon"
                      onClick={() => handleEdit(item?.set, item?.id)}
                    />
                  </span>
                  <img
                    src={deleteLeadIcon}
                    alt="Icon"
                    onClick={() => {
                      setSetDeleteData(item?.set);
                      setConfirmDeleteModal(true);
                    }}
                  />
                </div>
              </div>
            ))}
          </ModalBody>
        </div>
        {confirmDeleteModal && (
          <DeleteModal
            modal={confirmDeleteModal}
            toggle={() => setConfirmDeleteModal(!confirmDeleteModal)}
            title="Delete Confirmation"
            deleteConfirm={handleSetDelete}
            text="Are you want to sure to delete this?"
          />
        )}
      </Modal>
    );
  } else {
    return (
      <Modal
        isOpen={modal}
        toggle={toggle}
        size="md"
        style={{ maxWidth: "574px" }}
        centered
      >
        <div className="modal-input-questionnair custom-recruiter-modal">
          <ModalHeader
            toggle={() => {
              openCreate ? handleClose() : handleToggel();
            }}
          >
            {openCreate ? "View Questionaire" : "Create Questionaire"}
          </ModalHeader>

          <ModalBody>
            <div className="radio-outer">
              <Form>
                <div className="input-box">
                  <Input
                    type="text"
                    placeholder="Topic Name"
                    value={topicName}
                    disabled={openCreate}
                    onChange={(e) => {
                      setTopicName(e.target.value);
                      setTopicError("");
                    }}
                    onBlur={() => validation("topic")}
                    style={{ height: "58px", width: "514px" }}
                  />
                  {topicError && (
                    <label className="label-input100 text-danger">
                      {topicError}
                    </label>
                  )}
                </div>
                {allQuestions?.map((item, index) => (
                  <div className="input-box question-box mt-4">
                    <div className="d-flex justify-content-between title-list-item">
                      <div className="title">Question: {index + 1}</div>
                      {!openCreate && (
                        <img
                          src={deleteLeadIcon}
                          alt="Icon"
                          onClick={() => {
                            setQusDeleteData(item);
                            setConfirmQusDeleteModal(true);
                            // handleQuesDelete(item);
                          }}
                        />
                      )}
                    </div>
                    <p>{item}</p>
                  </div>
                ))}
                {openCreate && (
                  <div>
                    {questionaryList?.map((item, idx) => (
                      <div className="input-box question-box mt-4">
                        <div className="d-flex justify-content-between title-list-item">
                          <div className="title">Question: {idx + 1}</div>
                          {/* {isEditQuestionary && (
                        <img
                          src={deleteLeadIcon}
                          alt="Icon"
                          onClick={() => handleQuesDelete(item)}
                        />
                      )} */}
                        </div>
                        <p>{item?.question}</p>
                      </div>
                    ))}
                  </div>
                )}
                {!openCreate && (
                  <div>
                    <div className="input-box mt-4">
                      <Input
                        type="text"
                        className="text-box"
                        placeholder="Type question.."
                        value={question}
                        id="qus-input"
                        onChange={(e) => {
                          setQuestion(e.target.value);
                          validation("ques");
                          console.log(question.length);
                          setQuesError("");
                        }}
                        style={{
                          height: "40px",
                          width: "514px",
                          marginBottom: "15px",
                        }}
                        //  onBlur={() => validation("ques")}
                      />
                      {quesError && (
                        <label className="label-input100 text-danger">
                          {quesError}
                        </label>
                      )}
                    </div>
                    <p
                      className="add-btn mt-2"
                      id="add-btn"
                      onClick={handleAddQus}
                    >
                      + Add Question
                    </p>
                  </div>
                )}
                <div className="text-center">
                  {openCreate ? (
                    <div
                      className="btn btn-blue m-auto mt-5"
                      onClick={() => handleClose()}
                    >
                      Close
                    </div>
                  ) : (
                    <div
                      className="btn btn-blue m-auto mt-5"
                      onClick={() => handleSaveQus()}
                    >
                      {saveText}
                    </div>
                  )}
                </div>
              </Form>
            </div>
            {}
          </ModalBody>
        </div>
        {confirmQusDeleteModal && (
          <DeleteModal
            modal={confirmQusDeleteModal}
            toggle={() => setConfirmQusDeleteModal(!confirmQusDeleteModal)}
            title="Delete Confirmation"
            deleteConfirm={handleQuesDelete}
            text="Are you want to sure to delete this?"
          />
        )}
      </Modal>
    );
  }
};

export default CreateInterviewTopic;
