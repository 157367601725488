import { React, useEffect, useState } from "react";
import { call } from "../../api/apiCall";
import About from "./About";
import AboutWebView from "./AboutWebview";
import { useDispatch, useSelector } from "react-redux";
import { getLandingPageData } from "../../store/landing/landingAction";
import { initPageLoad, stopPageLoad } from "../../store/loader/loaderActions";
import AppLoader from "../../core/components/Loaders/appLoader/appLoader";
//import { useSelector } from "react-redux";

export default function AboutContainer() {
  const [data, setData] = useState({});
  const [mode, setMode] = useState("");
  const { isPageLoading } = useSelector((state) => state?.loader);
  const dispatch = useDispatch();

  // const { isWebView } = useSelector((state) => state.auth);

  // useEffect(() => {
  //   let params = new URL(document.location).searchParams;
  //   let param = params.get("mode");
  //   setMode(param);
  // }, []);

  useEffect(() => {
    dispatch(initPageLoad());
    dispatch(getLandingPageData({ title: "about_us" }))
      .then((res) => {
        setData(res);
        dispatch(stopPageLoad());
      })
      .catch((err) => {
        dispatch(stopPageLoad());
      });
  }, []);

  // if (mode === "mobile") {
  //   return <AboutWebView data={data} />;
  // }
  return isPageLoading ? <AppLoader /> : <About data={data} />;
}
