import React, { useEffect, useState } from "react";
import "./InterviewPrepration.scss";
import Navbar from "../../pages/navbar/navbar";
import { jobApi } from "../../api/jobs";
import micIcon from "../../assets/img/mic.png";
import speakerIcon from "../../assets/img/speaker.png";
import { useDispatch } from "react-redux";
import { initPageLoad, stopPageLoad } from "../../store/loader/loaderActions";
import axios from "axios";
import upArrow from "../../assets/img/UpArrow.svg";
import downArrow from "../../assets/img/DownArrow.svg";
const Tab = ({ label, active, onClick }) => {
  return (
    <div className={`tab ${active ? "active" : ""}`} onClick={onClick}>
      {label}
    </div>
  );
};

function AccordionItem({
  title,
  content,
  isOpen,
  onClick,
  speaking,
  audio,
  listen,
  isRecording,
  activeIndex,
}) {
  const handleSpeak = (text) => {
    if (window.responsiveVoice) {
      window.responsiveVoice.speak(text);
    } else {
      console.error("ResponsiveVoice is not loaded");
    }
  };
  return (
    <div className="accordion-item">
      <div
        className="d-flex justify-content-between align-items-center"
        onClick={onClick}
        style={{ cursor: "pointer" }}
      >
        <h3 className="accordion-button">{title}</h3>
        <img
          src={isOpen ? upArrow : downArrow}
          height={25}
          width={25}
          alt="Toggle Arrow"
          style={{ marginLeft: "10px" }}
        />
      </div>
      {isOpen && (
        <>
          <div className="accordion-content">{content}</div>
          <div className="mt-3">
            <button
              className="bg-transparent border-0"
              onClick={() => handleSpeak(content)}
            >
              <img
                src={speakerIcon}
                height={25}
                width={25}
                alt="Speaker Icon"
              />
              <span className="mx-3">Listen</span>
            </button>
          </div>
          <div className="mt-4 d-flex">
            <button className="bg-transparent border-0" onClick={speaking}>
              <img src={micIcon} height={25} width={25} alt="Microphone Icon" />
              <span className="mx-3">{isRecording ? "Stop" : "Speak"}</span>
            </button>
            <div className="mx-3">
              {audio.length > 1 &&
                audio?.map((ele, i) => {
                  if (i === activeIndex && ele) {
                    return (
                      <audio key={i} controls>
                        <source src={ele} type="audio/wav" />
                      </audio>
                    );
                  }
                })}
            </div>
          </div>
        </>
      )}
    </div>
  );
}

const TabContent = ({ children, active }) => {
  if (!active) return null;
  return <div className="tab-content">{children}</div>;
};

const InterviewPreperation = () => {
  const [activeTab, setActiveTab] = useState("background");
  const [question, setQuestion] = useState();
  const [activeIndex, setActiveIndex] = useState(0);

  const [mediaStream, setMediaStream] = useState(null);
  const [mediaRecorder, setMediaRecorder] = useState(null);
  const [isRecording, setIsRecording] = useState(false);
  const [recordedChunks, setRecordedChunks] = useState([]);
  const [timer, setTimer] = useState(0);
  const [audioUrl, setAudioUrl] = useState(null);
  const [audio, setAudio] = useState();

  const dispatch = useDispatch();

  useEffect(() => {
    let intervalId;
    if (isRecording) {
      intervalId = setInterval(() => {
        setTimer((prev) => prev + 1);
      }, 1000);
    } else {
      clearInterval(intervalId);
    }
    return () => clearInterval(intervalId);
  }, [isRecording]);

  const startRecording = async () => {
    try {
      const devices = await navigator.mediaDevices.enumerateDevices();
      const hasMicrophone = devices.some(
        (device) => device.kind === "audioinput"
      );
      const hasSpeaker = devices.some(
        (device) => device.kind === "audiooutput"
      );

      if (!hasMicrophone) {
        alert("Microphone is not connected.");
        return;
      }

      if (!hasSpeaker) {
        alert("Speaker is not connected.");
        return;
      }
      if (!isRecording) {
        const stream = await navigator.mediaDevices.getUserMedia({
          audio: true,
        });
        const recorder = new MediaRecorder(stream);
        const recordedChunks = [];

        recorder.ondataavailable = (e) => {
          recordedChunks.push(e.data);
        };

        recorder.onstop = async () => {
          dispatch(initPageLoad());

          const audioBlob = new Blob(recordedChunks, { type: "audio/wav" });
          const audioUrl = URL.createObjectURL(audioBlob);
          const aud = [...audio];
          aud[activeIndex] = audioUrl;
          setAudio(aud);
          const formData = new FormData();
          formData.append("image", audioBlob, "audio.wav");

          const UPLOAD_URL = `${process.env.REACT_APP_API_URL}api/v1/upload`;
          const headers = {
            "Accept-Language": "en",
            "content-type": "multipart/form-data",
          };

          try {
            const res = await axios.post(UPLOAD_URL, formData, { headers });
            console.log(res?.data?.urlsArray[0]);
          } catch (error) {
            console.error("Error uploading audio:", error);
          }

          dispatch(stopPageLoad());
        };

        recorder.start();
        setIsRecording(true);
        setMediaStream(stream);
        setMediaRecorder(recorder);
      } else {
        console.log("Stopping recording");
        mediaRecorder.stop();
        setIsRecording(false);
        mediaStream.getTracks().forEach((track) => track.stop());
      }
    } catch (err) {
      console.error("Error accessing media devices", err);
    }
  };

  const toggleAccordion = (index) => {
    if (activeIndex === index) {
      setActiveIndex(null);
    } else {
      setActiveIndex(index);
    }
  };

  const handleTabClick = (tabIndex) => {
    setActiveTab(tabIndex);
  };
  const [category, setCategory] = useState("webdev");
  const getQuetion = async () => {
    let cate = activeTab == "experience" ? category : "";
    const response = await jobApi.getPrepareQuestion({
      type: activeTab,
      category: cate || undefined,
    });
    if (response?.success) {
      setQuestion(response?.data?.data);
      setAudio(Array.from({ length: response?.data?.data?.length }, () => ""));
    }
  };

  useEffect(() => {
    getQuetion();
  }, [activeTab, category]);

  return (
    <>
      <Navbar isJobs={false} />

      <section className="interview-preperation-section">
        <div className="container">
          <div className="wrappper" style={{ height: "90vh" }}>
            <h2>Interview Preperation</h2>
            <p>
              Prepare yourself for the interview by exploring the most commonly
              asked questions. Learn from good examples of answers, optimize
              your own responses, and boost your confidence by recording and
              listening to your answers.
            </p>

            <div className="tabs-section">
              <div className="tabs-container">
                <div className="tabs">
                  <Tab
                    label="Background-related"
                    active={activeTab === "background"}
                    onClick={() => handleTabClick("background")}
                  />
                  <Tab
                    label="Situational/ conditional"
                    active={activeTab === "situational"}
                    onClick={() => handleTabClick("situational")}
                  />
                  <Tab
                    label="Experience-related"
                    active={activeTab === "experience"}
                    onClick={() => handleTabClick("experience")}
                  />
                </div>
                <div className="tab-contents">
                  <TabContent active={activeTab === "background"}>
                    <div className="accordion">
                      {question?.map((item, index) => (
                        <AccordionItem
                          key={index}
                          title={item?.question}
                          content={item?.answer}
                          listen={item?.audio_url}
                          isOpen={activeIndex === index}
                          onClick={() => {
                            toggleAccordion(index);
                          }}
                          speaking={startRecording}
                          audio={audio}
                          activeIndex={activeIndex}
                          isRecording={isRecording}
                        />
                      ))}
                    </div>
                  </TabContent>
                  <TabContent active={activeTab === "situational"}>
                    <div className="accordion">
                      {question?.map((item, index) => (
                        <AccordionItem
                          key={index}
                          title={item?.question}
                          content={item?.answer}
                          listen={item?.audio_url}
                          isOpen={activeIndex === index}
                          onClick={() => {
                            toggleAccordion(index);
                          }}
                          speaking={startRecording}
                          audio={audio}
                          isRecording={isRecording}
                        />
                      ))}
                    </div>
                  </TabContent>

                  <TabContent active={activeTab === "experience"}>
                    <div className="row">
                      <div className="col-9"></div>
                      <div className="col-3">
                        <select
                          value={category}
                          className="form-select"
                          onChange={(e) => setCategory(e.target.value)}
                        >
                          <option defaultValue="">Select catgory</option>
                          <option value="webdev">Web Development</option>
                          <option value="tech">Technology</option>
                          <option value="tc">Technology Consulting</option>
                          <option value="scm">Supply Chain Management</option>
                          <option value="stn">Sustainability</option>
                          <option value="sd">Software Development</option>
                          <option value="sale">Sales</option>
                          <option value="pjm">Project Management</option>
                          <option value="pdm">Product Management</option>
                          <option value="opmng">Operations Management</option>
                          <option value="mkt">Marketing</option>
                          <option value="lgl">Legal</option>
                          <option value="hr">Human Resources</option>
                          <option value="hc">Healthcare</option>
                          <option value="fin">Finance</option>
                          <option value="engg">Engineering</option>
                          <option value="eng">Energy</option>
                          <option value="edu">Education</option>
                          <option value="ds">Data Science</option>
                          <option value="da">Data Analytics</option>
                          <option value="cbs">Cybersecurity</option>
                          <option value="cs">Customer Service</option>
                          <option value="cd">Career Development</option>
                          <option value="bd">Business Development</option>
                          <option value="ai">
                            Artificial Intelligence (AI)
                          </option>
                        </select>
                      </div>
                    </div>
                    <div className="accordion">
                      {question?.map((item, index) => (
                        <AccordionItem
                          key={index}
                          title={item?.question}
                          content={item?.answer}
                          listen={item?.audio_url}
                          isOpen={activeIndex === index}
                          onClick={() => {
                            toggleAccordion(index);
                          }}
                          speaking={startRecording}
                          audio={audio}
                          isRecording={isRecording}
                        />
                      ))}
                    </div>
                  </TabContent>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default InterviewPreperation;
