import { call } from "./apiCall";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

class PeopleApi {
    getpeopleList(params) {
    let token = localStorage.getItem("accessToken");
    return new Promise((resolve, reject) => {
      (async () => {
        try {
          const res = await call(
            "get",
            `api/v1/feed/connection`,
            params,
            null,
            token
          );
          if (res.data.success === true) {
            resolve(res.data);
          }
          if (res.data.success === false) {
            resolve(res.data);
          }
        } catch (err) {
          console.log(err);
          reject(err);
        }
      })();
    });
  }

  // getConnectionTypeList(type) {
  //   return new Promise((resolve, reject) => {
  //     (async () => {
  //       try {
  //         const res = await call(
  //           "get",
  //           type? `api/v1/feed/connection?type=${type}`:  `api/v1/feed/connection`,
  //           null,
  //           null,
  //           token
  //         );
  //         if (res.data.success === true) {
  //           resolve(res.data);
  //         }
  //         if (res.data.success === false) {
  //           resolve(res.data);
  //         }
  //       } catch (err) {
  //         console.log(err);
  //         reject(err);
  //       }
  //     })();
  //   });
  // }


  getSuggestedPeopleList(params) {
    let token = localStorage.getItem("accessToken");
    return new Promise((resolve, reject) => {
      (async () => {
        try {
          const res = await call(
            "get",
            `api/v1/user/users-list`,
            params,
            null,
            token
          );
          if (res.data.success === true) {
            resolve(res.data);
          }
          if (res.data.success === false) {
            resolve(res.data);
          }
        } catch (err) {
          console.log(err);
          reject(err);
        }
      })();
    });
  }

  acceptConnectionRequest(id, request_type) {
    let token = localStorage.getItem("accessToken");
    return new Promise((resolve, reject) => {
      (async () => {
        try {
          const res = await call(
            "patch",
            `api/v1/feed/connection/${id}/${request_type}`,
            null,
            null,
            token
          );
          if (res.data.success === true) {
            resolve(res.data);
          }
        } catch (err) {
          console.log(err);
          reject(err);
        }
      })();
    });
  }

  createFollower(id) {
    let token = localStorage.getItem("accessToken");
    return new Promise((resolve, reject) => {
      (async () => {
        try {
          const res = await call(
            "post",
            `api/v1/feed/follow/${id}`,
            null,
            null,
            token
          );
          if (res.data.success === true) {
            resolve(res.data);
          }
        } catch (err) {
          console.log(err);
          reject(err);
        }
      })();
    });
  }

  createUnfollower(id) {
    let token = localStorage.getItem("accessToken");
    return new Promise((resolve, reject) => {
      (async () => {
        try {
          const res = await call(
            "delete",
            `api/v1/feed/unfollow/${id}`,
            null,
            null,
            token
          );
          if (res.data.success === true) {
            resolve(res.data);
          }
        } catch (err) {
          console.log(err);
          reject(err);
        }
      })();
    });
  }

  getUnfollowerUsers(params){
    let token = localStorage.getItem("accessToken");
    return new Promise((resolve, reject) => {
      (async () => {
        try {
          const res = await call(
            "get",
            `api/v1/user/unfollowed-user-list`,
            params,
            null,
            token
          );
          if (res.data.success === true) {
            resolve(res.data);
          }
        } catch (err) {
          console.log(err);
          reject(err);
        }
      })();
    });

  }
  removeConnection(id) {
    let token = localStorage.getItem("accessToken");
    return new Promise((resolve, reject) => {
      (async () => {
        try {
          const res = await call(
            "delete",
            `api/v1/feed/remove-connection/${id}`,
            null,
            null,
            token
          );
          if (res.data.success === true) {
            resolve(res.data);
            toast.success(res.data.message,{ position: 'bottom-left'} );
          }
        } catch (err) {
          console.log(err);
          reject(err);
        }
      })();
    });
  }

  blockUser(id) {
    let token = localStorage.getItem("accessToken");
    return new Promise((resolve, reject) => {
      (async () => {
        try {
          const res = await call(
            "post",
            `api/v1/user/block-user`,
            null,
            {user_id: id},
            token
          );
          if (res.data.success === true) {
            resolve(res.data);
          }
        } catch (err) {
          console.log(err);
          reject(err);
        }
      })();
    });
  }
  
  unblockUser(id){
    let token = localStorage.getItem("accessToken")
    return new Promise((resolve, reject) => {
      (async () => {
        try {
          const res = await call(
            "delete",
            `api/v1/user/unblock-user/${id}`,
            null,
            null,
            token
          );
          if (res.data.success === true) {
            resolve(res.data);
          }
        } catch (err) {
          console.log(err);
          reject(err);
        }
      })();
    });
  }

  updateBlock(id, data){
    let token = localStorage.getItem("accessToken")
    return new Promise((resolve, reject) => {
      (async () => {
        try {
          const res = await call(
            "patch",
            `api/v1/user/update-block-setting/${id}`,
            null,
            data,
            token
          );
          if (res.data.success === true) {
            resolve(res.data);
          }
        } catch (err) {
          console.log(err);
          reject(err);
        }
      })();
    });
  }


}

export const peopleApi = new PeopleApi();
