import {
    GET_ALL_USER,GET_ALL_USER_FAIL,GET_ALL_USER_FILTERED_FAIL,GET_ALL_USER_FILTERED
  } from "../types";

  const initialState = [];

export default function (state = initialState, action) {
    const { type, payload } = action
  
    switch (type) {

        case GET_ALL_USER:
           const { getAllUsers } = payload
        return {
          ...state,
          isUserApi: true,
          getAllUsers: payload
        }

      case GET_ALL_USER_FAIL:
          return {
            ...state,
            isUserApi: false
          }


      default:
        return state
    }
  }