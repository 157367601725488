import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import Posts from "./Posts";
import { GetAllUsers } from "../../../store/getAllUsers/allUsersAction";
import {
  deletePost,
  getPostDetails,
  updatePost,
  savePost,
  savePostDelete,
  repost_post,
  hide_user_post,
} from "../../../store/dashboard/dashboardActions";
import { getUserProfile } from "../../../store/profile/profileActions";
import { reactionsData } from "../../../store/reactionPost/reactionAction";
import moment from "moment";
import { ToastContainer, toast } from "react-toastify";
import { getPageDetails } from "../../../store/dashboard/dashboardActions";
import { POST_NOW_PREVIEW } from "../../../store/types";

export default function PostsContainer() {
  const { user } = useSelector((state) => state.auth);
  const { getPageDetailData } = useSelector((state) => state.dashboard);

  const [pageState, setPageState] =
    useState("published"); /*saved, scheduled, draft*/
  const [currentCount, setCurrentCount] = useState(0);
  const dispatch = useDispatch();
  const [modal, setModal] = useState(false);
  const [postId, setPostID] = useState(0);
  const [isViewReaction, setViewReaction] = useState(false);
  const [isReaction, setIsReaction] = useState(-1);
  const [viewMore, setViewMore] = useState(false);
  const [isComments, setIsComments] = useState(-1);
  const [dropDown, setDropDown] = useState(-1);
  const [isEdit, setIsEdit] = useState(0);
  const [createPostModal, setCreatePostModal] = useState(false);
  const [updateData, setUpdateData] = useState();
  const [isSaved, setSaved] = useState(-1);
  const [userId, setUserId] = useState();
  const [userName, setUserName] = useState("");
  const [userDesignation, setUserDesignation] = useState("");
  const [createPostClick, setCreatePostClick] = useState("");
  const [discardModalStatus, setDiscardModalStatus] = useState(false);
  const [isDataAvailable, setDataAvailable] = useState(false);
  const [draftDetails, setDraftDetails] = useState();
  const [dropDownShare, setDropDownShare] = useState(-1);
  const [commentBoxStatus, setCommentBoxStatus] = useState([]);
  const [reportModal, setReportModal] = useState(false);
  const [closeModal, setCloseModal] = useState(false);
  const [deleteId, setDeleteId] = useState("");
  const [roleShareModal, setRoleShareModal] = useState(false);
  const [dropDownRepost, setDropDownRepost] = useState(-1);
  const [rolePageId, setRolePageId] = useState(false);
  const [openShareModal, setOpenShareModal] = useState(false);
  const [shareData, setShareData] = useState("");
  const [byUserId, setByUserId] = useState(0);
  const { postData } = useSelector((state) => state.dashboard);
  const toggle = () => setModal(!modal);

  const dropRef = useRef();
  const dropRefShare = useRef(null);
  const dropRefRepost = useRef(null);

  const closeAllModal = () => {
    setDiscardModalStatus(!discardModalStatus);
    toggle();
  };

  // useEffect(() => {
  //   document.addEventListener("mousedown", clickOutsideAdmin);
  //   return () => {
  //     document.removeEventListener("mousedown", clickOutsideAdmin);
  //   };
  // }, [dropDown]);

  // const clickOutsideAdmin = (e) => {
  //   if (e.toElement.className === "listing") {
  //     return;
  //   }
  //   if (e.toElement.className === "clickDots") {
  //     return;
  //   }
  //   if (dropRef?.current?.contains(e.target) && dropDown > -1) {
  //     return;
  //   }
  //   setDropDown(-1);
  // };

  useEffect(() => {
    let id = user?.data?.id;
    setUserId(id);
    let token = window.localStorage.getItem("accessToken");
    if (id) {
      dispatch(getUserProfile(id, token));
    }
    setUserName(user?.data?.first_name + " " + user?.data?.last_name);
    setUserDesignation(user?.data?.designation);
  }, [user]);

  useEffect(() => {
    let token = window.localStorage.getItem("accessToken");
    dispatch(getPageDetails(token));
  }, []);

  // function reactionName(id) {
  //   dispatch(GetAllUsers({ id: id })).then((res) => {
  //     return `${res.data[0].first_name} ${res.data[0].last_name} and`;
  //   });
  // }

  // function privacy_type(privacy_id) {
  //   if (privacy_id === 1) return " Public";
  //   if (privacy_id === 2) return " Connection";
  //   return " Group";
  // }

  const delete_post = (_id) => {
    let token = window.localStorage.getItem("accessToken");
    dispatch(deletePost(_id, token)).then((response) => {
      toast.success("Deleted successfully!", {
        position: toast.POSITION.BOTTOM_LEFT,
      });
      // setDropDown(-1);
    });
  };
  // const removeSavePost = (id, i) => {
  //   let token = window.localStorage.getItem("accessToken");
  //   dispatch(savePostDelete(id, token));
  // };

  const editPost = (postData, isPostNowPreview = false) => {
    if (postData.Celebration) {
      console.log("Cannot edit celebration post and feed docs");
    } else {
      setIsEdit(postData?.id);
      setCreatePostModal(!createPostModal);
      if (isPostNowPreview) {
        dispatch({
          type: POST_NOW_PREVIEW,
          payload: { isPostNowPreview: true },
        });
        setUpdateData({ ...postData, feed_status: "published" });
      } else {
        dispatch({
          type: POST_NOW_PREVIEW,
          payload: { isPostNowPreview: false },
        });
        setUpdateData(postData);
      }
      setDropDown(-1);
    }
  };

  const postNow = (_id, type) => {
    let token = window.localStorage.getItem("accessToken");
    let data = { feed_status: "published", id: _id };
    dispatch(updatePost(data, token, type)).then((reponse) => {
      toast.success("Posted Sucessfully!", {
        position: toast.POSITION.BOTTOM_LEFT,
      });
      setDropDown(-1);
    });
  };

  // function handleReaction(item, reacted, type) {
  //   let data = {
  //     post_id: item.id,
  //     type: reacted,
  //   };
  //   if (item?.as_a_page) {
  //     Object.assign(data, { page_id: item?.as_a_page?.id });
  //   }
  //   dispatch(reactionsData(data, type)).then((res) => {});
  //   setIsReaction(!isReaction);
  //   // dispatch({type: LIKED_DASHBOARD_POST, payload: data});
  // }

  // useEffect(() => {
  //   getFeedApiCall();
  // }, []);

  useEffect(() => {
    let token = window.localStorage.getItem("accessToken");
    dispatch(
      getPostDetails(token, {
        user_id: user?.data?.id,
        feed_status: pageState,
        skip: currentCount,
        limit: 10,
      })
    );
    setCommentBoxStatus([]);
  }, [pageState, currentCount]);

  useEffect(() => {
    setCurrentCount(0);
  }, [pageState]);

  // const getFeedApiCall = () => {

  // }

  // const handleNext = () => {
  //   console.log("calling/", currentCount)
  //   setCurrentCount(currentCount + 10);
  // let token = window.localStorage.getItem("accessToken");
  // dispatch(
  //   getPostDetails(token, {
  //     user_id: user?.data?.id,
  //     feed_status: pageState,
  //     skip: currentCount,
  //     limit: 10,
  //   })
  // );
  // };

  const handleNext = () => {
    setCurrentCount(currentCount + 10);
  };

  // const hideUserPost = (id) => {
  //   dispatch(hide_user_post(id)).then((reponse) => {
  //     toast.success("Post Hide Sucessfully!", {
  //       position: toast.POSITION.BOTTOM_LEFT,
  //     });
  //   });
  //   setDropDown(-1);
  // };

  // const createLink = (content) =>
  //   content?.map((str, index) => {
  //     return (
  //       <a
  //         href={`/profile/${str.user_id}`}
  //         className="text-blue-500"
  //         key={index}
  //       >
  //         {str?.user?.first_name} {str?.user?.last_name}{" "}
  //       </a>
  //     );
  //   });

  // const save_post = (id, i) => {
  //   let token = window.localStorage.getItem("accessToken");
  //   dispatch(savePost(id, token));
  // };

  // const getHashtagText = (content) =>
  //   content
  //     ?.replace(/\n#/g, " \n#")
  //     .replace(/\n/g, " \n")
  //     ?.split(" ")
  //     ?.map((str, index) => {
  //       if (str.startsWith("#") || str.startsWith("\n#")) {
  //         let keyword = str.startsWith("#")
  //           ? str.substring(1)
  //           : str.substring(2);
  //           return (
  //             <a
  //               href={`/hashtag/feed/?keyword=${keyword}`}
  //               className="text-blue-500"
  //               key={index}
  //             >
  //               {str}{" "}
  //             </a>
  //           );
  //       }
  //       return str + " ";
  //     });

  // const repostPost = (id, type) => {
  //   let repost_data = {
  //     type: "repost",
  //     shared_id: id,
  //     privacy_id: 1,
  //   };
  //   dispatch(repost_post(repost_data, type)).then((res) => {
  //     setDropDownShare(-1);
  //     toast.success("Reposted successfully!", {
  //       position: toast.POSITION.BOTTOM_LEFT,
  //     });
  //   });
  // };

  // const likeResult = (arr1, arr2) => {
  //   if (arr1 === undefined || arr1 === null) {
  //     let array3 = arr2;
  //     return array3;
  //   } else {
  //     let arr4 = [{ type: arr1 }];
  //     let array3 = arr4?.concat(
  //       arr2?.filter((bo) =>
  //         arr4?.every((ao) => ao?.type !== bo?.type && bo?.page_id !== null)
  //       )
  //     );
  //     return array3;
  //   }
  // };

  // const getTime = (date) => {
  //   let result = moment(date).fromNow();
  //   const now = moment();
  //   const days = now.diff(date, "days");
  //   const weeks = now.diff(date, "weeks");
  //   if (days >= 7) {
  //     if (days <= 13) {
  //       result = `a week ago`;
  //     } else if (days > 13 && days <= 25) {
  //       result = `${weeks} weeks ago`;
  //     }
  //   }
  //   return result;
  // };

  return (
    <Posts
      pageState={pageState}
      setPageState={setPageState}
      postData={postData}
      handleNext={handleNext}
      user={user}
      userName={userName}
      userId={userId}
      createPostModal={createPostModal}
      setCreatePostModal={setCreatePostModal}
      // handleReaction={handleReaction}
      postId={postId}
      setPostID={setPostID}
      isViewReaction={isViewReaction}
      setViewReaction={setViewReaction}
      createPostClick={createPostClick}
      currentCount={currentCount}
      // privacy_type={privacy_type}
      dropDown={dropDown}
      setDropDown={setDropDown}
      discardModalStatus={discardModalStatus}
      setDiscardModalStatus={setDiscardModalStatus}
      closeAllModal={closeAllModal}
      delete_post={delete_post}
      editPost={editPost}
      updateData={updateData}
      isEdit={isEdit}
      setIsEdit={setIsEdit}
      postNow={postNow}
      dropRef={dropRef}
      dropRefShare={dropRefShare}
      setDraftDetails={setDraftDetails}
      isDataAvailable={isDataAvailable}
      setDataAvailable={setDataAvailable}
      // removeSavePost={removeSavePost}
      // save_post={save_post}
      // getHashtagText={getHashtagText}
      dropDownShare={dropDownShare}
      setDropDownShare={setDropDownShare}
      // createLink={createLink}
      // repostPost={repostPost}
      // likeResult={likeResult}
      commentBoxStatus={commentBoxStatus}
      setCommentBoxStatus={setCommentBoxStatus}
      // getTime={getTime}
      // hideUserPost={hideUserPost}
      reportModal={reportModal}
      setReportModal={setReportModal}
      closeModal={closeModal}
      setCloseModal={setCloseModal}
      deleteId={deleteId}
      setDeleteId={setDeleteId}
      dropDownRepost={dropDownRepost}
      setDropDownRepost={setDropDownRepost}
      dropRefRepost={dropRefRepost}
      roleShareModal={roleShareModal}
      setRoleShareModal={setRoleShareModal}
      getPageDetailData={getPageDetailData}
      rolePageId={rolePageId}
      setRolePageId={setRolePageId}
      openShareModal={openShareModal}
      setOpenShareModal={setOpenShareModal}
      shareData={shareData}
      setShareData={setShareData}
      setByUserId={setByUserId}
    />
  );
}
