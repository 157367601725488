import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import moment from "moment";
import PostDetails from "./postDetails";
import PostDetailsWebview from "./postDetailsWebview";
import { ToastContainer, toast } from "react-toastify";
import { loadUser } from "../../../store/auth/authActions";

import {
  block_comment,
  comment_status,
  deletePost,
  getAllComments,
  getPageDetails,
  getPostDetails,
  hide_user_post,
  postViewLog,
  repost_post,
  savePost,
  savePostDelete,
} from "../../../store/dashboard/dashboardActions";
import { initLoad, stopLoad } from "../../../store/loader/loaderActions";
import {
  followPage,
  getAllPages,
  unFollowPage,
} from "../../../store/pages/pagesAction";
import {
  profile_follower,
  profile_unfollower,
  remove_connection,
} from "../../../store/profile/profileActions";
import { reactionsData } from "../../../store/reactionPost/reactionAction";
import { sendConnectionRequest } from "../../../store/connections/connectionsActions";

export default function PostDetailsContainer() {
  const { postData } = useSelector((state) => state.dashboard);
  const { getPageDetailData } = useSelector((state) => state.dashboard);
  const { user } = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const { postId } = useParams();
  let params = new URL(document.location).searchParams;
  const token = localStorage.getItem("accessToken");
  const [byUserId, setByUserId] = useState(0);
  const [isViewReaction, setViewReaction] = useState(false);
  const [createPostClick, setCreatePostClick] = useState("");
  const [updateData, setUpdateData] = useState();
  const [isEdit, setIsEdit] = useState(0);
  const [reportType, setReportType] = useState();
  const [reportCommentId, setReportCommentId] = useState();
  const [closeModal, setCloseModal] = useState(false);
  const [deleteId, setDeleteId] = useState("");
  const [commentBoxStatus, setCommentBoxStatus] = useState([]);
  const [commentStatus, setCommentStatus] = useState(true);
  const [post_Id, setPost_ID] = useState(postId);
  const [dropDown, setDropDown] = useState(-1);
  const [dropDownShare, setDropDownShare] = useState(-1);
  const [dropDownRepost, setDropDownRepost] = useState(-1);
  const [roleShareModal, setRoleShareModal] = useState(false);
  const [rolePageId, setRolePageId] = useState(false);
  const dropRef = useRef(null);
  const dropRefShare = useRef(null);
  const dropRefRepost = useRef(null);
  const [shareData, setShareData] = useState("");
  const [openShareModal, setOpenShareModal] = useState(false);
  const navigate = useNavigate();

  const editPost = (postData) => {
    if (postData.Celebration) {
      alert("Can't edit celebration post and feed docs ,docs");
    } else {
      setIsEdit(postData.id);
      // setCreatePostModal(!createPostModal);
      setCreatePostClick("");
      setUpdateData(postData);
      setDropDown(-1);
    }
  };
  const handleCommentStatus = (postData, type) => {
    dispatch(comment_status(postData, type));
    setDropDown(-1);
  };

  const save_post = (id, i) => {
    dispatch(savePost(id, token)).then((response) => {
      toast.success("Post Saved", {
        position: toast.POSITION.BOTTOM_LEFT,
      });
    });
  };

  const removeSavePost = (id) => {
    dispatch(savePostDelete(id, token)).then((response) => {
      toast.success("Post Removed", {
        position: toast.POSITION.BOTTOM_LEFT,
      });
    });
  };

  function privacy_type(privacy_id) {
    if (privacy_id === 1) return " Public";
    if (privacy_id === 2) return " Connection";
    if (privacy_id === 3) return " Group";
    if (privacy_id === 4) return " Private";
  }

  const delete_post = (_id) => {
    // let token = window.localStorage.getItem("accessToken");
    dispatch(deletePost(_id, token)).then((response) => {
      toast.success("Deleted successfully!", {
        position: toast.POSITION.BOTTOM_LEFT,
      });
      setDropDown(-1);
    });
  };

  useEffect(() => {
    document.addEventListener("mousedown", clickOutsideAdmin);
    return () => {
      document.removeEventListener("mousedown", clickOutsideAdmin);
    };
  }, [dropDown, dropDownShare, dropDownRepost]);

  const clickOutsideAdmin = (e) => {
    if (e.toElement && e?.toElement.className === "shareListing") {
      return;
    }
    if (e.toElement && e?.toElement.className === "listing") {
      return;
    }
    if (e.toElement && e?.toElement.className === "clickDots") {
      return;
    }
    if (dropRef?.current?.contains(e.target) && dropDown > -1) {
      return;
    }
    if (dropRefRepost?.current?.contains(e.target) && dropDownRepost > -1) {
      return;
    }
    setDropDown(-1);
    setDropDownShare(-1);
    setDropDownRepost(-1);
  };

  function handleReaction(item, reacted, type) {
    let data = {
      post_id: item.id,
      type: reacted,
    };
    if (item?.as_a_page) {
      Object.assign(data, { page_id: item?.as_a_page?.id });
    }
    dispatch(reactionsData(data, type)).then((res) => {
      setByUserId(res.data[0]?.id);
    });
  }

  const sendRequest = (id, mode, post_id) => {
    dispatch(sendConnectionRequest(id, mode, post_id)).then((res) => {
      toast.success(res.message, {
        position: toast.POSITION.BOTTOM_LEFT,
      });
    });
  };

  const hideUserPost = (id) => {
    dispatch(hide_user_post(id)).then((reponse) => {
      toast.success("Post Hide Sucessfully!", {
        position: toast.POSITION.BOTTOM_LEFT,
      });
    });
    setDropDown(-1);
  };

  const postViewCounter = (id) => {
    const payload = {
      view_type: "post",
      post_id: id,
    };
    dispatch(postViewLog(payload));
  };
  const repostPost = (id, type, asPageId) => {
    let repost_data = {
      type: "repost",
      shared_id: id,
      privacy_id: 1,
      shared_by_type: type === "asPage" ? "page" : "user",
      shared_by: type === "asPage" ? asPageId : user?.data?.id,
    };
    dispatch(repost_post(repost_data)).then((res) => {
      setDropDownShare(-1);
      toast.success("Reposted successfully!", {
        position: toast.POSITION.BOTTOM_LEFT,
      });
      navigate("/dashboard");
    });
  };
  const likeResult = (arr1, arr2) => {
    if (arr1 === undefined || arr1 === null) {
      let array3 = arr2;
      return array3;
    } else {
      let arr4 = [{ type: arr1 }];
      let array3 = arr4?.concat(
        arr2?.filter((bo) =>
          arr4?.every((ao) => ao?.type !== bo?.type && bo?.page_id !== null)
        )
      );
      return array3;
    }
  };
  useEffect(() => {
    dispatch(initLoad());
    dispatch(getPostDetails(token, { id: postId }));
    dispatch(getAllPages({ filter: "my" }));
    dispatch(getPageDetails(token));
  }, []);

  function privacy_type(privacy_id) {
    if (privacy_id === 1) return " Public";
    if (privacy_id === 2) return " Connection";
    return " Group";
  }
  const peopleFollowRequest = (id, mode, post_id) => {
    dispatch(profile_follower(id, mode, post_id));
  };
  const peopleUnFollowRequest = (id, mode, post_id) => {
    dispatch(profile_unfollower(id, mode, post_id));
  };
  const peopleRemoveConnection = (id, mode, post_id) => {
    dispatch(remove_connection(id, mode, post_id));
  };
  const handlePageFollow = (pageID, mode, post_id) => {
    dispatch(followPage(pageID, mode, post_id));
  };
  const handlePageUnFollow = (pageID, mode, post_id) => {
    dispatch(unFollowPage(pageID, mode, post_id));
  };

  return (
    <>
      <PostDetails
        user={user}
        postDetailData={postData}
        privacy_type={privacy_type}
        dropRef={dropRef}
        dropRefShare={dropRefShare}
        dropDown={dropDown}
        setDropDown={setDropDown}
        dropDownShare={dropDownShare}
        setDropDownShare={setDropDownShare}
        likeResult={likeResult}
        setDropDownRepost={setDropDownRepost}
        dropRefRepost={dropRefRepost}
        save_post={save_post}
        removeSavePost={removeSavePost}
        handleCommentStatus={handleCommentStatus}
        setCommentBoxStatus={setCommentBoxStatus}
        commentStatus={commentStatus}
        setCommentStatus={setCommentStatus}
        hideUserPost={hideUserPost}
        repostPost={repostPost}
        setShareData={setShareData}
        delete_post={delete_post}
        sendRequest={sendRequest}
        createPostClick={createPostClick}
        setCreatePostClick={setCreatePostClick}
        handleReaction={handleReaction}
        isViewReaction={isViewReaction}
        setViewReaction={setViewReaction}
        setPost_ID={setPost_ID}
        commentBoxStatus={commentBoxStatus}
        postViewCounter={postViewCounter}
        openShareModal={openShareModal}
        setOpenShareModal={setOpenShareModal}
        shareData={shareData}
        editPost={editPost}
        dropDownRepost={dropDownRepost}
        roleShareModal={roleShareModal}
        setRoleShareModal={setRoleShareModal}
        getPageDetailData={getPageDetailData}
        rolePageId={rolePageId}
        setRolePageId={setRolePageId}
        post_Id={post_Id}
        reportType={reportType}
        setReportType={setReportType}
        reportCommentId={reportCommentId}
        setReportCommentId={setReportCommentId}
        closeModal={closeModal}
        setCloseModal={setCloseModal}
        deleteId={deleteId}
        setDeleteId={setDeleteId}
        peopleFollowRequest={peopleFollowRequest}
        peopleUnFollowRequest={peopleUnFollowRequest}
        peopleRemoveConnection={peopleRemoveConnection}
        handlePageFollow={handlePageFollow}
        handlePageUnFollow={handlePageUnFollow}
      />
      ;
    </>
  );
}
