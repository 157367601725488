import React from "react";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { Input, Modal, ModalBody, ModalHeader } from "reactstrap";
import { deleteProfileMedia } from "../../../../store/profile/profileActions";
import DeleteModal from "../../../../core/components/modal/DeleteModal";

const AddMediaExper = (props) => {
  const {
    modal,
    toggle,
    fileName,
    setFileName,
    image,
    setImage,
    add,
    mediaDescription,
    setMediaDescription,
    expData,
    setExpData,
    selectedMediaId,
  } = props;
  const [title, setTitle] = useState(fileName ? fileName : "");
  const [closeModal, setCloseModal] = useState(false);
  const dispatch = useDispatch();
  console.log("expData", selectedMediaId, expData);
  const handleDelete = () => {
    const temp = expData?.medias?.filter((el) => el?.id !== selectedMediaId);
    dispatch(deleteProfileMedia(selectedMediaId));
    setExpData({ ...expData, medias: temp });
    setImage("");
    setFileName("");
    toggle();
  };
  const [error, setError] = useState({
    title: "",
    description: "",
  });
  const checkValidation = (type) => {
    let formIsValid = true;
    if (type == "title" || type == "all") {
      if (!fileName.trim()) {
        setError({ ...error, title: "Title is mandatory" });
        formIsValid = false;
      } else setError({ ...error, title: "" });
    }
    if (type == "description" || type == "all") {
      if (!mediaDescription.trim()) {
        setError({ ...error, description: "Description is mandatory" });
        formIsValid = false;
      } else setError({ ...error, description: "" });
    }

    return formIsValid;
  };

  return (
    <Modal
      isOpen={modal}
      size="lg"
      className="fix-width gradient-background"
      centered
    >
      <div className="add-media-modal ">
        <ModalHeader toggle={toggle} className="py-3">
          Add Media
        </ModalHeader>
        <ModalBody>
          <form>
            <div className="title">
              <label className="text-dark">Title</label>

              <Input
                type="text"
                // onClick={(e) => setErrorTitle("")}
                placeholder="Enter Title"
                maxLength="50"
                // onBlur={() => checkValidation("title")}
                value={fileName}
                onChange={
                  (e) => setFileName(e.target.value)
                  //   setExpData({ ...expData, title: e.target.value })
                }
                onBlur={() => checkValidation("title")}
              />

              {error?.title && (
                <label className="label-input100 text-danger">
                  {error?.title}
                </label>
              )}
            </div>
            <div className="title">
              <label htmlFor="" className="text-dark">
                Description
              </label>
              <Input
                type="textarea"
                value={mediaDescription}
                maxLength="100"
                placeholder="Description"
                onChange={(e) => {
                  setMediaDescription(e.target.value);
                }}
                onBlur={() => checkValidation("description")}
              />
              {error?.description && (
                <label className="label-input100 text-danger">
                  {error?.description}
                </label>
              )}
            </div>
            <div className="col-4 media-img">
              {image && <img alt="media" src={image} />}
            </div>
          </form>
        </ModalBody>
        <div className="p-20 d-flex justify-content-between">
          <div
            className={"btn text-secondary"}
            onClick={() => setCloseModal(true)}
          >
            Delete
          </div>
          <div
            className={"btn btn-blue"}
            onClick={() => {
              if (checkValidation("all")) {
                add();
              }
            }}
          >
            Apply
          </div>
        </div>
      </div>
      {closeModal && (
        <DeleteModal
          modal={DeleteModal}
          toggle={() => setCloseModal(!closeModal)}
          title="Delete Media"
          deleteConfirm={() => {
            // setExpData({
            //   ...expData,
            //   is_visible: false,
            // });
            handleDelete();
          }}
          text="Do you want to delete this media?"
        />
      )}
    </Modal>
  );
};

export default AddMediaExper;
