import React, { useEffect, useState } from "react";
import { Input, Modal, ModalBody, ModalHeader } from "reactstrap";
import placeholderUser from "../../../../assets/img/placeholder_user.jpg";
import jobPromot from "../../../../assets/img/job_promot.svg";
import info from "../../../../assets/img/info.svg";
import card from "../../../../assets/img/Credit-card1.png";
import moment from "moment";
import headerImg from "../../../../assets/img/RectanglepayBycard.png";
import { useDispatch, useSelector } from "react-redux";
import { getJobsDetail } from "../../../../store/jobs/jobAction";
import pagePlaceholder from "../../../../assets/img/page-placeholder.svg";

const Payment = (props) => {
  const {
    modal,
    toggle,
    title,
    createCampaign,
    scheduleCheck,
    scheduleDate,
    scheduleTime,
    selectedJobId,
    totalAmount,
  } = props;
  const [endDate, setEndDate] = useState();
  useEffect(() => {
    setEndDate(moment(scheduleDate).add(30, "d"));
  }, []);

  const dispatch = useDispatch();
  const { getAJob } = useSelector((state) => state.jobs);
  useEffect(() => {
    dispatch(getJobsDetail(selectedJobId));
  }, [selectedJobId]);

  return (
    <Modal
      isOpen={modal}
      size="lg"
      style={{ maxWidth: "600px", maxHeight: "420px" }}
      className="topDiv gradient-background"
    >
      <ModalHeader
        className="header"
        toggle={toggle}
        style={{
          backgroundImage: `url(${headerImg})`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          color: "white",
        }}
      >
        {title}
      </ModalHeader>
      <ModalBody>
        <div className="grey-bg bg-white payment">
          {/* {selected && ( */}
          <div className="row mb-2">
            <p className="mb-2">Job Details</p>
            <div className="col-lg-3">
              <div className="img">
                {/* <img
                  src={placeholderUser}
                  // xsrc={
                  //   selected?.profile_img
                  //     ? selected?.profile_img
                  //     : placeholderUser
                  // }
                  className="image-fit profile-Img"
                  alt="profileimage"
                /> */}
                <div
                  style={{
                    height: "69px",
                    width: "92px",
                    margin: "10px 0px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <img
                    src={
                      getAJob?.company?.icon
                        ? getAJob?.company?.icon
                        : pagePlaceholder
                    }
                    alt="campaign_picture"
                    style={{ height: "69px", width: "92px" }}
                  />
                </div>
              </div>
            </div>
            <div className="col-lg-7 mt-3">
              <div className="text">
                <p>
                  {" "}
                  <strong>Job Promotion</strong>
                </p>
                <p className="mt-0">
                  Starts {moment(scheduleDate).format("ddd MMM Do YYYY")},{" "}
                  {moment(scheduleTime, "HH:mm:ss").format("hh:mm A")}
                </p>
                <p className="mt-0">
                  Ends {moment(endDate).format("ddd MMM Do YYYY")},{" "}
                  {moment(scheduleTime, "HH:mm:ss").format("hh:mm A")}
                </p>
              </div>
            </div>
            <div className="col-lg-2 mt-3">
              <div className="float-sm-end">
                <div
                  className="text-blue remove curserPointer"
                  onClick={() => toggle()}
                >
                  <strong>Edit</strong>
                </div>
              </div>
            </div>
          </div>
          {/* )} */}
          <div className="row">
            <div className="check">
              <Input
                type="checkbox"
                id="coupanCheck"
                name="coupanCheck"
                // checked={pageData?.experienceCheck}
                // onClick={(e) =>
                //   setPageData({
                //     ...pageData,
                //     experienceCheck: e.target.checked,
                //   })
                // }
              />
              <label htmlFor="couponCheck">I have a coupon code </label>
            </div>
          </div>
          <div className="row">
            <div className="col-7"></div>
            <div className="col-5">
              <div className="float-sm-end">
                <div className="campaign-cost">
                  <p>Campaign cost: ${totalAmount}</p>
                </div>
                <div className="total-cost">
                  <strong className="text-dark">
                    Total Amount: ${totalAmount}
                  </strong>
                </div>
              </div>
            </div>
          </div>
          <div className="payment-card">
            <div className="header">
              <p>Payment Method</p>
            </div>
            <div className="card-details d-flex align-items-center">
              <div className="col-9 d-flex align-items-center my-3">
                <div className="radio m-3">
                  <input
                    type="radio"
                    name="paymentcard"
                    //   onChange={(e) => updatePrivacy(e, "publicMode")}
                    //   checked={publicMode}
                  />
                </div>
                <div>
                  <h5>Ending in:...6372</h5>
                  <p>Exp. Date: 12/22</p>
                </div>
              </div>
              <div className="col-3">
                <div className="float-sm-end">
                  <img src={card} className="card-image" alt="credit-card" />
                </div>
              </div>
            </div>
            <div className="line"></div>
            <div className="card-details d-flex align-items-center">
              <div className="col-9 d-flex align-items-center my-3">
                <div className="radio m-3">
                  <input
                    type="radio"
                    name="paymentcard"
                    //   onChange={(e) => updatePrivacy(e, "publicMode")}
                    //   checked={publicMode}
                  />
                </div>
                <div>
                  <h5>Ending in:...6372</h5>
                  <p>Exp. Date: 12/22</p>
                </div>
              </div>
              <div className="col-3">
                <div className="float-sm-end">
                  <img src={card} className="card-image" alt="creditimage" />
                </div>
              </div>
            </div>
          </div>
          <div className="mt-2">
            <span
              className="addcard"
              onClick={() =>
                alert(
                  "This functionality will be implemented in future milestone"
                )
              }
            >
              + Add New Card
            </span>
          </div>
          <div className="text-center my-3">
            <div
              className="btn btn-blue"
              onClick={
                scheduleCheck === true
                  ? () => {
                      createCampaign("scheduled", "job");
                      toggle();
                    }
                  : () => {
                      createCampaign("published", "job");
                      toggle();
                    }
              }
            >
              {" "}
              Pay Now
            </div>
          </div>
        </div>
      </ModalBody>
    </Modal>
  );
};

export default Payment;
