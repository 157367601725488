import { directoryApi } from "../../api/directoryApi";
import { initPageLoad, stopPageLoad } from "../loader/loaderActions";
import {
  GET_ALL_COMPANY,
  GET_ALL_MEMBERS,
  GET_MORE_PUBLIC_ARTICLE_LIST,
  GET_MORE_PUBLIC_JOB_LIST,
  GET_MORE_PUBLIC_MEMBERS,
  GET_MORE_PUBLIC_NEWSLETTER_LIST,
  GET_MORE_PUBLIC_POST_LIST,
  GET_PUBLIC_ARTICLE_DETAILS,
  GET_PUBLIC_ARTICLE_LIST,
  GET_PUBLIC_JOB_DETAILS,
  GET_PUBLIC_JOB_LIST,
  GET_PUBLIC_NEWSLETTER_DETAILS,
  GET_PUBLIC_NEWSLETTER_LIST,
  GET_PUBLIC_POST_DETAILS,
  GET_PUBLIC_POST_LIST,
  GET_SINGLE_COMPANY,
  GET_SINGLE_MEMBER,
} from "../types";

export const getAllCompany = (query) => (dispatch) => {
  try {
    dispatch(initPageLoad());
    directoryApi.getCompanyList(query).then(
      (response) => {
        try {
          dispatch({
            type: GET_ALL_COMPANY,
            payload: response.data,
          });
        } catch (error) {
          dispatch(stopPageLoad());
          console.log("error", error);
        }
        dispatch(stopPageLoad());
        return Promise.resolve(response);
      },
      (error) => {
        console.log(error);
        dispatch(stopPageLoad());
        return Promise.reject(error);
      }
    );
  } catch (error) {
    console.log("coming ", error);
  }
};

export const getSinglCompany = (query) => (dispatch, getState) => {
  dispatch(initPageLoad());
  return directoryApi.getCompanyList(query).then(
    (response) => {
      dispatch({
        type: GET_SINGLE_COMPANY,
        payload: response.data,
      });
      dispatch(stopPageLoad());
      return Promise.resolve(response);
    },
    (error) => {
      console.log(error);
      dispatch(stopPageLoad());
      return Promise.reject(error);
    }
  );
};
// export const getAllMembers = (query) => (dispatch) => {
//   try {
//    return directoryApi.getMembersList(query).then(
//       (response) => {
//         try {
//           dispatch({
//             type: GET_ALL_MEMBERS,
//             payload: response.data,
//           });
//         } catch (error) {
//           console.log("error", error);
//         }
//         return Promise.resolve(response);
//       },
//       (error) => {
//         console.log(error);
//         return Promise.reject(error);
//       }
//     );
//   } catch (error) {
//     console.log("coming ", error);
//   }
// };
export const getAllMembers = (params) => (dispatch) => {
  dispatch(initPageLoad());
  return directoryApi.getMembersList(params).then((response) => {
    if(params.skip === 0) {
      dispatch({
        type: GET_ALL_MEMBERS,
        payload: response?.data,
      });
      dispatch(stopPageLoad());
    }
    else {
      dispatch({
        type: GET_MORE_PUBLIC_MEMBERS,
        payload: response?.data,
      });
      dispatch(stopPageLoad());
    }
    
    dispatch(stopPageLoad());
    return Promise.resolve(response);
  });
};

export const getSinglMember = (id) => (dispatch, getState) => {
  dispatch(initPageLoad());
   directoryApi.getPublicMemberDetail(id).then(
    (response) => {
      dispatch({
        type: GET_SINGLE_MEMBER,
        payload: response.data,
      });
      dispatch(stopPageLoad());
      return Promise.resolve(response);
    },
    (error) => {
      console.log(error);
      dispatch(stopPageLoad());
      return Promise.reject(error);
    }
  );
};

export const getAllPublicArticles = (params) => (dispatch) => {
  dispatch(initPageLoad());
  return directoryApi.getPublicArticleList(params).then((response) => {
    if(params.skip === 0) {
      dispatch({
        type: GET_PUBLIC_ARTICLE_LIST,
        payload: response?.data,
      });
      dispatch(stopPageLoad());
    }
    else {
      dispatch({
        type: GET_MORE_PUBLIC_ARTICLE_LIST,
        payload: response?.data,
      });
      dispatch(stopPageLoad());
    }
    
    dispatch(stopPageLoad());
    return Promise.resolve(response);
  });
};

export const getArticlePublicDetail = (id) => (dispatch, getState) => {
  dispatch(initPageLoad());
   directoryApi.getPublicArticleDetail(id).then(
    (response) => {
      dispatch({
        type: GET_PUBLIC_ARTICLE_DETAILS,
        payload: response.data,
      });
      dispatch(stopPageLoad());
      return Promise.resolve(response);
    },
    (error) => {
      console.log(error);
      dispatch(stopPageLoad());
      return Promise.reject(error);
    }
  );
};

export const getAllPublicNewsletters = (params) => (dispatch) => {
  dispatch(initPageLoad());
  return directoryApi.getPublicNewsletterList(params).then((response) => {
    if(params.skip === 0) {
      dispatch({
        type: GET_PUBLIC_NEWSLETTER_LIST,
        payload: response?.data,
      });
      dispatch(stopPageLoad());
    }
    else {
      dispatch({
        type: GET_MORE_PUBLIC_NEWSLETTER_LIST,
        payload: response?.data,
      });
      dispatch(stopPageLoad());
    }
    
    dispatch(stopPageLoad());
    return Promise.resolve(response);
  });
};

export const getNewsletterPublicDetail = (id) => (dispatch, getState) => {
  dispatch(initPageLoad());
   directoryApi.getPublicNewsletterDetail(id).then(
    (response) => {
      dispatch({
        type: GET_PUBLIC_NEWSLETTER_DETAILS,
        payload: response.data,
      });
      dispatch(stopPageLoad());
      return Promise.resolve(response);
    },
    (error) => {
      console.log(error);
      dispatch(stopPageLoad());
      return Promise.reject(error);
    }
  );
};

export const getAllPublicPosts = (params) => (dispatch) => {
  dispatch(initPageLoad());
  return directoryApi.getPublicPostsList(params).then((response) => {
    if(params.skip === 0) {
      dispatch({
        type: GET_PUBLIC_POST_LIST,
        payload: response?.data,
      });
      dispatch(stopPageLoad());
    }
    else {
      dispatch({
        type: GET_MORE_PUBLIC_POST_LIST,
        payload: response?.data,
      });
      dispatch(stopPageLoad());
    }
    
    dispatch(stopPageLoad());
    return Promise.resolve(response);
  });
};

export const getPostPublicDetail = (id) => (dispatch, getState) => {
  dispatch(initPageLoad());
   directoryApi.getPublicPostDetail(id).then(
    (response) => {
      dispatch({
        type: GET_PUBLIC_POST_DETAILS,
        payload: response.data,
      });
      dispatch(stopPageLoad());
      return Promise.resolve(response);
    },
    (error) => {
      console.log(error);
      dispatch(stopPageLoad());
      return Promise.reject(error);
    }
  );
};

export const getAllPublicJobs = (params) => (dispatch) => {
  dispatch(initPageLoad());
  return directoryApi.getPublicJobsList(params).then((response) => {
    if(params.skip === 0) {
      dispatch({
        type: GET_PUBLIC_JOB_LIST,
        payload: response?.data,
      });
      dispatch(stopPageLoad());
    }
    else {
      dispatch({
        type: GET_MORE_PUBLIC_JOB_LIST,
        payload: response?.data,
      });
      dispatch(stopPageLoad());
    }
    
    dispatch(stopPageLoad());
    return Promise.resolve(response);
  });
};

export const getJobPublicDetail = (id) => (dispatch, getState) => {
  dispatch(initPageLoad());
  return directoryApi.getPublicJobDetail(id).then(
    (response) => {
      dispatch({
        type: GET_PUBLIC_JOB_DETAILS,
        payload: response.data,
      });
      dispatch(stopPageLoad());
      return Promise.resolve(response);
    },
    (error) => {
      console.log(error);
      dispatch(stopPageLoad());
      return Promise.reject(error);
    }
  );
};
