import { useEffect, useMemo, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import TagsInput from "../tagsInput/tagsInput";
import { initLoad, stopLoad } from "../../../../store/loader/loaderActions";
import {
  createConversation,
  updateConversation,
  updateGroupConversation,
} from "../../../../store/message/messageActions";
import axios from "axios";
import { toast } from "react-toastify";
import EmojiPicker from "emoji-picker-react";
import MediaView from "../MediaView/MediaView.jsx";
import GiphyPicker from "../../../../core/components/GifPicker/GifPicker.js";

export default function NewMessageBox({
  setActiveTab,
  messages,
  setMessages,
  xmppService,
  chatService,
}) {
  const { user } = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const [textMessage, setTextMessage] = useState("");
  const [newMembers, setNewMembers] = useState([]);
  const [showGif, setShowGif] = useState(false);
  const [showEmoji, setShowEmoji] = useState(false);
  const [bottomDropDownOpen, setBottomDropDownOpen] = useState(false);
  const [isImage, setIsImage] = useState(false);
  const [isVideo, setIsVideo] = useState(false);
  const [isAttachment, setIsAttachment] = useState(false);
  const [isAudio, setIsAudio] = useState(false);
  const [fileName, setFileName] = useState("");
  const [media, setMedia] = useState([]);
  const [mediaSize, setMediaSize] = useState(0);
  const imgRef = useRef();
  const vidRef = useRef();
  const attachRef = useRef();

  const [mediaStream, setMediaStream] = useState(null);
  const [mediaRecorder, setMediaRecorder] = useState(null);
  const [isRecording, setIsRecording] = useState(false);
  const [timer, setTimer] = useState(0);

  const [sendType, setSendType] = useState("enter");

  const onSendMessage = (message) => {
    if (newMembers?.length >= 1) {
      const type = newMembers?.length == 1 ? "chat" : "group";
      dispatch(initLoad());
      dispatch(
        createConversation({
          users: newMembers?.map((member) => ({ user: member?.jid })),
          type,
        })
      )
        .then((res) => {
          const Room = res?.data?.room;
          console.log("Room Created", Room);

          chatService.join(
            { user: `user_id-${user?.data?.id}`, room: Room?.code },
            true
          );
          // newMembers?.forEach((member) => {
          //   chatService.join(
          //     {
          //       user: `user_id-${member?.id}`,
          //       room: Room?.code,
          //     },
          //     true
          //   );
          // });

          sendMessage(message, Room?.code);
          setActiveTab(type == "chat" ? "message" : "group");
          dispatch(stopLoad());
        })
        .catch((err) => {
          toast.error(err?.response?.data?.error || "Something went wrong", {
            position: "bottom-left",
          });
          dispatch(stopLoad());
        });
    } else {
      toast.error("Atleast select one user to start conversation", {
        position: "bottom-left",
      });
    }
  };

  // const onSendGroupMessage = (newMessage, groupMembers) => {
  //   if (newMessage) {
  //     dispatch(initLoad());
  //     dispatch(
  //       updateGroupConversation({
  //         type: "group",
  //         lastMessage: newMessage.message,
  //         groupName: "",
  //         groupLogo: "",
  //         groupMembers: groupMembers?.map((m) => ({ user: m?.jid })),
  //       })
  //     )
  //       .then((res) => {
  //         if (newMessage?.messageType === "1") {
  //           newMessage.to = res?.data?.roomJid;
  //           newMessage.groupId = res?.data?.id;
  //           newMessage.groupImageUrl = res?.data?.groupLogo;

  //           xmppService?.sendMessage(newMessage, "groupchat");
  //         } else {
  //           xmppService?.onMediaShareStanza(newMessage, "groupchat");
  //         }
  //         setMessages([...messages, newMessage]);
  //         setTextMessage("");
  //         setActiveTab("group");
  //         dispatch(stopLoad());
  //       })
  //       .catch((err) => dispatch(stopLoad()));
  //   }
  // };

  // const sendMessage = (messageText) => {
  //   if (newMembers?.length == 1) {
  //     let newMessage;
  //     if (isImage || isVideo || isAttachment || isAudio) {
  //       newMessage = {
  //         sender: {
  //           id: user?.data?.id,
  //           name: `${user?.data?.first_name} ${user?.data?.last_name}`?.trim(),
  //           first_name: user?.data?.first_name,
  //           last_name: user?.data?.last_name,
  //           profile_img: user?.data?.profile_image,
  //           jid: user?.data?.jid,
  //         },
  //         to: newMembers[0]?.jid,
  //         mediaUrl: media?.length ? media[0] : "",
  //         mediaSize: mediaSize,
  //         mediaName: fileName,
  //       };
  //        newMessage = {
  //         user: user?.data?.jid,
  //         roomCode: activeBox?.code,
  //         mediaUrl: media?.length ? media[0] : "",
  //         mediaSize: mediaSize,
  //         mediaName: fileName,
  //       };
  //       if (isImage) {
  //         newMessage.messageType = "2";
  //         newMessage.message = "[Image] Media Message";
  //       } else if (isVideo) {
  //         newMessage.messageType = "3";
  //         newMessage.message = "[Video] Media Message";
  //       } else if (isAudio) {
  //         newMessage.messageType = "4";
  //         newMessage.message = "[Audio] Media Message";
  //       } else if (isAttachment) {
  //         newMessage.messageType = "5";
  //         newMessage.message = "[Attachment] Media Message";
  //       }
  //     } else if (messageText) {
  //       newMessage = {
  //         sender: {
  //           id: user?.data?.id,
  //           name: `${user?.data?.first_name} ${user?.data?.last_name}`?.trim(),
  //           first_name: user?.data?.first_name,
  //           last_name: user?.data?.last_name,
  //           profile_img: user?.data?.profile_image,
  //           jid: user?.data?.jid,
  //         },
  //         to: newMembers[0]?.jid,
  //         message: messageText,
  //         messageType: "1",
  //       };
  //     }
  //     onSendMessage(newMessage, newMembers[0]?.jid);
  //   } else if (newMembers?.length > 1) {
  //     let newMessage;
  //     if (isImage || isVideo || isAttachment || isAudio) {
  //       newMessage = {
  //         sender: {
  //           id: user?.data?.id,
  //           name: `${user?.data?.first_name} ${user?.data?.last_name}`?.trim(),
  //           first_name: user?.data?.first_name,
  //           last_name: user?.data?.last_name,
  //           profile_img: user?.data?.profile_image,
  //           jid: user?.data?.jid,
  //         },
  //         mediaUrl: media?.length ? media[0] : "",
  //         mediaSize: mediaSize,
  //         mediaName: fileName,
  //       };
  //       if (isImage) {
  //         newMessage.messageType = "2";
  //         newMessage.message = "[Image] Media Message";
  //       } else if (isVideo) {
  //         newMessage.messageType = "3";
  //         newMessage.message = "[Video] Media Message";
  //       } else if (isAudio) {
  //         newMessage.messageType = "4";
  //         newMessage.message = "[Audio] Media Message";
  //       } else if (isAttachment) {
  //         newMessage.messageType = "5";
  //         newMessage.message = "[Attachment] Media Message";
  //       }
  //     } else if (messageText) {
  //       newMessage = {
  //         sender: {
  //           id: user?.data?.id,
  //           name: `${user?.data?.first_name} ${user?.data?.last_name}`?.trim(),
  //           first_name: user?.data?.first_name,
  //           last_name: user?.data?.last_name,
  //           profile_img: user?.data?.profile_image,
  //           jid: user?.data?.jid,
  //         },
  //         message: messageText,
  //         messageType: "1",
  //       };
  //     }
  //     onSendGroupMessage(newMessage, newMembers);
  //   }
  // };

  const sendMessage = (message, roomCode) => {
    if (isImage || isVideo || isAttachment || isAudio) {
      let newMessage = {
        user: user?.data?.jid,
        roomCode,
        mediaUrl: media?.length ? media[0] : "",
        mediaSize: mediaSize,
        mediaName: fileName,
      };
      if (isImage) {
        newMessage.messageType = 2;
        newMessage.message = "[Image] Media Message";
      } else if (isVideo) {
        newMessage.messageType = 3;
        newMessage.message = "[Video] Media Message";
      } else if (isAudio) {
        newMessage.messageType = 4;
        newMessage.message = "[Audio] Media Message";
      } else if (isAttachment) {
        newMessage.messageType = 5;
        newMessage.message = "[Attachment] Media Message";
      }
      setMessages([...messages, newMessage]);
      setTextMessage("");
      setIsImage(false);
      setIsAttachment(false);
      setIsVideo(false);
      setIsAudio(false);
      setMedia([]);

      chatService.send(newMessage);
    } else if (message) {
      const newMessage = {
        user: user?.data?.jid,
        roomCode,
        messageType: 1,
        message,
      };
      setMessages([...messages, newMessage]);
      setTextMessage("");

      chatService.send(newMessage);
    }
  };

  const onEmojiClick = (emojiData, event) => {
    setTextMessage((m) => m + emojiData.emoji);
    // setShowEmoji(false)
  };

  const onGifSelect = (gifData) => {
    setShowGif(false);
    if (gifData) {
      setIsImage(true);
      setIsVideo(false);
      setIsAttachment(false);
      setFileName(gifData.name);
      // setMediaSize(0);
      setMedia([gifData.url]);
      setTextMessage("");
    }
  };

  const handleMediaChange = async (e, type) => {
    var fileExtension = e.target.value.split(".").pop();
    var validFile = true;
    let videoValid = /^(mp4|MP4)$/;
    let ImageValid = /^(jpg|jpeg|png|gif|JPG|JPEG|PNG|GIF)$/;
    let attachmentValid = /^(pdf|ppt|pptx|txt|^text\/plain|doc|docx|xls|xlsx)$/;
    setIsAudio(false);

    if (type == "image") {
      setIsImage(true);
      setIsVideo(false);
      setIsAttachment(false);
      if (ImageValid.test(fileExtension) == false) {
        validFile = false;
      }
    } else if (type == "video") {
      setIsVideo(true);
      setIsAttachment(false);
      setIsImage(false);

      if (videoValid.test(fileExtension) == false) {
        validFile = false;
      }
    } else if (type == "attachment") {
      setIsAttachment(true);
      setIsImage(false);
      setIsVideo(false);
      if (attachmentValid.test(fileExtension) == false) {
        validFile = false;
      }
    }
    if (validFile) {
      let tempimage = [];
      Array.from(e.target.files).map((file) => {
        tempimage.push({
          type,
          file,
        });
      });
      try {
        const UPLOAD_URL = `${process.env.REACT_APP_UPLOAD_URL}api/v1/upload`;
        const formData = new FormData();
        const headers = {
          "Accept-Language": "en",
          "content-type": "multipart/form-data",
        };

        for (var i = 0; i < tempimage.length; i++) {
          formData.append("image", tempimage[i].file);
        }
        dispatch(initLoad());
        await axios.post(UPLOAD_URL, formData, { headers }).then((res) => {
          dispatch(stopLoad());
          let { filesArray, urlsArray } = res.data;
          setFileName(filesArray[0].originalname);
          setMediaSize(filesArray[0].size);
          setMedia(urlsArray);
          setTextMessage("");
        });
        //  e.target.files = ''
      } catch (err) {
        if (err.response.data.message === "File too large") {
          // alert("File is too large, please upload another file");
          toast.warn("File is too large, please upload another file", {
            position: toast.POSITION.BOTTOM_LEFT,
          });
          dispatch(stopLoad());
          setIsImage(false);
          setIsAttachment(false);
          setIsVideo(false);
          return;
        }
        setIsImage(false);
        setIsAttachment(false);
        setIsVideo(false);
        console.log(err);
      }
    } else {
      alert("Please choose valid file type");
      toast.warn("Please choose valid file type", {
        position: toast.POSITION.BOTTOM_LEFT,
      });
      setIsImage(false);
      setIsAttachment(false);
      setIsVideo(false);
      return;
    }
  };

  const handleMediaClose = () => {
    setIsImage(false);
    setIsAttachment(false);
    setIsVideo(false);
    setIsAudio(false);
    setMedia([]);
  };

  useEffect(() => {
    let intervalId;
    if (isRecording) {
      intervalId = setInterval(() => {
        setTimer((prev) => {
          if (prev > 180) {
            stopRecording();
            return 0;
          }
          return prev + 1;
        });
      }, 1000);
    } else {
      clearInterval(intervalId);
      setTimer(0);
    }
    return () => clearInterval(intervalId);
  }, [isRecording]);

  const startRecording = async () => {
    try {
      const devices = await navigator.mediaDevices.enumerateDevices();
      const hasMicrophone = devices.some(
        (device) => device.kind === "audioinput"
      );
      const hasSpeaker = devices.some(
        (device) => device.kind === "audiooutput"
      );

      if (!hasMicrophone) {
        alert("Microphone is not connected.");
        return;
      }

      if (!hasSpeaker) {
        alert("Speaker is not connected.");
        return;
      }
      const stream = await navigator.mediaDevices.getUserMedia({
        audio: true,
      });
      const recorder = new MediaRecorder(stream);
      const recordedChunks = [];

      recorder.ondataavailable = (e) => {
        recordedChunks.push(e.data);
      };

      recorder.onstop = async () => {
        const audioBlob = new Blob(recordedChunks, { type: "audio/mp3" });
        // const audioUrl = URL.createObjectURL(audioBlob);
        try {
          const formData = new FormData();
          formData.append(
            "image",
            audioBlob,
            `audio-${new Date().getTime()}.mp3`
          );
          const UPLOAD_URL = `${process.env.REACT_APP_UPLOAD_URL}api/v1/upload`;
          const headers = {
            "Accept-Language": "en",
            "content-type": "multipart/form-data",
          };
          setIsAudio(true);
          dispatch(initLoad());
          await axios.post(UPLOAD_URL, formData, { headers }).then((res) => {
            dispatch(stopLoad());
            let { filesArray, urlsArray } = res.data;
            setFileName(filesArray[0].originalname);
            setMediaSize(filesArray[0].size);
            setMedia(urlsArray);
            setTextMessage("");
          });
        } catch (error) {
          console.error("Error uploading audio:", error);
          setIsAudio(false);
          dispatch(stopLoad());
        }
      };

      recorder.start();
      setIsAttachment(false);
      setIsImage(false);
      setIsVideo(false);
      setIsRecording(true);
      setMediaStream(stream);
      setMediaRecorder(recorder);
    } catch (err) {
      console.error("Error accessing media devices", err);
    }
  };
  const stopRecording = async () => {
    try {
      console.log("Stopping recording");
      mediaRecorder.stop();
      setIsRecording(false);
      mediaStream.getTracks().forEach((track) => track.stop());
    } catch (err) {
      console.error("Error accessing media devices", err);
    }
  };

  const mainArea = useMemo(() => {
    return (
      <MediaView
        data={
          media
          // "http://commondatastorage.googleapis.com/gtv-videos-bucket/sample/BigBuckBunny.mp4"
        }
        isImage={isImage}
        isVideo={isVideo}
        isAttachment={isAttachment}
        isAudio={isAudio}
        handleMediaClose={handleMediaClose}
      />
    );
  }, [media]);

  return (
    <div className="new-message message-box-main mt-26">
      <div className="p-20">
        <div className="row">
          <div className="col-sm-12">
            <div className="details">
              <h5>New Message</h5>
              {/* <p>Online</p> */}
              <div className="d-flex gap-2 mt-3">
                {/* <div className="peoples">
                  <span>
                    Shabbeer <span className="close">X</span>
                  </span>
                </div>
                <div className="peoples">
                  <span>
                    Althuxi <span className="close">X</span>
                  </span>
                </div> */}
                <TagsInput
                  tags={newMembers}
                  setTags={setNewMembers}
                  placeholder={"Type a name or multiple names"}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="line"></div>
      <div className="chatting">
        <div className="p-20">
          <div
            className="messages-list"
            style={showEmoji || showGif ? { height: "30vh" } : {}}
          >
            <div className="text-center">No Messages</div>
          </div>
          {showEmoji && (
            <div className="addemoji">
              <EmojiPicker
                height={"100%"}
                width={"100%"}
                onEmojiClick={onEmojiClick}
                autoFocusSearch={false}
                Theme="light" //Control light and dark theme(will be used in upcoming milestones)
                // searchPlaceholder=""
              />
            </div>
          )}
          {showGif && (
            <div className="addemoji">
              <GiphyPicker onSelect={onGifSelect} />
            </div>
          )}
          <div className="write">
            <div className="row align-items-end w-100">
              <div className="col-lg-3 col-sm-3 px-sm-0 mb-2">
                <div className="d-flex align-items-center">
                  <div
                    className="image"
                    onClick={() => {
                      try {
                        imgRef?.current?.click();
                      } catch (error) {
                        console.log(error);
                      }
                    }}
                  ></div>
                  <div
                    className="icon-video"
                    onClick={() => {
                      try {
                        vidRef?.current?.click();
                      } catch (error) {
                        console.log(error);
                      }
                    }}
                  ></div>
                  <div
                    className="attach"
                    onClick={() => {
                      try {
                        attachRef?.current?.click();
                      } catch (error) {
                        console.log(error);
                      }
                    }}
                  ></div>
                  <div
                    className="gif"
                    onClick={() => {
                      setShowGif(!showGif);
                      setShowEmoji(false);
                    }}
                  ></div>
                </div>
              </div>
              <div className="col-10 col-sm-7">
                <div className="reply p-1">
                  {!(isAttachment || isImage || isVideo || isAudio) && (
                    <div className="d-flex align-items-center ps-4">
                      {!isRecording ? (
                        <>
                          <textarea
                            type="text"
                            placeholder="Type your message..."
                            className="form-control"
                            value={textMessage}
                            rows={1}
                            onChange={(e) => setTextMessage(e.target.value)}
                            onKeyDown={(e) => {
                              if (
                                e.key === "Enter" &&
                                !e.shiftKey &&
                                sendType === "enter"
                              ) {
                                e.preventDefault();
                                onSendMessage(e.target.value);
                              }
                            }}
                            // onKeyUp={(e) =>
                            //   e.key !== "Enter" ? keyup() : false
                            // }
                          />
                          <div
                            className="emojee"
                            onClick={() => {
                              setShowEmoji(!showEmoji);
                              setShowGif(false);
                            }}
                          ></div>
                        </>
                      ) : (
                        <>
                          <div className="w-100">
                            <h5>Recording</h5>
                          </div>
                          <span className="">{`${String(
                            Math.floor(timer / 60)
                          ).padStart(2, "0")}:${String(timer % 60).padStart(
                            2,
                            "0"
                          )}`}</span>
                        </>
                      )}
                      {!isRecording ? (
                        <div
                          className="mic"
                          onClick={() => startRecording()}
                        ></div>
                      ) : (
                        <div
                          className="stop-mic"
                          onClick={() => stopRecording()}
                        ></div>
                      )}
                    </div>
                  )}
                  {(isAttachment || isImage || isVideo || isAudio) && mainArea}
                </div>
              </div>
              <div className="col-2 col-sm-2 my-2">
                <div className="d-flex">
                  <div
                    className="send-icon"
                    onClick={() => onSendMessage(textMessage)}
                  ></div>
                  <div className="bottom-dropdown">
                    <div
                      className="dot"
                      onClick={() => setBottomDropDownOpen(!bottomDropDownOpen)}
                    >
                      <span>&bull;</span>
                      <span>&bull;</span>
                      <span>&bull;</span>
                    </div>
                    {bottomDropDownOpen && (
                      <div className="drops-list">
                        <ul>
                          <li className="mb-2">
                            <input
                              id="send-enter"
                              type="radio"
                              name="enter-type"
                              checked={sendType === "enter"}
                              onChange={(e) => setSendType("enter")}
                            />
                            <label for="send-enter">
                              <h5>Press Enter to Send</h5>
                              <p>Pressing Enter will send message</p>
                            </label>
                          </li>

                          <li>
                            <input
                              id="send-click"
                              type="radio"
                              name="enter-type"
                              checked={sendType === "click"}
                              onChange={(e) => setSendType("click")}
                            />
                            <label for="send-click">
                              <h5>Click Send Clicking</h5>
                              <p>Send will send message</p>
                            </label>
                          </li>
                        </ul>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
            <input
              type="file"
              ref={imgRef}
              accept="image/png, image/gif, image/jpeg"
              id="chooseImageFile"
              onChange={(e) => handleMediaChange(e, "image")}
              // onClick={(e) => (e.target.value = "")}
              // multiple
            />
            <input
              type="file"
              accept="video/mp4,video/x-m4v,video/*"
              id="chosefile2"
              ref={vidRef}
              onChange={(e) => handleMediaChange(e, "video")}
              // onClick={(e) => (e.target.value = "")}
            />
            <input
              type="file"
              accept="pdf,ppt,pptx,txt,doc,docx,xls,xlsx"
              id="chosefile3"
              ref={attachRef}
              onChange={(e) => handleMediaChange(e, "attachment")}
              // onClick={(e) => (e.target.value = "")}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
