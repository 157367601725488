import React from "react";
import image from "../../../assets/img/nobel.svg";
// import "./Directory.scss";
import { useNavigate } from "react-router-dom";
import InfiniteScroll from "react-infinite-scroll-component";

const NewsletterDirectory = (props) => {
  const {
    publicNewslettersList,
    SearchByText,
    setSearchKey,
    SearchAlphabaticly,
    loadMoreData,
    totalCount,
    handleEnterSubmit,
  } = props;
  const navigate = useNavigate();

  const navigateToCompanyDetail = (id) => {
    navigate(`/directory/newsletterdetail/?id=${id}`);
  };

  console.log(totalCount, publicNewslettersList?.length);

  return (
    <div className="directory">
      <div className="header">
        <div className="container">
          <div className="row">
            <div className="col-lg-8">
              <img
                src={image}
                alt=""
                style={{ cursor: "pointer" }}
                onClick={() => navigate("/")}
              />
            </div>
            <div className="col-lg-4">
              <div className="search">
                <input
                  type="text"
                  placeholder="Search"
                  onChange={(e) => setSearchKey(e.target.value)}
                  onKeyDown={(e) => handleEnterSubmit(e)}
                />
                <div
                  className="search-img"
                  onClick={() => SearchByText()}
                ></div>
              </div>
            </div>
          </div>
        </div>
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <h1>Directory / Newsletters</h1>
              <p className="mt-3">
              Browse through our directory to discover talented individuals,
               entrepreneurs, and industry experts from around the world.
              </p>
            </div>
          </div>
        </div>
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="browse">
                <h3>Browse By</h3>
                <ul className="mb-4 mt-3">
                  <li onClick={() => SearchAlphabaticly("A")}>A</li>
                  <li onClick={() => SearchAlphabaticly("B")}>B</li>
                  <li onClick={() => SearchAlphabaticly("C")}>C</li>
                  <li onClick={() => SearchAlphabaticly("D")}>D</li>
                  <li onClick={() => SearchAlphabaticly("E")}>E</li>
                  <li onClick={() => SearchAlphabaticly("F")}>F</li>
                  <li onClick={() => SearchAlphabaticly("G")}>G</li>
                  <li onClick={() => SearchAlphabaticly("H")}>H</li>
                  <li onClick={() => SearchAlphabaticly("I")}>I</li>
                  <li onClick={() => SearchAlphabaticly("J")}>J</li>
                  <li onClick={() => SearchAlphabaticly("K")}>K</li>
                  <li onClick={() => SearchAlphabaticly("L")}>L</li>
                  <li onClick={() => SearchAlphabaticly("M")}>M</li>
                  <li onClick={() => SearchAlphabaticly("N")}>N</li>
                  <li onClick={() => SearchAlphabaticly("O")}>O</li>
                  <li onClick={() => SearchAlphabaticly("P")}>P</li>
                  <li onClick={() => SearchAlphabaticly("Q")}>Q</li>
                  <li onClick={() => SearchAlphabaticly("R")}>R</li>
                  <li onClick={() => SearchAlphabaticly("S")}>S</li>
                  <li onClick={() => SearchAlphabaticly("T")}>T</li>
                  <li onClick={() => SearchAlphabaticly("U")}>U</li>
                  <li onClick={() => SearchAlphabaticly("V")}>V</li>
                  <li onClick={() => SearchAlphabaticly("W")}>W</li>
                  <li onClick={() => SearchAlphabaticly("X")}>X</li>
                  <li onClick={() => SearchAlphabaticly("Y")}>Y</li>
                  <li onClick={() => SearchAlphabaticly("Z")}>Z</li>
                  {/* <li
                    onClick={() =>  SearchAllCompany()}
                  >
                    All
                  </li> */}
                </ul>
              </div>
              <InfiniteScroll
                dataLength={
                  publicNewslettersList?.length
                    ? publicNewslettersList?.length
                    : 0
                }
                next={loadMoreData}
                hasMore={
                  totalCount === 0
                    ? false
                    : totalCount <= publicNewslettersList?.length
                    ? false
                    : true
                }
                style={{ overflow: "visible" }}
                loader={<p>Loading...</p>}
                endMessage={
                  <p>
                    {publicNewslettersList.length === 0 ? (
                      <b>Nothing found!</b>
                    ) : (
                      <></>
                    )}
                  </p>
                }
              >
                <div className="community">
                  <div className="row list">
                    {publicNewslettersList?.map((item, index) => {
                      return (
                        <div
                          key={index}
                          className="col-lg-4 list-name card-control-text-oneline"
                          onClick={() => navigateToCompanyDetail(item?.id)}
                        >
                          {item?.title}
                        </div>
                      );
                    })}
                  </div>
                </div>
              </InfiniteScroll>
              <div className="line mt-20 mb-20"> </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NewsletterDirectory;
