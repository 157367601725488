import React from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

export default function Footer() {
  const { firstFooter } = useSelector((state) => state.landingPage);
  console.log(firstFooter);

  return (
    <div>
      <footer>
        <div className="container">
          <div className="line mt-3" style={{ padding: "10px" }}></div>
          <div className="py-3" style={{ marginTop: "-18px" }}>
            <div className="text-center">
              <ul>
                <li className=" me-0">
                  {firstFooter?.title?.replace(
                    "YYYY",
                    new Date().getFullYear()
                  )}{" "}
                </li>
                <span className="disc-size">&#x2022;</span>
                {firstFooter?.body?.map((item, idx) => (
                  <>
                    <li>
                      <Link to={`/${item.router_link}`}>
                        <span>{item?.title}</span>
                      </Link>
                    </li>
                  </>
                ))}
              </ul>
            </div>
          </div>
        </div>
      </footer>
    </div>
  );
}
