import React, { useEffect, useState } from "react";
import CreateOrder from "./createOrder";
import { useDispatch, useSelector } from "react-redux";
import { getUserProfile } from "../../../store/profile/profileActions";
import {
  getScreeningRequest,
  updateBasicInfo,
} from "../../../store/backgroundScreening/backgroundScreeningAction";
import { useLocation } from "react-router-dom";

export const CreateOrderContainer = () => {
  const { profileData } = useSelector((state) => state.profile);
  const datas = useSelector((state) => state.backgroundScreen);
  const { state } = useLocation();
  const dispatch = useDispatch();
  const token = window.localStorage.getItem("accessToken");
  const [selectedCountry, setSelectedCountry] = useState(0);
  const [selectedState, setSelectedState] = useState(0);
  useEffect(() => {
    dispatch(getUserProfile(0, token));

    dispatch(getScreeningRequest({ limit: 100, skip: 0 }));
  }, []);
  const [step, setStep] = useState({
    basicInfo: true,
    employment: [],
    education: [],
    criminal: false,
    authorization: true,
  });
  const [completedStep, setCompletedStep] = useState(["step1"]);
  const [activeStep, setActiveStep] = useState(0);
  // const [data, setData] = useState({
  //   basicInfo: {
  //     info: {
  //       first_name: profileData?.first_name || "",
  //       middle_name: "",
  //       last_name: profileData?.last_name || "",
  //       name_type: "",
  //     },
  //     otherInfo: {
  //       first_name: "",
  //       middle_name: "",
  //       last_name: "",
  //       name_type: "",
  //     },
  //     ssn: "",
  //     gender: profileData?.gender || "",
  //     dob: profileData?.dob || null,
  //     country: profileData?.country?.name || "",
  //     nationality: profileData?.country?.name || "",
  //     additional_nationality: "",
  //     applicable_nationality: false,
  //     residence: profileData?.country?.name || "",
  //     state: profileData?.state?.name || "",
  //     applicable_state: false,
  //     city: profileData?.city?.name || "",
  //     zip: profileData?.pincode || "",
  //     address: profileData?.location || "",
  //     applicant_phone: profileData?.phone || "",
  //     applicant_email: profileData?.email || "",
  //     document: [],
  //   },

  //   employment: [],
  //   education: [],

  //   criminal: {},
  //   authorization: {
  //     first_name: "",
  //     middle_name: "",
  //     last_name: "",
  //     dob: null,
  //     country: "",
  //     citizenship: "",
  //     address: "",
  //     obtain_request: false,
  //     signature: "",
  //     date: null,
  //   },
  // });

  useEffect(() => {
    if (!datas?.basicInfo?.info?.first_name)
      dispatch(
        updateBasicInfo({
          info: {
            first_name: profileData?.first_name || "",
            middle_name: "",
            last_name: profileData?.last_name || "",
            name_type: "",
          },
          otherInfo: {
            first_name: "",
            middle_name: "",
            last_name: "",
            name_type: "",
          },
          ssn: "",
          gender: profileData?.gender || "",
          dob: profileData?.dob || null,
          country: profileData?.country?.name || "",
          nationality: profileData?.country?.name || "",
          additional_nationality: "",
          applicable_nationality: false,
          residence: "",
          state: "",
          applicable_state: false,
          city: "",
          zip: profileData?.pincode || "",
          address: profileData?.location || "",
          applicant_phone: profileData?.phone || "",
          applicant_email: profileData?.email || "",
          document: [],
        })
      );
    // setData({
    //   basicInfo: {
    //     info: {
    //       first_name: profileData?.first_name || "",
    //       middle_name: "",
    //       last_name: profileData?.last_name || "",
    //       name_type: "",
    //     },
    //     otherInfo: {
    //       first_name: "",
    //       middle_name: "",
    //       last_name: "",
    //       name_type: "",
    //     },
    //     ssn: "",
    //     gender: profileData?.gender || "",
    //     dob: profileData?.dob || null,
    //     country: profileData?.country?.name || "",
    //     nationality: profileData?.country?.name || "",
    //     additional_nationality: "",
    //     applicable_nationality: false,
    //     residence: "",
    //     state: "",
    //     applicable_state: false,
    //     city: "",
    //     zip: profileData?.pincode || "",
    //     address: profileData?.location || "",
    //     applicant_phone: profileData?.phone || "",
    //     applicant_email: profileData?.email || "",
    //     document: [],
    //   },

    //   employment: [],
    //   education: [],

    //   criminal: {},
    //   authorization: {
    //     first_name: "",
    //     middle_name: "",
    //     last_name: "",
    //     dob: null,
    //     country: "",
    //     citizenship: "",
    //     address: "",
    //     obtain_request: false,
    //     signature: "",
    //     date: null,
    //   },
    // });
  }, [profileData]);

  // console.log(data);
  return (
    <CreateOrder
      step={step}
      setStep={setStep}
      user={profileData}
      completedStep={completedStep}
      setCompletedStep={setCompletedStep}
      activeStep={activeStep}
      setActiveStep={setActiveStep}
      data={datas}
      selectedCountry={selectedCountry}
      setSelectedCountry={setSelectedCountry}
      setSelectedState={setSelectedState}
      selectedState={selectedState}
      editMode={state?.edit}
    />
  );
};

// employment
// {
//   company_name: "",
//   country: "",
//   contact_name: "",
//   state: "",
//   applicable_state: false,
//   city: "",
//   phone: "",
//   email: "",
//   zip: "",
//   company_address: "",
//   is_current: false,
//   position: "",
//   comment: "",
//   start_date: null,
//   end_date: null,
//   saperation_type: "",
//   document: [],
// },

// Education
// {
// school_name: "",
// country: "",
// campus: "",
// state: "",
// applicable_state: false,
// city: "",
// phone: "",
// email: "",
// zip: "",
// address: "",
// student_id: "",
// major: "",
// degree: "",
// comment: "",
// gpa: "",
// start_date: null,
// end_date: null,
// graduated: false,
// highest_achieved: false,
// transcript: false,
// document: [],
// },

// criminal
// {
//   residence: "",
//   nationality: "",
// }
