import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getAllCompany,
  getAllMembers,
  getAllPublicArticles,
} from "../../../store/directory/directoryAction";
import { useState } from "react";
import PeopleDirectory from "./ArticleDirectory";
import ArticleDirectory from "./ArticleDirectory";

const ArticleDirectoryContainer = () => {
  const dispatch = useDispatch();
  const [searchKey, setSearchKey] = useState("");
  const [currentCount, setCurrentCount] = useState(0);
  const [totalCount, setTotalCount] = useState(500);
  const [searchAlphabaticly, setSearchAlphabaticly] = useState(false);
  const { publicArticlesList } = useSelector((state) => state.directory);

  useEffect(() => {
    if (searchKey.length > 0) {
      SearchByText();
    } else if (searchAlphabaticly) {
      SearchAlphabaticly();
    } else SearchAllArticles();
  }, [currentCount]);

  const SearchByText = () => {
    dispatch(
      getAllPublicArticles({
        skip: 0,
        search: searchKey ? searchKey : "",
        limit: "300",
      })
    ).then((res) => setTotalCount(res.count));
  };

  const SearchAlphabaticly = (text) => {
    setSearchAlphabaticly(true);
    dispatch(
      getAllPublicArticles({ skip: 0, alpha_search: text, limit: "300" })
    ).then((res) => setTotalCount(res.count));
  };

  const SearchAllArticles = () => {
    dispatch(getAllPublicArticles({ skip: currentCount, limit: "500" })).then(
      (res) => setTotalCount(res.count)
    );
  };

  useEffect(() => {
    if (!searchKey) {
      SearchAllArticles();
    }
  }, [searchKey]);

  const loadMoreData = () => {
    // setTimeout(() => {
    setCurrentCount(currentCount + 500);
    // }, 2000);
  };

  const handleEnterSubmit = (e) => {
    if (e.key === "Enter") {
      SearchByText();
    }
  };

  return (
    <ArticleDirectory
      publicArticlesList={publicArticlesList}
      searchKey={searchKey}
      setSearchKey={setSearchKey}
      SearchByText={SearchByText}
      SearchAlphabaticly={SearchAlphabaticly}
      SearchAllArticles={SearchAllArticles}
      totalCount={totalCount}
      setCurrentCount={setCurrentCount}
      handleEnterSubmit={handleEnterSubmit}
      loadMoreData={loadMoreData}
    />
  );
};

export default ArticleDirectoryContainer;
