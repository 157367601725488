import React from "react";
import { call } from "./apiCall";
class HelpApi {
  contactUS(data) {
    return new Promise((resolve, reject) => {
      (async () => {
        try {
          const res = await call("post", "api/v1/support", null, data, null);
          if (res.data.success === true) {
            resolve(res.data);
          }
          if (res.data.success === false) {
            resolve(res.data);
          }
        } catch (err) {
          console.error(err);
          reject(err);
        }
      })();
    });
  }

  faqsSearch(params) {
    return new Promise((resolve, reject) => {
      (async () => {
        try {
          const res = await call("get", `api/v1/faq`, params, null, null);

          if (res.data.success === true) {
            resolve(res.data);
          }
          if (res.data.success === false) {
            resolve(res.data);
          }
        } catch (err) {
          console.error(err);
          reject(err);
        }
      })();
    });
  }
  faqFeedback(data) {
    return new Promise((resolve, reject) => {
      (async () => {
        try {
          const res = await call(
            "post",
            "api/v1/faq-feedback",
            null,
            data,
            null
          );
          if (res.data.success === true) {
            resolve(res.data);
          }
          if (res.data.success === false) {
            resolve(res.data);
          }
        } catch (err) {
          console.error(err);
          reject(err);
        }
      })();
    });
  }
}

export const helpApi = new HelpApi();
