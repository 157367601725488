import React, { useEffect, useState } from "react";
import Newsletter from "./Newsletter";
import { useDispatch, useSelector } from "react-redux";
import { getSuggestedNewsletters } from "../../../store/newsletter/newsletterActions";
import {
  subscribeNewsletter,
  unsubscribeNewsletter,
} from "../../../store/newsletter/newsletterActions";
import { useNavigate } from "react-router-dom";
import DOMPurify from "dompurify";
import { initLoad, stopLoad } from "../../../store/loader/loaderActions";
import { getPageDetails } from "../../../store/dashboard/dashboardActions";

export default function NewsletterContainer() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { suggested_newsletters } = useSelector((state) => state.newsletter);
  const { subscribed_newsletters } = useSelector((state) => state.newsletter);
  const { my_newsletters } = useSelector((state) => state.newsletter);
  const { drafts } = useSelector((state) => state.newsletter);
  const { user } = useSelector((state) => state.auth);

  const [createModal, setCreateModal] = useState(false);
  const [searchKey, setSearchKey] = useState("");
  const [searchVal, setSearchVal] = useState(0);
  const [updatedNewsletter, setUpdatedNewsletter] = useState([]);
  const [newSubscribedNewsletter, setNewSubscribedNewsletter] = useState([]);
  const [newMyNewsletter, setNewMyNewsletter] = useState([]);
  const [newDrafts, setNewDrafts] = useState([]);
  const [successModal, setSuccessModal] = useState(false);
  const [popupShareData, setPopupShareData] = useState("");
  const [currentCount, setCurrentCount] = useState(0);
  const [totalCount, setTotalCount] = useState(0);
  const { isLoading } = useSelector((state) => state.loader);
  const { getPageDetailData } = useSelector((state) => state.dashboard);
  const [tabs, setTabs] = useState("suggested_newsletter");
  const token = localStorage.getItem("accessToken");

  useEffect(() => {
    dispatch(initLoad());
    const token = localStorage.getItem("accessToken");
    if (tabs === "suggested_newsletter") {
      if (searchKey === "") {
        let query = "suggested";
        dispatch(
          getSuggestedNewsletters(token, query, {
            type: "suggested",
            skip: currentCount,
            limit: 10,
          })
        ).then((res) => {
          setTotalCount(res?.count);
          dispatch(stopLoad());
        });
      } else {
        let query = "suggested";
        dispatch(
          getSuggestedNewsletters(token, query, {
            type: "suggested",
            skip: currentCount,
            limit: 10,
            search: searchKey,
          })
        ).then((res) => {
          setTotalCount(res?.count);
          dispatch(stopLoad());
        });
      }
    } else if (tabs === "subscribed_newsletter") {
      let query = "subscribed";
      console.log(tabs);
      dispatch(
        getSuggestedNewsletters(
          token,
          query,
          {
            type: "subscribed",
            skip: currentCount,
            limit: 10,
            search: searchKey,
          },
          currentCount
        )
      ).then((res) => {
        setTotalCount(res?.count);
        dispatch(stopLoad());
      });
    } else if (tabs === "my_newsletter") {
      let query = "my";
      dispatch(
        getSuggestedNewsletters(
          token,
          query,
          { type: "my", skip: currentCount, limit: 10, search: searchKey },
          currentCount
        )
      ).then((res) => {
        setTotalCount(res?.count);
        dispatch(stopLoad());
      });
    } else if (tabs === "my_drafts") {
      let query = "draft";
      dispatch(
        getSuggestedNewsletters(
          token,
          query,
          { type: "draft", skip: currentCount, limit: 10 },
          currentCount
        )
      ).then((res) => {
        setTotalCount(res?.count);
        dispatch(stopLoad());
      });
    }
  }, [tabs, currentCount, searchVal]);

  useEffect(() => {
    if (currentCount > 0) {
      setUpdatedNewsletter([...updatedNewsletter, ...suggested_newsletters]);
    } else {
      setUpdatedNewsletter(suggested_newsletters);
    }
  }, [suggested_newsletters]);

  useEffect(() => {
    if (currentCount > 0) {
      setNewSubscribedNewsletter([
        ...newSubscribedNewsletter,
        ...subscribed_newsletters,
      ]);
    } else {
      setNewSubscribedNewsletter(subscribed_newsletters);
    }
  }, [subscribed_newsletters]);

  useEffect(() => {
    setNewMyNewsletter(my_newsletters);
  }, [my_newsletters]);

  useEffect(() => {
    setNewDrafts(drafts);
  }, [drafts]);
  useEffect(() => {
    setCurrentCount(0);
  }, [tabs]);

  useEffect(() => {
    dispatch(getPageDetails(token));
  }, []);

  // const filterSearchData = (searchKey) => {
  //   setCurrentCount(0);
  //   setSearchVal(!searchVal);
  // };

  const filterSearchData = (searchKey) => {
    setCurrentCount(0);
    if (tabs === "suggested_newsletter") {
      let query = "suggested";
      // const result = suggested_newsletters.filter(
      //   (newsletters) =>
      //     newsletters?.title
      //       .toString()
      //       .toLowerCase()
      //       .includes(searchKey.toLowerCase())
      //    || offers?.quantity.toLowerCase().includes(searchKey)
      // );
      // console.log(result);
      // setUpdatedNewsletter(result);
      dispatch(
        getSuggestedNewsletters(
          token,
          query,
          {
            type: "suggested",
            skip: currentCount,
            limit: 10,
            search: searchKey,
          },
          currentCount
        )
      ).then((res) => {
        setTotalCount(res?.count);
      });
      console.log(tabs);
    } else if (tabs === "subscribed_newsletter") {
      const result = subscribed_newsletters.filter((newsletters) =>
        newsletters?.title
          .toString()
          .toLowerCase()
          .includes(searchKey.toLowerCase())
      );
      setNewSubscribedNewsletter(result);
      console.log(tabs);
      let query = "subscribed";
      dispatch(
        getSuggestedNewsletters(
          token,
          query,
          {
            type: "subscribed",
            skip: currentCount,
            limit: 10,
            search: searchKey,
          },
          currentCount
        )
      );
    } else if (tabs === "my_newsletter") {
      const result = my_newsletters.filter((newsletters) =>
        newsletters?.title
          .toString()
          .toLowerCase()
          .includes(searchKey.toLowerCase())
      );
      setNewMyNewsletter(result);
      let query = "my";
      dispatch(
        getSuggestedNewsletters(
          token,
          query,
          { type: "my", skip: currentCount, limit: 10, search: searchKey },
          currentCount
        )
      );
    } else if (tabs === "my_drafts") {
      const result = drafts.filter((newsletters) =>
        newsletters?.title
          .toString()
          .toLowerCase()
          .includes(searchKey.toLowerCase())
      );
      setNewDrafts(result);

      let query = "draft";
      dispatch(
        getSuggestedNewsletters(
          token,
          query,
          { type: "draft", skip: currentCount, limit: 10, search: searchKey },
          currentCount
        )
      );
    }
  };

  const subscribeHandle = (id) => {
    const token = localStorage.getItem("accessToken");
    dispatch(subscribeNewsletter(id, token));
  };

  const UnsubscribeHandle = (id) => {
    const token = localStorage.getItem("accessToken");
    dispatch(unsubscribeNewsletter(id, token));
  };

  const readMore = (id) => {
    navigate(`/newsletterdetail/${id}`);
  };

  const loadMoreData = () => {
    // setCurrentCount(currentCount + 10);

    setTimeout(() => {
      setCurrentCount(currentCount + 10);
    }, 2000);

    // if(updatedNewsletter?.length<totalCount && currentCount+10<=totalCount && tabs === "suggested"){

    //   setCurrentCount(currentCount + 10, console.log('heyyyyyy',currentCount));
    // }

    // if(newSubscribedNewsletter?.length<totalCount && currentCount+10<=totalCount && tabs === "subscribed_newsletter"){

    //   setCurrentCount(currentCount + 10, console.log('heyyyyyy_sub',currentCount));
    // }

    // if(newMyNewsletter?.length<totalCount && currentCount<=totalCount+10 && tabs === "my"){

    //   setCurrentCount(currentCount + 10, console.log('heyyyyyy_sub',currentCount));
    // }

    // else if(tabs === "subscribed_newsletter"){
    //   if(newSubscribedNewsletter?.length<totalCount){

    //     setCurrentCount(currentCount + 10, console.log('heyyyyyy',currentCount));
    //   }
    // }
  };

  function createMarkup(html) {
    return {
      __html: DOMPurify.sanitize(html),
    };
  }

  const successfulStatus = (shareData) => {
    setPopupShareData(shareData);
    setSuccessModal(!successModal);
  };

  return (
    <Newsletter
      suggested_newsletters={suggested_newsletters}
      createModal={createModal}
      setCreateModal={setCreateModal}
      filterSearchData={filterSearchData}
      searchKey={searchKey}
      setSearchKey={setSearchKey}
      updatedNewsletter={updatedNewsletter}
      tabs={tabs}
      setTabs={setTabs}
      subscribeHandle={subscribeHandle}
      readMore={readMore}
      newSubscribedNewsletter={newSubscribedNewsletter}
      newMyNewsletter={newMyNewsletter}
      createMarkup={createMarkup}
      successfulStatus={successfulStatus}
      popupShareData={popupShareData}
      successModal={successModal}
      setSuccessModal={setSuccessModal}
      newDrafts={newDrafts}
      UnsubscribeHandle={UnsubscribeHandle}
      navigate={navigate}
      currentCount={currentCount}
      loadMoreData={loadMoreData}
      totalCount={totalCount}
      isPageLoading={isLoading}
      user={user}
      getPageDetailData={getPageDetailData}
    />
  );
}
