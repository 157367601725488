import React, { useEffect, useState } from "react";
import Navbar from "../../pages/navbar/navbar";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  getAllPages,
  followPage,
  unFollowPage,
} from "../../store/pages/pagesAction";
import {
  getSuggestedGroups,
  joinGroups,
} from "../../store/groups/groupsActions";
import {
  attendEvents,
  getSuggestedEvents,
} from "../../store/events/eventActions";
import { getSuggestedPeopleList } from "../../store/people/peopleActions";
import {
  getSuggestedNewsletters,
  subscribeNewsletter,
  unsubscribeNewsletter,
} from "../../store/newsletter/newsletterActions";
import { getSuggestedArticles } from "../../store/articles/articleActions";

import DOMPurify from "dompurify";
import PlaceholderIcon from "../../assets/img/page-placeholder.svg";
import union from "../../assets/img/Union-blue.svg";
import add from "../../assets/img/add-icon.svg";
import unfollow from "../../assets/img/unfoll.png";
import join from "../../assets/img/join.png";
import UserIcon from "../../assets/img/black_user.svg";
import CompanyIcon from "../../assets/img/black_company.svg";
import JobIcon from "../../assets/img/black_job.svg";
import GroupIcon from "../../assets/img/black_group.svg";
import EventIcon from "../../assets/img/black_event.svg";
import ArticleIcon from "../../assets/img/black_article.svg";
import NewsletterIcon from "../../assets/img/black_newsletter.svg";
import defaultGroupIcon from "../../assets/img/default_group_icon.svg";
import {
  getCurrentCompanyData,
  getLocationsData,
  getSchoolData,
  sendConnectionRequest,
} from "../../store/connections/connectionsActions";
import AppLoader from "../../core/components/Loaders/appLoader/appLoader";
import { CLEAR_SUGGESTED_GROUPS } from "../../store/types";
import { getJobs, saveJob } from "../../store/jobs/jobAction";
import { myNetworkAPI } from "../../api/myNetworkAPI";
import Autocomplete from "react-autocomplete";
import "./Search.scss";
import SidebarFooter from "../setting/SidebarFooter";
import Ad from "../../core/components/layout/Sidebar/Ad";
const SearchScreen = () => {
  const { state } = useLocation();
  const token = localStorage.getItem("accessToken");
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [currentTab, setCurrentTab] = useState(state?.type || "");
  const [limit, setLimit] = useState(3);
  const [skip, setSkip] = useState(0);
  const [loading, setLoading] = useState(false);
  const [feedCount, setFeedCount] = useState();
  const [searchFieldValue, setSearchFieldValue] = useState("");
  const [location, setLocation] = useState({
    location_type: "",
    location_id: "",
  });
  const [selectCompany, setSelectedCompany] = useState("");
  const [selectedSchool, setSelectedSchool] = useState("");
  const [locationsAPIData, setLocationsAPIData] = useState([]);
  const [currentCompanyAPIData, setCurrentCompanyAPIData] = useState([]);
  const [schoolsAPIData, setSchoolsAPIData] = useState([]);
  const [count, setCount] = useState({
    event: 0,
    article: 0,
    newsletter: 0,
    page: 0,
    group: 0,
    people: 0,
    job: 0,
  });

  useEffect(() => {
    setCurrentTab(state.type);
  }, [state]);

  useEffect(() => {
    getCurrentCompanyList();
    getSchoolList();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);

      try {
        const results = await Promise.all([
          dispatch(
            getAllPages({ page: skip + 1, size: limit, search: state?.search })
          ),
          dispatch(
            getSuggestedPeopleList({
              type: "suggested",
              limit,
              skip,
              search: state?.search,
              location_id: location?.location_id || undefined,
              location_type: location?.location_type || undefined,
              company_name: selectCompany || undefined,
              school_name: selectedSchool || undefined,
            })
          ),
          dispatch(
            getSuggestedGroups(
              "",
              { page: skip + 1, size: limit, search: state?.search },
              skip + 1
            )
          ),
          dispatch(
            getSuggestedNewsletters(
              token,
              {},
              { limit, skip, search: state?.search },
              skip
            )
          ),
          dispatch(
            getSuggestedEvents(
              token,
              { limit, skip, search: state?.search },
              skip
            )
          ),
          dispatch(
            getSuggestedArticles(
              token,
              {},
              { search: state?.search, limit, skip },
              skip
            )
          ),
          dispatch(getJobs({ limit, skip, search: state?.search }, skip)),
        ]);

        // Extract the results and update the count based on successful responses
        const newCounts = {
          people: count.people,
          group: count.group,
          newsletter: count.newsletter,
          event: count.event,
          article: count.article,
          job: count.job,
          page: count.page,
        };

        results.forEach((res, idx) => {
          console.log(idx, res);
          if (res?.success) {
            switch (idx) {
              case 1:
                newCounts.people = res?.totalItems || count.people;
                break;
              case 2:
                newCounts.group = res?.count || count.group;
                break;
              case 3:
                newCounts.newsletter = res?.count || count.newsletter;
                break;
              case 4:
                newCounts.event = res?.count || count.event;
                break;
              case 5:
                newCounts.article = res?.count || count.article;
                break;
              case 6:
                newCounts.job = res?.totalItem || count.job;
                break;
              case 0:
                newCounts.page = res?.count || count.page;
                break;
            }
          }
        });

        setCount(newCounts);
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [
    limit,
    skip,
    location,
    selectCompany,
    selectedSchool,
    currentTab,
    state?.search,
  ]);

  const { suggested_groups } = useSelector((state) => state.groups);
  const { suggested_events } = useSelector((state) => state.events);
  const { suggested_articles } = useSelector((state) => state.article);
  const { suggested_newsletters } = useSelector((state) => state.newsletter);
  const { suggested_people } = useSelector((state) => state.people);
  const { pageData } = useSelector((state) => state.pages);
  const { getAllJobs } = useSelector((state) => state.jobs);

  const [isExpanded, setIsExpanded] = useState(false);

  const insertLineBreaks = (text, interval) => {
    if (!text) return "";
    const regex = new RegExp(`.{1,${interval}}`, "g");
    return text.match(regex).join("<br>");
  };

  const handleToggle = () => {
    setIsExpanded(!isExpanded);
  };

  const getProfileHeadline = (text, interval, isExpanded) => {
    if (isExpanded) {
      return insertLineBreaks(text, interval);
    } else {
      const truncatedText = text?.slice(0, interval * 2); // Showing up to 60 characters
      return (
        insertLineBreaks(truncatedText, interval) +
        (text?.length > truncatedText?.length ? "..." : "")
      );
    }
  };

  // Mapping of tab names to their corresponding content
  const content = {
    people:
      suggested_people?.length > 0 ? (
        <div className="people_container p-5 bg-white mt-3 card_wrapper">
          {currentTab == "" ? (
            <h4 className="mb-3">People</h4>
          ) : (
            <p className="mb-3 result-count">{count?.people} Result</p>
          )}
          {suggested_people?.map((data, index) => (
            <div key={index} className="p-2">
              <div className="d-flex justify-content-between align-items-center">
                <div
                  className="d-flex align-items-start cursor-pointer"
                  onClick={() => navigate(`/profile/${data.id}`)}
                >
                  <img
                    className="rounded-circle"
                    src={data?.profile_img || PlaceholderIcon}
                    width={70}
                    height={70}
                  />
                  <div className="d-flex flex-column mx-3">
                    <p
                      className="fw-bold text-capitalize"
                      style={{
                        wordWrap: "break-word",
                        overflowWrap: "break-word",
                      }}
                      dangerouslySetInnerHTML={{
                        __html: insertLineBreaks(
                          data?.first_name + " " + data?.last_name,
                          40
                        ),
                      }}
                    ></p>
                    <div>
                      <p
                        style={{
                          wordWrap: "break-word",
                          overflowWrap: "break-word",
                        }}
                        dangerouslySetInnerHTML={{
                          __html: getProfileHeadline(
                            data?.profile?.profile_headline,
                            35,
                            isExpanded
                          ),
                        }}
                      />
                      {data?.profile?.profile_headline?.length > 60 && (
                        <button
                          onClick={handleToggle}
                          style={{
                            border: "none",
                            background: "none",
                            color: "#0432a3",
                            cursor: "pointer",
                          }}
                        >
                          {isExpanded ? "Read Less" : "Read More"}
                        </button>
                      )}
                    </div>
                    <span>{data?.profile?.location}</span>
                  </div>
                </div>
                {data?.connection_status === "Pending" ? (
                  <div className="btn btn-light text-blue ">Pending</div>
                ) : (
                  <div
                    className="btn btn-light text-blue"
                    onClick={() => dispatch(sendConnectionRequest(data?.id))}
                  >
                    <img src={union} alt="" /> Connect
                  </div>
                )}
              </div>
            </div>
          ))}
          {currentTab == "" ? (
            <p
              className="text-center fw-bold text-secondary mt-3"
              onClick={() => {
                setCurrentTab("people");
                setLimit(10);
              }}
              style={{ cursor: "pointer" }}
            >
              See all people result
            </p>
          ) : (
            suggested_people?.length < count.people && (
              <p
                className="text-center fw-bold text-secondary mt-3"
                onClick={() => {
                  setSkip((skip) => skip + 1);
                }}
                style={{ cursor: "pointer" }}
              >
                Load More
              </p>
            )
          )}
        </div>
      ) : (
        currentTab != "" && (
          <div className="people_container p-5 bg-white mt-3 card_wrapper">
            <p className="result-count">No Result Found</p>
          </div>
        )
      ),
    company:
      pageData?.data?.length > 0 ? (
        <div className="company_container p-5 bg-white mt-3 card_wrapper">
          {currentTab == "" ? (
            <h4 className="mb-3">Companies</h4>
          ) : (
            <p className="mb-3 result-count">{count?.page} Result</p>
          )}
          {pageData?.data?.map((data, index) => (
            <div key={index} className="p-2">
              <div className="d-flex justify-content-between align-items-center">
                <div
                  className="d-flex align-items-start cursor-pointer"
                  onClick={() =>
                    navigate(`/directory/companydetail/${data?.id}`)
                  }
                >
                  <img
                    src={data?.icon ? data?.icon : PlaceholderIcon}
                    width={70}
                    height={70}
                    alt="Company icon"
                  />
                  <div className="mx-3">
                    <p
                      className="fw-bold text-capitalize"
                      onClick={() => navigate(`/pageadminview/${data.id}`)}
                    >
                      {data.name}
                    </p>
                    <p>{data.industry}</p>
                    <p>
                      {data.followers_count}{" "}
                      {data.followers_count > 1 ? "followers" : "follower"}
                    </p>
                  </div>
                </div>
                <div
                  className="btn btn-light text-blue btn-margin-bottom"
                  onClick={() =>
                    data.is_following
                      ? dispatch(unFollowPage(data.id))
                      : dispatch(followPage(data.id))
                  }
                >
                  {data.is_following ? (
                    <span>
                      <img
                        src={unfollow}
                        alt="Unfollow"
                        className="followicon"
                      />{" "}
                      Unfollow
                    </span>
                  ) : (
                    <span>
                      <img src={add} alt="Follow" className="followicon" />{" "}
                      Follow
                    </span>
                  )}
                </div>
              </div>
            </div>
          ))}
          {currentTab == "" ? (
            <p
              className="text-center fw-bold text-secondary mt-3"
              onClick={() => {
                setCurrentTab("company");
                setLimit(10);
              }}
              style={{ cursor: "pointer" }}
            >
              See all company result
            </p>
          ) : (
            pageData?.data?.length < count?.page && (
              <p
                className="text-center fw-bold text-secondary mt-3"
                onClick={() => {
                  setSkip((skip) => skip + 1);
                }}
                style={{ cursor: "pointer" }}
              >
                Load More
              </p>
            )
          )}
        </div>
      ) : (
        currentTab != "" && (
          <div className="company_container p-5 bg-white mt-3 card_wrapper">
            <p className="result-count">No Result Found</p>
          </div>
        )
      ),
    job:
      getAllJobs?.length > 0 ? (
        <div className="article_container p-5 bg-white mt-3 card_wrapper">
          {currentTab == "" ? (
            <h4 className="mb-3">Jobs</h4>
          ) : (
            <p className="mb-3 result-count">{count?.job} Result</p>
          )}
          {getAllJobs.map((data, index) => (
            <div key={index} className="p-2">
              <div className="d-flex justify-content-between align-items-center">
                <div
                  className="d-flex align-items-center cursor-pointer"
                  onClick={() => navigate(`/directory/jobdetail/${data?.id}`)}
                >
                  <img
                    src={
                      data?.company?.icon
                        ? data?.company?.icon
                        : PlaceholderIcon
                    }
                    className="rounded"
                    width={70}
                    height={70}
                  />
                  <div className="mx-3">
                    <p className="fw-bold text-capitalize">{data.title}</p>
                  </div>
                </div>
                <div
                  className="btn btn-light blue-color"
                  onClick={() => dispatch(saveJob(data?.id))}
                >
                  {data?.savedJob?.status ? "Unsave" : "Save"}
                </div>
              </div>
            </div>
          ))}
          {currentTab == "" ? (
            <p
              className="text-center fw-bold text-secondary mt-3"
              onClick={() => {
                setCurrentTab("job");
                setLimit(10);
              }}
              style={{ cursor: "pointer" }}
            >
              See all jobs result
            </p>
          ) : (
            getAllJobs?.length < count?.job && (
              <p
                className="text-center fw-bold text-secondary mt-3"
                onClick={() => {
                  setSkip((skip) => skip + 1);
                }}
                style={{ cursor: "pointer" }}
              >
                Load More
              </p>
            )
          )}
        </div>
      ) : (
        currentTab != "" && (
          <div className="article_container p-5 bg-white mt-3 card_wrapper">
            <p className="result-count">No Result Found</p>
          </div>
        )
      ),
    group:
      suggested_groups?.length > 0 ? (
        <div className="group_container p-5 bg-white mt-3 card_wrapper">
          {currentTab == "" ? (
            <h4 className="mb-3">Groups</h4>
          ) : (
            <p className="mb-3 result-count">{count?.group} Result</p>
          )}
          {suggested_groups.map((data, index) => (
            <div key={index} className="p-2">
              <div className="d-flex justify-content-between align-items-center">
                <div
                  className="d-flex align-items-center cursor-pointer"
                  onClick={() => navigate(`/groupdetails/${data?.id}`)}
                >
                  <img
                    src={data?.icon ? data.icon : defaultGroupIcon}
                    className="rounded"
                    width={70}
                    height={70}
                  />
                  <div className="mx-3">
                    <p className="fw-bold text-capitalize">{data.name}</p>
                    <div className="d-flex align-items-center">
                      {data?.type === "public" ? (
                        <>
                          <div className="globe"></div>
                          <p>Public group</p>
                        </>
                      ) : (
                        <>
                          <div className="privateIcon"></div>
                          <p>Private group</p>
                        </>
                      )}
                    </div>
                  </div>
                </div>
                <div className="d-flex">
                  {data?.members?.length === data?.members_limit ? (
                    <div className="btn btn-light disabled-btn-blue">Join</div>
                  ) : data?.is_joined === "pending" ? (
                    <div
                      className="btn btn-light"
                      onClick={() =>
                        alert("Please wait for group admin response")
                      }
                    >
                      Requested
                    </div>
                  ) : (
                    <div
                      className="btn btn-light text-blue"
                      onClick={() => dispatch(joinGroups(data.id, "joinTrue"))}
                    >
                      <div>
                        <img
                          style={{ marginRight: "9px" }}
                          src={join}
                          alt="Join"
                        />
                        Join
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          ))}
          {currentTab == "" ? (
            <p
              className="text-center fw-bold text-secondary mt-3"
              onClick={() => {
                setCurrentTab("group");
                setLimit(10);
              }}
              style={{ cursor: "pointer" }}
            >
              See all group result
            </p>
          ) : (
            suggested_groups?.length < count?.group && (
              <p
                className="text-center fw-bold text-secondary mt-3"
                onClick={() => {
                  setSkip((skip) => skip + 1);
                }}
                style={{ cursor: "pointer" }}
              >
                Load More
              </p>
            )
          )}
        </div>
      ) : (
        currentTab != "" && (
          <div className="group_container p-5 bg-white mt-3 card_wrapper">
            <p className="result-count">No Result Found</p>
          </div>
        )
      ),
    event:
      suggested_events?.length > 0 ? (
        <div className="event_container p-5 bg-white mt-3 card_wrapper">
          {currentTab == "" ? (
            <h4 className="mb-3">Events</h4>
          ) : (
            <p className="mb-3 result-count">{count?.event} Result</p>
          )}
          {suggested_events.map((data, index) => (
            <div key={index} className="p-2">
              <div className="d-flex justify-content-between align-items-center">
                <div
                  className="d-flex align-items-center cursor-pointer"
                  onClick={() => navigate(`/eventdetail/${data?.id}`)}
                >
                  <img
                    src={data?.media ? data?.media : PlaceholderIcon}
                    className="rounded"
                    width={70}
                    height={70}
                  />
                  <div className="mx-3">
                    <p className="fw-bold text-capitalize">{data.title}</p>
                  </div>
                </div>
                <div>
                  {data.Attend_Event?.status ? (
                    <div
                      className="btn btn-light text-blue"
                      onClick={() =>
                        dispatch(attendEvents(data.id, token, "attendFalse"))
                      }
                    >
                      Leave Event
                    </div>
                  ) : (
                    <div
                      className="btn btn-blue"
                      onClick={() =>
                        dispatch(attendEvents(data.id, token, "attendTrue"))
                      }
                    >
                      Attend
                    </div>
                  )}
                </div>
              </div>
            </div>
          ))}
          {currentTab == "" ? (
            <p
              className="text-center fw-bold text-secondary mt-3"
              onClick={() => {
                setCurrentTab("event");
                setLimit(10);
              }}
              style={{ cursor: "pointer" }}
            >
              See all event result
            </p>
          ) : (
            suggested_events?.length < count?.event && (
              <p
                className="text-center fw-bold text-secondary mt-3"
                onClick={() => {
                  setSkip((skip) => skip + 1);
                }}
                style={{ cursor: "pointer" }}
              >
                Load More
              </p>
            )
          )}
        </div>
      ) : (
        currentTab != "" && (
          <div className="event_container p-5 bg-white mt-3 card_wrapper">
            <p className="result-count">No Result Found</p>
          </div>
        )
      ),
    article:
      suggested_articles?.length > 0 ? (
        <div className="article_container p-5 bg-white mt-3 card_wrapper">
          {currentTab == "" ? (
            <h4 className="mb-3">Articles</h4>
          ) : (
            <p className="mb-3 result-count">{count?.article} Result</p>
          )}
          {suggested_articles.map((data, index) => (
            <div key={index} className="p-2">
              <div className="d-flex justify-content-between align-items-center">
                <div
                  className="d-flex align-items-center cursor-pointer"
                  onClick={() => navigate(`/articledetail/${data.id}`)}
                >
                  <img
                    src={data?.media ? data?.media : PlaceholderIcon}
                    className="rounded"
                    width={70}
                    height={70}
                  />
                  <div className="mx-3">
                    <p className="fw-bold text-capitalize">{data.title}</p>
                  </div>
                </div>
                <div
                  className="btn btn-light text-blue"
                  onClick={() => navigate(`/articledetail/${data.id}`)}
                >
                  Read article
                </div>
              </div>
            </div>
          ))}
          {currentTab == "" ? (
            <p
              className="text-center fw-bold text-secondary mt-3"
              onClick={() => {
                setCurrentTab("article");
                setLimit(10);
              }}
              style={{ cursor: "pointer" }}
            >
              See all article result
            </p>
          ) : (
            suggested_articles?.length < count?.article && (
              <p
                className="text-center fw-bold text-secondary mt-3"
                onClick={() => {
                  setSkip((skip) => skip + 1);
                }}
                style={{ cursor: "pointer" }}
              >
                Load More
              </p>
            )
          )}
        </div>
      ) : (
        currentTab != "" && (
          <div className="article_container p-5 bg-white mt-3 card_wrapper">
            <p className="result-count">No Result Found</p>
          </div>
        )
      ),
    newsletter:
      suggested_newsletters?.length > 0 ? (
        <div className="newsletter_container p-5 bg-white mt-3 card_wrapper">
          {currentTab == "" ? (
            <h4 className="mb-3">Newsletters</h4>
          ) : (
            <p className="mb-3 result-count">{count?.newsletter} Result</p>
          )}
          {suggested_newsletters.map((data, index) => (
            <div key={index} className="p-2">
              <div className="d-flex justify-content-between align-items-center">
                <div
                  className="d-flex align-items-center cursor-pointer"
                  onClick={() => navigate(`/newsletterdetail/${data.id}`)}
                >
                  <img
                    src={data?.media ? data?.media : PlaceholderIcon}
                    className="rounded"
                    width={70}
                    height={70}
                  />
                  <div className="mx-3">
                    <p className="fw-bold text-capitalize">{data.title}</p>
                  </div>
                </div>
                {data?.subscribed?.status ? (
                  <div
                    className="btn btn-light text-blue"
                    onClick={() => dispatch(unsubscribeNewsletter(data?.id))}
                  >
                    Unsubscribe
                  </div>
                ) : (
                  <div
                    className="btn btn-light text-blue"
                    onClick={() => dispatch(subscribeNewsletter(data?.id))}
                  >
                    Subscribe
                  </div>
                )}
              </div>
            </div>
          ))}
          {currentTab == "" ? (
            <p
              className="text-center fw-bold text-secondary mt-3"
              onClick={() => {
                setCurrentTab("newsletter");
                setLimit(10);
              }}
              style={{ cursor: "pointer" }}
            >
              See all newsletter result
            </p>
          ) : (
            suggested_newsletters?.length < count?.newsletter && (
              <p
                className="text-center fw-bold text-secondary mt-3"
                onClick={() => {
                  setSkip((skip) => skip + 1);
                }}
                style={{ cursor: "pointer" }}
              >
                Load More
              </p>
            )
          )}
        </div>
      ) : (
        currentTab != "" && (
          <div className="newsletter_container p-5 bg-white mt-3 card_wrapper">
            <p className="result-count">No Result Found</p>
          </div>
        )
      ),
  };

  const getLocations = ({ value }) => {
    if (value)
      dispatch(getLocationsData(value))
        .then((response) => {
          if (response.data) {
            setLocationsAPIData(response.data);
          } else setLocationsAPIData([]);
        })
        .catch((err) => {
          // console.log("error!!", err);
          setLocationsAPIData([]);
        });
    else setLocationsAPIData([]);
  };

  const getCurrentCompanyList = () => {
    dispatch(getCurrentCompanyData())
      .then((response) => {
        if (response.data) setCurrentCompanyAPIData(response.data);
        else setCurrentCompanyAPIData([]);
      })
      .catch((err) => {
        // console.log("error!!", err);
        setCurrentCompanyAPIData([]);
      });
  };

  const getSchoolList = () => {
    dispatch(getSchoolData())
      .then((response) => {
        if (response.data) setSchoolsAPIData(response.data);
        else setSchoolsAPIData([]);
      })
      .catch((err) => {
        // console.log("error!!", err);
        setSchoolsAPIData([]);
      });
  };
  return (
    <div className="search">
      <Navbar searchValue={state.search} />
      <div className="container">
        {currentTab == "" ? (
          <div className="d-flex justify-content-around align-items-center p-2 bg-white mt-3">
            <div
              onClick={() => {
                setCurrentTab("people");
                setLimit(10);
                setSkip(0);
              }}
              style={{ cursor: "pointer" }}
              className="nav-item d-flex align-items-end"
            >
              <img src={UserIcon} width={25} height={25} />
              <p className="text-dark fw-bold mx-2">Peoples</p>
            </div>
            <div
              onClick={() => {
                setCurrentTab("company");
                setLimit(10);
                setSkip(0);
              }}
              style={{ cursor: "pointer" }}
              className="nav-item  d-flex align-items-end"
            >
              <img src={CompanyIcon} width={25} height={25} />
              <p className="text-dark fw-bold mx-2">Companies</p>
            </div>
            <div
              onClick={() => {
                setCurrentTab("job");
                setLimit(10);
                setSkip(0);
              }}
              style={{ cursor: "pointer" }}
              className="nav-item  d-flex align-items-end"
            >
              <img src={JobIcon} width={25} height={25} />
              <p className="text-dark fw-bold mx-2">Jobs</p>
            </div>
            <div
              onClick={() => {
                setCurrentTab("group");
                setLimit(10);
                setSkip(0);
              }}
              style={{ cursor: "pointer" }}
              className="nav-item  d-flex align-items-end"
            >
              <img src={GroupIcon} width={25} height={25} />
              <p className="text-dark fw-bold mx-2">Groups</p>
            </div>
            <div
              onClick={() => {
                setCurrentTab("event");
                setLimit(10);
                setSkip(0);
              }}
              style={{ cursor: "pointer" }}
              className="nav-item  d-flex align-items-end"
            >
              <img src={EventIcon} width={25} height={25} />
              <p className="text-dark fw-bold mx-2">Events</p>
            </div>
            <div
              onClick={() => {
                setCurrentTab("article");
                setLimit(10);
                setSkip(0);
              }}
              style={{ cursor: "pointer" }}
              className="nav-item  d-flex align-items-end"
            >
              <img src={ArticleIcon} width={25} height={25} />
              <p className="text-dark fw-bold mx-2">Articles</p>
            </div>
            <div
              onClick={() => {
                setCurrentTab("newsletter");
                setLimit(10);
                setSkip(0);
              }}
              style={{ cursor: "pointer" }}
              className="nav-item  d-flex align-items-end"
            >
              <img src={NewsletterIcon} width={25} height={25} />
              <p className="text-dark fw-bold mx-2">Newsletters</p>
            </div>
          </div>
        ) : (
          <>
            <div className="main-bg">
              <div className="people">
                <div className="container">
                  <div className="row">
                    <div className="col-12">
                      <div className="wrappers">
                        <ul className="d-flex">
                          <li className="autocomplete-wrapper">
                            <select
                              value={currentTab}
                              className="fw-bold text-dark px-4 form-select selectOptionOption"
                              onChange={(e) => {
                                if (e.target.value == "") {
                                  setCurrentTab(e.target.value);
                                  setLocation({
                                    location_id: null,
                                    location_type: null,
                                  });
                                  setSearchFieldValue("");
                                  setSelectedCompany("");
                                  setSelectedSchool("");
                                  setLimit(3);
                                  setSkip(0);
                                } else {
                                  setCurrentTab(e.target.value);
                                  setLimit(10);
                                  setSkip(0);
                                }
                              }}
                              style={{
                                border: 0,
                              }}
                            >
                              <option value="">All</option>
                              <option value="people">People</option>
                              <option value="company">Company</option>
                              <option value="group">Group</option>
                              <option value="newsletter">Newsletter</option>
                              <option value="article">Article</option>
                              <option value="event">Event</option>
                              <option value="job">Job</option>
                            </select>
                          </li>
                          {currentTab == "people" && (
                            <>
                              <strong className="vr-line"></strong>
                              <li className="autocomplete-wrapper d-flex align-items-center">
                                <Autocomplete
                                  inputProps={{ placeholder: "Location" }}
                                  placeholder="location"
                                  value={searchFieldValue}
                                  items={locationsAPIData}
                                  getItemValue={(item) => item.name}
                                  shouldItemRender={() => locationsAPIData}
                                  onChange={(e, value) => {
                                    !e.target.value &&
                                      setLocation({
                                        location_id: null,
                                        location_type: null,
                                      });
                                    setSearchFieldValue(e.target.value);
                                    getLocations(e.target);
                                  }}
                                  onSelect={(value, obj) => {
                                    setSearchFieldValue(value);
                                    setLocation({
                                      location_type: obj.type,
                                      location_id: obj.id,
                                    });
                                    setLimit(10);
                                    setSkip(0);
                                  }}
                                  renderItem={(item, isHighlighted) => (
                                    <div
                                      className={`item ${
                                        isHighlighted ? "item-highlighted" : ""
                                      }`}
                                      key={item.name}
                                    >
                                      {item.name}
                                    </div>
                                  )}
                                />
                              </li>

                              <li>
                                <select
                                  className="form-select selectOptionOption"
                                  style={{ width: "auto" }}
                                  onChange={({ target }) => {
                                    setSelectedCompany(target.value);
                                    setLimit(10);
                                    setSkip(0);
                                  }}
                                >
                                  <option value="">Current Company</option>
                                  {currentCompanyAPIData.map((item) => (
                                    <option
                                      className=""
                                      key={item.id}
                                      value={item.name}
                                    >
                                      {item.name}
                                    </option>
                                  ))}
                                </select>
                              </li>

                              <li>
                                <select
                                  className="form-select custom-scrollbar"
                                  onChange={({ target }) => {
                                    setSelectedSchool(target.value);
                                    setLimit(10);
                                    setSkip(0);
                                  }}
                                >
                                  <option default value="">
                                    School
                                  </option>
                                  {schoolsAPIData.map((item, i) => (
                                    <React.Fragment key={i}>
                                      <option value={item.school_name}>
                                        {item.school_name}
                                      </option>
                                    </React.Fragment>
                                  ))}
                                </select>
                              </li>
                            </>
                          )}
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        )}

        {/* <div className="p-5 mt-3">
          <h5>
            Search for
            <span className="fw-bold text-dark">{state?.search}</span>
          </h5> 
        </div> */}

        {/* If currentTab is empty, render all sections, otherwise, render only the selected section */}
        <div className="container">
          <div className="row">
            <div className="col-9 logo-relative">
              <div className="mt-20 mb-20">
                {loading ? (
                  <AppLoader />
                ) : currentTab === "" ? (
                  Object.values(content)
                ) : (
                  content[currentTab] || null
                )}
              </div>
            </div>
            <div className="col-3">
              <div className="mt-20 mb-20">
                <div className="mb-20">
                  <Ad />
                </div>
                <div className="sidebar-sticky">
                  <SidebarFooter />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SearchScreen;
