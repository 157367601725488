import React, { useState, useEffect } from "react";
import "./Comingsoon.scss";
import { Col, Container, Row } from "reactstrap";
import Card from "./components/Card";
import AppDownloadSection from "./components/appList/AppDownloadSection";
import  CFooter  from "./components/footer/Footer";
import Banner from "./components/banner/Banner";

const ComingSoonDetailPage = () => {
  const [backgroundImage, setBackgroundImage] = useState("");

  useEffect(() => {
    // Fetch the dynamic image URL from the API
    const fetchImage = async () => {
      try {
        const response = await fetch("your_image_api_url");
        const data = await response.json();
        setBackgroundImage(data.imageUrl); // Assuming the response contains an imageUrl property
      } catch (error) {
        console.error("Error fetching image:", error);
      }
    };

    fetchImage();
  }, []);

  // Define the features array
  const features = [
    {
      title: "Professional Networking",
      description:
        "A new era of networking! Rooted in anti-discrimination: simple, transparent, and built on the principle; everyone is equal.",
    },
    {
      title: "Job Portal",
      description:
        "New experience of seeking & hiring: self-paced interviews, management desks and more. Here, Skills Win Over Bias.",
    },
    // Add more features here
  ];

  return (
    <>
      <div className="Comming_Soon" id="comming-soon-container">
        <header>
          <nav className="navbar navbar-expand-lg bg-white navbar-white p-0">
            <div className="nav-container d-flex justify-content-between">
              <div>
              {/* <button
                class="navbar-toggler"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#navbarSupportedContent"
                aria-controls="navbarSupportedContent"
                aria-expanded="false"
                aria-label="Toggle navigation"
              >
                <span class="navbar-toggler-icon"></span>
              </button> */}
              <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
      <span class="navbar-toggler-icon"></span>
    </button>
              <div
                class="collapse navbar-collapse flex-grow-0"
                id="navbarSupportedContent"
              >
                <ul class="navbar-nav me-auto mb-2 mb-lg-0">
                  <li class="nav-item">
                    <a class="nav-link active" aria-current="page" href="#">
                    About
                    </a>
                  </li>
                  <li class="nav-item">
                    <a class="nav-link" href="#">
                    Contact
                    </a>
                  </li> 
                   <li class="nav-item">
                    <a class="nav-link" href="#">
                      Blogs
                    </a>
                  </li>
                </ul>
              </div>
              </div>
              <div class="navbar-brand">
                {/* <img
                  src="/static/media/noblepageLogo.54857b7f99eaa060ea193c9a2b2ce08f.svg"
                  alt="noblepage_logo"
                /> */}
                <svg width="162" height="86" viewBox="0 0 162 86" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M104.418 10.9493C102.077 7.98769 99.2238 6.50589 95.8574 6.50391H67.1738V76.8398L75.8454 65.3466V17.9972H82.3426L91.9864 30.7553V17.9972H95.9202C98.136 17.9972 99.2451 19.365 99.2473 22.1008V40.1306C99.2473 42.8653 98.1364 44.2332 95.9146 44.2342H92.7523L83.1085 31.4137V55.723H95.8574C99.226 55.723 102.08 54.2427 104.418 51.2821C106.756 48.3214 107.924 44.6097 107.921 40.147V22.0963C107.921 17.6296 106.753 13.9139 104.418 10.9493Z" fill="url(#paint0_linear_26200_56085)"/>
<path d="M145.971 41.3867H86.6992V67.9324H145.971V41.3867Z" fill="#19BCF4"/>
<path d="M86.699 41.3867H17.9883V67.9324H86.699V41.3867Z" fill="#0432A3"/>
<path d="M38.373 46.4354V62.398H38.2798L27.4032 52.7082L27.466 61.7726H24.3691V45.8359H24.5069L35.3632 55.6717L35.2903 46.4354H38.373Z" fill="white"/>
<path d="M41.8478 54.6038C42.2981 53.8617 42.9499 53.2588 43.7295 52.8632C44.5803 52.4304 45.5269 52.2127 46.484 52.2298C47.4295 52.2099 48.3644 52.4286 49.2001 52.8652C49.9562 53.2656 50.5819 53.8691 51.0048 54.6058C51.4381 55.3689 51.6603 56.231 51.6489 57.1057C51.6585 57.9858 51.4366 58.8533 51.0048 59.6235C50.588 60.3699 49.9613 60.9813 49.2001 61.3841C48.3512 61.8243 47.4024 62.0437 46.4435 62.0215C45.4961 62.0387 44.5582 61.8332 43.7072 61.422C42.933 61.0466 42.2867 60.4562 41.8478 59.7235C41.3881 58.925 41.1584 58.0177 41.1835 57.0997C41.1697 56.2234 41.3994 55.3602 41.8478 54.6038ZM44.7948 58.9281C44.999 59.1526 45.2489 59.3321 45.528 59.455C45.8072 59.5779 46.1095 59.6414 46.4152 59.6414C46.7209 59.6414 47.0232 59.5779 47.3023 59.455C47.5815 59.3321 47.8313 59.1526 48.0355 58.9281C48.4402 58.4083 48.6596 57.7711 48.6596 57.1157C48.6596 56.4602 48.4402 55.823 48.0355 55.3032C47.8313 55.0787 47.5815 54.8992 47.3023 54.7763C47.0232 54.6534 46.7209 54.5899 46.4152 54.5899C46.1095 54.5899 45.8072 54.6534 45.528 54.7763C45.2489 54.8992 44.999 55.0787 44.7948 55.3032C44.3861 55.8159 44.164 56.4493 44.164 57.1017C44.164 57.754 44.3861 58.3874 44.7948 58.9002V58.9281Z" fill="white"/>
<path d="M62.6318 52.7818C63.3114 53.174 63.8641 53.7485 64.2258 54.4384C64.6381 55.2389 64.84 56.1287 64.8132 57.0263C64.8329 57.9462 64.6073 58.8552 64.159 59.662C63.7485 60.3928 63.1424 60.9983 62.407 61.4126C61.6802 61.8183 60.8581 62.0285 60.023 62.0221C58.77 62.0221 57.735 61.633 56.9181 60.855V61.7703H53.9609V45.3301H56.8978V53.2914C57.3452 52.9434 57.8531 52.6786 58.3966 52.51C59.0506 52.3014 59.7348 52.1994 60.4221 52.2083C61.1975 52.2 61.9609 52.3981 62.6318 52.7818ZM60.5821 59.2244C60.9592 58.9987 61.2694 58.6789 61.4814 58.2972C61.7066 57.8992 61.8225 57.4501 61.8176 56.9943C61.8287 56.6621 61.7709 56.3312 61.6476 56.0219C61.5243 55.7126 61.3381 55.4314 61.1006 55.1958C60.8761 54.9676 60.6066 54.7871 60.3088 54.6655C60.011 54.5438 59.6911 54.4836 59.3688 54.4884C58.8338 54.4782 58.3067 54.6167 57.8477 54.888C57.4284 55.1326 57.1018 55.506 56.9181 55.9512V58.1813C57.4244 59.0978 58.2413 59.5561 59.3688 59.5561C59.7967 59.5595 60.217 59.4446 60.5821 59.2244Z" fill="white"/>
<path d="M73.5845 58.939L74.8949 61.1072C74.3855 61.3855 73.8469 61.6087 73.2888 61.7726C72.7695 61.9337 72.229 62.0186 71.6846 62.0244C70.7029 62.0483 69.7299 61.8371 68.849 61.4089C68.0824 61.0268 67.4522 60.4231 67.0423 59.6784C66.6253 58.8918 66.4163 58.014 66.4347 57.1265C66.4288 56.257 66.6739 55.4037 67.1416 54.6666C67.6154 53.9155 68.282 53.3012 69.0738 52.8861C69.8948 52.4489 70.8145 52.2234 71.7474 52.2306C73.0693 52.2306 74.157 52.613 75.0104 53.3777C75.8638 54.1424 76.4167 55.2328 76.6692 56.6489L69.9103 58.7751C70.1382 59.0316 70.4216 59.2341 70.7397 59.3676C71.0577 59.5012 71.4021 59.5623 71.7474 59.5465C72.4066 59.5244 73.0449 59.3133 73.5845 58.939ZM69.9387 55.1682C69.5444 55.6118 69.3473 56.2113 69.3473 56.9667V57.1545L73.3981 55.7996C73.1024 54.9404 72.4759 54.5101 71.5185 54.5087C71.2208 54.4947 70.9236 54.5467 70.6488 54.6608C70.374 54.7749 70.1286 54.9483 69.9306 55.1682H69.9387Z" fill="white"/>
<path d="M81.7794 45.3301V61.7723H78.8223V45.3301H81.7794Z" fill="white"/>
<path d="M101.296 47.8214C102.338 48.7446 102.859 50.0049 102.86 51.6022C102.866 52.4881 102.683 53.3652 102.321 54.1761C101.949 54.9909 101.338 55.6771 100.567 56.1464C99.7571 56.6593 98.712 56.9164 97.4319 56.9178H95.2343V61.7717H92.1719V46.4346H96.881C98.7836 46.4346 100.255 46.8968 101.296 47.8214ZM98.8052 53.5926C99.1346 53.362 99.3966 53.0497 99.5647 52.6873C99.7114 52.3737 99.7907 52.0335 99.7976 51.6882C99.7921 51.1287 99.6233 50.5827 99.3115 50.1155C98.9874 49.6079 98.3609 49.3541 97.4319 49.3541H95.2343V53.9802H97.4096C97.9051 54.0036 98.3949 53.8676 98.8052 53.5926Z" fill="#F7F8F9"/>
<path d="M115.045 52.665V61.7714H112.088V60.6463C111.202 61.5629 110.175 62.0211 109.006 62.0211C107.64 62.0211 106.532 61.6215 105.68 60.8221C104.828 60.0228 104.402 58.8238 104.402 57.2252C104.381 56.3087 104.596 55.402 105.026 54.5894C105.407 53.8658 105.979 53.2575 106.682 52.8289C107.347 52.4188 108.116 52.202 108.9 52.2034C109.568 52.1742 110.234 52.2987 110.845 52.5671C111.334 52.8207 111.765 53.1718 112.111 53.5982L112.301 52.661L115.045 52.665ZM112.088 58.2603V56.0422C111.923 55.6021 111.614 55.2283 111.211 54.9791C110.772 54.7071 110.261 54.5681 109.743 54.5794C109.432 54.5753 109.123 54.6363 108.838 54.7582C108.552 54.8801 108.296 55.0603 108.086 55.2868C107.857 55.5269 107.678 55.8095 107.56 56.1182C107.442 56.4269 107.388 56.7556 107.399 57.0853C107.394 57.5414 107.511 57.9907 107.738 58.3882C107.949 58.7698 108.258 59.0896 108.635 59.3154C109.001 59.5358 109.422 59.6513 109.85 59.6491C110.321 59.6686 110.787 59.5473 111.186 59.3013C111.586 59.0553 111.901 58.6959 112.09 58.2703L112.088 58.2603Z" fill="#F7F8F9"/>
<path d="M128.413 52.6656V61.772C128.419 62.6123 128.157 63.4332 127.664 64.118C127.146 64.8325 126.448 65.4003 125.638 65.7646C124.769 66.1661 123.819 66.371 122.859 66.3641C122.184 66.3754 121.511 66.2837 120.864 66.0923C120.382 65.9529 119.92 65.7514 119.491 65.4928C119.064 65.2081 118.651 64.902 118.255 64.5756L119.987 62.5773C120.366 62.933 120.788 63.2411 121.243 63.4945C121.732 63.732 122.272 63.846 122.817 63.8262C123.488 63.8488 124.148 63.6552 124.698 63.2747C124.943 63.1014 125.141 62.8709 125.274 62.6037C125.406 62.3366 125.469 62.0412 125.458 61.744V60.4511C125.147 60.9495 124.696 61.348 124.16 61.5981C123.489 61.9028 122.755 62.0479 122.017 62.0217C121.201 62.0257 120.4 61.8057 119.704 61.3863C118.99 60.9622 118.404 60.3588 118.004 59.6378C117.582 58.8699 117.364 58.0094 117.369 57.136C117.375 56.2626 117.603 55.4047 118.035 54.6419C118.452 53.9028 119.056 53.2832 119.789 52.8435C120.477 52.4238 121.27 52.2004 122.079 52.198C122.769 52.1872 123.454 52.2961 124.105 52.5197C124.619 52.6866 125.084 52.9771 125.456 53.365L125.689 52.6556L128.413 52.6656ZM125.456 58.2609V56.0428C125.254 55.5924 124.91 55.2191 124.474 54.9797C123.995 54.709 123.451 54.571 122.9 54.58C122.563 54.5715 122.229 54.6298 121.916 54.7513C121.602 54.8728 121.317 55.0551 121.077 55.2874C120.833 55.5198 120.642 55.7998 120.515 56.1096C120.387 56.4193 120.327 56.7518 120.338 57.0859C120.33 57.4235 120.391 57.7592 120.518 58.0728C120.645 58.3864 120.835 58.6713 121.077 58.9104C121.313 59.1508 121.596 59.3406 121.91 59.4678C122.224 59.595 122.561 59.657 122.9 59.6497C124.085 59.6484 124.939 59.1888 125.46 58.2709L125.456 58.2609Z" fill="#F7F8F9"/>
<path d="M137.899 58.9381L139.209 61.1063C138.7 61.385 138.162 61.6081 137.603 61.7717C137.084 61.9328 136.543 62.0176 135.999 62.0235C135.017 62.0474 134.044 61.8362 133.164 61.408C132.397 61.0264 131.767 60.4225 131.359 59.6775C130.941 58.8912 130.732 58.0132 130.751 57.1256C130.743 56.2568 130.985 55.4036 131.45 54.6657C131.924 53.914 132.591 53.2997 133.384 52.8852C134.206 52.4467 135.128 52.2212 136.062 52.2297C137.384 52.2297 138.471 52.6121 139.325 53.3768C140.178 54.1415 140.731 55.2319 140.984 56.648L134.225 58.7722C134.453 59.0285 134.736 59.2308 135.054 59.3643C135.372 59.4978 135.717 59.5591 136.062 59.5436C136.721 59.522 137.359 59.3117 137.899 58.9381ZM134.253 55.1673C133.86 55.6109 133.662 56.2084 133.662 56.9657V57.1536L137.713 55.7987C137.417 54.9394 136.79 54.5091 135.833 54.5078C135.535 54.4938 135.238 54.5458 134.963 54.6599C134.689 54.774 134.443 54.9474 134.245 55.1673H134.253Z" fill="#F7F8F9"/>
<defs>
<linearGradient id="paint0_linear_26200_56085" x1="61.6746" y1="11.9228" x2="109.938" y2="48.3384" gradientUnits="userSpaceOnUse">
<stop offset="0.03" stop-color="#662D91"/>
<stop offset="0.17" stop-color="#413092"/>
<stop offset="0.27" stop-color="#2E3192"/>
<stop offset="0.39" stop-color="#2E3192"/>
<stop offset="0.42" stop-color="#2E3192"/>
<stop offset="0.53" stop-color="#2E3393"/>
<stop offset="0.55" stop-color="#2D3594"/>
<stop offset="0.86" stop-color="#0D97D7"/>
<stop offset="1" stop-color="#00BFF2"/>
</linearGradient>
</defs>
</svg>

              </div>
              <div className="days-count d-flex align-items-center">
                <h1 className="d-flex align-items-center">
                 <span className="number">14</span> <span className="days">Days</span>
                </h1>
              </div>
            </div>
          
          </nav>
          </header>
          <div className="title text-center bg-white comming-soon-title">
            <h1>COMMING SOON</h1>
            <h6>We are working hard to get this up and running</h6>
          </div>
          <div className="full-width-video">
            <video controls className="video-player w-100 center">
              <source src="" type="video/mp4" />
              <span className="text-center">
                Your browser does not support the video tag.
              </span>
            </video>
            <div className="video-text set-area-container">
              <h2>
                Have you ever dreamed of a fair social <br />
                environment that enforces equality?
              </h2>
              <p>
                Stay tuned for a new experience of Business Networking, Fair
                Career Opportunities, Certified Profiles, Transparent
                Socializing, Companies <br />
                Career Portals, Advanced Recruitment Features and Filters,
                Career Support, Consulting and much more*
              </p>
              <div className="d-flex gap-4 flex-wrap number-of-hours">
                <div style={{ marginBottom: "1rem" }}>
                  <h6 style={{ marginBottom: "0.5rem" }}>Development hours</h6>
                  <h1>52000+</h1>
                </div>
                <div
                  // style={{
                  //   marginBottom: "1rem",
                  //   marginLeft: "55px",
                  //   marginRight: "55px",
                  // }}
                >
                  <h6 style={{ marginBottom: "0.5rem" }}>Ideas Implemented</h6>
                  <h1>52000+</h1>
                </div>
                <div>
                  <h6 style={{ marginBottom: "0.5rem" }}>
                    Customer Satisfaction
                  </h6>
                  <h1>100%</h1>
                </div>
              </div>
            </div>
            <div className="subscription_container set-area-container">
              <p className="mb-0">
                Hurry up! The first 1000 subscribers will receive 50% discount
                and with sumbimtting they agree to our privacy poly and terms
                and conditions..
              </p>
              <div className="d-flex gap-4 mt-4 search-section-input">
                <input
                  className="subscription-search"
                  placeholder="example@gmail.com"
                />
                <button className="subscription-btn">SUBMIT</button>
              </div>
            </div>
            <div className="comming-soon-feature">
              <div className="set-area-container">
                <h1 className="explore-feature-title">Explore NobelPage’s Features</h1>
                <p className="text-center explore-feature-description-head">
                  Your Gateway to Endless Global Networking Opportunities! What
                  you see now is just the start. We’re constantly evolving,
                  bringing you groundbreaking features for a world-class
                  networking experience. Get ready for a journey that takes your
                  professional connections to new heights. With NobelPage, the
                  future of networking is not just exciting; it’s limitless!
                </p>
              </div>
              <div className="set-area-container feature-card-content">
                <Row className="d-flex flex-wrap">
                  {Array.from({ length: 12 }).map((_, index) => (
                    <Col xl={4} lg={6} md={6} sm={12} xs={12}>
                    <Card key={index} />
                      </Col>
                  ))}
                </Row>
              </div>
            </div>
          </div>
          <section className="w-full app-download-section">
            <AppDownloadSection/>
          </section>
         <CFooter/>
         <Banner/>
      </div>
    </>
  );
};

export default ComingSoonDetailPage;
