import React, { useEffect } from "react";
import Ad from "../../core/components/layout/Sidebar/Ad";
import Npmail from "../../core/components/layout/Sidebar/Npmail";
import { Archive } from "../../core/components/layout/Sidebar/Archive";
import Navbar from "../navbar/navbar";
import "./message.scss";
import SidebarFooter from "../../routes/setting/SidebarFooter";
import MessagePeople from "./component/MesagePeople/MessagePeople";
import MessageBox from "./component/MessageBox/MessageBox";
import { useState } from "react";
import AwayMessageModal from "./component/MessageModal/AwayMessageModal";
import AwayMessageCheckModal from "./component/MessageModal/AwayMessageCheckModal";
import ScheduleMeetingModal from "./component/MessageModal/ScheduleMeetingModal";
import XMPPService from "./service/ejabberd/ejabberd.service";
import { useDispatch, useSelector } from "react-redux";
import AppLoader from "../../core/components/Loaders/appLoader/appLoader";
import ChatService from "./service/chatServices";
import { ToastContainer } from "react-toastify";
import { initLoad, stopLoad } from "../../store/loader/loaderActions";
import { updateAwayMessages } from "../../store/message/messageActions";
import SocketService from "./service/socket/socket.service";

export default function Message() {
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.auth);
  const { isLoading } = useSelector((state) => state.loader);
  const [awayMessageModal, setAwayMessageModal] = useState(false);
  const [awayMessageCheckModal, setAwayMessageCheckModal] = useState(false);
  const [scheduleMeetingModal, setScheduleMeetingModal] = useState(false);

  const [activeTab, setActiveTab] = useState("message");
  const [mediaType, setMediaType] = useState(null);
  const [filterType, setFilterType] = useState(null);

  const [activeBox, setActiveBox] = useState(null);
  // const xmppService = XMPPService;
  const chatService = SocketService;

  // useEffect(() => {
  //   xmppService.dispatch = dispatch;
  //   xmppService.chatService = new ChatService();
  //   setTimeout(() => {
  //     xmppService.login(user?.data?.jid, user?.data?.jid_password);
  //   }, 100);
  // }, []);

  // useEffect(() => {
  //   if (activeBox) {
  //   }
  // }, [activeBox]);

  useEffect(() => {
    setActiveBox(null);
  }, [activeTab, filterType]);

  const updateAwayMessage = (awayMessage) => {
    dispatch(initLoad());
    dispatch(updateAwayMessages(awayMessage))
      .then((res) => dispatch(stopLoad()))
      .catch((err) => dispatch(stopLoad()));
  };

  return (
    <div>
      <Navbar isJobs={false} />
      <div className=" ">
        {isLoading && <AppLoader />}
        <ToastContainer />
        <div className="message-boxes">
          <div className="container">
            <div className="row">
              <div className="col-lg-3">
                <MessagePeople
                  activeTab={activeTab}
                  setActiveTab={setActiveTab}
                  mediaType={mediaType}
                  setMediaType={setMediaType}
                  filterType={filterType}
                  setFilterType={setFilterType}
                  activeBox={activeBox}
                  setActiveBox={setActiveBox}
                  // xmppService={xmppService}
                  chatService={chatService}
                />
              </div>
              <div className="col-lg-6">
                <MessageBox
                  activeTab={activeTab}
                  setActiveTab={setActiveTab}
                  mediaType={mediaType}
                  setMediaType={setMediaType}
                  activeBox={activeBox}
                  // xmppService={xmppService}
                  chatService={chatService}
                />
              </div>
              <div className="col-lg-3 mt-26">
                <Ad />
                <Archive
                  isMembership={
                    user?.data?.membership?.id > 1 &&
                    new Date(user.data?.membership_expiry) > new Date()
                  }
                  setAwayMessageModal={setAwayMessageModal}
                  setAwayMessageCheckModal={setAwayMessageCheckModal}
                  setScheduleMeetingModal={setScheduleMeetingModal}
                  setFilterType={setFilterType}
                />
                <Npmail />
                <div className="mt-20">
                  <SidebarFooter />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {awayMessageModal && (
        <AwayMessageModal
          modal={awayMessageModal}
          toggle={() => setAwayMessageModal(!awayMessageModal)}
          title={"Set away messages"}
          updateAwayMessage={updateAwayMessage}
        />
      )}

      {awayMessageCheckModal && (
        <AwayMessageCheckModal
          modal={awayMessageCheckModal}
          toggle={() => setAwayMessageCheckModal(!awayMessageCheckModal)}
          title={"Set away messages"}
          // setAwayMessageModal={setAwayMessageModal}
        />
      )}

      {scheduleMeetingModal && (
        <ScheduleMeetingModal
          modal={scheduleMeetingModal}
          toggle={() => setScheduleMeetingModal(!scheduleMeetingModal)}
          title={"Set away messages"}
        />
      )}
    </div>
  );
}
