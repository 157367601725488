import React from "react";
import { useNavigate } from "react-router";
import placeholderUser from "../../../../assets/img/placeholder_user.jpg";

export default function PostJob(props) {
  const { getAJob, user } = props;
  const navigate = useNavigate();
  return (
    <React.Fragment>
      <div className="post-job mt-26 mt-sm-0 ">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <h3>
                <span>{getAJob?.applied_count} </span>Applicants
              </h3>
              <h3 className="mt-4">
                <span>{getAJob?.view_count} </span>
                {getAJob?.view_count > 1 ? "Job Views" : "Job View"}
              </h3>
              <div className="d-flex mb-3 mt-20">
                <div className="imgs">
                  {getAJob?.views?.length &&
                    getAJob?.views?.map(({ User, user_id }, idx) => {
                      return (
                        idx < 4 && (
                          <span
                            key={idx}
                            onClick={() =>
                              user_id && navigate(`/profile/${user_id}`)
                            }
                          >
                            <img
                              src={
                                User?.profile_img
                                  ? User?.profile_img
                                  : placeholderUser
                              }
                              alt=""
                              className="image-fit"
                            />
                          </span>
                        )
                      );
                    })}
                  {getAJob?.view_count > 4 && (
                    <span className="plus-count">
                      <p>{getAJob?.view_count - 4} +</p>
                    </span>
                  )}
                </div>
              </div>
              <p>Get 3x more qualified applicants</p>
              {getAJob?.user?.id === user?.data?.id && (
                <button
                  className="btn btn-border mt-20"
                  onClick={() =>
                    navigate("/createcampaign", {
                      state: {
                        isFeatured: true,
                        jobId: getAJob?.id,
                      },
                    })
                  }
                >
                  Promote this job
                </button>
              )}
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}
