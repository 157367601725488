import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Input, Modal, ModalHeader, ModalBody } from "reactstrap";
import { toast } from "react-toastify";
import {
  unblockUser,
  getBlockList,
  updateBlockSetting,
} from "../../../store/blockUserList/blockedUsersActions";

const Blocking = (props) => {
  const { isActive } = props;
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [modalOpen1, setModalOpen1] = useState(false);
  const [search, setSearch] = useState("");
  const [blockList, setBlockList] = useState([]);
  const [currentData, setCurrentData] = useState("");
  const { blockedUsers } = useSelector((state) => state.blockedUsers);

  useEffect(() => {
    if (blockedUsers) {
      setBlockList(blockedUsers.data);
    }
  }, [blockedUsers]);

  const toggleModal1 = (data) => {
    setModalOpen1(!modalOpen1);
    setCurrentData(data);
  };

  const handleChangeAll = (value) => {
    setCurrentData({
      ...currentData,
      all_block: value,
      message: value,
      following: value,
      joining: value,
      viewing: value,
      attending: value,
      reading: value,
    });
  };

  const handleChangeEach = (value, target) => {
    if (target === 1) {
      setCurrentData({
        ...currentData,
        message: value,
      });
    }

    if (target === 2) {
      setCurrentData({
        ...currentData,
        following: value,
      });
    }

    if (target === 3) {
      setCurrentData({
        ...currentData,
        joining: value,
      });
    }

    if (target === 4) {
      setCurrentData({
        ...currentData,
        viewing: value,
      });
    }

    if (target === 5) {
      setCurrentData({
        ...currentData,
        attending: value,
      });
    }

    if (target === 6) {
      setCurrentData({
        ...currentData,
        reading: value,
      });
    }
  };

  const handleSave = () => {
    let formValid = true;

    if (
      !currentData.all_block &&
      !currentData.message &&
      !currentData.following &&
      !currentData.joining &&
      !currentData.viewing &&
      !currentData.attending &&
      !currentData.reading
    ) {
      formValid = false;
      toast.error(
        "Please select atleast one setting or Unblock user entirely from main screen.",
        { position: toast.POSITION.BOTTOM_LEFT }
      );
    }

    if (formValid) {
      let token = localStorage.getItem("accessToken");
      let myData = currentData;
      delete myData.user_id;
      delete myData.user;
      delete myData.blocked_by;
      delete myData.blocked_by_user;
      let blockId = myData.id;
      delete myData.id;

      setModalOpen1(!modalOpen1);
      dispatch(updateBlockSetting(blockId, myData)).then((res) => {
        dispatch(getBlockList(token));
        if (res.success) {
          toast.success(res.message, { position: toast.POSITION.BOTTOM_LEFT });
        }
      });
    }
  };

  const handleUnblock = (targetId) => {
    let token = localStorage.getItem("accessToken");
    dispatch(unblockUser(targetId)).then((res) => {
      dispatch(getBlockList(token));
      if (res.success) {
        toast.success(res.message, { position: toast.POSITION.BOTTOM_LEFT });
      }
    });
  };

  useEffect(() => {
    let token = localStorage.getItem("accessToken");
    if (search !== "") {
      dispatch(getBlockList(token, search));
    } else {
      dispatch(getBlockList(token));
    }
  }, [search]);

  return (
    <div>
      <div className="row">
        <div className="col-lg-6">
          <div className="heading">
            <h1>Blocking</h1>
          </div>
        </div>
      </div>
      <div className="content">
        <div className="profile-information">
          <h4>Search Blocked Users</h4>
          <input
            type="search"
            value={search}
            onChange={(e) => {
              setSearch(e.target.value);
              console.log(e.target.value);
            }}
          />
        </div>
        <div className="profile-information">
          <h4>Blocked Users</h4>
          <div className="profile-box-outer">
            {blockList && blockList.length > 0 ? (
              blockList.map((ele) => {
                return (
                  <>
                    <div className="profile-box" style={{ width: "250px" }}>
                      <img
                        src={ele.user.profile_img}
                        alt="Profile Img"
                        className="profile-img"
                        onClick={() => toggleModal1(ele)}
                      />
                      <h5 onClick={() => toggleModal1(ele)}>
                        {ele.user.first_name} {ele.user.last_name}
                      </h5>
                      <h6>{ele.user.email}</h6>
                      <button
                        type="button"
                        className="btn btn-outline mt-10"
                        onClick={() => handleUnblock(ele.id)}
                      >
                        Unblock
                      </button>
                    </div>
                  </>
                );
              })
            ) : (
              <>No Blocked Users Found</>
            )}
          </div>
        </div>
      </div>
      <Modal
        isOpen={modalOpen1}
        className="profile-checkbox-list-modal setting-modal-default-modal"
      >
        <ModalHeader>
          <h4 style={{ color: "white" }}>{`Block ${
            currentData && currentData.user ? currentData.user.first_name : ""
          } ${
            currentData && currentData.user ? currentData.user.last_name : ""
          }`}</h4>
        </ModalHeader>
        <ModalBody>
          <div className="profile-box">
            <img
              src={
                currentData && currentData.user
                  ? currentData.user.profile_img
                  : ""
              }
              alt="Profile Img"
            />
            <div className="title-box">
              <h5>{`${
                currentData && currentData.user
                  ? currentData.user.first_name
                  : ""
              } ${
                currentData && currentData.user
                  ? currentData.user.last_name
                  : ""
              }`}</h5>
              <p className="gray-light400 mt-0">
                {currentData && currentData.user ? currentData.user.email : ""}
              </p>
            </div>
          </div>
          <div className="checkbox-box">
            <ul>
              <li className="gray-light600">
                <span className="title-list">{`Block ${
                  currentData && currentData.user
                    ? currentData.user.first_name
                    : ""
                } ${
                  currentData && currentData.user
                    ? currentData.user.last_name
                    : ""
                }`}</span>
                <div className="switch-box">
                  <label className="switch">
                    <input
                      type="checkbox"
                      checked={currentData && currentData.all_block}
                      onChange={(e) => handleChangeAll(e.target.checked)}
                    />
                    <span className="slider round"></span>
                  </label>
                </div>
              </li>
              <li>
                <span className="title-list">Block messages</span>
                <div className="switch-box">
                  <label className="switch">
                    <input
                      type="checkbox"
                      disabled={currentData && currentData.all_block}
                      checked={currentData && currentData.message}
                      onChange={(e) => handleChangeEach(e.target.checked, 1)}
                    />
                    <span className="slider round"></span>
                  </label>
                </div>
              </li>
              <li>
                <span className="title-list">Block from following my page</span>
                <div className="switch-box">
                  <label className="switch">
                    <input
                      type="checkbox"
                      disabled={currentData && currentData.all_block}
                      checked={currentData && currentData.following}
                      onChange={(e) => handleChangeEach(e.target.checked, 2)}
                    />
                    <span className="slider round"></span>
                  </label>
                </div>
              </li>
              <li>
                <span className="title-list">Block from joining my group</span>
                <div className="switch-box">
                  <label className="switch">
                    <input
                      type="checkbox"
                      disabled={currentData && currentData.all_block}
                      checked={currentData && currentData.joining}
                      onChange={(e) => handleChangeEach(e.target.checked, 3)}
                    />
                    <span className="slider round"></span>
                  </label>
                </div>
              </li>
              <li>
                <span className="title-list">
                  Block from viewing my post and activity
                </span>
                <div className="switch-box">
                  <label className="switch">
                    <input
                      type="checkbox"
                      disabled={currentData && currentData.all_block}
                      checked={currentData && currentData.viewing}
                      onChange={(e) => handleChangeEach(e.target.checked, 4)}
                    />
                    <span className="slider round"></span>
                  </label>
                </div>
              </li>
              <li>
                <span className="title-list">
                  Block from attending my event
                </span>
                <div className="switch-box">
                  <label className="switch">
                    <input
                      type="checkbox"
                      disabled={currentData && currentData.all_block}
                      checked={currentData && currentData.attending}
                      onChange={(e) => handleChangeEach(e.target.checked, 5)}
                    />
                    <span className="slider round"></span>
                  </label>
                </div>
              </li>
              <li>
                <span className="title-list">
                  Block from reading my newsletter and article
                </span>
                <div className="switch-box">
                  <label className="switch">
                    <input
                      type="checkbox"
                      disabled={currentData && currentData.all_block}
                      checked={currentData && currentData.reading}
                      onChange={(e) => handleChangeEach(e.target.checked, 6)}
                    />
                    <span className="slider round"></span>
                  </label>
                </div>
              </li>
            </ul>
            <div
              className="text-end"
              style={{
                marginTop: "40px",
                display: "flex",
                justifyContent: "flex-end",
                columnGap: "10px",
              }}
            >
              <div
                className="btn btn-outline"
                onClick={() => {
                  setModalOpen1(false);
                  setCurrentData("");
                }}
              >
                Close
              </div>
              <div className="btn btn-blue" onClick={() => handleSave()}>
                Save
              </div>
            </div>
          </div>
        </ModalBody>
      </Modal>
      {/* <ToastContainer /> */}
    </div>
  );
};

export default Blocking;
