import React, { useState } from 'react';
import './SettingModals.scss'
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, FormGroup, Label, Input } from 'reactstrap';
import setttingNobelLogo from '../../../assets/img/setttingNobelLogo.png'
import settingProfileImg3 from '../../../assets/img/settingProfileImg3.png'
import { Link } from 'react-router-dom';
import location from '../../../assets/img/location.svg'
import tickMember from '../../../assets/img/tick-member.svg'
import cross from '../../../assets/img/cross.png'
import documentDownloadFill from '../../../assets/img/documentDownloadFill.png'
import loggedSession1 from '../../../assets/img/loggedSession1.png'
import loggedSession2 from '../../../assets/img/loggedSession2.png'
import loggedSession3 from '../../../assets/img/loggedSession3.png'
import loggedSession4 from '../../../assets/img/loggedSession4.png'
import mastercardSmall from '../../../assets/img/mastercardSmall.png'
import visacrdSmall from '../../../assets/img/visacrdSmall.png'


const SettingModals = () => {
    const [openItem, setOpenItem] = useState(null);
    const toggleItem = (index) => {
      if (openItem === index) {
        setOpenItem(null);
      } else {
        setOpenItem(index);
      }
    };
    // 
    const [modalOpen1, setModalOpen1] = useState(false);
    const [modalOpen2, setModalOpen2] = useState(false);
    const [modalOpen3, setModalOpen3] = useState(false);
    const [modalOpen4, setModalOpen4] = useState(false);
    const [modalOpen5, setModalOpen5] = useState(false);
    const [modalOpen6, setModalOpen6] = useState(false);
    const [modalOpen7, setModalOpen7] = useState(false);
    const [modalOpen8, setModalOpen8] = useState(false);
    const [modalOpen9, setModalOpen9] = useState(false);
    const [modalOpen10, setModalOpen10] = useState(false);
    const [modalOpen11, setModalOpen11] = useState(false);
    const [modalOpen12, setModalOpen12] = useState(false);
    const [modalOpen13, setModalOpen13] = useState(false);
    const [modalOpen14, setModalOpen14] = useState(false);
    const [modalOpen15, setModalOpen15] = useState(false);
    const [modalOpen16, setModalOpen16] = useState(false);
    const [modalOpen17, setModalOpen17] = useState(false);
    const [modalOpen18, setModalOpen18] = useState(false);
    const [modalOpen19, setModalOpen19] = useState(false);
    const [modalOpen20, setModalOpen20] = useState(false);
    const [modalOpen21, setModalOpen21] = useState(false);
    const [modalOpen22, setModalOpen22] = useState(false);
    const [modalOpen23, setModalOpen23] = useState(false);
    const [modalOpen24, setModalOpen24] = useState(false);
    const [modalOpen25, setModalOpen25] = useState(false);
    const [modalOpen26, setModalOpen26] = useState(false);
    const [modalOpen27, setModalOpen27] = useState(false);
    const [modalOpen28, setModalOpen28] = useState(false);
    const [modalOpen29, setModalOpen29] = useState(false);
    const [modalOpen30, setModalOpen30] = useState(false);


    const toggleModal1 = () => {
      setModalOpen1(!modalOpen1);
    };
    const toggleModal2 = () => {
        setModalOpen2(!modalOpen2);
    };
    const toggleModal3 = () => {
        setModalOpen3(!modalOpen3);
    };
    const toggleModal4 = () => {
        setModalOpen4(!modalOpen4);
    };
    const toggleModal5 = () => {
        setModalOpen5(!modalOpen5);
    };
    const toggleModal6 = () => {
        setModalOpen6(!modalOpen6);
    };
    const toggleModal7 = () => {
        setModalOpen7(!modalOpen7);
    };
    const toggleModal8 = () => {
        setModalOpen8(!modalOpen8);
    };
    const toggleModal9 = () => {
        setModalOpen9(!modalOpen9);
    };
    const toggleModal10 = () => {
        setModalOpen10(!modalOpen10);
    };
    const toggleModal11 = () => {
        setModalOpen11(!modalOpen11);
    };
    const toggleModal12 = () => {
        setModalOpen12(!modalOpen12);
    };
    const toggleModal13 = () => {
        setModalOpen13(!modalOpen13);
    };
    const toggleModal14 = () => {
        setModalOpen14(!modalOpen14);
    };
    const toggleModal15 = () => {
        setModalOpen15(!modalOpen15);
    };
    const toggleModal16 = () => {
        setModalOpen16(!modalOpen16);
    };
    const toggleModal17 = () => {
        setModalOpen17(!modalOpen17);
    };
    const toggleModal18 = () => {
        setModalOpen18(!modalOpen18);
    };
    const toggleModal19 = () => {
        setModalOpen19(!modalOpen19);
    };
    const toggleModal20 = () => {
        setModalOpen20(!modalOpen20);
    };

    const toggleModal21 = () => {
        setModalOpen21(!modalOpen21);
    };
    const toggleModal22 = () => {
        setModalOpen22(!modalOpen22);
    };
    const toggleModal23 = () => {
        setModalOpen23(!modalOpen23);
    };
    const toggleModal24 = () => {
        setModalOpen24(!modalOpen24);
    };
    const toggleModal25 = () => {
        setModalOpen25(!modalOpen25);
    };
    const toggleModal26 = () => {
        setModalOpen26(!modalOpen26);
    };
    const toggleModal27 = () => {
        setModalOpen27(!modalOpen27);
    };
    const toggleModal28 = () => {
        setModalOpen28(!modalOpen28);
    };
    const toggleModal29 = () => {
        setModalOpen29(!modalOpen29);
    };
    const toggleModal30 = () => {
        setModalOpen30(!modalOpen30);
    };



    return (
    <>
        <div className='container'>
            <div className='mb-2'><Button color="primary" onClick={toggleModal1}>Nobel Plus Account</Button></div>
            <div className='mb-2'><Button color="primary" onClick={toggleModal2}>Emails you've added</Button></div>
            <div className='mb-2'><Button color="primary" onClick={toggleModal3}>Profile Viewing Options</Button></div>
            <div className='mb-2'><Button color="primary" onClick={toggleModal4}>Conversations</Button></div>
            <div className='mb-2'><Button color="primary" onClick={toggleModal5}>Block Hannibal Smith</Button></div>
            <div className='mb-2'><Button color="primary" onClick={toggleModal6}>Profile data</Button></div>
            <div className='mb-2'><Button color="primary" onClick={toggleModal7}>Language</Button></div>
            <div className='mb-2'><Button color="primary" onClick={toggleModal8}>Compare Plans and Features</Button></div>
            <div className='mb-2'><Button color="primary" onClick={toggleModal9}>Phone Numbers</Button></div>
            <div className='mb-2'><Button color="primary" onClick={toggleModal10}>Contact Visibility</Button></div>
            <div className='mb-2'><Button color="primary" onClick={toggleModal11}>Jobs/Network/ Profile/Email/ Messaging Suggestions/Messaging Notification</Button></div>
            <div className='mb-2'><Button color="primary" onClick={toggleModal12}>Connections/Represent company/Data sharing/NP Mail Notification/Location/Companies you follow/Groups/Ads beyond NobelPage/Ad-related actions</Button></div>
            <div className='mb-2'><Button color="primary" onClick={toggleModal13}>Feed Preferences/Education/Job information/Employer</Button></div>
            <div className='mb-2'><Button color="primary" onClick={toggleModal14}>Add-ons Packages</Button></div>
            <div className='mb-2'><Button color="primary" onClick={toggleModal15}>Change Password</Button></div>
            <div className='mb-2'><Button color="primary" onClick={toggleModal16}>Last name visibility</Button></div>
            <div className='mb-2'><Button color="primary" onClick={toggleModal17}>Transactions</Button></div>
            <div className='mb-2'><Button color="primary" onClick={toggleModal18}>Archive Message Duration/Follow visibility/Discover by email/Discover by phone/Manage active status/Sharing profile edits</Button></div>
            <div className='mb-2'><Button color="primary" onClick={toggleModal18}>In the news/Mentions or tags/Who Can Message Me</Button></div>
            <div className='mb-2'><Button color="primary" onClick={toggleModal19}>Logged in locations</Button></div>
            <div className='mb-2'><Button color="primary" onClick={toggleModal20}>Saved Cards</Button></div>
            <div className='mb-2'><Button color="primary" onClick={toggleModal21}>Two Factor Authentication</Button></div>
            <div className='mb-2'><Button color="primary" onClick={toggleModal22}>Who can follow you</Button></div>
            <div className='mb-2'><Button color="primary" onClick={toggleModal23}>Demographics</Button></div>
            

            
        </div>


        {/* Nobel Plus Account */}
        <Modal isOpen={modalOpen1} toggle={toggleModal1} className='nobel-plus-account-modal setting-modal-default-modal'>
            <ModalHeader toggle={toggleModal1}>
                <img src={setttingNobelLogo} alt='Logo' />
            </ModalHeader>
            <ModalBody>
                <div className='d-flex justify-content-between'>
                    <div className='left'>
                        <h3>Nobel Plus Account</h3>
                        <h6>Annual Plan</h6>
                        <h6>06 July 2022 - 05 July 2023</h6>
                    </div>
                    <div className='right'>
                        <button className='gray-blue-btn'>Cancel renewal</button>
                    </div>
                </div>
            </ModalBody>
            <ModalFooter className='footer-txt'>
                <div>
                    <p>Your next payment is $649.00</p>
                    <p className='gray-light400'>Your payment will be automatically renewed each year</p>
                </div>
            </ModalFooter>
        </Modal>


        {/* Emails you've added */}
        <Modal isOpen={modalOpen2} toggle={toggleModal2} className='add-email-account-modal setting-modal-default-modal'>
            <ModalHeader toggle={toggleModal2}>
                Emails you've added
            </ModalHeader>
            <ModalBody>
                <p className='gray-light400'>Primary</p>
                <p className='gray-light600'>kevinobrain@gmail.com</p>
                <button className='gray-blue-btn with-border'>Add email address</button>
            </ModalBody>
        </Modal>


        {/* Profile Viewing Options */}
        <Modal isOpen={modalOpen3} toggle={toggleModal3} className='add-email-account-modal setting-modal-default-modal'>
            <ModalHeader toggle={toggleModal3}>
                Profile Viewing Options
            </ModalHeader>
            <ModalBody>
                <div className="accordion">
                    <div className={`accordion-item ${openItem === 0 ? 'open' : ''}`}>
                        <h6 className="gray-light600 accordion-title" onClick={() => toggleItem(0)}>
                        Background and Profile Image Visibility
                        </h6>
                        {openItem === 0 && <div className="accordion-content">
                            <label for="everyone"><input type='radio' id="everyone" name='radio' /> Visible To Everyone</label>
                            <label for="network"><input type='radio' id="network" name='radio' /> Visible To My Network</label>
                            <label for="scouts"><input type='radio' id="scouts" name='radio' /> Scouts only</label>
                            <label for="hidden"><input type='radio' id="hidden" name='radio' /> Hidden</label>
                        </div>}
                    </div>
                    <div className={`accordion-item ${openItem === 1 ? 'open' : ''}`}>
                        <h6 className="gray-light600 accordion-title" onClick={() => toggleItem(1)}>
                            Connection
                        </h6>
                        {openItem === 1 && <div className="accordion-content">
                            <label for="everyone"><input type='radio' id="everyone" name='radio' /> Visible To Everyone</label>
                            <label for="network"><input type='radio' id="network" name='radio' /> Visible To My Network</label>
                            <label for="scouts"><input type='radio' id="scouts" name='radio' /> Scouts only</label>
                            <label for="hidden"><input type='radio' id="hidden" name='radio' /> Hidden</label>
                        </div>}
                    </div>
                    <div className={`accordion-item ${openItem === 2 ? 'open' : ''}`}>
                        <h6 className="gray-light600 accordion-title" onClick={() => toggleItem(2)}>
                            Followers
                        </h6>
                        {openItem === 2 && <div className="accordion-content">
                            <label for="everyone"><input type='radio' id="everyone" name='radio' /> Visible To Everyone</label>
                            <label for="network"><input type='radio' id="network" name='radio' /> Visible To My Network</label>
                            <label for="scouts"><input type='radio' id="scouts" name='radio' /> Scouts only</label>
                            <label for="hidden"><input type='radio' id="hidden" name='radio' /> Hidden</label>    
                        </div>}
                    </div>

                    <div className={`accordion-item ${openItem === 3 ? 'open' : ''}`}>
                        <h6 className="gray-light600 accordion-title" onClick={() => toggleItem(3)}>
                        Connection Count
                        </h6>
                        {openItem === 3 && <div className="accordion-content">
                            <label for="everyone"><input type='radio' id="everyone" name='radio' /> Visible To Everyone</label>
                            <label for="network"><input type='radio' id="network" name='radio' /> Visible To My Network</label>
                            <label for="scouts"><input type='radio' id="scouts" name='radio' /> Scouts only</label>
                            <label for="hidden"><input type='radio' id="hidden" name='radio' /> Hidden</label>    
                        </div>}
                    </div>
                    <div className={`accordion-item ${openItem === 4 ? 'open' : ''}`}>
                        <h6 className="gray-light600 accordion-title" onClick={() => toggleItem(4)}>
                        Followers Count
                        </h6>
                        {openItem === 4 && <div className="accordion-content">
                            <label for="everyone"><input type='radio' id="everyone" name='radio' /> Visible To Everyone</label>
                            <label for="network"><input type='radio' id="network" name='radio' /> Visible To My Network</label>
                            <label for="scouts"><input type='radio' id="scouts" name='radio' /> Scouts only</label>
                            <label for="hidden"><input type='radio' id="hidden" name='radio' /> Hidden</label>    
                        </div>}
                    </div>
                    <div className={`accordion-item ${openItem === 5 ? 'open' : ''}`}>
                        <h6 className="gray-light600 accordion-title" onClick={() => toggleItem(5)}>
                        Badge Visibility
                        </h6>
                        {openItem === 5 && <div className="accordion-content">
                            <label for="everyone"><input type='radio' id="everyone" name='radio' /> Visible To Everyone</label>
                            <label for="network"><input type='radio' id="network" name='radio' /> Visible To My Network</label>
                            <label for="scouts"><input type='radio' id="scouts" name='radio' /> Scouts only</label>
                            <label for="hidden"><input type='radio' id="hidden" name='radio' /> Hidden</label>    
                        </div>}
                    </div>
                    <div className={`accordion-item ${openItem === 6 ? 'open' : ''}`}>
                        <h6 className="gray-light600 accordion-title" onClick={() => toggleItem(6)}>
                        Badge Visibility
                        </h6>
                        {openItem === 6 && <div className="accordion-content">
                            <label for="everyone"><input type='radio' id="everyone" name='radio' /> Visible To Everyone</label>
                            <label for="network"><input type='radio' id="network" name='radio' /> Visible To My Network</label>
                            <label for="scouts"><input type='radio' id="scouts" name='radio' /> Scouts only</label>
                            <label for="hidden"><input type='radio' id="hidden" name='radio' /> Hidden</label>    
                        </div>}
                    </div>
                    <div className={`accordion-item ${openItem === 7 ? 'open' : ''}`}>
                        <h6 className="gray-light600 accordion-title" onClick={() => toggleItem(7)}>
                        About Me Visibility
                        </h6>
                        {openItem === 7 && <div className="accordion-content">
                            <label for="everyone"><input type='radio' id="everyone" name='radio' /> Visible To Everyone</label>
                            <label for="network"><input type='radio' id="network" name='radio' /> Visible To My Network</label>
                            <label for="scouts"><input type='radio' id="scouts" name='radio' /> Scouts only</label>
                            <label for="hidden"><input type='radio' id="hidden" name='radio' /> Hidden</label>    
                        </div>}
                    </div>
                    <div className={`accordion-item ${openItem === 8 ? 'open' : ''}`}>
                        <h6 className="gray-light600 accordion-title" onClick={() => toggleItem(8)}>
                        Experience Visibility
                        </h6>
                        {openItem === 8 && <div className="accordion-content">
                            <label for="everyone"><input type='radio' id="everyone" name='radio' /> Visible To Everyone</label>
                            <label for="network"><input type='radio' id="network" name='radio' /> Visible To My Network</label>
                            <label for="scouts"><input type='radio' id="scouts" name='radio' /> Scouts only</label>
                            <label for="hidden"><input type='radio' id="hidden" name='radio' /> Hidden</label>    
                        </div>}
                    </div>
                    <div className={`accordion-item ${openItem === 9 ? 'open' : ''}`}>
                        <h6 className="gray-light600 accordion-title" onClick={() => toggleItem(9)}>
                        Education Visibility
                        </h6>
                        {openItem === 9 && <div className="accordion-content">
                            <label for="everyone"><input type='radio' id="everyone" name='radio' /> Visible To Everyone</label>
                            <label for="network"><input type='radio' id="network" name='radio' /> Visible To My Network</label>
                            <label for="scouts"><input type='radio' id="scouts" name='radio' /> Scouts only</label>
                            <label for="hidden"><input type='radio' id="hidden" name='radio' /> Hidden</label>    
                        </div>}
                    </div>
                    <div className={`accordion-item ${openItem === 10 ? 'open' : ''}`}>
                        <h6 className="gray-light600 accordion-title" onClick={() => toggleItem(10)}>
                        CV Visibility
                        </h6>
                        {openItem === 10 && <div className="accordion-content">
                            <label for="everyone"><input type='radio' id="everyone" name='radio' /> Visible To Everyone</label>
                            <label for="network"><input type='radio' id="network" name='radio' /> Visible To My Network</label>
                            <label for="scouts"><input type='radio' id="scouts" name='radio' /> Scouts only</label>
                            <label for="hidden"><input type='radio' id="hidden" name='radio' /> Hidden</label>    
                        </div>}
                    </div>
                    <div className={`accordion-item ${openItem === 11 ? 'open' : ''}`}>
                        <h6 className="gray-light600 accordion-title" onClick={() => toggleItem(11)}>
                        Share Celebration
                        </h6>
                        {openItem === 11 && <div className="accordion-content">
                            <label for="everyone"><input type='radio' id="everyone" name='radio' /> Visible To Everyone</label>
                            <label for="network"><input type='radio' id="network" name='radio' /> Visible To My Network</label>
                            <label for="scouts"><input type='radio' id="scouts" name='radio' /> Scouts only</label>
                            <label for="hidden"><input type='radio' id="hidden" name='radio' /> Hidden</label>    
                        </div>}
                    </div>
                    <div className={`accordion-item ${openItem === 12 ? 'open' : ''}`}>
                        <h6 className="gray-light600 accordion-title" onClick={() => toggleItem(12)}>
                        Honors and Awards Visibility
                        </h6>
                        {openItem === 12 && <div className="accordion-content">
                            <label for="everyone"><input type='radio' id="everyone" name='radio' /> Visible To Everyone</label>
                            <label for="network"><input type='radio' id="network" name='radio' /> Visible To My Network</label>
                            <label for="scouts"><input type='radio' id="scouts" name='radio' /> Scouts only</label>
                            <label for="hidden"><input type='radio' id="hidden" name='radio' /> Hidden</label>    
                        </div>}
                    </div>
                    <div className={`accordion-item ${openItem === 13 ? 'open' : ''}`}>
                        <h6 className="gray-light600 accordion-title" onClick={() => toggleItem(13)}>
                        Licenses and Certification Visibility
                        </h6>
                        {openItem === 13 && <div className="accordion-content">
                            <label for="everyone"><input type='radio' id="everyone" name='radio' /> Visible To Everyone</label>
                            <label for="network"><input type='radio' id="network" name='radio' /> Visible To My Network</label>
                            <label for="scouts"><input type='radio' id="scouts" name='radio' /> Scouts only</label>
                            <label for="hidden"><input type='radio' id="hidden" name='radio' /> Hidden</label>    
                        </div>}
                    </div>
                    <div className={`accordion-item ${openItem === 14 ? 'open' : ''}`}>
                        <h6 className="gray-light600 accordion-title" onClick={() => toggleItem(14)}>
                        Courses Visibility
                        </h6>
                        {openItem === 14 && <div className="accordion-content">
                            <label for="everyone"><input type='radio' id="everyone" name='radio' /> Visible To Everyone</label>
                            <label for="network"><input type='radio' id="network" name='radio' /> Visible To My Network</label>
                            <label for="scouts"><input type='radio' id="scouts" name='radio' /> Scouts only</label>
                            <label for="hidden"><input type='radio' id="hidden" name='radio' /> Hidden</label>    
                        </div>}
                    </div>
                    <div className={`accordion-item ${openItem === 15 ? 'open' : ''}`}>
                        <h6 className="gray-light600 accordion-title" onClick={() => toggleItem(15)}>
                        Skills Visibility
                        </h6>
                        {openItem === 15 && <div className="accordion-content">
                            <label for="everyone"><input type='radio' id="everyone" name='radio' /> Visible To Everyone</label>
                            <label for="network"><input type='radio' id="network" name='radio' /> Visible To My Network</label>
                            <label for="scouts"><input type='radio' id="scouts" name='radio' /> Scouts only</label>
                            <label for="hidden"><input type='radio' id="hidden" name='radio' /> Hidden</label>    
                        </div>}
                    </div>
                    <div className={`accordion-item ${openItem === 16 ? 'open' : ''}`}>
                        <h6 className="gray-light600 accordion-title" onClick={() => toggleItem(16)}>
                        Language Visibility
                        </h6>
                        {openItem === 16 && <div className="accordion-content">
                            <label for="everyone"><input type='radio' id="everyone" name='radio' /> Visible To Everyone</label>
                            <label for="network"><input type='radio' id="network" name='radio' /> Visible To My Network</label>
                            <label for="scouts"><input type='radio' id="scouts" name='radio' /> Scouts only</label>
                            <label for="hidden"><input type='radio' id="hidden" name='radio' /> Hidden</label>    
                        </div>}
                    </div>
                    <div className={`accordion-item ${openItem === 17 ? 'open' : ''}`}>
                        <h6 className="gray-light600 accordion-title" onClick={() => toggleItem(17)}>
                        Introduction Video Visibility
                        </h6>
                        {openItem === 17 && <div className="accordion-content">
                            <label for="everyone"><input type='radio' id="everyone" name='radio' /> Visible To Everyone</label>
                            <label for="network"><input type='radio' id="network" name='radio' /> Visible To My Network</label>
                            <label for="scouts"><input type='radio' id="scouts" name='radio' /> Scouts only</label>
                            <label for="hidden"><input type='radio' id="hidden" name='radio' /> Hidden</label>    
                        </div>}
                    </div>
                    <div className={`accordion-item ${openItem === 18 ? 'open' : ''}`}>
                        <h6 className="gray-light600 accordion-title" onClick={() => toggleItem(18)}>
                        Interests Visibility
                        </h6>
                        {openItem === 18 && <div className="accordion-content">
                            <label for="everyone"><input type='radio' id="everyone" name='radio' /> Visible To Everyone</label>
                            <label for="network"><input type='radio' id="network" name='radio' /> Visible To My Network</label>
                            <label for="scouts"><input type='radio' id="scouts" name='radio' /> Scouts only</label>
                            <label for="hidden"><input type='radio' id="hidden" name='radio' /> Hidden</label>    
                        </div>}
                    </div>
                    <div className={`accordion-item ${openItem === 19 ? 'open' : ''}`}>
                        <h6 className="gray-light600 accordion-title" onClick={() => toggleItem(19)}>
                        Invitation/Connection Request Settings
                        </h6>
                        {openItem === 19 && <div className="accordion-content">
                            <label for="everyone"><input type='radio' id="everyone" name='radio' /> Visible To Everyone</label>
                            <label for="network"><input type='radio' id="network" name='radio' /> Visible To My Network</label>
                            <label for="scouts"><input type='radio' id="scouts" name='radio' /> Scouts only</label>
                            <label for="hidden"><input type='radio' id="hidden" name='radio' /> Hidden</label>    
                        </div>}
                    </div>
                    <div className={`accordion-item ${openItem === 20 ? 'open' : ''}`}>
                        <h6 className="gray-light600 accordion-title" onClick={() => toggleItem(20)}>
                        Who Can Recommend Me
                        </h6>
                        {openItem === 20 && <div className="accordion-content">
                            <label for="everyone"><input type='radio' id="everyone" name='radio' /> Visible To Everyone</label>
                            <label for="network"><input type='radio' id="network" name='radio' /> Visible To My Network</label>
                            <label for="scouts"><input type='radio' id="scouts" name='radio' /> Scouts only</label>
                            <label for="hidden"><input type='radio' id="hidden" name='radio' /> Hidden</label>    
                        </div>}
                    </div>
                </div>
            </ModalBody>
        </Modal>


        {/* Conversations */}
        <Modal isOpen={modalOpen4} toggle={toggleModal4} className='checkbox-list-modal setting-modal-default-modal'>
            <ModalHeader toggle={toggleModal4}>
                Conversations
            </ModalHeader>
            <ModalBody>
                <div className='checkbox-box'>
                    <ul>
                        <li className='gray-light600'><span className='title-list'>All conversations</span>
                            <div className="switch-box">
                                <label className="switch">
                                    <input type="checkbox" />
                                    <span className="slider round"></span>
                                </label>
                            </div>
                        </li>
                        <li><span className='title-list'>Activity on posts and comments you're mentioned in</span>
                            <div className="switch-box">
                                <label className="switch">
                                    <input type="checkbox" />
                                    <span className="slider round"></span>
                                </label>
                            </div>
                        </li>
                        <li><span className='title-list'>Activity on posts and comments you've responded to</span>
                            <div className="switch-box">
                                <label className="switch">
                                    <input type="checkbox" />
                                    <span className="slider round"></span>
                                </label>
                            </div>
                        </li>
                        <li><span className='title-list'>Comments on your job updates</span>
                            <div className="switch-box">
                                <label className="switch">
                                    <input type="checkbox" />
                                    <span className="slider round"></span>
                                </label>
                            </div>
                        </li>
                        <li><span className='title-list'>Confirmation of your posts</span>
                            <div className="switch-box">
                                <label className="switch">
                                    <input type="checkbox" />
                                    <span className="slider round"></span>
                                </label>
                            </div>
                        </li>
                        <li><span className='title-list'>NPMail reminders for hiring</span>
                            <div className="switch-box">
                                <label className="switch">
                                    <input type="checkbox" />
                                    <span className="slider round"></span>
                                </label>
                            </div>
                        </li>
                        <li><span className='title-list'>Responses to your job updates</span>
                            <div className="switch-box">
                                <label className="switch">
                                    <input type="checkbox" />
                                    <span className="slider round"></span>
                                </label>
                            </div>
                        </li>
                        <li><span className='title-list'>Message nudges</span>
                            <div className="switch-box">
                                <label className="switch">
                                    <input type="checkbox" />
                                    <span className="slider round"></span>
                                </label>
                            </div>
                        </li>
                        <li><span className='title-list'>Responses to your posts, comments and poll</span>
                            <div className="switch-box">
                                <label className="switch">
                                    <input type="checkbox" />
                                    <span className="slider round"></span>
                                </label>
                            </div>
                        </li>
                        <li><span className='title-list'>Results of poll you've created or voted on</span>
                            <div className="switch-box">
                                <label className="switch">
                                    <input type="checkbox" />
                                    <span className="slider round"></span>
                                </label>
                            </div>
                        </li>
                        <li><span className='title-list'>Views of your recent posts</span>
                            <div className="switch-box">
                                <label className="switch">
                                    <input type="checkbox" />
                                    <span className="slider round"></span>
                                </label>
                            </div>
                        </li>
                        <li><span className='title-list'>Your posts featured in NobelPage storylines</span>
                            <div className="switch-box">
                                <label className="switch">
                                    <input type="checkbox" />
                                    <span className="slider round"></span>
                                </label>
                            </div>
                        </li>
                    </ul>
                </div>
            </ModalBody>
        </Modal>


        {/* Block Hannibal Smith */}
        <Modal isOpen={modalOpen5} toggle={toggleModal5} className='profile-checkbox-list-modal setting-modal-default-modal'>
            <ModalHeader toggle={toggleModal5}>
                Block Hannibal Smith
            </ModalHeader>
            <ModalBody>
                <div className="profile-box">
                    <img src={settingProfileImg3} alt="Profile Img" />
                    <div className='title-box'>
                        <h5>Hannibal Smith</h5>
                        <p className='gray-light400 mt-0'>Web Developer at Infinite opensource solution</p>
                    </div>
                </div>
                <div className='checkbox-box'>
                    <ul>
                        <li className='gray-light600'><span className='title-list'>Block Hannibal Smith</span>
                        <div className="switch-box">
                                <label className="switch">
                                    <input type="checkbox" />
                                    <span className="slider round"></span>
                                </label>
                            </div>
                        </li>
                        <li><span className='title-list'>Block messages</span>
                        <div className="switch-box">
                                <label className="switch">
                                    <input type="checkbox" />
                                    <span className="slider round"></span>
                                </label>
                            </div>
                        </li>
                        <li><span className='title-list'>Block from following my page</span>
                        <div className="switch-box">
                                <label className="switch">
                                    <input type="checkbox" />
                                    <span className="slider round"></span>
                                </label>
                            </div>
                        </li>
                        <li><span className='title-list'>Block from joining my group</span>
                        <div className="switch-box">
                                <label className="switch">
                                    <input type="checkbox" />
                                    <span className="slider round"></span>
                                </label>
                            </div>
                        </li>
                        <li><span className='title-list'>Block from viewing my post and activity</span>
                        <div className="switch-box">
                                <label className="switch">
                                    <input type="checkbox" />
                                    <span className="slider round"></span>
                                </label>
                            </div>
                        </li>
                        <li><span className='title-list'>Block from attending my event</span>
                        <div className="switch-box">
                                <label className="switch">
                                    <input type="checkbox" />
                                    <span className="slider round"></span>
                                </label>
                            </div>
                        </li>
                        <li><span className='title-list'>Block from reading my newsletter and article</span>
                        <div className="switch-box">
                                <label className="switch">
                                    <input type="checkbox" />
                                    <span className="slider round"></span>
                                </label>
                            </div>
                        </li>
                    </ul>
                    <div className='button-outer text-end'>
                        <button className='btn btn-blue'>Save</button>
                    </div>
                </div>
            </ModalBody>
        </Modal>


        {/* Profile data */}
        <Modal isOpen={modalOpen6} toggle={toggleModal6} className='profile-data-modal setting-modal-default-modal'>
            <ModalHeader toggle={toggleModal6}>
                Profile data
            </ModalHeader>
            <ModalBody>
                <p className='gray-light400 mt-0'>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc vulputate libero et velit interdum, ac aliquet odio mattis.</p>
                <div className='checkbox-box mt-3'>
                    <ul>
                        <li><span className='title-list'>Profile data on ads</span>
                            <div className="switch-box">
                                <label className="switch">
                                    <input type="checkbox" />
                                    <span className="slider round"></span>
                                </label>
                            </div>
                        </li>
                    </ul>
                </div>
            </ModalBody>
        </Modal>


        {/* Language */}
        <Modal isOpen={modalOpen7} toggle={toggleModal7} className='language-modal setting-modal-default-modal'>
            <ModalHeader toggle={toggleModal7}>
                Language
            </ModalHeader>
            <ModalBody>
                <p className='gray-light400 mt-0'>Select the default language you use on NobelPage</p>
                <FormGroup className='mt-4'>
                    <Input
                    id="type"
                    name="select"
                    type="select"
                    >
                        <option value="">English (English)</option>
                        <option value="">Hindi (Hindi)</option>
                    </Input>
                </FormGroup>
                <p className='gray-light400'>At the moment, only English is available. More languages are coming soon! 
However, for posts and feed, there is a translate message option available. 
{/* <Link to='/' className='text-light-blue'>Learn More</Link> */}
</p>
            </ModalBody>
        </Modal>


        {/* Compare Plans and Features */}
        <Modal isOpen={modalOpen8} toggle={toggleModal8} className='compare-plans-modal setting-modal-default-modal'>
            <ModalHeader toggle={toggleModal8}>
                Compare Plans and Features
            </ModalHeader>
            <ModalBody>
                <div className='location'>
                    <p className='mt-0'><span className='gray-light400'>Your Location:</span> <img src={location} alt='Icon' /><span className='gray-light600'>India</span></p>
                </div>
                <table>
                    <tr>
                        <th>Packages</th>
                        <th>Starter</th>
                        <th>Nobel</th>
                        <th>Nobel Plus</th>
                        <th>Scout</th>
                    </tr>
                    <tr>
                        <td><span className="para-text">Referral offer (activated for a certain period of time, once or twice a year)</span></td>
                        <td><img src={tickMember} alt='Icon' /></td>
                        <td><img src={tickMember} alt='Icon' /></td>
                        <td><img src={tickMember} alt='Icon' /></td>
                        <td><img src={tickMember} alt='Icon' /></td>
                    </tr>
                    <tr>
                        <td><span className="para-text">Referral offer (activated for a certain period of time, once or twice a year)</span></td>
                        <td><img src={cross} alt="Icon" /> 1 Month (19-29-39)</td>
                        <td><img src={tickMember} alt="Icon" /> 1 Month (19-29-39)</td>
                        <td><img src={tickMember} alt="Icon" /> 1 Month (19-29-39)</td>
                        <td><img src={tickMember} alt="Icon" /> 1 Month (19-29-39)</td>
                    </tr>
                    <tr>
                        <td><span className="para-text">Referral offer (activated for a certain period of time, once or twice a year)</span></td>
                        <td><img src={cross} alt="Icon" /> 1 Month (19-29-39)</td>
                        <td><img src={cross} alt="Icon" /> 1 Month (19-29-39)</td>
                        <td><img src={tickMember} alt="Icon" /> 1 Month (19-29-39)</td>
                        <td><img src={tickMember} alt="Icon" /> 1 Month (19-29-39)</td>
                    </tr>
                    <tr>
                        <td><span className="para-text">Referral offer (activated for a certain period of time, once or twice a year)</span></td>
                        <td><img src={cross} alt="Icon" /> 1 Month (19-29-39)</td>
                        <td><img src={cross} alt="Icon" /> 1 Month (19-29-39)</td>
                        <td><img src={cross} alt="Icon" /> 1 Month (19-29-39)</td>
                        <td><img src={tickMember} alt="Icon" /> 1 Month (19-29-39)</td>
                    </tr>
                    <tr>
                        <td><span className="para-text">Referral offer (activated for a certain period of time, once or twice a year)</span></td>
                        <td><img src={cross} alt="Icon" /> 1 Month (19-29-39)</td>
                        <td><img src={cross} alt="Icon" /> 1 Month (19-29-39)</td>
                        <td><img src={tickMember} alt="Icon" /> 1 Month (19-29-39)</td>
                        <td><img src={tickMember} alt="Icon" /> 1 Month (19-29-39)</td>
                    </tr>
                    <tr>
                        <td><span className="para-text">Referral offer (activated for a certain period of time, once or twice a year)</span></td>
                        <td><img src={cross} alt="Icon" /> 1 Month (19-29-39)</td>
                        <td><img src={tickMember} alt="Icon" /> 1 Month (19-29-39)</td>
                        <td><img src={tickMember} alt="Icon" /> 1 Month (19-29-39)</td>
                        <td><img src={tickMember} alt="Icon" /> 1 Month (19-29-39)</td>
                    </tr>
                    <tr>
                        <td><span className="para-text">Referral offer (activated for a certain period of time, once or twice a year)</span></td>
                        <td><img src={cross} alt="Icon" /> 1 Month (19-29-39)</td>
                        <td><img src={cross} alt="Icon" /> 1 Month (19-29-39)</td>
                        <td><img src={tickMember} alt="Icon" /> 1 Month (19-29-39)</td>
                        <td><img src={tickMember} alt="Icon" /> 1 Month (19-29-39)</td>
                    </tr>
                </table>
                <div className='button-outer text-center'>
                    <button className='btn btn-blue'>Upgrade Membership</button>
                </div>
            </ModalBody>
        </Modal>

        {/* Phone Numbers */}
        <Modal isOpen={modalOpen9} toggle={toggleModal9} className='phone-numbers-modal setting-modal-default-modal'>
            <ModalHeader toggle={toggleModal9}>
                Phone Numbers
            </ModalHeader>
            <ModalBody>
                <p className='gray-light400'>Want to add your number now? It's a handy way to reset your password if you have problems.</p>
                <p className='gray-light400'>Your phone number helps us keep your account secure by adding an additional layer of verification. It also helps others, who already have your phone number, discover and connect with you. You can always decide how you want your phone number used</p>
                <div className='input-box'>
                    <label className='gray-light600'>Phone Nmber</label>
                    <Input
                        type="number"
                        placeholder="Enter Phone Number"
                    />
                </div>
                <div className='input-box'>
                    <label className='gray-light600'>Enter your NobelPage password</label>
                    <Input
                        type="password"
                        placeholder="Enter Password"
                    />
                </div>
                <div className='button-outer text-center'>
                    <button className='btn btn-blue'>Send Code</button>
                </div>
            </ModalBody>
        </Modal>


        {/* Contact Visibility */}
        <Modal isOpen={modalOpen10} toggle={toggleModal10} className='add-email-account-modal setting-modal-default-modal'>
            <ModalHeader toggle={toggleModal10}>
            Contact Visibility
            </ModalHeader>
            <ModalBody>
                <div className="accordion">
                    <div className={`accordion-item ${openItem === 30 ? 'open' : ''}`}>
                        <h6 className="gray-light600 accordion-title" onClick={() => toggleItem(30)}>
                        Phone Number
                        </h6>
                        {openItem === 30 && <div className="accordion-content">
                            <label for="everyone"><input type='radio' id="everyone" name='radio' /> Visible To Everyone</label>
                            <label for="network"><input type='radio' id="network" name='radio' /> Visible To My Network</label>
                            <label for="scouts"><input type='radio' id="scouts" name='radio' /> Scouts only</label>
                            <label for="hidden"><input type='radio' id="hidden" name='radio' /> Hidden</label>
                        </div>}
                    </div>
                    <div className={`accordion-item ${openItem === 31 ? 'open' : ''}`}>
                        <h6 className="gray-light600 accordion-title" onClick={() => toggleItem(31)}>
                        Email 
                        </h6>
                        {openItem === 31 && <div className="accordion-content">
                            <label for="everyone"><input type='radio' id="everyone" name='radio' /> Visible To Everyone</label>
                            <label for="network"><input type='radio' id="network" name='radio' /> Visible To My Network</label>
                            <label for="scouts"><input type='radio' id="scouts" name='radio' /> Scouts only</label>
                            <label for="hidden"><input type='radio' id="hidden" name='radio' /> Hidden</label>
                        </div>}
                    </div>
                    <div className={`accordion-item ${openItem === 32 ? 'open' : ''}`}>
                        <h6 className="gray-light600 accordion-title" onClick={() => toggleItem(32)}>
                        Address
                        </h6>
                        {openItem === 32 && <div className="accordion-content">
                            <label for="everyone"><input type='radio' id="everyone" name='radio' /> Visible To Everyone</label>
                            <label for="network"><input type='radio' id="network" name='radio' /> Visible To My Network</label>
                            <label for="scouts"><input type='radio' id="scouts" name='radio' /> Scouts only</label>
                            <label for="hidden"><input type='radio' id="hidden" name='radio' /> Hidden</label>
                        </div>}
                    </div>
                    <div className={`accordion-item ${openItem === 33 ? 'open' : ''}`}>
                        <h6 className="gray-light600 accordion-title" onClick={() => toggleItem(33)}>
                        Birthday
                        </h6>
                        {openItem === 33 && <div className="accordion-content">
                            <label for="everyone"><input type='radio' id="everyone" name='radio' /> Visible To Everyone</label>
                            <label for="network"><input type='radio' id="network" name='radio' /> Visible To My Network</label>
                            <label for="scouts"><input type='radio' id="scouts" name='radio' /> Scouts only</label>
                            <label for="hidden"><input type='radio' id="hidden" name='radio' /> Hidden</label>
                        </div>}
                    </div>
                    <div className={`accordion-item ${openItem === 34 ? 'open' : ''}`}>
                        <h6 className="gray-light600 accordion-title" onClick={() => toggleItem(34)}>
                        Website
                        </h6>
                        {openItem === 34 && <div className="accordion-content">
                            <label for="everyone"><input type='radio' id="everyone" name='radio' /> Visible To Everyone</label>
                            <label for="network"><input type='radio' id="network" name='radio' /> Visible To My Network</label>
                            <label for="scouts"><input type='radio' id="scouts" name='radio' /> Scouts only</label>
                            <label for="hidden"><input type='radio' id="hidden" name='radio' /> Hidden</label>
                        </div>}
                    </div>
                </div>
                <div className='button-outer text-end'>
                    <button className='btn btn-blue'>Save</button>
                </div>
            </ModalBody>
        </Modal>


        {/* Jobs,Network,Profile,Email,Messaging Suggestions,Messaging Notification, NP Mail Notification */}
        <Modal isOpen={modalOpen11} toggle={toggleModal11} className='jobs-list-modal setting-modal-default-modal'>
            <ModalHeader toggle={toggleModal11}>
                Jobs
            </ModalHeader>
            <ModalBody>
                <div className='checkbox-box'>
                    <ul>
                        <li className='gray-light600'><span className='title-list'>All jobs</span>
                            <div className="switch-box">
                                <label className="switch">
                                    <input type="checkbox" />
                                    <span className="slider round"></span>
                                </label>
                            </div>
                        </li>
                        <li><span className='title-list'>A newly posted job recommended to you</span>
                            <div className="switch-box">
                                <label className="switch">
                                    <input type="checkbox" />
                                    <span className="slider round"></span>
                                </label>
                            </div>
                        </li>
                        <li><span className='title-list'>A newly posted job that matches your job search alert</span>
                            <div className="switch-box">
                                <label className="switch">
                                    <input type="checkbox" />
                                    <span className="slider round"></span>
                                </label>
                            </div>
                        </li>
                        <li><span className='title-list'>Job application reminders</span>
                            <div className="switch-box">
                                <label className="switch">
                                    <input type="checkbox" />
                                    <span className="slider round"></span>
                                </label>
                            </div>
                        </li>
                        <li><span className='title-list'>Job application views</span>
                            <div className="switch-box">
                                <label className="switch">
                                    <input type="checkbox" />
                                    <span className="slider round"></span>
                                </label>
                            </div>
                        </li>
                        <li><span className='title-list'>Job change confirmation</span>
                            <div className="switch-box">
                                <label className="switch">
                                    <input type="checkbox" />
                                    <span className="slider round"></span>
                                </label>
                            </div>
                        </li>
                        <li><span className='title-list'>Job poster verification updates</span>
                            <div className="switch-box">
                                <label className="switch">
                                    <input type="checkbox" />
                                    <span className="slider round"></span>
                                </label>
                            </div>
                        </li>
                        <li><span className='title-list'>Jobs you may be interested in</span>
                            <div className="switch-box">
                                <label className="switch">
                                    <input type="checkbox" />
                                    <span className="slider round"></span>
                                </label>
                            </div>
                        </li>
                        <li><span className='title-list'>New job applicants for hiring managers</span>
                            <div className="switch-box">
                                <label className="switch">
                                    <input type="checkbox" />
                                    <span className="slider round"></span>
                                </label>
                            </div>
                        </li>
                        <li><span className='title-list'>Reminder to complete job preferences</span>
                            <div className="switch-box">
                                <label className="switch">
                                    <input type="checkbox" />
                                    <span className="slider round"></span>
                                </label>
                            </div>
                        </li>
                        <li><span className='title-list'>Resume downloads by recruiters</span>
                            <div className="switch-box">
                                <label className="switch">
                                    <input type="checkbox" />
                                    <span className="slider round"></span>
                                </label>
                            </div>
                        </li>
                        <li><span className='title-list'>Salary insights</span>
                            <div className="switch-box">
                                <label className="switch">
                                    <input type="checkbox" />
                                    <span className="slider round"></span>
                                </label>
                            </div>
                        </li>
                        <li><span className='title-list'>Your job search alerts</span>
                        <div className="switch-box">
                                <label className="switch">
                                    <input type="checkbox" />
                                    <span className="slider round"></span>
                                </label>
                            </div>
                        </li>
                    </ul>
                </div>
            </ModalBody>
        </Modal>



        {/* Connections */}
        <Modal isOpen={modalOpen12} className='profile-data-modal setting-modal-default-modal'>
            <ModalHeader toggle={toggleModal12}>
            Connections
            </ModalHeader>
            <ModalBody>
                <p className='gray-light400 mt-0'>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc vulputate libero et velit interdum, ac aliquet odio mattis.</p>
                <div className='checkbox-box mt-3'>
                    <ul>
                        <li><span className='title-list'>Use connections data</span>
                            <div className="switch-box">
                                <label className="switch">
                                    <input type="checkbox" />
                                    <span className="slider round"></span>
                                </label>
                            </div>
                        </li>
                    </ul>
                </div>
            </ModalBody>
        </Modal>


        {/* Feed Preferences */}
        <Modal isOpen={modalOpen13} toggle={toggleModal13} className='feed-preferences-modal setting-modal-default-modal'>
            <ModalHeader toggle={toggleModal13}>
                Feed Preferences
            </ModalHeader>
            <ModalBody>
                <p className='gray-light400 mt-0'>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
                <div className='checkbox-box mt-3'>
                    <ul>
                        <li className='mb-0'><label for="checkbox"><input type="checkbox" id="checkbox" name="checkbox" /> People I Follow</label></li>
                        <li><label for="checkbox1"><input type="checkbox" id="checkbox1" name="checkbox" /> Pages</label></li>
                        <li><label for="checkbox2"><input type="checkbox" id="checkbox2" name="checkbox" /> Hashtags</label></li>
                        <li><label for="checkbox3"><input type="checkbox" id="checkbox3" name="checkbox" /> Events</label></li>
                        <li><label for="checkbox4"><input type="checkbox" id="checkbox4" name="checkbox" /> Articles</label></li>
                        <li><label for="checkbox5"><input type="checkbox" id="checkbox5" name="checkbox" /> Groups</label></li>
                        <li><label for="checkbox6"><input type="checkbox" id="checkbox6" name="checkbox" /> Newsletters</label></li>
                        <li><label for="checkbox7"><input type="checkbox" id="checkbox7" name="checkbox" /> Connections</label></li>
                    </ul>
                </div>
                <div className='button-outer text-end'>
                    <button className='btn btn-blue'>Save</button>
                </div>
            </ModalBody>
        </Modal>


        {/* Add-ons Packages */}
        <Modal isOpen={modalOpen14} toggle={toggleModal14} className='add-ons-packages-modal setting-modal-default-modal'>
            <ModalHeader toggle={toggleModal14}>
            Add-ons Packages
            </ModalHeader>
            <ModalBody>
                <h6 className='gray-light600'>Prchased packages</h6>
                <div className='d-flex flex-wrap justify-content-between'>
                    <div className='left-content col-6'>
                        CV download non editable PDF <br />
                        (with different layouts)
                    </div>
                    <div className='right-content col-6'>
                        Expire at 24/12/2022
                    </div>
                    <div className='left-content col-6'>
                        CV download editable <br />
                        (with different layouts)
                    </div>
                    <div className='right-content col-6'>
                        Expire at 24/12/2022
                    </div>
                    <div className='left-content col-6'>
                        Profile truth badge
                    </div>
                    <div className='right-content col-6'>
                        Expire at 24/12/2022
                        </div>
                    <div className='left-content col-6'>
                        Feature profile (1 month)
                    </div>
                    <div className='right-content col-6'>
                        Expire at 24/12/2022
                    </div>
                </div>
            </ModalBody>
            <ModalFooter>
                <button className='add-btn'>+ Add-ons package</button>
            </ModalFooter>
        </Modal>


        {/* Change Password */}
        <Modal isOpen={modalOpen15} toggle={toggleModal15} className='change-password-modal setting-modal-default-modal'>
            <ModalHeader toggle={toggleModal15}>
                Change Password
            </ModalHeader>
            <ModalBody>
                <p className='gray-light400'>Enter the email address associated with the account you wish to merge into the account you are keeping.</p>
                <div className='input-box'>
                    <Input
                        type="password"
                        placeholder="Current Password"
                    />
                </div>
                <div className='input-box'>
                    <Input
                        type="password"
                        placeholder="New Password"
                    />
                </div>
                <div className='input-box'>
                    <Input
                        type="password"
                        placeholder="Retype Password"
                    />
                </div>
                <div className='button-outer d-flex flex-column'>
                    <button className='add-btn'>Forgot Password</button>
                    <button className='btn btn-blue mt-3'>Save Password</button>
                </div>
            </ModalBody>
        </Modal>



        {/* Last name visibility */}
        <Modal isOpen={modalOpen16} className='last-name-radio-list-modal setting-modal-default-modal'>
            <ModalHeader toggle={toggleModal16}>
            Last name visibility
            </ModalHeader>
            <ModalBody>
                <p className='gray-light400 mt-0'>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
                <div className="radio-list-outer">
                    <div className='radio-list'>
                        <input type="radio" id="checkbox1" name="checkbox" />
                        <img src={settingProfileImg3} alt="Profile Img" />
                        <h3>Gayathri Suresh</h3>
                    </div>
                    <div className='radio-list'>
                        <input type="radio" id="checkbox1" name="checkbox" />
                        <img src={settingProfileImg3} alt="Profile Img" />
                        <h3>Gayathri S</h3>
                    </div>
                    <div className='button-outer text-end'>
                        <button className='btn btn-blue'>Done</button>
                    </div>
                </div>
            </ModalBody>
        </Modal>


        {/* Transactions */}
        <Modal isOpen={modalOpen17} toggle={toggleModal17} className='add-ons-packages-modal setting-modal-default-modal'>
            <ModalHeader toggle={toggleModal17}>
            Transactions
            </ModalHeader>
            <ModalBody>
                <div className='d-flex justify-content-between'>
                    <h3 className='gray-light600'>Wallet history <img src={documentDownloadFill} alt='Icon' /></h3>
                    <FormGroup className='d-flex form-group-box align-items-center'>
                        <Label>Sort by:</Label>
                        <Input
                        id="type"
                        name="select"
                        type="select"
                        >
                        <option value="">Latest</option>
                        <option value="">Popular</option>
                        </Input>
                    </FormGroup>
                </div>
                <div className='d-flex flex-wrap justify-content-between transactions-item'>
                    <div className='left-content col-6'>
                        <span className='gray-light600'>Your Subscription plan upgrading</span> <br />
                        Yesterday, 08:40 PM
                    </div>
                    <div className='right-content col-6'>
                        -38.58$
                    </div>
                </div>
                <div className='d-flex flex-wrap justify-content-between transactions-item'>
                    <div className='left-content col-6'>
                        <span className='gray-light600'>Received cashback bonus</span> <br />
                        Yesterday, 08:40 PM
                    </div>
                    <div className='right-content col-6'>
                        -38.58$
                    </div>
                </div>
                <div className='d-flex flex-wrap justify-content-between transactions-item'>
                    <div className='left-content col-6'>
                        <span className='gray-light600'>Money sent to John Miller</span> <br />
                        Yesterday, 08:40 PM
                    </div>
                    <div className='right-content col-6'>
                        -38.58$
                    </div>
                </div>
                <div className='d-flex flex-wrap justify-content-between transactions-item'>
                    <div className='left-content col-6'>
                        <span className='gray-light600'>Money transfer failed to Amir Khan</span> <br />
                        Yesterday, 08:40 PM
                    </div>
                    <div className='right-content col-6'>
                        -38.58$ <br />
                        <div className='text-danger'>Failed</div>
                    </div>
                </div>
                <div className='d-flex flex-wrap justify-content-between transactions-item'>
                    <div className='left-content col-6'>
                        <span className='gray-light600'>Your Subscription plan upgradation</span> <br />
                        Yesterday, 08:40 PM
                    </div>
                    <div className='right-content col-6'>
                        -38.58$
                    </div>
                </div>
            </ModalBody>
        </Modal>

        {/* Archive Message Duration */}
        <Modal isOpen={modalOpen18} toggle={toggleModal18} className='last-name-radio-list-modal setting-modal-default-modal'>
            <ModalHeader toggle={toggleModal18}>
            Archive Message Duration
            </ModalHeader>
            <ModalBody>
                <div className="radio-list-outer mt-0">
                    <div className='radio-list'>
                        <input type="radio" id="checkbox1" name="checkbox" />
                        <label for="checkbox1">7 days</label>
                    </div>
                    <div className='radio-list'>
                        <input type="radio" id="checkbox2" name="checkbox" />
                        <label for="checkbox2">15 days</label>
                    </div>
                    <div className='radio-list'>
                        <input type="radio" id="checkbox3" name="checkbox" />
                        <label for="checkbox3">30 days</label>
                    </div>
                    <div className='radio-list'>
                        <input type="radio" id="checkbox4" name="checkbox" />
                        <label for="checkbox4">60 days</label>
                    </div>
                    <div className='button-outer text-end'>
                        <button className='btn btn-blue'>Done</button>
                    </div>
                </div>
            </ModalBody>
        </Modal>


        {/* Logged in Locations */}
        <Modal isOpen={modalOpen19} toggle={toggleModal19} className='logged-location-modal setting-modal-default-modal'>
            <ModalHeader toggle={toggleModal19}>
            Logged in locations
            </ModalHeader>
            <ModalBody>
                <p className='gray-light600 mt-0'>You're signed in to 2 locations</p>
                <p className='gray-light400'>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc vulputate libero et velit interdum, ac aliquet</p>
                <h5>Current location</h5>
                <div className='d-flex'>
                    <img src={loggedSession1} alt='Icon' />
                    <div className='system-location'>
                        <h6>Calicut, Kerala, India</h6>
                        <div className='os-system'>
                            MacOS on Safari
                            <span className='ip-address'>IP Address: 103.119.255.168</span>
                        </div>
                    </div>
                </div>
                <h5>Other active locations</h5>
                <button className='btn btn-blue'>Log out from all these locations</button>
                <div className='d-flex justify-content-between list-item'>
                    <div className='system-location'>
                        <div className='d-flex'>
                            <img src={loggedSession2} alt='Icon' />
                            <div>
                                <h6>Calicut, Kerala, India</h6>
                                <div className='os-system'>
                                    MacOS on Safari
                                    <span className='ip-address'>IP Address: 103.119.255.168</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <Link to='/'>Log out</Link>
                </div>
                <div className='d-flex justify-content-between list-item'>
                    <div className='system-location'>
                        <div className='d-flex'>
                            <img src={loggedSession3} alt='Icon' />
                            <div>
                                <h6>Calicut, Kerala, India</h6>
                                <div className='os-system'>
                                    MacOS on Safari
                                    <span className='ip-address'>IP Address: 103.119.255.168</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <Link to='/'>Log out</Link>
                </div>
                <div className='d-flex justify-content-between list-item'>
                    <div className='system-location'>
                        <div className='d-flex'>
                            <img src={loggedSession4} alt='Icon' />
                            <div>
                                <h6>Calicut, Kerala, India</h6>
                                <div className='os-system'>
                                    MacOS on Safari
                                    <span className='ip-address'>IP Address: 103.119.255.168</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <Link to='/'>Log out</Link>
                </div>
            </ModalBody>
        </Modal>


        {/* Saved Cards */}
        <Modal isOpen={modalOpen20} toggle={toggleModal20} className='saved-cards-modal setting-modal-default-modal'>
            <ModalHeader toggle={toggleModal20}>
                Saved Cards
            </ModalHeader>
            <ModalBody>
                <div className='d-flex justify-content-between list-item'>
                    <div className='system-location'>
                        <div className='d-flex'>
                            <img src={visacrdSmall} alt='Icon' />
                            <div className='card-desc'>
                                <h6 className='gray-light600 mt-0'>Mastercard: 6372</h6>
                                <h6 className='gray-light400 mt-0'>Exp. date: 12/22</h6>
                            </div>
                        </div>
                        <label for="checkbox"><input type='checkbox' id="checkbox" /> Make the card as "default"</label>
                    </div>
                    <span className='btn-close' />
                </div>
                <div className='d-flex justify-content-between list-item'>
                    <div className='system-location'>
                        <div className='d-flex'>
                            <img src={mastercardSmall} alt='Icon' />
                            <div className='card-desc'>
                                <h6 className='gray-light600 mt-0'>Mastercard: 6372</h6>
                                <h6 className='gray-light400 mt-0'>Exp. date: 12/22</h6>
                            </div>
                        </div>
                        <label for="checkbox1"><input type='checkbox' id="checkbox1" /> Make the card as "default"</label>
                    </div>
                    <span className='btn-close' />
                </div>
                <div className='btn-outer text-center mt-4'>
                    <button className="add-btn">+ Add New Card</button>
                </div>
            </ModalBody>
        </Modal>


        {/* Two Factor Authentication */}
        <Modal isOpen={modalOpen21} toggle={toggleModal21} className='phone-numbers-modal setting-modal-default-modal'>
            <ModalHeader toggle={toggleModal21}>
                Two Factor Authentication
            </ModalHeader>
            <ModalBody>
                <p className='gray-light400 mt-0'>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc vulputate 
                libero et velit interdum, ac aliquet</p>
                <div className='d-flex align-items-center justify-content-between'>
                    <h6 className='gray-light600'>Enable Two Factor Authentication</h6>
                    <div className="switch-box">
                        <label className="switch">
                            <input type="checkbox" />
                            <span className="slider round"></span>
                        </label>
                    </div>
                </div>
                <p className='gray-light400'>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc vulputate 
                libero et velit interdum, ac aliquet</p>
                <div className='input-box'>
                    <label className='gray-light600'>Phone Nmber</label>
                    <Input
                        type="number"
                        placeholder="Enter Phone Number"
                    />
                </div>
                <div className='button-outer text-center'>
                    <button className='btn btn-blue'>Save</button>
                </div>
            </ModalBody>
        </Modal>


        {/* Who can follow you */}
        <Modal isOpen={modalOpen22} toggle={toggleModal22} className='last-name-radio-list-modal setting-modal-default-modal'>
            <ModalHeader toggle={toggleModal22}>
            Who can follow you
            </ModalHeader>
            <ModalBody>
                <h6 className='gray-light400'>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</h6>
                <div className="radio-list-outer mt-4">
                    <div className='radio-list'>
                        <input type="radio" id="checkbox1" name="checkbox" />
                        <label for="checkbox1">Everyone on NobelPage</label>
                    </div>
                    <div className='radio-list'>
                        <input type="radio" id="checkbox2" name="checkbox" />
                        <label for="checkbox2">Your connections</label>
                    </div>
                </div>
                <div className='d-flex align-items-center justify-content-between mt-4'>
                    <h6 className='gray-light600 mt-0'>Make follow primary</h6>
                    <div className="switch-box">
                        <label className="switch">
                            <input type="checkbox" />
                            <span className="slider round"></span>
                        </label>
                    </div>
                </div>
                <p className='gray-light400 mt-2'>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
            </ModalBody>
        </Modal>

        {/* Demographics */}
        <Modal isOpen={modalOpen23} toggle={toggleModal23} className='last-name-radio-list-modal setting-modal-default-modal'>
            <ModalHeader toggle={toggleModal23}>
            Demographics
            </ModalHeader>
            <ModalBody>
                <p className='gray-light400'>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc vulputate libero et velit interdum, ac aliquet odio mattis.</p>
                <div className='d-flex align-items-center justify-content-between mt-4'>
                    <h6 className='gray-light400 mt-0'>Use your age for ads</h6>
                    <div className="switch-box">
                        <label className="switch">
                            <input type="checkbox" />
                            <span className="slider round"></span>
                        </label>
                    </div>
                </div>
                <p className='gray-light400 mt-4'>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc vulputate libero et velit interdum, ac aliquet odio mattis.</p>
                <div className='d-flex align-items-center justify-content-between mt-4'>
                    <h6 className='gray-light400 mt-0'>Use your gender for ads</h6>
                    <div className="switch-box">
                        <label className="switch">
                            <input type="checkbox" />
                            <span className="slider round"></span>
                        </label>
                    </div>
                </div>
            </ModalBody>
        </Modal>




    </>
  )
}

export default SettingModals
