import axios from "axios";
export const callChat = async (method, url, params = {}, data = {}, token) => {
  data = { ...data };

  const api = axios.create({
    baseURL: process.env.REACT_APP_SOCKET_URL,
  });
  // let currentUrl;
  // api.interceptors.request.use(
  //   (config) => {
  //     currentUrl = config.url;
  //     return config;
  //   },
  //   (error) => {
  //     return Promise.reject(error);
  //   }
  // );

  // api.interceptors.response.use(
  //   (response) => {
  //     return response;
  //   },
  //   (error) => {
  //     const { response } = error;
  //     if (response && (response.status === 401 || response.status === 403)) {
  //       // Handle unauthorized or forbidden error here
  //       localStorage.removeItem("chatToken");
  //       window.location.replace("/");
  //       console.log("Unauthorized or Forbidden Error:", error);
  //     }
  //     return Promise.reject(error);
  //   }
  // );

  token = token || localStorage.getItem("chatToken");

  const result = await api({
    method,
    url,
    params,
    data: { ...data },
    headers: {
      "Content-Type": "application/json",
      "Accept-Language": "en",
      authorization: token ? `Bearer ${token}` : "",
    },
    // timeout: 60000,
    responseType: "json",
  });

  return result;
};
