import React, { useEffect, useState } from "react";
import CreateCampaign from "./CreateCampaign";
import { getIndustryData } from "../../../store/master/masterActions";
import { useDispatch, useSelector } from "react-redux";
import { postCampaign } from "../../../store/campaign/campaignActions";
import moment from "moment";
import { getPageDetails } from "../../../store/dashboard/dashboardActions";
import { toast } from "react-toastify";
import { useLocation, useNavigate } from "react-router-dom";
import { getCampaign } from "../../../store/campaign/campaignActions";
import { getLocationsData } from "../../../store/connections/connectionsActions";
import { getPostDetails } from "../../../store/dashboard/dashboardActions";
import FeatureJob from "./Components/FeatureJob";

const CreateCampaigncontainer = () => {
  const { state } = useLocation();

  const navigate = useNavigate();

  const { user } = useSelector((state) => state.auth);
  const { postData } = useSelector((state) => state.dashboard);
  const { getPageDetailData } = useSelector((state) => state.dashboard);

  const [tabs, setTabs] = useState("create-camp");
  const [campTitle, setCampTitle] = useState("Promote and Feature");
  const [nameErrorMessage, setNameErrorMessage] = useState("");
  const [websiteErr, setWebsiteErr] = useState("");
  const [descriptionErrorMessage, setDescriptionErrorMessage] = useState("");
  const [errorActionLink, setErrorActionLink] = useState("");
  const [typeIdError, setTypeIdError] = useState("");
  const [gender, setGender] = useState("");
  const [savedStatus, setSavedStatus] = useState("payment");
  const [hashText, setHashText] = useState([]);
  const [industryInput, setIndustryInput] = useState(
    ""
    // editPageData ? editPageData.industry : ""
  );

  const [paymentModal, setPaymentModal] = useState(false);

  var d = new Date();
  let h = (d.getHours() < 10 ? "0" : "") + d.getHours();
  let m = (d.getMinutes() < 10 ? "0" : "") + d.getMinutes();
  var today = new Date().toISOString().split("T")[0];
  const [scheduleCheck, setScheduleCheck] = useState(false);
  const [scheduleDate, setScheduleDate] = useState(today);
  const [scheduleTime, setScheduleTime] = useState(`${h}:${m}`);
  const [duration, setDuration] = useState(1);
  const [locationList, setLocationList] = useState([]);
  const [locationInput, setLocationInput] = useState();
  const [postDetail, setPostDetail] = useState(null);
  const [locationOpen1, setLocationOpen1] = useState(false);
  const [locationOpen2, setLocationOpen2] = useState(false);
  const [locationOpen3, setLocationOpen3] = useState(false);
  const [locationOpen4, setLocationOpen4] = useState(false);
  const [locationOpen5, setLocationOpen5] = useState(false);
  const [selectedFeed, setSelectedFeed] = useState();
  const [campaignDetail, setCampaignDetail] = useState({
    name: "",
    description: "",
    campaign_status: "published",
    start_date: scheduleDate ? scheduleDate : new Date().format("YYYY-MM-DD"),
    end_date: moment(scheduleDate ? scheduleDate : new Date())
      .add(duration, "d")
      .format("YYYY-MM-DD"),
    type: "page",
    type_id: "",
    industry: [],
    region: [],
    gender: "male",
    age_from: 15,
    age_to: 45,
    hashtags: hashText,
    website: "",
    action_link: "",
    action_link_label: "",
    is_adBar: true,
    is_wp: false,
  });
  // useEffect(()=>{
  //   if(state.isPropmt){
  //     setCampaignDetail({})
  //   }
  // },[])

  const inputArr = [
    {
      value: "",
    },
  ];

  const inputLocationArr = [
    {
      id: 1,
      value: "",
    },
  ];

  const [arr, setArr] = useState(inputArr);
  const [locationArr, setLocationArr] = useState(inputLocationArr);

  const addInput = () => {
    setArr((s) => {
      return [
        ...s,
        {
          value: "",
        },
      ];
    });
  };

  const addLocation = () => {
    setLocationArr((s) => {
      return [
        ...s,
        {
          type: "text",
          value: "",
        },
      ];
    });
  };

  const dispatch = useDispatch();

  useEffect(() => {
    if (tabs === "create-camp") setCampTitle("Promote and Feature");
    else setCampTitle("Promote");
  }, [tabs]);
  useEffect(() => {
    dispatch(getIndustryData());
  }, []);

  useEffect(() => {
    if (locationInput) {
      dispatch(getLocationsData(locationInput))
        .then((res) => {
          if (res?.data) {
            const removeDuplicates = res?.data.filter((el) => {
              const isDuplicate = locationArr.find((x) => x.value === el.name);
              return el.name !== isDuplicate?.value;
            });
            setLocationList(removeDuplicates);
          } else setLocationList([]);
        })
        .catch(() => {
          setLocationList([]);
        });
    }
  }, [locationArr]);

  useEffect(() => {
    if (state) {
      if (state.type === "post") {
        const token = localStorage.getItem("accessToken");
        dispatch(getPostDetails(token, { id: state?.type_id })).then((res) => {
          setPostDetail(res.data[0]);
        });
      }
    }
  }, []);

  const getDaysDiff = (startDate, EndDate) => {
    const date1 = new Date(startDate);
    const date2 = new Date(EndDate);
    const diffTime = Math.abs(date2 - date1);
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
    setDuration(diffDays);
  };

  useEffect(() => {
    if (state?.id) {
      if (state?.source === "page") {
        setCampaignDetail({
          ...campaignDetail,
          type: "page",
          type_id: state?.id,
        });
      } else {
        dispatch(getCampaign({ id: state?.id }))
          .then((response) => {
            setCampaignDetail({
              ...campaignDetail,
              id: response.data[0].id,
              name: response.data[0].name,
              description: response.data[0].description,
              campaign_status: response.data[0].campaign_status,
              start_date: moment(response.data[0].start_date).format(
                "YYYY-MM-DD"
              ),
              end_date: moment(response.data[0].end_date).format("YYYY-MM-DD"),
              type: response.data[0].type,
              type_id: response.data[0].type_id,
              industry: response.data[0].industry,
              age_from: response.data[0].age_from,
              age_to: response.data[0].age_to,
              gender: response.data[0].gender,
              hashtags: response.data[0].hashtags.map((e) => {
                return e.name;
              }),
              action_link: response.data[0].action_link,
              action_link_label: response.data[0].action_link_label,
              is_adBar: response.data[0].is_adBar,
              website: response.data[0].website,
              is_wp: response.data[0].is_wp,
            });
            setScheduleDate(
              moment(response.data[0].start_date).format("YYYY-MM-DD")
            );
            setScheduleTime(
              moment(response.data[0].start_date).format("HH:mm")
            );
            setHashText(
              response.data[0].hashtags.map((e) => {
                return e.name;
              })
            );
            getDaysDiff(response.data[0].start_date, response.data[0].end_date);

            var newObj = [
              {
                value: "",
              },
            ];
            for (var i = 0; i < response.data[0].industry?.length; i++) {
              var temp = {};
              temp["value"] = response.data[0].industry[i];
              if (temp.value && i < 5) newObj.push(temp);
            }

            var newObj1 = [
              {
                value: "",
              },
            ];

            for (var i = 0; i < response.data[0].region?.length; i++) {
              var temp = {};
              temp["value"] = response.data[0].region[i];
              if (temp.value && i < 5) newObj1.push(temp);
            }

            var newArray = newObj.filter((item) => item.value !== "");
            var newArray1 = newObj1.filter((item) => item.value !== "");
            // console.log("newObj", newObj);
            // console.log("response.data[0].industry?.length", response.data[0].industry?.length);
            setArr(response.data[0].industry?.length > 0 ? newArray : newObj);
            setLocationArr(
              response.data[0].region?.length > 0 ? newArray1 : newObj1
            );
          })
          .catch((e) => {});
      }
    }
  }, []);

  useEffect(() => {
    let token = window.localStorage.getItem("accessToken");
    if (campaignDetail.type === "page") {
      dispatch(getPageDetails(token));
    } else if (campaignDetail.type === "post") {
      dispatch(
        getPostDetails(token, {
          user_id: user?.data?.id,
          feed_status: "published",
          skip: 0,
          limit: 10,
        })
      );
    }
  }, [campaignDetail.type]);

  const checkValidation = (type) => {
    let stringName = /^[a-zA-Z\s._]+$/;
    // let urlName = /^((ftp|http|https):\/\/)?(www.)?(?!.*(ftp|http|https|www.))[a-zA-Z0-9_-]+(\.[a-zA-Z]+)+((\/)[\w#]+)*(\/\w+\?[a-zA-Z0-9_]+=\w+(&[a-zA-Z0-9_]+=\w+)*)?\/?$/gm;
    let urlName =
      /^(https?:\/\/localhost(:\d+)?|https?:\/\/[^/?#]+(?:\.[^/?#]+)+)(?:[/?#]\S*)?$/gm;
    let websiteRegex =
      /^(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/;

    let formIsValid = true;

    if (!campaignDetail.name) {
      setNameErrorMessage("Enter your Campaign Name.");
      formIsValid = false;
    } else if (!stringName.test(campaignDetail.name)) {
      setNameErrorMessage("Campaign name is invalid.");
      formIsValid = false;
    } else if (!(campaignDetail.name.length <= 100)) {
      setNameErrorMessage("Max 80 characters allowed.");
      formIsValid = false;
    } else {
      setNameErrorMessage("");
    }

    if (!(campaignDetail.description.length <= 300)) {
      setDescriptionErrorMessage("Max 300 characters allowed");
      formIsValid = false;
    } else {
      setDescriptionErrorMessage("");
    }

    if (!campaignDetail.type_id) {
      setTypeIdError("Please select field.");
      formIsValid = false;
    }

    if (!urlName.test(campaignDetail.action_link)) {
      setErrorActionLink("Please enter valid action link url.");
      formIsValid = false;
    } else {
      setErrorActionLink("");
    }

    if (!websiteRegex.test(campaignDetail.website) && campaignDetail.website) {
      console.log(campaignDetail.website);
      setWebsiteErr("Please enter valid website url.");
      formIsValid = false;
    } else {
      setWebsiteErr("");
    }

    return formIsValid;
  };

  const createCampaign = (status, type) => {
    if (checkValidation()) {
      let newSubmitData = {
        name: campaignDetail.name,
        description: campaignDetail.description,
        start_date: scheduleDate
          ? scheduleDate +
            " " +
            moment(scheduleTime, "HH:mm:ss").format("HH:mm:ssZ")
          : campaignDetail.start_date,
        end_date: scheduleDate
          ? moment(scheduleDate ? scheduleDate : new Date())
              .add(duration, "d")
              .format("YYYY-MM-DD") +
            " " +
            moment(scheduleTime, "HH:mm:ss").format("HH:mm:ssZ")
          : campaignDetail.end_date,
        type: campaignDetail.type,
        type_id: campaignDetail.type_id,
        industry: arr
          .filter(function (e) {
            return e.value !== "";
          })
          .map((e) => {
            return e.value;
          }),
        region: locationArr
          .filter(function (e) {
            return e.value !== "";
          })
          .map((e) => {
            return e.value;
          }),
        gender: campaignDetail.gender,
        hashtags: campaignDetail.hashtags,
        age_from: campaignDetail.age_from,
        age_to: campaignDetail.age_to,
        website: campaignDetail.website,
        action_link: campaignDetail.action_link,
        action_link_label: campaignDetail.action_link_label,
        is_adBar: campaignDetail.is_adBar,
        is_wp: campaignDetail.is_wp,
        campaign_status: status,
      };

      if (campaignDetail?.id) {
        newSubmitData.id = campaignDetail?.id;
        dispatch(postCampaign(newSubmitData))
          .then((data) => {
            // console.log("status", status);
            if (data.status)
              setTimeout(
                () =>
                  toast.success(
                    status === "draft"
                      ? "Saved Successfully"
                      : status === "scheduled"
                      ? "Scheduled Successfully"
                      : "Published Successfully.",
                    {
                      position: toast.POSITION.BOTTOM_LEFT,
                    }
                  ),
                2000
              );

            setCampaignDetail({});
            // if (status === "published" || status === "scheduled") {
            //   navigate("/campaign");
            // }
            navigate("/campaign");
          })
          .catch((e) => {});
      } else
        dispatch(postCampaign(newSubmitData))
          .then((data) => {
            // console.log("dTt", data);
            setCampaignDetail({
              ...campaignDetail,
              id: data?.data?.id,
            });
            setTimeout(
              () =>
                toast.success(
                  status === "draft"
                    ? "Saved Successfully"
                    : status === "scheduled"
                    ? "Scheduled Successfully"
                    : "Published Successfully",
                  {
                    position: toast.POSITION.BOTTOM_LEFT,
                  }
                ),
              2000
            );
            setCampaignDetail({});
            // if (status === "published" || status === "scheduled") {
            //   navigate("/campaign");
            // }
            navigate("/campaign");
          })
          .catch((e) => {});
    }
  };

  // console.log(campaignDetail);

  return (
    <>
      <CreateCampaign
        tabs={tabs}
        setTabs={setTabs}
        campTitle={campTitle}
        campaignDetail={campaignDetail}
        setCampaignDetail={setCampaignDetail}
        paymentModal={paymentModal}
        setPaymentModal={setPaymentModal}
        nameErrorMessage={nameErrorMessage}
        setNameErrorMessage={setNameErrorMessage}
        websiteErr={websiteErr}
        setWebsiteErr={setWebsiteErr}
        descriptionErrorMessage={descriptionErrorMessage}
        setDescriptionErrorMessage={setDescriptionErrorMessage}
        hashText={hashText}
        setHashText={setHashText}
        savedStatus={savedStatus}
        setSavedStatus={setSavedStatus}
        gender={gender}
        setGender={setGender}
        industryInput={industryInput}
        setIndustryInput={setIndustryInput}
        createCampaign={createCampaign}
        scheduleDate={scheduleDate}
        setScheduleDate={setScheduleDate}
        scheduleTime={scheduleTime}
        setScheduleTime={setScheduleTime}
        today={today}
        duration={duration}
        setDuration={setDuration}
        scheduleCheck={scheduleCheck}
        setScheduleCheck={setScheduleCheck}
        pageDataDropdown={
          campaignDetail.type === "post"
            ? postData?.data
            : getPageDetailData?.data?.Pages
        }
        typeIdError={typeIdError}
        setTypeIdError={setTypeIdError}
        errorActionLink={errorActionLink}
        setErrorActionLink={setErrorActionLink}
        locationList={locationList}
        setLocationList={setLocationList}
        arr={arr}
        setArr={setArr}
        addInput={addInput}
        locationArr={locationArr}
        setLocationArr={setLocationArr}
        addLocation={addLocation}
        locationInput={locationInput}
        setLocationInput={setLocationInput}
        navigate={navigate}
        postDetail={postDetail}
        setPostDetail={setPostDetail}
        locationOpen1={locationOpen1}
        setLocationOpen1={setLocationOpen1}
        locationOpen2={locationOpen2}
        setLocationOpen2={setLocationOpen2}
        locationOpen3={locationOpen3}
        setLocationOpen3={setLocationOpen3}
        locationOpen4={locationOpen4}
        setLocationOpen4={setLocationOpen4}
        locationOpen5={locationOpen5}
        setLocationOpen5={setLocationOpen5}
        checkValidation={checkValidation}
        selectedFeed={selectedFeed}
        setSelectedFeed={setSelectedFeed}
        campaignStatus={state ? state?.status : ""}
      />
    </>
  );
};

export default CreateCampaigncontainer;
