import React, { useEffect, useState } from "react";
import { getLocationsData } from "../../../../store/connections/connectionsActions";
import { useDispatch } from "react-redux";
import Autocomplete from "react-autocomplete";
import "../../Recruiter/Recruiter.scss";
import { getShotlistedList } from "../../../../store/jobs/jobAction";
import { call } from "../../../../api/apiCall";
import debounce from "lodash/debounce";

const FilterHeader = (props) => {
  const {
    selectedJobId,
    setSortBy,
    setLocationFilter,
    setExperienceFilter,
    setEducationFilter,
    tab,
    setTab,
    setCurrentCount,
  } = props;
  const [locationOpen, setLocationOpen] = useState(false);
  const [searchFieldValue, setSearchFieldValue] = useState("");
  const [locationsAPIData, setLocationsAPIData] = useState([]);
  const [degreeList, setDegreeList] = useState("");
  const dispatch = useDispatch();

  const dropdownOptions = {
    experience: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15],
  };

  const getLocations = ({ value }) => {
    if (value)
      dispatch(getLocationsData(value))
        .then((response) => {
          if (response.data) {
            setLocationsAPIData(response.data);
          } else setLocationsAPIData([]);
        })
        .catch((err) => {
          setLocationsAPIData([]);
        });
    else setLocationsAPIData([]);
  };

  useEffect(() => {
    (async () => {
      try {
        const res = await call("get", "api/v1/degree", null, null);
        setDegreeList(res.data);
      } catch (err) {
        console.log(err);
      }
    })();
  }, []);

  const handleSorting = debounce((value) => {
    setSortBy(value);
    setCurrentCount(0);
  }, 300);

  return (
    <div className="col-12 mb-4 bg-white p-3">
      <div className="row filter-header">
        <div className="col-lg-6 p-0">
          <div className="filter-option">
            <ul>
              <li className="p-0">
                <select
                  className="form-select sort-header-select"
                  style={{ fontWeight: "500", fontSize: "13px" }}
                  //   placeholder="Pick"
                  onChange={(e) => handleSorting(e.target.value)}
                >
                  <option default value="asc">
                    Sort by
                  </option>
                  <option value="desc">Recent</option>
                  <option value="asc">Older</option>
                </select>
              </li>
              <li className="autocomplete-wrapper">
                <div className="search">
                  <Autocomplete
                    open={locationOpen}
                    inputProps={{
                      placeholder: "Location",
                      style: { fontWeight: "500", fontSize: "13px" },
                    }}
                    value={searchFieldValue}
                    items={locationsAPIData}
                    getItemValue={(item) => item.name}
                    shouldItemRender={() => locationsAPIData}
                    renderMenu={(items, value) => (
                      <div className="dropdown">
                        {items.length === 0 ? `No matches for ${value}` : items}
                      </div>
                    )}
                    renderItem={(item, isHighlighted) => (
                      <div
                        className={`item ${
                          isHighlighted ? "selected-item" : ""
                        }`}
                      >
                        {item.name}
                      </div>
                    )}
                    onChange={(e, val) => {
                      if (val.length === 0) {
                        if (locationOpen) setLocationOpen(false);
                      } else {
                        if (!locationOpen) setLocationOpen(true);
                      }
                      setSearchFieldValue(e.target.value);
                      setLocationFilter(e.target.value);
                      getLocations(e.target);
                    }}
                    onSelect={(value, obj) => {
                      setSearchFieldValue(value);
                      //   setPageDetails({
                      //     ...pageDetails,
                      //     location: obj.name,
                      //   });
                      setLocationFilter(obj?.name);
                      setLocationOpen(false);
                      setCurrentCount(0);
                    }}
                    wrapperStyle={{}}
                  />
                </div>
              </li>
              <li className="">
                <select
                  className="form-select sort-header-select"
                  style={{ fontWeight: "500", fontSize: "13px" }}
                  onChange={(e) => {
                    setExperienceFilter(e.target.value);
                    setCurrentCount(0);
                  }}
                >
                  <option default value="">
                    Experience
                  </option>
                  {dropdownOptions?.experience?.map((option, index) => (
                    <option key={index} value={option}>
                      {option === 0 ? "Fresher" : option}{" "}
                      {option === 0 ? "" : option === 1 ? "Year" : "Years"}
                    </option>
                  ))}
                </select>
              </li>

              <li className="">
                <select
                  className="form-select sort-edu-select"
                  style={{ fontWeight: "500", fontSize: "13px" }}
                  onChange={(e) => {
                    setEducationFilter(e.target.value);
                    setCurrentCount(0);
                  }}
                >
                  <option default value={null}>
                    Education
                  </option>
                  {degreeList?.data?.map((item, index) => {
                    return (
                      <option key={index} value={item.name}>
                        {item.name}
                      </option>
                    );
                  })}
                </select>
              </li>
            </ul>
          </div>
        </div>
        <div className="col-lg-6">
          <div className="filter-tabs">
            <span
              className={tab === "shotlisted" ? "active" : ""}
              onClick={() => {
                setTab("shotlisted");
                setCurrentCount(0);
              }}
            >
              Shortlisted Candidates
            </span>
            <span
              className={tab === "viewCandidate" ? "active" : ""}
              onClick={() => {
                setTab("viewCandidate");
                setCurrentCount(0);
              }}
            >
              View Candidate
            </span>
            <span
              className={tab === "viewSmartInterview" ? "active" : ""}
              onClick={() => {
                // alert("This feature will implement in upcomming milestone");
                setTab("viewSmartInterview");
              }}
            >
              View Smart Interview
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FilterHeader;
