import React, { useState } from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import tick from "../../../../assets/img/tick-icon.svg";
import join from "../../../../assets/img/join.png";
import defaultGroupIcon from "../../../../assets/img/default_group_icon.svg";
import {
  followPage,
  getAllPages,
  unFollowPage,
} from "../../../../store/pages/pagesAction";
import { useNavigate, useParams } from "react-router-dom";
import EditUrl from "./EditUrl";
import {
  getSuggestedGroups,
  joinGroups,
} from "../../../../store/groups/groupsActions";
import { toast } from "react-toastify";

const GroupViewSidebar = () => {
  const { suggested_groups } = useSelector((state) => state.groups);

  //console.log(params);
  //console.log(pageNavURL);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  useEffect(() => {
    dispatch(getSuggestedGroups(null, { size: 5 }));
  }, []);

  console.log("suggested_groups", suggested_groups);

  const joinHandle = (id, type) => {
    dispatch(joinGroups(id, type)).then((res) => {
      toast.success(res.message, {
        position: toast.POSITION.BOTTOM_LEFT,
      });
    });
  };
  const navigateToGroupDetail = (id) => {
    navigate(`/groupdetails/${id}`);
    window.location.reload();
  };

  const imageStyle = {
    objectFit: "cover",
    height: "55px",
    borderRadius: "55%",
    width: "55px",
  };

  return (
    <>
      <div className="side mt-4">
        <div className="bg-white p-20">
          <h3>Groups people also viewed</h3>
          {suggested_groups
            ? suggested_groups?.slice(0, 3)?.map((data, index) => {
                return (
                  <div className="box " key={index}>
                    <div className="d-flex gap-3 mt-20 mb-20">
                      <div className="img">
                        <img
                          src={data?.icon ? data?.icon : defaultGroupIcon}
                          //className="image-fit"
                          style={imageStyle}
                          alt=""
                        />
                      </div>
                      <div className="content " style={{ width: "80%" }}>
                        <h4
                          onClick={() => navigateToGroupDetail(data?.id)}
                          className="card-control-text-twoline"
                        >
                          {data?.name}
                        </h4>
                        <p className="card-control-text-oneline">
                          {data?.industry ? data?.industry : ""}
                        </p>
                        <div className="text mb-2">
                          <div className="d-flex align-items-center justify-content-start">
                            {data?.type === "public" ? (
                              <>
                                <div
                                  className="globe"
                                  style={{ height: "12px" }}
                                ></div>
                                <p>Public group</p>
                              </>
                            ) : (
                              <>
                                <div
                                  className="privateIcon"
                                  style={{ height: "12px" }}
                                ></div>
                                <p>Private group</p>
                              </>
                            )}
                          </div>
                        </div>
                        {/* <div
                          className="btn btn-light btn-margin-bottom"
                          onClick={() => joinHandle(data.id, "joinTrue")}
                        >
                          <div>
                            <img
                              style={{
                                marginRight: "9px",
                              }}
                              src={join}
                              alt="join"
                            />
                            Join
                          </div>
                        </div> */}
                        {data?.members_count === data?.members_limit ? (
                          <div className="btn btn-light disabled-btn-blue btn-margin-bottom">
                            Join
                          </div>
                        ) : data?.is_joined === "pending" ||
                          data?.is_joined === "recieved" ? (
                          <div
                            className="btn btn-light btn-margin-bottom"
                            onClick={() => {
                              toast.warn(
                                "Please wait for group admin response",
                                {
                                  position: toast.POSITION.BOTTOM_LEFT,
                                }
                              );
                            }}
                          >
                            Requested{" "}
                          </div>
                        ) : (
                          <div
                            className="btn btn-light btn-margin-bottom"
                            onClick={() => joinHandle(data.id, "joinTrue")}
                          >
                            <div className="d-flex justify-content-center align-items-center">
                              <img
                                style={{
                                  marginRight: "9px",
                                }}
                                src={join}
                                alt="join"
                              />
                              Join
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="line"></div>
                  </div>
                );
              })
            : ""}
        </div>
      </div>

      {/* <div className="insight-side">
          <div className="bg-white b-5 ">
            <div className="row p-20">
              <div className="col-8">
                <h2 className="">Page Url</h2>
              </div>
             
            </div>

            <div className="line"></div>
          
           
            <a
              href={`/pageadminview/${textURL}`}
              className="text-blue-500"
              style={{
                wordWrap: "break-word",
                display: "block",
                padding: "13px",
              }}
            >
            </a>
          
           
          </div>
        </div> */}
    </>
  );
};

export default GroupViewSidebar;
