import React, { useEffect, useState } from "react";
import { Input, Modal, ModalBody, ModalHeader } from "reactstrap";
import pub from "../../../../assets/img/public.svg";
import people from "../../../../assets/img/people.svg";
import union from "../../../../assets/img/Union.svg";
import profileadd from "../../../../assets/img/fluent_people.svg";
import SelectReceipent from "../CelebrationPostModals/selectReceipent";
import { useDispatch, useSelector } from "react-redux";

const VisibilityModal = (props) => {
  const {
    modal,
    toggle,
    title,
    privacyId,
    setPrivacyId,
    setSelectGroupModal,
    setVisibiltyModal,
    // joined_groups,
    setVisiblePersons,
  } = props;
  const [publicMode, setPublicMode] = useState();
  const [connectionsMode, setConnectionsMode] = useState();
  const [groupsMode, setGroupsMode] = useState();
  const [personsMode, setPersonsMode] = useState();
  const [selectPersons, setSelectPersons] = useState(false);
  const { my_groups } = useSelector((state) => state.groups);
  console.log(my_groups, "joined_groups");
  useEffect(() => {
    if (privacyId === 1) {
      setPublicMode(true);
    }
    if (privacyId === 2) {
      setConnectionsMode(true);
    }
    if (privacyId === 3) {
      setGroupsMode(true);
    }
    if (privacyId === 4) {
      setPersonsMode(true);
    }
  }, [privacyId]);
  const updatePrivacy = (event, mode) => {
    try {
      if (event.target.checked && mode === "publicMode") {
        setPrivacyId(1);
        toggle();
      }
      if (event.target.checked && mode === "connections") {
        setPrivacyId(2);
        toggle();
      }
      if (event.target.checked && mode === "group") {
        setPrivacyId(3);
        setSelectGroupModal(true);
      }
      if (event.target.checked && mode === "person") {
        setPrivacyId(4);
        setSelectPersons(true);
      }
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <div>
      <Modal
        isOpen={modal}
        className="create-post-header"
        style={{ maxWidth: "574px", top: "55px" }}
      >
        <ModalHeader toggle={toggle}>{title}</ModalHeader>
        <ModalBody>
          <div className="row create-post">
            <p>Select an option:</p>
            <div className="details d-flex justify-content-between align-items-center">
              <div className="d-flex gap-3 mt-20 mb-20">
                <div className="">
                  <img src={pub} alt="/" />
                </div>
                <div className="names">
                  <h6>Public</h6>
                  <p>Public to NobelPage </p>
                </div>
              </div>
              <div className="radio">
                <input
                  type="radio"
                  name="visibility"
                  onChange={(e) => updatePrivacy(e, "publicMode")}
                  checked={publicMode}
                />
              </div>
            </div>
            <div className="details d-flex justify-content-between  align-items-center">
              <div className="d-flex gap-3 mb-20">
                <div className="">
                  <img src={union} alt="/" />
                </div>
                <div className="names">
                  <h6>Connections Only</h6>
                  <p>Connections on NobelPage </p>
                </div>
              </div>
              <div className="radio">
                <input
                  type="radio"
                  name="visibility"
                  onChange={(e) => updatePrivacy(e, "connections")}
                  checked={connectionsMode}
                />
              </div>
            </div>
            {/* {joined_groups && joined_groups.length < 0  && <div className="details d-flex justify-content-between  align-items-center">
              <div className="d-flex gap-3 mb-20 group-select">
                <div className="">
                  <img src={people} alt="/" />
                </div>
                <div className="names" onClick={()=>setSelectGroupModal(true)}>
                  <h6>Group Members</h6>
                  <p>Select a group you are in</p>
                </div>
              </div>
              <div className="radio">
                <input
                  type="radio"
                  name="visibility"
                  onChange={(e) => updatePrivacy(e, "group")}
                  checked={groupsMode}
                />
              </div>
            </div>} */}
            {my_groups && my_groups.length > 0 && (
              <div className="details d-flex justify-content-between align-items-center">
                <div className="d-flex gap-3 mb-20 group-select">
                  <div className="">
                    <img src={people} alt="/" />
                  </div>
                  <div
                    className="names"
                    onClick={() => setSelectGroupModal(true)}
                  >
                    <h6>Group Members</h6>
                    <p>Select a group you are in</p>
                  </div>
                </div>
                <div className="radio">
                  <input
                    type="radio"
                    name="visibility"
                    onChange={(e) => updatePrivacy(e, "group")}
                    checked={groupsMode}
                  />
                </div>
              </div>
            )}
            <div className="details d-flex justify-content-between  align-items-center">
              <div className="d-flex gap-3 mb-20 group-select">
                <div className="">
                  <img src={profileadd} alt="/" />
                </div>
                <div className="names" onClick={() => setSelectPersons(true)}>
                  <h6>Private Connections</h6>
                  <p>Select persons from your connection</p>
                </div>
              </div>
              <div className="radio">
                <input
                  type="radio"
                  name="visibility"
                  onChange={(e) => updatePrivacy(e, "person")}
                  checked={personsMode}
                />
              </div>
            </div>
          </div>
        </ModalBody>
      </Modal>
      {selectPersons && (
        <SelectReceipent
          modal={selectPersons}
          toggle={() => setSelectPersons(!selectPersons)}
          nextModal={(data) => {
            // setCelebrationData({
            //   id: isData.id,
            //   celebration_tags: data,
            //   title: isData.title,
            //   media: isData.selectedImg,
            // });
            setVisiblePersons(data?.map((a) => a.id));
            setSelectPersons(!selectPersons);
            setVisibiltyModal(false);
          }}
          // backModal={() => {
          //   setSelectPersons(!selectPersons);
          //   setVisibiltyModal(false);
          // }}
          title="Private Connections"
          isVisible={true}
        />
      )}
    </div>
  );
};
export default VisibilityModal;
