import React, { useEffect, useState } from "react";
import profileicon from "../../../assets/img/profile-icon-pool.png";
import edit from "../../../assets/img/edit-orange.svg";
import logo from "../../../assets/img/logo-blue.png";
import { Dropdown, FormGroup, Input, Label } from "reactstrap";
import JoinUsSection from "../../../core/components/Sections/JoinUsSection/JoinUsSection";
import { useDispatch, useSelector } from "react-redux";
import placeholderUser from "../../../assets/img/placeholder_user.jpg";
import { Link, useNavigate } from "react-router-dom";
import placeholderUsers from "../../../assets/img/default_profile_sqr img.svg";
import { updateUserDetails } from "../../../store/profile/profileActions";
import {
  initPageLoad,
  stopPageLoad,
} from "../../../store/loader/loaderActions";
import AppLoader from "../../../core/components/Loaders/appLoader/appLoader";
import ProfilePhotoModal from "../../profile-pages/profile/components/ProfilePhotoModal";
import axios from "axios";
import { toast } from "react-toastify";
const EditIntroFirst = (props) => {
  const { activePage, setActivePage, activeTab, setActiveTab, profileData } =
    props;
  const { talentPoolData } = useSelector((state) => state.portal);
  const token = localStorage.getItem("accessTpToken");
  console.log(profileData?.highestEducation ? true : false);
  const dispatch = useDispatch();
  const { isPageLoading } = useSelector((state) => state.loader);
  const { settingData } = useSelector((state) => state.setting);
  const navigate = useNavigate();
  const [profilePhotoModal, setProfilePhotoModal] = useState(false);
  const [formData, setFormData] = useState({
    first_name: profileData?.first_name || "",
    last_name: profileData?.last_name || "",
    educationCheck: profileData?.highestEducation ? true : false,
    educationName: profileData?.highestEducation?.id || "",
    experienceName: profileData?.highestExperience?.id || "",
    experienceCheck: profileData?.highestExperience ? true : false,
  });
  useEffect(() => {
    setFormData({
      first_name: profileData?.first_name || "",
      last_name: profileData?.last_name || "",
      educationCheck: profileData?.highestEducation ? true : false,
      educationName: profileData?.highestEducation?.id || "",
      experienceName: profileData?.highestExperience?.id || "",
      experienceCheck: profileData?.highestExperience ? true : false,
    });
  }, [profileData]);

  const [error, setError] = useState({
    first_name: "",
    last_name: "",
  });
  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const checkvalidation = (type) => {
    let stringName = /^[a-zA-Z\s._]+$/;
    let stringNameLast = /^[a-zA-Z\s._]+$/;

    let formIsValid = true;

    if (type === "all" || type === "first_name") {
      if (!formData?.first_name.trim()) {
        setError({ ...error, first_name: "Enter your first name." });
        formIsValid = false;
      } else if (!stringName.test(formData?.first_name.trim())) {
        setError({ ...error, first_name: "First name is invalid." });
        formIsValid = false;
      } else if (formData?.first_name.length > 50) {
        setError({ ...error, first_name: "Max 50 characters allowed." });
        formIsValid = false;
      } else {
        setError({ ...error, first_name: "" });
      }
    }
    if (type === "all" || type === "last_name") {
      if (!formData?.last_name.trim()) {
        setError({ ...error, last_name: "Enter your last name." });
        formIsValid = false;
      } else if (!stringNameLast.test(formData?.last_name.trim())) {
        setError({ ...error, last_name: "Last name is invalid." });
        formIsValid = false;
      } else if (formData?.last_name.length > 50) {
        setError({ ...error, last_name: "Max 50 characters allowed." });
        formIsValid = false;
      } else {
        setError({ ...error, last_name: "" });
      }
    }

    return formIsValid;
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (checkvalidation("all")) {
      dispatch(initPageLoad());
      dispatch(
        updateUserDetails(
          {
            first_name: formData?.first_name,
            last_name: formData?.last_name,
            education_id:
              formData?.educationCheck === true
                ? formData?.educationName
                : null,
            experience_id:
              formData?.experienceCheck === true
                ? formData?.experienceName
                : null,
          },
          token
        )
      )
        .then((res) => {
          console.log("res",res.success)
          if (res.success){
            toast.success("Profile Updated Successfully",  {
              position: toast.POSITION.BOTTOM_LEFT,
             });

          }
          dispatch(stopPageLoad());
        })
        .catch((err) => dispatch(stopPageLoad()));
    }
  };

  const handleBannerPicture = async (file, type) => {
    const newImage = new File([file], "newEventCropped", { type: "image/png" });

    try {
      const UPLOAD_URL = `${process.env.REACT_APP_API_URL}api/v1/upload`;
      const formData = new FormData();
      const headers = {
        "Accept-Language": "en",
        "content-type": "multipart/form-data",
      };
      formData.append("image", newImage);

      await axios.post(UPLOAD_URL, formData, { headers }).then((res) => {
        if (type === "profile") {
          let formatProfileData = {
            profile_img: res?.data?.urlsArray[0],
          };
          dispatch(updateUserDetails(formatProfileData, token));
        } else if (type === "background") {
          let formatProfileData = {
            background_img: res?.data?.urlsArray[0],
          };
          dispatch(updateUserDetails(formatProfileData, token));
        }
      });
    } catch (err) {
      console.log(err);
    }
    // setProfilePhotoModal(false);
  };

  const handleRemovePicture = (type) => {
    if (type === "profile") {
      let formatProfileData = {
        profile_img: "",
      };
      dispatch(updateUserDetails(formatProfileData, token));
    } else if (type === "background") {
      let formatProfileData = {
        background_img: "",
      };
      dispatch(updateUserDetails(formatProfileData, token));
    }
  };

  const svgStyle = {
    background: `url("data:image/svg+xml,${encodeURIComponent(`<svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                <circle cx="15.1484" cy="14.8984" r="14.75" fill="white"/>
                <g clip-path="url(#clip0_906_18150)">
                <path d="M7.474 16.9436L6.50091 21.1432C6.46735 21.2968 6.4685 21.4558 6.50428 21.6089C6.54007 21.7619 6.60958 21.905 6.70775 22.0277C6.80592 22.1504 6.93026 22.2496 7.0717 22.3181C7.21313 22.3866 7.36808 22.4227 7.52522 22.4236C7.59844 22.431 7.67222 22.431 7.74545 22.4236L11.9707 21.4505L20.0832 13.3688L15.5558 8.85156L7.474 16.9436Z" fill="${talentPoolData?.theme}"/>
                <path d="M22.6346 9.32251L19.6128 6.3008C19.4142 6.10315 19.1453 5.99219 18.8651 5.99219C18.5849 5.99219 18.316 6.10315 18.1174 6.3008L16.4375 7.98067L20.9598 12.503L22.6397 10.8231C22.738 10.7243 22.8158 10.6071 22.8688 10.4782C22.9218 10.3492 22.9488 10.2111 22.9483 10.0717C22.9478 9.93237 22.9199 9.79445 22.866 9.66589C22.8122 9.53732 22.7335 9.42064 22.6346 9.32251Z" fill="${talentPoolData?.theme}"/>
                </g>
                <defs>
                <clipPath id="clip0_906_18150">
                <rect width="18.4375" height="18.4375" fill="white" transform="translate(5.3125 5.0625)"/>
                </clipPath>
                </defs>
                </svg>`)}") 98% 0 no-repeat`,
  };
  return (
    <>
      {isPageLoading && <AppLoader />}

      <div className="edit-intro-first">
        <div className="d-flex">
          <div className="profile-image">
            <div className="pro">
              <img
                src={
                  profileData?.profile_img
                    ? profileData.profile_img
                    : placeholderUsers
                }
                className="img-fluid"
                alt=""
              />
            </div>
            {/* {isAllowedToEdit() && ( */}
            <div className="edit">
              <svg
                width="30"
                height="30"
                viewBox="0 0 36 36"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                onClick={() => setProfilePhotoModal(!profilePhotoModal)}
              >
                <circle cx="18" cy="18" r="18" fill={talentPoolData?.theme} />
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M19.5137 9.8065C20.5869 8.73174 22.3274 8.73108 23.4013 9.80503L25.8932 12.2969C26.958 13.3617 26.969 15.0854 25.918 16.1638L16.6849 25.637C15.9793 26.3609 15.0117 26.7691 14.0012 26.7691L11.2491 26.7689C9.96984 26.7689 8.94823 25.7025 9.00203 24.4234L9.12019 21.6144C9.15968 20.6754 9.54996 19.7854 10.2138 19.1205L19.5137 9.8065ZM22.3415 10.8665C21.8533 10.3783 21.0622 10.3786 20.5744 10.8672L18.9113 12.5328L23.1911 16.8125L24.8446 15.116C25.3224 14.6259 25.3173 13.8423 24.8333 13.3583L22.3415 10.8665ZM11.2745 20.1812L17.8514 13.5942L22.144 17.8868L15.6115 24.5893C15.1882 25.0236 14.6076 25.2685 14.0013 25.2685L11.2492 25.2684C10.8227 25.2684 10.4822 24.9129 10.5001 24.4865L10.6183 21.6775C10.642 21.1141 10.8762 20.5801 11.2745 20.1812ZM26.5148 26.6958C26.9289 26.6958 27.2645 26.3598 27.2645 25.9455C27.2645 25.5311 26.9289 25.1952 26.5148 25.1952H20.3931C19.9791 25.1952 19.6434 25.5311 19.6434 25.9455C19.6434 26.3598 19.9791 26.6958 20.3931 26.6958H26.5148Z"
                  fill="white"
                />
              </svg>
            </div>
          </div>
          <div className="d-flex mail">
            <img src={logo} alt="logo" className="intro-img" />
            {profileData?.np ? (
              <p
                style={{ cursor: "pointer",wordBreak:"break-all" }}
                onClick={() =>
                  navigate(
                    settingData?.data[0]?.value
                      ? `/profile/${settingData?.data[0]?.value}`
                      : `/profile/${profileData?.id}`
                  )
                }
              >
                {settingData?.data[0]?.value
                  ? `${process.env.REACT_APP_WEB_STAGE_URL}profile/${settingData?.data[0]?.value}`
                  : `${process.env.REACT_APP_WEB_STAGE_URL}profile/${profileData?.id}`}
              </p>
            ) : (
              <p style={{ cursor: "pointer" }} onClick={() => navigate("/")}>
                Create NobelPage Account
              </p>
            )}
          </div>
        </div>
        <div className="edit-form">
          <form>
            <div className="title mb-3">
              <label>First Name</label>
              <Input
                type="text"
                placeholder="Enter First Name"
                value={formData.first_name}
                name="first_name"
                onChange={(e) => handleChange(e)}
                onBlur={() => checkvalidation("first_name")}
              />
              {error?.first_name && (
                <p className="label-input100 text-danger">
                  {error?.first_name}
                </p>
              )}
            </div>
            <div className="title mb-3">
              <label>Last Name</label>
              <Input
                type="text"
                placeholder="Enter Last Name"
                value={formData.last_name}
                name="last_name"
                onChange={(e) => handleChange(e)}
                onBlur={() => checkvalidation("last_name")}
              />
              {error?.last_name && (
                <p className="label-input100 text-danger">{error?.last_name}</p>
              )}
            </div>
            <div className="title mb-3">
              <label>Current Position</label>
              <Input
                placeholder="Enter Position"
                id="experiencedropdown"
                name="select"
                type="select"
                className="edit-box"
                value={formData.experienceName}
                // onChange={(e) =>
                //   setFormData({
                //     ...formData,
                //     experience_id: e.target.value,
                //     experienceName: e.target.value,
                //   })
                // }
                style={svgStyle}
                onChange={(e) =>
                  setFormData({
                    ...formData,
                    // educationName: (JSON.parse(e.target.value))
                    experienceName: e.target.value,
                  })
                }
              >
                <option value="">Please Select</option>

                {profileData?.experiences?.map((item, index) => {
                  return (
                    <option key={index} value={item.id}>
                      {item?.title?.length > 30
                        ? item?.title?.slice(0, 30) + "..."
                        : item?.title}{" "}
                      at{" "}
                      {item?.company_name?.length > 30
                        ? item?.company_name?.slice(0, 30) + "..."
                        : item?.company_name}
                    </option>
                  );
                })}
              </Input>
              <span
                className="addButton"
                onClick={() => setActivePage("experience")}
              >
                + Add new experience
              </span>
              <div className="check d-flex align-items-center mt-2 portal-check">
                <input
                  type="checkbox"
                  id="experienceCheck"
                  name="experienceCheck"
                  checked={formData?.experienceCheck}
                  onClick={(e) =>
                    setFormData({
                      ...formData,
                      experienceCheck: e.target.checked,
                    })
                  }
                  style={{
                    height: "20px",
                    width: "20px",
                  }}
                />
                <label
                  htmlFor="educationCheck"
                  className="mx-3"
                  style={{ marginBottom: 0 }}
                >
                  Show current position in my intro.
                </label>
              </div>
            </div>
            <div className="title mb-3">
              <label>Education</label>
              <Input
                placeholder="Enter Position"
                id="educationdropdown"
                name="select"
                type="select"
                className="edit-box"
                style={svgStyle}
                value={formData.educationName}
                // onChange={(e) =>
                //   setFormData({
                //     ...formData,
                //     education_id: e.target.value,
                //     educationName: e.target.value,
                //   })
                // }
                onChange={(e) =>
                  setFormData({
                    ...formData,
                    // educationName: (JSON.parse(e.target.value))
                    educationName: e.target.value,
                  })
                }
              >
                <option value="">Please Select</option>
                {profileData?.educations?.map((item, index) => {
                  return (
                    <option
                      key={index}
                      // value={JSON.stringify(item)}
                      value={item.id}
                    >
                      {item?.school_name?.length > 40
                        ? item?.school_name?.slice(0, 40) + "..."
                        : item?.school_name}
                    </option>
                  );
                })}
              </Input>
              <span
                className="addButton"
                onClick={() => setActivePage("education")}
              >
                + Add new education
              </span>
              <div className="check d-flex align-items-center mt-2 portal-check">
                <input
                  type="checkbox"
                  id="experienceCheck"
                  name="experienceCheck"
                  checked={formData?.educationCheck}
                  onClick={(e) =>
                    setFormData({
                      ...formData,
                      educationCheck: e.target.checked,
                    })
                  }
                  style={{
                    height: "20px",
                    width: "20px",
                  }}
                />
                <label
                  htmlFor="educationCheck"
                  className="mx-3"
                  style={{ marginBottom: 0 }}
                >
                  Show current education in my intro.
                </label>
              </div>
            </div>

            <div className="contact-info mb-3 mt-4">
              <h6>Contact information</h6>
              <p>Add or edit your profile URL, email, and more</p>
              <span
                style={{ color: `${talentPoolData?.theme}` }}
                className="addButton orange-clr"
                onClick={() => setActivePage("contact")}
              >
                Edit contact info
              </span>
            </div>
          </form>
        </div>

        {!profileData?.np && <JoinUsSection />}
        <button
          className="save-btn"
          onClick={(e) => handleSubmit(e)}
          style={{ background: `${talentPoolData?.theme}` }}
        >
          Save
        </button>
      </div>

      {profilePhotoModal && (
        <ProfilePhotoModal
          modal={profilePhotoModal}
          toggle={() => setProfilePhotoModal(!profilePhotoModal)}
          title="Profile Photo"
          editProfileImage={profileData?.profile_img}
          handleBannerPicture={handleBannerPicture}
          handleRemovePicture={handleRemovePicture}
          // // setBannerModal={setBannerModal}
        />
      )}
    </>
  );
};

export default EditIntroFirst;
