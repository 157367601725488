import React from "react";
import "./Upgrade.scss";

export default function UpgradeSidebar() {
  return (
    <div className="upgrade-sidebar text-center p-4 ">
      <h3 className="upgrade_header">
        Upgrade features with our add ons packages
      </h3>
      <p className="mt-10">
      Enjoy even more add-on packages, offering additional benefits at discounted pricing. 
      </p>
      <div className="btn btn-outline blue-color mt-20">Explore Now!</div>
    </div>
  );
}
