import {
  Modal,
  ModalBody,
  Input,
  Label,
  FormGroup,
  ModalHeader,
} from "reactstrap";
import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import FormInput from "../../../../core/components/Inputs/FormInput";
import MobileInput from "../../../../core/components/Inputs/MobileInput";
import edit from "../../../../assets/img/edit.svg";
import emailimg from "../../../../assets/img/a.svg";
import {
  CreateNewPage,
  updatePageData,
} from "../../../../store/pages/pagesAction";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import Autocomplete from "react-autocomplete";
import { getLocationsData } from "../../../../store/connections/connectionsActions";
import PageDropZone from "../PageDropZone";
import "react-autocomplete-input/dist/bundle.css";
import { PhoneNumberUtil } from "google-libphonenumber";
import { getIndustryData } from "../../../../store/master/masterActions";
import TagsInput from "../../../../core/components/HashTags/tagsInput";
import EventBannerModal from "../../Events/components/eventBannerModal";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const CreatePageModal = (props) => {
  const {
    modal,
    toggle,
    title,
    editPageData,
    setEditPageData,
    singlePageDetails,
  } = props;
  const [errorMessage, setErrorMessage] = useState("");
  const [phoneErrorMessage, setPhoneErrorMessage] = useState("");
  const [searchFieldValue, setSearchFieldValue] = useState(
    editPageData ? editPageData.location : ""
  );
  const [locationsAPIData, setLocationsAPIData] = useState([]);

  const [websiteError, setWebsiteError] = useState("");
  const [urlError, setUrlError] = useState("");
  const [phoneCode, setPhoneCode] = useState("");
  const [bannerModal, setBannerModal] = useState("");
  const phoneUtil = PhoneNumberUtil.getInstance();
  const [nameErrorMessage, setNameErrorMessage] = useState("");
  const [categoryErrorMessage, setCategoryErrorMessage] = useState("");
  const [uploadImage, setUploadedImage] = useState("");
  const [sizeErrorMessage, setSizeErrorMessage] = useState("");
  const [industryInput, setIndustryInput] = useState(
    editPageData ? editPageData.industry : ""
  );
  const [croppedImage, setCroppedImage] = useState(
    editPageData ? editPageData.icon : ""
  );

  const { industryData } = useSelector((state) => state.master);
  const dispatch = useDispatch();
  const [tags, setTags] = useState(
    editPageData ? editPageData?.hashtags?.map((tags) => tags) : []
  );
  const [disable, setDisable] = useState(true);
  const [locationOpen, setLocationOpen] = useState(false);
  const [submitStatus, setSubmitStatus] = useState(true);

  const [pageDetails, setPageDetails] = useState(
    editPageData
      ? {
          name: editPageData.name,
          icon: editPageData.icon,
          background_img: editPageData.background_img,
          website: editPageData.website,
          location: editPageData.location,
          email: editPageData.email,
          phone: editPageData.phone,
          description: editPageData.description,
          company_size: editPageData.company_size,
          custom_button: editPageData.custom_button,
          custom_button_text: editPageData.custom_button_text
            ? editPageData.custom_button_text
            : "",
          custom_button_url: editPageData.custom_button_url
            ? editPageData.custom_button_url
            : "",
          industry: editPageData.industry,
          category: editPageData.category,
          hashtags: editPageData.hashtags,
        }
      : {
          name: "",
          icon: "",
          background_img: "",
          website: "",
          location: searchFieldValue,
          email: "",
          phone: "",
          description: "",
          company_size: "",
          custom_button: false,
          custom_button_text: "",
          custom_button_url: "",
          industry: "",
          category: "",
          hashtags: [],
        }
  );

  useEffect(() => {
    dispatch(getIndustryData());
  }, []);

  useEffect(() => {
    if (
      !errorMessage &&
      !phoneErrorMessage &&
      !nameErrorMessage &&
      !categoryErrorMessage &&
      !sizeErrorMessage
    ) {
      setDisable(false);
    }
  }, [pageDetails]);

  const pageName = (e) => {
    setNameErrorMessage("");
    pageDetails.name = e.target.value;
    setPageDetails({ ...pageDetails });
  };

  const onChangePicture = (e) => {
    if (e.target.files[0]) {
      setUploadedImage(URL.createObjectURL(e.target.files[0]));
      setBannerModal(true);
    }
  };

  const pageWebsite = (e) => {
    pageDetails.website = e.target.value;
    setPageDetails({ ...pageDetails });
  };
  const handleBannerPicture = async (file, type) => {
    const newImage = new File([file], "newGroupCropped", { type: "image/png" });
    try {
      const UPLOAD_URL = `${process.env.REACT_APP_API_URL}api/v1/upload`;
      const formData = new FormData();
      const headers = {
        "Accept-Language": "en",
        "content-type": "multipart/form-data",
      };
      formData.append("image", newImage);
      await axios.post(UPLOAD_URL, formData, { headers }).then((res) => {
        if (type === "backgroundImg") {
          // setCroppedImage(res.data.urlsArray[0])
          pageDetails.background_img = res.data.urlsArray[0];
          setPageDetails({ ...pageDetails });
        } else {
          setCroppedImage(res.data.urlsArray[0]);
        }
      });
    } catch (err) {
      console.log(err);
    }
  };

  const companySize = (e) => {
    setSizeErrorMessage("");
    pageDetails.company_size = e.target.value;
    setPageDetails({ ...pageDetails });
  };
  const category = (e) => {
    setCategoryErrorMessage("");
    pageDetails.category = e.target.value;
    setPageDetails({ ...pageDetails });
  };
  const customText = (e) => {
    pageDetails.custom_button_text = e.target.value;
    setPageDetails({ ...pageDetails });
  };
  const customBtnUrl = (e) => {
    pageDetails.custom_button_url = e.target.value;
    setPageDetails({ ...pageDetails });
  };
  const pageEmail = (e) => {
    setErrorMessage("");
    pageDetails.email = e.target.value;
    setPageDetails({ ...pageDetails });
  };

  const getLocations = ({ value }) => {
    if (value)
      dispatch(getLocationsData(value))
        .then((response) => {
          if (response.data) {
            setLocationsAPIData(response.data);
          } else setLocationsAPIData([]);
        })
        .catch((err) => {
          // console.log("error!!", err);
          setLocationsAPIData([]);
        });
    else setLocationsAPIData([]);
  };
  const phoneCountValidation = () => {
    let num = JSON.stringify(pageDetails.phone);
    let code = JSON.stringify(phoneCode);
    const number = phoneUtil.parse(num, code);
    return phoneUtil.isValidNumber(number);
  };

  const validation = (type) => {
    let emailvalid =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    let formIsValid = true;
    if ((type = "all" || type === "pageName")) {
      if (!pageDetails.name.trim()) {
        setNameErrorMessage("Please enter page name");
        formIsValid = false;
        setDisable(true);
        return;
      } else {
        setNameErrorMessage("");
      }
    }
    if ((type = "all" || type === "category")) {
      if (!pageDetails?.category) {
        setCategoryErrorMessage("Please select category");
        formIsValid = false;
        setDisable(true);
        return;
      } else {
        setCategoryErrorMessage("");
      }
    }

    if ((type = "all" || type === "size")) {
      if (!pageDetails?.company_size) {
        setSizeErrorMessage("Please select company size");
        formIsValid = false;
        setDisable(true);
        return;
      } else {
        setSizeErrorMessage("");
      }
    }

    if ((type = "all" || type === "website")) {
      if (!pageDetails?.website) {
        setWebsiteError("Website is required");
        formIsValid = false;
        setDisable(true);
        return;
      } else if (!isValidWebsite(pageDetails?.website)) {
        setWebsiteError("Please enter a valid website URL");
        formIsValid = false;
        setDisable(true);
        return;
      } else {
        setWebsiteError("");
      }
    }

    if ((type = "all" || type === "emailName")) {
      if (!pageDetails?.email.trim()) {
        setErrorMessage("Please enter your email address.");
        formIsValid = false;
        setDisable(true);
        return;
      } else if (!emailvalid?.test(pageDetails.email)) {
        setErrorMessage("Please enter a valid email address.");
        formIsValid = false;
        setDisable(true);
        return;
      }
    }
    if ((type = "all" || type === "phone")) {
      if (pageDetails?.phone?.length === 0) {
        setPhoneErrorMessage("Please enter the phone number.");
        formIsValid = false;
        setDisable(true);
        return;
      } else if (pageDetails?.phone?.length < 10) {
        setPhoneErrorMessage("Please enter the valid number.");
        formIsValid = false;
        setDisable(true);
        return;
      } else if (!phoneCountValidation()) {
        setPhoneErrorMessage("Please enter the valid number.");
        formIsValid = false;
        setDisable(true);
        return;
      }
    }

    if ((type = "all" || type === "profileUrl")) {
      if (!pageDetails?.custom_button_url) {
        setUrlError("Url is required");
        formIsValid = false;
        setDisable(true);
        return;
      } else if (!isValidWebsite(pageDetails?.custom_button_url)) {
        setUrlError("Please enter a valid URL");
        formIsValid = false;
        setDisable(true);
        return;
      } else {
        setUrlError("");
      }
    }

    return formIsValid;
  };

  const createPage = (e) => {
    setSubmitStatus(false);
    try {
      pageDetails.icon = croppedImage;
      setPageDetails({ ...pageDetails });
      if (editPageData?.id) {
        dispatch(updatePageData(pageDetails, editPageData?.id)).then((data) => {
          singlePageDetails();
          setEditPageData("");
          toast.success(data.message, {
            position: toast.POSITION.BOTTOM_LEFT,
          });
          toggle();
        });
      } else {
        try {
          dispatch(CreateNewPage(pageDetails))
            .then((res) => {
              toast.success(res.message, {
                position: toast.POSITION.BOTTOM_LEFT,
              });
              toggle();
            })
            .catch((e) => {
              setSubmitStatus(true);
            });
        } catch (error) {
          console.log("error", error);
        }
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  const submitCreatePage = () => {
    pageDetails.hashtags = tags;
    if (validation("all")) {
      createPage();
    }
  };

  const uploadIcon = async (e, type) => {
    if (e.target?.files?.length || e.dataTransfer?.files?.length) {
      try {
        const UPLOAD_URL = `${process.env.REACT_APP_API_URL}api/v1/upload`;
        const formData = new FormData();
        const headers = {
          "Accept-Language": "en",
          "content-type": "multipart/form-data",
        };
        formData.append(
          "image",
          e.target?.files[0] || e.dataTransfer?.files[0]
        );
        await axios.post(UPLOAD_URL, formData, { headers }).then((res) => {
          if (type === "icon") {
            setCroppedImage(res.data.urlsArray[0]);
          } else if (type === "backgroundImg") {
            pageDetails.background_img = res.data.urlsArray[0];
            setPageDetails({ ...pageDetails });
          }
        });
      } catch (err) {
        console.log(err);
      }
    } else {
      alert("PLease choose file");
    }
  };

  const updateCustom = (e) => {
    try {
      pageDetails.custom_button = e.target.checked;
      pageDetails.custom_button_text =
        e.target.checked === false ? "" : "Explore";
      pageDetails.custom_button_url =
        e.target.checked === false ? "" : pageDetails.custom_button_url;
      setPageDetails({ ...pageDetails });
    } catch (error) {
      console.log(error);
    }
  };

  //Website validation logic

  const isValidWebsite = (website) => {
    // const websiteRegex = /^(https?:\/\/)?([\da-z\.-]+)\.([a-z\.]{2,6})([\/\w \.-]*)*\/?$/;
    const websiteRegex =
      /^(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/;

    return websiteRegex.test(website);
  };

  // const validateWebsite = (website) => {
  //   if (!website) {
  //     setWebsiteError("Website is required");
  //   } else if (!isValidWebsite(website)) {
  //     setWebsiteError("Please enter a valid website URL");
  //   } else {
  //     setWebsiteError("");
  //   }
  // };

  //Profile Url Validation

  // const handleProfileUrlValidation=()=>{

  //   setProfileUrlError(""); // Clear previous errors

  //   if (!profileUrl) {
  //     setProfileUrlError("Profile URL is required");
  //   } else if (!isValidWebsite(profileUrl)) {
  //     setProfileUrlError("Please enter a valid website URL");
  //   } else {
  //     // If valid, handle submission (e.g., update data, close modal)
  //     // ...
  //   }
  // }

  return (
    <div>
      <Modal
        isOpen={modal}
        size="lg"
        style={{ maxWidth: "600px" }}
        className="networks-popup"
        centered
      >
        <div>
          <div className="createpage">
            <ModalHeader toggle={toggle}>
              {editPageData ? "Edit Page Info" : "Create Page"}
            </ModalHeader>
            <ModalBody>
              <div className="box">
                {editPageData ? (
                  <></>
                ) : (
                  <div className="main-drag page-drag">
                    <PageDropZone
                      handleBannerPicture={handleBannerPicture}
                      bannerImage={pageDetails.background_img}
                    />
                    <div
                      className="add-icon object-fit-cover"
                      onClick={() => {
                        try {
                          document.getElementById("chosefile").click();
                        } catch (error) {
                          console.log(error);
                        }
                      }}
                    >
                      {croppedImage ? (
                        <>
                          <img
                            src={croppedImage}
                            width="100%"
                            height="100%"
                            className="create-page-icon"
                            alt="icon"
                          />
                          <div className="create-page-edit">
                            <img src={edit} alt="" />
                          </div>
                        </>
                      ) : (
                        <>
                          <div className="img"></div>
                          <p style={{ cursor: "pointer" }}>Add Icon</p>
                        </>
                      )}
                    </div>
                  </div>
                )}
                <form className={editPageData ? "" : "mt-7"}>
                  <Label>Page Name*</Label>
                  <Input
                    onChange={(e) => pageName(e)}
                    onBlur={() => validation("pageName")}
                    value={pageDetails.name}
                  />
                  {nameErrorMessage && (
                    <label className="label-input100 text-danger">
                      {nameErrorMessage}
                    </label>
                  )}
                  <div className="row">
                    <div className="col-12 mt-10">
                      <Label for="exampleText">Description</Label>
                      <Input
                        type="textarea"
                        name="text"
                        id="exampleText"
                        maxLength={600}
                        onChange={(e) =>
                          setPageDetails({
                            ...pageDetails,
                            description: e.target.value,
                          })
                        }
                        value={pageDetails.description}
                      />
                    </div>
                  </div>
                  <div className="row my-2">
                    <div className="col-sm-6 autocomplete-wrapper">
                      <Label>Industry</Label>
                      <Autocomplete
                        value={industryInput}
                        items={industryData}
                        getItemValue={(item) => item.name}
                        shouldItemRender={(item, value) =>
                          item.name.toLowerCase().indexOf(value.toLowerCase()) >
                          -1
                        }
                        renderMenu={(items, value) => (
                          <div className="dropdown custom-scrollbar">
                            {items.length === 0
                              ? `No matches for ${value}`
                              : items}
                          </div>
                        )}
                        renderItem={(item, isHighlighted) => (
                          <div
                            className={`item ${
                              isHighlighted ? "selected-item" : ""
                            }`}
                          >
                            {item.name}
                          </div>
                        )}
                        onChange={(event, val) => {
                          setIndustryInput(val);
                        }}
                        onSelect={(val, obj) => {
                          setPageDetails({ ...pageDetails, industry: val });
                          setIndustryInput(val);
                        }}
                        wrapperStyle={{}}
                      />
                    </div>
                    <div className="col-sm-6">
                      <Label for="exampleSelect1">Category*</Label>
                      <Input
                        type="select"
                        value={pageDetails.category}
                        name="select"
                        id="exampleSelect1"
                        onChange={(e) => category(e)}
                        onBlur={() => validation("category")}
                      >
                        <option>Select</option>
                        <option value={"company"}>Public company</option>
                        <option value={"self-employed"}>Self-employed</option>
                        <option value={"government-agency"}>
                          Government agency
                        </option>
                        <option value={"non-profit"}>Nonprofit</option>
                        <option value={"sole-proprietorship"}>
                          Sole proprietorship
                        </option>
                        <option value={"privately-held"}>Privately held</option>
                        <option value={"partnership"}>Partnership</option>
                        <option value={"institute"}>Institute</option>
                      </Input>
                      {categoryErrorMessage && (
                        <label className="label-input100 text-danger">
                          {categoryErrorMessage}
                        </label>
                      )}
                    </div>
                  </div>
                  <div className="row ">
                    <div className="col-sm-6">
                      <Label for="exampleSelect">Company Size*</Label>
                      <Input
                        type="select"
                        value={pageDetails.company_size}
                        name="select"
                        id="exampleSelect"
                        onChange={(e) => companySize(e)}
                        onBlur={() => validation("size")}
                      >
                        <option>Select</option>
                        <option value={"1-9"}>Below 10 Employees</option>
                        <option value={"10-20"}>10-20 Employees</option>
                        <option value={"20-50"}>20-50 Employees</option>
                        <option value={"50-100"}>50-100 Employees</option>
                        <option value={"100-500 Employees"}>
                          100-500 Employees
                        </option>
                        <option value={"500-1000 Employees"}>
                          500-1000 Employees
                        </option>
                        <option value={"1000+ Employees"}>
                          1000+ Employees
                        </option>
                      </Input>
                      {sizeErrorMessage && (
                        <label className="label-input100 text-danger">
                          {sizeErrorMessage}
                        </label>
                      )}
                    </div>
                    <div className="col-sm-6">
                      <Label>Website</Label>
                      <Input
                        onChange={(e) => pageWebsite(e)}
                        value={pageDetails.website}
                        onBlur={() => validation("website")}
                      />
                      {websiteError && (
                        <p className="text-danger">{websiteError}</p>
                      )}
                    </div>
                  </div>
                  <div className="row my-2">
                    <div className="col-sm-12 autocomplete-wrapper">
                      <Label>Location</Label>
                      <Autocomplete
                        open={locationOpen}
                        value={searchFieldValue}
                        items={locationsAPIData}
                        getItemValue={(item) => item.name}
                        shouldItemRender={() => locationsAPIData}
                        renderMenu={(items, value) => (
                          <div className="dropdown custom-scrollbar">
                            {items.length === 0
                              ? `No matches for ${value}`
                              : items}
                          </div>
                        )}
                        renderItem={(item, isHighlighted) => (
                          <div
                            className={`item ${
                              isHighlighted ? "selected-item" : ""
                            }`}
                          >
                            {item.name}
                          </div>
                        )}
                        onChange={(e, val) => {
                          if (val.length === 0) {
                            if (locationOpen) setLocationOpen(false);
                          } else {
                            if (!locationOpen) setLocationOpen(true);
                          }
                          setSearchFieldValue(e.target.value);
                          getLocations(e.target);
                        }}
                        onSelect={(value, obj) => {
                          setSearchFieldValue(value);
                          setPageDetails({
                            ...pageDetails,
                            location: obj.name,
                          });
                          setLocationOpen(false);
                        }}
                        wrapperStyle={{}}
                      />
                    </div>
                  </div>
                  <div className="row ">
                    <div className="col-sm-6">
                      <FormInput
                        type="email"
                        icon={emailimg}
                        label="Email*"
                        onChange={pageEmail}
                        error={errorMessage}
                        onBlur={() => validation("emailName")}
                        value={pageDetails.email}
                      />
                    </div>
                    <div className="col-sm-6 pt-3 phone_number_input">
                      <MobileInput
                        label="Phone Number*"
                        value={pageDetails.phone}
                        onChange={(value, country) => {
                          setPageDetails({
                            ...pageDetails,
                            phone: `+${country.dialCode}-${value.slice(
                              country.dialCode.length
                            )}`,
                          });
                          setPhoneCode(country.countryCode);
                          setPhoneErrorMessage("");
                        }}
                        error={phoneErrorMessage}
                        onBlur={() => validation("phone")}
                        labelStyle={{ fontSize: "14px", color: "#676a79" }}
                      />
                    </div>
                  </div>
                  <div className="row my-2">
                    <div className="col-sm-12">
                      <Label>Hashtag</Label>
                      <TagsInput tags={tags} setTags={setTags} />
                    </div>
                  </div>
                  <div className="row my-3">
                    <div className="col-sm-6">
                      <Label>Custom Button</Label>
                    </div>
                    <div className="col-sm-6">
                      <div className="float-sm-end">
                        <div className="switches">
                          <label className="switch">
                            <input
                              type="checkbox"
                              onClick={(e) => updateCustom(e)}
                              checked={pageDetails.custom_button}
                            />
                            <span className="slider round"></span>
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row my-2">
                    <div className=" col-12">
                      {pageDetails.custom_button === false ? (
                        <FormGroup>
                          <Input
                            type="select"
                            name="select"
                            id="exampleSelect"
                            value=""
                            disabled
                          >
                            <option>Explore</option>
                            <option>Read More</option>
                          </Input>
                        </FormGroup>
                      ) : (
                        <FormGroup>
                          <Input
                            type="select"
                            name="select"
                            id="exampleSelect"
                            // value={pageDetails.custom_button_text}
                            value={
                              pageDetails.custom_button === false
                                ? ""
                                : pageDetails.custom_button_text
                            }
                            onChange={(e) => customText(e)}
                          >
                            <option value="Explore">Explore</option>
                            <option value="Read More">Read More</option>
                          </Input>
                        </FormGroup>
                      )}
                    </div>
                  </div>
                  {pageDetails.custom_button === true && (
                    <div className="row">
                      <div className="col-sm-12">
                        <Input
                          placeholder="Paste URL"
                          onChange={(e) => customBtnUrl(e)}
                          value={pageDetails.custom_button_url}
                          onBlur={() => validation("profileUrl")}
                        />
                      </div>
                      {urlError && <p className="text-danger">{urlError}</p>}
                    </div>
                  )}
                </form>
              </div>
              <input
                type="file"
                accept="image/png, image/gif, image/jpeg"
                id="chosefile"
                onChange={onChangePicture}
                onClick={(e) => (e.target.value = "")}
              />
              <input
                type="file"
                accept="image/png, image/gif, image/jpeg"
                id="chosefile1"
                onChangeCapture={(e) => {
                  uploadIcon(e, "backgroundImg");
                }}
              />
              <ToastContainer />
            </ModalBody>
            {editPageData ? (
              <div className="text-center mt-20 mb-20 ml-30 mr-30">
                <div
                  className="btn btn-blue"
                  onClick={
                    submitStatus === true
                      ? () => submitCreatePage()
                      : () => null
                  }
                >
                  {submitStatus === true ? "Update" : "Updating..."}
                </div>
              </div>
            ) : (
              <div className="text-center mt-20 mb-20 ml-30 mr-30">
                <div
                  className={
                    "btn d-block " +
                    (disable ? "disabled-btn-blue" : "btn-blue")
                  }
                  onClick={
                    submitStatus === true
                      ? () => submitCreatePage()
                      : () => null
                  }
                >
                  {submitStatus === true ? "Create Page" : "Creating..."}
                </div>
              </div>
            )}
          </div>
        </div>
      </Modal>
      {bannerModal && (
        <EventBannerModal
          modal={bannerModal}
          toggle={() => setBannerModal(!bannerModal)}
          title="Edit Banner"
          uploadImage={uploadImage}
          croppedImage={croppedImage}
          setCroppedImage={setCroppedImage}
          handleBannerPicture={handleBannerPicture}
          setBannerModal={setBannerModal}
        />
      )}
    </div>
  );
};

export default CreatePageModal;
