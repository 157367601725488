import React from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router";
import placeholderUser from "../../../../assets/img/placeholder_user.jpg";
import { subscribeNewsletter } from "../../../../store/newsletter/newsletterActions";
import moment from "moment";

const PageNewsletter = (props) => {
 const {page_newsletters,createMarkup} = props;

//  console.log("page_newsletters",page_newsletters);
 const token = localStorage.getItem("accessToken");
 const navigate = useNavigate();
  const dispatch = useDispatch();
 const subscribeHandle = (id) => { 
  dispatch(subscribeNewsletter(id, token));
}

const readMore = (id) => {
  navigate(`/newsletterdetail/${id}`)
}


  return (
    <div>
      <div id="suggested_newsletter" className="container tab-pane active newsletter">
        <div className="row main-center">
           { page_newsletters.map((data) => {
              return (
                <div className=" col-md-12 mt-2 wrapper" key={data.id}>
                  <div className="card overflow-hidden card-height-article">
                    <div className="img card-image-square">
                      <img
                        src={data?.media}
                        className="card-image-square"
                        alt="newsletter-card"
                      />
                    </div>
                    <div className="card-header">
                      <div className="d-flex align-items-center gap-3">
                        <div className="profile-img">
                          <img
                            src={
                              data?.user?.profile_img
                                ? data?.user?.profile_img
                                : placeholderUser
                            }
                            alt=""
                            className="image-fit"
                            style={{ width: "36px" }}
                          />
                        </div>
                        <span className="name">
                          {data?.user?.first_name} {data?.user?.last_name}
                        </span>
                      </div>
                      <div className="date">
                        <div className="d-flex">
                          <span className="calender-icon"></span>
                          <div className="days ml-5">
                            {" "}
                            <span>
                              {moment(data?.createdAt).format(
                                "ddd, MMM DD, YYYY"
                              )}{" "}
                              at {moment(data?.createdAt).format("hh:mm A z")}
                            </span>
                          </div>
                        </div>
                        {data?.subscribed?.status ? (
                          <div className="subscribe">Subscribed</div>
                        ) : (
                          <div
                            className="subscribe"
                            onClick={() => {
                              subscribeHandle(data.id);
                            }}
                          >
                            Subscribe
                          </div>
                        )}
                      </div>
                      <h3 className="card-control-text-twoline">
                        {data.title}
                      </h3>
                    </div>
                    <div className="card-body">
                      <p>
                        {data?.content && (
                          <div
                            className="card-control-text-twoline"
                            dangerouslySetInnerHTML={createMarkup(
                              data?.content
                            )}
                          ></div>
                        )}
                      </p>
                      <div
                        className="read"
                        onClick={() => {
                          readMore(data.id);
                        }}
                      >
                        Read more
                      </div>
                    </div>
                  </div>
                </div>
              );
            })
          }
          
        </div>
      </div>
    </div>
  );
};

export default PageNewsletter;
