import React from "react";
import union from "../../../../assets/img/Union-blue.svg";

const PagePeople = (props) => {
  const { suggested_people, sendRequest } = props;
  return (
    <div className="networks px-4 pb-3">
      <div className="wrapper container">
        <div className="suggestion mt-30">
          <div className="row">
            <div className="slider-box flex-wrap gap-3">
              {suggested_people?.map((item, index) => (
                <div className="card people-card-height mt-3 " key={index}>
                  <div className="rounded-img">
                    <div className="profile-img">
                      <img
                        src={
                          item?.profile_img
                            ? item?.profile_img
                            : "https://img.freepik.com/free-photo/pleasant-looking-serious-man-stands-profile-has-confident-expression-wears-casual-white-t-shirt_273609-16959.jpg"
                        }
                        className="image-fit"
                        alt=""
                      />
                    </div>
                  </div>
                  <h3 className="card-control-text-oneline mt-1">
                    {item?.first_name} {item?.last_name}
                  </h3>
                  {item?.badge == "hiring" && (
                    <div className="hiring">Hiring</div>
                  )}
                  {item?.badge == "seeking" && (
                    <div className="seeking">Seeking</div>
                  )}

                  <div className="designation">
                    {item?.profile?.designation ? (
                      item?.profile?.designation
                    ) : (
                      <></>
                    )}
                  </div>
                  <div className="connections">
                    {item?.shared_connection?.length} mutual connections
                  </div>
                  {item?.connection_status?.request_status === "Pending" && (
                    <div className="btn btn-light text-blue d-flex gap-1 btn-margin-bottom">
                      Pending
                    </div>
                  )}
                  {item?.connection_status?.request_status === "Reject" && (
                    <div
                      className="btn btn-light text-blue d-flex gap-1 btn-margin-bottom"
                      onClick={() => sendRequest(item?.id)}
                    >
                      <img src={union} alt="" /> Connect
                    </div>
                  )}
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PagePeople;
