import React, { useEffect, useState } from "react";
import ReactPlayer from "react-player";
import { useSelector } from "react-redux";
import tag from "../../../assets/img/tag.svg";
import AppLoader from "../../../core/components/Loaders/appLoader/appLoader";
import "../dashboard.scss";
import ImageCarousel from "./ImageCarousel";
import ShowImageData from "./ShowImageData";

const ShowImage = (props) => {
  const {
    image,
    isImage,
    isVideo,
    isText,
    fileName,
    handleClose,
    setTagDetails,
    tagDetails,
    isEdit,
  } = props;
  const { isLoading } = useSelector((state) => state.loader);
  const [mainImage, setMainImage] = useState("");
  const [selected, setSelected] = useState([]);

  const seclectedUser = (user, id) => {
    if (!selected[id]) {
      selected[id] = [];
    }
    selected[id].push(user);
    setSelected({ ...selected });
  };
  useEffect(() => {
    let image1 = image.length == 0 ? "" : image[0];
    setMainImage(image1);
  }, [image]);

  useEffect(() => {
    tagDetails?.map((user) => {
      let temp = {
        x: Number,
        y: Number,
        data: {
          id: Number,
          first_name: "",
          last_name: "",
          profile_img: "",
        },
      };
      if (user?.media === mainImage) {
        temp.x = user?.coordinate_x;
        temp.y = user?.coordinate_y;
        temp.data.id = user?.user_id;
        temp.data.first_name = user?.user?.first_name;
        temp.data.last_name = user?.user?.last_name;
        temp.data.profile_img = user?.user?.profile_img;
      }
      seclectedUser(temp, user.media);
    });
  }, []);

  const handleRemove = (id, image) => {
    let temp = selected[image];
    selected[image] = temp.filter((el) => el?.data?.id !== id);
    setSelected({ ...selected });
  };
  useEffect(() => {
    let temp = [];
    if (tagDetails) temp = [...tagDetails];

    for (let x in selected) {
      for (let index = 0; index < selected[x]?.length; index++) {
        const element = selected[x][index];
        let data = {};
        data["user_id"] = element.data.id;
        data["coordinate_x"] = parseInt(element.x);
        data["coordinate_y"] = parseInt(element.y);
        data["media"] = x;
        temp.push(data);
      }
    }
    setTagDetails(temp);
  }, [selected]);

  //  console.log("tagdetails", tagDetails);

  const changeImage = (index) => {
    try {
      setMainImage(image[index]);
    } catch (error) {
      console.log("error", error);
    }
  };
  return (
    <div className="row">
      {/* {isLoading && <Loader />} */}
      {isLoading && <AppLoader />}
      <div>
        {image.length >= 1 && (
          <ShowImageData
            data={mainImage}
            id={mainImage}
            isImage={isImage}
            isVideo={isVideo}
            isText={isText}
            selected={selected}
            seclectedUser={seclectedUser}
            handleRemove={handleRemove}
            handleClose={handleClose}
            fileName={fileName}
            isEdit={isEdit}
            tagDetails={tagDetails}
          />
        )}
        {/* {image.length == 2 && (
          <ShowImageData
            data={image[1]}
            id={image[1]}
            isImage={isImage}
            isVideo={isVideo}
            isText={isText}
            selected={selected}
            seclectedUser={seclectedUser}
            handleRemove={handleRemove}
            handleClose={handleClose}
            fileName={fileName}
          />
        )} */}
      </div>
      {image.length >= 2 && (
        <ImageCarousel
          images={image}
          changeImage={changeImage}
          fromDashboard={false}
        />
      )}
    </div>
  );
};

export default ShowImage;
