import React from "react";
import Navbar from "../../pages/navbar/navbar";
import "./LeadsManage.scss";
import delIcon from "../../assets/img/deleteLeadIcon.svg";
import Autocomplete from "react-autocomplete";
import StatusModal from "./components/StatusModal";
import placeholderImg from "../../assets/img/placeholder_user.jpg";
import moment from "moment";
import plusIcon from "../../assets/img/plusIconFigma.svg";
// import deleteIcon from "../../assets/img/deleteLeadIcon.svg";
import edit from "../../assets/img/Edit_one.svg";
import editIcon from "../../assets/img/edit.svg";
import cross from "../../assets/img/cross.png";
import tick from "../../assets/img/tick-icon.svg";
import tickIcon from "../../assets/img/tick-blue.svg";
import CallAction from "./components/callAction";
import DeleteModal from "../../core/components/modal/DeleteModal";
import { ToastContainer } from "react-toastify";
import npMail from "../../assets/img/mail-icon.svg";
import Header from "../../core/components/layout/Header/Header";
import deleteIcon from "../../assets/img/Trash.svg";
export default function LeadsManage(props) {
  const {
    actionModal,
    setActionModal,
    leadsList,
    addGroup,
    groupName,
    setGroupName,
    statusModal,
    setStatusModal,
    leadUserData,
    setLeadUserData,
    editLead,
    deleteLead,
    deleteModal,
    setDeleteModal,
    sortFilter,
    setSortFilter,
    statusFilter,
    setStatusFilter,
    groupFilter,
    setGroupFilter,
    searchFilter,
    setSearchFilter,
    ExportToExcel,
    locationsAPIData,
    getLocations,
    searchFieldValue,
    setSearchFieldValue,
    locationFilterParams,
    setLocationFilterParams,
    emailEditId,
    setEmailEditId,
    emailEdit,
    setEmailEdit,
    phoneEdit,
    setPhoneEdit,
    phoneEditId,
    setPhoneEditId,
    sendConnectionHandle,
    errorGroupName,
    setErrorGroupName,
    errorEmail,
    setErrorEmail,
    isLoggedIn,
    errorPhone,
    deleteGroup,
    editGroup,
    editName,
    setEditName,
    currentIdx,
    cancelEdit,
    confirmEdit,
  } = props;

  return (
    <div className="leads bg-campaign">
      {isLoggedIn ? <Navbar isJobs={false} /> : <Header />}
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          columnGap: "20px",
          paddingLeft: "20px",
        }}
      >
        <div className="container mt-20 ">
          <div className="row">
            <div className="col-12">
              <div className="main-box bg-white mb-20">
                <div className="heading-row">
                  <h2 className="head">Company Leads</h2>
                </div>
                <div className="mb-20 ml-30 ml-sm-30 mr-30 mr-sm-30 group-bar">
                  <span className="group-title group-header">Groups: </span>
                  {leadsList?.group_list?.length < 4 && (
                    <>
                      <input
                        type="text"
                        placeholder="Add a Group"
                        className="input-group-add"
                        value={groupName}
                        onChange={(e) => setGroupName(e.target.value)}
                        maxLength={20}
                        onClick={() => setErrorGroupName("")}
                        // onBlur={() => setErrorGroupName("")}
                      />
                      <div
                        className="add-group-icon"
                        onClick={() => addGroup()}
                      >
                        <img src={plusIcon} className="" alt="" />
                      </div>
                      {errorGroupName && (
                        <label className="label-input100 text-danger ml-10 my-1">
                          {errorGroupName}
                        </label>
                      )}
                    </>
                  )}
                  <div
                    style={{
                      marginLeft: "10px",
                      display: "flex",
                      flexDirection: "row",
                    }}
                  >
                    {leadsList?.group_list?.map((data, idx, row) => {
                      return (
                        <>
                          <div className="groups" key={idx}>
                            {currentIdx == idx ? (
                              <input
                                type="text"
                                placeholder="Edit Group"
                                className="input-group-add"
                                value={editName}
                                onChange={(e) => setEditName(e.target.value)}
                                maxLength={20}
                                // onBlur={() => setErrorGroupName("")}
                              />
                            ) : (
                              <span className="group-title group-names">
                                {data}
                              </span>
                            )}

                            {idx ? (
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "row",
                                  columnGap: "5px",
                                }}
                              >
                                {currentIdx == idx ? (
                                  <>
                                    <img
                                      src={cross}
                                      alt=""
                                      onClick={() => cancelEdit()}
                                      style={{ cursor: "pointer" }}
                                    />
                                    <img
                                      src={tick}
                                      alt=""
                                      onClick={() => confirmEdit()}
                                      style={{ cursor: "pointer" }}
                                    />
                                  </>
                                ) : (
                                  <>
                                    <img
                                      src={edit}
                                      alt=""
                                      onClick={() => editGroup(idx, data)}
                                      style={{ cursor: "pointer" }}
                                    />
                                    <img
                                      src={deleteIcon}
                                      alt=""
                                      onClick={() => deleteGroup(idx, data)}
                                      style={{ cursor: "pointer" }}
                                    />
                                  </>
                                )}
                              </div>
                            ) : (
                              <></>
                            )}
                            {/* {idx + 1 === row.length ? <></> : <span> | </span>} */}
                          </div>
                          {idx !== leadsList.group_list.length - 1 ? (
                            <span
                              style={{ marginRight: "5px", fontSize: "17px" }}
                            >
                              |
                            </span>
                          ) : (
                            <></>
                          )}
                        </>
                      );
                    })}
                  </div>
                </div>
                <div className="wrapperLead">
                  <ul>
                    <li>
                      <div className="search">
                        <input
                          type="text"
                          placeholder="Search"
                          value={searchFilter}
                          onChange={(e) => setSearchFilter(e.target.value)}
                        />
                      </div>
                    </li>
                    <li>
                      <select
                        className="form-select"
                        style={{ width: "120px" }}
                        placeholder="Pick"
                        value={groupFilter}
                        onChange={(e) => {
                          setGroupFilter(e.target.value);
                        }}
                      >
                        <option default value="">
                          All
                        </option>
                        {leadsList?.group_list?.map((data, i) => (
                          <option key={i} value={data}>
                            {data}
                          </option>
                        ))}
                      </select>
                    </li>
                    <li>
                      <select
                        className="form-select"
                        value={statusFilter}
                        onChange={(e) => {
                          setStatusFilter(e.target.value);
                        }}
                        style={{ width: "127px" }}
                      >
                        <option value="">Status</option>
                        <option value="pending">Pending</option>
                        <option value="progress">Progress</option>
                        <option value="scheduled">Scheduled</option>
                        <option value="qualified">Qualified</option>
                        <option value="unqualified">Unqualified</option>
                      </select>
                    </li>

                    <li>
                      <select
                        className="form-select"
                        value={sortFilter}
                        onChange={(e) => {
                          setSortFilter(e.target.value);
                        }}
                        style={{ width: "132px" }}
                      >
                        <option default value="ascending">
                          Ascending
                        </option>
                        <option default value="descending">
                          Descending
                        </option>
                      </select>
                    </li>
                    <li className="autocomplete-wrapper">
                      <Autocomplete
                        inputProps={{ placeholder: "Location" }}
                        placeholder="location"
                        value={searchFieldValue}
                        items={locationsAPIData}
                        getItemValue={(item) => item.name}
                        shouldItemRender={() => locationsAPIData}
                        onChange={(e, value) => {
                          setSearchFieldValue(e.target.value);
                          getLocations(e.target);
                          // !e.target.value &&
                          //   handleFilter("location_type", null);
                        }}
                        onSelect={(value, obj) => {
                          setSearchFieldValue(value);
                          setLocationFilterParams({
                            ...locationFilterParams,
                            location_type: obj.type,
                            location_id: obj.id,
                          });
                        }}
                        renderItem={(item, isHighlighted) => (
                          <div
                            className={`item ${
                              isHighlighted ? "item-highlighted" : ""
                            }`}
                            key={item.name}
                          >
                            {item.name}
                          </div>
                        )}
                      />
                    </li>

                    {/* <li className="">Clear Filter</li> */}
                  </ul>

                  <ExportToExcel
                    apiData={leadsList?.lead_list}
                    fileName="LeadsData"
                  />
                </div>

                <div
                  className="table_data table-responsive mt-20"
                  style={{ overflow: "hidden" }}
                >
                  <table class="table">
                    <thead>
                      <tr>
                        <th
                          style={{ width: "11.11%", textAlign: "justify" }}
                          className="table_head"
                          scope="col"
                        >
                          Group
                        </th>
                        <th
                          style={{ width: "11.11%", textAlign: "justify" }}
                          className="table_head"
                          scope="col"
                        >
                          Date
                        </th>
                        <th
                          style={{ width: "11.11%" }}
                          className="table_head"
                          scope="col"
                        >
                          Name
                        </th>
                        <th
                          style={{ width: "19.11%", textAlign: "justify" }}
                          className="table_head"
                          scope="col"
                        >
                          Location
                        </th>
                        <th
                          style={{ width: "11.11%", textAlign: "justify" }}
                          className="table_head"
                          scope="col"
                        >
                          Contact
                        </th>
                        <th
                          style={{ width: "11.11%", paddingLeft: "15px" }}
                          className="table_head"
                          scope="col"
                        >
                          Action
                        </th>
                        <th
                          style={{ width: "11.11%" }}
                          className="table_head"
                          scope="col"
                        >
                          Status
                        </th>
                        {/* <th className="table_head" scope="col">
                        Action
                      </th> */}
                        <th
                          style={{ width: "11.11%", textAlign: "right" }}
                          className="table_head"
                          scope="col"
                        >
                          Delete
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {leadsList?.lead_list?.map((data, index) => {
                        return (
                          <tr key={index}>
                            <td className="table_cell" scope="row">
                              <select
                                style={{
                                  border: "none",
                                  paddingRight: "20px",
                                  paddingLeft: "0px",
                                  color: "#676a79",
                                  fontSize: "14px",
                                  width: "80px",
                                }}
                                value={data.group}
                                className="form-select"
                                onChange={(e) => {
                                  editLead(e.target.value, data.id, "group");
                                }}
                              >
                                {leadsList?.group_list?.map((data, i) => (
                                  <option key={i} value={data}>
                                    {data}
                                  </option>
                                ))}
                              </select>
                            </td>
                            <td className="table_cell">
                              {moment.utc(data.createdAt).format("DD/MM/YYYY")}
                            </td>
                            <td className="table_cell">
                              <div>
                                <p
                                  style={{
                                    whiteSpace: "nowrap",
                                    textOverflow: "ellipsis",
                                    overflow: "hidden",
                                    width: "100px",
                                  }}
                                >
                                  {data.user.full_name}
                                </p>
                              </div>
                            </td>
                            <td className="table_cell">
                              <p
                                style={{
                                  overflow: "hidden",
                                  whiteSpace: "nowrap",
                                  textOverflow: "ellipsis",
                                  width: "200px",
                                }}
                              >
                                {data.user.profile.location
                                  ? data.user.profile.location
                                  : "NA"}
                              </p>
                            </td>
                            <td
                              className="table_cell"
                              style={{ width: "18.22%" }}
                            >
                              <>
                                {emailEditId === index ? (
                                  <>
                                    <div className="d-flex">
                                      <input
                                        type="text"
                                        // disabled={
                                        //   emailEditId === index ? false : true
                                        // }
                                        placeholder="Email"
                                        value={
                                          emailEditId === index
                                            ? emailEdit
                                            : data.email
                                        }
                                        onChange={(e) =>
                                          setEmailEdit(e.target.value)
                                        }
                                        className="edit-input"
                                        autofocus
                                      />
                                      <img
                                        src={tickIcon}
                                        alt="tick_icon"
                                        className="curserPointer"
                                        onClick={() =>
                                          editLead(emailEdit, data.id, "email")
                                        }
                                      />
                                    </div>
                                    {errorEmail && (
                                      <label className="label-input100 text-danger">
                                        {errorEmail}
                                      </label>
                                    )}
                                  </>
                                ) : (
                                  <div
                                    className="d-flex"
                                    style={{ justifyContent: "space-between" }}
                                  >
                                    <p
                                      style={{
                                        overflow: "hidden",
                                        whiteSpace: "nowrap",
                                        textOverflow: "ellipsis",
                                        width: "200px",
                                      }}
                                    >
                                      {data.email}
                                    </p>
                                    <img
                                      src={editIcon}
                                      alt="edit_icon"
                                      className="curserPointer"
                                      onClick={() => {
                                        setEmailEditId(index);
                                        setEmailEdit(data.email);
                                      }}
                                    />
                                  </div>
                                )}
                                {phoneEditId === index ? (
                                  <>
                                    <div className="d-flex">
                                      <input
                                        type="text"
                                        // disabled={
                                        //   emailEditId === index ? false : true
                                        // }
                                        placeholder="Phone"
                                        value={
                                          phoneEditId === index
                                            ? phoneEdit
                                            : data.phone
                                        }
                                        onChange={(e) =>
                                          setPhoneEdit(e.target.value)
                                        }
                                        className="edit-input"
                                        autofocus
                                      />
                                      <img
                                        src={tickIcon}
                                        alt="tick_icon"
                                        className="curserPointer"
                                        onClick={() =>
                                          editLead(phoneEdit, data.id, "phone")
                                        }
                                      />
                                    </div>
                                    {errorPhone && (
                                      <label className="label-input100 text-danger">
                                        {errorPhone}
                                      </label>
                                    )}
                                  </>
                                ) : (
                                  <div
                                    className="d-flex"
                                    style={{ justifyContent: "space-between" }}
                                  >
                                    {data.phone}
                                    <img
                                      src={editIcon}
                                      alt="edit_icon"
                                      className="curserPointer"
                                      onClick={() => {
                                        setPhoneEditId(index);
                                        setPhoneEdit(data.phone);
                                      }}
                                    />
                                  </div>
                                )}
                              </>
                            </td>
                            <td
                              className="table_cell"
                              style={{ paddingLeft: "15px" }}
                            >
                              <p
                                onClick={
                                  data.connection_status === false ||
                                  data.connection_status === "cancel" ||
                                  data.connection_status === "reject" ||
                                  data.connection_status === "pending"
                                    ? () => {
                                        sendConnectionHandle(data.user_id);
                                      }
                                    : () => {
                                        setActionModal(!actionModal);
                                        setLeadUserData(data);
                                      }
                                }
                                className="lead_status"
                              >
                                {data.connection_status === false ||
                                data.connection_status === "cancel" ||
                                data.connection_status === "reject" ||
                                data.connection_status === "pending"
                                  ? "Connect"
                                  : data.lead_status === "pending"
                                  ? "Contact"
                                  : data.lead_status === "qualified" ||
                                    data.lead_status === "unqualified"
                                  ? "Complete"
                                  : data.lead_status === "progress"
                                  ? "Follow Up"
                                  : "Meeting/Call"}
                              </p>
                            </td>
                            <td className={`table_cell`}>
                              <span
                                onClick={() => {
                                  setLeadUserData(data);
                                }}
                                className={`${
                                  data.lead_status === "progress"
                                    ? "progres"
                                    : data.lead_status
                                }`}
                              >
                                {data.lead_status === "progress"
                                  ? "In Progress"
                                  : data.lead_status}
                              </span>

                              {(data.lead_status === "progress" ||
                                data.lead_status === "scheduled") && (
                                <div className="mt-1">
                                  <span className="schedule-table">
                                    {moment(data.schedule).format("DD/MM/YYYY")}
                                  </span>
                                </div>
                              )}
                              {(data.lead_status === "qualified" ||
                                data.lead_status === "unqualified") && (
                                <div className="mt-1">
                                  <span className="schedule-table">
                                    {moment(data.updatedAt).format(
                                      "DD/MM/YYYY"
                                    )}
                                  </span>
                                </div>
                              )}
                              {data.lead_status === "pending" && (
                                <div className="mt-1">
                                  <span className="schedule-table">N/A</span>
                                </div>
                              )}
                            </td>
                            <td
                              className="table_cell"
                              style={{ textAlign: "right" }}
                            >
                              <img
                                className="curserPointer"
                                src={delIcon}
                                alt="delete_icon"
                                onClick={() => {
                                  setDeleteModal(!deleteModal);
                                  setLeadUserData(data);
                                }}
                              />
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
          <ToastContainer />
        </div>
      </div>

      {actionModal && (
        <CallAction
          toggle={() => {
            setActionModal(!actionModal);
          }}
          modal={actionModal}
          title="Call To Action"
          leadUserData={leadUserData}
        />
      )}

      {statusModal && (
        <StatusModal
          toggle={() => {
            setStatusModal(!statusModal);
          }}
          modal={statusModal}
          title="Status"
          leadUserData={leadUserData}
        />
      )}

      {deleteModal && (
        <DeleteModal
          modal={deleteModal}
          toggle={() => setDeleteModal(!deleteModal)}
          title="Delete Confirmation"
          deleteConfirm={() => {
            deleteLead(leadUserData.id);
          }}
          text="Confirm your intention to delete the lead?"
        />
      )}
    </div>
  );
}
