import { BLOG_GET_ALL_BLOGS ,BLOG_GET_ALL_BY_ID, BLOG_GET_CATEGORY} from "../types";

export const initialState = {
 blogs: [],
 currentBlog:{},
 blogCategory:[]
};

const blog = (state = initialState, action) => {
 switch (action.type) {
  case BLOG_GET_ALL_BLOGS:
   return { ...state, blogs: action.payload };
   case BLOG_GET_ALL_BY_ID:
    return { ...state, currentBlog: action.payload };
    case BLOG_GET_CATEGORY:
      return{...state ,blogCategory:action.payload}
  default:
   return state;
 }
};

export default blog;