import {
  GET_CONNECTIONS_SUCCESS,
  GET_CONNECTIONS_FAIL,
  GET_FOLLOWERS_SUCCESS,
  GET_FOLLOWERS_FAIL,
  GET_FOLLOWING_SUCCESS,
  GET_FOLLOWING_FAIL,
} from "../types";

export const initialState = {
  connection: [],
  follower: [],
  following: [],
};

const connection = (state = initialState, action) => {
  switch (action.type) {
    case GET_CONNECTIONS_SUCCESS:
      return { ...state, connection: action.payload };
    case GET_CONNECTIONS_FAIL:
      return { ...state, connection: action.payload };
    case GET_FOLLOWERS_SUCCESS:
      return { ...state, follower: action.payload };
    case GET_FOLLOWERS_FAIL:
      return { ...state, follower: action.payload };
    case GET_FOLLOWING_SUCCESS:
      return { ...state, following: action.payload };
    case GET_FOLLOWING_FAIL:
      return { ...state, following: action.payload };
    default:
      return state;
  }
};

export default connection;
