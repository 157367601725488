import React, { useEffect, useState } from "react";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import email from "../../../assets/img/a.svg";
import pass from "../../../assets/img/shield-security.svg";
import FormInput from "../../../core/components/Inputs/FormInput";
import "../welcome/PortalAuth.scss";
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { login, loginWithNP } from "../../../store/portal/portalAction";

const LoginModal = (props) => {
  const { modal, toggle, data, handleNPSubmit } = props;
  const navigate = useNavigate();
  const { param } = useParams();
  const dispatch = useDispatch();
  const [formData, setFormData] = useState({
    email: " ",
    password: "",
    rememberMe: false,
  });
  const [error, setError] = useState({
    email: "",
    password: "",
  });
  const [errorMessage, setErrorMessage] = useState("");

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
    setErrorMessage("");
    setError({
      email: "",
      password: "",
    });
  };
  const handleValidation = (type) => {
    let formIsValid = true;
    let emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
    let passwordValid =
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!""#$%&'()*+,-./:;<=>?@[\]^_`{|}~])[A-Za-z\d!""#$%&'()*+,-./:;<=>?@[\]^_`{|}~]{8,}$/;

    if (type === "email" || type === "all") {
      if (!formData?.email.trim()) {
        setError({ ...error, email: "Please enter email" });
        formIsValid = false;
        return;
      } else if (!emailRegex.test(formData?.email.trim())) {
        setError({
          ...error,
          email: "Please enter your email in a valid format.",
        });
        formIsValid = false;
        return;
      } else {
        setError({
          ...error,
          email: "",
        });
      }
    }
    if (type === "all" || type === "password") {
      if (!formData?.password) {
        setError({
          ...error,
          password: "Please enter password",
        });
        formIsValid = false;
        return;
      } else if (!passwordValid.test(formData?.password.trim())) {
        setError({
          ...error,
          password:
            'The password should be minimum 8 characters long and contain at least one : Upper and Lower Case Alphabet, Number, Special Character. The special characters that can be used are [!""#$%&\'()*+,-./:;<=>?@[]^_`{|}~]',
        });
        formIsValid = false;
        return;
      } else {
        setError({
          ...error,
          password: "",
        });
      }
    }

    return formIsValid;
  };

  const handleModalSubmit = (e) => {
    e.preventDefault();
    if (handleValidation("all")) {
      dispatch(
        login(
          {
            email: formData?.email,
            password: formData?.password,
            id: parseInt(data?.id),
          },
          true,
          param
        )
      )
        .then((res) => {
          if (res?.success) navigate(`/tp/portal/${param}/dashboard`);
          else setErrorMessage(res?.message);
        })
        .catch((err) => {
          setErrorMessage(err?.response?.data?.message);
        });
    }
  };

  return (
    <Modal
      isOpen={modal}
      style={{ width: "464px" }}
      aria-labelledby="contained-modal-title-vcenter"
      backdrop={true}
      centered
      toggle={toggle}
    >
      <div className="login-modal">
        <ModalBody>
          <div className="login-form">
            <form
              className="portal-login-form auth-form"
              style={{
                padding: "0",
                width: "auto",
                marginBottom: "0px",
                float: "none",
              }}
            >
              <h3>Log In </h3>
              {errorMessage && (
                <label className="label-input100 text-danger">
                  {errorMessage}
                </label>
              )}
              <FormInput
                label="Email"
                name="email"
                type="email"
                onChange={(e) => handleChange(e)}
                placeholder="Enter Email"
                onBlur={() => handleValidation("email")}
                error={error?.email}
                imageCustomClass="img-prop"
                value={formData?.email}
              />
              <FormInput
                label="Password"
                name="password"
                type="password"
                onChange={(e) => handleChange(e)}
                placeholder="Enter Password"
                onBlur={() => handleValidation("password")}
                error={error?.password}
                value={formData?.password}
              />
              {/* <div className="d-flex login-checkbox ">
                <div className="form-check">
                  <input
                    type="checkbox"
                    className="form-check-input"
                    id="exampleCheck1"
                    checked={formData?.rememberMe}
                    onClick={() =>
                      setFormData({
                        ...formData,
                        rememberMe: !formData?.rememberMe,
                      })
                    }
                  />
                  <label className="form-check-label" htmlFor="exampleCheck1">
                    Keep me logged in
                  </label>
                </div>
                <small>
                  <span className="forgot">Forgot password?</span>
                </small>
              </div> */}
              <div className="text-center d-grid mt-3">
                <button
                  type="submit"
                  className="btn btn-orange"
                  onClick={(e) => handleModalSubmit(e)}
                  style={{ background: `${data?.theme}` }}
                >
                  Login with NobelPage
                </button>
              </div>
            </form>
          </div>
        </ModalBody>
      </div>
    </Modal>
  );
};

export default LoginModal;
